import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button
} from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  localFilePath,
  fotoStyle2
} from "../../redux/actions/constants";
import pdfIcon from "../../images/pdfIcon.png";
import CgnMessage from "../toolbox/CgnMessage";


class CustomerTicketDocumentDetail extends Component {
  state = {
    pageTitle: "Müşteriye Ait Belgeler Detay (ÜTS Belge Kaydı)",
    pageTitleDetail: "Müşteriye Ait Belgeler Detay (ÜTS Belge Kaydı)",
    pageListLink: "musteri-ticket-belgeleri-listele",
    breadcrumb: [{ text: "Müşteriye Ait Belgeler Detay", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    customerTicketImageDto: [],
    customerDocumentGuid: "",
    description: "",
    descriptionError: "",
    descriptionHtml: "",
    customerTicketData: [],
    customerDocumentImageDto: [],
    utsDocumentDto: [],
    technicalReadingDocumentDto: [],
    customerDocumentSuitableImageDto: [],
    customerTicket2Dto: [],
    status: "",
    customerMail: "",
    customerGuid: "",
    personelName: "",
    guid: "",
    createdAt: "",
    isLoadingModal: false,
    isHiddenSuitable: true,
    isHiddenUnSuitable: true, 
    isOpenModalPayment: false,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ]
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
      this.setState({
        isLoading: true,
      });
      this.state.customerGuid = urlSplit[2];
      this.state.customerDocumentGuid = urlSplit[3];
      axios
        .get(URL + "/customerTickets/getlist/" +  this.state.customerGuid + "/" +  this.state.customerDocumentGuid)
        .then((response) => {
          this.setState({
            customerGuid: response.data.customerGuid,
            customerName: response.data.customerName,
            description: this.state.description,
            createdAt: response.data.createdAt,
            customerDocumentImageDto: response.data.customerDocumentImageDto,
            customerDocumentSuitableImageDto: response.data.customerDocumentSuitableImageDto,
            technicalReadingDocumentDto: response.data.technicalReadingDocumentDto,
            utsDocumentDto: response.data.utsDocumentDto,
            customerTicket2Dto: response.data.customerTicket2Dto,
            customerDocumentGuid: response.data.customerDocumentGuid,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };

    var self1 = this;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="p-3">
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              <div className="row pl-1">
                <p>Müşteri : {this.state.customerName}</p>
              </div>
              {this.state.customerTicket2Dto !== null &&
                this.state.customerTicket2Dto.length > 0 &&
                this.state.customerTicket2Dto.map(function (item) {
                  return (
                    <>
           <Table>
              {item.customerTicketImageDto &&
                 item.customerTicketImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Ticket Sisteminden Eklenen Belgeler</th>
                    </tr>
                    <Row>
                      {item.customerTicketImageDto.map(function (item1) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item1.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item1.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "170px" }}>
                                {item1.imageName} - {item1.createdAt}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
               </Table>
               </>
                 );
              })}

             {this.state.customerDocumentImageDto &&
                this.state.customerDocumentImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Müşteri Tarafından Gönderilen ve Sisteme Eklenen Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.customerDocumentImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

             {this.state.technicalReadingDocumentDto &&
                this.state.technicalReadingDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Teknik Okuma Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.technicalReadingDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

             {this.state.customerDocumentSuitableImageDto &&
                this.state.customerDocumentSuitableImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Çeviri Belgeleri</th>
                    </tr>
                    <Row>
                      {this.state.customerDocumentSuitableImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

            {this.state.utsDocumentDto &&
                this.state.utsDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>UTS Tarama Belgeleri</th>
                    </tr>
                    <Row>
                      {this.state.utsDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerTicketDocumentDetail;