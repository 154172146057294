import React, { Component } from "react";
import { Row, Col, Card, Form,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import ErrorCodeCategoryAddUpdate from "../errorCodeCategory/ErrorCodeCategoryAddUpdate";
import CgnButton from "../toolbox/CgnButton";

class ErrorCodeAddUpdate extends Component {
  state = {
    pageTitle: "Hata Kodu Ekle",
    pageTitleAdd: "Hata Kodu Ekle",
    pageTitleUpdate: "Hata Kodu Güncelle",
    pageAddLink: "hata-kodu-ekle",
    pageListLink: "hata-kodu-listele",
    breadcrumb: [{ text: "Hata Kodu", link: "#" }],
    isLoading: false,
    urlSplit: [],
    errorCodeCategoryGuid: "",
    errorCodeCategoryArr: [],
    name: "",
    errorCodeDefinition: "",
    guid: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    errorCodeCategoryError: "",
    errorCodeDefinitionError: "",
    isOpenModalErrorCodeCategory: false,
    modalButtonIdx: 0,
    isNull: false,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2;
    axios
    .get(URL + "/errorCodeCategories/getlist/" + statusType, config)
    .then((response) => {
      var myArr = response.data.map(function (item) {
        return {
          value: item.guid,
          label: item.name,
        };
      });
      this.setState({
        errorCodeCategoryArr: myArr,
        isLoading: false,
      });
    })
    .catch((error) => {
      this.setState({
        alertErrorMessage: error.response.data,
        isLoading: false,
      });
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/errorCodes/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              name: response.data.name,
              errorCodeCategoryGuid: response.data.errorCodeCategoryGuid,
              errorCodeDefinition: response.data.errorCodeDefinition,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        errorCodeCategoryGuid: "",
        errorCodeDefinition: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalErrorCodeCategory &&
      this.props.location.modalAddedErrorCodeCategory !== undefined &&
      this.props.location.modalAddedErrorCodeCategory.isModal !== undefined &&
      this.props.location.modalAddedErrorCodeCategory.isModal === false
    ) {
      
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/errorCodeCategories/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            errorCodeCategoryArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedErrorCodeCategory.data &&
              element.name === this.props.location.modalAddedErrorCodeCategory.data
            ) {
              this.setState({
                errorCodeCategoryGuid: element.guid,
                errorCodeCategoryError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedErrorCodeCategory.isModal = true;
      this.setState({
        isOpenModalErrorCodeCategory: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      errorCodeDefinitionError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if(name === "errorCodeDefinition" && value === ""){
      this.setState({
        errorCodeDefinitionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  addModalErrorCodeCategory = () => {
    this.setState({
      isOpenModalErrorCodeCategory: !this.state.isOpenModalErrorCodeCategory,
    });
  };

  clearPage() {
    this.setState({
      name: "",
      errorCodeCategoryGuid: "",
      errorCodeDefinition: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      errorCodeDefinitionError: CgnMessage.textErrorMessage,
      errorCodeCategoryError: CgnMessage.textErrorMessage
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleErrorCodeCategorySelectChange(option) {
    if (option) {
      this.setState({
        errorCodeCategoryGuid: option.value,
        alertErrorMessage: "",
        errorCodeCategoryError: "",
      });
    } else {
      this.setState({
        errorCodeCategoryGuid: "",
        alertErrorMessage: CgnMessage.alertErrorMessage,
        errorCodeCategoryError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if(this.state.errorCodeDefinition === ""){
      this.setState({
        errorCodeDefinitionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } 
    if(this.state.errorCodeCategoryGuid === ""){
      this.setState({
       errorCodeCategoryError: CgnMessage.textErrorMessage,
       alertErrorMessage: CgnMessage.alertErrorMessage
      });
    }
    else if (this.state.name !== "" && this.state.errorCodeDefinition !== "" && this.state.errorCodeCategoryGuid !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        name: this.state.name,
        errorCodeCategoryGuid: this.state.errorCodeCategoryGuid,
        errorCodeDefinition: this.state.errorCodeDefinition,
        status: this.state.status,
      };
      var URLParam = "/errorCodes/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "hata-kodu-guncelle"
      ) {
        URLParam = "/errorCodes/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          errorCodeCategoryGuid: this.state.errorCodeCategoryGuid,
          errorCodeDefinition: this.state.errorCodeDefinition,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "hata-kodu-guncelle"
            ) {
              window.location.href = "/hata-kodu-listele";
            }
            if (this.state.urlSplit[1] === "hata-kodu-ekle") {
              window.location.reload();
            }
            var modalAddedErrorCode = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedErrorCode });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "hata-kodu-ekle" ||
          this.state.urlSplit[1] === "hata-kodu-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                <Col lg="6">
                <div className="flex-container">
                  <div className="p-0 flexInput">
                    <CgnReactSelect
                      name="errorCodeCategoryGuid"
                      label="Hata Kodu Kategori [*]"
                      isMulti={false}
                      selectValue={this.state.errorCodeCategoryGuid}
                      options={this.state.errorCodeCategoryArr}
                      onChange={(option) =>
                        this.handleErrorCodeCategorySelectChange(option)
                      }
                      error={this.state.errorCodeCategoryError}
                      placeholder="Lütfen hata kodu kategorisi seçin..."
                    />
                     </div>
                     <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalErrorCodeCategory()}
                            text={"+"}
                          />
                        </div>
                        </div>
                  </Col>

                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Hata Kodu [*]"
                      value={this.state.name}
                      placeHolder="Lütfen hata kodu girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                  </Row>
                  <Row>

                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="errorCodeDefinition"
                      label="Hata Kodu Tanımı [*]"
                      value={this.state.errorCodeDefinition}
                      placeHolder="Lütfen hata kodu tanımı girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.errorCodeDefinitionError}
                    />
                  </Col>

                  <Col lg="6">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "hata-kodu-ekle" ||
              this.state.urlSplit[1] === "hata-kodu-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalErrorCodeCategory}
          toggle={this.addModalErrorCodeCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Hata Kodu Kategori Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ErrorCodeCategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalErrorCodeCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(ErrorCodeAddUpdate);
