import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL,  fotoStyle, resizeFile, } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";
import noImg from "../../images/no-img.png";

class CastingCostAddUpdate extends Component {
  state = {
    pageTitle: "Döküm Maliyeti Ekle",
    pageTitleAdd: "Döküm Maliyeti Ekle",
    pageTitleUpdate: "Döküm Maliyeti Güncelle",
    pageAddLink: "dokum-maliyet-ekle",
    pageListLink: "dokum-maliyet-listele",
    breadcrumb: [{ text: "Döküm Maliyeti", link: "#" }],
    isLoading: false,
    urlSplit: [],
    offerSieveGuid: "",
    offerSieveArr: [],
    offerSieveGuidError: "",
    sieveProductGuid: "",
    sieveProductArr: [],
    sieveEyeSize: "",
    sieveProductError: "",
    sieveSizeArr: [],
    sieveEyeSizeArr: [],
    sieveSizeMultiplier: 0,
    sieveEyeSizeMultiplier: 0,
    sieveSize: "",
    tDIEster: 0,
    castingCostTotal: 0,
    tDIEsterRate: 0,
    tDIEsterTotal: 0,
    moca: 0,
    mocaPercent: 0,
    mocaRate: 0,
    mocaTotal: 0,
    transport: 0,
    transportRate: 0,
    transportTotal: 0,
    dSM: 0,
    dSMRate: 0,
    dSMTotal: 0,
    sandblasting: 0,
    sandblastingRate: 0,
    sandblastingTotal: 0,
    sidePlate: 0,
    sidePlateRate: 0,
    sidePlateTotal: 0,
    lama810: 0,
    lama810Rate: 0,
    lama810Total: 0,
    lama1020: 0,
    lama1020Rate: 0,
    lama1020Total: 0,
    rope: 0,
    ropeRate: 0,
    ropeTotal: 0,
    ring: 0,
    ringRate: 0,
    ringTotal: 0,
    pim: 0,
    pimRate: 0,
    pimTotal: 0,
    profitRate: 0,
    pieceTotal: 0,
    m2Total: 0,
    total: 0,
    tDIEther: 0,
    tDIEtherRate: 0,
    tDIEtherTotal: 0,
    mDIEster: 0,
    mDIEsterRate: 0,
    mDIEsterTotal: 0,
    mDIEther: 0,
    mDIEtherRate: 0,
    mDIEtherTotal: 0,
    bDO: 0,
    bDORate: 0,
    bDOTotal: 0,
    sundry: 0,
    sundryRate: 0,
    sundryTotal: 0,
    description: "",
    dsmFixed: 0,
    ropeFixed: 0,
    ringFixed: 0,
    pimFixed: 0,
    sundryKg: 0,
    productWeightKg: 0,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    bDOPercent: 0,
    shoreA: "",
    castingCostPictureDto: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/fixedCastings/getfirst", config)
      .then((response) => {
        if (response.data !== null) {
          this.setState({
            bDORate: parseFloat(response.data.bdoRate),
            dSMRate: parseFloat(response.data.dsmRate),
            lama1020Rate: parseFloat(response.data.lama1020Rate),
            lama810Rate: parseFloat(response.data.lama810Rate),
            mocaPercent: parseFloat(response.data.mocaPercent),
            pimRate: parseFloat(response.data.pimRate),
            profitRate: parseFloat(response.data.profitRate),
            mocaRate: parseFloat(response.data.mocaRate),
            ringRate: parseFloat(response.data.ringRate),
            ropeRate: parseFloat(response.data.ropeRate),
            sandblastingRate: parseFloat(response.data.sandblastingRate),
            sidePlateRate: parseFloat(response.data.sidePlateRate),
            transportRate: parseFloat(response.data.transportTotal),
            dsmFixed: parseFloat(response.data.dsm),
            ropeFixed: parseFloat(response.data.rope),
            ringFixed: parseFloat(response.data.ring),
            pimFixed: parseFloat(response.data.pim),
            tDIEsterRate: parseFloat(response.data.tdiEsterRate),
            mDIEsterRate: parseFloat(response.data.mdiEsterRate),
            tDIEtherRate: parseFloat(response.data.tdiEtherRate),
            mDIEtherRate: parseFloat(response.data.mdiEtherRate),
            bDOPercent: parseFloat(response.data.bdoPercent),
            shoreA: response.data.shoreA,
          });
          this.ProfitRateTotalCalculate(null);
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/offersieves/getListdocumenttype/", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.offerNumber}-${item.offerDate}-${item.customerName}`,
          };
        });
        this.setState({
          offerSieveArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    var statusType = 2;
    await axios
      .get(URL + "/sieveProducts/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}-${item.sieveSize}-${item.sieveEyeSize}`,
            sieveSize: item.sieveSize,
            sieveEyeSize: item.sieveEyeSize,
          };
        });
        this.setState({
          sieveProductArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .get(URL + "/castingCosts/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              offerSieveGuid: response.data.offerSieveGuid,
              sieveProductGuid: response.data.sieveProductGuid,
              sieveSize: response.data.sieveSize,
              sieveEyeSize: response.data.sieveEyeSize,
              tDIEster: parseFloat(response.data.tdiEster),
              moca: parseFloat(response.data.moca),
              mocaRate: parseFloat(response.data.mocaRate),
              mocaPercent: parseFloat(response.data.mocaPercent),
              transport: parseFloat(response.data.transport),
              transportRate: parseFloat(response.data.transportRate),
              dSM: parseFloat(response.data.dsm),
              dSMRate: parseFloat(response.data.dsmRate),
              sandblasting: parseFloat(response.data.sandblasting),
              sandblastingRate: parseFloat(response.data.sandblastingRate),
              sidePlate: parseFloat(response.data.sidePlate),
              sidePlateRate: parseFloat(response.data.sidePlateRate),
              lama810: parseFloat(response.data.lama810),
              lama810Rate: parseFloat(response.data.lama810Rate),
              lama1020: parseFloat(response.data.lama1020),
              lama1020Rate: parseFloat(response.data.lama1020Rate),
              rope: parseFloat(response.data.rope),
              ropeRate: parseFloat(response.data.ropeRate),
              ring: parseFloat(response.data.ring),
              ringRate: parseFloat(response.data.ringRate),
              pim: parseFloat(response.data.pim),
              pimRate: parseFloat(response.data.pimRate),
              profitRate: parseFloat(response.data.profitRate),
              pieceTotal: parseFloat(response.data.pieceTotal),
              m2Total: parseFloat(response.data.m2Total),
              total: parseFloat(response.data.total),
              tDIEther: parseFloat(response.data.tdiEther),
              tDIEtherRate: parseFloat(response.data.tdiEtherRate),
              mDIEster: parseFloat(response.data.mdiEster),
              mDIEther: parseFloat(response.data.mdiEther),
              mDIEtherRate: parseFloat(response.data.mdiEtherRate),
              bDO: parseFloat(response.data.bdo),
              bDORate: parseFloat(response.data.bdoRate),
              castingCostTotal: parseFloat(response.data.castingCostTotal),
              isLoading: false,
              tDIEsterTotal: parseFloat(response.data.tdiEsterTotal),
              tDIEtherTotal: parseFloat(response.data.tdiEtherTotal),
              mDIEsterTotal: parseFloat(response.data.mdiEsterTotal),
              mDIEtherTotal: parseFloat(response.data.mdiEtherTotal),
              bDOTotal: parseFloat(response.data.bdoTotal),
              mocaTotal: parseFloat(response.data.mocaTotal),
              dSMTotal: parseFloat(response.data.dsmTotal),
              sandblastingTotal: parseFloat(response.data.sandblastingTotal),
              sidePlateTotal: parseFloat(response.data.sidePlateTotal),
              lama810Total: parseFloat(response.data.lama810Total),
              lama1020Total: parseFloat(response.data.lama1020Total),
              ropeTotal: parseFloat(response.data.ropeTotal),
              ringTotal: parseFloat(response.data.ringTotal),
              pimTotal: parseFloat(response.data.pimTotal),
              transportTotal: parseFloat(response.data.transportTotal),
              description: response.data.description,
              sundry: parseFloat(response.data.sundry),
              sundryRate: parseFloat(response.data.sundryRate),
              sundryTotal: parseFloat(response.data.sundryTotal),
              sundryKg: parseFloat(response.data.sundryKg),
              productWeightKg: parseFloat(response.data.productWeightKg),
              tDIEsterRate: parseFloat(response.data.tdiEsterRate),
              mDIEsterRate: parseFloat(response.data.mdiEsterRate),
              tDIEtherRate: parseFloat(response.data.tdiEtherRate),
              mDIEtherRate: parseFloat(response.data.mdiEtherRate),
              bDOPercent: parseFloat(response.data.bdoPercent),
              shoreA: response.data.shoreA,
              castingCostPictureDto: response.data.castingCostPictureDto,
            });
          }
          if (response.data.sieveSize !== "") {
            this.state.sieveSizeArr = response.data.sieveSize.toLowerCase().split("x");
            let sieveEpisode =
              (this.state.sieveSizeArr[0] / 1000) *
              (this.state.sieveSizeArr[1] / 1000);
            this.setState({
              sieveSizeMultiplier: sieveEpisode,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  async handleOfferSieveSelectChange(option) {
    if (option) {
      this.setState({
        offerSieveGuid: option.value,
        offerSieveGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerSieveGuid: null,
        offerSieveGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleSieveProductSelectChange(option) {
    if (option) {
      this.setState({
        sieveProductGuid: option.value,
        sieveSize: option.sieveSize,
        sieveEyeSize: option.sieveEyeSize,
        sieveProductError: "",
        alertErrorMessage: "",
      });

      if (option.sieveSize !== "") {
        const searchTxt = "x";
        this.state.sieveSizeArr = option.sieveSize
          .toLowerCase()
          .split(searchTxt.toLowerCase());
        let sieveEpisode =
          (this.state.sieveSizeArr[0] / 1000) * (this.state.sieveSizeArr[1] / 1000);
        await this.setState({
          sieveSizeMultiplier: sieveEpisode,
        });
      }
    } else {
      this.setState({
        sieveProductGuid: null,
        sieveSize: "",
        sieveEyeSize: "",
        sieveEyeSizeMultiplier: 0,
        sieveSizeMultiplier: 0,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        sieveProductError: CgnMessage.textErrorMessage,
      });
    }

    this.ProfitRateTotalCalculate(null);
    this.M2TotalCalculate(null);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
  };

  async handleTDIEsterChange(e) {
    if (e) {
      await this.setState({
        tDIEster: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        tDIEster: 0,
      });
    }

    this.TDIPriceCalculate(null);
    this.MocaTotalCalculate(e.target.value);
    this.productWeightKgCalculate();
  }

  async handleTDIEsterRateChange(e) {
    if (e) {
      await this.setState({
        tDIEsterRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        tDIEsterRate: 0,
      });
    }

    this.TDIPriceCalculate(null);
  }

  TDIPriceCalculate = async (event) => {
    var tDITotal =
      parseFloat(this.state.tDIEsterRate) * parseFloat(this.state.tDIEster);
    await this.setState({
      tDIEsterTotal: tDITotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleMocaChange(e) {
    if (e) {
      await this.setState({
        moca: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        moca: 0,
      });
    }

    this.MocaPriceCalculate(null);
  }

  async handleMocaRateChange(e) {
    if (e) {
      await this.setState({
        mocaRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mocaRate: 0,
      });
    }

    this.MocaPriceCalculate(null);
    this.TransportTotalCalculate(e.target.value);
  }

  async handleMocaPercentChange(e) {
    if (e) {
      await this.setState({
        mocaPercent: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mocaPercent: 0,
      });
    }

    this.MocaPriceCalculate(null);
    this.TransportTotalCalculate(e.target.value);
  }

  async handleBDOPercentChange(e) {
    if (e) {
      await this.setState({
        bDOPercent: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bDOPercent: 0,
      });
    }

    this.BDOPriceCalculate(null);
    this.TransportTotalCalculate(e.target.value);
  }

  MocaPriceCalculate = async (event) => {
    var tDIRate = 0;

    if (this.state.tDIEster !== 0) {
      tDIRate = parseFloat(this.state.tDIEster);
    } else if (this.state.tDIEther !== 0) {
      tDIRate = parseFloat(this.state.tDIEther);
    }

    var moca = tDIRate * parseFloat(this.state.mocaPercent / 100);

    await this.setState({
      moca: moca.toFixed(2),
    });

    var mocaPercentTotal =
      parseFloat(this.state.moca) *
      parseFloat(this.state.mocaRate);

    await this.setState({
      mocaTotal: mocaPercentTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handledSMChange(e) {
    if (e) {
      await this.setState({
        dSM: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        dSM: 0,
      });
    }

    this.DSMPriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handledSMRateChange(e) {
    if (e) {
      await this.setState({
        dSMRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        dSMRate: 0,
      });
    }

    this.DSMPriceCalculate(null);
  }

  DSMPriceCalculate = async (event) => {
    var dSMTotal = parseFloat(this.state.dSMRate) * parseFloat(this.state.dSM);
    await this.setState({
      dSMTotal: dSMTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleSandblastingChange(e) {
    if (e) {
      await this.setState({
        sandblasting: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sandblasting: 0,
      });
    }

    this.SandblastingPriceCalculate(null);
  }

  async handleSandblastingRateChange(e) {
    if (e) {
      await this.setState({
        sandblastingRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sandblastingRate: 0,
      });
    }

    this.SandblastingPriceCalculate(null);
  }

  SandblastingPriceCalculate = async (event) => {
    var sandblastingTotal =
      parseFloat(this.state.sandblastingRate) *
      parseFloat(this.state.sandblasting);
    await this.setState({
      sandblastingTotal: sandblastingTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleSidePlateChange(e) {
    if (e) {
      await this.setState({
        sidePlate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sidePlate: 0,
      });
    }

    this.SidePlatePriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handleSidePlateRateChange(e) {
    if (e) {
      await this.setState({
        sidePlateRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sidePlateRate: 0,
      });
    }

    this.SidePlatePriceCalculate(null);
  }

  SidePlatePriceCalculate = async (event) => {
    var sidePlateTotal =
      parseFloat(this.state.sidePlateRate) * parseFloat(this.state.sidePlate);
    await this.setState({
      sidePlateTotal: sidePlateTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleLama810Change(e) {
    if (e) {
      await this.setState({
        lama810: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama810: 0,
      });
    }

    this.Lama810PriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handleLama810RateChange(e) {
    if (e) {
      await this.setState({
        lama810Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama810Rate: 0,
      });
    }

    this.Lama810PriceCalculate(null);
  }

  Lama810PriceCalculate = async (event) => {
    var lama810Total =
      parseFloat(this.state.lama810Rate) * parseFloat(this.state.lama810);
    await this.setState({
      lama810Total: lama810Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleLama1020Change(e) {
    if (e) {
      await this.setState({
        lama1020: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama1020: 0,
      });
    }

    this.Lama1020PriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handleLama1020RateChange(e) {
    if (e) {
      await this.setState({
        lama1020Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama1020Rate: 0,
      });
    }

    this.Lama1020PriceCalculate(null);
  }

  Lama1020PriceCalculate = async (event) => {
    var lama1020Total =
      parseFloat(this.state.lama1020Rate) * parseFloat(this.state.lama1020);
    await this.setState({
      lama1020Total: lama1020Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRopeChange(e) {
    if (e) {
      await this.setState({
        rope: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        rope: 0,
      });
    }

    this.RopePriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handleRopeRateChange(e) {
    if (e) {
      await this.setState({
        ropeRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        ropeRate: 0,
      });
    }

    this.RopePriceCalculate(null);
  }

  RopePriceCalculate = async (event) => {
    var ropeTotal =
      parseFloat(this.state.ropeRate) * parseFloat(this.state.rope);
    await this.setState({
      ropeTotal: ropeTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRingChange(e) {
    if (e) {
      await this.setState({
        ring: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        ring: 0,
      });
    }

    this.RingPriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handleRingRateChange(e) {
    if (e) {
      await this.setState({
        ringRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        ringRate: 0,
      });
    }

    this.RingPriceCalculate(null);
  }

  RingPriceCalculate = async (event) => {
    var ringTotal =
      parseFloat(this.state.ringRate) * parseFloat(this.state.ring);
    await this.setState({
      ringTotal: ringTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handlePimChange(e) {
    if (e) {
      await this.setState({
        pim: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        pim: 0,
      });
    }

    this.PimPriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handlePimRateChange(e) {
    if (e) {
      await this.setState({
        pimRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        pimRate: 0,
      });
    }

    this.PimPriceCalculate(null);
  }

  PimPriceCalculate = async (event) => {
    var pimTotal = parseFloat(this.state.pimRate) * parseFloat(this.state.pim);
    await this.setState({
      pimTotal: pimTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleTransportChange(e) {
    if (e) {
      await this.setState({
        transport: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        transport: 0,
      });
    }

    this.TransportPriceCalculate(null);
  }

  async handleTransportRateChange(e) {
    if (e) {
      await this.setState({
        transportRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        transportRate: 0,
      });
    }

    this.TransportPriceCalculate(null);
  }

  TransportPriceCalculate = async (event) => {
    var transportTotal =
      parseFloat(this.state.transportRate) * parseFloat(this.state.transport);
    await this.setState({
      transportTotal: transportTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleSundryChange(e) {
    if (e) {
      await this.setState({
        sundry: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sundry: 0,
      });
    }

    this.SundryPriceCalculate(null);
  }

  async handleSundryRateChange(e) {
    if (e) {
      await this.setState({
        sundryRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sundryRate: 0,
      });
    }

    this.SundryPriceCalculate(null);
  }

  SundryPriceCalculate = async (event) => {
    var sundryTotal =
      parseFloat(this.state.sundryRate) * parseFloat(this.state.sundry);
    await this.setState({
      sundryTotal: sundryTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleSundryKgChange(e) {
    if (e) {
      await this.setState({
        sundryKg: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sundryKg: 0,
      });
    }

    this.productWeightKgCalculate();
  }

  async handleShoreAChange(e) {
    if (e) {
      await this.setState({
        shoreA: e.target.value,
      });
    } else {
      await this.setState({
        shoreA: "",
      });
    }
  }

  productWeightKgCalculate = async () => {
    var total = 0;

    // Direk Yazılanlar
    if (this.state.lama810 && this.state.lama810 !== 0) {
      total += parseFloat(this.state.lama810);
    }
    if (this.state.lama1020 && this.state.lama1020 !== 0) {
      total += parseFloat(this.state.lama1020);
    }
    if (this.state.sidePlate && this.state.sidePlate !== 0) {
      total += parseFloat(this.state.sidePlate);
    }
    if (this.state.tDIEster && this.state.tDIEster !== 0) {
      total += parseFloat(this.state.tDIEster);
    }
    if (this.state.tDIEther && this.state.tDIEther !== 0) {
      total += parseFloat(this.state.tDIEther);
    }
    if (this.state.mDIEster && this.state.mDIEster !== 0) {
      total += parseFloat(this.state.mDIEster);
    }
    if (this.state.mDIEther && this.state.mDIEther !== 0) {
      total += parseFloat(this.state.mDIEther);
    }
    if (this.state.bDO && this.state.bDO !== 0) {
      total += parseFloat(this.state.bDO);
    }
    if (this.state.sundryKg && this.state.sundryKg !== 0) {
      total += parseFloat(this.state.sundryKg);
    }

    // Sabitle Çarpılanlar
    if (this.state.dSM && this.state.dSM !== 0) {
      total += parseFloat(this.state.dSM) * parseFloat(this.state.dsmFixed);
    }
    if (this.state.rope && this.state.rope !== 0) {
      total += parseFloat(this.state.rope) * parseFloat(this.state.ropeFixed);
    }
    if (this.state.ring && this.state.ring !== 0) {
      total += parseFloat(this.state.ring) * parseFloat(this.state.ringFixed);
    }
    if (this.state.pim && this.state.pim !== 0) {
      total += parseFloat(this.state.pim) * parseFloat(this.state.pimFixed);
    }

    // Moca ekleniyor
    if (this.state.moca && this.state.moca !== 0) {
      total += parseFloat(this.state.moca);
    }

    total = total.toFixed(2);

    await this.setState({
      productWeightKg: total,
    });

    this.ProfitRateTotalCalculate(null);
  };

  TotalCalculate = async (event) => {
    if (this.state.tDIEster === "NaN") {
      this.state.tDIEster = 0;
    }

    if (this.state.tDIEther === "NaN") {
      this.state.tDIEther = 0;
    }

    if (this.state.mDIEster === "NaN") {
      this.state.mDIEster = 0;
    }

    if (this.state.mDIEther === "NaN") {
      this.state.mDIEther = 0;
    }

    if (this.state.bDO === "NaN") {
      this.state.bDO = 0;
    }

    if (this.state.moca === "NaN") {
      this.state.moca = 0;
    }

    let transport =
      parseFloat(this.state.tDIEster) +
      parseFloat(this.state.tDIEther) +
      parseFloat(this.state.mDIEster) +
      parseFloat(this.state.mDIEther) +
      parseFloat(this.state.bDO) +
      parseFloat(this.state.moca);

    await this.setState({
      transport: transport.toFixed(2),
    });

    var transportTotal =
      parseFloat(this.state.transportRate) * parseFloat(this.state.transport);
    await this.setState({
      transportTotal: transportTotal.toFixed(2),
    });

    if (this.state.tDIEsterTotal === "NaN") {
      this.state.tDIEsterTotal = 0;
    }
    if (this.state.mocaTotal === "NaN") {
      this.state.mocaTotal = 0;
    }
    if (this.state.dSMTotal === "NaN") {
      this.state.dSMTotal = 0;
    }
    if (this.state.sandblastingTotal === "NaN") {
      this.state.sandblastingTotal = 0;
    }
    if (this.state.sidePlateTotal === "NaN") {
      this.state.sidePlateTotal = 0;
    }
    if (this.state.lama810Total === "NaN") {
      this.state.lama810Total = 0;
    }
    if (this.state.lama1020Total === "NaN") {
      this.state.lama1020Total = 0;
    }
    if (this.state.ropeTotal === "NaN") {
      this.state.ropeTotal = 0;
    }
    if (this.state.ringTotal === "NaN") {
      this.state.ringTotal = 0;
    }
    if (this.state.pimTotal === "NaN") {
      this.state.pimTotal = 0;
    }
    if (this.state.transportTotal === "NaN") {
      this.state.transportTotal = 0;
    }
    if (this.state.tDIEtherTotal === "NaN") {
      this.state.tDIEtherTotal = 0;
    }
    if (this.state.mDIEsterTotal === "NaN") {
      this.state.mDIEsterTotal = 0;
    }
    if (this.state.mDIEtherTotal === "NaN") {
      this.state.mDIEtherTotal = 0;
    }
    if (this.state.bDOTotal === "NaN") {
      this.state.bDOTotal = 0;
    }
    if (this.state.sundryTotal === "NaN") {
      this.state.sundryTotal = 0;
    }

    let total =
      parseFloat(this.state.tDIEsterTotal) +
      parseFloat(this.state.tDIEtherTotal) +
      parseFloat(this.state.mDIEsterTotal) +
      parseFloat(this.state.mDIEtherTotal) +
      parseFloat(this.state.bDOTotal) +
      parseFloat(this.state.mocaTotal) +
      parseFloat(this.state.dSMTotal) +
      parseFloat(this.state.sandblastingTotal) +
      parseFloat(this.state.sidePlateTotal) +
      parseFloat(this.state.lama810Total) +
      parseFloat(this.state.lama1020Total) +
      parseFloat(this.state.ropeTotal) +
      parseFloat(this.state.ringTotal) +
      parseFloat(this.state.pimTotal) +
      parseFloat(this.state.transportTotal) +
      parseFloat(this.state.sundryTotal);

    await this.setState({
      total: total.toFixed(2),
    });

    this.handleProfitRateChange(null);
    this.M2TotalCalculate(null);
  };

  M2TotalCalculate = async (event) => {
    this.ProfitRateTotalCalculate(null);

    let sieveTotal =
      parseFloat(this.state.castingCostTotal) /
      parseFloat(this.state.sieveSizeMultiplier);

    await this.setState({
      m2Total: sieveTotal.toFixed(2),
    });
  };

  async handleProfitRateChange(e) {
    await this.setState({
      profitRate: parseFloat(e.target.value),
      alertErrorMessage: "",
    });

    this.ProfitRateTotalCalculate(null);
  }

  ProfitRateTotalCalculate = async (event) => {
    let castingTotal =
      ((parseFloat(this.state.profitRate) / 100) * parseFloat(this.state.total)) + parseFloat(this.state.total);

    await this.setState({
      castingCostTotal: castingTotal.toFixed(2),
    });
  };

  async handleTDIEtherChange(e) {
    if (e) {
      await this.setState({
        tDIEther: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        tDIEther: 0,
      });
    }

    this.TDIEtherPriceCalculate(null);
    this.MocaTotalCalculate(e.target.value);
    this.productWeightKgCalculate();
  }

  async handleTDIEtherRateChange(e) {
    if (e) {
      await this.setState({
        tDIEtherRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        tDIEtherRate: 0,
      });
    }

    this.TDIEtherPriceCalculate(null);
  }

  TDIEtherPriceCalculate = async (event) => {
    var tDIEtherTotal =
      parseFloat(this.state.tDIEtherRate) * parseFloat(this.state.tDIEther);
    await this.setState({
      tDIEtherTotal: tDIEtherTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleMDIEsterChange(e) {
    if (e) {
      await this.setState({
        mDIEster: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mDIEster: 0,
      });
    }

    this.MDIEsterPriceCalculate(null);
    this.BDOPriceCalculate();
    this.MocaTotalCalculate(e.target.value);
    this.productWeightKgCalculate();
  }

  async handleMDIEsterRateChange(e) {
    if (e) {
      await this.setState({
        mDIEsterRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mDIEsterRate: 0,
      });
    }

    this.MDIEsterPriceCalculate(null);
  }

  MDIEsterPriceCalculate = async (event) => {
    var mDIEsterTotal =
      parseFloat(this.state.mDIEsterRate) * parseFloat(this.state.mDIEster);
    await this.setState({
      mDIEsterTotal: mDIEsterTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleMDIEtherChange(e) {
    if (e) {
      await this.setState({
        mDIEther: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mDIEther: 0,
      });
    }

    this.MDIEtherPriceCalculate(null);
    this.BDOPriceCalculate(null);
    this.MocaTotalCalculate(e.target.value);
    this.productWeightKgCalculate();
  }

  async handleMDIEtherRateChange(e) {
    if (e) {
      await this.setState({
        mDIEtherRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mDIEtherRate: 0,
      });
    }

    this.MDIEtherPriceCalculate(null);
  }

  MDIEtherPriceCalculate = async (event) => {
    var mDIEtherTotal =
      parseFloat(this.state.mDIEtherRate) * parseFloat(this.state.mDIEther);
    await this.setState({
      mDIEtherTotal: mDIEtherTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  MocaTotalCalculate = async (param) => {
    var total = 0;
    if (this.state.tDIEster && this.state.tDIEster !== 0) {
      total += parseFloat(this.state.tDIEster / 100);
    }
    if (this.state.tDIEther && this.state.tDIEther !== 0) {
      total += parseFloat(this.state.tDIEther / 100);
    }
    if (this.state.mDIEster && this.state.mDIEster !== 0) {
      total += parseFloat(this.state.mDIEster / 100);
    }
    if (this.state.mDIEther && this.state.mDIEther !== 0) {
      total += parseFloat(this.state.mDIEther / 100);
    }
    if (this.state.bDO && this.state.bDO !== 0) {
      total += parseFloat(this.state.bDO / 100);
    }
    var mocaTotal = total * parseFloat(this.state.mocaPercent);
    await this.setState({
      moca: mocaTotal.toFixed(3),
    });

    this.TotalCalculate(null);
    this.MocaPriceCalculate(null);
    this.TransportTotalCalculate(null);
  };

  TransportTotalCalculate = async (param) => {
    var mocaPercentValue = parseFloat(this.state.mocaPercent) / 100;
    var mocaPercentTotal =
      parseFloat(this.state.moca) * parseFloat(mocaPercentValue);
    var transportTotal =
      parseFloat(mocaPercentTotal) + parseFloat(this.state.moca);
    await this.setState({
      transport: transportTotal.toFixed(2),
    });

    this.TotalCalculate(null);
    this.TransportPriceCalculate(null);
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async handleBDOChange(e) {
    if (e) {
      await this.setState({
        bDO: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bDO: 0,
      });
    }

    this.BDOPriceCalculate(null);
    this.productWeightKgCalculate();
  }

  async handleBDORateChange(e) {
    if (e) {
      await this.setState({
        bDORate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bDORate: 0,
      });
    }

    this.BDOPriceCalculate(null);
  }

  BDOPriceCalculate = async (event) => {
    var mDIRate = 0;

    if (this.state.mDIEster !== 0) {
      mDIRate = parseFloat(this.state.mDIEster);
    } else if (this.state.mDIEther !== 0) {
      mDIRate = parseFloat(this.state.mDIEther);
    }

    var bDO = mDIRate * parseFloat(this.state.bDOPercent / 100);

    await this.setState({
      bDO: bDO.toFixed(2),
    });

    var bDOTotal = parseFloat(this.state.bDORate) * parseFloat(this.state.bDO);
    await this.setState({
      bDOTotal: bDOTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });

    if (this.state.total === 0 || this.state.total === "NaN") {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.offerSieveGuid === "") {
      this.setState({
        offerSieveGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.sieveProductGuid === "") {
      this.setState({
        sieveProductError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.m2Total === 0 || this.state.m2Total === "NaN") {
      this.setState({
        m2TotalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.profitRate === 0 || this.state.profitRate === "NaN") {
      this.setState({
        profitRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.castingCostTotal === 0 ||
      this.state.castingCostTotal === "NaN"
    ) {
      this.setState({
        castingCostTotalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    if (
      this.state.offerSieveGuid !== "" &&
      this.state.sieveProductGuid !== "" &&
      this.state.total !== 0 &&
      this.state.m2Total !== 0 &&
      this.state.castingCostTotal !== 0 &&
      this.state.profitRate !== 0
    ) {
      this.setState({
        isLoading: true,
      });

      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        castingCostPictureDto: this.state.castingCostPictureDto,
        offerSieveGuid: this.state.offerSieveGuid,
        sieveProductGuid: this.state.sieveProductGuid,
        sieveEyeSize: this.state.sieveEyeSize,
        sieveSize: this.state.sieveSize,
        tDIEster:
          this.state.tDIEster === "NaN" ? 0 : parseFloat(this.state.tDIEster),
        tDIEsterRate:
          this.state.tDIEsterRate === "NaN"
            ? 0
            : parseFloat(this.state.tDIEsterRate),
        tDIEsterTotal:
          this.state.tDIEsterTotal === "NaN"
            ? 0
            : parseFloat(this.state.tDIEsterTotal),
        tDIEther:
          this.state.tDIEther === "NaN" ? 0 : parseFloat(this.state.tDIEther),
        tDIEtherRate:
          this.state.tDIEtherRate === "NaN"
            ? 0
            : parseFloat(this.state.tDIEtherRate),
        tDIEtherTotal:
          this.state.tDIEtherTotal === "NaN"
            ? 0
            : parseFloat(this.state.tDIEtherTotal),
        mDIEster:
          this.state.mDIEster === "NaN" ? 0 : parseFloat(this.state.mDIEster),
        mDIEsterRate:
          this.state.mDIEsterRate === "NaN"
            ? 0
            : parseFloat(this.state.mDIEsterRate),
        mDIEsterTotal:
          this.state.mDIEsterTotal === "NaN"
            ? 0
            : parseFloat(this.state.mDIEsterTotal),
        mDIEther:
          this.state.mDIEther === "NaN" ? 0 : parseFloat(this.state.mDIEther),
        mDIEtherRate:
          this.state.mDIEtherRate === "NaN"
            ? 0
            : parseFloat(this.state.mDIEtherRate),
        mDIEtherTotal:
          this.state.mDIEtherTotal === "NaN"
            ? 0
            : parseFloat(this.state.mDIEtherTotal),
        bDO: this.state.bDO === "NaN" ? 0 : parseFloat(this.state.bDO),
        bDORate:
          this.state.bDORate === "NaN" ? 0 : parseFloat(this.state.bDORate),
        bDOPercent:
          this.state.bDOPercent === "NaN"
            ? 0
            : parseFloat(this.state.bDOPercent),
        bDOTotal:
          this.state.bDOTotal === "NaN" ? 0 : parseFloat(this.state.bDOTotal),
        moca: this.state.moca === "NaN" ? 0 : parseFloat(this.state.moca),
        mocaRate:
          this.state.mocaRate === "NaN" ? 0 : parseFloat(this.state.mocaRate),
        mocaPercent:
          this.state.mocaPercent === "NaN"
            ? 0
            : parseFloat(this.state.mocaPercent),
        mocaTotal:
          this.state.mocaTotal === "NaN" ? 0 : parseFloat(this.state.mocaTotal),
        dSM: this.state.dSM === "NaN" ? 0 : parseFloat(this.state.dSM),
        dSMRate:
          this.state.dSMRate === "NaN" ? 0 : parseFloat(this.state.dSMRate),
        dSMTotal:
          this.state.dSMTotal === "NaN" ? 0 : parseFloat(this.state.dSMTotal),
        sandblasting:
          this.state.sandblasting === "NaN"
            ? 0
            : parseFloat(this.state.sandblasting),
        sandblastingRate:
          this.state.sandblastingRate === "NaN"
            ? 0
            : parseFloat(this.state.sandblastingRate),
        sandblastingTotal:
          this.state.sandblastingTotal === "NaN"
            ? 0
            : parseFloat(this.state.sandblastingTotal),
        sidePlate:
          this.state.sidePlate === "NaN" ? 0 : parseFloat(this.state.sidePlate),
        sidePlateRate:
          this.state.sidePlateRate === "NaN"
            ? 0
            : parseFloat(this.state.sidePlateRate),
        sidePlateTotal:
          this.state.sidePlateTotal === "NaN"
            ? 0
            : parseFloat(this.state.sidePlateTotal),
        lama810:
          this.state.lama810 === "NaN" ? 0 : parseFloat(this.state.lama810),
        lama810Rate:
          this.state.lama810Rate === "NaN"
            ? 0
            : parseFloat(this.state.lama810Rate),
        lama810Total:
          this.state.lama810Total === "NaN"
            ? 0
            : parseFloat(this.state.lama810Total),
        lama1020:
          this.state.lama1020 === "NaN" ? 0 : parseFloat(this.state.lama1020),
        lama1020Rate:
          this.state.lama1020Rate === "NaN"
            ? 0
            : parseFloat(this.state.lama1020Rate),
        lama1020Total:
          this.state.lama1020Total === "NaN"
            ? 0
            : parseFloat(this.state.lama1020Total),
        rope: this.state.rope === "NaN" ? 0 : parseFloat(this.state.rope),
        ropeRate:
          this.state.ropeRate === "NaN" ? 0 : parseFloat(this.state.ropeRate),
        ropeTotal:
          this.state.ropeTotal === "NaN" ? 0 : parseFloat(this.state.ropeTotal),
        ring: this.state.ring === "NaN" ? 0 : parseFloat(this.state.ring),
        ringRate:
          this.state.ringRate === "NaN" ? 0 : parseFloat(this.state.ringRate),
        ringTotal:
          this.state.ringTotal === "NaN" ? 0 : parseFloat(this.state.ringTotal),
        pim: this.state.pim === "NaN" ? 0 : parseFloat(this.state.pim),
        pimRate:
          this.state.pimRate === "NaN" ? 0 : parseFloat(this.state.pimRate),
        pimTotal:
          this.state.pimTotal === "NaN" ? 0 : parseFloat(this.state.pimTotal),
        transport:
          this.state.transport === "NaN" ? 0 : parseFloat(this.state.transport),
        transportRate:
          this.state.transportRate === "NaN"
            ? 0
            : parseFloat(this.state.transportRate),
        transportTotal:
          this.state.transportTotal === "NaN"
            ? 0
            : parseFloat(this.state.transportTotal),
        castingCostTotal:
          this.state.castingCostTotal === "NaN"
            ? 0
            : parseFloat(this.state.castingCostTotal),
        profitRate:
          this.state.profitRate === "NaN"
            ? 0
            : parseFloat(this.state.profitRate),
        total: this.state.total === "NaN" ? 0 : parseFloat(this.state.total),
        m2Total:
          this.state.m2Total === "NaN" ? 0 : parseFloat(this.state.m2Total),
        status: true,
        description: this.state.description,
        sundry: this.state.sundry === "NaN" ? 0 : parseFloat(this.state.sundry),
        sundryRate:
          this.state.sundryRate === "NaN"
            ? 0
            : parseFloat(this.state.sundryRate),
        sundryTotal:
          this.state.sundryTotal === "NaN"
            ? 0
            : parseFloat(this.state.sundryTotal),
        sundryKg:
          this.state.sundryKg === "NaN" ? 0 : parseFloat(this.state.sundryKg),
        productWeightKg:
          this.state.productWeightKg === "NaN"
            ? 0
            : parseFloat(this.state.productWeightKg),
        shoreA: this.state.shoreA,
      };

      var URLParam = "/castingCosts/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "dokum-maliyet-guncelle"
      ) {
        URLParam = "/castingCosts/update";
        myObj = {
          castingCostPictureDto: this.state.castingCostPictureDto,
          guid: this.state.guid,
          offerSieveGuid: this.state.offerSieveGuid,
          sieveProductGuid: this.state.sieveProductGuid,
          sieveEyeSize: this.state.sieveEyeSize,
          sieveSize: this.state.sieveSize,
          tDIEster:
            this.state.tDIEster === "NaN" ? 0 : parseFloat(this.state.tDIEster),
          tDIEsterRate:
            this.state.tDIEsterRate === "NaN"
              ? 0
              : parseFloat(this.state.tDIEsterRate),
          tDIEsterTotal:
            this.state.tDIEsterTotal === "NaN"
              ? 0
              : parseFloat(this.state.tDIEsterTotal),
          tDIEther:
            this.state.tDIEther === "NaN" ? 0 : parseFloat(this.state.tDIEther),
          tDIEtherRate:
            this.state.tDIEtherRate === "NaN"
              ? 0
              : parseFloat(this.state.tDIEtherRate),
          tDIEtherTotal:
            this.state.tDIEtherTotal === "NaN"
              ? 0
              : parseFloat(this.state.tDIEtherTotal),
          mDIEster:
            this.state.mDIEster === "NaN" ? 0 : parseFloat(this.state.mDIEster),
          mDIEsterRate:
            this.state.mDIEsterRate === "NaN"
              ? 0
              : parseFloat(this.state.mDIEsterRate),
          mDIEsterTotal:
            this.state.mDIEsterTotal === "NaN"
              ? 0
              : parseFloat(this.state.mDIEsterTotal),
          mDIEther:
            this.state.mDIEther === "NaN" ? 0 : parseFloat(this.state.mDIEther),
          mDIEtherRate:
            this.state.mDIEtherRate === "NaN"
              ? 0
              : parseFloat(this.state.mDIEtherRate),
          mDIEtherTotal:
            this.state.mDIEtherTotal === "NaN"
              ? 0
              : parseFloat(this.state.mDIEtherTotal),
          bDO: this.state.bDO === "NaN" ? 0 : parseFloat(this.state.bDO),
          bDORate:
            this.state.bDORate === "NaN" ? 0 : parseFloat(this.state.bDORate),
          bDOPercent:
            this.state.bDOPercent === "NaN"
              ? 0
              : parseFloat(this.state.bDOPercent),
          bDOTotal:
            this.state.bDOTotal === "NaN" ? 0 : parseFloat(this.state.bDOTotal),
          moca: this.state.moca === "NaN" ? 0 : parseFloat(this.state.moca),
          mocaRate:
            this.state.mocaRate === "NaN" ? 0 : parseFloat(this.state.mocaRate),
          mocaPercent:
            this.state.mocaPercent === "NaN"
              ? 0
              : parseFloat(this.state.mocaPercent),
          mocaTotal:
            this.state.mocaTotal === "NaN"
              ? 0
              : parseFloat(this.state.mocaTotal),
          dSM: this.state.dSM === "NaN" ? 0 : parseFloat(this.state.dSM),
          dSMRate:
            this.state.dSMRate === "NaN" ? 0 : parseFloat(this.state.dSMRate),
          dSMTotal:
            this.state.dSMTotal === "NaN" ? 0 : parseFloat(this.state.dSMTotal),
          sandblasting:
            this.state.sandblasting === "NaN"
              ? 0
              : parseFloat(this.state.sandblasting),
          sandblastingRate:
            this.state.sandblastingRate === "NaN"
              ? 0
              : parseFloat(this.state.sandblastingRate),
          sandblastingTotal:
            this.state.sandblastingTotal === "NaN"
              ? 0
              : parseFloat(this.state.sandblastingTotal),
          sidePlate:
            this.state.sidePlate === "NaN"
              ? 0
              : parseFloat(this.state.sidePlate),
          sidePlateRate:
            this.state.sidePlateRate === "NaN"
              ? 0
              : parseFloat(this.state.sidePlateRate),
          sidePlateTotal:
            this.state.sidePlateTotal === "NaN"
              ? 0
              : parseFloat(this.state.sidePlateTotal),
          lama810:
            this.state.lama810 === "NaN" ? 0 : parseFloat(this.state.lama810),
          lama810Rate:
            this.state.lama810Rate === "NaN"
              ? 0
              : parseFloat(this.state.lama810Rate),
          lama810Total:
            this.state.lama810Total === "NaN"
              ? 0
              : parseFloat(this.state.lama810Total),
          lama1020:
            this.state.lama1020 === "NaN" ? 0 : parseFloat(this.state.lama1020),
          lama1020Rate:
            this.state.lama1020Rate === "NaN"
              ? 0
              : parseFloat(this.state.lama1020Rate),
          lama1020Total:
            this.state.lama1020Total === "NaN"
              ? 0
              : parseFloat(this.state.lama1020Total),
          rope: this.state.rope === "NaN" ? 0 : parseFloat(this.state.rope),
          ropeRate:
            this.state.ropeRate === "NaN" ? 0 : parseFloat(this.state.ropeRate),
          ropeTotal:
            this.state.ropeTotal === "NaN"
              ? 0
              : parseFloat(this.state.ropeTotal),
          ring: this.state.ring === "NaN" ? 0 : parseFloat(this.state.ring),
          ringRate:
            this.state.ringRate === "NaN" ? 0 : parseFloat(this.state.ringRate),
          ringTotal:
            this.state.ringTotal === "NaN"
              ? 0
              : parseFloat(this.state.ringTotal),
          pim: this.state.pim === "NaN" ? 0 : parseFloat(this.state.pim),
          pimRate:
            this.state.pimRate === "NaN" ? 0 : parseFloat(this.state.pimRate),
          pimTotal:
            this.state.pimTotal === "NaN" ? 0 : parseFloat(this.state.pimTotal),
          transport:
            this.state.transport === "NaN"
              ? 0
              : parseFloat(this.state.transport),
          transportRate:
            this.state.transportRate === "NaN"
              ? 0
              : parseFloat(this.state.transportRate),
          transportTotal:
            this.state.transportTotal === "NaN"
              ? 0
              : parseFloat(this.state.transportTotal),
          castingCostTotal:
            this.state.castingCostTotal === "NaN"
              ? 0
              : parseFloat(this.state.castingCostTotal),
          profitRate:
            this.state.profitRate === "NaN"
              ? 0
              : parseFloat(this.state.profitRate),
          total: this.state.total === "NaN" ? 0 : parseFloat(this.state.total),
          m2Total:
            this.state.m2Total === "NaN" ? 0 : parseFloat(this.state.m2Total),
          status: true,
          description: this.state.description,
          sundry:
            this.state.sundry === "NaN" ? 0 : parseFloat(this.state.sundry),
          sundryRate:
            this.state.sundryRate === "NaN"
              ? 0
              : parseFloat(this.state.sundryRate),
          sundryTotal:
            this.state.sundryTotal === "NaN"
              ? 0
              : parseFloat(this.state.sundryTotal),
          sundryKg:
            this.state.sundryKg === "NaN" ? 0 : parseFloat(this.state.sundryKg),
          productWeightKg:
            this.state.productWeightKg === "NaN"
              ? 0
              : parseFloat(this.state.productWeightKg),
          shoreA: this.state.shoreA,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "dokum-maliyet-guncelle"
            ) {
              window.location.href = "/dokum-maliyet-listele";
            }
            if (this.state.urlSplit[1] === "dokum-maliyet-ekle") {
              window.location.href = "/dokum-maliyet-listele";
            }
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    let array = this.state.castingCostPictureDto;
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        });
      }
    }
    await this.setState({ castingCostPictureDto: array });
  };

  deleteImage = (event) => {
    var index = -1;
    let array = this.state.castingCostPictureDto;
    this.state.castingCostPictureDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ castingCostPictureDto: array });
        }
      }
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        {(this.state.urlSplit[1] === "dokum-maliyet-ekle" ||
          this.state.urlSplit[1] === "dokum-maliyet-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="offerSieveGuid"
                      label="Teklif [*]"
                      placeholder="Lütfen teklif seçin..."
                      isMulti={false}
                      isClearable={true}
                      selectValue={this.state.offerSieveGuid}
                      options={this.state.offerSieveArr}
                      onChange={(option) =>
                        this.handleOfferSieveSelectChange(option)
                      }
                      error={this.state.offerSieveGuidError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="sieveProductGuid"
                      label="Malzeme [*]"
                      placeholder="Lütfen malzeme seçin..."
                      isMulti={false}
                      isClearable={true}
                      selectValue={this.state.sieveProductGuid}
                      options={this.state.sieveProductArr}
                      onChange={(option) =>
                        this.handleSieveProductSelectChange(option)
                      }
                      error={this.state.sieveProductError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="sieveSize"
                      label="Elek Boyutu"
                      value={this.state.sieveSize}
                      onChange={this.handleChange}
                      placeHolder="Lütfen elek boyutu girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sieveSizeError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="sieveEyeSize"
                      label="Elek Göz Boyutu"
                      value={this.state.sieveEyeSize}
                      onChange={this.handleChange}
                      placeHolder="Lütfen elek göz boyutu girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sieveEyeSizeError}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="tDIEster"
                      label="TDI Ester (Kg)"
                      value={this.state.tDIEster}
                      onChange={(e) => this.handleTDIEsterChange(e)}
                      placeHolder="Lütfen TDI Ester girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tDIEsterError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="tDIEsterRate"
                      label="TDI Ester (Euro)"
                      value={this.state.tDIEsterRate}
                      onChange={(e) => this.handleTDIEsterRateChange(e)}
                      placeHolder="Lütfen TDI Ester birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tDIEsterRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="tDIEsterTotal"
                      label="TDI Ester Toplam"
                      value={this.state.tDIEsterTotal}
                      onChange={this.TDIPriceCalculate}
                      placeHolder="Toplam TDI Ester değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tDIEsterTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="tDIEther"
                      label="TDI Ether (Kg)"
                      value={this.state.tDIEther}
                      onChange={(e) => this.handleTDIEtherChange(e)}
                      placeHolder="Lütfen TDI Ether girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tDIEtherError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="tDIEtherRate"
                      label="TDI Ether (Euro)"
                      value={this.state.tDIEtherRate}
                      onChange={(e) => this.handleTDIEtherRateChange(e)}
                      placeHolder="Lütfen TDI Ester birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tDIEtherRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="tDIEtherTotal"
                      label="TDI Ether Toplam"
                      value={this.state.tDIEtherTotal}
                      onChange={this.TDIEtherPriceCalculate}
                      placeHolder="Toplam TDI ether değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tDIEtherTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="mDIEster"
                      label="MDI Ester (Kg)"
                      value={this.state.mDIEster}
                      onChange={(e) => this.handleMDIEsterChange(e)}
                      placeHolder="Lütfen MDI Ester girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mDIEsterError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="mDIEsterRate"
                      label="MDI Ester (Euro)"
                      value={this.state.mDIEsterRate}
                      onChange={(e) => this.handleMDIEsterRateChange(e)}
                      placeHolder="Lütfen MDI Ester birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mDIEsterRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="mDIEsterTotal"
                      label="MDI Ester Toplam"
                      value={this.state.mDIEsterTotal}
                      onChange={this.MDIEsterPriceCalculate}
                      placeHolder="Toplam MDI Ester değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mDIEsterTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="mDIEther"
                      label="MDI Ether (Kg)"
                      value={this.state.mDIEther}
                      onChange={(e) => this.handleMDIEtherChange(e)}
                      placeHolder="Lütfen MDI Ether girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mDIEtherError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="mDIEtherRate"
                      label="MDI Ether (Euro)"
                      value={this.state.mDIEtherRate}
                      onChange={(e) => this.handleMDIEtherRateChange(e)}
                      placeHolder="Lütfen MDI Ether birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mDIEtherRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="mDIEtherTotal"
                      label="MDI Ether Toplam"
                      value={this.state.mDIEtherTotal}
                      onChange={this.MDIEtherPriceCalculate}
                      placeHolder="Toplam MDI Ether değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mDIEtherTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bDO"
                      label="BDO (Kg)"
                      value={this.state.bDO}
                      onChange={(e) => this.handleBDOChange(e)}
                      placeHolder="Lütfen BDO girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bDOError}
                      disabled={true}
                    //step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bDOPercent"
                      label="BDO Yüzdesi"
                      value={this.state.bDOPercent}
                      onChange={(e) => this.handleBDOPercentChange(e)}
                      placeHolder="Lütfen bdo yüzdeliği girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bDOPercentError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bDORate"
                      label="BDO Birimi (Euro)"
                      value={this.state.bDORate}
                      onChange={(e) => this.handleBDORateChange(e)}
                      placeHolder="Lütfen BDO birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bDORateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bDOTotal"
                      label="BDO Toplam"
                      value={this.state.bDOTotal}
                      onChange={this.BDOPriceCalculate}
                      placeHolder="Toplam BDO değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bDOTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="moca"
                      label="Moca (Kg)"
                      value={this.state.moca}
                      onChange={(e) => this.handleMocaChange(e)}
                      placeHolder="Lütfen moca girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mocaError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="mocaPercent"
                      label="Moca Yüzdesi"
                      value={this.state.mocaPercent}
                      onChange={(e) => this.handleMocaPercentChange(e)}
                      placeHolder="Lütfen moca yüzdeliği girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mocaPercentError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="mocaRate"
                      label="Moca Değeri (Euro)"
                      value={this.state.mocaRate}
                      onChange={(e) => this.handleMocaRateChange(e)}
                      placeHolder="Lütfen moca değeri girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mocaRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="mocaTotal"
                      label="Moca Toplam"
                      value={this.state.mocaTotal}
                      onChange={this.MocaPriceCalculate}
                      placeHolder="Toplam Moca değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mocaTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="dSM"
                      label="DSM"
                      value={this.state.dSM}
                      onChange={(e) => this.handledSMChange(e)}
                      placeHolder="Lütfen DSM girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.dSMError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="dSMRate"
                      label="DSM Birimi (Euro)"
                      value={this.state.dSMRate}
                      onChange={(e) => this.handledSMRateChange(e)}
                      placeHolder="Lütfen DSM birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.dSMRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="dSMTotal"
                      label="DSM Toplam"
                      value={this.state.dSMTotal}
                      onChange={this.DSMPriceCalculate}
                      placeHolder="Toplam DSM değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.dSMTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sandblasting"
                      label="Kumlama"
                      value={this.state.sandblasting}
                      onChange={(e) => this.handleSandblastingChange(e)}
                      placeHolder="Lütfen kumlama girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sandblastingError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sandblastingRate"
                      label="Kumlama Birimi (Euro)"
                      value={this.state.sandblastingRate}
                      onChange={(e) => this.handleSandblastingRateChange(e)}
                      placeHolder="Lütfen Kumlama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sandblastingRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sandblastingTotal"
                      label="Kumlama Toplam"
                      value={this.state.sandblastingTotal}
                      onChange={this.SandblastingPriceCalculate}
                      placeHolder="Toplam Kumlama değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sandblastingTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sidePlate"
                      label="Yan Gergi Sacı"
                      value={this.state.sidePlate}
                      onChange={(e) => this.handleSidePlateChange(e)}
                      placeHolder="Lütfen Yan Gergi Sacı girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sidePlateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sidePlateRate"
                      label="Yan Gergi Sacı Birimi (Euro)"
                      value={this.state.sidePlateRate}
                      onChange={(e) => this.handleSidePlateRateChange(e)}
                      placeHolder="Lütfen Yan Gergi Sacı birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sidePlateRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sidePlateTotal"
                      label="Yan Gergi Sacı Toplam"
                      value={this.state.sidePlateTotal}
                      onChange={this.SidePlatePriceCalculate}
                      placeHolder="Toplam Yan Gergi Sacı değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sidePlateTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama810"
                      label="8x16 Lama"
                      value={this.state.lama810}
                      onChange={(e) => this.handleLama810Change(e)}
                      placeHolder="Lütfen 8x16 Lama girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama810Rate"
                      label="8x16 Lama Birimi (Euro)"
                      value={this.state.lama810Rate}
                      onChange={(e) => this.handleLama810RateChange(e)}
                      placeHolder="Lütfen 8x16 Lama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama810Total"
                      label="8x16 Lama Toplam"
                      value={this.state.lama810Total}
                      onChange={this.Lama810PriceCalculate}
                      placeHolder="Toplam 8x16 Lama değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama1020"
                      label="10x20 Lama"
                      value={this.state.lama1020}
                      onChange={(e) => this.handleLama1020Change(e)}
                      placeHolder="Lütfen 10x20 Lama girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama1020Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama1020Rate"
                      label="10x20 Lama Birimi (Euro)"
                      value={this.state.lama1020Rate}
                      onChange={(e) => this.handleLama1020RateChange(e)}
                      placeHolder="Lütfen 10x20 Lama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama1020RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama1020Total"
                      label="10x20 Lama Toplam"
                      value={this.state.lama1020Total}
                      onChange={this.Lama1020PriceCalculate}
                      placeHolder="Toplam 10x20 Lama değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama1020TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="rope"
                      label="Halat"
                      value={this.state.rope}
                      onChange={(e) => this.handleRopeChange(e)}
                      placeHolder="Lütfen halat girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ropeError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="ropeRate"
                      label="Halat Birimi (Euro)"
                      value={this.state.ropeRate}
                      onChange={(e) => this.handleRopeRateChange(e)}
                      placeHolder="Lütfen halat birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ropeRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="ropeTotal"
                      label="Halat Toplam"
                      value={this.state.ropeTotal}
                      onChange={this.RopePriceCalculate}
                      placeHolder="Toplam halat değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ropeTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="ring"
                      label="Kurşun Yüzük"
                      value={this.state.ring}
                      onChange={(e) => this.handleRingChange(e)}
                      placeHolder="Lütfen kurşun yüzük girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ringError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="ringRate"
                      label="Kurşun Yüzük Birimi (Euro)"
                      value={this.state.ringRate}
                      onChange={(e) => this.handleRingRateChange(e)}
                      placeHolder="Lütfen kurşun yüzük birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ringRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="ringTotal"
                      label="Kurşun Yüzük Toplam"
                      value={this.state.ringTotal}
                      onChange={this.RingPriceCalculate}
                      placeHolder="Toplam kurşun yüzük değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ringTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="pim"
                      label="Pim"
                      value={this.state.pim}
                      onChange={(e) => this.handlePimChange(e)}
                      placeHolder="Lütfen pim girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.pimError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="pimRate"
                      label="Pim Birimi (Euro)"
                      value={this.state.pimRate}
                      onChange={(e) => this.handlePimRateChange(e)}
                      placeHolder="Lütfen pim birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.pimRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="pimTotal"
                      label="Pim Toplam"
                      value={this.state.pimTotal}
                      onChange={this.PimPriceCalculate}
                      placeHolder="Toplam pim değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.pimTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="transport"
                      label="Nakliye"
                      value={this.state.transport}
                      onChange={(e) => this.handleTransportChange(e)}
                      placeHolder="Lütfen nakliye girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="transportRate"
                      label="Nakliye Birimi (Euro)"
                      value={this.state.transportRate}
                      onChange={(e) => this.handleTransportRateChange(e)}
                      placeHolder="Lütfen nakliye birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="transportTotal"
                      label="Nakliye Toplam"
                      value={this.state.transportTotal}
                      onChange={this.TransportPriceCalculate}
                      placeHolder="Toplam nakliye değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sundry"
                      label="Muhtelif"
                      value={this.state.sundry}
                      onChange={(e) => this.handleSundryChange(e)}
                      placeHolder="Lütfen muhtelif girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sundryError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sundryRate"
                      label="Muhtelif Birimi (Euro)"
                      value={this.state.sundryRate}
                      onChange={(e) => this.handleSundryRateChange(e)}
                      placeHolder="Lütfen muhtelif birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sundryRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sundryTotal"
                      label="Muhtelif Toplam"
                      value={this.state.sundryTotal}
                      onChange={this.SundryPriceCalculate}
                      placeHolder="Toplam muhtelif değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sundryTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sundryKg"
                      label="Muhtelif (Kg)"
                      value={this.state.sundryKg}
                      onChange={(e) => this.handleSundryKgChange(e)}
                      placeHolder="Lütfen muhtelif (kg) girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sundryError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="8">
                    <CgnTextbox
                      type="text"
                      name="shoreA"
                      label="Shore A"
                      value={this.state.shoreA}
                      onChange={(e) => this.handleShoreAChange(e)}
                      placeHolder="Lütfen shore a girin..."
                      autoComplete="off"
                      error={this.state.shoreAError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="profitRate"
                      label="İşçilik Yüzdesi"
                      value={this.state.profitRate}
                      onChange={(e) => this.handleProfitRateChange(e)}
                      placeHolder="İşçilik yüzdesi değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.profitRateError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="productWeightKg"
                      label="Ürün Ağırlığı (Kg)"
                      value={this.state.productWeightKg}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="castingCostTotal"
                      label="Toplam maliyeti €/ad"
                      value={this.state.castingCostTotal}
                      onChange={this.ProfitRateTotalCalculate}
                      placeHolder="Toplam maliyeti €/ad değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.castingCostTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Malzeme Maliyeti €/ad"
                      value={this.state.total}
                      onChange={this.TotalCalculate}
                      placeHolder="Malzeme Maliyeti €/ad değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.totalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="m2Total"
                      label="m2 Toplam €/m2"
                      value={this.state.m2Total}
                      onChange={this.M2TotalCalculate}
                      placeHolder="m2 Toplam değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.m2totalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <PictureUploadMulti
                      image={this.state.castingCostPictureDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Fotoğraflar"}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      alt={this.state.name}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "dokum-maliyet-ekle" ||
              this.state.urlSplit[1] === "dokum-maliyet-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(CastingCostAddUpdate);
