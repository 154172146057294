import React, { Component } from "react";
import { Row, Col, Card, Form,  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import {
  URL,
  
  UserOperationClaimControl} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import { withRouter } from "react-router-dom";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";
import ProcessDefinitionAddUpdate from "../processDefinition/ProcessDefinitionAddUpdate";

class DimensionalReportAddUpdate extends Component {
  state = {
    pageTitle: "Boyutsal Ölçüm Raporu Ekle",
    pageTitleAdd: "Boyutsal Ölçüm Raporu Ekle",
    pageTitleUpdate: "Boyutsal Ölçüm Raporu Güncelle",
    pageAddLink: "boyutsal-olcum-raporu-ekle",
    pageListLink: "boyutsal-olcum-raporu-listele",
    breadcrumb: [{ text: "Boyutsal Ölçüm Raporu", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productArr: [],
    productGuid: "",
    workInstructionArr: [],
    dimensionalReportResultDto: [],
    supplierGuid: "",
    detectionPersonelGuid: "",
    lastControlGuid: "",
    dimensionalReportDate: "",
    itemNumber: "",
    processDefinitionGuid: "",
    lastControlPersonelGuid: "",
    serialNo: "",
    workInstructionGuid: "",
    customerGuid: "",
    personelGuid: "",
    productOutGuid: "",
    guid: "",
    cocNo: "",
    status: true,
    isNull: false,
    modalButtonIdx: 0,
    customerArr: [],
    productOutArr: [],
    supplierArr: [],
    detectionPersonelArr: [],
    lastControlPersonelArr: [],
    qualityPersonelArr: [],
    qualityPersonelGuid: "",
    processDefinitionArr: [],
    isOpenModalSupplier: false,
    isOpenModalProcessDefinition: false,
    isTrue: false,
    productError: "",
    alertErrorMessage: "",
    workInstructionError: "",
    dimensionalReportDateError: "",
    detectionPersonelError: "",
    processDefinitionError: "",
    counter: -1,
    message: "",
    statusChange: "",
    statusChangeError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    productModalPlus: false,
    materialModalPlus: false,
    supplierModalPlus: false,
    processDefinitionModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      productModalPlus: await UserOperationClaimControl(1029),
      supplierModalPlus: await UserOperationClaimControl(1038),
      processDefinitionModalPlus: await UserOperationClaimControl(1128),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    axios
      .get(URL + "/workInstructions/getlistsuitabilityform", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.customerName}`,
          };
        });
        this.setState({
          workInstructionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
      if (
        urlSplit.length !== 3 &&
        urlSplit[1] !== "boyutsal-olcum-raporu-guncelle"
      ) {
      axios
      .get(URL + "/dimensionalTemplates/getlist/" + statusType, config)
      .then((response) => {       
        this.setState({
          dimensionalReportResultDto: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
     }
    
    axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/processDefinitions/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          processDefinitionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          detectionPersonelArr: myArr,
          qualityPersonelArr: myArr,
          lastControlPersonelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    if (
      urlSplit.length === 3 &&
      urlSplit[1] === "boyutsal-olcum-raporu-guncelle"
    ) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/dimensionalReports/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              productGuid: response.data.productGuid,
              workInstructionGuid: response.data.workInstructionGuid,
              customerGuid: response.data.customerGuid,
              productOutGuid: response.data.productOutGuid,
              personelGuid: response.data.personelGuid,
              serialNo: response.data.serialNo,
              cocNo: response.data.cocNo,
              processDefinitionGuid: response.data.processDefinitionGuid,
              workInstructionCode: response.data.workInstructionCode,
              supplierGuid: response.data.supplierGuid,
              detectionPersonelGuid: response.data.detectionPersonelGuid,
              dimensionalReportResultDto: response.data.dimensionalReportResultDto,
              counter: response.data.dimensionalReportResultDto.length - 1,
              dimensionalReportDate: response.data.dimensionalReportDate,
              lastControlPersonelGuid: response.data.lastControlPersonelGuid,
              qualityPersonelGuid: response.data.qualityPersonelGuid,
              isLoading: false
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        serialNo: "",
        cocNo: "",
        workInstructionGuid: "",
        dimensionalReportDate: "",
        dimensionalReportResultDto: [],
        detectionPersonelGuid: "",
        processDefinitionGuid: "",
        status: true,
        counter: -1,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }

    if (
      this.state.isOpenModalSupplier &&
      this.props.location.modalAddedSupplier !== undefined &&
      this.props.location.modalAddedSupplier.isModal !== undefined &&
      this.props.location.modalAddedSupplier.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/suppliers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            supplierArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSupplier.data &&
              element.name === this.props.location.modalAddedSupplier.data
            ) {
              this.setState({
                supplierGuid: element.guid,
                supplierError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSupplier.isModal = true;
      this.setState({
        isOpenModalSupplier: false,
      });
    }
    if (
      this.state.isOpenModalProcessDefinition &&
      this.props.location.modalAddedProcessDefinition !== undefined &&
      this.props.location.modalAddedProcessDefinition.isModal !== undefined &&
      this.props.location.modalAddedProcessDefinition.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/processDefinitions/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            processDefinitionArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProcessDefinition.data &&
              element.name === this.props.location.modalAddedProcessDefinition.data
            ) {
              this.setState({
                processDefinitionGuid: element.guid,
                processDefinitionError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProcessDefinition.isModal = true;
      this.setState({
        isOpenModalProcessDefinition: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    this.setState({
      serialNo: "",
      workInstructionGuid: "",
      dimensionalReportDate: "",
      dimensionalReportResultDto: [],
      detectionPersonelGuid: "",
      processDefinitionGuid: "",
      cocNo: "",
      status: true,
      counter: -1,
      isTrue: false,
    });
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleWorkInstructionSelectChange(option){
    if(option){
     this.setState({
       workInstructionGuid: option.value,
       workInstructionError: "",
       isLoading: true,
     });
     // 
     var config = {
       headers: {
         Authorization: "Bearer " + localStorage.getItem("token"),
       },
     };
     axios 
     .get(URL + "/workInstructions/get/" + option.value, config)    
     .then((response) => {
       if(response.data.guid){
         this.setState({
            productGuid: response.data.productGuid,
            productOutGuid: response.data.productOutGuid,
            customerGuid: response.data.customerGuid,
            personelGuid: response.data.personelGuid,
            workInstructionCode: response.data.code,
            lastControlPersonelGuid: response.data.lastControlPersonelGuid,
            isLoading: false,
            qualityPersonelGuid: response.data.qualityPersonelGuid
         });
       }
     })
    }
    else{
     this.setState({
       workInstructionGuid: "",
       productGuid: "",
       customerGuid: "",
       personelGuid: "",
       productOutGuid: "",
       workInstructionCode: "",
       lastControlPersonelGuid: "",
       qualityPersonelGuid: "",
       workInstructionError: CgnMessage.alertErrorMessage,
     });
    }
   }
 

  handleSerialNoTextBoxChange(e) {
    this.setState({
      serialNo: e.target.value,
      serialNoError: "",
      alertErrorMessage: "",
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  addModalProcessDefinition = () => {
    this.setState({
      isOpenModalProcessDefinition: !this.state.isOpenModalProcessDefinition,
    });
  };

  handleDimensionalReportDateChange(dimensionalReportDate) {
    if (dimensionalReportDate) {
      this.setState({
        dimensionalReportDate: dimensionalReportDate,
        dimensionalReportDateError: "",
      });
    } else {
      this.setState({
        dimensionalReportDate: "",
        dimensionalReportDateError: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSupplierSelectChange(option) {
    if (option) {
      this.setState({
        supplierGuid: option.value,
        supplierError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        supplierGuid: "",
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDetectionPersonelSelectChange(option){
    if(option){
      this.setState({
        detectionPersonelGuid: option.value,
        detectionPersonelError: ""
      });
    }
    else{
      this.setState({
        detectionPersonelGuid: "",
        detectionPersonelError: CgnMessage.alertErrorMessage,
      })
    }
  }

  handleProcessDefinitionSelectChange(option) {
    if (option) {
      this.setState({
        processDefinitionGuid: option.value,
        processDefinitionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        processDefinitionGuid: "",
        processDefinitionError: CgnMessage.alertErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange2 = tags => (event) => {
    const { name, value } = event.target;
    let dimensionalReportResultDto = this.state.dimensionalReportResultDto;
    var idx = tags;

    dimensionalReportResultDto[idx][name] = value;
    this.setState({
      dimensionalReportResultDto: dimensionalReportResultDto,
    });
  };

  handleStatusChange(option, idx) {
    let dimensionalReportResultDto = this.state.dimensionalReportResultDto;
    if (option) {
      dimensionalReportResultDto[idx].statusChange = option.value;
      dimensionalReportResultDto[idx].statusChangeError = "";
    } else {
      dimensionalReportResultDto[idx].statusChange = null;
      dimensionalReportResultDto[idx].statusChangeError = "";
    }

    this.setState({ dimensionalReportResultDto: dimensionalReportResultDto });
  }

  handleResultAdd = async () => {
    let array = this.state.dimensionalReportResultDto;
    await array.push({
      documents: "",
      measurement: "",
      result: "",
      tolerance: "",
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].documents) {
      return array[this.state.counter].documentsError === "";
    }
    if (array[this.state.counter].documents === "") {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ dimensionalReportResultDto: array });
  };

  async handleResultRemove(idx) {
    let someArray = this.state.dimensionalReportResultDto;
    someArray.splice(idx, 1);
    await this.setState({
      dimensionalReportResultDto: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      workInstructionErrorError: "",
      dimensionalReportDateError: "",
      supplierError: "",
      processDefinitionError: "",
    });
    if (this.state.workInstructionGuid === "") {
      this.setState({
        workInstructionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.dimensionalReportDate === "") {
      this.setState({
        dimensionalReportDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.supplierGuid === "") {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.processDefinitionGuid === "") {
      this.setState({
        processDefinitionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.detectionPersonelGuid === "") {
      this.setState({
        detectionPersonelError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (
      this.state.dimensionalReportResultDto !== null &&
      this.state.dimensionalReportResultDto.length > 0
    ) {
      this.state.dimensionalReportResultDto.forEach((element) => {
        if (element.documents === "") {
          control = false;
        }
      });
    }

    if (
      this.state.workInstructionGuid !== "" &&
      this.state.dimensionalReportDate !== "" &&
      this.state.supplierGuid !== "" &&
      this.state.processDefinitionGuid !== "" &&
      this.state.detectionPersonelGuid !== "" &&
       control === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var dimensionalReportResultDto = [];
      this.state.dimensionalReportResultDto.forEach((element) => {
        dimensionalReportResultDto.push({
          guid: element.guid,
          documents: element.documents,
          tolerance: element.tolerance,
          measurement: element.measurement !== "" ? parseFloat(element.measurement) : 0,
          result: element.result !== "" ? parseFloat(element.result) : 0,
        });
      });

      var myObj = {
        workInstructionGuid: this.state.workInstructionGuid,
        supplierGuid: this.state.supplierGuid,
        detectionPersonelGuid: this.state.detectionPersonelGuid,
        dimensionalReportDate: this.state.dimensionalReportDate,
        serialNo: this.state.serialNo,
        cocNo: this.state.cocNo,
        processDefinitionGuid: this.state.processDefinitionGuid,
        dimensionalReportResultDto: dimensionalReportResultDto,
      };
      var URLParam = "/dimensionalReports/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "boyutsal-olcum-raporu-guncelle"
      ) {
        URLParam = "/dimensionalReports/update";
        myObj = {
          guid: this.state.guid,
          workInstructionGuid: this.state.workInstructionGuid,
          supplierGuid: this.state.supplierGuid,
          detectionPersonelGuid: this.state.detectionPersonelGuid,
          dimensionalReportDate: this.state.dimensionalReportDate,
          serialNo: this.state.serialNo,
          cocNo: this.state.cocNo,
          processDefinitionGuid: this.state.processDefinitionGuid,
          dimensionalReportResultDto: dimensionalReportResultDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.urlSplit[1] === "boyutsal-olcum-raporu-ekle") {
              window.location.reload();
            }
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "boyutsal-olcum-raporu-guncelle"
            ) {
              window.location.href = "/boyutsal-olcum-raporu-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "boyutsal-olcum-raporu-ekle" ||
          this.state.urlSplit[1] === "boyutsal-olcum-raporu-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="4">
                    <CgnDatePicker
                      name="dimensionalReportDate"
                      label="Tarih [*]"
                      selected={
                        this.state.dimensionalReportDate &&
                        this.state.dimensionalReportDate
                          .toString()
                          .indexOf("/") > 0
                          ? new Date(
                              this.state.dimensionalReportDate.split("/")[2] +
                                "-" +
                                this.state.dimensionalReportDate.split("/")[1] +
                                "-" +
                                this.state.dimensionalReportDate.split("/")[0]
                            )
                          : this.state.dimensionalReportDate
                      }
                      onChange={(dimensionalReportDate) =>
                        this.handleDimensionalReportDateChange(
                          dimensionalReportDate
                        )
                      }
                      error={this.state.dimensionalReportDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="workInstructionCode"
                      label="İş Emri Numarası [*]"
                      value={this.state.workInstructionCode}
                      placeHolder="Lütfen iş emri no girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.workInstructionCodeError}
                      readOnly={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      placeholder="Lütfen iş emri girin.."
                      selectValue={this.state.workInstructionGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionSelectChange(option)
                      }
                      options={this.state.workInstructionArr}
                      error={this.state.workInstructionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                  <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="supplierGuid"
                          label="Tedarikçi [*]"
                          placeholder="Tedarikçi seçiniz..."
                          selectValue={this.state.supplierGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSupplierSelectChange(option)
                          }
                          options={this.state.supplierArr}
                          error={this.state.supplierError}
                        />
                      </div>
                      {this.state.supplierModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalSupplier()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      options={this.state.customerArr}
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="qualityPersonelGuid"
                      label="Kalite Personel [*]"
                      placeholder="Kalite Personeli seçiniz..."
                      selectValue={this.state.qualityPersonelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleQualityPersonelSelectChange(option)
                      }
                      options={this.state.qualityPersonelArr}
                      error={this.state.qualityPersonelError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="lastControlPersonelGuid"
                      label="Son Kontrolü Yapan Personel [*]"
                      placeholder="Son kontrolü yapan personeli seçiniz..."
                      selectValue={this.state.lastControlPersonelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleLastControlPersonelSelectChange(option)
                      }
                      options={this.state.lastControlPersonelArr}
                      error={this.state.lastControlPersonelError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="detectionPersonelGuid"
                      label="Tespiti Yapan Personel [*]"
                      placeholder="Tespiti yapan personeli seçiniz..."
                      selectValue={this.state.detectionPersonelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleDetectionPersonelSelectChange(option)
                      }
                      options={this.state.detectionPersonelArr}
                      error={this.state.detectionPersonelError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      placeholder="Satış seçiniz..."
                      selectValue={this.state.productOutGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      options={this.state.productOutArr}
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="6">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Ürün seçiniz..."
                          selectValue={this.state.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productArr}
                          error={this.state.productError}
                          disabled={true}
                        />                  
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                  <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                    <CgnReactSelect
                      name="processDefinitionGuid"
                      label="Uygulanan Process Tanımı [*]"
                      placeholder="Lütfen uygulanan process tanımı seçiniz..."
                      selectValue={this.state.processDefinitionGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProcessDefinitionSelectChange(option)
                      }
                      options={this.state.processDefinitionArr}
                      error={this.state.processDefinitionError}
                    />
                     </div>
                      {this.state.processDefinitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalProcessDefinition()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4">
                  <CgnTextbox
                      type="text"
                      name="serialNo"
                      label="Seri Numarası"
                      value={this.state.serialNo}
                      placeHolder="Lütfen seri numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="cocNo"
                      label="COC Numarası"
                      value={this.state.cocNo}
                      placeHolder="Lütfen COC numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                {this.state.dimensionalReportResultDto.map(
                  (dimensionalReportResultDto, idx) => (
                    <Row>
                      <Col lg="3">
                        <CgnTextbox
                          type="text"
                          name="documents"
                          label="Döküman Ref. Bölge"
                          value={dimensionalReportResultDto.documents}
                          placeHolder="Lütfen döküman ref. bölge girin..."
                          autoComplete="off"
                          onChange={this.handleChange2(idx)}
                        />
                      </Col>
                      <Col lg="2">
                        <CgnTextbox
                          type="number"
                          name="measurement"
                          label="Gerek/Ölçü (cm)"
                          value={dimensionalReportResultDto.measurement}
                          placeHolder="Lütfen gerek/ölçü girin..."
                          autoComplete="off"
                          onChange={this.handleChange2(idx)}
                          step="0.1"
                        />
                      </Col>
                      <Col lg="3">
                        <CgnTextbox
                          type="text"
                          name="tolerance"
                          label="Tolerans"
                          value={dimensionalReportResultDto.tolerance}
                          placeHolder="Lütfen tolerans girin..."
                          autoComplete="off"
                          onChange={this.handleChange2(idx)}
                          step="0.1"
                        />
                      </Col>
                      <Col lg="3">
                        <CgnTextbox
                          type="number"
                          name="result"
                          label="Sonuç (cm)"
                          value={dimensionalReportResultDto.result}
                          placeHolder="Lütfen sonuç girin..."
                          autoComplete="off"
                          onChange={this.handleChange2(idx)}
                          step="0.1"
                        />
                      </Col>                    
                      <Col lg="1">
                        <CgnButton
                          className="dynamicRowDeleteButton"
                          type="button"
                          color="danger"
                          onClick={() => this.handleResultRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  )
                )}

                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-2 dynamicRowAddButton"
                      onClick={this.handleResultAdd}
                      text={"Satır Ekle"}
                    />
                    {this.state.dimensionalReportResultError && (
                      <div className="invalid-feedback d-block">
                        {this.state.dimensionalReportResultError}
                      </div>
                    )}
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "boyutsal-olcum-raporu-ekle" ||
              this.state.urlSplit[1] === "boyutsal-olcum-raporu-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalSupplier}
          toggle={this.addModalSupplier}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tedarikçi Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SupplierAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSupplier}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProcessDefinition}
          toggle={this.addModalProcessDefinition}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Uygulama Process Tanımı Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProcessDefinitionAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProcessDefinition}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(DimensionalReportAddUpdate);
