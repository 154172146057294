import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnButton from "../toolbox/CgnButton";
import MachineStatusAddUpdate from "../machineStatus/MachineStatusAddUpdate";
import MachineModelAddUpdate from "../machineModel/MachineModelAddUpdate";
import { confirmAlert } from "react-confirm-alert";

class NewMachineAddUpdate extends Component {
  state = {
    pageTitle: "Makine Ekle",
    pageTitleAdd: "Makine Ekle",
    pageTitleUpdate: "Makine Güncelle",
    pageAddLink: "yeni-makine-ekle",
    pageListLink: "yeni-makine-listele",
    breadcrumb: [{ text: "Makine", link: "#" }],
    isLoading: false,
    urlSplit: [],
    machineModelArr: [],
    machineStatusArr: [],
    name: "",
    guid: "",
    serialNo: "",
    bmb: "",
    machineModelGuid: "",
    machineStatusGuid: "",
    status: true,
    nameError: "",
    machineModelError: "",
    machineStatusError: "",
    isNull: false,
    isOpenModalMachineModel: false,
    isOpenModalMachineStatus: false,
    modalButtonIdx: 0,
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    canalModalPlus: false,
    brandModalPlus: false,
    modelModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    var statusType = 2; // sadece aktif olanları göstermesi için
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineModels/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          machineModelArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/machineStatuses/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          machineStatusArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/newMachines/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              name: response.data.name,
              machineModelGuid: response.data.machineModelGuid,
              machineStatusGuid: response.data.machineStatusGuid,
              serialNo: response.data.serialNo,
              bmb: response.data.bmb,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalMachineModel &&
      this.props.location.modalAddedMachineModel !== undefined &&
      this.props.location.modalAddedMachineModel.isModal !== undefined &&
      this.props.location.modalAddedMachineModel.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/machineModels/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            machineModelArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMachineModel.data &&
              element.name === this.props.location.modalAddedMachineModel.data
            ) {
              this.setState({
                machineModelGuid: element.guid,
                machineModelError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMachineModel.isModal = true;
      this.setState({
        isOpenModalMachineModel: false,
      });
    }
    if (
      this.state.isOpenModalMachineStatus &&
      this.props.location.modalAddedMachineStatus !== undefined &&
      this.props.location.modalAddedMachineStatus.isModal !== undefined &&
      this.props.location.modalAddedMachineStatus.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/machineStatuses/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            machineStatusArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMachineStatus.data &&
              element.name === this.props.location.modalAddedMachineStatus.data
            ) {
              this.setState({
                machineStatusGuid: element.guid,
                machineStatusError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMachineStatus.isModal = true;
      this.setState({
        isOpenModalMachineStatus: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalMachineModel = () => {
    this.setState({
      isOpenModalMachineModel: !this.state.isOpenModalMachineModel,
    });
  };

  addModalMachineStatus = () => {
    this.setState({
      isOpenModalMachineStatus: !this.state.isOpenModalMachineStatus,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleNotChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      serialNo: "",
      bmb: "",
      machineModelGuid: "",
      machineStatusGuid: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      machineModelError: CgnMessage.textErrorMessage,
      machineStatusError: CgnMessage.textErrorMessage,
    });
  }

  handleMachineModelSelectChange(option) {
    if (option) {
      this.setState({
        machineModelGuid: option.value,
        machineModelError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        machineModelGuid: "",
        alertErrorMessage: CgnMessage.alertErrorMessage,
        machineModelError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleMachineStatusSelectChange(option) {
    if (option) {
      this.setState({
        machineStatusGuid: option.value,
        machineStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        machineStatusGuid: "",
        alertErrorMessage: CgnMessage.alertErrorMessage,
        machineStatusError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      machineModelError: "",
      machineStatusError: "",
      nameError: "",
    });
    if (this.state.machineModelGuid === "") {
      this.setState({
        machineModelError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.machineStatusGuid === "") {
      this.setState({
        machineStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.machineModelGuid !== "" &&
      this.state.machineStatusGuid !== "" &&
      this.state.name !== ""
    ) {
      this.setState({
        machineModelError: "",
        machineStatusError: "",
        nameError: "",
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        status: this.state.status,
        machineModelGuid: this.state.machineModelGuid,
        machineStatusGuid: this.state.machineStatusGuid,
        name: this.state.name,
        serialNo: this.state.serialNo,
        bmb: this.state.bmb,
      };
      var URLParam = "/newMachines/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "yeni-makine-guncelle"
      ) {
        URLParam = "/newMachines/update";
        myObj = {
          guid: this.state.guid,
          status: this.state.status,
          machineModelGuid: this.state.machineModelGuid,
          machineStatusGuid: this.state.machineStatusGuid,
          name: this.state.name,
          serialNo: this.state.serialNo,
          bmb: this.state.bmb,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            var modalAddedNewMachine = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedNewMachine });

            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (
                      this.state.guid &&
                      this.state.urlSplit[1] === "yeni-makine-guncelle"
                    ) {
                      window.location.href = "/yeni-makine-listele";
                    }
                    if (this.state.urlSplit[1] === "yeni-makine-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "yeni-makine-ekle" ||
          this.state.urlSplit[1] === "yeni-makine-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="C4C [*]"
                      value={this.state.name}
                      placeHolder="Lütfen isim girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="machineModelGuid"
                          label="Model [*]"
                          placeholder="Model seçiniz..."
                          selectValue={this.state.machineModelGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleMachineModelSelectChange(option)
                          }
                          options={this.state.machineModelArr}
                          error={this.state.machineModelError}
                        />
                      </div>
                      <div className="p-0 flexAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalMachineModel()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="serialNo"
                      label="Makine Seri No"
                      value={this.state.serialNo}
                      placeHolder="Lütfen makine seri no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.machineSerialNoError}
                    />
                  </Col>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="bmb"
                      label="C4C"
                      value={this.state.bmb}
                      placeHolder="Lütfen C4C girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="machineStatusGuid"
                          label="Durum [*]"
                          placeholder="Durum seçiniz..."
                          selectValue={this.state.machineStatusGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleMachineStatusSelectChange(option)
                          }
                          options={this.state.machineStatusArr}
                          error={this.state.machineStatusError}
                        />
                      </div>
                      <div className="p-0 flexAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalMachineStatus()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "yeni-makine-ekle" ||
              this.state.urlSplit[1] === "yeni-makine-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalMachineModel}
          toggle={this.addModalMachineModel}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Model Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MachineModelAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalMachineModel}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalMachineStatus}
          toggle={this.addModalMachineStatus}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Durum Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MachineStatusAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalMachineStatus}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(NewMachineAddUpdate);
