import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  ModalHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  TaxTypeOptions,
  optionOfferStatusesOptions,
  URL,
  fotoStyle,
  localFilePath,
  fotoStyle2,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import CgnButton from "../toolbox/CgnButton";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import pdfIcon from "../../images/pdfIcon.png";
import fileIcon from "../../images/file.jpg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import NoteAddUpdate from "../notes/NoteAddUpdate";

class OfferSieveDetail extends Component {
  state = {
    pageTitle: "Teklif Detay",
    pageTitleDetail: "Teklif Detay",
    pageAddLink: "teklif3-ekle",
    pageListLink: "teklif3-listele",
    breadcrumb: [{ text: "Teklif", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    offerNumber: "",
    offerDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    sevkAddress: "",
    generalDiscountTotal: null,
    generalTaxTotal: null,
    taxTotal: null,
    discountPercent: "",
    language: "",
    customerPhone: "",
    discountValue: "",
    discountTotal: "",
    taxType: null,
    taxRate: null,
    currencyType: 0,
    description: "",
    bankName: "",
    productDto: [],
    customerEmail: "",
    offerSieveImageDto: [],
    offerSievePictureDto: [],
    productName: "",
    piece: null,
    unitPrice: null,
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    offerCoverImage: "",
    confirmationDate: "",
    warrantyPeriodName: "",
    deliveryTimeName: "",
    deliveryTypeName: "",
    paymentTypeName: "",
    approvalDate: "",
    noteDto: [],
    isOpenModalNote: false,
    isOpenModalUpdateNote: false,
    shipmentDate: "",
    connectionName: "",
    contents: "",
    reasonForCancellation: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "teklif32-detay") {
      await this.setState({
        pageAddLink: "teklif32-ekle",
        pageListLink: "teklif32-listele",
        pageTitle: "Yurt Dışı Teklif Detay",
        pageTitleDetail: "Yurt Dışı Teklif Detay",
      });
    } else if (urlSplit[1] === "teklif3-onaylanan-detay") {
      await this.setState({
        pageAddLink: null,
        pageListLink: "teklif3-onaylanan-listele",
        pageTitle: "Teklif Onaylanan Detay",
        pageTitleDetail: "Teklif Onaylanan Detay",
      });
    } else if (urlSplit[1] === "teklif3-sevk-edilen-detay") {
      await this.setState({
        pageAddLink: null,
        pageListLink: "teklif3-sevk-edilen-listele",
        pageTitle: "Teklif Sevk Edilen Detay",
        pageTitleDetail: "Teklif Sevk Edilen Detay",
      });
    }
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/offersieves/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            customerName: response.data.customerName,
            connectionName: response.data.connectionName,
            offerTypeName: response.data.offerTypeName,
            offerNumber: response.data.offerNumber,
            offerDate: response.data.offerDate,
            optionDate:
              response.data.optionDate === "01/01/1900"
                ? "-"
                : response.data.optionDate,
            reminderDate: response.data.reminderDate,
            language: response.data.language,
            productDto: response.data.offerSieveProductDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),
            currencyType: response.data.currencyType,
            customerEmail: response.data.customerEmail,
            customerPhone: response.data.customerPhone,
            paymentTypeName: response.data.paymentTypeName,
            deliveryTimeName: response.data.deliveryTimeName,
            deliveryTypeName: response.data.deliveryTypeName,
            //warrantyPeriodName: response.data.warrantyPeriodName,
            description: response.data.description,
            sevkAddress: response.data.sevkAddress,
            offerCoverImage: response.data.offerCoverImage,
            confirmationDate: response.data.confirmationDate,
            //offerCancelText: response.data.offerCancelText,
            offerSieveImageDto: response.data.offerSieveImageDto,
            offerSievePictureDto: response.data.offerSievePictureDto,
            bankName: response.data.bankName,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            approvalDate: response.data.approvalDate,
            noteDto: response.data.noteDto,
            shipmentDate: response.data.shipmentDate,
            isLoading: false,
            contents: response.data.contents,
            garantiden: response.data.garantiden,
            reasonForCancellation: response.data.reasonForCancellation,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalNote = () => {
    this.setState({
      isOpenModalNote: !this.state.isOpenModalNote,
      noteGuid: null,
    });
  };

  addModalUpdateNote = (guid) => {
    this.setState({
      isOpenModalUpdateNote: !this.state.isOpenModalUpdateNote,
      noteGuid: guid,
    });
  };

  handleNoteDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleNoteSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleNoteSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/notes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const sira = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "20px",
      textAlign: "center",
    };
    const siraTd = {
      backgroundColor: "#f00",
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    };
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const mainRowDate = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "215px",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self1 = this;
    var self = this.state;

    // var phone = this.state.customerPhone,
    //   maskPhone = `${phone.substring(0, 1)}  ${
    //     "(" + phone.substring(1, 4) + ")"
    //   } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
    //     9,
    //     11
    //   )}`;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={this.state.pageAddLink ? "/" + this.state.pageAddLink : ""}
          listLink={"/" + this.state.pageListLink}
          noteLink={(onClick) => this.addModalNote()}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tr>
                  <th>Teklif Dili</th>
                  <td colSpan="4">
                    {this.state.language === 1
                      ? "Türkçe"
                      : this.state.language === 2
                        ? "İngilizce"
                        : this.state.language === 3
                          ? "Fransızca"
                          : this.state.language === 4
                            ? "Almanca"
                            : this.state.language === 5
                              ? "Arapça"
                              : "-"}
                  </td>
                </tr>
                <tr>
                  <th>Teklif Türü</th>
                  <td colSpan="4">{this.state.offerTypeName}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>
                <tr>
                  <th>Yetkili Ad Soyad</th>
                  <td colSpan="4">
                    {this.state.connectionName &&
                      this.state.connectionName !== ""
                      ? this.state.connectionName
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th>Müşteri E-mail</th>
                  <td colSpan="4">
                    {this.state.customerEmail === ""
                      ? "-"
                      : this.state.customerEmail}
                  </td>
                </tr>
                <tr>
                  <th>Müşteri Telefon</th>
                  <td colSpan="4">
                    {this.state.customerPhone === ""
                      ? "-"
                      : this.state.customerPhone}
                  </td>
                </tr>
                <tr>
                  <th>Teklif Tarihi</th>
                  <td colSpan="4">{this.state.offerDate}</td>
                </tr>
                <tr>
                  <th>Teklif No</th>
                  <td colSpan="4">{this.state.offerNumber}</td>
                </tr>
                <tr>
                  <th>Sevkiyat Tarihi</th>
                  <td colSpan="4">
                    {this.state.shipmentDate !== "" &&
                      this.state.shipmentDate !== "01/01/1900" &&
                      this.state.shipmentDate !== "01/01/0001"
                      ? this.state.shipmentDate
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Ara Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam İndirim</th>
                  <td colSpan="4">
                    {this.state.generalDiscountTotal
                      ? this.state.generalDiscountTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam KDV Tutarı</th>
                  <td colSpan="4">
                    {this.state.generalTaxTotal
                      ? this.state.generalTaxTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Ödeme Yöntemi </th>
                  <td colSpan="4">{this.state.paymentTypeName}</td>
                </tr>

                <tr>
                  <th>Teslimat Yöntemi </th>
                  <td colSpan="4">{this.state.deliveryTypeName}</td>
                </tr>

                <tr>
                  <th>Teslimat Süresi </th>
                  <td colSpan="4">{this.state.deliveryTimeName}</td>
                </tr>

                <tr>
                  <th>Sevk Adresi </th>
                  <td colSpan="4">
                    {this.state.sevkAddress && this.state.sevkAddress !== ""
                      ? this.state.sevkAddress
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Teklif Notu</th>
                  <td
                    colSpan="4"
                    className="text-danger font-weight-bold"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description
                        ? this.state.description
                        : "-",
                    }}
                  ></td>
                </tr>

                <tr>
                  <th>İçerik </th>
                  <td colSpan="4">
                    {this.state.contents && this.state.contents !== ""
                      ? this.state.contents
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>İptal Sebebi </th>
                  <td colSpan="4">
                    {this.state.reasonForCancellation && this.state.reasonForCancellation !== ""
                      ? this.state.reasonForCancellation
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Teklif Kapağı</th>
                  <td colSpan="4">
                    {this.state.offerCoverImage ? (
                      <Button
                        onClick={this.toggle}
                        className="modalButtonDetail"
                      >
                        <img
                          src={this.state.offerCoverImage}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      </Button>
                    ) : (
                      <img
                        src={noImg}
                        style={fotoStyle}
                        alt={this.state.customerName}
                      />
                    )}
                  </td>
                </tr>

                <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}></ModalHeader>
                  <img
                    className="modalofferCoverImage"
                    src={this.state.offerCoverImage}
                    style={fotoStyle}
                    alt={this.state.customerName}
                  />
                </Modal>

                <tr>
                  <th>Teklif Onay/İptal Tarihi Saati</th>
                  <td colSpan="4">{this.state.confirmationDate}</td>
                </tr>

                <tr>
                  <th>Banka</th>
                  <td colSpan="4">{this.state.bankName}</td>
                </tr>
                <tr>
                  <th>Garantiden</th>
                  <td colSpan="4">
                    {this.state.garantiden ? "Evet" : "-"}
                  </td>
                </tr>
                <tr>
                  <th>Durum</th>
                  <td colSpan="4">
                    {optionOfferStatusesOptions(this.state.status)}
                  </td>
                </tr>
                {this.state.status === 2 && (
                  <tr>
                    <th>Onay Tarihi</th>
                    <td colSpan="4">{this.state.optionDate}</td>
                  </tr>
                )}
                <tr>
                  <th>Onaylanma Tarihi</th>
                  <td colSpan="4">{this.state.confirmationDate !== "01 Ocak 1900" ? this.state.confirmationDate : "-"}</td>
                </tr>
                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              {this.state.productDto !== null &&
                this.state.productDto.length > 0 &&
                this.state.productDto.map(function (item, idx) {
                  return (
                    <Table className="table table-responsive table-hover table-product">
                      <tr className="">
                        <th style={sira}>Sıra</th>
                        <th className="text-left pl-1" style={mainRow}>
                          Depo
                        </th>
                        <th className="text-left pl-1" style={mainRow}>
                          Ürün Adı
                        </th>
                        <th className="text-center" style={mainRow}>
                          Adet
                        </th>
                        <th className="text-center" style={mainRow}>
                          Birim
                        </th>
                        <th className="text-center" style={mainRow}>
                          Birim Fiyatı{" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Yüzde){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Net Tutar){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          KDV Oranı{" "}
                        </th>
                        <th style={mainRow}>Fiyat</th>
                        <th style={mainRow}>Alış Fiyat</th>
                      </tr>
                      <tr style={subRow}>
                        <td style={siraTd}>{idx + 1}</td>
                        <td className="text-left pl-1">{item.locationName}</td>
                        <td className="text-left pl-1">{item.productName}</td>
                        <td className="text-center">{item.piece}</td>
                        <td className="text-center">
                          {item.unit === "NIU"
                            ? "Adet"
                            : item.unit === "KGM"
                              ? "kg"
                              : item.unit === "GRM"
                                ? "gr"
                                : item.unit === "MTR"
                                  ? "Metre"
                                  : item.unit === "LTR"
                                    ? "Litre"
                                    : item.unit === "PA"
                                      ? "Paket"
                                      : item.unit === "BX"
                                        ? "Kutu"
                                        : item.unit === "CMT"
                                          ? "cm"
                                          : item.unit === "MTQ"
                                            ? "m3"
                                            : item.unit === "MTK"
                                              ? "m2"
                                              : item.unit === "ROLL"
                                                ? "Rulo"
                                                : item.unit === "SET"
                                                  ? "Set"
                                                  : item.unit === "CMQ"
                                                    ? "cm3"
                                                    : "-"}
                        </td>
                        <td className="text-center">
                          {item.unitPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                  ? "€"
                                  : "-")}
                        </td>

                        <td className="text-center">
                          {item.productDiscountPercent
                            ? "% " + item.productDiscountPercent
                            : "-"}
                        </td>
                        <td className="text-center">
                          {item.productDiscountValue &&
                            item.productDiscountValue > 0
                            ? item.productDiscountValue +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                  ? "€"
                                  : "-")
                            : "-"}
                        </td>
                        <td className="text-center">
                          {"% " + item.productTaxRate}
                        </td>
                        <td>
                          {item.total.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                  ? "€"
                                  : "-")}
                        </td>
                        <td>
                          {item.purchasePrice && item.purchasePrice !== 0
                            ?
                            item.purchasePrice.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                  ? "€"
                                  : "-")
                            : "-"
                          }
                        </td>
                      </tr>
                      <tr>
                        <th
                          colSpan={4}
                          style={mainRow}
                          className="text-left pl-3"
                        >
                          Makine Boyutu
                        </th>
                        <th style={mainRow} className="text-left pl-3">
                          Elek Boyutu
                        </th>
                        <th
                          colSpan={3}
                          style={mainRow}
                          className="text-left pl-3"
                        >
                          Elek Göz Boyutu
                        </th>
                        <th style={mainRowDate} className="text-left pl-3">
                          Müşteri Tahmini Teslimat
                        </th>
                        <th style={mainRowDate} className="text-left pl-3">
                          Güncel Teslimat
                        </th>
                        <th style={mainRowDate} className="text-left pl-3">
                          Tahmini Teslimat
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={3} className="text-left pl-3">
                          {item.machineSize !== "" ? item.machineSize : "-"}
                        </td>
                        <td className="text-left pl-3">
                          {item.sieveSize !== "" ? item.sieveSize : "-"}
                        </td>
                        <td colSpan={3} className="text-left pl-3">
                          {item.sieveEyeSize !== "" ? item.sieveEyeSize : "-"}
                        </td>
                        <td className="text-left pl-3">
                          {item.customerEstimatedDeliveryDateDetail !== ""
                            ? item.customerEstimatedDeliveryDateDetail
                            : "-"}
                        </td>
                        <td className="text-left pl-3">
                          {item.deliveryDateDetail !== ""
                            ? item.deliveryDateDetail
                            : "-"}
                        </td>
                        <td className="text-left pl-3">
                          {item.estimatedDeliveryDateDetail !== ""
                            ? item.estimatedDeliveryDateDetail
                            : "-"}
                        </td>
                      </tr>
                      {item.sieveProductPropertyDto != null &&
                        item.sieveProductPropertyDto.length > 0 && (
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#1D4B8C",
                                color: "#fff",
                              }}
                              colSpan="10"
                              className="text-left"
                            >
                              Ürün Özellikleri
                            </th>
                          </tr>
                        )}
                      {item.sieveProductPropertyDto.map(function (item2) {
                        return (
                          <tr style={subRow}>
                            <td colSpan={5} className="text-left">
                              {item2.propertyTypeName}
                            </td>
                            <td colSpan={5} className="text-left">
                              {" "}
                              {item2.value}
                            </td>
                          </tr>
                        );
                      })}
                      {item.offerSievesProductsShippingDto != null &&
                        item.offerSievesProductsShippingDto.length > 0 && (
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#1D4B8C",
                                color: "#fff",
                              }}
                              colSpan="7"
                            ></th>
                            <th
                              style={{
                                backgroundColor: "#1D4B8C",
                                color: "#fff",
                              }}
                              className="text-left pl-3"
                            >
                              Adet
                            </th>
                            <th
                              style={{
                                backgroundColor: "#1D4B8C",
                                color: "#fff",
                              }}
                              className="text-left pl-3"
                            >
                              Hazır Tarihi
                            </th>
                            <th
                              style={{
                                backgroundColor: "#1D4B8C",
                                color: "#fff",
                              }}
                              className="text-left pl-3"
                            >
                              Kalem Sevkiyat
                            </th>
                          </tr>
                        )}
                      {item.offerSievesProductsShippingDto.map(function (
                        item2
                      ) {
                        return (
                          <tr style={subRow}>
                            <td colSpan={7} className="text-left pl-3"></td>
                            <td className="text-left pl-3">{item2.piece}</td>
                            <td className="text-left pl-3">
                              {item2.readyDateDetail}
                            </td>
                            <td className="text-left pl-3">
                              {item2.shippingDateDetail}
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  );
                })}

              {this.state.offerSieveImageDto &&
                this.state.offerSieveImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.offerSieveImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={fileIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.offerSievePictureDto !== null &&
                this.state.offerSievePictureDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Teklif Fotoğraf</th>
                      </tr>
                      <Row>
                        {this.state.offerSievePictureDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}

              {this.state.noteDto !== null && this.state.noteDto.length > 0 && (
                <>
                  <div
                    className="row"
                    style={{
                      display:
                        self.noteDto !== null && self.noteDto.length > 0
                          ? ""
                          : "none",
                    }}
                  >
                    <h2 className="mt-2">Not</h2>
                  </div>
                  <Table className="table table-responsive table-product2">
                    <tr className=""></tr>
                    {this.state.noteDto.map(function (item) {
                      return (
                        <tbody>
                          <>
                            <Accordion allowZeroExpanded>
                              <AccordionItem>
                                <AccordionItemHeading
                                  style={{ backgroundColor: "#9B9796" }}
                                >
                                  <AccordionItemButton>
                                    <tr className="cuttingTr4 w-100">
                                      <th
                                        className="pl-3"
                                        style={self1.thStyle3}
                                      >
                                        Not Başlığı
                                      </th>
                                      <td style={self1.tdStyle2}>
                                        {item.title}
                                      </td>
                                    </tr>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <tr></tr>
                                  <tr>
                                    <th className="pl-3">Not</th>
                                    <td>{item.description}</td>
                                  </tr>
                                  <CgnButton
                                    type="button"
                                    color="success"
                                    className="printButton ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.addModalUpdateNote(item.guid)
                                    }
                                    text="Güncelle"
                                  />
                                  <CgnButton
                                    type="button"
                                    color="danger"
                                    style={{ width: "90px" }}
                                    className="ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.handleNoteDelete(item.guid)
                                    }
                                    text="Sil"
                                  />
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </>
                        </tbody>
                      );
                    })}
                  </Table>
                </>
              )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalNote}
          toggle={this.addModalNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.guid} updated={false} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalUpdateNote}
          toggle={this.addModalUpdateNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.noteGuid} updated={true} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default OfferSieveDetail;
