import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";
import { ro } from "date-fns/locale";

class NewMachineList extends Component {
  state = {
    pageTitle: "Makine Listele",
    pageAddLink: "yeni-makine-ekle",
    pageUpdateLink: "yeni-makine-guncelle",
    pageDetailLink: "yeni-makine-detay",
    pageListLink: "yeni-makine-listele",
    breadcrumb: [{ text: "Makine", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    modelData: [],
    guid: "",
    name: "",
    serialNo: "",
    bmb: "",
    machineModelName: "",
    machineStatusName: "",
    alertErrorMessage: "",
    machineModelGuid: "00000000-0000-0000-0000-000000000000",
    machineStatusGuid: "00000000-0000-0000-0000-000000000000",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // 1 aktif getirmesi için
    await axios
      .get(URL + "/machineModels/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          machineModelArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/machineStatuses/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          machineStatusArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 1;
    axios
      .get(URL + "/newMachines/getlist/" + statusType + "/" + this.state.machineModelGuid + "/" + this.state.machineStatusGuid, config)
      .then((response) => {
        this.setState({
          modelData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/newMachines/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  handleMachineModelSelectChange(option) {
    if (option) {
      this.setState({
        machineModelGuid: option.value
      });
    } else {
      this.setState({
        machineModelGuid: "00000000-0000-0000-0000-000000000000"
      });
    }
  }

  handleMachineStatusSelectChange(option) {
    if (option) {
      this.setState({
        machineStatusGuid: option.value
      });
    } else {
      this.setState({
        machineStatusGuid: "00000000-0000-0000-0000-000000000000"
      });
    }
  }

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "155px",
  };

  tdStyle = {
    width: "250px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="machineList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" lg="5">
                  <CgnReactSelect
                    name="machineModelGuid"
                    label="Model"
                    placeholder="Model seçiniz..."
                    selectValue={this.state.machineModelGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleMachineModelSelectChange(option)
                    }
                    options={this.state.machineModelArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" lg="5">
                  <CgnReactSelect
                    name="machineStatusGuid"
                    label="Durum"
                    placeholder="Durum seçiniz..."
                    selectValue={this.state.machineStatusGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleMachineStatusSelectChange(option)
                    }
                    options={this.state.machineStatusArr}
                  />
                </Col>
                <Col className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.modelData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Makine Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>İsim</th>
                              <td style={this.tdStyle}>
                                {row.name}
                              </td>
                              <th style={this.thStyle2}>Model</th>
                              <td>
                                {row.machineModelName}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Seri No</th>
                              <td style={this.tdStyle}>
                                {row.serialNo ? row.serialNo : "-"}
                              </td>
                              <th style={this.thStyle2}>C4C</th>
                              <td>
                                {row.bmb ? row.bmb : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th style={this.thStyle}>Durum</th>
                              <td style={this.tdStyle}>{row.machineStatusName}</td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>
                                {row.createdBy}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    name: "Durumu",
                    selector: "status",
                    width: "150px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.machineStatusName) {
                        return <CgnBadge color="info" text={row.machineStatusName} className="w-100" />;
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default NewMachineList;
