import { combineReducers } from "redux";
import loginReducer from "./user/loginReducer"
import companyUserGroupListReducer from "./companyUserGroups/companyUserGroupListReducer"
import employeeListReducer from "./employee/employeeListReducer"
import groupGetListSelectReducer from "./groupGetListSelect/groupGetListSelectReducer"




const rootReducer = combineReducers({

    loginReducer,
    companyUserGroupListReducer,
    employeeListReducer,
    groupGetListSelectReducer,

});

export default rootReducer;
