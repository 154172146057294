import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses,  fotoStyle } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";
import rarIcon from "../../images/rarIcon.png";
import zipIcon from "../../images/zipIcon.png";
import { confirmAlert } from "react-confirm-alert";
class FilesAddUpdate extends Component {
  state = {
    pageTitle: "Dosya Ekle",
    pageTitleAdd: "Dosya Ekle",
    pageTitleUpdate: "Dosya Güncelle",
    pageAddLink: "dosya-ekle",
    pageListLink: "dosya-listele",
    breadcrumb: [{ text: "Dosya", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    guid: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    filesDocumentDto: [],
    filesDocumentDtoError: "",
  };
  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/files/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              name: response.data.name,
              filesDocumentDto: response.data.filesDocumentDto,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        guid: "",
        name: "",
        filesDocumentDto: [],
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }
  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };
  clearPage() {
    this.setState({
      name: "",
      filesDocumentDto: [],
      filesDocumentDtoError: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }
  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }
  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
      cargoTrackingDocumentDtoError: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.name !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        name: this.state.name,
        filesDocumentDto: this.state.filesDocumentDto,
        status: this.state.status,
      };
      var URLParam = "/files/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "dosya-guncelle"
      ) {
        URLParam = "/files/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          filesDocumentDto: this.state.filesDocumentDto,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            var modalAddedFiles = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedFiles });

            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (
                      this.state.guid &&
                      this.state.urlSplit[1] === "dosya-guncelle"
                    ) {
                      window.location.href = "/dosya-listele";
                    }
                    if (this.state.urlSplit[1] === "dosya-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  uploadedDocument = async (event) => {
    let array = this.state.filesDocumentDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.setState({
        isLoading: true,
      });
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    }
    await this.setState({ filesDocumentDto: array });
  };
  deleteDocument = (event) => {
    var index = -1;
    let array = this.state.filesDocumentDto;
    this.state.filesDocumentDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ filesDocumentDto: array });
        }
      }
    });
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "dosya-ekle" ||
          this.state.urlSplit[1] === "dosya-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Ad [*]"
                      value={this.state.name}
                      placeHolder="Lütfen ad girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pl-0">
                    <CgnImageUploadMulti
                      images={this.state.filesDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      rarIcon={rarIcon}
                      zipIcon={zipIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png, .rar, .zip"
                      className="text-center text-danger"
                      label={"Dosyalar"}
                      onChange={this.uploadedDocument}
                      onDelete={this.deleteDocument}
                      error={this.state.filesDocumentDtoError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "dosya-ekle" ||
              this.state.urlSplit[1] === "dosya-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(FilesAddUpdate);
