import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses,  announcementType, fotoStyle } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnTextEditor from "../toolbox/CgnTextEditor";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import noImg from "../../images/no-img.png";
import docIcon from "../../images/docIcon.png";
import excelIcon from "../../images/xlsIcon.png";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
class CustomerEducationAnnouncementAddUpdate extends Component {
    state = {
        pageTitle: "Duyuru Ekle",
        pageTitleAdd: "Duyuru Ekle",
        pageTitleUpdate: "Duyuru Güncelle",
        pageAddLink: "musteri-egitim-duyuru-ekle",
        pageListLink: "musteri-egitim-duyuru-listele",
        breadcrumb: [{ text: "Duyurular", link: "#" }],
        isLoading: false,
        urlSplit: [],
        guid: "",
        name: "",
        nameError: "",
        briefDescription: "",
        briefDescriptionError: "",
        description: "",
        descriptionHtml: "",
        status: true,
        alertErrorMessage: "",
        buttons: [
            {
                type: "button",
                color: "secondary",
                text: CgnMessage.clearButton,
                onClick: (event) => this.handleClear(event),
                disabled: this.isLoading,
            },
            {
                type: "submit",
                color: "primary",
                text: CgnMessage.saveButton,
                disabled: this.isLoading,
            },
        ],
        announcementType: false,
        eMails: "",
        eMailsError: "",
        customerEducationAnnouncementFilesDto: [],
        customerEducationAnnouncementFilesDtoError: "",
        sendDateTime: "",
        sendDateTimeError: "",
    };
    async componentDidMount() {
        this.setState({
            isLoading: true,
        });
        let urlSplit = window.location.pathname.split("/");
        this.setState({
            urlSplit: urlSplit,
        });
        if (urlSplit.length === 3) {
            this.setState({
                guid: urlSplit[urlSplit.length - 1],
                pageTitle: this.state.pageTitleUpdate,
                isLoading: true,
            });
            this.getById(urlSplit[2]);
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .get(URL + "/customereducationannouncement/get/" + urlSplit[2], config)
                .then((response) => {
                    if (this.state.guid) {
                        this.setState({
                            guid: response.data.guid,
                            name: response.data.name,
                            briefDescription: response.data.briefDescription,
                            descriptionHtml: response.data.description,
                            description: EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(response.data.description)
                                )
                            ),
                            announcementType: response.data.announcementType,
                            eMails: response.data.eMails,
                            status: response.data.status,
                            isLoading: false,
                            customerEducationAnnouncementFilesDto: response.data.customerEducationAnnouncementFilesDto,
                            sendDateTime: response.data.sendDateTime,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }
    componentDidUpdate() {
        let urlSplit = window.location.pathname.split("/");
        if (
            urlSplit.length === 2 &&
            this.state.pageTitle !== this.state.pageTitleAdd
        ) {
            this.setState({
                urlSplit: urlSplit,
                pageTitle: this.state.pageTitleAdd,
                guid: "",
                name: "",
                briefDescription: "",
                description: "",
                descriptionHtml: "",
                status: true,
            });
        }
        if (this.state.status === undefined) {
            this.setState({
                status: true,
            });
        }
    }
    getById(guid) {
        this.setState({
            isLoading: true,
            guid: guid,
        });
    }
    handleSendDateTimeChange(sendDateTime) {
        if (sendDateTime) {
            this.setState({
                sendDateTime: sendDateTime,
                sendDateTimeError: "",
                alertErrorMessage: "",
            });
        } else {
            this.setState({
                sendDateTime: "",
                sendDateTimeError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
        this.setState({
            nameError: "",
            briefDescriptionError: "",
            eMailsError: "",
            alertErrorMessage: "",
        });
        if (name === "name" && value === "") {
            this.setState({
                nameError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
        if (name === "briefDescription" && value === "") {
            this.setState({
                briefDescriptionError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
        if (this.state.announcementType && name === "eMails" && value === "") {
            this.setState({
                eMailsError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    };
    handleClear = (event) => {
        this.clearPage();
    };
    clearPage() {
        this.setState({
            name: "",
            briefDescription: "",
            description: "",
            descriptionHtml: "",
            status: true,
            announcementType: false,
            eMails: "",
            alertErrorMessage: CgnMessage.alertErrorMessage,
            nameError: CgnMessage.textErrorMessage,
            sendDateTime: "",
            sendDateTimeError: CgnMessage.textErrorMessage,
        });
    }
    handleStatusSelectChange(status) {
        this.setState({ status: status.value });
    }
    handleAnnouncementTypeSelectChange(announcementType) {
        this.setState({ announcementType: announcementType.value });
    }
    handleSave = async (event) => {
        event.preventDefault();
        this.setState({
            alertErrorMessage: "",
            nameError: "",
            briefDescriptionError: "",
            eMailsError: "",
            sendDateTimeError: "",
        });
        if (this.state.name === "") {
            this.setState({
                nameError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if (this.state.briefDescription === "") {
            this.setState({
                briefDescriptionError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if (this.state.announcementType && this.state.eMails === "") {
            this.setState({
                eMailsError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if (this.state.name !== "" &&
            this.state.briefDescription !== "" &&
            ((this.state.announcementType && this.state.eMails !== "") || !this.state.announcementType)) {
            this.setState({
                isLoading: true,
            });
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            var myObj = {
                name: this.state.name,
                briefDescription: this.state.briefDescription,
                description: this.state.descriptionHtml,
                status: this.state.status,
                announcementType: this.state.announcementType,
                eMails: this.state.eMails,
                customerEducationAnnouncementFilesDto: this.state.customerEducationAnnouncementFilesDto,
                sendDateTime: this.state.sendDateTime !== "" ? moment(this.state.sendDateTime).format("DD/MM/YYYY") : "",
            };
            var URLParam = "/customereducationannouncement/add";
            if (this.state.guid && this.state.urlSplit.length !== 2 && this.state.urlSplit[1] === "musteri-egitim-duyuru-guncelle") {
                URLParam = "/customereducationannouncement/update";
                myObj = {
                    guid: this.state.guid,
                    name: this.state.name,
                    briefDescription: this.state.briefDescription,
                    description: this.state.descriptionHtml,
                    status: this.state.status,
                    announcementType: this.state.announcementType,
                    eMails: this.state.eMails,
                    customerEducationAnnouncementFilesDto: this.state.customerEducationAnnouncementFilesDto,
                    sendDateTime:
                        this.state.sendDateTime !== "" ? this.state.sendDateTime &&
                            this.state.sendDateTime.toString().indexOf("/") > 0
                            ? new Date(
                                this.state.sendDateTime.split("/")[2] +
                                "-" +
                                this.state.sendDateTime.split("/")[1] +
                                "-" +
                                this.state.sendDateTime.split("/")[0]
                            )
                            : moment(this.state.sendDateTime).format("DD/MM/YYYY") : "",
                };
            }
            axios
                .post(URL + URLParam, myObj, config)
                .then(() => {
                    setTimeout(() => {
                        if (this.state.guid && this.state.urlSplit[1] === "musteri-egitim-duyuru-guncelle") {
                            window.location.href = "/musteri-egitim-duyuru-listele";
                        }
                        if (this.state.urlSplit[1] === "musteri-egitim-duyuru-ekle") {
                            window.location.reload();
                        }
                        var modalAddedCustomerEducationAnnouncement = {
                            data: this.state.name,
                            isModal: false,
                        };
                        this.props.history.push({ modalAddedCustomerEducationAnnouncement });
                        this.setState({
                            isLoading: false,
                        });
                    }, 1500);
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        } else {
            this.setState({
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    };
    handleEditorChange = (editorState, editorStateHtml) => {
        if (editorStateHtml) {
            this.setState({
                description: editorState,
                descriptionHtml: editorStateHtml,
            });
        }
    };
    uploadedImage = async (event) => {
        let array = this.state.customerEducationAnnouncementFilesDto;
        const files = event.target.files;
        for (var i = 0; i < files.length; i++) {
            this.setState({
                isLoading: true,
            });
            const file = event.target.files[i];
            const formData = new FormData();
            formData.append("files", file);
            await axios
                .post(URL + "/uploads2/add", formData)
                .then((response) => {
                    array.push({
                        image: response.data,
                        imageName: file.name,
                    });
                    this.setState({
                        isLoading: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                    });
                });
        }
        await this.setState({ customerDocumentOtherImageDto: array });
    };
    deleteImage = (event) => {
        var index = -1;
        let array = this.state.customerEducationAnnouncementFilesDto;
        this.state.customerEducationAnnouncementFilesDto.forEach((element) => {
            index++;
            if (element.image === event) {
                if (index !== -1) {
                    array.splice(index, 1);
                    this.setState({ customerEducationAnnouncementFilesDto: array });
                }
            }
        });
    };
    render() {
        const textAreaStyle = {
            padding: "5px 10px",
            height: "100px",
        };
        return (
            <>
                {(this.state.urlSplit[1] === "musteri-egitim-duyuru-ekle" ||
                    this.state.urlSplit[1] === "musteri-egitim-duyuru-guncelle") && (
                        <ContentTop
                            breadcrumb={this.state.breadcrumb}
                            title={this.state.pageTitle}
                            addLink={"/" + this.state.pageAddLink}
                            listLink={"/" + this.state.pageListLink}
                        />
                    )}
                <Row>
                    <Col>
                        <Form onSubmit={this.handleSave}>
                            <Card body>
                                <CgnLoader visibled={this.state.isLoading} />
                                <Row>
                                    <Col lg="12">
                                        <CgnTextbox
                                            type="text"
                                            name="name"
                                            label="Başlık [*]"
                                            value={this.state.name}
                                            placeHolder="Lütfen başlık girin..."
                                            maxLength="255"
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            error={this.state.nameError}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <CgnTextbox
                                            inlineStyle={textAreaStyle}
                                            type="textarea"
                                            name="briefDescription"
                                            label="Kısa Açıklama [*]"
                                            value={this.state.briefDescription}
                                            placeHolder="Lütfen kısa açıklaması girin..."
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            error={this.state.briefDescriptionError}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <CgnTextEditor
                                            name="description"
                                            label="Açıklama [*]"
                                            editorState={this.state.description}
                                            placeHolder="Lütfen açıklama girin..."
                                            onChange={(editorState) =>
                                                this.handleEditorChange(
                                                    editorState,
                                                    draftToHtml(
                                                        convertToRaw(editorState.getCurrentContent())
                                                    )
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <ImageUploadMulti
                                            images={this.state.customerEducationAnnouncementFilesDto}
                                            noFoto={noImg}
                                            style={fotoStyle}
                                            pdfIcon={pdfIcon}
                                            docIcon={docIcon}
                                            excelIcon={excelIcon}
                                            accept="application/pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png"
                                            className="text-center text-danger"
                                            label={"Dosyalar"}
                                            onChange={this.uploadedImage}
                                            onDelete={this.deleteImage}
                                            error={this.state.customerEducationAnnouncementFilesDtoError}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <CgnReactSelect
                                            name="status"
                                            label="Duyuru Tipi"
                                            isMulti={false}
                                            selectValue={this.state.announcementType}
                                            options={announcementType}
                                            onChange={(announcementType) =>
                                                this.handleAnnouncementTypeSelectChange(announcementType)
                                            }
                                        />
                                    </Col>
                                </Row>
                                {this.state.announcementType &&
                                    <Row>
                                        <Col lg="4">
                                            <CgnDatePicker
                                                name="sendDateTime"
                                                label="Planlı Gönderim Tarihi [*]"
                                                selected={
                                                    this.state.sendDateTime &&
                                                        this.state.sendDateTime.toString().indexOf("/") > 0
                                                        ? new Date(
                                                            this.state.sendDateTime.split("/")[2] +
                                                            "-" +
                                                            this.state.sendDateTime.split("/")[1] +
                                                            "-" +
                                                            this.state.sendDateTime.split("/")[0]
                                                        )
                                                        : this.state.sendDateTime
                                                }
                                                onChange={(sendDateTime) =>
                                                    this.handleSendDateTimeChange(sendDateTime)
                                                }
                                                error={this.state.sendDateTimeError}
                                                maxDate={new Date(2100, 1, 1)}
                                                minDate={new Date(1900, 1, 1)}
                                                placeholder="GG/AA/YYYY"
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col lg="8">
                                            <CgnTextbox
                                                type="text"
                                                name="eMails"
                                                label="E-Mailler"
                                                value={this.state.eMails}
                                                placeHolder="Lütfen e-mail girin... (Lütfen e-mailleri birbirinden ayırmak için , kullanın! Örn; deneme@deneme.com, deneme2@deneme.com)"
                                                autoComplete="off"
                                                onChange={this.handleChange}
                                                error={this.state.eMailsError}
                                            />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col lg="12">
                                        <CgnReactSelect
                                            name="status"
                                            label="Durumu"
                                            isMulti={false}
                                            selectValue={this.state.status}
                                            options={optionStatuses}
                                            onChange={(status) =>
                                                this.handleStatusSelectChange(status)
                                            }
                                        />
                                    </Col>
                                </Row>
                                {this.state.alertErrorMessage && (
                                    <CgnAlert
                                        color="danger"
                                        title={CgnMessage.alertErrorTitle}
                                        text={this.state.alertErrorMessage}
                                    />
                                )}
                                <CgnButtonGroup items={this.state.buttons} />
                            </Card>
                        </Form>
                        {(this.state.urlSplit[1] === "musteri-egitim-duyuru-ekle" ||
                            this.state.urlSplit[1] === "musteri-egitim-duyuru-guncelle") && (
                                <CgnButtonLink
                                    color="secondary"
                                    to={"/" + this.state.pageListLink}
                                    text={CgnMessage.backButton}
                                    className="btn-back"
                                />
                            )}
                    </Col>
                </Row>
            </>
        );
    }
}
export default withRouter(CustomerEducationAnnouncementAddUpdate);