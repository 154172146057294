import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Button } from "reactstrap";
import { URL } from "../../redux/actions/constants";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnMessage from "../toolbox/CgnMessage";
import grafik01 from "../../images/grafik-01.jpg";
import grafik05 from "../../images/vawe2.PNG";
import grafik06 from "../../images/vawe3.PNG";
import grafik07 from "../../images/vawe4.PNG";
import grafik08 from "../../images/dashboard.png";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import { PieChart } from "react-minimal-pie-chart";
import { FaCircle } from 'react-icons/fa';
import CalendarList from "../calendar/CalendarList";

class HomePage extends Component {
  state = {
    pageTitle: "Ana Sayfa",
    breadcrumb: [{ text: "Ana Sayfa", link: "#" }],
    isLoading: false,
    reportData: [],
    firstDate: "",
    lastDate: "",
    firstDate2: "",
    lastDate2: "",
    userGuid: "null",
    userArr: [],
    userError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: "Filtrele",
        disabled: this.isLoading,
        className: "mr-0",
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    const currentDate = new Date();
    const getMonth = currentDate.getMonth() + 1;
    const getFullYear = currentDate.getFullYear();
    var x = 0; // ay 1-2-3... diye gidiyor. 01-02-03 gitsin diye.
    var y = 0;
    var day = 0;
    if (getMonth > 9) {
      // ay 10-11-12 olduğunda sıkıntı olmasın diye.
      x = "";
      y = "";
    }
    if (
      getMonth === 1 ||
      getMonth === 3 ||
      getMonth === 5 ||
      getMonth === 7 ||
      getMonth === 8 ||
      getMonth === 10 ||
      getMonth === 12
    ) {
      day = 31;
    }
    if (getMonth === 3 || getMonth === 6 || getMonth === 9 || getMonth === 11) {
      day = 30;
    }
    if (
      getMonth === 2 &&
      (getFullYear === 2016 ||
        getFullYear === 2020 ||
        getFullYear === 2024 ||
        getFullYear === 2028)
    ) {
      day = 29; //max
    } else if (getMonth === 2) {
      day = 28; //max
    }
    var currentFirstDate = `${x}${getMonth}.01.${getFullYear}`;
    var currentLastDate = `${y}${getMonth}.${day}.${getFullYear}`;

    await this.setState({
      firstDate: currentFirstDate,
      lastDate: currentLastDate,
      firstDate2: currentFirstDate,
      lastDate2: currentLastDate,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/users/getlistuser", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          userArr: myArr,
        });
        this.updateData();
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
  }

  handleUserSelectChange(option) {
    if (option) {
      this.setState({
        userGuid: option.value,
        userError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        userGuid: "null",
      });
    }
  }

  async updateData() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(
        URL +
          "/homepages/getlist/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate +
          "/" +
          this.state.userGuid,
        config
      )
      .then((response) => {
        this.setState({
          reportData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  render() {

    const shiftSize = 1;

    const defaultLabelStyle = {
      fontSize: '9px',
      color: "#fff",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row className="report" id="list">
          <Col>
            <Card body>
              <Row>
                <Col lg="3">
                  <CgnDatePicker
                    name="firstDate2"
                    label="Başlangıç Tarihi [*]"
                    selected={this.state.firstDate2}
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    error={this.state.firstDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col lg="3">
                  <CgnDatePicker
                    name="lastDate2"
                    label="Bitiş Tarihi [*]"
                    selected={this.state.lastDate2}
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    error={this.state.lastDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col lg="5">
                  <CgnReactSelect
                    name="userGuid"
                    label="Kullanıcı [*]"
                    placeholder="Kullanıcı seçiniz..."
                    selectValue={this.state.userGuid}
                    isMulti={false}
                    isClearable={true}
                    disabled={this.state.isTrue}
                    onChange={(option) => this.handleUserSelectChange(option)}
                    options={this.state.userArr}
                    error={this.state.userError}
                  />
                </Col>
                <Col lg="1">
                  <Button
                    className="d-block w-100 mt-2 btnReport"
                    onClick={() => this.updateData()}
                  >
                    Listele
                  </Button>
                </Col>
              </Row>

              <Row>
                <CgnLoader visibled={this.state.isLoading} />
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
              </Row>

              {this.state.reportData !== null && (
                <>
                  <Row className="mt-3">
                    <Col lg="4">
                      <div className="reportCircle2 reportSuccess">
                        <h5 className="reportTitle2 reportTitleGreen2">
                          İŞ ATAMA
                        </h5>
                        <div className="reportTotal2">
                          Toplam {this.state.reportData.totalAssignment}
                        </div>
                        <div className="reportTotal2">
                          Bekleyen {this.state.reportData.waitingAssignment}
                        </div>
                        <img src={grafik08} alt="x" className="grafik04" />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="reportCircle3 reportSuccess">
                        <h5 className="reportTitle2 reportTitleGreen2">
                          GÖREV ATAMA
                        </h5>
                        <div className="reportTotal2">
                          Toplam {this.state.reportData.totalTask}
                        </div>
                        <div className="reportTotal2">
                          Bekleyen {this.state.reportData.waitingTask}
                        </div>
                        <img src={grafik08} alt="x" className="grafik04" />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="reportCircle4 reportSuccess">
                        <h5 className="reportTitle2 reportTitleGreen2">
                          SERVİS
                        </h5>
                        <div className="reportTotal2">
                          Toplam {this.state.reportData.totalService}
                        </div>
                        <div className="reportTotal2">
                          Bekleyen {this.state.reportData.waitingService}
                        </div>
                        <img src={grafik08} alt="x" className="grafik04" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <div className="reportCircle5 reportSuccess">
                        <h5 className="reportTitle2 reportTitleGreen2">
                          TEKLİF
                        </h5>
                        <div className="reportTotal2">
                          Toplam {this.state.reportData.totalOffer}
                        </div>
                        <div className="reportTotal2">
                          Bekleyen {this.state.reportData.waitingOffer}
                        </div>
                        <img src={grafik08} alt="x" className="grafik04" />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="reportCircle6 reportSuccess">
                        <h5 className="reportTitle2 reportTitleGreen2">
                          SİPARİŞ
                        </h5>
                        <div className="reportTotal2">
                          Toplam {this.state.reportData.totalOrder}
                        </div>
                        <div className="reportTotal2">
                          Bekleyen {this.state.reportData.waitingOrder}
                        </div>
                        <img src={grafik08} alt="x" className="grafik04" />
                      </div>
                    </Col>        
                    <Col lg="4">
                      <div className="reportCircle7 reportSuccess">
                        <h5 className="reportTitle2 reportTitleGreen2">
                          MÜŞTERİ
                        </h5>
                        <div className="reportTotal2">
                          Toplam {this.state.reportData.totalCustomer}
                        </div>
                        <img src={grafik08} alt="x" className="grafik04" style={{bottom: "-27px"}}/>
                      </div>
                    </Col>                            
                  </Row>
                  <Row className="mt-5">          

                    <Col lg="6">
                      <div className="container borderRow">
                      <div className="row mt-4">
                        <div className="col-lg-6">
                        <h5 className="reportTitle2 reportTitleGreen3">
                      GELİR
                        </h5>
                        </div>
                     <div className="col-lg-6">
                     <div className="reportTotal3">
                           {parseFloat(
                            this.state.reportData.incomeTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                     </div>
                        
                      </div>
                    <div className="row mt-3">
                      <div className="offset-4"></div>
                      <div className="col-lg-4 m-auto">
                    <PieChart className="pieChart m-auto"
                      data={[
                        { title: "One", value: (this.state.reportData.incomeCollection !== 0 ? this.state.reportData.incomeCollection : 1), color: "#EB9183", lineWidth: "20%", animate: true},
                        { title: "Two", value: (this.state.reportData.incomeCollectionNot !== 0 ? this.state.reportData.incomeCollectionNot : 1), color: "#EA120C", lineWidth: "20%", animate: true},
                      ]}
                      label={({ x, y, dx, dy, dataEntry }) => (
                        <text
                          x={x}
                          y={y}
                          dx={dx}
                          dy={dy}
                          dominant-baseline="central"
                          text-anchor="middle"
                          style={{
                            defaultLabelStyle
                          }}
                        >
                          {Math.round(dataEntry.percentage) + '%'}
                        </text>
                        )}
                        radius={PieChart.defaultProps.radius - shiftSize}
                        segmentsShift={(index) => (index === 0 ? shiftSize : 0.5)}
                    />
                    </div>
                    <div className="offset-4"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-12">
                        <div className="firstCircle4">                        
                     <p> <FaCircle className="pr-2"/>   
                        Tahsilatı Yapılan</p>                 
                        </div>
                        <div className="firstCircle5">                        
                     <p> <FaCircle className="pr-2"/>  
                        Yapılmayan</p>                 
                        </div>   
                        </div>
                      </div>  
                      </div>                                
                    </Col>
                  <Col lg="6">
                  <div className="container borderRow">
                      <div className="row mt-4">
                        <div className="col-lg-6">
                        <h5 className="reportTitle2 reportTitleGreen3">
                      GİDER
                        </h5>
                        </div>
                     <div className="col-lg-6">
                     <div className="reportTotal3">
                           {parseFloat(
                            this.state.reportData.costTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                     </div>
                        
                      </div>
                    <div className="row mt-3">
                      <div className="offset-4"></div>
                      <div className="col-lg-4 m-auto">
                    <PieChart className="pieChart m-auto"
                      data={[
                        { title: "One", value: (this.state.reportData.costPayment !== 0 ? this.state.reportData.costPayment : 1), color: "#83EBAA", lineWidth: "20%", animate: true},
                        { title: "Two", value: (this.state.reportData.costPaymentNot !== 0 ? this.state.reportData.costPaymentNot : 1), color: "#298E4F", lineWidth: "20%", animate: true  },
                      ]}
                      label={({ x, y, dx, dy, dataEntry }) => (
                        <text
                          x={x}
                          y={y}
                          dx={dx}
                          dy={dy}
                          dominant-baseline="central"
                          text-anchor="middle"
                          style={{
                            defaultLabelStyle
                          }}
                        >
                          {Math.round(dataEntry.percentage) + '%'}
                        </text>
                        )}
                        radius={PieChart.defaultProps.radius - shiftSize}
                        segmentsShift={(index) => (index === 0 ? shiftSize : 0.5)}
                    />
                    </div>
                    <div className="offset-4"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-12">
                        <div className="firstCircle6">                        
                     <p> <FaCircle className="pr-2"/>   
                        Ödemesi Yapılan</p>                 
                        </div>
                        <div className="firstCircle7">                        
                     <p> <FaCircle className="pr-2"/> 
                        Yapılmayan</p>                 
                        </div>   
                        </div>
                      </div>  
                      </div> 
                    
                    </Col>
                   </Row>
                   <Row className="mt-5 mb-5">
                    <Col lg="6">
                    <div className="container borderRow">
                      <div className="row mt-4">
                        <div className="col-lg-6">
                        <h5 className="reportTitle2 reportTitleGreen3">
                      TEKLİF
                        </h5>
                        </div>
                     <div className="col-lg-6">
                     <div className="reportTotal3">
                           {parseFloat(
                            this.state.reportData.offerTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                     </div>
                        
                      </div>
                    <div className="row mt-3">
                      <div className="offset-4"></div>
                      <div className="col-lg-4 m-auto">
                    <PieChart className="pieChart m-auto"
                      data={[
                        { title: "Bekleyen", value: (this.state.reportData.waitingOffer !== 0 ? this.state.reportData.waitingOffer : 1), color: "#59BAD2", lineWidth: "20%", animate: true },
                        { title: "Tamamlayan", value: (this.state.reportData.completedOffer !== 0 ? this.state.reportData.completedOffer : 1), color: "#0B7F9B", lineWidth: "20%", animate: true  },
                        { title: "İptal", value: (this.state.reportData.cancelOffer !== 0 ? this.state.reportData.cancelOffer : 1), color: "#114677", lineWidth: "20%", animate: true  },
                      ]}
                      label={({ x, y, dx, dy, dataEntry }) => (
                        <text
                          x={x}
                          y={y}
                          dx={dx}
                          dy={dy}
                          dominant-baseline="central"
                          text-anchor="middle"
                          style={{
                            defaultLabelStyle
                          }}
                        >
                          {Math.round(dataEntry.percentage) + '%'}
                        </text>
                        )}
                        radius={PieChart.defaultProps.radius - shiftSize}
                        segmentsShift={(index) => (index === 0 ? shiftSize : 0.5)}
                    />
                    </div>
                    <div className="offset-4"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-12">
                      <div className="firstCircle">                        
                     <p> <FaCircle className="pr-2"/>   
                        Bekleyen</p>                 
                        </div>
                        <div className="firstCircle2">                        
                     <p> <FaCircle className="pr-2"/>   
                        Tamamlanan</p>                 
                        </div>
                        <div className="firstCircle3">                        
                     <p> <FaCircle className="pr-2"/>   
                        İptal</p>                 
                        </div>
                    </div>
                      </div>  
                      </div> 
                    </Col>
                    <Col lg="6">
                    <div className="container borderRow">
                      <div className="row mt-4">
                        <div className="col-lg-6">
                        <h5 className="reportTitle2 reportTitleGreen3">
                      SİPARİŞ
                        </h5>
                        </div>
                     <div className="col-lg-6">
                     <div className="reportTotal3">
                           {parseFloat(
                            this.state.reportData.orderTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                     </div>
                        
                      </div>
                    <div className="row mt-3">
                      <div className="offset-4"></div>
                      <div className="col-lg-4 m-auto">
                    <PieChart className="pieChart m-auto"
data={[
  { title: "Bekleyen", value: (this.state.reportData.waitingOrder !== 0 ? this.state.reportData.waitingOrder : 1), color: "#5B4E9D", lineWidth: "20%", animate: true },
  { title: "Tamamlayan", value: (this.state.reportData.completedOrder !== 0 ? this.state.reportData.completedOrder : 1), color: "#7A69CE", lineWidth: "20%", animate: true  },
  { title: "İptal", value: (this.state.reportData.cancelOrder !== 0 ? this.state.reportData.cancelOrder : 1), color: "#ACA2DE", lineWidth: "20%", animate: true  },
]}
                      label={({ x, y, dx, dy, dataEntry }) => (
                        <text
                          x={x}
                          y={y}
                          dx={dx}
                          dy={dy}
                          dominant-baseline="central"
                          text-anchor="middle"
                          style={{
                            defaultLabelStyle
                          }}
                        >
                          {Math.round(dataEntry.percentage) + '%'}
                        </text>
                        )}
                        radius={PieChart.defaultProps.radius - shiftSize}
                        segmentsShift={(index) => (index === 0 ? shiftSize : 0.5)}
                    />
                    </div>
                    <div className="offset-4"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-12">
                      <div className="firstCircle8">                        
                     <p> <FaCircle className="pr-2"/>   
                        Bekleyen</p>                 
                        </div>
                        <div className="firstCircle9">                        
                     <p> <FaCircle className="pr-2"/>   
                        Tamamlanan</p>                 
                        </div>
                        <div className="firstCircle10">                        
                     <p> <FaCircle className="pr-2"/>   
                        İptal</p>                 
                        </div>
                    </div>
                    </div>
                      </div> 
                    </Col>
                  </Row>
                </>
              )}
                     <CalendarList/>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
