import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Form } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  fotoStyle,
  localFilePath,
  resizeFile,
  errorReasons,
  detectionPhases,
  decisions,
  dfDescriptions,
  fotoStyle2
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButton from "../toolbox/CgnButton";
import ImageUpload from "../toolbox/CgnImageUpload";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import { th } from "date-fns/locale";

class QualityDetail extends Component {
  state = {
    pageTitle: "Kalite Detay",
    pageTitleDetail: "Kalite Detay",
    pageListLink: "kalite-havuzu",
    breadcrumb: [{ text: "Kalite", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    workDate: "",
    workStartDate: "",
    workEndDate: "",
    productGuid: "",
    customerGuid: "",
    personelGuid: "",
    productOutGuid: "",
    employmentDate: "",
    qualityPersonelGuid: "",
    errorCodeGuid: "",
    detectionPhase: "",
    errorDescription: "",
    errorReason: "",
    errorPiece: null,
    transactions: "",
    decision: "",
    dfDescription: "",
    improprietyNo: "",
    name: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    decsription: "",
    detectionPhaseError: "",
    currencyType: "",
    qualityImageDto: [],
    urlSplit: [],
    isHiddenWork: true,
    errorCodeDefinition: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    selectedQualityPersonelGuid: "",
    selectedQualityPersonelGuidError: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/workInstructions/getliststatus/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.customerName + "/" + item.productName,
            };
          });
          this.setState({
            workInstructionArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      axios
        .get(URL + "/workOrderSteps/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              workOrderStepImageDto: item.workOrderStepImageDto
            };
          });
          this.setState({
            stepArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            productArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      axios
        .get(URL + "/productOutsSecond/getlistforworkorder", config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.productOutDate}-${item.customerName}`,
            };
          });
          this.setState({
            productOutArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      axios
        .get(URL + "/errorCodes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              errorCodeDefinition: item.errorCodeDefinition
            };
          });
          this.setState({
            errorCodeArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/members/getmembers/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.firstName} ${item.lastName}`,
            };
          });
          this.setState({
            personelArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/members/getmembers/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.firstName} ${item.lastName}`,
            };
          });
          this.setState({
            qualityPersonelArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      axios
        .get(URL + "/qualities/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            workInstructionGuid: response.data.workInstructionGuid,
            workInstructionCode: response.data.workInstructionCode,
            workInstructionStepGuid: response.data.workInstructionStepGuid,
            errorReason: response.data.errorReason,
            errorCodeGuid: response.data.errorCodeGuid,
            customerGuid: response.data.customerGuid,
            personelGuid: response.data.personelGuid,
            productGuid: response.data.productGuid,
            productOutGuid: response.data.productOutGuid,
            errorPiece: response.data.errorPiece,
            workDate: response.data.workDate,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            currencyType: response.data.currencyType,
            employmentDate: response.data.employmentDate,
            qualityPersonelGuid: response.data.qualityPersonelGuid,
            selectedQualityPersonelGuid: response.data.qualityPersonelGuid ? response.data.qualityPersonelGuid : localStorage.getItem("membershipGuid"),
            detectionPhase: response.data.detectionPhase,
            errorDescription: response.data.errorDescription,
            image: response.data.image,
            transactions: response.data.transactions,
            decision: response.data.decision,
            dfDescription: response.data.dfDescription,
            improprietyNo: response.data.improprietyNo,
            isHiddenWork: response.data.qualityPersonelGuid !== null ? false : true,
            qualityImageDto: response.data.qualityImageDto,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  async handleWorkInstructionSelectChange(option) {
    if (option) {
      await this.setState({
        workInstructionGuid: option.value,
      });
    } else {
      this.setState({
        workInstructionGuid: "",
      });
    }
  }

  async handleDetectionPhaseSelectChange(option) {
    if (option) {
      await this.setState({
        detectionPhase: option.value,
        detectionPhaseError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        detectionPhase: "",
        detectionPhaseError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleErrorCodeSelectChange(option) {
    if (option) {
      await this.setState({
        errorCodeGuid: option.value,
        errorDescription: option.errorCodeDefinition,
      });
    } else {
      this.setState({
        errorCodeGuid: "",
        errorDescription: "",
      });
    }
  }

  async handleErrorReasonSelectChange(option) {
    if (option) {
      await this.setState({
        errorReason: option.value,
      });
    } else {
      this.setState({
        errorReason: "",
      });
    }
  }

  handleErrorPieceTextBoxChange(e) {
    this.setState({
      errorPiece: e.target.value,
      errorPieceError: "",
      alertErrorMessage: "",
    });
  }

  async handleDecisionSelectChange(option) {
    if (option) {
      await this.setState({
        decision: option.value,
        decisionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        decision: "",
        decisionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleDfDescriptionSelectChange(option) {
    if (option) {
      await this.setState({
        dfDescription: option.value,
      });
    } else {
      this.setState({
        dfDescription: "",
      });
    }
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath, "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    if (file.type === "image/png" || file.type === "image/jpeg") {
      const image = await resizeFile(file, 1000);
      this.setState({
        image: image,
        imageName: file.name,
      });
    }
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  uploadedImage2 = async (event) => {
    let array = this.state.qualityImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "application/pdf") {
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
              image: response.data,
              imageName: file.name
            })
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({ qualityImageDto: array });
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.qualityImageDto;
    this.state.qualityImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ qualityImageDto: array });
        }
      }
    });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
    });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  async handleUpdateQualityPersonel() {
    await this.setState({
      isLoading: true
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "/qualities/updatequalitypersonel";
    var myObj = {
      guid: this.state.guid,
      qualityPersonelGuid: this.state.selectedQualityPersonelGuid,
    };
    axios
      .post(URL + URLParam, myObj, config)
      .then(() => {
        setTimeout(() => {
          window.location.href = "/kalite-havuzu";
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      detectionPhaseError: "",
      decisionError: "",
    });
    if (this.state.detectionPhase === "") {
      this.setState({
        detectionPhaseError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.detectionPhase !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      if (
        this.state.guid
      ) {
        var URLParam = "/qualities/update";
        var myObj = {
          guid: this.state.guid,
          detectionPhase: parseInt(this.state.detectionPhase),
          errorCodeGuid: this.state.errorCodeGuid === "" ? null : this.state.errorCodeGuid,
          errorDescription: this.state.errorDescription === "" ? null : this.state.errorDescription,
          image: this.state.image,
          errorPiece: parseFloat(this.state.errorPiece),
          errorReason: parseInt(this.state.errorReason),
          transactions: this.state.transactions,
          decision: parseInt(this.state.decision),
          dfDescription: parseInt(this.state.dfDescription),
          improprietyNo: this.state.improprietyNo === "" ? null : this.state.improprietyNo,
          qualityImageDto: this.state.qualityImageDto,
          status: true,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid
            ) {
              window.location.href = "/kalite-havuzu";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  async handlePersonelSelectChange(option) {
    if (option) {
      await this.setState({
        selectedQualityPersonelGuid: option.value,
        selectedQualityPersonelGuidError: "",
        alertModalErrorMessage: "",
      });
    } else {
      await this.setState({
        selectedQualityPersonelGuid: 0,
        selectedQualityPersonelGuidError: CgnMessage.textErrorMessage,
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          //addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="4" style={{ display: this.state.qualityPersonelGuid === null ? "" : "none" }}>
                    <CgnButton
                      type="button"
                      color="danger"
                      className="mb-3"
                      onClick={() => this.handleUpdateQualityPersonel()}
                      text="İşi Seçili Personele Ata"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3"
                    className="pl-0"
                  >
                    <CgnReactSelect
                      name="selectedQualityPersonelGuid"
                      label="Kalite Personeli [*]"
                      isMulti={false}
                      selectValue={this.state.selectedQualityPersonelGuid}
                      placeholder="Personel seçiniz..."
                      options={this.state.qualityPersonelArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePersonelSelectChange(option)
                      }
                      error={this.state.personelError}
                      disabled={!this.state.isHiddenWork}
                    />
                  </Col>
                  <Col
                    lg="3"
                    style={{
                      display: this.state.isHiddenWork === true ? "none" : "",
                    }}
                  >
                    <CgnDatePicker
                      name="employmentDate"
                      label="İşe Alınma Tarihi"
                      selected={
                        this.state.employmentDate &&
                          this.state.employmentDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.employmentDate.split("/")[2] +
                            "-" +
                            this.state.employmentDate.split("/")[1] +
                            "-" +
                            this.state.employmentDate.split("/")[0]
                          )
                          : this.state.employmentDate
                      }
                      onChange={(employmentDate) =>
                        this.handleEntryDateChange(employmentDate)
                      }
                      error={this.state.entryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      isMulti={false}
                      selectValue={this.state.workInstructionGuid}
                      placeholder="İş Emri seçiniz..."
                      options={this.state.workInstructionArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionSelectChange(option)
                      }
                      error={this.state.workInstructionError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="workInstructionCode"
                      label="İş Emri No [*]"
                      value={this.state.workInstructionCode}
                      placeHolder="Lütfen iş emri no girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.workInstructionCodeError}
                      readOnly={true}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="workDate"
                      label="İş Emri Tarihi [*]"
                      selected={
                        this.state.workDate &&
                          this.state.workDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.workDate.split("/")[2] +
                            "-" +
                            this.state.workDate.split("/")[1] +
                            "-" +
                            this.state.workDate.split("/")[0]
                          )
                          : this.state.workDate
                      }
                      onChange={(workDate) =>
                        this.handleWorkDateChange(workDate)
                      }
                      error={this.state.workDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                      disabled={true}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="workInstructionStepGuid"
                      label="İş Emri Adımı [*]"
                      isMulti={false}
                      selectValue={this.state.workInstructionStepGuid}
                      placeholder="İş Emri seçiniz..."
                      options={this.state.stepArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionSelectChange(option)
                      }
                      error={this.state.workInstructionError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      isMulti={false}
                      selectValue={this.state.customerGuid}
                      placeholder="Müşteri seçiniz..."
                      options={this.state.customerArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="personelGuid"
                      label="Personel [*]"
                      isMulti={false}
                      selectValue={this.state.personelGuid}
                      placeholder="Personel seçiniz..."
                      options={this.state.personelArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePersonelSelectChange(option)
                      }
                      error={this.state.personelError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      isMulti={false}
                      selectValue={this.state.productOutGuid}
                      placeholder="Satış seçiniz..."
                      options={this.state.productOutArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductOutSelectChange(option)
                      }
                      error={this.state.productOutError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productGuid"
                      label="Ürün [*]"
                      isMulti={false}
                      selectValue={this.state.productGuid}
                      placeholder="Ürün seçiniz..."
                      options={this.state.productArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      error={this.state.productError}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row style={{ display: this.state.isHiddenWork === true ? "none" : "" }}>
                  <Col lg="6">
                    <CgnReactSelect
                      name="detectionPhase"
                      label="Tespit Aşaması [*]"
                      isMulti={false}
                      selectValue={this.state.detectionPhase}
                      placeholder="Tespit aşaması seçiniz..."
                      options={detectionPhases}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleDetectionPhaseSelectChange(option)
                      }
                      error={this.state.detectionPhaseError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="errorCodeGuid"
                      label="Hata Kodu"
                      isMulti={false}
                      selectValue={this.state.errorCodeGuid}
                      placeholder="Hata kodu seçiniz..."
                      options={this.state.errorCodeArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleErrorCodeSelectChange(option)
                      }
                      error={this.state.errorCodeError}
                    />
                  </Col>
                </Row>

                <Row style={{ display: this.state.isHiddenWork === true ? "none" : "" }}>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="errorDescription"
                      label="Hata Tanımı"
                      value={this.state.errorDescription}
                      placeHolder="Lütfen hata tanımı girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      readOnly={true}
                    />
                  </Col>
                </Row>

                <Row style={{ display: this.state.isHiddenWork === true ? "none" : "" }}>
                  <Col lg="4">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Hata Fotoğrafı"
                      image={this.state.image}
                      noFoto={noImg}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      error={this.state.imageError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="errorReason"
                      label="Hata Nedeni"
                      isMulti={false}
                      selectValue={this.state.errorReason}
                      placeholder="Hata nedeni seçiniz..."
                      options={errorReasons}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleErrorReasonSelectChange(option)
                      }
                      error={this.state.detectionPhaseError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="errorPiece"
                      label="Hata Adet"
                      value={this.state.errorPiece}
                      placeHolder="Lütfen hata adedi girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={(e) => this.handleErrorPieceTextBoxChange(e)}
                      error={this.state.errorPieceError}
                    />
                  </Col>
                </Row>

                <Row style={{ display: this.state.isHiddenWork === true ? "none" : "" }}>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="transactions"
                      label="Yapılan İşlemler"
                      value={this.state.transactions}
                      placeHolder="Lütfen yapılan işlemler girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                <Row style={{ display: this.state.isHiddenWork === true ? "none" : "" }}>
                  <Col lg="4">
                    <CgnReactSelect
                      name="decision"
                      label="Karar"
                      isMulti={false}
                      selectValue={this.state.decision}
                      placeholder="Karar seçiniz..."
                      options={decisions}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleDecisionSelectChange(option)
                      }
                      error={this.state.decisionError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="dfDescription"
                      label="DF Açılması"
                      isMulti={false}
                      selectValue={this.state.dfDescription}
                      placeholder="DF açılması seçiniz..."
                      options={dfDescriptions}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleDfDescriptionSelectChange(option)
                      }
                      error={this.state.dfDescriptionError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="improprietyNo"
                      label="Uygunsuzluk Sıra No"
                      value={this.state.improprietyNo}
                      placeHolder="Uygunsuzluk sıra no girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                <Row style={{ display: this.state.isHiddenWork === true ? "none" : "" }}>
                  <Col lg="12">
                    <ImageUploadMulti
                      images={this.state.qualityImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Test Cihazı Raporu"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.imageError2}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                {this.state.qualityPersonelGuid && (
                  <CgnButtonGroup items={this.state.buttons} />
                )}


                <Row>
                  {this.state.qualityImageDto &&
                    this.state.qualityImageDto.length > 0 && (
                      <Table className="table table-responsive table-hover table-product2">
                        <tr style={mainRow}>
                          <th>Test Cihazı Raporları</th>
                        </tr>
                        <Row>
                          {this.state.qualityImageDto.map(function (item) {
                            return (
                              <>
                                <div className="pl-2">
                                  <Button
                                    onClick={() => self1.fileClick(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={pdfIcon}
                                      style={fotoStyle2}
                                      alt={item.imageName}
                                    />
                                  </Button>
                                  <p style={{ maxWidth: "150px" }}>
                                    {item.imageName}
                                  </p>
                                </div>
                              </>
                            );
                          })}
                        </Row>
                      </Table>
                    )}
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

export default QualityDetail;
