import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  IsItTrue,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnButton from "../toolbox/CgnButton";

class CustomerEducationExamAddUpdate extends Component {
  state = {
    pageTitle: "Sınav Ekle",
    pageTitleAdd: "Sınav Ekle",
    pageTitleUpdate: "Sınav Güncelle",
    pageAddLink: "musteri-egitim-sinav-ekle",
    pageListLink: "musteri-egitim-sinav-listele",
    breadcrumb: [{ text: "Sınavlar", link: "#" }],
    isLoading: false,
    urlSplit: [],
    guid: "",
    question: "",
    questionError: "",
    customerEducationExamAnswersDtoList: [],
    status: true,
    alertErrorMessage: "",
    customerArr: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/customerEducationExamQuestions/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              question: response.data.question,
              customerEducationExamAnswersDtoList:
                response.data.customerEducationExamAnswersDtoList,
              status: response.data.status,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      questionError: "",
      alertErrorMessage: "",
    });
    if (name === "question" && value === "") {
      this.setState({
        questionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleStatusSelectChange(name, status) {
    if (status != null && status.value !== "") {
      this.setState({
        [name]: status.value,
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  }

  handleTextBoxChangeSubCategory(e, idx) {
    let customerEducationExamAnswersDtoList =
      this.state.customerEducationExamAnswersDtoList;
    if (e.target.value && e.target.value !== "") {
      customerEducationExamAnswersDtoList[idx].reply = e.target.value;
      customerEducationExamAnswersDtoList[idx].replyError = "";
      this.setState({
        customerEducationExamAnswersDtoList:
          customerEducationExamAnswersDtoList,
      });
    } else {
      customerEducationExamAnswersDtoList[idx].reply = "";
      customerEducationExamAnswersDtoList[idx].replyError =
        CgnMessage.textErrorMessage;
      this.setState({
        customerEducationExamAnswersDtoList:
          customerEducationExamAnswersDtoList,
      });
    }
  }

  handleSelectChangeSubCategory(option, idx) {
    let customerEducationExamAnswersDtoList =
      this.state.customerEducationExamAnswersDtoList;
    if (option) {
      customerEducationExamAnswersDtoList[idx].isItTrue = option.value;
      customerEducationExamAnswersDtoList[idx].isItTrueError = "";
      this.setState({
        customerEducationExamAnswersDtoList:
          customerEducationExamAnswersDtoList,
      });
    } else {
      customerEducationExamAnswersDtoList[idx].isItTrue = 0;
      customerEducationExamAnswersDtoList[idx].isItTrueError =
        CgnMessage.textErrorMessage;
      this.setState({
        customerEducationExamAnswersDtoList:
          customerEducationExamAnswersDtoList,
      });
    }
  }

  async handleRemoveSubCategory(idx) {
    let customerEducationExamAnswersDtoList = await this.state
      .customerEducationExamAnswersDtoList;
    await customerEducationExamAnswersDtoList.splice(idx, 1);
    await this.setState({
      customerEducationExamAnswersDtoList: customerEducationExamAnswersDtoList,
    });
  }

  handleAddSubCategory = async () => {
    let customerEducationExamAnswersDtoList =
      this.state.customerEducationExamAnswersDtoList;
    await customerEducationExamAnswersDtoList.push({
      reply: "",
      isItTrue: false,
      replyError: CgnMessage.textErrorMessage,
      isItTrueError: "",
    });
    this.setState({
      customerEducationExamAnswersDtoList: customerEducationExamAnswersDtoList,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      question: "",
      customerEducationExamAnswersDtoList: [],
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      questionError: CgnMessage.textErrorMessage,
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      questionError: "",
      alertErrorMessage: "",
    });

    if (this.state.question === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (
      this.state.customerEducationExamAnswersDtoList !== null &&
      this.state.customerEducationExamAnswersDtoList.length > 0
    ) {
      this.state.customerEducationExamAnswersDtoList.forEach((element) => {
        if (
          element.reply === "" ||
          element.isItTrue === 0
        ) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    } else if (
      this.state.customerEducationExamAnswersDtoList !== null &&
      this.state.customerEducationExamAnswersDtoList.length === 0
    ) {
      control = false;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else {
      control = false;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.question !== "" && control === true) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        question: this.state.question,
        customerEducationExamAnswersDtoList:
          this.state.customerEducationExamAnswersDtoList,
        status: this.state.status,
      };
      var URLParam = "/customerEducationExamQuestions/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/customerEducationExamQuestions/update";
        myObj = {
          guid: this.state.guid,
          question: this.state.question,
          customerEducationExamAnswersDtoList:
            this.state.customerEducationExamAnswersDtoList,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/musteri-egitim-sinav-listele";
            }
            if (this.state.urlSplit[1] === "musteri-egitim-sinav-ekle") {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "musteri-egitim-sinav-ekle" ||
          this.state.urlSplit[1] === "musteri-egitim-sinav-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="question"
                      label="Soru [*]"
                      value={this.state.question}
                      placeHolder="Lütfen soru girin..."
                      autoComplete="off"
                      maxLength="500"
                      onChange={this.handleChange}
                      error={this.state.questionError}
                    />
                  </Col>
                </Row>
                {this.state.customerEducationExamAnswersDtoList.map(
                  (item, idx) => (
                    <Row className="mobileBorder">
                      <Col lg="9">
                        <CgnTextbox
                          type="text"
                          name="reply"
                          label="Cevap [*]"
                          value={item.reply}
                          onChange={(e) =>
                            this.handleTextBoxChangeSubCategory(e, idx)
                          }
                          placeHolder="Lütfen cevap girin..."
                          maxLength="500"
                          autoComplete="off"
                          error={item.replyError}
                        />
                      </Col>

                      <Col lg="2">
                        <CgnReactSelect
                          name="isItTrue"
                          label="Doğru Mu [*]"
                          placeholder="Lütfen doğru mu seçiniz..."
                          selectValue={item.isItTrue}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSelectChangeSubCategory(option, idx)
                          }
                          options={IsItTrue}
                          error={item.isItTrueError}
                        />
                      </Col>

                      <Col lg="1" sm="3">
                        <CgnButton
                          type="button"
                          color="danger"
                          className="dynamicRowDeleteButton"
                          onClick={() => this.handleRemoveSubCategory(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  )
                )}
                <Row>
                  <Col className="pl-0">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton px-3 mb-3"
                      onClick={this.handleAddSubCategory}
                      text={"Cevap Ekle"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange("status", status)
                      }
                    />
                  </Col>
                  <Col lg="9"></Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "musteri-egitim-sinav-ekle" ||
              this.state.urlSplit[1] === "musteri-egitim-sinav-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(CustomerEducationExamAddUpdate);
