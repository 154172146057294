import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import "moment/locale/tr";
import CgnButton from "../toolbox/CgnButton";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import EmployeeAddUpdate from "../employee/EmployeeAddUpdate";

class PersonelCustomerAddUpdate extends Component {
  state = {
    pageTitle: "Personel Müşteri Ekle",
    pageTitleAdd: "Personel Müşteri Ekle",
    pageTitleUpdate: "Personel Müşteri Güncelle",
    pageAddLink: "personel-musteri-ekle",
    pageListLink: "personel-musteri-listele",
    breadcrumb: [{ text: "Personel Müşteri", link: "#" }],
    isLoading: false,
    personelArr: [],
    customerArr: [],
    urlSplit: [],
    guid: "",
    personel: "",
    customer: "",
    entryDate: null,
    endDate: null,
    status: true,
    nameError: "",
    personelGuid: null,
    customerGuid: null,
    isNull: false,
    isOpenModalSaleType: false,
    isOpenModalEmployee: false,
    isOpenModalCustomer: false,
    modalButtonIdx: 0,
    entryDateError: "",
    endDateError: "",
    personelNameError: "",
    customerNameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    personelModalPlus: false,
    customerModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      personelModalPlus: await UserOperationClaimControl(1020),
      customerModalPlus: await UserOperationClaimControl(1012),
    });

    var statusType = 2; 
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
            personelGuid: item.personelGuid,
          };
        });
        this.setState({
          personelArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            customerGuid: item.customerGuid,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
   
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
      isLoading: true,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
     await axios
        .get(URL + "/personelcustomers/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              personelName: response.data.personelName,
              personelGuid: response.data.personelGuid,
              customerGuid: response.data.customerGuid,
              entryDate: response.data.entryDate,
              endDate: response.data.endDate,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        personelGuid: null,
        customerGuid: null,
        endDate: null,
        entryDate: null,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalEmployee &&
      this.props.location.modalAddedEmployee !== undefined &&
      this.props.location.modalAddedEmployee.isModal !== undefined &&
      this.props.location.modalAddedEmployee.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/members/getmembers/" + 2, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.firstName} ${item.lastName}`,
            };
          });
          this.setState({
            personelArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedEmployee.data &&
              `${element.firstName} ${element.lastName}` ===
                this.props.location.modalAddedEmployee.data
            ) {
              this.setState({
                personelGuid: element.guid,
                personelNameError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedEmployee.isModal = true;
      this.setState({
        isOpenModalEmployee: false,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      personelGuid: null,
      customerGuid: null,
      endDate: "",
      entryDate: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      entryDateError: CgnMessage.textErrorMessage,
      endDateError: CgnMessage.textErrorMessage,
      personelNameError: CgnMessage.textErrorMessage,
      customerNameError: CgnMessage.textErrorMessage,
    });
  }

  addModalEmployee = () => {
    this.setState({
      isOpenModalEmployee: !this.state.isOpenModalEmployee,
    });
  };

  handlePersonelSelectChange(option) {
    if (option) {
      this.setState({
        personelGuid: option.value,
        personelNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        personelGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        personelNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        customerNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  handleEntryDateChange(entryDate) {
    if (entryDate) {
      this.setState({
        entryDate: entryDate,
        entryDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        entryDate: "",
        entryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleEndDateChange(endDate) {
    if (endDate) {
      this.setState({
        endDate: endDate,
        endDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        endDate: "",
        endDateError: "",
        alertErrorMessage: "",
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      entryDateError: "",
      endDateError: "",
      personelNameError: "",
      customerNameError: "",
    });
    if (this.state.personelGuid === null) {
      this.setState({
        personelNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === null) {
      this.setState({
        customerNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.entryDate === null) {
      this.setState({
        entryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.personelGuid !== null &&
      this.state.customerGuid !== null &&
      this.state.entryDate !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        personelGuid: this.state.personelGuid,
        customerGuid: this.state.customerGuid,
        entryDate: moment(this.state.entryDate).format("DD/MM/YYYY"),
        endDate: this.state.endDate
          ? moment(this.state.endDate).format("DD/MM/YYYY")
          : null,
        status: this.state.status,
      };
      var URLParam = "/personelCustomers/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/personelCustomers/update";
        myObj = {
          guid: this.state.guid,
          personelGuid: this.state.personelGuid,
          customerGuid: this.state.customerGuid,
          endDate: this.state.endDate
            ? moment(this.state.endDate).format("DD/MM/YYYY")
            : null,
          entryDate: this.state.entryDate,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then((response) => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/personel-musteri-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "personel-musteri-ekle" ||
          this.state.urlSplit[1] === "personel-musteri-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="personelGuid"
                          label="Personel [*]"
                          placeholder="Personel seçiniz..."
                          selectValue={this.state.personelGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePersonelSelectChange(option)
                          }
                          options={this.state.personelArr}
                          error={this.state.personelNameError}
                        />
                      </div>
                      {this.state.personelModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalEmployee()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerNameError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>                 
                </Row>
                <Row>             
                  <Col lg="6">
                    <CgnDatePicker
                      name="entryDate"
                      label="Giriş Tarihi [*]"
                      selected={
                        this.state.entryDate &&
                        this.state.entryDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.entryDate.split("/")[2] +
                                "-" +
                                this.state.entryDate.split("/")[1] +
                                "-" +
                                this.state.entryDate.split("/")[0]
                            )
                          : this.state.entryDate
                      }
                      onChange={(entryDate) =>
                        this.handleEntryDateChange(entryDate)
                      }
                      error={this.state.entryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>

                  <Col lg="6">
                    <CgnDatePicker
                      name="endDate"
                      label="Çıkış Tarihi"
                      selected={this.state.endDate}
                      onChange={(endDate) => this.handleEndDateChange(endDate)}
                      error={this.state.endDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="4" style={{display:"none"}}>
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "makine-musteri-ekle" ||
              this.state.urlSplit[1] === "makine-musteri-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalEmployee}
          toggle={this.addModalEmployee}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Personel Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <EmployeeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalEmployee}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
       
      </>
    );
  }
}

export default withRouter(PersonelCustomerAddUpdate);
