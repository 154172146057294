import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class PersonDetail extends Component {
  state = {
    pageTitle: "Kişi Detay",
    pageTitleDetail: "Kişi Detay",
    pageAddLink: "kisi-ekle",
    pageListLink: "kisi-listele",
    breadcrumb: [{ text: "Kişi", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    groupName: "",
    firstName: "",
    lastName: "",
    job: "",
    phone1: "",
    phone2: "",
    districtName: "",
    townName: "",
    cityName: "",
    countryName: "",
    zipCode: "",
    email: "",
    address: "",
    description: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/persons/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            groupName: response.data.groupName,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            job: response.data.job,
            phone1: response.data.phone1,
            phone2: response.data.phone2,
            email: response.data.email,
            address: response.data.address,
            countryName: response.data.countryName,
            cityName: response.data.cityName,
            townName: response.data.townName,
            districtName: response.data.districtName,
            zipCode: response.data.zipCode,
            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Grup</th>
                    <td colSpan="4">
                      {this.state.groupName ? this.state.groupName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ad Soyad</th>
                    <td colSpan="4">
                      {`${this.state.firstName} ${this.state.lastName}`}
                    </td>
                  </tr>

                  <tr>
                    <th>Meslek</th>
                    <td colSpan="4">{this.state.job ? this.state.job : "-"}</td>
                  </tr>

                  <tr>
                    <th>Telefon</th>
                    <td colSpan="4">
                      {this.state.phone1 ? this.state.phone1 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>GSM</th>
                    <td colSpan="4">
                      {this.state.phone2 ? this.state.phone2 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>E-Mail</th>
                    <td colSpan="4">
                      {this.state.email ? this.state.email : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Adres</th>
                    <td colSpan="4">
                      {this.state.address ? this.state.address : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Mahalle/İlçe/Şehir/Ülke</th>
                    <td colSpan="4">
                      {`${
                        this.state.districtName
                          ? this.state.districtName + "/"
                          : ""
                      }${this.state.townName ? this.state.townName + "/" : ""}${
                        this.state.cityName ? this.state.cityName + "/" : ""
                      }${
                        this.state.countryName ? this.state.countryName : "-"
                      }`}
                    </td>
                  </tr>

                  <tr>
                    <th>Posta Kodu</th>
                    <td colSpan="4">
                      {this.state.zipCode ? this.state.zipCode : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PersonDetail;
