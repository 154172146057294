import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL } from "../../redux/actions/constants";

class ProductReturnNewDetail extends Component {
  state = {
    pageTitle: "İade Detay",
    pageTitleDetail: "İade Detay",
    pageAddLink: "iade2-ekle",
    pageListLink: "iade2-listele",
    breadcrumb: [{ text: "İade", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    returnDate: "",
    supplierName: "",
    customerName: "",
    total: null,
    generalTotal: null,
    generalDiscountTotal: null,
    generalTaxTotal: null,
    productDto: [],
    productName: "",
    piece: null,
    unitPrice: null,
    currencyType: 0,
    incomeDate: "",
    costDate: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/returnsnew/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            returnDate: response.data.returnDate,
            supplierName: response.data.supplierName,
            productEntryGuid: response.data.productEntryGuid,
            customerName: response.data.customerName,
            productOutGuid: response.data.productOutGuid,
            productDto: response.data.returnProductNewDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal: response.data.generalDiscountTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            currencyType: response.data.currencyType,
            incomeDate: response.data.incomeDate,
            productEntryCostDate: response.data.productEntryCostDate,
            productEntryCostDiscountTotal:
              response.data.productEntryCostDiscountTotal,
            costDate: response.data.costDate,
            productOutIncomeDate: response.data.productOutIncomeDate,
            productOutIncomeDiscountTotal:
              response.data.productOutIncomeDiscountTotal,
            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });

        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>İade Tarihi</th>
                    <td colSpan="4">{this.state.returnDate}</td>
                  </tr>

                  <tr>
                    <th>{this.state.supplierName ? "Tedarikçi" : "Müşteri"}</th>
                    <td colSpan="4">
                      {this.state.supplierName
                        ? this.state.supplierName
                        : this.state.customerName}
                    </td>
                  </tr>

                  <tr>
                    <th>{this.state.supplierName ? "Alış" : "Satış"}</th>
                    <td colSpan="4">
                      {this.state.supplierName
                        ? this.state.productEntryCostDate +
                          " " +
                          this.state.supplierName +
                          " " +
                          this.state.productEntryCostDiscountTotal +
                          " " +
                          (this.state.currencyType === 1
                            ? "₺"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")
                        : this.state.productOutIncomeDate +
                          " " +
                          this.state.customerName +
                          " " +
                          this.state.productOutIncomeDiscountTotal +
                          " " +
                          (this.state.currencyType === 1
                            ? "₺"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")}
                    </td>
                  </tr>

                  {/* {this.state.productEntryTotal} TL tutarlı yukarıya yaz */}

                  <tr>
                    <th>Ara Toplam</th>
                    <td colSpan="4">
                      {this.state.generalTotal
                        ? this.state.generalTotal +
                          " " +
                          (this.state.currencyType === 1
                            ? "TL"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>İndirim Toplamı</th>
                    <td colSpan="4">
                    {this.state.generalDiscountTotal
                        ? this.state.generalDiscountTotal +
                          " " +
                          (this.state.currencyType === 1
                            ? "TL"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>KDV Toplamı</th>
                    <td colSpan="4">
                    {this.state.generalTaxTotal
                        ? this.state.generalTaxTotal +
                          " " +
                          (this.state.currencyType === 1
                            ? "TL"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Genel Tutar</th>
                    <td colSpan="4">
                      {this.state.total
                        ? this.state.total +
                          " " +
                          (this.state.currencyType === 1
                            ? "TL"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>          

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>

                  {/* <tr>
                    <th>Durum</th>
                    <th className="colon">:</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr> */}

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              {this.state.productDto !== null &&
                this.state.productDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                      <tr className="">
                        <th style={mainRow}>Depo</th>
                        <th className="text-left" style={mainRow}>
                          Ürün Adı
                        </th>
                        <th className="text-center" style={mainRow}>
                          Adet
                        </th>
                        <th className="text-center" style={mainRow}>
                          Birim
                        </th>
                        <th className="text-center" style={mainRow}>Birim Fiyatı </th>
                        <th className="text-center" style={mainRow}>İndirim(Yüzde) </th>
                        <th className="text-center" style={mainRow}>İndirim(Net Tutar) </th>
                        <th className="text-center" style={mainRow}>KDV Oranı </th>
                        <th className="text-center" style={mainRow}>Tevkifat Tutarı</th>
                        <th style={mainRow}>Fiyat</th>
                      </tr>
                    {this.state.productDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                        <td>{item.locationName}</td>
                        <td className="text-left">
                          {item.productName}
                        </td>
                        <td className="text-center">{item.piece}</td>
                        <td className="text-center">{item.unit === "NIU" ? "Adet" :
                        item.unit === "KGM" ? "kg" :
                        item.unit === "GRM" ? "gr" :
                        item.unit === "MTR" ? "Metre" :
                        item.unit === "LTR" ? "Litre" :
                        item.unit === "PA" ? "Paket" :
                        item.unit === "BX" ? "Kutu" : 
                        item.unit === "CMT" ? "cm" :
                        item.unit === "MTQ" ? "m3" :
                        item.unit === "MTK" ? "m2" :
                        item.unit === "ROLL" ? "Rulo" :
                        item.unit === "SET" ? "Set" :
                        item.unit === "CMQ" ? "cm3" : item.unit === "SAA" ? "Saat" : "-"}</td>
                        <td className="text-center">
                          {item.unitPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>

                        <td className="text-center">
                          {item.productDiscountPercent
                            ? "% " + item.productDiscountPercent
                            : "-"}
                        </td>
                        <td className="text-center">
                        {item.productDiscountValue && item.productDiscountValue > 0
                      ? item.productDiscountValue +
                        " " +
                        (item.currencyType === 1
                          ? "₺"
                          : item.currencyType === 2
                          ? "$"
                          : item.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                        </td>
                        <td className="text-center">
                          {"% " + item.productTaxRate}
                        </td>
                        <td className="text-center">
                          {item.tevkifatTotal.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                        <td>
                          {item.total.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>
                      );
                    })}
                  </Table>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductReturnNewDetail;
