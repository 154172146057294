import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Form,
} from "reactstrap";
import Logo from "../../images/cogen-crm-logo.png";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButton from "../toolbox/CgnButton";
import CgnLoader from "../toolbox/CgnLoader";
import { CgnEMailControl } from "../toolbox/CgnEMailControl";
import packageJson from "../../../package.json";
import { URL } from "../../redux/actions/constants";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

class ForgotInfomation extends React.Component {
  state = {
    email: "",
    emailError: "",
    isLoading: false,
    alertSuccessMessage: "",
    alertErrorMessage: "",
    googleRecaptcha: "",
  };

  componentDidMount() {}

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      forgotPassword: "",
      forgotPasswordError: "",
      emailError: "",
      alertErrorMessage: "",
    });
    if (name === "email" && value === "") {
      this.setState({
        emailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "email" && value !== "" && !CgnEMailControl.test(value)) {
      this.setState({
        emailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleSave = (event) => {
    event.preventDefault();
    this.setState({
      emailError: "",
      alertErrorMessage: "",
    });
    if (this.state.email === "") {
      this.setState({
        emailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.email !== "" && !CgnEMailControl.test(this.state.email)) {
      this.setState({
        emailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if (this.state.googleRecaptcha === "") {
    //   this.setState({
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // } 
    
    else if (
      (this.state.email !== "" && CgnEMailControl.test(this.state.email))
    ){
      this.setState({
        isLoading: true,
      });
      axios
        .post(URL + "/auth/forgotPassword/" + this.state.email)
        .then((response) => {
          localStorage.setItem("email", this.state.email);
          setTimeout(() => {
            this.props.history.push("/sifre-yenile");
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  };

  onChangeGoogleRecaptcha = (value) => {
    this.setState({
      googleRecaptcha: value,
      alertErrorMessage: "",
    });
  };

  render() {
    return (
      <div className="login-page" id="forgotInformation">
        <Card>
          <CgnLoader visibled={this.state.isLoading} />
          <CardHeader>
            <Row>
              <Col md="7">
                <h4>{CgnMessage.programName}</h4>
                <h1>Bilgilerimi Unuttum!</h1>
              </Col>
              <Col md="5" className="pl-0">
                <a
                  href="https://www.cogen.com.tr"
                  alt="Cogen® Yazılım | CRM"
                  target="blank"
                >
                  <img src={Logo} alt="Cogen® Yazılım | CRM" className="logo" />
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.handleSave}>
              <CgnTextbox
                type="text"
                name="email"
                label="E-Mail [*]"
                value={this.state.email}
                placeHolder="Lütfen e-mail adresi girin..."
                maxLength="100"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.emailError}
              />
              <ReCAPTCHA
                sitekey="6LdR6QQbAAAAAKmceasCKrr-0edWssWva5K672E0"
                onChange={this.onChangeGoogleRecaptcha}
                className="recaptcha text-center form-group"
              />

              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnButton
                type="submit"
                color="primary"
                block={true}
                text="Bilgilerimi Gönder"
                disabled={this.state.isLoading}
              />
            </Form>
            <a href="/" className="float-right mt-2">
              Kullanıcı Girişi Yap
            </a>
          </CardBody>
          <CardFooter>
            <p className="m-0">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://www.cogen.com.tr"
                alt="Cogen® Yazılım | CRM"
                target="blank"
              >
                Cogen® Yazılım | CRM
              </a>{" "}
              | Tüm hakları saklıdır.
              <br />
              Versiyon : {packageJson.version}
            </p>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default ForgotInfomation;
