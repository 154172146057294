import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as employeeListAction from "../../redux/actions/employee/employeeListAction";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnBadge from "../toolbox/CgnBadge";
import noFotoMember from "../../images/no-foto-user.jpg";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";

class EmployeeList extends Component {
  state = {
    pageTitle: "Personel Listele",
    pageAddLink: "personel2-ekle",
    pageUpdateLink: "personel2-guncelle",
    pageDetailLink: "personel2-detay",
    pageListLink: "personel2-listele",
    breadcrumb: [{ text: "Personel", link: "#" }],
    isLoading: false,
    isDeleted: false,
    alertErrorMessage: "",
    adminDeleteErrorMessage: "",
    employeeList: [],
    employeeListError: {},
    cell: 0,
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.props.actions.employeeList();
  }

  componentDidUpdate() {
    if (
      this.props.employeeList.status &&
      this.state.employeeList !== this.props.employeeList.data &&
      this.props.employeeList.data &&
      this.props.employeeList.status === 200
    ) {
      this.setState({
        isLoading: false,
        employeeList: this.props.employeeList.data,
      });
    }

    if (
      this.props.employeeList.status &&
      this.props.employeeList.status !== 200 &&
      this.state.employeeListError !== this.props.employeeList
    ) {
      this.setState({
        employeeListError: this.props.employeeList,
        isLoading: false,
      });
    }
  }

  handleDelete = (row) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(row),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(row) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/members/deletememberforcompany/" + row;
    axios
      .post(URL + URLParam, "", config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  thStyle = {
    width: "120px",
  };

  thStyle2 = {
    width: "96px",
  };

  tdStyle = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="employeeList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {(this.state.alertErrorMessage ||
                this.state.adminDeleteErrorMessage) && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={
                      this.state.alertErrorMessage ||
                      this.state.adminDeleteErrorMessage
                    }
                  />
                )}
              <CgnDatatable
                data={this.state.employeeList}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    sortable: false,
                    width: "80px",
                    selector: (cell) => (
                      <div>
                        {cell.image ? (
                          <img
                            alt={cell.firstName + " " + cell.lastName}
                            src={cell.image}
                            className="rdt_image rounded"
                          />
                        ) : (
                          <img
                            alt={cell.firstName + " " + cell.lastName}
                            src={noFotoMember}
                            className="rdt_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },

                  {
                    name: "Personel Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      var phone = row.phone,
                        maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
                          } ${phone.substring(4, 7)} ${phone.substring(
                            7,
                            9
                          )} ${phone.substring(9, 11)}`;
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Grup Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.groupName}</td>
                              <th style={this.thStyle2}>Yetki Durumu</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.userName ? "Yetkili" : "-"}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ünvan</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.title ? row.title : "-"}
                              </td>
                              <th style={this.thStyle}>Adı Soyadı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{`${row.firstName} ${row.lastName}`}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>E-Mail</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.eMail}</td>
                              <th style={this.thStyle}>Telefon</th>
                              {/* <th className="colon">:</th> */}
                              <td>{maskPhone}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Depo</th>
                              <td style={this.tdStyle}>
                                {row.locationName}
                              </td>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td>
                                {row.createdByFullName}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    selector: "status",
                    className: "w-100",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    employeeList: state.employeeListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      employeeList: bindActionCreators(
        employeeListAction.employeeList,
        dispatch
      ),
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeList)
);
