import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  MembershipList,
  TaskPriorityList,
  TaskSituationList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnBadge from "../toolbox/CgnBadge";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";

class TaskList extends Component {
  state = {
    pageTitle: "Görev Atama Listele",
    pageAddLink: "gorev-atama-ekle",
    pageUpdateLink: "gorev-atama-guncelle",
    pageDetailLink: "gorev-atama-detay",
    pageListLink: "gorev-atama-listele",
    breadcrumb: [{ text: "Görev Atama", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    taskData: [],
    personName: "",
    taskCategoryName: "",
    prioirtyColor: "",
    situationColor: "",
    taskName: "",
    createdBy: "",
    endDate: "",
    startDate: "",
    guid: "",
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    personGuid: "00000000-0000-0000-0000-000000000000",
    personelArr: [],
    taskPriorityGuid: "00000000-0000-0000-0000-000000000000",
    taskPriorityArr: [],
    taskSituationGuid: "00000000-0000-0000-0000-000000000000",
    taskSituationArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateTask")) {
      firstDate = localStorage.getItem("firstDateTask");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateTask")) {
      lastDate = localStorage.getItem("lastDateTask");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    await MembershipList().then(function (data) {
      self.setState({
        personelArr: data,
      });
    });

    if (localStorage.getItem("personGuidTask")) {
      this.setState({
        personGuid: localStorage.getItem("personGuidTask"),
      });
    }

    await TaskPriorityList().then(function (data) {
      self.setState({
        taskPriorityArr: data,
      });
    });

    if (localStorage.getItem("taskPriorityGuidTask")) {
      this.setState({
        taskPriorityGuid: localStorage.getItem("taskPriorityGuidTask"),
      });
    }

    await TaskSituationList().then(function (data) {
      self.setState({
        taskSituationArr: data,
      });
    });

    if (localStorage.getItem("taskSituationGuidTask")) {
      this.setState({
        taskSituationGuid: localStorage.getItem("taskSituationGuidTask"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateTask", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateTask", this.state.lastDate);
  }

  async handlePersonelSelectChange(option) {
    if (option) {
      await this.setState({
        personGuid: option.value,
      });
    } else {
      await this.setState({
        personGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("personGuidTask", this.state.personGuid);
  }

  async handleTaskPrioritySelectChange(option) {
    if (option) {
      await this.setState({
        taskPriorityGuid: option.value,
      });
    } else {
      await this.setState({
        taskPriorityGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("taskPriorityGuidTask", this.state.taskPriorityGuid);
  }

  async handleTaskSituationSelectChange(option) {
    if (option) {
      await this.setState({
        taskSituationGuid: option.value,
      });
    } else {
      await this.setState({
        taskSituationGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("taskSituationGuidTask", this.state.taskSituationGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam =
      "/tasks/getlist/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.personGuid +
      "/" +
      this.state.taskPriorityGuid +
      "/" +
      this.state.taskSituationGuid;

    axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          taskData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/tasks/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }
  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "300px",
  };
  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="taskList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="3">
                  <CgnReactSelect
                    name="personGuid"
                    label="Personel"
                    placeholder="Personel seçiniz..."
                    selectValue={this.state.personGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handlePersonelSelectChange(option)
                    }
                    options={this.state.personelArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="taskPriorityGuid"
                    label="Öncelik"
                    placeholder="Öncelik seçiniz..."
                    selectValue={this.state.taskPriorityGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleTaskPrioritySelectChange(option)
                    }
                    options={this.state.taskPriorityArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="taskSituationGuid"
                    label="Durum"
                    placeholder="Durum seçiniz..."
                    selectValue={this.state.taskSituationGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleTaskSituationSelectChange(option)
                    }
                    options={this.state.taskSituationArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.taskData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Görev Atama Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Görev Atayan</th>
                              <td style={this.tdStyle}>{row.createdBy}</td>
                              <th style={this.thStyle2}>Görevli Personel</th>
                              <td>{row.personName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Başlangıç Tarihi</th>
                              <td style={this.tdStyle}>{row.startDate}</td>
                              <th style={this.thStyle2}>Bitiş Tarihi</th>
                              <td>{row.endDate}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Görev Kategorisi</th>
                              <td style={this.tdStyle}>
                                {row.taskCategoryName}
                              </td>
                              <th style={this.thStyle2}>Görev Adı</th>
                              <td>{row.taskName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td colSpan={4}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "taskSituationName",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <CgnBadge
                          color="danger"
                          text={row.taskSituationName}
                          style={{ backgroundColor: row.situationColor }}
                        />
                      );
                    },
                  },
                  {
                    selector: "taskPriorityName",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <CgnBadge
                          color="danger"
                          text={row.taskPriorityName}
                          style={{ backgroundColor: row.priorityColor }}
                        />
                      );
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default TaskList;
