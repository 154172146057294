import React, { Component } from "react";
import { Row, Col, Card, Form, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  fotoStyle
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";

class CustomerDocumentRecordAddUpdate extends Component {
  state = {
    pageTitle: "Müşteriden Gelen Belgeler/Eklenecek Belgeler (ÜTS Belge Kayıt) Ekle",
    pageTitleAdd: "Müşteriden Gelen Belgeler/Eklenecek Belgeler Ekle",
    pageTitleUpdate: "Müşteriden Gelen Belgeler/Eklenecek Belgeler Güncelle",
    pageAddLink: "musteri-belge-kayit-ekle",
    pageListLink: "musteri-belge-kayit-listele",
    breadcrumb: [{ text: "Müşteriden Gelen Belgeler/Eklenecek Belgeler", link: "#" }],
    isLoading: false,
    urlSplit: [],
    customerDocumentImageDto: [],
    name: "",
    customerName: "",
    requestedDocumentsName: "",
    guid: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/customerDocumentRecords/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              customerName: response.data.customerName,
              documentRecordGuid: response.data.documentRecordGuid,
              requestedDocumentsName: response.data.requestedDocumentsName,
              status: response.data.status,
              customerDocumentImageDto: response.data.customerDocumentImageDto,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        customerDocumentImageDto: [],
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      customerDocumentImageDto: [],
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  uploadedImage2 = async (event) => {
    let array = this.state.customerDocumentImageDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
      if(file.type === "application/pdf"){
        const formData = new FormData();
        formData.append("files", file);
        // 
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({customerDocumentImageDto: array});
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.customerDocumentImageDto;
     this.state.customerDocumentImageDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({customerDocumentImageDto: array});
          }
        }
     });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      customerDocumentImageDtoError: "",
    });
    if (this.state.customerDocumentImageDto.length === 0) {
      this.setState({
        customerDocumentImageDtoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.customerDocumentImageDto !== 0) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        customerDocumentImageDto: this.state.customerDocumentImageDto,
        status: this.state.status,
      };
      var URLParam = "/customerDocumentRecords/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "musteri-belge-kayit-guncelle"
      ) {
        URLParam = "/customerDocumentRecords/update";
        myObj = {
          guid: this.state.guid,
          customerDocumentImageDto: this.state.customerDocumentImageDto,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "musteri-belge-kayit-guncelle"
            ) {
              window.location.href = "/musteri-belge-kayit-listele";
            }
            if (this.state.urlSplit[1] === "musteri-belge-kayit-ekle") {
              window.location.reload();
            }
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "musteri-belge-kayit-ekle" ||
          this.state.urlSplit[1] === "musteri-belge-kayit-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            listLink={"/" + this.state.pageListLink}
          />
        )}       
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">
                      {this.state.customerName ? this.state.customerName : "-"}
                    </td>
                  </tr>             
                 <tr>
                  <th>Talep Edilen Belgeler</th>
                    <td colSpan="4">
                      {this.state.requestedDocumentsName}
                    </td>
                  </tr>

                </tbody>
              </Table>
                <Row>
                  <Col lg="6">
                    <ImageUploadMulti
                      images={this.state.customerDocumentImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Dosya"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.customerDocumentImageDtoError}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "musteri-belge-kayit-ekle" ||
              this.state.urlSplit[1] === "musteri-belge-kayit-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(CustomerDocumentRecordAddUpdate);
