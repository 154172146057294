import React, { useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Collapse,
  Button
} from "reactstrap";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlinePlus,
  AiOutlineMenu,
  AiOutlineSearch,
} from "react-icons/ai";

const CgnListGroup = ({ items }) => {
  const [collapse, setCollapse] = useState(false);
  const toggle = (button) => {
    button.target.attributes.id &&
      button.target.attributes.id.nodeValue &&
      items &&
      items.map((item) => {
        if (item.id.toString() === button.target.attributes.id.nodeValue) {
          setCollapse(!collapse);
          item.collapse = !item.collapse;
        }
        return item.collapse;
      });
  };

  return (
    <>
      {items && (
        <ListGroup>
          {items &&
            items.map((item) => (
              item.visible === true &&
              <ListGroupItem
                action
                key={item.id.toString()}
                color={item.color}
                disabled={item.disabled}
                active={item.active}
              >
                {item.header ? (
                  <>
                    <a
                      href={
                        item.items && item.items && item.items.length > 0
                          ? "#"
                          : item.link
                      }
                      onClick={
                        item.items && item.items && item.items.length > 0
                          ? toggle
                          : null
                      }
                    >
                      <ListGroupItemHeading>{item.header}</ListGroupItemHeading>
                      <ListGroupItemText>{item.text}</ListGroupItemText>
                    </a>
                    {item.items && item.items.length > 0 && (
                      <Collapse isOpen={item.collapse}>
                        <ListGroup>
                          {item.items.map((item2) => (
                            <ListGroupItem
                              action
                              id={item2.id.toString()}
                              key={item2.id.toString()}
                              color={item2.color}
                              disabled={item.disabled}
                              active={item.active}
                            >
                              {item2.header ? (
                                <>
                                  <a
                                    href={
                                      item2.items &&
                                        item2.items &&
                                        item2.items.length > 0
                                        ? "#"
                                        : item2.link
                                    }
                                    onClick={
                                      item.items &&
                                        item.items &&
                                        item.items.length > 0
                                        ? toggle
                                        : null
                                    }
                                  >
                                    <ListGroupItemHeading>
                                      {item2.header}
                                    </ListGroupItemHeading>
                                    <ListGroupItemText>
                                      {item2.text}
                                    </ListGroupItemText>
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href={
                                      item2.items &&
                                        item2.items &&
                                        item2.items.length > 0
                                        ? "#"
                                        : item2.link
                                    }
                                    onClick={
                                      item.items &&
                                        item.items &&
                                        item.items.length > 0
                                        ? toggle
                                        : null
                                    }
                                  >
                                    {item2.icon === "ekle" && <AiOutlinePlus />}
                                    {item2.icon === "listele" && (
                                      <AiOutlineMenu />
                                    )}
                                    {item2.icon === "ara" && (
                                      <AiOutlineSearch />
                                    )}{" "}
                                    {item2.title}
                                  </a>
                                </>
                              )}
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </Collapse>
                    )}
                  </>
                ) : (
                  <>
                    {item.items && item.items && item.items.length > 0 ? (
                      <Button color="link" id={item.id.toString()} onClick={toggle}>{item.title} </Button>
                    ) : (
                      <a href={item.link}>{item.title} </a>
                    )}

                    {item.items &&
                      item.items.length > 0 &&
                      (item.collapse ? (
                        <AiOutlineCaretUp className="float-right" />
                      ) : (
                        <AiOutlineCaretDown className="float-right" />
                      ))}
                    {item.items && item.items.length > 0 && (
                      <Collapse isOpen={item.collapse}>
                        <ListGroup>
                          {item.items.map((item2) => (
                            <ListGroupItem
                              action
                              id={item2.id.toString()}
                              key={item2.id.toString()}
                              color={item2.color}
                              disabled={item.disabled}
                              active={item.active}
                            >
                              {item2.header ? (
                                <>
                                  <a
                                    href={
                                      item2.items &&
                                        item2.items &&
                                        item2.items.length > 0
                                        ? "#"
                                        : item2.link
                                    }
                                    onClick={
                                      item.items &&
                                        item.items &&
                                        item.items.length > 0
                                        ? toggle
                                        : null
                                    }
                                  >
                                    <ListGroupItemHeading>
                                      {item2.header}
                                    </ListGroupItemHeading>
                                    <ListGroupItemText>
                                      {item2.text}
                                    </ListGroupItemText>
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href={
                                      item2.items &&
                                        item2.items &&
                                        item2.items.length > 0
                                        ? "#"
                                        : item2.link
                                    }
                                    onClick={
                                      item.items &&
                                        item.items &&
                                        item.items.length > 0
                                        ? toggle
                                        : null
                                    }
                                  >
                                    {item2.icon === "ekle" && <AiOutlinePlus />}
                                    {item2.icon === "listele" && (
                                      <AiOutlineMenu />
                                    )}
                                    {item2.icon === "ara" && (
                                      <AiOutlineSearch />
                                    )}{" "}
                                    {item2.title}
                                  </a>
                                </>
                              )}
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </Collapse>
                    )}
                  </>
                )}
              </ListGroupItem>
            ))}
        </ListGroup>
      )}
    </>
  );
};

export default CgnListGroup;
