import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnReactMultiSelect from "../toolbox/CgnReactMultiSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import moment from "moment";
import TimePicker from "rc-time-picker";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";
import axios from "axios";
import {
  URL,
  optionServiceStatuses,
  TaxRateChoices,
  TaxType,
  CurrencyType,
  
  fotoStyle,
  resizeFile,
  UserOperationClaimControl,
  optionStatuses,
  viewExchangeRate,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import EmployeeAddUpdate from "../employee/EmployeeAddUpdate";
import ServiceTypeAddUpdate from "../serviceType/ServiceTypeAddUpdate";
import CgnTextEditor from "../toolbox/CgnTextEditor";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

class ServiceAddUpdate extends Component {
  state = {
    pageTitle: "Servis Ekle",
    pageTitleAdd: "Servis Ekle",
    pageTitleUpdate: "Servis Güncelle",
    pageAddLink: "servis-ekle",
    pageListLink: "servis-listele",
    breadcrumb: [{ text: "Servis", link: "#" }],
    isLoading: false,
    urlSplit: [],
    customerGuid: "",
    maintenanceKitGuid: "",
    employeeGuid: "",
    technicianGuid: "",
    customer: "",
    productionDate: null,
    maintenanceDate: "",
    notDescription: "",
    nextMaintenanceDate: "",
    maintenanceKitDto: [],
    maintenanceKitArr: [],
    serviceImageDto: [],
    servicePictureDto: [],
    salePrice: "",
    buyPrice: "",
    serviceTime: null,
    serviceTimeError: "",
    iType: "",
    status: 1,
    counter: -1,
    totalSalePrice: 0,
    totalBuyPrice: 0,
    pieceIdx: 0,
    targetValue: 0,
    firstValues: [],
    generalTotal: null,
    taxRateValue: "",
    taxTypeValue: null,
    discountPercent: "",
    discountValue: "",
    discountTotal: "",
    currencyType: "",
    description: "",
    descriptionHtml: "",
    guid: "",
    customerError: "",
    maintenanceKitError: "",
    employeeError: "",
    productionDateError: "",
    maintenanceDateError: "",
    nextMaintenanceDateError: "",
    descriptionError: "",
    productError: "",
    alertErrorMessage: "",
    isDeleted: false,
    isTruePrices: false,
    isOpenModalCustomer: false,
    isOpenModalProduct: false,
    // isOpenModalProduct2: false,
    isOpenModalEmployee: false,
    isOpenModalServiceType: false,
    isNull: false,
    customerArr: [],
    productArr: [],
    employeeArr: [],
    serviceTypeArr: [],
    loadWorkHour: 0,
    totalWorkHour: 0,
    workPressure: 0,
    guarantyArr: [],
    technicianArr: [],
    technicianOption: [],
    technicianId: [],
    assistantArr: [],
    assistantOption: [],
    assistantId: [],
    serviceOrderNo: "",
    serialNo: "",
    technicianError: "",
    customer2Error: "",
    TypeError: "",
    guarantyOption: [
      { value: "1", label: "İç" },
      { value: "2", label: "Dış" },
    ],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    customerModalPlus: false,
    membershipModalPlus: false,
    productModalPlus: false,
    serviceTypeModalPlus: false,
    isExchangeRate: false,
    address: "",
    nameSurname: "",
    phone: "",
    dolarValue: 0,
    euroValue: 0,
    exchangeRate: 0,
    workingHours: 0,
    dailyWorkingHours: 0,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
      membershipModalPlus: await UserOperationClaimControl(1020),
      productModalPlus: await UserOperationClaimControl(1029),
      serviceTypeModalPlus: await UserOperationClaimControl(1084),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/serviceTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          serviceTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if (item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            unitBuyPrice: item.buyPrice,
            unitSalePrice: item.salePrice,
          };
        });
        this.setState({
          productArr: myArr,
          maintenanceKitArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          employeeArr: myArr,
          technicianOption: myArr,
          assistantOption: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/kurbilgisi/getlast/", config)
      .then((response) => {
        this.setState({
          dolarValue: response.data.dolar,
          euroValue: response.data.euro,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      await axios
        .get(URL + "/services/get/" + urlSplit[2], config)
        .then((response) => {
          if (response.data.technicianId !== null) {
            const technicianArr = response.data.technicianId.split(",");
            technicianArr.forEach((element) => {
              var technicianObj = {};
              technicianObj.value = element;
              this.state.technicianArr.push(technicianObj);
            });
          }
          if (response.data.assistantId !== null) {
            const assistantArr = response.data.assistantId.split(",");
            assistantArr.forEach((element) => {
              var assistantObj = {};
              assistantObj.value = element;
              this.state.assistantArr.push(assistantObj);
            });
          }
        });
    }
    urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/services/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            if (response.data.guaranty) {
              const guaranty = response.data.guaranty.split(",");
              guaranty.forEach((element) => {
                var guarantyObj = {};
                guarantyObj.value = element;
                this.state.guarantyArr.push(guarantyObj);
              });
            } else {
              this.setState({
                guarantyArr: [],
              });
            }
            this.setState({
              customerGuid: response.data.customerGuid,
              productGuid: response.data.productGuid,
              employeeGuid: response.data.employeeGuid,
              technicianGuid: response.data.technicianGuid,
              customer: response.data.customer,
              productionDate:
                response.data.productionDate === "01/01/1900"
                  ? ""
                  : response.data.productionDate,
              maintenanceDate:
                response.data.maintenanceDate === "01/01/1900"
                  ? ""
                  : response.data.maintenanceDate,
              nextMaintenanceDate:
                response.data.nextMaintenanceDate === "01/01/1900"
                  ? ""
                  : response.data.nextMaintenanceDate,
              maintenanceKitDto: response.data.serviceMaintenanceKitDto,
              totalBuyPrice: response.data.totalBuyPrice,
              totalSalePrice: response.data.total,
              discountPercent: response.data.discountPercent,
              discountValue: response.data.discountValue,
              generalTotal: response.data.generalTotal,
              discountTotal: response.data.discountTotal,
              currencyType: response.data.currencyType,
              serviceImageDto: response.data.serviceImageDto,
              taxTypeValue: response.data.taxType,
              taxRateValue: response.data.taxRate.toString(),
              // description: response.data.description,
              descriptionHtml: response.data.description,
              description: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(response.data.description)
                )
              ),

              iType: response.data.iType,
              serviceTypeName: response.data.serviceTypeName,
              status: response.data.status,
              loadWorkHour: response.data.loadWorkHour,
              totalWorkHour: response.data.totalWorkHour,
              serviceOrderNo: response.data.serviceOrderNo,
              serialNo: response.data.serialNo,
              workPressure: response.data.workPressure,
              serviceTime:
                response.data.serviceTime === "01/01/1900 00:00"
                  ? null
                  : moment(response.data.serviceTime, "DD-MM-YYYY hh:mm"),
              notDescription: response.data.notDescription,
              servicePictureDto: response.data.servicePictureDto,
              isExchangeRate: response.data.isExchangeRate,
              isLoading: false,
              nameSurname: response.data.nameSurname,
              phone: response.data.phone,
              address: response.data.address,
              exchangeRate: response.data.exchangeRate,
              workingHours: response.data.workingHours,
              dailyWorkingHours: response.data.dailyWorkingHours,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        customerGuid: "",
        technicianGuid: "",
        customer: "",
        technicianArr: [],
        assistantArr: [],
        assistantGuid: "",
        employeeGuid: "",
        productionDate: null,
        maintenanceDate: "",
        nextMaintenanceDate: "",
        description: "",
        notDescription: "",
        descriptionHtml: "",
        productGuid: "",
        maintenanceKitDto: [],
        serviceImageDto: [],
        taxRateValue: "",
        taxTypeValue: null,
        generalTotal: "",
        serviceTime: null,
        discountPercent: "",
        discountValue: "",
        discountTotal: "",
        currencyType: "",
        totalSalePrice: "",
        totalBuyPrice: "",
        workPressure: 0,
        loadWorkHour: 0,
        totalWorkHour: 0,
        serviceOrderNo: "",
        serialNo: "",
        guarantyArr: [],
        iType: "",
        status: 1,
        isExchangeRate: false,
        nameSurname: "",
        phone: "",
        address: "",
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: 1,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
              this.props.location.modalAddedProduct.data
            ) {
              this.setState({
                productGuid: element.guid,
                productError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    // if (
    //   this.state.isOpenModalProduct2 &&
    //   this.props.location.modalAddedProduct !== undefined &&
    //   this.props.location.modalAddedProduct.isModal !== undefined &&
    //   this.props.location.modalAddedProduct.isModal === false
    // ) {
    //   config = {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   };
    //   statusType = 2; // sadece aktif olanları göstermesi için
    //   await axios
    //     .get(URL + "/products/getlist/" + statusType, config)
    //     .then((response) => {
    //       var myArr = response.data.map(function (item) {
    //         return {
    //           value: item.guid,
    //           label: `${item.code}-${item.name}`,
    //         };
    //       });
    //       this.setState({
    //         productArr: myArr,
    //         isLoading: false,
    //       });
    //       let productData = this.state.maintenanceKitDto;
    //       response.data.forEach((element) => {
    //         if (
    //           this.props.location.modalAddedProduct.data &&
    //           `${element.code}-${element.name}` ===
    //             this.props.location.modalAddedProduct.data &&
    //           this.state.modalButtonIdx !== previousState
    //         ) {
    //           productData[this.state.modalButtonIdx].maintenanceKitGuid = element.guid;
    //           productData[this.state.modalButtonIdx].maintenanceKitError = "";
    //         }
    //       });
    //       if (this.props.location.modalAddedProduct.maintenanceKitSalePrice) {
    //         productData[this.state.modalButtonIdx].maintenanceKitPiece =
    //           this.props.location.modalAddedProduct.maintenanceKitSalePrice;
    //         productData[this.state.modalButtonIdx].maintenanceKitPiece = 1;
    //         productData[this.state.modalButtonIdx].maintenanceKitPieceError = "";
    //         productData[this.state.modalButtonIdx].maintenanceKitSalePriceError = "";
    //       }
    //       this.setState({
    //         alertErrorMessage: "",
    //       });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         alertErrorMessage: error.response.data,
    //         isLoading: false,
    //       });
    //     });
    //   this.props.location.modalAddedProduct.isModal = true;
    //   this.setState({
    //     isOpenModalProduct2: false,
    //   });
    // }
    if (
      this.state.isOpenModalEmployee &&
      this.props.location.modalAddedEmployee !== undefined &&
      this.props.location.modalAddedEmployee.isModal !== undefined &&
      this.props.location.modalAddedEmployee.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/members/getmembers/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.firstName} ${item.lastName}`,
            };
          });
          this.setState({
            employeeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedEmployee.data &&
              `${element.firstName} ${element.lastName}` ===
              this.props.location.modalAddedEmployee.data
            ) {
              this.setState({
                employeeGuid: element.guid,
                employeeNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedEmployee.isModal = true;
      this.setState({
        isOpenModalEmployee: false,
      });
    }
    if (
      this.state.isOpenModalServiceType &&
      this.props.location.modalAddedServiceType !== undefined &&
      this.props.location.modalAddedServiceType.isModal !== undefined &&
      this.props.location.modalAddedServiceType.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/serviceTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            serviceTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedServiceType.data &&
              element.name === this.props.location.modalAddedServiceType.data
            ) {
              this.setState({
                iType: element.guid,
                TypeError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedServiceType.isModal = true;
      this.setState({
        isOpenModalServiceType: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleEditorChange = (editorState, editorStateHtml) => {
    if (editorStateHtml) {
      this.setState({
        description: editorState,
        descriptionHtml: editorStateHtml,
        descriptionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  async handleServiceTimeChange(option) {
    if (option) {
      var time = moment(option);
      await this.setState({
        serviceTime: time,
        serviceTimeError: "",
      });
    } else {
      this.setState({
        serviceTime: null,
        serviceTimeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      descriptionError: "",
      alertErrorMessage: "",
    });
  };

   handleChangeWorkingHours = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.calculateDate();
  };

  handleChangeDailyWorkingHours = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.calculateDate();
  };

  calculateDate() {
    if (this.state.maintenanceDate && this.state.maintenanceDate !== "" && 
        this.state.workingHours && this.state.workingHours !== "" && 
        this.state.dailyWorkingHours && this.state.dailyWorkingHours !== "") {
      const daysToAdd = Math.floor(this.state.workingHours / this.state.dailyWorkingHours);
      const date = new Date(this.state.maintenanceDate);
      date.setDate(date.getDate() + daysToAdd);
      this.setState({
        nextMaintenanceDate: date
      });
    } else {
      this.setState({
        nextMaintenanceDate: ""
      });
    }
  };

  handleCustomerChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      customer2Error: "",
      alertErrorMessage: "",
    });
  };

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleEmployeeSelectChange(option) {
    if (option) {
      this.setState({
        employeeGuid: option.value,
        employeeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        employeeGuid: "",
        employeeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleTechnicianSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        technicianArr: option,
        technicianError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        technicianArr: [],
      });
    }
  }

  handleAssistantSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        assistantArr: option,
        assistantError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        assistantArr: [],
      });
    }
  }

  handleServiceTypeSelectChange(option) {
    if (option) {
      this.setState({
        iType: option.value,
        TypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        iType: "",
        TypeError: CgnMessage.alertErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductionDateChange(productionDate) {
    if (productionDate) {
      this.setState({
        productionDate: productionDate,
      });
    } else {
      this.setState({
        productionDate: null,
      });
    }
  }

  handleGuarantyChange(option) {
    if (option && option.length > 0) {
      this.setState({
        guarantyArr: option,
      });
    } else {
      this.setState({
        guarantyArr: [],
      });
    }
  }

  async handleMaintenanceDateChange(maintenanceDate) {
    if (maintenanceDate) {
      await this.setState({
        maintenanceDate: maintenanceDate,
        maintenanceDateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        maintenanceDate: "",
        maintenanceDateError: CgnMessage.textErrorMessage,
      });
    }
    this.calculateDate();
  }

  handleNextMaintenanceDateChange(nextMaintenanceDate) {
    if (nextMaintenanceDate) {
      this.setState({
        nextMaintenanceDate: nextMaintenanceDate,
        nextMaintenanceDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        nextMaintenanceDate: "",
        nextMaintenanceDateError: CgnMessage.textErrorMessage,
      });
    }
  }

  // handleITypeSelectChange(iType) {
  //   this.setState({ iType: iType.value });
  // }

  handleStatusSelectChange(status) {
    if (status) {
      this.setState({
        status: status.value,
      });
    }
  }

  handleIsExchangeRateSelectChange(isExchangeRate) {
    if (isExchangeRate) {
      this.setState({
        isExchangeRate: isExchangeRate.value,
      });
    }
  }

  addModalServiceType = () => {
    this.setState({
      isOpenModalServiceType: !this.state.isOpenModalServiceType,
    });
  };

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  // addModalProduct2 = () => {
  //   this.setState({
  //     isOpenModalProduct2: !this.state.isOpenModalProduct2,
  //   });
  // };

  addModalEmployee = () => {
    this.setState({
      isOpenModalEmployee: !this.state.isOpenModalEmployee,
    });
  };

  uploadedImage = async (event) => {
    let array = this.state.servicePictureDto;
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        });
      }
    }
    await this.setState({ servicePictureDto: array });
  };

  deleteImage = (event) => {
    var index = -1;
    let array = this.state.servicePictureDto;
    this.state.servicePictureDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ servicePictureDto: array });
        }
      }
    });
  };

  uploadedImage2 = async (event) => {
    let array = this.state.serviceImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "application/pdf") {
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
              image: response.data,
              imageName: file.name,
            });
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({ serviceImageDto: array });
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.serviceImageDto;
    this.state.serviceImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ serviceImageDto: array });
        }
      }
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      customerGuid: "",
      employeeGuid: "",
      productionDate: null,
      maintenanceDate: "",
      nextMaintenanceDate: "",
      description: "",
      serviceTime: null,
      descriptionHtml: "",
      productGuid: "",
      maintenanceKitDto: [],
      serviceImageDto: [],
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      discountPercent: "",
      notDescription: "",
      discountValue: "",
      discountTotal: "",
      currencyType: "",
      totalSalePrice: "",
      totalBuyPrice: "",
      workPressure: 0,
      loadWorkHour: 0,
      totalWorkHour: 0,
      serviceOrderNo: "",
      serialNo: "",
      guarantyArr: [],
      iType: "",
      status: 1,
      isExchangeRate: false,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      maintenanceKitError: CgnMessage.textErrorMessage,
      employeeError: CgnMessage.textErrorMessage,
      maintenanceDateError: CgnMessage.textErrorMessage,
      nextMaintenanceDateError: CgnMessage.textErrorMessage,
      productError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      serviceTimeError: CgnMessage.textErrorMessage,
      address: "",
      nameSurname: "",
      phone: "",
    });
  }

  handleProductChange = async (option) => {
    this.setState({
      maintenanceKitDto: [],
      totalSalePrice: "",
      totalBuyPrice: "",
      discountPercent: "",
      discountValue: "",
      generalTotal: "",
      currencyType: "",
      taxRateValue: "",
      taxTypeValue: null,
      discountTotal: "",
    });
    if (option) {
      this.setState({
        productGuid: option.value,
        alertErrorMessage: "",
        productError: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/products/productgetlisttoservice/" + option.value, config)
        .then((response) => {
          var totalSalePrice = 0;
          var totalBuyPrice = 0;

          if (response.data.maintenanceKitDto) {
            var myArr = response.data.maintenanceKitDto.map(function (item) {
              totalSalePrice += item.maintenanceKitSalePrice;
              totalBuyPrice += item.maintenanceKitBuyPrice;
              return {
                unitBuyPrice:
                  item.maintenanceKitPiece !== 0
                    ? item.maintenanceKitBuyPrice / item.maintenanceKitPiece
                    : item.maintenanceKitBuyPrice,
                unitSalePrice:
                  item.maintenanceKitPiece !== 0
                    ? item.maintenanceKitSalePrice / item.maintenanceKitPiece
                    : item.maintenanceKitSalePrice,
                maintenanceKitBuyPrice: item.maintenanceKitBuyPrice,
                maintenanceKitSalePrice: item.maintenanceKitSalePrice,
                maintenanceKitPiece: item.maintenanceKitPiece,
                maintenanceKitGuid: item.maintenanceKitGuid,
              };
            });
            this.setState({
              maintenanceKitDto: myArr,
              totalSalePrice: totalSalePrice,
              totalBuyPrice: totalBuyPrice,
              generalTotal: totalSalePrice,
            });
          } else {
            this.setState({
              maintenanceKitDto: [],
              totalSalePrice: 0,
              totalBuyPrice: 0,
              generalTotal: 0,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (this.state.productGuid === null) {
        this.setState({
          productEntryError: CgnMessage.textErrorMessage,
        });
      }
    } else {
      this.setState({
        productGuid: "",
        maintenanceKitDto: [],
        totalSalePrice: "",
        totalBuyPrice: "",
        discountPercent: "",
        discountValue: "",
        generalTotal: "",
        currencyType: "",
        taxRateValue: "",
        taxTypeValue: null,
        discountTotal: "",
        alertErrorMessage: CgnMessage.alertErrorMessage,
        productError: CgnMessage.textErrorMessage,
      });
    }
  };

  handleMaintenanceKitAdd = async () => {
    var array = this.state.maintenanceKitDto;
    this.setState({
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
    await array.push({
      maintenanceKitGuid: "00000000-0000-0000-0000-000000000000",
      maintenanceKitPiece: 1,
      unitBuyPrice: 0,
      unitSalePrice: 0,
      price: 0,
      salePrice: 0,
      maintenanceKitBuyPrice: 0,
      maintenanceKitSalePrice: 0,
      maintenanceKitPieceError: "",
      maintenanceKitSalePriceError: "",
      maintenanceKitError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });

    this.setState({ maintenanceKitDto: array });
  };

  async handleMaintenanceKitChange(option, idx) {
    let maintenanceKitDto = this.state.maintenanceKitDto;
    if (option) {
      maintenanceKitDto[idx].maintenanceKitGuid = option.value;

      maintenanceKitDto[idx].maintenanceKitError = "";

      maintenanceKitDto[idx].unitBuyPrice = parseFloat(option.unitBuyPrice);

      maintenanceKitDto[idx].salePrice = parseFloat(option.unitSalePrice);

      maintenanceKitDto[idx].maintenanceKitBuyPrice =
        parseFloat(option.unitBuyPrice) *
        maintenanceKitDto[idx].maintenanceKitPiece;

      maintenanceKitDto[idx].price =
        parseFloat(maintenanceKitDto[idx].salePrice) *
        maintenanceKitDto[idx].maintenanceKitPiece;

      var totalBuyPrice = 0;
      var totalSalePrice = 0;
      await maintenanceKitDto.forEach((element) => {
        if (element.maintenanceKitBuyPrice > 0) {
          totalBuyPrice += parseFloat(element.maintenanceKitBuyPrice);
        }
        if (element.price > 0) {
          totalSalePrice += parseFloat(element.price);
        }
      });
      await this.setState({
        maintenanceKitDto: maintenanceKitDto,
        totalBuyPrice: totalBuyPrice,
        totalSalePrice: totalSalePrice,
        alertErrorMessage: "",
        isNull: false,
      });
    } else {
      maintenanceKitDto[idx].maintenanceKitGuid = "";
      maintenanceKitDto[idx].maintenanceKitBuyPrice = 0;
      maintenanceKitDto[idx].price = 0;
      maintenanceKitDto[idx].maintenanceKitError = CgnMessage.textErrorMessage;
      this.setState({
        maintenanceKitDto: maintenanceKitDto,
        isTruePrices: false,
      });
      if (
        this.state.maintenanceKitDto.length > 0 &&
        maintenanceKitDto[idx].maintenanceKitGuid === ""
      ) {
        maintenanceKitDto[idx].maintenanceKitError =
          CgnMessage.textErrorMessage;
        this.setState({
          isNull: true,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      } else {
        maintenanceKitDto[idx].maintenanceKitError = "";
        this.setState({
          isNull: false,
          alertErrorMessage: "",
        });
      }
    }
    this.PriceCalculate(null);
  }

  async handleMaintenanceKitSalePriceChange(option, idx) {
    let maintenanceKitDto = this.state.maintenanceKitDto;
    maintenanceKitDto[idx].salePrice = parseFloat(option.target.value);

    maintenanceKitDto[idx].price =
      maintenanceKitDto[idx].salePrice *
      maintenanceKitDto[idx].maintenanceKitPiece;
    maintenanceKitDto[idx].maintenanceKitSalePriceError = "";
    var totalBuyPrice = 0;
    var totalSalePrice = 0;
    maintenanceKitDto.forEach((element) => {
      if (element.salePrice > 0) {
        totalSalePrice += parseFloat(element.price);
      }
      if (element.maintenanceKitBuyPrice > 0) {
        totalBuyPrice += parseFloat(element.maintenanceKitBuyPrice);
      }
    });
    await this.setState({
      maintenanceKitDto: maintenanceKitDto,
      totalBuyPrice: totalBuyPrice,
      totalSalePrice: totalSalePrice,
      alertErrorMessage: "",
    });
    if (!option.target.value) {
      maintenanceKitDto[idx].maintenanceKitBuyPrice = 0;
      maintenanceKitDto[idx].maintenanceKitSalePrice = 0;
      await this.setState({
        maintenanceKitDto: maintenanceKitDto,
      });
    }
    this.PriceCalculate(null);
  }

  async handleMaintenanceKitPieceChange(e, idx) {
    let maintenanceKitDto = this.state.maintenanceKitDto;
    if (maintenanceKitDto[idx].maintenanceKitPiece) {
      maintenanceKitDto[idx].price =
        maintenanceKitDto[idx].maintenanceKitPiece !== 0
          ? parseFloat(maintenanceKitDto[idx].price) /
          maintenanceKitDto[idx].maintenanceKitPiece
          : parseFloat(maintenanceKitDto[idx].price);
      maintenanceKitDto[idx].unitBuyPrice =
        maintenanceKitDto[idx].maintenanceKitPiece !== 0
          ? parseFloat(maintenanceKitDto[idx].maintenanceKitBuyPrice) /
          maintenanceKitDto[idx].maintenanceKitPiece
          : parseFloat(maintenanceKitDto[idx].maintenanceKitBuyPrice);
    }
    if (e.target.value) {
      maintenanceKitDto[idx].maintenanceKitPiece = parseInt(e.target.value);
      maintenanceKitDto[idx].maintenanceKitBuyPrice =
        maintenanceKitDto[idx].maintenanceKitPiece *
        parseFloat(maintenanceKitDto[idx].unitBuyPrice);
      maintenanceKitDto[idx].price =
        maintenanceKitDto[idx].maintenanceKitPiece *
        parseFloat(maintenanceKitDto[idx].price);
      maintenanceKitDto[idx].maintenanceKitPieceError = "";
      maintenanceKitDto[idx].maintenanceKitSalePriceError = "";

      this.setState({
        alertErrorMessage: "",
      });
    } else {
      maintenanceKitDto[idx].maintenanceKitPiece = 1;
      maintenanceKitDto[idx].maintenanceKitBuyPrice = 0;
      maintenanceKitDto[idx].maintenanceKitSalePrice = 0;
    }

    var totalBuyPrice = 0;
    var totalSalePrice = 0;
    await maintenanceKitDto.forEach((element) => {
      if (element.maintenanceKitPiece > 0) {
        totalBuyPrice += parseFloat(element.maintenanceKitBuyPrice);
      }
      totalSalePrice += parseFloat(element.price);
    });
    this.setState({
      totalBuyPrice: totalBuyPrice,
      totalSalePrice: totalSalePrice,
      generalTotal: totalSalePrice,
      maintenanceKitDto: maintenanceKitDto,
    });
    this.PriceCalculate(null);
  }

  async handleMaintenanceKitRemove(idx) {
    let someArray = this.state.maintenanceKitDto;
    this.state.totalBuyPrice -= someArray[idx].maintenanceKitBuyPrice;
    this.state.totalSalePrice -= someArray[idx].price;
    this.state.generalTotal -= someArray[idx].maintenanceKitSalePrice;
    this.PriceCalculate(null);
    someArray.splice(idx, 1);
    await this.setState({
      maintenanceKitDto: someArray,
      counter: this.state.counter - 1,
      alertErrorMessage: "",
    });
    if (idx === 0 && someArray.length === 0) {
      this.setState({
        productGuid: "",
        maintenanceKitDto: [],
        totalSalePrice: 0,
        totalBuyPrice: 0,
        discountPercent: "",
        discountValue: "",
        generalTotal: 0,
        currencyType: "",
        taxRateValue: "",
        taxTypeValue: null,
        discountTotal: 0,
      });
    }
  }

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "totalSalePrice" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var totalSalePrice = parseFloat(this.state.totalSalePrice);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = totalSalePrice - totalSalePrice * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = totalSalePrice - discountValue;
    } else {
      generalTotal = totalSalePrice;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }
    this.setState({
      totalSalePrice: parseFloat(totalSalePrice).toFixed(2),
      generalTotal: parseFloat(generalTotal).toFixed(2),
      discountTotal: parseFloat(discountTotal).toFixed(2),
    });
  };

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      customerError: "",
      employeeError: "",
      productionDateError: "",
      maintenanceDateError: "",
      nextMaintenanceDateError: "",
      productError: "",
      currencyTypeError: "",
      taxTypeError: "",
      taxRateError: "",
      alertErrorMessage: "",
      serviceTimeError: "",
    });
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.employeeGuid === "") {
      this.setState({
        employeeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.iType === "") {
      this.setState({
        TypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.productGuid === undefined || this.state.productGuid === "") {
      this.setState({
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxRateValue === "") {
      this.setState({
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxTypeValue === null) {
      this.setState({
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.customerGuid !== "" &&
      this.state.employeeGuid !== "" &&
      this.state.iType !== "" &&
      this.state.productGuid !== "" &&
      this.state.productGuid !== undefined &&
      (this.state.totalBuyPrice || this.state.totalBuyPrice === 0) &&
      (this.state.totalSalePrice || this.state.totalSalePrice === 0) &&
      this.state.taxRateValue &&
      this.state.taxTypeValue &&
      this.state.isNull === false
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var technicians = [];
      this.state.technicianArr.forEach((element) => {
        var technicianObj = {};
        technicianObj = element.value;
        technicians.push(technicianObj);
      });

      var assistants = [];
      this.state.assistantArr.forEach((element) => {
        var assistantObj = {};
        assistantObj = element.value;
        assistants.push(assistantObj);
      });

      var guaranty = [];
      await this.state.guarantyArr.forEach((element) => {
        var guarantyObj = {};
        guarantyObj = element.value;
        guaranty.push(guarantyObj);
      });
      var myObj = {
        customerGuid: this.state.customerGuid,
        productGuid: this.state.productGuid,
        employeeGuid: this.state.employeeGuid,
        productionDate: this.state.productionDate,
        maintenanceDate: this.state.maintenanceDate,
        nextMaintenanceDate: this.state.nextMaintenanceDate,
        description: this.state.descriptionHtml,
        iType:
          this.state.iType === ""
            ? "00000000-0000-0000-0000-000000000000"
            : this.state.iType,
        technicianId: technicians.toString(),
        customer: this.state.customer === "" ? null : this.state.customer,
        taxType: this.state.taxTypeValue,
        taxRate: parseInt(this.state.taxRateValue),
        total: parseFloat(this.state.totalSalePrice),
        totalBuyPrice: parseFloat(this.state.totalBuyPrice),
        generalTotal: parseFloat(this.state.generalTotal),
        currencyType: this.state.currencyType,
        discountPercent:
          this.state.discountPercent !== ""
            ? parseInt(this.state.discountPercent)
            : 0,
        discountValue:
          this.state.discountValue !== ""
            ? parseFloat(this.state.discountValue)
            : 0,
        discountTotal: parseFloat(this.state.discountTotal),
        serviceMaintenanceKitDto: this.state.maintenanceKitDto,
        loadWorkHour: parseInt(this.state.loadWorkHour),
        totalWorkHour: parseInt(this.state.totalWorkHour),
        workPressure: parseInt(this.state.workPressure),
        guaranty: guaranty.toString(),
        serialNo: this.state.serialNo,
        serviceOrderNo: this.state.serviceOrderNo,
        serviceTime: this.state.serviceTime,
        status: this.state.status,
        assistantId: assistants.toString(),
        serviceImageDto: this.state.serviceImageDto,
        notDescription: this.state.notDescription,
        servicePictureDto: this.state.servicePictureDto,
        isExchangeRate: this.state.isExchangeRate,
        nameSurname: this.state.nameSurname,
        phone: this.state.phone,
        address: this.state.address,
        dolarValue: this.state.dolarValue,
        euroValue: this.state.euroValue,
        workingHours: this.state.workingHours,
        dailyWorkingHours: this.state.dailyWorkingHours
      };
      var URLParam = "/services/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/services/update";
        myObj = {
          guid: this.state.guid,
          customerGuid: this.state.customerGuid,
          productGuid: this.state.productGuid,
          employeeGuid: this.state.employeeGuid,
          productionDate: this.state.productionDate,
          maintenanceDate: this.state.maintenanceDate,
          nextMaintenanceDate: this.state.nextMaintenanceDate,
          description: this.state.descriptionHtml,
          iType:
            this.state.iType === ""
              ? "00000000-0000-0000-0000-000000000000"
              : this.state.iType,
          taxType: this.state.taxTypeValue,
          technicianId: technicians.toString(),
          customer: this.state.customer === "" ? null : this.state.customer,
          taxRate: parseInt(this.state.taxRateValue),
          total: parseFloat(this.state.totalSalePrice),
          totalBuyPrice: parseFloat(this.state.totalBuyPrice),
          generalTotal: parseFloat(this.state.generalTotal),
          serviceTime: this.state.serviceTime,
          currencyType: this.state.currencyType,
          discountPercent:
            this.state.discountPercent !== ""
              ? parseInt(this.state.discountPercent)
              : 0,
          discountValue:
            this.state.discountValue !== ""
              ? parseFloat(this.state.discountValue)
              : 0,
          discountTotal: parseFloat(this.state.discountTotal),
          serviceMaintenanceKitDto: this.state.maintenanceKitDto,
          loadWorkHour: parseInt(this.state.loadWorkHour),
          totalWorkHour: parseInt(this.state.totalWorkHour),
          workPressure: parseInt(this.state.workPressure),
          guaranty: guaranty.toString(),
          serialNo: this.state.serialNo,
          serviceOrderNo: this.state.serviceOrderNo,
          status: this.state.status,
          assistantId: assistants.toString(),
          serviceImageDto: this.state.serviceImageDto,
          notDescription: this.state.notDescription,
          servicePictureDto: this.state.servicePictureDto,
          isExchangeRate: this.state.isExchangeRate,
          nameSurname: this.state.nameSurname,
          phone: this.state.phone,
          address: this.state.address,
          dolarValue: this.state.dolarValue,
          euroValue: this.state.euroValue,
          workingHours: this.state.workingHours,
          dailyWorkingHours: this.state.dailyWorkingHours
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/servis-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="service">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="2" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="iType"
                          label="Servis Türü [*]"
                          placeholder="Servis türü seçiniz..."
                          isMulti={false}
                          isClearable={true}
                          selectValue={this.state.iType}
                          onChange={(option) =>
                            this.handleServiceTypeSelectChange(option)
                          }
                          options={this.state.serviceTypeArr}
                          error={this.state.TypeError}
                        />
                      </div>
                      {this.state.serviceTypeModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalServiceType()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="2">
                    <Label className="font-weight-bolder d-block">
                      Servis Saati
                    </Label>
                    <TimePicker
                      placeholder="Servis saati giriniz"
                      showSecond={false}
                      onChange={(value) => this.handleServiceTimeChange(value)}
                      error={this.state.serviceTimeError}
                      value={this.state.serviceTime}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnDatePicker
                      name="maintenanceDate  "
                      label="Son Bakım Tarihi"
                      selected={
                        this.state.maintenanceDate &&
                          this.state.maintenanceDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.maintenanceDate.split("/")[2] +
                            "-" +
                            this.state.maintenanceDate.split("/")[1] +
                            "-" +
                            this.state.maintenanceDate.split("/")[0]
                          )
                          : this.state.maintenanceDate
                      }
                      onChange={(maintenanceDate) =>
                        this.handleMaintenanceDateChange(maintenanceDate)
                      }
                      error={this.state.maintenanceDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="workingHours"
                      label="Çalışma Saati"
                      value={this.state.workingHours}
                      placeHolder="Lütfen çalışma saatini girin..."
                      autoComplete="off"
                      onChange={this.handleChangeWorkingHours}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="dailyWorkingHours"
                      label="Günlük Çalışma Saati"
                      value={this.state.dailyWorkingHours}
                      placeHolder="Lütfen çalışma saatini girin..."
                      autoComplete="off"
                      onChange={this.handleChangeDailyWorkingHours}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnDatePicker
                      name="nextMaintenanceDate  "
                      label="Bir Sonraki Bakım Tarihi"
                      selected={
                        this.state.nextMaintenanceDate &&
                          this.state.nextMaintenanceDate.toString().indexOf("/") >
                          0
                          ? new Date(
                            this.state.nextMaintenanceDate.split("/")[2] +
                            "-" +
                            this.state.nextMaintenanceDate.split("/")[1] +
                            "-" +
                            this.state.nextMaintenanceDate.split("/")[0]
                          )
                          : this.state.nextMaintenanceDate
                      }
                      onChange={(nextMaintenanceDate) =>
                        this.handleNextMaintenanceDateChange(
                          nextMaintenanceDate
                        )
                      }
                      error={this.state.nextMaintenanceDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="technicianArr"
                      label="Teknisyen"
                      placeholder="Teknisyen seçiniz..."
                      selectValue={this.state.technicianArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTechnicianSelectChange(option)
                      }
                      isMulti={true}
                      options={this.state.technicianOption}
                      error={this.state.technicianError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="assistantArr"
                      label="Yardımcı Personel"
                      placeholder="Yardımcı personel seçiniz..."
                      selectValue={this.state.assistantArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleAssistantSelectChange(option)
                      }
                      isMulti={true}
                      options={this.state.assistantOption}
                      error={this.state.assistantError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="nameSurname"
                      label="Servis Ad Soyad (Boş olursa müşteri adı gelecek!)"
                      value={this.state.nameSurname}
                      placeHolder="Lütfen ad soyad girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="phone"
                      label="Servis Telefon (Boş olursa müşteri telefon gelecek!)"
                      value={this.state.phone}
                      placeHolder="Lütfen telefon girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="address"
                      label="Servis Adresi (Boş olursa müşteri adresi gelecek!)"
                      value={this.state.address}
                      placeHolder="Lütfen servis adresi girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="productionDate "
                      label="Üretim Tarihi"
                      selected={
                        this.state.productionDate &&
                          this.state.productionDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.productionDate.split("/")[2] +
                            "-" +
                            this.state.productionDate.split("/")[1] +
                            "-" +
                            this.state.productionDate.split("/")[0]
                          )
                          : this.state.productionDate
                      }
                      onChange={(productionDate) =>
                        this.handleProductionDateChange(productionDate)
                      }
                      error={this.state.productionDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Lütfen ürün seçiniz..."
                          selectValue={this.state.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductChange(option)
                          }
                          options={this.state.productArr}
                          error={this.state.productError}
                        />
                      </div>
                      {this.state.productModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalProduct()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="workPressure"
                      label="Çalışma Basıncı"
                      placeholder="Lütfen çalışma basıncı girin..."
                      value={this.state.workPressure}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="loadWorkHour"
                      label="Yükte Çalışma Saati"
                      placeholder="Lütfen yükte çalışma saati girin..."
                      value={this.state.loadWorkHour}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="totalWorkHour"
                      label="Toplam Çalışma Saati"
                      placeholder="Lütfen toplam çalışma saati girin..."
                      value={this.state.totalWorkHour}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="serviceOrderNo"
                      label="Servis Sıra No"
                      placeholder="Lütfen servis sıra no girin..."
                      value={this.state.serviceOrderNo}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="serialNo"
                      label="Seri No"
                      placeholder="Lütfen seri no girin..."
                      value={this.state.serialNo}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnReactSelect
                      name="guaranty"
                      label="Garanti Türü"
                      placeholder="Firma garanti türü seçiniz..."
                      isMulti={true}
                      isClearable={true}
                      onChange={(option) => this.handleGuarantyChange(option)}
                      options={this.state.guarantyOption}
                      selectValue={this.state.guarantyArr}
                      error={this.state.guarantyError}
                    />
                  </Col>
                </Row>

                {this.state.maintenanceKitDto.map((maintenanceKitDto, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="4">
                      <CgnReactSelect
                        name="maintenanceKitGuid"
                        label="Bakım Kiti [*]"
                        placeholder="Lütfen bakım kiti seçiniz..."
                        selectValue={maintenanceKitDto.maintenanceKitGuid}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleMaintenanceKitChange(option, idx);
                        }}
                        options={this.state.maintenanceKitArr}
                        error={maintenanceKitDto.maintenanceKitError}
                      />
                    </Col>

                    <Col lg="3">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={maintenanceKitDto.maintenanceKitPiece}
                        onChange={(e) =>
                          this.handleMaintenanceKitPieceChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={maintenanceKitDto.maintenanceKitPieceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="salePrice"
                        label="Birim Fiyatı"
                        value={maintenanceKitDto.salePrice}
                        onChange={(option) =>
                          this.handleMaintenanceKitSalePriceChange(option, idx)
                        }
                        placeHolder="Lütfen birim fiyatı girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={maintenanceKitDto.maintenanceKitSalePriceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="price"
                        label="Satış Fiyatı"
                        value={maintenanceKitDto.price}
                        onChange={(option) =>
                          this.handleMaintenanceKitSalePriceChange(option, idx)
                        }
                        placeHolder="Lütfen satış fiyatı girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={maintenanceKitDto.maintenanceKitSalePriceError}
                        disabled={true}
                      />
                    </Col>

                    <CgnTextbox //// hidden text
                      type="number"
                      name="unitSalePrice"
                      label="Birim Fiyatı [*]"
                      value={maintenanceKitDto.unitSalePrice}
                      onChange={(e) => this.handleMaintenanceKitChange(e, idx)}
                      placeHolder="Lütfen birim fiyatı girin..."
                      maxLength="50"
                      autoComplete="off"
                      hidden={true}
                    />

                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleMaintenanceKitRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col lg="2">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton"
                      onClick={this.handleMaintenanceKitAdd}
                      text={"Bakım Kiti Ekle"}
                    />
                  </Col>
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="totalSalePrice"
                      label="Tutar"
                      value={this.state.totalSalePrice}
                      placeHolder="Lütfen tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                      error={this.state.totalError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountPercent"
                      label="İskonto(Yüzde)"
                      value={this.state.discountPercent}
                      placeHolder="Lütfen iskonto girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountPercent}
                      onChange={this.PriceCalculate}
                      error={this.state.discountPercentError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountValue"
                      label="İskonto(Net Tutar)"
                      value={this.state.discountValue}
                      placeHolder="Lütfen iskonto net tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountValue}
                      onChange={this.PriceCalculate}
                      error={this.state.discountValueError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnReactSelect
                      name="isExchangeRate"
                      label="Döviz Kuru"
                      selectValue={this.state.isExchangeRate}
                      isMulti={false}
                      options={viewExchangeRate}
                      onChange={(isExchangeRate) =>
                        this.handleIsExchangeRateSelectChange(isExchangeRate)
                      }
                      placeholder="Lütfen döviz kuru seçiniz..."
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="exchangeRate"
                      label="Teklif Kuru"
                      value={this.state.exchangeRate}
                      placeHolder="Lütfen teklif kuru girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="dolarValue"
                      label="Dolar Kuru"
                      value={this.state.dolarValue}
                      placeHolder="Lütfen dolar kuru girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="euroValue"
                      label="Euro Kuru"
                      value={this.state.euroValue}
                      placeHolder="Lütfen euro kuru girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="taxTypeValue"
                      label="K.D.V Türü [*]"
                      placeholder="Lütfen K.D.V türü seçiniz..."
                      selectValue={this.state.taxTypeValue}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTaxTypeSelectChange(option)
                      }
                      options={TaxType}
                      error={this.state.taxTypeError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="taxRateValue"
                      label="K.D.V Oranı [*]"
                      placeholder="Lütfen K.D.V oranı seçiniz..."
                      selectValue={this.state.taxRateValue}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTrue}
                      onChange={(option) =>
                        this.handleTaxRateSelectChange(option)
                      }
                      options={TaxRateChoices}
                      error={this.state.taxRateError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    {/* <CgnTextbox
                      inlineStyle={textAreaStyle}
                      className="textAreaStyle225"
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    /> */}
                    <CgnTextEditor
                      name="description"
                      label="Servis Notu"
                      editorState={this.state.description}
                      placeHolder="Lütfen servis notu girin..."
                      onChange={(editorState) =>
                        this.handleEditorChange(
                          editorState,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                      }
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="employeeGuid"
                          label="Servis Ekleyen Personel [*]"
                          placeholder="Personel seçiniz..."
                          selectValue={this.state.employeeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleEmployeeSelectChange(option)
                          }
                          options={this.state.employeeArr}
                          error={this.state.employeeError}
                        />
                      </div>
                      {this.state.membershipModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalEmployee()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="customer"
                      label="Servis Müşteri"
                      placeholder="Lütfen servis müşteri girin..."
                      value={this.state.customer}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleCustomerChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="notDescription"
                      label="Firma Açıklama"
                      value={this.state.notDescription}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      selectValue={this.state.status}
                      isMulti={false}
                      options={optionServiceStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <ImageUploadMulti
                      images={this.state.serviceImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Servis Dosyaları"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                    //error={this.state.imageError2}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <PictureUploadMulti
                      image={this.state.servicePictureDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Servis Fotoğrafları"}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      alt={this.state.name}
                    //error={this.state.imageError2}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        {/* <Modal
          isOpen={this.state.isOpenModalProduct2}
          toggle={this.addModalProduct2}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Bakım Kiti Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct2}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal> */}

        <Modal
          isOpen={this.state.isOpenModalEmployee}
          toggle={this.addModalEmployee}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Personel Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <EmployeeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalEmployee}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalServiceType}
          toggle={this.addModalServiceType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Servis Türü Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ServiceTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalServiceType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ServiceAddUpdate;
