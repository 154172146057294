import React from "react";
import packageJson from "../../../package.json";

class FooterContent extends React.Component {
  state = {
    companyId: 0,
  };

  async componentDidMount() {
    let urlSplit1 = window.location.href.split("//");
    let urlSplit2 = urlSplit1[1].split("/");
    if (
      urlSplit2[0] === "crm.medikozceviri.com" ||
      urlSplit2[0] === "crm.cevirikalitesi.com"
    ) {
      await this.setState({
        companyId: 1,
      });
    }
    if (
      urlSplit2[0] === "crm.alkom.com.tr"
    ) {
      await this.setState({
        companyId: 3,
      });
    }
  }

  render() {
    return (
      <>
        <div
          className="footer"
          style={{ display: this.state.companyId === 0 ? "" : "none" }}
        >
          <p className="m-0">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://www.cogen.com.tr"
              alt="Cogen® Yazılım | CRM"
              target="blank"
            >
              Cogen® Yazılım | CRM
            </a>{" "}
            | Tüm hakları saklıdır. | Versiyon : {packageJson.version}
          </p>
          <p className="note">Teknik destek talepleriniz için lütfen sol menünün en altındaki iletişim adreslerinden bizimle iletişime geçiniz.</p>
        </div>

        <div
          className="footer"
          style={{ display: this.state.companyId === 1 ? "" : "none" }}
        >
          <p className="m-0">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://www.medikozceviri.com/"
              alt="Medikoz Tercüme | CRM"
              target="blank"
            >
              Medikoz Tercüme | CRM
            </a>{" "}
            | Tüm hakları saklıdır. | Versiyon : {packageJson.version}
          </p>
        </div>

        <div
          className="footer"
          style={{ display: this.state.companyId === 3 ? "" : "none" }}
        >
          <p className="m-0">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://www.alkom.com.tr/"
              alt="Alkom | CRM"
              target="blank"
            >
              Alkom | CRM
            </a>{" "}
            | Tüm hakları saklıdır. | Versiyon : {packageJson.version}
          </p>
        </div>
      </>
    );
  }
}

export default FooterContent;
