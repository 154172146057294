import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL,  localFilePath } from "../../redux/actions/constants";

class ManuelControl2Detail extends Component {
  state = {
    pageTitle: "Manuel Kontrol Raporu 2 Detay",
    pageTitleDetail: "Manuel Kontrol Raporu 2 Detay",
    pageAddLink: "manuel-kontrol-raporu2-ekle",
    pageListLink: "manuel-kontrol-raporu2-listele",
    breadcrumb: [{ text: "Manuel Kontrol Raporu 2", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    productName: "",
    customerName: "",
    productOutName: "",
    currencyType: "",
    workInstructionCode: "",
    supplierName: "",
    detectionPersonelName: "",
    itemNumber: "",
    nmsNo: "",
    serialNo: "",
    cocNo: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    workDate: "",
    productOutDate: "",
    productOutCustomer: "",
    productOutTotal: "",
    manuelControlPin2Dto: [],
   };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/manuelControls2/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            workInstructionCode: response.data.workInstructionCode,
            customerName: response.data.customerName,
            productCode: response.data.productCode,
            productName: response.data.productName,
            productOutCustomer: response.data.productOutCustomer,
            productOutDate: response.data.productOutDate,
            productOutTotal: response.data.productOutTotal,
            manuelControlDate: response.data.manuelControlDate,
            manuelControlPin2Dto: response.data.manuelControlPin2Dto,
            currencyType: response.data.currencyType,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            supplierName: response.data.supplierName,
            detectionPersonelName: response.data.detectionPersonelName,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/manuelControls2/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          printLink={onClick => this.handlePdfPrint(this.state.guid)}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <td colSpan="4">
                      {this.state.manuelControlDate}
                    </td>
                  </tr>             
                  <tr>
                    <th>İş Emri Numarası</th>
                    <td colSpan="4">{this.state.workInstructionCode}</td>
                  </tr>
                  <tr>
                    <th>Tespiti Yapan Personel</th>
                    <td colSpan="4">{this.state.detectionPersonelName}</td>
                  </tr>
                  <tr>
                    <th>Tedarikçi</th>
                    <td colSpan="4">{this.state.supplierName}</td>
                  </tr>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">{this.state.customerName}</td>
                  </tr>
                  <tr>
                    <th>Satış</th>
                    <td colSpan="4">{this.state.productOutDate + "-" + this.state.productOutCustomer}</td>
                  </tr>
                  <tr>
                    <th>Ürün</th>
                    <td colSpan="4">{this.state.productName}</td>
                  </tr>
                
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.manuelControlPin2Dto !== null &&
                this.state.manuelControlPin2Dto.length > 0 && (
                  <>
                  <div
                  className="row"
                  style={{
                    display:
                      self.manuelControlPin2Dto !== null && self.manuelControlPin2Dto.length > 0
                        ? ""
                        : "none",
                  }}
                >
                  <h2 className="mt-2">Konnektör Bilgileri</h2>
                </div>
                <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Çıkış Konnektörü</th>
                      <th className="text-center">Pin No</th>
                      <th className="text-center">Varış Konnektörü</th>
                      <th className="text-center">Pin No</th>
                      <th>Uygunluk</th>
                    </tr>
                    {this.state.manuelControlPin2Dto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.outConnector}</td>
                          <td className="text-center">{item.pinNo}</td>
                          <td className="text-center"> {item.arrivalConnector}</td>
                          <td className="text-center">{item.pinNo2}</td>
                          <td className="font-weight-bold"> {item.statusChange === 1 ? "Uygun" : item.statusChange === 2 ? "Uygunsuz" : item.statusChange === 3 ? "Atla" : "-"}</td>
                        </tr>
                      );
                    })}
                  </Table>
                  </>
                )}

           </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ManuelControl2Detail;
