import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  localFilePath,
  GetMonthFirstDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  GetMonthLastDate,
  CustomerList,
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import moment from "moment";
import CgnBadge from "../toolbox/CgnBadge";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class AssignmentList extends Component {
  state = {
    pageTitle: "İş Atama Listele",
    pageAddLink: "is-atama-ekle",
    pageUpdateLink: "is-atama-guncelle",
    pageDetailLink: "is-atama-detay",
    pageListLink: "is-atama-listele",
    pageMachineServiceAddLink: "makine-servis-ekle",
    pageMachineServiceDetailLink: "makine-servis-detay",
    breadcrumb: [{ text: "İş Atama", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    assignmentData: [],
    statusAll: 0,
    status1: 0,
    status2: 0,
    status3: 0,
    assignmentDate: "",
    serviceDate: "",
    machineName: "",
    faultStatusName: "",
    complaintReasonName: "",
    complaintDescription: "",
    brandName: "",
    modelName: "",
    city: "",
    machineServiceGuid: "",
    personelName: "",
    machineSerialNo: "",
    guid: "",
    createdAtDateTime: "",
    alertErrorMessage: "",
    personelError: "",
    personelGuid: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    isOpenModalPersonel: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      personelModalPlus: await UserOperationClaimControl(1097),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/members/getmemberspersonel/" + 2, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          personelArr: myArr,
          isOpenModalPersonel: !this.state.isOpenModalPersonel,
          alertModalErrorMessage: "",
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    this.setState({
      isOpenModalPersonel: false,
    });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateAssignment")) {
      firstDate = localStorage.getItem("firstDateAssignment");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateAssignment")) {
      lastDate = localStorage.getItem("lastDateAssignment");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidAssignment")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidAssignment"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateAssignment", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateAssignment", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidAssignment", this.state.customerGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    let urlSplit = window.location.pathname.split("/");
    var urlSplitX = urlSplit.length > 2 ? urlSplit[2] : 0;
    this.setState({
      urlSplitX: urlSplitX,
    });

    URLParam =
      "/assignment/getliststatus/" +
      urlSplitX +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.customerGuid;

    var statusType = 2;
    axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          assignmentData: response.data.assignmentDtos,
          statusAll: response.data.statusAll,
          status1: response.data.status1,
          status2: response.data.status2,
          status3: response.data.status3,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineService/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalPersonel = (guid) => {
    this.setState({
      isOpenModalPersonel: !this.state.isOpenModalPersonel,
      assignmentGuid: guid,
    });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineService/sendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleSend = (guid) => {
    confirmAlert({
      title: CgnMessage.assignmentSendQuestionTitle,
      message: CgnMessage.assignmentSendQuestionMessage,
      buttons: [
        {
          label: CgnMessage.assignmentSendQuestionYes,
          onClick: () => this.addModalPersonel(guid),
        },
        {
          label: CgnMessage.assignmentSendQuestionNo,
        },
      ],
    });
  };

  async handleSendPersonel(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/assignment/assignmentsend";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handlePersonelSelectChange(option) {
    if (option) {
      await this.setState({
        personelGuid: option.value,
        personelError: "",
        alertModalErrorMessage: "",
      });
    } else {
      await this.setState({
        personelGuid: 0,
        personelError: CgnMessage.textErrorMessage,
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/assignment/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      personelError: "",
    });
    if (this.state.personelGuid === 0) {
      this.setState({
        personelError: CgnMessage.textErrorMessage,
      });
    } else {
      this.setState({
        personelError: "",
        isLoading: true,
        isLoadingModal: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        personelGuid: this.state.personelGuid,
        guid: this.state.assignmentGuid,
      };
      var URLParam = "/assignment/personelupdate/";
      axios.post(URL + URLParam, myObj, config).then(() => {
        setTimeout(() => {
          window.location.href = "/is-atama-listele";
        }).catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isOpenModalReply: !this.state.isOpenModalReply,
            isLoading: false,
            isLoadingModal: false,
          });
        });
      });
    }
  };

  thStyle = {
    width: "110px",
  };

  thStyle2 = {
    width: "130px",
  };

  tdStyle = {
    width: "350px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="assignmentList" id="list">
          <Col>
            <div className="d-inline-block mt-2 w-100">
              <a
                href={`/${this.state.pageListLink}/${1}`}
                className={
                  this.state.urlSplitX === "1"
                    ? "float-left statusTopButton mr-2 btn btn-primary"
                    : "float-left statusTopButton mr-2 bg-success"
                }
              >{`Beklemede: ${this.state.status1}`}</a>
              <a
                href={`/${this.state.pageListLink}/${2}`}
                className={
                  this.state.urlSplitX === "2"
                    ? "float-left statusTopButton mr-2 btn btn-primary"
                    : "float-left statusTopButton mr-2 bg-danger"
                }
              >{`Kapatıldı (Aktif): ${this.state.status2}`}</a>
              <a
                href={`/${this.state.pageListLink}/${3}`}
                className={
                  this.state.urlSplitX === "3"
                    ? "float-left statusTopButton mr-2 btn btn-primary"
                    : "float-left statusTopButton mr-2 bg-warning"
                }
              >{`Kapatıldı (Pasif): ${this.state.status3}`}</a>
              <a
                href={`/${this.state.pageListLink}`}
                className={
                  this.state.urlSplitX === 0
                    ? "float-left statusTopButton mr-2 btn btn-primary"
                    : "float-left statusTopButton mr-2 bg-info"
                }
              >{`Tümü: ${this.state.statusAll}`}</a>
            </div>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="7">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.assignmentData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "İş Atama Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Makine</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.customerName}</td>
                              <th style={this.thStyle2}>Personel</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.personelName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Arıza Durumu</th>
                              {/* <th className="colon">:</th> */}
                              <td
                                style={{
                                  color:
                                    row.faultStatusName === "KURULUM" ||
                                    row.faultStatusName === "Kurulum" ||
                                    row.faultStatusName === "kurulum"
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {row.faultStatusName}
                              </td>
                              <th style={this.thStyle2}>Şikayet Sebebi</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.complaintReasonName
                                  ? row.complaintReasonName
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Şehir</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.city ? row.city : "-"}</td>
                              <th style={this.thStyle2}>Tarih</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {moment(row.createdAtDateTime).format(
                                  "DD/MM/YYYY"
                                )
                                  ? moment(row.createdAtDateTime).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Bölge</th>
                              <td style={this.tdStyle}>{row.areaName}</td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (
                        row.machineServiceGuid ===
                        "00000000-0000-0000-0000-000000000000"
                      ) {
                        return (
                          <CgnBadge
                            color="success"
                            className="w-100"
                            text={"Bekliyor: " + row.dayCount}
                          />
                        );
                      } else {
                        if (row.machineServiceStatus === 1) {
                          return (
                            <CgnBadge
                              color="danger"
                              className="w-100"
                              text={"Kapatıldı: " + row.dayCount}
                            />
                          );
                        } else {
                          return (
                            <CgnBadge
                              color="success"
                              className="w-100"
                              style={{ backgroundColor: "#ff7600" }}
                              text={"Kapatıldı: " + row.dayCount}
                            />
                          );
                        }
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0 w-100"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                          {
                            text:
                              row.machineServiceGuid ===
                              "00000000-0000-0000-0000-000000000000"
                                ? ""
                                : "Servis Detay",
                            className: "dropDownBtn",
                            href: `/${this.state.pageMachineServiceDetailLink}/${row.machineServiceGuid}`,
                          },
                          {
                            text:
                              row.machineServiceGuid ===
                              "00000000-0000-0000-0000-000000000000"
                                ? ""
                                : "Servis Yazdır",
                            className: "dropDownBtn",
                            onClick: () =>
                              this.handlePdfPrint(row.machineServiceGuid),
                          },
                          {
                            text:
                              row.machineServiceGuid ===
                              "00000000-0000-0000-0000-000000000000"
                                ? ""
                                : "Servis E-Mail Gönder",
                            className: "dropDownBtn",
                            onClick: () =>
                              this.handlePdfSendMail(row.machineServiceGuid),
                          },
                          {
                            text:
                              row.machineServiceGuid !==
                              "00000000-0000-0000-0000-000000000000"
                                ? ""
                                : "Personel Değiştir",
                            className: "dropDownBtn",
                            onClick: () => this.handleSend(row.guid),
                            style: {
                              display: this.state.personelModalPlus
                                ? "block"
                                : "none",
                            },
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalPersonel}
          toggle={this.addModalPersonel}
          className="modal-xs content"
        >
          <ModalHeader className="font-weight-bold">Personel Ata</ModalHeader>
          <ModalBody className="modalBody">
            <CgnLoader visibled={this.state.isLoadingModal} />
            <Row>
              <Col>
                <CgnReactSelect
                  name="personelGuid"
                  label="Personel [*]"
                  placeholder="Lütfen personel seçiniz..."
                  selectValue={this.state.personelGuid}
                  isMulti={false}
                  isClearable={true}
                  onChange={(option) => this.handlePersonelSelectChange(option)}
                  options={this.state.personelArr}
                  error={this.state.personelError}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.alertModalErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertModalErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="primary"
              onClick={this.handleSave}
              text="Kaydet"
              className="modalButton"
            />
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalPersonel}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default AssignmentList;
