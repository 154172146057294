import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  TaxTypeOptions,
  URL,
  
  localFilePath,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";

class MachineServiceDetail extends Component {
  state = {
    pageTitle: "Makine Servis Detay",
    pageTitleDetail: "Makine Servis Detay",
    pageAddLink: "makine-servis-ekle",
    pageUpdateLink: "makine-servis-guncelle",
    pageListLink: "makine-servis-listele",
    breadcrumb: [{ text: "Makine Servis", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    departureTime: "",
    timeOfArrival: "",
    guarantyTypeGuid: null,
    guid: "",
    totalAmount: 0,
    amount: 0,
    assignmentGuid: null,
    description: "",
    overralAmount: null,
    percentage: "",
    KDVRate: "",
    KDVType: null,
    discount: "",
    currency: 0,
    guarantyTypeName: "",
    currencyError: "",
    departureTimeError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    status: true,
    locationGuid: null,
    locationError: "",
    processTypeGuid: null,
    processTypeError: "",
    timeOfArrivalError: "",
    guarantyTypeError: "",
    totalAmountError: "",
    KDVRateError: "",
    KDVTypeError: "",
    isDeleted: false,
    modalButtonIdx: 0,
    wageStatus: 0,
    counter: -1,
    machineServiceProcessDto: [],
    machineServiceSparePartDto: [],
    multipleProductArr: [],
    guarantyTypeArr: [],
    machineServiceProcessError: "",
    machineServiceSparePartError: "",
    productUnitPriceError: "",
    productPieceError: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if(urlSplit.length === 4)
    {
      this.setState({
        pageAddLink: "",
        pageListLink: parseInt(urlSplit[3]) === 1 ? "bekleyen-is-atama-listele" : "tamamlanan-is-atama-listele",
      });
      urlSplit.splice(3, 1);
    }
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/machineService/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            departureTime: response.data.departureTime,
            timeOfArrival: response.data.timeofArrival,
            guarantyTypeGuid: response.data.guarantyTypeGuid !== null ? response.data.guarantyTypeGuid : "00000000-0000-0000-0000-000000000000",
            guarantyTypeName: response.data.guarantyTypeName !== null ? response.data.guarantyTypeName : "-",
            machineServiceProcessDto: response.data.machineServiceProcessDto,
            machineServiceSparePartDto:
              response.data.machineServiceSparePartDto,
            amount: response.data.amount.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            overralAmount: response.data.overralAmount.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            currency: response.data.currency,
            percentage: response.data.percentage,
            discount: response.data.discount.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            totalAmount: response.data.totalAmount.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            KDVType: response.data.kdvType,
            KDVRate: response.data.kdvRate,
            description: response.data.description,
            interventionDate: response.data.interventionDate,
            assignmentDate: response.data.assignmentDate,
            wageStatus: response.data.wageStatus, 
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineservice/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineservice/sendmail/" +guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "120px",
  };

  tdStyle = {
    width: "120px",
  };


  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    const textRight = {
      textAlign: 'right',
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          updateLink={"/" + this.state.pageUpdateLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Varış Saati</th>
                    <td>
                      {this.state.departureTime === null
                        ? "-"
                        : this.state.departureTime}
                    </td>
                    <th>Ayrılış Saati</th>
                    <td>
                      {this.state.timeOfArrival === null
                        ? "-"
                        : this.state.timeOfArrival}
                    </td>
                  </tr>

                  <tr>
                    <th>Garanti Türü</th>
                    <td colSpan="4">{this.state.guarantyTypeName}</td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description === ""
                        ? "-"
                        : this.state.description}
                    </td>
                  </tr>

                  <tr style={{display: this.state.wageStatus === 1 ? '' : 'none' }}>
                    <th>Tutar</th>
                    <td colSpan="4">
                      {this.state.amount
                        ? this.state.amount +
                          " " +
                          (this.state.currency === 1
                            ? "₺"
                            : this.state.currency === 2
                            ? "$"
                            : this.state.currency === 3
                            ? "€"
                            : "")
                        : ""}
                    </td>
                  </tr>

                  
                  <tr style={{display: this.state.wageStatus === 1 ? '' : 'none' }}>
                    <th>İskonto(Yüzde)</th>
                    <td>
                      {this.state.percentage
                        ? "% " + this.state.percentage
                        : "-"}
                    </td>
                    <th>İskonto(Net Tutar)</th>
                    <td>
                      {this.state.discount && this.state.discount > 0
                        ? this.state.discount +
                          " " +
                          (this.state.currency === 1
                            ? "₺"
                            : this.state.currency === 2
                            ? "$"
                            : this.state.currency === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  
                  </tr>
                  <tr style={{display: this.state.wageStatus === 1 ? '' : 'none' }}>
                  <th>K.D.V. Türü</th>
                    <td>{TaxTypeOptions(this.state.KDVType)}</td>
                    <th>K.D.V. Oranı</th>
                    <td>
                      {this.state.KDVRate ? "% " + this.state.KDVRate : ""}
                    </td>
                  </tr>


                  <tr style={{display: this.state.wageStatus === 1 ? '' : 'none' }}>
                    <th>Genel Tutar</th>
                    <td>
                      {this.state.overralAmount
                        ? this.state.overralAmount +
                          " " +
                          (this.state.currency === 1
                            ? "₺"
                            : this.state.currency === 2
                            ? "$"
                            : this.state.currency === 3
                            ? "€"
                            : "")
                        : ""}
                    </td>
                    <th>Toplam Tutar</th>
                    <td>
                      {this.state.totalAmount
                        ? this.state.totalAmount +
                          " " +
                          (this.state.currency === 1
                            ? "₺"
                            : this.state.currency === 2
                            ? "$"
                            : this.state.currency === 3
                            ? "€"
                            : "")
                        : ""}
                    </td>
                  </tr>              
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.machineServiceSparePartDto !== null &&
                this.state.machineServiceSparePartDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-spare">
                    <tr style={mainRow}>
                      <th className="text-left pl-3">Depo</th>
                      <th className="text-left pl-3">Ürün</th>
                      <th className="text-left pl-3">Açıklama </th>
                      <th className="text-center">Adet</th>
                      <th className="text-right pr-3">Birim Fiyat</th>
                      <th className="text-right pr-3">Fiyat</th>
                    </tr>
                    {this.state.machineServiceSparePartDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td className="text-left pl-3">{item.locationName}</td>
                          <td className="text-left pl-3">{item.productName}</td>
                          <td className="text-left pl-3">
                            {item.description === null ? "-" : item.description}
                          </td>
                          <td className="text-center">{item.piece}</td>
                          <td className="text-right pr-3">
                            {item.unitPrice.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.currency === 1
                                ? "₺"
                                : item.currency === 2
                                ? "$"
                                : item.currency === 3
                                ? "€"
                                : "")}
                          </td>
                          <td className="text-right pr-3">
                            {item.price.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.currency === 1
                                ? "₺"
                                : item.currency === 2
                                ? "$"
                                : item.currency === 3
                                ? "€"
                                : "")}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
              {this.state.machineServiceProcessDto !== null &&
                this.state.machineServiceProcessDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-spare">
                    <tr style={mainRow}>
                      <th className="text-left pl-3">İşlem Tipi</th>
                      <th className="text-left pl-3">Açıklama</th>
                    </tr>
                    {this.state.machineServiceProcessDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td className="text-left pl-3">{item.processTypeName}</td>
                          <td className="text-left pl-3">
                            {item.description === null ? "-" : item.description}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}          
            </Card>
            <CgnButton
              type="button"
              color="success"
              className="printButton mr-3"
              onClick={() => this.handlePdfPrint(this.state.guid)}
              text={"Yazdır"}
            />
            <CgnButton
              type="button"
              color="primary"
              className="sendEmailButton"
              onClick={() => this.handlePdfSendMail(this.state.guid)}
              text={"E-Mail Gönder"}
            />
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineServiceDetail;
