import React, { Component } from "react";
import { Row, Col, Card, Form, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses, yesNoStatus, UserOperationClaimControl, fotoStyle, languages } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnButton from "../toolbox/CgnButton";
import DocumentTypeAddUpdate from "../documentType/DocumentTypeAddUpdate";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import noImg from "../../images/no-img.png";
import moment from "moment";
class DocumentTrackingAddUpdate extends Component {
    state = {
        pageTitle: "Belge Takip Ekle",
        pageTitleAdd: "Belge Takip Ekle",
        pageTitleUpdate: "Belge Takip Güncelle",
        pageAddLink: "belge-takip-ekle",
        pageListLink: "belge-takip-listele",
        breadcrumb: [{ text: "Belge Takip", link: "#" }],
        isLoading: false,
        urlSplit: [],
        guid: "",
        language: 0,
        languageError: "",
        customerGuid: "",
        customerGuidError: "",
        documentTypeGuid: "",
        documentTypeGuidError: "",
        documentTypeNote: "",
        documentTypeNoteError: "",
        isUnlimitedTime: false,
        isUnlimitedTimeError: "",
        validityDate: "",
        validityDateError: "",
        isUTSRecord: false,
        isUTSRecordError: "",
        documentNumber: "",
        documentNumberError: "",
        utsRecordDate: "",
        utsRecordDateError: "",
        note: "",
        opinions: "",
        documentTrackingFilesDto: [],
        documentTrackingFilesDtoError: "",
        isReminderEMail: true,
        status: true,
        alertErrorMessage: "",
        buttons: [
            {
                type: "button",
                color: "secondary",
                text: CgnMessage.clearButton,
                onClick: (event) => this.handleClear(event),
                disabled: this.isLoading,
            },
            {
                type: "submit",
                color: "primary",
                text: CgnMessage.saveButton,
                disabled: this.isLoading,
            },
        ],
        documentTypeArr: [],
        isOpenModalDocumentType: false,
        customerArr: [],
        isOpenModalCustomer: false,
        customerModalPlus: false,
        documentTypeModalPlus: false,
        eMails: "",
        affectedDevice: "",
        affectedDeviceError: "",
        plannedClosingDate: "",
        plannedClosingDateError: "",
    };
    async componentDidMount() {
        this.setState({
            isLoading: true,
            customerModalPlus: await UserOperationClaimControl(1012),
            documentTypeModalPlus: await UserOperationClaimControl(1049),
        });
        // 
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        var statusType = 2; // sadece aktif olanları göstermesi için
        await axios
            .get(URL + "/customers/getlist/" + statusType, config)
            .then((response) => {
                var myArr = response.data.map(function (item) {
                    return {
                        value: item.guid,
                        label: item.name,
                        customerGuid: item.customerGuid,
                    };
                });
                this.setState({
                    customerArr: myArr,
                });
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
        axios
            .get(URL + "/documentTypes/getlist/" + statusType, config)
            .then((response) => {
                var myArr = response.data.map(function (item) {
                    return {
                        value: item.guid,
                        label: item.name,
                    };
                });
                myArr.push({
                    value: "4f66b9fa-33c8-43ec-930e-ef7dc8582055",
                    label: "Diğer",
                });
                this.setState({
                    documentTypeArr: myArr,
                });
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
        let urlSplit = window.location.pathname.split("/");
        this.setState({
            urlSplit: urlSplit,
        });
        if (urlSplit.length === 3) {
            this.setState({
                guid: urlSplit[urlSplit.length - 1],
                pageTitle: this.state.pageTitleUpdate,
                isLoading: true,
            });
            this.getById(urlSplit[2]);
            axios
                .get(URL + "/documenttracking/get/" + urlSplit[2], config)
                .then((response) => {
                    if (this.state.guid) {
                        this.setState({
                            guid: response.data.guid,
                            language: response.data.language,
                            customerGuid: response.data.customerGuid,
                            documentTypeGuid: response.data.documentTypeGuid,
                            documentTypeNote: response.data.documentTypeNote,
                            isUnlimitedTime: response.data.isUnlimitedTime,
                            validityDate: response.data.validityDate,
                            isUTSRecord: response.data.isUTSRecord,
                            documentNumber: response.data.documentNumber,
                            utsRecordDate: response.data.utsRecordDate !== '01/01/1900' ? response.data.utsRecordDate : '',
                            note: response.data.note,
                            opinions: response.data.opinions,
                            isReminderEMail: response.data.isReminderEMail,
                            documentTrackingFilesDto: response.data.documentTrackingFilesDto,
                            eMails: response.data.eMails,
                            status: response.data.status,
                            isLoading: false,
                            plannedClosingDate: response.data.plannedClosingDate !== '01/01/1900' ? response.data.plannedClosingDate : '',
                            affectedDevice: response.data.affectedDevice,
                        });

                    }
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }
    async componentDidUpdate() {
        let urlSplit = window.location.pathname.split("/");
        if (
            urlSplit.length === 2 &&
            this.state.pageTitle !== this.state.pageTitleAdd
        ) {
            this.setState({
                urlSplit: urlSplit,
                pageTitle: this.state.pageTitleAdd,
                guid: "",
                name: "",
                status: true,
            });
        }
        if (this.state.status === undefined) {
            this.setState({
                status: true,
            });
        }
        if (
            this.state.isOpenModalDocumentType &&
            this.props.location.modalAddedDocumentType !== undefined &&
            this.props.location.modalAddedDocumentType.isModal !== undefined &&
            this.props.location.modalAddedDocumentType.isModal === false
        ) {
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            var statusType = 2; // sadece aktif olanları göstermesi için
            axios
                .get(URL + "/documentTypes/getlist/" + statusType, config)
                .then((response) => {
                    var myArr = response.data.map(function (item) {
                        return {
                            value: item.guid,
                            label: item.name,
                        };
                    });
                    this.setState({
                        documentTypeArr: myArr,
                        isLoading: false,
                    });
                    response.data.forEach((element) => {
                        if (
                            this.props.location.modalAddedDocumentType.data &&
                            element.name === this.props.location.modalAddedDocumentType.data
                        ) {
                            this.setState({
                                documentTypeGuid: element.guid,
                                documentTypeError: "",
                                alertErrorMessage: "",
                            });
                        }
                    });
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
            this.props.location.modalAddedDocumentType.isModal = true;
            this.setState({
                isOpenModalDocumentType: false,
            });
        }
        if (
            this.state.isOpenModalCustomer &&
            this.props.location.modalAddedCustomer !== undefined &&
            this.props.location.modalAddedCustomer.isModal !== undefined &&
            this.props.location.modalAddedCustomer.isModal === false
        ) {
            config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            statusType = 2;
            axios
                .get(URL + "/customers/getlist/" + statusType, config)
                .then((response) => {
                    var myArr = response.data.map(function (item) {
                        return {
                            value: item.guid,
                            label: item.name,
                        };
                    });
                    this.setState({
                        customerArr: myArr,
                        isLoading: false,
                    });
                    response.data.forEach((element) => {
                        if (
                            this.props.location.modalAddedCustomer.data &&
                            element.name === this.props.location.modalAddedCustomer.data
                        ) {
                            this.setState({
                                customerGuid: element.guid,
                                customerError: "",
                                alertErrorMessage: "",
                            });
                        }
                    });
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
            this.props.location.modalAddedCustomer.isModal = true;
            this.setState({
                isOpenModalCustomer: false,
            });
        }
    }
    getById(guid) {
        this.setState({
            isLoading: true,
            guid: guid,
        });
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
        this.setState({
            nameError: "",
            alertErrorMessage: "",
        });
        if (name === "name" && value === "") {
            this.setState({
                nameError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    };
    handleClear = (event) => {
        this.clearPage();
    };
    clearPage() {
        this.setState({
            name: "",
            status: true,
            alertErrorMessage: CgnMessage.alertErrorMessage,
            nameError: CgnMessage.textErrorMessage,
        });
    }
    handleStatusSelectChange(status) {
        this.setState({ status: status.value });
    }
    handleSave = async (event) => {
        event.preventDefault();
        this.setState({
            alertErrorMessage: "",
            languageError: "",
            customerGuidError: "",
            documentTypeGuidError: "",
            documentTypeNoteError: "",
            validityDateError: "",
            documentNumberError: "",
            utsRecordDateError: "",
            affectedDeviceError: "",
            plannedClosingDateError: "",
        });
        if (this.state.language === 0) {
            this.setState({
                languageError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if (this.state.customerGuid === "") {
            this.setState({
                customerGuidError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if (this.state.documentTypeGuid === "") {
            this.setState({
                documentTypeGuidError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if ((this.state.documentTypeGuid === "4f66b9fa-33c8-43ec-930e-ef7dc8582055" ||
                    this.state.documentTypeGuid === "dd09a126-e296-4972-8668-b7dc7ee004ac" ||
                    this.state.documentTypeGuid === "4bddd9c2-7576-4e88-a009-c2b3a6048d08") && this.state.documentTypeNote === "") {
            this.setState({
                documentTypeNoteError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if ((this.state.documentTypeGuid === "4f66b9fa-33c8-43ec-930e-ef7dc8582055" ||
                    this.state.documentTypeGuid === "653a8b04-2510-4e95-8a0d-1927434415ac" ||
                    this.state.documentTypeGuid === "61ffe32b-31da-409d-9281-98cd35de5c13" ||
                    this.state.documentTypeGuid === "7e3a35d6-1987-413c-93d5-b6e820c1d108"
            ) && this.state.isUnlimitedTime === false && this.state.validityDate === "") {
                this.setState({
                    validityDateError: CgnMessage.textErrorMessage,
                    alertErrorMessage: CgnMessage.alertErrorMessage,
                });
        } else if ((this.state.documentTypeGuid === "4f66b9fa-33c8-43ec-930e-ef7dc8582055" ||
                    this.state.documentTypeGuid === "653a8b04-2510-4e95-8a0d-1927434415ac" ||
                    this.state.documentTypeGuid === "61ffe32b-31da-409d-9281-98cd35de5c13" ||
                    this.state.documentTypeGuid === "7e3a35d6-1987-413c-93d5-b6e820c1d108"
                    ) && this.state.isUTSRecord === true && this.state.documentNumber === "") {
            this.setState({
                documentNumberError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if ((this.state.documentTypeGuid === "dd09a126-e296-4972-8668-b7dc7ee004ac" ||
                this.state.documentTypeGuid === "4bddd9c2-7576-4e88-a009-c2b3a6048d08"
            ) && this.state.plannedClosingDate === "") {
                this.setState({
                    plannedClosingDateError: CgnMessage.textErrorMessage,
                    alertErrorMessage: CgnMessage.alertErrorMessage,
                });
        } else if ((this.state.documentTypeGuid === "dd09a126-e296-4972-8668-b7dc7ee004ac" ||
                this.state.documentTypeGuid === "4bddd9c2-7576-4e88-a009-c2b3a6048d08"
            ) && this.state.affectedDevice === "") {
            this.setState({
                affectedDeviceError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else {
            this.setState({
                isLoading: true,
            });
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            var myObj = {
                language: this.state.language,
                customerGuid: this.state.customerGuid,
                documentTypeGuid: this.state.documentTypeGuid,
                documentTypeNote: this.state.documentTypeNote,
                isUnlimitedTime: this.state.isUnlimitedTime,
                validityDate: this.state.validityDate ? moment(this.state.validityDate).format("DD/MM/YYYY") : null,
                isUTSRecord: this.state.isUTSRecord,
                documentNumber: this.state.documentNumber,
                utsRecordDate: this.state.utsRecordDate ? moment(this.state.utsRecordDate).format("DD/MM/YYYY") : null,
                note: this.state.note,
                opinions: this.state.opinions,
                documentTrackingFilesDto: this.state.documentTrackingFilesDto,
                isReminderEMail: this.state.isReminderEMail,
                eMails: this.state.eMails,
                status: this.state.status,
                plannedClosingDate: this.state.plannedClosingDate ? moment(this.state.plannedClosingDate).format("DD/MM/YYYY") : null,
                affectedDevice: this.state.affectedDevice,
            };
            var URLParam = "/documenttracking/add";
            if (this.state.guid && this.state.urlSplit.length !== 2 && this.state.urlSplit[1] === "belge-takip-guncelle") {
                URLParam = "/documenttracking/update";

                myObj = {
                    guid: this.state.guid,
                    language: this.state.language,
                    customerGuid: this.state.customerGuid,
                    documentTypeGuid: this.state.documentTypeGuid,
                    documentTypeNote: this.state.documentTypeNote,
                    isUnlimitedTime: this.state.isUnlimitedTime,
                    validityDate:
                        this.state.validityDate &&
                            this.state.validityDate.toString().indexOf("/") > 0
                            ? new Date(
                                this.state.validityDate.split("/")[2] +
                                "-" +
                                this.state.validityDate.split("/")[1] +
                                "-" +
                                this.state.validityDate.split("/")[0]
                            )
                            : this.state.validityDate ? moment(this.state.validityDate).format("DD/MM/YYYY") : null,
                    isUTSRecord: this.state.isUTSRecord,
                    documentNumber: this.state.documentNumber,
                    utsRecordDate:
                        this.state.utsRecordDate &&
                            this.state.utsRecordDate.toString().indexOf("/") > 0
                            ? new Date(
                                this.state.utsRecordDate.split("/")[2] +
                                "-" +
                                this.state.utsRecordDate.split("/")[1] +
                                "-" +
                                this.state.utsRecordDate.split("/")[0]
                            )
                            : this.state.utsRecordDate ? moment(this.state.utsRecordDate).format("DD/MM/YYYY") : null,
                    note: this.state.note,
                    opinions: this.state.opinions,
                    documentTrackingFilesDto: this.state.documentTrackingFilesDto,
                    isReminderEMail: this.state.isReminderEMail,
                    eMails: this.state.eMails,
                    status: this.state.status,
                    plannedClosingDate: 
                        this.state.plannedClosingDate &&
                        this.state.plannedClosingDate.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.plannedClosingDate.split("/")[2] +
                            "-" +
                            this.state.plannedClosingDate.split("/")[1] +
                            "-" +
                            this.state.plannedClosingDate.split("/")[0]
                        )
                        : this.state.plannedClosingDate ? moment(this.state.plannedClosingDate).format("DD/MM/YYYY") : null,
                    affectedDevice: this.state.affectedDevice,
                };
            }
            axios
                .post(URL + URLParam, myObj, config)
                .then(() => {
                    setTimeout(() => {
                        if (this.state.guid && this.state.urlSplit[1] === "belge-takip-guncelle") {
                            window.location.href = "/belge-takip-listele";
                        }
                        if (this.state.urlSplit[1] === "belge-takip-ekle") {
                            window.location.reload();
                        }
                        var modalAddedDocumentTracking = {
                            data: this.state.name,
                            isModal: false,
                        };
                        this.props.history.push({ modalAddedDocumentTracking });
                        this.setState({
                            isLoading: false,
                        });
                    }, 1500);
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        }
    };
    addModalDocumentType = () => {
        this.setState({
            isOpenModalDocumentType: !this.state.isOpenModalDocumentType,
        });
    };
    handleValidityDateChange(validityDate) {
        if (validityDate) {
            this.setState({
                validityDate: validityDate,
                validityDateError: "",
                alertErrorMessage: "",
            });
        } else {
            this.setState({
                validityDate: "",
                validityDateError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    }
    handleIsUnlimitedTimeSelectChange(isUnlimitedTime) {
        this.setState({ isUnlimitedTime: isUnlimitedTime.value });
    }
    handleUTSRecordDateChange(utsRecordDate) {
        if (utsRecordDate) {
            this.setState({
                utsRecordDate: utsRecordDate,
                utsRecordDateError: "",
                alertErrorMessage: "",
            });
        } else {
            this.setState({
                utsRecordDate: "",
                utsRecordDateError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    }
    handlePlannedClosingDateChange(plannedClosingDate) {
        if (plannedClosingDate) {
            this.setState({
                plannedClosingDate: plannedClosingDate,
                plannedClosingDateeError: "",
                alertErrorMessage: "",
            });
        } else {
            this.setState({
                plannedClosingDate: "",
                plannedClosingDateError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    }
    handleIsUTSRecordSelectChange(isUTSRecord) {
        this.setState({ isUTSRecord: isUTSRecord.value });
    }
    addModalCustomer = () => {
        this.setState({
            isOpenModalCustomer: !this.state.isOpenModalCustomer,
        });
    };
    handleIsReminderEMailSelectChange(isReminderEMail) {
        this.setState({ isReminderEMail: isReminderEMail.value });
    }
    uploadedImage = async (event) => {
        let array = this.state.documentTrackingFilesDto;
        const files = event.target.files;
        for (var i = 0; i < files.length; i++) {
            const file = event.target.files[i];
            if (file.type === "application/pdf") {
                const formData = new FormData();
                formData.append("files", file);
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                };
                await axios
                    .post(URL + "/uploads/add", formData, config)
                    .then((response) => {
                        array.push({
                            image: response.data,
                            imageName: file.name
                        });
                        this.setState({
                            name2: this.state.name2 + file.name + " "
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            alertErrorMessage: error.response.data,
                        });
                    });
            }
        }
        await this.setState({ customerDocumentOtherImageDto: array });
    };
    deleteImage = (event) => {
        var index = -1;
        let array = this.state.documentTrackingFilesDto;
        this.state.documentTrackingFilesDto.forEach((element) => {
            index++;
            if (element.image === event) {
                if (index !== -1) {
                    array.splice(index, 1);
                    this.setState({ documentTrackingFilesDto: array });
                }
            }
        });
    };
    handleLanguageSelectChange(language) {
        this.setState({ language: language.value });
    }
    handleCustomerGuidSelectChange(customerGuid) {
        this.setState({ customerGuid: customerGuid.value });
    }
    handleDocumentTypeSelectChange(documentTypeGuid) {
        if(documentTypeGuid)
            this.setState({ documentTypeGuid: documentTypeGuid.value });
        else 
            this.setState({ documentTypeGuid: "" });
    }
    render() {
        const textAreaStyle = {
            padding: "5px 10px",
            height: "100px",
        };
        return (
            <>
                {(this.state.urlSplit[1] === "belge-takip-ekle" ||
                    this.state.urlSplit[1] === "belge-takip-guncelle") && (
                        <ContentTop
                            breadcrumb={this.state.breadcrumb}
                            title={this.state.pageTitle}
                            addLink={"/" + this.state.pageAddLink}
                            listLink={"/" + this.state.pageListLink}
                        />
                    )}
                <Row>
                    <Col>
                        <Form onSubmit={this.handleSave}>
                            <Card body>
                                <CgnLoader visibled={this.state.isLoading} />
                                <Row>
                                    <Col lg="4 pr-3">
                                        <CgnReactSelect
                                            name="language"
                                            label="Dil [*]"
                                            isMulti={false}
                                            selectValue={this.state.language}
                                            options={languages}
                                            onChange={(language) =>
                                                this.handleLanguageSelectChange(language)
                                            }
                                            placeholder="Lütfen dil seçiniz..."
                                            error={this.state.languageError}
                                        />
                                    </Col>
                                    <Col lg="4 pr-3" className="modalSelectCol">
                                        <div className="flex-container">
                                            <div className="p-0 flexMaxInput">
                                                <CgnReactSelect
                                                    name="customerGuid"
                                                    label="Müşteri [*]"
                                                    placeholder="Müşteri seçiniz..."
                                                    selectValue={this.state.customerGuid}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    onChange={(option) =>
                                                        this.handleCustomerGuidSelectChange(option)
                                                    }
                                                    options={this.state.customerArr}
                                                    error={this.state.customerGuidError}
                                                />
                                            </div>
                                            {this.state.customerModalPlus && (
                                                <div className="p-0 flexAddMinButton">
                                                    <CgnButton
                                                        type="button"
                                                        color="secondary"
                                                        className="modalSelectAddButton"
                                                        onClick={() => this.addModalCustomer()}
                                                        text={"+"}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg="4" className="modalSelectCol">
                                        <div className="flex-container">
                                            <div className="p-0 flexMaxInput">
                                                <CgnReactSelect
                                                    name="documentTypeGuid"
                                                    label="Belge Türü [*]"
                                                    placeholder="Belge Türü seçiniz..."
                                                    selectValue={this.state.documentTypeGuid}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    onChange={(option) =>
                                                        this.handleDocumentTypeSelectChange(option)
                                                    }
                                                    options={this.state.documentTypeArr}
                                                    error={this.state.documentTypeGuidError}
                                                />
                                            </div>
                                            <div className="p-0 flexMaxAddButton">
                                                <CgnButton
                                                    type="button"
                                                    color="secondary"
                                                    className="modalSelectAddButton"
                                                    onClick={() => this.addModalDocumentType()}
                                                    text={"+"}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {(this.state.documentTypeGuid === "4f66b9fa-33c8-43ec-930e-ef7dc8582055" || 
                                    this.state.documentTypeGuid === "dd09a126-e296-4972-8668-b7dc7ee004ac" ||
                                    this.state.documentTypeGuid === "4bddd9c2-7576-4e88-a009-c2b3a6048d08"
                                ) ? (
                                    <Row>
                                        <Col lg="12">
                                            <CgnTextbox
                                                inlineStyle={textAreaStyle}
                                                type="textarea"
                                                name="documentTypeNote"
                                                label="Açıklama [*]"
                                                value={this.state.documentTypeNote}
                                                placeHolder="Lütfen açıklama girin..."
                                                autoComplete="off"
                                                onChange={this.handleChange}
                                                error={this.state.documentTypeNoteError}
                                            />
                                        </Col>
                                    </Row>
                                ) : null}
                                {(this.state.documentTypeGuid === "4f66b9fa-33c8-43ec-930e-ef7dc8582055" ||
                                    this.state.documentTypeGuid === "653a8b04-2510-4e95-8a0d-1927434415ac" ||
                                    this.state.documentTypeGuid === "61ffe32b-31da-409d-9281-98cd35de5c13" ||
                                    this.state.documentTypeGuid === "7e3a35d6-1987-413c-93d5-b6e820c1d108"
                                ) &&
                                    <>
                                        <Row>
                                            <Col lg="4 pr-3">
                                                <CgnReactSelect
                                                    name="isUnlimitedTime"
                                                    label="Belge Sınırsız Süreyle Geçerli Midir? [*]"
                                                    isMulti={false}
                                                    selectValue={this.state.isUnlimitedTime}
                                                    options={yesNoStatus}
                                                    onChange={(isUnlimitedTime) =>
                                                        this.handleIsUnlimitedTimeSelectChange(isUnlimitedTime)
                                                    }
                                                    placeholder="Lütfen belge sınırsız süreyle geçerli mi seçiniz..."
                                                    error={this.state.isUnlimitedTimeError}
                                                />
                                            </Col>
                                            <Col lg="4 pr-3">
                                                {!this.state.isUnlimitedTime ? (
                                                    <CgnDatePicker
                                                        name="validityDate"
                                                        label="Belgenin Geçerlik Tarihi [*]"
                                                        selected={
                                                            this.state.validityDate &&
                                                                this.state.validityDate.toString().indexOf("/") > 0
                                                                ? new Date(
                                                                    this.state.validityDate.split("/")[2] +
                                                                    "-" +
                                                                    this.state.validityDate.split("/")[1] +
                                                                    "-" +
                                                                    this.state.validityDate.split("/")[0]
                                                                )
                                                                : this.state.validityDate
                                                        }
                                                        onChange={(validityDate) =>
                                                            this.handleValidityDateChange(validityDate)
                                                        }
                                                        error={this.state.validityDateError}
                                                        maxDate={new Date(2100, 1, 1)}
                                                        minDate={new Date(1900, 1, 1)}
                                                        placeholder="GG/AA/YYYY"
                                                        autoComplete="off"
                                                    />
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4 pr-3">
                                                <CgnReactSelect
                                                    name="isUTSRecord"
                                                    label="Belge ÜTS’de Kayıtlı Mı? [*]"
                                                    isMulti={false}
                                                    selectValue={this.state.isUTSRecord}
                                                    options={yesNoStatus}
                                                    onChange={(isUTSRecord) =>
                                                        this.handleIsUTSRecordSelectChange(isUTSRecord)
                                                    }
                                                    placeholder="Lütfen belge ÜTS’de kayıtlı mı seçiniz..."
                                                    error={this.state.isUTSRecordError}
                                                />
                                            </Col>
                                            {this.state.isUTSRecord ? (
                                                <>
                                                    <Col lg="4 pr-3">
                                                        <CgnTextbox
                                                            type="text"
                                                            name="documentNumber"
                                                            label="ÜTS Belge Numarası [*]"
                                                            value={this.state.documentNumber}
                                                            placeHolder="Lütfen ÜTS belge numarası girin..."
                                                            maxLength="100"
                                                            autoComplete="off"
                                                            onChange={this.handleChange}
                                                            error={this.state.documentNumberError}
                                                        />
                                                    </Col>
                                                    <Col lg="4">
                                                        <CgnDatePicker
                                                            name="utsRecordDate"
                                                            label="ÜTS Kayıt Tarihi"
                                                            selected={
                                                                this.state.utsRecordDate &&
                                                                    this.state.utsRecordDate.toString().indexOf("/") > 0
                                                                    ? new Date(
                                                                        this.state.utsRecordDate.split("/")[2] +
                                                                        "-" +
                                                                        this.state.utsRecordDate.split("/")[1] +
                                                                        "-" +
                                                                        this.state.utsRecordDate.split("/")[0]
                                                                    )
                                                                    : this.state.utsRecordDate
                                                            }
                                                            onChange={(utsRecordDate) =>
                                                                this.handleUTSRecordDateChange(utsRecordDate)
                                                            }
                                                            error={this.state.validityDateError}
                                                            maxDate={new Date(2100, 1, 1)}
                                                            minDate={new Date(1900, 1, 1)}
                                                            placeholder="GG/AA/YYYY"
                                                            autoComplete="off"
                                                        />

                                                    </Col>
                                                </>
                                            ) : null}
                                        </Row>
                                    </>
                                }
                                {(this.state.documentTypeGuid === "dd09a126-e296-4972-8668-b7dc7ee004ac" ||
                                    this.state.documentTypeGuid === "4bddd9c2-7576-4e88-a009-c2b3a6048d08"
                                ) &&
                                    <>
                                        <Row>
                                            <Col lg="4">
                                                <CgnDatePicker
                                                    name="plannedClosingDate"
                                                    label="Planlı Kapama Tarihi [*]"
                                                    selected={
                                                        this.state.plannedClosingDate &&
                                                            this.state.plannedClosingDate.toString().indexOf("/") > 0
                                                            ? new Date(
                                                                this.state.plannedClosingDate.split("/")[2] +
                                                                "-" +
                                                                this.state.plannedClosingDate.split("/")[1] +
                                                                "-" +
                                                                this.state.plannedClosingDate.split("/")[0]
                                                            )
                                                            : this.state.plannedClosingDate
                                                    }
                                                    onChange={(plannedClosingDate) =>
                                                        this.handlePlannedClosingDateChange(plannedClosingDate)
                                                    }
                                                    error={this.state.plannedClosingDateError}
                                                    maxDate={new Date(2100, 1, 1)}
                                                    minDate={new Date(1900, 1, 1)}
                                                    placeholder="GG/AA/YYYY"
                                                    autoComplete="off"
                                                />

                                            </Col>
                                            <Col lg="8">
                                                <CgnTextbox
                                                    type="text"
                                                    name="affectedDevice"
                                                    label="Etkilenen Cihaz [*]"
                                                    value={this.state.affectedDevice}
                                                    placeHolder="Lütfen etkilenen cihaz girin..."
                                                    maxLength={100}
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    error={this.state.affectedDeviceError}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row>
                                    <Col lg="12">
                                        <CgnTextbox
                                            inlineStyle={textAreaStyle}
                                            type="textarea"
                                            name="note"
                                            label="Not"
                                            value={this.state.note}
                                            placeHolder="Lütfen not girin..."
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <CgnTextbox
                                            inlineStyle={textAreaStyle}
                                            type="textarea"
                                            name="opinions"
                                            label="Görüşler"
                                            value={this.state.opinions}
                                            placeHolder="Lütfen görüş girin..."
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <ImageUploadMulti
                                            images={this.state.documentTrackingFilesDto}
                                            noFoto={noImg}
                                            style={fotoStyle}
                                            pdfIcon={pdfIcon}
                                            accept="application/pdf"
                                            className="text-center text-danger"
                                            label={"Belgeler"}
                                            onChange={this.uploadedImage}
                                            onDelete={this.deleteImage}
                                            error={this.state.documentTrackingFilesDtoError}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <CgnTextbox
                                            type="text"
                                            name="eMails"
                                            label="E-Mailler"
                                            value={this.state.eMails}
                                            placeHolder="Lütfen e-mail girin... (Lütfen e-mailleri birbirinden ayırmak için , kullanın! Örn; deneme@deneme.com, deneme2@deneme.com)"
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <CgnReactSelect
                                            name="isReminderEMail"
                                            label="Müşteriye Hatırlatma E-Maili Gönderilsin Mi? [*]"
                                            isMulti={false}
                                            selectValue={this.state.isReminderEMail}
                                            options={yesNoStatus}
                                            onChange={(isReminderEMail) =>
                                                this.handleIsReminderEMailSelectChange(isReminderEMail)
                                            }
                                            placeholder="Lütfen müşteriye hatırlatma e-maili gönderilsin mi seçiniz..."
                                            error={this.state.isReminderEMailError}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <CgnReactSelect
                                            name="status"
                                            label="Durumu [*]"
                                            isMulti={false}
                                            selectValue={this.state.status}
                                            options={optionStatuses}
                                            onChange={(status) =>
                                                this.handleStatusSelectChange(status)
                                            }
                                        />
                                    </Col>
                                </Row>
                                {this.state.alertErrorMessage && (
                                    <CgnAlert
                                        color="danger"
                                        title={CgnMessage.alertErrorTitle}
                                        text={this.state.alertErrorMessage}
                                    />
                                )}
                                <CgnButtonGroup items={this.state.buttons} />
                            </Card>
                        </Form>
                        {(this.state.urlSplit[1] === "belge-takip-ekle" ||
                            this.state.urlSplit[1] === "belge-takip-guncelle") && (
                                <CgnButtonLink
                                    color="secondary"
                                    to={"/" + this.state.pageListLink}
                                    text={CgnMessage.backButton}
                                    className="btn-back"
                                />
                            )}
                    </Col>
                </Row>
                <Modal
                    isOpen={this.state.isOpenModalDocumentType}
                    toggle={this.addModalDocumentType}
                    className="modal-xl content"
                >
                    <ModalHeader className="font-weight-bold">
                        Belge Türü Ekle
                    </ModalHeader>
                    <ModalBody className="modalBody">
                        <DocumentTypeAddUpdate />
                    </ModalBody>
                    <ModalFooter>
                        <CgnButton
                            type="button"
                            color="secondary"
                            onClick={this.addModalDocumentType}
                            text="Kapat"
                            className="modalButton"
                        />
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.isOpenModalCustomer}
                    toggle={this.addModalCustomer}
                    className="modal-xl content"
                >
                    <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
                    <ModalBody className="modalBody">
                        <CustomerAddUpdate />
                    </ModalBody>
                    <ModalFooter>
                        <CgnButton
                            type="button"
                            color="secondary"
                            onClick={this.addModalCustomer}
                            text="Kapat"
                            className="modalButton"
                        />
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
export default withRouter(DocumentTrackingAddUpdate);