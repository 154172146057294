import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";


class FixedCastingAddUpdate extends Component {
  state = {
    pageTitle: "Döküm Sabitleri Ekle",
    pageTitleAdd: "Döküm Sabitleri Ekle",
    pageTitleUpdate: "Döküm Sabitleri Güncelle",
    pageAddLink: "dokum-sabitleri-ekle",
    pageListLink: "dokum-sabitleri-listele",
    breadcrumb: [{ text: "Döküm Sabitleri", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    guid: "",
    bDORate: 0,
    dSMRate: 0,
    lama1020Rate: 0,
    lama810Rate: 0,
    mocaPercent: 0,
    pimRate: 0,
    ropeRate: 0,
    sandblastingRate: 0,
    sidePlateRate: 0,
    profitRate: 0,
    mocaRate: 0,
    ringRate: 0,
    transportTotal: 0,
    dsm: 0,
    rope: 0,
    ring: 0,
    pim: 0,
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    tdiEsterRate: 0,
    mdiEsterRate: 0,
    tdiEtherRate: 0,
    mdiEtherRate: 0,
    bdoPercent: 0,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/fixedCastings/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              bDORate: parseFloat(response.data.bdoRate),
              dSMRate: parseFloat(response.data.dsmRate),
              lama1020Rate: parseFloat(response.data.lama1020Rate),
              lama810Rate: parseFloat(response.data.lama810Rate),
              mocaPercent: parseFloat(response.data.mocaPercent),
              pimRate: parseFloat(response.data.pimRate),
              profitRate: parseFloat(response.data.profitRate),
              mocaRate: parseFloat(response.data.mocaRate),
              ringRate: parseFloat(response.data.ringRate),
              ropeRate: parseFloat(response.data.ropeRate),
              sandblastingRate: parseFloat(response.data.sandblastingRate),
              sidePlateRate: parseFloat(response.data.sidePlateRate),
              transportTotal: parseFloat(response.data.transportTotal),
              dsm: parseFloat(response.data.dsm),
              rope: parseFloat(response.data.rope),
              ring: parseFloat(response.data.ring),
              pim: parseFloat(response.data.pim),
              status: response.data.status,
              isLoading: false,
              tdiEsterRate: parseFloat(response.data.tdiEsterRate),
              mdiEsterRate: parseFloat(response.data.mdiEsterRate),
              tdiEtherRate: parseFloat(response.data.tdiEtherRate),
              mdiEtherRate: parseFloat(response.data.mdiEtherRate),
              bdoPercent: parseFloat(response.data.bdoPercent),
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };

  async handletdiEsterRateChange(e) {
    if (e) {
      await this.setState({
        tdiEsterRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        tdiEsterRate: 0,
      });
    }
  }

  async handletdiEtherRateChange(e) {
    if (e) {
      await this.setState({
        tdiEtherRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        tdiEtherRate: 0,
      });
    }
  }

  async handleBDORateChange(e) {
    if (e) {
      await this.setState({
        bDORate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bDORate: 0,
      });
    }
  }

  async handleDSMRateChange(e) {
    if (e) {
      await this.setState({
        dSMRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        dSMRate: 0,
      });
    }
  }

  async handleLama1020RateChange(e) {
    if (e) {
      await this.setState({
        lama1020Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama1020Rate: 0,
      });
    }
  }

  async handleLama810RateChange(e) {
    if (e) {
      await this.setState({
        lama810Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama810Rate: 0,
      });
    }
  }

  async handlemdiEtherRateChange(e) {
    if (e) {
      await this.setState({
        mdiEtherRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mdiEtherRate: 0,
      });
    }
  }

  async handlemdiEsterRateChange(e) {
    if (e) {
      await this.setState({
        mdiEsterRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mdiEsterRate: 0,
      });
    }
  }

  async handleMocaPercentChange(e) {
    if (e) {
      await this.setState({
        mocaPercent: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mocaPercent: 0,
      });
    }
  }

  async handlebdoPercentChange(e) {
    if (e) {
      await this.setState({
        bdoPercent: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bdoPercent: 0,
      });
    }
  }

  async handledSMRateChange(e) {
    if (e) {
      await this.setState({
        dSMRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        dSMRate: 0,
      });
    }
  }

  async handlePimRateChange(e) {
    if (e) {
      await this.setState({
        pimRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        pimRate: 0,
      });
    }
  }

  async handleProfitRateChange(e) {
    if (e) {
      await this.setState({
        profitRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        profitRate: 0,
      });
    }
  }

  async handleMocaRateChange(e) {
    if (e) {
      await this.setState({
        mocaRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        mocaRate: 0,
      });
    }
  }

  async handleRingRateChange(e) {
    if (e) {
      await this.setState({
        ringRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        ringRate: 0,
      });
    }
  }

  async handleRopeRateChange(e) {
    if (e) {
      await this.setState({
        ropeRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        ropeRate: 0,
      });
    }
  }

  async handleSandblastingRateChange(e) {
    if (e) {
      await this.setState({
        sandblastingRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sandblastingRate: 0,
      });
    }
  }

  async handleSidePlateRateChange(e) {
    if (e) {
      await this.setState({
        sidePlateRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sidePlateRate: 0,
      });
    }
  }

  async handleTransportTotalChange(e) {
    if (e) {
      await this.setState({
        transportTotal: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        transportTotal: 0,
      });
    }
  }

  async handleDSMChange(e) {
    if (e) {
      await this.setState({
        dsm: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        dsm: 0,
      });
    }
  }

  async handleRopeChange(e) {
    if (e) {
      await this.setState({
        rope: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        rope: 0,
      });
    }
  }

  async handleRingChange(e) {
    if (e) {
      await this.setState({
        ring: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        ring: 0,
      });
    }
  }

  async handlePimChange(e) {
    if (e) {
      await this.setState({
        pim: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        pim: 0,
      });
    }
  }

  clearPage() {
    this.setState({
      status: true,
      bDORate: 0,
      dSMRate: 0,
      lama1020Rate: 0,
      lama810Rate: 0,
      mocaPercent: 0,
      pimRate: 0,
      ropeRate: 0,
      sandblastingRate: 0,
      sidePlateRate: 0,
      profitRate: 0,
      mocaRate: 0,
      ringRate: 0,
      transportTotal: 0,
      dsm: 0,
      rope: 0,
      ring: 0,
      pim: 0,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      tdiEsterRate: 0,
      mdiEsterRate: 0,
      tdiEtherRate: 0,
      mdiEtherRate: 0,
      bdoPercent: 0,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });
    if (
      this.state.bDORate !== 0 ||
      this.state.dSMRate !== 0 ||
      this.state.lama1020Rate !== 0 ||
      this.state.lama810Rate !== 0 ||
      this.state.mocaPercent !== 0 ||
      this.state.pimRate !== 0 ||
      this.state.profitRate !== 0 ||
      this.state.mocaRate !== 0 ||
      this.state.ringRate !== 0 ||
      this.state.ropeRate !== 0 ||
      this.state.sandblastingRate !== 0 ||
      this.state.sidePlateRate !== 0 ||
      this.state.transportTotal !== 0 ||
      this.state.dsm !== 0 ||
      this.state.rope !== 0 ||
      this.state.ring !== 0 ||
      this.state.pim !== 0 ||
      this.state.tdiEsterRate !== 0 ||
      this.state.mdiEsterRate !== 0 ||
      this.state.tdiEtherRate !== 0 ||
      this.state.mdiEtherRate !== 0 ||
      this.state.bdoPercent !== 0
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        bDORate:
          this.state.bDORate === "NaN" ? 0 : parseFloat(this.state.bDORate),
        dSMRate:
          this.state.dSMRate === "NaN" ? 0 : parseFloat(this.state.dSMRate),
        lama1020Rate:
          this.state.lama1020Rate === "NaN"
            ? 0
            : parseFloat(this.state.lama1020Rate),
        lama810Rate:
          this.state.lama810Rate === "NaN"
            ? 0
            : parseFloat(this.state.lama810Rate),
        pimRate:
          this.state.pimRate === "NaN" ? 0 : parseFloat(this.state.pimRate),
        profitRate:
          this.state.profitRate === "NaN"
            ? 0
            : parseFloat(this.state.profitRate),
        mocaRate:
          this.state.mocaRate === "NaN" ? 0 : parseFloat(this.state.mocaRate),
        ringRate:
          this.state.ringRate === "NaN" ? 0 : parseFloat(this.state.ringRate),
        ropeRate:
          this.state.ropeRate === "NaN" ? 0 : parseFloat(this.state.ropeRate),
        sandblastingRate:
          this.state.sandblastingRate === "NaN"
            ? 0
            : parseFloat(this.state.sandblastingRate),
        sidePlateRate:
          this.state.sidePlateRate === "NaN"
            ? 0
            : parseFloat(this.state.sidePlateRate),
        transportTotal:
          this.state.transportTotal === "NaN"
            ? 0
            : parseFloat(this.state.transportTotal),
        dsm:
          this.state.dsm === "NaN"
            ? 0
            : parseFloat(this.state.dsm),
        rope:
          this.state.rope === "NaN"
            ? 0
            : parseFloat(this.state.rope),
        ring:
          this.state.ring === "NaN"
            ? 0
            : parseFloat(this.state.ring),
        pim:
          this.state.pim === "NaN"
            ? 0
            : parseFloat(this.state.pim),
        status: this.state.status,
        tdiEsterRate:
          this.state.tdiEsterRate === "NaN"
            ? 0
            : parseFloat(this.state.tdiEsterRate),
        mdiEsterRate:
          this.state.mdiEsterRate === "NaN"
            ? 0
            : parseFloat(this.state.mdiEsterRate),
        tdiEtherRate:
          this.state.tdiEtherRate === "NaN"
            ? 0
            : parseFloat(this.state.tdiEtherRate),
        mdiEtherRate:
          this.state.mdiEtherRate === "NaN"
            ? 0
            : parseFloat(this.state.mdiEtherRate),
        bdoPercent:
          this.state.bdoPercent === "NaN"
            ? 0
            : parseFloat(this.state.bdoPercent),
      };

      var URLParam = "/fixedCastings/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "dokum-sabitleri-guncelle"
      ) {
        URLParam = "/fixedCastings/update";
        myObj = {
          guid: this.state.guid,
          bDORate:
            this.state.bDORate === "NaN" ? 0 : parseFloat(this.state.bDORate),
          dSMRate:
            this.state.dSMRate === "NaN" ? 0 : parseFloat(this.state.dSMRate),
          lama1020Rate:
            this.state.lama1020Rate === "NaN"
              ? 0
              : parseFloat(this.state.lama1020Rate),
          lama810Rate:
            this.state.lama810Rate === "NaN"
              ? 0
              : parseFloat(this.state.lama810Rate),
          mocaPercent:
            this.state.mocaPercent === "NaN"
              ? 0
              : parseFloat(this.state.mocaPercent),
          pimRate:
            this.state.pimRate === "NaN" ? 0 : parseFloat(this.state.pimRate),
          profitRate:
            this.state.profitRate === "NaN"
              ? 0
              : parseFloat(this.state.profitRate),
          mocaRate:
            this.state.mocaRate === "NaN" ? 0 : parseFloat(this.state.mocaRate),
          ringRate:
            this.state.ringRate === "NaN" ? 0 : parseFloat(this.state.ringRate),
          ropeRate:
            this.state.ropeRate === "NaN" ? 0 : parseFloat(this.state.ropeRate),
          sandblastingRate:
            this.state.sandblastingRate === "NaN"
              ? 0
              : parseFloat(this.state.sandblastingRate),
          sidePlateRate:
            this.state.sidePlateRate === "NaN"
              ? 0
              : parseFloat(this.state.sidePlateRate),
          transportTotal:
            this.state.transportTotal === "NaN"
              ? 0
              : parseFloat(this.state.transportTotal),
          dsm:
            this.state.dsm === "NaN"
              ? 0
              : parseFloat(this.state.dsm),
          rope:
            this.state.rope === "NaN"
              ? 0
              : parseFloat(this.state.rope),
          ring:
            this.state.ring === "NaN"
              ? 0
              : parseFloat(this.state.ring),
          pim:
            this.state.pim === "NaN"
              ? 0
              : parseFloat(this.state.pim),
          status: this.state.status,
          tdiEsterRate:
            this.state.tdiEsterRate === "NaN"
              ? 0
              : parseFloat(this.state.tdiEsterRate),
          mdiEsterRate:
            this.state.mdiEsterRate === "NaN"
              ? 0
              : parseFloat(this.state.mdiEsterRate),
          tdiEtherRate:
            this.state.tdiEtherRate === "NaN"
              ? 0
              : parseFloat(this.state.tdiEtherRate),
          mdiEtherRate:
            this.state.mdiEtherRate === "NaN"
              ? 0
              : parseFloat(this.state.mdiEtherRate),
          bdoPercent:
            this.state.bdoPercent === "NaN"
              ? 0
              : parseFloat(this.state.bdoPercent),
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "dokum-sabitleri-guncelle"
            ) {
              window.location.href = "/dokum-sabitleri-listele";
            }
            if (this.state.urlSplit[1] === "dokum-sabitleri-ekle") {
              window.location.reload();
            }
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "dokum-sabitleri-ekle" ||
          this.state.urlSplit[1] === "dokum-sabitleri-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="tdiEsterRate"
                      label="TDI Ester (Euro)"
                      value={this.state.tdiEsterRate}
                      onChange={(e) => this.handletdiEsterRateChange(e)}
                      placeHolder="Lütfen TDI Ester birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tdiEsterRateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="mdiEsterRate"
                      label="MDI Ester (Euro)"
                      value={this.state.mdiEsterRate}
                      onChange={(e) => this.handlemdiEsterRateChange(e)}
                      placeHolder="Lütfen MDI Ester birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mdiEsterRateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="tdiEtherRate"
                      label="TDI Ether (Euro)"
                      value={this.state.tdiEtherRate}
                      onChange={(e) => this.handletdiEtherRateChange(e)}
                      placeHolder="Lütfen TDI Ether birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.tdiEtherRateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="mdiEtherRate"
                      label="MDI Ether (Euro)"
                      value={this.state.mdiEtherRate}
                      onChange={(e) => this.handlemdiEtherRateChange(e)}
                      placeHolder="Lütfen MDI Ether birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mdiEtherRateError}
                      step="0.1"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bDORate"
                      label="BDO Birimi (Euro)"
                      value={this.state.bDORate}
                      onChange={(e) => this.handleBDORateChange(e)}
                      placeHolder="Lütfen BDO birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bDORateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bdoPercent"
                      label="BDO Yüzdesi"
                      value={this.state.bdoPercent}
                      onChange={(e) => this.handlebdoPercentChange(e)}
                      placeHolder="Lütfen bdo yüzdeliği girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bdoPercentError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="mocaPercent"
                      label="Moca Yüzdesi"
                      value={this.state.mocaPercent}
                      onChange={(e) => this.handleMocaPercentChange(e)}
                      placeHolder="Lütfen moca yüzdeliği girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mocaPercentError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="dSMRate"
                      label="DSM Birimi (Euro)"
                      value={this.state.dSMRate}
                      onChange={(e) => this.handleDSMRateChange(e)}
                      placeHolder="Lütfen DSM birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.dSMRateError}
                      step="0.1"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="sandblastingRate"
                      label="Kumlama Birimi (Euro)"
                      value={this.state.sandblastingRate}
                      onChange={(e) => this.handleSandblastingRateChange(e)}
                      placeHolder="Lütfen Kumlama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sandblastingRateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="sidePlateRate"
                      label="Yan Gergi Sacı Birimi (Euro)"
                      value={this.state.sidePlateRate}
                      onChange={(e) => this.handleSidePlateRateChange(e)}
                      placeHolder="Lütfen Yan Gergi Sacı birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sidePlateRateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="lama810Rate"
                      label="8x16 Lama Birimi (Euro)"
                      value={this.state.lama810Rate}
                      onChange={(e) => this.handleLama810RateChange(e)}
                      placeHolder="Lütfen 8x16 Lama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810RateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="lama1020Rate"
                      label="10x20 Lama Birimi (Euro)"
                      value={this.state.lama1020Rate}
                      onChange={(e) => this.handleLama1020RateChange(e)}
                      placeHolder="Lütfen 10x20 Lama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama1020RateError}
                      step="0.1"
                    />
                  </Col>
                  </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="ropeRate"
                      label="Halat Birimi (Euro)"
                      value={this.state.ropeRate}
                      onChange={(e) => this.handleRopeRateChange(e)}
                      placeHolder="Lütfen halat birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ropeRateError}
                      step="0.1"
                    />
                  </Col>
                
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="ringRate"
                      label="Kurşun Yüzük Birimi (Euro)"
                      value={this.state.ringRate}
                      onChange={(e) => this.handleRingRateChange(e)}
                      placeHolder="Lütfen kurşun yüzük birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ringRateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="pimRate"
                      label="Pim Birimi (Euro)"
                      value={this.state.pimRate}
                      onChange={(e) => this.handlePimRateChange(e)}
                      placeHolder="Lütfen pim birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.pimRateError}
                      step="0.1"
                    />
                  </Col>
                
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="transportTotal"
                      label="Nakliye Birimi (Euro)"
                      value={this.state.transportTotal}
                      onChange={(e) => this.handleTransportTotalChange(e)}
                      placeHolder="Lütfen nakliye birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportTotalError}
                      step="0.1"
                    />
                  </Col>
                  </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="transportTotal"
                      label="Moca Değeri (Euro)"
                      value={this.state.mocaRate}
                      onChange={(e) => this.handleMocaRateChange(e)}
                      placeHolder="Lütfen moca değeri girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.mocaRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="transportTotal"
                      label="İşçilik Yüzdesi"
                      value={this.state.profitRate}
                      onChange={(e) => this.handleProfitRateChange(e)}
                      placeHolder="Lütfen işçilik yüzdesi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.profitRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="dsm"
                      label="DSM"
                      value={this.state.dsm}
                      onChange={(e) => this.handleDSMChange(e)}
                      placeHolder="Lütfen DSM girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.dSMError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="rope"
                      label="Rope"
                      value={this.state.rope}
                      onChange={(e) => this.handleRopeChange(e)}
                      placeHolder="Lütfen Rope girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ropeError}
                      step="0.1"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="ring"
                      label="Ring"
                      value={this.state.ring}
                      onChange={(e) => this.handleRingChange(e)}
                      placeHolder="Lütfen Ring girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.ringError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="pim"
                      label="Pim"
                      value={this.state.pim}
                      onChange={(e) => this.handlePimChange(e)}
                      placeHolder="Lütfen Pim girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.pimError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "dokum-sabitleri-ekle" ||
              this.state.urlSplit[1] === "dokum-sabitleri-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(FixedCastingAddUpdate);
