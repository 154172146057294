import React, { Component } from "react";
import { Row, Col, Card, Table, Form } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  machineProductOptionStatuses,
} from "../../redux/actions/constants";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";

class RecyclingProductsDetail extends Component {
  state = {
    pageTitle: "Geri Dönüşüm Detay",
    pageTitleDetail: "Geri Dönüşüm Detay",
    pageAddLink: "geri-donusum-ekle",
    pageListLink: "geri-donusum-listele",
    breadcrumb: [{ text: "Geri Dönüşüm", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    dateTime: "",
    locationName: "",
    recyclingProductsProductDtos: [],
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "yetkili-geri-donusum-detay") {
      await this.setState({
        pageTitle: "Yetkili Geri Dönüşüm Detay",
        pageTitleDetail: "Yetkili Geri Dönüşüm Detay",
        pageAddLink: "",
        pageListLink: "yetkili-geri-donusum-listele",
        pageType: "yetkili-geri-donusum-detay",
      });
    }

    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/recyclingproducts/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            dateTime: response.data.dateTime,
            locationName: response.data.locationName,
            recyclingProductsProductDtos: response.data.recyclingProductsProductDtos,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    const thStyle = {
      width: "400px",
    };

    const thStyle2 = {
      width: "100px",
      textAlign: "center",
    };

    const thStyle3 = {
      width: "auto",
    };

    const tdStyle = {
      paddingLeft: "10px",
      width: "400px",
    };

    const tdStyle2 = {
      paddingLeft: "10px",
      textAlign: "center",
      width: "100px",
    };

    const tdStyle3 = {
      width: "auto",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={
            this.state.pageAddLink !== "" && "/" + this.state.pageAddLink
          }
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <td colSpan="4">{this.state.date}</td>
                  </tr>
                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">{this.state.locationName}</td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              {this.state.recyclingProductsProductDtos !== null &&
                this.state.recyclingProductsProductDtos.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <Table className="table table-responsive table-hover table-product mb-3">
                        <tr style={mainRow}>
                          <th style={thStyle}>Ürün Adı</th>
                          <th style={thStyle2}>Adet</th>
                        </tr>
                        {this.state.recyclingProductsProductDtos.map(function (
                          item
                        ) {
                          return (
                            <tr style={subRow}>
                              <td style={tdStyle}>{item.machineProductName}</td>
                              <td style={tdStyle2}>{item.piece + " Adet"}</td>
                            </tr>
                          );
                        })}
                      </Table>
                    </Col>
                  </Row>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default RecyclingProductsDetail;
