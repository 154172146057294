import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, fotoStyle } from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";

class StockMovementsDetail extends Component {
  state = {
    pageTitle: "Ürün Stok Detay",
    pageTitleDetail: "Ürün Stok Detay",
    //pageAddLink: "urun-ekle",
    pageListLink: "stok-hareketleri",
    breadcrumb: [{ text: "Ürün Stok", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    categoryName: "",
    contractName: "",
    code: "",
    name: "",
    criticalPieces: null,
    currencyType: "",
    salePrice: "",
    buyPrice: "",
    peerProductName: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    productPropertyDto: [],
    maintenanceKitDto: [],
    stockStatus: false,
    productStock: 0,
    locationProductStock: [],
    stockMovementData: [],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 5) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/stockMovements/stockDetail/" + urlSplit[2] + "/" + urlSplit[3] + "/" + urlSplit[4] , config)
        .then((response) => {
          this.setState({
            stockMovementData: response.data,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };


  thStyle = {
    width: "120px",
  };

  thStyle2 = {
    width: "250px",
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self = this;
    var self2 = this.state;

    const totalUseStock = this.state.stockMovementData.reduce((acc, item) => {
      return acc + (item.useStock || 0); // item.useStock mevcut değilse 0 ekler
    }, 0);

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
 
              {this.state.stockMovementData !== null &&
                this.state.stockMovementData.length > 0 && (
                  <>
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th>Bölüm</th>
                      <th className="text-left">Ürün</th>
                      <th className="text-left">Müşteri</th>
                      <th className="text-center">Kullanılan Adet</th>
                      <th className="text-center">Kalan Stok</th>
                      <th>Tarih</th>
                    </tr>
                    {this.state.stockMovementData.map(function (item) {
                      return (
                        <>
                        <tr style={{display: item.type === 1 ? "" : "none"}}>
                          <td style={self.thStyle}>TEKLİF</td>
                          <td style={self.thStyle2} className="text-left">{item.productName}</td>
                          <td className="text-left">{item.customer}</td>
                          <td className="text-center">{item.useStock + " Adet"}</td>
                          <td className="text-center">{parseFloat(item.productStock)}</td>
                          <td>{item.createdAt}</td>
                        </tr>
                        <tr style={{display: item.type === 2 ? "" : "none"}}>
                        <td style={self.thStyle}>SİPARİŞ</td>
                        <td style={self.thStyle2} className="text-left">{item.productName}</td>
                        <td className="text-left">{item.customer}</td>
                        <td className="text-center">{item.useStock + " Adet"}</td>
                        <td className="text-center">{parseFloat(item.productStock)}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                      <tr style={{display: item.type === 3 ? "" : "none"}}>
                        <td style={self.thStyle}>SERVİS</td>
                        <td style={self.thStyle2} className="text-left">{item.productName}</td>
                        <td className="text-left">{item.customer}</td>
                        <td className="text-center">{item.useStock + " Adet"}</td>
                        <td className="text-center">{parseFloat(item.productStock)}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                      <tr style={{display: item.type === 4 ? "" : "none"}}>
                        <td style={self.thStyle}>SATIŞ</td>
                        <td style={self.thStyle2} className="text-left">{item.productName}</td>
                        <td className="text-left">{item.customer}</td>
                        <td className="text-center">{item.useStock + " Adet"}</td>
                        <td className="text-center">{parseFloat(item.productStock)}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                      <tr style={{display: item.type === 5 ? "" : "none"}}>
                        <td style={self.thStyle}>ALIŞ</td>
                        <td style={self.thStyle2} className="text-left">{item.productName}</td>
                        <td className="text-left">{item.customer}</td>
                        <td className="text-center">{item.useStock + " Adet"}</td>
                        <td className="text-center">{parseFloat(item.productStock)}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                        </>

                      );
                    })}
                  </Table>
                 <div className="row">
                  <span className="p-2 font-weight-bold mt-2" style={{border: "3px solid #1d4b8c"}}>KULLANILAN TOPLAM ADET: {totalUseStock}</span>
                 </div>
                 </>
                )}

            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default StockMovementsDetail;
