import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  CurrencyType,
  resizeFile,
  fotoStyle,
  StockStatus,

  UserOperationClaimControl,
  viewStatuses,
  kitStatuses,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";
import CategoryAddUpdate from "../category/CategoryAddUpdate";
import ProductPropertyTypeAddUpdate from "../productPropertyType/ProductPropertyTypeAddUpdate";
import ContractAddUpdate from "../contract/ContractAddUpdate";
import { withRouter } from "react-router-dom";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";

class ProductAddUpdate extends Component {
  state = {
    pageTitle: "Ürün Ekle",
    pageTitleAdd: "Ürün Ekle",
    pageTitleUpdate: "Ürün Güncelle",
    pageAddLink: "urun-ekle",
    pageListLink: "urun-listele",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    urlSplit: [],
    categoryArr: [],
    categoryGuid: "",
    contractGuid: "00000000-0000-0000-0000-000000000000",
    categoryName: "",
    peerProductArr: [],
    peerProductGuid: "00000000-0000-0000-0000-000000000000",
    code: "",
    supplierProductCode: "",
    image: "",
    viewStatus: null,
    name: "",
    firstValues: [],
    criticalPieces: "",
    currencyType: "1",
    salePrice: "0",
    buyPrice: "0",
    productPropertyTypeArr: [],
    productPropertyDto: [],
    propertyTypeGuid: "",
    maintenanceKitArr: [],
    maintenanceKitDto: [],
    maintenanceKitGuid: "",
    productImageDto: [],
    productPictureDto: [],
    productPicture2Dto: [],
    guid: "",
    name2: "",
    kitStatus: null,
    pieceIdx: 0,
    status: true,
    isNull: false,
    isOpenModalCategory: false,
    isOpenModalProductPropertyType: false,
    isOpenModalContract: false,
    modalButtonIdx: 0,
    counter: -1,
    counter2: -1,
    stockStatus: 1, // 1se stoklu, 0sa stoksuz
    isTrue: false,
    isTrueBuyPrices: false,
    isTrueSalePrices: false,
    categoryNameError: "",
    codeError: "",
    nameError: "",
    criticalPiecesError: "",
    currencyTypeError: "",
    salePriceError: "",
    alertErrorMessage: "",
    isOpenModalProduct: false,
    isOpenModalProduct2: false,
    message: "",
    contractError: "",
    description: null,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    categoryModalPlus: false,
    productPropertyTypeModalPlus: false,
    productModalPlus: false,
    shelfInformation: "",
    shelfInformationError: "",
    isCSSavunma: false,
  };

  async componentDidMount() {
    if (localStorage.getItem("companyGuid") === "dafb735d-4758-4c61-8ab8-340ee087210e") {
      this.setState({
        isCSSavunma: true,
      });
    }
    this.setState({
      isLoading: true,
      categoryModalPlus: await UserOperationClaimControl(1006),
      productPropertyTypeModalPlus: await UserOperationClaimControl(1033),
      productModalPlus: await UserOperationClaimControl(1029),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için

    axios
      .get(URL + "/categories/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          categoryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/contracts/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          contractArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if (item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            unitBuyPrice: item.buyPrice,
            unitSalePrice: item.salePrice,
            maintenanceKitName: item.name,
          };
        });
        this.setState({
          peerProductArr: myArr,
          maintenanceKitArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/ProductPropertyTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          productPropertyTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "urun-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/products/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              categoryGuid: response.data.categoryGuid,
              categoryName: response.data.categoryName,
              contractGuid: response.data.contractGuid,
              peerProductGuid: response.data.peerProductGuid,
              productPropertyDto: response.data.productPropertyDto,
              maintenanceKitDto: response.data.maintenanceKitDto,
              image: response.data.image,
              code: response.data.code,
              name: response.data.name,
              criticalPieces: response.data.criticalPieces,
              currencyType: response.data.currencyType,
              salePrice: response.data.salePrice,
              buyPrice: response.data.buyPrice,
              stockStatus: response.data.stockStatus,
              productImageDto: response.data.productImageDto,
              supplierProductCode: response.data.supplierProductCode,
              status: response.data.status,
              description: response.data.description,
              productPictureDto: response.data.productPictureDto,
              productPicture2Dto: response.data.productPicture2Dto,
              viewStatus: response.data.viewStatus,
              kitStatus: response.data.kitStatus,
              isLoading: false,
              isNull: false,
              shelfInformation: response.data.shelfInformation,
            });
            if (response.data.stockStatus === 0) {
              this.setState({
                isTrue: true,
                criticalPieces: "",
                criticalPiecesError: "",
                alertErrorMessage: "",
              });
            }
            // if (response.data.maintenanceKitDto.length > 0) {
            //   this.setState({
            //     isTrueBuyPrices: true,
            //     isTrueSalePrices: true,
            //   });
            // }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        image: "",
        categoryGuid: "",
        categoryName: "",
        contractGuid: "00000000-0000-0000-0000-000000000000",
        peerProductGuid: "00000000-0000-0000-0000-000000000000",
        code: "",
        name: "",
        supplierProductCode: "",
        criticalPieces: "",
        currencyType: "",
        salePrice: "",
        buyPrice: "",
        viewStatuses: null,
        description: null,
        productPropertyDto: [],
        maintenanceKitDto: [],
        productImageDto: [],
        stockStatus: 1,
        status: true,
        counter: -1,
        counter2: -1,
        isTrue: false,
        shelfInformation: "",
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalCategory &&
      this.props.location.modalAddedCategory !== undefined &&
      this.props.location.modalAddedCategory.isModal !== undefined &&
      this.props.location.modalAddedCategory.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/categories/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              categoryGuid: item.categoryGuid,
            };
          });
          this.setState({
            categoryArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCategory.data &&
              element.name === this.props.location.modalAddedCategory.data
            ) {
              this.setState({
                categoryGuid: element.guid,
                categoryNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCategory.isModal = true;
      this.setState({
        isOpenModalCategory: false,
      });
    }
    if (
      this.state.isOpenModalProductPropertyType &&
      this.props.location.modalAddedProductPropertyType !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/productPropertyTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            productPropertyTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProductPropertyType.data &&
              element.name ===
              this.props.location.modalAddedProductPropertyType.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              let productPropertyDto = this.state.productPropertyDto;
              productPropertyDto[this.state.modalButtonIdx].propertyTypeGuid =
                element.guid;
              productPropertyDto[this.state.modalButtonIdx].propertyTypeError =
                "";
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProductPropertyType.isModal = true;
      this.setState({
        isOpenModalProductPropertyType: false,
      });
    }
    if (
      this.state.isOpenModalContract &&
      this.props.location.modalAddedContract !== undefined &&
      this.props.location.modalAddedContract.isModal !== undefined &&
      this.props.location.modalAddedContract.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/contracts/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            contractArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedContract.data &&
              element.name === this.props.location.modalAddedContract.data
            ) {
              this.setState({
                contractGuid: element.guid,
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedContract.isModal = true;
      this.setState({
        isOpenModalContract: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            peerProductArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              element.name === this.props.location.modalAddedProduct.data
            ) {
              this.setState({
                peerProductGuid: element.guid,
                peerProductError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalProduct2 &&
      this.props.location.modalAddedProduct2 !== undefined &&
      this.props.location.modalAddedProduct2.isModal !== undefined &&
      this.props.location.modalAddedProduct2.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            maintenanceKitArr: myArr,
            isLoading: false,
          });
          let productData = this.state.maintenanceKitDto;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct2.data &&
              `${element.code}-${element.name}` ===
              this.props.location.modalAddedProduct2.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].maintenanceKitGuid =
                element.guid;
              productData[this.state.modalButtonIdx].maintenanceKitError = "";
            }
          });
          if (this.props.location.modalAddedProduct.unitBuyPrice) {
            productData[this.state.modalButtonIdx].unitBuyPrice =
              this.props.location.modalAddedProduct.unitBuyPrice;
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
          }
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      // this.props.location.modalAddedProduct2.isModal = true;
      // this.setState({
      //   isOpenModalProduct2: false,
      // });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  barcodeGenerator = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let url = "/products/barcodenumbergenerate";
    if (this.state.isCSSavunma) {
      url = "/products/barcodenumbergeneratecssavunma/" + this.state.categoryGuid;
    }
    await axios
      .get(URL + url, config)
      .then((response) => {
        this.setState({
          code: response.data,
          codeError: "",
          alertErrorMessage: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalCategory = () => {
    this.setState({
      isOpenModalCategory: !this.state.isOpenModalCategory,
    });
  };

  addModalContract = () => {
    this.setState({
      isOpenModalContract: !this.state.isOpenModalContract,
    });
  };

  addModalProductPropertyType = async (idx) => {
    await this.setState({
      isOpenModalProductPropertyType:
        !this.state.isOpenModalProductPropertyType,
      modalButtonIdx: idx,
    });
  };

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  addModalProduct2 = async (idx) => {
    await this.setState({
      isOpenModalProduct2: !this.state.isOpenModalProduct2,
      modalButtonIdx: idx,
    });
  };

  handleStockStatus = async (e) => {
    if (e.value === 0) {
      await this.setState({
        stockStatus: 0,
        isTrue: true,
        criticalPieces: "",
        criticalPiecesError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        stockStatus: 1,
        isTrue: false,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      categoryNameError: "",
      codeError: "",
      nameError: "",
      criticalPiecesError: "",
      currencyTypeError: "",
      salePriceError: "",
      shelfInformationError: "",
      alertErrorMessage: "",
    });
    if (name === "categoryGuid" && value === "") {
      this.setState({
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "code" && value === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "criticalPieces" && value === "") {
      this.setState({
        criticalPiecesError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "salePrice" && value === "") {
      this.setState({
        salePriceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "shelfInformation" && value === "") {
      this.setState({
        shelfInformationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    var array = this.state.productPropertyDto;
    array.forEach((item) => {
      item.propertyTypeGuid = "";
      item.value = "";
      item.propertyTypeError = CgnMessage.textErrorMessage;
      item.valueError = CgnMessage.textErrorMessage;
    });
    var array2 = this.state.maintenanceKitDto;
    array2.forEach((item) => {
      item.maintenanceKitGuid = "";
      item.maintenanceKitName = "";
      item.maintenanceKitPiece = "";
      item.maintenanceKitError = CgnMessage.textErrorMessage;
      item.maintenanceKitBuyPrice = "";
      item.maintenanceKitSalePrice = "";
    });
    await this.setState({
      categoryGuid: "",
      contractGuid: "00000000-0000-0000-0000-000000000000",
      categoryName: "",
      peerProductGuid: "00000000-0000-0000-0000-000000000000",
      image: "",
      code: "",
      description: null,
      name: "",
      criticalPieces: "",
      currencyType: "",
      salePrice: "",
      buyPrice: "",
      supplierProductCode: "",
      status: true,
      counter: -1,
      counter2: -1,
      stockStatus: 1,
      isTrue: false,
      viewStatus: null,
      isTrueBuyPrices: false,
      isTrueSalePrices: false,
      maintenanceKitDto: [],
      productPropertyDto: [],
      shelfInformation: "",
      alertErrorMessage: CgnMessage.alertErrorMessage,
      categoryNameError: CgnMessage.textErrorMessage,
      codeError: CgnMessage.textErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      criticalPiecesError: CgnMessage.textErrorMessage,
      shelfInformationError: CgnMessage.textErrorMessage,
      // salePriceError:
      //   this.state.maintenanceKitDto === [] ? "" : CgnMessage.textErrorMessage,
    });
  }

  async handleCategorySelectChange(option) {
    if (option) {
      await this.setState({
        categoryGuid: option.value,
        categoryNameError: "",
        alertErrorMessage: "",
      });
      if (this.state.isCSSavunma) {
        this.barcodeGenerator();
      }
    } else {
      this.setState({
        categoryGuid: "",
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleContractSelectChange(option) {
    if (option) {
      this.setState({
        contractGuid: option.value,
      });
    } else {
      this.setState({
        contractGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handlePeerProductSelectChange(option) {
    if (option) {
      this.setState({
        peerProductGuid: option.value,
      });
    } else {
      this.setState({
        peerProductGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleViewStatusSelectChange(option) {
    if (option) {
      this.setState({
        viewStatus: option.value,
      });
    } else {
      this.setState({
        viewStatus: null,
      });
    }
  }

  handleKitStatusSelectChange(option) {
    if (option) {
      this.setState({
        kitStatus: option.value,
      });
    } else {
      this.setState({
        kitStatus: null,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleProductPropertyAdd = async () => {
    var array = this.state.productPropertyDto;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      productGuid: "00000000-0000-0000-0000-000000000000",
      propertyTypeGuid: "",
      value: "",
      propertyTypeError: CgnMessage.textErrorMessage,
      valueError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].propertyTypeGuid) {
      return array[this.state.counter].propertyTypeError === "";
    }
    if (array[this.state.counter].value) {
      return array[this.state.counter].valueError === "";
    }
    if (
      (array[this.state.counter].propertyTypeGuid === "" ||
        array[this.state.counter].value === "") &&
      array.length > 0
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productPropertyDto: array });
  };

  handleTextBoxChange(e, idx) {
    let productPropertyDto = this.state.productPropertyDto;
    productPropertyDto[idx].value = e.target.value;
    this.setState({
      productPropertyDto: productPropertyDto,
    });
    this.productPropertyRequiredField();
  }

  handleInputValueChange(option, idx) {
    let productPropertyDto = this.state.productPropertyDto;
    if (option) {
      productPropertyDto[idx].propertyTypeGuid = option.value;
      productPropertyDto[idx].propertyTypeError = "";
      this.setState({
        productPropertyDto: productPropertyDto,
      });
    } else {
      productPropertyDto[idx].propertyTypeGuid = null;
      productPropertyDto[idx].propertyTypeError = CgnMessage.textErrorMessage;
      this.setState({
        productPropertyDto: productPropertyDto,
      });
    }
    this.productPropertyRequiredField();
  }

  productPropertyRequiredField = () => {
    var arr = this.state.productPropertyDto;
    arr.forEach((item) => {
      if (!item.propertyTypeGuid && item.value) {
        item.propertyTypeError = CgnMessage.textErrorMessage;
        item.valueError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (item.propertyTypeGuid && !item.value) {
        item.propertyTypeError = "";
        item.valueError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (!item.propertyTypeGuid && !item.value) {
        item.propertyTypeError = CgnMessage.textErrorMessage;
        item.valueError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (item.propertyTypeGuid && item.value) {
        item.propertyTypeError = "";
        item.valueError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
    });
  };

  async handleProductPropertyRemove(idx) {
    let someArray = await this.state.productPropertyDto;
    someArray.splice(idx, 1);
    this.setState({
      productPropertyDto: someArray,
      counter: this.state.counter - 1,
      isNull: false,
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        isNull: false,
        productGuid: "00000000-0000-0000-0000-000000000000",
        propertyTypeGuid: "",
        value: "",
      });
    }
  }

  handleMaintenanceKitAdd = async () => {
    var array = this.state.maintenanceKitDto;
    this.setState({
      alertErrorMessage: CgnMessage.alertErrorMessage,
      isNull: true,
      isTrueSalePrices: true,
      isTrueBuyPrices: true,
    });
    await array.push({
      maintenanceKitGuid: "00000000-0000-0000-0000-000000000000",
      maintenanceKitPiece: 1,
      maintenanceKitBuyPrice: "",
      maintenanceKitSalePrice: "",
      unitBuyPrice: "",
      unitSalePrice: "",
      maintenanceKitName: "",
      maintenanceKitError: CgnMessage.textErrorMessage,
      maintenanceKitPieceError: "",
      maintenanceKitBuyPriceError: CgnMessage.textErrorMessage,
      maintenanceKitSalePriceError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter2: this.state.counter2 + 1,
    });

    this.setState({ maintenanceKitDto: array });
  };

  async handleMaintenanceKitChange(option, idx) {
    let maintenanceKitDto = this.state.maintenanceKitDto;
    if (option) {
      maintenanceKitDto[idx].maintenanceKitGuid = option.value;
      maintenanceKitDto[idx].maintenanceKitName = option.maintenanceKitName;
      maintenanceKitDto[idx].maintenanceKitError = "";
      maintenanceKitDto[idx].unitBuyPrice = parseFloat(option.unitBuyPrice);
      maintenanceKitDto[idx].unitSalePrice = parseFloat(option.unitSalePrice);
      maintenanceKitDto[idx].maintenanceKitBuyPrice =
        parseFloat(option.unitBuyPrice) *
        maintenanceKitDto[idx].maintenanceKitPiece;
      maintenanceKitDto[idx].maintenanceKitSalePrice =
        parseFloat(option.unitSalePrice) *
        maintenanceKitDto[idx].maintenanceKitPiece;
      maintenanceKitDto[idx].maintenanceKitBuyPriceError = "";
      maintenanceKitDto[idx].maintenanceKitSalePriceError = "";
      var buyPrice = 0;
      var salePrice = 0;
      await maintenanceKitDto.forEach((element) => {
        if (element.maintenanceKitBuyPrice > 0) {
          buyPrice += element.maintenanceKitBuyPrice;
        }
        if (element.maintenanceKitSalePrice > 0) {
          salePrice += element.maintenanceKitSalePrice;
        }
      });
      await this.setState({
        maintenanceKitDto: maintenanceKitDto,
        buyPrice: buyPrice,
        salePrice: salePrice,
        alertErrorMessage: "",
        isNull: false,
        isTrueBuyPrices: true,
        isTrueSalePrices: true,
      });
    } else {
      this.setState({
        maintenanceKitDto: maintenanceKitDto,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        maintenanceKitPieceError: CgnMessage.textErrorMessage,
        isNull: true,
        isTrueBuyPrices: false,
        isTrueSalePrices: false,
      });
      maintenanceKitDto[idx].maintenanceKitGuid = "";
      maintenanceKitDto[idx].maintenanceKitName = "";
      maintenanceKitDto[idx].maintenanceKitBuyPrice = 0;
      maintenanceKitDto[idx].maintenanceKitSalePrice = 0;
      maintenanceKitDto[idx].maintenanceKitError = CgnMessage.textErrorMessage;
    }
  }

  async handleMaintenanceKitSalePriceChange(option, idx) {
    let maintenanceKitDto = this.state.maintenanceKitDto;
    maintenanceKitDto[idx].maintenanceKitSalePrice = parseFloat(
      option.target.value
    );
    maintenanceKitDto[idx].maintenanceKitSalePriceError = "";
    var salePrice = 0;
    await maintenanceKitDto.forEach((element) => {
      if (element.maintenanceKitSalePrice > 0) {
        salePrice += parseFloat(element.maintenanceKitSalePrice);
      }
    });
    await this.setState({
      maintenanceKitDto: maintenanceKitDto,
      salePrice: salePrice,
      alertErrorMessage: "",
      isNull: false,
      isTrueSalePrices: true,
    });
    if (!option.target.value) {
      maintenanceKitDto[idx].maintenanceKitSalePrice = "";
      maintenanceKitDto[idx].maintenanceKitSalePriceError =
        CgnMessage.textErrorMessage;
      await this.setState({
        maintenanceKitDto: maintenanceKitDto,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isNull: true,
        isTrueSalePrices: false,
      });
    }
  }

  async handleMaintenanceKitBuyPriceChange(option, idx) {
    let maintenanceKitDto = this.state.maintenanceKitDto;
    maintenanceKitDto[idx].maintenanceKitBuyPrice = parseFloat(
      option.target.value
    );
    maintenanceKitDto[idx].maintenanceKitBuyPriceError = "";
    var buyPrice = 0;
    await maintenanceKitDto.forEach((element) => {
      if (element.maintenanceKitBuyPrice > 0) {
        buyPrice += parseFloat(element.maintenanceKitBuyPrice);
      }
    });
    await this.setState({
      maintenanceKitDto: maintenanceKitDto,
      buyPrice: buyPrice,
      alertErrorMessage: "",
      isNull: false,
      isTrueBuyPrices: true,
    });
    if (!option.target.value) {
      maintenanceKitDto[idx].maintenanceKitBuyPrice = "";
      maintenanceKitDto[idx].maintenanceKitBuyPriceError =
        CgnMessage.textErrorMessage;
      await this.setState({
        maintenanceKitDto: maintenanceKitDto,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isNull: true,
        isTrueBuyPrices: false,
      });
    }
  }

  async handleMaintenanceKitPieceChange(e, idx) {
    let maintenanceKitDto = this.state.maintenanceKitDto;

    if (maintenanceKitDto[idx].maintenanceKitPiece) {
      maintenanceKitDto[idx].unitSalePrice =
        maintenanceKitDto[idx].maintenanceKitPiece !== 0
          ? parseFloat(maintenanceKitDto[idx].maintenanceKitSalePrice) /
          maintenanceKitDto[idx].maintenanceKitPiece
          : parseFloat(maintenanceKitDto[idx].maintenanceKitSalePrice);
      maintenanceKitDto[idx].unitBuyPrice =
        maintenanceKitDto[idx].maintenanceKitPiece !== 0
          ? parseFloat(maintenanceKitDto[idx].maintenanceKitBuyPrice) /
          maintenanceKitDto[idx].maintenanceKitPiece
          : parseFloat(maintenanceKitDto[idx].maintenanceKitBuyPrice);
    }
    if (e.target.value) {
      maintenanceKitDto[idx].maintenanceKitPiece = parseInt(e.target.value);
      maintenanceKitDto[idx].maintenanceKitBuyPrice =
        maintenanceKitDto[idx].maintenanceKitPiece *
        parseFloat(maintenanceKitDto[idx].unitBuyPrice);
      maintenanceKitDto[idx].maintenanceKitSalePrice =
        maintenanceKitDto[idx].maintenanceKitPiece *
        parseFloat(maintenanceKitDto[idx].unitSalePrice);
      maintenanceKitDto[idx].maintenanceKitPieceError = "";
      maintenanceKitDto[idx].maintenanceKitBuyPriceError = "";
      maintenanceKitDto[idx].maintenanceKitSalePriceError = "";

      this.setState({
        alertErrorMessage: "",
        isNull: false,
      });
    } else {
      maintenanceKitDto[idx].maintenanceKitPiece = "";
      maintenanceKitDto[idx].maintenanceKitBuyPrice = "";
      maintenanceKitDto[idx].maintenanceKitSalePrice = "";
      maintenanceKitDto[idx].maintenanceKitPieceError =
        CgnMessage.textErrorMessage;
      maintenanceKitDto[idx].maintenanceKitBuyPriceError =
        CgnMessage.textErrorMessage;
      maintenanceKitDto[idx].maintenanceKitSalePriceError =
        CgnMessage.textErrorMessage;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isNull: true,
      });
    }
    var buyPrice = 0;
    var salePrice = 0;
    await maintenanceKitDto.forEach((element) => {
      if (element.maintenanceKitPiece > 0) {
        buyPrice += parseFloat(element.maintenanceKitBuyPrice);
      }
      if (element.maintenanceKitPiece > 0) {
        salePrice += parseFloat(element.maintenanceKitSalePrice);
      }
    });
    this.setState({
      buyPrice: buyPrice,
      salePrice: salePrice,
      maintenanceKitDto: maintenanceKitDto,
    });
  }

  async handleMaintenanceKitRemove(idx) {
    let someArray = this.state.maintenanceKitDto;
    this.state.buyPrice -= someArray[idx].maintenanceKitBuyPrice;
    this.state.salePrice -= someArray[idx].maintenanceKitSalePrice;
    someArray.splice(idx, 1);
    await this.setState({
      maintenanceKitDto: someArray,
      counter2: this.state.counter2 - 1,
    });
    if (idx === 0 && someArray.length === 0) {
      this.setState({
        alertErrorMessage: "",
        isNull: false,
        isTrueSalePrices: false,
        isTrueBuyPrices: false,
        maintenanceKitDto: [],
        salePrice: 0,
        buyPrice: 0,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      categoryNameError: "",
      codeError: "",
      nameError: "",
      criticalPiecesError: "",
      currencyTypeError: "",
      salePriceError: "",
      contractError: "",
      shelfInformationError: "",
    });
    if (this.state.categoryGuid === "") {
      this.setState({
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.code === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.stockStatus === 1 && this.state.criticalPieces === "") {
      this.setState({
        criticalPiecesError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.productPropertyDto.length > 0) {
      this.state.productPropertyDto.forEach((element) => {
        if (element.productPropertyGuid === "" || element.value === "") {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (this.state.maintenanceKitDto.length === 0 && !this.state.salePrice) {
      this.setState({
        salePriceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.categoryGuid !== "" &&
      this.state.code !== "" &&
      this.state.name !== "" &&
      ((this.state.stockStatus === 0 && this.state.criticalPieces === "") ||
        (this.state.stockStatus === 1 && this.state.criticalPieces !== "")) &&
      this.state.currencyType !== "" &&
      this.state.salePrice !== "" &&
      ((this.state.maintenanceKitDto.length > 0 &&
        this.state.buyPrice &&
        this.state.salePrice) ||
        (this.state.maintenanceKitDto.length === 0 && this.state.salePrice)) &&
      this.state.isNull === false &&
      control === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        categoryGuid: this.state.categoryGuid,
        contractGuid: this.state.contractGuid,
        image: this.state.image,
        code: this.state.code,
        supplierProductCode: this.state.supplierProductCode,
        name: this.state.name,
        peerProductGuid: this.state.peerProductGuid,
        productPropertyDto: this.state.productPropertyDto,
        maintenanceKitDto: this.state.maintenanceKitDto,
        criticalPieces: parseInt(this.state.criticalPieces),
        currencyType: this.state.currencyType,
        viewStatus: this.state.viewStatus,
        kitStatus: this.state.kitStatus,
        salePrice: parseFloat(this.state.salePrice),
        buyPrice: this.state.buyPrice
          ? parseFloat(this.state.buyPrice)
          : parseFloat(0),
        stockStatus: this.state.stockStatus,
        status: this.state.status,
        productImageDto: this.state.productImageDto,
        productPictureDto: this.state.productPictureDto,
        productPicture2Dto: this.state.productPicture2Dto,
        description: this.state.description,
        shelfInformation: this.state.shelfInformation,
      };
      var URLParam = "/products/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "urun-guncelle"
      ) {
        URLParam = "/products/update";
        myObj = {
          guid: this.state.guid,
          image: this.state.image,
          categoryGuid: this.state.categoryGuid,
          contractGuid: this.state.contractGuid,
          code: this.state.code,
          supplierProductCode: this.state.supplierProductCode,
          name: this.state.name,
          peerProductGuid: this.state.peerProductGuid,
          productPropertyDto: this.state.productPropertyDto,
          maintenanceKitDto: this.state.maintenanceKitDto,
          criticalPieces: parseInt(this.state.criticalPieces),
          currencyType: this.state.currencyType,
          viewStatus: this.state.viewStatus,
          buyPrice: this.state.buyPrice
            ? parseFloat(this.state.buyPrice)
            : parseFloat(0.0),
          salePrice: parseFloat(this.state.salePrice),
          stockStatus: this.state.stockStatus,
          status: this.state.status,
          productImageDto: this.state.productImageDto,
          kitStatus: this.state.kitStatus,
          productPictureDto: this.state.productPictureDto,
          productPicture2Dto: this.state.productPicture2Dto,
          description: this.state.description,
          shelfInformation: this.state.shelfInformation,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid && this.state.urlSplit[1] === "urun-guncelle") {
              window.location.href = "/urun-listele/" + this.state.categoryGuid;
            }
            if (this.state.urlSplit[1] === "urun-ekle") {
              window.location.reload();
            }
            var modalAddedProduct = {
              data: `${this.state.code}-${this.state.name}`,
              salePrice: this.state.salePrice,
              buyPrice: this.state.buyPrice,
              maintenanceKitDto: this.state.maintenanceKitDto,
              isModal: false,
            };
            this.props.history.push({ modalAddedProduct });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  // uploadedImage = async (event) => {
  //   const file = event.target.files[0];
  //   if(file.type === "image/png" || file.type === "image/jpeg"){
  //     const image = await resizeFile(file, 1000);
  //     this.setState({
  //       image: image,
  //       imageName: file.name,
  //     });
  //   }
  // };

  // deleteImage = (event) => {
  //   this.setState({
  //     image: "",
  //     imageName: "",
  //   });
  // };

  uploadedImage = async (event) => {
    this.setState({
      isLoading: true,
    });
    let array = this.state.productPictureDto;
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        });
      }
    }
    await this.setState({ productPictureDto: array, isLoading: false });
  };

  deleteImage = (event) => {
    var index = -1;
    let array = this.state.productPictureDto;
    this.state.productPictureDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ productPictureDto: array });
        }
      }
    });
  };

  uploadedImage2 = async (event) => {
    this.setState({
      isLoading: true,
    });
    let array = this.state.productImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "application/pdf") {
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
              image: response.data,
              imageName: file.name,
            });
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({ productImageDto: array, isLoading: false });
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.productImageDto;
    this.state.productImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ productImageDto: array });
        }
      }
    });
  };

  uploadedImage3 = async (event) => {
    this.setState({
      isLoading: true,
    });
    let array = this.state.productPicture2Dto;
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        });
      }
    }
    await this.setState({ productPicture2Dto: array, isLoading: false });
  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.productPicture2Dto;
    this.state.productPicture2Dto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ productPicture2Dto: array });
        }
      }
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "urun-ekle" ||
          this.state.urlSplit[1] === "urun-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                {!this.state.isCSSavunma &&
                  <Row>
                    <Col lg="12">
                      <PictureUploadMulti
                        image={this.state.productPicture2Dto}
                        noFoto={noImg}
                        style={fotoStyle}
                        accept="image/png, image/jpeg"
                        className="text-center text-danger"
                        label={"Ürün Fotoğrafları (Müşteri)"}
                        onChange={this.uploadedImage3}
                        onDelete={this.deleteImage3}
                        alt={this.state.name}
                      //error={this.state.imageError2}
                      />
                    </Col>
                  </Row>
                }
                <Row>
                  <Col lg="12">
                    <PictureUploadMulti
                      image={this.state.productPictureDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Ürün Fotoğrafları"}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      alt={this.state.name}
                    //error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                {!this.state.isCSSavunma &&
                  <Row>
                    <Col lg="12">
                      <ImageUploadMulti
                        images={this.state.productImageDto}
                        noFoto={noImg}
                        style={fotoStyle}
                        pdfIcon={pdfIcon}
                        accept="application/pdf"
                        className="text-center text-danger"
                        label={"Dosya"}
                        onChange={this.uploadedImage2}
                        onDelete={this.deleteImage2}
                        error={this.state.imageError2}
                      />
                    </Col>
                  </Row>
                }
                <Row>
                  <Col lg="4" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="categoryGuid"
                          label="Kategori [*]"
                          placeholder="Kategori seçiniz..."
                          selectValue={this.state.categoryGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCategorySelectChange(option)
                          }
                          options={this.state.categoryArr}
                          error={this.state.categoryNameError}
                        />
                      </div>
                      {this.state.categoryModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCategory()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="code"
                      label="Ürün Kodu [*]"
                      value={this.state.code}
                      placeHolder="Lütfen ürün kodu girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.codeError}
                      readOnly={!this.state.isCSSavunma ? true : false}
                    />
                  </Col>
                  {!this.state.isCSSavunma &&
                    <Col lg="2">
                      <CgnButton
                        className="barcodeButton"
                        type="button"
                        color="secondary"
                        block
                        onClick={() => this.barcodeGenerator()}
                        text={"Ürün Kodu Üret"}
                      />
                    </Col>
                  }
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="supplierProductCode"
                      label="Tedarikçi Ürün Kodu"
                      value={this.state.supplierProductCode}
                      placeHolder="Lütfen tedarikçi ürün kodu girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.supplierProductCodeError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Ürün Adı [*]"
                      value={this.state.name}
                      placeHolder="Lütfen ürün girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                  {!this.state.isCSSavunma &&
                    <Col lg="4" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="peerProductGuid"
                            label="Emsal Ürün"
                            placeholder="Emsal ürün seçiniz..."
                            selectValue={this.state.peerProductGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handlePeerProductSelectChange(option)
                            }
                            options={this.state.peerProductArr}
                            error={this.state.peerProductError}
                          />
                        </div>
                      </div>
                    </Col>
                  }
                  <Col lg="2">
                    <CgnReactSelect
                      name="viewStatus"
                      label="Ürün Özellik Durum"
                      placeholder="Durum seçiniz..."
                      selectValue={this.state.viewStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleViewStatusSelectChange(option)
                      }
                      options={viewStatuses}
                    />
                  </Col>
                  {!this.state.isCSSavunma &&
                    <Col lg="2">
                      <CgnReactSelect
                        name="kitStatus"
                        label="Bakım Kiti Durum"
                        placeholder="Durum seçiniz..."
                        selectValue={this.state.kitStatus}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) =>
                          this.handleKitStatusSelectChange(option)
                        }
                        options={kitStatuses}
                      />
                    </Col>
                  }
                </Row>

                {this.state.productPropertyDto.map(
                  (productPropertyDto, idx) => (
                    <Row className="mobileBorder">
                      <Col lg="6" className="modalSelectCol">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="propertyTypeGuid"
                              label="Ürün Özellikleri Tipi [*]"
                              placeholder="Lütfen ürün özellikleri tipi seçiniz..."
                              selectValue={productPropertyDto.propertyTypeGuid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleInputValueChange(option, idx);
                              }}
                              options={this.state.productPropertyTypeArr}
                              error={productPropertyDto.propertyTypeError}
                            />
                          </div>
                          {this.state.productPropertyTypeModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() =>
                                  this.addModalProductPropertyType(idx)
                                }
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col lg="5">
                        <CgnTextbox
                          type="text"
                          name="value"
                          label="Ürün Özellikleri [*]"
                          value={productPropertyDto.value}
                          onChange={(e) => this.handleTextBoxChange(e, idx)}
                          placeHolder="Lütfen ürün özellikleri girin..."
                          //maxLength="50"
                          autoComplete="off"
                          error={productPropertyDto.valueError}
                        />
                      </Col>

                      <Col lg="1" sm="3">
                        <CgnButton
                          type="button"
                          color="danger"
                          className="dynamicRowDeleteButton"
                          onClick={() => this.handleProductPropertyRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  )
                )}

                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-3 dynamicRowAddButton"
                      onClick={this.handleProductPropertyAdd}
                      text={"Ürün Özellikleri Ekle"}
                    />
                  </Col>
                </Row>

                {!this.state.isCSSavunma &&
                  <>
                    {this.state.maintenanceKitDto.map((maintenanceKitDto, idx) => (
                      <Row className="mobileBorder">
                        <Col lg="3">
                          <div className="flex-container">
                            <div className="p-0 flexInput">
                              <CgnReactSelect
                                name="maintenanceKitGuid"
                                label="Bakım Kiti [*]"
                                placeholder="Lütfen bakım kiti seçiniz..."
                                selectValue={maintenanceKitDto.maintenanceKitGuid}
                                isMulti={false}
                                isClearable={true}
                                onChange={(option) => {
                                  this.handleMaintenanceKitChange(option, idx);
                                }}
                                options={this.state.maintenanceKitArr}
                                error={maintenanceKitDto.maintenanceKitGuidError}
                              />
                            </div>
                            {/* {this.state.productPropertyTypeModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() =>
                                  this.addModalProduct2(idx)
                                }
                                text={"+"}
                              />
                            </div>
                          )} */}
                          </div>
                        </Col>

                        <Col lg="2">
                          <CgnTextbox
                            type="number"
                            name="maintenanceKitPiece"
                            label="Adet [*]"
                            value={maintenanceKitDto.maintenanceKitPiece}
                            onChange={(e) =>
                              this.handleMaintenanceKitPieceChange(e, idx)
                            }
                            placeHolder="Lütfen adet girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={maintenanceKitDto.maintenanceKitPieceError}
                          />
                        </Col>

                        <Col lg="3">
                          <CgnTextbox
                            type="number"
                            name="maintenanceKitBuyPrice"
                            label="Alış Fiyatı"
                            value={maintenanceKitDto.maintenanceKitBuyPrice}
                            placeHolder="Lütfen alış fiyatı girin..."
                            maxLength="100"
                            autoComplete="off"
                            onChange={(e) =>
                              this.handleMaintenanceKitBuyPriceChange(e, idx)
                            }
                            error={maintenanceKitDto.maintenanceKitBuyPriceError}
                          />
                        </Col>

                        <CgnTextbox //// hidden text
                          type="number"
                          name="unitBuyPrice"
                          value={maintenanceKitDto.unitBuyPrice}
                          onChange={(e) => this.handleMaintenanceKitChange(e, idx)}
                          hidden={true}
                        />

                        <Col lg="3">
                          <CgnTextbox
                            type="number"
                            name="salePrice"
                            label="Satış Fiyatı"
                            value={maintenanceKitDto.maintenanceKitSalePrice}
                            onChange={(option) =>
                              this.handleMaintenanceKitSalePriceChange(option, idx)
                            }
                            placeHolder="Lütfen satış fiyatı girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={maintenanceKitDto.maintenanceKitSalePriceError}
                          />
                        </Col>

                        <CgnTextbox //// hidden text
                          type="number"
                          name="unitSalePrice"
                          value={maintenanceKitDto.unitSalePrice}
                          onChange={(e) => this.handleMaintenanceKitChange(e, idx)}
                          hidden={true}
                        />

                        <Col lg="1" sm="3">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton"
                            onClick={() => this.handleMaintenanceKitRemove(idx)}
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    ))}

                    <Row>
                      <Col lg="4">
                        <CgnButton
                          type="button"
                          color="primary"
                          className="mb-3 dynamicRowAddButton"
                          style={{ width: "141px" }}
                          onClick={this.handleMaintenanceKitAdd}
                          text={"Bakım Kiti Ekle"}
                        />
                      </Col>
                    </Row>
                  </>
                }

                <Row>
                  {!this.state.isCSSavunma &&
                    <>
                      <Col className="pl-0" lg="2">
                        <CgnTextbox
                          type="number"
                          name="buyPrice"
                          label="Alış Fiyatı"
                          value={this.state.buyPrice}
                          placeHolder="Lütfen alış fiyatı girin..."
                          maxLength="100"
                          autoComplete="off"
                          onChange={this.handleChange}
                          readOnly={this.state.isTrueBuyPrices}
                          error={this.state.buyPriceError}
                        />
                      </Col>
                      <Col lg="2">
                        <CgnTextbox
                          type="number"
                          name="salePrice"
                          label="Satış Fiyatı [*]"
                          value={this.state.salePrice}
                          placeHolder="Lütfen satış fiyatı girin..."
                          maxLength="100"
                          autoComplete="off"
                          onChange={this.handleChange}
                          error={this.state.salePriceError}
                          readOnly={this.state.isTrueSalePrices}
                        />
                      </Col>
                      <Col lg="2">
                        <CgnReactSelect
                          name="currencyType"
                          label="Para Birimi [*]"
                          placeholder="Lütfen para birimi seçiniz..."
                          selectValue={this.state.currencyType}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCurrencyTypeSelectChange(option)
                          }
                          options={CurrencyType}
                          error={this.state.currencyTypeError}
                        />
                      </Col>
                    </>
                  }
                  <Col lg="2">
                    <CgnReactSelect
                      name="stockStatus"
                      label="Stok Durumu"
                      placeholder="Lütfen stok durumu seçiniz..."
                      selectValue={this.state.stockStatus}
                      isMulti={false}
                      isClearable={false}
                      onChange={(e) => this.handleStockStatus(e)}
                      options={StockStatus}
                      error={this.state.stockStatusError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="shelfInformation"
                      label="Raf Bilgisi"
                      value={this.state.shelfInformation}
                      placeHolder="Lütfen raf bilgisi girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.shelfInformationError}
                    />
                  </Col>
                  <Col className="pr-0" lg="2">
                    <CgnTextbox
                      type="number"
                      name="criticalPieces"
                      label="Kritik Adet [*]"
                      value={this.state.criticalPieces}
                      placeHolder="Lütfen kritik adet girin..."
                      maxLength="100"
                      autoComplete="off"
                      disabled={this.state.isTrue}
                      onChange={this.handleChange}
                      error={this.state.criticalPiecesError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="contractGuid"
                          label="Sözleşme"
                          placeholder="Sözleşme seçiniz..."
                          selectValue={this.state.contractGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleContractSelectChange(option)
                          }
                          options={this.state.contractArr}
                          error={this.state.contractError}
                        />
                      </div>
                      <div className="p-0 flexMaxAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalContract()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>
                </Row> */}

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "urun-ekle" ||
              this.state.urlSplit[1] === "urun-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCategory}
          toggle={this.addModalCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Kategori Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProductPropertyType}
          toggle={this.addModalProductPropertyType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Ürün Özellikleri Tipi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ProductPropertyTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProductPropertyType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalContract}
          toggle={this.addModalContract}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Sözleşme Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ContractAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalContract}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Emsal Ürün Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProduct2}
          toggle={this.addModalProduct2}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Bakım Kiti Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct2}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(ProductAddUpdate);
