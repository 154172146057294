import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Label,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxRateChoices,
  CollectionMethodType,
  CurrencyType,
  resizeFile,
  fotoStyle,
  PaymentMethodType,
  
  UserOperationClaimControl,
  unitValuesStatuses,
  BillScenario,
  BillTypes,
  DeliveryType,
  TransportType,
  CabinType,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";
import BankAddUpdate from "../bank/BankAddUpdate";
import LocationAddUpdate from "../location/LocationAddUpdate";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";

class ProductOutSecondAddUpdate extends Component {
  state = {
    pageTitle: "Satış Ekle",
    pageTitleAdd: "Satış Ekle",
    pageTitleUpdate: "Satış Güncelle",
    pageAddLink: "yeni-satis-ekle",
    pageListLink: "yeni-satis-listele",
    breadcrumb: [{ text: "Satış", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productOutDate: "",
    customerGuid: "",
    locationguid: "",
    guid: "",
    incomeGuid: "",
    total: 0,
    generalTotal: parseFloat(0).toFixed(2),
    taxRateValue: "",
    taxTypeValue: null,
    discountPercent: "",
    tevkifatRate: 0,
    discountValue: "",
    discountTotal: parseFloat(0).toFixed(2),
    currencyType: "",
    currencyTypeError: "",
    deliveryAddress: "",
    deliveryCity: "",
    deliveryDistrict: "",
    billTypeId: 0,
    billScenarioId: 0,
    billScenarioError: "",
    billTypeError: "",
    isDiscountPercent: false,
    cabinPiece: 1,
    cabinNo: null,
    deliveryType: null,
    transportTypeId: null,
    transportTypeError: "",
    cabinType: null,
    cabinTypeError: "",
    isDiscountValue: false,
    unitError: "",
    productTaxRate: "",
    productDiscountPercentError: "",
    productDiscountValueError: "",
    productDiscountPercent: "",
    generalTotal1: 0,
    generalTaxTotal1: 0,
    generalDiscountTotal1: 0,
    productDiscountValue: "",
    description: "",
    status: true,
    productOutDateError: "",
    customerError: "",
    totalError: "",
    taxRateError: "",
    taxTypeError: "",
    productGuid: "",
    alertErrorMessage: "",
    deliveryTypeError: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    isOpenModalCustomer: false,
    isOpenModalProduct: false,
    modalButtonIdx: 0,
    counter: -1,
    collectionCalendarData: [],
    productData: [],
    productDataError: "",
    productOutModal: false,
    multipleProductArr: [],
    tevkifatArr: [],
    productOutImageDto: [],
    receiptNo: "",
    expiry: null,
    collectionMethodValue: null,
    bankGuid: null,
    chequesBondNo: "",
    image: "",
    cabinAddress: null,
    cabinCity: null,
    cabinDistrict: null,
    cabinCountry: null,
    cabinAddressError: "",
    cabinCityError: "",
    cabinDistrictError: "",
    cabinNoError: "",
    imageName: "",
    isOpenModalBank: false,
    isOpenModalLocation: false,
    isBankTrue: false,
    isHiddenIstisna: true,
    isHiddenIhracat: true,
    isChequesBondNoTrue: false,
    isHidden: true,
    istisnaError: "",
    istisnaArr: [],
    countryArr: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    customerModalPlus: false,
    productModalPlus: false,
    collectionModalPlus: false,
    bankModalPlus: false,
    collectionCalendarModalPlus: false,
    locationModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
      productModalPlus: await UserOperationClaimControl(1029),
      collectionModalPlus: await UserOperationClaimControl(1009),
      bankModalPlus: await UserOperationClaimControl(1003),
      collectionCalendarModalPlus: await UserOperationClaimControl(1008),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            salePrice: item.salePrice,
            code: item.code,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/tevkifats/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.tevkifatId,
            label: item.tevkifatNo + " (" + item.tevkifatRate + ")",
            tevkifatRate: item.tevkifatRate,
          };
        });
        this.setState({
          tevkifatArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/istisnas/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.id,
            label:
              item.istisnaCode +
              " " +
              item.istisnaKDVK +
              " " +
              item.istisnaName,
          };
        });
        this.setState({
          istisnaArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/countries/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          countryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    if (urlSplit.length === 3 && urlSplit[1] === "yeni-satis-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/productOutsSecond/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              productOutDate: response.data.productOutDate,
              incomeGuid: response.data.incomeGuid,
              customerGuid: response.data.customerGuid,
              productData: response.data.productOutProductSecondDto,
              total: response.data.total,
              generalTotal: response.data.generalTotal,
              collectionCalendarData: response.data.collectionCalendarDto,
              billTypeId: response.data.billTypeId,
              billScenarioId: response.data.billScenarioId,
              currencyType: response.data.currencyType,
              generalDiscountTotal: response.data.generalDiscountTotal,
              generalTaxTotal: response.data.generalTaxTotal,
              description: response.data.description,
              status: response.data.status,
              receiptNo: response.data.receiptNo,
              expiry: response.data.expiry,
              collectionMethodValue: response.data.collectionMethodType,
              bankGuid: response.data.bankGuid,
              chequesBondNo: response.data.chequesBondNo,
              image: response.data.image,
              isLoading: false,
              isHiddenIstisna: response.data.billTypeId === 4 ? false : true,
              cabinAddress: response.data.cabinAddress,
              cabinCity: response.data.cabinCity,
              cabinDistrict: response.data.cabinDistrict,
              cabinPiece: response.data.cabinPiece,
              cabinNo: response.data.cabinNo,
              deliveryType: response.data.deliveryType,
              transportTypeId: response.data.transportType,
              cabinCountry: response.data.cabinCountryGuid,
              isHiddenIhracat:
                response.data.billScenarioId === 3 ? false : true,
              cabinType: response.data.cabinType,
              counter: response.data.productOutProductSecondDto.length - 1,
              productOutImageDto: response.data.productOutImageDto,
            });
            let productData = this.state.productData;
            productData.forEach((item) => {
              if (item.productDiscountPercent === 0) {
                item.productDiscountPercent = "";
              }
              if (item.productDiscountValue === 0) {
                item.productDiscountValue = "";
              }
              if (item.deliveryDate === "01/01/1900") {
                item.deliveryDate = null;
              }
            });
            this.setState({
              productData: productData,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        productOutDate: "",
        customerGuid: "",
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        discountTotal: "",
        currencyType: "",
        collectionCalendarData: [],
        productOutImageDto: [],
        isDiscountPercent: false,
        isDiscountValue: false,
        description: "",
        receiptNo: "",
        expiry: "",
        collectionMethodValue: null,
        bankGuid: null,
        chequesBondNo: "",
        image: "",
        imageName: "",
        status: true,
        isHidden: true,
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
              salePrice: item.salePrice,
              code: item.code,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
            }
          });
          if (this.props.location.modalAddedProduct.salePrice) {
            productData[this.state.modalButtonIdx].unitPrice = parseFloat(
              this.props.location.modalAddedProduct.salePrice
            );
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
            productData[this.state.modalButtonIdx].unit = "";
            productData[this.state.modalButtonIdx].productTaxRate = "";
            productData[this.state.modalButtonIdx].productDiscountPercent = "";
            productData[this.state.modalButtonIdx].productDiscountValue = "";
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
            //productData[this.state.modalButtonIdx].unitError = "";
          }
          this.setState({
            alertErrorMessage: "",
            isNull: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalBank &&
      this.props.location.modalAddedBank !== undefined &&
      this.props.location.modalAddedBank.isModal !== undefined &&
      this.props.location.modalAddedBank.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/banks/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            bankArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBank.data &&
              element.name === this.props.location.modalAddedBank.data
            ) {
              this.setState({
                bankGuid: element.guid,
                bankError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBank.isModal = true;
      this.setState({
        isOpenModalBank: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].locationGuid =
                element.guid;
              productData[this.state.modalButtonIdx].locationError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleCollectionMethodSelectChange(option) {
    if (option) {
      this.setState({
        collectionMethodValue: option.value,
        collectionMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else if (!this.state.isHidden) {
      this.setState({
        collectionMethodValue: null,
        collectionMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleBillTypeSelectChange(option) {
    if (option) {
      this.setState({
        billTypeId: option.value,
        billTypeError: "",
      });

      if (option.value === 4) {
        this.setState({
          isHiddenIstisna: false,
        });
      } else {
        this.setState({
          isHiddenIstisna: true,
        });
      }
    } else {
      this.setState({
        billTypeId: 0,
        isHiddenIstisna: true,
        billTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleBillScenarioSelectChange(option) {
    if (option) {
      this.setState({
        billScenarioId: option.value,
        billScenarioError: "",
      });

      if (option.value === 3) {
        this.setState({
          isHiddenIhracat: false,
        });
      } else {
        this.setState({
          isHiddenIhracat: true,
          cabinAddress: null,
          cabinNo: null,
          cabinPiece: 1,
          deliveryType: null,
          transportType: null,
          cabinType: null,
          cabinDistrict: null,
          cabinCity: null,
          cabinCountry: null,
        });
      }
    } else {
      this.setState({
        billScenarioId: 0,
        cabinAddress: null,
        cabinNo: null,
        cabinPiece: 1,
        deliveryType: null,
        transportType: null,
        cabinType: null,
        cabinDistrict: null,
        cabinCity: null,
        cabinCountry: null,
        billScenarioError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productOutModal: !this.state.productOutModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleMultipleProductAdd(item.value);
        }
      });
    });
  };

  handleMultipleProductAdd = async (value) => {
    let productData = this.state.productData;
    await productData.push({
      productGuid: value,
      locationGuid: "",
      piece: 1,
      unitPrice: 1,
      total: 1,
      tevkifatRate: 0,
      tevkifatTotal: 0,
      unit: "NIU",
      productDiscountPercent: 0,
      productDiscountValue: 0,
      productDiscountTotal: 0,
      productTaxRate: null,
      deliveryDate: null,
      requestItemNo: "",
      requestNo: "",
      workOrderNo: "",
      notes: "",
      locationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (
      productData[this.state.counter].productGuid === "" ||
      productData[this.state.counter].piece === 0 ||
      productData[this.state.counter].unitPrice === 0 ||
      productData[this.state.counter].locationGuid === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: productData });
  };

  handleDeliveryTypeSelectChange(option) {
    if (option) {
      this.setState({
        deliveryType: option.value,
        deliveryTypeError: "",
      });
    } else {
      this.setState({
        deliveryType: null,
        deliveryTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleCabinTypeSelectChange(option) {
    if (option) {
      this.setState({
        cabinType: option.value,
        cabinTypeError: "",
      });
    } else {
      this.setState({
        cabinType: null,
        cabinTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleTransportTypeSelectChange(option) {
    if (option) {
      this.setState({
        transportTypeId: option.value,
        transportTypeError: "",
      });
    } else {
      this.setState({
        transportTypeId: null,
        transportTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleCabinCountrySelectChange(option) {
    if (option) {
      await this.setState({
        cabinCountry: option.value,
        cabinCountryError: "",
      });
    } else {
      await this.setState({
        cabinCountry: null,
        cabinCountryError: CgnMessage.textErrorMessage,
      });
    }
  }

  addModalBank = () => {
    this.setState({
      isOpenModalBank: !this.state.isOpenModalBank,
    });
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleDateChange(productOutDate) {
    if (productOutDate) {
      this.setState({
        productOutDate: productOutDate,
        productOutDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productOutDate: "",
        productOutDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      receiptNoError: "",
      expiryError: "",
      collectionMethodTypeError: "",
      bankError: "",
      chequesBondNoError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "customerGuid" && value === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  async handleProductTaxRateSelectChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productTaxRate = option.value;
      productData[idx].productTaxRateError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productTaxRate = "";
      productData[idx].productTaxRateError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    //this.PriceCalculate(null);
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var arrayCollectionCalendar = this.state.collectionCalendarData;
    arrayCollectionCalendar.forEach((item) => {
      item.targetCollectionDate = "";
      item.targetTotal = "";
      item.targetCollectionMethodType = null;
      item.targetCollectionDateError = CgnMessage.textErrorMessage;
      item.targetTotalError = CgnMessage.textErrorMessage;
      item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
      item.targetCurrencyType = CgnMessage.textErrorMessage;
      item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
    });
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.locationGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;

      item.productError = CgnMessage.textErrorMessage;
      item.productPieceError = CgnMessage.textErrorMessage;
      item.productUnitPriceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      productOutDate: "",
      customerGuid: "",
      total: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      description: "",
      status: true,
      receiptNo: "",
      expiry: "",
      collectionMethodValue: null,
      bankGuid: null,
      chequesBondNo: "",
      image: "",
      imageName: "",
      isBankTrue: false,
      isChequesBondNoTrue: false,
      isHidden: true,
      counter: -1,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      productOutDateError: CgnMessage.textErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: "",
      locationGuid: "",
      piece: 1,
      unitPrice: 0,
      taxTotal: 0,
      productDiscountTotal: 0,
      unit: "",
      productDiscountPercent: "",
      productDiscountValue: "",
      total: 0,
      discountTotal: 0,
      tevkifatRate: 0,
      tevkifatTotal: 0,
      deliveryDate: null,
      requestItemNo: "",
      requestNo: "",
      workOrderNo: "",
      notes: "",
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
      productTaxRateError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (array[this.state.counter].unit) {
      return array[this.state.counter].unitError === "";
    }
    if (array[this.state.counter].productTaxRate) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountPercent) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountValue) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (
      array[this.state.counter].productGuid === "" ||
      array[this.state.counter].locationGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].unit === "" ||
      array[this.state.counter].productTaxRate === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleCabinPieceTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinPiece: e.target.value,
        cabinPieceError: "",
      });
    } else {
      this.setState({
        cabinPiece: 1,
        cabinPieceError: "",
      });
    }
  }

  handleCabinNoTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinNo: e.target.value,
        cabinNoError: "",
      });
    } else {
      this.setState({
        cabinPiece: null,
        cabinNoError: "",
      });
    }
  }

  handleDeliveryDateChange(deliveryDate, idx) {
    let productData = this.state.productData;
    productData[idx].deliveryDate = deliveryDate;
    this.setState({
      productData: productData,
    });
  }

  handleRequestNoChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].requestNo = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleRequestItemNoChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].requestItemNo = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleWorkOrderNoChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].workOrderNo = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleNotesChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].notes = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleDeliveryAddressTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinAddress: e.target.value,
        cabinAddressError: "",
      });
    } else {
      this.setState({
        cabinAddress: null,
        cabinAddressError: "",
      });
    }
  }

  handleDeliveryCityTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinCity: e.target.value,
        cabinCityError: "",
      });
    } else {
      this.setState({
        cabinCity: null,
        cabinCityError: "",
      });
    }
  }

  handleDeliveryDistrictTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinDistrict: e.target.value,
        cabinDistrictError: "",
      });
    } else {
      this.setState({
        cabinDistrict: null,
        cabinDistrictError: "",
      });
    }
  }

  handleProductDiscountPercentChange(e, idx) {
    let productData = this.state.productData;
    if (e.target.value !== "") {
      productData[idx].productDiscountPercent = parseFloat(e.target.value);
    } else {
      productData[idx].productDiscountPercent = "";
    }
    this.setState({
      productData: productData,
    });

    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductDiscountValueChange(e, idx) {
    let productData = this.state.productData;
    if (e.target.value !== "") {
      productData[idx].productDiscountValue = parseFloat(e.target.value);
    } else {
      productData[idx].productDiscountValue = "";
    }
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleUnitChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].unit = option.value;
      productData[idx].unitError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].unit = "";
      productData[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleTevkifatChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].tevkifatId = option.value;
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].tevkifatId = 0;
      productData[idx].tevkifatTotal = 0;
      this.setState({
        productData: productData,
      });
    }
    this.ProductPriceCalculate(idx);
  }

  handleIstisnaChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].istisnaId = option.value;
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].istisnaId = 0;
      this.setState({
        productData: productData,
      });
    }
    //this.ProductPriceCalculate(idx);
  }

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].unitPrice = option.salePrice;
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].productUnitPriceError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productGuid = null;
      productData[idx].piece = 0;
      productData[idx].unitPrice = 0;
      productData[idx].productError = CgnMessage.textErrorMessage;
      productData[idx].productPieceError = CgnMessage.textErrorMessage;
      productData[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    //this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = "";
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  async ProductPriceCalculate(idx) {
    var totalProductPrice = 0.0;
    var totalProductTotal = 0.0;
    var itemTotal = 0.0;
    var percent = 0.0;
    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;
    var taxTotal = 0;
    var tevkifatTotal = 0;
    var tevkifatRate = 0;

    let productData = this.state.productData;

    var discountPercent = parseFloat(productData[idx].productDiscountPercent);

    var discountValue = parseFloat(productData[idx].productDiscountValue);

    itemTotal = (
      parseFloat(productData[idx].piece) *
      parseFloat(productData[idx].unitPrice)
    ).toFixed(2);

    totalProductPrice += parseFloat(itemTotal);

    if (productData[idx].tevkifatId !== undefined) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      this.setState({
        isLoading: true,
      });
      await axios
        .get(URL + "/tevkifats/get/" + productData[idx].tevkifatId, config)
        .then((response) => {
          tevkifatRate = response.data.tevkifatRate;
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }

    if (discountPercent > 0) {
      percent = parseFloat(totalProductPrice) * (discountPercent / 100);
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - parseFloat(percent);
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);
      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );
      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(taxTotal);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        productData[idx].total =
          parseFloat(totalProductTotal) + parseFloat(productData[idx].taxTotal);
      }
    } else if (discountValue > 0) {
      percent = discountValue;
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - discountValue;
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);

      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(taxTotal);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        productData[idx].total =
          parseFloat(totalProductTotal) + parseFloat(productData[idx].taxTotal);
      }
    } else {
      productData[idx].productDiscountTotal = 0;
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);

      taxTotal = parseFloat(
        totalProductPrice * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);

      totalProductTotal = parseFloat(taxTotal) + parseFloat(totalProductPrice);
      productData[idx].total = parseFloat(totalProductTotal).toFixed(2);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        totalProductTotal =
          parseFloat(productData[idx].taxTotal) + parseFloat(totalProductPrice);
        productData[idx].total = parseFloat(totalProductTotal).toFixed(2);
      }
    }

    this.setState({
      productData: productData,
    });
    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      total: parseFloat(discountTotal1).toFixed(2),
    });
  }

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductRemove(idx) {
    let someArray = await this.state.productData;
    await someArray.splice(idx, 1);

    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });

    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;

    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      total: parseFloat(discountTotal1).toFixed(2),
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleCollectionCalendarAdd = async () => {
    let array = this.state.collectionCalendarData;
    await array.push({
      targetCollectionDate: "",
      targetTotal: null,
      targetCollectionMethodType: null,
      targetCurrencyType: null,
      targetCollectionDateError: "",
      targetTotalError: "",
      targetCollectionMethodTypeError: "",
      targetCurrencyTypeError: "",
    });
    array.forEach((item) => {
      if (
        array.length > 0 &&
        (!item.targetCollectionDate ||
          !item.targetTotal ||
          !item.targetCollectionMethodType ||
          !item.targetCurrencyType)
      ) {
        item.targetCollectionDateError = CgnMessage.textErrorMessage;
        item.targetTotalError = CgnMessage.textErrorMessage;
        item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
        item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        this.setState({
          isNull: true,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
    this.setState({ collectionCalendarData: array });
  };

  handleTextBoxChange(e, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    collectionCalendarData[idx].targetTotal = parseFloat(e.target.value);
    this.setState({
      collectionCalendarData: collectionCalendarData,
    });
    this.collectionCalendarRequiredField();
  }

  handleInputValueChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCollectionMethodType = option.value;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCollectionMethodType = null;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  handleTargetCollectionDateChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCollectionDate = option;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCollectionDate = "";
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  handleTargetCurrencyTypeChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCurrencyType = option.value;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCurrencyType = null;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  collectionCalendarRequiredField = () => {
    var isAlert = false;
    var arr = this.state.collectionCalendarData;
    arr.forEach((item) => {
      item.targetCollectionDateError = "";
      item.targetTotalError = "";
      item.targetCollectionDateError = "";
      item.targetCurrencyTypeError = "";
      item.targetCollectionMethodTypeError = "";

      if (!item.targetCollectionDate) {
        item.targetCollectionDateError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetTotal) {
        item.targetTotalError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCollectionMethodType) {
        item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCurrencyType) {
        item.targetCurrencyType = this.state.currencyType;
        // item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        // isAlert = true;
        // this.setState({
        //   isNull: true,
        // });
      }
    });
    if (isAlert) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else {
      this.setState({
        isNull: false,
      });
    }
  };

  handleCollectionCalendarRemove(idx) {
    let someArray = this.state.collectionCalendarData;
    someArray.splice(idx, 1);
    this.setState({ collectionCalendarData: someArray });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      productOutDateError: "",
      customerError: "",
      totalError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
      productDataError: "",
    });
    if (this.state.productOutDate === "") {
      this.setState({
        productOutDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.isHidden && this.state.collectionMethodValue === null) {
      this.setState({
        collectionMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    var control1 = true;
    if (this.state.productData !== null || this.state.productData.length > 0) {
      this.state.productData.forEach((element) => {
        if (element.unit === "" || element.productTaxRate === null) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
          if (element.unit === "") {
            this.setState({
              unitError: CgnMessage.textErrorMessage,
            });
          }
          if (element.productTaxRate === null) {
            this.setState({
              productTaxRateError: CgnMessage.textErrorMessage,
            });
          }
        }
        if (this.state.isHiddenIstisna === false) {
          if (element.istisnaId === undefined || element.istisnaId === 0) {
            control = false;
            this.setState({
              istisnaError: CgnMessage.alertErrorMessage,
            });
          }
        }
      });
    }
    if (this.state.billScenarioId === 3) {
      if (this.state.deliveryType === null) {
        control1 = false;
        this.setState({
          deliveryTypeError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.transportTypeId === null) {
        control1 = false;
        this.setState({
          transportTypeError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinType === null) {
        control1 = false;
        this.setState({
          cabinTypeError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinPiece === 0 || this.state.cabinPiece === null) {
        control1 = false;
        this.setState({
          cabinPieceError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinNo === null) {
        control1 = false;
        this.setState({
          cabinNoError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinAddress === null) {
        control1 = false;
        this.setState({
          cabinAddressError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinDistrict === null) {
        control1 = false;
        this.setState({
          cabinDistrictError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinCity === null) {
        control1 = false;
        this.setState({
          cabinCityError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinCountry === null) {
        control1 = false;
        this.setState({
          cabinCountryError: CgnMessage.alertErrorMessage,
        });
      }
    }
    if (
      !this.state.isHidden &&
      this.state.bankGuid === null &&
      (this.state.collectionMethodValue === 2 ||
        this.state.collectionMethodValue === 3)
    ) {
      this.setState({
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.chequesBondNo === "" &&
      (this.state.collectionMethodValue === 1 ||
        this.state.collectionMethodValue === 5)
    ) {
      this.setState({
        chequesBondNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.productOutDate !== "" &&
      this.state.customerGuid !== "" &&
      this.state.currencyType &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      this.state.isNull === false &&
      (!this.state.isHidden
        ? this.state.collectionMethodValue !== "" &&
          ((this.state.bankGuid !== null &&
            (this.state.collectionMethodValue === 2 ||
              this.state.collectionMethodValue === 3)) ||
            (this.state.chequesBondNo !== "" &&
              (this.state.collectionMethodValue === 1 ||
                this.state.collectionMethodValue === 5)) ||
            (this.state.chequesBondNo === "" &&
              this.state.bankGuid === null &&
              this.state.collectionMethodValue === 4))
        : true) &&
      control === true &&
      control1 === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          productGuid: element.productGuid,
          locationGuid: element.locationGuid,
          piece: element.piece,
          unit: element.unit,
          unitPrice: element.unitPrice,
          productDiscountPercent:
            element.productDiscountPercent !== ""
              ? parseFloat(element.productDiscountPercent)
              : 0,
          productDiscountValue:
            element.productDiscountValue !== ""
              ? parseFloat(element.productDiscountValue)
              : 0,
          productTaxRate: element.productTaxRate,
          total: parseFloat(element.total),
          tevkifatId: element.tevkifatId !== undefined ? element.tevkifatId : 0,
          istisnaId: element.istisnaId !== undefined ? element.istisnaId : 0,
          tevkifatTotal: parseFloat(element.tevkifatTotal),
          deliveryDate:
            element.deliveryDate !== null ? element.deliveryDate : null,
          requestNo: element.requestNo,
          requestItemNo: element.requestItemNo,
          workOrderNo: element.workOrderNo,
          notes: element.notes,
        });
      });
      var myObj = {
        productOutDate: this.state.productOutDate,
        customerGuid: this.state.customerGuid,
        total: parseFloat(this.state.total),
        generalTotal: parseFloat(this.state.generalTotal),
        currencyType: this.state.currencyType,
        generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
        generalTaxTotal: parseFloat(this.state.generalTaxTotal),
        description: this.state.description,
        billTypeId: this.state.billTypeId,
        billScenarioId: this.state.billScenarioId,
        status: true, // this.state.status,
        collectionCalendarDto: this.state.collectionCalendarData,
        productOutProductSecondDto: productData,
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        paymentMethodType: this.state.collectionMethodValue
          ? parseInt(this.state.collectionMethodValue)
          : 0,
        bankGuid: this.state.bankGuid,
        chequesBondNo: this.state.chequesBondNo,
        image: this.state.image,
        isHidden: this.state.isHidden,
        deliveryType: this.state.deliveryType,
        transportType:
          this.state.transportTypeId === null ? 0 : this.state.transportTypeId,
        cabinType: this.state.cabinType,
        cabinPiece: this.state.cabinPiece,
        cabinNo: this.state.cabinNo,
        cabinAddress: this.state.cabinAddress,
        cabinDistrict: this.state.cabinDistrict,
        cabinCity: this.state.cabinCity,
        cabinCountryGuid:
          this.state.cabinCountry === null
            ? "00000000-0000-0000-0000-000000000000"
            : this.state.cabinCountry,
        productOutImageDto: this.state.productOutImageDto,
      };
      var URLParam = "/productOutsSecond/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/productOutsSecond/update";
        myObj = {
          guid: this.state.guid,
          productOutDate: this.state.productOutDate,
          incomeGuid: this.state.incomeGuid,
          customerGuid: this.state.customerGuid,
          total: parseFloat(this.state.total),
          generalTotal: parseFloat(this.state.generalTotal),
          currencyType: this.state.currencyType,
          generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
          generalTaxTotal: parseFloat(this.state.generalTaxTotal),
          billTypeId: this.state.billTypeId,
          billScenarioId: this.state.billScenarioId,
          description: this.state.description,
          status: true, // this.state.status
          collectionCalendarDto: this.state.collectionCalendarData,
          productOutProductSecondDto: productData,
          isHidden: this.state.isHidden,
          deliveryType: this.state.deliveryType,
          transportType:
            this.state.transportTypeId === null
              ? 0
              : this.state.transportTypeId,
          cabinType: this.state.cabinType,
          cabinPiece: parseInt(this.state.cabinPiece),
          cabinNo: this.state.cabinNo,
          cabinAddress: this.state.cabinAddress,
          cabinDistrict: this.state.cabinDistrict,
          cabinCity: this.state.cabinCity,
          cabinCountryGuid:
            this.state.cabinCountry === null
              ? "00000000-0000-0000-0000-000000000000"
              : this.state.cabinCountry,
          productOutImageDto: this.state.productOutImageDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/yeni-satis-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "total" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var total = parseFloat(this.state.total);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = total - total * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = total - discountValue;
    } else {
      generalTotal = total;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }
    this.state.total = parseFloat(total).toFixed(2);
    this.state.generalTotal = parseFloat(generalTotal).toFixed(2);
    this.state.discountTotal = parseFloat(discountTotal).toFixed(2);
  };

  toggle = () => {
    this.setState({
      productOutModal: !this.state.productOutModal,
    });
  };

  uploadedImage2 = async (event) => {
    let array = this.state.productOutImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "application/pdf") {
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
              image: response.data,
              imageName: file.name,
            });
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({ productOutImageDto: array });
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.productOutImageDto;
    this.state.productOutImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ productOutImageDto: array });
        }
      }
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productOutAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="productOutDate"
                      label="Tarih [*]"
                      selected={
                        this.state.productOutDate &&
                        this.state.productOutDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.productOutDate.split("/")[2] +
                                "-" +
                                this.state.productOutDate.split("/")[1] +
                                "-" +
                                this.state.productOutDate.split("/")[0]
                            )
                          : this.state.productOutDate
                      }
                      onChange={(productOutDate) =>
                        this.handleDateChange(productOutDate)
                      }
                      error={this.state.productOutDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="billTypeId"
                      label="Fatura Tipi"
                      placeholder="Fatura Tipi seçiniz..."
                      selectValue={this.state.billTypeId}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleBillTypeSelectChange(option)
                      }
                      options={BillTypes}
                      error={this.state.billTypeError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="billScenarioId"
                      label="Fatura Senaryo"
                      placeholder="Fatura Senaryo seçiniz..."
                      selectValue={this.state.billScenarioId}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleBillScenarioSelectChange(option)
                      }
                      options={BillScenario}
                      error={this.state.billScenarioError}
                    />
                  </Col>
                </Row>

                {this.state.productData.map((productData, idx) => (
                  <>
                    <Row className="mobileBorder pl-0">
                      <Col className="modalSelectCol pl-0">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="locationGuid"
                              label="Depo [*]"
                              placeholder="Lütfen depo seçiniz..."
                              selectValue={productData.locationGuid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleLocationInputValueChange(
                                  option,
                                  idx
                                );
                              }}
                              options={this.state.locationArr}
                              error={productData.locationError}
                            />
                          </div>
                          {this.state.locationModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() => this.addModalLocation(idx)}
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col className="modalSelectCol">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="productGuid"
                              label="Ürün [*]"
                              placeholder="Lütfen ürün seçiniz..."
                              selectValue={productData.productGuid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleProductInputValueChange(option, idx);
                              }}
                              options={this.state.productArr}
                              error={productData.productError}
                            />
                          </div>
                          {this.state.productModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() => this.addModalProduct(idx)}
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col className="pl-0">
                        <CgnTextbox
                          type="number"
                          name="piece"
                          label="Miktar [*]"
                          value={productData.piece}
                          onChange={(e) =>
                            this.handleProductPieceTextBoxChange(e, idx)
                          }
                          placeHolder="Lütfen miktar girin..."
                          maxLength="50"
                          autoComplete="off"
                          error={productData.productPieceError}
                          step="0.1"
                          min={0}
                        />
                      </Col>

                      <Col>
                        <CgnReactSelect
                          name="unit"
                          label="Birim [*]"
                          placeholder="Birim"
                          selectValue={productData.unit}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleUnitChange(option, idx)
                          }
                          options={unitValuesStatuses}
                          error={productData.unitError}
                        />
                      </Col>

                      <Col>
                        <CgnTextbox
                          type="number"
                          name="unitPrice"
                          label="Birim Fiyat [*]"
                          value={productData.unitPrice}
                          onChange={(e) =>
                            this.handleProductUnitPriceTextBoxChange(e, idx)
                          }
                          placeHolder="Lütfen birim fiyat girin..."
                          maxLength="50"
                          autoComplete="off"
                          error={productData.productUnitPriceError}
                        />
                      </Col>
                      <Col>
                        <CgnTextbox
                          type="number"
                          name="productDiscountPercent"
                          label="İskonto(Yüzde)"
                          value={productData.productDiscountPercent}
                          placeHolder="İskonto"
                          maxLength="50"
                          autoComplete="off"
                          readOnly={this.state.isProductDiscountPercent}
                          onChange={(e) =>
                            this.handleProductDiscountPercentChange(e, idx)
                          }
                          error={productData.productDiscountPercentError}
                        />
                      </Col>
                      <Col lg="12 pl-0">
                        <CgnReactSelect
                          name="istisnaId"
                          label="İstisna [*]"
                          placeholder="İstisna seçiniz"
                          selectValue={productData.istisnaId}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleIstisnaChange(option, idx)
                          }
                          options={this.state.istisnaArr}
                          hidden={this.state.isHiddenIstisna}
                          error={productData.istisnaError}
                        />
                      </Col>
                    </Row>
                    <Row className="pl-0">
                      <Col className="pl-0">
                        <CgnTextbox
                          type="number"
                          name="productDiscountValue"
                          label="İskonto(Net Tutar)"
                          value={productData.productDiscountValue}
                          placeHolder="İskonto net tutar"
                          maxLength="50"
                          autoComplete="off"
                          readOnly={this.state.isProductDiscountValue}
                          onChange={(e) =>
                            this.handleProductDiscountValueChange(e, idx)
                          }
                          error={productData.productDiscountValueError}
                        />
                      </Col>
                      <Col>
                        <CgnReactSelect
                          name="productTaxRate"
                          label="K.D.V Oranı [*]"
                          placeholder="K.D.V oranı"
                          selectValue={productData.productTaxRate}
                          isMulti={false}
                          isClearable={true}
                          disabled={this.state.isTrue}
                          onChange={(option) =>
                            this.handleProductTaxRateSelectChange(option, idx)
                          }
                          options={TaxRateChoices}
                          error={productData.productTaxRateError}
                        />
                      </Col>
                      <Col>
                        <CgnReactSelect
                          name="tevkifatId"
                          label="Tevkifat"
                          placeholder="Tevkifat"
                          selectValue={productData.tevkifatId}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTevkifatChange(option, idx)
                          }
                          options={this.state.tevkifatArr}
                        />
                      </Col>
                      <Col>
                        <CgnTextbox
                          type="number"
                          name="tevkifatTotal"
                          label="Tevkifat Fiyat"
                          value={productData.tevkifatTotal}
                          readOnly={true}
                          //onChange={this.ProductPriceCalculate()}
                          maxLength="50"
                          autoComplete="off"
                        />
                      </Col>
                      <Col>
                        <CgnTextbox
                          type="number"
                          name="total"
                          label="Fiyat [*]"
                          value={productData.total}
                          readOnly={true}
                          //onChange={this.ProductPriceCalculate()}
                          maxLength="50"
                          autoComplete="off"
                        />
                      </Col>

                      <Col>
                        <CgnButton
                          className="dynamicRowDeleteButton"
                          type="button"
                          color="danger"
                          onClick={() => this.handleProductRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <CgnDatePicker
                          name="deliveryDate"
                          label="Teslimat Tarihi"
                          selected={
                            productData.deliveryDate &&
                            productData.deliveryDate.toString().indexOf("/") > 0
                              ? new Date(
                                  productData.deliveryDate.split("/")[2] +
                                    "-" +
                                    productData.deliveryDate.split("/")[1] +
                                    "-" +
                                    productData.deliveryDate.split("/")[0]
                                )
                              : productData.deliveryDate
                          }
                          onChange={(deliveryDate) =>
                            this.handleDeliveryDateChange(deliveryDate, idx)
                          }
                          error={productData.deliveryDateError}
                          maxDate={new Date(2100, 1, 1)}
                          minDate={new Date(1900, 1, 1)}
                          placeholder="GG/AA/YYYY"
                          autoComplete="off"
                        />
                      </Col>
                      <Col lg="3">
                        <CgnTextbox
                          type="text"
                          name="requestNo"
                          label="Talep Numarası"
                          value={productData.requestNo}
                          placeHolder="Talep numarası"
                          autoComplete="off"
                          onChange={(event) =>
                            this.handleRequestNoChange(event, idx)
                          }
                          error={productData.requestItemNoError}
                        />
                      </Col>
                      <Col lg="3">
                        <CgnTextbox
                          type="text"
                          name="requestItemNo"
                          label="Talep Kalem Numarası"
                          value={productData.requestItemNo}
                          placeHolder="Talep kalem numarası"
                          autoComplete="off"
                          onChange={(event) =>
                            this.handleRequestItemNoChange(event, idx)
                          }
                          error={productData.requestItemNoError}
                        />
                      </Col>
                      <Col lg="3">
                        <CgnTextbox
                          type="text"
                          name="workOrderNo"
                          label="İş Emri Numarası"
                          value={productData.workOrderNo}
                          placeHolder="İş emri numarası"
                          maxLength="50"
                          autoComplete="off"
                          onChange={(event) =>
                            this.handleWorkOrderNoChange(event, idx)
                          }
                          error={productData.requestItemNoError}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <CgnTextbox
                          type="text"
                          name="notes"
                          label="Notlar ve Ekler"
                          value={productData.notes}
                          placeHolder="Notlar ve ekler"
                          autoComplete="off"
                          onChange={(event) =>
                            this.handleNotesChange(event, idx)
                          }
                          error={productData.requestItemNoError}
                        />
                      </Col>
                    </Row>
                  </>
                ))}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggle}
                    text={"Çoklu Ürün Ekle"}
                  />
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalDiscountTotal"
                      label="İndirim Toplamı"
                      value={this.state.generalDiscountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTaxTotal"
                      label="KDV Toplamı"
                      value={this.state.generalTaxTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Toplam Tutar"
                      value={this.state.total}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <h2 className="mb-2">İhracat Ayarları</h2>
                </Row>
                <Row
                  className="pl-0"
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <Col lg="3" className="pl-0">
                    <CgnReactSelect
                      name="deliveryType"
                      label="Teslim Şekli [*]"
                      placeholder="Teslim şekli seçiniz..."
                      selectValue={this.state.deliveryType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleDeliveryTypeSelectChange(option)
                      }
                      options={DeliveryType}
                      error={this.state.deliveryTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="transportTypeId"
                      label="Taşıma Şekli [*]"
                      placeholder="Taşıma şekli seçiniz..."
                      selectValue={this.state.transportTypeId}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTransportTypeSelectChange(option)
                      }
                      options={TransportType}
                      error={this.state.transportTypeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnReactSelect
                      name="cabinType"
                      label="Kabin Cinsi [*]"
                      placeholder="Kabin cinsi seçiniz..."
                      selectValue={this.state.cabinType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCabinTypeSelectChange(option)
                      }
                      options={CabinType}
                      error={this.state.cabinTypeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="cabinPiece"
                      label="Kabin Adet [*]"
                      value={this.state.cabinPiece}
                      onChange={(e) => this.handleCabinPieceTextBoxChange(e)}
                      placeHolder="Lütfen kabin adet girin..."
                      maxLength="50"
                      autoComplete="off"
                      step="0.1"
                      min={0}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="cabinNo"
                      label="Kabin No [*]"
                      value={this.state.cabinNo}
                      onChange={(e) => this.handleCabinNoTextBoxChange(e)}
                      placeHolder="Lütfen kabin no girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinNoError}
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <Col lg="4" className="pl-0">
                    <CgnTextbox
                      type="text"
                      name="cabinAddress"
                      label="Teslimat Adres [*]"
                      value={this.state.cabinAddress}
                      onChange={(e) =>
                        this.handleDeliveryAddressTextBoxChange(e)
                      }
                      placeHolder="Lütfen teslimat adres girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinAddressError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="cabinDistrict"
                      label="Teslimat İlçe [*]"
                      value={this.state.cabinDistrict}
                      onChange={(e) =>
                        this.handleDeliveryDistrictTextBoxChange(e)
                      }
                      placeHolder="Lütfen teslimat ilçe girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinDistrictError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="cabinCity"
                      label="Teslimat Şehir [*]"
                      value={this.state.cabinCity}
                      onChange={(e) => this.handleDeliveryCityTextBoxChange(e)}
                      placeHolder="Lütfen teslimat şehir girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinCityError}
                    />
                  </Col>

                  <Col lg="2">
                    <CgnReactSelect
                      name="cabinCountry"
                      label="Teslimat Ülke [*]"
                      placeholder="Teslimat ülke seçiniz..."
                      selectValue={this.state.cabinCountry}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCabinCountrySelectChange(option)
                      }
                      options={this.state.countryArr}
                      error={this.state.cabinCountryError}
                    />
                  </Col>
                </Row>

                {this.state.collectionCalendarModalPlus &&
                  this.state.collectionCalendarData.map(
                    (collectionCalendarData, idx) => (
                      <Row className="mobileBorder">
                        <Col lg="3">
                          <CgnDatePicker
                            name="targetCollectionDate"
                            label="Tahsilat Tarihi [*]"
                            selected={
                              collectionCalendarData.targetCollectionDate &&
                              collectionCalendarData.targetCollectionDate
                                .toString()
                                .indexOf("/") > 0
                                ? new Date(
                                    collectionCalendarData.targetCollectionDate.split(
                                      "/"
                                    )[2] +
                                      "-" +
                                      collectionCalendarData.targetCollectionDate.split(
                                        "/"
                                      )[1] +
                                      "-" +
                                      collectionCalendarData.targetCollectionDate.split(
                                        "/"
                                      )[0]
                                  )
                                : collectionCalendarData.targetCollectionDate
                            }
                            onChange={(option) => {
                              this.handleTargetCollectionDateChange(
                                option,
                                idx
                              );
                            }}
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                            error={
                              collectionCalendarData.targetCollectionDateError
                            }
                          />
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="number"
                            name="targetTotal"
                            label="Tahsilat Tutarı [*]"
                            value={collectionCalendarData.targetTotal}
                            onChange={(e) => this.handleTextBoxChange(e, idx)}
                            placeHolder="Lütfen tutar girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={collectionCalendarData.targetTotalError}
                          />
                        </Col>

                        <Col lg="4">
                          <CgnReactSelect
                            name="targetCollectionMethodType"
                            label="Tahsilat Yöntemi [*]"
                            placeholder="Lütfen tahsilat yöntemi seçiniz..."
                            selectValue={
                              collectionCalendarData.targetCollectionMethodType
                            }
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleInputValueChange(option, idx);
                            }}
                            options={CollectionMethodType}
                            error={
                              collectionCalendarData.targetCollectionMethodTypeError
                            }
                          />
                        </Col>

                        <Col lg="1" sm="3">
                          <CgnButton
                            className="dynamicRowDeleteButton"
                            type="button"
                            color="danger"
                            onClick={() =>
                              this.handleCollectionCalendarRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    )
                  )}

                {this.state.collectionCalendarModalPlus && (
                  <Row>
                    <Col lg="4">
                      <CgnButton
                        type="button"
                        color="primary"
                        className="mb-3 dynamicRowAddButton"
                        onClick={this.handleCollectionCalendarAdd}
                        text={"Tahsilat Takvimi Ekle"}
                      />
                    </Col>
                  </Row>
                )}

                {this.state.collectionModalPlus &&
                  this.state.urlSplit.length === 2 && (
                    <>
                      <Row>
                        <Col
                          lg="12"
                          className="font-weight-bold"
                          style={{ padding: "0 15px 10px 15px" }}
                        >
                          <Label
                            check
                            className="labelCheck"
                            style={{ fontSize: "13px" }}
                          >
                            <Input
                              type="checkbox"
                              className="mt-1"
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              checked={!this.state.isHidden}
                            />{" "}
                            Tahsilat Yapıldı mı ?
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <CgnTextbox
                            type="text"
                            name="receiptNo"
                            label="Makbuz No"
                            value={this.state.receiptNo}
                            placeHolder="Lütfen makbuz no girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.receiptNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                        <Col lg="6">
                          <CgnTextbox
                            type="number"
                            name="expiry"
                            label="Vade"
                            value={this.state.expiry}
                            placeHolder="Lütfen vade sayısı girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.expiryError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <CgnReactSelect
                            name="collectionMethodValue"
                            label="Tahsilat Yöntemi [*]"
                            placeholder="Lütfen tahsilat yöntemi seçiniz..."
                            selectValue={this.state.collectionMethodValue}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleCollectionMethodSelectChange(option)
                            }
                            options={PaymentMethodType}
                            error={this.state.collectionMethodTypeError}
                            hidden={this.state.isHidden}
                          />
                        </Col>

                        <Col
                          lg="4"
                          className="modalSelectCol"
                          style={
                            this.state.isHidden
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <div className="flex-container">
                            <div className="p-0 flexMaxInput">
                              <CgnReactSelect
                                name="bankGuid"
                                label="Banka"
                                placeholder="Banka seçiniz..."
                                selectValue={this.state.bankGuid}
                                isMulti={false}
                                isClearable={true}
                                disabled={this.state.isBankTrue}
                                onChange={(option) =>
                                  this.handleBankSelectChange(option)
                                }
                                options={this.state.bankArr}
                                error={this.state.bankError}
                                hidden={this.state.isHidden}
                              />
                            </div>
                            {this.state.bankModalPlus && (
                              <div className="p-0 flexAddMinButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalBank()}
                                  text={"+"}
                                  visibled={this.state.isHidden}
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="text"
                            name="chequesBondNo"
                            label="Çek/Senet No"
                            value={this.state.chequesBondNo}
                            placeHolder="Lütfen çek/senet no girin..."
                            autoComplete="off"
                            readOnly={this.state.isChequesBondNoTrue}
                            onChange={this.handleChange}
                            error={this.state.chequesBondNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <ImageUpload
                            accept="image/png, image/jpeg"
                            className="text-center"
                            style={fotoStyle}
                            label="Makbuz Fotoğrafı"
                            image={this.state.image}
                            noFoto={noImage}
                            alt={this.state.name}
                            onChange={this.uploadedImage}
                            onDelete={this.deleteImage}
                            error={this.state.imageError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <ImageUploadMulti
                      images={this.state.productOutImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Dosya"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.imageError2}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />

                <Row>
                  <Modal
                    isOpen={this.state.productOutModal}
                    toggle={this.toggle}
                  >
                    <ModalHeader
                      toggle={this.toggle}
                      className="font-weight-bold"
                    >
                      Çoklu Ürün Ekle
                    </ModalHeader>
                    <ModalBody className="modalBody">
                      <Input
                        type="textarea"
                        className="modalTextArea"
                        onChange={(e) => this.handleChangeMultipleProduct(e)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <CgnButton
                        type="button"
                        color="primary"
                        onClick={() => this.handleClickMultipleProduct()}
                        text={CgnMessage.addButton}
                        className="modalButton"
                      />
                      <CgnButton
                        type="button"
                        color="secondary"
                        onClick={this.toggle}
                        text="Kapat"
                        className="modalButton"
                      />
                    </ModalFooter>
                  </Modal>
                </Row>
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalBank}
          toggle={this.addModalBank}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Banka Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <BankAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBank}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductOutSecondAddUpdate;
