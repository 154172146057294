import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const CgnDatatable = ({ title, columns, data, loading, filter }) => {
  // useEffect(() => {
  //   const elements = document.querySelectorAll('[name*="filterDataTable"]'); // id'sinde "filterDataTable" geçen tüm öğeleri seç
  //   elements.forEach(element => {
  //     element.addEventListener('keyup', (e) => { // Her öğe için bir 'keyup' olay dinleyicisi ekle
  //       const value = e.target.value;
  //       const turkishToLowerMap = {
  //         'I': 'ı', 'İ': 'i', 'Ş': 'ş', 'Ğ': 'ğ', 'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  //       };

  //       const newValue = value.replace(/[IİŞĞÜÖÇ]/g, function (match) {
  //         return turkishToLowerMap[match];
  //       }).toLowerCase(); // Özel Türkçe karakterleri dönüştür ve küçük harfe çevir

  //       e.target.value = newValue; // Dönüştürülmüş değeri giriş öğesine atar
  //     });
  //   });
  // }, []);

  return (
    <>
      {title && columns && data && (
        <div className="data-table">
          <DataTableExtensions
            columns={columns}
            data={data}
            filterPlaceholder="Lütfen aramak istediğiniz kelimeyi girin..."
            export={false}
            print={false}
            filter={filter}
          >
            <DataTable
              title={title}
              defaultSortField="id"
              defaultSortAsc={false}
              persistTableHead={true}
              pagination={true}
              highlightOnHover={true}
              pointerOnHover={true}
              responsive={true}
              paginationPerPage={50}
              paginationRowsPerPageOptions={[50, 100, 250, 500]}
              noHeader={true}
              progressPending={loading}
              noDataComponent={
                <p className="p-3 m-0">Görüntülenecek kayıt yok!</p>
              }
              paginationComponentOptions={{
                rowsPerPageText: "Sayfa başına satır:",
                rangeSeparatorText: "/",
                selectAllRowsItemText: "Tümü",
              }}
            />
          </DataTableExtensions>
        </div>
      )}
    </>
  );
};

export default CgnDatatable;
