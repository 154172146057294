import * as actionTypes from "../actionTypes";
import { URL } from "../constants";
import { handleError, handleResponse } from "../handles";
import axios from "axios";

export function loginSuccess(success) {
  return { type: actionTypes.LOGIN_SUCCESS, payload: success };
}

export function loginError(error) {
  return { type: actionTypes.LOGIN_ERROR, payload: error };
}

export function login(login) {
  return async function (dispatch) {
    var config = {
      method: "POST",
      url: URL + "/auth/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: login,
    };

    axios(config)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("expiration", response.data.expiration);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("guid", response.data.id);
        if (response.status === 200) {
          localStorage.setItem("userName", login.userName);
        }

        handleResponse(loginSuccess, dispatch, response);
        handleError(loginError, dispatch, {});
      })
      .catch((error) => {
        handleResponse(loginSuccess, dispatch, {});
        handleError(loginError, dispatch, error.response);
      });
  };
}
