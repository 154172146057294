import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  CategoryList,
  fotoStyle,
  
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import CgnButton from "../toolbox/CgnButton";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import CgnTextbox from "../toolbox/CgnTextbox";
class MachinePieceProductLocationList extends Component {
  state = {
    pageTitle: "Depomdaki Ürünler",
    breadcrumb: [{ text: "Depom", link: "#" }],
    isLoading: false,
    productData: [],
    alertErrorMessage: "",
    image: "",
    name: "",
    isOpen: false,
    isOpenModalRemoveToLocation: false,
    description: "",
    descriptionError: "",
    alertErrorModalMessage: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var self = this;

    await CategoryList().then(function (data) {
      self.setState({
        categoryArr: data,
      });
    });

    if (localStorage.getItem("categoryGuidMachineProduct")) {
      this.setState({
        categoryGuid: localStorage.getItem("categoryGuidMachineProduct"),
      });
    }

    this.getList(null);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    if (this.state.unit === "") {
      this.state.unit = 0;
    }
    if (this.state.productStatus === "") {
      this.state.productStatus = 0;
    }
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var locationGuid = "";
    if (localStorage.getItem("locationGuid")) {
      locationGuid = localStorage.getItem("locationGuid");
    }
    URLParam = "/machineStocks/getlistallstocklocation/" + locationGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          productData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  handleProductPieceRemoveTextBoxChange(row, e) {
    let productData = this.state.productData;
    productData.forEach((item) => {
      if (item.guid === row.guid) {
        item.pieceRemove = parseFloat(e.target.value);
      }
    });
    this.setState({
      productData: productData,
    });
  }

  handleRemoveToLocation = async (row) => {
    if (row && !this.state.isOpenModalRemoveToLocation) {
      if (
        row.guid &&
        row.pieceRemove &&
        row.pieceRemove !== 0
      ) {
        this.setState({
          guidModal: row.guid,
          pieceModal: row.pieceRemove,
          isOpenModalRemoveToLocation: true,
        });
      }
    }
  };

  addRemoveToLocation = () => {
    this.setState({
      isOpenModalRemoveToLocation: !this.state.isOpenModalRemoveToLocation,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      descriptionError: "",
      alertErrorModalMessage: "",
    });
  };

  handleSaveRemoveToLocation = async () => {
    this.setState({
      alertErrorModalMessage: "",
    });

    if (this.state.description === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorModalMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.description !== "") {
      this.setState({
        isOpenModalRemoveToLocation: false,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        locationGuid: localStorage.getItem("locationGuid"),
        machineProductGuid: this.state.guidModal,
        piece: this.state.pieceModal,
        description: this.state.description,
        status: true,
      };
      axios
        .post(URL + "/machinestockremove/add", myObj, config)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorModalMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  thStyle = {
    width: "100px",
  };

  thStyle2 = {
    width: "90px",
  };

  tdStyle = {
    width: "200px",
  };
  
  textAreaStyle = {
    padding: "5px 10px",
    height: "100px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.productData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    selector: "image",
                    sortable: false,
                    width: "80px",
                    cell: (cell) => (
                      <div>
                        {cell.productImage ? (
                          <Button
                            onClick={() =>
                              this.toggle(
                                cell.productImage,
                                cell.machineProductName
                              )
                            }
                            className="modalButtonList"
                          >
                            <img
                              alt={cell.machineProductName}
                              src={`/Images/${cell.productImage}`}
                              className="rdt_image rounded"
                            />
                          </Button>
                        ) : (
                          <img
                            alt={cell.name}
                            src={noImg}
                            className="rdt_image rdt_no_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Ürün Adet Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Depo</th>
                              <td colSpan={3}>{row.locationName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ürün Kodu</th>
                              <td style={this.tdStyle}>
                                {row.machineProductCode}
                              </td>
                              <th style={this.thStyle2}>Ürün Adı</th>
                              <td>{row.machineProductName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Adet</th>
                              <td style={this.tdStyle}>{row.piece}</td>
                              <th style={this.thStyle2}>Kritik Adet</th>
                              <td>{row.criticalPiece}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Sağlam Adet</th>
                              <td style={this.tdStyle}>{row.pieceDurable}</td>
                              <th style={this.thStyle2}>Arızalı Adet</th>
                              <td>{row.pieceDefective}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Raf</th>
                              <td colSpan={3}>
                                {row.machineProductShelfRowDto !== null &&
                                  row.machineProductShelfRowDto.length > 0 &&
                                  row.machineProductShelfRowDto.map(function (
                                    item
                                  ) {
                                    if (item.locationGuid === localStorage.getItem("locationGuid")) {
                                      return item.shelf
                                        ? item.locationName +
                                            " " +
                                            item.shelf + "-" + item.row +
                                            " / "
                                        : "-";
                                    } else {
                                      return "";
                                    }
                                  })}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  // {
                  //   width: "75px",
                  //   ignoreRowClick: true,
                  //   allowOverflow: true,
                  //   button: true,
                  //   cell: (row, idx) => (
                  //     <CgnTextbox
                  //       type="number"
                  //       className="dataTableInput"
                  //       name="pieceRemove"
                  //       value={row.pieceRemove}
                  //       placeHolder="Adet"
                  //       maxLength="100"
                  //       autoComplete="off"
                  //       onChange={(e) =>
                  //         this.handleProductPieceRemoveTextBoxChange(row, e)
                  //       }
                  //       error={row.pieceRemoveError}
                  //     />
                  //   ),
                  // },
                  // {
                  //   width: "175px",
                  //   ignoreRowClick: true,
                  //   allowOverflow: true,
                  //   button: true,
                  //   cell: (row, idx) => (
                  //     <CgnButton
                  //       text={"Depomdan Çıkar"}
                  //       type="button"
                  //       className="w-100 ml-0"
                  //       color="danger"
                  //       onClick={() => this.handleRemoveToLocation(row)}
                  //     />
                  //   ),
                  // },
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalRemoveToLocation}
          toggle={this.addRemoveToLocation}
          className="modal-xs content"
        >
          <ModalBody className="modalBody">
            <CgnTextbox
              inlineStyle={this.textAreaStyle}
              type="textarea"
              name="description"
              label="Depodan Çıkarma Sebebi"
              value={this.state.description}
              placeHolder="Lütfen depodan çıkarma sebebinizi girin..."
              autoComplete="off"
              onChange={this.handleChange}
              error={this.state.descriptionError}
            />
            {this.state.alertErrorModalMessage && (
              <CgnAlert
                color="danger"
                title={CgnMessage.alertErrorTitle}
                text={this.state.alertErrorModalMessage}
              />
            )}
            <CgnButton
              type="button"
              color="primary"
              onClick={this.handleSaveRemoveToLocation}
              text="Kaydet"
              className="modalButton"
            />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addRemoveToLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default MachinePieceProductLocationList;
