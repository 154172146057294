import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import {
  
  URL,
  ReplyType,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
} from "../../redux/actions/constants";
import axios from "axios";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButton from "../toolbox/CgnButton";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import { confirmAlert } from "react-confirm-alert";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class ListOutgoingInvoice extends Component {
  state = {
    pageTitle: "Giden Fatura Listele",
    breadcrumb: [{ text: "Fatura", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    data: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "button",
        color: "primary",
        text: CgnMessage.saveButton,
        onClick: (event) => this.handleSave(event),
        disabled: this.isLoading,
      },
    ],
    alertModalErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateOutgoingInvoice")) {
      firstDate = localStorage.getItem("firstDateOutgoingInvoice");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateOutgoingInvoice")) {
      lastDate = localStorage.getItem("lastDateOutgoingInvoice");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateOutgoingInvoice", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateOutgoingInvoice", this.state.lastDate);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(
        URL +
          "/bills/getlistoutgoing" +
          "/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate,
        config
      )
      .then((response) => {
        this.setState({
          data: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  cancel = (uuid) => {
    confirmAlert({
      title: CgnMessage.cancelQuestionTitle,
      message: CgnMessage.cancelQuestionMessage,
      buttons: [
        {
          label: CgnMessage.cancelQuestionYes,
          onClick: () => this.cancelItem(uuid),
        },
        {
          label: CgnMessage.cancelQuestionNo,
        },
      ],
    });
  };

  cancelItem = async (uuid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var myObj = {
      uuid: uuid,
      cancelCogen: 1,
    };
    axios
      .post(URL + "/bills/cancel", myObj, config)
      .then(() => {
        this.setState({
          isLoading: false,
          alertErrorMessage: "",
        });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          alertErrorMessage: error.response.data,
        });
      });
  };

  thStyle = {
    width: "125px",
  };

  th2Style = {
    width: "90px",
  };

  tdStyle = {
    width: "300px",
  };

  td2Style = {
    width: "400px",
  };

  badgeStyle = {
    width: "100%",
  };

  textAreaStyle = {
    padding: "5px 10px",
    height: "100px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row className="costList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-0 pr-2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.data}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Giden Fatura Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tr>
                            <th style={this.thStyle}>Tarih</th>
                            <td style={this.tdStyle}>{row.dateTime}</td>
                            <th style={this.thStyle}>Firma Adı</th>
                            <td>{row.companyName}</td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>Fatura Numarası</th>
                            <td style={this.tdStyle}>
                              {row.billNumber !== "" ? row.billNumber : "-"}
                            </td>
                            <th style={this.thStyle}>Vergi Dairesi</th>
                            <td>
                              {row.companyTaxAdministration
                                ? row.companyTaxAdministration
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>UUID</th>
                            <td style={this.tdStyle}>
                              {row.uuid !==
                              "00000000-0000-0000-0000-000000000000"
                                ? row.uuid
                                : "-"}
                            </td>
                            <th style={this.thStyle}>Vergi Numarası</th>
                            <td>{row.companyTaxNumber}</td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>Genel Tutar</th>
                            <td colSpan={3}>
                              {row.generalPrice.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              })}{" "}
                              TL
                            </td>
                          </tr>
                        </Table>
                      );
                    },
                  },
                  {
                    name: "",
                    width: "125px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (
                        row.productOutGuid !==
                          "00000000-0000-0000-0000-000000000000" &&
                        row.productEntriesGuid ===
                          "00000000-0000-0000-0000-000000000000"
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="success"
                            text="Satış Faturası"
                          />
                        );
                      } else if (
                        row.productOutGuid ===
                          "00000000-0000-0000-0000-000000000000" &&
                        row.productEntriesGuid !==
                          "00000000-0000-0000-0000-000000000000"
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="warning"
                            text="İade Faturası"
                          />
                        );
                      }
                    },
                  },
                  {
                    name: "",
                    width: "125px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.billType === "TICARIFATURA") {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="primary"
                            text="Ticari Fatura"
                          />
                        );
                      } else if (row.billType === "TEMELFATURA") {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="info"
                            text="Temel Fatura"
                          />
                        );
                      }
                    },
                  },
                  {
                    name: "",
                    width: "175px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (
                        row.transferStatus === 0 &&
                        row.transferStateCode === 0
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="Bekliyor (1)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 1
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="Bilinmiyor (1)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 2
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="İşlenmeyi Bekliyor (1)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 3
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="İşleniyor (1)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 4
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="danger"
                            text="Hatalı (1)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 0
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="success"
                            text="Başarılı (1)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 1
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="Taslak Veri (2)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 2
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="Bekliyor (2)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 3
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="İşlem Yapılıyor (2)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 4
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="danger"
                            text="Hatalı (2)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 0
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="success"
                            text="Başarılı (2)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 1
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="Taslak Veri (3)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 2
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="Bekliyor (3)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 3
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="İşlem Yapılıyor (3)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 4
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="danger"
                            text="Hatalı (3)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 5 &&
                        row.answerTypeCode === 0
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="success"
                            text="Başarılı (3)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 5 &&
                        row.answerTypeCode === 1
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="secondary"
                            text="Bilinmiyor (4)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 5 &&
                        row.answerTypeCode === 2
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="warning"
                            text="İade Edildi (4)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 5 &&
                        row.answerTypeCode === 3
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="danger"
                            text="Reddedildi (4)"
                          />
                        );
                      } else if (
                        row.transferStatus === 1 &&
                        row.transferStateCode === 5 &&
                        row.invoiceStateCode === 5 &&
                        row.answerStateCode === 5 &&
                        row.answerTypeCode === 4
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="success"
                            text="Kabul Edildi (4)"
                          />
                        );
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) =>
                      row.uuid !== "00000000-0000-0000-0000-000000000000" && (
                        <CgnButtonDropdown
                          label={"Yönet"}
                          className="mr-0"
                          color="primary"
                          items={[
                            {
                              text: "Detay",
                              className: "dropDownBtn",
                              href:
                                row.uuid !==
                                "00000000-0000-0000-0000-000000000000"
                                  ? `/giden-fatura-detay/${row.uuid}`
                                  : "#",
                            },
                            {
                              text:
                                row.pdfFileName !== null &&
                                row.pdfFileName !== ""
                                  ? "Görüntüle"
                                  : "",
                              className: "dropDownBtn",
                              onClick: () =>
                                window.open(
                                  `/EFatura/${row.pdfFileName}`,
                                  "_blank"
                                ),
                            },
                            {
                              text:
                                row.type === 3 &&
                                row.billType === "TICARIFATURA" &&
                                row.cancel === 0 &&
                                row.cancelCogen === 0
                                  ? "İptal Et"
                                  : "",
                              className: "dropDownBtn",
                              onClick: () => this.cancel(row.uuid),
                            },
                          ]}
                        />
                      ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalReply}
          toggle={this.addModalReply}
          className="modal-xs content"
        >
          <ModalHeader className="font-weight-bold">Cevap Ver</ModalHeader>
          <ModalBody className="modalBody">
            <Row>
              <Col>
                <CgnReactSelect
                  name="replyType"
                  label="Cevap Tipi [*]"
                  placeholder="Lütfen cevap tipi seçiniz..."
                  selectValue={this.state.replyType}
                  isMulti={false}
                  isClearable={true}
                  onChange={(option) =>
                    this.handleReplyTypeSelectChange(option)
                  }
                  options={ReplyType}
                  error={this.state.replyTypeError}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CgnTextbox
                  inlineStyle={this.textAreaStyle}
                  type="textarea"
                  name="replyDescription"
                  label="Açıklama"
                  value={this.state.replyDescription}
                  placeHolder="Lütfen açıklama girin..."
                  autoComplete="off"
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.alertModalErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertModalErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalReply}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ListOutgoingInvoice;
