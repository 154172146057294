import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  WageStatus,
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";
import MachineAddUpdate from "../machine/MachineAddUpdate";
import FaultStatusAddUpdate from "../faultStatus/FaultStatusAddUpdate";
import ComplaintReasonAddUpdate from "../complaintReason/ComplaintReasonAddUpdate";

class AssignmentAddUpdate extends Component {
  state = {
    pageTitle: "İş Atama Ekle",
    pageTitleAdd: "İş Atama Ekle",
    pageTitleUpdate: "İş Atama Güncelle",
    pageAddLink: "is-atama-ekle",
    pageListLink: "is-atama-listele",
    breadcrumb: [{ text: "İş Atama", link: "#" }],
    isLoading: false,
    machineArr: [],
    faultStatusArr: [],
    complaintReasonArr: [],
    urlSplit: [],
    guid: "",
    machine: "",
    machineSerialNo: "",
    brandName: "",
    modelName: "",
    wageStatus: 2,
    faultStatus: "",
    complaintReason: "",
    complaintDescription: "",
    isNull: false,
    isOpenModalMachine: false,
    isOpenModalFaultStatus: false,
    isOpenModalComplaintReason: false,
    modalButtonIdx: 0,
    status: true,
    nameError: "",
    machineGuid: null,
    faultStatusGuid: null,
    complaintReasonGuid: null,
    machineNameError: "",
    faultStatusNameError: "",
    complaintReasonNameError: "",
    complaintReasonDescriptionError: "",
    wageStatusNameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    machineModalPlus: false,
    faultStatusModalPlus: false,
    complaintReasonModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      machineModalPlus: await UserOperationClaimControl(1071),
      faultStatusModalPlus: await UserOperationClaimControl(1064),
      complaintReasonModalPlus: await UserOperationClaimControl(1063),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/machines/getlistcustomermachines/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.machineName,
            machineGuid: item.machineGuid,
          };
        });
        this.setState({
          machineArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/faultStatus/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            faultStatusGuid: item.faultStatusGuid,
          };
        });
        this.setState({
          faultStatusArr: myArr,
        });

      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/complaintReason/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.description,
            complaintReasonGuid: item.complaintReasonGuid,
          };
        });
        this.setState({
          complaintReasonArr: myArr,
        });

      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
     await axios
        .get(URL + "/assignment/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              machineSerialNo: response.data.machineSerialNo,
              machineGuid: response.data.machineGuid,
              faultStatusGuid: response.data.faultstatusGuid,
              complaintReasonGuid: response.data.complaintReasonGuid,
              brandName: response.data.brandName,
              modelName: response.data.modelName,
              wageStatus: response.data.wageStatus,
              complaintDescription: response.data.complaintDescription,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        taxNo: "",
        taxAdministration: "",
        address: "",
        eMail: "",
        phone: "",
        machineGuid: null,
        faultStatusGuid: null,
        complaintReasonGuid: null,
        complaintDescription: "",
        wageStatus: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalMachine &&
      this.props.location.modalAddedMachine !== undefined &&
      this.props.location.modalAddedMachine.isModal !== undefined &&
      this.props.location.modalAddedMachine.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/machines/getlistcustomermachines/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.machineName,
            };
          });
          this.setState({
            machineArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMachine.data &&
              element.machineSerialNo ===
                this.props.location.modalAddedMachine.data
            ) {
              this.setState({
                machineGuid: element.guid,
                machineNameError: "",
                alertErrorMessage: "",
              });
            }

          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMachine.isModal = true;
      this.setState({
        isOpenModalMachine: false,
      });
    }
    if (
      this.state.isOpenModalFaultStatus &&
      this.props.location.modalAddedFaultStatus !== undefined &&
      this.props.location.modalAddedFaultStatus.isModal !== undefined &&
      this.props.location.modalAddedFaultStatus.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/faultStatus/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            faultStatusArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedFaultStatus.data &&
              element.name === this.props.location.modalAddedFaultStatus.data
            ) {
              this.setState({
                faultStatusGuid: element.guid,
                faultStatusNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedFaultStatus.isModal = true;
      this.setState({
        isOpenModalFaultStatus: false,
      });
    }
    if (
      this.state.isOpenModalComplaintReason &&
      this.props.location.modalAddedComplaintReason !== undefined &&
      this.props.location.modalAddedComplaintReason.isModal !== undefined &&
      this.props.location.modalAddedComplaintReason.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/complaintReason/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.description,
            };
          });
          this.setState({
            complaintReasonArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedComplaintReason.data &&
              element.description ===
                this.props.location.modalAddedComplaintReason.data
            ) {
              this.setState({
                complaintReasonGuid: element.guid,
                complaintReasonNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedComplaintReason.isModal = true;
      this.setState({
        isOpenModalComplaintReason: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      machineGuid: null,
      faultStatusGuid: null,
      complaintReasonGuid: null,
      complaintDescription: "",
      wageStatus: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleMachineSelectChange(option) {
    if (option) {
      this.setState({
        machineGuid: option.value,
        alertErrorMessage: "",
        machineNameError: "",
      });
    } else {
      this.setState({
        machineGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        machineNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  addModalMachine = () => {
    this.setState({
      isOpenModalMachine: !this.state.isOpenModalMachine,
    });
  };

  addModalFaultStatus = () => {
    this.setState({
      isOpenModalFaultStatus: !this.state.isOpenModalFaultStatus,
    });
  };

  addModalComplaintReason = () => {
    this.setState({
      isOpenModalComplaintReason: !this.state.isOpenModalComplaintReason,
    });
  };

  handleFaultStatusSelectChange(option) {
    if (option) {
      this.setState({
        faultStatusGuid: option.value,
        alertErrorMessage: "",
        faultStatusNameError: "",
      });
    } else {
      this.setState({
        faultStatusGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        faultStatusNameNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleComplaintReasonSelectChange(option) {
    if (option) {
      this.setState({
        complaintReasonGuid: option.value,
        alertErrorMessage: "",
        complaintReasonNameError: "",
      });
    } else {
      this.setState({
        complaintReasonGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        complaintReasonNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleWageStatusChange(option) {
    if (option) {
      this.setState({
        wageStatus: option.value,
        wageStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        wageStatus: "",
        wageStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      machineNameError: "",
      faultStatusNameError: "",
      complaintReasonNameError: "",
      wageStatusError: "",
    });
    if (this.state.machineGuid === null) {
      this.setState({
        machineNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.faultStatusGuid === null) {
      this.setState({
        faultStatusNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.complaintReasonGuid === null) {
      this.setState({
        complaintReasonNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.wageStatus === "") {
      this.setState({
        wageStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.machineGuid !== null &&
      this.state.faultStatusGuid !== null &&
      this.state.complaintReasonGuid !== null &&
      this.state.wageStatus !== null
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        machineGuid: this.state.machineGuid,
        faultStatusGuid: this.state.faultStatusGuid,
        complaintReasonGuid: this.state.complaintReasonGuid,
        complaintDescription: this.state.complaintDescription,
        wageStatus: this.state.wageStatus,
        status: this.state.status,
      };
      var URLParam = "/assignment/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/assignment/update";
        myObj = {
          guid: this.state.guid,
          machineGuid: this.state.machineGuid,
          faultStatusGuid: this.state.faultStatusGuid,
          complaintReasonGuid: this.state.complaintReasonGuid,
          complaintDescription: this.state.complaintDescription,
          wageStatus: this.state.wageStatus,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/is-atama-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "is-atama-ekle" ||
          this.state.urlSplit[1] === "is-atama-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="6">
                        <CgnReactSelect
                          name="machineGuid"
                          label="Makine [*]"
                          placeholder="Makine seçiniz..."
                          selectValue={this.state.machineGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleMachineSelectChange(option)
                          }
                          options={this.state.machineArr}
                          error={this.state.machineNameError}
                        />
                  </Col>

                  <Col lg="6">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="faultStatusGuid"
                          label="Arıza Durumu [*]"
                          placeholder="Arıza Durumu seçiniz..."
                          selectValue={this.state.faultStatusGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleFaultStatusSelectChange(option)
                          }
                          options={this.state.faultStatusArr}
                          error={this.state.faultStatusNameError}
                        />
                      </div>
                      {this.state.faultStatusModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalFaultStatus()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col lg="6">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="complaintReasonGuid"
                          label="Şikayet Sebebi [*]"
                          placeholder="Şikayet Sebebi seçiniz..."
                          selectValue={this.state.complaintReasonGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleComplaintReasonSelectChange(option)
                          }
                          options={this.state.complaintReasonArr}
                          error={this.state.complaintReasonNameError}
                        />
                      </div>
                      {this.state.complaintReasonModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalComplaintReason()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="wageStatus"
                      label="Ücretli/Ücretsiz [*]"
                      placeholder="Ücret Durumu seçiniz..."
                      selectValue={this.state.wageStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleWageStatusChange(option)}
                      options={WageStatus}
                      error={this.state.wageStatusError}
                    />
                  </Col>
                  </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="complaintDescription"
                      label="Şikayet Sebebi Açıklama"
                      value={this.state.complaintDescription}
                      placeHolder="Lütfen şikayet sebebi açıklaması girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" style={{display:"none"}}>
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "is-atama-ekle" ||
              this.state.urlSplit[1] === "is-atama-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalMachine}
          toggle={this.addModalMachine}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Makine Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MachineAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalMachine}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalFaultStatus}
          toggle={this.addModalFaultStatus}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Arıza Durumu Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <FaultStatusAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalFaultStatus}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalComplaintReason}
          toggle={this.addModalComplaintReason}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Şikayet Sebebi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ComplaintReasonAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalComplaintReason}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(AssignmentAddUpdate);
