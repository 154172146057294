import React, { Component } from "react";
import { Row, Col, Card, Form,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses, UserOperationClaimControl} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import BrandAddUpdate from "../brand/BrandAddUpdate";
import CgnButton from "../toolbox/CgnButton";

class ModelAddUpdate extends Component {
  state = {
    pageTitle: "Model Ekle",
    pageTitleAdd: "Model Ekle",
    pageTitleUpdate: "Model Güncelle",
    pageAddLink: "model-ekle",
    pageListLink: "model-listele",
    breadcrumb: [{ text: "Model", link: "#" }],
    isLoading: false,
    brandArr: [],
    urlSplit: [],
    name: "",
    guid: "",
    status: true,
    nameError: "",
    brandGuid: null,
    modelNameError: "",
    alertErrorMessage: "",
    isOpenModalBrand: false,
    modalButtonIdx: 0,
    isNull: false,

    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    brandModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      brandModalPlus: await UserOperationClaimControl(1061),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/brand/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            brandGuid: item.brandGuid,
          };
        });
        this.setState({
          brandArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/model/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              name: response.data.name,
              brandGuid: response.data.brandGuid,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        taxNo: "",
        taxAdministration: "",
        address: "",
        eMail: "",
        phone: "",
        brandGuid: null,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalBrand &&
      this.props.location.modalAddedBrand !== undefined &&
      this.props.location.modalAddedBrand.isModal !== undefined &&
      this.props.location.modalAddedBrand.isModal === false
    ) {
      
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/brand/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              brandGuid: item.brandGuid,
            };
          });
          this.setState({
            brandArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBrand.data &&
              element.name === this.props.location.modalAddedBrand.data
            ) {
              this.setState({
                brandGuid: element.guid,
                brandNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBrand.isModal = true;
      this.setState({
        isOpenModalBrand: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalBrand = () => {
    this.setState({
      isOpenModalBrand: !this.state.isOpenModalBrand,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      brandGuid: null,
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleBrandSelectChange(option) {
    if (option) {
      this.setState({
        brandGuid: option.value,
        alertErrorMessage: "",
        brandNameError: "",
      });
    } else {
      this.setState({
        brandGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        brandNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
      brandNameError: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } if (this.state.brandGuid === null) {
      this.setState({
        brandNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } 
    if (this.state.brandGuid === null && this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        brandNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.name !== "" && this.state.brandGuid !== null) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        name: this.state.name,
        status: this.state.status,
        brandGuid: this.state.brandGuid,
      };
      var URLParam = "/model/add";
      if (this.state.guid && this.state.urlSplit.length !== 2 && this.state.urlSplit[1] === "model-guncelle") {
        URLParam = "/model/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          status: this.state.status,
          brandGuid: this.state.brandGuid,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid && this.state.urlSplit[1] === "model-guncelle") {
              window.location.href = "/model-listele";
            }
            if (this.state.urlSplit[1] === "model-ekle") {
              window.location.reload();
            }
            var modalAddedModel = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedModel });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "model-ekle" ||
          this.state.urlSplit[1] === "model-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4">
                  <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="brandGuid"
                          label="Marka [*]"
                          placeholder="Marka seçiniz..."
                          selectValue={this.state.brandGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleBrandSelectChange(option)
                          }
                          options={this.state.brandArr}
                          error={this.state.brandNameError}
                        />
                      </div>
                      {this.state.brandModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalBrand()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Model [*]"
                      value={this.state.name}
                      placeHolder="Lütfen model girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "model-ekle" ||
              this.state.urlSplit[1] === "model-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalBrand}
          toggle={this.addModalBrand}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Marka Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <BrandAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBrand}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(ModelAddUpdate);
