import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";

class FixedTpuAddUpdate extends Component {
  state = {
    pageTitle: "TPU Sabitleri Ekle",
    pageTitleAdd: "TPU Sabitleri Ekle",
    pageTitleUpdate: "TPU Sabitleri Güncelle",
    pageAddLink: "tpu-sabitleri-ekle",
    pageListLink: "tpu-sabitleri-listele",
    breadcrumb: [{ text: "TPU Sabitleri", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    guid: "",
    lama810Rate: 0,
    profitRate: 0,
    transportRate: 0,
    raw1Rate: 0,
    raw2Rate: 0,
    raw3Rate: 0,
    raw4Rate: 0,
    raw5Rate: 0,
    raw6Rate: 0,
    raw7Rate: 0,
    raw8Rate: 0,
    bigCostRate: 0,
    bigProductionRate: 0,
    smallCostRate: 0,
    smallProductionRate: 0,
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/fixedTpus/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              lama810Rate: parseFloat(response.data.lama810Rate),
              profitRate: parseFloat(response.data.profitRate),
              transportRate: parseFloat(response.data.transportRate),
              raw1Rate: parseFloat(response.data.raw1Rate),
              raw2Rate: parseFloat(response.data.raw2Rate),
              raw3Rate: parseFloat(response.data.raw3Rate),
              raw4Rate: parseFloat(response.data.raw4Rate),
              raw5Rate: parseFloat(response.data.raw5Rate),
              raw6Rate: parseFloat(response.data.raw6Rate),
              raw7Rate: parseFloat(response.data.raw7Rate),
              raw8Rate: parseFloat(response.data.raw8Rate),
              bigCostRate: parseFloat(response.data.bigCostRate),
              bigProductionRate: parseFloat(response.data.bigProductionRate),
              smallCostRate: parseFloat(response.data.smallCostRate),
              smallProductionRate: parseFloat(
                response.data.smallProductionRate
              ),
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };

  async handleTDIEsterRateChange(e) {
    if (e) {
      await this.setState({
        tDIEsterRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        tDIEsterRate: 0,
      });
    }
  }

  async handleRaw1RateChange(e) {
    if (e) {
      await this.setState({
        raw1Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw1Rate: 0,
      });
    }
  }

  async handleRaw2RateChange(e) {
    if (e) {
      await this.setState({
        raw2Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw2Rate: 0,
      });
    }
  }

  async handleRaw3RateChange(e) {
    if (e) {
      await this.setState({
        raw3Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw3Rate: 0,
      });
    }
  }

  async handleRaw4RateChange(e) {
    if (e) {
      await this.setState({
        raw4Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw4Rate: 0,
      });
    }
  }

  async handleRaw5RateChange(e) {
    if (e) {
      await this.setState({
        raw5Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw5Rate: 0,
      });
    }
  }

  async handleRaw6RateChange(e) {
    if (e) {
      await this.setState({
        raw6Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw6Rate: 0,
      });
    }
  }

  async handleRaw7RateChange(e) {
    if (e) {
      await this.setState({
        raw7Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw7Rate: 0,
      });
    }
  }

  async handleRaw8RateChange(e) {
    if (e) {
      await this.setState({
        raw8Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw8Rate: 0,
      });
    }
  }

  async handleLama810RateChange(e) {
    if (e) {
      await this.setState({
        lama810Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama810Rate: 0,
      });
    }
  }

  async handleBigCostRateChange(e) {
    if (e) {
      await this.setState({
        bigCostRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bigCostRate: 0,
      });
    }
  }

  async handleBigProductionRateChange(e) {
    if (e) {
      await this.setState({
        bigProductionRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bigProductionRate: 0,
      });
    }
  }

  async handleSmallCostRateChange(e) {
    if (e) {
      await this.setState({
        smallCostRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        smallCostRate: 0,
      });
    }
  }

  async handleSmallProductionRateChange(e) {
    if (e) {
      await this.setState({
        smallProductionRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        smallProductionRate: 0,
      });
    }
  }

  async handleProfitRateChange(e) {
    if (e) {
      await this.setState({
        profitRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        profitRate: 0,
      });
    }
  }

  async handleTransportRateChange(e) {
    if (e) {
      await this.setState({
        transportRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        transportRate: 0,
      });
    }
  }

  clearPage() {
    this.setState({
      status: true,
      lama810Rate: 0,
      profitRate: 0,
      transportRate: 0,
      smallProductionRate: 0,
      smallCostRate: 0,
      bigCostRate: 0,
      bigProductionRate: 0,
      raw1Rate: 0,
      raw2Rate: 0,
      raw3Rate: 0,
      raw4Rate: 0,
      raw5Rate: 0,
      raw6Rate: 0,
      raw7Rate: 0,
      raw8Rate: 0,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });
    if (
      this.state.lama810Rate !== 0 ||
      this.state.profitRate !== 0 ||
      this.state.bigCostRate !== 0 ||
      this.state.bigProductionRate !== 0 ||
      this.state.smallCostRate !== 0 ||
      this.state.smallProductionRate !== 0 ||
      this.state.transportRate !== 0 ||
      this.state.raw1Rate !== 0 ||
      this.state.raw2Rate !== 0 ||
      this.state.raw3Rate !== 0 ||
      this.state.raw4Rate !== 0 ||
      this.state.raw5Rate !== 0 ||
      this.state.raw6Rate !== 0 ||
      this.state.raw7Rate !== 0 ||
      this.state.raw8Rate !== 0
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        lama810Rate:
          this.state.lama810Rate === "NaN"
            ? 0
            : parseFloat(this.state.lama810Rate),
        profitRate:
          this.state.profitRate === "NaN"
            ? 0
            : parseFloat(this.state.profitRate),

        transportRate:
          this.state.transportRate === "NaN"
            ? 0
            : parseFloat(this.state.transportRate),
        bigCostRate:
          this.state.bigCostRate === "NaN"
            ? 0
            : parseFloat(this.state.bigCostRate),
        bigProductionRate:
          this.state.bigProductionRate === "NaN"
            ? 0
            : parseFloat(this.state.bigProductionRate),
        smallCostRate:
          this.state.smallCostRate === "NaN"
            ? 0
            : parseFloat(this.state.smallCostRate),
        smallProductionRate:
          this.state.smallProductionRate === "NaN"
            ? 0
            : parseFloat(this.state.smallProductionRate),
        raw1Rate:
          this.state.raw1Rate === "NaN" ? 0 : parseFloat(this.state.raw1Rate),
        raw2Rate:
          this.state.raw2Rate === "NaN" ? 0 : parseFloat(this.state.raw2Rate),
        raw3Rate:
          this.state.raw3Rate === "NaN" ? 0 : parseFloat(this.state.raw3Rate),
        raw4Rate:
          this.state.raw4Rate === "NaN" ? 0 : parseFloat(this.state.raw4Rate),
        raw5Rate:
          this.state.raw5Rate === "NaN" ? 0 : parseFloat(this.state.raw5Rate),
        raw6Rate:
          this.state.raw6Rate === "NaN" ? 0 : parseFloat(this.state.raw6Rate),
        raw7Rate:
          this.state.raw7Rate === "NaN" ? 0 : parseFloat(this.state.raw7Rate),
        raw8Rate:
          this.state.raw8Rate === "NaN" ? 0 : parseFloat(this.state.raw8Rate),
        status: this.state.status,
      };
      var URLParam = "/fixedTpus/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "tpu-sabitleri-guncelle"
      ) {
        URLParam = "/fixedTpus/update";
        myObj = {
          guid: this.state.guid,
          lama810Rate:
          this.state.lama810Rate === "NaN"
            ? 0
            : parseFloat(this.state.lama810Rate),
        profitRate:
          this.state.profitRate === "NaN"
            ? 0
            : parseFloat(this.state.profitRate),

        transportRate:
          this.state.transportRate === "NaN"
            ? 0
            : parseFloat(this.state.transportRate),
        bigCostRate:
          this.state.bigCostRate === "NaN"
            ? 0
            : parseFloat(this.state.bigCostRate),
        bigProductionRate:
          this.state.bigProductionRate === "NaN"
            ? 0
            : parseFloat(this.state.bigProductionRate),
        smallCostRate:
          this.state.smallCostRate === "NaN"
            ? 0
            : parseFloat(this.state.smallCostRate),
        smallProductionRate:
          this.state.smallProductionRate === "NaN"
            ? 0
            : parseFloat(this.state.smallProductionRate),
        raw1Rate:
          this.state.raw1Rate === "NaN" ? 0 : parseFloat(this.state.raw1Rate),
        raw2Rate:
          this.state.raw2Rate === "NaN" ? 0 : parseFloat(this.state.raw2Rate),
        raw3Rate:
          this.state.raw3Rate === "NaN" ? 0 : parseFloat(this.state.raw3Rate),
        raw4Rate:
          this.state.raw4Rate === "NaN" ? 0 : parseFloat(this.state.raw4Rate),
        raw5Rate:
          this.state.raw5Rate === "NaN" ? 0 : parseFloat(this.state.raw5Rate),
        raw6Rate:
          this.state.raw6Rate === "NaN" ? 0 : parseFloat(this.state.raw6Rate),
        raw7Rate:
          this.state.raw7Rate === "NaN" ? 0 : parseFloat(this.state.raw7Rate),
        raw8Rate:
          this.state.raw8Rate === "NaN" ? 0 : parseFloat(this.state.raw8Rate),
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "tpu-sabitleri-guncelle"
            ) {
              window.location.href = "/tpu-sabitleri-listele";
            }
            if (this.state.urlSplit[1] === "tpu-sabitleri-ekle") {
              window.location.reload();
            }
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "tpu-sabitleri-ekle" ||
          this.state.urlSplit[1] === "tpu-sabitleri-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw1Rate"
                      label="Hammadde-1 Birimi (Euro)"
                      value={this.state.raw1Rate}
                      onChange={(e) => this.handleRaw1RateChange(e)}
                      placeHolder="Lütfen hammadde-1 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw2Rate"
                      label="Hammadde-2 Birimi (Euro)"
                      value={this.state.raw2Rate}
                      onChange={(e) => this.handleRaw2RateChange(e)}
                      placeHolder="Lütfen hammadde-2 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw2RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw3Rate"
                      label="Hammadde-3 Birimi (Euro)"
                      value={this.state.raw3Rate}
                      onChange={(e) => this.handleRaw3RateChange(e)}
                      placeHolder="Lütfen hammadde-3 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw3RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw4Rate"
                      label="Hammadde-4 Birimi (Euro)"
                      value={this.state.raw4Rate}
                      onChange={(e) => this.handleRaw4RateChange(e)}
                      placeHolder="Lütfen hammadde-4 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw4RateError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                <Row>
                <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw5Rate"
                      label="Hammadde-5 Birimi (Euro)"
                      value={this.state.raw5Rate}
                      onChange={(e) => this.handleRaw5RateChange(e)}
                      placeHolder="Lütfen hammadde-5 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw5RateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw6Rate"
                      label="Hammadde-6 Birimi (Euro)"
                      value={this.state.raw6Rate}
                      onChange={(e) => this.handleRaw6RateChange(e)}
                      placeHolder="Lütfen hammadde-6 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw6RateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw7Rate"
                      label="Hammadde-7 Birimi (Euro)"
                      value={this.state.raw7Rate}
                      onChange={(e) => this.handleRaw7RateChange(e)}
                      placeHolder="Lütfen hammadde-7 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw7RateError}
                      step="0.1"
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="raw8Rate"
                      label="Hammadde-8 Birimi (Euro)"
                      value={this.state.raw8Rate}
                      onChange={(e) => this.handleRaw8RateChange(e)}
                      placeHolder="Lütfen hammadde-8 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw8RateError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                <Row>                
                <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="smallProductionRate"
                      label="Küçük Enj Üretim Miktarı Birimi (Euro)"
                      value={this.state.smallProductionRate}
                      onChange={(e) => this.handleSmallProductionRateChange(e)}
                      placeHolder="Lütfen küçük enj üretim miktarı birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallProductionRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bigProductionRate"
                      label="Büyük Enj Üretim Miktarı Birimi (Euro)"
                      value={this.state.bigProductionRate}
                      onChange={(e) => this.handleBigProductionRateChange(e)}
                      placeHolder="Lütfen büyük enj üretim miktarı birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigProductionRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="smallCostRate"
                      label="Küçük Enj Maliyeti Birimi (Euro)"
                      value={this.state.smallCostRate}
                      onChange={(e) => this.handleSmallCostRateChange(e)}
                      placeHolder="Lütfen küçük enj maliyeti birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallCostRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="bigCostRate"
                      label="Büyük Enj Maliyeti Birimi (Euro)"
                      value={this.state.bigCostRate}
                      onChange={(e) => this.handleBigCostRateChange(e)}
                      placeHolder="Lütfen büyük enj maliyeti birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigCostRateError}
                      step="0.1"
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama810Rate"
                      label="8x16 Lama Birimi (Euro)"
                      value={this.state.lama810Rate}
                      onChange={(e) => this.handleLama810RateChange(e)}
                      placeHolder="Lütfen 8x16 Lama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="profitRate"
                      label="İşçilik Yüzdesi"
                      value={this.state.profitRate}
                      onChange={(e) => this.handleProfitRateChange(e)}
                      placeHolder="İşçilik yüzdesi değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.profitRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="transportRate"
                      label="Nakliye Birimi (Euro)"
                      value={this.state.transportRate}
                      onChange={(e) => this.handleTransportRateChange(e)}
                      placeHolder="Lütfen nakliye birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportTotalError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "tpu-sabitleri-ekle" ||
              this.state.urlSplit[1] === "tpu-sabitleri-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(FixedTpuAddUpdate);
