import * as actionTypes from "../actionTypes";
import { URL } from "../constants";
import { handleError, handleResponse } from "../handles";
import axios from "axios";

export function employeeListSuccess(success) {
  return { type: actionTypes.EMPLOYEE_LIST_SUCCESS, payload: success };
}

export function employeeListError(error) {
  return { type: actionTypes.EMPLOYEE_LIST_ERROR, payload: error };
}

export function employeeList() {
  let urlSplit = window.location.pathname.split("/");
  return async function (dispatch) {
    if (urlSplit[1] === "personel-listele" || urlSplit[1] === "personel2-listele") {
      var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
      var config = {
        method: "GET",
        url: URL + "/members/getmembers/" + statusType,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
    }
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          handleResponse(employeeListSuccess, dispatch, response);
          handleError(employeeListError, dispatch, {});
        } else {
          handleResponse(employeeListSuccess, dispatch, response);
          handleError(employeeListError, dispatch, {});
        }
      })
      .catch((error) => {
        handleResponse(employeeListSuccess, dispatch, {});
        handleError(employeeListError, dispatch, error.response);
      });
  };
}
