import React from "react";
import { Badge } from "reactstrap";

const CgnBadge = ({ color, text, style, className }) => {
  return (
    <>
      {color && text && (
        <Badge className={className} color={color} style={style}>{text}</Badge>
      )}
    </>
  );
};

export default CgnBadge;
