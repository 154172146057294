import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL,  localFilePath } from "../../redux/actions/constants";

class ShipmentDetail extends Component {
  state = {
    pageTitle: "Sevkiyat Detay",
    pageTitleDetail: "Sevkiyat Detay",
    pageAddLink: "sevkiyat-ekle",
    pageListLink: "sevkiyat-listele",
    breadcrumb: [{ text: "Sevkiyat", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    productName: "",
    customerName: "",
    productOutName: "",
    currencyType: "",
    workInstructionCode: "",
    supplierName: "",
    detectionPersonelName: "",
    waybillNumber: "",
    locationName: "",
    piece: "",
    shipmentDate: "",
    shipmentStatus: "",
    description: "",
    personelName: "",
    cocNo: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    workDate: "",
    productOutDate: "",
    productOutCustomer: "",
    productOutTotal: "",
   };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/shipments/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            workInstructionCode: response.data.workInstructionCode,
            customerName: response.data.customerName,
            personelName: response.data.personelName,
            locationName: response.data.locationName,
            productCode: response.data.productCode,
            productName: response.data.productName,
            productOutCustomer: response.data.productOutCustomer,
            productOutDate: response.data.productOutDate,
            productOutTotal: response.data.productOutTotal,
            shipmentDate: response.data.shipmentDate,
            shipmentStatus: response.data.shipmentStatus,
            waybillNumber: response.data.waybillNumber,
            description: response.data.description,
            piece: response.data.piece,
            currencyType: response.data.currencyType,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/shipments/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };


  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          printLink={onClick => this.handlePdfPrint(this.state.guid)}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Sevkiyat Tarihi</th>
                    <td colSpan="4">
                      {this.state.shipmentDate}
                    </td>
                  </tr>             
                  <tr>
                    <th>İş Emri Numarası</th>
                    <td colSpan="4">{this.state.workInstructionCode}</td>
                  </tr>
                  <tr>
                    <th>Sevkiyatı Yapan Personel</th>
                    <td colSpan="4">{this.state.personelName}</td>
                  </tr>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">{this.state.customerName}</td>
                  </tr>
                  <tr>
                    <th>Satış</th>
                    <td colSpan="4">{this.state.productOutDate + "-" + this.state.productOutCustomer}</td>
                  </tr>
                  <tr>
                    <th>Ürün</th>
                    <td colSpan="4">{this.state.productName}</td>
                  </tr>
                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">{this.state.locationName}</td>
                  </tr>
                  <tr>
                    <th>Adet</th>
                    <td colSpan="4">{this.state.piece}</td>
                  </tr>
                  
                  <tr>
                    <th>İrsaliye Numarası</th>
                    <td colSpan="4">{this.state.waybillNumber === null ? "-" : this.state.waybillNumber}</td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.shipmentStatus === 1 ? "Sevkiyat Aşamasında" : this.state.shipmentStatus === 2 ? "Sevk Edildi" : "-"}</td>
                  </tr>
                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">{this.state.description === "" ? "-" : this.state.description}</td>
                  </tr>
                
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

           </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ShipmentDetail;
