import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  cuttingStatuses,
  
  cutList,
  cutTypeList,
  CustomerList,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import CgnButton from "../toolbox/CgnButton";

class CuttingProcessAddUpdate extends Component {
  state = {
    pageTitle: "Kesim İşlemi Ekle",
    pageTitleAdd: "Kesim İşlemi Ekle",
    pageTitleUpdate: "Kesim İşlemi Güncelle",
    pageAddLink: "kesim-islemi-ekle",
    pageListLink: "kesim-islemi-listele",
    breadcrumb: [{ text: "Kesim İşlemi", link: "#" }],
    isLoading: false,
    urlSplit: [],
    customerGuid: null,
    customerArr: [],
    cutId: 0,
    cutTypeId: 0,
    thickness: "",
    width: "",
    height: "",
    piece: 1,
    kg: 0,
    time: "",
    hourTL: "",
    laserCutting: "",
    totalKg: 0,
    sheetPrice: "",
    cuttingWork: "",
    twistingWork: "",
    specialTL: "",
    transportTL: "",
    totalPrice: "",
    guid: "",
    projectName: "",
    fileName: "",
    processStatus: 0,
    status: true,
    nameError: "",
    alertErrorMessage: "",
    customerError: "",
    cutError: "",
    cutTypeError: "",
    thicknessError: "",
    heightError: "",
    pieceError: "",
    timeError: "",
    laserCuttingError: "",
    sheetPriceError: "",
    cuttingWorkError: "",
    twistingWorkError: "",
    widthError: "",
    specialTLError: "",
    transportTLError: "",
    totalPriceError: "",
    fileNameError: "",
    projectNameError: "",
    processStatusError: "",
    isHiddenLaser: true,
    isHiddenSac: true,
    isHiddenCutting: true,
    isHiddenTwisting: true,
    isHiddenSpecial: true,
    isHiddenTransport: true,
    isHiddenTotal: true,
    isHiddenBukum: true,
    productData: [],
    counter: -1,
    generalKg: 0,
    productDataError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2;
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            customerGuid: item.customerGuid,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/cuttingprocess/getvariable/", config)
      .then((response) => {
        this.setState({
          hourTL: response.data.value,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .get(URL + "/cuttingprocess/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              customerGuid: response.data.customerGuid,
              productData: response.data.cuttingKilogramDtos,
              specialTL:
                response.data.specialTL === 0 ? "" : response.data.specialTL,
              transportTL:
                response.data.transportTL === 0
                  ? ""
                  : response.data.transportTL,
              totalPrice: response.data.totalPrice,
              status: response.data.status,
              isLoading: false,
              fileName: response.data.fileName,
              projectName:
                response.data.projectName === ""
                  ? ""
                  : response.data.projectName,
              processStatus: response.data.processStatus,
              counter: response.data.cuttingKilogramDtos.length - 1,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      let myArray = await this.state.productData;
      myArray.forEach((element) => {
        if (element.cuttingWork === 0) {
          element.cuttingWork = "";
        }
        if (element.twistingWork === 0) {
          element.twistingWork = "";
        }
        if (element.sheetPrice === 0) {
          element.sheetPrice = "";
        }
        if (element.hourTL === 0) {
          element.hourTL = "";
        }
        if (element.time === 0) {
          element.time = "";
        }
        if (element.laserCutting === 0) {
          element.laserCutting = "";
        }
      });
      this.setState({
        productData: myArray,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        customerError: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleCutChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].cutId = option.value;
      productData[idx].cutError = "";
      if (productData[idx].cutId === 1) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 2) {
        productData[idx].isHiddenLaser = false;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 3) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 4) {
        productData[idx].isHiddenLaser = false;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 6) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 5) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 7) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 8) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = true;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 9) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 10) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 11) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 12) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 13) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 14) {
        productData[idx].isHiddenLaser = true;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 15) {
        productData[idx].isHiddenLaser = false;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else if (productData[idx].cutId === 16) {
        productData[idx].isHiddenLaser = false;
        productData[idx].isHiddenTwisting = false;
        productData[idx].isHiddenSac = false;
        productData[idx].isHiddenSpecial = false;
        productData[idx].isHiddenTransport = false;
        productData[idx].isHiddenTotal = false;
        productData[idx].isHiddenCutting = false;
        productData[idx].isHiddenBukum = false;
        productData[idx].time = "";
        productData[idx].laserCutting = "";
        productData[idx].sheetPrice = "";
        productData[idx].cuttingWork = "";
        productData[idx].twistingWork = "";
        productData[idx].timeError = "";
        productData[idx].laserCuttingError = "";
        productData[idx].sheetPriceError = "";
        productData[idx].cuttingWorkError = "";
        productData[idx].twistingWorkError = "";
        this.setState({
          productData: productData,
        });
      } else {
        await this.setState({
          productData: [],
          specialTL: "",
          transportTL: "",
          totalPrice: "",
          timeError: "",
          specialTLError: "",
          transportTLError: "",
          totalPriceError: "",
        });
      }
    } else {
      productData[idx].isHiddenLaser = true;
      productData[idx].isHiddenTwisting = false;
      productData[idx].isHiddenSac = false;
      productData[idx].isHiddenSpecial = false;
      productData[idx].isHiddenTransport = false;
      productData[idx].isHiddenTotal = false;
      productData[idx].isHiddenCutting = false;
      productData[idx].isHiddenBukum = true;
      productData[idx].time = "";
      productData[idx].laserCutting = "";
      productData[idx].sheetPrice = "";
      productData[idx].cuttingWork = "";
      productData[idx].twistingWork = "";
      productData[idx].timeError = "";
      productData[idx].laserCuttingError = "";
      productData[idx].sheetPriceError = "";
      productData[idx].cuttingWorkError = "";
      productData[idx].twistingWorkError = "";
      productData[idx].cutId = "";
      productData[idx].cutErorr = CgnMessage.textErrorMessage;
      this.state.alertErrorMessage = CgnMessage.textErrorMessage;
      await this.setState({
        specialTL: "",
        transportTL: "",
        totalPrice: "",
        timeError: "",
        specialTLError: "",
        transportTLError: "",
        totalPriceError: "",
      });
    }
  }

  async handleCutTypeChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].cutTypeId = option.value;
      productData[idx].cutTypeError = "";
    } else {
      productData[idx].cutTypeId = "";
      productData[idx].cutTypeError = CgnMessage.alertErrorMessage;
      this.state.alertErrorMessage = CgnMessage.alertErrorMessage;
    }
    this.setState({
      productData: productData,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      sheetPrice: "",
      cutId: "",
      cuttTypeId: "",
      laserCutting: "",
      time: "",
      hourTL: "",
      twistingWork: "",
      cuttingWork: "",
      thickness: "",
      description: "",
      width: "",
      piece: 1,
      height: "",
      kg: 0,
      totalKg: 0,
      isHiddenLaser: true,
      isHiddenBukum: true,
      thicknessError: CgnMessage.textErrorMessage,
      widthError: CgnMessage.textErrorMessage,
      heightError: CgnMessage.textErrorMessage,
      cutError: CgnMessage.textErrorMessage,
      cutTypeError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].thickness) {
      return array[this.state.counter].thicknessError === "";
    }
    if (array[this.state.counter].width) {
      return array[this.state.counter].widthError === "";
    }
    if (array[this.state.counter].height) {
      return array[this.state.counter].heightError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].pieceError === "";
    }
    if (array[this.state.counter].cutId) {
      return array[this.state.counter].cutErorr === "";
    }
    if (array[this.state.counter].cutTypeId) {
      return array[this.state.counter].cutTypeErorr === "";
    }
    if (
      array[this.state.counter].thickness === "" ||
      array[this.state.counter].width === "" ||
      array[this.state.counter].height === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].kg === 0 ||
      array[this.state.counter].cutId === "" ||
      array[this.state.counter].cutTypeId === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  async handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);

    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        counter: -1,
      });
    }
    this.TotalCalculate(null, this.state.counter);
  }

  async handleThicknessChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].thickness = parseFloat(e.target.value);
    productData[idx].thicknessError = "";
    this.setState({
      productData: productData,
    });
    this.PriceCalculate(null, idx);
  }

  async handleWidthChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].width = parseFloat(e.target.value);
    productData[idx].widthError = "";
    this.setState({
      productData: productData,
    });
    this.PriceCalculate(null, idx);
  }

  async handleHeightChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].height = parseFloat(e.target.value);
    productData[idx].heightError = "";
    this.setState({
      productData: productData,
    });

    this.PriceCalculate(null, idx);
  }

  async handlePieceChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    productData[idx].pieceError = "";
    this.setState({
      productData: productData,
    });
    this.PriceCalculate(null, idx);
  }

  PriceCalculate = async (event, idx) => {
    let productData = this.state.productData;
    productData[idx].totalKg = 0;
    var genelKg = 0;
    var totalKg =
      parseFloat(productData[idx].thickness) *
      parseFloat(productData[idx].height) *
      parseFloat(productData[idx].width) *
      parseFloat(productData[idx].piece) *
      8;
    var genelTotalKg = totalKg / 1000000;
    productData[idx].kg = genelTotalKg;

    // await this.state.productData.forEach((item) => {
    //   genelKg += parseFloat(item.kg)
    // });

    // productData[idx].generalKg = parseFloat(genelKg).toFixed(2);

    this.setState({
      productData: productData,
    });

    this.TotalCalculate(null, idx);
  };

  async handleTimeChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].time = e.target.value;
    productData[idx].timeError = "";

    this.LaserCuttingCalculate(null, idx);
  }

  async handleHourTLChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].hourTL = e.target.value;
    productData[idx].hourTLError = "";

    this.LaserCuttingCalculate(null, idx);
  }

  LaserCuttingCalculate = async (event, idx) => {
    let productData = this.state.productData;
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });

      if (name === "time" && value === "") {
        this.setState({
          time: 0,
          timeError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "hourTL" && value === "") {
        this.setState({
          hourTL: 0,
          hourTLError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    }
    var newTime = await productData[idx].time;
    if (newTime === "") {
      newTime = 0;
    }

    var newHourTL = await productData[idx].hourTL;
    if (newHourTL === "") {
      newHourTL = 0;
    }

    var totalLaserCutting = (parseFloat(newTime) * parseFloat(newHourTL)) / 60;

    productData[idx].laserCutting = totalLaserCutting;

    //productData[idx].totalKg += productData[idx].laserCutting;

    this.setState({
      productData: productData,
    });

    this.TotalCalculate(null, idx);
  };

  async handleSheetPriceChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].sheetPrice = e.target.value;
    productData[idx].sheetPriceError = "";

    this.TotalCalculate(null, idx);
  }

  async handleCuttingWorkChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].cuttingWork = e.target.value;
    productData[idx].cuttingWorkError = "";

    this.TotalCalculate(null, idx);
  }

  async handleTwistingWorkChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].twistingWork = e.target.value;
    productData[idx].twistingWorkError = "";

    this.TotalCalculate(null, idx);
  }

  async handleSpecialTLChange(e) {
    await this.setState({
      specialTL: e.target.value,
      specialTLError: "",
      alertErrorMessage: "",
    });

    this.TotalCalculate2();
  }

  async handleTransportTLChange(e) {
    await this.setState({
      transportTL: e.target.value,
      transportTLError: "",
      alertErrorMessage: "",
    });

    this.TotalCalculate2();
  }

  TotalCalculate = async (event, idx) => {
    let productData = await this.state.productData;
    if (idx !== -1) {
      if (productData[idx].cutId !== "" && productData[idx].cutTypeId !== "") {
        await this.setState({
          totalPrice: 0,
        });

        productData[idx].totalKg = 0;

        var newSheetPrice = await productData[idx].sheetPrice;
        if (newSheetPrice === "") {
          newSheetPrice = 0;
        }

        var newTime = await productData[idx].time;
        if (newTime === "") {
          newTime = 0;
        }

        var newHourTL = await productData[idx].hourTL;
        if (newHourTL === "") {
          newHourTL = 0;
        }

        var newLaserCutting = await productData[idx].laserCutting;
        if (newLaserCutting === "") {
          newLaserCutting = 0;
        }

        var newCuttingWork = await productData[idx].cuttingWork;
        if (newCuttingWork === "") {
          newCuttingWork = 0;
        }

        var newTwistingWork = await productData[idx].twistingWork;
        if (newTwistingWork === "") {
          newTwistingWork = 0;
        }

        // if(newSheetPrice === 0 && newCuttingWork === 0 && newTwistingWork === 0){
        //   productData[idx].totalKg = parseFloat(productData[idx].kg) + parseFloat(newLaserCutting);
        // }
        // else{
        //  productData[idx].totalKg = parseFloat(productData[idx].kg * parseFloat(newSheetPrice)) + parseFloat(productData[idx].kg * parseFloat(newCuttingWork)) + parseFloat(productData[idx].kg * parseFloat(newTwistingWork)) + parseFloat(newLaserCutting);
        // }

        productData[idx].totalKg =
          parseFloat(productData[idx].kg * parseFloat(newSheetPrice)) +
          parseFloat(productData[idx].kg * parseFloat(newCuttingWork)) +
          parseFloat(productData[idx].kg * parseFloat(newTwistingWork)) +
          parseFloat(newLaserCutting);

        await this.setState({
          productData: productData,
        });
      }
    }
    this.TotalCalculate2(null, idx);
  };

  TotalCalculate2 = async () => {
    let productData = this.state.productData;

    this.state.totalPrice = 0;

    productData.forEach((element) => {
      this.state.totalPrice += parseFloat(element.totalKg);
    });

    var newSpecialTL = await this.state.specialTL;
    if (newSpecialTL === "") {
      newSpecialTL = 0;
    }

    var newTransportTL = await this.state.transportTL;
    if (newTransportTL === "") {
      newTransportTL = 0;
    }
    this.state.totalPrice =
      parseFloat(this.state.totalPrice) + parseFloat(newTransportTL);

    await this.setState({
      totalPrice: parseFloat(this.state.totalPrice).toFixed(2),
      totalPriceError: "",
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  handleChange2 = (tags) => (event) => {
    const { name, value } = event.target;
    let productData = this.state.productData;
    var idx = tags;

    productData[idx][name] = value;
    this.setState({
      productData: productData,
    });
  };

  clearPage() {
    this.setState({
      name: "",
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleFileNameChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    await this.setState({
      fileNameError: "",
      alertErrorMessage: "",
    });
    if (name === "fileName" && value === "") {
      await this.setState({
        fileNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleProjectNameChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    await this.setState({
      projectNameError: "",
    });
    if (name === "projectName" && value === "") {
      await this.setState({
        projectNameError: CgnMessage.textErrorMessage,
      });
    }
  };

  handleProcessStatusSelectChange(option) {
    if (option) {
      this.setState({
        processStatus: option.value,
        processStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        processStatus: 0,
        processStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
    });

    if (this.state.customerGuid === "" || this.state.customerGuid === null) {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.fileName === "") {
      this.setState({
        fileNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if (this.state.cutId === 0 || this.state.cutId === null) {
    //   this.setState({
    //     cutError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.totalPrice === 0 || this.state.totalPrice === null) {
    //   this.setState({
    //     totalPriceError: CgnMessage.alertErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    if (
      this.state.productData === null ||
      this.state.productData.length === 0
    ) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.productData !== null || this.state.productData.length > 0) {
      this.state.productData.forEach((element) => {
        if (
          element.thickness === "" ||
          element.width === "" ||
          element.height === "" ||
          element.piece === ""
        ) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
        if (element.cutId === "") {
          control = false;
          this.setState({
            cutError: CgnMessage.textErrorMessage,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
        if (element.cutTypeId === "") {
          control = false;
          this.setState({
            cutTypeError: CgnMessage.textErrorMessage,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (this.state.processStatus === 0) {
      this.setState({
        processStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control2 = true;
    var control6 = true;

    // if (this.state.cutId === 2) {
    //   if (this.state.time === "" || this.state.time === null) {
    //     control2 = false;
    //     this.setState({
    //       timeError: CgnMessage.textErrorMessage,
    //       alertErrorMessage: CgnMessage.alertErrorMessage,
    //     });
    //   }
    //   if (this.state.hourTL === "" || this.state.hourTL === null) {
    //     control2 = false;
    //     this.setState({
    //       hourTLError: CgnMessage.textErrorMessage,
    //       alertErrorMessage: CgnMessage.alertErrorMessage,
    //     });
    //   }
    // }

    // if (this.state.cutId === 4) {
    //   if (this.state.time === "" || this.state.time === null) {
    //     control6 = false;
    //     this.setState({
    //       timeError: CgnMessage.textErrorMessage,
    //       alertErrorMessage: CgnMessage.alertErrorMessage,
    //     });
    //   }
    //   if (this.state.hourTL === "" || this.state.hourTL === null) {
    //     control6 = false;
    //     this.setState({
    //       hourTLError: CgnMessage.textErrorMessage,
    //       alertErrorMessage: CgnMessage.alertErrorMessage,
    //     });
    //   }
    // }

    if (
      this.state.customerGuid !== null &&
      this.state.fileName !== "" &&
      this.state.productData.length > 0 &&
      this.state.processStatus !== 0 &&
      control2 === true &&
      control6 === true &&
      control === true
    ) {
      this.setState({
        isLoading: true,
      });
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          cutId: element.cutId,
          cutTypeId: element.cutTypeId,
          thickness: parseFloat(element.thickness),
          height: parseFloat(element.height),
          width: parseFloat(element.width),
          piece: parseFloat(element.piece),
          kg: parseFloat(element.kg),
          time: element.time !== "" ? parseFloat(element.time) : 0,
          hourTL: element.hourTL !== "" ? parseFloat(element.hourTL) : 0,
          laserCutting:
            element.laserCutting !== "" ? parseFloat(element.laserCutting) : 0,
          sheetPrice:
            element.sheetPrice !== "" ? parseFloat(element.sheetPrice) : 0,
          cuttingWork:
            element.cuttingWork !== "" ? parseFloat(element.cuttingWork) : 0,
          twistingWork:
            element.twistingWork !== "" ? parseFloat(element.twistingWork) : 0,
          totalKg: parseFloat(element.totalKg),
          description: element.description,
        });
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        customerGuid: this.state.customerGuid,
        cuttingKilogramDtos: productData,
        specialTL:
          this.state.specialTL !== "" ? parseFloat(this.state.specialTL) : 0,
        transportTL:
          this.state.transportTL !== ""
            ? parseFloat(this.state.transportTL)
            : 0,
        totalPrice: parseFloat(this.state.totalPrice),
        status: true,
        fileName: this.state.fileName,
        projectName: this.state.projectName,
        processStatus: this.state.processStatus,
      };

      var URLParam = "/cuttingprocess/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "kesim-islemi-guncelle"
      ) {
        URLParam = "/cuttingprocess/update";
        myObj = {
          guid: this.state.guid,
          customerGuid: this.state.customerGuid,
          cuttingKilogramDtos: productData,
          specialTL:
            this.state.specialTL !== "" ? parseFloat(this.state.specialTL) : 0,
          transportTL:
            this.state.transportTL !== ""
              ? parseFloat(this.state.transportTL)
              : 0,
          totalPrice: parseFloat(this.state.totalPrice),
          status: true,
          fileName: this.state.fileName,
          projectName: this.state.projectName,
          processStatus: this.state.processStatus,
        };
      }

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "kesim-islemi-guncelle"
            ) {
              window.location.href = "/kesim-islemi-listele";
            }
            if (this.state.urlSplit[1] === "kesim-islemi-ekle") {
              window.location.href = "/kesim-islemi-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "kesim-islemi-ekle" ||
          this.state.urlSplit[1] === "kesim-islemi-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      placeholder="Lütfen müşteri seçin..."
                      isMulti={false}
                      isClearable={true}
                      selectValue={this.state.customerGuid}
                      options={this.state.customerArr}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      error={this.state.customerError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="fileName"
                      label="Dosya Adı [*]"
                      value={this.state.fileName}
                      onChange={this.handleFileNameChange}
                      placeHolder="Lütfen dosya adı girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.fileNameError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="projectName"
                      label="Proje Adı"
                      value={this.state.projectName}
                      onChange={this.handleProjectNameChange}
                      placeHolder="Lütfen proje adı girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.projectNameError}
                    />
                  </Col>
                </Row>
                {this.state.productData !== null &&
                  this.state.productData.map((productData, idx) => (
                    <>
                      <Row className="mobileBorder">
                        <Col className="pl-0">
                          <CgnReactSelect
                            name="cutId"
                            label="Kesim [*]"
                            placeholder="kesim seçin..."
                            isMulti={false}
                            isClearable={true}
                            selectValue={productData.cutId}
                            options={cutList}
                            onChange={(option) =>
                              this.handleCutChange(option, idx)
                            }
                            error={productData.cutError}
                          />
                        </Col>
                        <Col>
                          <CgnReactSelect
                            name="cutTypeId"
                            label="Cins [*]"
                            placeholder="cins seçin..."
                            isMulti={false}
                            isClearable={true}
                            selectValue={productData.cutTypeId}
                            options={cutTypeList}
                            onChange={(option) =>
                              this.handleCutTypeChange(option, idx)
                            }
                            error={productData.cutTypeError}
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="thickness"
                            label="Kalınlık [*]"
                            value={productData.thickness}
                            onChange={(e) => this.handleThicknessChange(e, idx)}
                            placeHolder="kalınlık girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.thicknessError}
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="width"
                            label="En [*]"
                            value={productData.width}
                            onChange={(e) => this.handleWidthChange(e, idx)}
                            placeHolder="en girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.widthError}
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="height"
                            label="Boy [*]"
                            value={productData.height}
                            onChange={(e) => this.handleHeightChange(e, idx)}
                            placeHolder="boy girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.heightError}
                          />
                        </Col>
                        <Col className="pl-0">
                          <CgnTextbox
                            type="number"
                            name="piece"
                            label="Adet [*]"
                            value={productData.piece}
                            onChange={(e) => this.handlePieceChange(e, idx)}
                            placeHolder="adet girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.pieceError}
                            step="0.1"
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="kg"
                            label="Kilogram"
                            value={productData.kg}
                            maxLength="50"
                            autoComplete="off"
                            readOnly={true}
                            onChange={this.PriceCalculate}
                          />
                        </Col>
                        <Col className="pl-0">
                          <CgnTextbox
                            type="number"
                            name="sheetPrice"
                            label="Sac Fiyatı"
                            value={productData.sheetPrice}
                            onChange={(e) =>
                              this.handleSheetPriceChange(e, idx)
                            }
                            placeHolder="sac fiyatı girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.sheetPriceError}
                            step={0.1}
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="cuttingWork"
                            label="Kesim İşçiliği"
                            value={productData.cuttingWork}
                            onChange={(e) =>
                              this.handleCuttingWorkChange(e, idx)
                            }
                            placeHolder="kesim işçiliği girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.cuttingWorkError}
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="twistingWork"
                            label="Büküm İşçiliği"
                            value={productData.twistingWork}
                            onChange={(e) =>
                              this.handleTwistingWorkChange(e, idx)
                            }
                            placeHolder="büküm işçiliği girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.twistingWorkError}
                            disabled={productData.isHiddenBukum}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="1">
                          <CgnTextbox
                            type="number"
                            name="time"
                            label="Süre"
                            value={productData.time}
                            onChange={(e) => this.handleTimeChange(e, idx)}
                            placeHolder="süre girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.timeError}
                            hidden={productData.isHiddenLaser}
                          />
                        </Col>
                        <Col lg="1">
                          <CgnTextbox
                            type="number"
                            name="hourTL"
                            label="TL/Saat"
                            value={productData.hourTL}
                            onChange={(e) => this.handleHourTLChange(e, idx)}
                            placeHolder="TL/Saat girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.hourTLError}
                            hidden={productData.isHiddenLaser}
                          />
                        </Col>
                        <Col lg="1">
                          <CgnTextbox
                            type="number"
                            name="laserCutting"
                            label="Lazer Kesim"
                            value={productData.laserCutting}
                            readOnly={true}
                            onChange={this.LaserCuttingCalculate}
                            maxLength="50"
                            autoComplete="off"
                            error={productData.laserCuttingError}
                            hidden={productData.isHiddenLaser}
                          />
                        </Col>
                        <Col lg="1">
                          <CgnTextbox
                            type="number"
                            name="totalKg"
                            label="Toplam"
                            value={productData.totalKg}
                            readOnly={true}
                            onChange={this.TotalPriceCalculate}
                            maxLength="50"
                            autoComplete="off"
                            error={productData.totalPriceError}
                          />
                        </Col>
                        <Col lg="2">
                          <CgnTextbox
                            type="text"
                            name="description"
                            label="Açıklama"
                            value={productData.description}
                            //readOnly={true}
                            onChange={this.handleChange2(idx)}
                            //maxLength="50"
                            autoComplete="off"
                            //error={productData.totalPriceError}
                          />
                        </Col>
                        <Col lg="1">
                          <CgnButton
                            className="dynamicRowDeleteButton"
                            type="button"
                            color="danger"
                            onClick={() => this.handleProductRemove(idx)}
                            text={"Sil"}
                          />
                        </Col>
                      </Row>

                      <div
                        className="cizgi"
                        style={{
                          width: "100%",
                          border: "1px solid red",
                          marginBottom: "5px",
                        }}
                      ></div>
                    </>
                  ))}
                <Row>
                  <Col lg="2">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton mb-2"
                      onClick={this.handleProductAdd}
                      text={"İş Ekle"}
                    />
                  </Col>
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row>
                  <Col lg="2" className="pl-0">
                    <CgnTextbox
                      type="number"
                      name="transportTL"
                      label="Nakliye TL"
                      value={this.state.transportTL}
                      onChange={(e) => this.handleTransportTLChange(e)}
                      placeHolder="Lütfen nakliye TL girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportTLError}
                    />
                  </Col>
                  <Col lg="2" className="pl-0">
                    <CgnTextbox
                      type="number"
                      name="specialTL"
                      label="Özel TL"
                      value={this.state.specialTL}
                      onChange={(e) => this.handleSpecialTLChange(e)}
                      placeHolder="Lütfen özel TL girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.specialTLError}
                    />
                  </Col>
                  <Col lg="2" className="pl-0">
                    <CgnTextbox
                      type="number"
                      name="totalPrice"
                      label="Genel Toplam"
                      value={this.state.totalPrice}
                      readOnly={true}
                      onChange={this.TotalPriceCalculate}
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.totalPriceError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="processStatus"
                      label="Durumu [*]"
                      placeholder="Lütfen durum seçin.."
                      selectValue={this.state.processStatus}
                      isMulti={false}
                      options={cuttingStatuses}
                      onChange={(status) =>
                        this.handleProcessStatusSelectChange(status)
                      }
                      error={this.state.processStatusError}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "kesim-islemi-ekle" ||
              this.state.urlSplit[1] === "kesim-islemi-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(CuttingProcessAddUpdate);
