import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  localFilePath,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  CustomerList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class MachineServiceList extends Component {
  state = {
    pageTitle: "Makine Servis Listele",
    pageAddLink: "makine-servis-ekle",
    pageUpdateLink: "makine-servis-guncelle",
    pageDetailLink: "makine-servis-detay",
    pageListLink: "makine-servis-listele",
    breadcrumb: [{ text: "Makine Servis", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    machineServiceProcessData: [],
    machineServiceSparePartData: [],
    totalAmount: 0,
    piece: 0,
    price: 0,
    guarantyTypeName: "",
    assignmentDate: "",
    interventionDate: "",
    departureTime: "",
    timeOfArrival: "",
    description: "",
    productItemCount: 0,
    discountTotalDolar: 0,
    totalPieceDolar: 0,
    productItemCountDolar: 0,
    discountTotalEuro: 0,
    totalPieceEuro: 0,
    productItemCountEuro: 0,
    guid: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateMachineService")) {
      firstDate = localStorage.getItem("firstDateMachineService");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateMachineService")) {
      lastDate = localStorage.getItem("lastDateMachineService");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidMachineService")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidMachineService"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateMachineService", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateMachineService", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidMachineService", this.state.customerGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let urlSplit = window.location.pathname.split("/");
    var URLParam = "";
    var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    URLParam =
      "/machineService/getlist/" +
      statusType +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.customerGuid;
    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          machineServiceData: response.data,
          isLoading: false,
        });
        var totalAmount = 0;
        var piece = 0;
        var productItemCount = 0;
        var discountTotalDolar = 0;
        var totalPieceDolar = 0;
        var productItemCountDolar = 0;
        var discountTotalEuro = 0;
        var totalPieceEuro = 0;
        var productItemCountEuro = 0;
        response.data.forEach((element) => {
          if (element.currencyType === 1) {
            totalAmount += element.totalAmount;
            piece += element.piece;
            productItemCount += element.productItemCount;
          } else if (element.currency === 2) {
            discountTotalDolar += element.totalAmount;
            totalPieceDolar += element.piece;
            productItemCountDolar += element.productItemCount;
          } else if (element.currency === 3) {
            discountTotalEuro += element.totalAmount;
            totalPieceEuro += element.piece;
            productItemCountEuro += element.productItemCount;
          }
        });
        this.setState({
          totalAmount: totalAmount,
          piece: piece,
          productItemCount: productItemCount,
          discountTotalDolar: discountTotalDolar,
          totalPieceDolar: totalPieceDolar,
          productItemCountDolar: productItemCountDolar,
          discountTotalEuro: discountTotalEuro,
          totalPieceEuro: totalPieceEuro,
          productItemCountEuro: productItemCountEuro,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineService/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineService/sendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/machineService/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "110px",
  };

  tdStyle = {
    width: "400px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row className="machineServiceList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="7">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.machineServiceData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Servis Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Atama Tarihi</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.assignmentDate}</td>
                              <th style={this.thStyle2}>Müşteri</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.customerName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müdahale Tarihi</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.interventionDate}
                              </td>
                              <th style={this.thStyle2}>Arıza Durumu</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.faultStatusName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Makine</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.machineName}</td>
                              <th style={this.thStyle2}>Şehir</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.city}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={3} style={this.tdStyle}>
                                {row.createdBy}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                          {
                            text: "Servis Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfPrint(row.guid),
                          },
                          {
                            text: "Servis E-Mail Gönder",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfSendMail(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineServiceList;
