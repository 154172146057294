import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const CgnCheckbox = ({
  name,
  label,
  onChange,
  onLoad,
  checked,
  error,
  disabled,
  style
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <FormGroup className={wrapperClass}>
      <div className="custom-control custom-checkbox" style={style}>
        <Input
          type="checkbox"
          className="custom-control-input"
          checked={checked}
          name={name}
          id={name}
          onChange={onChange}
          onLoad={onLoad}
          disabled={disabled}
        />
        <Label className="custom-control-label" for={name}>
          {label}
        </Label>
      </div>
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
};

export default CgnCheckbox;
