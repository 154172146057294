import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { localFilePath, URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButtonLinkGroup from "../toolbox/CgnButtonLinkGroup";
import noImg from "../../images/no-img.png";

class ProductRecipeList extends Component {
  state = {
    pageTitle: "Ürün Reçete Listele",
    pageAddLink: "urun-recete-ekle",
    pageUpdateLink: "urun-recete-guncelle",
    pageDetailLink: "urun-recete-detay",
    pageListLink: "urun-recete-listele",
    breadcrumb: [{ text: "Ürün Reçete", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    productData: [],
    name: "",
    productName: "",
    salePrice: "",
    guid: "",
    currencyType: 0,
    alertErrorMessage: "",
    activeTab: "1",
    categoryArr: [],
  };


  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    var statustype = 1;
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/productRecipes/getlist/" + statustype, config)
      .then((response) => {
        this.setState({
          productData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  
  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/productRecipes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }


  thStyle = {
    width: "120px",
  };

  thStyle2 = {
    width: "100px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}

              {this.state.categoryArr && this.state.categoryArr.length > 0 && (
                <div className="productFilterButton">
                  <CgnButtonLinkGroup items={this.state.categoryArr} />
                </div>
              )}

              <CgnDatatable
                data={this.state.productData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    selector: "image",
                    sortable: false,
                    width: "80px",
                    cell: (cell) => (
                      <div>
                        {cell.image ? (
                          <img
                            alt={cell.name}
                            src={cell.image}
                            className="rdt_image rounded"
                          />
                        ) : (
                          <img
                            alt={cell.name}
                            src={noImg}
                            className="rdt_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Ürün Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive">
                          <tbody>
                            <tr>
                            <th style={this.thStyle}>Ürün Kodu</th>
                              <td>{row.productCode}</td>                       
                            </tr>   
                            <tr>
                            <th style={this.thStyle}>Ürün</th>
                              <td>{row.productName}</td>      
                              </tr>                        
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.createdBy}</td>                              
                            </tr>                        
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },                        
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductRecipeList;
