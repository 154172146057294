import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  localFilePath,
  
  CustomerList
} from "../../redux/actions/constants";
import CgnMessage from "../toolbox/CgnMessage";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";


class OtherTicketDocumentList extends Component {
  state = {
    pageTitle: "Diğer İşlemler Belgeleri (ÜTS Dışı)",
    pageTitleDetail: "Diğer İşlemler Belgeleri (ÜTS Dışı)",
    pageListLink: "diger-islemler-ticket-belgeleri-listele",
    pageDetailLink: "diger-islemler-ticket-belgeleri-detay",
    breadcrumb: [{ text: "Diğer İşlemler Belgeleri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    customerDocumentOtherGuid: "",
    description: "",
    descriptionError: "",
    descriptionHtml: "",
    otherTicketData: [],
    status: "",
    customerMail: "",
    personelName: "",
    guid: "",
    createdAt: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ]
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

      this.getList();
  }


  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    URLParam =
      "/otherTickets/getcustomerticketlist/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {       
          this.setState({
            otherTicketData: response.data,
            isLoading: false,
          });              
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };


  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidOtherTicket", this.state.customerGuid);
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  thStyle = {
    width: "90px",
  };

  thStyle2 = {
    width: "100px",
  };

  tdStyle = {
    width: "300px",
  };

  tdStyle2 = {
    width: "300px",
  };

  render() {

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col lg="11" className="pl-2 pr-2">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col lg="1" className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.otherTicketData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Diğer İşlemler Ticket Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>                          
                            <tr>
                              <th style={this.thStyle}>Tarihi</th>
                              <td style={this.tdStyle}>{row.createdAt}</td>
                              <th style={this.thStyle2}>
                                Müşteri
                              </th>
                              <td>{row.customerName}</td>
                            </tr>                          
                          </tbody>
                        </Table>
                      );
                    },
                  },               
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.customerGuid}/${row.customerDocumentOtherGuid}`,
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default OtherTicketDocumentList;