import React from "react";
import { Row, Col } from "reactstrap";
import CgnButtonLinkGroup from "../toolbox/CgnButtonLinkGroup";
import CgnBreadcrumb from "../toolbox/CgnBreadcrumb";
import CgnMessage from "../toolbox/CgnMessage";


const ContentTop = ({ breadcrumb, title, addLink, listLink, excelLink, collectionLink, paymentLink, collectionMailLink, noteLink, printLink, emailLink ,onClick }) => {
  const buttons = [
    { onClick: printLink, text: CgnMessage.printButton, color: "secondary"},
    { onClick: emailLink, text: CgnMessage.sendMailTitle, color: "primary"},
    { onClick: collectionMailLink, text: CgnMessage.collectionMailButton, color: "danger"},
    { onClick: collectionLink, text: CgnMessage.collectionButton, color: "success"},
    { onClick: paymentLink, text: CgnMessage.paymentButton, color: "success"},
    { to: addLink, text: CgnMessage.addButton, color: "success" },
    { to: listLink, text: CgnMessage.listButton, color: "primary" },
    { onClick: excelLink, text: CgnMessage.excelButton, color: "success"},
    { onClick: noteLink, text: CgnMessage.noteButton, color: "success"},
  ];

  return (
    <div className="contentTop">
      <Row>
        <Col>
          {title && (
          <h1>{title}</h1>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          {title && breadcrumb && (
          <CgnBreadcrumb items={breadcrumb} title={title} />
          )}
        </Col>
        <Col md="6">
          <CgnButtonLinkGroup items={buttons} />
        </Col>
      </Row>
    </div>
  );
};

export default ContentTop;
