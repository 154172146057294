import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal, ModalHeader
} from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  localFilePath,
  fotoStyle2,
  fotoStyle,
} from "../../redux/actions/constants";
import pdfIcon from "../../images/pdfIcon.png";
import CgnMessage from "../toolbox/CgnMessage";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";


class CustomerProductTicketDocumentDetail extends Component {
  state = {
    pageTitle: "Ürün Kaydı Belgeleri Detay",
    pageTitleDetail: "Ürün Kaydı Belgeleri Detay",
    pageListLink: "musteri-urun-ticket-belgeleri-listele",
    breadcrumb: [{ text: "Ürün Kaydı Belgeleri Detay", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    customerProductTicketImageDto: [],
    customerProductDocumentGuid: "",
    customerTicket2Dto: [],
    barcodeDocumentDto: [],
    gtipDocumentDto: [],
    description: "",
    barcodeProductImageDto: [],
    descriptionError: "",
    descriptionHtml: "",
    status: "",
    productName: "",
    productImage: "",
    barcode: "",
    tag: "",
    gtipNo: "",
    customerMail: "",
    customerGuid: "",
    personelName: "",
    guid: "",
    createdAt: "",
    isLoadingModal: false,
    isHiddenSuitable: true,
    isHiddenUnSuitable: true, 
    isOpenModalPayment: false,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ]
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
      this.setState({
        isLoading: true,
      });
      this.state.customerGuid = urlSplit[2];
      this.state.customerProductDocumentGuid = urlSplit[3];
      axios
        .get(URL + "/customerProductTickets/getlist/" +  this.state.customerGuid + "/" +  this.state.customerProductDocumentGuid)
        .then((response) => {
          this.setState({
            customerGuid: response.data.customerGuid,
            customerName: response.data.customerName,
            description: this.state.description,
            createdAt: response.data.createdAt,
            customerTicket2Dto: response.data.customerTicket2Dto,
            customerProductDocumentGuid: response.data.customerProductDocumentGuid,
            customerProductTicketImageDto: response.data.customerProductTicketImageDto,
            productName: response.data.productName,
            productImage: response.data.productImage,
            barcode: response.data.barcode,
            gtipNo: response.data.gtipNo,
            tag: response.data.tag,
            barcodeDocumentDto: response.data.barcodeDocumentDto,
            gtipDocumentDto: response.data.gtipDocumentDto,
            barcodeProductImageDto: response.data.barcodeProductImageDto,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggle1 = () => {
    this.setState({
      isOpen1: !this.state.isOpen1,
    });
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };

    var self1 = this;
    var self = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="p-3">
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              <Table> 
                  <tr className="">
                        <th>Müşteri</th>
                        <td>{this.state.customerName}</td>                       
                      </tr>    
                   <tr className="">
                        <th>Ürün Adı</th>
                        <td>{this.state.productName}</td>                       
                      </tr>    
                    
                      <tr className="">
                        <th>Barkod</th>
                        <td style={{textAlign:"left"}}>{this.state.barcode}</td>                       
                      </tr>       
                      <tr>
                    <th>Etiket</th>
                    <td colSpan="4">
                      {this.state.tag ? (
                        <Button
                          onClick={this.toggle1}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.tag}
                            style={fotoStyle}
                            alt={this.state.productName}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.productName}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal isOpen={this.state.isOpen1} toggle={this.toggle1}>
                    <ModalHeader toggle={this.toggle1}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.tag}
                      style={fotoStyle}
                      alt={this.state.productName}
                    />
                  </Modal> 
                      <tr className="">
                        <th>GTİP Numarası</th>
                        <td style={{textAlign:"left"}}>{this.state.gtipNo}</td>                       
                      </tr>  
              </Table>


              {this.state.barcodeProductImageDto !== null &&
                this.state.barcodeProductImageDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Ürün Fotoğrafları</th>
                      </tr>
                      <Row>
                        {this.state.barcodeProductImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}       

              {this.state.barcodeDocumentDto &&
                this.state.barcodeDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Barkod Dosyaları</th>
                    </tr>
                    <Row>
                      {this.state.barcodeDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={excelIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}      

             {this.state.gtipDocumentDto &&
                this.state.gtipDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Gtip Numara Dosyaları</th>
                    </tr>
                    <Row>
                      {this.state.gtipDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={excelIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}      

              {this.state.customerTicket2Dto !== null &&
                this.state.customerTicket2Dto.length > 0 &&
                this.state.customerTicket2Dto.map(function (item) {
                  return (
                    <>
           <Table>
              {item.customerProductTicketImageDto &&
                 item.customerProductTicketImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Ürün Ticket Sisteminden Eklenen Belgeler</th>
                    </tr>
                    <Row>
                      {item.customerProductTicketImageDto.map(function (item1) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item1.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item1.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "170px" }}>
                                {item1.imageName} - {item1.createdAt}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
               </Table>
               </>
                 );
              })}

            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerProductTicketDocumentDetail;