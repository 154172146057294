import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";

class DimensionalTemplateAddUpdate extends Component {
  state = {
    pageTitle: "Boyutsal Ölçüm Şablon Ekle",
    pageTitleAdd: "Boyutsal Ölçüm Şablon Ekle",
    pageTitleUpdate: "Boyutsal Ölçüm Şablon Güncelle",
    pageAddLink: "boyutsal-olcum-sablon-ekle",
    pageListLink: "boyutsal-olcum-sablon-listele",
    breadcrumb: [{ text: "Boyutsal Ölçüm Şablon", link: "#" }],
    isLoading: false,
    urlSplit: [],
    measurement: "",
    documents: "",
    tolerance: "",
    measurementError: "",
    documentsError: "",
    toleranceError: "",
    guid: "",
    color: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/dimensionalTemplates/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              documents: response.data.documents,
              measurement: response.data.measurement,
              tolerance: response.data.tolerance,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      documentsError: "",
      toleranceError: "",
      alertErrorMessage: "",
    });
    if (name === "documents" && value === "") {
      this.setState({
        documentsError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "tolerance" && value === "") {
      this.setState({
        toleranceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      measurement: "",
      tolerance: "",
      documents: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      documentsError: CgnMessage.textErrorMessage,
      toleranceError: CgnMessage.textErrorMessage,
      measurementError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }
  
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  handleMeasurementTextBoxChange(e) {
    this.setState({
      measurement: parseFloat(e.target.value),
      measurementError: "",
      alertErrorMessage: "",
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      measurementError: "",
      toleranceError: "",
      documentsError: "",
    });
    if (this.state.documents === "") {
      this.setState({
        documentsError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.measurement === "") {
      this.setState({
        measurementError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.tolerance === "") {
      this.setState({
        toleranceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
     if (this.state.documents !== "" &&
     this.state.measurement !== "" &&
     this.state.tolerance !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        documents: this.state.documents,
        measurement: parseFloat(this.state.measurement),
        tolerance: this.state.tolerance,
        status: this.state.status,
      };
      var URLParam = "/dimensionalTemplates/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "boyutsal-olcum-sablon-guncelle"
      ) {
        URLParam = "/dimensionalTemplates/update";
        myObj = {
          guid: this.state.guid,
          documents: this.state.documents,
          measurement: parseFloat(this.state.measurement),
          tolerance: this.state.tolerance,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "boyutsal-olcum-sablon-guncelle"
            ) {
              window.location.href = "/boyutsal-olcum-sablon-listele";
            }
            if (this.state.urlSplit[1] === "boyutsal-olcum-sablon-ekle") {
              window.location.reload();
            }          
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "boyutsal-olcum-sablon-ekle" ||
          this.state.urlSplit[1] === "boyutsal-olcum-sablon-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="documents"
                      label="Dökümandaki Ref. Bölge [*]"
                      value={this.state.documents}
                      placeHolder="Lütfen dökümandaki ref. bölge girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.documentsError}
                    />
                  </Col>
                  <Col lg="4">
                  <CgnTextbox
                      name="measurement"
                      label="Gerek/Ölçü (cm)"
                      isMulti={false}
                      value={this.state.measurement}
                      placeHolder="Lütfen gerek/ölçü (cm) girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleMeasurementTextBoxChange(e)}
                      error={this.state.measurementError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="tolerance"
                      label="Tolerans [*]"
                      value={this.state.tolerance}
                      placeHolder="Lütfen tolerans girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.toleranceError}
                    />
                  </Col>                  
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "boyutsal-olcum-sablon-ekle" ||
              this.state.urlSplit[1] === "boyutsal-olcum-sablon-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(DimensionalTemplateAddUpdate);
