import React, { Component } from "react";
import { Row, Col, Card, Table, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL, localFilePath, fotoStyle2 } from "../../redux/actions/constants";
import fileIcon from "../../images/file.jpg";
class DocumentTrackingDetail extends Component {
    state = {
        pageTitle: "Belge Takip Detay",
        pageTitleDetail: "Belge Takip Detay",
        pageAddLink: "belge-takip-ekle",
        pageListLink: "belge-takip-listele",
        breadcrumb: [{ text: "Belge Takip", link: "#" }],
        isLoading: false,
        alertErrorMessage: "",
        guid: "",
        language: 0,
        customerName: "",
        documentTypeName: "",
        documentTypeNote: "",
        isUnlimitedTime: false,
        validityDate: "",
        isUTSRecord: false,
        documentNumber: "",
        utsRecordDate: "",
        note: "",
        opinions: "",
        documentTrackingFilesDto: [],
        isReminderEMail: true,
        remindCount: 0,
        eMails: "",
        status: true,
        createdBy: "",
        createdAt: "",
        updatedAt: "",
        updatedBy: "",
        plannedClosingDate: "",
        affectedDevice: "",
    };
    async componentDidMount() {
        let urlSplit = window.location.pathname.split("/");
        if (urlSplit.length === 3) {
            this.setState({
                pageTitle: this.state.pageTitleDetail,
                isLoading: true,
            });
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .get(URL + "/documenttracking/get/" + urlSplit[2], config)
                .then((response) => {
                    this.setState({
                        language: response.data.language,
                        customerName: response.data.customerName,
                        documentTypeName: response.data.documentTypeName,
                        documentTypeNote: response.data.documentTypeNote,
                        isUnlimitedTime: response.data.isUnlimitedTime,
                        validityDate: response.data.validityDate,
                        isUTSRecord: response.data.isUTSRecord,
                        documentNumber: response.data.documentNumber,
                        utsRecordDate: response.data.utsRecordDate,
                        note: response.data.note,
                        opinions: response.data.opinions,
                        documentTrackingFilesDto: response.data.documentTrackingFilesDto,
                        isReminderEMail: response.data.isReminderEMail,
                        remindCount: response.data.remindCount,
                        eMails: response.data.eMails,
                        status: response.data.status,
                        createdBy: response.data.createdBy,
                        createdAt: response.data.createdAt,
                        updatedBy: response.data.updatedBy,
                        updatedAt: response.data.updatedAt,
                        isLoading: false,
                        plannedClosingDate: response.data.plannedClosingDate,
                        affectedDevice: response.data.affectedDevice,
                    });
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        }
    }
    fileClick = (image) => {
        let urlWebSite = "http://sys.cogenteknoloji.com";
        let urlSplit = window.location.hostname;
        if (urlSplit === "crm.cevirikalitesi.com") {
            urlWebSite = "https://www.cevirikalitesi.com";
        }

        var myData = image;
        let replaceLink = myData.replace(localFilePath + "/Files/", "");
        if (replaceLink) {
            window.open(urlWebSite + "/Files/" + myData, "_blank");
            this.setState({
                isLoadingParent: false,
            });
        }
    };
    render() {
        var self1 = this;
        const mainRow = {
            backgroundColor: "#1d4b8c",
            color: "#fff",
        };
        return (
            <>
                <ContentTop
                    breadcrumb={this.state.breadcrumb}
                    title={this.state.pageTitle}
                    addLink={"/" + this.state.pageAddLink}
                    listLink={"/" + this.state.pageListLink}
                />
                <Row>
                    <Col>
                        <Card body className="table-responsive">
                            <CgnLoader visibled={this.state.isLoading} />
                            {this.state.alertErrorMessage && (
                                <CgnAlert
                                    color="danger"
                                    title="Uyarı"
                                    text={this.state.alertErrorMessage}
                                />
                            )}
                            <Table className="table table-responsive table-hover">
                                <tbody>
                                    <tr>
                                        <th>Dil</th>
                                        <td colSpan="4">
                                            {this.state.language ? (this.state.language === 1 ? "Türkçe" : "İngilizce") : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Müşteri</th>
                                        <td colSpan="4">
                                            {this.state.customerName ? this.state.customerName : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Belge Türü</th>
                                        <td colSpan="4">
                                            {this.state.documentTypeName ? this.state.documentTypeName : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Açıklama</th>
                                        <td colSpan="4">
                                            {this.state.documentTypeNote ? this.state.documentTypeNote : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Belge Sınırsız Süreyle Geçerli Midir?</th>
                                        <td colSpan="4">
                                            {this.state.isUnlimitedTime === true ? "Evet" : "Hayır"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Belgenin Geçerlik Tarihi</th>
                                        <td colSpan="4">
                                            {!this.state.isUnlimitedTime && this.state.validityDate && this.state.validityDate !== "01/01/1900" ? this.state.validityDate : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Belge ÜTS’de Kayıtlı Mı?</th>
                                        <td colSpan="4">
                                            {this.state.isUTSRecord === true ? "Evet" : "Hayır"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>ÜTS Belge Numarası</th>
                                        <td colSpan="4">
                                            {this.state.isUTSRecord && this.state.documentNumber ? this.state.documentNumber : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>ÜTS Kayıt Tarihi</th>
                                        <td colSpan="4">
                                            {this.state.isUTSRecord && this.state.utsRecordDate && this.state.utsRecordDate !== "01/01/1900" ? this.state.utsRecordDate : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Planlı Kapama Tarihi</th>
                                        <td colSpan="4">
                                            {this.state.plannedClosingDate && this.state.plannedClosingDate !== "01/01/1900" ? this.state.plannedClosingDate : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Etkilenen Cihaz</th>
                                        <td colSpan="4">
                                            {this.state.affectedDevice ? this.state.affectedDevice : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Not</th>
                                        <td colSpan="4">
                                            {this.state.note ? this.state.note : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Görüşler</th>
                                        <td colSpan="4">
                                            {this.state.opinions ? this.state.opinions : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Müşteriye Hatırlatma E-Maili Gönderilsin Mi?</th>
                                        <td colSpan="4">
                                            {this.state.isReminderEMail === true ? "Evet" : "Hayır"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Müşteriye Hatırlatma Sayısı</th>
                                        <td colSpan="4">
                                            {this.state.remindCount ? this.state.remindCount : 0}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>E-Mailler</th>
                                        <td colSpan="4">{this.state.eMails ? this.state.eMails : "-"}</td>
                                    </tr>
                                    <tr>
                                        <th>Durumu</th>
                                        <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                                    </tr>
                                    <tr>
                                        <th>Kaydeden Kullanıcı</th>
                                        <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                                        <th>Kaydedilme Tarihi</th>
                                        <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                                    </tr>
                                    <tr>
                                        <th>Son Güncelleyen Kullanıcı</th>
                                        <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                                        <th>Son Güncellenme Tarihi</th>
                                        <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            {this.state.documentTrackingFilesDto &&
                                this.state.documentTrackingFilesDto.length > 0 && (
                                    <Table className="table table-responsive table-hover table-product2">
                                        <tr style={mainRow}>
                                            <th>Belgeler</th>
                                        </tr>
                                        <Row>
                                            {this.state.documentTrackingFilesDto.map(function (item) {
                                                return (
                                                    <>
                                                        <div className="pl-4">
                                                            <Button
                                                                onClick={() => self1.fileClick(item.image)}
                                                                className="modalButtonDetail"
                                                            >
                                                                <img
                                                                    src={fileIcon}
                                                                    style={fotoStyle2}
                                                                    alt={item.imageName}
                                                                />
                                                            </Button>
                                                            <p style={{ maxWidth: "150px" }}>
                                                                {item.imageName}
                                                            </p>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Table>
                                )}
                        </Card>
                        <CgnButtonLink
                            color="secondary"
                            to={"/" + this.state.pageListLink}
                            text="Geri"
                            className="btn-back"
                        />
                    </Col>
                </Row>
            </>
        );
    }
}
export default DocumentTrackingDetail;