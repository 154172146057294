import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { URL, GetMonthFirstDate, GetMonthLastDate, GetMonthFirstDate2, GetMonthLastDate2 } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";
class DocumentTrackingList extends Component {
    state = {
        pageTitle: "Belge Takip Listele",
        pageAddLink: "belge-takip-ekle",
        pageUpdateLink: "belge-takip-guncelle",
        pageDetailLink: "belge-takip-detay",
        pageListLink: "belge-takip-listele",
        breadcrumb: [{ text: "Belge Takip", link: "#" }],
        isLoading: false,
        isDeleted: false,
        status: false,
        data: [],
        name: "",
        guid: "",
        alertErrorMessage: "",
        firstDate: "",
        firstDate2: "",
        lastDate: "",
        lastDate2: "",
        customerGuid: "00000000-0000-0000-0000-000000000000",
        customerArr: [],
        documentTypeGuid: "00000000-0000-0000-0000-000000000000",
        documentTypeArr: [],
    };
    async componentDidMount() {
        this.setState({
            isLoading: true,
        });
        var firstDate = GetMonthFirstDate();
        var lastDate = GetMonthLastDate();
        var firstDate2 = GetMonthFirstDate2();
        var lastDate2 = GetMonthLastDate2();
        if (localStorage.getItem("firstDateOffer")) {
            firstDate = localStorage.getItem("firstDateOffer");
            firstDate2 = new Date(firstDate);
        }
        if (localStorage.getItem("lastDateOffer")) {
            lastDate = localStorage.getItem("lastDateOffer");
            lastDate2 = new Date(lastDate);
        }
        await this.setState({
            firstDate: firstDate,
            lastDate: lastDate,
            firstDate2: firstDate2,
            lastDate2: lastDate2,
        });
        // 
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        var statusType = 2; // sadece aktif olanları göstermesi için
        await axios
            .get(URL + "/customers/getlist/" + statusType, config)
            .then((response) => {
                var myArr = response.data.map(function (item) {
                    return {
                        value: item.guid,
                        label: item.name,
                        customerGuid: item.customerGuid,
                    };
                });
                this.setState({
                    customerArr: myArr,
                });
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
        axios
            .get(URL + "/documentTypes/getlist/" + statusType, config)
            .then((response) => {
                var myArr = response.data.map(function (item) {
                    return {
                        value: item.guid,
                        label: item.name,
                    };
                });
                myArr.push({
                    value: "4f66b9fa-33c8-43ec-930e-ef7dc8582055",
                    label: "Diğer",
                });
                this.setState({
                    documentTypeArr: myArr,
                });
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
        this.getList();
    }
    getList = async () => {
        this.setState({
            isLoading: true,
            alertErrorMessage: "",
        });
        // 
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        axios
            .get(URL + "/documenttracking/getlist/" +
                this.state.firstDate +
                "/" +
                this.state.lastDate +
                "/" +
                this.state.customerGuid +
                "/" +
                this.state.documentTypeGuid, config)
            .then((response) => {
                this.setState({
                    data: response.data,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
    };
    handleDelete = (guid) => {
        confirmAlert({
            title: CgnMessage.deleteQuestionTitle,
            message: CgnMessage.deleteQuestionMessage,
            buttons: [
                {
                    label: CgnMessage.deleteQuestionYes,
                    onClick: () => this.deleteItem(guid),
                },
                {
                    label: CgnMessage.deleteQuestionNo,
                },
            ],
        });
    };
    async deleteItem(guid) {
        this.setState({
            isLoading: true,
        });
        // 
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        const URLParam = "/documenttracking/delete";
        const obj = {
            guid,
        };
        axios
            .post(URL + URLParam, obj, config)
            .then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
    }
    async handleFirstDateChange(firstDate) {
        if (firstDate) {
            await this.setState({
                firstDate:
                    firstDate.getMonth() +
                    1 +
                    "." +
                    firstDate.getDate() +
                    "." +
                    firstDate.getFullYear(),
                firstDate2: firstDate,
            });
        } else {
            firstDate = GetMonthFirstDate();
            await this.setState({
                firstDate: firstDate,
                firstDate2: firstDate,
            });
        }
        localStorage.setItem("firstDateOffer", this.state.firstDate);
    }

    async handleLastDateChange(lastDate) {
        if (lastDate) {
            await this.setState({
                lastDate:
                    lastDate.getMonth() +
                    1 +
                    "." +
                    lastDate.getDate() +
                    "." +
                    lastDate.getFullYear(),
                lastDate2: lastDate,
            });
        } else {
            lastDate = GetMonthLastDate();
            await this.setState({
                lastDate: lastDate,
                lastDate2: lastDate,
            });
        }
        localStorage.setItem("lastDateOffer", this.state.lastDate);
    }

    async handleCustomerGuidSelectChange(option) {
        if (option) {
            await this.setState({
                customerGuid: option.value,
            });
        } else {
            await this.setState({
                customerGuid: "00000000-0000-0000-0000-000000000000",
            });
        }
        localStorage.setItem("customerGuidDocumentTracking", this.state.customerGuid);
    }
    async handleDocumentTypeGuidSelectChange(option) {
        if (option) {
            await this.setState({
                documentTypeGuid: option.value,
            });
        } else {
            await this.setState({
                documentTypeGuid: "00000000-0000-0000-0000-000000000000",
            });
        }
        localStorage.setItem("documentTypeGuidDocumentTracking", this.state.documentTypeGuid);
    }
    thStyle = {
        width: "150px",
    };
    tdStyle = {
        width: "175px",
    };
    render() {
        return (
            <>
                <ContentTop
                    breadcrumb={this.state.breadcrumb}
                    title={this.state.pageTitle}
                    addLink={"/" + this.state.pageAddLink}
                    listLink={"/" + this.state.pageListLink}
                />
                <Row className="DocumentTrackingList" id="list">
                    <Col>
                        <Card body>
                            <CgnLoader visibled={this.state.isLoading} />
                            {this.state.alertErrorMessage && (
                                <CgnAlert
                                    color="danger"
                                    title={CgnMessage.alertErrorTitle}
                                    text={this.state.alertErrorMessage}
                                />
                            )}
                            <Row className="filterRow">
                                <Col className="pl-2 pr-2" md="2">
                                    <CgnDatePicker
                                        name="firstDate"
                                        label="Baş. Tarihi"
                                        selected={
                                            this.state.firstDate2 &&
                                                this.state.firstDate2.toString().indexOf("/") > 0
                                                ? new Date(
                                                    this.state.firstDate2.split("/")[2] +
                                                    "-" +
                                                    this.state.firstDate2.split("/")[1] +
                                                    "-" +
                                                    this.state.firstDate2.split("/")[0]
                                                )
                                                : this.state.firstDate2
                                        }
                                        onChange={(firstDate2) =>
                                            this.handleFirstDateChange(firstDate2)
                                        }
                                        maxDate={new Date(2100, 1, 1)}
                                        minDate={new Date(1900, 1, 1)}
                                        placeholder="GG/AA/YYYY"
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col className="pl-2 pr-2" md="2">
                                    <CgnDatePicker
                                        name="lastDate"
                                        label="Bit. Tarihi"
                                        selected={
                                            this.state.lastDate2 &&
                                                this.state.lastDate2.toString().indexOf("/") > 0
                                                ? new Date(
                                                    this.state.lastDate2.split("/")[2] +
                                                    "-" +
                                                    this.state.lastDate2.split("/")[1] +
                                                    "-" +
                                                    this.state.lastDate2.split("/")[0]
                                                )
                                                : this.state.lastDate2
                                        }
                                        onChange={(lastDate2) =>
                                            this.handleLastDateChange(lastDate2)
                                        }
                                        maxDate={new Date(2100, 1, 1)}
                                        minDate={new Date(1900, 1, 1)}
                                        placeholder="GG/AA/YYYY"
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col className="pl-2 pr-2" md="4">
                                    <CgnReactSelect
                                        name="customerGuid"
                                        label="Müşteri"
                                        placeholder="Müşteri seçiniz..."
                                        selectValue={this.state.customerGuid}
                                        isMulti={false}
                                        isClearable={true}
                                        onChange={(option) =>
                                            this.handleCustomerGuidSelectChange(option)
                                        }
                                        options={this.state.customerArr}
                                    />
                                </Col>
                                <Col className="pl-2 pr-2" md="3">
                                    <CgnReactSelect
                                        name="documentTypeGuid"
                                        label="Belge Türü"
                                        placeholder="Belge türü seçiniz..."
                                        selectValue={this.state.documentTypeGuid}
                                        isMulti={false}
                                        isClearable={true}
                                        onChange={(option) =>
                                            this.handleDocumentTypeGuidSelectChange(option)
                                        }
                                        options={this.state.documentTypeArr}
                                    />
                                </Col>
                                <Col className="pl-2 pr-0" md="1">
                                    <CgnButton
                                        type="button"
                                        color="primary"
                                        onClick={this.getList}
                                        text="Listele"
                                        className="listButton"
                                    />
                                </Col>
                            </Row>
                            <CgnDatatable
                                data={this.state.data}
                                title={this.state.pageTitle}
                                columns={[
                                    {
                                        name: "Belge Takip Bilgileri",
                                        sortable: false,
                                        cell: (row) => {
                                            return (
                                                <Table className="table table-responsive ">
                                                    <tr>
                                                        <th style={this.thStyle}>Dil</th>
                                                        <td style={this.tdStyle}>{row.language === 1 ? "Türkçe" : "İngilizce"}</td>
                                                        <th style={this.thStyle}>Belge Türü</th>
                                                        <td>{row.documentTypeName}</td>
                                                    </tr>
                                                    {row.documentNumber !== null && row.documentNumber !== "" &&
                                                        <tr>
                                                            <th style={this.thStyle}>ÜTS Belge Numarası </th>
                                                            <td colSpan={3}>{row.documentNumber}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <th style={this.thStyle}>Müşteri</th>
                                                        <td colSpan={3}>{row.customerName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={this.thStyle}>Tarih</th>
                                                        <td>{row.createdAt}</td>
                                                        <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                                                        <td>{row.createdBy}</td>
                                                    </tr>
                                                </Table>
                                            );
                                        },
                                    },
                                    {
                                        name: "Hatırlatma",
                                        selector: "isReminderEMail",
                                        width: "110px",
                                        padding: "0px",
                                        sortable: false,
                                        cell: (row) => {
                                            if (row.isReminderEMail) {
                                                return <CgnBadge color="success" text="Evet" />;
                                            } else {
                                                return <CgnBadge color="danger " text="Hayır" />;
                                            }
                                        },
                                    },
                                    {
                                        selector: "status",
                                        width: "110px",
                                        padding: "0px",
                                        sortable: false,
                                        cell: (row) => {
                                            if (row.status) {
                                                return <CgnBadge color="success" text="Aktif" />;
                                            } else {
                                                return <CgnBadge color="danger " text="Pasif" />;
                                            }
                                        },
                                    },
                                    {
                                        ignoreRowClick: true,
                                        allowOverflow: true,
                                        button: true,
                                        cell: (row, cell) => (
                                            <CgnButtonDropdown
                                                label={"Yönet"}
                                                className="mr-0"
                                                color="primary"
                                                items={[
                                                    {
                                                        text: CgnMessage.updateButton,
                                                        className: "dropDownBtn",
                                                        href: `/${this.state.pageUpdateLink}/${row.guid}`,
                                                    },
                                                    {
                                                        text: CgnMessage.detailButton,
                                                        className: "dropDownBtn",
                                                        href: `/${this.state.pageDetailLink}/${row.guid}`,
                                                    },
                                                    {
                                                        text: CgnMessage.deleteButton,
                                                        className: "dropDownBtn",
                                                        onClick: () => this.handleDelete(row.guid),
                                                    },
                                                ]}
                                            />
                                        ),
                                    },
                                ]}
                                loading={this.state.isLoading}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}
export default DocumentTrackingList;