import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, fotoStyle2,  localFilePath } from "../../redux/actions/constants";
import pdfIcon from "../../images/pdfIcon.png";

class SuitabilityFormDetail extends Component {
  state = {
    pageTitle: "Uygunluk Formu Detay",
    pageTitleDetail: "Uygunluk Formu Detay",
    pageAddLink: "uygunluk-formu-ekle",
    pageListLink: "uygunluk-formu-listele",
    breadcrumb: [{ text: "Uygunluk Formu", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    productName: "",
    customerName: "",
    productOutName: "",
    currencyType: "",
    workInstructionCode: "",
    suitabilityDate: "",
    suitabilityNo: "",
    dispatchPiece: "",
    serialNo: "",
    classAndCategory: "",
    electricalControl: "",
    observationControl: "",
    packagingControl: "",
    description: "",
    opticalControl: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    workDate: "",
    productOutDate: "",
    productOutCustomer: "",
    productOutTotal: "",
    customerDocumentDto: [],
    supplierDocumentDto: [],
    suitabilityDocumentDto: [],
   };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/suitabilityForms/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            workInstructionCode: response.data.workInstructionCode,
            workDate: response.data.workDate,
            customerName: response.data.customerName,
            productCode: response.data.productCode,
            productName: response.data.productName,
            productOutCustomer: response.data.productOutCustomer,
            productOutDate: response.data.productOutDate,
            productOutTotal: response.data.productOutTotal,
            suitabilityDate: response.data.suitabilityDate,
            suitabilityNo: response.data.suitabilityNo,
            dispatchPiece: response.data.dispatchPiece,
            serialNo: response.data.serialNo,
            classAndCategory: response.data.classAndCategory,
            observationControl: response.data.observationControl,
            electricalControl: response.data.electricalControl,
            opticalControl: response.data.opticalControl,
            packagingControl: response.data.packagingControl,
            description: response.data.description,
            customerDocumentDto: response.data.customerDocumentDto,
            supplierDocumentDto: response.data.supplierDocumentDto,
            suitabilityDocumentDto: response.data.suitabilityDocumentDto,
            currencyType: response.data.currencyType,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/suitabilityForms/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath, "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;
    var self1 = this;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          printLink={onClick => this.handlePdfPrint(this.state.guid)}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <td colSpan="4">
                      {this.state.suitabilityDate}
                    </td>
                  </tr>
                  <tr>
                    <th>Kalite Uygunluk Numarası</th>
                    <td colSpan="4"> {this.state.suitabilityNo}</td>
                  </tr>                
                  <tr>
                    <th>İş Emri Numarası</th>
                    <td colSpan="4">{this.state.workInstructionCode}</td>
                  </tr>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">{this.state.customerName}</td>
                  </tr>
                  <tr>
                    <th>Satış</th>
                    <td colSpan="4">{this.state.productOutDate + "-" + this.state.productOutCustomer}</td>
                  </tr>

                  <tr>
                    <th>Sevk Edilecek Ürün Miktarı</th>
                    <td colSpan="4">{this.state.dispatchPiece}</td>
                  </tr>

                  <tr>
                    <th>Seri Numarası</th>
                    <td colSpan="4">{this.state.serialNo === "" ? "-" : this.state.serialNo}</td>
                  </tr>

                  <tr>
                    <th>Sınıf ve Kategorisi</th>
                    <td colSpan="4">{this.state.classAndCategory === "" ? "-" : this.state.classAndCategory}</td>
                  </tr>

                  <tr>
                    <th>Gözlem Denetimi</th>
                    <td colSpan="4">{this.state.observationControl === "" ? "-" : this.state.observationControl}</td>
                  </tr>

                  <tr>
                    <th>Elektriksel Denetim</th>
                    <td colSpan="4">{this.state.electricalControl === "" ? "-" : this.state.electricalControl}</td>
                  </tr>

                  <tr>
                    <th>Boyutsal-Optik-Mekanik Ölçümler</th>
                    <td colSpan="4">{this.state.opticalControl === "" ? "-" : this.state.opticalControl}</td>
                  </tr>

                  <tr>
                    <th>Ambalaj,Paketleme,Taşıma,Nakliye ve Depolama</th>
                    <td colSpan="4">{this.state.packagingControl === "" ? "-" : this.state.packagingControl}</td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">{this.state.description === "" ? "-" : this.state.description}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              <Row>
                {this.state.suitabilityDocumentDto &&
                this.state.suitabilityDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Atıf Yapılan Standart Kural veya Dökümanlar</th>
                    </tr>
                    <Row>
                      {this.state.suitabilityDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
                </Row>
                <Row>
                {this.state.supplierDocumentDto &&
                this.state.supplierDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Farklı Tedarikçiden Alınan Uygunluk Belgeleri</th>
                    </tr>
                    <Row>
                      {this.state.supplierDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
                </Row>

                <Row>
                {this.state.customerDocumentDto &&
                this.state.customerDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Müşteri Tarafından Gelen (D.F) ve Yapılan İşlemler</th>
                    </tr>
                    <Row>
                      {this.state.customerDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
                </Row>

           </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default SuitabilityFormDetail;
