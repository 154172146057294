import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";

class CustomerList extends Component {
  state = {
    pageTitle: "Müşteri Listele",
    pageAddLink: "musteri-ekle",
    pageUpdateLink: "musteri-guncelle",
    pageDetailLink: "musteri-detay",
    pageListLink: "musteri-listele",
    breadcrumb: [{ text: "Müşteri", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    customerData: [],
    isCityTrue: true,
    isDistrictTrue: true,
    isTownTrue: true,
    labelIsTurkey: false,
    name: "",
    eMail: "",
    phone: "",
    guid: "",
    alertErrorMessage: "",
    districtGuid: "00000000-0000-0000-0000-000000000000",
    townGuid: "00000000-0000-0000-0000-000000000000",
    cityGuid: "00000000-0000-0000-0000-000000000000",
    countryGuid: "00000000-0000-0000-0000-000000000000",
    districtArr: [],
    townArr: [],
    cityArr: [],
    countryArr: [],
    customerGroupArr: [],
    customerGroupGuid: "00000000-0000-0000-0000-000000000000",
    customerGroupError: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/countries/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          countryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/customergroups/getlist/1", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerGroupArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var statusType = 1;
    URLParam =
      "/customers/getlistcustomer/" +
      statusType +
      "/" +
      this.state.countryGuid +
      "/" +
      this.state.cityGuid +
      "/" +
      this.state.townGuid +
      "/" +
      this.state.districtGuid +
      "/" +
      this.state.customerGroupGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          customerData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async handleCustomerGroupSelectChange(option) {
    if (option) {
      await this.setState({
        customerGroupGuid: option.value,
        customerGroupError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGroupGuid: "00000000-0000-0000-0000-000000000000",
        customerGroupError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleCountrySelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      await this.setState({
        countryGuid: option.value,
        isCityTrue: false,
        countryError: "",
        alertErrorMessage: "",
      });
      if (option.label === "TÜRKİYE") {
        this.setState({
          labelIsTurkey: true,
        });
      }
      if (option.label !== "TÜRKİYE") {
        this.setState({
          isCityTrue: true,
          isTownTrue: true,
          isDistrictTrue: true,
          labelIsTurkey: false,
          isLoading: false,
          cityGuid: "00000000-0000-0000-0000-000000000000",
          townGuid: "00000000-0000-0000-0000-000000000000",
          districtGuid: "00000000-0000-0000-0000-000000000000",
          cityError: "",
          townError: "",
          districtError: "",
        });
      }
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/cities/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            cityArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        countryGuid: "",
        isCityTrue: true,
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        cityGuid: "00000000-0000-0000-0000-000000000000",
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        cityError: "",
        townError: "",
        districtError: "",
        countryError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleCitySelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      await this.setState({
        cityGuid: option.value,
        isTownTrue: false,
        cityError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/towns/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            townArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        cityGuid: "",
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        townError: "",
        districtError: "",
        cityError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleTownSelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      this.setState({
        townGuid: option.value,
        isDistrictTrue: false,
        townError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/districts/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            districtArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        townGuid: "",
        isDistrictTrue: true,
        districtGuid: "00000000-0000-0000-0000-000000000000",
        districtError: "",
        isLoading: false,
        townError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleDistrictSelectChange(option) {
    if (option) {
      this.setState({
        districtGuid: option.value,
        districtError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        districtGuid: "00000000-0000-0000-0000-000000000000",
        districtError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }


  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/customers/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  thStyle = {
    width: "150px",
  };

  thStyle2 = {
    width: "150px",
  };

  tdStyle = {
    width: "5 00px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="customerList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="customerGroupGuid"
                    label="Bölge"
                    placeholder="Lütfen bölge seçiniz..."
                    selectValue={this.state.customerGroupGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerGroupSelectChange(option)
                    }
                    options={this.state.customerGroupArr}
                    error={this.state.customerGroupError}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="countryGuid"
                    label="Ülke"
                    placeholder="Lütfen ülke seçiniz..."
                    selectValue={this.state.countryGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCountrySelectChange(option)
                    }
                    options={this.state.countryArr}
                    error={this.state.countryError}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="cityGuid"
                    label="Şehir"
                    placeholder="Lütfen şehir seçiniz..."
                    selectValue={this.state.cityGuid}
                    isMulti={false}
                    isClearable={true}
                    disabled={this.state.isCityTrue}
                    onChange={(option) => this.handleCitySelectChange(option)}
                    options={this.state.cityArr}
                    error={this.state.cityError}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="townGuid"
                    label="İlçe"
                    placeholder="Lütfen ilçe seçiniz..."
                    selectValue={this.state.townGuid}
                    isMulti={false}
                    isClearable={true}
                    disabled={this.state.isTownTrue}
                    onChange={(option) => this.handleTownSelectChange(option)}
                    options={this.state.townArr}
                    error={this.state.townError}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="districtGuid"
                    label="Mahalle"
                    placeholder="Lütfen mahalle seçiniz..."
                    selectValue={this.state.districtGuid}
                    isMulti={false}
                    isClearable={true}
                    disabled={this.state.isDistrictTrue}
                    onChange={(option) =>
                      this.handleDistrictSelectChange(option)
                    }
                    options={this.state.districtArr}
                    error={this.state.districtError}
                  />
                </Col>
                <Col className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.customerData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Müşteri Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      var phone = row.phone,
                        maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
                          } ${phone.substring(4, 7)} ${phone.substring(
                            7,
                            9
                          )} ${phone.substring(9, 11)}`;
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td style={this.tdStyle}>{row.name}</td>
                              <th style={this.thStyle2}>Bölge</th>
                              <td>{row.customerGroupName ? row.customerGroupName : "-"}</td>
                            </tr>
                            <tr>

                              <th style={this.thStyle}>Telefon</th>
                              <td style={this.tdStyle}>{row.phone === "" ? "-" : maskPhone}</td>
                              <th style={this.thStyle2}>E-Mail</th>
                              <td>
                                {row.eMail ? row.eMail : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={3}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerList;
