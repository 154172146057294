import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";

class PurchaseWorkOrdersList extends Component {
  state = {
    pageTitle: "Satınalma İş Emri Listele",
    pageAddLink: "satin-alma-is-emri-ekle",
    pageUpdateLink: "satin-alma-is-emri-guncelle",
    pageDetailLink: "satin-alma-is-emri-detay",
    pageListLink: "satin-alma-is-emri-listele",
    breadcrumb: [{ text: "Satınalma İş Emri", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    locationData: [],
    name: "",
    guid: "",
    alertErrorMessage: "",
    pageType: "",
  };

  async componentDidMount() {
    var param = "/purchaseworkorders/getlistemployee/";
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "yetkili-satin-alma-is-emri") {
      this.setState({
        pageType: "yetkili-satin-alma-is-emri",
        pageTitle: "Yetkili Satınalma İş Emri",
        pageTitleAdd: "",
        pageTitleUpdate: "",
        pageAddLink: "",
        pageUpdateLink: "",
        pageDetailLink: "yetkili-satin-alma-is-emri-detay",
        pageListLink: "yetkili-satin-alma-is-emri",
        breadcrumb: [{ text: "Yetkili Satınalma İş Emri", link: "#" }],
      });
      param = "/purchaseworkorders/getlist/";
    }

    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + param, config)
      .then((response) => {
        this.setState({
          locationData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/purchaseworkorders/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={
            this.state.pageAddLink !== "" && "/" + this.state.pageAddLink
          }
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="locationList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.locationData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Ad",
                    selector: "name",
                    sortable: false,
                  },
                  {
                    name: "Durumu",
                    selector: "status",
                    width: "150px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status === 0) {
                        return (
                          <CgnBadge
                            color="danger"
                            text="Bekliyor"
                            style={{ width: "100%" }}
                          />
                        );
                      } else if (row.status === 1) {
                        return (
                          <CgnBadge
                            color="warning"
                            text="Devam Ediyor"
                            style={{ width: "100%" }}
                          />
                        );
                      } else if (row.status === 2) {
                        return (
                          <CgnBadge
                            color="info"
                            text="Onaylanmadı"
                            style={{ width: "100%" }}
                          />
                        );
                      } else if (row.status === 3) {
                        return (
                          <CgnBadge
                            color="success"
                            text="Tamamlandı"
                            style={{ width: "100%" }}
                          />
                        );
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) =>
                      this.state.pageType !== "yetkili-satin-alma-is-emri" ? (
                        <CgnButtonDropdown
                          label={"Yönet"}
                          className="mr-0"
                          color="primary"
                          items={[
                            {
                              text: CgnMessage.updateButton,
                              className: "dropDownBtn",
                              href: `/${this.state.pageUpdateLink}/${row.guid}`,
                            },
                            {
                              text: CgnMessage.detailButton,
                              className: "dropDownBtn",
                              href: `/${this.state.pageDetailLink}/${row.guid}`,
                            },
                            {
                              text: CgnMessage.deleteButton,
                              className: "dropDownBtn",
                              onClick: () => this.handleDelete(row.guid),
                            },
                          ]}
                        />
                      ) : (
                        <CgnButtonDropdown
                          label={"Yönet"}
                          className="mr-0"
                          color="primary"
                          items={[
                            {
                              text: CgnMessage.detailButton,
                              className: "dropDownBtn",
                              href: `/${this.state.pageDetailLink}/${row.guid}`,
                            },
                          ]}
                        />
                      ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default PurchaseWorkOrdersList;
