import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

class CategoryAddUpdate extends Component {
  state = {
    pageTitle: "Kategori Ekle",
    pageTitleAdd: "Kategori Ekle",
    pageTitleUpdate: "Kategori Güncelle",
    pageAddLink: "kategori-ekle",
    pageListLink: "kategori-listele",
    breadcrumb: [{ text: "Kategori", link: "#" }],
    isLoading: false,
    categoryArr: [],
    urlSplit: [],
    name: "",
    code: "",
    guid: "",
    status: true,
    nameError: "",
    categoryGuid: null,
    categoryNameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/categories/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.code ? item.code + " / " + item.name : item.name,
            categoryGuid: item.categoryGuid,
          };
        });
        this.setState({
          categoryArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/categories/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              name: response.data.name,
              code: response.data.code,
              categoryGuid: response.data.categoryGuid,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        code: "",
        taxNo: "",
        taxAdministration: "",
        address: "",
        eMail: "",
        phone: "",
        categoryGuid: null,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      categoryGuid: null,
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleCategorySelectChange(option) {
    if (option) {
      this.setState({
        categoryGuid: option.value,
      });
    } else {
      this.setState({
        categoryGuid: null,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.name !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        name: this.state.name,
        code: this.state.code,
        status: this.state.status,
        categoryGuid: this.state.categoryGuid,
      };
      var URLParam = "/categories/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/categories/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          code: this.state.code,
          status: this.state.status,
          categoryGuid: this.state.categoryGuid,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            var modalAddedCategory = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedCategory });
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (this.state.guid) {
                      window.location.href = "/kategori-listele";
                    }
                    if (this.state.urlSplit[1] === "kategori-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "kategori-ekle" ||
          this.state.urlSplit[1] === "kategori-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="categoryGuid"
                      label="Üst Kategori "
                      placeholder="Üst kategori seçiniz..."
                      selectValue={this.state.categoryGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCategorySelectChange(option)
                      }
                      options={this.state.categoryArr}
                      error={this.state.categoryNameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="code"
                      label="Kategori Kodu"
                      value={this.state.code}
                      placeHolder="Lütfen kategori kodu girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Kategori [*]"
                      value={this.state.name}
                      placeHolder="Lütfen kategori girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "kategori-ekle" ||
              this.state.urlSplit[1] === "kategori-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(CategoryAddUpdate);
