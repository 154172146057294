import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  PaymentTypeOptions,
  
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButtonLink from "../toolbox/CgnButtonLink";

class PaymentList extends Component {
  state = {
    pageTitle: "Ödeme Listele",
    pageAddLink: "odeme-ekle",
    pageUpdateLink: "odeme/guncelle", // ekle ve güncelle sayfalarındaki  urlSplitten dolayı ayırdım dokunma bozulur
    pageDetailLink: "odeme-detay",
    pageListLink: "odeme-listele",
    breadcrumb: [{ text: "Ödeme", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    paymentData: [],
    paymentDate: "",
    receiptNo: "",
    paymentMethodTypeValue: null,
    total: 0,
    totalDolar: 0,
    totalEuro: 0,
    costGuid: "",
    guid: "",
    alertErrorMessage: "",
    detail: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "gider-detay") {
      this.setState({
        detail: true,
      });
    }
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/payments/getlist/" + urlSplit[2], config)
      .then((response) => {
        this.setState({
          paymentData: response.data,
          isLoading: false,
        });
        var total = 0;
        var totalDolar = 0;
        var totalEuro = 0;
        response.data.forEach((element) => {
          if (element.currencyType === 1) {
            total += element.total;
          } else if (element.currencyType === 2) {
            totalDolar += element.total;
          } else if (element.currencyType === 3) {
            totalEuro += element.total;
          }
        });
        this.setState({
          total: total,
          totalDolar: totalDolar,
          totalEuro: totalEuro,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/payments/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "140px",
  };

  thStyle2 = {
    width: "75px",
  };

  tdStyle = {
    width: "150px",
  };

  render() {
    let urlSplit = window.location.pathname.split("/");
    let urlNumber;
    if (urlSplit.length === 4) {
      urlNumber = 3;
    } else if (urlSplit.length === 3) {
      urlNumber = 2;
    }
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink + "/" + urlSplit[urlNumber]}
            listLink={"/" + this.state.pageListLink + "/" + urlSplit[urlNumber]}
          />
        )}
        {this.state.detail === true && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            addLink={"/" + this.state.pageAddLink + "/" + urlSplit[urlNumber]}
          />
        )}
        <Row className="paymentList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.paymentData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Ödeme Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.paymentDate}</td>
                              <th style={this.thStyle2}>Makbuz No</th>
                              <td>{row.receiptNo}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ödeme Yöntemi Türü</th>
                              <td style={this.tdStyle}>
                                {PaymentTypeOptions(row.paymentMethodType)}
                              </td>
                              <th style={this.thStyle2}>Tutar</th>
                              <td>
                                {row.total.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (₺):{" "}
                {this.state.total.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.totalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.totalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
            {this.state.detail === false && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text="Geri"
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default PaymentList;
