import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import classnames from "classnames";
import BankMovementList from "./BankMovementList";

class SupplierDetail extends Component {
  state = {
    pageTitle: "Banka Detay",
    pageTitleDetail: "Banka Detay",
    pageAddLink: "banka-ekle",
    pageListLink: "banka-listele",
    breadcrumb: [{ text: "Banka", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    name: "",
    branchCode: "",
    branchName: "",
    accountNo: "",
    iban: "",
    openingBalance: 0,
    totalPayment: 0,
    swiftCode: "",
    totalCollection: 0,
    currencyType: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    activeTab: "1",
    bankDetail: "1",
    bankMovementList: "2",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/banks/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            name: response.data.name,
            branchCode: response.data.branchCode,
            branchName: response.data.branchName,
            accountNo: response.data.accountNo,
            iban: response.data.iban,
            openingBalance: response.data.openingBalance,
            totalCollection: response.data.totalCollection,
            totalPayment: response.data.totalPayment,
            currencyType: response.data.currencyType,
            status: response.data.status,
            swiftCode: response.data.swiftCode,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const balance = this.state.totalCollection + this.state.totalPayment;
    const totalBalance = balance + this.state.openingBalance;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="customerDetail">
          <Col>
            <CgnLoader visibled={this.state.isLoading} />
            {this.state.alertErrorMessage && (
              <CgnAlert
                color="danger"
                title="Uyarı"
                text={this.state.alertErrorMessage}
              />
            )}
            <Nav tabs className="mt-3">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === this.state.bankDetail,
                  })}
                  onClick={() => {
                    this.toggle(this.state.bankDetail);
                  }}
                >
                  BANKA DETAY
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      this.state.activeTab === this.state.bankMovementList,
                  })}
                  onClick={() => {
                    this.toggle(this.state.bankMovementList);
                  }}
                >
                  BANKA HAREKETLERİ
                </NavLink>
              </NavItem>
            </Nav>
            <Card body className="customerDetailCard table-responsive">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={this.state.bankDetail}>
                  <Table className="table table-responsive table-hover">
                    <tbody>
                      <tr>
                        <th>Banka</th>
                        <td colSpan="4">
                          {this.state.name ? this.state.name : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Şube Kodu</th>
                        <td colSpan="4">
                          {this.state.branchCode ? this.state.branchCode : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Şube Adı</th>
                        <td colSpan="4">
                          {this.state.branchName ? this.state.branchName : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Swift Kodu</th>
                        <td colSpan="4">
                          {this.state.swiftCode ? this.state.swiftCode : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Hesap No</th>
                        <td colSpan="4">
                          {this.state.accountNo ? this.state.accountNo : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Iban</th>
                        <td colSpan="4">
                          {this.state.iban ? this.state.iban : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Açılış Bakiyesi</th>
                        <td colSpan="4">
                          {this.state.openingBalance.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>

                      <tr>
                        <th>Bakiye</th>
                        <td colSpan="4">
                          {balance.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>

                      <tr>
                        <th>Toplam Bakiye</th>
                        <td colSpan="4">
                          {totalBalance.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>
                      <tr>
                        <th>Durumu</th>
                        <td colSpan="4">
                          {this.state.status ? "Aktif" : "Pasif"}
                        </td>
                      </tr>

                      <tr>
                        <th>Kaydeden Kullanıcı</th>
                        <td>
                          {this.state.createdBy ? this.state.createdBy : "-"}
                        </td>
                        <th>Kaydedilme Tarihi</th>
                        <td>
                          {this.state.createdAt ? this.state.createdAt : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Son Güncelleyen Kullanıcı</th>
                        <td>
                          {this.state.updatedBy ? this.state.updatedBy : "-"}
                        </td>
                        <th>Son Güncellenme Tarihi</th>
                        <td>
                          {this.state.updatedAt ? this.state.updatedAt : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId={this.state.bankMovementList}>
                  <BankMovementList />
                </TabPane>
              </TabContent>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default SupplierDetail;
