import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";


class TpuCostDetailList extends Component {
  state = {
    pageTitle: "Tpu Maliyetleri",
    pageTitleDetail: "Tpu Maliyetleri",
    breadcrumb: [{ text: "Tpu Maliyetleri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    sieveProductGuid: "",
    sieveProductArr: [],
    sieveEyeSize: "",
    sieveProductName: "",
    sieveProductError: "",
    sieveSizeArr: [],
    sieveEyeSizeArr: [],
    sieveSizeMultiplier: 0,
    sieveEyeSizeMultiplier: 0,
    sieveSize: "",
    castingCostTotal: 0,
    transport: 0,
    transportRate: 0,
    transportTotal: 0,
    lama810: 0,
    lama810Rate: 0,
    lama810Total: 0,
    lama1020: 0,
    lama1020Rate: 0,
    lama1020Total: 0,
    profitRate: 0,
    pieceTotal: 0,
    m2Total: 0,
    total: 0,
    costType: "",
    costTypeError: "",
    raw1: 0,
    raw1Rate: 0,
    raw1Total: 0,
    raw2: 0,
    raw2Rate: 0,
    raw2Total: 0,
    raw3: 0,
    raw3Rate: 0,
    raw3Total: 0,
    raw4: 0,
    raw4Rate: 0,
    raw4Total: 0,
    raw5: 0,
    raw5Rate: 0,
    raw5Total: 0,
    raw6: 0,
    raw6Rate: 0,
    raw6Total: 0,
    raw7: 0,
    raw7Rate: 0,
    raw7Total: 0,
    raw8: 0,
    raw8Rate: 0,
    raw8Total: 0,
    sundry: 0,
    sundryRate: 0,
    sundryTotal: 0,
    lamaLaser: 0,
    lamaLaserRate: 0,
    lamaLaserTotal: 0,
    smallCost: 0,
    smallCostRate: 0,
    smallCostTotal: 0,
    bigCost: 0,
    bigCostRate: 0,
    bigCostTotal: 0,
    smallProduction: 0,
    smallProductionRate: 0,
    smallProductionTotal: 0,
    bigProduction: 0,
    bigProductionRate: 0,
    bigProductionTotal: 0,
    cost: 0,  
    updatedAt: "",
    updatedBy: "",
    createdAt: "",
    createdBy: "",
    tpuData: [],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/tpuCosts/getlistcost/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            tpuData: response.data,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }


  thStyle = {
    width: "150px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "180px",
  };

  thStyle1 ={
    width: "200px",
    display: "none"
  }

  tdStyle1 = {
    width: "200px",
    display: "none"
  }

  render() {
    var self = this;
    var self1 = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              {this.state.tpuData !== null &&
                this.state.tpuData.length > 0 && (
                  <>
                    {this.state.tpuData.map(function (item) {
                      return (
                        <>
                          <Table className="mb-3 table-product3">
                            <tbody>
                            <tr>
                    <th style={{color: "#fff"}}>Malzeme</th>
                    <td style={{color: "#fff"}} colSpan="4">
                      {item.sieveProductName
                        ? item.sieveProductName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Elek Boyutu (mm)</th>
                    <td>
                      {item.sieveSize ? item.sieveSize : "-"}
                    </td>
                    <th>Elek Göz Boyutu (mm)</th>
                    <td>
                      {item.sieveEyeSize ? item.sieveEyeSize : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Hammadde-1 Birimi (€)</th>
                    <td>
                      {item.raw1Rate ? item.raw1Rate : "-"}
                    </td>
                    <th>Hammadde-1</th>
                    <td>
                      {item.raw1 ? item.raw1 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Hammadde-2 Birimi (€)</th>
                    <td>
                      {item.raw2Rate ? item.raw2Rate : "-"}
                    </td>
                    <th>Hammadde-2</th>
                    <td>
                      {item.raw2 ? item.raw2 : "-"}
                    </td>
                  </tr>
                 
                 
                  <tr>
                    <th>Hammadde-3 Birimi (€)</th>
                    <td>
                      {item.raw3Rate ? item.raw3Rate : "-"}
                    </td>
                    <th>Hammadde-3</th>
                    <td>
                      {item.raw3 ? item.raw3 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Hammadde-4 Birimi (€)</th>
                    <td>
                      {item.raw4Rate ? item.raw4Rate : "-"}
                    </td>
                    <th>Hammadde-4</th>
                    <td>
                      {item.raw4 ? item.raw4 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Hammadde-5 Birimi (€)</th>
                    <td>
                      {item.raw5Rate ? item.raw5Rate : "-"}
                    </td>
                    <th>Hammadde-5</th>
                    <td>
                      {item.raw5 ? item.raw5 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Hammadde-6 Birimi (€)</th>
                    <td>
                      {item.raw6Rate ? item.raw6Rate : "-"}
                    </td>
                    <th>Hammadde-6</th>
                    <td>
                      {item.raw6 ? item.raw6 : "-"}
                    </td>
                  </tr>

                  
                  <tr>
                    <th>Hammadde-7 Birimi (€)</th>
                    <td>
                      {item.raw7Rate ? item.raw7Rate : "-"}
                    </td>
                    <th>Hammadde-7</th>
                    <td>
                      {item.raw7 ? item.raw7 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Hammadde-8 Birimi (€)</th>
                    <td>
                      {item.raw8Rate ? item.raw8Rate : "-"}
                    </td>
                    <th>Hammadde-8</th>
                    <td>
                      {item.raw8 ? item.raw8 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Nakliye Birimi (€)</th>
                    <td>
                      {item.transportRate ? item.transportRate : "-"}
                    </td>
                    <th>Nakliye</th>
                    <td>
                      {item.transport ? item.transport : "-"}
                    </td>
                  </tr>
            
                  <tr>
                    <th>8x16 Lama Birimi (€)</th>
                    <td>
                      {item.lama810Rate ? item.lama810Rate : "-"}
                    </td>
                    <th>8x10 Lama</th>
                    <td>
                      {item.lama810 ? item.lama810 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Muhtelif Birimi (€)</th>
                    <td>
                      {item.sundryRate ? item.sundryRate : "-"}
                    </td>
                    <th>Muhtelif</th>
                    <td>
                      {item.sundry ? item.sundry : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Lama Lazer Kesim Birimi (€)</th>
                    <td>
                      {item.lamaLaserRate ? item.lamaLaserRate : "-"}
                    </td>
                    <th>Lama Lazer Kesim</th>
                    <td>
                      {item.lamaLaser ? item.lamaLaser : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Küçük Enj Üretim Miktarı Birimi (€)</th>
                    <td>
                      {item.smallProductionRate ? item.smallProductionRate : "-"}
                    </td>
                    <th>Küçük Enj Üretim Miktarı</th>
                    <td>
                      {item.smallProduction ? item.smallProduction : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Büyük Enj Üretim Miktarı Birimi (€)</th>
                    <td>
                      {item.bigProductionRate ? item.bigProductionRate : "-"}
                    </td>
                    <th>Büyük Enj Üretim Miktarı</th>
                    <td>
                      {item.bigProduction ? item.bigProduction : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Küçük Enj Maliyeti Birimi (€)</th>
                    <td>
                      {item.smallCostRate ? item.smallCostRate : "-"}
                    </td>
                    <th>Küçük Enj Maliyeti</th>
                    <td>
                      {item.smallCost ? item.smallCost : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Büyük Enj Maliyeti Birimi (€)</th>
                    <td>
                      {item.bigCostRate ? item.bigCostRate : "-"}
                    </td>
                    <th>Büyük Enj Maliyeti</th>
                    <td>
                      {item.bigCost ? item.bigCost : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Genel Gider</th>
                    <td colSpan="4">
                      {item.cost
                        ? item.cost
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Malzeme Maliyeti (€/ad)</th>
                    <td>
                      {item.total}
                    </td>
                    <th>Toplam (€/m2) </th>
                    <td>
                      {item.m2Total}
                    </td>
                  </tr>

                  <tr>
                    <th>İşçilik Yüzdesi (%)</th>
                    <td>
                      {item.profitRate}
                    </td>
                    <th>Toplam (€/ad)</th>
                    <td>
                      {item.castingCostTotal}
                    </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{item.createdBy ? item.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{item.createdAt ? item.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{item.updatedBy ? item.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{item.updatedAt ? item.updatedAt : "-"}</td>
                  </tr>
                   </tbody>
                      </Table>
                        </>
                      );
                    })}
                  </>
                )}

            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default TpuCostDetailList;
