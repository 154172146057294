import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses } from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import DocumentTypeAddUpdate from "../documentType/DocumentTypeAddUpdate";
import bsCustomFileInput from "bs-custom-file-input";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";

class DocumentAddUpdate extends Component {
  state = {
    pageTitle: "Belge Ekle",
    pageTitleAdd: "Belge Ekle",
    pageTitleUpdate: "Belge Güncelle",
    pageAddLink: "belge-ekle",
    pageListLink: "belge-listele",
    breadcrumb: [{ text: "Belge", link: "#" }],
    isLoading: false,
    urlSplit: [],
    file: null,
    fileName: "",
    status: true,
    fileError: "",
    guid: "",
    supplierGuid: null,
    customerGuid: null,
    isOpenModalDocumentType: false,
    isOpenModalCustomer: false,
    isOpenModalSupplier: false,
    documentTypeGuid: "",
    documentTypeError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    bsCustomFileInput.init(); // inputa dosya ismi eklenmiyordu onun için
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/documentTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          documentTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/documents/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              documentTypeGuid: response.data.documentTypeGuid,
              customerGuid: response.data.customerGuid,
              supplierGuid: response.data.supplierGuid,
              file: response.data.file,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.removeFile();
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        documentTypeGuid: "",
        customerGuid: null,
        supplierGuid: null,
        file: null,
        fileName: "",
        status: true,
      });
    }
    if (
      this.state.isOpenModalDocumentType &&
      this.props.location.modalAddedDocumentType !== undefined &&
      this.props.location.modalAddedDocumentType.isModal !== undefined &&
      this.props.location.modalAddedDocumentType.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/documentTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            documentTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedDocumentType.data &&
              element.name === this.props.location.modalAddedDocumentType.data
            ) {
              this.setState({
                documentTypeGuid: element.guid,
                documentTypeError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedDocumentType.isModal = true;
      this.setState({
        isOpenModalDocumentType: false,
      });
    }

    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                supplierGuid: null,
                customerError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }

    if (
      this.state.isOpenModalSupplier &&
      this.props.location.modalAddedSupplier !== undefined &&
      this.props.location.modalAddedSupplier.isModal !== undefined &&
      this.props.location.modalAddedSupplier.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/suppliers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            supplierArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSupplier.data &&
              element.name === this.props.location.modalAddedSupplier.data
            ) {
              this.setState({
                supplierGuid: element.guid,
                customerGuid: null,
                supplierError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSupplier.isModal = true;
      this.setState({
        isOpenModalSupplier: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      alertErrorMessage: "",
    });
  };

  handleDocumentTypeSelectChange(option) {
    if (option) {
      this.setState({
        documentTypeGuid: option.value,
        documentTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        documentTypeGuid: "",
        documentTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleFile = async (e) => {
    const file = await e.target.files[0];
    var x = document.getElementsByClassName("custom-file-label")[0];
    x.innerHTML = e.target.files[0].name;
    this.setState({
      file: file,
      fileName: e.target.files[0].name,
      fileError: "",
      alertErrorMessage: "",
    });
    e.target.value = ""; // sildikten sonra tekrar aynı dosyayı yükleyebilmek için içini boşalttım.
  };

  removeFile = async () => {
    const input = document.getElementById("inputGroupFile01");
    const fileListArr = Array.from(input.files);
    await fileListArr.splice(0, 1);
    var x = document.getElementsByClassName("custom-file-label")[0];
    x.innerHTML = "Dosya seçmek için tıklayınız...";
    await this.setState({
      file: null,
      fileName: "",
      fileError: CgnMessage.fileErrorMessage,
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  };

  addModalDocumentType = () => {
    this.setState({
      isOpenModalDocumentType: !this.state.isOpenModalDocumentType,
    });
  };

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        supplierGuid: null,
      });
    } else {
      this.setState({
        customerGuid: null,
      });
    }
  }

  handleSupplierSelectChange(option) {
    if (option) {
      this.setState({
        supplierGuid: option.value,
        customerGuid: null,
      });
    } else {
      this.setState({
        supplierGuid: null,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.removeFile();
    this.setState({
      documentTypeGuid: "",
      file: null,
      customerGuid: null,
      supplierGuid: null,
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      documentTypeError: CgnMessage.textErrorMessage,
      fileError: CgnMessage.fileErrorMessage,
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      documentTypeError: "",
      fileError: "",
      alertErrorMessage: "",
    });

    if (this.state.documentTypeGuid === "") {
      this.setState({
        documentTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.file === null) {
      this.setState({
        fileError: CgnMessage.fileErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.documentTypeGuid && this.state.file) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const file = this.state.file;
      const formData = new FormData();
      formData.append("files", file);
      var myObj = {
        documentTypeGuid: this.state.documentTypeGuid,
        customerGuid: this.state.customerGuid,
        supplierGuid: this.state.supplierGuid,
        fileName: file.name,
        status: this.state.status,
      };
      var URLParam = "/documents/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/documents/update";
        myObj = {
          guid: this.state.guid,
          documentTypeGuid: this.state.documentTypeGuid,
          customerGuid: this.state.customerGuid,
          supplierGuid: this.state.supplierGuid,
          fileName: file.name,
          status: this.state.status,
        };
      }
      this.setState({
        isLoading: true,
      });
      await axios
        .post(URL + "/uploads/add", formData, config)
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
      await axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/belge-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="documentTypeGuid"
                          label="Belge Türü [*]"
                          placeholder="Belge Türü seçiniz..."
                          selectValue={this.state.documentTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleDocumentTypeSelectChange(option)
                          }
                          options={this.state.documentTypeArr}
                          error={this.state.documentTypeError}
                        />
                      </div>
                      <div className="p-0 flexMaxAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalDocumentType()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <div className="font-weight-bold fileTopLabel">
                        Dosya [*]
                      </div>
                      <div class="custom-file">
                        <input
                          id="inputGroupFile01"
                          type="file"
                          class="custom-file-input"
                          onChange={(e) => this.handleFile(e)}
                        />
                        <label class="custom-file-label" for="inputGroupFile01">
                          Dosya seçmek için tıklayınız...
                        </label>
                      </div>
                      <button
                        type="button"
                        onClick={() => this.removeFile()}
                        className="fileRemoveButton"
                      >
                        X
                      </button>
                      {this.state.fileError ? (
                        <div className="invalid-feedback d-block">
                          {this.state.fileError}
                        </div>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      <div className="p-0 flexAddMinButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalCustomer()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="supplierGuid"
                          label="Tedarikçi"
                          placeholder="Tedarikçi seçiniz..."
                          selectValue={this.state.supplierGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSupplierSelectChange(option)
                          }
                          options={this.state.supplierArr}
                          error={this.state.supplierError}
                        />
                      </div>
                      <div className="p-0 flexAddMinButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalSupplier()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalDocumentType}
          toggle={this.addModalDocumentType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Belge Türü Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <DocumentTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalDocumentType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalSupplier}
          toggle={this.addModalSupplier}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tedarikçi Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SupplierAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSupplier}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default DocumentAddUpdate;
