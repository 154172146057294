import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";

class NoteList extends Component {
  state = {
    pageTitle: "Not Listele",
    pageAddLink: "not-ekle",
    pageUpdateLink: "not-guncelle",
    pageDetailLink: "not-detay",
    pageListLink: "not-listele",
    breadcrumb: [{ text: "Not", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: true,
    noteListData: [],
    alertErrorMessage: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var urlSplit = window.location.pathname.split("/");
    var noteType = 0;
    var customerOrSupplierGuid = null;
    var path = "";

    if (urlSplit[1] === "musteri-detay") {
      noteType = 1;
      customerOrSupplierGuid = urlSplit[2];
      path = "getlistforcustomer";
    } else if (urlSplit[1] === "tedarikci-detay") {
      noteType = 2;
      customerOrSupplierGuid = urlSplit[2];
      path = "getlistforsupplier";
    }
    axios
      .get(URL + `/notes/${path}/${customerOrSupplierGuid}/${noteType}`, config)
      .then((response) => {
        this.setState({
          noteListData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/notes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <>
        <Row className="noteList">
          <Col>
            <CgnLoader visibled={this.state.isLoading} />
            {this.state.alertErrorMessage && (
              <CgnAlert
                color="danger"
                title={CgnMessage.alertErrorTitle}
                text={this.state.alertErrorMessage}
              />
            )}

            {this.state.noteListData.map((item) => {
              return (
                <Card body className="noteCard">
                  <Row className="my-3">
                    <Col xs="12">
                      <h6 className="noteAuthor">{item.createdBy}</h6>
                    </Col>
                    <Col xs="12" className="text-muted">
                      {item.noteDate}
                    </Col>
                    <Col md="10" className="titleDesCol mt-3">
                      <div className="font-weight-bold noteTitle">
                        {item.title}
                      </div>
                      <span>{item.description}</span>
                    </Col>
                    <Col md="2" className="text-right buttonGroupCol">
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${item.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${item.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(item.guid),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </Col>
        </Row>
      </>
    );
  }
}

export default NoteList;
