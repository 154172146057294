import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  TaxTypeOptions,
  optionOfferStatusesOptions,
  URL,
  fotoStyle,
  localFilePath,
  
  fotoStyle2
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import CgnButton from "../toolbox/CgnButton";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import pdfIcon from "../../images/pdfIcon.png";
import fileIcon from "../../images/file.jpg";

class OfferNewDetail extends Component {
  state = {
    pageTitle: "Teklif Detay",
    pageTitleDetail: "Teklif Detay",
    pageAddLink: "teklif2-ekle",
    pageListLink: "teklif2-listele",
    breadcrumb: [{ text: "Teklif", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    offerNumber: "",
    offerDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    generalDiscountTotal: null,
    generalTaxTotal: null,
    taxTotal: null,
    discountPercent: "",
    language: "",
    customerPhone: "",
    discountValue: "",
    discountTotal: "",
    taxType: null,
    taxRate: null,
    currencyType: 0,
    description: "",
    productDto: [],
    customerEmail: "",
    offerDocumentDto: [],
    productName: "",
    piece: null,
    unitPrice: null,
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    offerCoverImage: "",
    confirmationDate: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/offernews/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            customerName: response.data.customerName,
            offerNumber: response.data.offerNumber,
            offerDate: response.data.offerDate,
            optionDate: response.data.optionDate,
            reminderDate: response.data.reminderDate,
            language: response.data.language,
            productDto: response.data.offerProductNewDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),
            currencyType: response.data.currencyType,
            customerEmail: response.data.customerEmail,
            customerPhone: response.data.customerPhone,
            guaranty: response.data.guaranty,
            paymentMethodType: response.data.paymentMethodType,
            deliveryMethodType: response.data.deliveryMethodType,
            deliveryTime: response.data.deliveryTime,
            description: response.data.description,
            offerCoverImage: response.data.offerCoverImage,
            confirmationDate: response.data.confirmationDate,
            offerCancelText: response.data.offerCancelText,
            offerDocumentDto: response.data.offerDocumentDto,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }


  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offernews/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offernews/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self1 = this;

    var phone = this.state.customerPhone,
      maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
        } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
          9,
          11
        )}`;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        //emailLink={onClick => this.handlePdfSendMail(this.state.urlSplit)}
        //printLink={onClick => this.handlePdfPrint(this.state.urlSplit)}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tr>
                  <th>Teklif Dili</th>
                  <td colSpan="4">{this.state.language === 1 ? "Türkçe" : this.state.language === 2 ? "İngilizce" :
                    this.state.language === 3 ? "Fransızca" : this.state.language === 4 ? "Almanca" : this.state.language === 5 ? "Arapça" : "-"}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>
                <tr>
                  <th>Müşteri E-mail</th>
                  <td colSpan="4">{this.state.customerEmail === "" ? "-" : this.state.customerEmail}</td>
                </tr>
                <tr>
                  <th>Müşteri Telefon</th>
                  <td colSpan="4">
                    {this.state.customerPhone === "" ? "-" : maskPhone}
                  </td>
                </tr>
                <tr>
                  <th>Teklif Tarihi</th>
                  <td colSpan="4">{this.state.offerDate}</td>
                </tr>
                <tr>
                  <th>Teklif No</th>
                  <td colSpan="4">{this.state.offerNumber}</td>
                </tr>
                <tr>
                  <th>Opsiyon Tarihi</th>
                  <td colSpan="4">{this.state.optionDate}</td>
                </tr>

                <tr>
                  <th>Hatırlatma Tarihi</th>
                  <td colSpan="4">{this.state.reminderDate}</td>
                </tr>

                <tr>
                  <th>Ara Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam İndirim</th>
                  <td colSpan="4">
                    {this.state.generalDiscountTotal
                      ? this.state.generalDiscountTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam KDV Tutarı</th>
                  <td colSpan="4">
                    {this.state.generalTaxTotal
                      ? this.state.generalTaxTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Ödeme Yöntemi </th>
                  <td colSpan="4">{this.state.paymentMethodType}</td>
                </tr>

                <tr>
                  <th>Teslimat Yöntemi </th>
                  <td colSpan="4">{this.state.deliveryMethodType}</td>
                </tr>

                <tr>
                  <th>Teslimat Süresi </th>
                  <td colSpan="4">{this.state.deliveryTime}</td>
                </tr>

                <tr>
                  <th>Garanti Süresi </th>
                  <td colSpan="4">{this.state.guaranty}</td>
                </tr>

                <tr>
                  <th>Teklif Notu</th>
                  <td
                    colSpan="4"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description
                        ? this.state.description
                        : "-",
                    }}
                  >
                    {/* {this.state.description ? this.state.description : "-"} */}
                  </td>
                </tr>

                <tr>
                  <th>Teklif Kapağı</th>
                  <td colSpan="4">
                    {this.state.offerCoverImage ? (
                      <Button
                        onClick={this.toggle}
                        className="modalButtonDetail"
                      >
                        <img
                          src={this.state.offerCoverImage}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      </Button>
                    ) : (
                      <img
                        src={noImg}
                        style={fotoStyle}
                        alt={this.state.customerName}
                      />
                    )}
                  </td>
                </tr>

                <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}></ModalHeader>
                  <img
                    className="modalofferCoverImage"
                    src={this.state.offerCoverImage}
                    style={fotoStyle}
                    alt={this.state.customerName}
                  />
                </Modal>

                <tr>
                  <th>Teklif Onay/İptal Tarihi Saati</th>
                  <td colSpan="4">{this.state.confirmationDate}</td>
                </tr>

                <tr>
                  <th>Durum</th>
                  <td colSpan="4">
                    {optionOfferStatusesOptions(this.state.status)}
                  </td>
                </tr>
                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              {this.state.productDto !== null &&
                this.state.productDto.length > 0 &&
                this.state.productDto.map(function (item) {
                  return (
                    <Table className="table table-responsive table-hover table-product">
                      <tr className="">
                        <th style={mainRow}>Depo</th>
                        <th className="text-left" style={mainRow}>
                          Ürün Adı
                        </th>
                        <th className="text-center" style={mainRow}>
                          Adet
                        </th>
                        <th className="text-center" style={mainRow}>
                          Birim
                        </th>
                        <th className="text-center" style={mainRow}>Birim Fiyatı </th>
                        <th className="text-center" style={mainRow}>İndirim(Yüzde) </th>
                        <th className="text-center" style={mainRow}>İndirim(Net Tutar) </th>
                        <th className="text-center" style={mainRow}>KDV Oranı </th>
                        <th style={mainRow}>Fiyat</th>
                      </tr>
                      <tr style={subRow}>
                        <td>{item.locationName}</td>
                        <td className="text-left">
                          {item.productName}
                        </td>
                        <td className="text-center">{item.piece}</td>
                        <td className="text-center">{item.unit === "NIU" ? "Adet" :
                          item.unit === "KGM" ? "kg" :
                            item.unit === "GRM" ? "gr" :
                              item.unit === "MTR" ? "Metre" :
                                item.unit === "LTR" ? "Litre" :
                                  item.unit === "PA" ? "Paket" :
                                    item.unit === "BX" ? "Kutu" :
                                      item.unit === "CMT" ? "cm" :
                                        item.unit === "MTQ" ? "m3" :
                                          item.unit === "MTK" ? "m2" :
                                            item.unit === "ROLL" ? "Rulo" :
                                              item.unit === "SET" ? "Set" :
                                                item.unit === "CMQ" ? "cm3" :
                                                  item.unit === "SAA" ? "Saat" : "-"}</td>
                        <td className="text-center">
                          {item.unitPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                  ? "€"
                                  : "-")}
                        </td>

                        <td className="text-center">
                          {item.productDiscountPercent
                            ? "% " + item.productDiscountPercent
                            : "-"}
                        </td>
                        <td className="text-center">
                          {item.productDiscountValue && item.productDiscountValue > 0
                            ? item.productDiscountValue +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                  ? "€"
                                  : "-")
                            : "-"}
                        </td>
                        <td className="text-center">
                          {"% " + item.productTaxRate}
                        </td>
                        <td>
                          {item.total.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                  ? "€"
                                  : "-")}
                        </td>
                      </tr>

                      {item.productPropertyDto != null &&
                        item.productPropertyDto.length > 0 && (
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#1D4B8C",
                                color: "#fff",
                              }}
                              colSpan="9"
                              className="text-left"
                            >
                              Ürün Özellikleri
                            </th>
                          </tr>
                        )}
                      {item.productPropertyDto.map(function (item2) {
                        return (
                          <tr style={subRow}>
                            <td colSpan="2" className="text-left">
                              {item2.propertyTypeName}
                            </td>
                            <td colSpan="2" className="text-left">
                              {" "}
                              {item2.value}
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  );
                })}

              {this.state.offerDocumentDto &&
                this.state.offerDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.offerDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={fileIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
            </Card>
            {/* <CgnButton
              type="button"
              color="success"
              className="printButton mr-3"
              onClick={() => this.handlePdfPrint(this.state.urlSplit)}
              text={"Yazdır"}
            /> */}
            {/* <CgnButton
              style={{ display: this.state.customerEmail !== "" ? "" : "none" }}
              type="button"
              color="primary"
              className="sendEmailButton"
              onClick={() => this.handlePdfSendMail(this.state.urlSplit)}
              text={"E-Mail Gönder"}
            /> */}
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default OfferNewDetail;
