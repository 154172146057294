import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  PaymentMethodType,
  resizeFile,
  fotoStyle,
  CurrencyType,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";
import moment from "moment";
import CgnButton from "../toolbox/CgnButton";
import BankAddUpdate from "../bank/BankAddUpdate";

class PaymentAddUpdate extends Component {
  state = {
    pageTitle: "Ödeme Ekle",
    pageTitleAdd: "Ödeme Ekle",
    pageTitleUpdate: "Ödeme Güncelle",
    pageAddLink: "odeme-ekle",
    pageListLink: "gider-detay",
    breadcrumb: [{ text: "Ödeme", link: "#" }],
    isLoading: false,
    urlSplit: [],
    paymentDate: "",
    receiptNo: "",
    expiry: null,
    paymentMethodValue: null,
    productEntryGuid: "00000000-0000-0000-0000-000000000000",
    bankGuid: null,
    costGuid: "",
    chequesBondNo: "",
    total: "",
    description: "",
    image: "",
    imageName: "",
    currencyType: "",
    currencyTypeError: "",
    status: true,
    isOpenModalBank: false,
    paymentDateError: "",
    receiptNoError: "",
    expiryError: "",
    paymentMethodTypeError: "",
    bankError: "",
    chequesBondNoError: "",
    totalError: "",
    descriptionError: "",
    guid: "",
    alertErrorMessage: "",
    isDeleted: false,
    isBankTrue: false,
    isChequesBondNoTrue: false,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    bankModalPlus: false,
  };

  async componentDidMount() {
    if(this.props.guid !== undefined){
      this.state.guid = this.props.guid;
   }
    this.setState({
      isLoading: true,
      bankModalPlus: await UserOperationClaimControl(1003),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    if(urlSplit[1] === "alis2-detay" || urlSplit[1] === "yeni-alis-detay"){
      var productEntry = urlSplit[urlSplit.length - 1];
      this.setState({
        productEntryGuid: productEntry.toString()
      });
   }
    if (urlSplit.length === 3) {
      this.getByGuid(urlSplit[2]); //  urpSplit.length ===3 ise  ekle, 4 ise  güncelle . eklede costGuid, güncellede paymentGuid yolluyorsun.
    }
    if (urlSplit.length === 4) {
      this.getByGuid(urlSplit[3]); //  urpSplit.length ===3 ise  ekle, 4 ise  güncelle . eklede costGuid, güncellede paymentGuid yolluyorsun.
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      await axios
        .get(URL + "/payments/get/" + urlSplit[3], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              paymentDate: response.data.paymentDate,
              costGuid: response.data.costGuid,
              receiptNo: response.data.receiptNo,
              expiry: response.data.expiry,
              paymentMethodValue: response.data.paymentMethodType,
              bankGuid: response.data.bankGuid,
              chequesBondNo: response.data.chequesBondNo,
              total: response.data.total,
              description: response.data.description,
              image: response.data.image,
              currencyType: response.data.currencyType,
              status: response.data.status,
              isLoading: false,
            });
            if (
              response.data.paymentMethodType === 1 ||
              response.data.paymentMethodType === 5
            ) {
              this.setState({
                isChequesBondNoTrue: false,
                isBankTrue: true,
              });
            }
            if (
              response.data.paymentMethodType === 2 ||
              response.data.paymentMethodType === 3
            ) {
              this.setState({
                isChequesBondNoTrue: true,
                isBankTrue: false,
              });
            }
            if (response.data.paymentMethodType === 4) {
              this.setState({
                isChequesBondNoTrue: false,
                isBankTrue: false,
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } 
    else if(this.props.guid !== undefined){
      this.setState({
        guid: this.props.guid,
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      await axios
        .get(URL + "/payments/get/" + this.props.guid, config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              paymentDate: response.data.paymentDate,
              costGuid: response.data.costGuid,
              receiptNo: response.data.receiptNo,
              expiry: response.data.expiry,
              paymentMethodValue: response.data.paymentMethodType,
              bankGuid: response.data.bankGuid,
              chequesBondNo: response.data.chequesBondNo,
              total: response.data.total,
              description: response.data.description,
              image: response.data.image,
              currencyType: response.data.currencyType,
              status: response.data.status,
              isLoading: false,
            });
            if (
              response.data.paymentMethodType === 1 ||
              response.data.paymentMethodType === 5
            ) {
              this.setState({
                isChequesBondNoTrue: false,
                isBankTrue: true,
              });
            }
            if (
              response.data.paymentMethodType === 2 ||
              response.data.paymentMethodType === 3
            ) {
              this.setState({
                isChequesBondNoTrue: true,
                isBankTrue: false,
              });
            }
            if (response.data.paymentMethodType === 4) {
              this.setState({
                isChequesBondNoTrue: false,
                isBankTrue: false,
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
    else if (urlSplit.length === 3) {
      this.setState({
        isLoading: false,
      });
    }
  }

  getByGuid(guid) {
    var urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 4) {
      this.setState({
        guid: guid, // paymentguid
      });
    } else if (urlSplit.length === 3) {
      this.setState({
        costGuid: guid, // costGuid
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 3 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        paymentDate: "",
        receiptNo: "",
        expiry: "",
        paymentMethodValue: null,
        bankGuid: null,
        chequesBondNo: "",
        total: "",
        description: "",
        image: "",
        imageName: "",
        currencyType: "",
        status: true,
      });
    }

    if (
      this.state.isOpenModalBank &&
      this.props.location.modalAddedBank !== undefined &&
      this.props.location.modalAddedBank.isModal !== undefined &&
      this.props.location.modalAddedBank.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/banks/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            bankArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBank.data &&
              element.name === this.props.location.modalAddedBank.data
            ) {
              this.setState({
                bankGuid: element.guid,
                bankError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBank.isModal = true;
      this.setState({
        isOpenModalBank: false,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      receiptNoError: "",
      expiryError: "",
      paymentMethodTypeError: "",
      bankError: "",
      chequesBondNoError: "",
      totalError: "",
      descriptionError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "chequesBondNo" && value === "") {
      this.setState({
        chequesBondNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "total" && value === "") {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "total" && value && value < 0) {
      this.setState({
        totalError: CgnMessage.totalErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handlePaymentMethodSelectChange(option) {
    if (option) {
      this.setState({
        paymentMethodValue: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else {
      this.setState({
        paymentMethodValue: null,
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDateChange(paymentDate) {
    if (paymentDate) {
      this.setState({
        paymentDate: paymentDate,
        paymentDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        paymentDate: "",
        paymentDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalBank = () => {
    this.setState({
      isOpenModalBank: !this.state.isOpenModalBank,
    });
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      paymentDate: "",
      receiptNo: "",
      expiry: "",
      paymentMethodValue: null,
      bankGuid: null,
      chequesBondNo: "",
      total: "",
      description: "",
      image: "",
      imageName: "",
      currencyType: "",
      isBankTrue: false,
      isChequesBondNoTrue: false,
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      paymentDateError: CgnMessage.textErrorMessage,
      receiptNoError: CgnMessage.textErrorMessage,
      expiryError: CgnMessage.textErrorMessage,
      paymentMethodTypeError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      paymentDateError: "",
      receiptNoError: "",
      expiryError: "",
      paymentMethodTypeError: "",
      bankError: "",
      chequesBondNoError: "",
      totalError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (this.state.paymentDate === "") {
      this.setState({
        paymentDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.paymentMethodValue === null) {
      this.setState({
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.bankGuid === null &&
      (this.state.paymentMethodValue === 2 ||
        this.state.paymentMethodValue === 3)
    ) {
      this.setState({
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.chequesBondNo === "" &&
      (this.state.paymentMethodValue === 1 ||
        this.state.paymentMethodValue === 5)
    ) {
      this.setState({
        chequesBondNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.total === null) {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.total && this.state.total <= 0) {
      this.setState({
        totalError: CgnMessage.totalErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.paymentDate !== "" &&
      this.state.paymentMethodValue !== "" &&
      this.state.total &&
      this.state.currencyType &&
      ((this.state.bankGuid !== null &&
        (this.state.paymentMethodValue === 2 ||
          this.state.paymentMethodValue === 3)) ||
        (this.state.chequesBondNo !== "" &&
          (this.state.paymentMethodValue === 1 ||
            this.state.paymentMethodValue === 5)) ||
        (this.state.chequesBondNo === "" &&
          this.state.bankGuid === null &&
          this.state.paymentMethodValue === 4))
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        paymentDate: moment(this.state.paymentDate).format("DD/MM/YYYY"),
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        paymentMethodType: parseInt(this.state.paymentMethodValue),
        bankGuid: this.state.bankGuid,
        productEntryGuid: this.state.productEntryGuid,
        costGuid: this.state.costGuid,
        chequesBondNo: this.state.chequesBondNo,
        total: parseFloat(this.state.total),
        description: this.state.description,
        image: this.state.image,
        currencyType: this.state.currencyType,
        status: true, //this.state.status,
      };
      var URLParam = "/payments/add";
      if (this.state.guid && this.state.urlSplit.length !== 4) {
        URLParam = "/payments/update";
        myObj = {
          guid: this.state.guid,
          costGuid: this.state.costGuid,
          paymentDate:
            this.state.paymentDate &&
            this.state.paymentDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.paymentDate.split("/")[2] +
                    "-" +
                    this.state.paymentDate.split("/")[1] +
                    "-" +
                    this.state.paymentDate.split("/")[0]
                )
              : moment(this.state.paymentDate).format("DD/MM/YYYY"),
          receiptNo: this.state.receiptNo,
          expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
          paymentMethodType: parseInt(this.state.paymentMethodValue),
          bankGuid: this.state.bankGuid,
          chequesBondNo: this.state.chequesBondNo,
          total: parseFloat(this.state.total),
          description: this.state.description,
          image: this.state.image,
          currencyType: this.state.currencyType,
          status: true, //this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          let urlSplit = window.location.pathname.split("/");
            if(urlSplit[1] === "alis2-detay" || urlSplit[1] === "yeni-alis-detay"){
              window.location.reload();
            }
            else{
              setTimeout(() => {
                window.location.href = "/gider-detay/" + this.state.costGuid;
              }, 1500);
            }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          //addLink={"/" + this.state.pageAddLink + "/" + this.state.costGuid}
          //listLink={"/" + this.state.pageListLink + "/"+ this.state.costGuid}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4">
                    <CgnDatePicker
                      name="paymentDate"
                      label="Tarih [*]"
                      selected={
                        this.state.paymentDate &&
                        this.state.paymentDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.paymentDate.split("/")[2] +
                                "-" +
                                this.state.paymentDate.split("/")[1] +
                                "-" +
                                this.state.paymentDate.split("/")[0]
                            )
                          : this.state.paymentDate
                      }
                      onChange={(paymentDate) =>
                        this.handleDateChange(paymentDate)
                      }
                      error={this.state.paymentDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="receiptNo"
                      label="Makbuz No"
                      value={this.state.receiptNo}
                      placeHolder="Lütfen makbuz no girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.receiptNoError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="expiry"
                      label="Vade"
                      value={this.state.expiry}
                      placeHolder="Lütfen vade sayısı girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.expiryError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="paymentMethodValue"
                      label="Ödeme Yöntemi [*]"
                      placeholder="Lütfen ödeme yöntemi seçiniz..."
                      selectValue={this.state.paymentMethodValue}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePaymentMethodSelectChange(option)
                      }
                      options={PaymentMethodType}
                      error={this.state.paymentMethodTypeError}
                    />
                  </Col>

                  <Col lg="4" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="bankGuid"
                          label="Banka"
                          placeholder="Banka seçiniz..."
                          selectValue={this.state.bankGuid}
                          isMulti={false}
                          isClearable={true}
                          disabled={this.state.isBankTrue}
                          onChange={(option) =>
                            this.handleBankSelectChange(option)
                          }
                          options={this.state.bankArr}
                          error={this.state.bankError}
                        />
                      </div>
                      {this.state.bankModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalBank()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="chequesBondNo"
                      label="Çek/Senet No"
                      value={this.state.chequesBondNo}
                      placeHolder="Lütfen çek/senet no girin..."
                      autoComplete="off"
                      readOnly={this.state.isChequesBondNoTrue}
                      onChange={this.handleChange}
                      error={this.state.chequesBondNoError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Tutar [*]"
                      value={this.state.total}
                      placeHolder="Lütfen tutar girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.totalError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Makbuz Fotoğrafı"
                      image={this.state.image}
                      noFoto={noImage}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      error={this.state.imageError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12" >
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

              {(this.state.urlSplit[1] === "odeme-ekle" ||
              this.state.urlSplit[1] === "odeme-guncelle") && (
                <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink + "/" + this.state.costGuid}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalBank}
          toggle={this.addModalBank}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Banka Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <BankAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBank}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default PaymentAddUpdate;
