import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  CustomerList,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  localFilePath,
  UserList,
  cutList,
} from "../../redux/actions/constants";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnDatatable2 from "../toolbox/CgnDatatable2";

class CuttingReportList extends Component {
  state = {
    pageTitle: "Kesim Raporları",
    //pageAddLink: "kesim-islemi-ekle",
    //pageUpdateLink: "kesim-islemi-guncelle",
    //pageDetailLink: "kesim-islemi-detay",
    //pageListLink: "kesim-islemi-listele",
    //pageProjectLink: "kesim-proje-listele",
    pagePrintLink: "kesim-yazdir",
    breadcrumb: [{ text: "Kesim Raporları", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    cutData: [],
    name: "",
    guid: "",
    cutId: 0,
    processStatus1: 0,
    cutTypeId: 0,
    totalPrice: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    userGuid: "00000000-0000-0000-0000-000000000000",
    userArr: [],
    cuttingProcessData: [],
    alertErrorMessage: "",
    status1: 0,
    status2: 0,
    status3: 0,
    statusAll: 0,
    cutIdArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateCuttingProcess")) {
      firstDate = localStorage.getItem("firstDateCuttingProcess");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateCuttingProcess")) {
      lastDate = localStorage.getItem("lastDateCuttingProcess");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidCuttingProcess")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidCuttingProcess"),
      });
    }

    await UserList().then(function (data) {
      self.setState({
        userArr: data,
      });
    });

    if (localStorage.getItem("userGuidCuttingReport")) {
      this.setState({
        userGuid: localStorage.getItem("userGuidCuttingReport"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateCuttingProcess", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateCuttingProcess", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidCuttingProcess", this.state.customerGuid);
  }

  async handleUserSelectChange(option) {
    if (option) {
      await this.setState({
        userGuid: option.value,
      });
    } else {
      await this.setState({
        userGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("userGuidCuttingReport", this.state.userGuid);
  }

  handleProcessStatusSelectChange(option) {
    if (option) {
      this.setState({
        processStatus1: option.value,
        processStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        processStatus1: 0,
      });
    }
  }

  handleCutIdSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        cutIdArr: option,
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        cutIdArr: [],
      });
    }
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    let urlSplit = window.location.pathname.split("/");
    var urlSplitX = urlSplit.length > 2 ? urlSplit[2] : 0;
    this.setState({
      urlSplitX: urlSplitX,
    });

    var cutDocuments = null;
    if (this.state.cutIdArr.length > 0) {
      var cutIdDocuments = [];
      this.state.cutIdArr.forEach((element) => {
        var cutIdObj = {};
        cutIdObj = element.value;
        cutIdDocuments.push(cutIdObj);
      });
      cutDocuments = cutIdDocuments.toString();
    } else {
      cutDocuments = null;
    }

    URLParam =
      "/cuttingprocess/getlistreport/" +
      this.state.processStatus1 +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.userGuid +
      "/" +
      cutDocuments;

    axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          cuttingProcessData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  // async handleProject(fileName) {
  //   this.setState({
  //     isLoading: true,
  //   });
  //   // 
  //   var config = {
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //   };
  //   const URLParam = "/cuttingprocess/getlistprojects";
  //   const obj = {
  //     fileName,
  //   };
  //   axios
  //     .post(URL + URLParam, obj, config)
  //     .then(() => {
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1500);
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         alertErrorMessage: error.response.data,
  //         isLoading: false,
  //       });
  //     });
  // }

  handleServicePrint = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var cutDocuments = null;
    if (this.state.cutIdArr.length > 0) {
      var cutIdDocuments = [];
      this.state.cutIdArr.forEach((element) => {
        var cutIdObj = {};
        cutIdObj = element.value;
        cutIdDocuments.push(cutIdObj);
      });
      cutDocuments = cutIdDocuments.toString();
    } else {
      cutDocuments = null;
    }

    var URLParam = "";
    URLParam =
      "/cuttingprocess/print/" +
      this.state.processStatus1 +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.userGuid +
      "/" +
      cutDocuments;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/cuttingprocess/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "110px",
  };

  thStyle2 = {
    width: "160px",
  };

  tdStyle = {
    width: "350px",
  };

  render() {
    return (
      <>
        <div>
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            //addLink={"/" + this.state.pageAddLink}
            //listLink={"/" + this.state.pageListLink}
            printLink={(onClick) => this.handleServicePrint()}
          />
        </div>
        <Row className="cutList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="4">
                  <CgnReactSelect
                    name="userGuid"
                    label="Personel"
                    placeholder="Personel seçiniz..."
                    selectValue={this.state.userGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) => this.handleUserSelectChange(option)}
                    options={this.state.userArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="3">
                  <CgnReactSelect
                    name="cutIdArr"
                    label="Kesim"
                    placeholder="Kesim seçiniz..."
                    selectValue={this.state.cutIdArr}
                    isMulti={true}
                    isClearable={true}
                    onChange={(option) => this.handleCutIdSelectChange(option)}
                    options={cutList}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable2
                data={this.state.cuttingProcessData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Kesim İşlemi Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr className="cuttingTr">
                              <th style={this.thStyle} className="text-white">
                                Kesim
                              </th>
                              <td style={this.tdStyle}>
                                {row.cutId === 1
                                  ? "Plazma Kesim"
                                  : row.cutId === 2
                                  ? "Lazer Kesim"
                                  : row.cutId === 3
                                  ? "Plazma Kesim İşçiliği"
                                  : row.cutId === 4
                                  ? "Lazer Kesim İşçiliği"
                                  : row.cutId === 5
                                  ? "Oksijen Kesim"
                                  : row.cutId === 6
                                  ? "Oksijen Kesim İşçiliği"
                                  : row.cutId === 7
                                  ? "Makas Kesim"
                                  : row.cutId === 8
                                  ? "Makas Kesim İşçiliği"
                                  : row.cutId === 9
                                  ? "Makas Büküm İşçiliği"
                                  : row.cutId === 10
                                  ? "Abkant Büküm"
                                  : row.cutId === 11
                                  ? "Abkant Büküm İşçiliği"
                                  : "-"}
                              </td>
                              <th style={this.thStyle2}>Cins</th>
                              <td>
                                {row.cutTypeId === 1
                                  ? "Baklavalı"
                                  : row.cutTypeId === 2
                                  ? "St37"
                                  : row.cutTypeId === 3
                                  ? "HRP"
                                  : row.cutTypeId === 4
                                  ? "DKP"
                                  : row.cutTypeId === 5
                                  ? "St52"
                                  : row.cutTypeId === 6
                                  ? "Paslanmaz"
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Toplam Adet</th>
                              <td style={this.tdStyle}>{row.piece}</td>
                              <th style={this.thStyle2}>Toplam Kg</th>
                              <td>{row.kg}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Toplam Süre</th>
                              <td style={this.tdStyle}>{row.time}</td>
                              <th style={this.thStyle2}>Toplam Lazer Kesim</th>
                              <td>{row.laserCutting}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Toplam Fiyat</th>
                              <td style={this.tdStyle}>
                                {row.totalKg.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) + " ₺"}
                              </td>
                              <th style={this.thStyle2}>
                                Son Ekleyen Kullanıcı
                              </th>
                              <td>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  // {
                  //   selector: "status",
                  //   width: "110px",
                  //   padding: "0px",
                  //   sortable: false,
                  //   cell: (row) => {
                  //     if (
                  //       row.processStatus === 1
                  //     ) {
                  //       return (
                  //         <CgnBadge
                  //           color="success"
                  //           className="w-100"
                  //           text={'Bekliyor'}
                  //         />
                  //       );
                  //     } else if(row.processStatus === 2){
                  //         return <CgnBadge color="danger" className="w-100" text={'Onaylandı'} />;
                  //       }
                  //       else{
                  //         return <CgnBadge color="success" className="w-100" style={{backgroundColor: "#ff7600"}} text={'İşlemde'} />;
                  //       }

                  //     }
                  // },
                  // {
                  //   ignoreRowClick: true,
                  //   allowOverflow: true,
                  //   button: true,
                  //   cell: (row, cell) => (
                  //     <CgnButtonDropdown
                  //       label={"Yönet"}
                  //       className="mr-0 w-100"
                  //       color="primary"
                  //       items={[
                  //         {
                  //           text: CgnMessage.printButton,
                  //           className: "dropDownBtn",
                  //           href: `/${this.state.pageProjectLink}/${row.fileName}`,
                  //         },
                  //         {
                  //           text: CgnMessage.detailButton,
                  //           className: "dropDownBtn",
                  //           href: `/${this.state.pageDetailLink}/${row.guid}`,
                  //         },
                  //       ]}
                  //     />
                  //   ),
                  // },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CuttingReportList;
