import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  fotoStyle,
  PaymentTypeOptions,
  URL,
  
} from "../../redux/actions/constants";
import noImage from "../../images/no-img.png";

class PaymentDetail extends Component {
  state = {
    pageTitle: "Ödeme Detay",
    pageTitleDetail: "Ödeme Detay",
    pageAddLink: "odeme-ekle",
    pageListLink: "gider-detay",
    breadcrumb: [{ text: "Ödeme", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    costGuid: "",
    paymentDate: "",
    receiptNo: "",
    expiry: null,
    paymentMethodValue: "",
    bankName: "",
    branchCode: "",
    branchName: "",
    accountNo: "",
    currencyType: 0,
    iban: "",
    chequesBondNo: "",
    total: null,
    description: "",
    image: "",
    status: "",
    isOpen: false,
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/payments/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            costGuid: response.data.costGuid,
            paymentDate: response.data.paymentDate,
            receiptNo: response.data.receiptNo,
            expiry: response.data.expiry,
            paymentMethodValue: response.data.paymentMethodType,
            bankName: response.data.bankName,
            branchCode: response.data.branchCode,
            branchName: response.data.branchName,
            accountNo: response.data.accountNo,
            iban: response.data.iban,
            chequesBondNo: response.data.chequesBondNo,
            description: response.data.description,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            currencyType: response.data.currencyType,
            image: response.data.image,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          //addLink={"/" + this.state.pageAddLink}
          //listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Ödeme Tarihi</th>
                    <td colSpan="4">{this.state.paymentDate}</td>
                  </tr>

                  <tr>
                    <th>Makbuz No</th>
                    <td colSpan="4">
                      {this.state.receiptNo ? this.state.receiptNo : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Vade</th>
                    <td colSpan="4">
                      {this.state.expiry > 0 ? this.state.expiry : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ödeme Yöntemi Türü</th>
                    <td colSpan="4">
                      {PaymentTypeOptions(this.state.paymentMethodValue)}
                    </td>
                  </tr>

                  <tr>
                    <th>Banka Adı</th>
                    <td colSpan="4">
                      {this.state.bankName ? this.state.bankName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Banka Şube Adı</th>
                    <td>
                      {this.state.branchName ? this.state.branchName : "-"}
                    </td>

                    <th>Banka Şube Kodu</th>
                    <td>
                      {this.state.branchCode ? this.state.branchCode : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Banka Hesap Numarası</th>
                    <td>{this.state.accountNo ? this.state.accountNo : "-"}</td>

                    <th>Iban</th>
                    <td>{this.state.iban ? this.state.iban : "-"}</td>
                  </tr>

                  <tr>
                    <th>Çek/Senet Numarası</th>
                    <td colSpan="4">
                      {this.state.chequesBondNo
                        ? this.state.chequesBondNo
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Tutar</th>
                    <td colSpan="4">
                      {this.state.total +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")}
                    </td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>

                  {/* <tr>
                    <th>Durum</th>
                    <th className="colon">:</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr> */}

                  <tr>
                    <th>Makbuz Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.image ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.image}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImage}
                          style={fotoStyle}
                          alt={this.state.receiptNo}
                        />
                      )}
                    </td>
                  </tr>

                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.image}
                      style={fotoStyle}
                      alt={this.state.receiptNo}
                    />
                  </Modal>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink + "/" + this.state.costGuid}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PaymentDetail;
