import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  MembershipList,
  statusStatusMedikoz,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";
class EMailQuestList extends Component {
  state = {
    pageTitle: "E-Mail Görev Listele",
    pageAddLink: "e-mail-gorev-ekle",
    pageUpdateLink: "e-mail-gorev-guncelle",
    pageDetailLink: "e-mail-gorev-detay",
    pageListLink: "e-mail-gorev-listele",
    breadcrumb: [{ text: "E-Mail Görev", link: "#" }],
    isLoading: false,
    isDeleted: false,
    data: [],
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    membershipGuid: "00000000-0000-0000-0000-000000000000",
    membershipArr: [],
    status: 0,
  };
  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();
    if (localStorage.getItem("firstDateEMailQuestList")) {
      firstDate = localStorage.getItem("firstDateEMailQuestList");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateEMailQuestList")) {
      lastDate = localStorage.getItem("lastDateEMailQuestList");
      lastDate2 = new Date(lastDate);
    }
    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });
    var self = this;
    await MembershipList().then(function (data) {
      self.setState({
        membershipArr: data,
      });
    });
    if (localStorage.getItem("membershipGuidEMailQuestList")) {
      this.setState({
        membershipGuid: localStorage.getItem("membershipGuidEMailQuestList"),
      });
    }
    this.getList();
  }
  getList = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(
        URL +
          "/emailquest/getlist" +
          "/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate +
          "/" +
          this.state.membershipGuid +
          "/" +
          this.state.status,
        config
      )
      .then((response) => {
        this.setState({
          data: response.data.eMailQuestDto,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };
  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };
  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/emailquest/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }
  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateEMailQuestList", this.state.firstDate);
  }
  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateEMailQuestList", this.state.lastDate);
  }
  async handleMembershipSelectChange(option) {
    if (option) {
      await this.setState({
        membershipGuid: option.value,
      });
    } else {
      await this.setState({
        membershipGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem(
      "membershipGuidEMailQuestList",
      this.state.membershipGuid
    );
  }
  handleStatusSelectChange(status) {
    if (status) {
      this.setState({ status: status.value });
    } else {
      this.setState({ status: 0 });
    }
  }
  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="EMailQuestList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="4">
                  <CgnReactSelect
                    name="membershipGuid"
                    label="Kullanıcı"
                    placeholder="Kullanıcı seçiniz..."
                    selectValue={this.state.membershipGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleMembershipSelectChange(option)
                    }
                    options={this.state.membershipArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="3">
                  <CgnReactSelect
                    name="status"
                    label="Durumu"
                    placeholder="Durumu seçiniz..."
                    selectValue={this.state.status}
                    isMulti={false}
                    isClearable={true}
                    onChange={(status) => this.handleStatusSelectChange(status)}
                    options={statusStatusMedikoz}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.data}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Tarih",
                    selector: "creationDate",
                    width: "100px",
                    sortable: false,
                  },
                  {
                    name: "Planlı Bitiş",
                    selector: "plannedEndDate",
                    width: "110px",
                    sortable: false,
                  },
                  {
                    name: "Gönderen",
                    selector: "senderEMail",
                    width: "250px",
                    sortable: false,
                  },
                  {
                    name: "Kullanıcı",
                    selector: "membershipName",
                    width: "200px",
                    sortable: false,
                  },
                  {
                    name: "Konu",
                    selector: "name",
                    sortable: false,
                  },
                  {
                    selector: "status",
                    width: "50px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.isReply) {
                        return (
                          <CgnBadge
                            color="warning"
                            text="!"
                            className="w-100 font-weight-bold"
                          />
                        );
                      }
                    },
                  },
                  {
                    name: "Derece",
                    selector: "dayCount",
                    width: "125px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status !== 3) {
                        if (row.dayCount < 1) {
                          return (
                            <CgnBadge
                              color="success"
                              text={`${row.dayCount + 1}. Gün`}
                              className="w-100"
                            />
                          );
                        } else if (row.dayCount < 2) {
                          return (
                            <CgnBadge
                              color="info"
                              text={`${row.dayCount + 1}. Gün`}
                              className="w-100"
                            />
                          );
                        } else if (row.dayCount < 3) {
                          return (
                            <CgnBadge
                              color="warning"
                              text={`${row.dayCount + 1}. Gün`}
                              className="w-100"
                            />
                          );
                        } else {
                          return (
                            <CgnBadge
                              color="danger"
                              text={`${row.dayCount + 1}. Gün`}
                              className="w-100"
                            />
                          );
                        }
                      }
                    },
                  },
                  {
                    name: "Durumu",
                    selector: "status",
                    width: "125px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status === 1) {
                        return (
                          <CgnBadge
                            color="danger"
                            text="Bekliyor"
                            className="w-100"
                          />
                        );
                      } else if (row.status === 2) {
                        return (
                          <CgnBadge
                            color="warning"
                            text="İşlemde"
                            className="w-100"
                          />
                        );
                      } else if (row.status === 3) {
                        return (
                          <CgnBadge
                            color="success"
                            text="Tamamlandı"
                            className="w-100"
                          />
                        );
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text:
                              row.membershipGuid ===
                                localStorage.getItem("membershipGuid") &&
                              row.senderEMail ===
                                localStorage.getItem("membershipEMail")
                                ? CgnMessage.updateButton
                                : "",
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
export default EMailQuestList;
