/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Form,
} from "reactstrap";
import Logo from "../../images/cogen-crm-logo.png";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButton from "../toolbox/CgnButton";
import CgnLoader from "../toolbox/CgnLoader";
import packageJson from "../../../package.json";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { URL } from "../../redux/actions/constants";
import Logo1 from "../../images/medikozlogo.png";
import Logo3 from "../../images/alkomlogo.png";

class Login extends React.Component {
  state = {
    userName: "",
    userNameError: "",
    password: "",
    passwordError: "",
    alertErrorMessage: "",
    isLoading: false,
    error: "",
    success: {},
    googleRecaptcha: "",
    companyId: 0,
    errorCount: 0,
  };

  async componentDidMount() {
    let urlSplit = window.location.href;
    if (
      urlSplit === "https://crm.medikozceviri.com/" ||
      urlSplit === "https://crm.cevirikalitesi.com/"
    ) {
      this.setState({
        companyId: 1,
      });
    }
    if (urlSplit === "https://kg.somelsac.com/") {
      this.setState({
        companyId: 2,
      });
    }
    if (
      urlSplit === "https://crm.alkom.com.tr/"
    ) {
      this.setState({
        companyId: 3,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      userNameError: "",
      passwordError: "",
      alertErrorMessage: "",
      error: "",
      success: {},
    });
    if (name === "userName" && value === "") {
      this.setState({
        userNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "password" && value === "") {
      this.setState({
        passwordError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  onChangeGoogleRecaptcha = (value) => {
    this.setState({
      googleRecaptcha: value,
      alertErrorMessage: "",
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      userNameError: "",
      passwordError: "",
      alertErrorMessage: "",
      errorCount: this.state.error++,
    });
    if (this.state.errorCount >= 2) {
      localStorage.setItem("timecount", 12389);
    }
    if (this.state.userName === "") {
      this.setState({
        userNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    else if (this.state.password === "") {
      this.setState({
        passwordError: CgnMessage.selectErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    else if ((this.state.errorCount >= 2 && this.state.googleRecaptcha === "") || localStorage.getItem("timecount") === 12389) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    else {
      this.setState({
        isLoading: true,
      });
      var login = {
        username: this.state.userName,
        password: this.state.password,
      };
      axios
        .post(URL + "/auth/login/", login)
        .then((response) => {
          this.setState({
            success: response.data,
          });
          var bakimgec = localStorage.getItem("bakimgec");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("expiration", response.data.expiration);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("guid", response.data.id);
          localStorage.setItem("api", "1");
          localStorage.setItem("isAdmin", response.data.isAdmin);
          localStorage.setItem("locationGuid", response.data.locationGuid);
          localStorage.setItem("companyGuid", response.data.companyGuid);
          localStorage.setItem("birthdayMonth", response.data.birthdayMonth);
          localStorage.setItem("birthdayDay", response.data.birthdayDay);
          localStorage.setItem("membershipGuid", response.data.membershipGuid);
          localStorage.setItem("membershipEMail", response.data.membershipEMail);
          localStorage.setItem("birthday", 0);
          localStorage.setItem("isOrderCount", response.data.isOrderCount);
          localStorage.setItem("isProductCountMachine", response.data.isProductCountMachine);
          localStorage.setItem("bakimgec", bakimgec);
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            error: error.response.data,
          });
        });
    }
  };

  render() {
    return (
      <div className="login-page">
        <Card
          style={{
            display:
              this.state.companyId === 0
                ? ""
                : "none",
          }}
        >
          <CgnLoader visibled={this.state.isLoading} />
          <CardHeader>
            <Row>
              <Col md="7">
                <h4>{CgnMessage.programName}</h4>
                <h1>Kullanıcı Giriş</h1>
              </Col>
              <Col md="5" className="pl-0">
                <a
                  href="https://www.cogen.com.tr"
                  alt="Cogen® Yazılım | CRM"
                  target="blank"
                >
                  <img src={Logo} alt="Cogen® Yazılım | CRM" className="logo" />
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.handleSave}>
              <CgnTextbox
                type="text"
                name="userName"
                label="Kullanıcı Adı [*]"
                value={this.state.userName}
                placeHolder="Lütfen kullanıcı adı girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.userNameError}
              />
              <CgnTextbox
                type="password"
                name="password"
                label="Şifre [*]"
                value={this.state.password}
                placeHolder="Lütfen şifre girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.passwordError}
              />
              {(this.state.errorCount >= 2 || localStorage.getItem("timecount") === 12389) && (
                <ReCAPTCHA
                  sitekey="6LdR6QQbAAAAAKmceasCKrr-0edWssWva5K672E0"
                  onChange={this.onChangeGoogleRecaptcha}
                  className="recaptcha text-center form-group"
                />
              )}

              <Row className="forgotInfoText">
                <Col md="12" className="mb-3">
                  <a href="/bilgilerimi-unuttum" className="float-right">
                    Bilgilerimi unuttum!
                  </a>
                </Col>
              </Row>
              {(this.state.alertErrorMessage || this.state.error) && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage || this.state.error}
                />
              )}

              <CgnButton
                type="submit"
                color="primary"
                block={true}
                text="Giriş Yap"
                disabled={this.state.isLoading}
              />
            </Form>
          </CardBody>
          <CardFooter>
            <p className="m-0">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://www.cogen.com.tr"
                alt="Cogen® Yazılım | CRM"
                target="blank"
              >
                Cogen® Yazılım | CRM
              </a>{" "}
              | Tüm hakları saklıdır.
              <br />
              Versiyon : {packageJson.version}
            </p>
          </CardFooter>
        </Card>
        <Card style={{ display: this.state.companyId === 1 ? "" : "none" }}>
          <CgnLoader visibled={this.state.isLoading} />
          <CardHeader>
            <Row>
              <Col md="7">
                <h4>{CgnMessage.programName}</h4>
                <h1>Kullanıcı Giriş</h1>
              </Col>
              <Col md="5" className="pl-0">
                <a
                  href="https://www.medikozceviri.com/"
                  alt="Medikoz Tercüme | CRM"
                  target="blank"
                >
                  <img
                    src={Logo1}
                    alt="Medikoz Tercüme | CRM"
                    className="logo"
                  />
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.handleSave}>
              <CgnTextbox
                type="text"
                name="userName"
                label="Kullanıcı Adı [*]"
                value={this.state.userName}
                placeHolder="Lütfen kullanıcı adı girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.userNameError}
              />
              <CgnTextbox
                type="password"
                name="password"
                label="Şifre [*]"
                value={this.state.password}
                placeHolder="Lütfen şifre girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.passwordError}
              />
              <ReCAPTCHA
                sitekey="6LeKJBgiAAAAAJUheFf2_cvBZ7BQBQ4pdmgAkAMc"
                onChange={this.onChangeGoogleRecaptcha}
                className="recaptcha text-center form-group"
              />

              <Row className="forgotInfoText">
                <Col md="12" className="mb-3">
                  <a href="/bilgilerimi-unuttum" className="float-right">
                    Bilgilerimi unuttum!
                  </a>
                </Col>
              </Row>
              {(this.state.alertErrorMessage || this.state.error) && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage || this.state.error}
                />
              )}

              <CgnButton
                type="submit"
                color="primary"
                block={true}
                text="Giriş Yap"
                disabled={this.state.isLoading}
              />
            </Form>
          </CardBody>
          <CardFooter>
            <p className="m-0">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://www.medikozceviri.com/"
                alt="Medikoz Tercüme| CRM"
                target="blank"
              >
                Medikoz Tercüme | CRM
              </a>{" "}
              | Tüm hakları saklıdır.
              <br />
              Versiyon : {packageJson.version}
            </p>
          </CardFooter>
        </Card>
        <Card style={{ display: this.state.companyId === 2 ? "" : "none" }}>
          <CgnLoader visibled={this.state.isLoading} />
          <CardHeader>
            <Row>
              <Col md="7">
                <h4>{CgnMessage.programName}</h4>
                <h1>Kullanıcı Giriş</h1>
              </Col>
              <Col md="5" className="pl-0">
                <a
                  href="https://www.cogen.com.tr"
                  alt="Cogen® Yazılım | CRM"
                  target="blank"
                >
                  <img src={Logo} alt="Cogen® Yazılım | CRM" className="logo" />
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.handleSave}>
              <CgnTextbox
                type="text"
                name="userName"
                label="Kullanıcı Adı [*]"
                value={this.state.userName}
                placeHolder="Lütfen kullanıcı adı girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.userNameError}
              />
              <CgnTextbox
                type="password"
                name="password"
                label="Şifre [*]"
                value={this.state.password}
                placeHolder="Lütfen şifre girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.passwordError}
              />
              <ReCAPTCHA
                sitekey="6LepSgcjAAAAAKLUC_T9TpTStKh7SLHCHV854lyK"
                onChange={this.onChangeGoogleRecaptcha}
                className="recaptcha text-center form-group"
              />

              <Row className="forgotInfoText">
                <Col md="12" className="mb-3">
                  <a href="/bilgilerimi-unuttum" className="float-right">
                    Bilgilerimi unuttum!
                  </a>
                </Col>
              </Row>
              {(this.state.alertErrorMessage || this.state.error) && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage || this.state.error}
                />
              )}

              <CgnButton
                type="submit"
                color="primary"
                block={true}
                text="Giriş Yap"
                disabled={this.state.isLoading}
              />
            </Form>
          </CardBody>
          <CardFooter>
            <p className="m-0">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://www.cogen.com.tr"
                alt="Cogen® Yazılım | CRM"
                target="blank"
              >
                Cogen® Yazılım | CRM
              </a>{" "}
              | Tüm hakları saklıdır.
              <br />
              Versiyon : {packageJson.version}
            </p>
          </CardFooter>
        </Card>
        <Card style={{ display: this.state.companyId === 3 ? "" : "none" }}>
          <CgnLoader visibled={this.state.isLoading} />
          <CardHeader>
            <Row>
              <Col md="7">
                <h4>{CgnMessage.programName}</h4>
                <h1>Kullanıcı Giriş</h1>
              </Col>
              <Col md="5" className="pl-0">
                <a
                  href="https://www.alkom.com.tr"
                  alt="Alkom | CRM"
                  target="blank"
                >
                  <img src={Logo3} alt="Alkom | CRM" className="logo" />
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.handleSave}>
              <CgnTextbox
                type="text"
                name="userName"
                label="Kullanıcı Adı [*]"
                value={this.state.userName}
                placeHolder="Lütfen kullanıcı adı girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.userNameError}
              />
              <CgnTextbox
                type="password"
                name="password"
                label="Şifre [*]"
                value={this.state.password}
                placeHolder="Lütfen şifre girin..."
                maxLength="50"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.passwordError}
              />
              <ReCAPTCHA
                sitekey="6LdR6QQbAAAAAKmceasCKrr-0edWssWva5K672E0"
                onChange={this.onChangeGoogleRecaptcha}
                className="recaptcha text-center form-group"
              />

              <Row className="forgotInfoText">
                <Col md="12" className="mb-3">
                  <a href="/bilgilerimi-unuttum" className="float-right">
                    Bilgilerimi unuttum!
                  </a>
                </Col>
              </Row>
              {(this.state.alertErrorMessage || this.state.error) && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage || this.state.error}
                />
              )}

              <CgnButton
                type="submit"
                color="primary"
                block={true}
                text="Giriş Yap"
                disabled={this.state.isLoading}
              />
            </Form>
          </CardBody>
          <CardFooter>
            <p className="m-0">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://www.alkom.com.tr"
                alt="Alkom | CRM"
                target="blank"
              >
                Alkom | CRM
              </a>{" "}
              | Tüm hakları saklıdır.
              <br />
              Versiyon : {packageJson.version}
            </p>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default Login;
