import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Modal, ModalHeader
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import axios from "axios";
import {
  URL,
  localFilePath,
  fotoStyle,
  fotoStyle2,
  resizeFile
} from "../../redux/actions/constants";
import pdfIcon from "../../images/pdfIcon.png";
import CgnMessage from "../toolbox/CgnMessage";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnTextbox from "../toolbox/CgnTextbox";
import ImageUpload from "../toolbox/CgnImageUpload";
import CgnButton from "../toolbox/CgnButton";
import ExcelUploadMulti from "../toolbox/CgnExcelUploadMulti";
import excelIcon from "../../images/xlsIcon.png";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";
import medikozLogo from "../../images/medikozlogo.png";

class CustomerProductTicketDetail extends Component {
  state = {
    pageTitle: "Müşteri Ürün Bilgilendirme Sistemi",
    pageTitleDetail: "Müşteri Ürün Bilgilendirme Sistemi",
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    productName: "",
    barcode: "",
    productImage: "",
    tag: "",
    productNameError: "",
    productImageError: "",
    tagError: "",
    productName1: "",
    description: "",
    gtipNoError: "",
    gtipNo: "",
    productName2: "",
    customerProductTicketImageDto: [],
    customerProductDocumentGuid: "",
    customerTicket2Dto: [],
    barcodeDocumentDto: [],
    barcodeProductImageDto: [],
    gtipDocumentDto: [],
    descriptionError: "",
    descriptionHtml: "",
    isOpen: false,
    status: "",
    customerMail: "",
    customerGuid: "",
    personelName: "",
    offerTotal: "",
    total: 0,
    currencyType: "",
    guid: "",
    createdAt: "",
    isLoadingModal: false,
    isHiddenSuitable: true,
    isHiddenUnSuitable: true, 
    isOpenModalPayment: false,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ]
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
      this.setState({
        isLoading: true,
      });
      this.state.customerGuid = urlSplit[2];
      this.state.customerProductDocumentGuid = urlSplit[3];
      this.state.personelName = urlSplit[4];
      axios
        .get(URL + "/customerProductTickets/getlist/" +  this.state.customerGuid + "/" +  this.state.customerProductDocumentGuid)
        .then((response) => {
          this.setState({
            customerGuid: response.data.customerGuid,
            customerName: response.data.customerName,
            description: this.state.description,
            createdAt: response.data.createdAt,
            customerProductDocumentGuid: response.data.customerProductDocumentGuid,
            customerTicket2Dto: response.data.customerTicket2Dto,
            productImage: response.data.productImage,
            productName: response.data.productName,
            barcode: response.data.barcode,
            tag: response.data.tag,
            gtipNo: response.data.gtipNo,
            isLoading: false,
            offerTotal: response.data.offerTotal,
            total: response.data.total,
            currencyType: response.data.currencyType,
            barcodeDocumentDto: response.data.barcodeDocumentDto,
            barcodeProductImageDto: response.data.barcodeProductImageDto,
            gtipDocumentDto: response.data.gtipDocumentDto,

          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      productImage: image,
      productName2: event.target.files[0].name,
      //imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      productImage: "",
    });
  };

  uploadedImage1 = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      tag: image,
      productName1: event.target.files[0].name,
      //imageName: event.target.files[0].name,
    });
  };

  deleteImage1 = (event) => {
    this.setState({
      tag: "",
    });
  };

  uploadedImage3 = async (event) => {
    let array = this.state.barcodeDocumentDto;
    for(var i = 0; i < event.target.files.length; i++){
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
              image: response.data,
              imageName: file.name
          })
          this.state.name2 += file.name + " ";
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    } 
    await this.setState({barcodeDocumentDto: array});
  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.barcodeDocumentDto;
     this.state.barcodeDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({barcodeDocumentDto: array});
          }
        }
     });
  };

  uploadedImage4 = async (event) => {
    let array = this.state.barcodeProductImageDto;
    for(var i = 0; i < event.target.files.length; i++){
      const file = event.target.files[i];
      if(file.type === "image/png" || file.type === "image/jpeg"){
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        })
      }
    }
    await this.setState({barcodeProductImageDto: array});
  }

  deleteImage4 = (event) => {
    var index = -1;
    let array = this.state.barcodeProductImageDto;
     this.state.barcodeProductImageDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({barcodeProductImageDto: array});
          }
        }
     });
  };

  uploadedImage5 = async (event) => {
    let array = this.state.gtipDocumentDto;
    for(var i = 0; i < event.target.files.length; i++){
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
              image: response.data,
              imageName: file.name
          })
          this.state.name2 += file.name + " ";
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    } 
    await this.setState({gtipDocumentDto: array});
  };

  deleteImage5 = (event) => {
    var index = -1;
    let array = this.state.gtipDocumentDto;
     this.state.gtipDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({gtipDocumentDto: array});
          }
        }
     });
  };

 

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      productNameError: "",
      productImageError: "",
      tagError: "",
      gtipNoError: "",
    });
    if(this.state.description === ""){
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoading: false
      });
    }
    if (this.state.productName === "" || this.state.productName === null) {
      this.setState({
        productNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.barcodeProductImageDto.length === 0) {
      this.setState({
        productImageError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }  
    if (this.state.tag === "" || this.state.tag === null) {
      this.setState({
        tagError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } 
    else if(this.state.description !== "" &&
           this.state.barcodeProductImageDto.length > 0 &&
            this.state.productName !== "" &&
            this.state.tag !== ""){
      var URLParam = "/customerProductTickets/add";     
      var myObj = {
        customerGuid: this.state.customerGuid,
        customerProductTicketImageDto: this.state.customerProductTicketImageDto,
        customerProductDocumentGuid: this.state.customerProductDocumentGuid,
        description: this.state.description,
        productImage: this.state.productImage,
        productName: this.state.productName,
        barcode: this.state.barcode,
        tag: this.state.tag,
        gtipNo: this.state.gtipNo,
        barcodeDocumentDto: this.state.barcodeDocumentDto,
        barcodeProductImageDto: this.state.barcodeProductImageDto,
        gtipDocumentDto: this.state.gtipDocumentDto,
      };
       await axios
        .post(URL + URLParam, myObj)
        .then((response) => {
          setTimeout(() => {
             window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
    else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoading: false,
      });
    }
  }


  async handleSendPayment(offerTotal) {
    var kalanTutar = (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)).toFixed(2);
    window.location.href = "https://www.medikozceviri.com/Odeme/Odeme?total=" + kalanTutar + "&customerGuid=" + this.state.customerGuid + "&guid=" + this.state.customerProductDocumentGuid;
}

  uploadedImage2 = async (event) => {
    let array = this.state.customerProductTicketImageDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
      if(file.type === "application/pdf"){
        const formData = new FormData();
        formData.append("files", file);
        await axios
          .post(URL + "/uploads2/add", formData)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
   await this.setState({customerProductTicketImageDto: array});

  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.customerProductTicketImageDto;
     this.state.customerProductTicketImageDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({customerProductTicketImageDto: array});
          }
        }
     });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggle1 = () => {
    this.setState({
      isOpen1: !this.state.isOpen1,
    });
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };

    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    var self1 = this;
    var self = this.state;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <p style={{fontSize: "30px", color:"#cd0b0b", paddingLeft:"20px", marginTop:"25px", fontWeight:"bold"}}>Müşteri Ürün Bilgilendirme Sistemi</p>
          </div>
        </div>
        <Row>
          <Col lg="12">
          <img
            src={medikozLogo}
            style={{width: "200px",
            height: "80px",
            padding: "2px",
            boxShadow: "0px 0px 5px #999",
            marginLeft: "20px"}}
            alt="medikozceviri"
           />
          </Col>
        </Row>
        <Row className="p-3">
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              <div className="row">
                <p style={{color: "red", paddingLeft:"20px"}}>Ödenmesi Gereken Toplam Tutar : {(parseFloat(this.state.offerTotal) - parseFloat(this.state.total)).toFixed(2) + " " + (this.state.currencyType === 1 ? "TL" : this.state.currencyType === 2 ? "$" : 
                this.state.currencyType === 3 ? "€" : "")}</p>
              </div>
              <div className="row">
              <CgnButton
                  type="button"
                  color="primary"
                  style={{display: (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)) > 0  ? "" : "none", float:"right"}}
                  className="float-rigth ml-3"
                  text="Ödeme Ekle"
                  onClick={() => self1.handleSendPayment(this.state.offerTotal)}
                />
              </div>
              <Table> 
                   <tr className="">
                        <th>Ürün Adı</th>
                        <td>{this.state.productName}</td>                       
                      </tr>

                  {/* <tr>
                    <th>Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.productImage !== "" ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.productImage}
                            style={fotoStyle}
                            alt={this.state.productName2}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.productName2}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.productImage}
                      style={fotoStyle}
                      alt={this.state.productName2}
                    />
                  </Modal> */}

                      <tr className="">
                        <th>Barkod</th>
                        <td style={{textAlign:"left"}}>{this.state.barcode}</td>                       
                      </tr>

                    <tr>
                    <th>Etiket</th>
                    <td colSpan="4">
                      {this.state.tag ? (
                        <Button
                          onClick={this.toggle1}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.tag}
                            style={fotoStyle}
                            alt={this.state.productName1}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.productName1}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal isOpen={this.state.isOpen1} toggle={this.toggle1}>
                    <ModalHeader toggle={this.toggle1}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.tag}
                      style={fotoStyle}
                      alt={this.state.productName1}
                    />
                  </Modal>
                      <tr className="">
                        <th>GTİP Numarası</th>
                        <td style={{textAlign:"left"}}>{this.state.gtipNo}</td>                       
                      </tr>  
              </Table>
              
              {this.state.customerTicket2Dto !== null &&
                this.state.customerTicket2Dto.length > 0 &&
                this.state.customerTicket2Dto.map(function (item) {
                  return (
                    <>
                <Table className="table table-responsive table-hover table-product2 mb-3">
                      <tr className="">
                        <th style={{borderTop: "1px solid #ddd", color:"#fff"}}>Müşteri</th>
                        <td style={{borderTop: "1px solid #ddd", textAlign:"left", color:"#fff"}}>{self.customerName}</td>                       
                      </tr>    
                      <tr className="">
                        <th>Eklenen Tarih</th>
                        <td style={{textAlign:"left"}}>{item.createdAt}</td>                       
                      </tr>       
                      <tr className="">
                        <th>Açıklama</th>
                        <td style={{textAlign:"left"}}>{item.description}</td>                       
                      </tr>            
                    </Table>
                    <Table>

              {item.customerProductTicketImageDto &&
                 item.customerProductTicketImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>İlave Belgeler</th>
                    </tr>
                    <Row>
                      {item.customerProductTicketImageDto.map(function (item1) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item1.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item1.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item1.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

               </Table>
               </>
                 );
              })}

              {this.state.barcodeDocumentDto &&
                this.state.barcodeDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Barkod Dosyaları</th>
                    </tr>
                    <Row>
                      {this.state.barcodeDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={excelIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}                
                    </Row>
                  </Table>
                )}   

              {this.state.gtipDocumentDto &&
                this.state.gtipDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Gtip Numarası Dosyaları</th>
                    </tr>
                    <Row>
                      {this.state.gtipDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={excelIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}                
                    </Row>
                  </Table>
                )}  

              {this.state.barcodeProductImageDto !== null &&
                this.state.barcodeProductImageDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Ürün Fotoğrafları</th>
                      </tr>
                      <Row>
                        {this.state.barcodeProductImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}     

            </Card>
            <Card body className="table-responsive mt-3">   
            <CgnLoader visibled={this.state.isLoading} />          
            <Form onSubmit={this.handleSave}>   
            <Row>
                  <Col lg="6">
                  <PictureUploadMulti
                      image={this.state.barcodeProductImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Ürün Fotoğrafları [*]"}
                      onChange={this.uploadedImage4}
                      onDelete={this.deleteImage4}
                      alt={this.state.name}
                      error={this.state.productImageError}
                    />
                  </Col>
                  <Col lg="6">
                  <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Etiket [*]"
                      image={this.state.tag}
                      noFoto={noImg}
                      alt={this.state.name}
                      onChange={this.uploadedImage1}
                      onDelete={this.deleteImage1}
                      error={this.state.tagError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                  <CgnTextbox
                      type="text"
                      name="productName"
                      label="Ürün Adı [*]"
                      value={this.state.productName}
                      placeHolder="Lütfen ürün adı girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.productNameError}
                    />
                  </Col>
                  <Col lg="6">
                  <CgnTextbox
                      type="text"
                      name="barcode"
                      label="Barkod"
                      value={this.state.barcode}
                      placeHolder="Lütfen barkod girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="6">
                  <ExcelUploadMulti
                      images={this.state.barcodeDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      excelIcon={excelIcon}
                      accept=".xlsx, .xls, .csv"
                      className="text-center text-danger"
                      label={"Excel Çoklu Barkod Dosyaları"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      //error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="6">
                  <ExcelUploadMulti
                      images={this.state.gtipDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      excelIcon={excelIcon}
                      accept=".xlsx, .xls, .csv"
                      className="text-center text-danger"
                      label={"Excel Çoklu Gtip Numarası Dosyaları"}
                      onChange={this.uploadedImage5}
                      onDelete={this.deleteImage5}
                      //error={this.state.imageError2}
                    />
                  </Col>
                  <Col lg="6">
                  <CgnTextbox
                      type="text"
                      name="gtipNo"
                      label="GTİP Numarası [*]"
                      value={this.state.gtipNo}
                      placeHolder="Lütfen GTİP Numarası girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.gtipNoError}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                <CgnTextbox
                        inlineStyle={textAreaStyle}
                        type="textarea"
                        name="description"
                        label="Açıklama"
                        value={this.state.description}
                        placeHolder="Lütfen açıklama girin..."
                        autoComplete="off"
                        onChange={this.handleChange}
                        error={this.state.descriptionError}
                      />
                </Col>           
                  <Col lg="12">
                  <ImageUploadMulti
                      images={this.state.customerProductTicketImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"İlave Belgeler (PDF)"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.customerProductTicketImageDtoError}
                    />
                    </Col>          
                </Row>
                       
                    <CgnButtonGroup items={this.state.buttons} />
                 </Form>
                {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
            </Card>          
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerProductTicketDetail;