import React, { Component } from "react";
import { Row, Col, Card, Table, Button } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  Mounth,
  Year,
  CustomerList,
  TypeList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnCheckbox from "../toolbox/CgnCheckbox";

class TypeTableList extends Component {
  state = {
    pageTitle: "Muhasebe Takip",
    pageAddLink: "tip-ekle",
    pageUpdateLink: "tip-guncelle",
    pageDetailLink: "tip-detay",
    pageListLink: "muhasebe-takip",
    breadcrumb: [{ text: "Muhasebe Takip", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    typeTableData: [],
    name: "",
    endDay: 0,
    optionDay: 0,
    remindPerson: 0,
    mounth: 0,
    year: 0,
    guid: "",
    customerGroupName: "",
    alertErrorMessage: "",
    selectedMonth: 0,
    selectedMonthValue: 0,
    selectedYear: 0,
    selectedYearValue: 0,
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    typeGuid: "00000000-0000-0000-0000-000000000000",
    typeArr: [],
    columns: [],
    rows: [],
    table: [],
    checked: false
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    if (this.state.selectedMonth === 0 && this.state.selectedYear === 0) {
      this.setState({
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
      });
    }

    if (localStorage.getItem("selectedMonthType")) {
      await this.setState({
        selectedMonth: parseInt(localStorage.getItem("selectedMonthType")),
      });
    }
    if (localStorage.getItem("selectedYearType")) {
      await this.setState({
        selectedYear: parseInt(localStorage.getItem("selectedYearType")),
      });
    }

    // 

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidTypeTable")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidTypeTable"),
      });
    }

    // await TypeList().then(function (data) {
    //   self.setState({
    //     typeArr: data,
    //   });
    // });

    // if (localStorage.getItem("typeGuidTypeTable")) {
    //   this.setState({
    //     typeGuid: localStorage.getItem("typeGuidTypeTable"),
    //   });
    // }

    this.setState({
      isLoading: false,
    });

    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    if (this.state.selectedMonth === 0 && this.state.selectedYear === 0) {
      this.setState({
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
      });
    }

    if (localStorage.getItem("selectedMonthTypeTable")) {
      await this.setState({
        selectedMonth: parseInt(localStorage.getItem("selectedMonthTypeTable")),
      });
    }
    if (localStorage.getItem("selectedYearTypeTable")) {
      await this.setState({
        selectedYear: parseInt(localStorage.getItem("selectedYearTypeTable")),
      });
    }

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    axios
      .get(URL + "/types/getlistselecttable/" +
      this.state.selectedMonth +
      "/" +
      this.state.selectedYear, config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          typeArr: data,
        });
    });
      
    // 
     config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
     statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    axios
      .get(URL + "/typetables/getlist/" +
      this.state.selectedMonth +
      "/" +
      this.state.selectedYear, config)
      .then((response) => {
        this.setState({
          typeTableData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleMounthChange(option) {
    if (option) {
      await this.setState({
        selectedMonth: option.value,
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        selectedMonth: "",
      });
    }
    localStorage.setItem("selectedMonthTypeTable", this.state.selectedMonth);
  }

  async handleYearChange(option) {
    if (option) {
      await this.setState({
        selectedYear: option.value,
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        selectedYear: "",
      });
    }
    localStorage.setItem("selectedYearTypeTable", this.state.selectedYear);
  }

  handleTypeChange(event, customerGuid, typeGuid) {
    if(event.target.checked === true){
      event.target.checked = true;
      // ekle
      this.setState({
        isLoading: true,
      });

      
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        mounth: this.state.selectedMonth,
        year: this.state.selectedYear,
        typeGuid: typeGuid,
        customerGuid: customerGuid,
        checkStatus: true
      };

      var URLParam = "/typetables/add";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.reload();        
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      event.target.checked = false;
      // eklediysen sil
      this.setState({
        isLoading: true,
      });

      
       config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      myObj = {
        mounth: this.state.selectedMonth,
        year: this.state.selectedYear,
        typeGuid: typeGuid,
        customerGuid: customerGuid,
        checkStatus: true
      };

      URLParam = "/typetables/delete";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.reload();        
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }


  handleCheckedControl (customerGuid, typeGuid){
    var checkstatus = false;
    this.state.typeTableData.map(function (item) { 
       if(item.customerGuid === customerGuid && item.typeGuid === typeGuid){
         checkstatus = true;
         return checkstatus;
       }
       else{
         return checkstatus;
       }   
    })

    return checkstatus;
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/types/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "50px",
  };

  tdStyle = {
    width: "300px",
  };

  render() {
    var self = this.state.typeArr;
    var self1 = this.state.checkStatus;
    var self2 = this;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="typeList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )} 
              <div className="monthFilter filterRow">
                <div className="row">
                  <div className="col-md-5">
                    <CgnReactSelect
                      name="selectedMounth"
                      className="selectedMounth"
                      label="Ay"
                      placeholder="Ay seçiniz..."
                      selectValue={this.state.selectedMonth}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleMounthChange(option)}
                      options={Mounth}
                      error={this.state.mounthError}
                    />
                  </div>
                  <div className="col-md-6">
                    <CgnReactSelect
                      name="selectedYear"
                      className="selectedYear"
                      label="Yıl"
                      placeholder="Yıl seçiniz..."
                      selectValue={this.state.selectedYear}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleYearChange(option)}
                      options={Year}
                      error={this.state.yearError}
                    />
                  </div>
                  <div className="col-md-1">
                    <Button
                      className="d-block mt-2 w-100 btnReport"
                      onClick={(date) => this.getList(date)}
                    >
                      Listele
                    </Button>
                  </div>
                </div>
              </div>
              <Row>
                {this.state.customerArr !== null &&
                  this.state.customerArr.length > 0 && (
                    <Table className="table table-responsive table-hover">
                      <tbody>
                        <tr>
                          <td className="customerTd" style={{width:"200px"}}>Müşteriler</td>
                          {this.state.typeArr.map(function (item) {
                            return <td className="typeTd" style={{width:"30px"}}>{item.label}</td>;
                          })}
                        </tr>

                        {this.state.customerArr.map(function (item) {
                          return (
                            <tr>
                              <td style={{ width: "200px"}} className="itemLabel">{item.label + " " + item.customerGroupName}</td>
                              {self.map((key) => (
                                <td key={"checkbox-" + item.value + "-" + key.value}>
                                  <CgnCheckbox
                                  //checked={self2.checked}
                                  checked={self2.handleCheckedControl(item.value, key.value)}
                                  name={"checkbox-" + item.value + "-" + key.value}
                                  onChange={(event) => self2.handleTypeChange(event, item.value, key.value)}>                                
                                  </CgnCheckbox>
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default TypeTableList;
