import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  HazirunStatus,
  VoteStatus,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";

class KingUserAddUpdate extends Component {
  state = {
    pageTitle: "Üye Ekle",
    pageTitleAdd: "Üye Ekle",
    pageTitleUpdate: "Üye Güncelle",
    pageAddLink: "uye-ekle",
    pageListLink: "uye-listele",
    breadcrumb: [{ text: "Üye", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    roomNo: 0,
    registrationNo: 0,
    vote: false,
    hazirunStatus: null,
    guid: "",
    status: true,
    nameError: "",
    roomNoError: "",
    registartionNoError: "",
    voteError: "",
    hazirunStatusError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/kingUsers/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              name: response.data.name,
              roomNo: response.data.roomNo,
              registrationNo: response.data.registrationNo,
              vote: response.data.vote,
              hazirunStatus: response.data.hazirunStatus,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        roomNo: 0,
        registrationNo: 0,
        vote: false,
        hazirunStatus: false,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleNameChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };


  handleRegistrationChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      registrationError: "",
      alertErrorMessage: "",
    });  
    if (name === "registrationNo" && value === "") {
      this.setState({
        registrationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleRoomNoChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      roomNoError: "",
      alertErrorMessage: "",
    });  
    if (name === "roomNo" && value === "") {
      this.setState({
        roomNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleHazirunStatusChange(option) {
    if (option) {
      this.setState({
        hazirunStatus: option.value,
        hazirunStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        hazirunStatus: false,
        hazirunStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleVoteStatusChange(option) {
    if (option) {
      this.setState({
        vote: option.value,
        voteError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        vote: false,
        voteError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      roomNo: 0,
      registrationNo: 0,
      vote: false,
      hazirunStatus: false,
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
      roomNoError: "",
      hazirunStatusError: "",
      registrationError: "",
      voteError: "",   
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.roomNo === 0 || this.state.roomNo === "") {
      this.setState({
        roomNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }  
    if (this.state.registrationNo === 0 || this.state.registrationNo === "") {
      this.setState({
        registrationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.hazirunStatus === null) {
      this.setState({
        hazirunStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.vote === null) {
      this.setState({
        voteError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    else if (this.state.name !== "" &&
            (this.state.roomNo !== 0) &&
            (this.state.registrationNo !== 0) &&
            this.state.vote !== null &&
            this.state.hazirunStatus !== null
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        name: this.state.name,
        roomNo: parseInt(this.state.roomNo),
        registrationNo: parseInt(this.state.registrationNo),
        hazirunStatus: this.state.hazirunStatus,
        vote: this.state.vote,
        status: true,
      };
      var URLParam = "/kingUsers/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "uye-guncelle"
      ) {
        URLParam = "/kingUsers/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          roomNo: parseInt(this.state.roomNo),
          registrationNo: parseInt(this.state.registrationNo),
          hazirunStatus: this.state.hazirunStatus,
          vote: this.state.vote,
          status: true,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "uye-guncelle"
            ) {
              window.location.href = "/uye-listele";
            }
            if (this.state.urlSplit[1] === "uye-ekle") {
              window.location.reload();
            }
            var modalAddedKingUser = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedKingUser });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "uye-ekle" ||
          this.state.urlSplit[1] === "uye-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Ad [*]"
                      value={this.state.name}
                      placeHolder="Lütfen ad girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNameChange}
                      error={this.state.nameError}
                    />
                  </Col>
                  </Row>
                                   
               
                <Row>
                <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="roomNo"
                      label="Oda Sicil No [*]"
                      value={this.state.roomNo}
                      placeHolder="Lütfen oda sicil no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleRoomNoChange}
                      error={this.state.roomNoError}
                    />
                  </Col>          
                <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="registrationNo"
                      label="Esnaf Sicil No [*]"
                      value={this.state.registrationNo}
                      placeHolder="Lütfen esnaf sicil no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleRegistrationChange}
                      error={this.state.registrationError}
                    />
                  </Col>        
                 <Col lg="3">
                 <CgnReactSelect
                      name="hazirunStatus"
                      label="Hazirun Durumu [*]"
                      placeholder="Hazirun Durumu seçiniz..."
                      selectValue={this.state.hazirunStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleHazirunStatusChange(option)}
                      options={HazirunStatus}
                      error={this.state.hazirunStatusError}
                    />
                 </Col>
                 <Col lg="3">
                 <CgnReactSelect
                      name="vote"
                      label="Oy Durumu [*]"
                      placeholder="Oy Durumu seçiniz..."
                      selectValue={this.state.vote}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleVoteStatusChange(option)}
                      options={VoteStatus}
                      error={this.state.voteError}
                    />
                 </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "uye-ekle" ||
              this.state.urlSplit[1] === "uye-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(KingUserAddUpdate);
