import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class CastingCostDetailList extends Component {
  state = {
    pageTitle: "Tüm Döküm Maliyetleri",
    pageTitleDetail: "Tüm Döküm Maliyetleri",
    pageAddLink: "tum-dokum-maliyetleri",
    pageListLink: "tum-dokum-maliyetleri",
    breadcrumb: [{ text: "Tüm Döküm Maliyetleri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    castingCostData: [],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/castingCosts/getlistcost/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            castingCostData: response.data,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "180px",
  };

  thStyle1 = {
    width: "200px",
    display: "none",
  };

  tdStyle1 = {
    width: "200px",
    display: "none",
  };

  render() {
    var self = this;
    var self1 = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              {this.state.castingCostData !== null &&
                this.state.castingCostData.length > 0 && (
                  <>
                    {this.state.castingCostData.map(function (item) {
                      return (
                        <>
                          <Table className="mb-3 table-product3">
                            <tbody>
                              <tr>
                                <th style={{ color: "#fff" }}>Teklif</th>
                                <td style={{ color: "#fff" }} colSpan="4">
                                  {item.offerSieveName
                                    ? item.offerSieveName
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Malzeme</th>
                                <td colSpan="4">
                                  {item.sieveProductName
                                    ? item.sieveProductName
                                    : "-"}
                                </td>
                              </tr>

                              <tr>
                                <th>Elek Boyutu (mm)</th>
                                <td>{item.sieveSize ? item.sieveSize : "-"}</td>
                                <th>Elek Göz Boyutu (mm)</th>
                                <td>
                                  {item.sieveEyeSize ? item.sieveEyeSize : "-"}
                                </td>
                              </tr>

                              {/* <tr>
                    <th>TDI Ester Birimi (€)</th>
                    <td>
                      {item.tdiEsterRate ? item.tdiEsterRate : "-"}
                    </td>
                    <th>TDI Ester</th>
                    <td>
                      {item.tdiEster ? item.tdiEster : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>MDI Ester Birimi (€)</th>
                    <td>
                      {item.mdiEsterRate ? item.mdiEsterRate : "-"}
                    </td>
                    <th>MDI Ester</th>
                    <td>
                      {item.mdiEster ? item.mdiEster : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>MDI Ether Birimi (€)</th>
                    <td>
                      {item.mdiEtherRate ? item.mdiEtherRate : "-"}
                    </td>
                    <th>MDI Ester</th>
                    <td>
                      {item.mdiEther ? item.mdiEther : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Moca Birimi (€)</th>
                    <td>
                      {item.mocaRate ? item.mocaRate : "-"}
                    </td>
                    <th>MDI Ester</th>
                    <td>
                      {item.moca ? item.moca : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Moca Birimi (€)</th>
                    <td>
                      {item.mocaRate ? item.mocaRate : "-"}
                    </td>
                    <th>Moca</th>
                    <td>
                      {item.moca ? item.moca : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>BDO Birimi (€)</th>
                    <td>
                      {item.bdoRate ? item.bdoRate : "-"}
                    </td>
                    <th>BDO</th>
                    <td>
                      {item.bdo ? item.bdo : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Nakliye Birimi (€)</th>
                    <td>
                      {item.transportRate ? item.transportRate : "-"}
                    </td>
                    <th>Nakliye</th>
                    <td>
                      {item.transport ? item.transport : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>DSM Birimi (€)</th>
                    <td>
                      {item.dsmRate ? item.dsmRate : "-"}
                    </td>
                    <th>DSM</th>
                    <td>
                      {item.dsm ? item.dsm : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kumlama Birimi (€)</th>
                    <td>
                      {item.sandblastingRate ? item.sandblastingRate : "-"}
                    </td>
                    <th>Kumlama</th>
                    <td>
                      {item.sandblasting ? item.sandblasting : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Yan Gergi Sacı Birimi (€)</th>
                    <td>
                      {item.sidePlateRate ? item.sidePlateRate : "-"}
                    </td>
                    <th>Yan Gergi Sacı</th>
                    <td>
                      {item.sidePlate ? item.sidePlate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>8x16 Lama Birimi (€)</th>
                    <td>
                      {item.lama810Rate ? item.lama810Rate : "-"}
                    </td>
                    <th>8x10 Lama</th>
                    <td>
                      {item.lama810 ? item.lama810 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>10x20 Lama Birimi (€)</th>
                    <td>
                      {item.lama1020Rate ? item.lama1020Rate : "-"}
                    </td>
                    <th>10x20 Lama</th>
                    <td>
                      {item.lama1020 ? item.lama1020 : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Halat Birimi (€)</th>
                    <td>
                      {item.ropeRate ? item.ropeRate : "-"}
                    </td>
                    <th>Halat</th>
                    <td>
                      {item.rope ? item.rope : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kurşun Yüzük Birimi (€)</th>
                    <td>
                      {item.ringRate ? item.ringRate : "-"}
                    </td>
                    <th>Kurşun Yüzük </th>
                    <td>
                      {item.ring ? item.ring : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Pim Birimi (€)</th>
                    <td>
                      {item.pimRate ? item.pimRate : "-"}
                    </td>
                    <th>Pim </th>
                    <td>
                      {item.pim ? item.pim : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Muhtelif Birimi (€)</th>
                    <td>
                      {item.sundryRate ? item.sundryRate : "-"}
                    </td>
                    <th>Muhtelif</th>
                    <td>
                      {item.sundry ? item.sundry : "-"}
                    </td>
                  </tr> */}

                              {/* <tr>
                    <th>Malzeme Maliyeti (€/ad)</th>
                    <td>
                      {item.total}
                    </td>
                    <th>Toplam (€/m2) </th>
                    <td>
                      {item.m2Total}
                    </td>
                  </tr>

                  <tr>
                    <th>İşçilik Yüzdesi (%)</th>
                    <td>
                      {item.profitRate}
                    </td>
                    <th>Toplam (€/ad)</th>
                    <td>
                      {item.castingCostTotal}
                    </td>
                  </tr> */}

                              <tr>
                                <th>Toplam (€/m2) </th>
                                <td>{item.m2Total}</td>
                                <th>Toplam (€/ad)</th>
                                <td>{item.castingCostTotal}</td>
                              </tr>

                              <tr>
                                <th>Kaydeden Kullanıcı</th>
                                <td>{item.createdBy ? item.createdBy : "-"}</td>
                                <th>Kaydedilme Tarihi</th>
                                <td>{item.createdAt ? item.createdAt : "-"}</td>
                              </tr>
                              <tr>
                                <th>Son Güncelleyen Kullanıcı</th>
                                <td>{item.updatedBy ? item.updatedBy : "-"}</td>
                                <th>Son Güncellenme Tarihi</th>
                                <td>{item.updatedAt ? item.updatedAt : "-"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      );
                    })}
                  </>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CastingCostDetailList;
