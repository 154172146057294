import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const CgnDatatable2 = ({ title, columns, data, loading, filter }) => {
  return (
    <>
      {title && columns && data && (
        <div className="data-table">
        <DataTableExtensions
          columns={columns}
          data={data}
          filterPlaceholder="Lütfen aramak istediğiniz kelimeyi girin..."
          export={false}
          print={false}
          filter={filter}
        >
          <DataTable
            title={title}
            defaultSortField="id"
            defaultSortAsc={false}
            persistTableHead={true}
            pagination={true}
            highlightOnHover={true}
            pointerOnHover={true}
            responsive={true}
            paginationPerPage={500}
            paginationRowsPerPageOptions={[500, 1000, 1500, 2000]}
            noHeader={true}
            progressPending={loading}
            noDataComponent={
              <p className="p-3 m-0">Görüntülenecek kayıt yok!</p>
            }
            paginationComponentOptions={{
              rowsPerPageText: "Sayfa başına satır:",
              rangeSeparatorText: "/",
              selectAllRowsItemText: "Tümü",
            }}
          />
        </DataTableExtensions>
      </div>
      )}
    </>
  );
};

export default CgnDatatable2;
