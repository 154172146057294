import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxType,
  TaxRateChoices,
  PaymentMethodType,
  CurrencyType,
  
  UserOperationClaimControl,
  resizeFile,
  fotoStyle,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import moment from "moment";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";

class IncomeAddUpdate extends Component {
  state = {
    pageTitle: "Gelir Ekle",
    pageTitleAdd: "Gelir Ekle",
    pageTitleUpdate: "Gelir Güncelle",
    pageAddLink: "gelir-ekle",
    pageListLink: "gelir-listele",
    breadcrumb: [{ text: "Gelir", link: "#" }],
    isLoading: false,
    urlSplit: [],
    incomeDate: "",
    customerGuid: "",
    total: null,
    generalTotal: parseFloat(0).toFixed(2),
    taxRateValue: "",
    taxTypeValue: null,
    description: "",
    discountPercent: "",
    discountValue: "",
    discountTotal: parseFloat(0).toFixed(2),
    currencyType: "",
    currencyTypeError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    status: true,
    incomeDateError: "",
    customerError: "",
    totalError: "",
    descriptionError: "",
    taxRateError: "",
    taxTypeError: "",
    targetCollectionDateError: "",
    targetTotalError: "",
    targetCollectionMethodTypeError: "",
    guid: "",
    receiptNo: "",
    expiry: null,
    paymentMethodValue: null,
    bankGuid: null,
    chequesBondNo: "",
    image: "",
    imageName: "",
    isOpenModalBank: false,
    isBankTrue: false,
    isChequesBondNoTrue: false,
    isHidden: true,
    alertErrorMessage: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    isOpenModalCustomer: false,
    targetCollectionDate: "",
    targetTotal: null,
    targetCollectionMethodType: null,
    collectionCalendarData: [],
    bankArr: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    customerModalPlus: false,
    collectionCalendarModalPlus: false,
    paymentModalPlus: false,
    collectionModalPlus: false
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
      collectionCalendarModalPlus: await UserOperationClaimControl(1008),
      paymentModalPlus: await UserOperationClaimControl(1026),
      collectionModalPlus: await UserOperationClaimControl(1009),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/incomes/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              incomeDate: response.data.incomeDate,
              customerGuid: response.data.customerGuid,
              taxRateValue: response.data.taxRate.toString(),
              taxTypeValue: response.data.taxType,
              description: response.data.description,
              total: response.data.total,
              generalTotal: response.data.generalTotal,
              collectionCalendarData: response.data.collectionCalendarDto,
              currencyType: response.data.currencyType,
              discountPercent: response.data.discountPercent,
              discountTotal: response.data.discountTotal,
              discountValue: response.data.discountValue,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        incomeDate: "",
        collectionCalendarData: [],
        customerGuid: "",
        total: "",
        generalTotal: "",
        description: "",
        taxRateValue: "",
        taxTypeValue: null,
        discountPercent: "",
        discountValue: "",
        discountTotal: "",
        currencyType: "",
        isDiscountPercent: false,
        isDiscountValue: false,
        status: true,
        isHidden: true,
      });
    }

    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleCollectionMethodSelectChange(option) {
    if (option) {
      this.setState({
        collectionMethodValue: option.value,
        collectionMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else if (!this.state.isHidden) {
      this.setState({
        collectionMethodValue: null,
        collectionMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      incomeDateError: "",
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "incomeDate" && value === "") {
      this.setState({
        incomeDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: this.include,
      });
    }
    if (name === "customerGuid" && value === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "total" && (value === null || value === "")) {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "total" && value && value < 0) {
      this.setState({
        totalError: CgnMessage.totalErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.discountPercent) {
      await this.setState({
        discountValue: "",
        isDiscountValue: true,
      });
    }
    if (this.state.discountValue) {
      await this.setState({
        discountPercent: "",
        isDiscountPercent: true,
      });
    }
    if (this.state.discountPercent === "") {
      await this.setState({
        isDiscountValue: false,
      });
    }
    if (this.state.discountValue === "") {
      await this.setState({
        isDiscountPercent: false,
      });
    }
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
  };

  DiscountCalculate = async (prevState) => {
    var totalAmount = 0;
    if (this.state.generalTotal && this.state.discountPercent) {
      totalAmount =
        this.state.generalTotal * (1 - this.state.discountPercent / 100);
      if (this.state.discountTotal !== totalAmount) {
        this.state.discountTotal = totalAmount;
      }
    }
    if (this.state.generalTotal && this.state.discountPercent === "") {
      this.state.discountTotal = "";
    }
    if (this.state.generalTotal && this.state.discountValue) {
      totalAmount = this.state.generalTotal - this.state.discountValue;
      if (this.state.discountTotal !== totalAmount) {
        await this.setState({
          discountTotal: totalAmount,
        });
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDateChange(incomeDate) {
    if (incomeDate) {
      this.setState({
        incomeDate: incomeDate,
        incomeDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        incomeDate: "",
        incomeDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var array = this.state.collectionCalendarData;
    array.forEach((item) => {
      item.targetCollectionDate = "";
      item.targetTotal = "";
      item.targetCollectionMethodType = null;
      item.targetCollectionDateError = CgnMessage.textErrorMessage;
      item.targetTotalError = CgnMessage.textErrorMessage;
      item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
      item.targetCurrencyType = CgnMessage.textErrorMessage;
      item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
    });
    this.setState({
      incomeDate: "",
      customerGuid: "",
      total: "",
      description: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      status: true,
      isHidden: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      incomeDateError: CgnMessage.textErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
    });
  }

  handleCollectionCalendarAdd = async () => {
    let array = this.state.collectionCalendarData;
    await array.push({
      targetCollectionDate: "",
      targetTotal: null,
      targetCollectionMethodType: null,
      targetCollectionDateError: "",
      targetTotalError: "",
      targetCollectionMethodTypeError: "",
      targetPaymentMethodTypeError: "",
      targetCurrencyTypeError: "",
    });
    array.forEach((item) => {
      if (
        array.length > 0 &&
        (!item.targetCollectionDate ||
          !item.targetTotal ||
          !item.targetCollectionMethodType ||
          !item.targetCurrencyType)
      ) {
        item.targetCollectionDateError = CgnMessage.textErrorMessage;
        item.targetTotalError = CgnMessage.textErrorMessage;
        item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
        item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        this.setState({
          isNull: true,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
    this.setState({ collectionCalendarData: array });
  };

  handleTextBoxChange(e, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    collectionCalendarData[idx].targetTotal = parseFloat(e.target.value);
    this.setState({
      collectionCalendarData: collectionCalendarData,
    });
    this.collectionCalendarRequiredField();
  }

  handleInputValueChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCollectionMethodType = option.value;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCollectionMethodType = null;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  handleTargetCollectionDateChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCollectionDate = option;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCollectionDate = "";
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  handleTargetCurrencyTypeChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCurrencyType = option.value;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCurrencyType = null;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  collectionCalendarRequiredField = () => {
    var isAlert = false;
    var arr = this.state.collectionCalendarData;
    arr.forEach((item) => {
      item.targetPaymentDateError = "";
      item.targetTotalError = "";
      item.targetCollectionMethodTypeError = "";
      item.targetCurrencyTypeError = "";

      if (!item.targetCollectionDate) {
        item.targetCollectionDateError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetTotal) {
        item.targetTotalError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCollectionMethodType) {
        item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCurrencyType) {
        item.targetCurrencyType = this.state.currencyType;
        // item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        // isAlert= true;
        // this.setState({
        //   isNull: true
        // })
      }
    });
    if (isAlert) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else {
      this.setState({
        isNull: false,
      });
    }
  };

  handleCollectionCalendarRemove(idx) {
    let someArray = this.state.collectionCalendarData;
    someArray.splice(idx, 1);
    this.setState({ collectionCalendarData: someArray });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      incomeDateError: "",
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (this.state.incomeDate === "") {
      this.setState({
        incomeDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.total === null || this.state.total === "") {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      (this.state.total !== null || this.state.total === "") &&
      this.state.total < 0
    ) {
      this.setState({
        totalError: CgnMessage.totalErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxTypeValue === null) {
      this.setState({
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxRateValue === "") {
      this.setState({
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.isHidden && this.state.collectionMethodValue === null) {
      this.setState({
        collectionMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.bankGuid === null &&
      (this.state.collectionMethodValue === 2 ||
        this.state.collectionMethodValue === 3)
    ) {
      this.setState({
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.chequesBondNo === "" &&
      (this.state.collectionMethodValue === 1 ||
        this.state.collectionMethodValue === 5)
    ) {
      this.setState({
        chequesBondNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.incomeDate !== "" &&
      this.state.customerGuid !== "" &&
      this.state.total &&
      this.state.taxTypeValue &&
      this.state.taxRateValue &&
      this.state.currencyType &&
      this.state.isNull === false &&
      (!this.state.isHidden
        ? this.state.collectionMethodValue !== "" &&
          ((this.state.bankGuid !== null &&
            (this.state.collectionMethodValue === 2 ||
              this.state.collectionMethodValue === 3)) ||
            (this.state.chequesBondNo !== "" &&
              (this.state.collectionMethodValue === 1 ||
                this.state.collectionMethodValue === 5)) ||
            (this.state.chequesBondNo === "" &&
              this.state.bankGuid === null &&
              this.state.collectionMethodValue === 4))
        : true)
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        incomeDate: moment(this.state.incomeDate).format("DD/MM/YYYY"),
        customerGuid: this.state.customerGuid,
        taxType: this.state.taxTypeValue,
        taxRate: parseInt(this.state.taxRateValue),
        total: parseFloat(this.state.total),
        generalTotal: parseFloat(this.state.generalTotal),
        description: this.state.description,
        currencyType: this.state.currencyType,
        discountPercent:
          this.state.discountPercent !== ""
            ? parseInt(this.state.discountPercent)
            : 0,
        discountValue:
          this.state.discountValue !== ""
            ? parseFloat(this.state.discountValue)
            : 0,
        discountTotal: parseFloat(this.state.discountTotal),
        status: true, //this.state.status,
        collectionCalendarDto: this.state.collectionCalendarData,
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        paymentMethodType: this.state.collectionMethodValue
          ? parseInt(this.state.collectionMethodValue)
          : 0,
        bankGuid: this.state.bankGuid,
        chequesBondNo: this.state.chequesBondNo,
        image: this.state.image,
        isHidden: this.state.isHidden,
      };
      var URLParam = "/incomes/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/incomes/update";
        myObj = {
          guid: this.state.guid,
          incomeDate:
            this.state.incomeDate &&
            this.state.incomeDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.incomeDate.split("/")[2] +
                    "-" +
                    this.state.incomeDate.split("/")[1] +
                    "-" +
                    this.state.incomeDate.split("/")[0]
                )
              : moment(this.state.incomeDate).format("DD/MM/YYYY"),
          customerGuid: this.state.customerGuid,
          taxType: this.state.taxTypeValue,
          taxRate: parseInt(this.state.taxRateValue),
          total: parseFloat(this.state.total),
          generalTotal: parseFloat(this.state.generalTotal),
          description: this.state.description,
          currencyType: this.state.currencyType,
          discountPercent:
            this.state.discountPercent !== ""
              ? parseInt(this.state.discountPercent)
              : 0,
          discountValue:
            this.state.discountValue !== ""
              ? parseFloat(this.state.discountValue)
              : 0,
          discountTotal: parseFloat(this.state.discountTotal),
          status: true, //this.state.status,
          collectionCalendarDto: this.state.collectionCalendarData,
          isHidden: this.state.isHidden,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/gelir-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "total" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var total = parseFloat(this.state.total);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = total - total * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = total - discountValue;
    } else {
      generalTotal = total;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }

    await this.setState({
      total: total,
    });
    await this.setState({
      generalTotal: parseFloat(generalTotal).toFixed(2),
    });
    await this.setState({
      discountTotal: parseFloat(discountTotal).toFixed(2),
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="incomeDate"
                      label="Tarih [*]"
                      selected={
                        this.state.incomeDate &&
                        this.state.incomeDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.incomeDate.split("/")[2] +
                                "-" +
                                this.state.incomeDate.split("/")[1] +
                                "-" +
                                this.state.incomeDate.split("/")[0]
                            )
                          : this.state.incomeDate
                      }
                      onChange={(incomeDate) =>
                        this.handleDateChange(incomeDate)
                      }
                      error={this.state.incomeDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Tutar [*]"
                      value={this.state.total}
                      placeHolder="Lütfen tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.PriceCalculate}
                      error={this.state.totalError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountPercent"
                      label="İskonto(Yüzde)"
                      value={this.state.discoountPercent}
                      placeHolder="Lütfen iskonto girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountPercent}
                      onChange={this.PriceCalculate}
                      error={this.state.discountPercentError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountValue"
                      label="İskonto(Net Tutar)"
                      value={this.state.discountValue}
                      placeHolder="Lütfen iskonto net tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountValue}
                      onChange={this.PriceCalculate}
                      error={this.state.discountValueError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxTypeValue"
                      label="K.D.V Türü [*]"
                      placeholder="Lütfen K.D.V türü seçiniz..."
                      selectValue={this.state.taxTypeValue}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTaxTypeSelectChange(option)
                      }
                      options={TaxType}
                      error={this.state.taxTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxRateValue"
                      label="K.D.V Oranı [*]"
                      placeholder="Lütfen K.D.V oranı seçiniz..."
                      selectValue={this.state.taxRateValue}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTrue}
                      onChange={(option) =>
                        this.handleTaxRateSelectChange(option)
                      }
                      options={TaxRateChoices}
                      error={this.state.taxRateError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                {this.state.collectionCalendarModalPlus &&
                  this.state.collectionCalendarData.map(
                    (collectionCalendarData, idx) => (
                      <Row className="mobileBorder">
                        <Col lg="3">
                          <CgnDatePicker
                            name="targetCollectionDate"
                            label="Tahsilat Tarihi [*]"
                            selected={
                              collectionCalendarData.targetCollectionDate &&
                              collectionCalendarData.targetCollectionDate
                                .toString()
                                .indexOf("/") > 0
                                ? new Date(
                                    collectionCalendarData.targetCollectionDate.split(
                                      "/"
                                    )[2] +
                                      "-" +
                                      collectionCalendarData.targetCollectionDate.split(
                                        "/"
                                      )[1] +
                                      "-" +
                                      collectionCalendarData.targetCollectionDate.split(
                                        "/"
                                      )[0]
                                  )
                                : collectionCalendarData.targetCollectionDate
                            }
                            onChange={(option) => {
                              this.handleTargetCollectionDateChange(
                                option,
                                idx
                              );
                            }}
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                            error={
                              collectionCalendarData.targetCollectionDateError
                            }
                          />
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="number"
                            name="targetTotal"
                            label="Tahsilat Tutarı [*]"
                            value={collectionCalendarData.targetTotal}
                            onChange={(e) => this.handleTextBoxChange(e, idx)}
                            placeHolder="Lütfen tutar girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={collectionCalendarData.targetTotalError}
                          />
                        </Col>

                        {/* <Col lg="3">
                    <CgnReactSelect
                      name="targetCurrencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={
                        collectionCalendarData.targetCurrencyType
                      }
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTargetCurrencyTypeChange(option, idx)
                      }
                      options={CurrencyType}
                      error={collectionCalendarData.targetCurrencyTypeError}
                    />
                  </Col> */}

                        <Col lg="4">
                          <CgnReactSelect
                            name="targetCollectionMethodType"
                            label="Tahsilat Yöntemi [*]"
                            placeholder="Lütfen tahsilat yöntemi seçiniz..."
                            selectValue={
                              collectionCalendarData.targetCollectionMethodType
                            }
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleInputValueChange(option, idx);
                            }}
                            options={PaymentMethodType}
                            error={
                              collectionCalendarData.targetCollectionMethodTypeError
                            }
                          />
                        </Col>

                        <Col lg="1" sm="3">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton"
                            onClick={() =>
                              this.handleCollectionCalendarRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    )
                  )}

                {this.state.collectionCalendarModalPlus && (
                  <Row>
                    <Col lg="4">
                      <CgnButton
                        type="button"
                        color="primary"
                        className="mb-3 dynamicRowAddButton"
                        onClick={this.handleCollectionCalendarAdd}
                        text={"Tahsilat Takvimi Ekle"}
                      />
                    </Col>
                  </Row>
                )}

                 {this.state.collectionModalPlus && (
                    <>
                      <Row>
                        <Col
                          lg="12"
                          className="font-weight-bold"
                          style={{ padding: "0 15px 10px 15px" }}
                        >
                          <Label
                            check
                            className="labelCheck"
                            style={{ fontSize: "13px" }}
                          >
                            <Input
                              type="checkbox"
                              className="mt-1"
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              checked={!this.state.isHidden}
                            />{" "}
                            Tahsilat Yapıldı mı ?
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <CgnTextbox
                            type="text"
                            name="receiptNo"
                            label="Makbuz No"
                            value={this.state.receiptNo}
                            placeHolder="Lütfen makbuz no girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.receiptNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                        <Col lg="6">
                          <CgnTextbox
                            type="number"
                            name="expiry"
                            label="Vade"
                            value={this.state.expiry}
                            placeHolder="Lütfen vade sayısı girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.expiryError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <CgnReactSelect
                            name="collectionMethodValue"
                            label="Tahsilat Yöntemi [*]"
                            placeholder="Lütfen tahsilat yöntemi seçiniz..."
                            selectValue={this.state.collectionMethodValue}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleCollectionMethodSelectChange(option)
                            }
                            options={PaymentMethodType}
                            error={this.state.collectionMethodTypeError}
                            hidden={this.state.isHidden}
                          />
                        </Col>

                        <Col
                          lg="4"
                          className="modalSelectCol"
                          style={
                            this.state.isHidden
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <div className="flex-container">
                            <div className="p-0 flexMaxInput">
                              <CgnReactSelect
                                name="bankGuid"
                                label="Banka"
                                placeholder="Banka seçiniz..."
                                selectValue={this.state.bankGuid}
                                isMulti={false}
                                isClearable={true}
                                disabled={this.state.isBankTrue}
                                onChange={(option) =>
                                  this.handleBankSelectChange(option)
                                }
                                options={this.state.bankArr}
                                error={this.state.bankError}
                                hidden={this.state.isHidden}
                              />
                            </div>
                            {this.state.bankModalPlus && (
                              <div className="p-0 flexAddMinButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalBank()}
                                  text={"+"}
                                  visibled={this.state.isHidden}
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="text"
                            name="chequesBondNo"
                            label="Çek/Senet No"
                            value={this.state.chequesBondNo}
                            placeHolder="Lütfen çek/senet no girin..."
                            autoComplete="off"
                            readOnly={this.state.isChequesBondNoTrue}
                            onChange={this.handleChange}
                            error={this.state.chequesBondNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <ImageUpload
                            accept="image/png, image/jpeg"
                            className="text-center"
                            style={fotoStyle}
                            label="Makbuz Fotoğrafı"
                            image={this.state.image}
                            noFoto={noImage}
                            alt={this.state.name}
                            onChange={this.uploadedImage}
                            onDelete={this.deleteImage}
                            error={this.state.imageError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12" >
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default IncomeAddUpdate;
