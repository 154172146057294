import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  resizeFile,
  fotoStyle,
  CurrencyType,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as groupGetListSelectAction from "../../redux/actions/groupGetListSelect/groupGetListSelectAction";
import ImageUpload from "../toolbox/CgnImageUpload";
import noFotoMember from "../../images/no-foto-user.jpg";
import { CgnEMailControl } from "../toolbox/CgnEMailControl";
import { CgnPhoneControl } from "../toolbox/CgnPhoneControl";
import { CgnSalaryControl } from "../toolbox/CgnSalaryControl";
import moment from "moment";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CompanyUserGroupAddUpdate from "../companyUserGroup/CompanyUserGroupAddUpdate";
import CgnButton from "../toolbox/CgnButton";
import CategoryAddUpdate from "../category/CategoryAddUpdate";
import { confirmAlert } from "react-confirm-alert";

class EmployeeAddUpdate extends Component {
  state = {
    pageTitle: "Personel Ekle",
    pageTitleAdd: "Personel Ekle",
    pageTitleUpdate: "Personel Güncelle",
    pageAddLink: "personel-ekle",
    pageListLink: "personel-listele",
    breadcrumb: [{ text: "Personel", link: "#" }],
    isLoading: false,
    urlSplit: [],
    groupGuid: null,
    userName: "",
    firstName: "",
    lastName: "",
    eMail: "",
    phone: "",
    salary: "",
    startDate: "",
    endDate: "",
    image: "",
    currencyType: 0,
    salaryVestingDate: "",
    birthDate: "",
    categoryGuid: null,
    productGuid: null,
    saleRegionGuid: null,
    categoryOption: [],
    productOption: [],
    saleRegionOption: [],
    categoryArr: [],
    productArr: [],
    saleRegionArr: [],
    areaArr: [],
    discountAmount: 0,
    area: "00000000-0000-0000-0000-000000000000",
    status: true,
    userNameError: "",
    firstNameError: "",
    lastNameError: "",
    eMailError: "",
    phoneError: "",
    salaryError: "",
    currencyTypeError: "",
    startDateError: "",
    endDateError: "",
    birthDateError: "",
    salaryVestingDateError: "",
    imageError: "",
    groupGetListSelectError: "",
    alertErrorMessage: "",
    guid: "",
    title: "",
    id: 0,
    isDeleted: false,
    isOpenModalCompanyUserGroup: false,
    isOpenModalCategory: false,
    isOpenModalProduct: false,
    isOpenModalSaleRegion: false,
    groupGetListSelect: {},
    groupSelectOption: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    groupModalPlus: false,
    locationGuid: "00000000-0000-0000-0000-000000000000",
    locationGuidError: "",
    locationArr: [],
    description: null,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      groupModalPlus: await UserOperationClaimControl(1014),
    });
    this.props.actions.groupGetListSelect();
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/categories/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          categoryOption: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if (item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productOption: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/areas/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          areaArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/locations/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    // axios
    //   .get(URL + "/salesRegions/getlist/" + statusType, config)
    //   .then((response) => {
    //     var myArr = response.data.map(function (item) {
    //       return {
    //         value: item.guid,
    //         label: item.name,
    //       };
    //     });
    //     this.setState({
    //       saleRegionOption: myArr,
    //     });
    //   })
    //   .catch((error) => {
    //     this.setState({
    //       alertErrorMessage: error.response.data,
    //       isLoading: false,
    //     });
    //   });
    if (urlSplit.length === 3 && urlSplit[1] === "personel-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/members/getmember/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              userName: response.data.userName,
              groupGuid: response.data.groupGuid,
              title: response.data.title,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              eMail: response.data.eMail,
              phone: response.data.phone,
              area: response.data.area,
              salary: response.data.salary,
              startDate: response.data.startDate,
              endDate: response.data.endDate,
              birthDate: response.data.birthDate,
              currencyType: response.data.currencyType,
              image: response.data.image,
              categoryArr: response.data.category,
              productArr: response.data.product,
              // saleRegionArr: response.data.salesRegion,
              discountAmount: response.data.discountAmount,
              status: response.data.status,
              locationGuid: response.data.locationGuid,
              description: response.data.description,
            });
          }
        });
      // .catch((error) => {
      //   this.setState({
      //     alertErrorMessage: error.response.data,
      //     isLoading: false,
      //   });
      // });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  GroupSelectList = () => {
    this.setState({
      isLoading: true,
    });
    var groupGetListSelect = this.props.groupGetListSelect.data.map(function (
      item
    ) {
      return {
        value: item.guid,
        label: item.name,
      };
    });
    this.setState({
      groupSelectOption: groupGetListSelect,
      isLoading: false,
    });
  };

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        groupGuid: null,
        area: "",
        image: "",
        title: "",
        firstName: "",
        lastName: "",
        userName: "",
        eMail: "",
        phone: "",
        salary: "",
        startDate: "",
        endDate: "",
        birthDate: "",
        currencyType: "",
        categoryArr: [],
        productArr: [],
        saleRegionArr: [],
        discountAmount: 0,
        status: true,
        locationGuid: "00000000-0000-0000-0000-000000000000",
        description: null,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.props.groupGetListSelect.status &&
      this.state.groupGetListSelect !== this.props.groupGetListSelect.data &&
      this.props.groupGetListSelect.data &&
      this.props.groupGetListSelect.status === 200
    ) {
      this.setState({
        groupGetListSelect: this.props.groupGetListSelect.data,
        isLoading: false,
      });
      this.GroupSelectList();
    }

    if (
      this.props.groupGetListSelect.status &&
      this.props.groupGetListSelect.status !== 200 &&
      this.state.groupGetListSelectError !== this.props.groupGetListSelect &&
      this.props.groupGetListSelect.status !== 401
    ) {
      this.setState({
        groupGetListSelectError: this.props.groupGetListSelect.data,
        isLoading: false,
      });
    }

    if (
      this.state.isOpenModalCompanyUserGroup &&
      this.props.location.modalAddedCompanyUserGroup !== undefined &&
      this.props.location.modalAddedCompanyUserGroup.isModal !== undefined &&
      this.props.location.modalAddedCompanyUserGroup.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/groups/getlist", config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            groupSelectOption: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCompanyUserGroup.data &&
              element.name ===
              this.props.location.modalAddedCompanyUserGroup.data
            ) {
              this.setState({
                groupGuid: element.guid,
                groupGetListSelectError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCompanyUserGroup.isModal = true;
      this.setState({
        isOpenModalCompanyUserGroup: false,
      });
    }

    if (
      this.state.isOpenModalCategory &&
      this.props.location.modalAddedCategory !== undefined &&
      this.props.location.modalAddedCategory.isModal !== undefined &&
      this.props.location.modalAddedCategory.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/categories/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            categoryOption: myArr,
            isLoading: false,
          });
          if (this.state.categoryArr.length === 0) {
            response.data.forEach((element) => {
              if (
                this.props.location.modalAddedCategory.data &&
                element.name === this.props.location.modalAddedCategory.data
              ) {
                var category = {};
                category.value = element.guid;
                category.label = element.name;
                this.state.categoryArr.push(category);
              }
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCategory.isModal = true;
      this.setState({
        isOpenModalCategory: false,
      });
    }

    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            productOption: myArr,
            isLoading: false,
          });
          if (this.state.productArr.length === 0) {
            response.data.forEach((element) => {
              if (
                this.props.location.modalAddedProduct.data &&
                `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data
              ) {
                var product = {};
                product.value = element.guid;
                product.label = `${element.code}-${element.name}`;
                this.state.productArr.push(product);
              }
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }

    if (
      this.state.isOpenModalSaleRegion &&
      this.props.location.modalAddedSaleRegion !== undefined &&
      this.props.location.modalAddedSaleRegion.isModal !== undefined &&
      this.props.location.modalAddedSaleRegion.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      // statusType = 2; // sadece aktif olanları göstermesi için
      // await axios
      //   .get(URL + "/salesRegions/getlist/" + statusType, config)
      //   .then((response) => {
      //     var myArr = response.data.map(function (item) {
      //       return {
      //         value: item.guid,
      //         label: item.name,
      //       };
      //     });
      //     this.setState({
      //       saleRegionOption: myArr,
      //       isLoading: false,
      //     });
      //     if (this.state.saleRegionArr.length === 0) {
      //       response.data.forEach((element) => {
      //         if (
      //           this.props.location.modalAddedSaleRegion.data &&
      //           element.name === this.props.location.modalAddedSaleRegion.data
      //         ) {
      //           var saleRegion = {};
      //           saleRegion.value = element.guid;
      //           saleRegion.label = element.name;
      //           this.state.saleRegionArr.push(saleRegion);
      //         }
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.setState({
      //       alertErrorMessage: error.response.data,
      //       isLoading: false,
      //     });
      //   });
      this.props.location.modalAddedSaleRegion.isModal = true;
      this.setState({
        isOpenModalSaleRegion: false,
      });
    }
  }

  addModalCompanyUserGroup = () => {
    this.setState({
      isOpenModalCompanyUserGroup: !this.state.isOpenModalCompanyUserGroup,
    });
  };

  addModalCategory = () => {
    this.setState({
      isOpenModalCategory: !this.state.isOpenModalCategory,
    });
  };

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  // addModalSaleRegion = () => {
  //   this.setState({
  //     isOpenModalSaleRegion: !this.state.isOpenModalSaleRegion,
  //   });
  // };

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      groupGetListSelectError: "",
      firstNameError: "",
      lastNameError: "",
      eMailError: "",
      phoneError: "",
      salaryError: "",
      startDateError: "",
      endDateError: "",
      imageError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "firstName" && value === "") {
      this.setState({
        firstNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "lastName" && value === "") {
      this.setState({
        lastNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "eMail" && value === "") {
      this.setState({
        eMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "eMail" && value !== "" && !CgnEMailControl.test(value)) {
      this.setState({
        eMailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "phone" && value === "") {
      this.setState({
        phoneError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "phone" && !CgnPhoneControl.test(value)) {
      this.setState({
        phoneError: CgnMessage.phoneErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "salary" && value && !CgnSalaryControl.test(value)) {
      this.setState({
        salaryError: CgnMessage.salaryErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      name === "salary" &&
      value &&
      (this.state.currencyType === "" || this.state.currencyType === 0)
    ) {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleDateChange(startDate) {
    if (startDate) {
      this.setState({
        startDate: startDate,
        startDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        startDate: "",
        startDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDateChange2(endDate) {
    if (endDate) {
      this.setState({
        endDate: endDate,
        endDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        endDate: "",
        endDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleAreaChange(option) {
    if (option) {
      this.setState({
        area: option.value,
      });
    } else {
      this.setState({
        area: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handleSalaryVestingDateChange(salaryVestingDate) {
    this.setState({ salaryVestingDate: salaryVestingDate });
  }

  handleBirthDateChange(birthDate) {
    this.setState({ birthDate: birthDate });
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
      if (this.state.salary === "" || this.state.salary === null) {
        this.setState({
          salaryError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    } else {
      this.setState({
        currencyType: 0,
        salaryError: "",
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    }
  }

  handleCategorySelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        categoryArr: option,
      });
    } else {
      this.setState({
        categoryArr: [],
      });
    }
  }

  handleProductSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        productArr: option,
      });
    } else {
      this.setState({
        productArr: [],
      });
    }
  }

  // handleSaleRegionSelectChange(option) {
  //   if (option && option.length > 0) {
  //     this.setState({
  //       saleRegionArr: option,
  //     });
  //   } else {
  //     this.setState({
  //       saleRegionArr: [],
  //     });
  //   }
  // }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleGroupSelectChange(option) {
    if (option) {
      this.setState({
        groupGuid: option.value,
        groupGetListSelectError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        groupGuid: null,
        groupGetListSelectError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      groupGuid: null,
      title: "",
      firstName: "",
      lastName: "",
      userName: "",
      eMail: "",
      phone: "",
      salary: "",
      startDate: "",
      endDate: "",
      birthDate: "",
      currencyType: "",
      image: "",
      categoryArr: [],
      productArr: [],
      saleRegionArr: [],
      discountAmount: 0,
      status: true,
      locationGuid: "00000000-0000-0000-0000-000000000000",
      description: null,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      groupGetListSelectError: CgnMessage.textErrorMessage,
      firstNameError: CgnMessage.textErrorMessage,
      lastNameError: CgnMessage.textErrorMessage,
      eMailError: CgnMessage.textErrorMessage,
      phoneError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      groupGetListSelectError: "",
      firstNameError: "",
      lastNameError: "",
      eMailError: "",
      phoneError: "",
      salaryError: "",
      startDateError: "",
      endDateError: "",
      currencyTypeError: "",
      imageError: "",
    });

    if (this.state.groupGuid === null) {
      this.setState({
        groupGetListSelectError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.firstName === "") {
      this.setState({
        firstNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.lastName === "") {
      this.setState({
        lastNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.phone === "") {
      this.setState({
        phoneError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.phone !== "" && !CgnPhoneControl.test(this.state.phone)) {
      this.setState({
        phoneError: CgnMessage.phoneErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.eMail === "") {
      this.setState({
        eMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.eMail !== "" && !CgnEMailControl.test(this.state.eMail)) {
      this.setState({
        eMailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.salary &&
      (this.state.currencyType === "" || this.state.currencyType === 0)
    ) {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      (this.state.salary === "" || this.state.salary === null) &&
      (this.state.currencyType || this.state.currencyType > 0)
    ) {
      this.setState({
        salaryError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.groupGuid !== null &&
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.eMail !== "" &&
      (((this.state.salary === "" || this.state.salary === null) &&
        (this.state.currencyType === "" || this.state.currencyType === 0)) ||
        (this.state.salary && this.state.currencyType)) &&
      CgnEMailControl.test(this.state.eMail) &&
      this.state.phone !== "" &&
      CgnPhoneControl.test(this.state.phone)
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      // var categories = [];
      // this.state.categoryArr.forEach((element) => {
      //   categories.push(element.value);
      // });
      // var products = [];
      // this.state.productArr.forEach((element) => {
      //   products.push(element.value);
      // });
      // var saleRegions = [];
      // this.state.saleRegionArr.forEach((element) => {
      //   saleRegions.push(element.value);
      // });
      // var productString = products.toString();
      // var categoryString = categories.toString();
      // var saleRegionString = saleRegions.toString();
      var myObj = {
        userName: this.state.userName,
        groupGuid: this.state.groupGuid,
        title: this.state.title,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        eMail: this.state.eMail,
        phone: this.state.phone,
        currencyType: this.state.currencyType,
        area: this.state.area,
        salary: parseFloat(this.state.salary),
        birthDate: this.state.birthDate
          ? moment(this.state.birthDate).format("YYYY-MM-DD")
          : null,
        startDate: this.state.startDate
          ? moment(this.state.startDate).format("YYYY-MM-DD")
          : null,
        endDate: this.state.endDate
          ? moment(this.state.endDate).format("YYYY-MM-DD")
          : null,
        salaryVestingDate: this.state.salaryVestingDate
          ? moment(this.state.salaryVestingDate).format("YYYY-MM-DD")
          : null,
        image: this.state.image,
        category: "",
        product: "",
        // category: categoryString,
        // product: productString,
        // salesRegion: saleRegionString,
        discountAmount: parseFloat(this.state.discountAmount),
        status: this.state.status,
        locationGuid: this.state.locationGuid,
        description: this.state.description,
      };

      var URLParam = "/members/addmemberforcompany";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "personel-guncelle"
      ) {
        URLParam = "/members/updatememberforcompany";
        myObj = {
          userName: this.state.userName,
          guid: this.state.guid,
          groupGuid: this.state.groupGuid,
          title: this.state.title,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          eMail: this.state.eMail,
          phone: this.state.phone,
          currencyType: this.state.currencyType,
          area: this.state.area,
          birthDate: this.state.birthDate
            ? moment(this.state.birthDate).format("YYYY-MM-DD")
            : null,
          salary: parseFloat(this.state.salary),
          startDate: this.state.startDate
            ? moment(this.state.startDate).format("YYYY-MM-DD")
            : null,
          endDate: this.state.endDate
            ? moment(this.state.endDate).format("YYYY-MM-DD")
            : null,
          salaryVestingDate: this.state.salaryVestingDate
            ? moment(this.state.salaryVestingDate).format("YYYY-MM-DD")
            : null,
          image: this.state.image,
          category: "",
          product: "",
          // category: categoryString,
          // product: productString,
          // salesRegion: saleRegionString,
          discountAmount: parseFloat(this.state.discountAmount),
          status: this.state.status,
          locationGuid: this.state.locationGuid,
          description: this.state.description,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {

            var modalAddedEmployee = {
              data: `${this.state.firstName} ${this.state.lastName}`,
              isModal: false,
            };
            this.props.history.push({ modalAddedEmployee });

            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (
                      this.state.guid &&
                      this.state.urlSplit[1] === "personel-guncelle"
                    ) {
                      window.location.href = "/personel-listele";
                    }
                    if (this.state.urlSplit[1] === "personel-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    if (file.type === "image/png" || file.type === "image/jpeg") {
      const image = await resizeFile(file, 1000);
      this.setState({
        image: image,
        imageName: file.name,
      });
    }
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "personel-ekle" ||
          this.state.urlSplit[1] === "personel-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Fotoğraf"
                      image={this.state.image}
                      noFoto={noFotoMember}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      error={this.state.imageError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="modalSelectCol pl-0" sm="2" md="3" lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="groupGuid"
                          label="Grup [*]"
                          placeholder="Grup seçiniz..."
                          selectValue={this.state.groupGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleGroupSelectChange(option)
                          }
                          options={this.state.groupSelectOption}
                          error={this.state.groupGetListSelectError}
                        />
                      </div>
                      {this.state.groupModalPlus && (
                        <div className="p-0 flexMaxAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCompanyUserGroup()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col sm="2" md="3" lg="2">
                    <CgnTextbox
                      type="text"
                      name="title"
                      label="Ünvan"
                      value={this.state.title}
                      placeHolder="Lütfen ünvan girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.titleError}
                    />
                  </Col>

                  <Col sm="2" md="3" lg="2">
                    <CgnTextbox
                      type="text"
                      name="firstName"
                      label="Ad [*]"
                      value={this.state.firstName}
                      placeHolder="Lütfen ad girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.firstNameError}
                    />
                  </Col>

                  <Col sm="2" md="3" lg="2">
                    <CgnTextbox
                      type="text"
                      name="lastName"
                      label="Soyad [*]"
                      value={this.state.lastName}
                      placeHolder="Lütfen soyad girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.lastNameError}
                    />
                  </Col>
                  <Col className="mb-0 pr-0" sm="2" md="12" lg="3">
                    <CgnTextbox
                      style={{ marginBottom: "0" }}
                      type="text"
                      name="userName"
                      label="Kullanıcı Adı"
                      value={this.state.userName}
                      placeHolder="Lütfen kullanıcı adı girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.userNameError}
                    />
                    <div className="text-danger mb-1">
                      Giriş yapacak personeller içindir!
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="eMail"
                      label="E-Mail [*]"
                      value={this.state.eMail}
                      placeHolder="Lütfen e-mail girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.eMailError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="phone"
                      label="Telefon [*]"
                      value={this.state.phone}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.phoneError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="locationGuid"
                      label="Depo"
                      isMulti={false}
                      selectValue={this.state.locationGuid}
                      placeholder="Depo seçiniz..."
                      options={this.state.locationArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleLocationSelectChange(option)
                      }
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="area"
                      label="Personel Bölge"
                      placeholder="Bölge seçiniz..."
                      selectValue={this.state.area}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleAreaChange(option)}
                      options={this.state.areaArr}
                      error={this.state.areaError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      min={"0"}
                      name="salary"
                      label="Maaş"
                      value={this.state.salary}
                      placeHolder="Lütfen maaş girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.salaryError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi"
                      placeholder="Para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnDatePicker
                      name="startDate"
                      label="Giriş Tarihi"
                      selected={this.state.startDate}
                      onChange={(startDate) => this.handleDateChange(startDate)}
                      error={this.state.startDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="2">
                    <CgnDatePicker
                      name="endDate"
                      label="Çıkış Tarihi"
                      selected={this.state.endDate}
                      onChange={(endDate) => this.handleDateChange2(endDate)}
                      error={this.state.endDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="2">
                    <CgnDatePicker
                      name="salaryVestingDate"
                      label="Maaş Hak Ediş Tarihi"
                      selected={this.state.salaryVestingDate}
                      onChange={(salaryVestingDate) =>
                        this.handleSalaryVestingDateChange(salaryVestingDate)
                      }
                      error={this.state.salaryVestingDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="2">
                    <CgnDatePicker
                      name="birthDate"
                      label="Doğum Tarihi"
                      selected={this.state.birthDate}
                      onChange={(birthDate) =>
                        this.handleBirthDateChange(birthDate)
                      }
                      error={this.state.birthDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="categoryGuid"
                          label="Kategori"
                          placeholder="Kategori seçiniz..."
                          isMulti={true}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCategorySelectChange(option)
                          }
                          options={this.state.categoryOption}
                          selectValue={this.state.categoryArr}
                          error={this.state.categoryError}
                        />
                      </div>
                      <div className="p-0 flexMaxAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalCategory()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün"
                          placeholder="Ürün seçiniz..."
                          isMulti={true}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productOption}
                          selectValue={this.state.productArr}
                          error={this.state.productError}
                        />
                      </div>
                      <div className="p-0 flexMaxAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalProduct()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="saleRegionGuid"
                          label="Saha Satış Bölgesi"
                          placeholder="Saha satış bölgesi seçiniz..."
                          isMulti={true}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSaleRegionSelectChange(option)
                          }
                          options={this.state.saleRegionOption}
                          selectValue={this.state.saleRegionArr}
                          error={this.state.saleRegionError}
                        />
                      </div>
                      <div className="p-0 flexAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalSaleRegion()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col> 

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      min={"0"}
                      name="discountAmount"
                      label="İndirim Tutarı"
                      value={this.state.discountAmount}
                      placeHolder="Lütfen indirim tutarı girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.discountAmountError}
                    />
                  </Col>
                        </Row> */}

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "personel-ekle" ||
              this.state.urlSplit[1] === "personel-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCompanyUserGroup}
          toggle={this.addModalCompanyUserGroup}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Grup Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CompanyUserGroupAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCompanyUserGroup}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalCategory}
          toggle={this.addModalCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Kategori Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        {/* <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalSaleRegion}
          toggle={this.addModalSaleRegion}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Saha Satış Bölgesi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <SaleRegionAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSaleRegion}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupGetListSelect: state.groupGetListSelectReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      groupGetListSelect: bindActionCreators(
        groupGetListSelectAction.groupGetListSelect,
        dispatch
      ),
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeAddUpdate)
);
