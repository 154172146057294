const obj = {
  programName: "SYS - Şirket Yönetim Sistemi",
  programNameUpper: "SYS - ŞİRKET YÖNETİM SİSTEMİ",
  textErrorMessage: "Lütfen bu alanı doldurun!",
  fileErrorMessage: "Lütfen dosya seçin!",
  selectErrorMessage: "Lütfen bu alanı seçin!",
  emailErrorMessage: "Lütfen geçerli bir e-mail adresi girin!",
  phoneErrorMessage: "Lütfen geçerli bir telefon numarası girin!",
  faxErrorMessage: "Lütfen geçerli bir faks numarası girin!",
  ibanErrorMessage: "Lütfen geçerli bir iban girin!",
  salaryErrorMessage: "Lütfen geçerli bir maaş tutarı girin!",
  totalErrorMessage: "Tutar 0'dan büyük olmalıdır!",
  leastOneErrorMessage: "Lütfen tedarikçi veya personel seçimlerinden birini yapın",
  priceErrorMessage: "Lütfen fiyatı uygun formatla ve cinsiyle(TL,USD,EURO) ile beraber girin!",
  taxNoErrorMessage: "Lütfen geçerli bir vergi numarası girin!",
  taxExcludeRateZeroErrorMessage: "K.D.V hariç seçtiniz, K.D.V oranını 0 seçiniz!",
  newPasswordErrorMessage: "Lütfen en az 6 haneli,1 küçük harf,1 büyük harf,1 rakamdan oluşan bir şifre girin!",
  passwordErrorMessage: "Lütfen eski şifrenizi giriniz!",
  savePasswordControlError:"Lütfen şifre alanlarını kontrol edin!",
  alertSuccessTitle: "Bilgi",
  alertErrorTitle: "Uyarı",
  alertErrorMessage: "Lütfen zorunlu alanları doldurun!",
  materialErrorMessage: "Lütfen el aleti adetine eşit ya da küçük değer giriniz!",
  productErrorMessage: "Lütfen ürün adetine eşit ya da küçük değer giriniz!",
  adminErrorMessage: "Lütfen bir yetkiliyle iletişime geçiniz!",
  adminDeleteErrorMessage: "Silme işlemi başarısız! Lütfen bir yetkiliyle iletişime geçiniz",
  listErrorMessage: "Kayıt listelenirken bir hata oluştu!",
  getErrorMessage: "Kayıt getirilirken bir hata oluştu!",
  addSuccessMessage: "Kayıt başarılı bir şekilde eklendi...",
  updateSuccessMessage: "Kayıt başarılı bir şekilde güncellendi...",
  deleteSuccessMessage: "Kayıt başarılı bir şekilde silindi...",
  emailSendSuccessMessage: "Kayıtlı e-mail adresinize bilgileriniz gönderildi...",
  passwordChangeSuccessMessage: "Şifreniz başarılı bir şekilde değiştirildi...",
  sendMailTitle: "E-Mail Gönder",
  sendMailConfirm: "Tamam",
  deleteQuestionTitle: "Soru",
  deleteQuestionMessage: "Kayıtı silmek istediğinize emin misiniz?",
  deleteQuestionYes: "Evet",
  deleteQuestionNo: "Hayır",
  collectionQuestionMessage: "Tahsilat talep etmek istediğinize emin misiniz?",
  collectionQuestionYes: "Evet",
  collectionQuestionNo: "Hayır",
  copyQuestionTitle: "Soru",
  copyQuestionMessage: "Kayıtı kopyalamak istediğinize emin misiniz?",
  copyRevisionQuestionMessage: "Kayıtı revize etmek istediğinize emin misiniz?",
  copyQuestionYes: "Evet",
  copyQuestionNo: "Hayır",
  listButton: "Listele",
  addButton: "Ekle",
  updateButton: "Güncelle",
  projectButton: "Projeler",
  printButton: "Yazdır",
  excelButton: "Excel",
  noteButton: "Not Ekle",
  collectionButton: "Tahsilat Ekle",
  collectionCalendarButton: "T. Takvimi Ekle",
  paymentButton: "Ödeme Ekle",
  collectionMailButton: "Tahsilat İste",
  voteButton: "Oy Güncelle",
  serviceUpdateButton: "Servis Güncelle",
  machineServiceAddButton: "Makine Servis Ekle",
  detailButton: "Detay",
  deleteButton: "Sil",
  saveButton: "Kaydet",
  clearButton: "Temizle",
  backButton: "Geri",
  cancelQuestionTitle: "Soru",
  cancelQuestionMessage: "Kayıtı iptal etmek istediğinize emin misiniz?",
  cancelQuestionYes: "Evet",
  cancelQuestionNo: "Hayır",
  createInvoiceButton: "Fatura Oluştur",
  createInvoice2Button: "İade Faturası Oluştur",
  detailInvoiceButton: "Fatura Detay",
  viewInvoiceButton: "Faturayı Görüntüle",
  cancelInvoiceButton: "Faturayı İptal Et",
  createInvoiceQuestionTitle: "Soru",
  createInvoiceQuestionMessage: "Fatura oluşturmak istediğinize emin misiniz?",
  createInvoice2QuestionMessage: "İade faturası oluşturmak istediğinize emin misiniz?",
  createInvoiceQuestionYes: "Evet",
  createInvoiceQuestionNo: "Hayır",
  assignmentSendQuestionTitle: "Soru",
  assignmentSendQuestionMessage: "İş atamanın personelini değiştirmek istediğinize emin misiniz?",
  userSendQuestionMessage: "Oy Durumunu değiştirmek istediğinize emin misiniz?",
  assignmentSendQuestionYes: "Evet",
  assignmentSendQuestionNo: "Hayır",
  offerQuestionMessage: "Teklifi satışa dönüştürmek istediğinize emin misiniz?",
  orderQuestionMessage: "Siparişi alışa dönüştürmek istediğinize emin misiniz?",
  addToCartTitle: "Sepete Ekle",
  closeButton: "Kapat",
  addToCartSuccessMessage: "Ürün başarılı bir şekilde sepete eklendi...",
  addToStockSuccessMessage: "Ürün başarılı bir şekilde eklendi...",
  stockTransferSuccessMessage: "Stok başarılı bir şekilde aktarıldı...",
  countTransferSuccessMessage: "Sayım başarılı bir şekilde aktarıldı...",
};
export default obj;
