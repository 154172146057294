import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Table,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  UserOperationClaimControl,
  fotoStyle,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import LocationAddUpdate from "../location/LocationAddUpdate";
import MachineProductAddUpdate from "../machineProduct/MachineProductAddUpdate";
import noImg from "../../images/no-img.png";
import CgnDatatable from "../toolbox/CgnDatatable";
import Countdown from "react-countdown";
import { confirmAlert } from "react-confirm-alert";
class ProductCountMachineAddUpdate extends Component {
  state = {
    pageTitle: "Sayım Ekle",
    pageTitleAdd: "Sayım Ekle",
    pageTitleUpdate: "Sayım Güncelle",
    pageAddLink: "sayim2-ekle",
    pageListLink: "sayim2-listele",
    breadcrumb: [{ text: "Sayım", link: "#" }],
    isLoading: false,
    urlSplit: [],
    guid: "",
    locationGuid: null,
    productDataError: "",
    productGuid: null,
    status: true,
    locationError: "",
    alertErrorMessage: "",
    transfer: "",
    isDeleted: false,
    isOpenModalProduct: false,
    modalButtonIdx: 0,
    isNull: false,
    isOpenModalLocation: false,
    controlProduct: false,
    counter: -1,
    productData: [],
    productError: "",
    productCountModal: false,
    productPieceError: "",
    multipleProductArr: [],
    locationArr: [],
    productArr: [],
    productArr2: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    locationModalPlus: false,
    productModalPlus: false,
    productEntryModal: false,
    isOpen: false,
    image: "",
    name: "",
    stockData: [],
    dateNow: "",
    isOpenDateTime: true,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    const today = new Date();
    const lastDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    this.setState({
      lastDayOfLastMonth: lastDayOfLastMonth,
    });
    if (localStorage.getItem("locationGuid")) {
      this.setState({
        locationGuid: localStorage.getItem("locationGuid"),
      });
    }

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    this.setState({
      isLoadingStockData: true,
    });


    await axios
      .get(
        URL +
        "/machineStocks/getlistallstocklocationfullproduct/" +
        this.state.locationGuid,
        config
      )
      .then((response) => {
        this.setState({
          stockData: response.data,
          isLoadingStockData: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoadingStockData: false,
        });
      });

    await axios
      .get(URL + "/locations/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            locationGuid: item.locationGuid,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/machineProducts/getlistselect/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          let machineProductShelfRow = "";
          item.machineProductShelfRowDto.forEach((item) => {
            if (machineProductShelfRow !== "") {
              machineProductShelfRow += ", ";
            }
            machineProductShelfRow += item.shelf + "-" + item.row;
          });

          return {
            value: item.guid,
            label: `${item.code}-${item.name}-${machineProductShelfRow}`,
            image: item.image,
            name: item.name,
            code: item.code,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    if (urlSplit.length === 3 && urlSplit[1] === "sayim2-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/productCountsMachine/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              locationGuid: response.data.locationGuid,
              productData: response.data.productCountProductMachineDto,
              transfer: response.data.transfer,
              status: response.data.status,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        locationGuid: "",
        productData: [],
        status: true,
        transfer: "",
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data
            ) {
              this.setState({
                locationGuid: element.guid,
                locationError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
    if (
      this.state.isOpenModalMachineProduct &&
      this.props.location.modalAddedMachineProduct !== undefined &&
      this.props.location.modalAddedMachineProduct.isModal !== undefined &&
      this.props.location.modalAddedMachineProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/machineProducts/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}`,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMachineProduct.data &&
              `${element.code}-${element.name}` ===
              this.props.location.modalAddedMachineProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].machineProductGuid =
                element.guid;
              productData[this.state.modalButtonIdx].productError = "";
              productData[this.state.modalButtonIdx].pieceDurable = 1;
              productData[this.state.modalButtonIdx].pieceDefective = 1;
              productData[this.state.modalButtonIdx].productUnitPriceError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMachineProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      locationError: "",
      alertErrorMessage: "",
    });
    if (name === "locationGuid" && value === "") {
      this.setState({
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalLocation = () => {
    this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleProductAdd(item.value, item.image, item.name);
        }
      });
    });
  };

  handleProductPieceDurableTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].pieceDurable = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
  }

  handleProductPieceDefectiveTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].pieceDefective = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.machineProductGuid = null;
      item.pieceDurable = "";
      item.pieceDefective = "";
      item.productError = CgnMessage.textErrorMessage;
      item.productPieceDurableError = CgnMessage.textErrorMessage;
      item.productPieceDefectiveError = CgnMessage.textErrorMessage;
    });
    this.setState({
      locationGuid: "",
      counter: -1,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async (value, image, name) => {
    let array = this.state.productData;
    await array.push({
      machineProductGuid: value,
      machineProductImage: image,
      machineProductName: name,
      pieceDurable: 0,
      pieceDefective: 0,
      status: true,
      productError: CgnMessage.textErrorMessage,
      productPieceDurableError: CgnMessage.textErrorMessage,
      productPieceDefectiveError: CgnMessage.textErrorMessage,
      alertErrorMessage: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].machineProductGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].pieceDurable) {
      return array[this.state.counter].productPieceDurableError === "";
    }
    if (array[this.state.counter].pieceDefective) {
      return array[this.state.counter].productPieceDefectiveError === "";
    }
    if (array[this.state.counter].machineProductGuid === "") {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductInputValueChange(option, idx) {
    // let productData = this.state.productData;
    // if (option) {
    //   productData[idx].machineProductGuid = option.value;
    //   productData[idx].piece = 1;
    //   productData[idx].productError = "";
    //   productData[idx].productPieceError = "";
    //   productData[idx].status = true;
    //   this.setState({
    //     productData: productData,
    //     alertErrorMessage: "",
    //   });
    // } else {
    //   productData[idx].machineProductGuid = null;
    //   //productData[idx].piece = 0;
    //   productData[idx].productError = CgnMessage.textErrorMessage;
    //   //productData[idx].productPieceError = CgnMessage.textErrorMessage;
    //   this.setState({
    //     productData: productData,
    //   });
    // }

    let productData = this.state.productData;
    if (option) {
      let productArr = this.state.productArr;
      productArr.forEach((element) => {
        if (element.value === option.value) {
          if (element.image) {
            productData[idx].machineProductName = element.name;
            productData[idx].machineProductImage = element.image;
          } else {
            productData[idx].machineProductName = "";
            productData[idx].machineProductImage = "";
          }
        }
      });

      productData[idx].machineProductGuid = option.value;
      productData[idx].machineProductGuidError = "";
      productData[idx].pieceDurable = 0;
      productData[idx].pieceDefective = 0;
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].machineProductGuid = null;
      productData[idx].machineProductName = "";
      productData[idx].machineProductImage = "";
      productData[idx].machineProductGuidError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
  }

  async handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        productError: "",
        total: 0,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      locationError: "",
      productDataError: "",
      alertErrorMessage: "",
      productError: "",
    });
    if (this.state.locationGuid === null) {
      this.setState({
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var rowControl = true;
    this.state.productData.forEach((element) => {
      if (element.machineProductGuid === null) {
        rowControl = false;
      }
      if (element.pieceDurable === null) {
        rowControl = false;
      }
      if (element.pieceDefective === null) {
        rowControl = false;
      }
    });

    if (rowControl === false) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.locationGuid !== null &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      rowControl
    ) {

      
      
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          machineProductGuid: element.machineProductGuid,
          pieceDurable: element.pieceDurable,
          pieceDefective: element.pieceDefective,
          status: true,
        });
      });

      if (this.state.productArr2 != null && this.state.productArr2.length > 0) {
        this.state.productArr2.forEach((element2) => {
          var lVarmi = false;
          this.state.productData.forEach((element1) => {
            if (element2.machineProductGuid === element1.machineProductGuid) {
              lVarmi = true;
            }
          });

          if (lVarmi === false) {
            this.setState({
              controlProduct: true,
            });
            productData.push({
              guid: element2.guid,
              machineProductGuid: element2.machineProductGuid,
              pieceDurable: 0,
              pieceDefective: 0,
              status: true,
            });
          }
        });
        this.setState({
          productData: productData,
        });
      }

      if (this.state.controlProduct === false) {
        var myObj = {
          locationGuid: this.state.locationGuid,
          transfer: 0,
          status: true,
          productCountProductMachineDto: productData,
        };
        var URLParam = "/productCountsmachine/add";
        if (this.state.guid && this.state.urlSplit.length !== 2) {
          URLParam = "/productCountsmachine/update";
          myObj = {
            guid: this.state.guid,
            locationGuid: this.state.locationGuid,
            status: true, //this.state.status,
            productCountProductMachineDto: productData,
          };
        }
        this.setState({
          isLoading: true,
        });
        // 
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        axios
          .post(URL + URLParam, myObj, config)
          .then(() => {
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (this.state.guid) {
                      window.location.href = "/sayim2-listele";
                    } else {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          })
          .catch((error) => {
            confirmAlert({
              title: CgnMessage.alertErrorTitle,
              message: error.response.data,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    this.setState({
                      isLoading: false,
                    });
                  }
                },
              ],
            });
          });
      } else {
        this.setState({
          isLoading: false,
          controlProduct: false,
        });
      }
    }
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  toggleMultiProduct = () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
  };

  handleProductPieceDurableTextBoxChange2(e, row) {
    let stockData = this.state.stockData;
    stockData.forEach((item) => {
      if (item.guid === row.guid) {
        item.pieceDurableNew = parseFloat(e.target.value);
      }
    });
    this.setState({
      stockData: stockData,
    });
  }

  handleProductPieceDefectiveTextBoxChange2(e, row) {
    let stockData = this.state.stockData;
    stockData.forEach((item) => {
      if (item.guid === row.guid) {
        item.pieceDefectiveNew = parseFloat(e.target.value);
      }
    });
    this.setState({
      stockData: stockData,
    });
  }

  handleAddToCart = async (row) => {
    if (row.pieceDurableNew && !row.pieceDefectiveNew) {
      row.pieceDefectiveNew = 0;
    }
    if (!row.pieceDurableNew && row.pieceDefectiveNew) {
      row.pieceDurableNew = 0;
    }
    if (
      row.guid &&
      (row.pieceDurableNew || row.pieceDefectiveNew) &&
      row.pieceDurableNew + row.pieceDefectiveNew > 0
    ) {
      let array = this.state.productData;

      let control = false;
      array.forEach((item) => {
        if (item.machineProductGuid === row.guid) {
          item.pieceDurable = row.pieceDurableNew;
          item.pieceDefective = row.pieceDefectiveNew;
          control = true;
        }
      });

      if (control === false) {
        await array.push({
          machineProductGuid: row.machineProductGuid,
          machineProductImage: row.machineProductImage,
          machineProductName: row.machineProductName,
          pieceDurable: row.pieceDurableNew,
          pieceDefective: row.pieceDefectiveNew,
          status: true,
          productError: "",
          productPieceError: "",
          alertErrorMessage: "",
        });
        await this.setState({
          counter: this.state.counter + 1,
        });
        // if (array[this.state.counter].machineProductGuid) {
        //   return array[this.state.counter].productError === "";
        // }
        // if (array[this.state.counter].pieceDurable) {
        //   return array[this.state.counter].pieceDurableError === "";
        // }
        // if (array[this.state.counter].pieceDefective) {
        //   return array[this.state.counter].pieceDefectiveError === "";
        // }
        // if (array[this.state.counter].machineProductGuid === "") {
        //   this.setState({
        //     alertErrorMessage: CgnMessage.alertErrorMessage,
        //   });
        // }
      }
      this.setState({ productData: array });

      confirmAlert({
        title: CgnMessage.alertSuccessTitle,
        message: CgnMessage.addToStockSuccessMessage,
        buttons: [
          {
            label: CgnMessage.closeButton,
          },
        ],
      });
    }
  };

  thStyle = {
    width: "100px",
  };

  thStyle2 = {
    width: "90px",
  };

  tdStyle = {
    width: "250px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />

        {!this.state.isOpenDateTime &&
          localStorage.getItem("isProductCountMachine") === "false" && (
            <Card className="countdown-container" body>
              <p>
                Bu sayfaya her ayın 1. - 5. günleri arasında ulaşım
                sağlayabilirsiniz!
              </p>
              <p>
                Kalan Süre{" "}
                <span className="count">
                  <Countdown date={new Date(this.state.lastDayOfLastMonth)} />
                </span>
              </p>
            </Card>
          )}
        {(this.state.isOpenDateTime ||
          localStorage.getItem("isProductCountMachine") === "true") && (
            <>
              <Row className="productCountAddUpdate">
                <Col>
                  <Form onSubmit={this.handleSave}>
                    <Card body>
                      <CgnLoader visibled={this.state.isLoading} />
                      {this.state.locationGuid ===
                        "00000000-0000-0000-0000-000000000000" && (
                          <Row>
                            <Col lg="12" className="modalSelectCol">
                              <div className="flex-container">
                                <div className="p-0 flexInput">
                                  <CgnReactSelect
                                    name="locationGuid"
                                    label="Depo [*]"
                                    placeholder="Depo seçiniz..."
                                    selectValue={this.state.locationGuid}
                                    isMulti={false}
                                    isClearable={true}
                                    onChange={(option) =>
                                      this.handleLocationSelectChange(option)
                                    }
                                    options={this.state.locationArr}
                                    error={this.state.locationError}
                                  />
                                </div>
                                {this.state.locationModalPlus && (
                                  <div className="p-0 flexAddButton">
                                    <CgnButton
                                      type="button"
                                      color="secondary"
                                      className="modalSelectAddButton"
                                      onClick={() => this.addModalLocation()}
                                      text={"+"}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                      {this.state.productData.map((productData, idx) => (
                        <Row className="mobileBorder">
                          <Col lg="7" className="modalSelectCol">
                            {productData.machineProductImage ? (
                              <Button
                                onClick={() =>
                                  this.toggle(
                                    productData.machineProductImage,
                                    productData.machineProductName
                                  )
                                }
                                className="cart_image_button"
                              >
                                <img
                                  alt={productData.machineProductName}
                                  src={`/Images/${productData.machineProductImage}`}
                                  className="cart_no_image rounded"
                                />
                              </Button>
                            ) : (
                              <img
                                alt={productData.machineProductName}
                                src={noImg}
                                className="cart_no_image rounded"
                              />
                            )}
                            <div className="flex-container">
                              <div className="p-0 flexInput">
                                <CgnReactSelect
                                  name="machineProductGuid"
                                  label="Ürün [*]"
                                  placeholder="Lütfen ürün seçiniz..."
                                  selectValue={productData.machineProductGuid}
                                  isMulti={false}
                                  isClearable={true}
                                  onChange={(option) => {
                                    this.handleProductInputValueChange(
                                      option,
                                      idx
                                    );
                                  }}
                                  options={this.state.productArr}
                                  error={productData.productError}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col lg="2">
                            <CgnTextbox
                              type="number"
                              name="pieceDurable"
                              label="Sağlam Adet [*]"
                              value={productData.pieceDurable}
                              onChange={(e) =>
                                this.handleProductPieceDurableTextBoxChange(
                                  e,
                                  idx
                                )
                              }
                              placeHolder="Lütfen adet girin..."
                              maxLength="50"
                              autoComplete="off"
                              error={productData.productPieceDurableError}
                            />
                          </Col>

                          <Col lg="2">
                            <CgnTextbox
                              type="number"
                              name="pieceDefective"
                              label="Arızalı Adet [*]"
                              value={productData.pieceDefective}
                              onChange={(e) =>
                                this.handleProductPieceDefectiveTextBoxChange(
                                  e,
                                  idx
                                )
                              }
                              placeHolder="Lütfen adet girin..."
                              maxLength="50"
                              autoComplete="off"
                              error={productData.productPieceDefectiveError}
                            />
                          </Col>
                          <Col lg="1" sm="3">
                            <CgnButton
                              type="button"
                              color="danger"
                              className="dynamicRowDeleteButton"
                              onClick={() => this.handleProductRemove(idx)}
                              text={"Sil"}
                            />
                          </Col>
                        </Row>
                      ))}

                      {this.state.alertErrorMessage && (
                        <CgnAlert
                          color="danger"
                          title={CgnMessage.alertErrorTitle}
                          text={this.state.alertErrorMessage}
                        />
                      )}

                      <Row>
                        <CgnButton
                          type="button"
                          color="primary"
                          className="dynamicRowAddButton productAdd"
                          onClick={this.handleProductAdd}
                          text={"Ürün Ekle"}
                        />
                        <CgnButton
                          type="button"
                          color="primary"
                          className="ml-2 dynamicRowAddButton"
                          onClick={this.toggleMultiProduct}
                          text={"Çoklu Ürün Ekle"}
                        />
                      </Row>
                      <CgnButtonGroup items={this.state.buttons} />
                    </Card>
                  </Form>
                  <CgnButtonLink
                    color="secondary"
                    to={"/" + this.state.pageListLink}
                    text={CgnMessage.backButton}
                    className="btn-back"
                  />
                </Col>
              </Row>

              <Row className="assignmentList" id="list">
                <Col>
                  <Card body>
                    <CgnLoader visibled={this.state.isLoadingStockData} />
                    <CgnDatatable
                      data={this.state.stockData}
                      title={this.state.pageTitle}
                      columns={[
                        {
                          name: "Fotoğraf",
                          selector: "image",
                          sortable: false,
                          width: "80px",
                          cell: (cell) => (
                            <div>
                              {cell.machineProductImage ? (
                                <Button
                                  onClick={() =>
                                    this.toggle(
                                      cell.machineProductImage,
                                      cell.machineProductName
                                    )
                                  }
                                  className="modalButtonList"
                                >
                                  <img
                                    alt={cell.machineProductName}
                                    src={`/Images/${cell.machineProductImage}`}
                                    className="rdt_image rounded"
                                  />
                                </Button>
                              ) : (
                                <img
                                  alt={cell.machineProductName}
                                  src={noImg}
                                  className="rdt_image rdt_no_image rounded"
                                />
                              )}
                            </div>
                          ),
                        },
                        {
                          name: "Ürün Adet Bilgileri",
                          sortable: false,
                          cell: (row) => {
                            return (
                              <Table className="table table-responsive ">
                                <tbody>
                                  <tr>
                                    <th style={this.thStyle}>Depo</th>
                                    <td colSpan={3}>{row.locationName}</td>
                                  </tr>
                                  <tr>
                                    <th style={this.thStyle}>Ürün Kodu</th>
                                    <td style={this.tdStyle}>
                                      {row.machineProductCode}
                                    </td>
                                    <th style={this.thStyle2}>Ürün Adı</th>
                                    <td>{row.machineProductName}</td>
                                  </tr>
                                  <tr>
                                    <th style={this.thStyle}>Sağlam Adet</th>
                                    <td style={this.tdStyle}>
                                      {row.pieceDurable}
                                    </td>
                                    <th style={this.thStyle2}>Arızalı Adet</th>
                                    <td>{row.pieceDefective}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            );
                          },
                        },
                        {
                          name: "Sağlam Adet",
                          width: "125px",
                          ignoreRowClick: true,
                          allowOverflow: true,
                          button: true,
                          cell: (row, idx) => (
                            <CgnTextbox
                              type="number"
                              name="pieceDurableNew"
                              className="dataTableInput"
                              value={row.pieceDurableNew}
                              placeHolder="Adet"
                              maxLength="100"
                              autoComplete="off"
                              onChange={(e) =>
                                this.handleProductPieceDurableTextBoxChange2(
                                  e,
                                  row
                                )
                              }
                              error={row.pieceDurableNewError}
                            />
                          ),
                        },
                        {
                          name: "Arızalı Adet",
                          width: "125px",
                          ignoreRowClick: true,
                          allowOverflow: true,
                          button: true,
                          cell: (row, idx) => (
                            <CgnTextbox
                              type="number"
                              name="pieceDefectiveNew"
                              className="dataTableInput"
                              value={row.pieceDefectiveNew}
                              placeHolder="Adet"
                              maxLength="100"
                              autoComplete="off"
                              onChange={(e) =>
                                this.handleProductPieceDefectiveTextBoxChange2(
                                  e,
                                  row
                                )
                              }
                              error={row.pieceDefectiveNewError}
                            />
                          ),
                        },
                        {
                          width: "125px",
                          ignoreRowClick: true,
                          allowOverflow: true,
                          button: true,
                          cell: (row, index) => (
                            <CgnButton
                              text={"Güncelle"}
                              type="button"
                              className="w-100 ml-0"
                              color="warning"
                              onClick={() => this.handleAddToCart(row)}
                            />
                          ),
                        },
                      ]}
                    />
                  </Card>
                </Col>
              </Row>

              <Modal
                isOpen={this.state.isOpenModalLocation}
                toggle={this.addModalLocation}
                className="modal-xl content"
              >
                <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
                <ModalBody className="modalBody">
                  <LocationAddUpdate />
                </ModalBody>
                <ModalFooter>
                  <CgnButton
                    type="button"
                    color="secondary"
                    onClick={this.addModalLocation}
                    text="Kapat"
                    className="modalButton"
                  />
                </ModalFooter>
              </Modal>

              <Modal
                isOpen={this.state.isOpenModalProduct}
                toggle={this.addModalProduct}
                className="modal-xl content"
              >
                <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
                <ModalBody className="modalBody">
                  <MachineProductAddUpdate />
                </ModalBody>
                <ModalFooter>
                  <CgnButton
                    type="button"
                    color="secondary"
                    onClick={this.addModalProduct}
                    text="Kapat"
                    className="modalButton"
                  />
                </ModalFooter>
              </Modal>
              <Modal
                className="modal-xs"
                isOpen={this.state.isOpen}
                toggle={this.toggle}
              >
                <ModalHeader toggle={this.toggle}></ModalHeader>
                <img
                  className="modalImage"
                  src={`/Images/${this.state.image}`}
                  style={fotoStyle}
                  alt={this.state.name}
                />
              </Modal>
              <Modal
                isOpen={this.state.productEntryModal}
                toggle={this.toggleMultiProduct}
              >
                <ModalHeader
                  toggle={this.toggleMultiProduct}
                  className="font-weight-bold"
                >
                  Çoklu Ürün Ekle
                </ModalHeader>
                <ModalBody className="modalBody">
                  <Input
                    type="textarea"
                    className="modalTextArea"
                    onChange={(e) => this.handleChangeMultipleProduct(e)}
                  />
                </ModalBody>
                <ModalFooter>
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={() => this.handleClickMultipleProduct()}
                    text={CgnMessage.addButton}
                    className="modalButton"
                  />
                  <CgnButton
                    type="button"
                    color="secondary"
                    onClick={this.toggleMultiProduct}
                    text="Kapat"
                    className="modalButton"
                  />
                </ModalFooter>
              </Modal>
            </>
          )}
      </>
    );
  }
}

export default ProductCountMachineAddUpdate;
