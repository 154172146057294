import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const FileInput = ({
  accept,
  name,
  label,
  onChange,
  placeHolder,
  value,
  error,
  autoComplete,
  multiple,
  onDelete,
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <FormGroup className={wrapperClass}>
      <Label className="font-weight-bolder" for={name}>
        {label}
      </Label>
      <Input
        type="file"
        accept={accept}
        name={name}
        id={name}
        value={value}
        placeholder={placeHolder}
        onChange={onChange}
        onDelete={onDelete}
        autoComplete={autoComplete}
        multiple={multiple}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
};

export default FileInput;
