import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { localFilePath, URL } from "../../redux/actions/constants";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";

class SieveCriticalProductList extends Component {
  state = {
    pageTitle: "Kritik Ürün Listele",
    pageAddLink: "urun2-ekle",
    pageUpdateLink: "urun2-guncelle",
    pageDetailLink: "urun2-detay",
    pageListLink: "urun2-listele",
    breadcrumb: [{ text: "Kritik Ürün", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    criticalProductData: [],
    alertErrorMessage: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    axios
      .get(URL + "/sieveProducts/getcriticalpiecelist/" + statusType, config)
      .then((response) => {
        this.setState({
          criticalProductData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleBarcodePrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/sieveProducts/barcodenumberprint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "95px",
  };

  thStyle2 = {
    width: "90px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "400px",
  };

  tdStyle3 = {
    width: "400px",
    color: 'red'
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          // addLink={"/" + this.state.pageAddLink}
          // listLink={"/" + this.state.pageListLink}
        />
        <Row className="criticalProductList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.criticalProductData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Kritik Ürün Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Kategori Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={4}>{row.categoryName}</td>
                            </tr>

                            <tr>
                              <th style={this.thStyle2}>Alış Fiyatı</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.buyPrice > 0
                                  ? row.buyPrice.toLocaleString("tr-TR", {
                                      minimumFractionDigits: 2,
                                    }) +
                                    " " +
                                    (row.currencyType === 1
                                      ? "₺"
                                      : row.currencyType === 2
                                      ? "$"
                                      : row.currencyType === 3
                                      ? "€"
                                      : "-")
                                  : "-"}
                              </td>

                              <th style={this.thStyle2}>Satış Fiyatı</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.salePrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ürün Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle2}>{row.name}</td>
                              <th style={this.thStyle2}>Stok Durumu</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.stockStatus === 1 ? "Stoklu Ürün" : "Stoksuz Ürün"}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Elek Boyutu</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.sieveSize}</td>
                              <th style={this.thStyle2}>Elek Göz Boyutu</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.sieveEyeSize}</td>
                            </tr>
                            {row.criticalPieces ? (
                              <>
                                <tr>
                                  <th style={this.thStyle}>Stok</th>
                                  {/* <th className="colon">:</th> */}
                                  <td style={this.tdStyle3}>{row.productStock}</td>
                                  <th style={this.thStyle2}>Kritik Stok</th>
                                  {/* <th className="colon">:</th> */}
                                  <td style={{color:'red'}}>{row.criticalPieces}</td>                                  
                                </tr>
                              </>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Barkod Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handleBarcodePrint(row.guid),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default SieveCriticalProductList;
