import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import {
  
  URL,
  ReplyType,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
} from "../../redux/actions/constants";
import axios from "axios";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButton from "../toolbox/CgnButton";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class ListIncomingInvoice extends Component {
  state = {
    pageTitle: "Gelen Fatura Listele",
    breadcrumb: [{ text: "Fatura", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    data: [],
    isOpenModalReply: false,
    isLoadingModal: false,
    replyType: 0,
    replyTypeError: "",
    replyDescription: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "button",
        color: "primary",
        text: CgnMessage.saveButton,
        onClick: (event) => this.handleSave(event),
        disabled: this.isLoading,
      },
    ],
    alertModalErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateIncomingInvoice")) {
      firstDate = localStorage.getItem("firstDateIncomingInvoice");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateIncomingInvoice")) {
      lastDate = localStorage.getItem("lastDateIncomingInvoice");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateIncomingInvoice", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateIncomingInvoice", this.state.lastDate);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .get(
        URL +
          "/bills/getlistincoming" +
          "/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate,
        config
      )
      .then((response) => {
        this.setState({
          data: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalReply = (uuid) => {
    this.setState({
      uuidReply: uuid,
      isOpenModalReply: !this.state.isOpenModalReply,
      alertModalErrorMessage: "",
    });
  };

  handleClear = () => {
    this.setState({
      replyType: 0,
      replyTypeError: "",
      replyDescription: "",
      alertModalErrorMessage: "",
    });
  };

  async handleReplyTypeSelectChange(option) {
    if (option) {
      await this.setState({
        replyType: option.value,
        replyTypeError: "",
        alertModalErrorMessage: "",
      });
    } else {
      await this.setState({
        replyType: 0,
        replyTypeError: CgnMessage.textErrorMessage,
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSave = async (event) => {
    this.setState({
      replyTypeError: "",
    });
    if (this.state.replyType === 0) {
      this.setState({
        replyTypeError: CgnMessage.textErrorMessage,
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.replyType !== 0) {
      this.setState({
        isLoading: true,
        isLoadingModal: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        uuid: this.state.uuidReply,
        replyCogen: this.state.replyType,
        replyDescription: this.state.replyDescription,
      };
      axios
        .post(URL + "/bills/reply", myObj, config)
        .then(() => {
          this.setState({
            isOpenModalReply: !this.state.isOpenModalReply,
            alertModalErrorMessage: "",
            isLoading: false,
            isLoadingModal: false,
          });
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            alertModalErrorMessage: error.response.data,
            isOpenModalReply: !this.state.isOpenModalReply,
            isLoading: false,
            isLoadingModal: false,
          });
        });
    } else {
      this.setState({
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  thStyle = {
    width: "125px",
  };

  th2Style = {
    width: "90px",
  };

  tdStyle = {
    width: "300px",
  };

  td2Style = {
    width: "400px",
  };

  badgeStyle = {
    width: "100%",
  };

  textAreaStyle = {
    padding: "5px 10px",
    height: "100px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row className="costList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-0 pr-2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.data}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Gelen Fatura Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tr>
                            <th style={this.thStyle}>Tarih</th>
                            <td style={this.tdStyle}>{row.dateTime}</td>
                            <th style={this.thStyle}>Firma Adı</th>
                            <td>{row.companyName}</td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>Fatura Numarası</th>
                            <td style={this.tdStyle}>{row.billNumber}</td>
                            <th style={this.thStyle}>Vergi Dairesi</th>
                            <td>
                              {row.companyTaxAdministration
                                ? row.companyTaxAdministration
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>UUID</th>
                            <td style={this.tdStyle}>{row.uuid}</td>
                            <th style={this.thStyle}>Vergi Numarası</th>
                            <td>{row.companyTaxNumber}</td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>Genel Tutar</th>
                            <td colSpan={3}>
                              {row.generalPrice.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              })}{" "}
                              TL
                            </td>
                          </tr>
                        </Table>
                      );
                    },
                  },
                  {
                    name: "",
                    width: "125px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.billType === "TICARIFATURA") {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="info"
                            text="Ticari Fatura"
                          />
                        );
                      } else if (row.billType === "TEMELFATURA") {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="primary "
                            text="Temel Fatura"
                          />
                        );
                      }
                    },
                  },
                  {
                    name: "",
                    width: "175px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (
                        row.billType === "TICARIFATURA" &&
                        row.replyCogen === 0
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="info"
                            text="Cevap Bekleniyor"
                          />
                        );
                      } else if (
                        row.billType === "TICARIFATURA" &&
                        row.replyCogen === 1 &&
                        row.reply === 0
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="primary"
                            text="Kabul Ediliyor"
                          />
                        );
                      } else if (
                        row.billType === "TICARIFATURA" &&
                        row.replyCogen === 1 &&
                        row.reply === 1
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="success"
                            text="Kabul Edildi"
                          />
                        );
                      } else if (
                        row.billType === "TICARIFATURA" &&
                        row.replyCogen === 2 &&
                        row.reply === 0
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="warning"
                            text="Reddediliyor"
                          />
                        );
                      } else if (
                        row.billType === "TICARIFATURA" &&
                        row.replyCogen === 2 &&
                        row.reply === 2
                      ) {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="danger"
                            text="Reddedildi"
                          />
                        );
                      } else if (row.billType === "TEMELFATURA") {
                        return (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="success "
                            text="Kabul Edildi"
                          />
                        );
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) =>
                      row.uuid !== "00000000-0000-0000-0000-000000000000" && (
                        <CgnButtonDropdown
                          label={"Yönet"}
                          className="mr-0"
                          color="primary"
                          items={[
                            {
                              text: "Detay",
                              className: "dropDownBtn",
                              href: `/gelen-fatura-detay/${row.uuid}`,
                            },
                            {
                              text:
                                row.pdfFileName !== null &&
                                row.pdfFileName !== ""
                                  ? "Görüntüle"
                                  : "",
                              className: "dropDownBtn",
                              onClick: () =>
                                window.open(
                                  `/EFatura/${row.pdfFileName}`,
                                  "_blank"
                                ),
                            },
                            {
                              text:
                                row.billType === "TICARIFATURA" &&
                                row.reply === 0 &&
                                row.replyCogen === 0
                                  ? "Cevap Ver"
                                  : "",
                              className: "dropDownBtn",
                              onClick: () => this.addModalReply(row.uuid),
                            },
                          ]}
                        />
                      ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalReply}
          toggle={this.addModalReply}
          className="modal-xs content"
        >
          <ModalHeader className="font-weight-bold">Cevap Ver</ModalHeader>
          <ModalBody className="modalBody">
            <CgnLoader visibled={this.state.isLoadingModal} />
            <Row>
              <Col>
                <CgnReactSelect
                  name="replyType"
                  label="Cevap Tipi [*]"
                  placeholder="Lütfen cevap tipi seçiniz..."
                  selectValue={this.state.replyType}
                  isMulti={false}
                  isClearable={true}
                  onChange={(option) =>
                    this.handleReplyTypeSelectChange(option)
                  }
                  options={ReplyType}
                  error={this.state.replyTypeError}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CgnTextbox
                  inlineStyle={this.textAreaStyle}
                  type="textarea"
                  name="replyDescription"
                  label="Açıklama"
                  value={this.state.replyDescription}
                  placeHolder="Lütfen açıklama girin..."
                  autoComplete="off"
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.alertModalErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertModalErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalReply}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ListIncomingInvoice;
