import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import moment from "moment";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import { confirmAlert } from "react-confirm-alert";

class WorkAccidentAddUpdate extends Component {
  state = {
    pageTitle: "İş Kazası Ekle",
    pageTitleAdd: "İş Kazası Ekle",
    pageTitleUpdate: "İş Kazası Güncelle",
    pageAddLink: "is-kazasi-ekle",
    pageListLink: "is-kazasi-listele",
    breadcrumb: [{ text: "İş Kazası", link: "#" }],
    isLoading: false,
    urlSplit: [],
    workAccidentDate: "",
    name: "",
    description: "",
    guid: "",
    status: true,
    workAccidentDateError: "",
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/workaccident/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              workAccidentDate: response.data.workAccidentDate,
              name: response.data.name,
              description: response.data.description,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        workAccidentDate: "",
        name: "",
        description: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleDateChange(workAccidentDate) {
    if (workAccidentDate) {
      this.setState({
        workAccidentDate: workAccidentDate,
        workAccidentDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        workAccidentDate: "",
        workAccidentDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      description: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      workAccidentDateError: "",
      nameError: "",
    });
    if (this.state.workAccidentDate === "") {
      this.setState({
        workAccidentDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    if (this.state.workAccidentDate !== "" && this.state.name !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        workAccidentDate: moment(this.state.workAccidentDate).format(
          "DD/MM/YYYY"
        ),
        name: this.state.name,
        description: this.state.description,
        status: this.state.status,
      };
      var URLParam = "/workaccident/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "is-kazasi-guncelle"
      ) {
        URLParam = "/workaccident/update";
        myObj = {
          guid: this.state.guid,
          workAccidentDate:
            this.state.workAccidentDate &&
              this.state.workAccidentDate.toString().indexOf("/") > 0
              ? new Date(
                this.state.workAccidentDate.split("/")[2] +
                "-" +
                this.state.workAccidentDate.split("/")[1] +
                "-" +
                this.state.workAccidentDate.split("/")[0]
              )
              : moment(this.state.workAccidentDate).format("DD/MM/YYYY"),
          name: this.state.name,
          description: this.state.description,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          confirmAlert({
            title: CgnMessage.alertSuccessTitle,
            message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  if (
                    this.state.guid &&
                    this.state.urlSplit[1] === "is-kazasi-guncelle"
                  ) {
                    window.location.href = "/is-kazasi-listele";
                  }
                  if (this.state.urlSplit[1] === "is-kazasi-ekle") {
                    window.location.reload();
                  }
                }
              },
            ],
          });
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        {(this.state.urlSplit[1] === "is-kazasi-ekle" ||
          this.state.urlSplit[1] === "is-kazasi-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="workAccidentDate"
                      label="Tarih [*]"
                      selected={
                        this.state.workAccidentDate &&
                          this.state.workAccidentDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.workAccidentDate.split("/")[2] +
                            "-" +
                            this.state.workAccidentDate.split("/")[1] +
                            "-" +
                            this.state.workAccidentDate.split("/")[0]
                          )
                          : this.state.workAccidentDate
                      }
                      onChange={(workAccidentDate) => this.handleDateChange(workAccidentDate)}
                      error={this.state.workAccidentDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="İş Kazası [*]"
                      value={this.state.name}
                      placeHolder="Lütfen iş kazası girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "is-kazasi-ekle" ||
              this.state.urlSplit[1] === "is-kazasi-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(WorkAccidentAddUpdate);
