import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  
  URL,
  localFilePath,
  fotoStyle,
  fotoStyle2
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";

class CustomerDocumentProductRecordDetail extends Component {
  state = {
    pageTitle: "Müşteri Tarafından Eklenen/Eklenecek Belgeler Detay (ÜTS Ürün Kaydı)",
    pageTitleDetail: "Müşteri Tarafından Eklenen/Eklenecek Belgeler Detay (ÜTS Ürün Kaydı)",
    pageAddLink: "musteri-urun-belge-kayit-ekle",
    pageListLink: "musteri-urun-belge-kayit-listele",
    breadcrumb: [{ text: "Müşteri Tarafından Eklenen/Eklenecek Belgeler", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    requestedDocumentsName: "",
    customerDocumentImageProductDto: [],
    barcodeDocumentDto: [],
    barcodeProductImageDto: [],
    gtipDocumentDto: [],
    status: "",
    offerTotal: "",
    currencyType: "",
    remainingAmount: "",
    customerMail: "",
    guid: "",
    createdBy: "",
    productName: "",
    barcode: "",
    productImage: "",
    tag: "",
    gtipNo: "",
    total: "",
    addTotal: "",
    description: "",
    createdAt: "",
    isLoadingModal: false,
    updatedAt: "",
    updatedBy: "",
    paymentValue: "",
    isOpenModalPayment: false,
    paymentValueEFT: "",
    isOpenModalPaymentEFT: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/customerDocumentProductRecords/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            customerName: response.data.customerName,
            customerMail: response.data.customerMail,
            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            productImage: response.data.productImage,
            productName: response.data.productName,
            barcode: response.data.barcode,
            tag: response.data.tag,
            gtipNo: response.data.gtipNo,
            isLoading: false,
            total: response.data.total,
            offerTotal: response.data.offerTotal,
            remainingAmount: response.data.remainingAmount,
            currencyType: response.data.currencyType,
            customerGroupId: response.data.customerGroupId,
            customerGuid: response.data.customerGuid,
            barcodeDocumentDto: response.data.barcodeDocumentDto,
            barcodeProductImageDto: response.data.barcodeProductImageDto,
            gtipDocumentDto: response.data.gtipDocumentDto,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  ticketClick = () => {
    window.open("/musteri-urun-bilgilendirme-sistemi/" + this.state.customerGuid + "/" + this.state.guid + "/1", "_blank");
    this.setState({
      isLoading: false,
    });
  };

  enTicketClick = () => {
    window.open("/customer-product-information-system/" + this.state.customerGuid + "/" + this.state.guid + "/1", "_blank");
    this.setState({
      isLoading: false,
    });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggle1 = () => {
    this.setState({
      isOpen1: !this.state.isOpen1,
    });
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handleSendPayment = async () => {
    this.setState({
      isOpenModalPayment: !this.state.isOpenModalPayment,
    });
  };

  handleSendPaymentEFT = async () => {
    this.setState({
      isOpenModalPaymentEFT: !this.state.isOpenModalPaymentEFT,
    });
  };

  handleSendMailPayment = async () => {
    this.setState({
      isLoadingModal: true,
    });
    if(this.state.paymentValue === "" || this.state.paymentValue === null){
      this.setState({
        paymentValueError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoadingModal: false,
      });
    }
    else if(this.state.paymentValue !== "" || this.state.paymentValue !== null){
      this.setState({
        isLoadingModal: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/customerDocumentProductRecords/paymentsendmail";
      var myObj = {
        guid: this.state.guid,
        customerName: this.state.customerName,
        customerMail: this.state.customerMail,
        total: parseFloat(this.state.paymentValue),
      };
       axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
              window.location.reload();
            this.setState({
              isLoadingModal: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoadingModal: false,
          });
        });
    }
    else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSendMailPaymentEFT = async () => {
    this.setState({
      isLoadingModal: true,
    });
    if(this.state.paymentValueEFT === "" || this.state.paymentValueEFT === null){
      this.setState({
        paymentValueError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoadingModal: false,
      });
    }
    else if(this.state.paymentValueEFT !== "" || this.state.paymentValueEFT !== null){
      this.setState({
        isLoadingModal: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/customerDocumentProductRecords/addpayment";
      var myObj = {
        guid: this.state.guid,
        customerName: this.state.customerName,
        customerMail: this.state.customerMail,
        addTotal: parseFloat(this.state.paymentValueEFT),
      };
       axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
              window.location.reload();
            this.setState({
              isLoadingModal: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoadingModal: false,
          });
        });
    }
    else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self1 = this;
    var self = this.state;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">
                      {this.state.customerName ? this.state.customerName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description
                        ? this.state.description
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                  <th>Ürün Adı</th>
                    <td colSpan="4">
                      {this.state.productName ? this.state.productName : "-"}
                    </td>
                </tr>
                <tr>
                  <th>Barkod</th>
                    <td colSpan="4">
                      {this.state.barcode ? this.state.barcode : "-"}
                    </td>
                </tr>
                <tr>
                    <th>Etiket</th>
                    <td colSpan="4">
                      {this.state.tag ? (
                        <Button
                          onClick={this.toggle1}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.tag}
                            style={fotoStyle}
                            alt={this.state.productName}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.productName}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal isOpen={this.state.isOpen1} toggle={this.toggle1}>
                    <ModalHeader toggle={this.toggle1}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.tag}
                      style={fotoStyle}
                      alt={this.state.productName}
                    />
                  </Modal>
                <tr>
                  <th>GTİP Numarası</th>
                    <td colSpan="4">
                      {this.state.gtipNo ? this.state.gtipNo : "-"}
                    </td>
                </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>   
              {this.state.barcodeDocumentDto &&
                this.state.barcodeDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Dosya</th>
                    </tr>
                    <Row>
                      {this.state.barcodeDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={excelIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}        

              {this.state.barcodeProductImageDto !== null &&
                this.state.barcodeProductImageDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Ürün Fotoğrafları</th>
                      </tr>
                      <Row>
                        {this.state.barcodeProductImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}

             {this.state.gtipDocumentDto &&
                this.state.gtipDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Gtip Numaraları</th>
                    </tr>
                    <Row>
                      {this.state.gtipDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={excelIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}   

                <Row className="mt-3" style={{display: this.state.total !== 0 ? "" : "none", color:"red"}}>
                   <div>
                    <p style={{fontWeight: "bold"}}>Ödenen Tutar : {this.state.total + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                 <p style={{fontWeight: "bold"}}>Kalan Ödeme Tutarı : {(parseFloat(this.state.offerTotal) - parseFloat(this.state.total)).toFixed(2) + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                   </div>
                </Row>
                {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />

            <CgnButton
              style={{float:"right"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.ticketClick()}
              text={"Ticket Sistemi"}
              target="_blank"
            />

            <CgnButton
              style={{float:"right", width: "150px"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.enTicketClick()}
              text={"EN Ticket Sistemi"}
              target="_blank"
            />

              <CgnButton
              style={{display: (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)) > 0 ? "" : "none", float:"right"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.handleSendPayment()}
              text={"Ödeme Ekle"}
            />

            <CgnButton
              style={{display: (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)) > 0 ? "" : "none", float:"right"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.handleSendPaymentEFT()}
              text={"Gelen Ödeme"}
            />

            <Modal
              isOpen={this.state.isOpenModalPayment}
              toggle={this.handleSendPayment}
              className="modal-xl content"
            >
              <ModalHeader className="font-weight-bold">
                Ödeme Gönder
              </ModalHeader>
              <ModalBody className="modalBody">
              <CgnLoader visibled={this.state.isLoadingModal} />
                <Row>
                  <Col>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Teklif Tutarı : {this.state.offerTotal + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Ödenen Tutar : {this.state.remainingAmount + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                    <CgnTextbox
                      type="number"
                      name="paymentValue"
                      label="Ödenecek Tutar"
                      value={this.state.paymentValue}
                      placeHolder="Lütfen ödenecek tutarı girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleChange(e)}
                      step="0.1"
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
              <CgnButton
                  type="button"
                  color="primary"
                  onClick={this.handleSendMailPayment}
                  text="Gönder"
                  className="modalButton"
                />
                <CgnButton
                  type="button"
                  color="secondary"
                  onClick={this.handleSendPayment}
                  text="Kapat"
                  className="modalButton"
                />
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.isOpenModalPaymentEFT}
              toggle={this.handleSendPaymentEFT}
              className="modal-xl content"
            >
              <ModalHeader className="font-weight-bold">
                Gelen Ödeme Ekle
              </ModalHeader>
              <ModalBody className="modalBody">
              <CgnLoader visibled={this.state.isLoadingModal} />
                <Row>
                  <Col>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Teklif Tutarı : {this.state.offerTotal + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Ödenen Tutar : {this.state.remainingAmount + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                    <CgnTextbox
                      type="number"
                      name="paymentValueEFT"
                      label="Gelen Ödeme Tutarı"
                      value={this.state.paymentValueEFT}
                      placeHolder="Lütfen gelen ödeme tutarı girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleChange(e)}
                      step="0.1"
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
              <CgnButton
                  type="button"
                  color="primary"
                  onClick={this.handleSendMailPaymentEFT}
                  text="Ekle"
                  className="modalButton"
                />
                <CgnButton
                  type="button"
                  color="secondary"
                  onClick={this.handleSendPayment}
                  text="Kapat"
                  className="modalButton"
                />
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerDocumentProductRecordDetail;
