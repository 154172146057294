import React, { Component } from "react";
import { Row, Col, Card, Modal, Table, ModalHeader, Button,  ModalBody, ModalFooter } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import CgnButton from "../toolbox/CgnButton";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  PaymentTypeOptions,
  URL,
  
  localFilePath,
  fotoStyle,
  CabinTypeList
} from "../../redux/actions/constants";
import moment from "moment";
import noImage from "../../images/no-img.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import CollectionAddUpdate from "../collection/CollectionAddUpdate";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";

class ProductOutMachineDetail extends Component {
  state = {
    pageTitle: "Satış Detay",
    pageTitleDetail: "Satış Detay",
    pageAddLink: "satis4-ekle",
    pageListLink: "satis4-listele",
    breadcrumb: [{ text: "Satış", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    productOutDate: "",
    incomeDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    taxType: null,
    taxRate: null,
    taxtTotal: null,
    description: "",
    productDto: [],
    collectionCalendarDto: [],
    productName: "",
    currencyType: 0,
    piece: null,
    unitPrice: null,
    generalDiscountTotal: 0,
    generalTaxTotal: 0,
    generalTotalCollection: 0,
    status: "",
    createdBy: "",
    collectionGuid: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    billsProductOutGuid: "00000000-0000-0000-0000-000000000000",
    billsGuid: "00000000-0000-0000-0000-000000000000",
    pdfFileName: "",
    billsType: "",
    billsBillType: "",
    billsCancel: 0,
    billsCancelCogen: 0,
    bills: false,
    guid: "00000000-0000-0000-0000-000000000000",
    incomeGuid: "00000000-0000-0000-0000-000000000000",
    billTypeId: 0,
    billScenarioId: 0,
    istisnaId: 0,
    deliveryType: null,
    collectionDto: [],
    remainingDto: [],
    transportType: null,
    cabinAddress: null,
    cabinTypeName: null,
    cabinCity: null,
    cabinDistrict: null,
    cabinCountry: "00000000-0000-0000-0000-000000000000",
    cabinType: null,
    cabinNo: null,
    cabinPiece: 1,
    isHiddenIhracat: true,
    isHiddenIstisna: true,
    tevkifatName: null,
    countryName: null,
    isHiddenBill: true,
    isOpen: false,
    isModalCollection :false,
    isModalUpdateCollection: false,
    totalCollection : 0,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/productOutsmachine/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            productOutDate: response.data.productOutDate,
            productDto: response.data.productOutProductMachineDto,
            customerName: response.data.customerName,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            currencyType: response.data.currencyType,
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),

            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            isHiddenIstisna: response.data.billTypeId === 4 ? false : true,
            cabinAddress: response.data.cabinAddress,
            cabinCity: response.data.cabinCity,
            cabinDistrict: response.data.cabinDistrict,
            cabinPiece: response.data.cabinPiece,
            cabinNo: response.data.cabinNo,
            deliveryType: response.data.deliveryType,
            transportTypeId: response.data.transportType,
            countryName: response.data.countryName,
            isHiddenIhracat: response.data.billScenarioId === 3 ? false : true,
            cabinType: response.data.cabinType,
            tevkifatName: response.data.tevkifatName,
            billTypeId: response.data.billTypeId,
            billScenarioId: response.data.billScenarioId,
            isHiddenBill:
              response.data.billTypeId !== 0 &&
              response.data.billScenarioId !== 0
                ? false
                : true,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

        if(this.state.cabinType !== null){         
             this.setState({
               cabinTypeName: CabinTypeList(this.state.cabinType)
             });           
        }
    }
  }
  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/productoutsmachine/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleCollectionMail = (guid, totalCollection) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.collectionQuestionMessage,
      buttons: [
        {
          label: CgnMessage.collectionQuestionYes,
          onClick: () => this.handleCollectionSendMail(guid, totalCollection),
        },
        {
          label: CgnMessage.collectionQuestionNo,
        },
      ],
    });
  };

  handleCollectionDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleCollectionSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleCollectionSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/collections/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalCollection = () => {
    this.setState({
      isOpenModalCollection: !this.state.isOpenModalCollection,
    });
  };

  addModalUpdateCollection = (guid) => {
    this.setState({
      isModalUpdateCollection: !this.state.isModalUpdateCollection,
      collectionGuid: guid
    });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  thStyle = {
    width: "175px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "200px",
  };


  tdStyle3 = {
    width: "90px",
  };

  tdStyle2 = {
    width: "600px",
  };

  thStyle3 = {
    width:"150px",
  };

  render() {
    const mainRow = {
      backgroundColor: "#9B9796",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    const mainRow2 = {
      backgroundColor: "#9B9796",
      color: "#fff",
      width: "300px",
    };
    var self = this.state;

    var self1 = this;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          printLink={onClick => this.handlePdfPrint(this.state.guid)}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tr>
                  <th>Satış Tarihi</th>
                  <td colSpan="4">{this.state.productOutDate}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>

                <tr>
                  <th>Ara Toplam</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : this.state.currencyType === 4
                          ? "SFr"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>İndirim Toplamı</th>
                  <td colSpan="4">
                    {this.state.generalDiscountTotal
                      ? this.state.generalDiscountTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : this.state.currencyType === 4
                          ? "SFr"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>KDV Toplamı</th>
                  <td colSpan="4">
                    {this.state.generalTaxTotal
                      ? this.state.generalTaxTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : this.state.currencyType === 4
                          ? "SFr"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Genel Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : this.state.currencyType === 4
                          ? "SFr"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Açıklama</th>
                  <td colSpan="4">
                    {this.state.description ? this.state.description : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>

                <tr
                  style={{
                    display: this.state.isHiddenBill === false ? "" : "none",
                  }}
                >
                  <th>Fatura Tipi</th>
                  <td>
                    {this.state.billTypeId === 1
                      ? "Satış Faturası"
                      : this.state.billTypeId === 2
                      ? "İade Faturası"
                      : this.state.billTypeId === 3
                      ? "Tevkifat"
                      : this.state.billTypeId === 4
                      ? "İstisna"
                      : this.state.billTypeId === 5
                      ? "Özel Matrah"
                      : this.state.billTypeId === 6
                      ? "İhraç Kayıtlı"
                      : this.state.billTypeId === 7
                      ? "İhracat"
                      : ""}
                  </td>

                  <th>Fatura Senaryo</th>
                  <td>
                    {this.state.billScenarioId === 1
                      ? "Ticari"
                      : this.state.billScenarioId === 2
                      ? "Temel"
                      : this.state.billScenarioId === 3
                      ? "İhracat"
                      : this.state.billScenarioId === 4
                      ? "Kamu"
                      : ""}
                  </td>
                </tr>

                <tr
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <th>Teslim Şekli</th>
                  <td>
                    {this.state.deliveryType === "CFR"
                      ? "CFR => Cost And Freight"
                      : this.state.deliveryType === "CIF"
                      ? "CIF => Cost, Insurance And Freight"
                      : this.state.deliveryType === "CIP"
                      ? "CIP => Carriage And Insurance Paid To"
                      : this.state.deliveryType === "CPT"
                      ? "CPT => Carriage Paid To"
                      : this.state.deliveryType === "DAF"
                      ? "DAF => Delivered At Frontier"
                      : this.state.deliveryType === "DAP"
                      ? "DAP => Delivered At Place"
                      : this.state.deliveryType === "DAT"
                      ? "DAT => Delivered At Terminal"
                      : this.state.deliveryType === "DDP"
                      ? "DDP => Delivered Duty Paid"
                      : this.state.deliveryType === "DDU"
                      ? "DDU => Delivered Duty Unpaid"
                      : this.state.deliveryType === "DEQ"
                      ? "DEQ => Delivered Ex Quay"
                      : this.state.deliveryType === "DES"
                      ? "DES => Delivered Ex Ship"
                      : this.state.deliveryType === "EXW"
                      ? "EXW => Ex Works"
                      : this.state.deliveryType === "FAS"
                      ? "FAS => Free Along Ship"
                      : this.state.deliveryType === "FCA"
                      ? "FCA => Free Carrier"
                      : this.state.deliveryType === "FOB"
                      ? "FOB => Free On Board"
                      : ""}
                  </td>

                  <th>Taşıma Şekli</th>
                  <td>
                    {this.state.transportTypeId === 0
                      ? "0 => Transport mode not specified"
                      : this.state.transportTypeId === 1
                      ? "1 => Maritime transport"
                      : this.state.transportTypeId === 2
                      ? "2 => Rail transport"
                      : this.state.transportTypeId === 3
                      ? "3 => Road transport"
                      : this.state.transportTypeId === 4
                      ? "4 => Air transport"
                      : this.state.transportTypeId === 5
                      ? "5 => Mail"
                      : this.state.transportTypeId === 6
                      ? "6 => Multimodal transport"
                      : this.state.transportTypeId === 7
                      ? "7 => Fixed transport installations"
                      : this.state.transportTypeId === 8
                      ? "8 => Fixed transport installations"
                      : this.state.transportTypeId === 9
                      ? "9 => Transport mode not applicable"
                      : ""}
                  </td>
                </tr>

                <tr
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <th>Kabin Cinsi</th>
                  <td>{this.state.cabinTypeName}</td>
                  <th>Kabin Adet</th>
                  <td>{this.state.cabinPiece}</td>
                </tr>

                <tr
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <th>Kabin Numarası</th>
                  <td colSpan={4}>
                    {this.state.cabinNo !== null ? this.state.cabinNo : "-"}
                  </td>
                </tr>
                <tr
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <th>Teslimat Adres</th>
                  <td>
                    {this.state.cabinAddress !== null
                      ? this.state.cabinAddress
                      : "-"}
                  </td>
                  <th>Teslimat İlçe</th>
                  <td>
                    {this.state.cabinDistrict !== null
                      ? this.state.cabinDistrict
                      : "-"}
                  </td>
                </tr>

                <tr
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <th>Teslimat Şehir</th>
                  <td>
                    {this.state.cabinCity !== null ? this.state.cabinCity : "-"}
                  </td>
                  <th>Teslimat Ülke</th>
                  <td>
                    {this.state.countryName !== null
                      ? this.state.countryName
                      : "-"}
                  </td>
                </tr>
              </Table>

              {this.state.productDto !== null &&
                this.state.productDto.length > 0 && (
                  <>
               <div
                className="row"
                style={{
                  display:
                    self.productDto !== null && self.productDto.length > 0
                      ? ""
                      : "none",
                }}
              >
                <h2 className="mt-2">Ürünler</h2>
              </div>
              <Table className="table table-responsive table-product2">
                    <tr className="">
                      <th style={mainRow}>Depo</th>
                      <th className="text-left" style={mainRow2}>
                        Ürün Adı
                      </th>
                      <th className="text-center" style={mainRow}>
                        Adet
                      </th>
                      <th className="text-center" style={mainRow}>
                        Birim
                      </th>
                      <th className="text-center" style={mainRow}>
                        Birim Fiyatı
                      </th>
                      <th className="text-center" style={mainRow}>
                        İndirim
                      </th>
                      <th className="text-center" style={mainRow}>
                        KDV Oranı
                      </th>
                      <th className="text-rigth" style={mainRow}>Tevkifat Tutarı</th>
                      <th style={mainRow}>Fiyat</th>
                    </tr>
                    {this.state.productDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.locationName}</td>
                          <td className="text-left">{item.machineProductName}</td>
                          <td className="text-center">{item.piece}</td>
                          <td className="text-center">
                            {item.unit === "NIU"
                              ? "Adet"
                              : item.unit === "KGM"
                              ? "kg"
                              : item.unit === "GRM"
                              ? "gr"
                              : item.unit === "MTR"
                              ? "Metre"
                              : item.unit === "LTR"
                              ? "Litre"
                              : item.unit === "PA"
                              ? "Paket"
                              : item.unit === "BX"
                              ? "Kutu"
                              : item.unit === "CMT"
                              ? "cm"
                              : item.unit === "MTQ"
                              ? "m3"
                              : item.unit === "MTK"
                              ? "m2"
                              : item.unit === "ROLL"
                              ? "Rulo"
                              : item.unit === "SET"
                              ? "Set"
                              : item.unit === "CMQ"
                              ? "cm3"
                              : item.unit === "SAA" ? "Saat" : "-"}
                          </td>
                          <td className="text-center">
                            {item.unitPrice.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : item.currencyType === 4
                                ? "SFr"
                                : "-")}
                          </td>

                          <td className="text-center">
                            {item.productDiscountTotal &&
                            item.productDiscountTotal > 0
                              ? item.productDiscountTotal.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                  ? "$"
                                  : item.currencyType === 3
                                  ? "€"
                                  : item.currencyType === 4
                                  ? "SFr"
                                  : "-")
                              : "-"}
                          </td>
                          <td className="text-center">
                            {"% " + item.productTaxRate}
                          </td>
                          <td className="text-right">
                            {item.tevkifatTotal.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : item.currencyType === 4
                                ? "SFr"
                                : "-")}
                          </td>
                          <td>
                            {item.total.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : item.currencyType === 4
                                ? "SFr"
                                : "-")}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                  </>
                )}

            </Card>
            {this.state.bills &&
              this.state.billsGuid !== null &&
              this.state.billsGuid !==
                "00000000-0000-0000-0000-000000000000" && (
                <CgnButtonLink
                  color="primary"
                  to={"/giden-fatura-detay/" + this.state.billsGuid}
                  text="Fatura Detay"
                />
              )}
            {this.state.bills &&
              this.state.pdfFileName !== null &&
              this.state.pdfFileName !== "" && (
                <CgnButtonLink
                  color="primary"
                  to={"/EFatura/" + this.state.pdfFileName}
                  target={"_blank"}
                  text="Faturayı Görüntüle"
                  className="ml-2"
                />
              )}
            {this.state.bills &&
              this.state.billsGuid !== null &&
              this.state.billsGuid !== "00000000-0000-0000-0000-000000000000" &&
              this.state.billsType !== null &&
              this.state.billsType === 3 &&
              this.state.billsBillType !== null &&
              this.state.billsBillType === "TICARIFATURA" &&
              this.state.billsCancel !== null &&
              this.state.billsCancel === 0 &&
              this.state.billsCancelCogen !== null &&
              this.state.billsCancelCogen === 0 && (
                <CgnButton
                  type="button"
                  color="secondary"
                  onClick={() => this.cancel(this.state.billsGuid)}
                  text="Faturayı İptal Et"
                  className="ml-2"
                />
              )}
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>


        <Modal
          isOpen={this.state.isOpenModalCollection}
          toggle={this.addModalCollection}
          className="modal-xl content"
        >
          {/* <ModalHeader className="font-weight-bold">Tahsilat Ekle</ModalHeader> */}
          <ModalBody className="modalBody">
            <CollectionAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCollection}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isModalUpdateCollection}
          toggle={this.addModalUpdateCollection}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <CollectionAddUpdate guid={this.state.collectionGuid}/>
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateCollection}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductOutMachineDetail;
