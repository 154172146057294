
export const ERROR = "ERROR";

export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGIN_ERROR="LOGIN_ERROR";

export const COMPANY_USER_GROUP_GET_LIST_SUCCESS="COMPANY_USER_GROUP_GET_LIST_SUCCESS";
export const COMPANY_USER_GROUP_GET_LIST_ERROR="COMPANY_USER_GROUP_GET_LIST_ERROR";

export const EMPLOYEE_LIST_SUCCESS="EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_ERROR="EMPLOYEE_LIST_ERROR";


export const GROUP_GET_LIST_SELECT_SUCCESS="GROUP_GET_LIST_SELECT_SUCCESS";
export const GROUP_GET_LIST_SELECT_ERROR="GROUP_GET_LIST_SELECT_ERROR";



