import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { localFilePath, URL,  CustomerList, MembershipList, ProductList, ProductOutList2} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class SuitabilityFormList extends Component {
  state = {
    pageTitle: "Uygunluk Formu Listele",
    pageDetailLink: "uygunluk-formu-detay",
    pageAddLink: "uygunluk-formu-ekle",
    pageUpdateLink: "uygunluk-formu-guncelle",
    pageListLink: "uygunluk-formu-listele",
    breadcrumb: [{ text: "Uygunluk Formu Listesi", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    name: "",
    locationName: "",
    code: "",
    materialCategoryName: "",
    guid: "",
    alertErrorMessage: "",
    qualityData: [],
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    personelGuid: "00000000-0000-0000-0000-000000000000",
    personelArr: [],
    productOutGuid: "00000000-0000-0000-0000-000000000000",
    productOutArr: [],
    productGuid: "00000000-0000-0000-0000-000000000000",
    productArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // // 

    // var firstDate = GetMonthFirstDate();
    // var lastDate = GetMonthLastDate();

    // if (localStorage.getItem("firstDateWorkInstruction")) {
    //   firstDate = localStorage.getItem("firstDateWorkInstruction");
    // }
    // if (localStorage.getItem("lastDateWorkInstruction")) {
    //   lastDate = localStorage.getItem("lastDateWorkInstruction");
    // }

    // await this.setState({
    //   firstDate: firstDate,
    //   firstDate2: firstDate,
    //   lastDate: lastDate,
    //   lastDate2: lastDate,
    // });

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidSuitabilityForm")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidSuitabilityForm"),
      });
    }

    await MembershipList().then(function (data) {
      self.setState({
        personelArr: data,
      });
    });

    if (localStorage.getItem("personelGuidSuitabilityForm")) {
      this.setState({
        personelGuid: localStorage.getItem("personelGuidSuitabilityForm"),
      });
    }

    await ProductList().then(function (data) {
      self.setState({
        productArr: data,
      });
    });

    await ProductOutList2().then(function (data) {
      self.setState({
        productOutArr: data,
      });
    });

    // if (localStorage.getItem("productOutGuidWorkInstruction")) {
    //   this.setState({
    //     productOutArr: localStorage.getItem("productOutGuidWorkInstruction"),
    //   });
    // }

    this.getList();
  }
  
  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      //var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem("firstDateSuitabilityForm", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      //var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate:  "",
        lastDate2: "",
      });
    }
    localStorage.setItem("lastDateSuitabilityForm", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidSuitabilityForm", this.state.customerGuid);
  }

  async handlePersonelSelectChange(option) {
    if (option) {
      await this.setState({
        personelGuid: option.value,
      });
    } else {
      await this.setState({
        personelGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("personelGuidSuitabilityForm", this.state.personelGuid);
  }

  async handleProductOutSelectChange(option) {
    if (option) {
      await this.setState({
        productOutGuid: option.value,
      });
    } else {
      await this.setState({
        productOutGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("productOutGuidSuitabilityForm", this.state.productOutGuid);
  }

  async handleProductSelectChange(option) {
    if (option) {
      await this.setState({
        productGuid: option.value,
      });
    } else {
      await this.setState({
        productGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("productGuidSuitabilityForm", this.state.productGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var userGuid = localStorage.getItem("guid");
    URLParam =
      "/suitabilityForms/getlist/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
      "/" +
      this.state.customerGuid +
      "/" +
      this.state.personelGuid +
      "/" +
      this.state.productOutGuid +
      "/" +
      this.state.productGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {       
          this.setState({
            qualityData: response.data,
            isLoading: false,
          });              
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };


  handleCopyOffer = async (guid) => {
    confirmAlert({
      title: CgnMessage.copyQuestionTitle,
      message: CgnMessage.copyRevisionQuestionMessage,
      buttons: [
        {
          label: CgnMessage.copyQuestionYes,
          onClick: () => this.copyItem(guid),
        },
        {
          label: CgnMessage.copyQuestionNo,
        },
      ],
    });
  };

  handleServicePrint = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var statusType = 1;
    URLParam =
      "/suitabilityForms/print/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
      "/" +
      this.state.customerGuid +
      "/" +
      this.state.personelGuid +
      "/" +
      this.state.productOutGuid +
      "/" +
      this.state.productGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/suitabilityForms/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/suitabilityForms/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }


  thStyle = {
    width: "150px",
  };

  thStyle2 = {
    width: "150px",
  };

  tdStyle = {
    width: "300px",
  };

  tdStyle2 = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          excelLink={onClick => this.handleServicePrint()}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="personelGuid"
                    label="Personel"
                    placeholder="Personel seçiniz..."
                    selectValue={this.state.personelGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handlePersonelSelectChange(option)
                    }
                    options={this.state.personelArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="productOutGuid"
                    label="Satış"
                    placeholder="Satış seçiniz..."
                    selectValue={this.state.productOutGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleProductOutSelectChange(option)
                    }
                    options={this.state.productOutArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="productGuid"
                    label="Ürün"
                    placeholder="Ürün seçiniz..."
                    selectValue={this.state.productGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleProductSelectChange(option)
                    }
                    options={this.state.productArr}
                  />
                </Col>
                <Col className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.qualityData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Uygunluk Formu Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                           <tr>
                           <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.suitabilityDate}</td>
                              <th style={this.thStyle2}>
                                Kalite Uygunluk No
                              </th>
                              <td>{row.suitabilityNo}</td>
                           </tr>
                            <tr>
                              <th style={this.thStyle}>İş Emri Tarihi</th>
                              <td style={this.tdStyle}>{row.workDate}</td>
                              <th style={this.thStyle2}>
                                İş Emri Numarası
                              </th>
                              <td>{row.workInstructionCode}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td style={this.tdStyle}>{row.customerName}</td>
                              <th style={this.thStyle2}>Ürün</th>
                              <td>{row.productCode + "-" + row.productName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Satış</th>
                              <td style={this.tdStyle}>{row.productOutDate + "-" + row.productOutCustomer}</td>
                                                             <th style={this.thStyle2}>
                              Ekleyen Kullanıcı
                              </th>
                              <td>{row.createdBy}</td>
                            </tr>                   
                          </tbody>
                        </Table>
                      );
                    },
                  },                           
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfPrint(row.guid),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default SuitabilityFormList;
