export async function handleResponse(actionSuccess, dispatch, response) {
    if (response.data) {
      return dispatch(actionSuccess(response));
    } else {
      return dispatch(actionSuccess(response));
    }
  }
  
  export async function handleError(actionError, dispatch, response) {
    if(response){
      const error = {
     
        status: response.status,
        statusText: response.statusText,
        data: response.data,
       
        
      };
      return dispatch(actionError(error));
    }
    else{
      window.location.href="/";
    }
    
  
  }
  export async function handleResponseText(actionResponse, dispatch, response) {
    const responseText = {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    };
    return dispatch(actionResponse(responseText));
  }
  