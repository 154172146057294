import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class CuttingProjectDetail extends Component {
  state = {
    pageTitle: "Kesim Proje Detay",
    pageTitleDetail: "Kesim Proje Detay",
    pageAddLink: "kesim-islemi-ekle",
    pageListLink: "kesim-proje-listele",
    breadcrumb: [{ text: "Kesim İşlemleri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    cutId: 0,
    cutTypeId: 0,
    cuttingWork: 0,
    height: 0,
    width: 0,
    thickness: 0,
    piece: 1,
    kg: 0,
    time: 0,
    hourTL: 0,
    laserCutting: 0,
    sheetPrice: 0,
    twistingWork: 0,
    specialTL: 0,
    TransportTL: 0,
    totalPrice: 0,
    fileName: "",
    cuttingProcessProjectDtos: [],
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/cuttingprocess/getguid/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            customerGuid: response.data.customerGuid,
            customerName: response.data.customerName,
            cutId: response.data.cutId,
            cutTypeId: response.data.cutTypeId,
            cuttingWork: response.data.cuttingWork,
            height: response.data.height,
            width: response.data.width,
            thickness: response.data.thickness,
            piece: response.data.piece,
            kg: response.data.kg,
            time: response.data.time,
            hourTL: response.data.hourTL,
            laserCutting: response.data.laserCutting,
            sheetPrice: response.data.sheetPrice,
            twistingWork: response.data.twistingWork,
            specialTL: response.data.specialTL,
            transportTL: response.data.transportTL,
            totalPrice: response.data.totalPrice,
            status: response.data.status,
            isLoading: false,
            fileName: response.data.fileName,
            projectName: response.data.projectName,
            processStatus: response.data.processStatus,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink + "/" + this.state.fileName}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr className="cuttingTr3">
                    <th>Müşteri</th>
                    <td style={{ width: "400px" }}>
                      {this.state.customerName}
                    </td>
                    <th>Dosya Adı</th>
                    <td>{this.state.fileName}</td>
                  </tr>
                  {/* <tr className="cuttingTr2">
                    <th>Proje Adı</th>
                    <td colSpan={4}>{this.state.projectName}</td>
                  </tr> */}
                  <tr className="cuttingTr">
                    <th>Kesim</th>
                    <td>
                      {this.state.cutId === 1
                        ? "Plazma Kesim"
                        : this.state.cutId === 2
                        ? "Lazer Kesim"
                        : this.state.cutId === 3
                        ? "Plazma Kesim İşçiliği"
                        : this.state.cutId === 4
                        ? "Lazer Kesim İşçiliği"
                        : this.state.cutId === 5
                        ? "Oksijen Kesim"
                        : this.state.cutId === 6
                        ? "Oksijen Kesim İşçiliği"
                        : "-"}
                    </td>
                    <th>Cins</th>
                    <td>
                      {this.state.cutTypeId === 1
                        ? "Baklavalı"
                        : this.state.cutTypeId === 2
                        ? "St37"
                        : this.state.cutTypeId === 3
                        ? "HRP"
                        : this.state.cutTypeId === 4
                        ? "DKP"
                        : this.state.cutTypeId === 5
                        ? "St52"
                        : this.state.cutTypeId === 6
                        ? "Paslanmaz"
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kalınlık</th>
                    <td>
                      {this.state.thickness !== 0 ? this.state.thickness : "-"}
                    </td>
                    <th>En</th>
                    <td>{this.state.width !== 0 ? this.state.width : "-"}</td>
                  </tr>
                  <tr>
                    <th>Boy</th>
                    <td>{this.state.height !== 0 ? this.state.height : "-"}</td>
                    <th>Adet</th>
                    <td>{this.state.piece !== 0 ? this.state.piece : "-"}</td>
                  </tr>

                  <tr>
                    <th>Kilogram</th>
                    <td colSpan="4">
                      {this.state.kg !== 0 ? this.state.kg : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Süre</th>
                    <td>{this.state.time !== 0 ? this.state.time : "-"}</td>
                    <th>TL/Saat</th>
                    <td>{this.state.hourTL !== 0 ? this.state.hourTL : "-"}</td>
                  </tr>

                  <tr>
                    <th>Lazer Kesim</th>
                    <td colSpan="4">
                      {this.state.laserCutting !== 0
                        ? this.state.laserCutting
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Sac Fiyatı TL/Kg</th>
                    <td colSpan="4">
                      {this.state.sheetPrice !== 0
                        ? this.state.sheetPrice + " ₺"
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kesim İşçiliği TL/Kg</th>
                    <td>
                      {this.state.cuttingWork !== 0
                        ? this.state.cuttingWork
                        : "-"}
                    </td>
                    <th>Büküm İşçiliği TL/Kg</th>
                    <td>
                      {this.state.twistingWork !== 0
                        ? this.state.twistingWork
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Özel TL</th>
                    <td>
                      {this.state.specialTL !== 0 ? this.state.specialTL : "-"}
                    </td>
                    <th>Nakliye TL</th>
                    <td>
                      {this.state.transportTL !== 0
                        ? this.state.transportTL
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Toplam Fiyat</th>
                    <td colSpan="4">
                      {this.state.totalPrice !== 0
                        ? this.state.totalPrice.toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                            },
                            +" ₺"
                          )
                        : "-"}
                    </td>
                  </tr>                 
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CuttingProjectDetail;
