import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Modal,
  ModalHeader,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  machineProductOptionStatuses1,
  machineProductOptionStatuses2,
  fotoStyle,
  localFilePath,
  cargoCompanys,
} from "../../redux/actions/constants";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import noImg from "../../images/no-img.png";
import CgnTextbox from "../toolbox/CgnTextbox";
import { confirmAlert } from "react-confirm-alert";

class MachineProductOrderDetail extends Component {
  state = {
    pageTitle: "Sipariş Detay",
    pageTitleDetail: "Sipariş Detay",
    pageAddLink: "makine-urun-siparis-ekle",
    pageListLink: "makine-urun-siparis-listele",
    breadcrumb: [{ text: "Sipariş", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    alertErrorMessageForm: "",
    date: "",
    locationName: "",
    machineProductCarts: [],
    status: "",
    statusDetail: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    pageType: "makine-urun-siparis-detay",
    isOpen: false,
    isCargo: false,
    cargo: 0,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "yetkili-makine-urun-siparis-detay") {
      await this.setState({
        pageTitle: "Yetkili Sipariş Detay",
        pageTitleDetail: "Yetkili Sipariş Detay",
        pageAddLink: "",
        pageListLink: "yetkili-makine-urun-siparis-listele",
        pageType: "yetkili-makine-urun-siparis-detay",
      });
    }

    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/machineproductorder/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            date: response.data.date,
            locationGuid: response.data.locationGuid,
            locationName: response.data.locationName,
            machineProductCarts: response.data.machineProductOrderProducts,
            status: response.data.status,
            cargo: response.data.cargo,
            statusDetail: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            isCargo: response.data.status === 2 ? true : false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
    if (status.value === 2) {
      this.setState({ isCargo: true });
    } else {
      this.setState({ isCargo: false, cargo: 0 });
    }
  }

  handleCargoSelectChange(cargo) {
    if (cargo !== null) {
      this.setState({ cargo: cargo.value });
    } else {
      this.setState({ cargo: 0 });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessageForm: "",
      statusError: "",
    });
    if (this.state.status === null) {
      this.setState({
        statusError: CgnMessage.textErrorMessage,
        alertErrorMessageForm: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.status === 2 &&
      (this.state.cargo === null || this.state.cargo === undefined)
    ) {
      this.setState({
        cargoError: CgnMessage.textErrorMessage,
        alertErrorMessageForm: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.status !== null &&
      (this.state.status !== 2 ||
        (this.state.status === 2 &&
          this.state.cargo !== null &&
          this.state.cargo !== undefined))
    ) {
      if (
        this.state.pageType === "makine-urun-siparis-detay" &&
        this.state.status !== 3
      ) {
        await this.setState({
          statusError: CgnMessage.textErrorMessage,
          alertErrorMessageForm: CgnMessage.alertErrorMessage,
        });
      } else {
        this.setState({
          isLoading: true,
        });

        var URLParam = "/machineproductorder/update";
        var myObj = {
          guid: this.state.guid,
          date: this.state.date,
          locationGuid: this.state.locationGuid,
          machineProductOrderProducts: this.state.machineProductCarts,
          status: this.state.status,
          cargo: this.state.cargo,
          locationTransferGuid: localStorage.getItem("locationGuid"),
        };

        // 
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + URLParam, myObj, config)
          .then(() => {
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (this.state.status === 1 || this.state.status === 2) {
                      window.location.href = "/yetkili-makine-urun-siparis-listele";
                    } else if (this.state.status === 3) {
                      window.location.href = "/makine-urun-siparis-listele";
                    }
                  }
                },
              ],
            });
          })
          .catch((error) => {
            confirmAlert({
              title: CgnMessage.alertErrorTitle,
              message: error.response.data,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    this.setState({
                      isLoading: false,
                    });
                  }
                },
              ],
            });
          });
      }
    }
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let machineProductCarts = this.state.machineProductCarts;
    machineProductCarts[idx].pieceNew = parseFloat(e.target.value);
    this.setState({
      machineProductCarts: machineProductCarts,
    });
  }

  handlePrint = async () => {
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineproductorder/getexcel/" + urlSplit[2], config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    let _self = this;

    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    const thStyle = {
      width: "400px",
      textAlign: "left",
    };

    const thStyle2 = {
      width: "150px",
      textAlign: "center",
    };

    const thStyle3 = {
      width: "50px",
    };

    const thStyle4 = {
      width: "100px",
      textAlign: "center",
    };

    const thStyle5 = {
      textAlign: "left",
    };

    const tdStyle = {
      textAlign: "left",
      paddingTop: "23px",
    };

    const tdStyle2 = {
      textAlign: "center",
      width: "100px",
      paddingTop: "23px",
    };

    const tdStyle3 = {
      width: "50px",
    };

    const tdStyle4 = {
      width: "150px",
      paddingTop: "21px",
    };

    const tdStyle5 = {
      textAlign: "left",
      paddingTop: "23px",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={
            this.state.pageAddLink !== "" && "/" + this.state.pageAddLink
          }
          listLink={"/" + this.state.pageListLink}
          excelLink={
            this.state.pageType === "yetkili-makine-urun-siparis-detay"
              ? (onClick) => this.handlePrint()
              : null
          }
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <td colSpan="4">{this.state.date}</td>
                  </tr>
                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">{this.state.locationName}</td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">
                      {this.state.status === 1
                        ? "Bekliyor"
                        : this.state.status === 2
                          ? "Kargolandı"
                          : this.state.status === 3
                            ? "Tamamlandı"
                            : this.state.status === 4
                              ? "Askıda"
                              : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Kargo</th>
                    <td colSpan="4">
                      {this.state.cargo === 1
                        ? "Yurtiçi Kargo"
                        : this.state.cargo === 2
                          ? "MNG Kargo"
                          : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              {this.state.machineProductCarts !== null &&
                this.state.machineProductCarts.length > 0 && (
                  <Row>
                    <Col lg="8" className="pl-0">
                      <Table className="table table-responsive table-hover table-product mb-3">
                        <tr style={mainRow}>
                          <th style={thStyle3}>Fotoğraf</th>
                          <th style={thStyle}>Ürün Adı</th>
                          <th style={thStyle5}>Raf</th>
                          <th style={thStyle2}>Adet</th>
                          {_self.state.pageType ===
                            "yetkili-makine-urun-siparis-detay" &&
                            _self.state.statusDetail === 1 && (
                              <th style={thStyle4}>Aktarılacak Adet</th>
                            )}
                        </tr>
                        {this.state.machineProductCarts.map(function (
                          item,
                          idx
                        ) {
                          return (
                            <tr style={subRow}>
                              <td style={tdStyle3}>
                                {item.machineProductImage ? (
                                  <Button
                                    onClick={() =>
                                      _self.toggle(
                                        item.machineProductImage,
                                        item.machineProductName
                                      )
                                    }
                                    className="modalButtonList"
                                  >
                                    <img
                                      alt={item.machineProductName}
                                      src={`/Images/${item.machineProductImage}`}
                                      className="rdt_image table-image"
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    alt={item.name}
                                    src={noImg}
                                    className="rdt_image rdt_no_image table-image"
                                  />
                                )}
                              </td>
                              <td style={tdStyle}>{item.machineProductName}</td>
                              <td style={tdStyle5}>
                                {item.machineProductShelfRowDto !== null &&
                                  item.machineProductShelfRowDto.length > 0 &&
                                  item.machineProductShelfRowDto.map(function (
                                    item2
                                  ) {
                                    if (
                                      item2.locationGuid ===
                                      localStorage.getItem("locationGuid")
                                    ) {
                                      return item2.shelf
                                        ? item2.locationName +
                                        " " +
                                        item2.shelf +
                                        "-" +
                                        item2.row +
                                        " / "
                                        : "-";
                                    } else {
                                      return "";
                                    }
                                  })}
                              </td>
                              <td style={tdStyle2}>{item.piece + " Adet"}</td>
                              {_self.state.pageType ===
                                "yetkili-makine-urun-siparis-detay" &&
                                _self.state.statusDetail === 1 && (
                                  <td style={tdStyle4} className="table-input">
                                    <CgnTextbox
                                      type="number"
                                      name="pieceNew"
                                      className="dataTableInput"
                                      value={item.pieceNew}
                                      placeHolder="Adet"
                                      maxLength="100"
                                      autoComplete="off"
                                      onChange={(e) =>
                                        _self.handleProductPieceTextBoxChange(
                                          e,
                                          idx
                                        )
                                      }
                                    />
                                  </td>
                                )}
                            </tr>
                          );
                        })}
                      </Table>
                    </Col>
                  </Row>
                )}

              {this.state.pageType === "yetkili-makine-urun-siparis-detay" &&
                this.state.statusDetail !== 3 && (
                  <Form onSubmit={this.handleSave} style={{ height: "250px" }}>
                    <CgnLoader visibled={this.state.isLoading} />
                    <Row>
                      <Col lg="12">
                        <CgnReactSelect
                          name="status"
                          label="Durumu [*]"
                          placeholder="Lütfen durum seçin..."
                          isMulti={false}
                          selectValue={this.state.status}
                          options={machineProductOptionStatuses1}
                          onChange={(status) =>
                            this.handleStatusSelectChange(status)
                          }
                          error={this.state.statusError}
                        />
                      </Col>
                    </Row>
                    {this.state.isCargo && (
                      <Row>
                        <Col lg="12">
                          <CgnReactSelect
                            name="status"
                            label="Kargo [*]"
                            placeholder="Lütfen kargo seçin..."
                            isMulti={false}
                            isClearable={true}
                            selectValue={this.state.cargo}
                            options={cargoCompanys}
                            onChange={(cargo) =>
                              this.handleCargoSelectChange(cargo)
                            }
                            error={this.state.cargoError}
                          />
                        </Col>
                      </Row>
                    )}
                    {this.state.alertErrorMessageForm && (
                      <CgnAlert
                        color="danger"
                        title={CgnMessage.alertErrorTitle}
                        text={this.state.alertErrorMessageForm}
                      />
                    )}
                    <CgnButtonGroup items={this.state.buttons} />
                  </Form>
                )}

              {this.state.pageType === "makine-urun-siparis-detay" &&
                this.state.statusDetail === 2 && (
                  <Form onSubmit={this.handleSave} style={{ height: "250px" }}>
                    <CgnLoader visibled={this.state.isLoading} />
                    <Row>
                      <Col lg="12">
                        <CgnReactSelect
                          name="status"
                          label="Durumu"
                          placeholder="Lütfen durum seçin..."
                          isMulti={false}
                          selectValue={this.state.status}
                          options={machineProductOptionStatuses2}
                          onChange={(status) =>
                            this.handleStatusSelectChange(status)
                          }
                          error={this.state.statusError}
                        />
                      </Col>
                    </Row>
                    {this.state.alertErrorMessageForm && (
                      <CgnAlert
                        color="danger"
                        title={CgnMessage.alertErrorTitle}
                        text={this.state.alertErrorMessageForm}
                      />
                    )}
                    <CgnButtonGroup items={this.state.buttons} />
                  </Form>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default MachineProductOrderDetail;
