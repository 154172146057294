import React, { Component } from "react";
import axios from "axios";
import { URL } from "../../redux/actions/constants";
import CgnLoader from "../toolbox/CgnLoader";

class OfferNewDetail extends Component {
  state = {
    isLoading: false,
    alertErrorMessage: "",
  };

  
  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
        this.setState({
            isLoading: true,
          });
        await axios
        .get(URL + "/offerstatus3/statuschange/" + urlSplit[2] + "/2")
        .then((response) => {
            this.setState({
                alertErrorMessage: "",
                isLoading: false,
            });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    return (
      <div className="teklif-box">
        <CgnLoader visibled={this.state.isLoading} />
        {this.state.alertErrorMessage && (
          <div className="teklif teklif-iptal">
            <h3>Uyarı!</h3>
            <p>{this.state.alertErrorMessage}</p>
          </div>
        )}
        {!this.state.alertErrorMessage && (
        <div className="teklif teklif-onay">
            <h3>Teklif Onaylandı</h3>
            <p>Yetkili personelimiz teklif ile alakalı sizinle en kısa sürede iletişime geçecektir.</p>
            <p>Teşekkürler, iyi çalışmalar dileriz.</p>
        </div>
        )}
      </div>
    );
  }
}

export default OfferNewDetail;
