import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxRateChoices,
  CurrencyType,
  
  unitValuesStatuses
} from "../../redux/actions/constants";
import moment from "moment";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";

class ProductReturnNewAddUpdate extends Component {
  state = {
    pageTitle: "İade Ekle",
    pageTitleAdd: "İade Ekle",
    pageTitleUpdate: "İade Güncelle",
    pageAddLink: "iade2-ekle",
    pageListLink: "iade2-listele",
    breadcrumb: [{ text: "İade", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productReturnDate: "",
    supplierGuid: null,
    productEntryGuid: null,
    productOutGuid: null,
    customerGuid: null,
    guid: "",
    costGuid: null,
    incomeGuid: null,
    total: null,
    generalTotal: null,
    taxRateValue: "",
    taxTypeValue: null,
    discountPercent: "",
    discountValue: "",
    discountTotal: parseFloat(0).toFixed(2),
    description: "",
    status: true,
    productReturnDateError: "",
    supplierError: "",
    productEntryError: "",
    productOutError: "",
    customerError: "",
    totalError: "",
    taxRateError: "",
    taxTypeError: "",
    productGuid: "",
    currencyType: "",
    currencyTypeError: "",
    alertErrorMessage: "",
    isDeleted: false,
    isTrue: false,
    unitError: "",
    productTaxRate: "",
    productDiscountPercentError: "",
    productDiscountValueError: "",
    productDiscountPercent: "",
    generalTotal1: 0,
    generalTaxTotal1: 0,
    generalDiscountTotal1: 0,
    productDiscountValue: "",
    generalTaxTotal: 0,
    generalDiscountTotal: 0,
    isNull: false,
    isOpenModalSupplier: false,
    isOpenModalCustomer: false,
    productData: [],
    supplierArr: [],
    productEntryArr: [],
    customerArr: [],
    productArr: [],
    productOutArr: [],
    tevkifatArr: [],
    isNullSupplier: false,
    isNullCustomer: false,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      isNullSupplier: true,
      isNullCustomer: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

      await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      await axios
      .get(URL + "/tevkifats/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.tevkifatId,
            label: item.tevkifatNo + " (" + item.tevkifatRate + ")",
            tevkifatRate: item.tevkifatRate,
          };
        });
        this.setState({
          tevkifatArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/returnsnew/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              productReturnDate: response.data.returnDate,
              supplierGuid: response.data.supplierGuid,
              incomeGuid: response.data.incomeGuid,
              productEntryGuid: response.data.productEntryGuid,
              customerGuid: response.data.customerGuid,
              costGuid: response.data.costGuid,
              productOutGuid: response.data.productOutGuid,
              productData: response.data.returnProductNewDto,
              total: response.data.total,
              generalTotal: response.data.generalTotal,
              generalDiscountTotal: response.data.generalDiscountTotal,
              generalTaxTotal: response.data.generalTaxTotal,
              currencyType: response.data.currencyType,
              description: response.data.description,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (this.state.supplierGuid) {
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        let conditionType = 1; // iadesi yapılmış ürünleri 2 diye belirledik.
        await axios
          .get(
            URL +
              "/productentriesnew/getlist/" +
              this.state.supplierGuid +
              "/" +
              conditionType,
            config
          )
          .then((response) => {
            var myArr = response.data.map(function (item) {
              return {
                value: item.guid,
                costGuid: item.costGuid,
                label:
                  // item.costDate +
                  // " " +
                  item.supplierName +
                  " " +
                  item.total +
                  " " +
                  (item.currencyType === 1
                    ? "₺"
                    : item.currencyType === 2
                    ? "$"
                    : item.currencyType === 3
                    ? "€"
                    : "-"),
                productData: item.productEntryProductNewDto,
                currencyType: item.currencyType,
                status: item.status,
              };
            });
            this.setState({
              productEntryArr: myArr,
            });
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
              isLoading: false,
            });
          });
      } else if (this.state.customerGuid) {
        let conditionType = 1; // iadesi yapılmış ürünleri 2 diye belirledik.
        await axios
          .get(
            URL +
              "/productoutsnew/getlist/" +
              this.state.customerGuid +
              "/" +
              conditionType,
            config
          )
          .then((response) => {
            var myArr = response.data.map(function (item) {
              return {
                value: item.guid,
                incomeGuid: item.incomeGuid,
                label:
                  // moment(item.incomeDate).format("DD/MM/YYYY") +
                  // " " +
                  item.customerName +
                  " " +
                  item.total +
                  " " +
                  (item.currencyType === 1
                    ? "₺"
                    : item.currencyType === 2
                    ? "$"
                    : item.currencyType === 3
                    ? "€"
                    : "-"),
                productData: item.productOutProductNewDto,
                currencyType: item.currencyType,
                status: item.status,
              };
            });
            this.setState({
              productOutArr: myArr,
            });
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
              isLoading: false,
            });
          });
      }
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        isNullSupplier: false,
        isNullCustomer: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        productReturnDate: "",
        supplierGuid: null,
        customerGuid: null,
        incomeGuid: null,
        costGuid: null,
        productEntryGuid: null,
        productEntryArr: [],
        productOutGuid: null,
        productOutArr: [],
        productData: [],
        currencyType: "",
        discountPercent: "",
        discountValue: "",
        discountTotal: parseFloat(0).toFixed(2),
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        description: "",
        status: true,
        isNullSupplier: false,
        isNullCustomer: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleDateChange(productReturnDate) {
    if (productReturnDate) {
      this.setState({
        productReturnDate: productReturnDate,
        productReturnDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productReturnDate: "",
        productReturnDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      supplierError: "",
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
  };

  handleSupplierSelectChange = async (option) => {
    if (option) {
      await this.setState({
        supplierGuid: option.value,
        customerGuid: null,
        productOutGuid: null,
        incomeGuid: null,
        supplierError: "",
        customerError: "",
        productEntryError: "",
        productOutError: "",
        alertErrorMessage: "",
        isNullSupplier: false,
        isNullCustomer: true,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let conditionType = 1; // iadesi hiç yapılmamış ürünleri 1 diye belirledik.
      await axios
        .get(
          URL + "/productEntriesnew/getlist/" + option.value + "/" + conditionType,
          config
        )
        .then((response) =>  {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label:
                //item.costDate +
                " " +
                item.supplierName +
                " " +
                item.total +
                " " +
                (item.currencyType === 1
                  ? "₺"
                  : item.currencyType === 2
                  ? "$"
                  : item.currencyType === 3
                  ? "€"
                  : "-"),
              costGuid: item.costGuid,
              productData: item.productEntryProductNewDto,
              currencyType: item.currencyType,
              status: item.status,
              generalDiscountTotal: item.generalDiscountTotal,
              generalTaxTotal: item.generalTaxTotal,
              total: item.total,
              generalTotal: item.generalTotal
            };
          });
          this.setState({
            productEntryArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (this.state.productEntryGuid === null) {
        this.setState({
          productEntryError: CgnMessage.textErrorMessage,
        });
      }
    } else {
      await this.setState({
        supplierGuid: null,
        costGuid: null,
        productEntryGuid: null,
        productEntryArr: [],
        productData: [],
        taxTypeValue: null,
        taxRateValue: "",
        discountValue: "",
        discountPercent: "",
        currencyType: 0,
        status: true,
        supplierError: CgnMessage.textErrorMessage,
        productEntryError: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isNullCustomer: false,
      });
    }
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[2]) {
      this.setState({
        isNullCustomer: true,
        customerError: "",
      });
    }
  };

  async handleProductEntrySelectChange(option) {
    if (this.state.supplierGuid && option) {
      await this.setState({
        productEntryGuid: option.value,
        productData: option.productData,
        generalDiscountTotal: option.generalDiscountTotal,
        generalTaxTotal: option.generalTaxTotal,
        total: option.total,
        generalTotal: option.generalTotal,
        currencyType: option.currencyType,
        costGuid: option.costGuid,
        status: option.status,
        productEntryError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        productEntryGuid: null,
        productData: [],
        taxTypeValue: null,
        costGuid: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        currencyType: 0,
        status: true,
        productEntryError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCustomerSelectChange = async (option) => {
    if (option) {
      this.setState({
        customerGuid: option.value,
        supplierGuid: null,
        costGuid: null,
        productEntryGuid: null,
        customerError: "",
        supplierError: "",
        productEntryError: "",
        productOutError: "",
        alertErrorMessage: "",
        isNullSupplier: true,
        isNullCustomer: false,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let conditionType = 1; // iadesi hiç yapılmamış ürünleri 1 diye belirledik.
      await axios
        .get(
          URL + "/productOutsnew/getlist/" + option.value + "/" + conditionType,
          config
        )
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label:
                // moment(item.incomeDate).format("DD/MM/YYYY") +
                // " " +
                item.customerName +
                " " +
                item.total +
                " " +
                (item.currencyType === 1
                  ? "₺"
                  : item.currencyType === 2
                  ? "$"
                  : item.currencyType === 3
                  ? "€"
                  : "-"),
              incomeGuid: item.incomeGuid,
              generalTaxTotal: item.generalTaxTotal,
              generalDiscountTotal: item.generalDiscountTotal,
              generalTotal: item.generalTotal,
              total: item.total,
              productData: item.productOutProductNewDto,
              currencyType: item.currencyType,
              status: item.status,
            };
          });
          this.setState({
            productOutArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (this.state.productOutGuid === null) {
        this.setState({
          productOutError: CgnMessage.textErrorMessage,
        });
      }
    } else {
      this.setState({
        customerGuid: null,
        incomeGuid: null,
        productOutGuid: null,
        productOutArr: [],
        productData: [],
        taxTypeValue: null,
        taxRateValue: "",
        discountValue: "",
        discountPercent: "",
        currencyType: 0,
        status: true,
        customerError: CgnMessage.textErrorMessage,
        supplierError: CgnMessage.textErrorMessage,
        productOutError: "",
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isNullSupplier: false,
      });
    }
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[2]) {
      this.setState({
        isNullSupplier: true,
        supplierError: "",
      });
    }
  };

  handleProductOutSelectChange(option) {
    if (option) {
      this.setState({
        productOutGuid: option.value,
        incomeGuid: option.incomeGuid,
        productData: option.productData,
        generalDiscountTotal: option.generalDiscountTotal,
        generalTaxTotal: option.generalTaxTotal,
        generalTotal: option.generalTotal,
        total: option.total,
        currencyType: option.currencyType,
        status: option.status,
        productOutError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productOutGuid: null,
        incomeGuid: null,
        productData: [],
        taxTypeValue: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        currencyType: 0,
        status: true,
        productOutError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleProductTaxRateSelectChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productTaxRate = option.value;
      productData[idx].productTaxRateError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productTaxRate = "";
      productData[idx].productTaxRateError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;

      item.productPieceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      productReturnDate: "",
      supplierGuid: null,
      customerGuid: null,
      costGuid: null,
      incomeGuid: null,
      productEntryGuid: null,
      productEntryArr: [],
      productOutGuid: null,
      productOutArr: [],
      productData: [],
      currencyType: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: parseFloat(0).toFixed(2),

      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      isNullSupplier: false,
      isNullCustomer: false,
      description: "",

      alertErrorMessage: CgnMessage.alertErrorMessage,
      productReturnDateError: CgnMessage.textErrorMessage,
      supplierError: CgnMessage.textErrorMessage,
      customerError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: "",
      locationGuid: "",
      piece: 1,
      unitPrice: 0,
      taxTotal: 0, 
      productDiscountTotal: 0,
      unit: "",
      productDiscountPercent: "",
      productDiscountValue: "",
      total: 0,
      discountTotal: 0,
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
      productTaxRateError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (array[this.state.counter].unit) {
      return array[this.state.counter].unitError === "";
    }
    if (array[this.state.counter].productTaxRate) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountPercent) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountValue) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (
      array[this.state.counter].productGuid === "" ||
      array[this.state.counter].locationGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].unit === "" ||
      array[this.state.counter].productTaxRate === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductDiscountPercentChange(e, idx) {
    let productData = this.state.productData;
    if(e.target.value !== ""){
      productData[idx].productDiscountPercent = parseFloat(e.target.value);

    }
    else{
      productData[idx].productDiscountPercent = "";
    }
     this.setState({
      productData: productData,
    });

    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

   handleProductDiscountValueChange(e, idx) {
    let productData = this.state.productData;
     if(e.target.value !== ""){
      productData[idx].productDiscountValue = parseFloat(e.target.value);

     }
     else{
      productData[idx].productDiscountValue = "";
     }
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleUnitChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].unit = option.value;
      productData[idx].unitError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].unit = "";
      productData[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }


  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].unitPrice = option.salePrice;
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].productUnitPriceError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productGuid = null;
      productData[idx].piece = 0;
      productData[idx].unitPrice = 0;
      productData[idx].productError = CgnMessage.textErrorMessage;
      productData[idx].productPieceError = CgnMessage.textErrorMessage;
      productData[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }


  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = "";
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }


  async ProductPriceCalculate(idx) {
    var totalProductPrice = 0.0;
    var totalProductTotal = 0.0;
    var itemTotal = 0.0;
    var percent = 0.0;
    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;
    var taxTotal = 0;
    var tevkifatTotal = 0;
    var tevkifatRate = 0;

    let productData = this.state.productData;

    var discountPercent = parseFloat(productData[idx].productDiscountPercent);

    var discountValue = parseFloat(productData[idx].productDiscountValue);

    itemTotal = (
      parseFloat(productData[idx].piece) *
      parseFloat(productData[idx].unitPrice)
    ).toFixed(2);

    totalProductPrice += parseFloat(itemTotal);

    if (productData[idx].tevkifatId !== undefined) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      this.setState({
        isLoading: true
      });
      await axios
        .get(URL + "/tevkifats/get/" + productData[idx].tevkifatId, config)
        .then((response) => {
          tevkifatRate = response.data.tevkifatRate;
          this.setState({
            isLoading: false
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }

    if (discountPercent > 0) {
      percent = parseFloat(totalProductPrice) * (discountPercent / 100);
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - parseFloat(percent);
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);
      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );
      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(taxTotal);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(productData[idx].taxTotal);
      }
    } else if (discountValue > 0) {
      percent = discountValue;
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - discountValue;
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);

      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(taxTotal);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(productData[idx].taxTotal);
      }
    } else {
      productData[idx].productDiscountTotal = 0;
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);

      taxTotal = parseFloat(
        totalProductPrice * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);

      totalProductTotal = parseFloat(taxTotal) + parseFloat(totalProductPrice);
      productData[idx].total = parseFloat(totalProductTotal).toFixed(2);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        totalProductTotal = parseFloat(productData[idx].taxTotal) + parseFloat(totalProductPrice);
        productData[idx].total = parseFloat(totalProductTotal).toFixed(2);
      }
    }

    this.setState({
      productData: productData,
    });
    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      total: parseFloat(discountTotal1).toFixed(2),
    });
  }

  productRequiredField = () => {
    var arr = this.state.productData;;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductRemove(idx) {
  
    let someArray = await this.state.productData;
   
    await someArray.splice(idx, 1);
     
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });

    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;

    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);

    });
     
    await this.setState({ 
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      total: parseFloat(discountTotal1).toFixed(2),
    })

    if (idx === 0) {
       await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      productReturnDateError: "",
      supplierError: "",
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      alertErrorMessage: "",
    });
    if (this.state.productReturnDate === "") {
      this.setState({
        productReturnDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.supplierGuid === null && this.state.customerGuid === null) {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
     else if (
      ((this.state.supplierGuid && this.state.customerGuid === null) ||
        (this.state.supplierGuid === null && this.state.customerGuid)) &&
      this.state.productReturnDate !== "" &&
      this.state.isNull === false
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          productGuid: element.productGuid,
          locationGuid: element.locationGuid,
          piece: element.piece,
          unitPrice: element.unitPrice,
          unit: element.unit,
          productDiscountPercent: element.productDiscountPercent !== ""
          ? parseFloat(element.productDiscountPercent)
          : 0,
          productDiscountValue:element.productDiscountValue !== ""
          ? parseFloat(element.productDiscountValue)
          : 0,
          productTaxRate: element.productTaxRate,
          total: parseFloat(element.total),
          tevkifatTotal: parseFloat(element.tevkifatTotal),
          tevkifatId: element.tevkifatId !== undefined ? element.tevkifatId : 0,
        });
      });
      var myObj = {
        returnDate: this.state.productReturnDate,
        supplierGuid: this.state.supplierGuid,
        costGuid: this.state.costGuid,
        productEntryGuid: this.state.productEntryGuid,
        customerGuid: this.state.customerGuid,
        incomeGuid: this.state.incomeGuid,
        productOutGuid: this.state.productOutGuid,
        total: parseFloat(this.state.total),
        generalTotal: parseFloat(this.state.generalTotal),
        currencyType: this.state.currencyType,
        generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
        generalTaxTotal: parseFloat(this.state.generalTaxTotal),
        description: this.state.description,
        status: true, //this.state.status,
        returnProductNewDto: productData,
      };
      var URLParam = "/returnsnew/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/returnsnew/update";
        myObj = {
          guid: this.state.guid,
          returnDate: this.state.productReturnDate,
          supplierGuid: this.state.supplierGuid,
          productEntryGuid: this.state.productEntryGuid,
          customerGuid: this.state.customerGuid,
          productOutGuid: this.state.productOutGuid,
          taxType: this.state.taxTypeValue,
          taxRate: parseInt(this.state.taxRateValue),
          total: parseFloat(this.state.total),
          generalTotal: parseFloat(this.state.generalTotal),
          currencyType: this.state.currencyType,
          generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
          generalTaxTotal: parseFloat(this.state.generalTaxTotal),
          description: this.state.description,
          status: true, //this.state.status,
          returnProductNewDto: productData,
        };
        if (this.state.supplierGuid) {
          myObj.incomeGuid = this.state.incomeGuid;
          myObj.costGuid = null;
        } else if (this.state.customerGuid) {
          myObj.costGuid = this.state.costGuid;
          myObj.incomeGuid = null;
        }

      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/iade2-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "total" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var total = parseFloat(this.state.total);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = total - total * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = total - discountValue;
    } else {
      generalTotal = total;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }
    this.state.total = parseFloat(total).toFixed(2);
    this.state.generalTotal = parseFloat(generalTotal).toFixed(2);
    this.state.discountTotal = parseFloat(discountTotal).toFixed(2);
  };

  render() {
    const noMultipleChoiceStyle = {
      marginTop: "-14px",
      marginBottom: "5px",
    };

    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6">
                    <CgnDatePicker
                      name="productReturnDate"
                      label="Tarih [*]"
                      selected={
                        this.state.productReturnDate &&
                        this.state.productReturnDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.productReturnDate.split("/")[2] +
                                "-" +
                                this.state.productReturnDate.split("/")[1] +
                                "-" +
                                this.state.productReturnDate.split("/")[0]
                            )
                          : this.state.productReturnDate
                      }
                      onChange={(productReturnDate) =>
                        this.handleDateChange(productReturnDate)
                      }
                      error={this.state.productReturnDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      disabled={true}
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="supplierGuid"
                      label="Tedarikçi [*]"
                      placeholder="Tedarikçi seçiniz..."
                      selectValue={this.state.supplierGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleSupplierSelectChange(option)
                      }
                      options={this.state.supplierArr}
                      error={this.state.supplierError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productEntryGuid"
                      label="Alış [*]"
                      placeholder="Alış seçiniz..."
                      selectValue={this.state.productEntryGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isNullSupplier}
                      onChange={(option) =>
                        this.handleProductEntrySelectChange(option)
                      }
                      options={this.state.productEntryArr}
                      error={this.state.productEntryError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      options={this.state.customerArr}
                      error={this.state.customerError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      placeholder="Satış seçiniz..."
                      selectValue={this.state.productOutGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isNullCustomer}
                      onChange={(option) =>
                        this.handleProductOutSelectChange(option)
                      }
                      options={this.state.productOutArr}
                      error={this.state.productOutError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="text-danger" style={noMultipleChoiceStyle}>
                      Not: Lütfen sadece tedarikçi veya müşteri seçimlerinden
                      birini yapınız!
                    </div>
                  </Col>
                </Row>

                {/* <Col lg="2" >
                  <CgnButton
                    type="button"
                    color="primary"
                    className="mb-3 dynamicRowAddButton"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                </Col> */}

                {this.state.productData !== null && this.state.productData.map((productData, idx) => (
                  <>
                  <Row className="mobileBorder">
                    <Col className="modalSelectCol pl-0">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            placeholder="Lütfen depo seçiniz..."
                            selectValue={productData.locationGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleLocationInputValueChange(option, idx);
                            }}
                            options={this.state.locationArr}
                            error={productData.locationError}
                            disabled={true}
                          />
                        </div>
                        {this.state.locationModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalLocation(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="productGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={productData.productGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={productData.productError}
                            disabled={true}
                          />
                        </div>
                        {this.state.productModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalProduct(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Miktar [*]"
                        value={productData.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen miktar girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productPieceError}
                        step="0.1"
                        min={0}
                      />
                    </Col>

                    <Col>
                    <CgnReactSelect
                      name="unit"
                      label="Birim [*]"
                      placeholder="Birim"
                      selectValue={productData.unit}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleUnitChange(option, idx)
                      }
                      options={unitValuesStatuses}
                      error={productData.unitError}
                      disabled={true}
                    />
                    </Col>

                    <Col>
                      <CgnTextbox
                        type="number"
                        name="unitPrice"
                        label="Birim Fiyat [*]"
                        value={productData.unitPrice}
                        onChange={(e) =>
                          this.handleProductUnitPriceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen birim fiyat girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productUnitPriceError}
                        readOnly={true}
                      />
                    </Col>
                    
                    <Col>
                    <CgnTextbox
                      type="number"
                      name="productDiscountPercent"
                      label="İskonto(Yüzde)"
                      value={productData.productDiscountPercent}
                      placeHolder="İskonto"
                      maxLength="50"
                      autoComplete="off"
                      onChange={(e) =>
                        this.handleProductDiscountPercentChange(e, idx)
                      }
                      error={productData.productDiscountPercentError}
                      readOnly={true}
                    />
                  </Col>
                    </Row>
                    <Row className="pl-0">                  
                  <Col className="pl-0">
                    <CgnTextbox
                      type="number"
                      name="productDiscountValue"
                      label="İskonto(Net Tutar)"
                      value={productData.productDiscountValue}
                      placeHolder="İskonto net tutar"
                      maxLength="50"
                      autoComplete="off"
                      onChange={(e) =>
                        this.handleProductDiscountValueChange(e, idx)
                      }                      
                      error={productData.productDiscountValueError}
                      readOnly={true}
                    />
                  </Col>
                    <Col>
                    <CgnReactSelect
                      name="productTaxRate"
                      label="K.D.V Oranı [*]"
                      placeholder="K.D.V oranı"
                      selectValue={productData.productTaxRate}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductTaxRateSelectChange(option, idx)
                      }
                      options={TaxRateChoices}
                      error={productData.productTaxRateError}
                      disabled={true}
                    />
                    </Col>
                    <Col>
                        <CgnReactSelect
                          name="tevkifatId"
                          label="Tevkifat"
                          placeholder="Tevkifat seçiniz"
                          selectValue={productData.tevkifatId}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTevkifatChange(option, idx)
                          }
                          options={this.state.tevkifatArr}
                          disabled={true}
                        />
                      </Col>
                    <Col>
                      <CgnTextbox
                        type="number"
                        name="tevkifatTotal"
                        label="Tevkifat Tutar"
                        value={productData.tevkifatTotal}
                        readOnly={true}
                        //onChange={this.ProductPriceCalculate()}
                        maxLength="50"
                        autoComplete="off"
                      />
                    </Col>

                    <Col>
                      <CgnTextbox
                        type="number"
                        name="total"
                        label="Fiyat [*]"
                        value={productData.total}
                        readOnly={true}
                        //onChange={this.ProductPriceCalculate()}
                        maxLength="50"
                        autoComplete="off"
                      />
                    </Col>

                    <Col>
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                 </>
                  
                ))}

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  
                  <Col lg="3">
                  <CgnTextbox
                      type="number"
                      name="generalDiscountTotal"
                      label="İndirim Toplamı"
                      value={this.state.generalDiscountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                  <CgnTextbox
                      type="number"
                      name="generalTaxTotal"
                      label="KDV Toplamı"
                      value={this.state.generalTaxTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Toplam Tutar"
                      value={this.state.total}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>
             

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12" >
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      disabled={true}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductReturnNewAddUpdate;
