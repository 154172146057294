import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxType,
  TaxRateChoices,
  CurrencyType,
  
} from "../../redux/actions/constants";
import moment from "moment";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class ProductReturnAddUpdate extends Component {
  state = {
    pageTitle: "İade Ekle",
    pageTitleAdd: "İade Ekle",
    pageTitleUpdate: "İade Güncelle",
    pageAddLink: "iade-ekle",
    pageListLink: "iade-listele",
    breadcrumb: [{ text: "İade", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productReturnDate: "",
    supplierGuid: null,
    productEntryGuid: null,
    productOutGuid: null,
    customerGuid: null,
    guid: "",
    costGuid: null,
    incomeGuid: null,
    total: 0,
    generalTotal: null,
    taxRateValue: "",
    taxTypeValue: null,
    discountPercent: "",
    discountValue: "",
    discountTotal: parseFloat(0).toFixed(2),
    description: "",
    status: true,
    productReturnDateError: "",
    supplierError: "",
    productEntryError: "",
    productOutError: "",
    customerError: "",
    totalError: "",
    taxRateError: "",
    taxTypeError: "",
    productGuid: "",
    currencyType: "",
    currencyTypeError: "",
    alertErrorMessage: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    isOpenModalSupplier: false,
    isOpenModalCustomer: false,
    productData: [],
    supplierArr: [],
    productEntryArr: [],
    customerArr: [],
    productArr: [],
    productOutArr: [],
    isNullSupplier: false,
    isNullCustomer: false,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      isNullSupplier: true,
      isNullCustomer: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

      await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });


    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/returns/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              productReturnDate: response.data.returnDate,
              supplierGuid: response.data.supplierGuid,
              incomeGuid: response.data.incomeGuid,
              productEntryGuid: response.data.productEntryGuid,
              customerGuid: response.data.customerGuid,
              costGuid: response.data.costGuid,
              productOutGuid: response.data.productOutGuid,
              productData: response.data.returnProductDto,
              total: response.data.total,
              taxTypeValue: response.data.taxType,
              taxRateValue: response.data.taxRate.toString(),
              generalTotal: response.data.generalTotal,
              currencyType: response.data.currencyType,
              discountPercent: response.data.discountPercent,
              discountTotal: response.data.discountTotal,
              discountValue: response.data.discountValue,
              description: response.data.description,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (this.state.supplierGuid) {
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        let conditionType = 1; // iadesi yapılmış ürünleri 2 diye belirledik.
        await axios
          .get(
            URL +
              "/productentries/getlist/" +
              this.state.supplierGuid +
              "/" +
              conditionType,
            config
          )
          .then((response) => {
            var myArr = response.data.map(function (item) {
              return {
                value: item.guid,
                costGuid: item.costGuid,
                label:
                  item.costDate +
                  " " +
                  item.supplierName +
                  " " +
                  item.discountTotal +
                  " " +
                  (item.currencyType === 1
                    ? "₺"
                    : item.currencyType === 2
                    ? "$"
                    : item.currencyType === 3
                    ? "€"
                    : "-"),
                productData: item.productEntryProductDto,
                taxTypeValue: item.taxType,
                taxRateValue: item.taxRate.toString(),
                discountValue: item.discountValue,
                discountPercent: item.discountPercent,
                currencyType: item.currencyType,
                status: item.status,
              };
            });
            this.setState({
              productEntryArr: myArr,
            });
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
              isLoading: false,
            });
          });
      } else if (this.state.customerGuid) {
        let conditionType = 1; // iadesi yapılmış ürünleri 2 diye belirledik.
        await axios
          .get(
            URL +
              "/productouts/getlist/" +
              this.state.customerGuid +
              "/" +
              conditionType,
            config
          )
          .then((response) => {
            var myArr = response.data.map(function (item) {
              return {
                value: item.guid,
                incomeGuid: item.incomeGuid,
                label:
                  moment(item.incomeDate).format("DD/MM/YYYY") +
                  " " +
                  item.customerName +
                  " " +
                  item.discountTotal +
                  " " +
                  (item.currencyType === 1
                    ? "₺"
                    : item.currencyType === 2
                    ? "$"
                    : item.currencyType === 3
                    ? "€"
                    : "-"),
                productData: item.productOutProductDto,
                taxTypeValue: item.taxType,
                taxRateValue: item.taxRate.toString(),
                discountValue: item.discountValue,
                discountPercent: item.discountPercent,
                currencyType: item.currencyType,
                status: item.status,
              };
            });
            this.setState({
              productOutArr: myArr,
            });
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
              isLoading: false,
            });
          });
      }
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        isNullSupplier: false,
        isNullCustomer: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        productReturnDate: "",
        supplierGuid: null,
        customerGuid: null,
        incomeGuid: null,
        costGuid: null,
        productEntryGuid: null,
        productEntryArr: [],
        productOutGuid: null,
        productOutArr: [],
        productData: [],
        currencyType: "",
        discountPercent: "",
        discountValue: "",
        discountTotal: parseFloat(0).toFixed(2),
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        description: "",
        status: true,
        isNullSupplier: false,
        isNullCustomer: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleDateChange(productReturnDate) {
    if (productReturnDate) {
      this.setState({
        productReturnDate: productReturnDate,
        productReturnDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productReturnDate: "",
        productReturnDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      supplierError: "",
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
  };

  handleSupplierSelectChange = async (option) => {
    if (option) {
      await this.setState({
        supplierGuid: option.value,
        customerGuid: null,
        productOutGuid: null,
        incomeGuid: null,
        supplierError: "",
        customerError: "",
        productEntryError: "",
        productOutError: "",
        alertErrorMessage: "",
        isNullSupplier: false,
        isNullCustomer: true,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let conditionType = 1; // iadesi hiç yapılmamış ürünleri 1 diye belirledik.
      await axios
        .get(
          URL + "/productEntries/getlist/" + option.value + "/" + conditionType,
          config
        )
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label:
                item.costDate +
                " " +
                item.supplierName +
                " " +
                item.discountTotal +
                " " +
                (item.currencyType === 1
                  ? "₺"
                  : item.currencyType === 2
                  ? "$"
                  : item.currencyType === 3
                  ? "€"
                  : "-"),
              costGuid: item.costGuid,
              productData: item.productEntryProductDto,
              taxTypeValue: item.taxType,
              taxRateValue: item.taxRate.toString(),
              discountValue: item.discountValue,
              discountPercent: item.discountPercent,
              currencyType: item.currencyType,
              status: item.status,
            };
          });
          this.setState({
            productEntryArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (this.state.productEntryGuid === null) {
        this.setState({
          productEntryError: CgnMessage.textErrorMessage,
        });
      }
    } else {
      await this.setState({
        supplierGuid: null,
        costGuid: null,
        productEntryGuid: null,
        productEntryArr: [],
        productData: [],
        taxTypeValue: null,
        taxRateValue: "",
        discountValue: "",
        discountPercent: "",
        currencyType: 0,
        status: true,
        supplierError: CgnMessage.textErrorMessage,
        productEntryError: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isNullCustomer: false,
      });
    }
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[2]) {
      this.setState({
        isNullCustomer: true,
        customerError: "",
      });
    }
  };

  async handleProductEntrySelectChange(option) {
    if (this.state.supplierGuid && option) {
      await this.setState({
        productEntryGuid: option.value,
        productData: option.productData,
        taxTypeValue: option.taxTypeValue,
        taxRateValue: option.taxRateValue,
        discountPercent: option.discountPercent,
        discountValue: option.discountValue,
        currencyType: option.currencyType,
        costGuid: option.costGuid,
        status: option.status,
        productEntryError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        productEntryGuid: null,
        productData: [],
        taxTypeValue: null,
        costGuid: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        currencyType: 0,
        status: true,
        productEntryError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCustomerSelectChange = async (option) => {
    if (option) {
      this.setState({
        customerGuid: option.value,
        supplierGuid: null,
        costGuid: null,
        productEntryGuid: null,
        customerError: "",
        supplierError: "",
        productEntryError: "",
        productOutError: "",
        alertErrorMessage: "",
        isNullSupplier: true,
        isNullCustomer: false,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let conditionType = 1; // iadesi hiç yapılmamış ürünleri 1 diye belirledik.
      await axios
        .get(
          URL + "/productOuts/getlist/" + option.value + "/" + conditionType,
          config
        )
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label:
                moment(item.incomeDate).format("DD/MM/YYYY") +
                " " +
                item.customerName +
                " " +
                item.discountTotal +
                " " +
                (item.currencyType === 1
                  ? "₺"
                  : item.currencyType === 2
                  ? "$"
                  : item.currencyType === 3
                  ? "€"
                  : "-"),
              incomeGuid: item.incomeGuid,
              productData: item.productOutProductDto,
              taxTypeValue: item.taxType,
              taxRateValue: item.taxRate.toString(),
              discountValue: item.discountValue,
              discountPercent: item.discountPercent,
              currencyType: item.currencyType,
              status: item.status,
            };
          });
          this.setState({
            productOutArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (this.state.productOutGuid === null) {
        this.setState({
          productOutError: CgnMessage.textErrorMessage,
        });
      }
    } else {
      this.setState({
        customerGuid: null,
        incomeGuid: null,
        productOutGuid: null,
        productOutArr: [],
        productData: [],
        taxTypeValue: null,
        taxRateValue: "",
        discountValue: "",
        discountPercent: "",
        currencyType: 0,
        status: true,
        customerError: CgnMessage.textErrorMessage,
        supplierError: CgnMessage.textErrorMessage,
        productOutError: "",
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isNullSupplier: false,
      });
    }
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[2]) {
      this.setState({
        isNullSupplier: true,
        supplierError: "",
      });
    }
  };

  handleProductOutSelectChange(option) {
    if (option) {
      this.setState({
        productOutGuid: option.value,
        incomeGuid: option.incomeGuid,
        productData: option.productData,
        taxTypeValue: option.taxTypeValue,
        taxRateValue: option.taxRateValue,
        discountPercent: option.discountPercent,
        discountValue: option.discountValue,
        currencyType: option.currencyType,
        status: option.status,
        productOutError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productOutGuid: null,
        incomeGuid: null,
        productData: [],
        taxTypeValue: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        currencyType: 0,
        status: true,
        productOutError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;

      item.productPieceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      productReturnDate: "",
      supplierGuid: null,
      customerGuid: null,
      costGuid: null,
      incomeGuid: null,
      productEntryGuid: null,
      productEntryArr: [],
      productOutGuid: null,
      productOutArr: [],
      productData: [],
      currencyType: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: parseFloat(0).toFixed(2),

      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      isNullSupplier: false,
      isNullCustomer: false,
      description: "",

      alertErrorMessage: CgnMessage.alertErrorMessage,
      productReturnDateError: CgnMessage.textErrorMessage,
      supplierError: CgnMessage.textErrorMessage,
      customerError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: this.state.productGuid,
      locationGuid: this.state.locationGuid,
      piece: null,
      unitPrice: null,
      total: 0,
      status: this.state.status,
      productError: "",
      productPieceError: "",
      productUnitPriceError: "",
    });
    array.forEach((item) => {
      if (
        array.length > 0 &&
        (!item.productGuid || !item.piece || !item.unitPrice || !item.total || !item.locationGuid)
      ) {
        if (item.piece === 0) {
          item.productPieceError = "";
          this.setState({
            alertErrorMessage: "",
            isNull: false,
          });
        } else {
          item.productError = CgnMessage.textErrorMessage;
          item.locationError = CgnMessage.textErrorMessage;
          item.productPieceError = CgnMessage.textErrorMessage;
          item.productUnitPriceError = CgnMessage.textErrorMessage;
          this.setState({
            isNull: true,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      }
    });
    this.setState({ productData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
  }

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productGuid = null;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
  }

  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = null;
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  ProductPriceCalculate = () => {
    var totalProductPrice = 0;
    this.state.productData.forEach((item) => {
      item.total = (
        parseFloat(item.piece) * parseFloat(item.unitPrice)
      ).toFixed(2);
      totalProductPrice += parseFloat(item.total);
    });
    this.state.total = parseFloat(totalProductPrice).toFixed(2); // loop girmemesi için setState kullanmadım.
    this.PriceCalculate(null);
  };

  productRequiredField = () => {
    var arr = this.state.productData;;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);
    this.setState({ productData: someArray });
    if (idx === 0) {
      this.setState({
        productEntryGuid: null,
        productOutGuid: null,
        alertErrorMessage: "",
        total: 0,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      productReturnDateError: "",
      supplierError: "",
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      alertErrorMessage: "",
    });
    if (this.state.productReturnDate === "") {
      this.setState({
        productReturnDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.supplierGuid === null && this.state.customerGuid === null) {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxTypeValue === null) {
      this.setState({
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxRateValue === "") {
      this.setState({
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      ((this.state.supplierGuid && this.state.customerGuid === null) ||
        (this.state.supplierGuid === null && this.state.customerGuid)) &&
      this.state.productReturnDate !== "" &&
      this.state.isNull === false
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          productGuid: element.productGuid,
          locationGuid: element.locationGuid,
          piece: element.piece,
          unitPrice: element.unitPrice,
          total: parseFloat(element.total),
        });
      });
      var myObj = {
        returnDate: this.state.productReturnDate,
        supplierGuid: this.state.supplierGuid,
        costGuid: this.state.costGuid,
        productEntryGuid: this.state.productEntryGuid,
        customerGuid: this.state.customerGuid,
        incomeGuid: this.state.incomeGuid,
        productOutGuid: this.state.productOutGuid,
        taxType: this.state.taxTypeValue,
        taxRate: parseInt(this.state.taxRateValue),
        total: parseFloat(this.state.total),
        generalTotal: parseFloat(this.state.generalTotal),
        currencyType: this.state.currencyType,
        discountPercent:
          this.state.discountPercent !== ""
            ? parseInt(this.state.discountPercent)
            : 0,
        discountValue:
          this.state.discountValue !== ""
            ? parseFloat(this.state.discountValue)
            : 0,
        discountTotal: parseFloat(this.state.discountTotal),
        description: this.state.description,
        status: true, //this.state.status,
        returnProductDto: productData,
      };
      var URLParam = "/returns/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/returns/update";
        myObj = {
          guid: this.state.guid,
          returnDate: this.state.productReturnDate,
          supplierGuid: this.state.supplierGuid,
          productEntryGuid: this.state.productEntryGuid,
          customerGuid: this.state.customerGuid,
          productOutGuid: this.state.productOutGuid,
          taxType: this.state.taxTypeValue,
          taxRate: parseInt(this.state.taxRateValue),
          total: parseFloat(this.state.total),
          generalTotal: parseFloat(this.state.generalTotal),
          currencyType: this.state.currencyType,
          discountPercent:
            this.state.discountPercent !== ""
              ? parseInt(this.state.discountPercent)
              : 0,
          discountValue:
            this.state.discountValue !== ""
              ? parseFloat(this.state.discountValue)
              : 0,
          discountTotal: parseFloat(this.state.discountTotal),
          description: this.state.description,
          status: true, //this.state.status,
          returnProductDto: productData,
        };
        if (this.state.supplierGuid) {
          myObj.incomeGuid = this.state.incomeGuid;
          myObj.costGuid = null;
        } else if (this.state.customerGuid) {
          myObj.costGuid = this.state.costGuid;
          myObj.incomeGuid = null;
        }
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/iade-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "total" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var total = parseFloat(this.state.total);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = total - total * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = total - discountValue;
    } else {
      generalTotal = total;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }
    this.state.total = parseFloat(total).toFixed(2);
    this.state.generalTotal = parseFloat(generalTotal).toFixed(2);
    this.state.discountTotal = parseFloat(discountTotal).toFixed(2);
  };

  render() {
    const noMultipleChoiceStyle = {
      marginTop: "-14px",
      marginBottom: "5px",
    };

    const ProductPriceCalculate = this.ProductPriceCalculate();

    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="12">
                    <CgnDatePicker
                      name="productReturnDate"
                      label="Tarih [*]"
                      selected={
                        this.state.productReturnDate &&
                        this.state.productReturnDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.productReturnDate.split("/")[2] +
                                "-" +
                                this.state.productReturnDate.split("/")[1] +
                                "-" +
                                this.state.productReturnDate.split("/")[0]
                            )
                          : this.state.productReturnDate
                      }
                      onChange={(productReturnDate) =>
                        this.handleDateChange(productReturnDate)
                      }
                      error={this.state.productReturnDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="supplierGuid"
                      label="Tedarikçi [*]"
                      placeholder="Tedarikçi seçiniz..."
                      selectValue={this.state.supplierGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleSupplierSelectChange(option)
                      }
                      options={this.state.supplierArr}
                      error={this.state.supplierError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productEntryGuid"
                      label="Alış [*]"
                      placeholder="Alış seçiniz..."
                      selectValue={this.state.productEntryGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isNullSupplier}
                      onChange={(option) =>
                        this.handleProductEntrySelectChange(option)
                      }
                      options={this.state.productEntryArr}
                      error={this.state.productEntryError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      options={this.state.customerArr}
                      error={this.state.customerError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      placeholder="Satış seçiniz..."
                      selectValue={this.state.productOutGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isNullCustomer}
                      onChange={(option) =>
                        this.handleProductOutSelectChange(option)
                      }
                      options={this.state.productOutArr}
                      error={this.state.productOutError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="text-danger" style={noMultipleChoiceStyle}>
                      Not: Lütfen sadece tedarikçi veya müşteri seçimlerinden
                      birini yapınız!
                    </div>
                  </Col>
                </Row>

                {/* <Col lg="2" >
                  <CgnButton
                    type="button"
                    color="primary"
                    className="mb-3 dynamicRowAddButton"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                </Col> */}

                {this.state.productData.map((productData, idx) => (
                  <Row className="mobileBorder mb-3">
                     <Col lg="3">
                      <CgnReactSelect
                        name="locationGuid"
                        label="Depo [*]"
                        placeholder="Lütfen depo seçiniz..."
                        selectValue={productData.locationGuid}
                        isMulti={false}
                        isClearable={true}
                        disabled={true}
                        onChange={(option) => {
                          this.handleLocationInputValueChange(option, idx);
                        }}
                        options={this.state.locationArr}
                        error={productData.locationError}
                      />
                    </Col>
                    <Col lg="3">
                      <CgnReactSelect
                        name="productGuid"
                        label="Ürün [*]"
                        placeholder="Lütfen ürün seçiniz..."
                        selectValue={productData.productGuid}
                        isMulti={false}
                        isClearable={true}
                        disabled={true}
                        onChange={(option) => {
                          this.handleProductInputValueChange(option, idx);
                        }}
                        options={this.state.productArr}
                        error={productData.productError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={productData.piece}
                        min={0}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productPieceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="unitPrice"
                        label="Birim Fiyat [*]"
                        value={productData.unitPrice}
                        readOnly={true}
                        onChange={(e) =>
                          this.handleProductUnitPriceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen birim fiyat girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productUnitPriceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="total"
                        label="Fiyat [*]"
                        value={productData.total}
                        readOnly={true}
                        onChange={this.ProductPriceCalculate()}
                        maxLength="50"
                        autoComplete="off"
                      />
                    </Col>

                    {/* <Col lg="1" sm="3" >
                      <CgnButton
                        type="button"
                        color="danger"
                        className="dynamicRowDeleteButton"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col> */}
                  </Row>
                ))}

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Tutar [*]"
                      value={this.state.total}
                      placeHolder="Lütfen tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={ProductPriceCalculate}
                      error={this.state.totalError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountPercent"
                      label="İskonto(Yüzde)"
                      value={this.state.discountPercent}
                      placeHolder="Lütfen iskonto girin..."
                      maxLength="50"
                      autoComplete="off"
                      //readOnly={true}
                      onChange={this.PriceCalculate}
                      error={this.state.discountPercentError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountValue"
                      label="İskonto(Net Tutar)"
                      value={this.state.discountValue}
                      placeHolder="Lütfen iskonto net tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      //readOnly={true}
                      onChange={this.PriceCalculate}
                      error={this.state.discountValueError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      disabled={true}
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxTypeValue"
                      label="K.D.V Türü [*]"
                      placeholder="Lütfen K.D.V türü seçiniz..."
                      selectValue={this.state.taxTypeValue}
                      isMulti={false}
                      isClearable={true}
                      disabled={true}
                      onChange={(option) =>
                        this.handleTaxTypeSelectChange(option)
                      }
                      options={TaxType}
                      error={this.state.taxTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxRateValue"
                      label="K.D.V Oranı [*]"
                      placeholder="Lütfen K.D.V oranı seçiniz..."
                      selectValue={this.state.taxRateValue}
                      isMulti={false}
                      isClearable={true}
                      disabled={true}
                      onChange={(option) =>
                        this.handleTaxRateSelectChange(option)
                      }
                      options={TaxRateChoices}
                      error={this.state.taxRateError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar [*]"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12" >
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      disabled={true}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductReturnAddUpdate;
