import React from "react";
import { Button } from "reactstrap";

const CgnButton = ({
  type,
  color,
  text,
  size,
  outline,
  block,
  active,
  disabled,
  onClick,
  className,
  style
}) => {
  return (
    <>
      {type && color && text && (
        <Button
          type={type}
          color={color}
          size={size}
          outline={outline}
          block={block}
          active={active}
          disabled={disabled}
          onClick={onClick}
          className={className}
          style= {style}
        >
          {text}
        </Button>
      )}
    </>
  );
};

export default CgnButton;
