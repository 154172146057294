import React, { useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";

const CgnReactSelect = ({
  name,
  label,
  onChange,
  error,
  disabled,
  options,
  defaultValue,
  selectValue,
  placeholder,
  isClearable,
  isMulti,
  hidden,
  readOnly,
  isSearchable
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }
  // useEffect(() => {
  //   const elements = document.querySelectorAll('[id*="react-select"]'); // id'sinde "react-select" geçen tüm öğeleri seç
  //   elements.forEach(element => {
  //     element.addEventListener('keyup', (e) => { // Her öğe için bir 'keyup' olay dinleyicisi ekle
  //       const value = e.target.value;
  //       const turkishToLowerMap = {
  //         'I': 'ı', 'İ': 'i', 'Ş': 'ş', 'Ğ': 'ğ', 'Ü': 'ü', 'Ö': 'ö', 'Ç': 'ç'
  //       };

  //       const newValue = value.replace(/[IİŞĞÜÖÇ]/g, function (match) {
  //         return turkishToLowerMap[match];
  //       }).toLowerCase(); // Özel Türkçe karakterleri dönüştür ve küçük harfe çevir

  //       e.target.value = newValue; // Dönüştürülmüş değeri giriş öğesine atar
  //     });
  //   });
  // }, []);
  return (
    <FormGroup className={wrapperClass} hidden={hidden}>
      <Label className="font-weight-bolder" for={name} hidden={hidden}>
        {label}
      </Label>
      <Select
        name={name}
        id={name}
        onChange={onChange}
        options={options}
        isMulti={isMulti}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        defaultValue={defaultValue}
        selectValue={selectValue}
        placeholder={placeholder}
        noOptionsMessage={() => "Listelenecek kayıt bulunamadı!"}
        classNamePrefix="react-select"
        value={filter(options, selectValue, isMulti)}
        hidden={hidden}
        readOnly={readOnly}
        filterOption={(option, query) =>
          String(option.data.label)
            .toLocaleLowerCase('tr')
            .includes(query.toLocaleLowerCase('tr'))
        }
      />
      {error && !hidden && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
};

const filter = (options, selectValue, isMulti) => {
  if (options) {
    if (isMulti) {
      var selectedOptions = [];
      options.forEach((element) => {
        selectValue.forEach((element2) => {
          if (element.value === element2.value) {
            selectedOptions.push(element);
          }
        });
      });
      return selectedOptions;
    } else {
      return options.filter((option) => option.value + '_' === selectValue + '_');
    }
  }
};

export default CgnReactSelect;
