import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  Form,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  fotoStyle,
  stockTransferProcessStatus,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";

class TransferStockGetDetail extends Component {
  state = {
    pageTitle: "Yetkili Stok Aktarım Detay",
    pageTitleDetail: "Yetkili Stok Aktarım Detay",
    pageAddLink: "sayim2-ekle",
    pageListLink: "yetkili-stok-aktarim-listele",
    breadcrumb: [{ text: "Stok Aktarım", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    locationName: "",
    locationGuid: null,
    transferLocationName: "",
    transferLocationGuid: null,
    transferStockProductDto: [],
    totalPiece: "",
    productName: "",
    piece: null,
    status: "",
    processStatus: 0,
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    isOpen: false,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/transferStocks/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            transferStockProductDto: response.data.transferStockProductDto,
            locationName: response.data.locationName,
            locationGuid: response.data.locationGuid,
            transferLocationName: response.data.transferLocationName,
            transferLocationGuid: response.data.transferLocationGuid,
            status: response.data.status,
            processStatus: response.data.processStatus ? response.data.processStatus : 1,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  handleProcessStatusSelectChange(processStatus) {
    this.setState({ processStatus: processStatus.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(
        URL +
        "/transferstocks/processstatusupdate/" +
        this.state.guid +
        "/" +
        this.state.processStatus,
        config
      )
      .then((response) => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.href = "/yetkili-stok-aktarim-listele";
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  };

  render() {
    let _self = this;

    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    const thStyle = {
      width: "400px",
      textAlign: "left",
    };

    const thStyle2 = {
      width: "150px",
      textAlign: "center",
    };

    const thStyle3 = {
      width: "50px",
    };

    const tdStyle = {
      textAlign: "left",
      paddingTop: "23px",
    };

    const tdStyle2 = {
      textAlign: "center",
      width: "100px",
      paddingTop: "23px",
    };

    const tdStyle3 = {
      width: "50px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">{this.state.locationName}</td>
                  </tr>
                  <tr>
                    <th>Aktarılan Depo</th>
                    <td colSpan="4">{this.state.transferLocationName}</td>
                  </tr>
                  <tr>
                    <th>Ekleyen Kullanıcı</th>
                    <td colSpan="4">{this.state.createdBy}</td>
                  </tr>
                  <tr>
                    <th>Eklenen Tarih</th>
                    <td colSpan="4">{this.state.createdAt}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.transferStockProductDto !== null &&
                this.state.transferStockProductDto.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <Table className="table table-responsive table-hover table-productCount mb-3">
                        <tr style={mainRow}>
                          <th style={thStyle3}>Fotoğraf</th>
                          <th style={thStyle}>Ürün Adı</th>
                          <th style={thStyle2} className="text-center">
                            Aktarılan Adet
                          </th>
                        </tr>
                        {this.state.transferStockProductDto.map(function (
                          item
                        ) {
                          return (
                            <tr>
                              <td style={tdStyle3}>
                                {item.machineProductImage ? (
                                  <Button
                                    onClick={() =>
                                      _self.toggle(
                                        item.machineProductImage,
                                        item.machineProductName
                                      )
                                    }
                                    className="modalButtonList"
                                  >
                                    <img
                                      alt={item.machineProductName}
                                      src={`/Images/${item.machineProductImage}`}
                                      className="rdt_image table-image"
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    alt={item.name}
                                    src={noImg}
                                    className="rdt_image rdt_no_image table-image"
                                  />
                                )}
                              </td>
                              <td style={tdStyle}>{item.machineProductName}</td>
                              <td style={tdStyle2} className="text-center">
                                {item.piece}
                              </td>
                            </tr>
                          );
                        })}
                      </Table>
                    </Col>
                  </Row>
                )}

              <Form onSubmit={this.handleSave} style={{ height: "250px" }}>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="processStatus"
                      label="İşlem Durumu"
                      placeholder="Lütfen işlem durumunu seçin..."
                      isMulti={false}
                      selectValue={this.state.processStatus}
                      options={stockTransferProcessStatus}
                      onChange={(processStatus) =>
                        this.handleProcessStatusSelectChange(processStatus)
                      }
                    />
                  </Col>
                </Row>
                <CgnButtonGroup items={this.state.buttons} />
              </Form>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default TransferStockGetDetail;
