import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

class PersonelCuttingProcessDetail extends Component {
  state = {
    pageTitle: "Personel Kesim İşlemi Detay",
    pageTitleDetail: "Personel Kesim İşlemi Detay",
    pageAddLink: "personel-kesim-islemi-ekle",
    pageListLink: "personel-kesim-islemi-listele",
    breadcrumb: [{ text: "Personel Kesim İşlemleri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    cutId: 0,
    cutTypeId: 0,
    cuttingWork: 0,
    height: 0,
    width: 0,
    thickness: 0,
    piece: 1,
    kg: 0,
    time: 0,
    hourTL: 0,
    laserCutting: "",
    sheetPrice: "",
    twistingWork: "",
    specialTL: "",
    transportTL: "",
    totalPrice: 0,
    fileName: "",
    cuttingProcessProjectDtos: [],
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    open: false,
    details: false,
    collapseArray: [],
    productData: [],
    generalKg: 0
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/cuttingprocess/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            customerGuid: response.data.customerGuid,
            customerName: response.data.customerName,
            productData: response.data.cuttingKilogramDtos,
            specialTL: response.data.specialTL === 0 ? "" : response.data.specialTL,
            transportTL: response.data.transportTL === 0 ? "" : response.data.transportTL,
            totalPrice: response.data.totalPrice.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            status: response.data.status,
            isLoading: false,
            fileName: response.data.fileName,
            projectName: response.data.projectName === "" ? "" : response.data.projectName,
            processStatus: response.data.processStatus,
            updatedAt: response.data.updatedAt,
            updatedBy: response.data.updatedBy
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }


  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "180px",
  };

  thStyle1 ={
    width: "200px",
    display: "none"
  }

  tdStyle1 = {
    width: "200px",
    display: "none"
  }

  render() {
    var self = this;
    var self1 = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr className="cuttingTr3">
                    <th className="pl-3">Müşteri</th>
                    <td style={{ width: "400px" }}>
                      {this.state.customerName}
                    </td>
                    <th className="pl-3">Dosya Adı</th>
                    <td>{this.state.fileName}</td>
                    <th className="pl-3">Proje Adı</th>
                    <td>{this.state.projectName !== null ? this.state.projectName : "-"}</td>
                  </tr>
                </tbody>
              </Table>

                 {this.state.productData && this.state.productData.length > 0 && (
                   <Table className="table table-responsive table-hover">
                  {this.state.productData.map(function (item) {
                   return (
                       <>
                       <Accordion allowZeroExpanded>
                              <AccordionItem>
                                <AccordionItemHeading
                                  style={{ backgroundColor: "#1d4b8c" }}
                                >
                                  <AccordionItemButton>
                                    <tr className="cuttingTr">
                                      <th className="pl-3" style={self.thStyle}>Kesim</th>
                                      <td style={self.tdStyle}>
                                        {item.cutId === 1
                                          ? "Plazma Kesim"
                                          : item.cutId === 2
                                          ? "Lazer Kesim"
                                          : item.cutId === 3
                                          ? "Plazma Kesim İşçiliği"
                                          : item.cutId === 4
                                          ? "Lazer Kesim İşçiliği"
                                          : item.cutId === 5
                                          ? "Oksijen Kesim"
                                          : item.cutId === 6
                                          ? "Oksijen Kesim İşçiliği"
                                          : item.cutId === 7
                                          ? "Makas Kesim"
                                          : item.cutId === 8 
                                          ? "Makas Kesim İşçiliği"
                                          : item.cutId === 9 
                                          ? "Makas Büküm İşçiliği"
                                          : item.cutId === 10
                                          ? "Abkant Büküm"
                                          : item.cutId === 11
                                          ? "Abkant Büküm İşçiliği"
                                          : "-"}
                                      </td>
                                      <th className="pl-3" style={item.cutId === 2 || item.cutId === 4 ? self.thStyle1 : self.thStyle}>Cins</th>
                                      <td style={item.cutId === 2 || item.cutId === 4 ? self.tdStyle1 : self.tdStyle}>
                                        {item.cutTypeId === 1
                                          ? "Baklavalı"
                                          : item.cutTypeId === 2
                                          ? "St37"
                                          : item.cutTypeId === 3
                                          ? "HRP"
                                          : item.cutTypeId === 4
                                          ? "DKP"
                                          : item.cutTypeId === 5
                                          ? "St52"
                                          : item.cutTypeId === 6
                                          ? "Paslanmaz"
                                          : "-"}
                                      </td>
                                      <th className="pl-3" style={item.cutId === 2 || item.cutId === 4 ? self.thStyle : self.thStyle1}>Toplam Süre</th>
                                      <td style={item.cutId === 2 || item.cutId === 4 ? self.tdStyle : self.tdStyle1}>
                                        {item.time !== 0  ? item.time : 0}
                                      </td>
                                      <th style={self.thStyle}>Kalınlık</th>
                                      <td style={self.tdStyle}>
                                        {item.thickness}
                                      </td>
                                      <th style={self.thStyle}>Toplam KG</th>
                                      <td style={self.tdStyle}>
                                        {item.kg}
                                      </td>
                                      <th className="pl-3" style={self.thStyle}>Genel Toplam</th>
                                      <td style={self.tdStyle}>
                                        {item.totalKg !== 0
                                          ? item.totalKg.toLocaleString(
                                              "tr-TR",
                                              {
                                                minimumFractionDigits: 2,
                                              },
                                              +" ₺"
                                            )
                                          : "-"}
                                      </td>
                                    </tr>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <tr>
                                    <th className="pl-3" style={self.thStyle}>TL/Saat</th>
                                    <td style={self.tdStyle}>
                                      {item.hourTL !== 0 ? item.hourTL : "-"}
                                    </td>
                                    <th className="pl-3" style={self.thStyle}>Lazer Kesim</th>
                                    <td style={self.tdStyle}>
                                      {item.laserCutting !== ""
                                        ? item.laserCutting
                                        : "-"}
                                    </td>
                                    <th className="pl-3" style={self.thStyle}>
                                      Sac Fiyatı TL/Kg
                                    </th>
                                    <td style={self.tdStyle}>
                                      {item.sheetPrice !== ""
                                        ? item.sheetPrice + " ₺"
                                        : "-"}
                                    </td>
                        
                                    <th className="pl-3" style={self.thStyle}>
                                      Kesim İşçiliği TL/Kg
                                    </th>
                                    <td style={self.tdStyle}>
                                      {item.cuttingWork !== 0
                                        ? item.cuttingWork
                                        : "-"}
                                    </td>
                                    <th className="pl-3" style={self.thStyle}>
                                      Büküm İşçiliği TL/Kg
                                    </th>
                                    <td style={self.tdStyle}>
                                      {item.twistingWork === 0 ? "-" : item.twistingWork}
                                    </td>
                                 </tr>
                                    <tr>
                                    <th className="pl-3" style={self.thStyle}>Nakliye TL</th>
                                    <td style={self.tdStyle}>
                                      {self1.transportTL !== ""
                                        ? self1.transportTL
                                        : "-"}
                                    </td>

                                    <th className="pl-3" style={self.thStyle}>Cins</th>
                                      <td style={self.tdStyle}>
                                      {item.cutTypeId === 1
                                          ? "Baklavalı"
                                          : item.cutTypeId === 2
                                          ? "St37"
                                          : item.cutTypeId === 3
                                          ? "HRP"
                                          : item.cutTypeId === 4
                                          ? "DKP"
                                          : item.cutTypeId === 5
                                          ? "St52"
                                          : item.cutTypeId === 6
                                          ? "Paslanmaz"
                                          : "-"}
                                      </td>
                                    <th className="pl-3" style={self.thStyle}>
                                      Güncelleyen Kullanıcı
                                    </th>
                                    <td style={self.tdStyle}>
                                      {item.updatedBy ? item.updatedBy : "-"}
                                    </td>
                                    <th className="pl-3" style={self.thStyle}>
                                      Güncellenme Tarihi
                                    </th>
                                    <td style={self.tdStyle}>
                                      {item.updatedAt ? item.updatedAt : "-"}
                                    </td>
                                  </tr>
                                  </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                       </>
                    );
                    })}
                   </Table>
                 )} 

              <Table>
                <tbody>
                  <tr className="cuttingTr">
                    <th style={this.thStyle} className="pl-3">Toplam Tutar</th>
                    <td>{this.state.totalPrice + " ₺"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PersonelCuttingProcessDetail;
