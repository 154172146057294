import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  
  GetMonthFirstDateYear,
  GetMonthLastDateYear,
  GetMonthFirstDate2Year,
  GetMonthLastDate2Year,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class UsedSparePartsList extends Component {
  state = {
    pageTitle: "Kullanılan Yedek Parça Listele",
    pageAddLink: "kullanilan-yedek-parca-ekle",
    pageUpdateLink: "kullanilan-yedek-parca-guncelle",
    pageDetailLink: "kullanilan-yedek-parca-detay",
    pageListLink: "kullanilan-yedek-parca-listele",
    breadcrumb: [{ text: "Kullanılan Yedek Parça", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    pageType: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 

    var firstDate = GetMonthFirstDateYear();
    var lastDate = GetMonthLastDateYear();
    var firstDate2 = GetMonthFirstDate2Year();
    var lastDate2 = GetMonthLastDate2Year();

    if (localStorage.getItem("firstDateUsedSparePartsList")) {
      firstDate = localStorage.getItem("firstDateUsedSparePartsList");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateUsedSparePartsList")) {
      lastDate = localStorage.getItem("lastDateUsedSparePartsList");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem("firstDateUsedSparePartsList", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      await this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
    localStorage.setItem("lastDateUsedSparePartsList", this.state.lastDate);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    URLParam =
      "/usedspareparts/getlist/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos");
    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          data: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/usedspareparts/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  thStyle = {
    width: "75px",
  };

  thStyle2 = {
    width: "75px",
  };

  tdStyle = {
    width: "150px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={
            this.state.pageAddLink !== "" && "/" + this.state.pageAddLink
          }
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.firstDate2.split("/")[2] +
                          "-" +
                          this.state.firstDate2.split("/")[1] +
                          "-" +
                          this.state.firstDate2.split("/")[0]
                        )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.lastDate2.split("/")[2] +
                          "-" +
                          this.state.lastDate2.split("/")[1] +
                          "-" +
                          this.state.lastDate2.split("/")[0]
                        )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-0" lg="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.data}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Kullanılan Yedek Parça Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.dateTime}</td>
                              <th style={this.thStyle2}>Depo</th>
                              <td>{row.locationName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Makine</th>
                              <td colSpan={3}>
                                {row.newMachineSerialNo !== ""
                                  ? row.newMachineSerialNo +
                                  " / " +
                                  row.newMachineName
                                  : row.newMachineName}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: "Güncelle",
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Sil",
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default UsedSparePartsList;
