import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  CurrencyType,
  resizeFile,
  fotoStyle,
  StockStatus2,
  
  UserOperationClaimControl,
  machineProductStatuses,
  machineProductUnits,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import ImageUpload from "../toolbox/CgnImageUpload";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";
import CategoryAddUpdate from "../category/CategoryAddUpdate";
import ProductPropertyTypeAddUpdate from "../productPropertyType/ProductPropertyTypeAddUpdate";
import LocationAddUpdate from "../location/LocationAddUpdate";
import ContractAddUpdate from "../contract/ContractAddUpdate";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
//import ProductAddUpdate from "../product/ProductAddUpdate";

class MachineProductAddUpdate extends Component {
  state = {
    pageTitle: "Ürün Ekle",
    pageTitleAdd: "Ürün Ekle",
    pageTitleUpdate: "Ürün Güncelle",
    pageAddLink: "urun3-ekle",
    pageListLink: "urun3-listele",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    urlSplit: [],
    categoryArr: [],
    categoryGuid: "",
    categoryName: "",
    code: "",
    unit: "",
    productStatus: "",
    supplierProductCode: "",
    image: "",
    name: "",
    firstValues: [],
    criticalPieces: "",
    currencyType: "",
    salePrice: "",
    buyPrice: "",
    productPropertyTypeArr: [],
    machineProductPropertyDto: [],
    machineProductShelfRowDto: [],
    propertyTypeGuid: "",
    productImageDto: [],
    machineId: "",
    machineError: "",
    foreignName: "",
    guid: "",
    name2: "",
    pieceIdx: 0,
    status: true,
    isNull: false,
    isOpenModalCategory: false,
    isOpenModalProductPropertyType: false,
    isOpenModalContract: false,
    modalButtonIdx: 0,
    counter: -1,
    counter2: -1,
    stockStatus: 1, // 1se stoklu, 0sa stoksuz
    isTrue: false,
    isTrueBuyPrices: false,
    isTrueSalePrices: false,
    categoryNameError: "",
    codeError: "",
    nameError: "",
    criticalPiecesError: "",
    unitError: "",
    productStatusError: "",
    currencyTypeError: "",
    salePriceError: "",
    alertErrorMessage: "",
    message: "",
    description: null,
    machineArr: [],
    machineOption: [],
    machinesId: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    categoryModalPlus: false,
    productPropertyTypeModalPlus: false,
    productModalPlus: false,
    locationArr: [],
    isOpenModalLocation: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      categoryModalPlus: await UserOperationClaimControl(1006),
      productPropertyTypeModalPlus: await UserOperationClaimControl(1033),
      productModalPlus: await UserOperationClaimControl(1029),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/categories/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          categoryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/productPropertyTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            machineGuid: item.machineGuid,
          };
        });
        this.setState({
          productPropertyTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/machinemodels/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          machineOption: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      await axios
        .get(URL + "/machineProducts/get/" + urlSplit[2], config)
        .then((response) => {
          if (response.data.machineId !== null) {
            const machineArr = response.data.machineId.split(",");
            machineArr.forEach((element) => {
              var machineObj = {};
              machineObj.value = element;
              this.state.machineArr.push(machineObj);
            });
          }
        });
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/machineProducts/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              categoryGuid: response.data.categoryGuid,
              categoryName: response.data.categoryName,
              machineProductPropertyDto:
                response.data.machineProductPropertyDto,
              image: response.data.image !== "" ? "/Images/" + response.data.image : "",
              code: response.data.code,
              name: response.data.name,
              foreignName: response.data.foreignName,
              criticalPieces: response.data.criticalPieces,
              currencyType: response.data.currencyType,
              salePrice: response.data.salePrice,
              buyPrice: response.data.buyPrice,
              stockStatus: response.data.stockStatus,
              machineId: response.data.machineId,
              //productImageDto: response.data.productImageDto,
              status: response.data.status,
              unit: response.data.unit,
              productStatus: response.data.productStatus,
              description: response.data.description,
              isLoading: false,
              isNull: false,
              machineProductShelfRowDto:
                response.data.machineProductShelfRowDto,
            });
            if (response.data.stockStatus === 0) {
              this.setState({
                isTrue: true,
                criticalPieces: "",
                criticalPiecesError: "",
                alertErrorMessage: "",
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        image: "",
        categoryGuid: "",
        categoryName: "",
        code: "",
        name: "",
        supplierProductCode: "",
        criticalPieces: "",
        currencyType: "",
        salePrice: "",
        buyPrice: "",
        description: null,
        machineProductPropertyDto: [],
        //productImageDto: [],
        stockStatus: 1,
        status: true,
        counter: -1,
        counter2: -1,
        isTrue: false,
        machineProductShelfRowDto: [],
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalCategory &&
      this.props.location.modalAddedCategory !== undefined &&
      this.props.location.modalAddedCategory.isModal !== undefined &&
      this.props.location.modalAddedCategory.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/categories/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              categoryGuid: item.categoryGuid,
            };
          });
          this.setState({
            categoryArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCategory.data &&
              element.name === this.props.location.modalAddedCategory.data
            ) {
              this.setState({
                categoryGuid: element.guid,
                categoryNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCategory.isModal = true;
      this.setState({
        isOpenModalCategory: false,
      });
    }
    if (
      this.state.isOpenModalProductPropertyType &&
      this.props.location.modalAddedProductPropertyType !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/productPropertyTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              machineGuid: item.machineGuid,
            };
          });
          this.setState({
            productPropertyTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProductPropertyType.data &&
              element.name ===
              this.props.location.modalAddedProductPropertyType.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              let machineProductPropertyDto =
                this.state.machineProductPropertyDto;
              machineProductPropertyDto[
                this.state.modalButtonIdx
              ].propertyTypeGuid = element.guid;
              machineProductPropertyDto[
                this.state.modalButtonIdx
              ].propertyTypeError = "";
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProductPropertyType.isModal = true;
      this.setState({
        isOpenModalProductPropertyType: false,
      });
    }
    if (
      this.state.isOpenModalProductPropertyType &&
      this.props.location.modalAddedProductPropertyType !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              let machineProductShelfRowDto =
                this.state.machineProductShelfRowDto;
              machineProductShelfRowDto[
                this.state.modalButtonIdx
              ].locationGuid = element.guid;
              machineProductShelfRowDto[
                this.state.modalButtonIdx
              ].locationGuidError = "";
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  barcodeGenerator = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineProducts/barcodenumbergenerate", config)
      .then((response) => {
        this.setState({
          code: response.data,
          codeError: "",
          alertErrorMessage: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalCategory = () => {
    this.setState({
      isOpenModalCategory: !this.state.isOpenModalCategory,
    });
  };

  addModalProductPropertyType = async (idx) => {
    await this.setState({
      isOpenModalProductPropertyType:
        !this.state.isOpenModalProductPropertyType,
      modalButtonIdx: idx,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  handleMachineSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        machineArr: option,
        machineArrError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        machineArr: [],
      });
    }
  }

  handleStockStatus = async (e) => {
    if (e.value === 0) {
      await this.setState({
        stockStatus: 0,
        isTrue: true,
        criticalPieces: "",
        criticalPiecesError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        stockStatus: e.value,
        isTrue: false,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      categoryNameError: "",
      codeError: "",
      nameError: "",
      criticalPiecesError: "",
      currencyTypeError: "",
      salePriceError: "",
      alertErrorMessage: "",
    });
    if (name === "categoryGuid" && value === "") {
      this.setState({
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "code" && value === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "criticalPieces" && value === "") {
      this.setState({
        criticalPiecesError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "salePrice" && value === "") {
      this.setState({
        salePriceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    await this.setState({
      categoryGuid: "",
      categoryName: "",
      image: "",
      code: "",
      description: null,
      name: "",
      criticalPieces: "",
      currencyType: "",
      salePrice: "",
      buyPrice: "",
      supplierProductCode: "",
      status: true,
      counter: -1,
      counter2: -1,
      stockStatus: 1,
      isTrue: false,
      isTrueBuyPrices: false,
      isTrueSalePrices: false,
      machineProductPropertyDto: [],
      machineProductShelfRowDto: [],
      alertErrorMessage: CgnMessage.alertErrorMessage,
      categoryNameError: CgnMessage.textErrorMessage,
      codeError: CgnMessage.textErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      criticalPiecesError: CgnMessage.textErrorMessage,
      salePriceError: CgnMessage.textErrorMessage,
    });
  }

  handleCategorySelectChange(option) {
    if (option) {
      this.setState({
        categoryGuid: option.value,
        categoryNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        categoryGuid: "",
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleUnitSelectChange(option) {
    if (option) {
      this.setState({
        unit: option.value,
        unitError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        unit: "",
        unitError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductStatusSelectChange(option) {
    if (option) {
      this.setState({
        productStatus: option.value,
        productStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productStatus: "",
        productStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleProductPropertyAdd = async () => {
    var array = this.state.machineProductPropertyDto;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      machineProductGuid: "00000000-0000-0000-0000-000000000000",
      propertyTypeGuid: "",
      value: "",
      propertyTypeError: CgnMessage.textErrorMessage,
      valueError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].propertyTypeGuid) {
      return array[this.state.counter].propertyTypeError === "";
    }
    if (array[this.state.counter].value) {
      return array[this.state.counter].valueError === "";
    }
    if (
      (array[this.state.counter].propertyTypeGuid === "" ||
        array[this.state.counter].value === "") &&
      array.length > 0
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ machineProductPropertyDto: array });
  };

  handleAddMachineProductShelfRow = async () => {
    var array = this.state.machineProductShelfRowDto;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      machineProductGuid: "00000000-0000-0000-0000-000000000000",
      locationGuid: "",
      shelf: "",
      row: "",
      locationGuidError: CgnMessage.textErrorMessage,
      shelfError: CgnMessage.textErrorMessage,
      rowError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].locationGuid) {
      array[this.state.counter].locationGuidError = "";
    }
    if (array[this.state.counter].shelf) {
      array[this.state.counter].shelfError = "";
    }
    if (array[this.state.counter].row) {
      array[this.state.counter].rowError = "";
    }
    if (
      (array[this.state.counter].locationGuid === "" ||
        array[this.state.counter].shelf === "" ||
        array[this.state.counter].row === "") &&
      array.length > 0
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ machineProductShelfRowDto: array });
  };

  handleTextBoxChange(e, idx) {
    let machineProductPropertyDto = this.state.machineProductPropertyDto;
    machineProductPropertyDto[idx].value = e.target.value;
    this.setState({
      machineProductPropertyDto: machineProductPropertyDto,
    });
    this.productPropertyRequiredField();
  }

  handleTextBoxChange2Shelf(e, idx) {
    let machineProductShelfRowDto = this.state.machineProductShelfRowDto;
    machineProductShelfRowDto[idx].shelf = e.target.value;
    this.setState({
      machineProductShelfRowDto: machineProductShelfRowDto,
    });
    this.productPropertyRequiredField2();
  }

  handleTextBoxChange2Row(e, idx) {
    let machineProductShelfRowDto = this.state.machineProductShelfRowDto;
    machineProductShelfRowDto[idx].row = e.target.value;
    this.setState({
      machineProductShelfRowDto: machineProductShelfRowDto,
    });
    this.productPropertyRequiredField2();
  }

  handleInputValueChange(option, idx) {
    let machineProductPropertyDto = this.state.machineProductPropertyDto;
    if (option) {
      machineProductPropertyDto[idx].propertyTypeGuid = option.value;
      machineProductPropertyDto[idx].propertyTypeError = "";
      this.setState({
        machineProductPropertyDto: machineProductPropertyDto,
      });
    } else {
      machineProductPropertyDto[idx].propertyTypeGuid = null;
      machineProductPropertyDto[idx].propertyTypeError =
        CgnMessage.textErrorMessage;
      this.setState({
        machineProductPropertyDto: machineProductPropertyDto,
      });
    }
    this.productPropertyRequiredField();
  }

  handleInputValueChange2(option, idx) {
    let machineProductShelfRowDto = this.state.machineProductShelfRowDto;
    if (option) {
      machineProductShelfRowDto[idx].locationGuid = option.value;
      machineProductShelfRowDto[idx].locationGuidError = "";
      this.setState({
        machineProductShelfRowDto: machineProductShelfRowDto,
      });
    } else {
      machineProductShelfRowDto[idx].locationGuid = null;
      machineProductShelfRowDto[idx].locationGuidError =
        CgnMessage.textErrorMessage;
      this.setState({
        machineProductShelfRowDto: machineProductShelfRowDto,
      });
    }
    this.productPropertyRequiredField2();
  }

  productPropertyRequiredField = () => {
    var arr = this.state.machineProductPropertyDto;
    arr.forEach((item) => {
      if (!item.propertyTypeGuid && item.value) {
        item.propertyTypeError = CgnMessage.textErrorMessage;
        item.valueError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (item.propertyTypeGuid && !item.value) {
        item.propertyTypeError = "";
        item.valueError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (!item.propertyTypeGuid && !item.value) {
        item.propertyTypeError = CgnMessage.textErrorMessage;
        item.valueError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (item.propertyTypeGuid && item.value) {
        item.propertyTypeError = "";
        item.valueError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
    });
  };

  productPropertyRequiredField2 = () => {
    var arr = this.state.machineProductShelfRowDto;
    this.setState({
      alertErrorMessage: "",
      isNull: false,
    });
    arr.forEach((item) => {
      item.locationGuidError = "";
      item.shelfError = "";
      item.rowError = "";
      if (!item.locationGuid) {
        item.locationGuidError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (!item.shelf) {
        item.shelfError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (!item.row) {
        item.rowError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductPropertyRemove(idx) {
    let someArray = await this.state.machineProductPropertyDto;
    someArray.splice(idx, 1);
    this.setState({
      machineProductPropertyDto: someArray,
      counter: this.state.counter - 1,
      isNull: false,
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        isNull: false,
        machineProductGuid: "00000000-0000-0000-0000-000000000000",
        propertyTypeGuid: "",
        value: "",
      });
    }
  }

  async handleRemoveMachineProductShelfRow(idx) {
    let someArray = await this.state.machineProductShelfRowDto;
    someArray.splice(idx, 1);
    this.setState({
      machineProductShelfRowDto: someArray,
      counter: this.state.counter - 1,
      isNull: false,
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        isNull: false,
        locationGuid: "00000000-0000-0000-0000-000000000000",
        propertyTypeGuid: "",
        shelf: "",
        row: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      categoryNameError: "",
      codeError: "",
      nameError: "",
      criticalPiecesError: "",
      currencyTypeError: "",
      salePriceError: "",
      contractError: "",
      unitError: "",
      productStatusError: "",
    });
    if (this.state.categoryGuid === "") {
      this.setState({
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.code === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.unit === "") {
      this.setState({
        unitError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.productStatus === "") {
      this.setState({
        productStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      (this.state.stockStatus === 2 || this.state.stockStatus === 1) &&
      this.state.criticalPieces === ""
    ) {
      this.setState({
        criticalPiecesError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.salePrice === "") {
      this.setState({
        salePriceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.machineProductPropertyDto.length > 0) {
      this.state.machineProductPropertyDto.forEach((element) => {
        if (element.productPropertyGuid === "" || element.value === "") {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (this.state.machineProductShelfRowDto.length > 0) {
      this.state.machineProductShelfRowDto.forEach((element) => {
        if (
          element.locationGuid === "" ||
          element.shelf === "" ||
          element.row === ""
        ) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (
      this.state.categoryGuid !== "" &&
      this.state.code !== "" &&
      this.state.name !== "" &&
      ((this.state.stockStatus === 0 && this.state.criticalPieces === "") ||
        ((this.state.stockStatus === 2 || this.state.stockStatus === 1) &&
          this.state.criticalPieces !== "")) &&
      this.state.currencyType !== "" &&
      this.state.salePrice !== "" &&
      this.state.isNull === false &&
      control === true &&
      this.state.unit !== "" &&
      this.state.productStatus !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var machines = [];
      this.state.machineArr.forEach((element) => {
        var machineObj = {};
        machineObj = element.value;
        machines.push(machineObj);
      });
      var myObj = {
        categoryGuid: this.state.categoryGuid,
        image: this.state.image,
        code: this.state.code,
        name: this.state.name,
        foreignName: this.state.foreignName,
        unit: this.state.unit,
        productStatus: this.state.productStatus,
        machineProductPropertyDto: this.state.machineProductPropertyDto,
        criticalPieces: parseInt(this.state.criticalPieces),
        currencyType: this.state.currencyType,
        salePrice: parseFloat(this.state.salePrice),
        buyPrice: this.state.buyPrice
          ? parseFloat(this.state.buyPrice)
          : parseFloat(0),
        stockStatus: this.state.stockStatus,
        status: this.state.status,
        //productImageDto: this.state.productImageDto,
        description: this.state.description,
        machineId: machines.toString(),
        machineProductShelfRowDto: this.state.machineProductShelfRowDto,
      };
      var URLParam = "/machineProducts/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "urun3-guncelle"
      ) {
        URLParam = "/machineProducts/update";
        myObj = {
          guid: this.state.guid,
          image: this.state.image,
          categoryGuid: this.state.categoryGuid,
          foreignName: this.state.foreignName,
          code: this.state.code,
          name: this.state.name,
          unit: this.state.unit,
          productStatus: this.state.productStatus,
          peerProductGuid: this.state.peerProductGuid,
          machineProductPropertyDto: this.state.machineProductPropertyDto,
          criticalPieces: parseInt(this.state.criticalPieces),
          currencyType: this.state.currencyType,
          buyPrice: this.state.buyPrice
            ? parseFloat(this.state.buyPrice)
            : parseFloat(0.0),
          salePrice: parseFloat(this.state.salePrice),
          stockStatus: this.state.stockStatus,
          status: this.state.status,
          //productImageDto: this.state.productImageDto,
          description: this.state.description,
          machineId: machines.toString(),
          machineProductShelfRowDto: this.state.machineProductShelfRowDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            var modalAddedMachineProduct = {
              data: `${this.state.code}-${this.state.name}`,
              salePrice: this.state.salePrice,
              buyPrice: this.state.buyPrice,
              isModal: false,
            };
            this.props.history.push({ modalAddedMachineProduct });
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (
                      this.state.guid &&
                      this.state.urlSplit[1] === "urun3-guncelle"
                    ) {
                      window.location.href =
                        "/urun3-listele/" + this.state.categoryGuid;
                    }
                    if (this.state.urlSplit[1] === "urun3-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    if (file.type === "image/png" || file.type === "image/jpeg") {
      const image = await resizeFile(file, 1000);
      this.setState({
        image: image,
        imageName: file.name,
      });
    }
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  uploadedImage2 = async (event) => {
    let array = this.state.productImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "application/pdf") {
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
              image: response.data,
              imageName: file.name,
            });
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({ productImageDto: array });
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.productImageDto;
    this.state.productImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ productImageDto: array });
        }
      }
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "urun3-ekle" ||
          this.state.urlSplit[1] === "urun3-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Fotoğraf"
                      image={this.state.image}
                      noFoto={noImg}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      error={this.state.imageError}
                    />
                  </Col>

                  {/* <Col lg="3">
                    <ImageUploadMulti
                      images={this.state.productImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Dosya"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.imageError2}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="code"
                      label="Ürün Kodu [*]"
                      value={this.state.code}
                      placeHolder="Lütfen ürün kodu girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.codeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnButton
                      className="barcodeButton"
                      type="button"
                      color="secondary"
                      block
                      onClick={() => this.barcodeGenerator()}
                      text={"Ürün Kodu Üret"}
                    />
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="categoryGuid"
                          label="Kategori [*]"
                          placeholder="Kategori seçiniz..."
                          selectValue={this.state.categoryGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCategorySelectChange(option)
                          }
                          options={this.state.categoryArr}
                          error={this.state.categoryNameError}
                        />
                      </div>
                      {this.state.categoryModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCategory()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Ürün Adı [*]"
                      value={this.state.name}
                      placeHolder="Lütfen ürün girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="foreignName"
                      label="Ürün Yabancı Adı"
                      value={this.state.foreignName}
                      placeHolder="Lütfen yabancı ürün adı girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.nameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="8">
                    <CgnReactSelect
                      name="machineArr"
                      label="Makine"
                      placeholder="Makine seçiniz..."
                      selectValue={this.state.machineArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleMachineSelectChange(option)
                      }
                      isMulti={true}
                      options={this.state.machineOption}
                      error={this.state.machineError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnReactSelect
                      name="unit"
                      label="Birim [*]"
                      placeholder="Lütfen birim seçiniz..."
                      selectValue={this.state.unit}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleUnitSelectChange(option)}
                      options={machineProductUnits}
                      error={this.state.unitError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnReactSelect
                      name="productStatus"
                      label="İthal/Yerli/Saf [*]"
                      placeholder="Lütfen seçiniz..."
                      selectValue={this.state.productStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductStatusSelectChange(option)
                      }
                      options={machineProductStatuses}
                      error={this.state.productStatusError}
                    />
                  </Col>
                </Row>

                {/* {this.state.machineProductPropertyDto.map(
                  (machineProductPropertyDto, idx) => (
                    <Row className="mobileBorder">
                      <Col lg="6" className="modalSelectCol">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="propertyTypeGuid"
                              label="Ürün Özellikleri Tipi [*]"
                              placeholder="Lütfen ürün özellikleri tipi seçiniz..."
                              selectValue={
                                machineProductPropertyDto.propertyTypeGuid
                              }
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleInputValueChange(option, idx);
                              }}
                              options={this.state.productPropertyTypeArr}
                              error={
                                machineProductPropertyDto.propertyTypeError
                              }
                            />
                          </div>
                          {this.state.productPropertyTypeModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() =>
                                  this.addModalProductPropertyType(idx)
                                }
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col lg="5">
                        <CgnTextbox
                          type="text"
                          name="value"
                          label="Ürün Özellikleri [*]"
                          value={machineProductPropertyDto.value}
                          onChange={(e) => this.handleTextBoxChange(e, idx)}
                          placeHolder="Lütfen ürün özellikleri girin..."
                          //maxLength="50"
                          autoComplete="off"
                          error={machineProductPropertyDto.valueError}
                        />
                      </Col>

                      <Col lg="1" sm="3">
                        <CgnButton
                          type="button"
                          color="danger"
                          className="dynamicRowDeleteButton"
                          onClick={() => this.handleProductPropertyRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  )
                )}

                <Row>
                  <Col lg="12">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-3 dynamicRowAddButton"
                      onClick={this.handleProductPropertyAdd}
                      text={"Ürün Özellikleri Ekle"}
                    />
                  </Col>
                </Row> */}

                {this.state.machineProductShelfRowDto.map((item, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            placeholder="Lütfen depo seçiniz..."
                            selectValue={item.locationGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleInputValueChange2(option, idx);
                            }}
                            options={this.state.locationArr}
                            error={item.locationGuidError}
                          />
                        </div>
                        {this.state.locationModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalLocation(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="4">
                      <CgnTextbox
                        type="text"
                        name="shelf"
                        label="Raf [*]"
                        value={item.shelf}
                        onChange={(e) => this.handleTextBoxChange2Shelf(e, idx)}
                        placeHolder="Lütfen raf girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={item.shelfError}
                      />
                    </Col>
                    <Col lg="4">
                      <CgnTextbox
                        type="text"
                        name="row"
                        label="Sıra [*]"
                        value={item.row}
                        onChange={(e) => this.handleTextBoxChange2Row(e, idx)}
                        placeHolder="Lütfen sıra girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={item.rowError}
                      />
                    </Col>
                    <Col lg="1" sm="3">
                      <CgnButton
                        type="button"
                        color="danger"
                        className="dynamicRowDeleteButton"
                        onClick={() =>
                          this.handleRemoveMachineProductShelfRow(idx)
                        }
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col lg="12">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-3 dynamicRowAddButton"
                      onClick={this.handleAddMachineProductShelfRow}
                      text={"Depo Raf Bilgisi Ekle"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="pl-0" lg="2">
                    <CgnTextbox
                      type="number"
                      name="buyPrice"
                      label="Alış Fiyatı"
                      value={this.state.buyPrice}
                      placeHolder="Lütfen alış fiyatı girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      readOnly={this.state.isTrueBuyPrices}
                      error={this.state.buyPriceError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="salePrice"
                      label="Satış Fiyatı [*]"
                      value={this.state.salePrice}
                      placeHolder="Lütfen satış fiyatı girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.salePriceError}
                      readOnly={this.state.isTrueSalePrices}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="stockStatus"
                      label="Stok Durumu"
                      placeholder="Lütfen stok durumu seçiniz..."
                      selectValue={this.state.stockStatus}
                      isMulti={false}
                      isClearable={false}
                      onChange={(e) => this.handleStockStatus(e)}
                      options={StockStatus2}
                      error={this.state.stockStatusError}
                    />
                  </Col>
                  <Col className="pr-0" lg="2">
                    <CgnTextbox
                      type="number"
                      name="criticalPieces"
                      label="Kritik Adet [*]"
                      value={this.state.criticalPieces}
                      placeHolder="Lütfen kritik adet girin..."
                      maxLength="100"
                      autoComplete="off"
                      disabled={this.state.isTrue}
                      onChange={this.handleChange}
                      error={this.state.criticalPiecesError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "urun3-ekle" ||
              this.state.urlSplit[1] === "urun3-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCategory}
          toggle={this.addModalCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Kategori Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProductPropertyType}
          toggle={this.addModalProductPropertyType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Ürün Özellikleri Tipi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ProductPropertyTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProductPropertyType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalContract}
          toggle={this.addModalContract}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Sözleşme Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ContractAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalContract}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(MachineProductAddUpdate);
