import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";


const CgnButtonDropdown = ({ color, className, items,label, style}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  return (
    <>
      {color && items && items.length > 0 && (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret color={color} className={className}>
            {label}
          </DropdownToggle>
          <DropdownMenu className="text-center" style={style}>
            {items &&
              items.map((item) =>
                item.text !== "" && (
                  item.type === "header" ? (
                    <DropdownItem key={item.text} header>{item.text}</DropdownItem>
                  ) : item.type === "divider" ? (
                    <DropdownItem key={item.text} divider></DropdownItem>
                  ) : item.type === "disabled" ? (
                    <DropdownItem key={item.text} disabled>{item.text}</DropdownItem>
                  ) : (
                    <DropdownItem key={item.text} tag="a" href={item.href} className={item.className} onClick={item.onClick}>
                      {item.text}
                    </DropdownItem>
                  )
                )
              )}
          </DropdownMenu>
        </ButtonDropdown>
      )}
    </>
  );
};

export default CgnButtonDropdown;
