import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  remindPersonStatuses,
  optionStatuses,
  
  Mounth,
  Day,
  remindOptionStatuses,
  endDateOptionStatuses
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnButton from "../toolbox/CgnButton";

class TypeAddUpdate extends Component {
  state = {
    pageTitle: "Tip Ekle",
    pageTitleAdd: "Tip Ekle",
    pageTitleUpdate: "Tip Güncelle",
    pageAddLink: "tip-ekle",
    pageListLink: "tip-listele",
    breadcrumb: [{ text: "Tip", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    endDay: 0,
    customerOptionDay: 0,
    adminOptionDay: 0,
    optionTypeCustomer: 0,
    optionTypeAdmin: 0,
    remindPerson: 0,
    year: 0,
    mounth: 0,
    endDaySelect: 0,
    guid: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    counter: -1,
    counter1: -1,
    counter2: -1,
    endDayError: "",
    optionTypeAdminError: "",
    optionTypeCustomerError: "",
    customerOptionDayError: "",
    adminOptionDayError: "",
    remindPersonError: "",
    typeDateDtosAdmin: [],
    typeDateDtosCustomer: [],
    typeDateDtos: [],
    typeDateDtosAdminError: "",
    typeDateDtosCustomerError: "",
    customerMounthError: "",
    customerDayError: "",
    adminMounthError: "",
    adminDayError: "",
    dayAndMountError: "",
    isHiddenAdminDay: true,
    isHiddenCustomerDay: true,
    isHiddenCustomer: true,
    isHiddenAdmin: true,
    isHiddenAdminDayandMounth: true,
    isHiddenCustomerDayandMounth: true,
    typeEndDateDtos: [],
    isHiddenEndDay: true,
    isHiddenDayandMount: true,
    endDaySelectError: "",
    typeEndDateDtosError: "",
    dayError: "",
    mountError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/types/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              name: response.data.name,
              endDay: response.data.endDay === 0 ? "-" : response.data.endDay,
              customerOptionDay: response.data.customerOptionDay,
              adminOptionDay: response.data.adminOptionDay,
              remindPerson: response.data.remindPerson,
              year: response.data.year,
              mounth: response.data.mounth,
              typeDateDtos: response.data.typeDateDtos,
              status: response.data.status,
              isLoading: false,
              typeDateDtosAdmin: response.data.typeDateDtosAdmin,
              typeDateDtosCustomer: response.data.typeDateDtosCustomer,
              typeEndDateDtos: response.data.typeEndDateDtos,
              endDaySelect: response.data.endDay !== 0 ? 1 : 2,
              isHiddenAdmin: response.data.remindPerson === 1 || response.data.remindPerson === 3 ? false : true,
              isHiddenCustomer: response.data.remindPerson === 2 || response.data.remindPerson === 3 ? false : true,
              optionTypeAdmin: response.data.optionTypeAdmin,
              optionTypeCustomer: response.data.optionTypeCustomer,
              isHiddenAdminDay: response.data.adminOptionDay !== 0 ? false : true,
              isHiddenCustomerDay: response.data.customerOptionDay !== 0 ? false : true,
              isHiddenAdminDayandMounth: response.data.typeDateDtosAdmin !== null && response.data.typeDateDtosAdmin.length > 0 ? false : true,
              isHiddenCustomerDayandMounth: response.data.typeDateDtosCustomer !== null && response.data.typeDateDtosCustomer.length > 0 ? false : true,
              isHiddenEndDay: response.data.endDay !== 0 ? false : true,
              isHiddenDayandMount: response.data.typeEndDateDtos !== null && response.data.typeEndDateDtos.length > 0 ? false : true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        endDay: 0,
        optionDay: 0,
        remindPerson: 0,
        year: 0,
        mounth: 0,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleEndDayChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      endDayError: "",
      alertErrorMessage: "",
    });
    if (name === "endDay" && value === 0) {
      this.setState({
        endDayError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleOptionDayChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      optionDayError: "",
      alertErrorMessage: "",
    });
    if (name === "optionDay" && value === 0) {
      this.setState({
        optionDayError: CgnMessage.textErrorMessage,
      });
    }
  };

  async handleEndDaySelectChange(option) {
    if (option) {
      if(option.value === 1){
        await this.setState({
          isHiddenEndDay: false,
          isHiddenDayandMount: true,
          endDaySelect: 1,
          endDayError: CgnMessage.alertErrorMessage,
          endDaySelectError: "",
          typeEndDateDtosError: "",
          typeEndDateDtos: []
        });
      }
      if(option.value === 2){
        await this.setState({
          isHiddenDayandMount: false,
          isHiddenEndDay: true,
          endDaySelect: 2,
          endDaySelectError: "",
          typeEndDateDtosError: CgnMessage.alertErrorMessage,
          endDay: 0
        });
      }
    } else {
      this.setState({
        isHiddenDayandMount: true,
        endDaySelect: null,
        isHiddenEndDay: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        endDaySelectError: CgnMessage.alertErrorMessage,
        typeEndDateDtosError: ""
      });
    }
  }

  handleOptionDayAdminSelectChange(option) {
    if (option) {
      this.setState({
        adminOptionDay: option.value,
        adminOptionDayError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        adminOptionDay: 0,
        endDayError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  
  handleDayChange(option) {
    if (option) {
      this.setState({
        endDay: option.value,
        endDayError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        endDay: 0,
        endDayError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleAdminDayChange(option, idx) {
    let typeDateDtosAdmin = this.state.typeDateDtosAdmin;
    if (option) {
      typeDateDtosAdmin[idx].adminDay = option.value;
      typeDateDtosAdmin[idx].adminDayError = "";

      await this.setState({
        typeDateDtosAdmin: typeDateDtosAdmin,
        alertErrorMessage: "",
      });
    } else {
      typeDateDtosAdmin[idx].adminDay = null;
      typeDateDtosAdmin[idx].adminDayError = CgnMessage.alertErrorMessage;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleEndDateDayChange(option, idx) {
    let typeEndDateDtos = this.state.typeEndDateDtos;
    if (option) {
      typeEndDateDtos[idx].day = option.value;
      typeEndDateDtos[idx].dayError = "";

      await this.setState({
        typeEndDateDtos: typeEndDateDtos,
        alertErrorMessage: "",
      });
    } else {
      typeEndDateDtos[idx].day = null;
      typeEndDateDtos[idx].dayError = CgnMessage.alertErrorMessage;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleAdminMounthChange(option, idx) {
    let typeDateDtosAdmin = this.state.typeDateDtosAdmin;
    if (option) {
      typeDateDtosAdmin[idx].adminMounth = option.value;
      typeDateDtosAdmin[idx].adminMounthError = "";
      await this.setState({
        typeDateDtos: typeDateDtosAdmin,
        alertErrorMessage: "",
      });
    } else {
      typeDateDtosAdmin[idx].adminMounth = null;
      typeDateDtosAdmin[idx].adminMounthError = CgnMessage.alertErrorMessage;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleEndDateMountChange(option, idx) {
    let typeEndDateDtos = this.state.typeEndDateDtos;
    if (option) {
      typeEndDateDtos[idx].mount = option.value;
      typeEndDateDtos[idx].mountError = "";
      await this.setState({
        typeEndDateDtos: typeEndDateDtos,
        alertErrorMessage: "",
      });
    } else {
      typeEndDateDtos[idx].mount = null;
      typeEndDateDtos[idx].mountError = CgnMessage.alertErrorMessage;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleAdminRemove(idx) {
    let someArray = this.state.typeDateDtosAdmin;
    someArray.splice(idx, 1);
    await this.setState({
      typeDateDtosAdmin: someArray,
      counter: this.state.counter - 1,
      alertErrorMessage: "",
    });

    if (idx === 0 && someArray.length === 0) {
      this.setState({
        typeDateDtosAdmin: [],
      });
    }
  }

  async handleEndDateRemove(idx) {
    let someArray = this.state.typeEndDateDtos;
    someArray.splice(idx, 1);
    await this.setState({
      typeEndDateDtos: someArray,
      counter2: this.state.counter2 - 1,
      alertErrorMessage: "",
    });

    if (idx === 0 && someArray.length === 0) {
      this.setState({
        typeEndDateDtos: [],
        typeEndDateDtosError: CgnMessage.alertErrorMessage
      });
    }
  }


  async handleCustomerRemove(idx) {
    let someArray = this.state.typeDateDtosCustomer;
    someArray.splice(idx, 1);
    await this.setState({
      typeDateDtosCustomer: someArray,
      counter1: this.state.counter1 - 1,
      alertErrorMessage: "",
    });

    if (idx === 0 && someArray.length === 0) {
      this.setState({
        typeDateDtosCustomer: [],
      });
    }
  }

  async handleCustomerDayChange(option, idx) {
    let typeDateDtosCustomer = this.state.typeDateDtosCustomer;
    if (option) {
      typeDateDtosCustomer[idx].customerDay = option.value;
      typeDateDtosCustomer[idx].customerDayError = "";

      await this.setState({
        typeDateDtosCustomer: typeDateDtosCustomer,
        alertErrorMessage: "",
      });
    } else {
      typeDateDtosCustomer[idx].customerDay = null;
      typeDateDtosCustomer[idx].adminDayError = CgnMessage.alertErrorMessage;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleCustomerMounthChange(option, idx) {
    let typeDateDtosCustomer = this.state.typeDateDtosCustomer;
    if (option) {
      typeDateDtosCustomer[idx].customerMounth = option.value;
      typeDateDtosCustomer[idx].customerMounthError = "";
      await this.setState({
        typeDateDtosCustomer: typeDateDtosCustomer,
        alertErrorMessage: "",
      });
    } else {
      typeDateDtosCustomer[idx].customerMounth = null;
      typeDateDtosCustomer[idx].customerMounthError =
        CgnMessage.alertErrorMessage;
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleOptionDayCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerOptionDay: option.value,
        customerOptionDayError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerOptionDay: 0,
        endDayError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleAdminDayandMounthAdd = async () => {
    var array = this.state.typeDateDtosAdmin;
    this.setState({
      alertErrorMessage: CgnMessage.alertErrorMessage,
      adminDayError: CgnMessage.alertErrorMessage,
      adminMounthError: CgnMessage.alertErrorMessage,
      typeDateDtosAdminError: "",
    });

    await array.push({
      adminMounth: null,
      adminDay: null,
      adminDayError: CgnMessage.alertErrorMessage,
      adminMounthError: CgnMessage.alertErrorMessage,
    });

    await this.setState({
      counter: this.state.counter + 1,
    });

    if (array[this.state.counter].adminDay) {
      return array[this.state.counter].adminDayError === "";
    }
    if (array[this.state.counter].adminMounth) {
      return array[this.state.counter].adminMounthError === "";
    }
    if (
      array[this.state.counter].adminDay === null ||
      array[this.state.counter].adminMounth === null
    ) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    this.setState({ typeDateDtosAdmin: array });
  };


  handleDayAndMountAdd = async () => {
    var array = this.state.typeEndDateDtos;
    this.setState({
      alertErrorMessage: CgnMessage.alertErrorMessage,
      dayError: CgnMessage.alertErrorMessage,
      mountError: CgnMessage.alertErrorMessage,
      typeEndDateDtosError: "",
      endDay: 0,
    });

    await array.push({
      mount: null,
      day: null,
      dayError: CgnMessage.alertErrorMessage,
      mountError: CgnMessage.alertErrorMessage,
    });

    await this.setState({
      counter2: this.state.counter2 + 1,
    });

    if (array[this.state.counter2].day) {
      return array[this.state.counter2].dayError === "";
    }
    if (array[this.state.counter2].mount) {
      return array[this.state.counter2].mountError === "";
    }
    if (
      array[this.state.counter2].day === null ||
      array[this.state.counter2].mount === null
    ) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    this.setState({ typeEndDateDtos: array });
  };


  handleCustomerDayandMounthAdd = async () => {
    var array = this.state.typeDateDtosCustomer;
    this.setState({
      alertErrorMessage: CgnMessage.alertErrorMessage,
      customerDayError: CgnMessage.alertErrorMessage,
      customerMounthError: CgnMessage.alertErrorMessage,
      typeDateDtosCustomerError: "",
    });

    await array.push({
      customerMounth: null,
      customerDay: null,
      customerDayError: CgnMessage.alertErrorMessage,
      customerMounthError: CgnMessage.alertErrorMessage,
    });

    await this.setState({
      counter1: this.state.counter1 + 1,
    });

    if (array[this.state.counter1].customerDay) {
      return array[this.state.counter1].customerDayError === "";
    }
    if (array[this.state.counter1].customerMounth) {
      return array[this.state.counter1].customerMounthError === "";
    }
    if (
      array[this.state.counter1].customerDay === null ||
      array[this.state.counter1].customerMounth === null
    ) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    this.setState({ typeDateDtosCustomer: array });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  async handlePersonSelectChange(option) {
    if (option) {
      await this.setState({
        remindPerson: option.value,
        remindPersonError: "",
        alertErrorMessage: "",
      });
      if (this.state.remindPerson === 1) {
        this.setState({
          isHiddenAdmin: false,
          isHiddenCustomer: true,
          isHiddenCustomerDay: true,
          optionTypeAdmin: null,
          isHiddenAdminDay: true,
          isHiddenAdminDayandMounth: true,
          isHiddenCustomerDayandMounth: true,
          typeDateDtosAdmin: [],
          typeDateDtosCustomer: []
        });
      } else if (this.state.remindPerson === 2) {
        this.setState({
          isHiddenCustomer: false,
          isHiddenAdmin: true,
          isHiddenAdminDay: true,
          optionTypeCustomer: null,
          isHiddenAdminDayandMounth: true,
          isHiddenCustomerDayandMounth: true,
          typeDateDtosAdmin: [],
          typeDateDtosCustomer: []
        });
      } else if (this.state.remindPerson === 3) {
        this.setState({
          isHiddenCustomer: false,
          isHiddenAdmin: false,
          isHiddenAdminDay: true,
          isHiddenCustomerDay: true,
          optionTypeCustomer: null,
          optionTypeAdmin: null,
          isHiddenAdminDayandMounth: true,
          isHiddenCustomerDayandMounth: true,
          typeDateDtosAdmin: [],
          typeDateDtosCustomer: []
        });
      }
    } else {
      this.setState({
        remindPerson: 0,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        remindPersonError: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleOptionTypeAdminChange(option) {
    if (option) {
      await this.setState({
        optionTypeAdmin: option.value,
        optionTypeAdminError: "",
        alertErrorMessage: "",
      });

      if (this.state.optionTypeAdmin === 1) {
        let newDate = new Date();
        if(newDate.getMonth() + 1 === 1 || newDate.getMonth() + 1 === 3 || newDate.getMonth() + 1 === 5 || 
        newDate.getMonth() + 1 === 7 || newDate.getMonth() + 1 === 8 || newDate.getMonth() + 1 === 10 || newDate.getMonth() + 1 === 12){
          this.setState({
            adminOptionDay: 31,
            isHiddenAdminDay: true,
            isHiddenAdminDayandMounth: true,
            typeDateDtosAdmin: [],
          });
        }     
        if(newDate.getMonth() + 1 === 4 || newDate.getMonth() + 1 === 6 || newDate.getMonth() + 1 === 9 || newDate.getMonth() + 1 === 11){
          this.setState({
            adminOptionDay: 30,
            isHiddenAdminDay: true,
            isHiddenAdminDayandMounth: true,
            typeDateDtosAdmin: [],
          });
        } 
        if (newDate.getMonth() + 1 === 2 && (newDate.getFullYear() === 2016 || newDate.getFullYear() === 2020 || newDate.getFullYear() === 2024 || newDate.getFullYear() === 2028)) {
          this.setState({
            adminOptionDay: 29,
            isHiddenAdminDay: true,
            isHiddenAdminDayandMounth: true,
            typeDateDtosAdmin: [],
          }); //max
        }else if(newDate.getMonth() + 1 === 2){
          this.setState({
            adminOptionDay: 28,
            isHiddenAdminDay: true,
            isHiddenAdminDayandMounth: true,
            typeDateDtosAdmin: [],
          }); 
        } 
      } else if (this.state.optionTypeAdmin === 2) {
        this.setState({
          adminOptionDay: null,
          isHiddenAdminDay: false,
          isHiddenAdminDayandMounth: true,
          typeDateDtosAdmin: [],
        });
      } else if (this.state.optionTypeAdmin === 3) {
        this.setState({
          adminOptionDay: null,
          isHiddenAdminDay: true,
          isHiddenAdminDayandMounth: false,
          typeDateDtosAdmin: [],
        });
      }
    } else {
      this.setState({
        optionTypeAdmin: 0,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        optionTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleOptionTypeCustomerChange(option) {
    if (option) {
      await this.setState({
        optionTypeCustomer: option.value,
        optionTypeCustomerError: "",
        alertErrorMessage: "",
      });

      if (this.state.optionTypeCustomer === 1) {
        let newDate = new Date();
        if(newDate.getMonth() + 1 === 1 || newDate.getMonth() + 1 === 3 || newDate.getMonth() + 1 === 5 || 
        newDate.getMonth() + 1 === 7 || newDate.getMonth() + 1 === 8 || newDate.getMonth() + 1 === 10 || newDate.getMonth() + 1 === 12){
          this.setState({
            customerOptionDay: 31,
            isHiddenCustomerDay: true,
            isHiddenCustomerDayandMounth: true,
            typeDateDtosCustomer: [],
          });
        }     
        if(newDate.getMonth() + 1 === 4 || newDate.getMonth() + 1 === 6 || newDate.getMonth() + 1 === 9 || newDate.getMonth() + 1 === 11){
          this.setState({
            customerOptionDay: 30,
            isHiddenCustomerDay: true,
            isHiddenCustomerDayandMounth: true,
            typeDateDtosCustomer: [],
          });
        } 
        if (newDate.getMonth() + 1 === 2 && (newDate.getFullYear() === 2016 || newDate.getFullYear() === 2020 || newDate.getFullYear() === 2024 || newDate.getFullYear() === 2028)) {
          this.setState({
            customerOptionDay: 29,
            isHiddenCustomerDay: true,
            isHiddenCustomerDayandMounth: true,
            typeDateDtosCustomer: [],
          }); //max
        }else if(newDate.getMonth() + 1 === 2){
          this.setState({
            customerOptionDay: 28,
            isHiddenCustomerDay: true,
            isHiddenCustomerDayandMounth: true,
            typeDateDtosCustomer: [],
          }); 
        } 
      } else if (this.state.optionTypeCustomer === 2) {
        this.setState({
          customerOptionDay: null,
          isHiddenCustomerDay: false,
          isHiddenCustomerDayandMounth: true,
          typeDateDtosCustomer: [],
        });
      } else if (this.state.optionTypeCustomer === 3) {
        this.setState({
          customerOptionDay: null,
          isHiddenCustomerDay: true,
          isHiddenCustomerDayandMounth: false,
          typeDateDtosCustomer: [],
        });
      }
    } else {
      this.setState({
        optionTypeCustomer: 0,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        optionTypeCustomerError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
      endDayError: "",
      optionTypeAdminError: "",
      optionTypeCustomerError: "",
      adminDayError: "",
      customerDayError: "",
      typeDateDtosAdminError: "",
      typeDateDtosCustomerError: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.endDaySelect === 0 || this.state.endDaySelect === null) {
      this.setState({
        endDaySelectError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    var controlType1 = true;
    var controlType2 = true;
    var controlType3 = true;
    var controlType4 = true;

    if (this.state.remindPerson !== 0) {
      if (this.state.remindPerson === 1) {
        if (
          this.state.optionTypeAdmin === 0 ||
          this.state.optionTypeAdmin === null
        ) {
          controlType1 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            optionTypeAdminError: CgnMessage.alertErrorMessage,
          });
        } else if (this.state.optionTypeAdmin === 2) {
          if (
            this.state.adminOptionDay === null ||
            this.state.adminOptionDay === 0
          ) {
            controlType1 = false;
            this.setState({
              adminOptionDayError: CgnMessage.textErrorMessage,
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          }
        } else if (this.state.optionTypeAdmin === 3) {
          if (
            this.state.typeDateDtosAdmin === null ||
            this.state.typeDateDtosAdmin.length === 0
          ) {
            controlType1 = false;
            this.setState({
              typeDateDtosAdminError: CgnMessage.textErrorMessage,
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          } else {
            this.state.typeDateDtosAdmin.forEach((element) => {
              if (element.adminDay === null || element.adminMounth === null) {
                controlType1 = false;
                this.setState({
                  alertErrorMessage: CgnMessage.alertErrorMessage,
                });
              }
            });
          }
        }

      } else if (this.state.remindPerson === 2) {
        if (
          this.state.optionTypeCustomer === 0 ||
          this.state.optionTypeCustomer === null
        ) {
          controlType2 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            optionTypeCustomerError: CgnMessage.alertErrorMessage,
          });
        } else if (this.state.optionTypeCustomer === 2) {
          if (
            this.state.customerOptionDay === null ||
            this.state.customerOptionDay === 0
          ) {
            controlType2 = false;
            this.setState({
              customerOptionDayError: CgnMessage.textErrorMessage,
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          }
        } else if (this.state.optionTypeCustomer === 3) {
          if (
            this.state.typeDateDtosCustomer === null ||
            this.state.typeDateDtosCustomer.length === 0
          ) {
            controlType2 = false;
            this.setState({
              typeDateDtosCustomerError: CgnMessage.textErrorMessage,
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          } else {
            this.state.typeDateDtosCustomer.forEach((element) => {
              if (
                element.customerDay === null ||
                element.customerMounth === null
              ) {
                controlType2 = false;
                this.setState({
                  alertErrorMessage: CgnMessage.alertErrorMessage,
                });
              }
            });
          }
        }

      } else if (this.state.remindPerson === 3) {
        if (this.state.optionTypeAdmin === null) {
          controlType3 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            optionTypeAdminError: CgnMessage.alertErrorMessage,
          });
        }
        if (this.state.optionTypeCustomer === null) {
          controlType3 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            optionTypeCustomerError: CgnMessage.alertErrorMessage,
          });
        }
        if (
          this.state.optionTypeCustomer === null &&
          this.state.optionTypeAdmin === null
        ) {
          controlType3 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            optionTypeCustomerError: CgnMessage.alertErrorMessage,
            optionTypeAdminError: CgnMessage.alertErrorMessage,
          });
        }
        if (this.state.optionTypeCustomer === 2) {
          if (
            this.state.customerOptionDay === null ||
            this.state.customerOptionDay === 0
          ) {
            controlType3 = false;
            this.setState({
              alertErrorMessage: CgnMessage.alertErrorMessage,
              customerOptionDayError: CgnMessage.alertErrorMessage,
            });
          }
        }
        if (this.state.optionTypeAdmin === 2) {
          if (
            this.state.adminOptionDay === null ||
            this.state.adminOptionDay === 0
          ) {
            controlType3 = false;
            this.setState({
              alertErrorMessage: CgnMessage.alertErrorMessage,
              adminOptionDayError: CgnMessage.alertErrorMessage,
            });
          }
        }
        if (
          this.state.optionTypeAdmin === 2 &&
          this.state.optionTypeCustomer === 2
        ) {
          if (
            (this.state.adminOptionDay === null ||
              this.state.adminOptionDay === 0) &&
            (this.state.customerOptionDay === null ||
              this.state.customerOptionDay === 0)
          ) {
            controlType3 = false;
            this.setState({
              alertErrorMessage: CgnMessage.alertErrorMessage,
              adminOptionDayError: CgnMessage.alertErrorMessage,
              customerOptionDayError: CgnMessage.alertErrorMessage,
            });
          }
        }
        if (this.state.optionTypeCustomer === 3) {
          if (
            this.state.typeDateDtosCustomer === null ||
            this.state.typeDateDtosCustomer.length === 0
          ) {
            controlType3 = false;
            this.setState({
              typeDateDtosCustomerError: CgnMessage.textErrorMessage,
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          } else {
            this.state.typeDateDtosCustomer.forEach((element) => {
              if (
                element.customerDay === null ||
                element.customerMounth === null
              ) {
                controlType3 = false;
                this.setState({
                  alertErrorMessage: CgnMessage.alertErrorMessage,
                });
              }
            });
          }
        }
        if (this.state.optionTypeAdmin === 3) {
          if (
            this.state.typeDateDtosAdmin === null ||
            this.state.typeDateDtosAdmin.length === 0
          ) {
            controlType3 = false;
            this.setState({
              typeDateDtosAdminError: CgnMessage.textErrorMessage,
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          } else {
            this.state.typeDateDtosAdmin.forEach((element) => {
              if (element.adminDay === null || element.adminMounth === null) {
                controlType3 = false;
                this.setState({
                  alertErrorMessage: CgnMessage.alertErrorMessage,
                });
              }
            });
          }
        }
        if (
          this.state.optionTypeAdmin === 3 &&
          this.state.optionTypeCustomer === 3
        ) {
          if (
            (this.state.typeDateDtosAdmin === null ||
              this.state.typeDateDtosAdmin.length === 0) &&
            (this.state.typeDateDtosCustomer === null ||
              this.state.typeDateDtosCustomer.length === 0)
          ) {
            controlType3 = false;
            this.setState({
              typeDateDtosAdminError: CgnMessage.textErrorMessage,
              typeDateDtosCustomerError: CgnMessage.textErrorMessage,
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          } else {
            this.state.typeDateDtosAdmin.forEach((element) => {
              if (element.adminDay === null || element.adminMounth === null) {
                controlType3 = false;
                this.setState({
                  alertErrorMessage: CgnMessage.alertErrorMessage,
                });
              }
            });

            this.state.typeDateDtosCustomer.forEach((element) => {
              if (
                element.customerDay === null ||
                element.customerMounth === null
              ) {
                controlType3 = false;
                this.setState({
                  alertErrorMessage: CgnMessage.alertErrorMessage,
                });
              }
            });
          }
        }
      }
    }

    if(this.state.endDaySelect === 1){
      if(this.state.endDay === 0 || this.state.endDay === null){
        controlType4 = false;
        this.setState({
          endDayError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    }

    if(this.state.endDaySelect === 2){
      if(this.state.typeEndDateDtos === null || this.state.typeEndDateDtos.length === 0){
        controlType4 = false;
        this.setState({
          typeEndDateDtosError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      else{
        this.state.typeEndDateDtos.forEach((element) => {
          if (element.day === null || element.mount === null) {
            controlType4 = false;
            this.setState({
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          }
        });
      }
    }

    if (
      this.state.name !== "" &&
      this.state.endDaySelect !== 0 &&
      controlType1 === true &&
      controlType2 === true &&
      controlType3 === true &&
      controlType4 === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      let newDate = new Date();

      var myObj = {
        name: this.state.name,
        endDay: parseInt(this.state.endDay),
        adminOptionDay: parseInt(this.state.adminOptionDay),
        customerOptionDay: parseInt(this.state.customerOptionDay),
        optionTypeCustomer: parseInt(this.state.optionTypeCustomer),
        optionTypeAdmin: parseInt(this.state.optionTypeAdmin),
        remindPerson: this.state.remindPerson,
        mounth: newDate.getMonth() + 1,
        year: newDate.getFullYear(),
        status: this.state.status,
        typeDateDtosCustomer: this.state.typeDateDtosCustomer,
        typeDateDtosAdmin: this.state.typeDateDtosAdmin,
        typeEndDateDtos: this.state.typeEndDateDtos,
      };

      var URLParam = "/types/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "tip-guncelle"
      ) {
        URLParam = "/types/update";
        myObj = {
        guid: this.state.guid,
        name: this.state.name,
        endDay: parseInt(this.state.endDay),
        adminOptionDay: parseInt(this.state.adminOptionDay),
        customerOptionDay: parseInt(this.state.customerOptionDay),
        optionTypeCustomer: parseInt(this.state.optionTypeCustomer),
        optionTypeAdmin: parseInt(this.state.optionTypeAdmin),
        remindPerson: this.state.remindPerson,
        mounth: newDate.getMonth() + 1,
        year: newDate.getFullYear(),
        status: this.state.status,
        typeDateDtosCustomer: this.state.typeDateDtosCustomer,
        typeDateDtosAdmin: this.state.typeDateDtosAdmin,
        typeEndDateDtos: this.state.typeEndDateDtos,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid && this.state.urlSplit[1] === "tip-guncelle") {
              window.location.href = "/tip-listele";
            }
            if (this.state.urlSplit[1] === "tip-ekle") {
              window.location.reload();
            }
            var modalAddedType = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedType });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "tip-ekle" ||
          this.state.urlSplit[1] === "tip-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Tip [*]"
                      value={this.state.name}
                      placeHolder="Lütfen tip girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>

                  <Col lg="6">
                  <CgnReactSelect
                      name="endDaySelect"
                      label="Son Tarih"
                      placeholder="Lütfen son tarih seçiniz..."
                      isMulti={false}
                      selectValue={this.state.endDaySelect}
                      isClearable={true}
                      options={endDateOptionStatuses}
                      onChange={(option) =>
                        this.handleEndDaySelectChange(option)
                      }
                      error={this.state.endDaySelectError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                  <CgnReactSelect
                      name="endDay"
                      label="Son Günü [*]"
                      placeholder="Lütfen son günü seçiniz..."
                      isMulti={false}
                      selectValue={this.state.endDay}
                      isClearable={true}
                      options={Day}
                      onChange={(option) =>
                        this.handleDayChange(option)
                      }
                      error={this.state.endDayError}
                      hidden={this.state.isHiddenEndDay}
                    />
                  </Col>
                </Row>
                {this.state.typeEndDateDtos.map((typeEndDateDtos, idx) => (
                  <Row className="mobileBorder p-0">
                    <Col lg="5" className="p-0">
                      <CgnReactSelect
                        name="day"
                        label="Gün [*]"
                        placeholder="Lütfen gün seçiniz..."
                        selectValue={typeEndDateDtos.day}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleEndDateDayChange(option, idx);
                        }}
                        options={Day}
                        error={typeEndDateDtos.dayError}
                        hidden={this.state.isHiddenDayandMount}
                      />
                    </Col>

                    <Col lg="6">
                      <CgnReactSelect
                        name="mount"
                        label="Ay [*]"
                        placeholder="Lütfen ay seçiniz..."
                        selectValue={typeEndDateDtos.mount}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleEndDateMountChange(option, idx);
                        }}
                        options={Mounth}
                        error={typeEndDateDtos.mountError}
                        hidden={this.state.isHiddenDayandMount}
                      />
                    </Col>
                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleEndDateRemove(idx)}
                        text={"Sil"}
                        style={{
                          display:
                            this.state.isHiddenDayandMount === true
                              ? "none"
                              : "",
                        }}
                      />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col lg="12">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton mb-2"
                      onClick={this.handleDayAndMountAdd}
                      text={"Gün ve Ay Ekle"}
                      style={{
                        display:
                          this.state.isHiddenDayandMount === true
                            ? "none"
                            : "",
                      }}
                    />
                  </Col>
                  {this.state.typeEndDateDtosError && (
                    <div className="invalid-feedback d-block">
                      {this.state.typeEndDateDtosError}
                    </div>
                  )}
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="remindPerson"
                      label="Kimlere Hatırlatılsın ?"
                      placeholder="Lütfen kişi seçiniz..."
                      isMulti={false}
                      selectValue={this.state.remindPerson}
                      isClearable={true}
                      options={remindPersonStatuses}
                      onChange={(option) =>
                        this.handlePersonSelectChange(option)
                      }
                      error={this.state.remindPersonError}
                    />
                  </Col>
                </Row>
                <Row className="p-0">
                  <Col lg="12">
                    <h2
                      style={{
                        display:
                          this.state.isHiddenAdmin === true ? "none" : "",
                      }}
                      className="mb-3"
                    >
                      Yönetici
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="optionTypeAdmin"
                      label="Hatırlatma Türü [*]"
                      placeholder="Lütfen hatırlatma türü seçiniz..."
                      isMulti={false}
                      selectValue={this.state.optionTypeAdmin}
                      isClearable={true}
                      options={remindOptionStatuses}
                      onChange={(option) =>
                        this.handleOptionTypeAdminChange(option)
                      }
                      error={this.state.optionTypeAdminError}
                      hidden={this.state.isHiddenAdmin}
                    />
                  </Col>
                </Row>           
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="adminOptionDay"
                      label="Hatırlatma Günü [*]"
                      placeholder="Lütfen hatırlatma günü seçiniz..."
                      isMulti={false}
                      selectValue={this.state.adminOptionDay}
                      isClearable={true}
                      options={Day}
                      onChange={(option) =>
                        this.handleOptionDayAdminSelectChange(option)
                      }
                      error={this.state.adminOptionDayError}
                      hidden={this.state.isHiddenAdminDay}
                    />
                  </Col>
                </Row>
                {this.state.typeDateDtosAdmin.map((typeDateDtosAdmin, idx) => (
                  <Row className="mobileBorder p-0">
                    <Col lg="5" className="p-0">
                      <CgnReactSelect
                        name="adminDay"
                        label="Gün [*]"
                        placeholder="Lütfen gün seçiniz..."
                        selectValue={typeDateDtosAdmin.adminDay}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleAdminDayChange(option, idx);
                        }}
                        options={Day}
                        error={typeDateDtosAdmin.adminDayError}
                        hidden={this.state.isHiddenAdminDayandMounth}
                      />
                    </Col>

                    <Col lg="6">
                      <CgnReactSelect
                        name="adminMounth"
                        label="Ay [*]"
                        placeholder="Lütfen ay seçiniz..."
                        selectValue={typeDateDtosAdmin.adminMounth}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleAdminMounthChange(option, idx);
                        }}
                        options={Mounth}
                        error={typeDateDtosAdmin.adminMounthError}
                        hidden={this.state.isHiddenAdminDayandMounth}
                      />
                    </Col>
                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleAdminRemove(idx)}
                        text={"Sil"}
                        style={{
                          display:
                            this.state.isHiddenAdminDayandMounth === true
                              ? "none"
                              : "",
                        }}
                      />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton mb-2"
                      onClick={this.handleAdminDayandMounthAdd}
                      text={"Gün ve Ay Ekle"}
                      style={{
                        display:
                          this.state.isHiddenAdminDayandMounth === true
                            ? "none"
                            : "",
                      }}
                    />
                  </Col>
                  {this.state.typeDateDtosAdminError && (
                    <div className="invalid-feedback d-block">
                      {this.state.typeDateDtosAdminError}
                    </div>
                  )}
                </Row>
                <Row className="p-0">
                  <Col lg="12">
                    <h2
                      style={{
                        display:
                          this.state.isHiddenCustomer === true ? "none" : "",
                      }}
                      className="mb-3"
                    >
                      Müşteri
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="optionTypeCustomer"
                      label="Hatırlatma Türü [*]"
                      placeholder="Lütfen hatırlatma türü seçiniz..."
                      isMulti={false}
                      selectValue={this.state.optionTypeCustomer}
                      isClearable={true}
                      options={remindOptionStatuses}
                      onChange={(option) =>
                        this.handleOptionTypeCustomerChange(option)
                      }
                      error={this.state.optionTypeCustomerError}
                      hidden={this.state.isHiddenCustomer}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="customerOptionDay"
                      label="Hatırlatma Günü [*]"
                      placeholder="Lütfen hatırlatma günü seçiniz..."
                      isMulti={false}
                      selectValue={this.state.customerOptionDay}
                      isClearable={true}
                      options={Day}
                      onChange={(option) =>
                        this.handleOptionDayCustomerSelectChange(option)
                      }
                      error={this.state.customerOptionDayError}
                      hidden={this.state.isHiddenCustomerDay}
                    />
                  </Col>
                </Row>
                {this.state.typeDateDtosCustomer.map(
                  (typeDateDtosCustomer, idx) => (
                    <Row className="mobileBorder p-0">
                      <Col lg="5" className="p-0">
                        <CgnReactSelect
                          name="customerDay"
                          label="Gün [*]"
                          placeholder="Lütfen gün seçiniz..."
                          selectValue={typeDateDtosCustomer.customerDay}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) => {
                            this.handleCustomerDayChange(option, idx);
                          }}
                          options={Day}
                          error={typeDateDtosCustomer.customerDayError}
                          hidden={this.state.isHiddenCustomerDayandMounth}
                        />
                      </Col>

                      <Col lg="6">
                        <CgnReactSelect
                          name="customerMounth"
                          label="Ay [*]"
                          placeholder="Lütfen ay seçiniz..."
                          selectValue={typeDateDtosCustomer.customerMounth}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) => {
                            this.handleCustomerMounthChange(option, idx);
                          }}
                          options={Mounth}
                          error={typeDateDtosCustomer.customerMounthError}
                          hidden={this.state.isHiddenCustomerDayandMounth}
                        />
                      </Col>
                      <Col lg="1" sm="3">
                        <CgnButton
                          className="dynamicRowDeleteButton"
                          type="button"
                          color="danger"
                          onClick={() => this.handleCustomerRemove(idx)}
                          text={"Sil"}
                          style={{
                            display:
                              this.state.isHiddenCustomerDayandMounth === true
                                ? "none"
                                : "",
                          }}
                        />
                      </Col>
                    </Row>
                  )
                )}
                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton mb-2"
                      onClick={this.handleCustomerDayandMounthAdd}
                      text={"Gün ve Ay Ekle"}
                      style={{
                        display:
                          this.state.isHiddenCustomerDayandMounth === true
                            ? "none"
                            : "",
                      }}
                    />
                  </Col>
                  {this.state.typeDateDtosCustomerError && (
                    <div className="invalid-feedback d-block">
                      {this.state.typeDateDtosCustomerError}
                    </div>
                  )}
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "tip-ekle" ||
              this.state.urlSplit[1] === "tip-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(TypeAddUpdate);
