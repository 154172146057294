import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  costTypeStatuses,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";

class TpuCostAddUpdate extends Component {
  state = {
    pageTitle: "TPU Maliyeti Ekle",
    pageTitleAdd: "TPU Maliyeti Ekle",
    pageTitleUpdate: "TPU Maliyeti Güncelle",
    pageAddLink: "tpu-maliyet-ekle",
    pageListLink: "tpu-maliyet-listele",
    breadcrumb: [{ text: "TPU Maliyeti", link: "#" }],
    isLoading: false,
    urlSplit: [],
    offerSieveGuid: "",
    offerSieveArr: [],
    offerSieveGuidError: "",
    sieveProductGuid: "",
    sieveProductArr: [],
    sieveEyeSize: "",
    sieveProductError: "",
    sieveSizeArr: [],
    sieveEyeSizeArr: [],
    sieveSizeMultiplier: 0,
    sieveEyeSizeMultiplier: 0,
    sieveSize: "",
    castingCostTotal: 0,
    transport: 0,
    transportRate: 0,
    transportTotal: 0,
    lama810: 0,
    lama810Rate: 0,
    lama810Total: 0,
    lama1020: 0,
    lama1020Rate: 0,
    lama1020Total: 0,
    profitRate: 0,
    pieceTotal: 0,
    m2Total: 0,
    total: 0,
    costType: "",
    costTypeError: "",
    raw1: 0,
    raw1Rate: 0,
    raw1Total: 0,
    raw2: 0,
    raw2Rate: 0,
    raw2Total: 0,
    raw3: 0,
    raw3Rate: 0,
    raw3Total: 0,
    raw4: 0,
    raw4Rate: 0,
    raw4Total: 0,
    raw5: 0,
    raw5Rate: 0,
    raw5Total: 0,
    raw6: 0,
    raw6Rate: 0,
    raw6Total: 0,
    raw7: 0,
    raw7Rate: 0,
    raw7Total: 0,
    raw8: 0,
    raw8Rate: 0,
    raw8Total: 0,
    sundry: 0,
    sundryRate: 0,
    sundryTotal: 0,
    lamaLaser: 0,
    lamaLaserRate: 0,
    lamaLaserTotal: 0,
    smallCost: 0,
    smallCostRate: 0,
    smallCostTotal: 0,
    bigCost: 0,
    bigCostRate: 0,
    bigCostTotal: 0,
    smallProduction: 0,
    smallProductionRate: 0,
    smallProductionTotal: 0,
    bigProduction: 0,
    bigProductionRate: 0,
    bigProductionTotal: 0,
    cost: 0,
    description: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2;
    await axios
      .get(URL + "/fixedTpus/getfirst", config)
      .then((response) => {
        if (response.data !== null) {
          this.setState({
            lama810Rate: parseFloat(response.data.lama810Rate),
            profitRate: parseFloat(response.data.profitRate),
            transportRate: parseFloat(response.data.transportRate),
            raw1Rate: parseFloat(response.data.raw1Rate),
            raw2Rate: parseFloat(response.data.raw2Rate),
            raw3Rate: parseFloat(response.data.raw3Rate),
            raw4Rate: parseFloat(response.data.raw4Rate),
            raw5Rate: parseFloat(response.data.raw5Rate),
            raw6Rate: parseFloat(response.data.raw6Rate),
            raw7Rate: parseFloat(response.data.raw7Rate),
            raw8Rate: parseFloat(response.data.raw8Rate),
            bigCostRate: parseFloat(response.data.bigCostRate),
            bigProductionRate: parseFloat(response.data.bigProductionRate),
            smallCostRate: parseFloat(response.data.smallCostRate),
            smallProductionRate: parseFloat(response.data.smallProductionRate),
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      await axios
      .get(URL + "/offersieves/getListdocumenttype/", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.offerNumber}-${item.offerDate}-${item.customerName}`,
          };
        });
        this.setState({
          offerSieveArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/sieveProducts/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}-${item.sieveSize}-${item.sieveEyeSize}`,
            sieveSize: item.sieveSize,
            sieveEyeSize: item.sieveEyeSize,
          };
        });
        this.setState({
          sieveProductArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .get(URL + "/tpuCosts/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              offerSieveGuid: response.data.offerSieveGuid,
              sieveProductGuid: response.data.sieveProductGuid,
              sieveSize: response.data.sieveSize,
              sieveEyeSize: response.data.sieveEyeSize,
              transport: parseFloat(response.data.transport),
              transportRate: parseFloat(response.data.transportRate),
              lama810: parseFloat(response.data.lama810),
              lama810Rate: parseFloat(response.data.lama810Rate),
              profitRate: parseFloat(response.data.profitRate),
              m2Total: parseFloat(response.data.m2Total),
              total: parseFloat(response.data.total),
              castingCostTotal: parseFloat(response.data.castingCostTotal),
              isLoading: false,
              lama810Total: parseFloat(response.data.lama810Total),
              transportTotal: parseFloat(response.data.transportTotal),
              raw1: parseFloat(response.data.raw1),
              raw1Rate: parseFloat(response.data.raw1Rate),
              raw1Total: parseFloat(response.data.raw1Total),
              raw2: parseFloat(response.data.raw2),
              raw2Rate: parseFloat(response.data.raw2Rate),
              raw2Total: parseFloat(response.data.raw2Total),
              raw3: parseFloat(response.data.raw3),
              raw3Rate: parseFloat(response.data.raw3Rate),
              raw3Total: parseFloat(response.data.raw3Total),
              raw4: parseFloat(response.data.raw4),
              raw4Rate: parseFloat(response.data.raw4Rate),
              raw4Total: parseFloat(response.data.raw4Total),
              raw5: parseFloat(response.data.raw5),
              raw5Rate: parseFloat(response.data.raw5Rate),
              raw5Total: parseFloat(response.data.raw5Total),
              raw6: parseFloat(response.data.raw6),
              raw6Rate: parseFloat(response.data.raw6Rate),
              raw6Total: parseFloat(response.data.raw6Total),
              raw7: parseFloat(response.data.raw7),
              raw7Rate: parseFloat(response.data.raw7Rate),
              raw7Total: parseFloat(response.data.raw7Total),
              raw8: parseFloat(response.data.raw8),
              raw8Rate: parseFloat(response.data.raw8Rate),
              raw8Total: parseFloat(response.data.raw8Total),
              lamaLaser: parseFloat(response.data.lamaLaser),
              lamaLaserRate: parseFloat(response.data.lamaLaserRate),
              lamaLaserTotal: parseFloat(response.data.lamaLaserTotal),
              smallProduction: parseFloat(response.data.smallProduction),
              smallProductionRate: parseFloat(
                response.data.smallProductionRate
              ),
              smallProductionTotal: parseFloat(
                response.data.smallProductionTotal
              ),
              bigProduction: parseFloat(response.data.bigProduction),
              bigProductionRate: parseFloat(response.data.bigProductionRate),
              bigProductionTotal: parseFloat(response.data.bigProductionTotal),
              smallCost: parseFloat(response.data.smallCost),
              smallCostRate: parseFloat(response.data.smallCostRate),
              smallCostTotal: parseFloat(response.data.smallCostTotal),
              bigCost: parseFloat(response.data.bigCost),
              bigCostRate: parseFloat(response.data.bigCostRate),
              bigCostTotal: parseFloat(response.data.bigCostTotal),
              cost: parseFloat(response.data.cost),
              sundry: parseFloat(response.data.sundry),
              sundryRate: parseFloat(response.data.sundryRate),
              sundryTotal: parseFloat(response.data.sundryTotal),
              description: response.data.description,
            });
          }

          if (response.data.sieveSize !== "") {
            this.state.sieveSizeArr = response.data.sieveSize.split("x");
            let sieveEpisode =
              (this.state.sieveSizeArr[0] / 1000) *
              (this.state.sieveSizeArr[1] / 1000);
            this.setState({
              sieveSizeMultiplier: sieveEpisode,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  async handleOfferSieveSelectChange(option) {
    if (option) {
      this.setState({
        offerSieveGuid: option.value,
        offerSieveGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerSieveGuid: null,
        offerSieveGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleSieveProductSelectChange(option) {
    if (option) {
      this.setState({
        sieveProductGuid: option.value,
        sieveSize: option.sieveSize,
        sieveEyeSize: option.sieveEyeSize,
        sieveProductError: "",
        alertErrorMessage: "",
      });

      if (option.sieveSize !== "") {
        const searchTxt = "x";
        this.state.sieveSizeArr = option.sieveSize
          .toLowerCase()
          .split(searchTxt.toLowerCase());
        //this.state.sieveSizeArr = option.sieveSize.split('x');
        let sieveEpisode =
          this.state.sieveSizeArr[0] / this.state.sieveSizeArr[1];
        await this.setState({
          sieveSizeMultiplier: sieveEpisode,
        });
      }
    } else {
      this.setState({
        sieveProductGuid: null,
        sieveSize: "",
        sieveEyeSize: "",
        sieveEyeSizeMultiplier: 0,
        sieveSizeMultiplier: 0,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        sieveProductError: CgnMessage.textErrorMessage,
      });
    }

    this.M2TotalCalculate(null);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
  };

  async handleRaw1Change(e) {
    if (e) {
      await this.setState({
        raw1: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw1: 0,
      });
    }

    this.Raw1Calculate(null);
  }

  async handleRaw1RateChange(e) {
    if (e) {
      await this.setState({
        raw1Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw1Rate: 0,
      });
    }

    this.Raw1Calculate(null);
  }

  Raw1Calculate = async (event) => {
    var raw1Total =
      parseFloat(this.state.raw1Rate) * parseFloat(this.state.raw1);
    await this.setState({
      raw1Total: raw1Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRaw2Change(e) {
    if (e) {
      await this.setState({
        raw2: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw2: 0,
      });
    }

    this.Raw2Calculate(null);
  }

  async handleRaw2RateChange(e) {
    if (e) {
      await this.setState({
        raw2Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw2Rate: 0,
      });
    }

    this.Raw2Calculate(null);
  }

  Raw2Calculate = async (event) => {
    var raw2Total =
      parseFloat(this.state.raw2Rate) * parseFloat(this.state.raw2);
    await this.setState({
      raw2Total: raw2Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRaw3Change(e) {
    if (e) {
      await this.setState({
        raw3: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw3: 0,
      });
    }

    this.Raw3Calculate(null);
  }

  async handleRaw3RateChange(e) {
    if (e) {
      await this.setState({
        raw3Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw3Rate: 0,
      });
    }

    this.Raw3Calculate(null);
  }

  Raw3Calculate = async (event) => {
    var raw3Total =
      parseFloat(this.state.raw3Rate) * parseFloat(this.state.raw3);
    await this.setState({
      raw3Total: raw3Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRaw4Change(e) {
    if (e) {
      await this.setState({
        raw4: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw4: 0,
      });
    }

    this.Raw4Calculate(null);
  }

  async handleRaw4RateChange(e) {
    if (e) {
      await this.setState({
        raw4Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw4Rate: 0,
      });
    }

    this.Raw4Calculate(null);
  }

  Raw4Calculate = async (event) => {
    var raw4Total =
      parseFloat(this.state.raw4Rate) * parseFloat(this.state.raw4);
    await this.setState({
      raw4Total: raw4Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRaw5Change(e) {
    if (e) {
      await this.setState({
        raw5: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw5: 0,
      });
    }

    this.Raw5Calculate(null);
  }

  async handleRaw5RateChange(e) {
    if (e) {
      await this.setState({
        raw5Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw5Rate: 0,
      });
    }

    this.Raw5Calculate(null);
  }

  Raw5Calculate = async (event) => {
    var raw5Total =
      parseFloat(this.state.raw5Rate) * parseFloat(this.state.raw5);
    await this.setState({
      raw5Total: raw5Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRaw6Change(e) {
    if (e) {
      await this.setState({
        raw6: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw6: 0,
      });
    }

    this.Raw6Calculate(null);
  }

  async handleRaw6RateChange(e) {
    if (e) {
      await this.setState({
        raw6Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw6Rate: 0,
      });
    }

    this.Raw6Calculate(null);
  }

  Raw6Calculate = async (event) => {
    var raw6Total =
      parseFloat(this.state.raw6Rate) * parseFloat(this.state.raw6);
    await this.setState({
      raw6Total: raw6Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRaw7Change(e) {
    if (e) {
      await this.setState({
        raw7: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw7: 0,
      });
    }

    this.Raw7Calculate(null);
  }

  async handleRaw7RateChange(e) {
    if (e) {
      await this.setState({
        raw7Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw7Rate: 0,
      });
    }

    this.Raw7Calculate(null);
  }

  Raw7Calculate = async (event) => {
    var raw7Total =
      parseFloat(this.state.raw7Rate) * parseFloat(this.state.raw7);
    await this.setState({
      raw7Total: raw7Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleRaw8Change(e) {
    if (e) {
      await this.setState({
        raw8: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw8: 0,
      });
    }

    this.Raw8Calculate(null);
  }

  async handleRaw8RateChange(e) {
    if (e) {
      await this.setState({
        raw8Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        raw8Rate: 0,
      });
    }

    this.Raw8Calculate(null);
  }

  Raw8Calculate = async (event) => {
    var raw8Total =
      parseFloat(this.state.raw8Rate) * parseFloat(this.state.raw8);
    await this.setState({
      raw8Total: raw8Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleLama810Change(e) {
    if (e) {
      await this.setState({
        lama810: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama810: 0,
      });
    }

    this.Lama810PriceCalculate(null);
  }

  async handleLama810RateChange(e) {
    if (e) {
      await this.setState({
        lama810Rate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lama810Rate: 0,
      });
    }

    this.Lama810PriceCalculate(null);
  }

  Lama810PriceCalculate = async (event) => {
    var lama810Total =
      parseFloat(this.state.lama810Rate) * parseFloat(this.state.lama810);
    await this.setState({
      lama810Total: lama810Total.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleTransportChange(e) {
    if (e) {
      await this.setState({
        transport: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        transport: 0,
      });
    }

    this.TransportPriceCalculate(null);
  }

  async handleTransportRateChange(e) {
    if (e) {
      await this.setState({
        transportRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        transportRate: 0,
      });
    }

    this.TransportPriceCalculate(null);
  }

  TransportPriceCalculate = async (event) => {
    var transportTotal =
      parseFloat(this.state.transportRate) * parseFloat(this.state.transport);
    await this.setState({
      transportTotal: transportTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleSundryChange(e) {
    if (e) {
      await this.setState({
        sundry: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sundry: 0,
      });
    }

    this.SundryPriceCalculate(null);
  }

  async handleSundryRateChange(e) {
    if (e) {
      await this.setState({
        sundryRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        sundryRate: 0,
      });
    }

    this.SundryPriceCalculate(null);
  }

  SundryPriceCalculate = async (event) => {
    var sundryTotal =
      parseFloat(this.state.sundryRate) * parseFloat(this.state.sundry);
    await this.setState({
      sundryTotal: sundryTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleLamaLaserChange(e) {
    if (e) {
      await this.setState({
        lamaLaser: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lamaLaser: 0,
      });
    }

    this.LamaLaserPriceCalculate(null);
  }

  async handleLamaLaserRateChange(e) {
    if (e) {
      await this.setState({
        lamaLaserRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        lamaLaserRate: 0,
      });
    }

    this.LamaLaserPriceCalculate(null);
  }

  LamaLaserPriceCalculate = async (event) => {
    var lamaLaserTotal =
      parseFloat(this.state.lamaLaserRate) * parseFloat(this.state.lamaLaser);
    await this.setState({
      lamaLaserTotal: lamaLaserTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleSmallProductionChange(e) {
    if (e) {
      await this.setState({
        smallProduction: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        smallProduction: 0,
      });
    }

    this.SmallProductionPriceCalculate(null);
  }

  async handleSmallProductionRateChange(e) {
    if (e) {
      await this.setState({
        smallProductionRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        smallProductionRate: 0,
      });
    }

    this.SmallProductionPriceCalculate(null);
  }

  SmallProductionPriceCalculate = async (event) => {
    var smallProductionTotal =
      parseFloat(this.state.smallProductionRate) *
      parseFloat(this.state.smallProduction);
    await this.setState({
      smallProductionTotal: smallProductionTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleBigProductionChange(e) {
    if (e) {
      await this.setState({
        bigProduction: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bigProduction: 0,
      });
    }

    this.BigProductionPriceCalculate(null);
  }

  async handleBigProductionRateChange(e) {
    if (e) {
      await this.setState({
        bigProductionRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bigProductionRate: 0,
      });
    }

    this.BigProductionPriceCalculate(null);
  }

  BigProductionPriceCalculate = async (event) => {
    var bigProductionTotal =
      parseFloat(this.state.bigProductionRate) *
      parseFloat(this.state.bigProduction);
    await this.setState({
      bigProductionTotal: bigProductionTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleSmallCostChange(e) {
    if (e) {
      await this.setState({
        smallCost: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        smallCost: 0,
      });
    }

    this.SmallCostPriceCalculate(null);
  }

  async handleSmallCostRateChange(e) {
    if (e) {
      await this.setState({
        smallCostRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        smallCostRate: 0,
      });
    }

    this.SmallCostPriceCalculate(null);
  }

  SmallCostPriceCalculate = async (event) => {
    var smallCostTotal =
      parseFloat(this.state.smallCostRate) * parseFloat(this.state.smallCost);
    await this.setState({
      smallCostTotal: smallCostTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  async handleBigCostChange(e) {
    if (e) {
      await this.setState({
        bigCost: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bigCost: 0,
      });
    }

    this.BigCostPriceCalculate(null);
  }

  async handleBigCostRateChange(e) {
    if (e) {
      await this.setState({
        bigCostRate: parseFloat(e.target.value),
      });
    } else {
      await this.setState({
        bigCostRate: 0,
      });
    }

    this.BigCostPriceCalculate(null);
  }

  BigCostPriceCalculate = async (event) => {
    var bigCostTotal =
      parseFloat(this.state.bigCostRate) * parseFloat(this.state.bigCost);
    await this.setState({
      bigCostTotal: bigCostTotal.toFixed(2),
    });

    this.TotalCalculate(null);
  };

  TotalCalculate = async (event) => {
    if (this.state.raw1Total === "NaN") {
      this.state.raw1Total = 0;
    }
    if (this.state.raw2Total === "NaN") {
      this.state.raw2Total = 0;
    }
    if (this.state.raw3Total === "NaN") {
      this.state.raw3Total = 0;
    }
    if (this.state.raw4Total === "NaN") {
      this.state.raw4Total = 0;
    }
    if (this.state.raw5Total === "NaN") {
      this.state.raw5Total = 0;
    }
    if (this.state.raw6Total === "NaN") {
      this.state.raw6Total = 0;
    }
    if (this.state.raw7Total === "NaN") {
      this.state.raw7Total = 0;
    }
    if (this.state.raw8Total === "NaN") {
      this.state.raw8Total = 0;
    }
    if (this.state.lama810Total === "NaN") {
      this.state.lama810Total = 0;
    }
    if (this.state.transportTotal === "NaN") {
      this.state.transportTotal = 0;
    }
    if (this.state.sundryTotal === "NaN") {
      this.state.sundryTotal = 0;
    }
    if (this.state.lamaLaserTotal === "NaN") {
      this.state.lamaLaserTotal = 0;
    }
    if (this.state.smallProductionTotal === "NaN") {
      this.state.smallProductionTotal = 0;
    }
    if (this.state.bigProductionTotal === "NaN") {
      this.state.bigProductionTotal = 0;
    }
    if (this.state.smallCostTotal === "NaN") {
      this.state.smallCostTotal = 0;
    }
    if (this.state.bigCostTotal === "NaN") {
      this.state.bigCostTotal = 0;
    }
    if (this.state.cost === "NaN") {
      this.state.cost = 0;
    }

    let total =
      parseFloat(this.state.raw1Total) +
      parseFloat(this.state.raw2Total) +
      parseFloat(this.state.raw3Total) +
      parseFloat(this.state.raw4Total) +
      parseFloat(this.state.raw5Total) +
      parseFloat(this.state.lama810Total) +
      parseFloat(this.state.raw6Total) +
      parseFloat(this.state.raw7Total) +
      parseFloat(this.state.raw8Total) +
      parseFloat(this.state.transportTotal) +
      parseFloat(this.state.sundryTotal) +
      parseFloat(this.state.lamaLaserTotal) +
      parseFloat(this.state.smallProductionTotal) +
      parseFloat(this.state.bigProductionTotal) +
      parseFloat(this.state.smallCostTotal) +
      parseFloat(this.state.bigCostTotal) +
      parseFloat(this.state.cost);

    await this.setState({
      total: total.toFixed(2),
    });

    this.ProfitRateTotalCalculate(null);
    this.M2TotalCalculate(null);
  };

  M2TotalCalculate = async (event) => {
    let sieveTotal =
      parseFloat(this.state.castingCostTotal) /
      parseFloat(this.state.sieveSizeMultiplier);

    await this.setState({
      m2Total: sieveTotal.toFixed(2),
    });
  };

  async handleProfitRateChange(e) {
    await this.setState({
      profitRate: parseFloat(e.target.value),
      alertErrorMessage: "",
    });

    this.ProfitRateTotalCalculate(null);
  }

  async handleCostChange(e) {
    await this.setState({
      cost: parseFloat(e.target.value),
      alertErrorMessage: "",
    });

    this.TotalCalculate(null);
  }

  ProfitRateTotalCalculate = async (event) => {
    let castingTotal =
      (parseFloat(this.state.profitRate) / 100) * parseFloat(this.state.total);
    castingTotal = castingTotal + parseFloat(this.state.total);

    this.setState({
      castingCostTotal: castingTotal.toFixed(2),
    });

    this.M2TotalCalculate(null);
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleCostTypeSelectChange(option) {
    if (option) {
      this.setState({
        costType: option.value,
        costTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        costType: "",
        costTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });

    if (this.state.total === 0 || this.state.total === "NaN") {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.offerSieveGuid === "") {
      this.setState({
        offerSieveGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.sieveProductGuid === "") {
      this.setState({
        sieveProductError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.m2Total === 0 || this.state.m2Total === "NaN") {
      this.setState({
        m2TotalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.profitRate === 0 || this.state.profitRate === "NaN") {
      this.setState({
        profitRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.castingCostTotal === 0 ||
      this.state.castingCostTotal === "NaN"
    ) {
      this.setState({
        castingCostTotalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    if (
      this.state.offerSieveGuid !== "" &&
      this.state.sieveProductGuid !== "" &&
      this.state.total !== 0 &&
      this.state.m2Total !== 0 &&
      this.state.castingCostTotal !== 0 &&
      this.state.profitRate !== 0
    ) {
      this.setState({
        isLoading: true,
      });

      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        offerSieveGuid: this.state.offerSieveGuid,
        sieveProductGuid: this.state.sieveProductGuid,
        sieveEyeSize: this.state.sieveEyeSize,
        sieveSize: this.state.sieveSize,
        raw1: this.state.raw1 === "NaN" ? 0 : parseFloat(this.state.raw1),
        raw1Rate:
          this.state.raw1Rate === "NaN" ? 0 : parseFloat(this.state.raw1Rate),
        raw1Total:
          this.state.raw1Total === "NaN" ? 0 : parseFloat(this.state.raw1Total),
        raw2: this.state.raw2 === "NaN" ? 0 : parseFloat(this.state.raw2),
        raw2Rate:
          this.state.raw2Rate === "NaN" ? 0 : parseFloat(this.state.raw2Rate),
        raw2Total:
          this.state.raw2Total === "NaN" ? 0 : parseFloat(this.state.raw2Total),
        raw3: this.state.raw3 === "NaN" ? 0 : parseFloat(this.state.raw3),
        raw3Rate:
          this.state.raw3Rate === "NaN" ? 0 : parseFloat(this.state.raw3Rate),
        raw3Total:
          this.state.raw3Total === "NaN" ? 0 : parseFloat(this.state.raw3Total),
        raw4: this.state.raw4 === "NaN" ? 0 : parseFloat(this.state.raw4),
        raw4Rate:
          this.state.raw4Rate === "NaN" ? 0 : parseFloat(this.state.raw4Rate),
        raw4Total:
          this.state.raw4Total === "NaN" ? 0 : parseFloat(this.state.raw4Total),
        raw5: this.state.raw5 === "NaN" ? 0 : parseFloat(this.state.raw5),
        raw5Rate:
          this.state.raw5Rate === "NaN" ? 0 : parseFloat(this.state.raw5Rate),
        raw5Total:
          this.state.raw5Total === "NaN" ? 0 : parseFloat(this.state.raw5Total),
        raw6: this.state.raw6 === "NaN" ? 0 : parseFloat(this.state.raw6),
        raw6Rate:
          this.state.raw6Rate === "NaN" ? 0 : parseFloat(this.state.raw6Rate),
        raw6Total:
          this.state.raw6Total === "NaN" ? 0 : parseFloat(this.state.raw6Total),
        raw7: this.state.raw7 === "NaN" ? 0 : parseFloat(this.state.raw7),
        raw7Rate:
          this.state.raw7Rate === "NaN" ? 0 : parseFloat(this.state.raw7Rate),
        raw7Total:
          this.state.raw7Total === "NaN" ? 0 : parseFloat(this.state.raw7Total),
        raw8: this.state.raw8 === "NaN" ? 0 : parseFloat(this.state.raw8),
        raw8Rate:
          this.state.raw8Rate === "NaN" ? 0 : parseFloat(this.state.raw8Rate),
        raw8Total:
          this.state.raw8Total === "NaN" ? 0 : parseFloat(this.state.raw8Total),
        lama810:
          this.state.lama810 === "NaN" ? 0 : parseFloat(this.state.lama810),
        lama810Rate:
          this.state.lama810Rate === "NaN"
            ? 0
            : parseFloat(this.state.lama810Rate),
        lama810Total:
          this.state.lama810Total === "NaN"
            ? 0
            : parseFloat(this.state.lama810Total),
        lamaLaser:
          this.state.lamaLaser === "NaN" ? 0 : parseFloat(this.state.lamaLaser),
        lamaLaserRate:
          this.state.lamaLaserRate === "NaN"
            ? 0
            : parseFloat(this.state.lamaLaserRate),
        lamaLaserTotal:
          this.state.lamaLaserTotal === "NaN"
            ? 0
            : parseFloat(this.state.lamaLaserTotal),
        transport:
          this.state.transport === "NaN" ? 0 : parseFloat(this.state.transport),
        transportRate:
          this.state.transportRate === "NaN"
            ? 0
            : parseFloat(this.state.transportRate),
        transportTotal:
          this.state.transportTotal === "NaN"
            ? 0
            : parseFloat(this.state.transportTotal),
        sundry: this.state.sundry === "NaN" ? 0 : parseFloat(this.state.sundry),
        sundryRate:
          this.state.sundryRate === "NaN"
            ? 0
            : parseFloat(this.state.sundryRate),
        sundryTotal:
          this.state.sundryTotal === "NaN"
            ? 0
            : parseFloat(this.state.sundryTotal),
        smallProduction:
          this.state.smallProduction === "NaN"
            ? 0
            : parseFloat(this.state.smallProduction),
        smallProductionRate:
          this.state.smallProductionRate === "NaN"
            ? 0
            : parseFloat(this.state.smallProductionRate),
        smallProductionTotal:
          this.state.smallProductionTotal === "NaN"
            ? 0
            : parseFloat(this.state.smallProductionTotal),
        bigProduction:
          this.state.bigProduction === "NaN"
            ? 0
            : parseFloat(this.state.bigProduction),
        bigProductionRate:
          this.state.bigProductionRate === "NaN"
            ? 0
            : parseFloat(this.state.bigProductionRate),
        bigProductionTotal:
          this.state.bigProductionTotal === "NaN"
            ? 0
            : parseFloat(this.state.bigProductionTotal),
        smallCost:
          this.state.smallCost === "NaN" ? 0 : parseFloat(this.state.smallCost),
        smallCostRate:
          this.state.smallCostRate === "NaN"
            ? 0
            : parseFloat(this.state.smallCostRate),
        smallCostTotal:
          this.state.smallCostTotal === "NaN"
            ? 0
            : parseFloat(this.state.smallCostTotal),
        bigCost:
          this.state.bigCost === "NaN" ? 0 : parseFloat(this.state.bigCost),
        bigCostRate:
          this.state.bigCostRate === "NaN"
            ? 0
            : parseFloat(this.state.bigCostRate),
        bigCostTotal:
          this.state.bigCostTotal === "NaN"
            ? 0
            : parseFloat(this.state.bigCostTotal),
        cost: this.state.cost === "NaN" ? 0 : parseFloat(this.state.cost),
        castingCostTotal:
          this.state.castingCostTotal === "NaN"
            ? 0
            : parseFloat(this.state.castingCostTotal),
        profitRate:
          this.state.profitRate === "NaN"
            ? 0
            : parseFloat(this.state.profitRate),
        total: this.state.total === "NaN" ? 0 : parseFloat(this.state.total),
        m2Total:
          this.state.m2Total === "NaN" ? 0 : parseFloat(this.state.m2Total),
        status: true,
        description: this.state.description,
      };

      var URLParam = "/tpuCosts/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "tpu-maliyet-guncelle"
      ) {
        URLParam = "/tpuCosts/update";
        myObj = {
          guid: this.state.guid,
          offerSieveGuid: this.state.offerSieveGuid,
          sieveProductGuid: this.state.sieveProductGuid,
          sieveEyeSize: this.state.sieveEyeSize,
          sieveSize: this.state.sieveSize,
          raw1: this.state.raw1 === "NaN" ? 0 : parseFloat(this.state.raw1),
          raw1Rate:
            this.state.raw1Rate === "NaN" ? 0 : parseFloat(this.state.raw1Rate),
          raw1Total:
            this.state.raw1Total === "NaN"
              ? 0
              : parseFloat(this.state.raw1Total),
          raw2: this.state.raw2 === "NaN" ? 0 : parseFloat(this.state.raw2),
          raw2Rate:
            this.state.raw2Rate === "NaN" ? 0 : parseFloat(this.state.raw2Rate),
          raw2Total:
            this.state.raw2Total === "NaN"
              ? 0
              : parseFloat(this.state.raw2Total),
          raw3: this.state.raw3 === "NaN" ? 0 : parseFloat(this.state.raw3),
          raw3Rate:
            this.state.raw3Rate === "NaN" ? 0 : parseFloat(this.state.raw3Rate),
          raw3Total:
            this.state.raw3Total === "NaN"
              ? 0
              : parseFloat(this.state.raw3Total),
          raw4: this.state.raw4 === "NaN" ? 0 : parseFloat(this.state.raw4),
          raw4Rate:
            this.state.raw4Rate === "NaN" ? 0 : parseFloat(this.state.raw4Rate),
          raw4Total:
            this.state.raw4Total === "NaN"
              ? 0
              : parseFloat(this.state.raw4Total),
          raw5: this.state.raw5 === "NaN" ? 0 : parseFloat(this.state.raw5),
          raw5Rate:
            this.state.raw5Rate === "NaN" ? 0 : parseFloat(this.state.raw5Rate),
          raw5Total:
            this.state.raw5Total === "NaN"
              ? 0
              : parseFloat(this.state.raw5Total),
          raw6: this.state.raw6 === "NaN" ? 0 : parseFloat(this.state.raw6),
          raw6Rate:
            this.state.raw6Rate === "NaN" ? 0 : parseFloat(this.state.raw6Rate),
          raw6Total:
            this.state.raw6Total === "NaN"
              ? 0
              : parseFloat(this.state.raw6Total),
          raw7: this.state.raw7 === "NaN" ? 0 : parseFloat(this.state.raw7),
          raw7Rate:
            this.state.raw7Rate === "NaN" ? 0 : parseFloat(this.state.raw7Rate),
          raw7Total:
            this.state.raw7Total === "NaN"
              ? 0
              : parseFloat(this.state.raw7Total),
          raw8: this.state.raw8 === "NaN" ? 0 : parseFloat(this.state.raw8),
          raw8Rate:
            this.state.raw8Rate === "NaN" ? 0 : parseFloat(this.state.raw8Rate),
          raw8Total:
            this.state.raw8Total === "NaN"
              ? 0
              : parseFloat(this.state.raw8Total),
          lama810:
            this.state.lama810 === "NaN" ? 0 : parseFloat(this.state.lama810),
          lama810Rate:
            this.state.lama810Rate === "NaN"
              ? 0
              : parseFloat(this.state.lama810Rate),
          lama810Total:
            this.state.lama810Total === "NaN"
              ? 0
              : parseFloat(this.state.lama810Total),
          lamaLaser:
            this.state.lamaLaser === "NaN"
              ? 0
              : parseFloat(this.state.lamaLaser),
          lamaLaserRate:
            this.state.lamaLaserRate === "NaN"
              ? 0
              : parseFloat(this.state.lamaLaserRate),
          lamaLaserTotal:
            this.state.lamaLaserTotal === "NaN"
              ? 0
              : parseFloat(this.state.lamaLaserTotal),
          transport:
            this.state.transport === "NaN"
              ? 0
              : parseFloat(this.state.transport),
          transportRate:
            this.state.transportRate === "NaN"
              ? 0
              : parseFloat(this.state.transportRate),
          transportTotal:
            this.state.transportTotal === "NaN"
              ? 0
              : parseFloat(this.state.transportTotal),
          sundry:
            this.state.sundry === "NaN" ? 0 : parseFloat(this.state.sundry),
          sundryRate:
            this.state.sundryRate === "NaN"
              ? 0
              : parseFloat(this.state.sundryRate),
          sundryTotal:
            this.state.sundryTotal === "NaN"
              ? 0
              : parseFloat(this.state.sundryTotal),
          smallProduction:
            this.state.smallProduction === "NaN"
              ? 0
              : parseFloat(this.state.smallProduction),
          smallProductionRate:
            this.state.smallProductionRate === "NaN"
              ? 0
              : parseFloat(this.state.smallProductionRate),
          smallProductionTotal:
            this.state.smallProductionTotal === "NaN"
              ? 0
              : parseFloat(this.state.smallProductionTotal),
          bigProduction:
            this.state.bigProduction === "NaN"
              ? 0
              : parseFloat(this.state.bigProduction),
          bigProductionRate:
            this.state.bigProductionRate === "NaN"
              ? 0
              : parseFloat(this.state.bigProductionRate),
          bigProductionTotal:
            this.state.bigProductionTotal === "NaN"
              ? 0
              : parseFloat(this.state.bigProductionTotal),
          smallCost:
            this.state.smallCost === "NaN"
              ? 0
              : parseFloat(this.state.smallCost),
          smallCostRate:
            this.state.smallCostRate === "NaN"
              ? 0
              : parseFloat(this.state.smallCostRate),
          smallCostTotal:
            this.state.smallCostTotal === "NaN"
              ? 0
              : parseFloat(this.state.smallCostTotal),
          bigCost:
            this.state.bigCost === "NaN" ? 0 : parseFloat(this.state.bigCost),
          bigCostRate:
            this.state.bigCostRate === "NaN"
              ? 0
              : parseFloat(this.state.bigCostRate),
          bigCostTotal:
            this.state.bigCostTotal === "NaN"
              ? 0
              : parseFloat(this.state.bigCostTotal),
          cost: this.state.cost === "NaN" ? 0 : parseFloat(this.state.cost),
          castingCostTotal:
            this.state.castingCostTotal === "NaN"
              ? 0
              : parseFloat(this.state.castingCostTotal),
          profitRate:
            this.state.profitRate === "NaN"
              ? 0
              : parseFloat(this.state.profitRate),
          total: this.state.total === "NaN" ? 0 : parseFloat(this.state.total),
          m2Total:
            this.state.m2Total === "NaN" ? 0 : parseFloat(this.state.m2Total),
          status: true,
          description: this.state.description,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "tpu-maliyet-guncelle"
            ) {
              window.location.href = "/tpu-maliyet-listele";
            }
            if (this.state.urlSplit[1] === "tpu-maliyet-ekle") {
              window.location.href = "/tpu-maliyet-listele";
            }
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        {(this.state.urlSplit[1] === "tpu-maliyet-ekle" ||
          this.state.urlSplit[1] === "tpu-maliyet-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="offerSieveGuid"
                      label="Teklif [*]"
                      placeholder="Lütfen teklif seçin..."
                      isMulti={false}
                      isClearable={true}
                      selectValue={this.state.offerSieveGuid}
                      options={this.state.offerSieveArr}
                      onChange={(option) =>
                        this.handleOfferSieveSelectChange(option)
                      }
                      error={this.state.offerSieveGuidError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="sieveProductGuid"
                      label="Malzeme [*]"
                      placeholder="Lütfen malzeme seçin..."
                      isMulti={false}
                      isClearable={true}
                      selectValue={this.state.sieveProductGuid}
                      options={this.state.sieveProductArr}
                      onChange={(option) =>
                        this.handleSieveProductSelectChange(option)
                      }
                      error={this.state.sieveProductError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="sieveSize"
                      label="Elek Boyutu"
                      value={this.state.sieveSize}
                      onChange={this.handleChange}
                      placeHolder="Lütfen elek boyutu girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sieveSizeError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="sieveEyeSize"
                      label="Elek Göz Boyutu"
                      value={this.state.sieveEyeSize}
                      onChange={this.handleChange}
                      placeHolder="Lütfen elek göz boyutu girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sieveEyeSizeError}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw1"
                      label="Hammadde-1"
                      value={this.state.raw1}
                      onChange={(e) => this.handleRaw1Change(e)}
                      placeHolder="Lütfen hammadde-1 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw1Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw1Rate"
                      label="Hammadde-1 Birimi (Euro)"
                      value={this.state.raw1Rate}
                      onChange={(e) => this.handleRaw1RateChange(e)}
                      placeHolder="Lütfen hammadde-1 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw1RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw1Total"
                      label="Hammadde-1 Toplam"
                      value={this.state.raw1Total}
                      onChange={this.Raw1Calculate}
                      placeHolder="Toplam hammadde-1 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw1TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw2"
                      label="Hammadde-2"
                      value={this.state.raw2}
                      onChange={(e) => this.handleRaw2Change(e)}
                      placeHolder="Lütfen hammadde-2 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw2Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw2Rate"
                      label="Hammadde-2 Birimi (Euro)"
                      value={this.state.raw2Rate}
                      onChange={(e) => this.handleRaw2RateChange(e)}
                      placeHolder="Lütfen hammadde-2 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw2RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw2Total"
                      label="Hammadde-2 Toplam"
                      value={this.state.raw2Total}
                      onChange={this.Raw2Calculate}
                      placeHolder="Toplam hammadde-2 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw2TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw3"
                      label="Hammadde-3"
                      value={this.state.raw3}
                      onChange={(e) => this.handleRaw3Change(e)}
                      placeHolder="Lütfen hammadde-3 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw3Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw3Rate"
                      label="Hammadde-3 Birimi (Euro)"
                      value={this.state.raw3Rate}
                      onChange={(e) => this.handleRaw3RateChange(e)}
                      placeHolder="Lütfen hammadde-3 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw3RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw3Total"
                      label="Hammadde-3 Toplam"
                      value={this.state.raw3Total}
                      onChange={this.Raw3Calculate}
                      placeHolder="Toplam hammadde-3 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw3TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw4"
                      label="Hammadde-4"
                      value={this.state.raw4}
                      onChange={(e) => this.handleRaw4Change(e)}
                      placeHolder="Lütfen hammadde-4 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw4Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw4Rate"
                      label="Hammadde-4 Birimi (Euro)"
                      value={this.state.raw4Rate}
                      onChange={(e) => this.handleRaw4RateChange(e)}
                      placeHolder="Lütfen hammadde-4 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw4RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw4Total"
                      label="Hammadde-4 Toplam"
                      value={this.state.raw4Total}
                      onChange={this.Raw4Calculate}
                      placeHolder="Toplam hammadde-4 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw4TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw5"
                      label="Hammadde-5"
                      value={this.state.raw5}
                      onChange={(e) => this.handleRaw5Change(e)}
                      placeHolder="Lütfen hammadde-5 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw5Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw5Rate"
                      label="Hammadde-5 Birimi (Euro)"
                      value={this.state.raw5Rate}
                      onChange={(e) => this.handleRaw5RateChange(e)}
                      placeHolder="Lütfen hammadde-5 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw5RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw5Total"
                      label="Hammadde-5 Toplam"
                      value={this.state.raw5Total}
                      onChange={this.Raw5Calculate}
                      placeHolder="Toplam hammadde-5 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw5TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw6"
                      label="Hammadde-6"
                      value={this.state.raw6}
                      onChange={(e) => this.handleRaw6Change(e)}
                      placeHolder="Lütfen hammadde-6 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw6Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw6Rate"
                      label="Hammadde-6 Birimi (Euro)"
                      value={this.state.raw6Rate}
                      onChange={(e) => this.handleRaw6RateChange(e)}
                      placeHolder="Lütfen hammadde-6 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw6RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw6Total"
                      label="Hammadde-6 Toplam"
                      value={this.state.raw6Total}
                      onChange={this.Raw6Calculate}
                      placeHolder="Toplam hammadde-6 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw6TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw7"
                      label="Hammadde-7"
                      value={this.state.raw7}
                      onChange={(e) => this.handleRaw7Change(e)}
                      placeHolder="Lütfen hammadde-7 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw7Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw7Rate"
                      label="Hammadde-7 Birimi (Euro)"
                      value={this.state.raw7Rate}
                      onChange={(e) => this.handleRaw7RateChange(e)}
                      placeHolder="Lütfen hammadde-7 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw7RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw7Total"
                      label="Hammadde-7 Toplam"
                      value={this.state.raw7Total}
                      onChange={this.Raw7Calculate}
                      placeHolder="Toplam hammadde-7 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw7TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw8"
                      label="Hammadde-8"
                      value={this.state.raw8}
                      onChange={(e) => this.handleRaw8Change(e)}
                      placeHolder="Lütfen hammadde-8 girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw8Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw8Rate"
                      label="Hammadde-8 Birimi (Euro)"
                      value={this.state.raw8Rate}
                      onChange={(e) => this.handleRaw8RateChange(e)}
                      placeHolder="Lütfen hammadde-8 birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw8RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="raw8Total"
                      label="Hammadde-8 Toplam"
                      value={this.state.raw8Total}
                      onChange={this.Raw8Calculate}
                      placeHolder="Toplam hammadde-8 değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.raw8TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama810"
                      label="8x16 Lama"
                      value={this.state.lama810}
                      onChange={(e) => this.handleLama810Change(e)}
                      placeHolder="Lütfen 8x16 Lama girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810Error}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama810Rate"
                      label="8x16 Lama Birimi (Euro)"
                      value={this.state.lama810Rate}
                      onChange={(e) => this.handleLama810RateChange(e)}
                      placeHolder="Lütfen 8x16 Lama birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810RateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lama810Total"
                      label="8x16 Lama Toplam"
                      value={this.state.lama810Total}
                      onChange={this.Lama810PriceCalculate}
                      placeHolder="Toplam 8x16 Lama değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lama810TotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="transport"
                      label="Nakliye"
                      value={this.state.transport}
                      onChange={(e) => this.handleTransportChange(e)}
                      placeHolder="Lütfen nakliye girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="transportRate"
                      label="Nakliye Birimi (Euro)"
                      value={this.state.transportRate}
                      onChange={(e) => this.handleTransportRateChange(e)}
                      placeHolder="Lütfen nakliye birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="transportTotal"
                      label="Nakliye Toplam"
                      value={this.state.transportTotal}
                      onChange={this.TransportPriceCalculate}
                      placeHolder="Toplam nakliye değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.transportTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sundry"
                      label="Muhtelif"
                      value={this.state.sundry}
                      onChange={(e) => this.handleSundryChange(e)}
                      placeHolder="Lütfen muhtelif girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sundryError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sundryRate"
                      label="Muhtelif Birimi (Euro)"
                      value={this.state.sundryRate}
                      onChange={(e) => this.handleSundryRateChange(e)}
                      placeHolder="Lütfen muhtelif birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sundryRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="sundryTotal"
                      label="Muhtelif Toplam"
                      value={this.state.sundryTotal}
                      onChange={this.SundryPriceCalculate}
                      placeHolder="Toplam muhtelif değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.sundryTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lamaLaser"
                      label="Lama Lazer Kesim"
                      value={this.state.lamaLaser}
                      onChange={(e) => this.handleLamaLaserChange(e)}
                      placeHolder="Lütfen lama lazer kesim girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lamaLaserError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lamaLaserRate"
                      label="Lama Lazer Kesim Birimi (Euro)"
                      value={this.state.lamaLaserRate}
                      onChange={(e) => this.handleLamaLaserRateChange(e)}
                      placeHolder="Lütfen lama lazer kesim birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lamaLaserRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="lamaLaserTotal"
                      label="Lama Lazer Kesim Toplam"
                      value={this.state.lamaLaserTotal}
                      onChange={this.LamaLaserPriceCalculate}
                      placeHolder="Toplam lama lazer kesim değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.lamaLaserTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="smallProduction"
                      label="Küçük Enj Üretim Miktarı"
                      value={this.state.smallProduction}
                      onChange={(e) => this.handleSmallProductionChange(e)}
                      placeHolder="Lütfen küçük enj üretim miktarı girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallProductionError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="smallProductionRate"
                      label="Küçük Enj Üretim Miktarı Birimi (Euro)"
                      value={this.state.smallProductionRate}
                      onChange={(e) => this.handleSmallProductionRateChange(e)}
                      placeHolder="Lütfen küçük enj üretim miktarı birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallProductionRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="smallProductionTotal"
                      label="Küçük Enj Üretim Miktarı Toplam"
                      value={this.state.smallProductionTotal}
                      onChange={this.SmallProductionPriceCalculate}
                      placeHolder="Toplam küçük enj üretim miktarı değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallProductionTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="bigProduction"
                      label="Büyük Enj Üretim Miktarı"
                      value={this.state.bigProduction}
                      onChange={(e) => this.handleBigProductionChange(e)}
                      placeHolder="Lütfen büyük enj üretim miktarı girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigProductionError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="bigProductionRate"
                      label="Büyük Enj Üretim Miktarı Birimi (Euro)"
                      value={this.state.bigProductionRate}
                      onChange={(e) => this.handleBigProductionRateChange(e)}
                      placeHolder="Lütfen büyük enj üretim miktarı birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigProductionRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="bigProductionTotal"
                      label="Büyük Enj Üretim Miktarı Toplam"
                      value={this.state.bigProductionTotal}
                      onChange={this.BigProductionPriceCalculate}
                      placeHolder="Toplam büyük enj üretim miktarı değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigProductionTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="smallCost"
                      label="Küçük Enj Maliyeti"
                      value={this.state.smallCost}
                      onChange={(e) => this.handleSmallCostChange(e)}
                      placeHolder="Lütfen küçük enj maliyeti girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallProductionError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="smallCostRate"
                      label="Küçük Enj Maliyeti Birimi (Euro)"
                      value={this.state.smallCostRate}
                      onChange={(e) => this.handleSmallCostRateChange(e)}
                      placeHolder="Lütfen küçük enj maliyeti birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallCostRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="smallCostTotal"
                      label="Küçük Enj Maliyeti Toplam"
                      value={this.state.smallCostTotal}
                      onChange={this.SmallCostPriceCalculate}
                      placeHolder="Toplam küçük enj maliyeti toplam değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.smallCostTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="bigCost"
                      label="Büyük Enj Maliyeti"
                      value={this.state.bigCost}
                      onChange={(e) => this.handleBigCostChange(e)}
                      placeHolder="Lütfen büyük enj maliyeti girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigCostError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="bigCostRate"
                      label="Büyük Enj Maliyeti Birimi (Euro)"
                      value={this.state.bigCostRate}
                      onChange={(e) => this.handleBigCostRateChange(e)}
                      placeHolder="Lütfen büyük enj maliyeti birimi girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigCostRateError}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="bigCostTotal"
                      label="Büyük Enj Maliyeti Toplam"
                      value={this.state.bigCostTotal}
                      onChange={this.BigCostPriceCalculate}
                      placeHolder="Toplam büyük enj maliyeti toplam değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.bigCostTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      //error={this.state.descriptionError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="cost"
                      label="Genel Gider"
                      value={this.state.cost}
                      onChange={(e) => this.handleCostChange(e)}
                      placeHolder="Genel gider değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.costError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="profitRate"
                      label="İşçilik Yüzdesi"
                      value={this.state.profitRate}
                      onChange={(e) => this.handleProfitRateChange(e)}
                      placeHolder="İşçilik yüzdesi değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.profitRateError}
                      step="0.1"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="castingCostTotal"
                      label="Toplam maliyeti €/ad"
                      value={this.state.castingCostTotal}
                      onChange={this.ProfitRateTotalCalculate}
                      placeHolder="Toplam maliyeti €/ad değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.castingCostTotalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Malzeme Maliyeti €/ad"
                      value={this.state.total}
                      onChange={this.TotalCalculate}
                      placeHolder="Malzeme maliyeti €/ad değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.totalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8"></Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="m2Total"
                      label="m2 Toplam €/m2"
                      value={this.state.m2Total}
                      onChange={this.M2TotalCalculate}
                      placeHolder="m2 Toplam değeri..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.m2totalError}
                      step="0.1"
                      disabled={true}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "tpu-maliyet-ekle" ||
              this.state.urlSplit[1] === "tpu-maliyet-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(TpuCostAddUpdate);
