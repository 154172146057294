import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  
  BillsType,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  CustomerList,
  localFilePath,
  productOutStatuses,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class ProductOutNewList extends Component {
  state = {
    pageTitle: "Satış Listele",
    pageAddLink: "satis2-ekle",
    pageUpdateLink: "satis2-guncelle",
    pageDetailLink: "satis2-detay",
    pageListLink: "satis2-listele",
    breadcrumb: [{ text: "Satış", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    producOutData: [],
    discountTotal: 0,
    totalPiece: 0,
    productItemCount: 0,
    discountTotalDolar: 0,
    totalPieceDolar: 0,
    productItemCountDolar: 0,
    discountTotalEuro: 0,
    totalPieceEuro: 0,
    productItemCountEuro: 0,
    remainingCollection: 0,
    remainingCollectionDolar: 0,
    remainingCollectionEuro: 0,
    guid: "",
    productOutStatus: 1,
    alertErrorMessage: "",
    detail: false,
    isOpenModalCreateInvoice: false,
    isLoadingModal: false,
    productOutGuid: "",
    billType: 0,
    billTypeError: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "button",
        color: "primary",
        text: CgnMessage.saveButton,
        onClick: (event) => this.handleSave(event),
        disabled: this.isLoading,
      },
    ],
    supplierGuid: "00000000-0000-0000-0000-000000000000",
    supplierArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateProductOutNew")) {
      firstDate = localStorage.getItem("firstDateProductOutNew");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateProductOutNew")) {
      lastDate = localStorage.getItem("lastDateProductOutNew");
      lastDate2 = new Date(lastDate);
    }

    if (localStorage.getItem("productOutStatusProductOutNew")) {
      this.setState({
        productOutStatus: localStorage.getItem("productOutStatusProductOutNew"),
      });
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });
    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidProductOutNew")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidProductOutNew"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateProductOutNew", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateProductOutNew", this.state.lastDate);
  }

  async handleSupplierSelectChange(option) {
    if (option) {
      await this.setState({
        supplierGuid: option.value,
      });
    } else {
      await this.setState({
        supplierGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("supplierGuidProductOutNew", this.state.supplierGuid);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidProductOutNew", this.state.customerGuid);
  }

  async handleProductOutStatusSelectChange(option) {
    if (option) {
      await this.setState({
        productOutStatus: option.value,
      });
      localStorage.setItem("productOutStatusProductOutNew", option.value);
    } else {
      await this.setState({
        productOutStatus: null,
      });
    }
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let urlSplit = window.location.pathname.split("/");
    var URLParam = "";
    if (urlSplit[1] === "musteri-detay") {
      URLParam = "/productOutsNew/getlistforcustomer/" + urlSplit[2];
      this.setState({
        detail: true,
      });
    } else {
      var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
      URLParam =
        "/productOutsNew/getlist/" +
        statusType +
        "/" +
        (this.state.productOutStatus === null
          ? 1
          : this.state.productOutStatus) +
        "/" +
        this.state.firstDate +
        "/" +
        this.state.lastDate +
        "/" +
        this.state.customerGuid;
    }
    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          producOutData: response.data,
          isLoading: false,
        });
        var discountTotal = 0;
        var totalPiece = 0;
        var productItemCount = 0;
        var discountTotalDolar = 0;
        var totalPieceDolar = 0;
        var productItemCountDolar = 0;
        var discountTotalEuro = 0;
        var totalPieceEuro = 0;
        var productItemCountEuro = 0;
        var remainingCollection = 0;
        var remainingCollectionDolar = 0;
        var remainingCollectionEuro = 0;
        response.data.forEach((element) => {
          if (element.currencyType === 1) {
            discountTotal += element.total;
            totalPiece += element.totalPiece;
            productItemCount += element.productItemCount;
            remainingCollection += element.totalCollection;
          } else if (element.currencyType === 2) {
            discountTotalDolar += element.total;
            totalPieceDolar += element.totalPiece;
            productItemCountDolar += element.productItemCount;
            remainingCollectionDolar += element.totalCollection;
          } else if (element.currencyType === 3) {
            discountTotalEuro += element.total;
            totalPieceEuro += element.totalPiece;
            productItemCountEuro += element.productItemCount;
            remainingCollectionEuro += element.totalCollection;
          }
        });
        this.setState({
          discountTotal: discountTotal,
          totalPiece: totalPiece,
          productItemCount: productItemCount,
          discountTotalDolar: discountTotalDolar,
          totalPieceDolar: totalPieceDolar,
          productItemCountDolar: productItemCountDolar,
          discountTotalEuro: discountTotalEuro,
          totalPieceEuro: totalPieceEuro,
          productItemCountEuro: productItemCountEuro,
          remainingCollection: remainingCollection,
          remainingCollectionDolar: remainingCollectionDolar,
          remainingCollectionEuro: remainingCollectionEuro,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/productOutsnew/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  addModalCreateInvoice = (productOutGuid) => {
    this.setState({
      productOutGuid: productOutGuid,
      isOpenModalCreateInvoice: !this.state.isOpenModalCreateInvoice,
      billType: 0,
      billTypeError: "",
      alertModalErrorMessage: "",
    });
  };

  handleClear = () => {
    this.setState({
      billType: 0,
      billTypeError: "",
      alertModalErrorMessage: "",
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/productoutsnew/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async handleBillTypeSelectChange(option) {
    if (option) {
      await this.setState({
        billType: option.value,
        billTypeError: "",
        alertModalErrorMessage: "",
      });
    } else {
      await this.setState({
        billType: 0,
        billTypeError: CgnMessage.textErrorMessage,
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleExcel = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var StatusType = 1;
    var URLParam = "";
    URLParam =
      "/productoutsnew/print/" +
      StatusType +
      "/" +
      this.state.productOutStatus +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleSave = async (event) => {
    this.setState({
      billTypeError: "",
    });
    if (this.state.billType === 0) {
      this.setState({
        billTypeError: CgnMessage.textErrorMessage,
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.billType !== null && this.state.billType !== "") {
      this.setState({
        isLoading: true,
        isLoadingModal: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        ProductOutGuid: this.state.productOutGuid,
        BillType: this.state.billType,
      };
      axios
        .post(URL + "/bills/add", myObj, config)
        .then(() => {
          this.setState({
            isOpenModalBillType: !this.state.isOpenModalBillType,
            alertModalErrorMessage: "",
            isLoading: false,
            isLoadingModal: false,
          });
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            alertModalErrorMessage: error.response.data,
            isOpenModalReply: !this.state.isOpenModalReply,
            isLoading: false,
            isLoadingModal: false,
          });
        });
    } else {
      this.setState({
        alertModalErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  cancel = (uuid) => {
    confirmAlert({
      title: CgnMessage.cancelQuestionTitle,
      message: CgnMessage.cancelQuestionMessage,
      buttons: [
        {
          label: CgnMessage.cancelQuestionYes,
          onClick: () => this.cancelItem(uuid),
        },
        {
          label: CgnMessage.cancelQuestionNo,
        },
      ],
    });
  };

  cancelItem = async (uuid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var myObj = {
      uuid: uuid,
      cancelCogen: 1,
    };
    axios
      .post(URL + "/bills/cancel", myObj, config)
      .then(() => {
        this.setState({
          isLoading: false,
          alertErrorMessage: "",
        });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          alertErrorMessage: error.response.data,
        });
      });
  };

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "150px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
            excelLink={(onClick) => this.handleExcel()}
          />
        )}
        <Row className="productOutList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              {this.state.detail === false && (
                <Row className="filterRow">
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="firstDate"
                      label="Baş. Tarihi"
                      selected={
                        this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.firstDate2.split("/")[2] +
                                "-" +
                                this.state.firstDate2.split("/")[1] +
                                "-" +
                                this.state.firstDate2.split("/")[0]
                            )
                          : this.state.firstDate2
                      }
                      onChange={(firstDate2) =>
                        this.handleFirstDateChange(firstDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="lastDate"
                      label="Bit. Tarihi"
                      selected={
                        this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.lastDate2.split("/")[2] +
                                "-" +
                                this.state.lastDate2.split("/")[1] +
                                "-" +
                                this.state.lastDate2.split("/")[0]
                            )
                          : this.state.lastDate2
                      }
                      onChange={(lastDate2) =>
                        this.handleLastDateChange(lastDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="5">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      options={this.state.customerArr}
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="2">
                    <CgnReactSelect
                      name="productOutStatus"
                      label="Durum"
                      placeholder="Durum seçiniz..."
                      selectValue={this.state.productOutStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductOutStatusSelectChange(option)
                      }
                      options={productOutStatuses}
                    />
                  </Col>
                  <Col className="pl-2 pr-0" md="1">
                    <CgnButton
                      type="button"
                      color="primary"
                      onClick={this.getList}
                      text="Listele"
                      className="listButton"
                    />
                  </Col>
                </Row>
              )}
              <CgnDatatable
                data={this.state.producOutData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Satış Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      if (row.customerPhone !== null) {
                        var phone = row.customerPhone,
                          maskPhone = `${phone.substring(0, 1)}  ${
                            "(" + phone.substring(1, 4) + ")"
                          } ${phone.substring(4, 7)} ${phone.substring(
                            7,
                            9
                          )} ${phone.substring(9, 11)}`;
                      }
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.productOutDate}</td>
                              <th style={this.thStyle2}>Müşteri</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.customerName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Telefon</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.customerPhone === "" ? "-" : maskPhone}
                              </td>
                              <th style={this.thStyle2}>E-Mail</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.customerEMail !== ""
                                  ? row.customerEMail
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle2}>Toplam Tutar</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.total.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "TL"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                              <th style={this.thStyle2}>Kalan Tahsilat</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.totalCollection.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "TL"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={4}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text:
                              row.bills &&
                              row.billsProductOutGuid ===
                                "00000000-0000-0000-0000-000000000000"
                                ? CgnMessage.createInvoiceButton
                                : "",
                            className: "dropDownBtn",
                            onClick: () => this.addModalCreateInvoice(row.guid),
                          },
                          {
                            text:
                              row.bills &&
                              row.billsGuid !== null &&
                              row.billsGuid !==
                                "00000000-0000-0000-0000-000000000000"
                                ? CgnMessage.detailInvoiceButton
                                : "",
                            className: "dropDownBtn",
                            href: `/giden-fatura-detay/${row.billsGuid}`,
                          },
                          {
                            text:
                              row.bills &&
                              row.pdfFileName !== null &&
                              row.pdfFileName !== ""
                                ? CgnMessage.viewInvoiceButton
                                : "",
                            className: "dropDownBtn",
                            onClick: () =>
                              window.open(
                                `/EFatura/${row.pdfFileName}`,
                                "_blank"
                              ),
                          },
                          {
                            text:
                              row.bills &&
                              row.billsType !== null &&
                              row.billsType === 3 &&
                              row.billsBillType !== null &&
                              row.billsBillType === "TICARIFATURA" &&
                              row.billsCancel !== null &&
                              row.billsCancel === 0 &&
                              row.billsCancelCogen !== null &&
                              row.billsCancelCogen === 0
                                ? CgnMessage.cancelInvoiceButton
                                : "",
                            className: "dropDownBtn",
                            onClick: () => this.cancel(row.billsGuid),
                          },
                          {
                            text: "Satış Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfPrint(row.guid),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (TL):{" "}
                {this.state.discountTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                TL
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Kalan Tahsilat (TL):{" "}
                {this.state.remainingCollection.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                TL
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.discountTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Kalan Tahsilat ($):{" "}
                {this.state.remainingCollectionDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.discountTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Kalan Tahsilat (€):{" "}
                {this.state.remainingCollectionEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCreateInvoice}
          toggle={this.addModalCreateInvoice}
          className="modal-xs content"
        >
          <ModalHeader className="font-weight-bold">Fatura Oluştur</ModalHeader>
          <ModalBody className="modalBody">
            <CgnLoader visibled={this.state.isLoadingModal} />
            <Row>
              <Col>
                <CgnReactSelect
                  name="billType"
                  label="Fatura Tipi [*]"
                  placeholder="Lütfen fatura tipi seçiniz..."
                  selectValue={this.state.billType}
                  isMulti={false}
                  isClearable={true}
                  onChange={(option) => this.handleBillTypeSelectChange(option)}
                  options={BillsType}
                  error={this.state.billTypeError}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.alertModalErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertModalErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCreateInvoice}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductOutNewList;
