import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Form } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  resultChanges,
  localFilePath
} from "../../redux/actions/constants";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButton from "../toolbox/CgnButton";

class LastControlDetail extends Component {
  state = {
    pageTitle: "Son Kontrol Detay",
    pageTitleDetail: "Son Kontrol Detay",
    pageListLink: "son-kontrol-havuzu",
    breadcrumb: [{ text: "Son Kontrol", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    workDate: "",
    workStartDate: "",
    guid: "",
    workEndDate: "",
    productGuid: "",
    customerGuid: "",
    personelGuid: "",
    productOutGuid: "",
    employmentDate: "",
    qualityPersonelGuid: "",
    lastControlPersonelGuid: null,
    errorCodeGuid: "",
    name: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    description: "",
    workInstructionStepGuid: "00000000-0000-0000-0000-000000000000",
    currencyType: "",
    lastControlPhysicalDto: [], 
    lastControlElectricalDto: [],
    workInstructionStepArr: [],
    dispatchDate: "",
    dispatchPiece: "",
    urlSplit: [],
    isHiddenWork: true,
    physicalControlError: "",
    resultChangeError: "",
    counter: -1,
    counter1: -1,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
      .get(URL + "/workInstructions/getliststatus/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.customerName + "/" + item.productName,
          };
        });
        this.setState({
          workInstructionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });    
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      axios
      .get(URL + "/electricalTests/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name
          };
        });
        this.setState({
          electricalTestArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      axios
      .get(URL + "/physicalControls/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name
          };
        });
        this.setState({
          physicalControlArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      axios
      .get(URL + "/deviceUseds/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name
          };
        });
        this.setState({
          deviceUsedArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          personelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          qualityPersonelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      await axios
        .get(URL + "/lastControls/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            workInstructionGuid: response.data.workInstructionGuid,
            workInstructionCode: response.data.workInstructionCode,
            customerGuid: response.data.customerGuid,
            personelGuid: response.data.personelGuid,
            productGuid: response.data.productGuid,
            productOutGuid: response.data.productOutGuid,          
            workInstructionDate: response.data.workInstructionDate,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            currencyType: response.data.currencyType,
            employmentDate: response.data.employmentDate,
            lastControlPersonelGuid: response.data.lastControlPersonelGuid,
            description: response.data.description,
            isHiddenWork: response.data.lastControlPersonelGuid !== null ? false : true,
            dispatchDate: response.data.dispatchDate,
            dispatchPiece: response.data.dispatchPiece,
            counter: response.data.lastControlPhysicalDto.length - 1,
            counter1: response.data.lastControlElectricalDto.length - 1,
            lastControlElectricalDto: response.data.lastControlElectricalDto,
            lastControlPhysicalDto: response.data.lastControlPhysicalDto,
            workInstructionStepGuid: response.data.workInstructionStepGuid,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      await axios
      .get(URL + "/workInstructionSteps/getlistreport/" + this.state.workInstructionGuid, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.workOrderStepName,
          };
        });
        this.setState({
          workInstructionStepArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    }
  }

  async handleWorkInstructionSelectChange(option) {
    if (option) {
      await this.setState({
        workInstructionGuid: option.value,
      });    
    } else {
      this.setState({
        workInstructionGuid: "",
      });
    }
  }

  async handleWorkInstructionStepSelectChange(option) {
    if (option) {
      await this.setState({
        workInstructionStepGuid: option.value,
      });
    } else {
      this.setState({
        workInstructionStepGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handleDispatchPieceTextBoxChange(e) {
    this.setState({
      dispatchPiece: e.target.value,
      dispatchPieceError: "",
      alertErrorMessage: "",
    });
  }

   handleDispatchDateChange(dispatchDate) {
    if (dispatchDate) {
       this.setState({
        dispatchDate: dispatchDate,
        dispatchDateError: "",
      });
    } else {
      this.setState({
        dispatchDate: "",
        dispatchDateError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };


  async handleUpdateLastControlPersonel (guid) {
    await this.setState({
      isLoading: true
    });
    // 
  var config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  var URLParam = "/lastControls/updatelastcontrolpersonel";
  var myObj = {
     guid: guid
  };
  axios
  .post(URL + URLParam, myObj, config)
  .then(() => {
    setTimeout(() => {
        window.location.reload();
    }, 1500);
  })
  .catch((error) => {
    this.setState({
      alertErrorMessage: error.response.data,
      isLoading: false,
    });
  });
  }

  handlePhysicalAdd = async () => {
    let array = this.state.lastControlPhysicalDto;
    await array.push({
      physicalControlGuid: "",
      deviceUsedGuid: null,
      serialNo: "",
      resultChange: "",
      physicalControlError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].physicalControlGuid) {
      return array[this.state.counter].physicalControlError === "";
    }
    if (array[this.state.counter].deviceUsedGuid) {
      return array[this.state.counter].deviceUsedError === "";
    }
    if (array[this.state.counter].serialNo) {
      return array[this.state.counter].serialError === "";
    }
    if (array[this.state.counter].resultChange) {
      return array[this.state.counter].resultChangeError === "";
    }
    if (
      array[this.state.counter].physicalControlGuid === "") {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ lastControlPhysicalDto: array });
  };

  handleElectricalAdd = async () => {
    let array = this.state.lastControlElectricalDto;
    await array.push({
      electricalTestGuid: "",
      deviceUsedGuid: null,
      serialNo: "",
      resultChange: "",
      electricalTestError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter1: this.state.counter1 + 1,
    });
    if (array[this.state.counter1].electricalTestGuid) {
      return array[this.state.counter1].electricalTestError === "";
    }
    if (array[this.state.counter1].deviceUsedGuid) {
      return array[this.state.counter1].ldeviceUsedError === "";
    }
    if (array[this.state.counter1].serialNo) {
      return array[this.state.counter1].serialError === "";
    }
    if (array[this.state.counter1].resultChange) {
      return array[this.state.counter1].resultChangeError === "";
    }
    if (
      array[this.state.counter1].electricalTestGuid === "") {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ lastControlElectricalDto: array });
  };

  handlePhysicalChange(option, idx){
    let lastControlPhysicalDto = this.state.lastControlPhysicalDto;
    if(option){
      lastControlPhysicalDto[idx].physicalControlGuid = option.value;
      lastControlPhysicalDto[idx].physicalControlError = "";
      this.state.alertErrorMessage = "";
    }
    else{
      lastControlPhysicalDto[idx].physicalControlGuid = "";
      lastControlPhysicalDto[idx].physicalControlError = CgnMessage.textErrorMessage;
    }

    this.setState({lastControlPhysicalDto: lastControlPhysicalDto});
  }

  handleElectricalChange(option, idx){
    let lastControlElectricalDto = this.state.lastControlElectricalDto;
    if(option){
      lastControlElectricalDto[idx].electricalTestGuid = option.value;
      lastControlElectricalDto[idx].electricalTestError = "";
      this.state.alertErrorMessage = "";
    }
    else{
      lastControlElectricalDto[idx].electricalTestGuid = "";
      lastControlElectricalDto[idx].electricalTestError = CgnMessage.textErrorMessage;
    }

    this.setState({lastControlElectricalDto: lastControlElectricalDto});
  }

  handleDeviceUsedChange(option, idx){
    let lastControlPhysicalDto = this.state.lastControlPhysicalDto;
    if(option){
      lastControlPhysicalDto[idx].deviceUsedGuid = option.value;
      lastControlPhysicalDto[idx].deviceUsedError = "";
    }
    else{
      lastControlPhysicalDto[idx].deviceUsedGuid = null;
      lastControlPhysicalDto[idx].deviceUsedError = "";
    }

    this.setState({lastControlPhysicalDto: lastControlPhysicalDto});
  }

  handleDeviceUsedChange2(option, idx){
    let lastControlElectricalDto = this.state.lastControlElectricalDto;
    if(option){
      lastControlElectricalDto[idx].deviceUsedGuid = option.value;
      lastControlElectricalDto[idx].deviceUsedError = "";
    }
    else{
      lastControlElectricalDto[idx].deviceUsedGuid = null;
      lastControlElectricalDto[idx].deviceUsedError = "";
    }

    this.setState({lastControlElectricalDto: lastControlElectricalDto});
  }

  handleSerialNoTextBoxChange(e, idx) {
    let lastControlPhysicalDto = this.state.lastControlPhysicalDto;
    lastControlPhysicalDto[idx].serialNo = e.target.value;
    this.setState({
      lastControlPhysicalDto: lastControlPhysicalDto,
    });
  }

  handleSerialNoTextBoxChange2(e, idx) {
    let lastControlElectricalDto = this.state.lastControlElectricalDto;
    lastControlElectricalDto[idx].serialNo = e.target.value;
    this.setState({
      lastControlElectricalDto: lastControlElectricalDto,
    });
  }

  handleResultChangeChange(option, idx){
    let lastControlPhysicalDto = this.state.lastControlPhysicalDto;
    if(option){
      lastControlPhysicalDto[idx].resultChange = option.value;
      lastControlPhysicalDto[idx].resultChangeError = "";
    }
    else{
      lastControlPhysicalDto[idx].resultChange = "";
      lastControlPhysicalDto[idx].resultChangeError = "";
    }

    this.setState({lastControlPhysicalDto: lastControlPhysicalDto});
  }

  handleResultChangeChange2(option, idx){
    let lastControlElectricalDto = this.state.lastControlElectricalDto;
    if(option){
      lastControlElectricalDto[idx].resultChange = option.value;
      lastControlElectricalDto[idx].resultChangeError = "";
    }
    else{
      lastControlElectricalDto[idx].resultChange = "";
      lastControlElectricalDto[idx].resultChangeError = "";
    }

    this.setState({lastControlElectricalDto: lastControlElectricalDto});
  }


  async handlePhysicalRemove(idx) {
    let someArray = this.state.lastControlPhysicalDto;
    someArray.splice(idx, 1);
    await this.setState({
      lastControlPhysicalDto: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }


  async handleElectricalRemove(idx) {
    let someArray = this.state.lastControlElectricalDto;
    someArray.splice(idx, 1);
    await this.setState({
      lastControlElectricalDto: someArray,
      counter1: this.state.counter1 - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/lastControls/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };


  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });
    var control = true;
    if(this.state.lastControlPhysicalDto !== null && this.state.lastControlPhysicalDto.length > 0){
      this.state.lastControlPhysicalDto.forEach((element) => {
       if(element.physicalControlGuid === ""){
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
       }
       if(element.resultChange === null || element.resultChange === ""){
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
       }
      })
    }

    var control1 = true;
    if(this.state.lastControlElectricalDto !== null && this.state.lastControlElectricalDto.length > 0){
      this.state.lastControlElectricalDto.forEach((element) => {
       if(element.electricalTestGuid === ""){
          control1 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
       }
       if(element.resultChange === null || element.resultChange === ""){
          control1 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
       }
      })
    }

   if (control === true && control1 === true) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      if (this.state.guid) {
        var URLParam = "/lastControls/update";
        var myObj = {
          guid: this.state.guid,
          dispatchDate: this.state.dispatchDate,
          dispatchPiece: this.state.dispatchPiece === "" ? 0 : parseFloat(this.state.dispatchPiece),
          lastControlElectricalDto: this.state.lastControlElectricalDto,
          lastControlPhysicalDto: this.state.lastControlPhysicalDto,
          description: this.state.description,
          workInstructionStepGuid: this.state.workInstructionStepGuid,
          status: true,
        };
      }

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid
            ) {
              window.location.href = "/son-kontrol-havuzu";
            }          
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
          printLink={onClick => this.handlePdfPrint(this.state.guid)}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="4" style={{display: this.state.lastControlPersonelGuid === null ? "" : "none"}}>
                    <CgnButton
                      type="button"
                      color="danger"
                      className="mb-3"
                      style={{width: "150px"}}
                      onClick={() => this.handleUpdateLastControlPersonel(this.state.guid)}
                      text="İşi Al"
                    />
                  </Col>
                  <Col
                    lg="4"
                    className="pl-0"
                    style={{
                      display: this.state.isHiddenWork === true ? "none" : "",
                    }}
                  >
                    <CgnDatePicker
                      name="employmentDate"
                      label="İşe Alınma Tarihi"
                      selected={
                        this.state.employmentDate &&
                        this.state.employmentDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.employmentDate.split("/")[2] +
                                "-" +
                                this.state.employmentDate.split("/")[1] +
                                "-" +
                                this.state.employmentDate.split("/")[0]
                            )
                          : this.state.employmentDate
                      }
                      onChange={(employmentDate) =>
                        this.handleEntryDateChange(employmentDate)
                      }
                      error={this.state.entryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4"                     
                    style={{
                      display: this.state.isHiddenWork === true ? "none" : "",
                    }}>
                    <CgnReactSelect
                      name="lastControlPersonelGuid"
                      label="Son Kontrol Personeli"
                      isMulti={false}
                      selectValue={this.state.lastControlPersonelGuid}
                      options={this.state.qualityPersonelArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePersonelSelectChange(option)
                      }
                      error={this.state.personelError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      isMulti={false}
                      selectValue={this.state.workInstructionGuid}
                      placeholder="İş Emri seçiniz..."
                      options={this.state.workInstructionArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionSelectChange(option)
                      }
                      error={this.state.workInstructionError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="workInstructionCode"
                      label="İş Emri No [*]"
                      value={this.state.workInstructionCode}
                      placeHolder="Lütfen iş emri no girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.workInstructionCodeError}
                      readOnly={true}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="workInstructionDate"
                      label="İş Emri Tarihi [*]"
                      selected={
                        this.state.workInstructionDate &&
                        this.state.workInstructionDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.workInstructionDate.split("/")[2] +
                                "-" +
                                this.state.workInstructionDate.split("/")[1] +
                                "-" +
                                this.state.workInstructionDate.split("/")[0]
                            )
                          : this.state.workInstructionDate
                      }
                      onChange={(workInstructionDate) =>
                        this.handleWorkDateChange(workInstructionDate)
                      }
                      error={this.state.workDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                      disabled={true}
                    />
                  </Col>       
                  <Col lg="3">
                  <CgnReactSelect
                      name="workInstructionStepGuid"
                      label="Hata Bulunan İş Adımı [*]"
                      isMulti={false}
                      selectValue={this.state.workInstructionStepGuid}
                      placeholder="Lütfen hata bulunan iş adımı seçiniz..."
                      options={this.state.workInstructionStepArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionStepSelectChange(option)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      isMulti={false}
                      selectValue={this.state.customerGuid}
                      placeholder="Müşteri seçiniz..."
                      options={this.state.customerArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="personelGuid"
                      label="Personel [*]"
                      isMulti={false}
                      selectValue={this.state.personelGuid}
                      placeholder="Personel seçiniz..."
                      options={this.state.personelArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePersonelSelectChange(option)
                      }
                      error={this.state.personelError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="6">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      isMulti={false}
                      selectValue={this.state.productOutGuid}
                      placeholder="Satış seçiniz..."
                      options={this.state.productOutArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductOutSelectChange(option)
                      }
                      error={this.state.productOutError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productGuid"
                      label="Ürün [*]"
                      isMulti={false}
                      selectValue={this.state.productGuid}
                      placeholder="Ürün seçiniz..."
                      options={this.state.productArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      error={this.state.productError}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row style={{display: this.state.isHiddenWork === true ? "none" : ""}}>
                 <Col lg="6">
                 <CgnTextbox
                      name="dispatchPiece"
                      label="Sevk Edilecek Ürün Miktarı"
                      isMulti={false}
                      value={this.state.dispatchPiece}
                      placeHolder="Lütfen sevk edilecek ürün miktarı girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleDispatchPieceTextBoxChange(e)}
                    />
                 </Col>       
                 <Col lg="6">
                 <CgnDatePicker
                      name="dispatchDate"
                      label="Sevk Tarihi"
                      selected={
                        this.state.dispatchDate &&
                        this.state.dispatchDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.dispatchDate.split("/")[2] +
                                "-" +
                                this.state.dispatchDate.split("/")[1] +
                                "-" +
                                this.state.dispatchDate.split("/")[0]
                            )
                          : this.state.dispatchDate
                      }
                      onChange={(dispatchDate) =>
                        this.handleDispatchDateChange(dispatchDate)
                      }
                      error={this.state.workDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                 </Col>   
                </Row>

                 {this.state.lastControlPhysicalDto.map((lastControlPhysicalDto, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="physicalControlGuid"
                            label="Fiziksel Kontrol [*]"
                            placeholder="Lütfen fiziksel kontrol seçiniz..."
                            selectValue={lastControlPhysicalDto.physicalControlGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handlePhysicalChange(option, idx);
                            }}
                            options={this.state.physicalControlArr}
                            error={lastControlPhysicalDto.physicalControlError}
                          />
                        </div>
                        {this.state.physicalControlModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalPhysicalControl(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="deviceUsedGuid"
                            label="Kullanılan Cihaz"
                            placeholder="Lütfen kullanılan cihaz seçiniz..."
                            selectValue={lastControlPhysicalDto.deviceUsedGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleDeviceUsedChange(option, idx);
                            }}
                            options={this.state.deviceUsedArr}
                            error={lastControlPhysicalDto.productError}
                          />
                        </div>
                        {this.state.deviceUsedModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalDeviceUsed(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col lg="3">
                      <CgnTextbox
                        name="serialNo"
                        label="Seri No"
                        value={lastControlPhysicalDto.serialNo}
                        onChange={(e) =>
                          this.handleSerialNoTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen seri no girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={lastControlPhysicalDto.productPieceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnReactSelect
                        name="resultChange"
                        label="Kabul Sonucu [*]"
                        selectValue={lastControlPhysicalDto.resultChange}
                        onChange={(option) =>
                          this.handleResultChangeChange(option, idx)
                        }
                        placeholder="Lütfen kabul sonucu girin..."
                        options={resultChanges}
                        error={lastControlPhysicalDto.resultChangeError}
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handlePhysicalRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}
                  
                  <Row style={{display: this.state.isHiddenWork === true ? "none" : ""}}>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-2 dynamicRowAddButton"
                      onClick={this.handlePhysicalAdd}
                      text={"Fiziksel Kontrol Ekle"}
                        />
                      {this.state.lastControlPhysicalError && (
                        <div className="invalid-feedback d-block">
                          {this.state.lastControlPhysicalError}
                        </div>
                      )}
                  </Col>
                  </Row>

                  {this.state.lastControlElectricalDto.map((lastControlElectricalDto, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="electricalTestGuid"
                            label="Elektriksel ve Diğer Testler [*]"
                            placeholder="Lütfen elektriksel test seçiniz..."
                            selectValue={lastControlElectricalDto.electricalTestGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleElectricalChange(option, idx);
                            }}
                            options={this.state.electricalTestArr}
                            error={lastControlElectricalDto.electricalTestError}
                          />
                        </div>
                        {this.state.electricalTestModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalElectricalTest(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="deviceUsedGuid"
                            label="Kullanılan Cihaz"
                            placeholder="Lütfen kullanılan cihaz seçiniz..."
                            selectValue={lastControlElectricalDto.deviceUsedGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleDeviceUsedChange2(option, idx);
                            }}
                            options={this.state.deviceUsedArr}
                            error={lastControlElectricalDto.productError}
                          />
                        </div>
                        {this.state.deviceUsedModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalDeviceUsed2(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col lg="3">
                      <CgnTextbox
                        name="serialNo"
                        label="Seri No"
                        value={lastControlElectricalDto.serialNo}
                        onChange={(e) =>
                          this.handleSerialNoTextBoxChange2(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={lastControlElectricalDto.productPieceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnReactSelect
                        name="resultChange"
                        label="Kabul Sonucu [*]"
                        selectValue={lastControlElectricalDto.resultChange}
                        onChange={(option) =>
                          this.handleResultChangeChange2(option, idx)
                        }
                        placeholder="Lütfen kabul sonucu girin..."
                        options={resultChanges}
                        error={lastControlElectricalDto.resultChangeError}
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleElectricalRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}


                  <Row style={{display: this.state.isHiddenWork === true ? "none" : ""}}>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-2 dynamicRowAddButton"
                      onClick={this.handleElectricalAdd}
                      text={"Elektriksel ve Diğer Testler Ekle"}
                        />
                      {this.state.lastControlElectricalError && (
                        <div className="invalid-feedback d-block">
                          {this.state.lastControlElectricalError}
                        </div>
                      )}
                  </Col>
                  </Row>

                  <Row style={{display: this.state.isHiddenWork === true ? "none" : ""}}>
                  <Col lg="12">
                  <CgnTextbox
                        inlineStyle={textAreaStyle}
                        type="textarea"
                        name="description"
                        label="Açıklama"
                        value={this.state.description}
                        onChange={this.handleChange}
                        placeHolder="Lütfen açıklama girin..."
                        autoComplete="off"
                      />
                  </Col>
                  </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                {this.state.lastControlPersonelGuid && (
                <CgnButtonGroup items={this.state.buttons} />
                )}

              </Card>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

export default LastControlDetail;
