import React, { Component } from "react";
import { Row, Col, Card, Table, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  fotoStyle2,
  localFilePath,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import moment from "moment";
import pdfIcon from "../../images/pdfIcon.png";
import CgnBadge from "../toolbox/CgnBadge";

class PersonelWorkInstructionDetail extends Component {
  state = {
    pageTitle: "Personel İş Emri Detay",
    pageTitleDetail: "Personel İş Emri Detay",
    pageTitleQualityHistory: "Kalite Geçmişi",
    pageTitleQualityHistoryLink: "kalite-gecmisi",
    pageListLink: "personel-is-emri-listele",
    breadcrumb: [{ text: "Personel İş Emri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    workDate: "",
    workStartDate: "",
    workEndDate: "",
    customerName: "",
    personelName: "",
    productOutName: "",
    productCode: "",
    productName: "",
    personelGuid: "",
    customerGuid: "",
    productGuid: "",
    productOutGuid: "",
    workInstructionGuid: "",
    piece: "",
    guid: "",
    lastControl: "",
    stepData: [],
    qualityData: [],
    productData: [],
    workOrderDateData: [],
    productionData: [],
    productOutImageDto: [],
    lastControlButton: "",
    materialProduct: "",
    materialQuality: "",
    workOrderStepName: "",
    productOutTotal: "",
    productOutDate: "",
    productOutCustomer: "",
    statusChange: "",
    code: "",
    customerCode: "",
    name: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    decsription: "",
    currencyType: "",
    errorPiece: 0,
    suitablePiece: 0,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/workInstructions/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            code: response.data.code,
            workInstructionGuid: response.data.guid,
            customerCode: response.data.customerCode,
            customerName: response.data.customerName,
            personelName: response.data.personelName,
            productName: response.data.productName,
            productCode: response.data.productCode,
            decsription: response.data.decsription,
            workDate: response.data.workDate,
            workStartDate: response.data.workStartDate,
            workEndDate: response.data.workEndDate,
            stepData: response.data.workInstructionStepDto,
            productData: response.data.productRecipeProductDto,
            productionData: response.data.productRecipeProductionDto,
            qualityData: response.data.productRecipeQualityDto,
            productOutDate: response.data.productOutDate,
            productOutTotal: response.data.productOutTotal,
            productOutCustomer: response.data.productOutCustomer,
            piece: response.data.piece,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            currencyType: response.data.currencyType,
            statusChange: response.data.statusChange,
            productOutImageDto: response.data.productOutImageDto,
            lastControl: response.data.lastControl,
            lastControlButton: response.data.lastControlButton,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      var errorPiece = 0;
      var suitablePiece = 0;

      await this.state.stepData.forEach((element) => {
        errorPiece += element.errorPieceQuality;
      });
      suitablePiece = parseFloat(this.state.piece) - parseFloat(errorPiece);
      this.setState({
        errorPiece: errorPiece,
        suitablePiece: suitablePiece
      });
    }
  }

  async handleStartDate(guid) {
    if (guid !== null || guid !== undefined)
      await this.setState({
        isLoading: true,
      });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "/workOrderDates/add";
    var myObj = {
      workInstructionStepGuid: guid,
    };
    axios
      .post(URL + URLParam, myObj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleUpdateDate(guid) {
    if (guid !== null || guid !== undefined) {
      await this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/workOrderDates/update";
      var myObj = {
        workInstructionStepGuid: guid,
      };
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }


  async handleSendLastControl(guid) {
    if (guid !== null || guid !== undefined) {
      await this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/lastControls/add";
      var myObj = {
        workInstructionGuid: guid,
      };
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  async handleQualityHistory(guid) { }

  async handleEndDate(guid) {
    if (guid !== null || guid !== undefined) {
      await this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/workOrderDates/endUpdate";
      var myObj = {
        workInstructionStepGuid: guid,
      };
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (guid !== null || guid !== undefined) {
              var config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              };
              var URLParam = "/qualities/add";
              var myObj = {
                workInstructionStepGuid: guid,
                workInstructionGuid: this.state.workInstructionGuid,
              };
              axios
                .post(URL + URLParam, myObj, config)
                .then(() => {
                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath, "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  DateCalculate(startDate, endDate) {
    if (endDate !== null) {
      const timeStartDate = new Date(startDate);
      const timeEnd = new Date(endDate);
      const diff = moment(timeEnd).diff(moment(timeStartDate));
      const diffDuration = moment.duration(diff);
      var dk = diffDuration.minutes();
      var hour = diffDuration.hours();
      return `${hour} saat ${dk} dk`;
    } else {
      return 0;
    }
  }

  render() {
    const mainRow = {
      backgroundColor: "#9B9796",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>İş Emri Numarası</th>
                    <td colSpan="4">{this.state.code}</td>
                  </tr>
                  <tr>
                    <th>Müşteri İş Emri Numarası</th>
                    <td colSpan="4">
                      {this.state.customerCode === ""
                        ? "-"
                        : this.state.customerCode}
                    </td>
                  </tr>
                  <tr>
                    <th>İş Emri Tarihi</th>
                    <td colSpan="4">{this.state.workDate}</td>
                  </tr>

                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">{this.state.customerName}</td>
                  </tr>

                  <tr>
                    <th>Personel</th>
                    <td colSpan="4">{this.state.personelName}</td>
                  </tr>

                  <tr>
                    <th>Başlangıç Tarihi</th>
                    <td colSpan="4">{this.state.workStartDate}</td>
                  </tr>

                  <tr>
                    <th>Bitiş Tarihi</th>
                    <td colSpan="4">
                      {this.state.workEndDate === "01/01/1900"
                        ? "-"
                        : this.state.workEndDate}
                    </td>
                  </tr>

                  <tr>
                    <th>Satış</th>
                    <td colSpan="4">
                      {this.state.productOutDate +
                        " " +
                        this.state.productOutCustomer
                      }
                    </td>
                  </tr>

                  <tr>
                    <th>Ürün</th>
                    <td colSpan="4">
                      {this.state.productCode + "-" + this.state.productName}
                    </td>
                  </tr>

                  <tr>
                    <th>Ürün Adedi</th>
                    <td colSpan="4">
                      {this.state.piece}
                    </td>
                  </tr>

                  <tr>
                    <th>Uygun Ürün Adedi</th>
                    <td colSpan="4">
                      {this.state.suitablePiece}
                    </td>
                  </tr>

                  <tr className="text-danger">
                    <th>Hatalı Ürün Adedi</th>
                    <td colSpan="4">
                      {this.state.errorPiece}
                    </td>
                  </tr>

                  <tr>
                    <th>Durum</th>
                    <td colSpan="4">
                      {this.state.statusChange === 1
                        ? "Beklemede"
                        : this.state.statusChange === 2
                          ? "İşlemde"
                          : this.state.statusChange === 3
                            ? "İptal Edildi"
                            : this.state.statusChange === 4
                              ? "Revize"
                              : this.state.statusChange === 5
                                ? "Tamamlandı"
                                : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.productData !== null &&
                this.state.productData.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.productData !== null &&
                            self.productData.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Kullanılan Ürünler</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Ürün</th>
                        <th className="text-center">Miktar</th>
                        <th>Birim</th>
                      </tr>
                      {this.state.productData.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.productCode + "-" + item.productName}</td>
                            <td className="text-center">{item.piece}</td>
                            <td>
                              {" "}
                              {item.unit === "NIU"
                                ? "Adet"
                                : item.unit === "KGM"
                                  ? "kg"
                                  : item.unit === "GRM"
                                    ? "gr"
                                    : item.unit === "MTR"
                                      ? "Metre"
                                      : item.unit === "LTR"
                                        ? "Litre"
                                        : item.unit === "PA"
                                          ? "Paket"
                                          : item.unit === "BX"
                                            ? "Kutu"
                                            : item.unit === "CMT"
                                              ? "cm"
                                              : item.unit === "MTQ"
                                                ? "m3"
                                                : item.unit === "MTK"
                                                  ? "m2"
                                                  : item.unit === "ROLL"
                                                    ? "Rulo"
                                                    : item.unit === "SET"
                                                      ? "Set"
                                                      : item.unit === "CMQ"
                                                        ? "cm3"
                                                        : item.unit === "SAA" ? "Saat" : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  </>
                )}

              {this.state.productionData !== null &&
                this.state.productionData.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.productionData !== null &&
                            self.productionData.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Üretim Alet veya Cihazları</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Alet veya Cihaz</th>
                        <th></th>
                      </tr>
                      {this.state.productionData.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.materialName}</td>
                            <td>
                              <CgnBadge
                                color="success"
                                style={{
                                  width: "180px",
                                  backgroundColor: "#791FE5",
                                  display:
                                    item.locationEntryType === 1 ? "" : "none",
                                }}
                                className="ml-3 mt-2"
                                text="Giriş"
                              />
                              <CgnBadge
                                color="success"
                                style={{
                                  width: "180px",
                                  backgroundColor: "#F57817",
                                  display:
                                    item.locationEntryType === 2 ? "" : "none",
                                }}
                                className="ml-3 mt-2"
                                text="Teslim Alındı"
                              />
                              <CgnBadge
                                color="success"
                                style={{
                                  width: "180px",
                                  display:
                                    item.locationEntryType === 3 ? "" : "none",
                                }}
                                className="ml-3 mt-2"
                                text="İade Edildi"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  </>
                )}

              {this.state.qualityData !== null &&
                this.state.qualityData.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.qualityData !== null &&
                            self.qualityData.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Kalite Alet veya Cihazları</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Alet veya Cihaz</th>
                        <th></th>
                      </tr>
                      {this.state.qualityData.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.materialName}</td>
                            <td>
                              <CgnBadge
                                color="success"
                                style={{
                                  width: "180px",
                                  backgroundColor: "#791FE5",
                                  display:
                                    item.locationEntryType === 1 ? "" : "none",
                                }}
                                className="ml-3 mt-2"
                                text="Giriş"
                              />
                              <CgnBadge
                                color="success"
                                style={{
                                  width: "180px",
                                  backgroundColor: "#F57817",
                                  display:
                                    item.locationEntryType === 2 ? "" : "none",
                                }}
                                className="ml-3 mt-2"
                                text="Teslim Alındı"
                              />
                              <CgnBadge
                                color="success"
                                style={{
                                  width: "180px",
                                  display:
                                    item.locationEntryType === 3 ? "" : "none",
                                }}
                                className="ml-3 mt-2"
                                text="İade Edildi"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  </>
                )}

              {this.state.stepData !== null && this.state.stepData.length > 0 && (
                <>
                  <div
                    className="row"
                    style={{
                      display:
                        self.stepData !== null && self.stepData.length > 0
                          ? ""
                          : "none",
                    }}
                  >
                    <h2 className="mt-2">İş Emri Adımları</h2>
                  </div>

                  {this.state.stepData.map(function (item) {
                    return (
                      (item.personWorkInstructionStatus || item.personGuid === localStorage.getItem("membershipGuid")) &&
                      (<>
                        <Table className="table table-responsive table-hover table-product2">
                          <tr style={mainRow}>
                            <th>İş Emri Adımı</th>
                            <th></th>
                          </tr>
                          <tr style={subRow}>
                            <td className="font-weight-bold" style={{ paddingTop: "15px" }}>
                              {item.workOrderStepName}
                            </td>
                            <td>
                              <CgnButton
                                type="button"
                                color="success"
                                className="printButton ml-3"
                                onClick={() => self1.handleStartDate(item.guid)}
                                style={{
                                  display:
                                    ((item.endDateChange === 2 ||
                                      item.endDateChange === 0) &&
                                      item.dateStatusChange !== 2) ||
                                      item.qualityStituation === 3
                                      ? ""
                                      : "none",
                                }}
                                text="Başlat"
                              />
                              <CgnButton
                                type="button"
                                color="warning"
                                style={{
                                  width: "90px",
                                  display:
                                    item.dateStatusChange !== 2 ? "" : "none",
                                }}
                                className="ml-3"
                                onClick={() =>
                                  self1.handleUpdateDate(item.guid)
                                }
                                text="Duraklat"
                              />
                              <CgnButton
                                type="button"
                                color="danger"
                                style={{
                                  width: "90px",
                                  display:
                                    item.dateStatusChange !== 2 ? "" : "none",
                                }}
                                className="ml-3"
                                onClick={() => self1.handleEndDate(item.guid)}
                                text="Bitir"
                              />

                              <CgnBadge
                                color="primary"
                                className="ml-3"
                                text="Tamamlandı"
                                style={{
                                  display:
                                    (item.dateStatusChange === 2 &&
                                      item.qualityStituation === 2) ||
                                      (item.dateStatusChange === 2 &&
                                        item.qualityStituation === 1)
                                      ? ""
                                      : "none",
                                }}
                              />

                              <CgnButton
                                type="button"
                                color="primary"
                                style={{
                                  width: "180px",
                                  display:
                                    item.dateStatusChange === 2 &&
                                      item.qualityStituation === 1
                                      ? ""
                                      : "none",
                                }}
                                className="ml-3 mt-1"
                                text="Kaliteye Gönder"
                                onClick={() =>
                                  self1.handleSendQuality(item.guid)
                                }
                              />

                              <CgnBadge
                                color="primary"
                                style={{
                                  width: "180px",
                                  display:
                                    item.qualityStituation === 2 ? "" : "none",
                                }}
                                className="ml-3"
                                text="Kaliteye Gönderildi"
                              />

                              <Button
                                type="button"
                                color="primary"
                                style={{
                                  width: "180px",
                                  display:
                                    item.qualityStituation === 2 ||
                                      item.qualityStatus === 1
                                      ? ""
                                      : "none",
                                }}
                                className="ml-3"
                                text="Kalite Geçmişi"
                                href={`/${self.pageTitleQualityHistoryLink}/${item.guid}`}
                                target="_blank"
                              >
                                Kalite Geçmişi
                              </Button>

                              <CgnBadge
                                color="primary"
                                style={{
                                  width: "180px",
                                  backgroundColor: "#791FE5",
                                  display:
                                    item.qualityStituation === 3 ? "" : "none",
                                }}
                                className="ml-3"
                                text="Uygunsuz"
                              />

                              <CgnBadge
                                color="danger"
                                style={{
                                  width: "180px",
                                  display:
                                    item.qualityStituation === 2 &&
                                      item.qualityStatusChange === 1
                                      ? ""
                                      : "none",
                                }}
                                className="ml-3"
                                text="Bekliyor"
                              />

                              <CgnBadge
                                color="danger"
                                style={{
                                  width: "180px",
                                  display:
                                    item.qualityStituation === 2 &&
                                      item.qualityStatusChange === 4
                                      ? ""
                                      : "none",
                                }}
                                className="ml-3"
                                text="İşlemde"
                              />

                              <CgnBadge
                                color="success"
                                style={{
                                  width: "180px",
                                  display:
                                    item.qualityStituation === 2 &&
                                      item.qualityStatusChange === 3
                                      ? ""
                                      : "none",
                                }}
                                className="ml-3 mt-2"
                                text="Uygun"
                              />
                            </td>
                          </tr>
                          <tr style={subRow}>
                            <td colSpan={2}>{item.personName} / {item.personWorkInstructionStatus ? "Görünsün" : "Görünmesin!"}</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              {item.stepImageDto &&
                                item.stepImageDto.map(function (item1) {
                                  return (
                                    <>
                                      <div className="pl-2">
                                        <Button
                                          onClick={() =>
                                            self1.fileClick(item1.image)
                                          }
                                          className="modalButtonDetail"
                                        >
                                          <img
                                            src={pdfIcon}
                                            style={fotoStyle2}
                                            alt={item1.imageName}
                                          />
                                        </Button>
                                        <p style={{ maxWidth: "150px" }}>
                                          {item1.imageName}
                                        </p>
                                      </div>
                                    </>
                                  );
                                })}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="p-1">
                              <Table className="table table-responsive table-hover table-product2">
                                {item.workOrderDateDto &&
                                  item.workOrderDateDto.map(function (item1) {
                                    return (
                                      <>
                                        <tr style={mainRow}>
                                          <th style={mainRow}>
                                            Başlangıç Tarihi
                                          </th>
                                          <th style={mainRow}>Bitiş Tarihi</th>
                                          <th style={mainRow}>
                                            iş Emri Süresi
                                          </th>
                                        </tr>
                                        <tr style={subRow}>
                                          <td>{item1.startDate}</td>
                                          <td>{item1.endDate}</td>
                                          <td>
                                            {self1.DateCalculate(
                                              item1.startDateDateTime,
                                              item1.endDateDateTime
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                              </Table>
                            </td>
                          </tr>
                        </Table>
                      </>
                      )
                    );
                  })}

                </>
              )}

              {this.state.productOutImageDto &&
                this.state.productOutImageDto.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.productOutImageDto !== null &&
                            self.productOutImageDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Dosyalar</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Dosya</th>
                      </tr>
                      <Row>
                        {this.state.productOutImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-2">
                                <Button
                                  onClick={() => self1.fileClick(item.image)}
                                  className="modalButtonDetail"
                                >
                                  <img
                                    src={pdfIcon}
                                    style={fotoStyle2}
                                    alt={item.imageName}
                                  />
                                </Button>
                                <p style={{ maxWidth: "150px" }}>
                                  {item.imageName}
                                </p>
                              </div>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}

              <Row>
                <CgnButton
                  type="button"
                  color="danger"
                  style={{
                    width: "200px",
                    position: "relative", right: 2,
                    display: (this.state.lastControl === 1 && this.state.lastControlButton === 0) ? "" : "none",
                  }}
                  className="mt-2 float-right"
                  text="Son Kontrole Gönder"
                  onClick={() => self1.handleSendLastControl(this.state.guid)}
                />
                <CgnBadge
                  color="warning"
                  style={{
                    width: "200px",
                    position: "relative", right: 2,
                    display: (this.state.lastControlButton === 0) ? "none" : "",
                  }}
                  className="mt-2 float-right"
                  text="Son Kontrole Gönderildi"
                />
              </Row>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PersonelWorkInstructionDetail;
