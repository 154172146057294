
import moment from "moment";
import { URL } from "../../redux/actions/constants";
import Dashboard from "./Dashboard";
import axios from "axios";

let isRequestPending = false;

axios.interceptors.request.use(async (config) => {
  while (isRequestPending) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }
  isRequestPending = true;
  if (config.headers.Authorization !== null && config.headers.Authorization !== undefined) {
    if (localStorage.getItem("token")) {
      var dateNow = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
      moment.locale("tr");
      var tokenDateTime = moment(localStorage.getItem("expiration")).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      if (tokenDateTime < dateNow) {
        const response = await fetch(URL + "/auth/refresh", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            guid: localStorage.getItem("guid"),
            refreshToken: localStorage.getItem("refreshToken")
          })
        });
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("token", data.token);
          localStorage.setItem("refreshToken", data.refreshToken);
          localStorage.setItem("expiration", data.expiration);
          config.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
          return config;
        } else {
          localStorage.clear();
          window.location.href = "/";
        }
      } else {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
        return config;
      }
    }
  } else {
    return config;
  }
}, error => {
  isRequestPending = false;
  return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  isRequestPending = false;
  return response;
}, error => {
  isRequestPending = false;
  return Promise.reject(error);
});

function App() {
  return <Dashboard />;
}

export default App;