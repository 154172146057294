import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { localFilePath, URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButtonLinkGroup from "../toolbox/CgnButtonLinkGroup";
import noImg from "../../images/no-img.png";

class ProductList extends Component {
  state = {
    pageTitle: "Ürün Listele",
    pageAddLink: "urun-ekle",
    pageUpdateLink: "urun-guncelle",
    pageDetailLink: "urun-detay",
    pageListLink: "urun-listele",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    productData: [],
    name: "",
    categoryName: "",
    salePrice: "",
    guid: "",
    currencyType: 0,
    alertErrorMessage: "",
    activeTab: "1",
    categoryArr: [],
    buyPriceTotal: 0,
    buyPriceTotalDolar: 0,
    buyPriceTotalEuro: 0,
    buyPriceTotalSFr: 0,
    salePriceTotal: 0,
    salePriceTotalDolar: 0,
    salePriceTotalEuro: 0,
    salePriceTotalSFr: 0,
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/categories/getlistselect/", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            to: "/urun-listele/" + item.guid,
            text: item.name,
            color: "secondary",
          };
        });
        myArr.push({ to: "/urun-listele", text: "Tümü", color: "primary" });
        this.setState({
          categoryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    this.getList(null);
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      if (this.state.categoryGuid !== urlSplit[urlSplit.length - 1]) {
        await this.setState({
          isLoading: true,
          categoryGuid: urlSplit[urlSplit.length - 1],
        });
        this.state.categoryArr.forEach((item) => {
          if ("/urun-listele/" + this.state.categoryGuid === item.to) {
            item.color = "primary";
          } else {
            item.color = "secondary";
          }
        });
        this.getList();
      }
    } else if (urlSplit.length === 2) {
      if (this.state.categoryGuid !== null) {
        await this.setState({
          isLoading: true,
          categoryGuid: null,
        });
        this.state.categoryArr.forEach((item) => {
          if ("/urun-listele" === item.to) {
            item.color = "primary";
          } else {
            item.color = "secondary";
          }
        });
        this.getList();
      }
    }
  }

  async getList() {
    var apiURL = "";
    if (this.state.categoryGuid) {
      apiURL = "/products/getlistcategory/" + this.state.categoryGuid;
    } else {
      apiURL = "/products/getlist/1";
    }
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + apiURL, config)
      .then((response) => {
        this.setState({
          productData: response.data,
        });
        var buyPriceTotalLocal = 0;
        var buyPriceTotalLocalDolar = 0;
        var buyPriceTotalLocalEuro = 0;
        var buyPriceTotalLocalSFr = 0;
        var salePriceTotalLocal = 0;
        var salePriceTotalLocalDolar = 0;
        var salePriceTotalLocalEuro = 0;
        var salePriceTotalLocalSFr = 0;
        this.state.productData.forEach((element) => {
          if (element.currencyType === 1) {
            buyPriceTotalLocal += element.buyPrice;
            salePriceTotalLocal += element.salePrice;
          } else if (element.currencyType === 2) {
            buyPriceTotalLocalDolar += element.buyPrice;
            salePriceTotalLocalDolar += element.salePrice;
          } else if (element.currencyType === 3) {
            buyPriceTotalLocalEuro += element.buyPrice;
            salePriceTotalLocalEuro += element.salePrice;
          } else if (element.currencyType === 3) {
            buyPriceTotalLocalSFr += element.buyPrice;
            salePriceTotalLocalSFr += element.salePrice;
          }
        });
        this.setState({
          buyPriceTotal: buyPriceTotalLocal,
          buyPriceTotalDolar: buyPriceTotalLocalDolar,
          buyPriceTotalEuro: buyPriceTotalLocalEuro,
          buyPriceTotalSFr: buyPriceTotalLocalSFr,
          salePriceTotal: salePriceTotalLocal,
          salePriceTotalDolar: salePriceTotalLocalDolar,
          salePriceTotalEuro: salePriceTotalLocalEuro,
          salePriceTotalSFr: salePriceTotalLocalSFr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/products/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleCopyProduct = async (guid) => {
    confirmAlert({
      title: CgnMessage.copyQuestionTitle,
      message: CgnMessage.copyQuestionMessage,
      buttons: [
        {
          label: CgnMessage.copyQuestionYes,
          onClick: () => this.copyItem(guid),
        },
        {
          label: CgnMessage.copyQuestionNo,
        },
      ],
    });
  };

  async copyItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/products/copy/" + guid, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/products/productPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleBarcodePrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/products/barcodenumberprint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "145px",
  };

  tdStyle = {
    width: "250px",
  };

  tdStyle2 = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}

              {this.state.categoryArr && this.state.categoryArr.length > 0 && (
                <div className="productFilterButton">
                  <CgnButtonLinkGroup items={this.state.categoryArr} />
                </div>
              )}

              <CgnDatatable
                data={this.state.productData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Ürün Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Ürün Kodu</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.code}</td>
                              <th style={this.thStyle2}>Tedarikçi Ürün Kodu</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.supplierProductCode === null ||
                                  row.supplierProductCode === ""
                                  ? "-"
                                  : row.supplierProductCode}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Kategori Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.categoryName}</td>
                              <th style={this.thStyle2}>Ürün Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.name}</td>
                            </tr>

                            <tr>
                              <th style={this.thStyle}>Alış Fiyatı</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.buyPrice > 0
                                  ? row.buyPrice.toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                  }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                      ? "$"
                                      : row.currencyType === 3
                                        ? "€"
                                        : "-")
                                  : "-"}
                              </td>
                              <th style={this.thStyle2}>Satış Fiyatı</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.salePrice > 0
                                  ? row.salePrice.toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                  }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                      ? "$"
                                      : row.currencyType === 3
                                        ? "€"
                                        : "-")
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Raf Bilgisi</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.shelfInformation
                                  ? row.shelfInformation
                                  : "-"}
                              </td>
                              <th style={this.thStyle2}>Stok Durumu</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.stockStatus === 1
                                  ? "Stoklu Ürün"
                                  : "Stoksuz Ürün"}
                              </td>
                            </tr>
                            {row.stockStatus === 1 &&
                              <tr>
                                <th style={this.thStyle}>Stok</th>
                                <td style={this.tdStyle}>
                                  {row.productStock}
                                </td>
                                <th style={this.thStyle2}>Kritik Stok</th>
                                <td>
                                  {row.criticalPieces}
                                </td>
                              </tr>
                            }
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={3}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Kopyala",
                            className: "dropDownBtn",
                            onClick: () => this.handleCopyProduct(row.guid),
                          },
                          {
                            text: "Barkod Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handleBarcodePrint(row.guid),
                          },
                          {
                            text: "Ürün Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfPrint(row.guid),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar (SFr):{" "}
                {this.state.buyPriceTotalSFr.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                SFr
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar (€):{" "}
                {this.state.buyPriceTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar ($):{" "}
                {this.state.buyPriceTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar (₺):{" "}
                {this.state.buyPriceTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar (SFr):{" "}
                {this.state.salePriceTotalSFr.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                SFr
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar (€):{" "}
                {this.state.salePriceTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar ($):{" "}
                {this.state.salePriceTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar (₺):{" "}
                {this.state.salePriceTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductList;
