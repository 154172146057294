import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  optionOfferStatusesOptions,
  URL,
  fotoStyle,
  localFilePath,
  
  unitValuesStatuses,
  fotoStyle2,
  TransactionStatus,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import fileIcon from "../../images/file.jpg";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import NoteAddUpdate from "../notes/NoteAddUpdate";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import CgnCheckbox from "../toolbox/CgnCheckbox";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import excelIcon from "../../images/xlsIcon.png";

class MoldingRoomDetail extends Component {
  state = {
    pageTitle: "Kalıphane Detay",
    pageTitleDetail: "Kalıphane Detay",
    //pageAddLink: "teklif3-ekle",
    pageListLink: "kaliphane",
    breadcrumb: [{ text: "Kalıphane", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    offerNumber: "",
    offerDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    generalDiscountTotal: null,
    generalTaxTotal: null,
    taxTotal: null,
    discountPercent: "",
    language: "",
    customerPhone: "",
    offerStatus: "",
    discountValue: "",
    discountTotal: "",
    taxType: null,
    taxRate: null,
    currencyType: 0,
    description: "",
    offerMoldingRoomDto: [],
    bankName: "",
    productDto: [],
    isHiddenSatinAlma: true,
    customerEmail: "",
    productPieceError: "",
    productUnitPriceError: "",
    productError: "",
    locationError: "",
    unitError: "",
    offerSieveImageDto: [],
    offerSievePictureDto: [],
    productArr: [],
    productEntryDto: [],
    locationArr: [],
    productName: "",
    piece: null,
    isHiddenKaliphane: true,
    unitPrice: null,
    productDesign: "",
    moldDesign: "",
    materialOrder: "",
    vertical: "",
    wire: "",
    render: "",
    foreignAffairs: "",
    deliveryTime: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    wireErosion: "",
    statusText: "",
    statusChange: "",
    processing: "",
    offerCoverImage: "",
    confirmationDate: "",
    counter: -1,
    warrantyPeriodName: "",
    deliveryTimeName: "",
    deliveryTypeName: "",
    paymentTypeName: "",
    workCode: "",
    transactionStatus2: 1,
    counter1: -1,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    approvalDate: "",
    noteDto: [],
    isOpenModalNote: false,
    isOpenModalUpdateNote: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 1;
      await axios
        .get(URL + "/sieveProducts/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return (
              item.categoryName === "SARF MALZEME" && {
                value: item.guid,
                label: `${item.code}-${item.name}`,
                buyPrice: item.buyPrice,
                code: item.code,
              }
            );
          });
          this.setState({
            productArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      await axios
        .get(URL + "/offersieves/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            customerName: response.data.customerName,
            offerNumber: response.data.offerNumber,
            offerDate: response.data.offerDate,
            optionDate:
              response.data.optionDate === "01/01/1900"
                ? "-"
                : response.data.optionDate,
            reminderDate: response.data.reminderDate,
            language: response.data.language,
            productDto: response.data.offerSieveProductDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),
            currencyType: response.data.currencyType,
            customerEmail: response.data.customerEmail,
            customerPhone: response.data.customerPhone,
            paymentTypeName: response.data.paymentTypeName,
            deliveryTimeName: response.data.deliveryTimeName,
            deliveryTypeName: response.data.deliveryTypeName,
            warrantyPeriodName: response.data.warrantyPeriodName,
            description: response.data.description,
            offerCoverImage: response.data.offerCoverImage,
            confirmationDate: response.data.confirmationDate,
            //offerCancelText: response.data.offerCancelText,
            offerSieveImageDto: response.data.offerSieveImageDto,
            offerSievePictureDto: response.data.offerSievePictureDto,
            bankName: response.data.bankName,
            offerStatus: response.data.offerStatus,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            offerMoldingRoomDto: response.data.offerMoldingRoomDto,
            counter: response.data.offerMoldingRoomDto.length - 1,
            productEntryDto: response.data.productEntryDto,
            counter1: response.data.productEntryDto.length - 1,
            workCode: response.data.workCode,
            transactionStatus2: response.data.transactionStatus2,
            moldingRoomImageDto: response.data.moldingRoomImageDto,
            approvalDate: response.data.approvalDate,
            noteDto: response.data.noteDto,
          });

          var offerMoldingRoomDto = this.state.offerMoldingRoomDto;
          this.state.offerMoldingRoomDto.map((item) => {
            if (item.productDesign && item.productDesign !== "") {
              item.productDesignCheckbox = true;
            }
            if (item.moldDesign && item.moldDesign !== "") {
              item.moldDesignCheckbox = true;
            }
            if (item.processing && item.processing !== "") {
              item.processingCheckbox = true;
            }
            if (item.render && item.render !== "") {
              item.renderCheckbox = true;
            }
            if (item.wireErosion && item.wireErosion !== "") {
              item.wireErosionCheckbox = true;
            }
            if (item.foreignAffairs && item.foreignAffairs !== "") {
              item.foreignAffairsCheckbox = true;
            }
            if (item.materialOrder && item.materialOrder !== "") {
              item.materialOrderCheckbox = true;
            }
            if (item.statusText && item.statusText !== "") {
              item.statusTextCheckbox = true;
            }
          });
          this.setState({
            offerMoldingRoomDto: offerMoldingRoomDto,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  uploadedImage3 = async (event) => {
    let array = this.state.offerSieveImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    }
    await this.setState({ offerSieveImageDto: array });
  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.offerSieveImageDto;
    this.state.offerSieveImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ offerSieveImageDto: array });
        }
      }
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenSatinAlma: false,
      });
    } else {
      this.setState({
        isHiddenSatinAlma: true,
      });
    }
  };

  handleKaliphaneCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenKaliphane: false,
        isHiddenSatinAlma: true,
        productEntryDto: [],
      });
    } else {
      this.setState({
        isHiddenKaliphane: true,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      alertErrorMessage: "",
    });
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handleOfferStatusChange(option) {
    if (option) {
      this.setState({
        offerStatus: option.value,
      });
    } else {
      this.setState({
        offerStatus: "",
      });
    }
  }

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productEntryDto = this.state.productEntryDto;
    productEntryDto[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productEntryDto: productEntryDto,
    });
  }

  handleUnitChange(option, idx) {
    let productEntryDto = this.state.productEntryDto;
    if (option) {
      productEntryDto[idx].unit = option.value;
      productEntryDto[idx].unitError = "";
      this.setState({
        productEntryDto: productEntryDto,
      });
    } else {
      productEntryDto[idx].unit = "";
      productEntryDto[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productEntryDto: productEntryDto,
      });
    }
  }

  handleProductPieceTextBoxChange(e, idx) {
    let productEntryDto = this.state.productEntryDto;
    productEntryDto[idx].piece = parseFloat(e.target.value);
    this.setState({
      productEntryDto: productEntryDto,
    });
  }

  // handleSave = async (event) => {
  //   event.preventDefault();

  // };

  handleChange2 = (idx) => (event) => {
    const { name, value } = event.target;
    const split = name.split("_");
    let offerMoldingRoomDto = this.state.offerMoldingRoomDto;
    offerMoldingRoomDto[idx][split[0]] = value;
    this.setState({
      offerMoldingRoomDto: offerMoldingRoomDto,
    });
  };

  handleTypeChange = (idx) => (event) => {
    const { name, checked } = event.target;
    const split = name.split("_");
    let offerMoldingRoomDto = this.state.offerMoldingRoomDto;
    offerMoldingRoomDto[idx][split[0]] = checked;
    this.setState({
      offerMoldingRoomDto: offerMoldingRoomDto,
    });
  };

  async handleProductRemove(idx) {
    let someArray = await this.state.offerMoldingRoomDto;

    await someArray.splice(idx, 1);

    await this.setState({
      offerMoldingRoomDto: someArray,
      counter: this.state.counter - 1,
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleProductAdd = async () => {
    let array = this.state.offerMoldingRoomDto;
    await array.push({
      productDesign: "",
      moldDesign: "",
      processing: "",
      render: "",
      wireErosion: "",
      statusText: "",
      materialOrder: "",
      foreignAffairs: "",
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    this.setState({ offerMoldingRoomDto: array });
  };

  handleLocationInputValueChange(option, idx) {
    let productEntryDto = this.state.productEntryDto;
    if (option) {
      productEntryDto[idx].locationGuid = option.value;
      productEntryDto[idx].locationError = "";
      this.setState({
        productEntryDto: productEntryDto,
      });
    } else {
      productEntryDto[idx].locationGuid = "";
      productEntryDto[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productEntryDto: productEntryDto,
      });
    }
  }

  handleProductInputValueChange(option, idx) {
    let productEntryDto = this.state.productEntryDto;
    if (option) {
      productEntryDto[idx].sieveProductGuid = option.value;
      productEntryDto[idx].piece = 1;
      productEntryDto[idx].unitPrice = option.buyPrice;
      productEntryDto[idx].productError = "";
      productEntryDto[idx].productPieceError = "";
      productEntryDto[idx].productUnitPriceError = "";
      this.setState({
        productEntryDto: productEntryDto,
      });
    } else {
      productEntryDto[idx].sieveProductGuid = null;
      productEntryDto[idx].piece = 0;
      productEntryDto[idx].unitPrice = 0;
      productEntryDto[idx].productError = CgnMessage.textErrorMessage;
      productEntryDto[idx].productPieceError = CgnMessage.textErrorMessage;
      productEntryDto[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productEntryDto: productEntryDto,
      });
    }
    this.ProductPriceCalculate();
  }

  ProductPriceCalculate = () => {
    var totalProductPrice = 0;
    this.state.productEntryDto.forEach((item) => {
      item.total = (
        parseFloat(item.piece) * parseFloat(item.unitPrice)
      ).toFixed(2);
      totalProductPrice += parseFloat(item.total);
    });
    this.state.total = parseFloat(totalProductPrice).toFixed(2); // loop girmemesi için setState kullanmadım.
  };

  async handleProductRemove2(idx) {
    let someArray = await this.state.productEntryDto;

    await someArray.splice(idx, 1);

    await this.setState({
      productEntryDto: someArray,
      counter1: this.state.counter1 - 1,
    });

    var generalTotal1 = 0;
    await this.state.productEntryDto.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleProductAdd2 = async () => {
    let array = this.state.productEntryDto;
    await array.push({
      sieveProductGuid: "",
      locationGuid: "",
      piece: 1,
      unitPrice: 0,
      unit: "",
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter1: this.state.counter1 + 1,
    });
    if (array[this.state.counter1].sieveProductGuid) {
      return array[this.state.counter1].productError === "";
    }
    if (array[this.state.counter1].locationGuid) {
      return array[this.state.counter1].locationError === "";
    }
    if (array[this.state.counter1].piece) {
      return array[this.state.counter1].productPieceError === "";
    }
    if (array[this.state.counter1].unitPrice) {
      return array[this.state.counter1].productUnitPriceError === "";
    }
    if (array[this.state.counter1].unit) {
      return array[this.state.counter1].unitError === "";
    }

    if (
      array[this.state.counter1].sieveProductGuid === "" ||
      array[this.state.counter1].locationGuid === "" ||
      array[this.state.counter1].piece === 0 ||
      array[this.state.counter1].unit === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productEntryDto: array });
  };

  handleSave2 = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });
    if (this.state.productEntryDto) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.transactionStatus2 === "" &&
      this.state.transactionStatus2 === null
    ) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (
      this.state.productEntryDto !== null ||
      this.state.productEntryDto.length > 0
    ) {
      this.state.productEntryDto.forEach((element) => {
        if (
          element.unit === "" ||
          element.sieveProductGuid === "" ||
          element.locationGuid === "" ||
          element.piece === ""
        ) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (this.state.productEntryDto && control === true) {
      this.setState({
        isLoading: true,
      });

      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var myObj = {
        productEntryDto: this.state.productEntryDto,
        workCode: this.state.workCode,
        transactionStatus2: this.state.transactionStatus2,
        offerGuid: this.state.guid,
        offerSieveProductDto: this.state.productDto,
        offerSieveImageDto: this.state.offerSieveImageDto,
      };
      var URLParam = "/offerSieveProductEntries/update";

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/kaliphane";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    // save
    this.setState({
      alertErrorMessage: "",
    });
    if (this.state.offerMoldingRoomDto) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    this.setState({
      isLoading: true,
    });

    // 
    config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    myObj = {
      offerMoldingRoomDto: this.state.offerMoldingRoomDto,
      offerGuid: this.state.guid,
    };
    URLParam = "/moldingRooms/update";

    axios
      .post(URL + URLParam, myObj, config)
      .then(() => {
        setTimeout(() => {
          if (this.state.guid) {
            window.location.href = "/kaliphane";
          } else {
            window.location.reload();
          }
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleTransactionStatusSelectChange(transactionStatus2) {
    if (transactionStatus2) {
      this.setState({
        transactionStatus2: transactionStatus2.value,
      });
    }
  }

  handleEstimatedDeliveryDateChange2(estimatedDeliveryDate, idx) {
    let productDto = this.state.productDto;
    if (estimatedDeliveryDate) {
      productDto[idx].estimatedDeliveryDate = estimatedDeliveryDate;
      this.setState({
        productDto: productDto,
      });
    } else {
      productDto[idx].estimatedDeliveryDate = "";
      this.setState({
        productDto: productDto,
      });
    }
  }

  handleNoteDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleNoteSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleNoteSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/notes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDeliveryDateChange2(deliveryDate, idx) {
    let productDto = this.state.productDto;
    if (deliveryDate) {
      productDto[idx].deliveryDate = deliveryDate;
      this.setState({
        productDto: productDto,
      });
    } else {
      productDto[idx].deliveryDate = "";
      this.setState({
        productDto: productDto,
      });
    }
  }

  addModalNote = () => {
    this.setState({
      isOpenModalNote: !this.state.isOpenModalNote,
      noteGuid: null,
    });
  };

  addModalUpdateNote = (guid) => {
    this.setState({
      isOpenModalUpdateNote: !this.state.isOpenModalUpdateNote,
      noteGuid: guid,
    });
  };

  render() {
    const sira = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "20px",
      textAlign: "center",
    };
    const siraTd = {
      backgroundColor: "#f00",
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    };
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const mainRowDate = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "215px",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self1 = this;
    var self = this.state;

    var phone = this.state.customerPhone,
      maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
        } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
          9,
          11
        )}`;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          noteLink={(onClick) => this.addModalNote()}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                {/* <tr>
                  <th>Teklif Dili</th>
                  <td colSpan="4">
                    {this.state.language === 1
                      ? "Türkçe"
                      : this.state.language === 2
                      ? "İngilizce"
                      : this.state.language === 3
                      ? "Fransızca"
                      : this.state.language === 4
                      ? "Almanca"
                      : this.state.language === 5
                      ? "Arapça"
                      : "-"}
                  </td>
                </tr> */}
                <tr>
                  <th>Tarih</th>
                  <td colSpan="4">{this.state.approvalDate}</td>
                </tr>
                <tr>
                  <th>Sipariş No</th>
                  <td colSpan="4">{this.state.offerNumber}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>
                {/* <tr>
                  <th>Müşteri E-mail</th>
                  <td colSpan="4">
                    {this.state.customerEmail === ""
                      ? "-"
                      : this.state.customerEmail}
                  </td>
                </tr>
                <tr>
                  <th>Müşteri Telefon</th>
                  <td colSpan="4">
                    {this.state.customerPhone === "" ? "-" : maskPhone}
                  </td>
                </tr>
                <tr>
                  <th>Teklif Tarihi</th>
                  <td colSpan="4">{this.state.offerDate}</td>
                </tr>
                
                <tr>
                  <th>Opsiyon Tarihi</th>
                  <td colSpan="4">{this.state.optionDate}</td>
                </tr>

                <tr>
                  <th>Hatırlatma Tarihi</th>
                  <td colSpan="4">{this.state.reminderDate}</td>
                </tr>

                <tr>
                  <th>Ara Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam İndirim</th>
                  <td colSpan="4">
                    {this.state.generalDiscountTotal
                      ? this.state.generalDiscountTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam KDV Tutarı</th>
                  <td colSpan="4">
                    {this.state.generalTaxTotal
                      ? this.state.generalTaxTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Ödeme Yöntemi </th>
                  <td colSpan="4">{this.state.paymentTypeName}</td>
                </tr> */}

                <tr>
                  <th>Teslimat Yöntemi </th>
                  <td colSpan="4">{this.state.deliveryTypeName}</td>
                </tr>

                <tr>
                  <th>Teslimat Süresi </th>
                  <td colSpan="4">{this.state.deliveryTimeName}</td>
                </tr>

                {/* <tr>
                  <th>Garanti Süresi </th>
                  <td colSpan="4">{this.state.warrantyPeriodName}</td>
                </tr>

                <tr>
                  <th>Teklif Notu</th>
                  <td
                    colSpan="4"
                    className="text-danger font-weight-bold"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description
                        ? this.state.description
                        : "-",
                    }}
                  ></td>
                </tr>

                <tr>
                  <th>Teklif Kapağı</th>
                  <td colSpan="4">
                    {this.state.offerCoverImage ? (
                      <Button
                        onClick={this.toggle}
                        className="modalButtonDetail"
                      >
                        <img
                          src={this.state.offerCoverImage}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      </Button>
                    ) : (
                      <img
                        src={noImg}
                        style={fotoStyle}
                        alt={this.state.customerName}
                      />
                    )}
                  </td>
                </tr>

                <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}></ModalHeader>
                  <img
                    className="modalofferCoverImage"
                    src={this.state.offerCoverImage}
                    style={fotoStyle}
                    alt={this.state.customerName}
                  />
                </Modal>

                <tr>
                  <th>Teklif Onay/İptal Tarihi Saati</th>
                  <td colSpan="4">{this.state.confirmationDate}</td>
                </tr>

                <tr>
                  <th>Banka</th>
                  <td colSpan="4">{this.state.bankName}</td>
                </tr>
                <tr>
                  <th>Durum</th>
                  <td colSpan="4">
                    {optionOfferStatusesOptions(this.state.status)}
                  </td>
                </tr>
                 */}
                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              {this.state.moldingRoomImageDto &&
                this.state.moldingRoomImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Kalıphane Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.moldingRoomImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={fileIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              <Form onSubmit={this.handleSave2} className="mt-3">
                <Row>
                  <Col lg="12" className="pl-0">
                    <ImageUploadMulti
                      images={this.state.offerSieveImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx"
                      className="text-center text-danger"
                      label={"Belgeler"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      error={this.state.offerSieveImageDtoError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="workCode"
                      label="İş Notu"
                      value={this.state.workCode}
                      placeHolder="Lütfen iş kodu girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="transactionStatus2"
                      label="İşlem Durumu [*]"
                      placeholder="Lütfen işlem durumu seçiniz..."
                      selectValue={this.state.transactionStatus2}
                      isMulti={false}
                      options={TransactionStatus}
                      onChange={(transactionStatus2) =>
                        this.handleTransactionStatusSelectChange(
                          transactionStatus2
                        )
                      }
                    />
                  </Col>
                </Row>
                {this.state.productEntryDto.map((productEntryDto, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="2" className="modalSelectCol">
                      <CgnReactSelect
                        name="locationGuid"
                        label="Depo [*]"
                        placeholder="Lütfen depo seçiniz..."
                        selectValue={productEntryDto.locationGuid}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleLocationInputValueChange(option, idx);
                        }}
                        options={this.state.locationArr}
                        error={productEntryDto.locationError}
                      />
                    </Col>
                    <Col lg="5" className="modalSelectCol">
                      <CgnReactSelect
                        name="sieveProductGuid"
                        label="Ürün [*]"
                        placeholder="Lütfen ürün seçiniz..."
                        selectValue={productEntryDto.sieveProductGuid}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleProductInputValueChange(option, idx);
                        }}
                        options={this.state.productArr}
                        error={productEntryDto.productError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnReactSelect
                        name="unit"
                        label="Birim [*]"
                        placeholder="Birim"
                        selectValue={productEntryDto.unit}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) =>
                          this.handleUnitChange(option, idx)
                        }
                        options={unitValuesStatuses}
                        error={productEntryDto.unitError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={productEntryDto.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productEntryDto.productPieceError}
                      />
                    </Col>

                    {/* <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="unitPrice"
                        label="Birim Fiyat [*]"
                        value={productEntryDto.unitPrice}
                        onChange={(e) =>
                          this.handleProductUnitPriceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen birim fiyat girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productEntryDto.productUnitPriceError}
                      />
                    </Col> */}

                    <Col lg="1" sm="3">
                      <CgnButton
                        type="button"
                        color="danger"
                        className="dynamicRowDeleteButton"
                        onClick={() => this.handleProductRemove2(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col className="pl-0">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton mb-3"
                      onClick={this.handleProductAdd2}
                      text={"Ürün Ekle"}
                    />
                  </Col>
                </Row>

                {this.state.productDto !== null &&
                  this.state.productDto.length > 0 &&
                  this.state.productDto.map((productDto, idx) =>
                    productDto.isMoldingRoom ? (
                      <>
                        <Table className="table table-responsive table-hover table-product mt-1">
                          <tr className="">
                            <th style={sira}>Sıra</th>
                            <th className="text-left pl-3" style={mainRow}>
                              Depo
                            </th>
                            <th
                              colSpan={3}
                              className="text-left pl-3"
                              style={mainRow}
                            >
                              Ürün Adı
                            </th>
                            <th className="text-left pl-3" style={mainRow}>
                              Adet
                            </th>
                            <th className="text-left pl-3" style={mainRow}>
                              Birim
                            </th>
                            {/* <th className="text-center" style={mainRow}>
                          Birim Fiyatı{" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Yüzde){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Net Tutar){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          KDV Oranı{" "}
                        </th>
                        <th style={mainRow}>Fiyat</th> */}
                          </tr>
                          <tr style={subRow}>
                            <td style={siraTd}>{idx + 1}</td>
                            <td className="text-left pl-3">
                              {productDto.locationName}
                            </td>
                            <td colSpan={3} className="text-left pl-3">
                              {productDto.productName}
                            </td>
                            <td className="text-left pl-3">
                              {productDto.piece}
                            </td>
                            <td className="text-left pl-3">
                              {productDto.unit === "NIU"
                                ? "Adet"
                                : productDto.unit === "KGM"
                                  ? "kg"
                                  : productDto.unit === "GRM"
                                    ? "gr"
                                    : productDto.unit === "MTR"
                                      ? "Metre"
                                      : productDto.unit === "LTR"
                                        ? "Litre"
                                        : productDto.unit === "PA"
                                          ? "Paket"
                                          : productDto.unit === "BX"
                                            ? "Kutu"
                                            : productDto.unit === "CMT"
                                              ? "cm"
                                              : productDto.unit === "MTQ"
                                                ? "m3"
                                                : productDto.unit === "MTK"
                                                  ? "m2"
                                                  : productDto.unit === "ROLL"
                                                    ? "Rulo"
                                                    : productDto.unit === "SET"
                                                      ? "Set"
                                                      : productDto.unit === "CMQ"
                                                        ? "cm3"
                                                        : productDto.unit === "SAA"
                                                        ? "Saat"
                                                        : "-"}
                            </td>
                            {/* <td className="text-center">
                          {item.unitPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>

                        <td className="text-center">
                          {item.productDiscountPercent
                            ? "% " + item.productDiscountPercent
                            : "-"}
                        </td>
                        <td className="text-center">
                          {item.productDiscountValue &&
                          item.productDiscountValue > 0
                            ? item.productDiscountValue +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : "-")
                            : "-"}
                        </td>
                        <td className="text-center">
                          {"% " + item.productTaxRate}
                        </td>
                        <td>
                          {item.total.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td> */}
                          </tr>
                          <tr>
                            <th
                              colSpan={2}
                              className="text-left pl-3"
                              style={mainRow}
                            >
                              Makine Boyutu
                            </th>
                            <th style={mainRow} className="text-left pl-3">
                              Elek Boyutu
                            </th>
                            <th
                              colSpan={2}
                              style={mainRow}
                              className="text-left pl-3"
                            >
                              Elek Göz Boyutu
                            </th>
                            <th style={mainRowDate} className="text-left pl-3">
                              Müşteri Tahmini Teslimat
                            </th>
                            <th style={mainRowDate} className="text-left pl-3">
                              Kalem Sevkiyat
                            </th>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              {productDto.machineSize !== ""
                                ? productDto.machineSize
                                : "-"}
                            </td>
                            <td className="text-left pl-3">
                              {productDto.sieveSize !== ""
                                ? productDto.sieveSize
                                : "-"}
                            </td>
                            <td colSpan={2} className="text-left pl-3">
                              {productDto.sieveEyeSize !== ""
                                ? productDto.sieveEyeSize
                                : "-"}
                            </td>
                            <td className="text-left pl-3">
                              {productDto.customerEstimatedDeliveryDateDetail !==
                                ""
                                ? productDto.customerEstimatedDeliveryDateDetail
                                : "-"}
                            </td>
                            <td className="text-left pl-3">
                              {productDto.shipmentDateDetail !== ""
                                ? productDto.shipmentDateDetail
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              colSpan={7}
                              className="text-left pl-3"
                              style={mainRow}
                            >
                              Kalıphane Notu
                            </th>
                          </tr>
                          <tr>
                            <td colSpan={7}>
                              {productDto.moldingRoomNote !== ""
                                ? productDto.moldingRoomNote
                                : "-"}
                            </td>
                          </tr>

                          {productDto.sieveProductPropertyDto != null &&
                            productDto.sieveProductPropertyDto.length > 0 && (
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#1D4B8C",
                                    color: "#fff",
                                  }}
                                  colSpan="7"
                                  className="text-left"
                                >
                                  Ürün Özellikleri
                                </th>
                              </tr>
                            )}
                          {productDto.sieveProductPropertyDto.map(function (
                            item2
                          ) {
                            return (
                              <tr style={subRow}>
                                <td colSpan={3} className="text-left">
                                  {item2.propertyTypeName}
                                </td>
                                <td colSpan={4} className="text-left">
                                  {" "}
                                  {item2.value}
                                </td>
                              </tr>
                            );
                          })}
                          {productDto.offerSievesProductsShippingDto != null &&
                            productDto.offerSievesProductsShippingDto.length >
                            0 && (
                              <tr>
                                <th
                                  style={{
                                    backgroundColor: "#1D4B8C",
                                    color: "#fff",
                                  }}
                                  colSpan="4"
                                ></th>
                                <th
                                  style={{
                                    backgroundColor: "#1D4B8C",
                                    color: "#fff",
                                  }}
                                  className="text-left pl-3"
                                >
                                  Adet
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#1D4B8C",
                                    color: "#fff",
                                  }}
                                  className="text-left pl-3"
                                >
                                  Hazır Tarihi
                                </th>
                                <th
                                  style={{
                                    backgroundColor: "#1D4B8C",
                                    color: "#fff",
                                  }}
                                  className="text-left pl-3"
                                >
                                  Kalem Sevkiyat
                                </th>
                              </tr>
                            )}
                          {productDto.offerSievesProductsShippingDto.map(
                            function (item2) {
                              return (
                                <tr style={subRow}>
                                  <td
                                    colSpan={4}
                                    className="text-left pl-3"
                                  ></td>
                                  <td className="text-left pl-3">
                                    {item2.piece}
                                  </td>
                                  <td className="text-left pl-3">
                                    {item2.readyDateDetail}
                                  </td>
                                  <td className="text-left pl-3">
                                    {item2.shippingDateDetail !== "01 Ocak 1900"
                                      ? item2.shippingDateDetail
                                      : "-"}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </Table>
                        <Row>
                          <Col lg="8"></Col>
                          <Col lg="2" className="pt-3">
                            <CgnDatePicker
                              name="deliveryDate"
                              label="Tahmini Teslimat Tarihi"
                              selected={
                                productDto.deliveryDate &&
                                  productDto.deliveryDate
                                    .toString()
                                    .indexOf("/") > 0
                                  ? new Date(
                                    productDto.deliveryDate.split("/")[2] +
                                    "-" +
                                    productDto.deliveryDate.split("/")[1] +
                                    "-" +
                                    productDto.deliveryDate.split("/")[0]
                                  )
                                  : productDto.deliveryDate
                              }
                              onChange={(deliveryDate) =>
                                this.handleDeliveryDateChange2(
                                  deliveryDate,
                                  idx
                                )
                              }
                              maxDate={new Date(2100, 1, 1)}
                              minDate={new Date(1900, 1, 1)}
                              placeholder="GG/AA/YYYY"
                              autoComplete="off"
                              disabled={
                                productDto.deliveryDate !== "" ? true : false
                              }
                            />
                          </Col>
                          <Col lg="2" className="pt-3">
                            <CgnDatePicker
                              name="customerEstimatedDeliveryDate"
                              label="Güncel Teslimat Tarihi"
                              selected={
                                productDto.estimatedDeliveryDate &&
                                  productDto.estimatedDeliveryDate
                                    .toString()
                                    .indexOf("/") > 0
                                  ? new Date(
                                    productDto.estimatedDeliveryDate.split(
                                      "/"
                                    )[2] +
                                    "-" +
                                    productDto.estimatedDeliveryDate.split(
                                      "/"
                                    )[1] +
                                    "-" +
                                    productDto.estimatedDeliveryDate.split(
                                      "/"
                                    )[0]
                                  )
                                  : productDto.estimatedDeliveryDate
                              }
                              onChange={(estimatedDeliveryDate) =>
                                this.handleEstimatedDeliveryDateChange2(
                                  estimatedDeliveryDate,
                                  idx
                                )
                              }
                              maxDate={new Date(2100, 1, 1)}
                              minDate={new Date(1900, 1, 1)}
                              placeholder="GG/AA/YYYY"
                              autoComplete="off"
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )
                  )}
                {this.state.offerMoldingRoomDto.map(
                  (offerMoldingRoomDto, idx) => (
                    <>
                      <Row>
                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"productDesignCheckbox_" + idx}
                                  checked={
                                    offerMoldingRoomDto.productDesignCheckbox
                                  }
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"productDesign_" + idx}
                              label="Ürün Tasarımı"
                              value={offerMoldingRoomDto.productDesign}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen ürün tasarımı girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={
                                !offerMoldingRoomDto.productDesignCheckbox
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"moldDesignCheckbox_" + idx}
                                  checked={
                                    offerMoldingRoomDto.moldDesignCheckbox
                                  }
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"moldDesign_" + idx}
                              label="Kalıp Tasarımı"
                              value={offerMoldingRoomDto.moldDesign}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen kalıp tasarımı girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={!offerMoldingRoomDto.moldDesignCheckbox}
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"processingCheckbox_" + idx}
                                  checked={
                                    offerMoldingRoomDto.processingCheckbox
                                  }
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"processing_" + idx}
                              label="Dik İşleme"
                              value={offerMoldingRoomDto.processing}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen dik işleme girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={!offerMoldingRoomDto.processingCheckbox}
                            />
                          </InputGroup>
                        </Col>

                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"renderCheckbox_" + idx}
                                  checked={offerMoldingRoomDto.renderCheckbox}
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"render_" + idx}
                              label="Torna"
                              value={offerMoldingRoomDto.render}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen torna girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={!offerMoldingRoomDto.renderCheckbox}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"wireErosionCheckbox_" + idx}
                                  checked={
                                    offerMoldingRoomDto.wireErosionCheckbox
                                  }
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"wireErosion_" + idx}
                              label="Tel Erezyon"
                              value={offerMoldingRoomDto.wireErosion}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen tel erezyon girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={
                                !offerMoldingRoomDto.wireErosionCheckbox
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"foreignAffairsCheckbox_" + idx}
                                  checked={
                                    offerMoldingRoomDto.foreignAffairsCheckbox
                                  }
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"foreignAffairs_" + idx}
                              label="Dış İşler"
                              value={offerMoldingRoomDto.foreignAffairs}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen dış işler girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={
                                !offerMoldingRoomDto.foreignAffairsCheckbox
                              }
                            />
                          </InputGroup>
                        </Col>

                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"materialOrderCheckbox_" + idx}
                                  checked={
                                    offerMoldingRoomDto.materialOrderCheckbox
                                  }
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"materialOrder_" + idx}
                              label="Malzeme Siparişi"
                              value={offerMoldingRoomDto.materialOrder}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen malzeme siparişi girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={
                                !offerMoldingRoomDto.materialOrderCheckbox
                              }
                            />
                          </InputGroup>
                        </Col>

                        <Col lg="3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <CgnCheckbox
                                  name={"statusTextCheckbox_" + idx}
                                  checked={
                                    offerMoldingRoomDto.statusTextCheckbox
                                  }
                                  onChange={this.handleTypeChange(idx)}
                                ></CgnCheckbox>
                              </InputGroupText>
                            </InputGroupAddon>
                            <CgnTextbox
                              type="text"
                              name={"statusText_" + idx}
                              label="Durum"
                              value={offerMoldingRoomDto.statusText}
                              onChange={this.handleChange2(idx)}
                              placeHolder="Lütfen durum girin..."
                              maxLength="50"
                              autoComplete="off"
                              disabled={!offerMoldingRoomDto.statusTextCheckbox}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="1" sm="3">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton mt-0 mb-3"
                            onClick={() => this.handleProductRemove(idx)}
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                )}

                <Row>
                  <Col className="pl-0">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton"
                      onClick={this.handleProductAdd}
                      text={"Kalıphane Ekle"}
                    />
                  </Col>
                </Row>
                <CgnButtonGroup items={this.state.buttons} />
              </Form>

              {this.state.alertErrorMessage && (
                <CgnAlert
                  className="mt-3"
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}

              {this.state.noteDto !== null && this.state.noteDto.length > 0 && (
                <>
                  <div
                    className="row"
                    style={{
                      display:
                        self.noteDto !== null && self.noteDto.length > 0
                          ? ""
                          : "none",
                    }}
                  >
                    <h2 className="mt-2">Not</h2>
                  </div>
                  <Table className="table table-responsive table-product2">
                    <tr className=""></tr>
                    {this.state.noteDto.map(function (item) {
                      return (
                        <tbody>
                          <>
                            <Accordion allowZeroExpanded>
                              <AccordionItem>
                                <AccordionItemHeading
                                  style={{ backgroundColor: "#9B9796" }}
                                >
                                  <AccordionItemButton>
                                    <tr className="cuttingTr4 w-100">
                                      <th
                                        className="pl-3"
                                        style={self1.thStyle3}
                                      >
                                        Not Başlığı
                                      </th>
                                      <td style={self1.tdStyle2}>
                                        {item.title}
                                      </td>
                                    </tr>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <tr></tr>
                                  <tr>
                                    <th className="pl-3">Not</th>
                                    <td>{item.description}</td>
                                  </tr>
                                  <CgnButton
                                    type="button"
                                    color="success"
                                    className="printButton ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.addModalUpdateNote(item.guid)
                                    }
                                    text="Güncelle"
                                  />
                                  <CgnButton
                                    type="button"
                                    color="danger"
                                    style={{ width: "90px" }}
                                    className="ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.handleNoteDelete(item.guid)
                                    }
                                    text="Sil"
                                  />
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </>
                        </tbody>
                      );
                    })}
                  </Table>
                </>
              )}
            </Card>

            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalNote}
          toggle={this.addModalNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.guid} updated={false} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalUpdateNote}
          toggle={this.addModalUpdateNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.noteGuid} updated={true} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default MoldingRoomDetail;
