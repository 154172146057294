import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";

class BankList extends Component {
  state = {
    pageTitle: "Banka Listele",
    pageAddLink: "banka-ekle",
    pageUpdateLink: "banka-guncelle",
    pageDetailLink: "banka-detay",
    pageListLink: "banka-listele",
    breadcrumb: [{ text: "Banka", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    bankData: [],
    name: "",
    branchCode: "",
    branchName: "",
    accountNo: "",
    iban: "",
    openingBalance: 0,
    balance: 0,
    totalBalance: 0,
    openingBalanceDolar: 0,
    balanceDolar: 0,
    totalBalanceDolar: 0,
    openingBalanceEuro: 0,
    balanceEuro: 0,
    totalBalanceEuro: 0,
    guid: "",
    alertErrorMessage: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        this.setState({
          bankData: response.data,
          isLoading: false,
        });
        var openingBalance = 0;
        var balance = 0;
        var totalBalance = 0;
        var openingBalanceDolar = 0;
        var balanceDolar = 0;
        var totalBalanceDolar = 0;
        var openingBalanceEuro = 0;
        var balanceEuro = 0;
        var totalBalanceEuro = 0;
        response.data.forEach((element) => {
          if (element.currencyType === 1) {
            openingBalance += element.openingBalance
              ? element.openingBalance
              : 0;
            balance +=
              (element.totalCollection ? element.totalCollection : 0) +
              (element.totalPayment ? element.totalPayment : 0);
            totalBalance +=
              (element.totalCollection ? element.totalCollection : 0) +
              (element.totalPayment ? element.totalPayment : 0) +
              element.openingBalance;
          } else if (element.currencyType === 2) {
            openingBalanceDolar += element.openingBalance
              ? element.openingBalance
              : 0;
            balanceDolar +=
              (element.totalCollection ? element.totalCollection : 0) +
              (element.totalPayment ? element.totalPayment : 0);
            totalBalanceDolar +=
              (element.totalCollection ? element.totalCollection : 0) +
              (element.totalPayment ? element.totalPayment : 0) +
              element.openingBalance;
          } else if (element.currencyType === 3) {
            openingBalanceEuro += element.openingBalance
              ? element.openingBalance
              : 0;
            balanceEuro +=
              (element.totalCollection ? element.totalCollection : 0) +
              (element.totalPayment ? element.totalPayment : 0);
            totalBalanceEuro +=
              (element.totalCollection ? element.totalCollection : 0) +
              (element.totalPayment ? element.totalPayment : 0) +
              element.openingBalance;
          }
        });
        this.setState({
          openingBalance: openingBalance,
          balance: balance,
          totalBalance: totalBalance,
          openingBalanceDolar: openingBalanceDolar,
          balanceDolar: balanceDolar,
          totalBalanceDolar: totalBalanceDolar,
          openingBalanceEuro: openingBalanceEuro,
          balanceEuro: balanceEuro,
          totalBalanceEuro: totalBalanceEuro,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/banks/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "120px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "250px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="bankList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.bankData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Banka Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      const balance = row.totalCollection + row.totalPayment;
                      const totalBalance = balance + row.openingBalance;
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Banka Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={7}>{row.name}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Şube Kodu</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.branchCode}</td>
                              <th style={this.thStyle2}>Şube Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.branchName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Hesap No</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.accountNo}</td>
                              <th style={this.thStyle2}>Iban</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.iban}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Açılış Bakiyesi</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.openingBalance.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>

                              <th style={this.thStyle2}>Bakiye</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {balance.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Toplam Bakiye</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {totalBalance.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.createdBy}</td>
                            </tr>                          
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          // {
                          //   text: "Banka Hareketleri",
                          //   className: "dropDownBtn",
                          //   href: `/banka-hareketleri/${row.guid}`,
                          // },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton">
                Toplam Bakiye (₺):{" "}
                {this.state.totalBalance.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton mr-2">
                Bakiye (₺):{" "}
                {this.state.balance.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Açılış Bakiyesi (₺):{" "}
                {this.state.openingBalance.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton">
                Toplam Bakiye ($):{" "}
                {this.state.totalBalanceDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Bakiye ($):{" "}
                {this.state.balanceDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Açılış Bakiyesi ($):{" "}
                {this.state.openingBalanceDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton">
                Toplam Bakiye (€):{" "}
                {this.state.totalBalanceEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
              <div className="float-right ListDownButton mr-2">
                Bakiye (€):{" "}
                {this.state.balanceEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Açılış Bakiyesi (€):{" "}
                {this.state.openingBalanceEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default BankList;
