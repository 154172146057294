import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Modal, ModalHeader } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  localFilePath,
  URL,
  
  fotoStyle,
} from "../../redux/actions/constants";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnTextbox from "../toolbox/CgnTextbox";
import noImg from "../../images/no-img.png";

class MachineCriticalProductList extends Component {
  state = {
    pageTitle: "Kritik Ürün Listele",
    pageAddLink: "urun3-ekle",
    pageUpdateLink: "urun3-guncelle",
    pageDetailLink: "urun3-detay",
    pageListLink: "urun3-listele",
    breadcrumb: [{ text: "Kritik Ürün", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    criticalProductData: [],
    alertErrorMessage: "",
    image: "",
    name: "",
    isOpen: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var statusType = 2; // 1 aktif ve pasiflerin ikisinide getirmesi için
    axios
      .get(URL + "/machineStocks/getlistallstock/" + statusType, config)
      .then((response) => {
        this.setState({
          stockData: response.data.filter(item => item.CriticalPiece > (item.pieceDurable + item.pieceDefective)),
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleBarcodePrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineProducts/barcodenumberprint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleAddToCart = async (idx) => {
    let criticalProductData = this.state.criticalProductData;
    if (
      criticalProductData[idx].guid &&
      criticalProductData[idx].piece &&
      criticalProductData[idx].piece !== 0
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(
          URL +
            "/machineproducts/addtocart/" +
            criticalProductData[idx].guid +
            "/" +
            criticalProductData[idx].piece,
          config
        )
        .then((response) => {
          this.props.data.cartCount =
            this.props.data.cartCount + criticalProductData[idx].piece;
          criticalProductData[idx].piece = 1;
          this.setState({
            criticalProductData: criticalProductData,
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  };

  handleProductPieceTextBoxChange(e, idx) {
    let criticalProductData = this.state.criticalProductData;
    criticalProductData[idx].piece = parseFloat(e.target.value);
    this.setState({
      criticalProductData: criticalProductData,
    });
  }

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "145px",
  };

  tdStyle = {
    width: "400px",
  };

  tdStyle2 = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          // addLink={"/" + this.state.pageAddLink}
          // listLink={"/" + this.state.pageListLink}
        />
        <Row className="criticalProductList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.stockData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    selector: "image",
                    sortable: false,
                    width: "80px",
                    cell: (cell) => (
                      <div>
                        {cell.machineProductImage ? (
                          <Button
                            onClick={() =>
                              this.toggleImage(
                                cell.machineProductImage,
                                cell.machineProductName
                              )
                            }
                            className="modalButtonList"
                          >
                            <img
                              alt={cell.machineProductName}
                              src={`/Images/${cell.machineProductImage}`}
                              className="rdt_image rounded"
                            />
                          </Button>
                        ) : (
                          <img
                            alt={cell.machineProductName}
                            src={noImg}
                            className="rdt_image rdt_no_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Ürün Adet Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Depo</th>
                              <td style={this.tdStyle}>{row.locationName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ürün Kodu</th>
                              <td style={this.tdStyle}>
                                {row.machineProductCode}
                              </td>
                              <th style={this.thStyle2}>Ürün Adı</th>
                              <td>{row.machineProductName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Sağlam Adet</th>
                              <td style={this.tdStyle}>{row.pieceDurable}</td>
                              <th style={this.thStyle2}>Arızalı Adet</th>
                              <td>{row.pieceDefective}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  }
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default MachineCriticalProductList;
