import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxType,
  TaxRateChoices,
  CurrencyType,
  
  fotoStyle,
  resizeFile,
  PaymentMethodType,
  MachineStatus,
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import moment from "moment";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";
import TimePicker from "rc-time-picker";
import GuarantyTypeAddUpdate from "../guarantyType/GuarantyTypeAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import ProcessTypeAddUpdate from "../processType/ProcessTypeAddUpdate";
import LocationAddUpdate from "../location/LocationAddUpdate";

class MachineServiceAddUpdate extends Component {
  state = {
    pageTitle: "Makine Servis Ekle",
    pageTitleAdd: "Makine Servis Ekle",
    pageTitleUpdate: "Makine Servis Güncelle",
    pageAddLink: "makine-servis-ekle",
    pageListLink: "makine-servis-listele",
    breadcrumb: [{ text: "Makine Servis", link: "#" }],
    isLoading: false,
    urlSplit: [],
    departureTime: null,
    timeofArrival: null,
    guarantyTypeGuid: null,
    guid: "",
    totalAmount: 0,
    amount: 0,
    assignmentGuid: null,
    wageStatus: 0,
    machineStatus: "",
    description: "",
    descriptionProcess: "",
    descriptionSparePart: "",
    overralAmount: 0,
    percentage: "",
    KDVRate: null,
    KDVType: null,
    discount: "",
    currency: 0,
    guarantyTypeName: "",
    currencyError: "",
    departureTimeError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    status: true,
    locationGuid: null,
    locationError: "",
    processTypeGuid: null,
    isNull: false,
    isTrue: false,
    isOpenModalGuarantyType: false,
    isOpenModalProduct: false,
    isOpenModalPeocessType: false,
    isOpenModalLocation: false,
    modalButtonIdx: 0,
    processTypeError: "",
    timeOfArrivalError: "",
    guarantyTypeError: "",
    totalAmountError: "",
    KDVRateError: "",
    KDVTypeError: "",
    alertErrorMessage: "",
    isDeleted: false,
    updateStatus: 0,
    counter: -1,
    counter1: -1,
    machineServiceProcessData: [],
    machineServiceSparePartData: [],
    multipleProductArr: [],
    guarantyTypeArr: [],
    processTypeArr: [],
    locationArr: [],
    productArr: [],
    machineServiceProcessError: "",
    machineServiceSparePartError: "",
    productUnitPriceError: "",
    productPieceError: "",
    machineStatusError: "",
    isHidden: true,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    guarantyTypeModalPlus: false,
    productModalPlus: false,
    processTypeModalPlus: false,
    collectionModalPlus: false,
    bankModalPlus: false,
    locationModalPlus: false,
    pageType: 0,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      guarantyTypeModalPlus: await UserOperationClaimControl(1065),
      productModalPlus: await UserOperationClaimControl(1029),
      processTypeModalPlus: await UserOperationClaimControl(1073),
      collectionModalPlus: await UserOperationClaimControl(1009),
      bankModalPlus: await UserOperationClaimControl(1003),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 4) {
      this.setState({
        pageAddLink: "",
        pageListLink: "",
        pageType: parseInt(urlSplit[3])
      });
      urlSplit.splice(3, 1);
    }
    this.setState({
      urlSplit: urlSplit,
      assignmentGuid: urlSplit[2],
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/assignment/get/" + this.state.assignmentGuid, config)
      .then((response) => {
        this.setState({
          wageStatus: response.data.wageStatus,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/guarantyType/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            guarantyTypeGuid: item.guarantyTypeGuid,
          };
        });
        this.setState({
          guarantyTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/processType/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            processTypeGuid: item.processTypeGuid,
          };
        });
        this.setState({
          processTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            locationGuid: item.locationGuid,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            productGuid: item.productGuid,
            salePrice: item.salePrice,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    if (urlSplit.length === 3 && urlSplit[1] === "makine-servis-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        updateStatus: 1,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/machineService/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              assignmentGuid: response.data.assignmentGuid,
              departureTime: moment(
                response.data.departureTime,
                "DD-MM-YYYY hh:mm"
              ),
              timeofArrival: moment(
                response.data.timeofArrival,
                "DD-MM-YYYY hh:mm"
              ),
              guarantyTypeGuid: response.data.guarantyTypeGuid,
              machineServiceProcessData: response.data.machineServiceProcessDto,
              locationGuid: response.data.locationGuid,
              machineServiceSparePartData:
                response.data.machineServiceSparePartDto,
              totalAmount: response.data.totalAmount,
              KDVType: response.data.kdvType,
              KDVRate: response.data.kdvRate.toString(),
              overralAmount: response.data.overralAmount,
              amount: response.data.amount,
              currency: response.data.currency,
              percentage: response.data.percentage,
              discount: response.data.discount,
              description: response.data.description,
              guarantyTypeName: response.data.guarantyTypeName,
              status: response.data.status,
              wageStatus: response.data.wageStatus,
              machineStatus: response.data.machineStatus,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        departureTime: null,
        timeofArrival: null,
        guarantyTypeGuid: null,
        totalAmount: 0,
        amount: 0,
        assignmentGuid: null,
        wageStatus: 0,
        machineStatus: "",
        description: "",
        overralAmount: 0,
        percentage: "",
        KDVRate: "",
        KDVType: null,
        discount: "",
        currency: 0,
        status: true,
        counter: -1,
        counter1: -1,
      });
    }
    if (
      this.state.isOpenModalGuarantyType &&
      this.props.location.modalAddedGuarantyType !== undefined &&
      this.props.location.modalAddedGuarantyType.isModal !== undefined &&
      this.props.location.modalAddedGuarantyType.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/guarantyType/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            guarantyTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedGuarantyType.data &&
              element.name === this.props.location.modalAddedGuarantyType.data
            ) {
              this.setState({
                guarantyTypeGuid: element.guid,
                guarantyTypeNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedGuarantyType.isModal = true;
      this.setState({
        isOpenModalGuarantyType: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.machineServiceSparePartData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
            }
          });
          if (this.props.location.modalAddedProduct.buyPrice) {
            productData[this.state.modalButtonIdx].unitPrice =
              this.props.location.modalAddedProduct.buyPrice;
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
          }
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalProcessType &&
      this.props.location.modalAddedProcessType !== undefined &&
      this.props.location.modalAddedProcessType.isModal !== undefined &&
      this.props.location.modalAddedProcessType.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/processType/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            processTypeArr: myArr,
            isLoading: false,
          });
          let processTypeData = this.state.machineServiceProcessData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProcessType.data &&
              element.name === this.props.location.modalAddedProcessType.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              processTypeData[this.state.modalButtonIdx].processTypeGuid =
                element.guid;
              processTypeData[this.state.modalButtonIdx].processTypeError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProcessType.isModal = true;
      this.setState({
        isOpenModalProcessType: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
          isLoading: false,
        });
        let productData = this.state.machineServiceSparePartData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name ===
                this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].locationGuid = element.guid;
              productData[this.state.modalButtonIdx].locationError = "";
            }
          });        
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalGuarantyType = () => {
    this.setState({
      isOpenModalGuarantyType: !this.state.isOpenModalGuarantyType,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  handleMachineStatusChange(option) {
    if (option) {
      this.setState({
        machineStatus: option.value,
        machineStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        machineStatus: "",
        machineStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDepartureTimeChange(departureTime) {
    if (departureTime) {
      this.setState({
        departureTime: departureTime,
        departureTimeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        departureTime: null,
        departureTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleTimeofArrivalChange(option) {
    if (option) {
      var time = moment(option);
      await this.setState({
        timeofArrival: time,
        timeOfArrivalError: "",
      });
    } else {
      this.setState({
        timeofArrival: null,
        timeofArrivalError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleGuarantyTypeSelectChange(option) {
    if (option) {
      this.setState({
        guarantyTypeGuid: option.value,
        guarantyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        guarantyTypeGuid: null,
      });
    }
  }

  handleProcessTypeSelectChange(option) {
    if (option) {
      this.setState({
        processTypeGuid: option.value,
        processTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        processTypeGuid: null,
        processTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: null,
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    if (name === "descriptionProcess" && value !== "") {
      this.setState({
        descriptionProcess: "",
      });
    }
  };

  async handleTimeChange(option) {
    if (option) {
      var time = moment(option);
      await this.setState({
        departureTime: time,
        departureTimeError: "",
      });
    } else {
      this.setState({
        departureTime: null,
        departureTimeError: CgnMessage.textErrorMessage,
      });
    }
  }

  TaxCalculate = async () => {
    if (
      this.state.KDVType === 1 &&
      this.state.amount &&
      this.state.amount !== 0 &&
      this.state.KDVRate
    ) {
      var overralAmount = (
        this.state.amount *
        (1 + parseInt(this.state.KDVRate) / 100)
      ).toFixed(2);
      if (this.state.overralAmount !== overralAmount) {
        await this.setState({
          overralAmount: overralAmount,
        });
      }
    }
    if (this.state.KDVType === 2 && this.state.amount && this.state.KDVRate) {
      if (this.state.overralAmount !== this.state.amount) {
        await this.setState({
          overralAmount: this.state.amount,
        });
      }
    }
    if (this.state.amount && this.state.KDVType === 3) {
      if (this.state.overralAmount !== this.state.amount) {
        await this.setState({
          overralAmount: this.state.amount,
        });
      }
    }
    if (this.state.amount === 0) {
      if (
        this.state.overralAmount &&
        this.state.overralAmount !== this.state.amount
      ) {
        await this.setState({
          overralAmount: 0,
        });
      }
    }
  };

  DiscountCalculate = async () => {
    var totalAmount = 0;
    if (this.state.overralAmount && this.state.percentage) {
      totalAmount =
        this.state.overralAmount * (1 - this.state.percentage / 100);
      if (this.state.discount !== totalAmount) {
        this.state.discount = totalAmount;
      }
    }
    if (this.state.overralAmount && this.state.percentage === "") {
      this.state.discount = "";
    }
    if (this.state.overralAmount && this.state.discount) {
      totalAmount = this.state.overralAmount - this.state.discount;
      if (this.state.discount !== totalAmount) {
        this.state.discount = totalAmount;
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        KDVType: option.value,
        KDVTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          KDVRate: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          KDVRate: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        KDVType: null,
        KDVTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        KDVRate: option.value,
        KDVRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        KDVRate: "",
        KDVRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: null,
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  addModalProcess = async (idx) => {
    await this.setState({
      isOpenModalProcessType: !this.state.isOpenModalProcessType,
      modalButtonIdx: idx,
    });
  };

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currency: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currency: 0,
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var machineProcess = this.state.machineServiceProcessData;
    if (machineProcess !== null && machineProcess.length > 0) {
      machineProcess.forEach((item) => {
        item.processTypeGuid = null;
        item.descriptionProcess = "";
        item.processTypeError = CgnMessage.textErrorMessage;
        item.descriptionProcessError = CgnMessage.textErrorMessage;
      });
    }

    var machineSparePart = this.state.machineServiceSparePartData;
    if (machineSparePart !== null && machineSparePart.length > 0) {
      machineSparePart.forEach((item) => {
        item.productGuid = null;
        item.locationGuid = null;
        item.piece = 0;
        item.price = 0;
        item.total = 0;
        item.unitPrice = 0;
        item.descriptionSparePart = "";

        item.productError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.descriptionSparePartError = CgnMessage.textErrorMessage;
      });
      this.setState({
        KDVTypeError: CgnMessage.textErrorMessage,
        KDVRateError: CgnMessage.textErrorMessage,
        currencyTypeError: CgnMessage.textErrorMessage,
      });
    }

    this.setState({
      departureTime: null,
      timeofArrival: null,
      guarantyTypeGuid: null,
      totalAmount: "",
      amount: "",
      KDVRate: "",
      KDVType: null,
      overralAmount: "",
      percentage: "",
      discount: "",
      currency: 0,
      isDiscountPercent: false,
      isDiscountValue: false,
      description: "",
      status: true,
      counter: -1,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      departureTimeError: CgnMessage.textErrorMessage,
      guarantyTypeError: CgnMessage.textErrorMessage,
      timeOfArrivalError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.machineServiceSparePartData;
    await array.push({
      locationGuid: null,
      productGuid: null,
      piece: 0,
      unitPrice: parseFloat(0),
      price: parseFloat(0),
      descriptionSparePart: "",
      productError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      descriptionSparePartError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].locationGuid !== null) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].productGuid !== null) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].piece !== 0) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].descriptionSparePart !== "") {
      return array[this.state.counter].descriptionSparePartError === "";
    } else if (
      array[this.state.counter].productGuid === null ||
      array[this.state.counter].locationGuid === null ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].price === 0 ||
      array[this.state.counter].descriptionSparePart === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ machineServiceSparePartData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let machineServiceSparePartData = this.state.machineServiceSparePartData;
    machineServiceSparePartData[idx].piece = parseFloat(e.target.value);
    this.setState({
      machineServiceSparePartData: machineServiceSparePartData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let machineServiceSparePartData = this.state.machineServiceSparePartData;
    machineServiceSparePartData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      machineServiceSparePartData: machineServiceSparePartData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleProductInputValueChange(option, idx) {
    let machineServiceSparePartData = this.state.machineServiceSparePartData;
    if (option) {
      if (this.state.wageStatus === 1) {
        machineServiceSparePartData[idx].productGuid = option.value;
        machineServiceSparePartData[idx].piece = 1;
        machineServiceSparePartData[idx].unitPrice = option.salePrice;
        machineServiceSparePartData[idx].description =
          option.descriptionSparePart;
        machineServiceSparePartData[idx].productError = "";
        machineServiceSparePartData[idx].productPieceError = "";
        machineServiceSparePartData[idx].productUnitPriceError = "";
        machineServiceSparePartData[idx].descriptionSparePartError = "";
        this.setState({
          machineServiceSparePartData: machineServiceSparePartData,
        });
      } else {
        machineServiceSparePartData[idx].productGuid = option.value;
        machineServiceSparePartData[idx].piece = 1;
        machineServiceSparePartData[idx].description =
          option.descriptionSparePart;
        machineServiceSparePartData[idx].unitPrice = 0;
        machineServiceSparePartData[idx].productError = "";
        machineServiceSparePartData[idx].productPieceError = "";
        machineServiceSparePartData[idx].productUnitPriceError = "";
        machineServiceSparePartData[idx].descriptionSparePartError = "";
        this.setState({
          machineServiceSparePartData: machineServiceSparePartData,
          alertErrorMessage: "",
        });
      }
    } else {
      machineServiceSparePartData[idx].productGuid = null;
      machineServiceSparePartData[idx].piece = 0;
      machineServiceSparePartData[idx].unitPrice = 0;
      machineServiceSparePartData[idx].description = "";
      machineServiceSparePartData[idx].price = 0;
      machineServiceSparePartData[idx].productError =
        CgnMessage.textErrorMessage;
      machineServiceSparePartData[idx].productPieceError =
        CgnMessage.textErrorMessage;
      machineServiceSparePartData[idx].productUnitPriceError =
        CgnMessage.textErrorMessage;
      machineServiceSparePartData[idx].descriptionSparePartError =
        CgnMessage.textErrorMessage;
      this.setState({
        machineServiceSparePartData: machineServiceSparePartData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleLocationInputValueChange(option, idx) {
    let machineServiceSparePartData = this.state.machineServiceSparePartData;
    if (option) {
      machineServiceSparePartData[idx].locationGuid = option.value;
      machineServiceSparePartData[idx].locationError = "";
      this.setState({
        machineServiceSparePartData: machineServiceSparePartData,
      });
    } else {
      machineServiceSparePartData[idx].locationGuid = null;
      machineServiceSparePartData[idx].locationError =
        CgnMessage.textErrorMessage;
      this.setState({
        machineServiceSparePartData: machineServiceSparePartData,
      });
    }
  }

  handleProcessInputValueChange(option, idx) {
    let machineServiceProcessData = this.state.machineServiceProcessData;
    if (option) {
      machineServiceProcessData[idx].processTypeGuid = option.value;
      machineServiceProcessData[idx].processTypeError = "";
      this.setState({
        machineServiceProcessData: machineServiceProcessData,
        alertErrorMessage: "",
      });
    } else {
      machineServiceProcessData[idx].processTypeGuid = null;
      machineServiceProcessData[idx].processTypeError =
        CgnMessage.textErrorMessage;
      this.setState({
        machineServiceProcessData: machineServiceProcessData,
        alertErrorMessage: CgnMessage.textErrorMessage,
      });
    }
  }

  handleProcessDescriptionInputValueChange(option, idx) {
    let machineServiceProcessData = this.state.machineServiceProcessData;
    if (option) {
      machineServiceProcessData[idx].description = option.value;
      machineServiceProcessData[idx].descriptionProcessError = "";
      this.setState({
        machineServiceProcessData: machineServiceProcessData,
        alertErrorMessage: "",
      });
    } else {
      machineServiceProcessData[idx].description = "";
      machineServiceProcessData[idx].descriptionProcessError =
        CgnMessage.textErrorMessage;
      this.setState({
        machineServiceProcessData: machineServiceProcessData,
        alertErrorMessage: CgnMessage.textErrorMessage,
      });
    }
  }

  ProductPriceCalculate = () => {
    var totalProductPrice = 0;
    this.state.machineServiceSparePartData.forEach((item) => {
      item.price = (
        parseFloat(item.piece) * parseFloat(item.unitPrice)
      ).toFixed(2);
      totalProductPrice += parseFloat(item.price);
    });
    this.state.amount = parseFloat(totalProductPrice).toFixed(2); // loop girmemesi için setState kullanmadım.
    this.PriceCalculate(null);
  };

  productRequiredField = () => {
    var arr = this.state.machineServiceSparePartData;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductRemove(idx) {
    let someArray = this.state.machineServiceSparePartData;
    someArray.splice(idx, 1);
    await this.setState({
      machineServiceSparePartData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        total: 0,
        KDVRateError: "",
        KDVTypeError: "",
        currencyTypeError: "",
        rowControlSparePart: true,
      });
    }
  }

  async handleProcessRemove(idx) {
    let someArray = this.state.machineServiceProcessData;
    someArray.splice(idx, 1);
    await this.setState({
      machineServiceProcessData: someArray,
      counter1: this.state.counter1 - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        total: 0,
        rowControlProcess: true,
      });
    }
  }

  handleMachineServiceProcessAdd = async () => {
    let array = this.state.machineServiceProcessData;
    await array.push({
      processTypeGuid: null,
      processTypeError: CgnMessage.textErrorMessage,
    });
    this.setState({
      counter1: this.state.counter1 + 1,
    });
    if (array[this.state.counter1].processTypeGuid) {
      return array[this.state.counter1].processTypeError === "";
    }
    if (array[this.state.counter1].descriptionProcess) {
      return array[this.state.counter1].descriptionProcessError === "";
    }
    if (
      array[this.state.counter1].processTypeGuid === null &&
      array[this.state.counter1].descriptionProcess === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ machineServiceProcessData: array });
  };

  handleProcessTextBoxChange(e, idx) {
    let machineServiceProcessData = this.state.machineServiceProcessData;
    machineServiceProcessData[idx].description = e.target.value;
    this.setState({
      machineServiceProcessData: machineServiceProcessData,
    });
  }

  handleSparePartTextBoxChange(e, idx) {
    let machineServiceSparePartData = this.state.machineServiceSparePartData;
    machineServiceSparePartData[idx].description = e.target.value;
    this.setState({
      machineServiceSparePartData: machineServiceSparePartData,
    });
  }

  handleMachineServiceProcessRemove(idx) {
    let someArray = this.state.machineServiceProcessData;
    someArray.splice(idx, 1);
    this.setState({ machineServiceProcessData: someArray });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleCollectionMethodSelectChange(option) {
    if (option) {
      this.setState({
        collectionMethodValue: option.value,
        collectionMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else if (!this.state.isHidden) {
      this.setState({
        collectionMethodValue: null,
        collectionMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalBank = () => {
    this.setState({
      isOpenModalBank: !this.state.isOpenModalBank,
    });
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      departureTimeError: "",
      timeOfArrivalError: "",
      guarantyTypeError: "",
      KDVRateError: "",
      KDVTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
      machineServiceError: "",
    });
    if (this.state.departureTime === null) {
      this.setState({
        departureTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.timeofArrival === null) {
      this.setState({
        timeOfArrivalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.machineStatus === "") {
      this.setState({
        machineStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    var rowControlSparePart = true;
    var rowControlProcess = true;
    if (
      this.state.machineServiceSparePartData !== null &&
      this.state.machineServiceSparePartData.length > 0
    ) {
      this.state.machineServiceSparePartData.forEach((element) => {
        if (
          element.locationGuid === null ||
          element.productGuid === null ||
          element.piece === 0
        ) {
          rowControlSparePart = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            KDVRateError: CgnMessage.textErrorMessage,
            KDVTypeError: CgnMessage.textErrorMessage,
            currencyTypeError: CgnMessage.textErrorMessage,
          });
        }
      });
      if (this.state.wageStatus === 1) {
        if (this.state.KDVType === null) {
          this.setState({
            KDVTypeError: CgnMessage.textErrorMessage,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
        if (this.state.KDVRate === "") {
          this.setState({
            KDVRateError: CgnMessage.textErrorMessage,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
        if (this.state.currency === 0) {
          this.setState({
            currencyTypeError: CgnMessage.textErrorMessage,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      } else {
        this.setState({
          KDVType: 0,
          KDVRate: 0,
          currency: 0,
        });
      }
    }

    if (
      this.state.machineServiceProcessData !== null &&
      this.state.machineServiceProcessData.length > 0
    ) {
      this.state.machineServiceProcessData.forEach((element) => {
        if (element.processTypeGuid === null || element.description === "") {
          rowControlProcess = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            processTypeError: CgnMessage.textErrorMessage,
            descriptionProcessError: CgnMessage.textErrorMessage,
          });
        }
      });
    }
    if (rowControlSparePart === false || rowControlProcess === false) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.machineServiceSparePartData !== null &&
      this.state.machineServiceSparePartData.length > 0 &&
      this.state.KDVType === null &&
      this.state.wageStatus === 1
    ) {
      this.setState({
        KDVTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.machineServiceSparePartData !== null &&
      this.state.machineServiceSparePartData.length > 0 &&
      this.state.KDVRate === "" &&
      this.state.wageStatus === 1
    ) {
      this.setState({
        KDVRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.machineServiceSparePartData !== null &&
      this.state.machineServiceSparePartData.length > 0 &&
      this.state.currency === 0 &&
      this.state.wageStatus === 1
    ) {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.departureTime !== null &&
      this.state.timeofArrival !== null &&
      this.state.machineStatus !== "" &&
      rowControlSparePart !== false &&
      rowControlProcess !== false
    ) {
      this.setState({
        isLoading: true,
      });

      var machineServiceSparePartData = [];
      if (this.state.machineServiceSparePartData !== null) {
        this.state.machineServiceSparePartData.forEach((element) => {
          machineServiceSparePartData.push({
            guid: element.guid,
            locationGuid: element.locationGuid,
            productGuid: element.productGuid,
            piece: element.piece,
            unitPrice: parseFloat(element.unitPrice),
            price: parseFloat(element.price.replace(",", ".")),
            description: element.description,
            status: true,
          });
        });
      }

      var machineServiceProcessData = [];
      if (this.state.machineServiceProcessData !== null) {
        this.state.machineServiceProcessData.forEach((element) => {
          machineServiceProcessData.push({
            guid: element.guid,
            processTypeGuid: element.processTypeGuid,
            description: element.description,
            status: true,
          });
        });
      }

      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        assignmentGuid: this.state.assignmentGuid,
        departureTime: this.state.departureTime,
        timeofArrival: this.state.timeofArrival,
        guarantyTypeGuid: this.state.guarantyTypeGuid !== null ? this.state.guarantyTypeGuid : "00000000-0000-0000-0000-000000000000",
        description: this.state.description,
        KDVType: this.state.KDVType !== null ? parseInt(this.state.KDVType) : 0,
        KDVRate: this.state.KDVRate !== null ? parseInt(this.state.KDVRate) : 0,
        amount: parseFloat(this.state.amount),
        overralAmount: parseFloat(this.state.overralAmount),
        currency: this.state.currency,
        percentage:
          this.state.percentage !== "" ? parseInt(this.state.percentage) : 0,
        discount:
          this.state.discount !== "" ? parseFloat(this.state.discount) : 0,
        totalAmount: parseFloat(this.state.totalAmount),
        status: true, //this.state.status,
        machineServiceProcessDto: machineServiceProcessData,
        machineServiceSparePartDto: machineServiceSparePartData,
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        paymentMethodType: this.state.collectionMethodValue
          ? parseInt(this.state.collectionMethodValue)
          : 0,
        bankGuid: this.state.bankGuid,
        chequesBondNo: this.state.chequesBondNo,
        image: this.state.image,
        isHidden: this.state.isHidden,
        machineStatus: this.state.machineStatus,
      };
      var URLParam = "/machineService/add";
      if (this.state.guid) {
        URLParam = "/machineService/update";
        myObj = {
          guid: this.state.guid,
          assignmentGuid: this.state.assignmentGuid,
          departureTime: this.state.departureTime,
          timeofArrival: this.state.timeofArrival,
          guarantyTypeGuid: this.state.guarantyTypeGuid !== null ? this.state.guarantyTypeGuid : "00000000-0000-0000-0000-000000000000",
          description: this.state.description,
          KDVType:
            this.state.KDVType !== null ? parseInt(this.state.KDVType) : 0,
          KDVRate:
            this.state.KDVRate !== null ? parseInt(this.state.KDVRate) : 0,
          amount: parseFloat(this.state.amount),
          overralAmount: parseFloat(this.state.overralAmount),
          currency: this.state.currency,
          percentage:
            this.state.percentage !== "" ? parseInt(this.state.percentage) : 0,
          discount:
            this.state.discount !== "" ? parseFloat(this.state.discount) : 0,
          totalAmount: parseFloat(this.state.totalAmount),
          status: true, //this.state.status,
          machineServiceProcessDto: machineServiceProcessData,
          machineServiceSparePartDto: machineServiceSparePartData,
          machineStatus: this.state.machineStatus,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.pageType === 1) {
              window.location.href = "/bekleyen-is-atama-listele";
            } else if (this.state.pageType === 2) {
              window.location.href = "/tamamlanan-is-atama-listele";
            } else if (this.state.pageType === 0) {
              window.location.href = "/makine-servis-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "amount" && value === 0) {
        this.setState({
          amountError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "overralAmount" && value === 0) {
        this.setState({
          overralAmountError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "totalAmount" && value === 0) {
        this.setState({
          totalAmountError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "percentage") {
        await this.setState({
          discount: "",
        });
      }
      if (name === "discount") {
        await this.setState({
          percentage: "",
        });
      }
    }
    var amount = parseFloat(this.state.amount);
    var percentage = parseFloat(this.state.percentage);
    var discount = parseFloat(this.state.discount);
    var overralAmount = 0;
    var totalAmount = 0;
    if (percentage > 0) {
      overralAmount = amount - amount * (percentage / 100);
    } else if (discount > 0) {
      overralAmount = amount - discount;
    } else {
      overralAmount = amount;
    }
    if (this.state.KDVType && this.state.KDVRate) {
      if (this.state.KDVType === 1) {
        totalAmount = overralAmount * (this.state.KDVRate / 100 + 1);
      } else if (this.state.KDVType === 2) {
        totalAmount = overralAmount;
      } else if (this.state.KDVType === 3) {
        totalAmount = overralAmount;
      }
    }
    this.state.amount = parseFloat(amount).toFixed(2);
    this.state.overralAmount = parseFloat(overralAmount).toFixed(2);
    this.state.totalAmount = parseFloat(totalAmount).toFixed(2);
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
      
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          //addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="machineServiceAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <>
                  <Row>
                    <Col lg="3">
                      <Label className="font-weight-bolder d-block">
                        Varış Saati [*]
                      </Label>
                      <TimePicker
                        placeholder="Varış saati giriniz"
                        showSecond={false}
                        onChange={(option) => this.handleTimeChange(option)}
                        errors={this.state.departureTimeError}
                        value={this.state.departureTime}
                      />
                    </Col>
                    <Col lg="3">
                      <Label className="font-weight-bolder d-block">
                        Ayrılış Saati [*]
                      </Label>
                      <TimePicker
                        placeholder="Ayrılış saati giriniz"
                        showSecond={false}
                        onChange={(value) =>
                          this.handleTimeofArrivalChange(value)
                        }
                        errors={this.state.timeOfArrivalErrorError}
                        value={this.state.timeofArrival}
                      />
                    </Col>
                    <Col lg="3">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="guarantyTypeGuid"
                            label="Garanti Türü [*]"
                            placeholder="Lütfen garanti türü seçiniz..."
                            selectValue={this.state.guarantyTypeGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleGuarantyTypeSelectChange(option)
                            }
                            options={this.state.guarantyTypeArr}
                            error={this.state.guarantyTypeError}
                          />
                        </div>
                        {this.state.guarantyTypeModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalGuarantyType()}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="3">
                      <CgnReactSelect
                        name="machineStatus"
                        label="Makine Durumu [*]"
                        placeholder="Makine Durumu seçiniz..."
                        selectValue={this.state.machineStatus}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) =>
                          this.handleMachineStatusChange(option)
                        }
                        options={MachineStatus}
                        error={this.state.machineStatusError}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <CgnTextbox
                        inlineStyle={textAreaStyle}
                        type="textarea"
                        name="description"
                        label="Açıklama"
                        value={this.state.description}
                        placeHolder="Lütfen açıklama girin..."
                        autoComplete="off"
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                </>
                {this.state.machineServiceSparePartData.map(
                  (machineServiceSparePartData, idx) => (
                    <>
                      <Row className="mobileBorder">
                      <Col lg="3" className="modalSelectCol">
                        <div className="flex-container">
                        <div className="p-0 flexInput">
                        <CgnReactSelect
                           name="locationGuid"
                           label="Depo [*]"
                           placeholder="Lütfen depo seçiniz..."
                           selectValue={machineServiceSparePartData.locationGuid}
                           isMulti={false}
                           isClearable={true}
                           onChange={(option) => {
                             this.handleLocationInputValueChange(option, idx);
                           }}
                           options={this.state.locationArr}
                           error={machineServiceSparePartData.locationError}
                        />
                      </div>
                      {this.state.locationModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalLocation(idx)}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                    </Col>
                        <Col lg="3" className="modalSelectCol">
                          <div className="flex-container">
                            <div className="p-0 flexInput">
                              <CgnReactSelect
                                name="productGuid"
                                label="Ürün [*]"
                                placeholder="Lütfen ürün seçiniz..."
                                selectValue={
                                  machineServiceSparePartData.productGuid
                                }
                                isMulti={false}
                                isClearable={true}
                                onChange={(option) => {
                                  this.handleProductInputValueChange(
                                    option,
                                    idx
                                  );
                                }}
                                options={this.state.productArr}
                                error={machineServiceSparePartData.productError}
                              />
                            </div>
                            {this.state.productModalPlus && (
                              <div className="p-0 flexAddButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalProduct(idx)}
                                  text={"+"}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg="1">
                          <CgnTextbox
                            type="number"
                            name="piece"
                            label="Adet [*]"
                            value={machineServiceSparePartData.piece}
                            onChange={(e) =>
                              this.handleProductPieceTextBoxChange(e, idx)
                            }
                            placeHolder="Lütfen adet girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={
                              machineServiceSparePartData.productPieceError
                            }
                          />
                        </Col>
                        <Col lg="2">
                          <CgnTextbox
                            type="number"
                            name="unitPrice"
                            label="Birim Fiyat [*]"
                            value={machineServiceSparePartData.unitPrice}
                            onChange={(e) =>
                              this.handleProductUnitPriceTextBoxChange(e, idx)
                            }
                            placeHolder="Lütfen birim fiyat girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={
                              machineServiceSparePartData.productUnitPriceError
                            }
                          />
                        </Col>
                        <Col lg="2">
                          <CgnTextbox
                            type="number"
                            name="price"
                            label="Fiyat [*]"
                            value={machineServiceSparePartData.price}
                            readOnly={true}
                            onChange={this.ProductPriceCalculate()}
                            maxLength="50"
                            autoComplete="off"
                          />
                        </Col>
                        <Col lg="1">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton"
                            onClick={() => this.handleProductRemove(idx)}
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <CgnTextbox
                            inlineStyle={textAreaStyle}
                            type="textarea"
                            name="descriptionSparePart"
                            label="Açıklama"
                            value={machineServiceSparePartData.description}
                            onChange={(e) => {
                              this.handleSparePartTextBoxChange(e, idx);
                            }}
                            placeHolder="Lütfen açıklama girin..."
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                    </>
                  )
                )}
                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd"
                    onClick={this.handleProductAdd}
                    text={"Yedek Parça Ekle"}
                  />
                  {this.state.machineServiceSparePartDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.machineServiceSparePartDataError}
                    </div>
                  )}
                </Row>

                {this.state.machineServiceProcessData.map(
                  (machineServiceProcessData, idx) => (
                    <>
                      <Row className="mt-3">
                        <Col lg="11" className="pl-0">
                          <div className="flex-container">
                            <div className="p-0 flexInput">
                              <CgnReactSelect
                                name="processTypeGuid"
                                label="İşlem Tipi [*]"
                                placeholder="Lütfen işlem tipi seçiniz..."
                                selectValue={
                                  machineServiceProcessData.processTypeGuid
                                }
                                isMulti={false}
                                isClearable={true}
                                onChange={(option) => {
                                  this.handleProcessInputValueChange(
                                    option,
                                    idx
                                  );
                                }}
                                options={this.state.processTypeArr}
                                error={
                                  machineServiceProcessData.processTypeError
                                }
                              />
                            </div>
                            {this.state.processTypeModalPlus && (
                              <div className="p-0 flexAddButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalProcess(idx)}
                                  text={"+"}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg="1">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton"
                            onClick={() => this.handleProcessRemove(idx)}
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <CgnTextbox
                            inlineStyle={textAreaStyle}
                            type="textarea"
                            name="descriptionProcess"
                            label="Açıklama"
                            value={machineServiceProcessData.description}
                            onChange={(e) => {
                              this.handleProcessTextBoxChange(e, idx);
                            }}
                            placeHolder="Lütfen açıklama girin..."
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                    </>
                  )
                )}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton machineProcessAdd mt-3 mb-3"
                    onClick={this.handleMachineServiceProcessAdd}
                    text={"İşlem Ekle"}
                  />
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>
                <Row
                  className="mt-3"
                  style={{ display: this.state.wageStatus === 1 ? "" : "none" }}
                >
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="amount"
                      label="Tutar [*]"
                      value={this.state.amount}
                      placeHolder="Lütfen tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                      error={this.state.totalError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="percentage"
                      label="İskonto(Yüzde)"
                      value={this.state.percentage}
                      placeHolder="Lütfen iskonto girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountPercent}
                      onChange={this.PriceCalculate}
                      error={this.state.discountPercentError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discount"
                      label="İskonto(Net Tutar)"
                      value={this.state.discount}
                      placeHolder="Lütfen iskonto net tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountValue}
                      onChange={this.PriceCalculate}
                      error={this.state.discountValueError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="overralAmount"
                      label="Genel Tutar"
                      value={this.state.overralAmount}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>
                <Row
                  style={{ display: this.state.wageStatus === 1 ? "" : "none" }}
                >
                  <Col lg="3">
                    <CgnReactSelect
                      name="currency"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currency}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="KDVType"
                      label="K.D.V Türü [*]"
                      placeholder="Lütfen K.D.V türü seçiniz..."
                      selectValue={this.state.KDVType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTaxTypeSelectChange(option)
                      }
                      options={TaxType}
                      error={this.state.KDVTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="KDVRate"
                      label="K.D.V Oranı [*]"
                      placeholder="Lütfen K.D.V oranı seçiniz..."
                      selectValue={this.state.KDVRate}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTrue}
                      onChange={(option) =>
                        this.handleTaxRateSelectChange(option)
                      }
                      options={TaxRateChoices}
                      error={this.state.KDVRateError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="totalAmount"
                      label="Toplam Tutar"
                      value={this.state.totalAmount}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                {this.state.collectionModalPlus &&
                  this.state.updateStatus !== 1 && (
                    <>
                      <Row  style={{ display: this.state.wageStatus === 1 ? "" : "none" }}>
                        <Col
                          lg="12"
                          className="font-weight-bold"
                          style={{ padding: "0 15px 10px 15px" }}
                        >
                          <Label
                            check
                            className="labelCheck"
                            style={{ fontSize: "13px" }}
                          >
                            <Input
                              type="checkbox"
                              className="mt-1"
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              checked={!this.state.isHidden}
                            />{" "}
                            Tahsilat Yapıldı mı ?
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <CgnTextbox
                            type="text"
                            name="receiptNo"
                            label="Makbuz No"
                            value={this.state.receiptNo}
                            placeHolder="Lütfen makbuz no girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.receiptNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                        <Col lg="6">
                          <CgnTextbox
                            type="number"
                            name="expiry"
                            label="Vade"
                            value={this.state.expiry}
                            placeHolder="Lütfen vade sayısı girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.expiryError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <CgnReactSelect
                            name="collectionMethodValue"
                            label="Tahsilat Yöntemi [*]"
                            placeholder="Lütfen tahsilat yöntemi seçiniz..."
                            selectValue={this.state.collectionMethodValue}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleCollectionMethodSelectChange(option)
                            }
                            options={PaymentMethodType}
                            error={this.state.collectionMethodTypeError}
                            hidden={this.state.isHidden}
                          />
                        </Col>

                        <Col
                          lg="4"
                          className="modalSelectCol"
                          style={
                            this.state.isHidden
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <div className="flex-container">
                            <div className="p-0 flexMaxInput">
                              <CgnReactSelect
                                name="bankGuid"
                                label="Banka"
                                placeholder="Banka seçiniz..."
                                selectValue={this.state.bankGuid}
                                isMulti={false}
                                isClearable={true}
                                disabled={this.state.isBankTrue}
                                onChange={(option) =>
                                  this.handleBankSelectChange(option)
                                }
                                options={this.state.bankArr}
                                error={this.state.bankError}
                                hidden={this.state.isHidden}
                              />
                            </div>
                            {this.state.bankModalPlus && (
                              <div className="p-0 flexAddMinButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalBank()}
                                  text={"+"}
                                  visibled={this.state.isHidden}
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="text"
                            name="chequesBondNo"
                            label="Çek/Senet No"
                            value={this.state.chequesBondNo}
                            placeHolder="Lütfen çek/senet no girin..."
                            autoComplete="off"
                            readOnly={this.state.isChequesBondNoTrue}
                            onChange={this.handleChange}
                            error={this.state.chequesBondNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <ImageUpload
                            accept="image/png, image/jpeg"
                            className="text-center"
                            style={fotoStyle}
                            label="Makbuz Fotoğrafı"
                            image={this.state.image}
                            noFoto={noImage}
                            alt={this.state.name}
                            onChange={this.uploadedImage}
                            onDelete={this.deleteImage}
                            error={this.state.imageError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalGuarantyType}
          toggle={this.addModalGuarantyType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Garanti Türü Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <GuarantyTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalGuarantyType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProcessType}
          toggle={this.addModalProcess}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            İşlem Tipi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ProcessTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProcess}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default MachineServiceAddUpdate;
