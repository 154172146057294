import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  resizeFile,
  fotoStyle,
  
  UserOperationClaimControl,
  unitValuesStatuses,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImg from "../../images/no-img.png";
import { withRouter } from "react-router-dom";
import ProductAddUpdate from "../product/ProductAddUpdate";
import { isThisSecond, secondsToMilliseconds } from "date-fns";
import MaterialAddUpdate from "../material/MaterialAddUpdate";

class ProductRecipeAddUpdate extends Component {
  state = {
    pageTitle: "Ürün Reçete Ekle",
    pageTitleAdd: "Ürün Reçete Ekle",
    pageTitleUpdate: "Ürün Reçete Güncelle",
    pageAddLink: "urun-recete-ekle",
    pageListLink: "urun-recete-listele",
    breadcrumb: [{ text: "Ürün Reçete", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productArr: [],
    product2Arr: [],
    productGuid: "",
    product2Guid: "",
    unit: "",
    piece: "",
    image: "",
    name: "",
    guid: "",
    productRecipeProductionDto: [],
    productRecipeQualityDto: [],
    productionMaterialArr: [],
    qualityMaterialArr: [],
    productionMaterialGuid: "",
    qualityMaterialGuid: "",
    isOpenModalProductMaterial: false,
    isOpenModalQualityMaterial: false,
    status: true,
    isNull: false,
    modalButtonIdx: 0,
    counter: -1,
    counter1: -1,
    counter2: -1,
    isTrue: false,
    productError: "",
    alertErrorMessage: "",
    isOpenModalProduct: false,
    isOpenModalProduct2: false,
    productRecipeProductDto: [],
    product2Error: "",
    unitError: "",
    pieceError: "",
    productRecipeProductError: "",
    message: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    productModalPlus: false,
    materialModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      productModalPlus: await UserOperationClaimControl(1029),
      materialModalPlus: await UserOperationClaimControl(1133),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
          product2Arr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });

    axios
      .get(URL + "/materials/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}`,
          };
        });
        this.setState({
          productionMaterialArr: myArr,
          qualityMaterialArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "urun-recete-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/productRecipes/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              productGuid: response.data.productGuid,
              image: response.data.image,
              productRecipeProductDto: response.data.productRecipeProductDto,
              productRecipeProductionDto:
                response.data.productRecipeProductionDto,
              productRecipeQualityDto: response.data.productRecipeQualityDto,
              status: response.data.status,
              isLoading: false,
              isNull: false,
              counter: response.data.productRecipeProductDto.length - 1,
              counter1: response.data.productRecipeProductionDto.length - 1,
              counter2: response.data.productRecipeQualityDto.length - 1,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        image: "",
        productGuid: "",
        productRecipeProductDto: [],
        status: true,
        counter: -1,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }

    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data
            ) {
              this.setState({
                productGuid: element.guid,
                productError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalProduct2 &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            product2Arr: myArr,
            isLoading: false,
          });
          let productRecipeProductDto = this.state.productRecipeProductDto;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productRecipeProductDto[this.state.modalButtonIdx].product2Guid =
                element.guid;
              productRecipeProductDto[this.state.modalButtonIdx].product2Error =
                "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct2: false,
      });
    }
    if (
      this.state.isOpenModalProductMaterial &&
      this.props.location.modalAddedMaterial !== undefined &&
      this.props.location.modalAddedMaterial.isModal !== undefined &&
      this.props.location.modalAddedMaterial.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/materials/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}`,
            };
          });
          this.setState({
            productionMaterialArr: myArr,
            qualityMaterialArr: myArr,
            isLoading: false,
          });
          let productRecipeProductionDto =
            this.state.productRecipeProductionDto;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMaterial.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedMaterial.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productRecipeProductionDto[
                this.state.modalButtonIdx
              ].productionMaterialGuid = element.guid;
              productRecipeProductionDto[
                this.state.modalButtonIdx
              ].productionMaterialError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
            isNull1: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMaterial.isModal = true;
      this.setState({
        isOpenModalProductMaterial: false,
      });
    }
    if (
      this.state.isOpenModalQualityMaterial &&
      this.props.location.modalAddedMaterial !== undefined &&
      this.props.location.modalAddedMaterial.isModal !== undefined &&
      this.props.location.modalAddedMaterial.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/materials/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}`,
            };
          });
          this.setState({
            qualityMaterialArr: myArr,
            productionMaterialArr: myArr,
            isLoading: false,
          });
          let productRecipeQualityDto = this.state.productRecipeQualityDto;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMaterial.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedMaterial.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productRecipeQualityDto[
                this.state.modalButtonIdx
              ].qualityMaterialGuid = element.guid;
              productRecipeQualityDto[
                this.state.modalButtonIdx
              ].qualityMaterialProductError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
            isNull2: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMaterial.isModal = true;
      this.setState({
        isOpenModalQualityMaterial: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  addModalProduct2 = async (idx) => {
    await this.setState({
      isOpenModalProduct2: !this.state.isOpenModalProduct2,
      modalButtonIdx: idx,
    });
  };

  addModalProductMaterial = async (idx) => {
    await this.setState({
      isOpenModalProductMaterial: !this.state.isOpenModalProductMaterial,
      modalButtonIdx: idx,
    });
  };

  addModalQualityMaterial = async (idx) => {
    await this.setState({
      isOpenModalQualityMaterial: !this.state.isOpenModalQualityMaterial,
      modalButtonIdx: idx,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    var array = this.state.productRecipeProductDto;
    array.forEach((item) => {
      item.productGuid = "";
      item.piece = "";
      item.unit = "";
      item.productError = CgnMessage.textErrorMessage;
      item.pieceError = CgnMessage.textErrorMessage;
      item.unitError = CgnMessage.textErrorMessage;
    });

    await this.setState({
      productGuid: "",
      image: "",
      status: true,
      counter: -1,
      productRecipeProductDto: [],

      alertErrorMessage: CgnMessage.alertErrorMessage,
      productError: CgnMessage.textErrorMessage,
      productRecipeProductError: CgnMessage.alertErrorMessage,
    });
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleProductRecipeProductAdd = async () => {
    var array = this.state.productRecipeProductDto;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      product2Guid: "",
      piece: "",
      unit: "",
      pieceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
      product2Error: CgnMessage.textErrorMessage,
    });
    await this.setState({
      productRecipeProductError: "",
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].product2Guid) {
      return array[this.state.counter].product2Error === "";
    }
    if (array[this.state.counter].unit) {
      return array[this.state.counter].unitError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].pieceError === "";
    }
    if (
      (array[this.state.counter].piece === "" ||
        array[this.state.counter].unit === "") &&
      array.length > 0
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productRecipeProductDto: array });
  };

  handleProductionAdd = async () => {
    var array = this.state.productRecipeProductionDto;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      productionMaterialGuid: "",
      piece: "",
      productionMaterialError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      productionMaterialError: "",
      counter1: this.state.counter1 + 1,
    });
    if (array[this.state.counter1].productionMaterialGuid) {
      return array[this.state.counter1].productionMaterialError === "";
    }

    this.setState({ productRecipeProductionDto: array });
  };

  handleQualityAdd = async () => {
    var array = this.state.productRecipeQualityDto;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      qualityMaterialGuid: "",
      piece: "",
      qualityMaterialError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      qualityMaterialError: "",
      counter2: this.state.counter2 + 1,
    });
    if (array[this.state.counter2].qualityMaterialGuid) {
      return array[this.state.counter2].qualityMaterialError === "";
    }

    this.setState({ productRecipeQualityDto: array });
  };

  handleTextBoxChange(e, idx) {
    let productRecipeProductDto = this.state.productRecipeProductDto;
    productRecipeProductDto[idx].piece = e.target.value;
    productRecipeProductDto[idx].pieceError = "";
    this.setState({
      productRecipeProductDto: productRecipeProductDto,
    });
  }

  handleQualityTextBoxChange(e, idx) {
    let productRecipeQualityDto = this.state.productRecipeQualityDto;
    productRecipeQualityDto[idx].piece = e.target.value;
    productRecipeQualityDto[idx].pieceError = "";
    this.setState({
      productRecipeQualityDto: productRecipeQualityDto,
    });
  }

  handleProductionTextBoxChange(e, idx) {
    let productRecipeProductionDto = this.state.productRecipeProductionDto;
    productRecipeProductionDto[idx].piece = e.target.value;
    productRecipeProductionDto[idx].pieceError = "";
    this.setState({
      productRecipeProductionDto: productRecipeProductionDto,
    });
  }

  handleUnitChange(option, idx) {
    let productRecipeProductDto = this.state.productRecipeProductDto;
    if (option) {
      productRecipeProductDto[idx].unit = option.value;
      productRecipeProductDto[idx].unitError = "";
      this.setState({
        productRecipeProductDto: productRecipeProductDto,
      });
    } else {
      productRecipeProductDto[idx].unit = "";
      productRecipeProductDto[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productRecipeProductDto: productRecipeProductDto,
      });
    }
  }

  handleInputValueChange(option, idx) {
    let productRecipeProductDto = this.state.productRecipeProductDto;
    if (option) {
      productRecipeProductDto[idx].product2Guid = option.value;
      productRecipeProductDto[idx].product2Error = "";
      this.setState({
        productRecipeProductDto: productRecipeProductDto,
        alertErrorMessage: "",
      });
    } else {
      productRecipeProductDto[idx].product2Guid = "";
      productRecipeProductDto[idx].product2Error = CgnMessage.textErrorMessage;
      this.setState({
        productRecipeProductDto: productRecipeProductDto,
      });
    }
  }

  handleProductionInputValueChange(option, idx) {
    let productRecipeProductionDto = this.state.productRecipeProductionDto;
    if (option) {
      productRecipeProductionDto[idx].productionMaterialGuid = option.value;
      productRecipeProductionDto[idx].productionMaterialError = "";
      this.setState({
        productRecipeProductionDto: productRecipeProductionDto,
        alertErrorMessage: "",
      });
    } else {
      productRecipeProductionDto[idx].productionMaterialGuid = "";
      productRecipeProductionDto[idx].productionMaterialError =
        CgnMessage.textErrorMessage;
      this.setState({
        productRecipeProductionDto: productRecipeProductionDto,
      });
    }
  }

  handleQualityInputValueChange(option, idx) {
    let productRecipeQualityDto = this.state.productRecipeQualityDto;
    if (option) {
      productRecipeQualityDto[idx].qualityMaterialGuid = option.value;
      productRecipeQualityDto[idx].qualityMaterialError = "";
      this.setState({
        productRecipeQualityDto: productRecipeQualityDto,
        alertErrorMessage: "",
      });
    } else {
      productRecipeQualityDto[idx].qualityMaterialGuid = "";
      productRecipeQualityDto[idx].qualityMaterialError =
        CgnMessage.textErrorMessage;
      this.setState({
        productRecipeQualityDto: productRecipeQualityDto,
      });
    }
  }

  async handleProductRecipeProductRemove(idx) {
    let someArray = await this.state.productRecipeProductDto;
    someArray.splice(idx, 1);
    this.setState({
      productRecipeProductDto: someArray,
      counter: this.state.counter - 1,
      isNull: false,
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        isNull: false,
        product2Guid: "",
        unit: "",
        piece: "",
      });
    }
  }

  async handleProductRecipeProductionRemove(idx) {
    let someArray = await this.state.productRecipeProductionDto;
    someArray.splice(idx, 1);
    this.setState({
      productRecipeProductionDto: someArray,
      counter1: this.state.counter1 - 1,
      isNull: false,
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        productionMaterialGuid: "",
      });
    }
  }

  async handleProductRecipeQualityRemove(idx) {
    let someArray = await this.state.productRecipeQualityDto;
    someArray.splice(idx, 1);
    this.setState({
      productRecipeQualityDto: someArray,
      counter2: this.state.counter2 - 1,
      isNull: false,
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        qualityMaterialGuid: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      productError: "",
      productRecipeProductError: "",
    });
    if (this.state.productGuid === "") {
      this.setState({
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.productRecipeProductDto.length === 0) {
      this.setState({
        productRecipeProductError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    var control = true;
    if (this.state.productRecipeProductDto.length > 0) {
      this.state.productRecipeProductDto.forEach((element) => {
        if (
          element.product2Guid === "" ||
          element.unit === "" ||
          element.piece === ""
        ) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    var control2 = true;
    if (this.state.productRecipeProductionDto.length > 0) {
      this.state.productRecipeProductionDto.forEach((element) => {
        if (element.productionMaterialGuid === "") {
          control2 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
        if (
          element.piece === "" ||
          element.piece === 0 ||
          element.piece === null
        ) {
          control2 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    var control3 = true;
    if (this.state.productRecipeQualityDto.length > 0) {
      this.state.productRecipeQualityDto.forEach((element) => {
        if (element.qualityMaterialGuid === "") {
          control3 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
        if (
          element.piece === "" ||
          element.piece === 0 ||
          element.piece === null
        ) {
          control3 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (
      this.state.productGuid !== "" &&
      control === true &&
      control2 === true &&
      control3 === true &&
      this.state.productRecipeProductDto !== null &&
      this.state.productRecipeProductDto.length > 0
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var productRecipeProductDto = [];
      this.state.productRecipeProductDto.forEach((element) => {
        productRecipeProductDto.push({
          guid: element.guid,
          productGuid: element.product2Guid,
          piece: parseFloat(element.piece),
          unit: element.unit,
        });
      });

      var productRecipeProductionDto = [];
      this.state.productRecipeProductionDto.forEach((element) => {
        productRecipeProductionDto.push({
          guid: element.guid,
          productionMaterialGuid: element.productionMaterialGuid,
          piece: parseFloat(element.piece),
        });
      });

      var productRecipeQualityDto = [];
      this.state.productRecipeQualityDto.forEach((element) => {
        productRecipeQualityDto.push({
          guid: element.guid,
          qualityMaterialGuid: element.qualityMaterialGuid,
          piece: parseFloat(element.piece),
        });
      });

      var myObj = {
        productGuid: this.state.productGuid,
        image: this.state.image,
        productRecipeProductDto: productRecipeProductDto,
        productRecipeProductionDto: productRecipeProductionDto,
        productRecipeQualityDto: productRecipeQualityDto,
        status: this.state.status,
      };
      var URLParam = "/productRecipes/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "urun-recete-guncelle"
      ) {
        URLParam = "/productRecipes/update";
        myObj = {
          guid: this.state.guid,
          productGuid: this.state.productGuid,
          image: this.state.image,
          productRecipeProductDto: productRecipeProductDto,
          productRecipeProductionDto: productRecipeProductionDto,
          productRecipeQualityDto: productRecipeQualityDto,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.urlSplit[1] === "urun-recete-ekle") {
              window.location.reload();
            }
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "urun-recete-guncelle"
            ) {
              window.location.href = "/urun-recete-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  render() {
    var self = this.state;
    return (
      <>
        {(this.state.urlSplit[1] === "urun-recete-ekle" ||
          this.state.urlSplit[1] === "urun-recete-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Fotoğraf"
                      image={this.state.image}
                      noFoto={noImg}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      error={this.state.imageError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Ürün seçiniz..."
                          selectValue={this.state.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productArr}
                          error={this.state.productError}
                        />
                      </div>
                      {this.state.productModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalProduct()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {this.state.productRecipeProductDto.map(
                  (productRecipeProductDto, idx) => (
                    <Row className="mobileBorder pl-0">
                      <Col lg="7" className="modalSelectCol pl-0">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="product2Guid"
                              label="Ürün Parçası [*]"
                              placeholder="Lütfen ürün parçası seçiniz..."
                              selectValue={productRecipeProductDto.product2Guid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleInputValueChange(option, idx);
                              }}
                              options={this.state.product2Arr}
                              error={productRecipeProductDto.product2Error}
                            />
                          </div>
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalProduct2(idx)}
                              text={"+"}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg="2">
                        <CgnTextbox
                          type="number"
                          name="piece"
                          label="Adet [*]"
                          value={productRecipeProductDto.piece}
                          onChange={(e) => this.handleTextBoxChange(e, idx)}
                          placeHolder="Lütfen adet girin..."
                          maxLength="50"
                          autoComplete="off"
                          error={productRecipeProductDto.pieceError}
                        />
                      </Col>

                      <Col lg="2">
                        <CgnReactSelect
                          name="unit"
                          label="Birim [*]"
                          placeholder="Birim"
                          selectValue={productRecipeProductDto.unit}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleUnitChange(option, idx)
                          }
                          options={unitValuesStatuses}
                          error={productRecipeProductDto.unitError}
                        />
                      </Col>

                      <Col lg="1" sm="3">
                        <CgnButton
                          type="button"
                          color="danger"
                          className="dynamicRowDeleteButton"
                          onClick={() =>
                            this.handleProductRecipeProductRemove(idx)
                          }
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  )
                )}

                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-2 dynamicRowAddButton"
                      onClick={this.handleProductRecipeProductAdd}
                      text={"Ürün Parçası Ekle"}
                    />
                    {self.productRecipeProductError && (
                      <div className="invalid-feedback d-block">
                        {self.productRecipeProductError}
                      </div>
                    )}
                  </Col>
                </Row>

                {this.state.productRecipeQualityDto.map(
                  (productRecipeQualityDto, idx) => (
                    <>
                      <Row className="mobileBorder pl-0">
                        <Col lg="9" className="modalSelectCol pl-0">
                          <div className="flex-container">
                            <div className="p-0 flexInput">
                              <CgnReactSelect
                                name="qualityMaterialGuid"
                                label="Kalite Alet veya Cihaz [*]"
                                placeholder="Lütfen alet veya cihaz seçiniz..."
                                selectValue={
                                  productRecipeQualityDto.qualityMaterialGuid
                                }
                                isMulti={false}
                                isClearable={true}
                                onChange={(option) => {
                                  this.handleQualityInputValueChange(
                                    option,
                                    idx
                                  );
                                }}
                                options={this.state.qualityMaterialArr}
                                error={
                                  productRecipeQualityDto.materialQualityError
                                }
                              />
                            </div>
                            {this.state.materialModalPlus && (
                              <div className="p-0 flexAddButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() =>
                                    this.addModalQualityMaterial(idx)
                                  }
                                  text={"+"}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg="2">
                          <CgnTextbox
                            type="number"
                            name="piece"
                            label="Adet [*]"
                            value={productRecipeQualityDto.piece}
                            onChange={(e) =>
                              this.handleQualityTextBoxChange(e, idx)
                            }
                            placeHolder="Lütfen adet girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productRecipeQualityDto.pieceError}
                            step="0.1"
                          />
                        </Col>
                        <Col lg="1">
                          <CgnButton
                            className="dynamicRowDeleteButton"
                            type="button"
                            color="danger"
                            onClick={() =>
                              this.handleProductRecipeQualityRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                )}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd mb-2"
                    onClick={this.handleQualityAdd}
                    text={"Kalite Alet veya Cihaz Ekle"}
                  />
                  {this.state.qualityDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.qualityDataError}
                    </div>
                  )}
                </Row>

                {this.state.productRecipeProductionDto.map(
                  (productRecipeProductionDto, idx) => (
                    <>
                      <Row className="mobileBorder pl-0">
                        <Col lg="9" className="modalSelectCol pl-0">
                          <div className="flex-container">
                            <div className="p-0 flexInput">
                              <CgnReactSelect
                                name="productionMaterialGuid"
                                label="Üretim Alet veya Cihaz [*]"
                                placeholder="Lütfen alet veya cihaz seçiniz..."
                                selectValue={
                                  productRecipeProductionDto.productionMaterialGuid
                                }
                                isMulti={false}
                                isClearable={true}
                                onChange={(option) => {
                                  this.handleProductionInputValueChange(
                                    option,
                                    idx
                                  );
                                }}
                                options={this.state.productionMaterialArr}
                                error={
                                  productRecipeProductionDto.materialQualityError
                                }
                              />
                            </div>
                            {this.state.materialModalPlus && (
                              <div className="p-0 flexAddButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() =>
                                    this.addModalProductMaterial(idx)
                                  }
                                  text={"+"}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg="2">
                          <CgnTextbox
                            type="number"
                            name="piece"
                            label="Adet [*]"
                            value={productRecipeProductionDto.piece}
                            onChange={(e) =>
                              this.handleProductionTextBoxChange(e, idx)
                            }
                            placeHolder="Lütfen adet girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productRecipeProductionDto.pieceError}
                            step="0.1"
                          />
                        </Col>
                        <Col lg="1">
                          <CgnButton
                            className="dynamicRowDeleteButton"
                            type="button"
                            color="danger"
                            onClick={() =>
                              this.handleProductRecipeProductionRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                )}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd mb-2"
                    onClick={this.handleProductionAdd}
                    text={"Üretim Alet veya Cihaz Ekle"}
                  />
                  {this.state.qualityDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.qualityDataError}
                    </div>
                  )}
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "urun-recete-ekle" ||
              this.state.urlSplit[1] === "urun-recete-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProduct2}
          toggle={this.addModalProduct2}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct2}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProductMaterial}
          toggle={this.addModalProductMaterial}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">El Aleti Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MaterialAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProductMaterial}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalQualityMaterial}
          toggle={this.addModalQualityMaterial}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">El Aleti Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MaterialAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalQualityMaterial}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(ProductRecipeAddUpdate);
