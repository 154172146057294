import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Label,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxType,
  TaxRateChoices,
  CollectionMethodType,
  CurrencyType,
  resizeFile,
  fotoStyle,
  PaymentMethodType,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";
import BankAddUpdate from "../bank/BankAddUpdate";
import LocationAddUpdate from "../location/LocationAddUpdate";

class ProductOutAddUpdate extends Component {
  state = {
    pageTitle: "Satış Ekle",
    pageTitleAdd: "Satış Ekle",
    pageTitleUpdate: "Satış Güncelle",
    pageAddLink: "satis-ekle",
    pageListLink: "satis-listele",
    breadcrumb: [{ text: "Satış", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productOutDate: "",
    customerGuid: "",
    locationguid: "",
    guid: "",
    incomeGuid: "",
    total: 0,
    generalTotal: parseFloat(0).toFixed(2),
    taxRateValue: "",
    taxTypeValue: null,
    discountPercent: "",
    discountValue: "",
    discountTotal: parseFloat(0).toFixed(2),
    currencyType: "",
    currencyTypeError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    description: "",
    status: true,
    productOutDateError: "",
    customerError: "",
    totalError: "",
    taxRateError: "",
    taxTypeError: "",
    productGuid: "",
    alertErrorMessage: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    isOpenModalCustomer: false,
    isOpenModalProduct: false,
    modalButtonIdx: 0,
    counter: -1,
    collectionCalendarData: [],
    productData: [],
    productDataError: "",
    productOutModal: false,
    multipleProductArr: [],
    receiptNo: "",
    expiry: null,
    collectionMethodValue: null,
    bankGuid: null,
    chequesBondNo: "",
    image: "",
    imageName: "",
    isOpenModalBank: false,
    isOpenModalLocation: false,
    isBankTrue: false,
    isChequesBondNoTrue: false,
    isHidden: true,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    customerModalPlus: false,
    productModalPlus: false,
    collectionModalPlus: false,
    bankModalPlus: false,
    collectionCalendarModalPlus: false,
    locationModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
      productModalPlus: await UserOperationClaimControl(1029),
      collectionModalPlus: await UserOperationClaimControl(1009),
      bankModalPlus: await UserOperationClaimControl(1003),
      collectionCalendarModalPlus: await UserOperationClaimControl(1008),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            salePrice: item.salePrice,
            code: item.code,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    if (urlSplit.length === 3 && urlSplit[1] === "satis-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/productOuts/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              productOutDate: response.data.productOutDate,
              incomeGuid: response.data.incomeGuid,
              customerGuid: response.data.customerGuid,
              productData: response.data.productOutProductDto,
              total: response.data.total,
              taxTypeValue: response.data.taxType,
              taxRateValue: response.data.taxRate.toString(),
              generalTotal: response.data.generalTotal,
              collectionCalendarData: response.data.collectionCalendarDto,
              currencyType: response.data.currencyType,
              discountPercent: response.data.discountPercent,
              discountTotal: response.data.discountTotal,
              discountValue: response.data.discountValue,
              description: response.data.description,
              status: response.data.status,
              receiptNo: response.data.receiptNo,
              expiry: response.data.expiry,
              collectionMethodValue: response.data.collectionMethodType,
              bankGuid: response.data.bankGuid,
              chequesBondNo: response.data.chequesBondNo,
              image: response.data.image,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        productOutDate: "",
        customerGuid: "",
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        discountTotal: "",
        currencyType: "",
        collectionCalendarData: [],
        isDiscountPercent: false,
        isDiscountValue: false,
        description: "",
        receiptNo: "",
        expiry: "",
        collectionMethodValue: null,
        bankGuid: null,
        chequesBondNo: "",
        image: "",
        imageName: "",
        status: true,
        isHidden: true,
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
              code: item.code,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
            }
          });
          if (this.props.location.modalAddedProduct.salePrice) {
            productData[this.state.modalButtonIdx].unitPrice =
              this.props.location.modalAddedProduct.salePrice;
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
          }
          this.setState({
            alertErrorMessage: "",
            isNull: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalBank &&
      this.props.location.modalAddedBank !== undefined &&
      this.props.location.modalAddedBank.isModal !== undefined &&
      this.props.location.modalAddedBank.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/banks/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            bankArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBank.data &&
              element.name === this.props.location.modalAddedBank.data
            ) {
              this.setState({
                bankGuid: element.guid,
                bankError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBank.isModal = true;
      this.setState({
        isOpenModalBank: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].locationGuid =
                element.guid;
              productData[this.state.modalButtonIdx].locationError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleCollectionMethodSelectChange(option) {
    if (option) {
      this.setState({
        collectionMethodValue: option.value,
        collectionMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else if (!this.state.isHidden) {
      this.setState({
        collectionMethodValue: null,
        collectionMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalBank = () => {
    this.setState({
      isOpenModalBank: !this.state.isOpenModalBank,
    });
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleDateChange(productOutDate) {
    if (productOutDate) {
      this.setState({
        productOutDate: productOutDate,
        productOutDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productOutDate: "",
        productOutDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      receiptNoError: "",
      expiryError: "",
      collectionMethodTypeError: "",
      bankError: "",
      chequesBondNoError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "customerGuid" && value === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  DiscountCalculate = async () => {
    var totalAmount = 0;
    if (this.state.generalTotal && this.state.discountPercent) {
      totalAmount =
        this.state.generalTotal * (1 - this.state.discountPercent / 100);
      if (this.state.discountTotal !== totalAmount) {
        this.state.discountTotal = totalAmount;
      }
    }
    if (this.state.generalTotal && this.state.discountPercent === "") {
      this.state.discountTotal = "";
    }
    if (this.state.generalTotal && this.state.discountValue) {
      totalAmount = this.state.generalTotal - this.state.discountValue;
      if (this.state.discountTotal !== totalAmount) {
        this.state.discountTotal = totalAmount;
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productOutModal: !this.state.productOutModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleMultipleProductAdd(item.value);
        }
      });
    });
  };

  handleMultipleProductAdd = async (value) => {
    let productData = this.state.productData;
    await productData.push({
      productGuid: value,
      locationGuid: "",
      piece: 1,
      unitPrice: 1,
      total: 1,
      locationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (
      productData[this.state.counter].productGuid === "" ||
      productData[this.state.counter].piece === 0 ||
      productData[this.state.counter].unitPrice === 0 ||
      productData[this.state.counter].locationGuid === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: productData });
  };

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var arrayCollectionCalendar = this.state.collectionCalendarData;
    arrayCollectionCalendar.forEach((item) => {
      item.targetCollectionDate = "";
      item.targetTotal = "";
      item.targetCollectionMethodType = null;
      item.targetCollectionDateError = CgnMessage.textErrorMessage;
      item.targetTotalError = CgnMessage.textErrorMessage;
      item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
      item.targetCurrencyType = CgnMessage.textErrorMessage;
      item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
    });
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.locationGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;

      item.productError = CgnMessage.textErrorMessage;
      item.productPieceError = CgnMessage.textErrorMessage;
      item.productUnitPriceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      productOutDate: "",
      customerGuid: "",
      total: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      description: "",
      status: true,
      receiptNo: "",
      expiry: "",
      collectionMethodValue: null,
      bankGuid: null,
      chequesBondNo: "",
      image: "",
      imageName: "",
      isBankTrue: false,
      isChequesBondNoTrue: false,
      isHidden: true,
      counter: -1,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      productOutDateError: CgnMessage.textErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: "",
      locationGuid: "",
      piece: null,
      unitPrice: null,
      total: 0,
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (
      array[this.state.counter].productGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].locationGuid === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].unitPrice = option.salePrice;
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].productUnitPriceError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productGuid = null;
      productData[idx].piece = 0;
      productData[idx].unitPrice = 0;
      productData[idx].productError = CgnMessage.textErrorMessage;
      productData[idx].productPieceError = CgnMessage.textErrorMessage;
      productData[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = "";
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  ProductPriceCalculate = () => {
    var totalProductPrice = 0;
    this.state.productData.forEach((item) => {
      item.total = (
        parseFloat(item.piece) * parseFloat(item.unitPrice)
      ).toFixed(2);
      totalProductPrice += parseFloat(item.total);
    });
    this.state.total = parseFloat(totalProductPrice).toFixed(2); // loop girmemesi için setState kullanmadım.
    this.PriceCalculate(null);
  };

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };
  async handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        total: 0,
      });
    }
  }

  handleCollectionCalendarAdd = async () => {
    let array = this.state.collectionCalendarData;
    await array.push({
      targetCollectionDate: "",
      targetTotal: null,
      targetCollectionMethodType: null,
      targetCurrencyType: null,
      targetCollectionDateError: "",
      targetTotalError: "",
      targetCollectionMethodTypeError: "",
      targetCurrencyTypeError: "",
    });
    array.forEach((item) => {
      if (
        array.length > 0 &&
        (!item.targetCollectionDate ||
          !item.targetTotal ||
          !item.targetCollectionMethodType ||
          !item.targetCurrencyType)
      ) {
        item.targetCollectionDateError = CgnMessage.textErrorMessage;
        item.targetTotalError = CgnMessage.textErrorMessage;
        item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
        item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        this.setState({
          isNull: true,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
    this.setState({ collectionCalendarData: array });
  };

  handleTextBoxChange(e, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    collectionCalendarData[idx].targetTotal = parseFloat(e.target.value);
    this.setState({
      collectionCalendarData: collectionCalendarData,
    });
    this.collectionCalendarRequiredField();
  }

  handleInputValueChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCollectionMethodType = option.value;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCollectionMethodType = null;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  handleTargetCollectionDateChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCollectionDate = option;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCollectionDate = "";
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  handleTargetCurrencyTypeChange(option, idx) {
    let collectionCalendarData = this.state.collectionCalendarData;
    if (option) {
      collectionCalendarData[idx].targetCurrencyType = option.value;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    } else {
      collectionCalendarData[idx].targetCurrencyType = null;
      this.setState({
        collectionCalendarData: collectionCalendarData,
      });
    }
    this.collectionCalendarRequiredField();
  }

  collectionCalendarRequiredField = () => {
    var isAlert = false;
    var arr = this.state.collectionCalendarData;
    arr.forEach((item) => {
      item.targetCollectionDateError = "";
      item.targetTotalError = "";
      item.targetCollectionDateError = "";
      item.targetCurrencyTypeError = "";
      item.targetCollectionMethodTypeError = "";

      if (!item.targetCollectionDate) {
        item.targetCollectionDateError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetTotal) {
        item.targetTotalError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCollectionMethodType) {
        item.targetCollectionMethodTypeError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCurrencyType) {
        item.targetCurrencyType = this.state.currencyType;
        // item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        // isAlert = true;
        // this.setState({
        //   isNull: true,
        // });
      }
    });
    if (isAlert) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else {
      this.setState({
        isNull: false,
      });
    }
  };

  handleCollectionCalendarRemove(idx) {
    let someArray = this.state.collectionCalendarData;
    someArray.splice(idx, 1);
    this.setState({ collectionCalendarData: someArray });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      productOutDateError: "",
      customerError: "",
      totalError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
      productDataError: "",
    });
    if (this.state.productOutDate === "") {
      this.setState({
        productOutDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxTypeValue === null) {
      this.setState({
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxRateValue === "") {
      this.setState({
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
      if (!this.state.isHidden && this.state.collectionMethodValue === null) {
        this.setState({
          collectionMethodTypeError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (
        !this.state.isHidden &&
        this.state.bankGuid === null &&
        (this.state.collectionMethodValue === 2 ||
          this.state.collectionMethodValue === 3)
      ) {
        this.setState({
          bankError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (
        !this.state.isHidden &&
        this.state.chequesBondNo === "" &&
        (this.state.collectionMethodValue === 1 ||
          this.state.collectionMethodValue === 5)
      ) {
        this.setState({
          chequesBondNoError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    } else if (
      this.state.productOutDate !== "" &&
      this.state.customerGuid !== "" &&
      this.state.total &&
      this.state.taxTypeValue &&
      this.state.taxRateValue &&
      this.state.currencyType &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      this.state.isNull === false &&
      (!this.state.isHidden
        ? this.state.collectionMethodValue !== "" &&
          ((this.state.bankGuid !== null &&
            (this.state.collectionMethodValue === 2 ||
              this.state.collectionMethodValue === 3)) ||
            (this.state.chequesBondNo !== "" &&
              (this.state.collectionMethodValue === 1 ||
                this.state.collectionMethodValue === 5)) ||
            (this.state.chequesBondNo === "" &&
              this.state.bankGuid === null &&
              this.state.collectionMethodValue === 4))
        : true)
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          productGuid: element.productGuid,
          locationGuid: element.locationGuid,
          piece: element.piece,
          unitPrice: element.unitPrice,
          total: parseFloat(element.total),
        });
      });
      var myObj = {
        productOutDate: this.state.productOutDate,
        customerGuid: this.state.customerGuid,
        taxType: this.state.taxTypeValue,
        taxRate: parseInt(this.state.taxRateValue),
        total: parseFloat(this.state.total),
        generalTotal: parseFloat(this.state.generalTotal),
        currencyType: this.state.currencyType,
        discountPercent:
          this.state.discountPercent !== ""
            ? parseInt(this.state.discountPercent)
            : 0,
        discountValue:
          this.state.discountValue !== ""
            ? parseFloat(this.state.discountValue)
            : 0,
        discountTotal: parseFloat(this.state.discountTotal),
        description: this.state.description,
        status: true, // this.state.status,
        collectionCalendarDto: this.state.collectionCalendarData,
        productOutProductDto: productData,
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        paymentMethodType: this.state.collectionMethodValue
          ? parseInt(this.state.collectionMethodValue)
          : 0,
        bankGuid: this.state.bankGuid,
        chequesBondNo: this.state.chequesBondNo,
        image: this.state.image,
        isHidden: this.state.isHidden,
      };
      var URLParam = "/productOuts/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/productOuts/update";
        myObj = {
          guid: this.state.guid,
          productOutDate: this.state.productOutDate,
          incomeGuid: this.state.incomeGuid,
          customerGuid: this.state.customerGuid,
          taxType: this.state.taxTypeValue,
          taxRate: parseInt(this.state.taxRateValue),
          total: parseFloat(this.state.total),
          generalTotal: parseFloat(this.state.generalTotal),
          currencyType: this.state.currencyType,
          discountPercent:
            this.state.discountPercent !== ""
              ? parseInt(this.state.discountPercent)
              : 0,
          discountValue:
            this.state.discountValue !== ""
              ? parseFloat(this.state.discountValue)
              : 0,
          discountTotal: parseFloat(this.state.discountTotal),
          description: this.state.description,
          status: true, // this.state.status
          collectionCalendarDto: this.state.collectionCalendarData,
          productOutProductDto: productData,
          isHidden: this.state.isHidden,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/satis-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "total" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var total = parseFloat(this.state.total);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = total - total * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = total - discountValue;
    } else {
      generalTotal = total;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }
    this.state.total = parseFloat(total).toFixed(2);
    this.state.generalTotal = parseFloat(generalTotal).toFixed(2);
    this.state.discountTotal = parseFloat(discountTotal).toFixed(2);
  };

  toggle = () => {
    this.setState({
      productOutModal: !this.state.productOutModal,
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productOutAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="productOutDate"
                      label="Tarih [*]"
                      selected={
                        this.state.productOutDate &&
                        this.state.productOutDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.productOutDate.split("/")[2] +
                                "-" +
                                this.state.productOutDate.split("/")[1] +
                                "-" +
                                this.state.productOutDate.split("/")[0]
                            )
                          : this.state.productOutDate
                      }
                      onChange={(productOutDate) =>
                        this.handleDateChange(productOutDate)
                      }
                      error={this.state.productOutDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {this.state.productData.map((productData, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            placeholder="Lütfen depo seçiniz..."
                            selectValue={productData.locationGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleLocationInputValueChange(option, idx);
                            }}
                            options={this.state.locationArr}
                            error={productData.locationError}
                          />
                        </div>
                        {this.state.locationModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalLocation(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="productGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={productData.productGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={productData.productError}
                          />
                        </div>
                        {this.state.productModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalProduct(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col lg="1">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={productData.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productPieceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="unitPrice"
                        label="Birim Fiyat [*]"
                        value={productData.unitPrice}
                        onChange={(e) =>
                          this.handleProductUnitPriceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen birim fiyat girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productUnitPriceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="total"
                        label="Fiyat [*]"
                        value={productData.total}
                        readOnly={true}
                        onChange={this.ProductPriceCalculate()}
                        maxLength="50"
                        autoComplete="off"
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggle}
                    text={"Çoklu Ürün Ekle"}
                  />
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Tutar [*]"
                      value={this.state.total}
                      placeHolder="Lütfen tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                      error={this.state.totalError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountPercent"
                      label="İskonto(Yüzde)"
                      value={this.state.discountPercent}
                      placeHolder="Lütfen iskonto girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountPercent}
                      onChange={this.PriceCalculate}
                      error={this.state.discountPercentError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountValue"
                      label="İskonto(Net Tutar)"
                      value={this.state.discountValue}
                      placeHolder="Lütfen iskonto net tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountValue}
                      onChange={this.PriceCalculate}
                      error={this.state.discountValueError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar [*]"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxTypeValue"
                      label="K.D.V Türü [*]"
                      placeholder="Lütfen K.D.V türü seçiniz..."
                      selectValue={this.state.taxTypeValue}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTaxTypeSelectChange(option)
                      }
                      options={TaxType}
                      error={this.state.taxTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxRateValue"
                      label="K.D.V Oranı [*]"
                      placeholder="Lütfen K.D.V oranı seçiniz..."
                      selectValue={this.state.taxRateValue}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTrue}
                      onChange={(option) =>
                        this.handleTaxRateSelectChange(option)
                      }
                      options={TaxRateChoices}
                      error={this.state.taxRateError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar [*]"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                    />
                  </Col>
                </Row>

                {this.state.collectionCalendarModalPlus &&
                  this.state.collectionCalendarData.map(
                    (collectionCalendarData, idx) => (
                      <Row className="mobileBorder">
                        <Col lg="3">
                          <CgnDatePicker
                            name="targetCollectionDate"
                            label="Tahsilat Tarihi [*]"
                            selected={
                              collectionCalendarData.targetCollectionDate &&
                              collectionCalendarData.targetCollectionDate
                                .toString()
                                .indexOf("/") > 0
                                ? new Date(
                                    collectionCalendarData.targetCollectionDate.split(
                                      "/"
                                    )[2] +
                                      "-" +
                                      collectionCalendarData.targetCollectionDate.split(
                                        "/"
                                      )[1] +
                                      "-" +
                                      collectionCalendarData.targetCollectionDate.split(
                                        "/"
                                      )[0]
                                  )
                                : collectionCalendarData.targetCollectionDate
                            }
                            onChange={(option) => {
                              this.handleTargetCollectionDateChange(
                                option,
                                idx
                              );
                            }}
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                            error={
                              collectionCalendarData.targetCollectionDateError
                            }
                          />
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="number"
                            name="targetTotal"
                            label="Tahsilat Tutarı [*]"
                            value={collectionCalendarData.targetTotal}
                            onChange={(e) => this.handleTextBoxChange(e, idx)}
                            placeHolder="Lütfen tutar girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={collectionCalendarData.targetTotalError}
                          />
                        </Col>

                        {/* <Col lg="3">
                        <CgnReactSelect
                          name="targetCurrencyType"
                          label="Para Birimi [*]"
                          placeholder="Lütfen para birimi seçiniz..."
                          selectValue={collectionCalendarData.targetCurrencyType}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTargetCurrencyTypeChange(option, idx)
                          }
                          options={CurrencyType}
                          error={collectionCalendarData.targetCurrencyTypeError}
                        />
                      </Col> */}

                        <Col lg="4">
                          <CgnReactSelect
                            name="targetCollectionMethodType"
                            label="Tahsilat Yöntemi [*]"
                            placeholder="Lütfen tahsilat yöntemi seçiniz..."
                            selectValue={
                              collectionCalendarData.targetCollectionMethodType
                            }
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleInputValueChange(option, idx);
                            }}
                            options={CollectionMethodType}
                            error={
                              collectionCalendarData.targetCollectionMethodTypeError
                            }
                          />
                        </Col>

                        <Col lg="1" sm="3">
                          <CgnButton
                            className="dynamicRowDeleteButton"
                            type="button"
                            color="danger"
                            onClick={() =>
                              this.handleCollectionCalendarRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    )
                  )}

                {this.state.collectionCalendarModalPlus && (
                  <Row>
                    <Col lg="4">
                      <CgnButton
                        type="button"
                        color="primary"
                        className="mb-3 dynamicRowAddButton"
                        onClick={this.handleCollectionCalendarAdd}
                        text={"Tahsilat Takvimi Ekle"}
                      />
                    </Col>
                  </Row>
                )}

                {this.state.collectionModalPlus &&
                  this.state.urlSplit.length === 2 && (
                    <>
                      <Row>
                        <Col
                          lg="12"
                          className="font-weight-bold"
                          style={{ padding: "0 15px 10px 15px" }}
                        >
                          <Label
                            check
                            className="labelCheck"
                            style={{ fontSize: "13px" }}
                          >
                            <Input
                              type="checkbox"
                              className="mt-1"
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              checked={!this.state.isHidden}
                            />{" "}
                            Tahsilat Yapıldı mı ?
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <CgnTextbox
                            type="text"
                            name="receiptNo"
                            label="Makbuz No"
                            value={this.state.receiptNo}
                            placeHolder="Lütfen makbuz no girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.receiptNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                        <Col lg="6">
                          <CgnTextbox
                            type="number"
                            name="expiry"
                            label="Vade"
                            value={this.state.expiry}
                            placeHolder="Lütfen vade sayısı girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.expiryError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <CgnReactSelect
                            name="collectionMethodValue"
                            label="Tahsilat Yöntemi [*]"
                            placeholder="Lütfen tahsilat yöntemi seçiniz..."
                            selectValue={this.state.collectionMethodValue}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleCollectionMethodSelectChange(option)
                            }
                            options={PaymentMethodType}
                            error={this.state.collectionMethodTypeError}
                            hidden={this.state.isHidden}
                          />
                        </Col>

                        <Col
                          lg="4"
                          className="modalSelectCol"
                          style={
                            this.state.isHidden
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <div className="flex-container">
                            <div className="p-0 flexMaxInput">
                              <CgnReactSelect
                                name="bankGuid"
                                label="Banka"
                                placeholder="Banka seçiniz..."
                                selectValue={this.state.bankGuid}
                                isMulti={false}
                                isClearable={true}
                                disabled={this.state.isBankTrue}
                                onChange={(option) =>
                                  this.handleBankSelectChange(option)
                                }
                                options={this.state.bankArr}
                                error={this.state.bankError}
                                hidden={this.state.isHidden}
                              />
                            </div>
                            {this.state.bankModalPlus && (
                              <div className="p-0 flexAddMinButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalBank()}
                                  text={"+"}
                                  visibled={this.state.isHidden}
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="text"
                            name="chequesBondNo"
                            label="Çek/Senet No"
                            value={this.state.chequesBondNo}
                            placeHolder="Lütfen çek/senet no girin..."
                            autoComplete="off"
                            readOnly={this.state.isChequesBondNoTrue}
                            onChange={this.handleChange}
                            error={this.state.chequesBondNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <ImageUpload
                            accept="image/png, image/jpeg"
                            className="text-center"
                            style={fotoStyle}
                            label="Makbuz Fotoğrafı"
                            image={this.state.image}
                            noFoto={noImage}
                            alt={this.state.name}
                            onChange={this.uploadedImage}
                            onDelete={this.deleteImage}
                            error={this.state.imageError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12" >
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />

                <Row>
                  <Modal
                    isOpen={this.state.productOutModal}
                    toggle={this.toggle}
                  >
                    <ModalHeader
                      toggle={this.toggle}
                      className="font-weight-bold"
                    >
                      Çoklu Ürün Ekle
                    </ModalHeader>
                    <ModalBody className="modalBody">
                      <Input
                        type="textarea"
                        className="modalTextArea"
                        onChange={(e) => this.handleChangeMultipleProduct(e)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <CgnButton
                        type="button"
                        color="primary"
                        onClick={() => this.handleClickMultipleProduct()}
                        text={CgnMessage.addButton}
                        className="modalButton"
                      />
                      <CgnButton
                        type="button"
                        color="secondary"
                        onClick={this.toggle}
                        text="Kapat"
                        className="modalButton"
                      />
                    </ModalFooter>
                  </Modal>
                </Row>
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalBank}
          toggle={this.addModalBank}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Banka Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <BankAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBank}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductOutAddUpdate;
