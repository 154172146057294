import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, fotoStyle } from "../../redux/actions/constants";
import noFotoMember from "../../images/no-foto-user.jpg";

class CompanyDetail extends Component {
  state = {
    pageTitle: "Firma Detay",
    pageTitleDetail: "Firma Detay",
    pageAddLink: "firma-ekle",
    pageListLink: "firma-listele",
    breadcrumb: [{ text: "Firma", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    guid: "",
    name: "",
    taxNo: "",
    commercialRegistrationNumber: "",
    mersisNumber: "",
    veribanUsername: "",
    veribanPassword: "",
    taxAdministration: "",
    address: "",
    phone: "",
    fax: "",
    remindDay: null,
    eMail: "",
    website: "",
    logo: "",
    logoName: "",
    icon: "",
    iconName: "",
    districtName: "",
    townName: "",
    cityName: "",
    countryGuid: "",
    countryName: "",
    isCityTrue: true,
    isDistrictTrue: true,
    isTownTrue: true,
    labelIsTurkey: false,
    zipCode: "",
    messagePort: "",
    messageHost: "",
    messageEMail: "",
    messagePassword: "",
    gsm: "",
    whatsapp: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    isDeleted: false,
    status: false,
    createdBy: 0,
    createdAt: "",
    updatedBy: 0,
    updatedAt: "",
    isOpen: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/companies/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            name: response.data.name,
            taxNo: response.data.taxNo,
            commercialRegistrationNumber: response.data.commercialRegistrationNumber,
            mersisNumber: response.data.mersisNumber,
            veribanUsername: response.data.veribanUsername,
            veribanPassword: response.data.veribanPassword,
            taxAdministration: response.data.taxAdministration,
            address: response.data.address,
            phone: response.data.phone,
            fax: response.data.fax,
            eMail: response.data.eMail,
            website: response.data.website,
            logo: response.data.logo,
            icon: response.data.icon,
            districtName: response.data.districtName,
            townName: response.data.townName,
            cityName: response.data.cityName,
            countryGuid: response.data.countryGuid,
            countryName: response.data.countryName,
            zipCode: response.data.zipCode,
            status: response.data.status,
            messagePort: response.data.messagePort,
            messageHost: response.data.messageHost,
            messageEMail: response.data.messageEMail,
            gsm: response.data.gsm,
            whatsapp: response.data.whatsapp,
            facebook: response.data.facebook,
            twitter: response.data.twitter,
            instagram: response.data.instagram,
            youtube: response.data.youtube,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            remindDay: response.data.remindDay,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    var phone = this.state.phone,
      maskPhone = `${phone.substring(0, 1)}  ${
        "(" + phone.substring(1, 4) + ")"
      } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
        9,
        11
      )}`;

    if (this.state.fax) {
      var fax = this.state.fax,
        maskFax = `${fax.substring(0, 1)}  ${
          "(" + fax.substring(1, 4) + ")"
        } ${fax.substring(4, 7)} ${fax.substring(7, 9)} ${fax.substring(
          9,
          11
        )}`;
    }

    if (this.state.gsm) {
      var gsm = this.state.gsm,
        maskGsm = `${gsm.substring(0, 1)}  ${
          "(" + gsm.substring(1, 4) + ")"
        } ${gsm.substring(4, 7)} ${gsm.substring(7, 9)} ${gsm.substring(
          9,
          11
        )}`;
    }

    if (this.state.whatsapp) {
      var whatsapp = this.state.whatsapp,
        maskWhatsapp = `${whatsapp.substring(0, 1)}  ${
          "(" + whatsapp.substring(1, 4) + ")"
        } ${whatsapp.substring(4, 7)} ${whatsapp.substring(
          7,
          9
        )} ${whatsapp.substring(9, 11)}`;
    }
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="employeeDetail">
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Ad</th>
                    <td colSpan="4">{this.state.name}</td>
                  </tr>

                  <tr>
                    <th>Logo</th>
                    <td colSpan="4">
                      {this.state.logo ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.logo}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noFotoMember}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      )}
                    </td>
                  </tr>

                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.logo}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal>

                  <tr>
                    <th>İkon</th>
                    <td colSpan="4">
                      {this.state.icon ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.icon}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noFotoMember}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      )}
                    </td>
                  </tr>

                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.icon}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal>

                  <tr>
                    <th>Vergi Dairesi</th>
                    <td colSpan="4">{this.state.taxAdministration}</td>
                  </tr>

                  <tr>
                    <th>Vergi Numarası</th>
                    <td colSpan="4">{this.state.taxNo}</td>
                  </tr>

                  <tr>
                    <th>Ticari Sicil Numarası</th>
                    <td colSpan="4">{this.state.commercialRegistrationNumber}</td>
                  </tr>

                  <tr>
                    <th>Mersis Numarası</th>
                    <td colSpan="4">{this.state.mersisNumber}</td>
                  </tr>

                  <tr>
                    <th>Veriban Kullanıcı Adı</th>
                    <td colSpan="4">{this.state.veribanUsername}</td>
                  </tr>

                  <tr>
                    <th>Veriban Şifre</th>
                    <td colSpan="4">{this.state.veribanPassword}</td>
                  </tr>

                  <tr>
                    <th>Adres</th>
                    <td colSpan="4">
                      {this.state.address ? this.state.address : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Mahalle/İlçe/Şehir/Ülke</th>
                    <td colSpan="4">
                      {`${
                        this.state.districtName
                          ? this.state.districtName + "/"
                          : ""
                      }${this.state.townName ? this.state.townName + "/" : ""}${
                        this.state.cityName ? this.state.cityName + "/" : ""
                      }${
                        this.state.countryName ? this.state.countryName : "-"
                      }`}
                    </td>
                  </tr>

                  <tr>
                    <th>Posta Kodu</th>
                    <td colSpan="4">
                      {this.state.zipCode ? this.state.zipCode : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>E-Mail</th>
                    <td colSpan="4">
                      {this.state.eMail ? this.state.eMail : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Telefon</th>
                    <td colSpan="4">{maskPhone ? maskPhone : "-"}</td>
                  </tr>

                  <tr>
                    <th>Faks</th>
                    <td colSpan="4">{maskFax ? maskFax : "-"}</td>
                  </tr>

                  <tr>
                    <th>GSM</th>
                    <td colSpan="4">{maskGsm ? maskGsm : "-"}</td>
                  </tr>

                  <tr>
                    <th>Whatsapp</th>
                    <td colSpan="4">{maskWhatsapp ? maskWhatsapp : "-"}</td>
                  </tr>

                  <tr>
                    <th>Facebook</th>
                    <td colSpan="4">
                      <a href={this.state.facebook} target="_blank" rel="noreferrer">
                        {this.state.facebook}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <th>Twitter</th>
                    <td colSpan="4">
                      <a href={this.state.twitter} target="_blank" rel="noreferrer">
                        {this.state.twitter}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <th>Instagram</th>
                    <td colSpan="4">
                      <a href={this.state.instagram} target="_blank" rel="noreferrer">
                        {this.state.instagram}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <th>Youtube</th>
                    <td colSpan="4">
                      <a href={this.state.youtube} target="_blank" rel="noreferrer">
                        {this.state.youtube}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <th>Hatırlatma Günü</th>
                    <td colSpan="4">{this.state.remindDay === null ? "-" : this.state.remindDay}</td>
                  </tr>

                  <tr>
                    <th>Giden Posta Sunucusu Adresi</th>
                    <td colSpan="4">{this.state.messageHost}</td>
                  </tr>

                  <tr>
                    <th>Giden Posta Sunucu Portu</th>
                    <td colSpan="4">{this.state.messagePort}</td>
                  </tr>

                  <tr>
                    <th>Gönderen E-Mail</th>
                    <td colSpan="4">{this.state.messageEMail}</td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>
                      {this.state.createdByFullName
                        ? this.state.createdByFullName
                        : "-"}
                    </td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>
                      {this.state.updatedByFullName
                        ? this.state.updatedByFullName
                        : "-"}
                    </td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CompanyDetail;
