import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL, CustomerList, documentStatus} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class CustomerDocumentSuitableList extends Component {
  state = {
    pageTitle: "Çevirisi Yapılan Belgeleri Listele (ÜTS Belge Kayıt)",
    pageDetailLink: "ceviri-bekleyen-kayit-detay",
    pageListLink: "ceviri-bekleyen-kayitlari-listele",
    breadcrumb: [{ text: "Çeviri Bekleyen Kayıtlar", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    customerDocumentData: [],
    name: "",
    guid: "",
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidCustomerDocumentSuitable")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidCustomerDocumentSuitable"),
      });
    }

    this.getList();  
  }


  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      //var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem("firstDateCustomerDocumentSuitable", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      //var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate:  "",
        lastDate2: "",
      });
    }
    localStorage.setItem("lastDateCustomerDocumentSuitable", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidCustomerDocumentSuitable", this.state.customerGuid);
  }

  async handleDocumentStatusSelectChange(option) {
    if (option) {
      await this.setState({
        resultChangeStatus: option.value,
      });
    } else {
      await this.setState({
        resultChangeStatus: 3,
      });
    }
    
    localStorage.setItem("resultChangeStatusCustomerDocumentSuitable", this.state.resultChangeStatus);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var URLParam = "";
    URLParam =
      "/customerDocumentRecords/getsuitablelist/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
      "/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {       
          this.setState({
            customerDocumentData: response.data,
            isLoading: false,
          });              
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/customerDocumentRecords/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "75px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "400px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="canalList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
          <Row className="filterRow">
                <Col className="pl-2 pr-2" lg="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" lg="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" lg="7">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>              
                <Col className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.customerDocumentData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Çevirisi Yapılan Belgeler",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td style={this.tdStyle}>
                                {row.customerName}
                              </td>

                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>{row.createdBy}</td>
                            </tr>                            
                          </tbody>
                        </Table>
                      );
                    },
                  },                
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          // {
                          //   text: CgnMessage.deleteButton,
                          //   className: "dropDownBtn",
                          //   onClick: () => this.handleDelete(row.guid),
                          // },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerDocumentSuitableList;
