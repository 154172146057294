import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxType,
  TaxRateChoices,
  PaymentMethodType,
  CurrencyType,
  resizeFile,
  fotoStyle,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";
import BankAddUpdate from "../bank/BankAddUpdate";
import LocationAddUpdate from "../location/LocationAddUpdate";

class ProductEntryAddUpdate extends Component {
  state = {
    pageTitle: "Alış Ekle",
    pageTitleAdd: "Alış Ekle",
    pageTitleUpdate: "Alış Güncelle",
    pageAddLink: "alis-ekle",
    pageListLink: "alis-listele",
    breadcrumb: [{ text: "Alış", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productEntryDate: "",
    supplierGuid: "",
    guid: "",
    costGuid: "",
    total: 0,
    generalTotal: null,
    taxRateValue: "",
    taxTypeValue: null,
    discountPercent: "",
    discountValue: "",
    discountTotal: "",
    currencyType: "",
    description: "",
    currencyTypeError: "",
    productDataError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    productGuid: "",
    locationGuid: "",
    status: true,
    receiptNo: "",
    expiry: null,
    paymentMethodValue: null,
    bankGuid: null,
    chequesBondNo: "",
    image: "",
    imageName: "",
    isOpenModalBank: false,
    isBankTrue: false,
    isChequesBondNoTrue: false,
    isHidden: true,
    productEntryDateError: "",
    supplierError: "",
    totalError: "",
    taxRateError: "",
    taxTypeError: "",
    alertErrorMessage: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    receiptNoError: "",
    expiryError: "",
    paymentMethodTypeError: "",
    bankError: "",
    chequesBondNoError: "",
    isOpenModalSupplier: false,
    isOpenModalLocation: false,
    isOpenModalProduct: false,
    isOpenModalBrand: false,
    modalButtonIdx: 0,
    counter: -1,
    paymentCalendarData: [],
    productData: [],
    productEntryModal: false,
    multipleProductArr: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    supplierModalPlus: false,
    productModalPlus: false,
    paymentCalendarModalPlus: false,
    paymentModalPlus: false,
    bankModalPlus: false,
    locationModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      supplierModalPlus: await UserOperationClaimControl(1038),
      productModalPlus: await UserOperationClaimControl(1029),
      paymentCalendarModalPlus: await UserOperationClaimControl(1025),
      paymentModalPlus: await UserOperationClaimControl(1026),
      bankModalPlus: await UserOperationClaimControl(1003),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            buyPrice: item.buyPrice,
            code: item.code,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    if (urlSplit.length === 3 && urlSplit[1] === "alis-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/productEntries/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              productEntryDate: response.data.productEntryDate,
              costGuid: response.data.costGuid,
              supplierGuid: response.data.supplierGuid,
              productData: response.data.productEntryProductDto,
              total: response.data.total,
              taxTypeValue: response.data.taxType,
              taxRateValue: response.data.taxRate.toString(),
              generalTotal: response.data.generalTotal,
              paymentCalendarData: response.data.paymentCalendarDto,
              currencyType: response.data.currencyType,
              discountPercent: response.data.discountPercent,
              discountTotal: response.data.discountTotal,
              discountValue: response.data.discountValue,
              description: response.data.description,
              receiptNo: response.data.receiptNo,
              expiry: response.data.expiry,
              paymentMethodValue: response.data.paymentMethodType,
              bankGuid: response.data.bankGuid,
              chequesBondNo: response.data.chequesBondNo,
              image: response.data.image,
              status: response.data.status,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        productEntryDate: "",
        supplierGuid: "",
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        discountTotal: "",
        currencyType: "",
        paymentCalendarData: [],
        isDiscountPercent: false,
        isDiscountValue: false,
        description: "",
        receiptNo: "",
        expiry: "",
        paymentMethodValue: null,
        bankGuid: null,
        chequesBondNo: "",
        image: "",
        imageName: "",
        status: true,
        isHidden: true,
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalSupplier &&
      this.props.location.modalAddedSupplier !== undefined &&
      this.props.location.modalAddedSupplier.isModal !== undefined &&
      this.props.location.modalAddedSupplier.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/suppliers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            supplierArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSupplier.data &&
              element.name === this.props.location.modalAddedSupplier.data
            ) {
              this.setState({
                supplierGuid: element.guid,
                supplierError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSupplier.isModal = true;
      this.setState({
        isOpenModalSupplier: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
              code: item.code,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
            }
          });
          if (this.props.location.modalAddedProduct.buyPrice) {
            productData[this.state.modalButtonIdx].unitPrice =
              this.props.location.modalAddedProduct.buyPrice;
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
          }
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }

    if (
      this.state.isOpenModalBank &&
      this.props.location.modalAddedBank !== undefined &&
      this.props.location.modalAddedBank.isModal !== undefined &&
      this.props.location.modalAddedBank.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/banks/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            bankArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBank.data &&
              element.name === this.props.location.modalAddedBank.data
            ) {
              this.setState({
                bankGuid: element.guid,
                bankError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBank.isModal = true;
      this.setState({
        isOpenModalBank: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].locationGuid =
                element.guid;
              productData[this.state.modalButtonIdx].locationError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleDateChange(productEntryDate) {
    if (productEntryDate) {
      this.setState({
        productEntryDate: productEntryDate,
        productEntryDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productEntryDate: "",
        productEntryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePaymentMethodSelectChange(option) {
    if (option) {
      this.setState({
        paymentMethodValue: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else if (!this.state.isHidden) {
      this.setState({
        paymentMethodValue: null,
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else if (this.state.isHidden) {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalBank = () => {
    this.setState({
      isOpenModalBank: !this.state.isOpenModalBank,
    });
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      supplierError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      paymentMethodTypeError: "",
      bankError: "",
      chequesBondNoError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "supplierGuid" && value === "") {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.discountPercent) {
      await this.setState({
        discountValue: null,
        isDiscountValue: true,
      });
    }
    if (this.state.discountValue) {
      await this.setState({
        discountPercent: null,
        isDiscountPercent: true,
      });
    }
    if (this.state.discountPercent === "") {
      await this.setState({
        isDiscountValue: false,
      });
    }
    if (this.state.discountValue === "") {
      await this.setState({
        isDiscountPercent: false,
      });
    }
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  DiscountCalculate = async () => {
    var totalAmount = 0;
    if (this.state.generalTotal && this.state.discountPercent) {
      totalAmount =
        this.state.generalTotal * (1 - this.state.discountPercent / 100);
      if (this.state.discountTotal !== totalAmount) {
        this.state.discountTotal = totalAmount;
      }
    }
    if (this.state.generalTotal && this.state.discountPercent === "") {
      this.state.discountTotal = "";
    }
    if (this.state.generalTotal && this.state.discountValue) {
      totalAmount = this.state.generalTotal - this.state.discountValue;
      if (this.state.discountTotal !== totalAmount) {
        this.state.discountTotal = totalAmount;
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleSupplierSelectChange(option) {
    if (option) {
      this.setState({
        supplierGuid: option.value,
        supplierError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        supplierGuid: "",
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleMultipleProductAdd(item.value);
        }
      });
    });
  };

  handleMultipleProductAdd = async (value) => {
    let productData = this.state.productData;
    await productData.push({
      productGuid: value,
      locationGuid: "",
      piece: 1,
      unitPrice: 1,
      total: 1,
      locationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (
      productData[this.state.counter].productGuid === "" ||
      productData[this.state.counter].piece === 0 ||
      productData[this.state.counter].unitPrice === 0 ||
      productData[this.state.counter].locationGuid === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: productData });
  };

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var arrayPaymentCalendar = this.state.paymentCalendarData;
    arrayPaymentCalendar.forEach((item) => {
      item.targetPaymentDate = "";
      item.targetTotal = "";
      item.targetPaymentMethodType = null;
      item.targetPaymentDateError = CgnMessage.textErrorMessage;
      item.targetTotalError = CgnMessage.textErrorMessage;
      item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
      item.targetCurrencyType = CgnMessage.textErrorMessage;
      item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
    });
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.locationGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;

      item.productError = CgnMessage.textErrorMessage;
      item.locationError = CgnMessage.textErrorMessage;
      item.productPieceError = CgnMessage.textErrorMessage;
      item.productUnitPriceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      productEntryDate: "",
      supplierGuid: "",
      total: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      description: "",
      status: true,
      receiptNo: "",
      expiry: "",
      paymentMethodValue: null,
      bankGuid: null,
      chequesBondNo: "",
      image: "",
      imageName: "",
      isBankTrue: false,
      isChequesBondNoTrue: false,
      isHidden: true,
      counter: -1,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      productEntryDateError: CgnMessage.textErrorMessage,
      supplierError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: "",
      locationGuid: "",
      piece: 0,
      unitPrice: 0,
      total: 0,
      productError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (
      array[this.state.counter].productGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].locationGuid === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].unitPrice = option.buyPrice;
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].productUnitPriceError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productGuid = null;
      productData[idx].piece = 0;
      productData[idx].unitPrice = 0;
      productData[idx].productError = CgnMessage.textErrorMessage;
      productData[idx].productPieceError = CgnMessage.textErrorMessage;
      productData[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = "";
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate();
  }

  ProductPriceCalculate = () => {
    var totalProductPrice = 0;
    this.state.productData.forEach((item) => {
      item.total = (
        parseFloat(item.piece) * parseFloat(item.unitPrice)
      ).toFixed(2);
      totalProductPrice += parseFloat(item.total);
    });
    this.state.total = parseFloat(totalProductPrice).toFixed(2); // loop girmemesi için setState kullanmadım.
    this.PriceCalculate(null);
  };

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        total: 0,
      });
    }
  }
  handlePaymentCalendarAdd = async () => {
    let array = this.state.paymentCalendarData;
    await array.push({
      targetPaymentDate: "",
      targetTotal: null,
      targetPaymentMethodType: null,
      targetCurrencyType: null,
      targetPaymentDateError: "",
      targetTotalError: "",
      targetPaymentMethodTypeError: "",
      targetCurrencyTypeError: "",
    });
    array.forEach((item) => {
      if (
        array.length > 0 &&
        (!item.targetPaymentDate ||
          !item.targetTotal ||
          !item.targetPaymentMethodType ||
          !item.targetCurrencyType)
      ) {
        item.targetPaymentDateError = CgnMessage.textErrorMessage;
        item.targetTotalError = CgnMessage.textErrorMessage;
        item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
        item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        this.setState({
          isNull: true,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
    this.setState({ paymentCalendarData: array });
  };

  handleTextBoxChange(e, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    paymentCalendarData[idx].targetTotal = parseFloat(e.target.value);
    this.setState({
      paymentCalendarData: paymentCalendarData,
    });
    this.paymentCalendarRequiredField();
  }

  handleInputValueChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetPaymentMethodType = option.value;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetPaymentMethodType = null;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  handleTargetPaymentDateChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetPaymentDate = option;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetPaymentDate = "";
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  handleTargetCurrencyTypeChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetCurrencyType = option.value;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetCurrencyType = null;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  paymentCalendarRequiredField = () => {
    var isAlert = false;
    var arr = this.state.paymentCalendarData;
    arr.forEach((item) => {
      item.targetPaymentDateError = "";
      item.targetTotalError = "";
      item.targetPaymentMethodTypeError = "";
      item.targetCurrencyTypeError = "";

      if (!item.targetPaymentDate) {
        item.targetPaymentDateError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetTotal) {
        item.targetTotalError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetPaymentMethodType) {
        item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCurrencyType) {
        item.targetCurrencyType = this.state.currencyType;
        // item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        // isAlert = true;
        // this.setState({
        //   isNull: true,
        // });
      }
    });
    if (isAlert) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else {
      this.setState({
        isNull: false,
      });
    }
  };

  handlePaymentCalendarRemove(idx) {
    let someArray = this.state.paymentCalendarData;
    someArray.splice(idx, 1);
    this.setState({ paymentCalendarData: someArray });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      productEntryDateError: "",
      supplierError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      productDataError: "",
      alertErrorMessage: "",
    });
    if (this.state.productEntryDate === "") {
      this.setState({
        productEntryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.supplierGuid === "") {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxTypeValue === null) {
      this.setState({
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxRateValue === "") {
      this.setState({
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.isHidden && this.state.paymentMethodValue === null) {
      this.setState({
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.bankGuid === null &&
      (this.state.paymentMethodValue === 2 ||
        this.state.paymentMethodValue === 3)
    ) {
      this.setState({
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.chequesBondNo === "" &&
      (this.state.paymentMethodValue === 1 ||
        this.state.paymentMethodValue === 5)
    ) {
      this.setState({
        chequesBondNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.productEntryDate !== "" &&
      this.state.supplierGuid !== "" &&
      this.state.total &&
      this.state.taxTypeValue &&
      this.state.taxRateValue &&
      this.state.currencyType &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      this.state.isNull === false &&
      (!this.state.isHidden
        ? this.state.paymentMethodValue !== "" &&
          ((this.state.bankGuid !== null &&
            (this.state.paymentMethodValue === 2 ||
              this.state.paymentMethodValue === 3)) ||
            (this.state.chequesBondNo !== "" &&
              (this.state.paymentMethodValue === 1 ||
                this.state.paymentMethodValue === 5)) ||
            (this.state.chequesBondNo === "" &&
              this.state.bankGuid === null &&
              this.state.paymentMethodValue === 4))
        : true)
    ) {
      this.setState({
        isLoading: true,
      });
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          locationGuid: element.locationGuid,
          productGuid: element.productGuid,
          piece: element.piece,
          unitPrice: element.unitPrice,
          total: parseFloat(element.total),
        });
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        productEntryDate: this.state.productEntryDate,
        supplierGuid: this.state.supplierGuid,
        taxType: this.state.taxTypeValue,
        taxRate: parseInt(this.state.taxRateValue),
        total: parseFloat(this.state.total),
        generalTotal: parseFloat(this.state.generalTotal),
        currencyType: this.state.currencyType,
        discountPercent:
          this.state.discountPercent !== ""
            ? parseInt(this.state.discountPercent)
            : 0,
        discountValue:
          this.state.discountValue !== ""
            ? parseFloat(this.state.discountValue)
            : 0,
        discountTotal: parseFloat(this.state.discountTotal),
        description: this.state.description,
        status: true, //this.state.status,
        paymentCalendarDto: this.state.paymentCalendarData,
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        paymentMethodType: this.state.paymentMethodValue
          ? parseInt(this.state.paymentMethodValue)
          : 0,
        bankGuid: this.state.bankGuid,
        chequesBondNo: this.state.chequesBondNo,
        image: this.state.image,
        productEntryProductDto: productData,
        isHidden: this.state.isHidden,
      };
      var URLParam = "/productentries/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/productentries/update";
        myObj = {
          guid: this.state.guid,
          productEntryDate: this.state.productEntryDate,
          costGuid: this.state.costGuid,
          supplierGuid: this.state.supplierGuid,
          taxType: this.state.taxTypeValue,
          taxRate: parseInt(this.state.taxRateValue),
          total: parseFloat(this.state.total),
          generalTotal: parseFloat(this.state.generalTotal),
          currencyType: this.state.currencyType,
          discountPercent:
            this.state.discountPercent !== ""
              ? parseInt(this.state.discountPercent)
              : 0,
          discountValue:
            this.state.discountValue !== ""
              ? parseFloat(this.state.discountValue)
              : 0,
          discountTotal: parseFloat(this.state.discountTotal),
          description: this.state.description,
          status: true, //this.state.status,
          paymentCalendarDto: this.state.paymentCalendarData,
          productEntryProductDto: productData,
          isHidden: this.state.isHidden,
        };
      }

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/alis-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
      });
      if (name === "total" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var total = parseFloat(this.state.total);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = total - total * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = total - discountValue;
    } else {
      generalTotal = total;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }
    this.state.total = parseFloat(total).toFixed(2);
    this.state.generalTotal = parseFloat(generalTotal).toFixed(2);
    this.state.discountTotal = parseFloat(discountTotal).toFixed(2);
  };

  toggle = () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productEntryAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="productEntryDate"
                      label="Tarih [*]"
                      selected={
                        this.state.productEntryDate &&
                        this.state.productEntryDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.productEntryDate.split("/")[2] +
                                "-" +
                                this.state.productEntryDate.split("/")[1] +
                                "-" +
                                this.state.productEntryDate.split("/")[0]
                            )
                          : this.state.productEntryDate
                      }
                      onChange={(productEntryDate) =>
                        this.handleDateChange(productEntryDate)
                      }
                      error={this.state.productEntryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="supplierGuid"
                          label="Tedarikçi [*]"
                          placeholder="Tedarikçi seçiniz..."
                          selectValue={this.state.supplierGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSupplierSelectChange(option)
                          }
                          options={this.state.supplierArr}
                          error={this.state.supplierError}
                        />
                      </div>
                      {this.state.supplierModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalSupplier()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {this.state.productData.map((productData, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            placeholder="Lütfen depo seçiniz..."
                            selectValue={productData.locationGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleLocationInputValueChange(option, idx);
                            }}
                            options={this.state.locationArr}
                            error={productData.locationError}
                          />
                        </div>
                        {this.state.locationModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalLocation(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="3" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="productGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={productData.productGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={productData.productError}
                          />
                        </div>
                        {this.state.productModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalProduct(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col lg="1">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={productData.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productPieceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="unitPrice"
                        label="Birim Fiyat [*]"
                        value={productData.unitPrice}
                        onChange={(e) =>
                          this.handleProductUnitPriceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen birim fiyat girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productUnitPriceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="total"
                        label="Fiyat [*]"
                        value={productData.total}
                        readOnly={true}
                        onChange={this.ProductPriceCalculate()}
                        maxLength="50"
                        autoComplete="off"
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        type="button"
                        color="danger"
                        className="dynamicRowDeleteButton"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggle}
                    text={"Çoklu Ürün Ekle"}
                  />
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Tutar [*]"
                      value={this.state.total}
                      placeHolder="Lütfen tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                      error={this.state.totalError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountPercent"
                      label="İskonto(Yüzde)"
                      value={this.state.discountPercent}
                      placeHolder="Lütfen iskonto girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountPercent}
                      onChange={this.PriceCalculate}
                      error={this.state.discountPercentError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountValue"
                      label="İskonto(Net Tutar)"
                      value={this.state.discountValue}
                      placeHolder="Lütfen iskonto net tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountValue}
                      onChange={this.PriceCalculate}
                      error={this.state.discountValueError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxTypeValue"
                      label="K.D.V Türü [*]"
                      placeholder="Lütfen K.D.V türü seçiniz..."
                      selectValue={this.state.taxTypeValue}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTaxTypeSelectChange(option)
                      }
                      options={TaxType}
                      error={this.state.taxTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxRateValue"
                      label="K.D.V Oranı [*]"
                      placeholder="Lütfen K.D.V oranı seçiniz..."
                      selectValue={this.state.taxRateValue}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTrue}
                      onChange={(option) =>
                        this.handleTaxRateSelectChange(option)
                      }
                      options={TaxRateChoices}
                      error={this.state.taxRateError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                {this.state.paymentCalendarModalPlus &&
                  this.state.paymentCalendarData.map(
                    (paymentCalendarData, idx) => (
                      <Row className="mobileBorder">
                        <Col lg="3">
                          <CgnDatePicker
                            name="targetPaymentDate"
                            label="Ödeme Tarihi [*]"
                            selected={
                              paymentCalendarData.targetPaymentDate &&
                              paymentCalendarData.targetPaymentDate
                                .toString()
                                .indexOf("/") > 0
                                ? new Date(
                                    paymentCalendarData.targetPaymentDate.split(
                                      "/"
                                    )[2] +
                                      "-" +
                                      paymentCalendarData.targetPaymentDate.split(
                                        "/"
                                      )[1] +
                                      "-" +
                                      paymentCalendarData.targetPaymentDate.split(
                                        "/"
                                      )[0]
                                  )
                                : paymentCalendarData.targetPaymentDate
                            }
                            onChange={(option) => {
                              this.handleTargetPaymentDateChange(option, idx);
                            }}
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                            error={paymentCalendarData.targetPaymentDateError}
                          />
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="number"
                            name="targetTotal"
                            label="Ödeme Tutarı [*]"
                            value={paymentCalendarData.targetTotal}
                            onChange={(e) => this.handleTextBoxChange(e, idx)}
                            placeHolder="Lütfen tutar girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={paymentCalendarData.targetTotalError}
                          />
                        </Col>

                        {/* <Col lg="3">
                        <CgnReactSelect
                          name="targetCurrencyType"
                          label="Para Birimi [*]"
                          placeholder="Lütfen para birimi seçiniz..."
                          selectValue={paymentCalendarData.targetCurrencyType}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTargetCurrencyTypeChange(option, idx)
                          }
                          options={CurrencyType}
                          error={paymentCalendarData.targetCurrencyTypeError}
                        />
                      </Col> */}

                        <Col lg="4">
                          <CgnReactSelect
                            name="targetPaymentMethodType"
                            label="Ödeme Yöntemi [*]"
                            placeholder="Lütfen ödeme yöntemi seçiniz..."
                            selectValue={
                              paymentCalendarData.targetPaymentMethodType
                            }
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleInputValueChange(option, idx);
                            }}
                            options={PaymentMethodType}
                            error={
                              paymentCalendarData.targetPaymentMethodTypeError
                            }
                          />
                        </Col>

                        <Col lg="1" sm="3">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton"
                            onClick={() =>
                              this.handlePaymentCalendarRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    )
                  )}

                {this.state.paymentCalendarModalPlus && (
                  <Row>
                    <Col lg="4">
                      <CgnButton
                        type="button"
                        color="primary"
                        className="mb-3 dynamicRowAddButton"
                        onClick={this.handlePaymentCalendarAdd}
                        text={"Ödeme Takvimi Ekle"}
                      />
                    </Col>
                  </Row>
                )}

                {this.state.paymentModalPlus &&
                  this.state.urlSplit.length === 2 && (
                    <>
                      <Row>
                        <Col
                          lg="12"
                          className="font-weight-bold"
                          style={{ padding: "0 15px 10px 15px" }}
                        >
                          <Label
                            check
                            className="labelCheck"
                            style={{ fontSize: "13px" }}
                          >
                            <Input
                              type="checkbox"
                              className="mt-1"
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              checked={!this.state.isHidden}
                            />{" "}
                            Ödeme Yapıldı mı ?
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <CgnTextbox
                            type="text"
                            name="receiptNo"
                            label="Makbuz No"
                            value={this.state.receiptNo}
                            placeHolder="Lütfen makbuz no girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.receiptNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                        <Col lg="6">
                          <CgnTextbox
                            type="number"
                            name="expiry"
                            label="Vade"
                            value={this.state.expiry}
                            placeHolder="Lütfen vade sayısı girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.expiryError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <CgnReactSelect
                            name="paymentMethodValue"
                            label="Ödeme Yöntemi [*]"
                            placeholder="Lütfen ödeme yöntemi seçiniz..."
                            selectValue={this.state.paymentMethodValue}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handlePaymentMethodSelectChange(option)
                            }
                            options={PaymentMethodType}
                            error={this.state.paymentMethodTypeError}
                            hidden={this.state.isHidden}
                          />
                        </Col>

                        <Col
                          lg="4"
                          className="modalSelectCol"
                          style={
                            this.state.isHidden
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <div className="flex-container">
                            <div className="p-0 flexMaxInput">
                              <CgnReactSelect
                                name="bankGuid"
                                label="Banka"
                                placeholder="Banka seçiniz..."
                                selectValue={this.state.bankGuid}
                                isMulti={false}
                                isClearable={true}
                                disabled={this.state.isBankTrue}
                                onChange={(option) =>
                                  this.handleBankSelectChange(option)
                                }
                                options={this.state.bankArr}
                                error={this.state.bankError}
                                hidden={this.state.isHidden}
                              />
                            </div>
                            {this.state.bankModalPlus && (
                              <div className="p-0 flexAddMinButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalBank()}
                                  text={"+"}
                                  visibled={this.state.isHidden}
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="text"
                            name="chequesBondNo"
                            label="Çek/Senet No"
                            value={this.state.chequesBondNo}
                            placeHolder="Lütfen çek/senet no girin..."
                            autoComplete="off"
                            readOnly={this.state.isChequesBondNoTrue}
                            onChange={this.handleChange}
                            error={this.state.chequesBondNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <ImageUpload
                            accept="image/png, image/jpeg"
                            className="text-center"
                            style={fotoStyle}
                            label="Makbuz Fotoğrafı"
                            image={this.state.image}
                            noFoto={noImage}
                            alt={this.state.name}
                            onChange={this.uploadedImage}
                            onDelete={this.deleteImage}
                            error={this.state.imageError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />

                <Row>
                  <Modal
                    isOpen={this.state.productEntryModal}
                    toggle={this.toggle}
                  >
                    <ModalHeader
                      toggle={this.toggle}
                      className="font-weight-bold"
                    >
                      Çoklu Ürün Ekle
                    </ModalHeader>
                    <ModalBody className="modalBody">
                      <Input
                        type="textarea"
                        className="modalTextArea"
                        onChange={(e) => this.handleChangeMultipleProduct(e)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <CgnButton
                        type="button"
                        color="primary"
                        onClick={() => this.handleClickMultipleProduct()}
                        text={CgnMessage.addButton}
                        className="modalButton"
                      />
                      <CgnButton
                        type="button"
                        color="secondary"
                        onClick={this.toggle}
                        text="Kapat"
                        className="modalButton"
                      />
                    </ModalFooter>
                  </Modal>
                </Row>
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalSupplier}
          toggle={this.addModalSupplier}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tedarikçi Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SupplierAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSupplier}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalBank}
          toggle={this.addModalBank}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Banka Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <BankAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBank}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductEntryAddUpdate;
