import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionOfferStatuses,
  TaxRateChoices,
  unitValuesStatuses,
  // PaymentMethodType,
  // DeliveryMethodType,
  // DeliveryTime,
  // Guaranty,
  CurrencyType,
  
  UserOperationClaimControl,
  offerLanguages,
  fotoStyle
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import OfferCoverAddUpdate from "../offerCover/OfferCoverAddUpdate";
import OfferDefinitionAddUpdate from "../offerDefinition/OfferDefinitionAddUpdate";
import CgnTextEditor from "../toolbox/CgnTextEditor";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import LocationAddUpdate from "../location/LocationAddUpdate";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";

class OfferNewAddUpdate extends Component {
  state = {
    pageTitle: "Teklif Ekle",
    pageTitleAdd: "Teklif Ekle",
    pageTitleUpdate: "Teklif Güncelle",
    pageAddLink: "teklif2-ekle",
    pageListLink: "teklif2-listele",
    breadcrumb: [{ text: "Teklif", link: "#" }],
    isLoading: false,
    urlSplit: [],
    offerDocumentDto: [],
    customerGuid: "",
    offerDate: "",
    optionDate: "",
    reminderDate: "",
    guid: "",
    total: 0,
    generalTotal: 0, //ara toplam diyelim
    generalDiscountTotal: 0, //toplam indirim tutarları
    grossTotal: 0, //brüt toplam (ara toplam - toplam indirim)
    generalTaxTotal: 0, //genel kdv tutatları toplamı
    taxRateValue: "",
    taxTypeValue: null,
    paymentMethodType: "",
    deliveryMethodType: "",
    deliveryTime: "",
    guaranty: "",
    title: "",
    description: "",
    descriptionHtml: "",
    // discountPercent: "",
    // discountValue: "",
    // discountTotal: "",
    currencyType: "",
    currencyTypeError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    productDiscountPercent: "",
    generalTotal1: 0,
    generalTaxTotal1: 0,
    generalDiscountTotal1: 0,
    discountTotal1: 0,
    productDiscountValue: "",
    status: 1,
    customerError: "",
    offerDateError: "",
    optionDateError: "",
    reminderDateError: "",
    totalError: "",
    taxRateError: "",
    taxTypeError: "",
    paymentMethodTypeError: "",
    deliveryMethodTypeError: "",
    deliveryTimeError: "",
    guarantyError: "",
    productDataError: "",
    offerCoverError: "",
    productGuid: "",
    coverGuid: "00000000-0000-0000-0000-000000000000",
    offerCoverButtonStyle: "",
    alertErrorMessage: "",
    unitError: "",
    productTaxRate: "",
    productDiscountPercentError: "",
    productDiscountValueError: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    isOpenModalCustomer: false,
    isOpenModalProduct: false,
    isOpenModalOfferCover: false,
    isOpenModalDefinition: false,
    isOpenModalLocation: false,
    modalButtonIdx: 0,
    counter: -1,
    idx: 0,
    productData: [],
    offerCoverData: [],
    descriptionData: [],
    offerMultipleModal: false,
    multipleProductArr: [],
    language: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    customerModalPlus: false,
    productModalPlus: false,
    definitionModalPlus: false,
    locationModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
      productModalPlus: await UserOperationClaimControl(1029),
      definitionModalPlus: await UserOperationClaimControl(1058),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/offerdefinitions/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          definitionData: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            salePrice: item.salePrice,
            code: item.code
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/offerCovers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            src: item.image,
          };
        });
        this.setState({
          offerCoverData: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/descriptions/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.title,
          };
        });
        this.setState({
          descriptionData: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3 && urlSplit[1] === "teklif2-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/offernews/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              customerGuid: response.data.customerGuid,
              offerDate: response.data.offerDate,
              optionDate: response.data.optionDate,
              reminderDate: response.data.reminderDate,
              productData: response.data.offerProductNewDto,
              discountTotal: response.data.total,
              generalTotal: response.data.generalTotal,
              guarantyGuid: response.data.guarantyGuid,
              paymentMethodTypeGuid: response.data.paymentMethodTypeGuid,
              deliveryMethodTypeGuid: response.data.deliveryMethodTypeGuid,
              deliveryTimeGuid: response.data.deliveryTimeGuid,
              generalDiscountTotal: response.data.generalDiscountTotal,
              generalTaxTotal: response.data.generalTaxTotal,
              language: response.data.language,
              offerDocumentDto: response.data.offerDocumentDto,
              descriptionHtml: response.data.description,
              description: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(response.data.description)
                )
              ),
              coverGuid: response.data.coverGuid,
              currencyType: response.data.currencyType,
              status: response.data.status,
              isLoading: false,
              counter: response.data.offerProductNewDto.length - 1
            });
            let productData = this.state.productData;
            productData.forEach((item) => {
              if(item.productDiscountPercent === 0){
                item.productDiscountPercent = "";
              }         
              if(item.productDiscountValue === 0){
                item.productDiscountValue = "";
              }
            })
            this.setState({
              productData : productData
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        customerGuid: "",
        offerDate: "",
        optionDate: "",
        reminderDate: "",
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        paymentMethodType: null,
        deliveryMethodType: null,
        deliveryTime: null,
        guaranty: null,
        productData: [],
        descriptionData: [],
        description: "",
        descriptionHtml: "",
        coverGuid: "",
        currencyType: "",
        isDiscountPercent: false,
        isDiscountValue: false,
        status: 1,
        counter: -1,
      });
    }

    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
    if (
      this.state.isOpenModalOfferCover &&
      this.props.location.modalAddedOfferCover !== undefined &&
      this.props.location.modalAddedOfferCover.isModal !== undefined &&
      this.props.location.modalAddedOfferCover.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/offerCovers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              src: item.image,
            };
          });
          this.setState({
            offerCoverData: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedOfferCover.name &&
              element.name === this.props.location.modalAddedOfferCover.name
            ) {
              this.setState({
                coverGuid: element.guid,
                offerCoverError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedOfferCover.isModal = true;
      this.setState({
        isOpenModalOfferCover: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
            return {
              value: item.guid,
              label: label,
              salePrice: item.salePrice,
              code: item.code
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
            }
          });
          if (this.props.location.modalAddedProduct.salePrice) {
            productData[this.state.modalButtonIdx].unitPrice =
              parseFloat(this.props.location.modalAddedProduct.salePrice);
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].unit = "";
            productData[this.state.modalButtonIdx].productTaxRate = "";
            productData[this.state.modalButtonIdx].productDiscountPercent = "";
            productData[this.state.modalButtonIdx].productDiscountValue = "";
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
            //productData[this.state.modalButtonIdx].unitError = "";
          }
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalDefinition &&
      this.props.location.modalAddedDefinition !== undefined &&
      this.props.location.modalAddedDefinition.isModal !== undefined &&
      this.props.location.modalAddedDefinition.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/offerdefinitions/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              src: item.image,
            };
          });
          this.setState({
            definitionData: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedDefinition.data &&
              element.name === this.props.location.modalAddedDefinition.data
            ) {
              if (this.state.isOpenModalDefinitionGuaranty) {
                this.setState({
                  isOpenModalDefinitionGuaranty:
                    !this.state.isOpenModalDefinitionGuaranty,
                  guarantyGuid: element.guid,
                  guarantyError: "",
                  alertErrorMessage: "",
                });
              } else if (this.state.isOpenModalDefinitionPaymentMethodType) {
                this.setState({
                  isOpenModalDefinitionPaymentMethodType:
                    !this.state.isOpenModalDefinitionPaymentMethodType,
                  paymentMethodTypeGuid: element.guid,
                  paymentMethodTypeError: "",
                  alertErrorMessage: "",
                });
              } else if (this.state.isOpenModalDefinitionDeliveryMethodType) {
                this.setState({
                  isOpenModalDefinitionDeliveryMethodType:
                    !this.state.isOpenModalDefinitionDeliveryMethodType,
                  deliveryMethodTypeGuid: element.guid,
                  deliveryMethodTypeError: "",
                  alertErrorMessage: "",
                });
              } else if (this.state.isOpenModalDefinitionDeliveryTime) {
                this.setState({
                  isOpenModalDefinitionDeliveryTime:
                    !this.state.isOpenModalDefinitionDeliveryTime,
                  deliveryTimeGuid: element.guid,
                  deliveryTimeError: "",
                  alertErrorMessage: "",
                });
              }
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedDefinition.isModal = true;
      this.setState({
        isOpenModalDefinition: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].locationGuid =
                element.guid;
              productData[this.state.modalButtonIdx].locationError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleEditorChange = (editorState, editorStateHtml) => {
    if (editorStateHtml) {
      this.setState({
        description: editorState,
        descriptionHtml: editorStateHtml,
        descriptionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      customerError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "customerGuid" && value === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage3 = async (event) => {
    let array = this.state.offerDocumentDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
        const formData = new FormData();
        formData.append("files", file);
        await axios
          .post(URL + "/uploads2/add", formData)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
    }
   await this.setState({offerDocumentDto: array});

  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.offerDocumentDto;
     this.state.offerDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({offerDocumentDto: array});
          }
        }
     });
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }


  async handleProductTaxRateSelectChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productTaxRate = option.value;
      productData[idx].productTaxRateError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productTaxRate = "";
      productData[idx].productTaxRateError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleOfferDateChange(offerDate) {
    if (offerDate) {
      this.setState({
        offerDate: offerDate,
        offerDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerDate: "",
        offerDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      offerMultipleModal: !this.state.offerMultipleModal,
    });
    var x = this.state.multipleProductArr.split("\n");
     await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleMultipleProductAdd(item.value);
        }
      });
    });
  };


  handleMultipleProductAdd = async (value) => {
    let productData = this.state.productData;
    await productData.push({
      productGuid: value,
      locationGuid: "",
      piece: 1,
      unitPrice: 1,
      total: 1,
      discountTotal: 1,
      unit: "NIU",
      taxTotal: 0, 
      productDiscountTotal: 0,
      productDiscountPercent: 0,
      productDiscountValue: 0,
      productTaxRate: null,
      locationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });   

    this.setState({ productData: productData });
  };

  handleOptionDateChange(optionDate) {
    if (optionDate) {
      this.setState({
        optionDate: optionDate,
        optionDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        optionDate: "",
        optionDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleReminderDateChange(reminderDate) {
    if (reminderDate) {
      this.setState({
        reminderDate: reminderDate,
        reminderDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        reminderDate: "",
        reminderDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePaymentMethodTypeSelectChange(option) {
    if (option) {
      this.setState({
        paymentMethodTypeGuid: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        paymentMethodTypeGuid: "",
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDeliveryMethodTypeSelectChange(option) {
    if (option) {
      this.setState({
        deliveryMethodTypeGuid: option.value,
        deliveryMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        deliveryMethodTypeGuid: "",
        deliveryMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDeliveryTimeSelectChange(option) {
    if (option) {
      this.setState({
        deliveryTimeGuid: option.value,
        deliveryTimeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        deliveryTimeGuid: "",
        deliveryTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleGuarantySelectChange(option) {
    if (option) {
      this.setState({
        guarantyGuid: option.value,
        guarantyError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        guarantyGuid: "",
        guarantyError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }


  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleLanguageSelectChange(option) {
    if (option) {
      this.setState({
        language: option.value,
      });
    } else {
      this.setState({
        language: "",
      });
    }
  }

  handlePaymentMethodSelectChange(option) {
    if (option) {
      this.setState({
        paymentMethodType: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        paymentMethodType: null,
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDeliveryMethodSelectChange(option) {
    if (option) {
      this.setState({
        deliveryMethodType: option.value,
        deliveryMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        deliveryMethodType: null,
        deliveryMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  
  handleOfferCoverSelectChange(option) {
    if (option) {
      this.setState({
        coverGuid: option,
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    if (status) {
      this.setState({
        status: status.value,
      });
    }
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  addModalOfferCover = async () => {
    await this.setState({
      isOpenModalOfferCover: !this.state.isOpenModalOfferCover,
    });
  };

  addModalDefinitionGuaranty = () => {
    this.setState({
      isOpenModalDefinition: !this.state.isOpenModalDefinition,
      isOpenModalDefinitionGuaranty: !this.state.isOpenModalDefinitionGuaranty,
    });
  };

  addModalDefinitionPaymentMethodType = () => {
    this.setState({
      isOpenModalDefinition: !this.state.isOpenModalDefinition,
      isOpenModalDefinitionPaymentMethodType:
        !this.state.isOpenModalDefinitionPaymentMethodType,
    });
  };

  addModalDefinitionDeliveryMethodType = () => {
    this.setState({
      isOpenModalDefinition: !this.state.isOpenModalDefinition,
      isOpenModalDefinitionDeliveryMethodType:
        !this.state.isOpenModalDefinitionDeliveryMethodType,
    });
  };

  addModalDefinitionDeliveryTime = () => {
    this.setState({
      isOpenModalDefinition: !this.state.isOpenModalDefinition,
      isOpenModalDefinitionDeliveryTime:
        !this.state.isOpenModalDefinitionDeliveryTime,
    });
  };

  addModalDefinition = async () => {
    await this.setState({
      isOpenModalDefinition: !this.state.isOpenModalDefinition,
      isOpenModalDefinitionDeliveryTime:
        !this.state.isOpenModalDefinitionDeliveryTime,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;

      item.productError = CgnMessage.textErrorMessage;
      item.productPieceError = CgnMessage.textErrorMessage;
      item.productUnitPriceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      customerGuid: "",
      total: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      offerDate: "",
      optionDate: "",
      reminderDate: "",
      paymentMethodType: null,
      deliveryMethodType: null,
      deliveryTime: null,
      guaranty: null,
      description: "",
      descriptionHtml: "",
      coverGuid: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      status: 1,
      counter: -1,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      offerDateError: CgnMessage.textErrorMessage,
      optionDateError: CgnMessage.textErrorMessage,
      reminderDateError: CgnMessage.textErrorMessage,
      paymentMethodTypeError: CgnMessage.textErrorMessage,
      deliveryMethodTypeError: CgnMessage.textErrorMessage,
      deliveryTimeError: CgnMessage.textErrorMessage,
      guarantyError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
      offerCoverError: CgnMessage.textErrorMessage,
    });
  }

  toggle = () => {
    this.setState({
      offerMultipleModal: !this.state.offerMultipleModal,
    });
  };

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: "",
      locationGuid: "",
      piece: 1,
      unitPrice: 0,
      taxTotal: 0, 
      productDiscountTotal: 0,
      unit: "",
      productDiscountPercent: "",
      productDiscountValue: "",
      total: 0,
      discountTotal: 0,
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
      productTaxRateError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (array[this.state.counter].unit) {
      return array[this.state.counter].unitError === "";
    }
    if (array[this.state.counter].productTaxRate) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountPercent) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountValue) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (
      array[this.state.counter].productGuid === "" ||
      array[this.state.counter].locationGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].unit === "" ||
      array[this.state.counter].productTaxRate === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

   handleProductDiscountPercentChange(e, idx) {
    let productData = this.state.productData;
    if(e.target.value !== ""){
      productData[idx].productDiscountPercent = parseFloat(e.target.value);

    }
    else{
      productData[idx].productDiscountPercent = "";
    }
     this.setState({
      productData: productData,
    });

    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

   handleProductDiscountValueChange(e, idx) {
    let productData = this.state.productData;
     if(e.target.value !== ""){
      productData[idx].productDiscountValue = parseFloat(e.target.value);

     }
     else{
      productData[idx].productDiscountValue = "";
     }
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleUnitChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].unit = option.value;
      productData[idx].unitError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].unit = "";
      productData[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].unitPrice = option.salePrice;
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].productUnitPriceError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productGuid = null;
      productData[idx].piece = 0;
      productData[idx].unitPrice = 0;
      productData[idx].productError = CgnMessage.textErrorMessage;
      productData[idx].productPieceError = CgnMessage.textErrorMessage;
      productData[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = "";
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  async ProductPriceCalculate(idx) {
    var totalProductPrice = 0.0;
    var totalProductTotal = 0.0;
    var itemTotal = 0.0;
    var percent = 0.0;
    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;
    var taxTotal = 0;
    
    let productData = this.state.productData;
    
    var discountPercent = parseFloat(productData[idx].productDiscountPercent);
    
    var discountValue = parseFloat(productData[idx].productDiscountValue);
    
    itemTotal = (parseFloat(productData[idx].piece) * parseFloat(productData[idx].unitPrice)).toFixed(2); 
    
    totalProductPrice += parseFloat(itemTotal);

    if(discountPercent > 0){
      percent = parseFloat(totalProductPrice) * (discountPercent / 100);
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - parseFloat(percent);
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);
      taxTotal =  parseFloat(totalProductTotal * parseFloat(newProductTaxRate / 100));
      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total = parseFloat(totalProductTotal) + parseFloat(taxTotal);
    }

    else if(discountValue > 0){
      percent = discountValue;
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - discountValue;
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);
    
      taxTotal =  parseFloat(totalProductTotal * parseFloat(newProductTaxRate / 100));
    
      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total = parseFloat(totalProductTotal) + parseFloat(taxTotal);  
    }

    else{
    productData[idx].productDiscountTotal = 0;
    var newProductTaxRate = parseInt(productData[idx].productTaxRate); 
    taxTotal =  parseFloat(totalProductPrice * parseFloat(newProductTaxRate / 100));
    
    productData[idx].taxTotal = parseFloat(taxTotal);
    
    totalProductTotal = parseFloat(taxTotal) + parseFloat(totalProductPrice);
    productData[idx].total = parseFloat(totalProductTotal).toFixed(2);
    }

     this.setState({
      productData: productData,
    });
     await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);
    });
     
     await this.setState({ 
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      discountTotal: parseFloat(discountTotal1).toFixed(2),
    })

  };

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductRemove(idx) {
  
    let someArray = await this.state.productData;
   
    await someArray.splice(idx, 1);
     
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });

    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;

    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);

    });
     
    await this.setState({ 
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      discountTotal: parseFloat(discountTotal1).toFixed(2),
    })

    if (idx === 0) {
       await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      customerError: "",
      totalError: "",
      taxRateError: "",
      taxTypeError: "",
      offerDateError: "",
      optionDateError: "",
      reminderDateError: "",
      paymentMethodTypeError: "",
      deliveryMethodTypeError: "",
      deliveryTimeError: "",
      guarantyError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
      productDataError: "",
      offerCoverError: "",
    });
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.offerDate === "") {
      this.setState({
        offerDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.optionDate === "") {
      this.setState({
        optionDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.reminderDate === "") {
      this.setState({
        reminderDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if(this.state.productData !== null || this.state.productData.length > 0){
      this.state.productData.forEach((element) => {
        if (element.unit === "" || element.productTaxRate === null) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (this.state.discountTotal === "" || this.state.discountTotal === 0) {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
 else if (
      this.state.customerGuid !== "" &&
      //this.state.total &&
      this.state.offerDate !== "" &&
      this.state.optionDate !== "" &&
      this.state.reminderDate !== "" &&
      this.state.currencyType &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      this.state.isNull === false &&
      this.state.discountTotal > 0 &&
      control === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          productGuid: element.productGuid,
          locationGuid: element.locationGuid,
          piece: element.piece,
          unitPrice: element.unitPrice,
          unit: element.unit,
          productDiscountPercent: element.productDiscountPercent !== ""
          ? parseFloat(element.productDiscountPercent)
          : 0,
          productDiscountValue:element.productDiscountValue !== ""
          ? parseFloat(element.productDiscountValue)
          : 0,
          productTaxRate: element.productTaxRate,
          total: parseFloat(element.total),
        });
      });

      var myObj = {
        customerGuid: this.state.customerGuid,
        offerDate: moment(this.state.offerDate).format("DD/MM/YYYY"),
        optionDate: moment(this.state.optionDate).format("DD/MM/YYYY"),
        reminderDate: moment(this.state.reminderDate).format("DD/MM/YYYY"),
        generalTotal: parseFloat(this.state.generalTotal),
        guarantyGuid: this.state.guarantyGuid,
        paymentMethodTypeGuid: this.state.paymentMethodTypeGuid,
        deliveryMethodTypeGuid: this.state.deliveryMethodTypeGuid,
        deliveryTimeGuid: this.state.deliveryTimeGuid,
        description: this.state.descriptionHtml,
        coverGuid: this.state.coverGuid,
        currencyType: this.state.currencyType,
        total: parseFloat(this.state.discountTotal),
        generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
        generalTaxTotal: parseFloat(this.state.generalTaxTotal),
        status: this.state.status,
        offerProductNewDto: productData,
        language: (this.state.language === "" || this.state.language === null) ? null : this.state.language,
        offerDocumentDto: this.state.offerDocumentDto,
      };
      var URLParam = "/offernews/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/offernews/update";
        myObj = {
          guid: this.state.guid,
          customerGuid: this.state.customerGuid,
          offerDate:
            (this.state.offerDate &&
            this.state.offerDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.offerDate.split("/")[2] +
                    "-" +
                    this.state.offerDate.split("/")[1] +
                    "-" +
                    this.state.offerDate.split("/")[0]
                )
              : moment(this.state.offerDate).format("DD/MM/YYYY")),
          optionDate:
            this.state.optionDate &&
            this.state.optionDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.optionDate.split("/")[2] +
                    "-" +
                    this.state.optionDate.split("/")[1] +
                    "-" +
                    this.state.optionDate.split("/")[0]
                )
              : moment(this.state.optionDate).format("DD/MM/YYYY"),
          reminderDate:
            this.state.reminderDate &&
            this.state.reminderDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.reminderDate.split("/")[2] +
                    "-" +
                    this.state.reminderDate.split("/")[1] +
                    "-" +
                    this.state.reminderDate.split("/")[0]
                )
              : moment(this.state.reminderDate).format("DD/MM/YYYY"),
          generalTotal: parseFloat(this.state.generalTotal),
          guarantyGuid: this.state.guarantyGuid,
          paymentMethodTypeGuid: this.state.paymentMethodTypeGuid,
          deliveryMethodTypeGuid: this.state.deliveryMethodTypeGuid,
          deliveryTimeGuid: this.state.deliveryTimeGuid,
          description: this.state.descriptionHtml,
          coverGuid: this.state.coverGuid,
          currencyType: this.state.currencyType,
          total: parseFloat(this.state.discountTotal),
          generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
          generalTaxTotal: parseFloat(this.state.generalTaxTotal),
          status: this.state.status,
          offerProductNewDto: productData,
          language: (this.state.language === "" || this.state.language === null) ? null : this.state.language,
          offerDocumentDto: this.state.offerDocumentDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/teklif2-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };


  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                <Col lg="2">
                    <CgnReactSelect
                      name="language"
                      label="Teklif Dili"
                      placeholder="Lütfen teklif dili seçiniz..."
                      selectValue={this.state.language}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleLanguageSelectChange(option)
                      }
                      options={offerLanguages}
                    />
                  </Col>
                  <Col lg="10" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="offerDate"
                      label="Teklif Tarihi [*]"
                      selected={
                        this.state.offerDate &&
                        this.state.offerDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.offerDate.split("/")[2] +
                                "-" +
                                this.state.offerDate.split("/")[1] +
                                "-" +
                                this.state.offerDate.split("/")[0]
                            )
                          : this.state.offerDate
                      }
                      onChange={(offerDate) =>
                        this.handleOfferDateChange(offerDate)
                      }
                      error={this.state.offerDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="optionDate"
                      label="Opsiyon Tarihi [*]"
                      selected={
                        this.state.optionDate &&
                        this.state.optionDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.optionDate.split("/")[2] +
                                "-" +
                                this.state.optionDate.split("/")[1] +
                                "-" +
                                this.state.optionDate.split("/")[0]
                            )
                          : this.state.optionDate
                      }
                      onChange={(optionDate) =>
                        this.handleOptionDateChange(optionDate)
                      }
                      error={this.state.optionDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="reminderDate"
                      label="Hatırlatma Tarihi [*]"
                      selected={
                        this.state.reminderDate &&
                        this.state.reminderDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.reminderDate.split("/")[2] +
                                "-" +
                                this.state.reminderDate.split("/")[1] +
                                "-" +
                                this.state.reminderDate.split("/")[0]
                            )
                          : this.state.reminderDate
                      }
                      onChange={(reminderDate) =>
                        this.handleReminderDateChange(reminderDate)
                      }
                      error={this.state.reminderDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                </Row>

                {this.state.productData.map((productData, idx) => (
                  <>
                  <Row className="mobileBorder">
                    <Col lg="4" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            placeholder="Lütfen depo seçiniz..."
                            selectValue={productData.locationGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleLocationInputValueChange(option, idx);
                            }}
                            options={this.state.locationArr}
                            error={productData.locationError}
                          />
                        </div>
                        {this.state.locationModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalLocation(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="8" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="productGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={productData.productGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={productData.productError}
                          />
                        </div>
                        {this.state.productModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalProduct(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    </Row>
                    <Row className="pl-0">
                    <Col className="pl-0">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Miktar [*]"
                        value={productData.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen miktar girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productPieceError}
                        step="0.1"
                        min={0}
                      />
                    </Col>

                    <Col>
                    <CgnReactSelect
                      name="unit"
                      label="Birim [*]"
                      placeholder="Birim"
                      selectValue={productData.unit}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleUnitChange(option, idx)
                      }
                      options={unitValuesStatuses}
                      error={productData.unitError}
                    />
                    </Col>

                    <Col>
                      <CgnTextbox
                        type="number"
                        name="unitPrice"
                        label="Birim Fiyat [*]"
                        value={productData.unitPrice}
                        onChange={(e) =>
                          this.handleProductUnitPriceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen birim fiyat girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productUnitPriceError}
                      />
                    </Col>
                    <Col>
                    <CgnTextbox
                      type="number"
                      name="productDiscountPercent"
                      label="İskonto(Yüzde)"
                      value={productData.productDiscountPercent}
                      placeHolder="İskonto"
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isProductDiscountPercent}
                      onChange={(e) =>
                        this.handleProductDiscountPercentChange(e, idx)
                      }
                      error={productData.productDiscountPercentError}
                    />
                  </Col>
                  <Col>
                    <CgnTextbox
                      type="number"
                      name="productDiscountValue"
                      label="İskonto(Net Tutar)"
                      value={productData.productDiscountValue}
                      placeHolder="İskonto net tutar"
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isProductDiscountValue}
                      onChange={(e) =>
                        this.handleProductDiscountValueChange(e, idx)
                      }                      
                      error={productData.productDiscountValueError}
                    />
                  </Col>
                    <Col>
                    <CgnReactSelect
                      name="productTaxRate"
                      label="K.D.V Oranı [*]"
                      placeholder="K.D.V oranı"
                      selectValue={productData.productTaxRate}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTrue}
                      onChange={(option) =>
                        this.handleProductTaxRateSelectChange(option, idx)
                      }
                      options={TaxRateChoices}
                      error={productData.productTaxRateError}
                    />
                    </Col>

                    <Col>
                      <CgnTextbox
                        type="number"
                        name="total"
                        label="Fiyat [*]"
                        value={productData.total}
                        readOnly={true}
                        //onChange={this.ProductPriceCalculate()}
                        maxLength="50"
                        autoComplete="off"
                      />
                    </Col>

                    <Col>
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                 </>
                  
                ))}

                <Row>
                  <Col lg="2">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton"
                      onClick={this.handleProductAdd}
                      text={"Ürün Ekle"}
                    />
                 <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggle}
                    text={"Çoklu Ürün Ekle"}
                  />
                  </Col>
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  
                  <Col lg="3">
                  <CgnTextbox
                      type="number"
                      name="generalDiscountTotal"
                      label="İndirim Toplamı"
                      value={this.state.generalDiscountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                  <CgnTextbox
                      type="number"
                      name="generalTaxTotal"
                      label="KDV Toplamı"
                      value={this.state.generalTaxTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="paymentMethodType"
                      label="Ödeme Yöntemi"
                      placeholder="Lütfen ödeme yöntemi girin..."
                      value={this.state.paymentMethodType}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Ödeme Yöntemi"
                          placeholder="Ödeme yöntemi seçiniz..."
                          selectValue={this.state.paymentMethodTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePaymentMethodTypeSelectChange(option)
                          }
                          options={this.state.definitionData}
                          error={this.state.paymentMethodTypeError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() =>
                              this.addModalDefinitionPaymentMethodType()
                            }
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="deliveryMethodType"
                      label="Teslimat Yöntemi"
                      placeholder="Lütfen teslimat yöntemi girin..."
                      value={this.state.deliveryMethodType}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Teslimat Yöntemi"
                          placeholder="Teslimat yöntemi seçiniz..."
                          selectValue={this.state.deliveryMethodTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleDeliveryMethodTypeSelectChange(option)
                          }
                          options={this.state.definitionData}
                          error={this.state.deliveryMethodTypeError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() =>
                              this.addModalDefinitionDeliveryMethodType()
                            }
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="deliveryTime"
                      label="Teslimat Süresi"
                      placeholder="Lütfen teslimat süresi girin..."
                      value={this.state.deliveryTime}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Teslimat Süresi"
                          placeholder="Teslimat süresi seçiniz..."
                          selectValue={this.state.deliveryTimeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleDeliveryTimeSelectChange(option)
                          }
                          options={this.state.definitionData}
                          error={this.state.deliveryTimeError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() =>
                              this.addModalDefinitionDeliveryTime()
                            }
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="guaranty"
                      label="Garanti Süresi"
                      placeholder="Lütfen garanti süresi girin..."
                      value={this.state.guaranty}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Garanti Süresi"
                          placeholder="Garanti süresi seçiniz..."
                          selectValue={this.state.guarantyGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleGuarantySelectChange(option)
                          }
                          options={this.state.definitionData}
                          error={this.state.guarantyError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalDefinitionGuaranty()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="title"
                      label="Açıklama Başlığı"
                      placeholder="Açıklama Başlığı seçiniz..."
                      selectValue={this.state.title}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTitleSelectChange(option)
                      }
                      options={this.state.descriptionData}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col lg="12">
                    <CgnTextEditor
                      name="description"
                      label="Teklif Notu"                
                      editorState={this.state.description}
                      placeHolder="Lütfen teklif notu girin..."
                      onChange={(editorState) =>
                        this.handleEditorChange(
                          editorState,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                      }
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Label className="font-weight-bolder">
                      Teklif Kapağı
                    </Label>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={12}>
                    {this.state.offerCoverData.map((item) => (
                      <Button
                        type="button"
                        className="offerCoverButton"
                        onClick={() =>
                          this.handleOfferCoverSelectChange(item.value)
                        }
                      >
                        {this.state.coverGuid === item.value ? (
                          <img
                            src={item.src}
                            alt={item.label}
                            className="offerCoverImage offerCoverImageSelected"
                          />
                        ) : (
                          <img
                            src={item.src}
                            alt={item.label}
                            className="offerCoverImage"
                          />
                        )}
                      </Button>
                    ))}
                  </Col>
                  {this.state.offerCoverError && (
                    <div className="invalid-feedback d-block">
                      {this.state.offerCoverError}
                    </div>
                  )}
                </Row>

                <Row>
                  <Col lg="2" className="mb-2">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton"
                      onClick={this.addModalOfferCover}
                      text={"Teklif Kapağı Ekle"}
                    />
                  </Col>
                </Row>

                <Row>
                <Col lg="12" className="pl-0">
                  <ImageUploadMulti
                      images={this.state.offerDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx"
                      className="text-center text-danger"
                      label={"Belgeler"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      error={this.state.offerDocumentDtoError}
                    />
                    </Col>   
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      selectValue={this.state.status}
                      isMulti={false}
                      options={optionOfferStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />

                <Row>
                  <Modal
                    isOpen={this.state.offerMultipleModal}
                    toggle={this.toggle}
                  >
                    <ModalHeader
                      toggle={this.toggle}
                      className="font-weight-bold"
                    >
                      Çoklu Ürün Ekle
                    </ModalHeader>
                    <ModalBody className="modalBody">
                      <Input
                        type="textarea"
                        className="modalTextArea"
                        onChange={(e) => this.handleChangeMultipleProduct(e)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <CgnButton
                        type="button"
                        color="primary"
                        onClick={() => this.handleClickMultipleProduct()}
                        text={CgnMessage.addButton}
                        className="modalButton"
                      />
                      <CgnButton
                        type="button"
                        color="secondary"
                        onClick={this.toggle}
                        text="Kapat"
                        className="modalButton"
                      />
                    </ModalFooter>
                  </Modal>
                </Row>
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalOfferCover}
          toggle={this.addModalOfferCover}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Teklif Kapağı Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <OfferCoverAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalOfferCover}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalDefinition}
          toggle={this.addModalDefinition}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tanımlama Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <OfferDefinitionAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalDefinition}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default OfferNewAddUpdate;
