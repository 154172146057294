import React, { Component } from "react";
import { Row, Col, Card, Table, Form } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import "moment/locale/tr";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class TaskDetail extends Component {
  state = {
    pageTitle: "Görev Atama Detay",
    pageTitleDetail: "Görev Atama Detay",
    pageAddLink: "gorev-atama-ekle",
    pageListLink: "gorev-atama-listele",
    breadcrumb: [{ text: "Görev Atama", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    guid: "",
    taskSituationGuid: "",
    personName: "",
    nameError: "",
    taskCategoryName: "",
    taskSituationName: "",
    note: "",
    startDate: "",
    endDate: "",
    status: "",
    taskName: "",
    taskDescription: "",
    taskNotes: [],
    taskSituationArr: [],
    priorityColor: "",
    situationColor: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/tasks/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            personName: response.data.personName,
            taskCategoryName: response.data.taskCategoryName,
            taskPriorityName: response.data.taskPriorityName,
            taskSituationName: response.data.taskSituationName,
            taskSituationGuid: response.data.taskSituationGuid,
            situationColor: response.data.situationColor,
            priorityColor: response.data.priorityColor,
            taskName: response.data.taskName,
            taskDescription: response.data.taskDescription,
            startDate: response.data.startDate,
            endDate: response.data.endDate,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            taskNotes:
              response.data.taskNotes !== null ? response.data.taskNotes : null,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      var statusType = 1;
      await axios
        .get(URL + "/taskSituation/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            taskSituationArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "note" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
      });
    }
  };

  handleTaskSituationSelectChange(option) {
    if (option) {
      this.setState({
        taskSituationGuid: option.value,
        taskSituationNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        taskSituationGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        taskSituationNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameEror: "",
    });
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var myObj = {
      guid: this.state.guid,
      note: this.state.note,
      taskSituationGuid: this.state.taskSituationGuid,
    };
    var URLParam = "/tasks/taskaddnote";
    axios
      .post(URL + URLParam, myObj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Görev Atama Yapan</th>
                    <td>{this.state.createdBy}</td>

                    <th>Görevli Personel</th>
                    <td>{this.state.personName}</td>
                  </tr>

                  <tr>
                    <th>Başlangıç Tarihi</th>
                    <td>{this.state.startDate}</td>

                    <th>Bitiş Tarihi</th>
                    <td>{this.state.endDate}</td>
                  </tr>

                  <tr>
                    <th>Öncelik</th>
                    <td>
                      <div
                        style={{
                          height: "25px",
                          width: "100px",
                          padding: "3px",
                          backgroundColor:
                            this.state.priorityColor !== null
                              ? this.state.priorityColor
                              : "#1D4B8C",
                        }}
                      >     
                      <p style={{textAlign: "center", color:"white"}}>{this.state.taskPriorityName}</p>              
                      </div>
                    </td>

                    <th>Kategori</th>
                    <td>{this.state.taskCategoryName}</td>
                  </tr>

                  <tr>
                    <th>Görev Adı</th>
                    <td colSpan="4">{this.state.taskName}</td>
                  </tr>

                  <tr>
                    <th>Görev Açıklaması</th>
                    <td
                      colSpan="4"
                      dangerouslySetInnerHTML={{
                        __html: this.state.taskDescription
                          ? this.state.taskDescription
                          : "-",
                      }}
                    ></td>
                  </tr>

                  <tr>
                    <th>Görev Durumu</th>
                    <td colSpan="4">
                      <div
                        style={{
                          height: "25px",
                          width: "100px",
                          padding: "3px",
                          backgroundColor:
                            this.state.situationColor !== null
                              ? this.state.situationColor
                              : "#1D4B8C",
                        }}
                      >     
                      <p style={{textAlign: "center", color:"white"}}>{this.state.taskSituationName}</p>              
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>
                </tbody>
              </Table>
              {this.state.taskNotes !== null &&
                this.state.taskNotes.length > 0 && (
                  <Table className="table table-responsive table-hover table-spare w-100">
                    <tr style={mainRow}>
                      <th className="text-left pl-3">Not</th>
                      <th className="text-right pr-3">Ekleyen Kullanıcı</th>
                    </tr>
                    {this.state.taskNotes.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td className="text-left pl-3">{item.note}</td>
                          <td className="text-right pr-3">{item.createdBy}</td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
              <Form onSubmit={this.handleSave}>
                <Row>
                  <Col lg="12" className="ml-1 mt-2">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="note"
                      label="Not :"
                      value={this.state.note}
                      placeHolder="Lütfen not girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                  <Col lg="12 p-0">
                    <CgnReactSelect
                      name="taskSituationGuid"
                      label="Durumu"
                      placeholder="Durum seçiniz..."
                      selectValue={this.state.taskSituationGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTaskSituationSelectChange(option)
                      }
                      options={this.state.taskSituationArr}
                      error={this.state.taskSituationNameError}
                    />
                  </Col>
                  <CgnButtonGroup items={this.state.buttons} />
                </Row>
              </Form>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default TaskDetail;
