import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
class CustomerEducationAnnouncementEMailAddUpdate extends Component {
    state = {
        pageTitle: "Potansiyel Müşteriler Ekle",
        pageTitleAdd: "Potansiyel Müşteriler Ekle",
        pageTitleUpdate: "Potansiyel Müşteriler Güncelle",
        pageAddLink: "musteri-egitim-duyuru-e-mail-ekle",
        pageListLink: "musteri-egitim-duyuru-e-mail-listele",
        breadcrumb: [{ text: "Potansiyel Müşteriler", link: "#" }],
        isLoading: false,
        urlSplit: [],
        name: "",
        guid: "",
        status: true,
        nameError: "",
        alertErrorMessage: "",
        buttons: [
            {
                type: "button",
                color: "secondary",
                text: CgnMessage.clearButton,
                onClick: (event) => this.handleClear(event),
                disabled: this.isLoading,
            },
            {
                type: "submit",
                color: "primary",
                text: CgnMessage.saveButton,
                disabled: this.isLoading,
            },
        ],
    };
    async componentDidMount() {
        this.setState({
            isLoading: true,
        });
        let urlSplit = window.location.pathname.split("/");
        this.setState({
            urlSplit: urlSplit,
        });
        if (urlSplit.length === 3) {
            this.setState({
                guid: urlSplit[urlSplit.length - 1],
                pageTitle: this.state.pageTitleUpdate,
                isLoading: true,
            });
            this.getById(urlSplit[2]);
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .get(URL + "/customereducationannouncementemail/get/" + urlSplit[2], config)
                .then((response) => {
                    if (this.state.guid) {
                        this.setState({
                            guid: response.data.guid,
                            name: response.data.name,
                            status: response.data.status,
                            isLoading: false,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }
    componentDidUpdate() {
        let urlSplit = window.location.pathname.split("/");
        if (
            urlSplit.length === 2 &&
            this.state.pageTitle !== this.state.pageTitleAdd
        ) {
            this.setState({
                urlSplit: urlSplit,
                pageTitle: this.state.pageTitleAdd,
                guid: "",
                name: "",
                status: true,
            });
        }
        if (this.state.status === undefined) {
            this.setState({
                status: true,
            });
        }
    }
    getById(guid) {
        this.setState({
            isLoading: true,
            guid: guid,
        });
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
        this.setState({
            nameError: "",
            alertErrorMessage: "",
        });
        if (name === "name" && value === "") {
            this.setState({
                nameError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    };
    handleClear = (event) => {
        this.clearPage();
    };
    clearPage() {
        this.setState({
            name: "",
            status: true,
            alertErrorMessage: CgnMessage.alertErrorMessage,
            nameError: CgnMessage.textErrorMessage,
        });
    }
    handleStatusSelectChange(status) {
        this.setState({ status: status.value });
    }
    handleSave = async (event) => {
        event.preventDefault();
        this.setState({
            alertErrorMessage: "",
            nameError: "",
        });
        if (this.state.name === "") {
            this.setState({
                nameError: CgnMessage.textErrorMessage,
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        } else if (this.state.name !== "") {
            this.setState({
                isLoading: true,
            });
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            var myObj = {
                name: this.state.name,
                status: this.state.status,
            };
            var URLParam = "/customereducationannouncementemail/add";
            if (this.state.guid && this.state.urlSplit.length !== 2 && this.state.urlSplit[1] === "musteri-egitim-duyuru-e-mail-guncelle") {
                URLParam = "/customereducationannouncementemail/update";
                myObj = {
                    guid: this.state.guid,
                    name: this.state.name,
                    status: this.state.status,
                };
            }
            axios
                .post(URL + URLParam, myObj, config)
                .then(() => {
                    setTimeout(() => {
                        if (this.state.guid && this.state.urlSplit[1] === "musteri-egitim-duyuru-e-mail-guncelle") {
                            window.location.href = "/musteri-egitim-duyuru-e-mail-listele";
                        }
                        if (this.state.urlSplit[1] === "musteri-egitim-duyuru-e-mail-ekle") {
                            window.location.reload();
                        }
                        var modalAddedCustomerEducationAnnouncementEMail = {
                            data: this.state.name,
                            isModal: false,
                        };
                        this.props.history.push({ modalAddedCustomerEducationAnnouncementEMail });
                        this.setState({
                            isLoading: false,
                        });
                    }, 1500);
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        } else {
            this.setState({
                alertErrorMessage: CgnMessage.alertErrorMessage,
            });
        }
    };
    render() {
        return (
            <>
                {(this.state.urlSplit[1] === "musteri-egitim-duyuru-e-mail-ekle" ||
                    this.state.urlSplit[1] === "musteri-egitim-duyuru-e-mail-guncelle") && (
                        <ContentTop
                            breadcrumb={this.state.breadcrumb}
                            title={this.state.pageTitle}
                            addLink={"/" + this.state.pageAddLink}
                            listLink={"/" + this.state.pageListLink}
                        />
                    )}
                <Row>
                    <Col>
                        <Form onSubmit={this.handleSave}>
                            <Card body>
                                <CgnLoader visibled={this.state.isLoading} />
                                <Row>
                                    <Col lg="6">
                                        <CgnTextbox
                                            type="text"
                                            name="name"
                                            label="E-Mail [*]"
                                            value={this.state.name}
                                            placeHolder="Lütfen e-mail girin..."
                                            maxLength="255"
                                            autoComplete="off"
                                            onChange={this.handleChange}
                                            error={this.state.nameError}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <CgnReactSelect
                                            name="status"
                                            label="Durumu"
                                            isMulti={false}
                                            selectValue={this.state.status}
                                            options={optionStatuses}
                                            onChange={(status) =>
                                                this.handleStatusSelectChange(status)
                                            }
                                        />
                                    </Col>
                                </Row>
                                {this.state.alertErrorMessage && (
                                    <CgnAlert
                                        color="danger"
                                        title={CgnMessage.alertErrorTitle}
                                        text={this.state.alertErrorMessage}
                                    />
                                )}
                                <CgnButtonGroup items={this.state.buttons} />
                            </Card>
                        </Form>
                        {(this.state.urlSplit[1] === "musteri-egitim-duyuru-e-mail-ekle" ||
                            this.state.urlSplit[1] === "musteri-egitim-duyuru-e-mail-guncelle") && (
                                <CgnButtonLink
                                    color="secondary"
                                    to={"/" + this.state.pageListLink}
                                    text={CgnMessage.backButton}
                                    className="btn-back"
                                />
                            )}
                    </Col>
                </Row>
            </>
        );
    }
}
export default withRouter(CustomerEducationAnnouncementEMailAddUpdate);