import React from "react";
import CgnLeftSidebar from "../toolbox/CgnLeftSidebar";
import Login from "../user/Login";
import RefreshPassword from "../user/RefreshPassword";
import { Route, Switch } from "react-router-dom";
import CgnMessage from "../toolbox/CgnMessage";
import NotFound from "../root/NotFound";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as loginAction from "../../redux/actions/user/loginAction";
import { URL } from "../../redux/actions/constants";
import ForgotInformation from "../user/ForgotInformation";
import axios from "axios";
import OfferApproved from "../offer/OfferApproved";
import OfferCanceled from "../offer/OfferCanceled";
import OfferNewApproved from "../offerNew/OfferNewApproved";
import OfferNewCanceled from "../offerNew/OfferNewCanceled";
import OfferSecondApproved from "../offerSecond/OfferSecondApproved";
import OfferSecondCanceled from "../offerSecond/OfferSecondCanceled";
import CustomerTicketDetail from "../customerTicket/CustomerTicketDetail";
import CustomerProductTicketDetail from "../customerProductTicket/CustomerProductTicketDetail";
import EnCustomerTicketDetail from "../enCustomerTicket/EnCustomerTicketDetail";
import EnCustomerProductTicketDetail from "../enCustomerProductTicket/EnCustomerProductTicketDetail";
import OtherTicketDetail from "../otherTicket/OtherTicketDetail";
import EnOtherTicketDetail from "../enOtherTicket/EnOtherTicketDetail";
import OfferSieveApproved from "../offerSieve/OfferSieveApproved";
import OfferSieveCanceled from "../offerSieve/OfferSieveCanceled";
import OfferMachineApproved from "../offerMachine/OfferMachineApproved";
import OfferMachineCanceled from "../offerMachine/OfferMachineCanceled";
import SendingEmailToCustomer from "../customer/SendingEmailToCustomer";
import SendingEmailToCustomer2 from "../customer/SendingEmailToCustomer2";
import CgnAlert from "../toolbox/CgnAlert";
import moment from "moment";

class Dashboard extends React.Component {
  state = {
    userPhoto: "",
    userFullName: "",
    userTitle: "",
    operationClaimArr: [],
    workAccidentDay: 0,
    cartCount: 0,
    remindCount: 0,
    orderCount: 0,
    orderLocationCount: 0,
  };

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/users/getprofile", config)
        .then((response) => {
          this.setState({
            userPhoto: response.data.memberDto.image,
            userFullName:
              response.data.memberDto.firstName +
              " " +
              response.data.memberDto.lastName,
            userTitle: response.data.memberDto.title,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/users/getuseroperationclaims", config)
        .then((response) => {
          response.data.forEach((element) => {
            this.state.operationClaimArr.push(element.operationClaimId);
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      await axios
        .get(URL + "/workaccident/getdaycount", config)
        .then((response) => {
          this.setState({
            workAccidentDay: response.data,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      await axios
        .get(URL + "/machineProducts/listtocart", config)
        .then((response) => {
          var totalPiece = 0;
          response.data.forEach((element) => {
            totalPiece += element.piece;
          });
          this.setState({
            cartCount: totalPiece,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      await axios
        .get(URL + "/reminds/getcount", config)
        .then((response) => {
          this.setState({
            remindCount: response.data,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      if (localStorage.getItem("isOrderCount")) {
        await axios
          .get(URL + "/machineproductorder/getcount", config)
          .then((response) => {
            this.setState({
              orderCount: response.data,
            });
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
              isLoading: false,
            });
          });
      }
      await axios
        .get(URL + "/machineproductorder/getcountlocation", config)
        .then((response) => {
          this.setState({
            orderLocationCount: response.data,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      this.state.operationClaimArr.forEach((element) => {
        this.items3.forEach((item) => {
          if (element === item.id) {
            item.visible = true;
          }
        });
      });
    }
  }

  items3 = [
    {
      id: 1265,
      title: "Dökümanlar",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12651,
          title: "Prosedürler",
          header: "",
          text: "",
          link: "/prosedurler-cs-savunma",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 12652,
          title: "Talimatlar",
          header: "",
          text: "",
          link: "/talimatlar-cs-savunma",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1105,
      title: "Kesim İşlemleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11051,
          title: "Kesim İşlemi Ekle",
          header: "",
          text: "",
          link: "/kesim-islemi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11052,
          title: "Kesim İşlemi Listele",
          header: "",
          text: "",
          link: "/kesim-islemi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1109,
      title: "Kesim Raporları",
      header: "",
      text: "",
      link: "/kesim-raporlari",
      color: "default",
      icon: "listele",
      active: false,
      disabled: false,
    },

    {
      id: 1189,
      title: "Kesim İşlemleri Log",
      header: "",
      text: "",
      link: "/kesim-islemleri-log",
      color: "default",
      icon: "listele",
      active: false,
      disabled: false,
    },

    {
      id: 1182,
      title: "Personel Kesim İşlemleri Listele",
      header: "",
      text: "",
      link: "/personel-kesim-islemi-listele",
      color: "default",
      icon: "listele",
      active: false,
      disabled: false,
    },

    {
      id: 1183,
      title: "Muhasebe Kesim İşlemleri Listele",
      header: "",
      text: "",
      link: "/muhasebe-kesim-islemi-listele",
      color: "default",
      icon: "listele",
      active: false,
      disabled: false,
    },

    {
      id: 1108,
      title: "TL/Saat Tanımlama",
      header: "",
      text: "",
      link: "/tl-saat-tanimla",
      color: "default",
      icon: "listele",
      active: false,
      disabled: false,
    },

    {
      id: 1107,
      title: "Tedarikçi Grupları",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11071,
          title: "Tedarikçi Grubu Ekle",
          header: "",
          text: "",
          link: "/tedarikci-grubu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11072,
          title: "Tedarikçi Grubu Listele",
          header: "",
          text: "",
          link: "/tedarikci-grubu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1038,
      title: "Tedarikçi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10381,
          title: "Tedarikçi Ekle",
          header: "",
          text: "",
          link: "/tedarikci-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10382,
          title: "Tedarikçi Listele",
          header: "",
          text: "",
          link: "/tedarikci-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1106,
      title: "Bölgeler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11061,
          title: "Bölge Ekle",
          header: "",
          text: "",
          link: "/bolge2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11062,
          title: "Bölge Listele",
          header: "",
          text: "",
          link: "/bolge2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1012,
      title: "Müşteri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10121,
          title: "Müşteri Ekle",
          header: "",
          text: "",
          link: "/musteri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10122,
          title: "Müşteri Listele",
          header: "",
          text: "",
          link: "/musteri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1242,
      title: "Eğitim Alacak Müşteriler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12421,
          title: "Eğitim Alacak Müşteriler Ekle",
          header: "",
          text: "",
          link: "/musteri-egitim-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12422,
          title: "Eğitim Alacak Müşteriler Listele",
          header: "",
          text: "",
          link: "/musteri-egitim-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1285,
      title: "Potansiyel Müşteriler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12851,
          title: "Potansiyel Müşteriler Ekle",
          header: "",
          text: "",
          link: "/musteri-egitim-duyuru-e-mail-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12852,
          title: "Potansiyel Müşteriler Listele",
          header: "",
          text: "",
          link: "/musteri-egitim-duyuru-e-mail-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1284,
      title: "Duyurular",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12841,
          title: "Duyuru Ekle",
          header: "",
          text: "",
          link: "/musteri-egitim-duyuru-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12842,
          title: "Duyuru Listele",
          header: "",
          text: "",
          link: "/musteri-egitim-duyuru-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1243,
      title: "Videolar",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12431,
          title: "Video Ekle",
          header: "",
          text: "",
          link: "/musteri-egitim-youtube-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12432,
          title: "Video Listele",
          header: "",
          text: "",
          link: "/musteri-egitim-youtube-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1245,
      title: "Sınavlar",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12451,
          title: "Sınav Ekle",
          header: "",
          text: "",
          link: "/musteri-egitim-sinav-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12452,
          title: "Sınav Listele",
          header: "",
          text: "",
          link: "/musteri-egitim-sinav-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },


    {
      id: 1244,
      title: "Müşteri Faydalı Link",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12441,
          title: "Müşteri Faydalı Link Ekle",
          header: "",
          text: "",
          link: "/musteri-faydali-link-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12442,
          title: "Müşteri Faydalı Link Listele",
          header: "",
          text: "",
          link: "/musteri-faydali-link-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1076,
      title: "Satış Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10761,
          title: "Satış Türü Ekle",
          header: "",
          text: "",
          link: "/satis-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10762,
          title: "Satış Türü Listele",
          header: "",
          text: "",
          link: "/satis-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1066,
      title: "Makine Müşteri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10661,
          title: "Makine Müşteri Ekle",
          header: "",
          text: "",
          link: "/makine-musteri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10662,
          title: "Makine Müşteri Listele",
          header: "",
          text: "",
          link: "/makine-musteri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1247,
      title: "Makine Müşteri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12471,
          title: "Makine Müşteri Ekle",
          header: "",
          text: "",
          link: "/makine-musteri-2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12472,
          title: "Makine Müşteri Listele",
          header: "",
          text: "",
          link: "/makine-musteri-2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1056,
      title: "Kişi Grubu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10561,
          title: "Kişi Grubu Ekle",
          header: "",
          text: "",
          link: "/kisi-grubu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10562,
          title: "Kişi Grubu Listele",
          header: "",
          text: "",
          link: "/kisi-grubu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1057,
      title: "Kişiler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10571,
          title: "Kişi Ekle",
          header: "",
          text: "",
          link: "/kisi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10572,
          title: "Kişi Listele",
          header: "",
          text: "",
          link: "/kisi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1089,
      title: "Üyeler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10891,
          title: "Üye Ekle",
          header: "",
          text: "",
          link: "/uye-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10892,
          title: "Üye Listele",
          header: "",
          text: "",
          link: "/uye-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1055,
      title: "Depo",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10551,
          title: "Depo Ekle",
          header: "",
          text: "",
          link: "/depo-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10552,
          title: "Depo Listele",
          header: "",
          text: "",
          link: "/depo-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1006,
      title: "Kategori",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10061,
          title: "Kategori Ekle",
          header: "",
          text: "",
          link: "/kategori-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10062,
          title: "Kategori Listele",
          header: "",
          text: "",
          link: "/kategori-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1033,
      title: "Ürün Özellikleri Tipi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10331,
          title: "Ürün Özellikleri Tipi Ekle",
          header: "",
          text: "",
          link: "/urun-ozellikleri-tipi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10332,
          title: "Ürün Özellikleri Tipi Listele",
          header: "",
          text: "",
          link: "/urun-ozellikleri-tipi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1288,
      title: "Ürün Grupları",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12881,
          title: "Ürün Grubu Ekle",
          header: "",
          text: "",
          link: "/urun-grubu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12882,
          title: "Ürün Grubu Listele",
          header: "",
          text: "",
          link: "/urun-grubu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1029,
      title: "Ürün",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10291,
          title: "Ürün Ekle",
          header: "",
          text: "",
          link: "/urun-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10292,
          title: "Ürün Listele",
          header: "",
          text: "",
          link: "/urun-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 1029,
          title: "Kritik Ürün Listele",
          header: "",
          text: "",
          link: "/kritik-urun-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1208,
      title: "Ürün",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12081,
          title: "Ürün Ekle",
          header: "",
          text: "",
          link: "/urun2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12082,
          title: "Ürün Listele",
          header: "",
          text: "",
          link: "/urun2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 1208,
          title: "Kritik Ürün Listele",
          header: "",
          text: "",
          link: "/kritik-urun2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1224,
      title: "Ürün",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12241,
          title: "Ürün Ekle",
          header: "",
          text: "",
          link: "/urun3-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12242,
          title: "Ürün Listele",
          header: "",
          text: "",
          link: "/urun3-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 12243,
          title: "Ürün Adet Listele",
          header: "",
          text: "",
          link: "/urun3-adet-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 1224,
          title: "Kritik Ürün Listele",
          header: "",
          text: "",
          link: "/kritik-urun3-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1256,
      title: "Depom",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12561,
          title: "Ürün Listele",
          header: "",
          text: "",
          link: "/depomdaki-urunler",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 12562,
          title: "Depomdaki Ürünler",
          header: "",
          text: "",
          link: "/depomdaki-urun-adetleri",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1258,
      title: "Depolardan Çıkarılan Ürünler",
      header: "",
      text: "",
      link: "/depolardan-cikarilan-urunler",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1254,
      title: "Sipariş",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12541,
          title: "Sipariş Ekle",
          header: "",
          text: "",
          link: "/makine-urun-siparis-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12542,
          title: "Sipariş Listele",
          header: "",
          text: "",
          link: "/makine-urun-siparis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1255,
      title: "Yetkili Sipariş Listele",
      header: "",
      text: "",
      link: "/yetkili-makine-urun-siparis-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
    },

    {
      id: 1230,
      title: "Stok Aktarım",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12301,
          title: "Stok Aktarım Ekle",
          header: "",
          text: "",
          link: "/stok-aktarim-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12302,
          title: "Stok Aktarım Listele",
          header: "",
          text: "",
          link: "/stok-aktarim-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1231,
      title: "Yetkili Stok Aktarım",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12311,
          title: "Yetkili Stok Aktarım Listele",
          header: "",
          text: "",
          link: "/yetkili-stok-aktarim-listele",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12312,
          title: "Stok Hareketleri",
          header: "",
          text: "",
          link: "/yetkili-stok-aktarim-stok-hareketleri",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1257,
      title: "Kullanılan Yedek Parça",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12571,
          title: "Kullanılan Yedek Parça Ekle",
          header: "",
          text: "",
          link: "/kullanilan-yedek-parca-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12572,
          title: "Kullanılan Yedek Parça Listele",
          header: "",
          text: "",
          link: "/kullanilan-yedek-parca-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1272,
      title: "Yetkili Kullanılan Yedek Parça",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12721,
          title: "Stok Hareketleri",
          header: "",
          text: "",
          link: "/kullanilan-yedek-parca-stok-hareketleri",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1278,
      title: "Arızalı Ürün",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12781,
          title: "Arızalı Ürün Ekle",
          header: "",
          text: "",
          link: "/arizali-urun-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12782,
          title: "Arızalı Ürün Listele",
          header: "",
          text: "",
          link: "/arizali-urun-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1279,
      title: "Yetkili Arızalı Ürün Listele",
      header: "",
      text: "",
      link: "/yetkili-arizali-urun-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1260,
      title: "Geri Dönüşüm",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12601,
          title: "Geri Dönüşüm Ekle",
          header: "",
          text: "",
          link: "/geri-donusum-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12602,
          title: "Geri Dönüşüm Listele",
          header: "",
          text: "",
          link: "/geri-donusum-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1263,
      title: "Yetkili Geri Dönüşüm Listele",
      header: "",
      text: "",
      link: "/yetkili-geri-donusum-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1112,
      title: "Alış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11121,
          title: "Alış Ekle",
          header: "",
          text: "",
          link: "/alis2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11122,
          title: "Alış Listele",
          header: "",
          text: "",
          link: "/alis2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1027,
      title: "Alış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10271,
          title: "Alış Ekle",
          header: "",
          text: "",
          link: "/alis-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10272,
          title: "Alış Listele",
          header: "",
          text: "",
          link: "/alis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1225,
      title: "Alış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12251,
          title: "Alış Ekle",
          header: "",
          text: "",
          link: "/alis4-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12252,
          title: "Alış Listele",
          header: "",
          text: "",
          link: "/alis4-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1116,
      title: "Satış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11161,
          title: "Satış Ekle",
          header: "",
          text: "",
          link: "/satis2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11162,
          title: "Satış Listele",
          header: "",
          text: "",
          link: "/satis2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1030,
      title: "Satış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10301,
          title: "Satış Ekle",
          header: "",
          text: "",
          link: "/satis-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10302,
          title: "Satış Listele",
          header: "",
          text: "",
          link: "/satis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1226,
      title: "Satış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12261,
          title: "Satış Ekle",
          header: "",
          text: "",
          link: "/satis4-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12262,
          title: "Satış Listele",
          header: "",
          text: "",
          link: "/satis4-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1118,
      title: "İade",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11181,
          title: "İade Ekle",
          header: "",
          text: "",
          link: "/iade2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11182,
          title: "İade Listele",
          header: "",
          text: "",
          link: "/iade2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1036,
      title: "İade",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10361,
          title: "İade Ekle",
          header: "",
          text: "",
          link: "/iade-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10362,
          title: "İade Listele",
          header: "",
          text: "",
          link: "/iade-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1121,
      title: "Stok Hareketleri",
      header: "",
      text: "",
      link: "/stok-hareketleri",
      color: "default",
      icon: "listele",
      active: false,
      disabled: false,
    },

    {
      id: 1074,
      title: "Sayım",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10741,
          title: "Sayım Ekle",
          header: "",
          text: "",
          link: "/sayim-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10742,
          title: "Sayım Listele",
          header: "",
          text: "",
          link: "/sayim-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1228,
      title: "Sayım",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12281,
          title: "Sayım Ekle",
          header: "",
          text: "",
          link: "/sayim2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12282,
          title: "Sayım Listele",
          header: "",
          text: "",
          link: "/sayim2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1078,
      title: "Yetkili Sayım",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10781,
          title: "Yetkili Sayım Listele",
          header: "",
          text: "",
          link: "/yetkili-sayim-listele",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1229,
      title: "Yetkili Sayım",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12291,
          title: "Yetkili Sayım Listele",
          header: "",
          text: "",
          link: "/yetkili-sayim2-listele",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1090,
      title: "Açıklamalar",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10901,
          title: "Açıklama Ekle",
          header: "",
          text: "",
          link: "/aciklama-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10902,
          title: "Açıklama Listele",
          header: "",
          text: "",
          link: "/aciklama-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1098,
      title: "Sipariş Kapağı",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10981,
          title: "Sipariş Kapağı Ekle",
          header: "",
          text: "",
          link: "/siparis-kapagi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10982,
          title: "Sipariş Kapağı Listele",
          header: "",
          text: "",
          link: "/siparis-kapagi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1086,
      title: "Sipariş",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10861,
          title: "Sipariş Ekle",
          header: "",
          text: "",
          link: "/siparis-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10862,
          title: "Sipariş Listele",
          header: "",
          text: "",
          link: "/siparis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1114,
      title: "Sipariş",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11141,
          title: "Sipariş Ekle",
          header: "",
          text: "",
          link: "/siparis2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11142,
          title: "Sipariş Listele",
          header: "",
          text: "",
          link: "/siparis2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1058,
      title: "Teklif Tanımlama",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10581,
          title: "Teklif Tanımlama Ekle",
          header: "",
          text: "",
          link: "/teklif-tanimlama-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10582,
          title: "Teklif Tanımlama Listele",
          header: "",
          text: "",
          link: "/teklif-tanimlama-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1022,
      title: "Teklif Kapağı",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10221,
          title: "Teklif Kapağı Ekle",
          header: "",
          text: "",
          link: "/teklif-kapagi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10222,
          title: "Teklif Kapağı Listele",
          header: "",
          text: "",
          link: "/teklif-kapagi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1204,
      title: "Teklif Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12041,
          title: "Teklif Türü Ekle",
          header: "",
          text: "",
          link: "/teklif-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12042,
          title: "Teklif Türü Listele",
          header: "",
          text: "",
          link: "/teklif-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1023,
      title: "Teklif",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10231,
          title: "Teklif Ekle",
          header: "",
          text: "",
          link: "/teklif-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10232,
          title: "Teklif Listele",
          header: "",
          text: "",
          link: "/teklif-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1110,
      title: "Teklif",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11101,
          title: "Teklif Ekle",
          header: "",
          text: "",
          link: "/teklif2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11102,
          title: "Teklif Listele",
          header: "",
          text: "",
          link: "/teklif2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1209,
      title: "Teklif",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12091,
          title: "Teklif Ekle",
          header: "",
          text: "",
          link: "/teklif3-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12092,
          title: "Teklif Listele",
          header: "",
          text: "",
          link: "/teklif3-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1281,
      title: "Yurt İçi Teklif",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12811,
          title: "Yurt İçi Teklif Ekle",
          header: "",
          text: "",
          link: "/teklif33-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12812,
          title: "Yurt İçi Teklif Listele",
          header: "",
          text: "",
          link: "/teklif33-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 12813,
          title: "Yurt İçi Teklif Raporları",
          header: "",
          text: "",
          link: "/teklif33-raporlari",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1252,
      title: "Yurt Dışı Teklif",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12091,
          title: "Yurt Dışı Teklif Ekle",
          header: "",
          text: "",
          link: "/teklif32-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12092,
          title: "Yurt Dışı Teklif Listele",
          header: "",
          text: "",
          link: "/teklif32-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 12093,
          title: "Yurt Dışı Teklif Raporları",
          header: "",
          text: "",
          link: "/teklif32-raporlari",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },



    {
      id: 1240,
      title: "Teklif Onaylanan Listele",
      header: "",
      text: "",
      link: "/teklif3-onaylanan-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1287,
      title: "Teklif Sevk Edilen Listele",
      header: "",
      text: "",
      link: "/teklif3-sevk-edilen-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1210,
      title: "Personel Teklif Listele",
      header: "",
      text: "",
      link: "/personel-teklif3-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1134,
      title: "Teklif",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11341,
          title: "Teklif Ekle",
          header: "",
          text: "",
          link: "/yeni-teklif-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11342,
          title: "Teklif Listele",
          header: "",
          text: "",
          link: "/yeni-teklif-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1227,
      title: "Teklif",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12271,
          title: "Teklif Ekle",
          header: "",
          text: "",
          link: "/teklif4-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12272,
          title: "Teklif Listele",
          header: "",
          text: "",
          link: "/teklif4-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1211,
      title: "Teklif Raporları",
      header: "",
      text: "",
      link: "/teklif-raporlari",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1215,
      title: "Ödeme Yöntemi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12151,
          title: "Ödeme Yöntemi Ekle",
          header: "",
          text: "",
          link: "/odeme-yontemi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12152,
          title: "Ödeme Yöntemi Listele",
          header: "",
          text: "",
          link: "/odeme-yontemi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1216,
      title: "Teslimat Süresi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12161,
          title: "Teslimat Süresi Ekle",
          header: "",
          text: "",
          link: "/teslimat-suresi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12162,
          title: "Teslimat Süresi Listele",
          header: "",
          text: "",
          link: "/teslimat-suresi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1217,
      title: "Teslimat Yöntemi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12171,
          title: "Teslimat Yöntemi Ekle",
          header: "",
          text: "",
          link: "/teslimat-yontemi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12172,
          title: "Teslimat Yöntemi Listele",
          header: "",
          text: "",
          link: "/teslimat-yontemi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1218,
      title: "Garanti Süresi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12181,
          title: "Garanti Süresi Ekle",
          header: "",
          text: "",
          link: "/garanti-suresi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12182,
          title: "Garanti Süresi Listele",
          header: "",
          text: "",
          link: "/garanti-suresi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1003,
      title: "Banka",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10031,
          title: "Banka Ekle",
          header: "",
          text: "",
          link: "/banka-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10032,
          title: "Banka Listele",
          header: "",
          text: "",
          link: "/banka-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1010,
      title: "Gider Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10101,
          title: "Gider Türü Ekle",
          header: "",
          text: "",
          link: "/gider-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10102,
          title: "Gider Türü Listele",
          header: "",
          text: "",
          link: "/gider-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1002,
      title: "Gider",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10021,
          title: "Gider Ekle",
          header: "",
          text: "",
          link: "/gider-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10022,
          title: "Gider Listele",
          header: "",
          text: "",
          link: "/gider-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 1025,
          title: "Ödeme Takvimi Listele",
          header: "",
          text: "",
          link: "/odeme-takvimi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1016,
      title: "Gelir",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10161,
          title: "Gelir Ekle",
          header: "",
          text: "",
          link: "/gelir-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10162,
          title: "Gelir Listele",
          header: "",
          text: "",
          link: "/gelir-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 1008,
          title: "Tahsilat Takvimi Listele",
          header: "",
          text: "",
          link: "/tahsilat-takvimi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1079,
      title: "Fatura",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10791,
          title: "Gelen Fatura Listele",
          header: "",
          text: "",
          link: "/gelen-fatura-listele",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10792,
          title: "Giden Fatura Listele",
          header: "",
          text: "",
          link: "/giden-fatura-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1084,
      title: "Servis Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10841,
          title: "Servis Türü Ekle",
          header: "",
          text: "",
          link: "/servis-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10842,
          title: "Servis Türü Listele",
          header: "",
          text: "",
          link: "/servis-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1050,
      title: "Servis",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10501,
          title: "Servis Ekle",
          header: "",
          text: "",
          link: "/servis-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10502,
          title: "Servis Listele",
          header: "",
          text: "",
          link: "/servis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 10503,
          title: "Yaklaşan Servis Listele",
          header: "",
          text: "",
          link: "/yaklasan-servis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1087,
      title: "Poliçe",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10871,
          title: "Poliçe Ekle",
          header: "",
          text: "",
          link: "/police-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10872,
          title: "Poliçe Listele",
          header: "",
          text: "",
          link: "/police-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 10873,
          title: "Yaklaşan Poliçe Listele",
          header: "",
          text: "",
          link: "/yaklasan-police-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 10874,
          title: "Girilen Poliçe Listele",
          header: "",
          text: "",
          link: "/girilen-police-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1064,
      title: "Arıza Durumu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10641,
          title: "Arıza Durumu Ekle",
          header: "",
          text: "",
          link: "/ariza-durumu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10642,
          title: "Arıza Durumu Listele",
          header: "",
          text: "",
          link: "/ariza-durumu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    // {
    //   id: 1077,
    //   title: "Servis Durumu",
    //   header: "",
    //   text: "",
    //   link: "#",
    //   color: "default",
    //   active: false,
    //   visible: false,
    //   disabled: false,
    //   collapse: false,
    //   items: [
    //     {
    //       id: 10771,
    //       title: "Servis Durumu Ekle",
    //       header: "",
    //       text: "",
    //       link: "/servis-durumu-ekle",
    //       color: "default",
    //       icon: "ekle",
    //       active: false,
    //       disabled: false,
    //     },
    //     {
    //       id: 10772,
    //       title: "Servis Durumu Listele",
    //       header: "",
    //       text: "",
    //       link: "/servis-durumu-listele",
    //       color: "default",
    //       icon: "listele",
    //       active: false,
    //       disabled: false,
    //     },
    //   ],
    // },

    {
      id: 1063,
      title: "Şikayet Sebebi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10631,
          title: "Şikayet Sebebi Ekle",
          header: "",
          text: "",
          link: "/sikayet-sebebi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10632,
          title: "Şikayet Sebebi Listele",
          header: "",
          text: "",
          link: "/sikayet-sebebi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1065,
      title: "Garanti Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10651,
          title: "Garanti Türü Ekle",
          header: "",
          text: "",
          link: "/garanti-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10652,
          title: "Garanti Türü Listele",
          header: "",
          text: "",
          link: "/garanti-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1073,
      title: "İşlem Tipi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10731,
          title: "İşlem Tipi Ekle",
          header: "",
          text: "",
          link: "/islem-tipi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10732,
          title: "İşlem Tipi Listele",
          header: "",
          text: "",
          link: "/islem-tipi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1061,
      title: "Marka",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10611,
          title: "Marka Ekle",
          header: "",
          text: "",
          link: "/marka-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10612,
          title: "Marka Listele",
          header: "",
          text: "",
          link: "/marka-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1072,
      title: "Model",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10721,
          title: "Model Ekle",
          header: "",
          text: "",
          link: "/model-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10722,
          title: "Model Listele",
          header: "",
          text: "",
          link: "/model-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1062,
      title: "Kanal",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10621,
          title: "Kanal Ekle",
          header: "",
          text: "",
          link: "/kanal-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10622,
          title: "Kanal Listele",
          header: "",
          text: "",
          link: "/kanal-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1071,
      title: "Makine",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10711,
          title: "Makine Ekle",
          header: "",
          text: "",
          link: "/makine-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10712,
          title: "Makine Listele",
          header: "",
          text: "",
          link: "/makine-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1067,
      title: "Makine Personel",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10671,
          title: "Makine Personel Ekle",
          header: "",
          text: "",
          link: "/makine-personel-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10672,
          title: "Makine Personel Listele",
          header: "",
          text: "",
          link: "/makine-personel-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1060,
      title: "İş Atama",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10601,
          title: "İş Atama Ekle",
          header: "",
          text: "",
          link: "/is-atama-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10602,
          title: "İş Atama Listele",
          header: "",
          text: "",
          link: "/is-atama-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1080,
      title: "Personel İş Atama",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10801,
          title: "Bekleyen İş Atama Listele",
          header: "",
          text: "",
          link: "/bekleyen-is-atama-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
        {
          id: 10802,
          title: "Tamamlanan İş Atama Listele",
          header: "",
          text: "",
          link: "/tamamlanan-is-atama-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1068,
      title: "Makine Servis",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10682,
          title: "Makine Servis Listele",
          header: "",
          text: "",
          link: "/makine-servis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1091,
      title: "Görev Kategorileri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10911,
          title: "Görev Kategori Ekle",
          header: "",
          text: "",
          link: "/gorev-kategori-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10912,
          title: "Görev Kategori Listele",
          header: "",
          text: "",
          link: "/gorev-kategori-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1092,
      title: "Görev Önceliği",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10921,
          title: "Görev Önceliği Ekle",
          header: "",
          text: "",
          link: "/gorev-onceligi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10922,
          title: "Görev Önceliği Listele",
          header: "",
          text: "",
          link: "/gorev-onceligi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1093,
      title: "Görev Durumu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10931,
          title: "Görev Durumu Ekle",
          header: "",
          text: "",
          link: "/gorev-durumu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10932,
          title: "Görev Durumu Listele",
          header: "",
          text: "",
          link: "/gorev-durumu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1094,
      title: "Görev Atama",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10941,
          title: "Görev Atama Ekle",
          header: "",
          text: "",
          link: "/gorev-atama-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10942,
          title: "Görev Atama Listele",
          header: "",
          text: "",
          link: "/gorev-atama-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1095,
      title: "Personel Görev Listesi",
      header: "",
      text: "",
      link: "/personel-gorev-listele",
      color: "default",
      icon: "listele",
      active: false,
      disabled: false,
    },

    {
      id: 1101,
      title: "Personel Müşteri Atama",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11011,
          title: "Personel Müşteri Ekle",
          header: "",
          text: "",
          link: "/personel-musteri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11012,
          title: "Personel Müşteri Listele",
          header: "",
          text: "",
          link: "/personel-musteri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1099,
      title: "Tip Alanı",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10991,
          title: "Tip Ekle",
          header: "",
          text: "",
          link: "/tip-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10992,
          title: "Tip Listele",
          header: "",
          text: "",
          link: "/tip-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1100,
      title: "Muhasebe Takip",
      header: "",
      text: "",
      link: "/muhasebe-takip",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1102,
      title: "Personel Muhasebe Takip",
      header: "",
      text: "",
      link: "/personel-muhasebe-takip",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    // {
    //   id: 1103,
    //   title: "Kesim",
    //   header: "",
    //   text: "",
    //   link: "#",
    //   color: "default",
    //   active: false,
    //   visible: false,
    //   disabled: false,
    //   collapse: false,
    //   items: [
    //     {
    //       id: 11031,
    //       title: "Kesim Ekle",
    //       header: "",
    //       text: "",
    //       link: "/kesim-ekle",
    //       color: "default",
    //       icon: "ekle",
    //       active: false,
    //       disabled: false,
    //     },
    //     {
    //       id: 11032,
    //       title: "Kesim Listele",
    //       header: "",
    //       text: "",
    //       link: "/kesim-listele",
    //       color: "default",
    //       icon: "listele",
    //       active: false,
    //       disabled: false,
    //     },
    //   ],
    // },

    // {
    //   id: 1104,
    //   title: "Kesim Cinsi",
    //   header: "",
    //   text: "",
    //   link: "#",
    //   color: "default",
    //   active: false,
    //   visible: false,
    //   disabled: false,
    //   collapse: false,
    //   items: [
    //     {
    //       id: 11041,
    //       title: "Kesim Cinsi Ekle",
    //       header: "",
    //       text: "",
    //       link: "/kesim-cinsi-ekle",
    //       color: "default",
    //       icon: "ekle",
    //       active: false,
    //       disabled: false,
    //     },
    //     {
    //       id: 11042,
    //       title: "Kesim Cinsi Listele",
    //       header: "",
    //       text: "",
    //       link: "/kesim-cinsi-listele",
    //       color: "default",
    //       icon: "listele",
    //       active: false,
    //       disabled: false,
    //     },
    //   ],
    // },

    // {
    //   id: 1048,
    //   title: "Sözleşmeler",
    //   header: "",
    //   text: "",
    //   link: "#",
    //   color: "default",
    //   active: false,
    //   visible: false,
    //   disabled: false,
    //   collapse: false,
    //   items: [
    //     {
    //       id: 1048-1,
    //       title: "Sözleşme Ekle",
    //       header: "",
    //       text: "",
    //       link: "/sozlesme-ekle",
    //       color: "default",
    //       icon: "ekle",
    //       active: false,
    //       disabled: false,
    //     },
    //     {
    //       id: 1048-2,
    //       title: "Sözleşme Listele",
    //       header: "",
    //       text: "",
    //       link: "/sozlesme-listele",
    //       color: "default",
    //       icon: "listele",
    //       active: false,
    //       disabled: false,
    //     },
    //   ],
    // },

    {
      id: 1049,
      title: "Belge Türleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10491,
          title: "Belge Türü Ekle",
          header: "",
          text: "",
          link: "/belge-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10492,
          title: "Belge Türü Listele",
          header: "",
          text: "",
          link: "/belge-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1051,
      title: "Belgeler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10511,
          title: "Belge Ekle",
          header: "",
          text: "",
          link: "/belge-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10512,
          title: "Belge Listele",
          header: "",
          text: "",
          link: "/belge-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1283,
      title: "Belge Takip",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12831,
          title: "Belge Takip Ekle",
          header: "",
          text: "",
          link: "/belge-takip-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12832,
          title: "Belge Takip Listele",
          header: "",
          text: "",
          link: "/belge-takip-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1052,
      title: "Saha Satış Bölgeleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10521,
          title: "Saha Satış Bölgesi Ekle",
          header: "",
          text: "",
          link: "/saha-satis-bolgesi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10522,
          title: "Saha Satış Bölgesi Listele",
          header: "",
          text: "",
          link: "/saha-satis-bolgesi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1135,
      title: "Satış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11351,
          title: "Satış Ekle",
          header: "",
          text: "",
          link: "/yeni-satis-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11352,
          title: "Satış Listele",
          header: "",
          text: "",
          link: "/yeni-satis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1137,
      title: "Alış",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11371,
          title: "Alış Ekle",
          header: "",
          text: "",
          link: "/yeni-alis-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11372,
          title: "Alış Listele",
          header: "",
          text: "",
          link: "/yeni-alis-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1139,
      title: "İade",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11391,
          title: "İade Ekle",
          header: "",
          text: "",
          link: "/yeni-iade-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11392,
          title: "İade Listele",
          header: "",
          text: "",
          link: "/yeni-iade-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1122,
      title: "El Aleti Kategori",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11221,
          title: "El Aleti Kategori Ekle",
          header: "",
          text: "",
          link: "/malzeme-kategori-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11222,
          title: "El Aleti Kategori Listele",
          header: "",
          text: "",
          link: "/malzeme-kategori-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1133,
      title: "El Aleti",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11331,
          title: "El Aleti Ekle",
          header: "",
          text: "",
          link: "/malzeme-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11332,
          title: "El Aleti Listele",
          header: "",
          text: "",
          link: "/malzeme-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1131,
      title: "Ürün Reçete",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11311,
          title: "Ürün Reçete Ekle",
          header: "",
          text: "",
          link: "/urun-recete-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11312,
          title: "Ürün Reçete Listele",
          header: "",
          text: "",
          link: "/urun-recete-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1124,
      title: "Hata Kodu Kategori",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11241,
          title: "Hata Kodu Kategori Ekle",
          header: "",
          text: "",
          link: "/hata-kodu-kategori-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11242,
          title: "Hata Kodu Kategori Listele",
          header: "",
          text: "",
          link: "/hata-kodu-kategori-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1130,
      title: "Hata Kodu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11301,
          title: "Hata Kodu Ekle",
          header: "",
          text: "",
          link: "/hata-kodu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11302,
          title: "Hata Kodu Listele",
          header: "",
          text: "",
          link: "/hata-kodu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1125,
      title: "Fiziksel Kontrol",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11251,
          title: "Fiziksel Kontrol Ekle",
          header: "",
          text: "",
          link: "/fiziksel-kontrol-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11252,
          title: "Fiziksel Kontrol Listele",
          header: "",
          text: "",
          link: "/fiziksel-kontrol-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1126,
      title: "Elektrik ve Diğer Testler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11261,
          title: "Elektrik ve Diğer Testler Ekle",
          header: "",
          text: "",
          link: "/elektrik-ve-diger-testler-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11262,
          title: "Elektrik ve Diğer Testler Listele",
          header: "",
          text: "",
          link: "/elektrik-ve-diger-testler-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1127,
      title: "Kullanılan Cihaz",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11271,
          title: "Kullanılan Cihaz Ekle",
          header: "",
          text: "",
          link: "/kullanilan-cihaz-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11272,
          title: "Kullanılan Cihaz Listele",
          header: "",
          text: "",
          link: "/kullanilan-cihaz-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1128,
      title: "Uygulanan Process Tanımı",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11281,
          title: "Uygulanan Process Tanımı Ekle",
          header: "",
          text: "",
          link: "/uygulanan-process-tanimi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11282,
          title: "Uygulanan Process Tanımı Listele",
          header: "",
          text: "",
          link: "/uygulanan-process-tanimi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1129,
      title: "İş Emri Durumu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11291,
          title: "İş Emri Durumu Ekle",
          header: "",
          text: "",
          link: "/is-emri-durumu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11292,
          title: "İş Emri Durumu Listele",
          header: "",
          text: "",
          link: "/is-emri-durumu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1123,
      title: "İş Emri Adımı",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11231,
          title: "İş Emri Adımı Ekle",
          header: "",
          text: "",
          link: "/is-emri-adimi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11232,
          title: "İş Emri Adımı Listele",
          header: "",
          text: "",
          link: "/is-emri-adimi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1141,
      title: "İş Emri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11411,
          title: "İş Emri Ekle",
          header: "",
          text: "",
          link: "/is-emri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11412,
          title: "İş Emri Listele",
          header: "",
          text: "",
          link: "/is-emri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1144,
      title: "Personel İş Emri",
      header: "",
      text: "",
      link: "/personel-is-emri-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1145,
      title: "Depo Ürün İşlemleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11451,
          title: "Depo Ürün İşlemi Ekle",
          header: "",
          text: "",
          link: "/depo-urun-islemi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11452,
          title: "Depo Ürün İşlemi Listele",
          header: "",
          text: "",
          link: "/depo-urun-islemi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1150,
      title: "Depo El Aleti İşlemleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11501,
          title: "Depo El Aleti İşlemi Ekle",
          header: "",
          text: "",
          link: "/depo-el-aleti-islemi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11502,
          title: "Depo El Aleti İşlemi Listele",
          header: "",
          text: "",
          link: "/depo-el-aleti-islemi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1147,
      title: "Kalite Havuzu",
      header: "",
      text: "",
      link: "/kalite-havuzu",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1148,
      title: "Personel Kalite Listesi",
      header: "",
      text: "",
      link: "/personel-kalite-listesi",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1155,
      title: "Son Kontrol Havuzu",
      header: "",
      text: "",
      link: "/son-kontrol-havuzu",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1152,
      title: "Personel Son Kontrol Listesi",
      header: "",
      text: "",
      link: "/personel-son-kontrol-listesi",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1156,
      title: "Uygunluk Formu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11561,
          title: "Uygunluk Formu Ekle",
          header: "",
          text: "",
          link: "/uygunluk-formu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11562,
          title: "Uygunluk Formu Listele",
          header: "",
          text: "",
          link: "/uygunluk-formu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1160,
      title: "Manuel Kontrol Raporu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11601,
          title: "Manuel Kontrol Raporu Ekle",
          header: "",
          text: "",
          link: "/manuel-kontrol-raporu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11602,
          title: "Manuel Kontrol Raporu Listele",
          header: "",
          text: "",
          link: "/manuel-kontrol-raporu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1162,
      title: "Manuel Kontrol Raporu 2",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11621,
          title: "Manuel Kontrol Raporu 2 Ekle",
          header: "",
          text: "",
          link: "/manuel-kontrol-raporu2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11622,
          title: "Manuel Kontrol Raporu 2 Listele",
          header: "",
          text: "",
          link: "/manuel-kontrol-raporu2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1164,
      title: "Boyutsal Ölçüm Raporu",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11641,
          title: "Boyutsal Ölçüm Raporu Ekle",
          header: "",
          text: "",
          link: "/boyutsal-olcum-raporu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11642,
          title: "Boyutsal Ölçüm Raporu Listele",
          header: "",
          text: "",
          link: "/boyutsal-olcum-raporu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1166,
      title: "Pin İtme Testi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11661,
          title: "Pin İtme Testi Ekle",
          header: "",
          text: "",
          link: "/pin-itme-testi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11662,
          title: "Pin İtme Testi Listele",
          header: "",
          text: "",
          link: "/pin-itme-testi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1169,
      title: "Pin Çekme Testi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11691,
          title: "Pin Çekme Testi Ekle",
          header: "",
          text: "",
          link: "/pin-cekme-testi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11692,
          title: "Pin Çekme Testi Listele",
          header: "",
          text: "",
          link: "/pin-cekme-testi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1174,
      title: "Manuel Kontrol Şablon",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11741,
          title: "Manuel Kontrol Şablon Ekle",
          header: "",
          text: "",
          link: "/manuel-kontrol-sablon-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11742,
          title: "Manuel Kontrol Şablon Listele",
          header: "",
          text: "",
          link: "/manuel-kontrol-sablon-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1195,
      title: "Boyutsal Ölçüm Şablon",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11951,
          title: "Boyutsal Ölçüm Şablon Ekle",
          header: "",
          text: "",
          link: "/boyutsal-olcum-sablon-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11952,
          title: "Boyutsal Ölçüm Şablon Listele",
          header: "",
          text: "",
          link: "/boyutsal-olcum-sablon-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1214,
      title: "Sevkiyat",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12141,
          title: "Sevkiyat Ekle",
          header: "",
          text: "",
          link: "/sevkiyat-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12142,
          title: "Sevkiyat Listele",
          header: "",
          text: "",
          link: "/sevkiyat-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1172,
      title: "Belge Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11721,
          title: "Belge Türü Ekle",
          header: "",
          text: "",
          link: "/belge-turu2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11722,
          title: "Belge Türü Listele",
          header: "",
          text: "",
          link: "/belge-turu2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1173,
      title: "Müşteriden İstenen Belgeler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11731,
          title: "Müşteriden İstenen Belgeler Ekle",
          header: "",
          text: "",
          link: "/musteri-belge-talep-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11732,
          title: "Müşteriden İstenen Belgeler Listele",
          header: "",
          text: "",
          link: "/musteri-belge-talep-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1175,
      title: "Müşteriden Gelen Belgeler/Eklenecek Belgeler",
      header: "",
      text: "",
      link: "/musteri-belge-kayit-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    // {
    //   id: 1176,
    //   title: "Ödemesi Onaylanmayan Kayıtlar",
    //   header: "",
    //   text: "",
    //   link: "/odemesi-onaylanmayan-kayitlari-listele",
    //   color: "default",
    //   active: false,
    //   visible: false,
    //   disabled: false,
    //   collapse: false,
    //   items: [],
    // },

    {
      id: 1177,
      title: "Teknik Okuma Yapılacak Belgeler",
      header: "",
      text: "",
      link: "/teknik-okuma-bekleyen-kayitlari-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1178,
      title: "Çevirisi Yapılan Belgeler",
      header: "",
      text: "",
      link: "/ceviri-bekleyen-kayitlari-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1179,
      title: "Çevirmen Onayı",
      header: "",
      text: "",
      link: "/ceviri-onayi-bekleyen-kayitlari-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1180,
      title: "ÜTS İçin Taramalar",
      header: "",
      text: "",
      link: "/uts-tarama-bekleyen-kayitlari-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1181,
      title: "ÜTS'ye Yüklenen Belgeler",
      header: "",
      text: "",
      link: "/uts-belgeleri-onay-bekleyen-kayitlari-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1190,
      title: "Müşteriden Ürün Bilgi/Belge İstenmesi",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 11901,
          title: "Müşteriden Ürün Bilgi/Belge İstenmesi Ekle",
          header: "",
          text: "",
          link: "/musteri-urun-belge-talep-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 11902,
          title: "Müşteriden Ürün Bilgi/Belge İstenmesi Listele",
          header: "",
          text: "",
          link: "/musteri-urun-belge-talep-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1191,
      title: "Müşteri Tarafından Eklenen/Eklenecek Belgeler",
      header: "",
      text: "",
      link: "/musteri-urun-belge-kayit-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1198,
      title: "ÜTS Durum Bilgilendirme",
      header: "",
      text: "",
      link: "/musteri-urun-belge-barkod-durum-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1196,
      title: "Müşteriye Ait Belgeler (ÜTS Belge Kaydı)",
      header: "",
      text: "",
      link: "/musteri-ticket-belgeleri-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1197,
      title: "Ürün Kaydı Belgeleri",
      header: "",
      text: "",
      link: "/musteri-urun-ticket-belgeleri-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1201,
      title: "Diğer Çeviri İşlemleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12011,
          title: "Diğer Çeviri İşlemleri Ekle",
          header: "",
          text: "",
          link: "/musteri-diger-islemler-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12012,
          title: "Diğer Çeviri İşlemleri Listele",
          header: "",
          text: "",
          link: "/musteri-diger-islemler-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    // {
    //   id: 1202,
    //   title: "Müşteri Diğer İşlemler Onay",
    //   header: "",
    //   text: "",
    //   link: "/musteri-diger-islemler-onay-listele",
    //   color: "default",
    //   active: false,
    //   visible: false,
    //   disabled: false,
    //   collapse: false,
    //   items: [],
    // },

    {
      id: 1203,
      title: "Diğer İşlemler Belgeleri",
      header: "",
      text: "",
      link: "/diger-islemler-ticket-belgeleri-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1236,
      title: "Döküm Sabitleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12361,
          title: "Döküm Sabitleri Ekle",
          header: "",
          text: "",
          link: "/dokum-sabitleri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12362,
          title: "Döküm Sabitleri Listele",
          header: "",
          text: "",
          link: "/dokum-sabitleri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1212,
      title: "Döküm Maliyet",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12121,
          title: "Döküm Maliyet Ekle",
          header: "",
          text: "",
          link: "/dokum-maliyet-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12122,
          title: "Döküm Maliyet Listele",
          header: "",
          text: "",
          link: "/dokum-maliyet-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1237,
      title: "TPU Sabitleri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12371,
          title: "TPU Sabitleri Ekle",
          header: "",
          text: "",
          link: "/tpu-sabitleri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12372,
          title: "TPU Sabitleri Listele",
          header: "",
          text: "",
          link: "/tpu-sabitleri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1213,
      title: "TPU Maliyet",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12131,
          title: "TPU Maliyet Ekle",
          header: "",
          text: "",
          link: "/tpu-maliyet-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12132,
          title: "TPU Maliyet Listele",
          header: "",
          text: "",
          link: "/tpu-maliyet-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1238,
      title: "Üretim Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12381,
          title: "Üretim Türü Ekle",
          header: "",
          text: "",
          link: "/uretim-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12382,
          title: "Üretim Türü Listele",
          header: "",
          text: "",
          link: "/uretim-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1232,
      title: "Üretim Planlama",
      header: "",
      text: "",
      link: "/uretim-planlama",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1250,
      title: "Üretim Takibi",
      header: "",
      text: "",
      link: "/uretim-takibi-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1280,
      title: "Üretim Takibi (Sevk Edilenler)",
      header: "",
      text: "",
      link: "/uretim-takibi-listele-2",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1233,
      title: "Satınalma",
      header: "",
      text: "",
      link: "/satin-alma",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1251,
      title: "Satınalma İş Emri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12511,
          title: "Satınalma İş Emri Ekle",
          header: "",
          text: "",
          link: "/satin-alma-is-emri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12512,
          title: "Satınalma İş Emri Listele",
          header: "",
          text: "",
          link: "/satin-alma-is-emri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1253,
      title: "Yetkili Satınalma İş Emri",
      header: "",
      text: "",
      link: "/yetkili-satin-alma-is-emri",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1234,
      title: "Kalıphane",
      header: "",
      text: "",
      link: "/kaliphane",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1235,
      title: "Üretim",
      header: "",
      text: "",
      link: "/uretim",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1286,
      title: "Üretim Arşivi",
      header: "",
      text: "",
      link: "/uretim-arsivi",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1239,
      title: "Sevkiyat",
      header: "",
      text: "",
      link: "/sevkiyat",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1219,
      title: "Model",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12191,
          title: "Model Ekle",
          header: "",
          text: "",
          link: "/makine-model-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12192,
          title: "Model Listele",
          header: "",
          text: "",
          link: "/makine-model-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1220,
      title: "Makine Durum",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12201,
          title: "Makine Durum Ekle",
          header: "",
          text: "",
          link: "/makine-durum-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12202,
          title: "Makine Durum Listele",
          header: "",
          text: "",
          link: "/makine-durum-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1222,
      title: "Makine",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12221,
          title: "Makine Ekle",
          header: "",
          text: "",
          link: "/yeni-makine-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12222,
          title: "Makine Listele",
          header: "",
          text: "",
          link: "/yeni-makine-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1035,
      title: "Raporlar",
      header: "",
      text: "",
      link: "/raporlar",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1083,
      title: "İş Raporları",
      header: "",
      text: "",
      link: "/is-raporlari",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1274,
      title: "Raporlar",
      header: "",
      text: "",
      link: "/raporlar-nestle",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1271,
      title: "GMail API Bilgileri",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12711,
          title: "GMail API Bilgileri Ekle",
          header: "",
          text: "",
          link: "/gmail-api-bilgileri-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12712,
          title: "GMail API Bilgileri Listele",
          header: "",
          text: "",
          link: "/gmail-api-bilgileri-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1273,
      title: "GMail API Engellenenler",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12731,
          title: "GMail API Engellenenler Ekle",
          header: "",
          text: "",
          link: "/gmail-api-engellenenler-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12732,
          title: "GMail API Engellenenler Listele",
          header: "",
          text: "",
          link: "/gmail-api-engellenenler-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1269,
      title: "E-Mail Görev",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12691,
          title: "E-Mail Görev Ekle",
          header: "",
          text: "",
          link: "/e-mail-gorev-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12692,
          title: "E-Mail Görev Listele",
          header: "",
          text: "",
          link: "/e-mail-gorev-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1270,
      title: "Personel E-Mail Görev",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12701,
          title: "Personel E-Mail Görev Ekle",
          header: "",
          text: "",
          link: "/personel-e-mail-gorev-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12702,
          title: "Personel E-Mail Görev Listele",
          header: "",
          text: "",
          link: "/personel-e-mail-gorev-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1266,
      title: "Kargo Firmaları",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12661,
          title: "Kargo Firmaları Ekle",
          header: "",
          text: "",
          link: "/kargo-firmalari-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12662,
          title: "Kargo Firmaları Listele",
          header: "",
          text: "",
          link: "/kargo-firmalari-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1267,
      title: "Kargo Türü",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12671,
          title: "Kargo Türü Ekle",
          header: "",
          text: "",
          link: "/kargo-turu-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12672,
          title: "Kargo Türü Listele",
          header: "",
          text: "",
          link: "/kargo-turu-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1268,
      title: "Kargo Takip",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12681,
          title: "Kargo Takip Ekle",
          header: "",
          text: "",
          link: "/kargo-takip-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12682,
          title: "Kargo Takip Listele",
          header: "",
          text: "",
          link: "/kargo-takip-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1275,
      title: "Broşür Kategori",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12751,
          title: "Broşür Kategori Ekle",
          header: "",
          text: "",
          link: "/brosur-kategori-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12752,
          title: "Broşür Kategori Listele",
          header: "",
          text: "",
          link: "/brosur-kategori-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1276,
      title: "Broşür",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12761,
          title: "Broşür Ekle",
          header: "",
          text: "",
          link: "/brosur-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12762,
          title: "Broşür Listele",
          header: "",
          text: "",
          link: "/brosur-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1277,
      title: "Dosya",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12771,
          title: "Dosya Ekle",
          header: "",
          text: "",
          link: "/dosya-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12772,
          title: "Dosya Listele",
          header: "",
          text: "",
          link: "/dosya-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1004,
      title: "Takvim",
      header: "",
      text: "",
      link: "/takvim",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1081,
      title: "Takvim Kullanıcı",
      header: "",
      text: "",
      link: "/takvim-kullanici",
      color: "default",
      active: true,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 1034,
      title: "Hatırlatma",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10341,
          title: "Hatırlatma Ekle",
          header: "",
          text: "",
          link: "/hatirlatma-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10342,
          title: "Hatırlatma Listele",
          header: "",
          text: "",
          link: "/hatirlatma-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1015,
      title: "Firmalar",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10151,
          title: "Firma Ekle",
          header: "",
          text: "",
          link: "/firma-ekle",
          color: "default",
          icon: "ekle",
          visible: false,
          active: false,
          disabled: false,
        },
        {
          id: 10152,
          title: "Firma Listele",
          header: "",
          text: "",
          link: "/firma-listele",
          color: "default",
          icon: "listele",
          visible: false,
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1014,
      title: "Firma Personel Grupları",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10141,
          title: "Firma Personel Grubu Ekle",
          header: "",
          text: "",
          link: "/firma-personel-grup-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10142,
          title: "Firma Personel Grubu Listele",
          header: "",
          text: "",
          link: "/firma-personel-grup-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1082,
      title: "Bölge",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10821,
          title: "Bölge Ekle",
          header: "",
          text: "",
          link: "/bolge-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10822,
          title: "Bölge Listele",
          header: "",
          text: "",
          link: "/bolge-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },
    {
      id: 1020,
      title: "Personel",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 10201,
          title: "Personel Ekle",
          header: "",
          text: "",
          link: "/personel-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 10202,
          title: "Personel Listele",
          header: "",
          text: "",
          link: "/personel-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1261,
      title: "Personel",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12611,
          title: "Personel Ekle",
          header: "",
          text: "",
          link: "/personel2-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12612,
          title: "Personel Listele",
          header: "",
          text: "",
          link: "/personel2-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1246,
      title: "İş Kazası",
      header: "",
      text: "",
      link: "#",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [
        {
          id: 12461,
          title: "İş Kazası Ekle",
          header: "",
          text: "",
          link: "/is-kazasi-ekle",
          color: "default",
          icon: "ekle",
          active: false,
          disabled: false,
        },
        {
          id: 12462,
          title: "İş Kazası Listele",
          header: "",
          text: "",
          link: "/is-kazasi-listele",
          color: "default",
          icon: "listele",
          active: false,
          disabled: false,
        },
      ],
    },

    {
      id: 1096,
      title: "Ana sayfa",
      header: "",
      text: "",
      link: "/anasayfa",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },
    {
      id: 1282,
      title: "Kullanıcı Giriş Bilgileri Listele",
      header: "",
      text: "",
      link: "/kullanici-giris-bilgileri-listele",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },
    {
      id: 1054,
      title: "Firma Bilgilerini Güncelle",
      header: "",
      text: "",
      link: "/firma-bilgilerini-guncelle",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },
    {
      id: 1040,
      title: "Kullanıcı Bilgilerini Güncelle",
      header: "",
      text: "",
      link: "/kullanici-bilgilerini-guncelle",
      color: "default",
      active: false,
      visible: false,
      disabled: false,
      collapse: false,
      items: [],
    },

    {
      id: 4,
      title: "Güvenli Çıkış",
      header: "",
      text: "",
      link: "/guvenli-cikis",
      color: "default",
      active: false,
      visible: true,
      disabled: false,
      collapse: false,
      items: [],
    },
  ];

  render() {
    const sidebarData = {
      programName: CgnMessage.programNameUpper,
      userPhoto: this.state.userPhoto,
      userName: this.state.userFullName,
      userTitle: this.state.userTitle,
      docked: false,
      pullRight: false,
      shadow: true,
      transitions: true,
      items: this.items3,
      workAccidentDay: this.state.workAccidentDay,
      cartCount: this.state.cartCount,
      remindCount: this.state.remindCount,
      orderCount: this.state.orderCount,
      orderLocationCount: this.state.orderLocationCount,
    };

    return (
      <>
        {localStorage.getItem("token") ? (
          <>
            {/* <Api /> */}
            <CgnLeftSidebar
              style={{ backgroundColor: "red" }}
              data={sidebarData}
            />
            {
              moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") < localStorage.getItem("startDateTime") &&
              moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") < localStorage.getItem("endDateTime") &&
              (<CgnAlert
                color="danger"
                title="Planlı Bakım Çalışması"
                text={"Sizlere daha iyi hizmet verebilmek için " + localStorage.getItem("startDate") + " " + localStorage.getItem("startTime") + " ile " + localStorage.getItem("endDate") + " " + localStorage.getItem("endTime") + " tarihleri ve saatleri arasında planlı bakım çalışması yapılacaktır!"}
                className="planli-bakim-calismasi"
              />)
            }
          </>
        ) : (
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/bilgilerimi-unuttum" component={ForgotInformation} />
            <Route path="/sifre-yenile" component={RefreshPassword} />
            <Route path="/teklif-onay" component={OfferApproved} />
            <Route path="/teklif-iptal" component={OfferCanceled} />
            <Route path="/teklif-onay2" component={OfferNewApproved} />
            <Route path="/teklif-iptal2" component={OfferNewCanceled} />
            <Route path="/teklif-onay3" component={OfferSecondApproved} />
            <Route path="/teklif-iptal3" component={OfferSecondCanceled} />
            <Route
              path="/musteri-bilgilendirme-sistemi"
              component={CustomerTicketDetail}
            />
            <Route
              path="/musteri-urun-bilgilendirme-sistemi"
              component={CustomerProductTicketDetail}
            />
            <Route
              path="/customer-information-system"
              component={EnCustomerTicketDetail}
            />
            <Route
              path="/customer-product-information-system"
              component={EnCustomerProductTicketDetail}
            />
            <Route
              path="/diger-islemler-bilgilendirme-sistemi"
              component={OtherTicketDetail}
            />
            <Route
              path="/other-processes-information-system"
              component={EnOtherTicketDetail}
            />
            <Route path="/teklif-onay4" component={OfferSieveApproved} />
            <Route path="/teklif-iptal4" component={OfferSieveCanceled} />
            <Route path="/teklif-onay5" component={OfferMachineApproved} />
            <Route path="/teklif-iptal5" component={OfferMachineCanceled} />
            <Route
              path="/bilgilendirme-email-gonderi-iptali"
              component={SendingEmailToCustomer}
            />
            <Route
              path="/bilgilendirme-email-gonderi-iptali-2"
              component={SendingEmailToCustomer2}
            />
            <Route component={NotFound} />
          </Switch>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.loginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(loginAction.login, dispatch),
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
