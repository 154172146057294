import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  localFilePath,
  URL,
  
  GetMonthFirstDateYearElkapol,
  GetMonthLastDateYear,
  GetMonthFirstDate2YearElkapol,
  GetMonthLastDate2Year,
  CustomerList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class OfferSieveProductEntryList extends Component {
  state = {
    pageTitle: "Satınalma Listele",
    //pageAddLink: "teklif3-ekle",
    //pageUpdateLink: "teklif3-guncelle",
    pageDetailLink: "satin-alma-detay",
    pageListLink: "satin-alma",
    breadcrumb: [{ text: "Satınalma", link: "#" }],
    isLoading: false,
    isDeleted: false,
    offerData: [],
    urlSplit: [],
    discountTotal: 0,
    totalPiece: 0,
    productItemCount: 0,
    discountTotalDolar: 0,
    totalPieceDolar: 0,
    productItemCountDolar: 0,
    discountTotalEuro: 0,
    totalPieceEuro: 0,
    productItemCountEuro: 0,
    statusAll: 0,
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
    status5: 0,
    status6: 0,
    guid: "",
    replaceLink: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });

    var firstDate = GetMonthFirstDateYearElkapol();
    var lastDate = GetMonthLastDateYear();
    var firstDate2 = GetMonthFirstDate2YearElkapol();
    var lastDate2 = GetMonthLastDate2Year();

    if (localStorage.getItem("firstDateOfferSieveProductEntry")) {
      firstDate = localStorage.getItem("firstDateOfferSieveProductEntry");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateOfferSieveProductEntry")) {
      lastDate = localStorage.getItem("lastDateOfferSieveProductEntry");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidOfferSieveProductEntry")) {
      this.setState({
        customerGuid: localStorage.getItem(
          "customerGuidOfferSieveProductEntry"
        ),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDateYearElkapol();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem(
      "firstDateOfferSieveProductEntry",
      this.state.firstDate
    );
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDateYear();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateOfferSieveProductEntry", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem(
      "customerGuidOfferSieveProductEntry",
      this.state.customerGuid
    );
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    if (this.state.urlSplit[1] === "musteri-detay") {
      URLParam = "/offersieves/getlistforcustomer/" + this.state.urlSplit[2];
      this.setState({
        detail: true,
      });
    } else {
      var urlSplitX =
        this.state.urlSplit.length > 2 ? this.state.urlSplit[2] : 0;
      this.setState({
        urlSplitX: urlSplitX,
      });
      URLParam =
        "/offersieves/getlisttostatusproductentry/" +
        this.state.firstDate +
        "/" +
        this.state.lastDate +
        "/" +
        this.state.customerGuid;
    }

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        if (this.state.detail === false) {
          this.setState({
            offerData: response.data.offerSieveDto,
            status1: response.data.status1,
            status2: response.data.status2,
            status3: response.data.status3,
            status4: response.data.status4,
            status5: response.data.status5,
            status6: response.data.status6,
            statusAll: response.data.statusAll,
          });
        } else {
          this.setState({
            offerData: response.data,
          });
        }
        var discountTotal = 0;
        var totalPiece = 0;
        var productItemCount = 0;
        var discountTotalDolar = 0;
        var totalPieceDolar = 0;
        var productItemCountDolar = 0;
        var discountTotalEuro = 0;
        var totalPieceEuro = 0;
        var productItemCountEuro = 0;
        this.state.offerData.forEach((element) => {
          if (element.currencyType === 1) {
            discountTotal += element.total;
            totalPiece += element.totalPiece;
            productItemCount += element.productItemCount;
          } else if (element.currencyType === 2) {
            discountTotalDolar += element.total;
            totalPieceDolar += element.totalPiece;
            productItemCountDolar += element.productItemCount;
          } else if (element.currencyType === 3) {
            discountTotalEuro += element.total;
            totalPieceEuro += element.totalPiece;
            productItemCountEuro += element.productItemCount;
          }
        });
        this.setState({
          discountTotal: discountTotal,
          totalPiece: totalPiece,
          productItemCount: productItemCount,
          discountTotalDolar: discountTotalDolar,
          totalPieceDolar: totalPieceDolar,
          productItemCountDolar: productItemCountDolar,
          discountTotalEuro: discountTotalEuro,
          totalPieceEuro: totalPieceEuro,
          productItemCountEuro: productItemCountEuro,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleProductOutAlert = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.offerQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleOfferToProductOut(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/offersieves/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleExcel = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var urlSplitX = this.state.urlSplit.length > 2 ? this.state.urlSplit[2] : 0;
    this.setState({
      urlSplitX: urlSplitX,
    });
    var URLParam = "";
    URLParam =
      "/offersieves/print/" +
      urlSplitX +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleOfferToProductOut = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .post(URL + "/offersieves/offertoproductout/" + guid, "", config)
      .then((response) => {
        if (response.data) {
          window.open("/satis2-guncelle/" + response.data, "_self");
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleCopyOffer = async (guid) => {
    confirmAlert({
      title: CgnMessage.copyQuestionTitle,
      message: CgnMessage.copyQuestionMessage,
      buttons: [
        {
          label: CgnMessage.copyQuestionYes,
          onClick: () => this.copyItem(guid),
        },
        {
          label: CgnMessage.copyQuestionNo,
        },
      ],
    });
  };

  async copyItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .post(URL + "/offersieves/newofferafterrevision/" + guid, "", config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfPrintEn = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrintEn/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfSendMailEn = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMailEn/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "100px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={
              this.state.urlSplit[1] !== "musteri-detay"
                ? this.state.pageTitle
                : ""
            }
            //addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
            //excelLink={onClick => this.handleExcel()}
          />
        )}
        <Row className="offerList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              {/* {this.state.detail === false && (
                <div className="d-inline-block mb-3">
                  <a
                    href={`/${this.state.pageListLink}/${1}`}
                    style={{ backgroundColor: "red" }}
                    className={
                      this.state.urlSplitX === "1"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Beklemede: ${this.state.status1}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${2}`}
                    style={{ backgroundColor: "#29B237" }}
                    className={
                      this.state.urlSplitX === "2"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Onaylandı: ${this.state.status2}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${3}`}
                    style={{ backgroundColor: "#FFC433" }}
                    className={
                      this.state.urlSplitX === "3"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`İptal: ${this.state.status3}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${4}`}
                    style={{ backgroundColor: "#FF6E33" }}
                    className={
                      this.state.urlSplitX === "4"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Revize: ${this.state.status4}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${5}`}
                    style={{ backgroundColor: "#0679AF" }}
                    className={
                      this.state.urlSplitX === "5"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Satış: ${this.state.status5}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${6}`}
                    style={{ backgroundColor: "#7C4811" }}
                    className={
                      this.state.urlSplitX === "6"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Arşiv: ${this.state.status6}`}</a>
                  <a
                    href={`/${this.state.pageListLink}`}
                    className={
                      this.state.urlSplitX === 0
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2 bg-secondary"
                    }
                  >{`Tümü: ${this.state.statusAll}`}</a>
                </div>
              )} */}
              {this.state.detail === false && (
                <Row className="filterRow">
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="firstDate"
                      label="Baş. Tarihi"
                      selected={
                        this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.firstDate2.split("/")[2] +
                                "-" +
                                this.state.firstDate2.split("/")[1] +
                                "-" +
                                this.state.firstDate2.split("/")[0]
                            )
                          : this.state.firstDate2
                      }
                      onChange={(firstDate2) =>
                        this.handleFirstDateChange(firstDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="lastDate"
                      label="Bit. Tarihi"
                      selected={
                        this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.lastDate2.split("/")[2] +
                                "-" +
                                this.state.lastDate2.split("/")[1] +
                                "-" +
                                this.state.lastDate2.split("/")[0]
                            )
                          : this.state.lastDate2
                      }
                      onChange={(lastDate2) =>
                        this.handleLastDateChange(lastDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="7">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      options={this.state.customerArr}
                    />
                  </Col>
                  <Col className="pl-2 pr-0" md="1">
                    <CgnButton
                      type="button"
                      color="primary"
                      onClick={this.getList}
                      text="Listele"
                      className="listButton"
                    />
                  </Col>
                </Row>
              )}
              <CgnDatatable
                data={this.state.offerData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Satınalma Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      if (row.customerPhone !== null) {
                        var phone = row.customerPhone,
                          maskPhone = `${phone.substring(0, 1)}  ${
                            "(" + phone.substring(1, 4) + ")"
                          } ${phone.substring(4, 7)} ${phone.substring(
                            7,
                            9
                          )} ${phone.substring(9, 11)}`;
                      }
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.offerDate}</td>
                              <th style={this.thStyle}>Sipariş No</th>
                              <td>{row.offerNumber}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td colSpan={4}>{row.customerName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td colSpan={4}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    selector: "transactionStatus",
                    width: "150px",
                    padding: "0px",
                    center: true,
                    sortable: false,
                    cell: (row) => {
                      if (
                        row.transactionStatus &&
                        row.transactionStatus3 !== 0
                      ) {
                        if (
                          row.transactionStatus === 1 &&
                          (row.isMoldShopPurchaseChange === null ||
                            row.isMoldShopPurchaseChange === false)
                        ) {
                          return (
                            <CgnBadge
                              color="secondary"
                              text="Devam Ediyor"
                              style={{ backgroundColor: "red" }}
                            />
                          );
                        } else if (
                          row.transactionStatus === 2 &&
                          (row.isMoldShopPurchaseChange === null ||
                            row.isMoldShopPurchaseChange === false)
                        ) {
                          return (
                            <CgnBadge
                              color="secondary"
                              text="Tamamlandı"
                              style={{ backgroundColor: "green" }}
                            />
                          );
                        } else if (
                          row.transactionStatus === 1 &&
                          row.isMoldShopPurchaseChange
                        ) {
                          return (
                            <CgnBadge
                              color="secondary"
                              text="Devam Ediyor"
                              style={{
                                backgroundColor: "yellow",
                                color: "black",
                              }}
                            />
                          );
                        } else if (
                          row.transactionStatus === 2 &&
                          row.isMoldShopPurchaseChange
                        ) {
                          return (
                            <CgnBadge
                              color="secondary"
                              text="Tamamlandı"
                              style={{
                                backgroundColor: "yellow",
                                color: "black",
                              }}
                            />
                          );
                        }
                      } else {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Bekliyor"
                            style={{ backgroundColor: "orange" }}
                          />
                        );
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            {/* <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (₺):{" "}
                {this.state.discountTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.discountTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.discountTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div> */}
          </Col>
        </Row>
      </>
    );
  }
}

export default OfferSieveProductEntryList;
