import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, purchaseWorkOrdersEmployee,  fotoStyle, resizeFile } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import noImg from "../../images/no-img.png";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";

class PurchaseWorkOrdersAddUpdate extends Component {
  state = {
    pageTitle: "Satınalma İş Emri Ekle",
    pageTitleAdd: "Satınalma İş Emri Ekle",
    pageTitleUpdate: "Satınalma İş Emri Güncelle",
    pageAddLink: "satin-alma-is-emri-ekle",
    pageListLink: "satin-alma-is-emri-listele",
    breadcrumb: [{ text: "Satınalma İş Emri", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    description: "",
    guid: "",
    status: 0,
    nameError: "",
    descriptionError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    purchaseWorkOrderImageDto: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/purchaseworkorders/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              purchaseWorkOrderImageDto: response.data.purchaseWorkOrderImageDto,
              name: response.data.name,
              description: response.data.description,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        purchaseWorkOrderImageDto: [],
        name: "",
        description: "",
        status: 0,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: 0,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      description: "",
      status: 0,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.name !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        purchaseWorkOrderImageDto: this.state.purchaseWorkOrderImageDto,
        name: this.state.name,
        description: this.state.description,
        status: this.state.status,
      };

      var URLParam = "/purchaseworkorders/add";
      if (this.state.guid && this.state.urlSplit.length !== 2 && this.state.urlSplit[1] === "satin-alma-is-emri-guncelle") {
        URLParam = "/purchaseworkorders/update";
        myObj = {
          guid: this.state.guid,
          purchaseWorkOrderImageDto: this.state.purchaseWorkOrderImageDto,
          name: this.state.name,
          description: this.state.description,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid && this.state.urlSplit[1] === "satin-alma-is-emri-guncelle") {
              window.location.href = "/satin-alma-is-emri-listele";
            }
            if (this.state.urlSplit[1] === "satin-alma-is-emri-ekle") {
              window.location.reload();
            }
            var modalAddedPurchaseWorkOrders = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedPurchaseWorkOrders });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    let array = this.state.purchaseWorkOrderImageDto;
    for(var i = 0; i < event.target.files.length; i++){
      const file = event.target.files[i];
      if(file.type === "image/png" || file.type === "image/jpeg"){
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        })
      }
    }
    await this.setState({purchaseWorkOrderImageDto: array});
  }

  deleteImage = (event) => {
    var index = -1;
    let array = this.state.purchaseWorkOrderImageDto;
     this.state.purchaseWorkOrderImageDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({purchaseWorkOrderImageDto: array});
          }
        }
     });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "150px",
    };

    return (
      <>
        {(this.state.urlSplit[1] === "satin-alma-is-emri-ekle" ||
          this.state.urlSplit[1] === "satin-alma-is-emri-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="12">
                    <PictureUploadMulti
                      image={this.state.purchaseWorkOrderImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Fotoğraflar"}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      alt={this.state.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Satınalma İş Emri [*]"
                      value={this.state.name}
                      placeHolder="Lütfen Satınalma iş emri girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                  </Row>
                  <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={purchaseWorkOrdersEmployee}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "satin-alma-is-emri-ekle" ||
              this.state.urlSplit[1] === "satin-alma-is-emri-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(PurchaseWorkOrdersAddUpdate);
