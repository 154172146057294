import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function companyUserGroupListReducer(state = initialState.companyUserGroup, action) {
  switch (action.type) {
    case actionTypes.COMPANY_USER_GROUP_GET_LIST_SUCCESS:
      return action.payload;
      case actionTypes.COMPANY_USER_GROUP_GET_LIST_ERROR:
        return action.payload;
    default:
      return state;
  }
}
