import React, { Component } from "react";
import { Row, Col, Card, Table, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL,  fotoStyle2, localFilePath } from "../../redux/actions/constants";
import pdfIcon from "../../images/pdfIcon.png";

class WorkInstructionDetail extends Component {
  state = {
    pageTitle: "İş Emri Detay",
    pageTitleDetail: "İş Emri Detay",
    pageAddLink: "is-emri-ekle",
    pageListLink: "is-emri-listele",
    breadcrumb: [{ text: "İş Emri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    workDate: "",
    workStartDate: "",
    workEndDate: "",
    customerName: "",
    personelName: "",
    productOutName: "",
    productCode: "",
    productName: "",
    statusChange: "",
    piece: "",
    stepData: [],
    productionData: [],
    qualityData: [],
    productData: [],
    productOutImageDto: [],
    materialProduct: "",
    materialQuality: "",
    workOrderStepName: "",
    productOutTotal: "",
    productOutDate: "",
    productOutCustomer: "",
    code: "",
    customerCode: "",
    name: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    decsription: "",
    currencyType: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/workInstructions/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            code: response.data.code,
            customerCode: response.data.customerCode,
            customerName: response.data.customerName,
            personelName: response.data.personelName,
            productName: response.data.productName,
            productCode: response.data.productCode,
            decsription: response.data.decsription,
            workDate: response.data.workDate,
            workStartDate: response.data.workStartDate,
            workEndDate: response.data.workEndDate,
            stepData: response.data.workInstructionStepDto,
            productData: response.data.productRecipeProductDto,
            productionData: response.data.productRecipeProductionDto,
            qualityData: response.data.productRecipeQualityDto,
            productOutDate: response.data.productOutDate,
            productOutTotal: response.data.productOutTotal,
            productOutCustomer: response.data.productOutCustomer,
            piece: response.data.piece,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            currencyType: response.data.currencyType,
            statusChange: response.data.statusChange,
            productOutImageDto: response.data.productOutImageDto,
            serialNumber: response.data.serialNumber,
            productRevisionNo: response.data.productRevisionNo,
            drawinRevisionNo: response.data.drawinRevisionNo,
            bomRevisionNo: response.data.bomRevisionNo,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath, "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };


  render() {
    const mainRow = {
      backgroundColor: "#9B9796",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>İş Emri Numarası</th>
                    <td colSpan="4">
                      {this.state.code}
                    </td>
                  </tr>
                  <tr>
                    <th>Müşteri İş Emri Numarası</th>
                    <td colSpan="4">
                      {this.state.customerCode === null ? "-" : this.state.customerCode}
                    </td>
                  </tr>
                  <tr>
                    <th>Seri Numarası</th>
                    <td colSpan="4">
                      {this.state.serialNumber === null ? "-" : this.state.serialNumber}
                    </td>
                  </tr>
                  <tr>
                    <th>Ürün Revizyon Numarası</th>
                    <td colSpan="4">
                      {this.state.productRevisionNo === null ? "-" : this.state.productRevisionNo}
                    </td>
                  </tr>
                  <tr>
                    <th>Çizim Revizyon Numarası</th>
                    <td colSpan="4">
                      {this.state.drawinRevisionNo === null ? "-" : this.state.drawinRevisionNo}
                    </td>
                  </tr>
                  <tr>
                    <th>Bom Revizyon Numarası</th>
                    <td colSpan="4">
                      {this.state.bomRevisionNo === null ? "-" : this.state.bomRevisionNo}
                    </td>
                  </tr>
                  <tr>
                    <th>İş Emri Tarihi</th>
                    <td colSpan="4">
                      {this.state.workDate}
                    </td>
                  </tr>

                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">
                      {this.state.customerName}
                    </td>
                  </tr>

                  <tr>
                    <th>Personel</th>
                    <td colSpan="4">
                      {this.state.personelName}
                    </td>
                  </tr>

                  <tr>
                    <th>Başlangıç Tarihi</th>
                    <td colSpan="4">
                      {this.state.workStartDate}
                    </td>
                  </tr>

                  <tr>
                    <th>Bitiş Tarihi</th>
                    <td colSpan="4">
                      {this.state.workEndDate === "01/01/1900" ? "-" : this.state.workEndDate}
                    </td>
                  </tr>

                  <tr>
                    <th>Satış</th>
                    <td colSpan="4">
                      {this.state.productOutDate + " " + this.state.productOutCustomer}
                    </td>
                  </tr>

                  <tr>
                    <th>Ürün</th>
                    <td colSpan="4">{this.state.productCode + "-" + this.state.productName}</td>
                  </tr>

                  <tr>
                    <th>Ürün Adedi</th>
                    <td colSpan="4">{this.state.piece}</td>
                  </tr>

                  <tr>
                    <th>Durum</th>
                    <td colSpan="4">{this.state.statusChange === 1 ? "Beklemede" :
                      this.state.statusChange === 2 ? "İşlemde" :
                        this.state.statusChange === 3 ? "İptal Edildi" :
                          this.state.statusChange === 4 ? "Revize" :
                            this.state.statusChange === 5 ? "Tamamlandı" : "-"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.productData !== null &&
                this.state.productData.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.productData !== null && self.productData.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Kullanılan Ürünler</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Ürün</th>
                        <th className="text-center">Miktar</th>
                        <th>Birim</th>
                      </tr>
                      {this.state.productData.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.productCode + "-" + item.productName}</td>
                            <td className="text-center">{item.piece}</td>
                            <td> {item.unit === "NIU"
                              ? "Adet"
                              : item.unit === "KGM"
                                ? "kg"
                                : item.unit === "GRM"
                                  ? "gr"
                                  : item.unit === "MTR"
                                    ? "Metre"
                                    : item.unit === "LTR"
                                      ? "Litre"
                                      : item.unit === "PA"
                                        ? "Paket"
                                        : item.unit === "BX"
                                          ? "Kutu"
                                          : item.unit === "CMT"
                                            ? "cm"
                                            : item.unit === "MTQ"
                                              ? "m3"
                                              : item.unit === "MTK"
                                                ? "m2"
                                                : item.unit === "ROLL"
                                                  ? "Rulo"
                                                  : item.unit === "SET"
                                                    ? "Set"
                                                    : item.unit === "CMQ"
                                                      ? "cm3"
                                                      : item.unit === "SAA"
                                                        ? "Saat"
                                                        : "-"}</td>
                          </tr>
                        );
                      })}
                    </Table>
                  </>
                )}

              {this.state.productionData !== null &&
                this.state.productionData.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.productionData !== null && self.productionData.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Üretim Alet veya Cihazları</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Alet veya Cihaz</th>
                      </tr>
                      {this.state.productionData.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.materialName}</td>
                          </tr>
                        );
                      })}
                    </Table>
                  </>
                )}

              {this.state.qualityData !== null &&
                this.state.qualityData.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.qualityData !== null && self.qualityData.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Kalite Alet veya Cihazları</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Alet veya Cihaz</th>
                      </tr>
                      {this.state.qualityData.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.materialName}</td>
                          </tr>
                        );
                      })}
                    </Table>
                  </>
                )}

              {this.state.stepData !== null &&
                this.state.stepData.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.stepData !== null && self.stepData.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">İş Emri Adımları</h2>
                    </div>


                    {this.state.stepData.map(function (item) {
                      return (
                        <>
                          <Table className="table table-responsive table-hover table-product2">
                            <tr style={mainRow}>
                              <th>İş Emri Adımı</th>
                            </tr>
                            <tr style={subRow}>
                              <td>{item.workOrderStepName}</td>
                            </tr>
                            <tr style={subRow}>
                              <td>{item.personName} / {item.personWorkInstructionStatus ? "Görünsün" : "Görünmesin!"}</td>
                            </tr>
                            <tr style={subRow}>
                              <td>
                                {item.stepImageDto && item.stepImageDto.map(function (item1) {
                                  return (
                                    <>
                                      <div className="pl-2">
                                        <Button
                                          onClick={() => self1.fileClick(item1.image)}
                                          className="modalButtonDetail"
                                        >
                                          <img
                                            src={pdfIcon}
                                            style={fotoStyle2}
                                            alt={item1.imageName}
                                          />
                                        </Button>
                                        <p style={{ maxWidth: "150px" }}>{item1.imageName}</p>
                                      </div>
                                    </>
                                  )
                                })}
                              </td>
                            </tr>
                          </Table >
                        </>
                      );
                    })}

                  </>
                )}

              {this.state.productOutImageDto &&
                this.state.productOutImageDto.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.productOutImageDto !== null && self.productOutImageDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Dosyalar</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Dosya</th>
                      </tr>
                      <Row>
                        {this.state.productOutImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-2">
                                <Button
                                  onClick={() => self1.fileClick(item.image)}
                                  className="modalButtonDetail"
                                >
                                  <img
                                    src={pdfIcon}
                                    style={fotoStyle2}
                                    alt={item.imageName}
                                  />
                                </Button>
                                <p style={{ maxWidth: "150px" }}>{item.imageName}</p>
                              </div>

                            </>
                          );
                        })}
                      </Row>

                    </Table>
                  </>

                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row >
      </>
    );
  }
}

export default WorkInstructionDetail;
