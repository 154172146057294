import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import {
  URL,
  fotoStyle,
  
  UserOperationClaimControl
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import { withRouter } from "react-router-dom";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";

class SuitabilityFormAddUpdate extends Component {
  state = {
    pageTitle: "Uygunluk Formu Ekle",
    pageTitleAdd: "Uygunluk Formu Ekle",
    pageTitleUpdate: "Uygunluk Formu Güncelle",
    pageAddLink: "uygunluk-formu-ekle",
    pageListLink: "uygunluk-formu-listele",
    breadcrumb: [{ text: "Uygunluk Formu", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productArr: [],
    productGuid: "",
    workInstructionArr: [],
    customerDocumentDto: [],
    supplierDocumentDto: [],
    suitabilityDocumentDto: [],
    dispatchPiece: "",
    suitabilityDate: "",
    suitabilityNo: "",
    serialNo: "",
    classAndCategory: "",
    observationControl: "",
    electricalControl: "",
    packagingControl: "",
    opticalControl: "",
    description: "",
    workInstructionGuid: "",
    customerGuid: "",
    personelGuid: "",
    productOutGuid: "",
    guid: "",
    status: true,
    isNull: false,
    modalButtonIdx: 0,
    customerArr: [],
    productOutArr: [],
    isTrue: false,   
    productError: "",
    alertErrorMessage: "",
    workInstructionError: "",
    suitabilityDateError: "",
    suitabilityNoError: "",
    suitabilityDocumentDtoError: "",
    customerDocumentDtoError: "",
    supplierDocumentDtoError: "",
    message: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    productModalPlus: false,
    materialModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      productModalPlus: await UserOperationClaimControl(1029),
      materialModalPlus: await UserOperationClaimControl(1133),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/workInstructions/getlistsuitabilityform", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.customerName}`,
          };
        });
        this.setState({
          workInstructionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
      axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
      axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
     
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "uygunluk-formu-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/suitabilityForms/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              productGuid: response.data.productGuid,
              workInstructionGuid: response.data.workInstructionGuid,
              customerGuid: response.data.customerGuid,
              productOutGuid: response.data.productOutGuid,
              personelGuid: response.data.personelGuid,
              suitabilityDate: response.data.suitabilityDate,
              suitabilityNo: response.data.suitabilityNo,
              dispatchPiece: response.data.dispatchPiece,
              serialNo: response.data.serialNo,
              classAndCategory: response.data.classAndCategory,
              observationControl: response.data.observationControl,
              electricalControl: response.data.electricalControl,
              opticalControl: response.data.opticalControl,
              packagingControl: response.data.packagingControl,
              description: response.data.description,
              customerDocumentDto: response.data.customerDocumentDto,
              supplierDocumentDto: response.data.supplierDocumentDto,
              suitabilityDocumentDto: response.data.suitabilityDocumentDto,
              isLoading: false
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        image: "",
        productGuid: "",
        productRecipeProductDto: [],
        status: true,
        counter: -1,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  addModalProduct2 = async (idx) => {
    await this.setState({
      isOpenModalProduct2: !this.state.isOpenModalProduct2,
      modalButtonIdx: idx,
    });
  };

  addModalProductMaterial = async (idx) => {
    await this.setState({
      isOpenModalProductMaterial: !this.state.isOpenModalProductMaterial,
      modalButtonIdx: idx,
    });
  };

  addModalQualityMaterial = async (idx) => {
    await this.setState({
      isOpenModalQualityMaterial: !this.state.isOpenModalQualityMaterial,
      modalButtonIdx: idx,
    });
  };

  uploadedImage2 = async (event) => {
    let array = this.state.suitabilityDocumentDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
      if(file.type === "application/pdf"){
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({suitabilityDocumentDto: array});
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.suitabilityDocumentDto;
     this.state.suitabilityDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({suitabilityDocumentDto: array});
          }
        }
     });
  };


  uploadedImage3 = async (event) => {
    let array = this.state.supplierDocumentDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
      if(file.type === "application/pdf"){
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({supplierDocumentDto: array});
  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.supplierDocumentDto;
     this.state.supplierDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({supplierDocumentDto: array});
          }
        }
     });
  };

  uploadedImage4 = async (event) => {
    let array = this.state.customerDocumentDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
      if(file.type === "application/pdf"){
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({customerDocumentDto: array});
  };

  deleteImage4 = (event) => {
    var index = -1;
    let array = this.state.customerDocumentDto;
     this.state.customerDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({customerDocumentDto: array});
          }
        }
     });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {

  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

   async handleWorkInstructionSelectChange(option){
   if(option){
    this.setState({
      workInstructionGuid: option.value,
      workInstructionError: "",
      isLoading: true
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios 
    .get(URL + "/workInstructions/get/" + option.value, config)    
    .then((response) => {
      if(response.data.guid){
        this.setState({
           productGuid: response.data.productGuid,
           productOutGuid: response.data.productOutGuid,
           customerGuid: response.data.customerGuid,
           personelGuid: response.data.personelGuid,
           workInstructionCode: response.data.code,
           isLoading: false
        });
      }
    })
   }
   else{
    this.setState({
      workInstructionGuid: "",
      productGuid: "",
      customerGuid: "",
      personelGuid: "",
      productOutGuid: "",
      workInstructionCode: "",
      workInstructionError: CgnMessage.alertErrorMessage,
    });
   }
  }


  handleSerialNoTextBoxChange(e) {
    this.setState({
      serialNo: e.target.value,
      serialNoError: "",
      alertErrorMessage: "",
    });
  }

  handleDispatchPieceTextBoxChange(e) {
    this.setState({
      dispatchPiece: parseFloat(e.target.value),
      dispatchPieceError: "",
      alertErrorMessage: "",
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSuitabilityDateChange(suitabilityDate){
    if(suitabilityDate){
      this.setState({
        suitabilityDate: suitabilityDate,
        suitabilityDateError: ""
      });
    }
    else{
      this.setState({
        suitabilityDate: "",
        suitabilityDateError: CgnMessage.alertErrorMessage,
      })
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      workInstructionErrorError: "",
      suitabilityDateError: "",
      suitabilityNoError: "",
    });
    if (this.state.workInstructionGuid === "") {
      this.setState({
        workInstructionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.suitabilityDate === "") {
      this.setState({
        suitabilityDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.suitabilityNo === "") {
      this.setState({
        suitabilityNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  
    if (this.state.workInstructionGuid !== "" &&
        this.state.suitabilityDate !== "" &&
        this.state.suitabilityNo !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
  
      var myObj = {
        workInstructionGuid: this.state.workInstructionGuid,
        suitabilityDate: this.state.suitabilityDate,
        suitabilityNo: this.state.suitabilityNo,
        dispatchPiece: this.state.dispatchPiece === "" ? 0 : parseFloat(this.state.dispatchPiece),
        serialNo: this.state.serialNo,
        classAndCategory: this.state.classAndCategory,
        electricalControl: this.state.electricalControl,
        observationControl: this.state.observationControl,
        packagingControl: this.state.packagingControl,
        description: this.state.description,
        opticalControl: this.state.opticalControl,
        suitabilityDocumentDto: this.state.suitabilityDocumentDto,
        supplierDocumentDto: this.state.supplierDocumentDto,
        customerDocumentDto: this.state.customerDocumentDto,
      };
      var URLParam = "/suitabilityForms/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "uygunluk-formu-guncelle"
      ) {
        URLParam = "/suitabilityForms/update";
        myObj = {
          guid: this.state.guid,
          workInstructionGuid: this.state.workInstructionGuid,
          suitabilityDate: this.state.suitabilityDate,
          suitabilityNo: this.state.suitabilityNo,
          dispatchPiece: this.state.dispatchPiece === "" ? 0 : parseFloat(this.state.dispatchPiece),
          serialNo: this.state.serialNo,
          classAndCategory: this.state.classAndCategory,
          electricalControl: this.state.electricalControl,
          observationControl: this.state.observationControl,
          packagingControl: this.state.packagingControl,
          description: this.state.description,
          opticalControl: this.state.opticalControl,
          suitabilityDocumentDto: this.state.suitabilityDocumentDto,
          supplierDocumentDto: this.state.supplierDocumentDto,
          customerDocumentDto: this.state.customerDocumentDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.urlSplit[1] === "uygunluk-formu-ekle") {
              window.location.reload();
            }
            if (this.state.guid && this.state.urlSplit[1] === "uygunluk-formu-guncelle") {
              window.location.href = "/uygunluk-formu-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "uygunluk-formu-ekle" ||
          this.state.urlSplit[1] === "uygunluk-formu-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                 <Col lg="3">
                 <CgnDatePicker
                      name="suitabilityDate"
                      label="Tarih [*]"
                      selected={
                        this.state.suitabilityDate &&
                        this.state.suitabilityDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.suitabilityDate.split("/")[2] +
                                "-" +
                                this.state.suitabilityDate.split("/")[1] +
                                "-" +
                                this.state.suitabilityDate.split("/")[0]
                            )
                          : this.state.suitabilityDate
                      }
                      onChange={(suitabilityDate) =>
                        this.handleSuitabilityDateChange(suitabilityDate)
                      }
                      error={this.state.suitabilityDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                 </Col>
                 <Col lg="3">
                   <CgnTextbox
                   type="text"
                   name="suitabilityNo"
                   label="Kalite Uygunluk Numarası [*]"
                   value={this.state.suitabilityNo}
                   placeHolder="Lütfen kalite uygunluk no girin..."
                   maxLength="100"
                   autoComplete="off"
                   onChange={this.handleChange}
                   error={this.state.suitabilityNoError}
                 />
                 </Col>
                 <Col lg="3">
                 <CgnTextbox
                   type="text"
                   name="workInstructionCode"
                   label="İş Emri Numarası [*]"
                   value={this.state.workInstructionCode}
                   placeHolder="Lütfen iş emri no girin..."
                   maxLength="100"
                   autoComplete="off"
                   onChange={this.handleChange}
                   error={this.state.wrokInstructionCodeError}
                   readOnly={true}
                 />
                 </Col>
                 <Col lg="3">
                 <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      placeholder="Lütfen iş emri seçin..."
                      selectValue={this.state.workInstructionGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleWorkInstructionSelectChange(option)}
                      options={this.state.workInstructionArr}
                      error={this.state.workInstructionError}
                       />
                 </Col>
                </Row>
                <Row>
                  <Col lg="6">
                  <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                          disabled={true}
                        />        
                  </Col>
                  <Col lg="6">
                  <CgnReactSelect
                          name="productOutGuid"
                          label="Satış [*]"
                          placeholder="Satış seçiniz..."
                          selectValue={this.state.productOutGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productOutArr}
                          error={this.state.customerError}
                          disabled={true}
                        />        
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Ürün seçiniz..."
                          selectValue={this.state.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productArr}
                          error={this.state.productError}
                          disabled={true}
                        />                  
                  </Col>
                  <Col lg="4">
                  <CgnTextbox
                      type="number" 
                      name="dispatchPiece"
                      label="Sevk Edilecek Ürün Miktarı"
                      isMulti={false}
                      value={this.state.dispatchPiece}
                      placeHolder="Lütfen sevk edilecek ürün miktarı girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleDispatchPieceTextBoxChange(e)}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                  <CgnTextbox
                        name="serialNo"
                        label="Seri No"
                        value={this.state.serialNo}
                        onChange={(e) =>
                          this.handleSerialNoTextBoxChange(e)
                        }
                        placeHolder="Lütfen seri no girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={this.state.productPieceError}
                      />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                  <ImageUploadMulti
                      images={this.state.suitabilityDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Atıf Yapılan Standart Kural veya Dökümanlar"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                  <CgnTextbox
                      type="text"
                      name="classAndCategory"
                      label="Sınıf ve Kategorisi"
                      value={this.state.classAndCategory}
                      placeHolder="Lütfen sınıf ve kategorisi girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="observationControl"
                      label="Gözlem Denetimi"
                      value={this.state.observationControl}
                      placeHolder="Lütfen gözlem denetimi girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="electricalControl"
                      label="Elektriksel Denetim"
                      value={this.state.electricalControl}
                      placeHolder="Lütfen elektriksel denetim girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="opticalControl"
                      label="Boyutsal-Optik-Mekanik Ölçümler"
                      value={this.state.opticalControl}
                      placeHolder="Lütfen boyutsal-optik-mekanik ölçümler girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="packagingControl"
                      label="Ambalaj,Paketleme,Taşıma,Nakliye ve Depolama"
                      value={this.state.packagingControl}
                      placeHolder="Lütfen ambalaj,paketleme,taşıma,nakliye ve depolama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                  <ImageUploadMulti
                      images={this.state.supplierDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Farklı Tedarikçiden Alınan Uygunluk Belgeleri"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                  <ImageUploadMulti
                      images={this.state.customerDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Müşteri Tarafından Gelen (D.F) ve Yapılan İşlemler"}
                      onChange={this.uploadedImage4}
                      onDelete={this.deleteImage4}
                      error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "uygunluk-formu-ekle" ||
              this.state.urlSplit[1] === "uygunluk-formu-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(SuitabilityFormAddUpdate);
