import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  UserOperationClaimControl,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  ProductList,
  CustomerList,
  SupplierList,
  optionStatuses,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import ProductAddUpdate from "../product/ProductAddUpdate";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";

import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";

class PolicyAddUpdate extends Component {
  state = {
    pageTitle: "Poliçe Ekle",
    pageTitleAdd: "Poliçe Ekle",
    pageTitleUpdate: "Poliçe Güncelle",
    pageAddLink: "police-ekle",
    pageListLink: "police-listele",
    breadcrumb: [{ text: "Poliçe", link: "#" }],
    isLoading: false,
    urlSplit: [],
    guid: "",
    productDataError: "",
    productGuid: "00000000-0000-0000-0000-000000000000",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    supplierGuid: "00000000-0000-0000-0000-000000000000",
    status: true,
    alertErrorMessage: "",
    isDeleted: false,
    isOpenModalProduct: false,
    modalButtonIdx: 0,
    isNull: false,
    isOpenModalSupplier: false,
    isOpenModalCustomer: false,
    controlProduct: false,
    counter: -1,
    productData: [],
    productError: "",
    supplierError: "",
    customerError: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    plateNo: "",
    multipleProductArr: [],
    productArr: [],
    customerArr: [],
    suppierArr: [],
    policyDate: "",
    nextPolicyDate: "",
    policyNo: "",
    documentNo: "",
    policyDateError: "",
    nextPolicyDateError: "",
    policyNoError: "",
    documentNoError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    locationModalPlus: false,
    productModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
      productModalPlus: await UserOperationClaimControl(1029),
      supplierModalPlus: await UserOperationClaimControl(1038),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDatePolicy")) {
      firstDate = localStorage.getItem("firstDatePolicy");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDatePolicy")) {
      lastDate = localStorage.getItem("lastDatePolicy");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    await ProductList().then(function (data) {
      self.setState({
        productArr: data,
      });
    });

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    await SupplierList().then(function (data) {
      self.setState({
        supplierArr: data,
      });
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    if (urlSplit.length === 3 && urlSplit[1] === "police-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/policies/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              supplierGuid: response.data.supplierGuid,
              customerGuid: response.data.customerGuid,
              productGuid: response.data.productGuid,
              plateNo: response.data.plateNo,
              policyDate: response.data.policyDate,
              nextPolicyDate: response.data.nextPolicyDate,
              policyNo: response.data.policyNo,
              documentNo: response.data.documentNo,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        customerGuid: "",
        productGuid: "",
        supplierGuid: "",
        policyNo: "",
        documentNo: "",
        nextPolicyDate: "",
        policyDate: "",
        productData: [],
        status: true,
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data
            ) {
              this.setState({
                productGuid: element.guid,
                productError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalSupplier &&
      this.props.location.modalAddedSupplier !== undefined &&
      this.props.location.modalAddedSupplier.isModal !== undefined &&
      this.props.location.modalAddedSupplier.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/suppliers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            supplierArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSupplier.data &&
              element.name === this.props.location.modalAddedSupplier.data
            ) {
              this.setState({
                supplierGuid: element.guid,
                supplierError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSupplier.isModal = true;
      this.setState({
        isOpenModalSupplier: false,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      policyNoError: "",
      alertErrorMessage: "",
    });
    if (name === "policyNo" && value === "") {
      this.setState({
        policyNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleChangeDocument = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      documentNoError: "",
      alertErrorMessage: "",
    });
    if (name === "documentNo" && value === "") {
      this.setState({
        documentNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  handleSupplierSelectChange(option) {
    if (option) {
      this.setState({
        supplierGuid: option.value,
        supplierError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        supplierGuid: "",
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  handlePlateChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      plateNoError: "",
      alertErrorMessage: "",
    });
    if (name === "plateNo" && value === "") {
      this.setState({
        policyNoError: CgnMessage.textErrorMessage,
      });
    }
  };

  handleAddressChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].addressNo = e.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productCountModal: !this.state.productCountModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleProductAdd(item.value);
        }
      });
    });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      productGuid: "",
      plateNo: "",
      document: "",
      addressNo: "",
      counter: -1,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    this.setState({
      productDataError: "",
    });

    let array = this.state.productData;
    await array.push({
      productGuid: "",
      plateNo: "",
      addressNo: "",
      status: true,
      productDataError: CgnMessage.textErrorMessage,
      alertErrorMessage: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].productGuid === "") {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].plateNo = "";
      productData[idx].addressNo = "";
      productData[idx].productError = "";
      productData[idx].status = true;
      this.setState({
        productData: productData,
        alertErrorMessage: "",
      });
    } else {
      productData[idx].productGuid = "";
      productData[idx].productError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
  }

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (!item.productGuid) {
        item.productError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (item.productGuid) {
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
  };

  async handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        productGuid: "",
        plateNo: "",
        addressNo: "",
        productError: "",
      });
    }
  }

  handlePolicyDateChange(policyDate) {
    if (policyDate) {
      this.setState({
        policyDate: policyDate,
        policyDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        policyDate: "",
        policyDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleNextPolicyDateChange(nextPolicyDate) {
    if (nextPolicyDate) {
      this.setState({
        nextPolicyDate: nextPolicyDate,
        nextPolicyDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        nextPolicyDate: "",
        nextPolicyDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      customerError: "",
      supplierError: "",
      productError: "",
      alertErrorMessage: "",
      policyDateError: "",
      nextPolicyDateError: "",
      policyNoError: "",
    });
    if (this.state.customerGuid === "00000000-0000-0000-0000-000000000000") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.supplierGuid === "00000000-0000-0000-0000-000000000000") {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.productGuid === "00000000-0000-0000-0000-000000000000") {
      this.setState({
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.policyDate === "") {
      this.setState({
        policyDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.nextPolicyDate === "") {
      this.setState({
        nextPolicyDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.customerGuid !== null &&
      this.state.supplierGuid !== null &&
      this.state.productGuid !== null
    ) {
      this.setState({
        isLoading: true,
      });
      var statusType = 2;
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var myObj = {
        customerGuid: this.state.customerGuid,
        supplierGuid: this.state.supplierGuid,
        productGuid: this.state.productGuid,
        plateNo: this.state.plateNo,
        status: true, //this.state.status,
        policyDate: moment(this.state.policyDate).format("DD/MM/YYYY"),
        nextPolicyDate: moment(this.state.nextPolicyDate).format("DD/MM/YYYY"),
        policyNo: this.state.policyNo,
        documentNo: this.state.documentNo,
      };
      var URLParam = "/policies/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/policies/update";
        myObj = {
          guid: this.state.guid,
          customerGuid: this.state.customerGuid,
          supplierGuid: this.state.supplierGuid,
          productGuid: this.state.productGuid,
          plateNo: this.state.plateNo,
          status: this.state.status, //this.state.status,
          policyDate:
            this.state.policyDate &&
            this.state.policyDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.policyDate.split("/")[2] +
                    "-" +
                    this.state.policyDate.split("/")[1] +
                    "-" +
                    this.state.policyDate.split("/")[0]
                )
              : moment(this.state.policyDate).format("DD/MM/YYYY"),
          nextPolicyDate:
            this.state.nextPolicyDate &&
            this.state.nextPolicyDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.nextPolicyDate.split("/")[2] +
                    "-" +
                    this.state.nextPolicyDate.split("/")[1] +
                    "-" +
                    this.state.nextPolicyDate.split("/")[0]
                )
              : moment(this.state.nextPolicyDate).format("DD/MM/YYYY"),
          policyNo: this.state.policyNo,
          documentNo: this.state.documentNo,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/police-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="policyAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="supplierGuid"
                          label="Tedarikçi [*]"
                          placeholder="Tedarikçi seçiniz..."
                          selectValue={this.state.supplierGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSupplierSelectChange(option)
                          }
                          options={this.state.supplierArr}
                          error={this.state.supplierError}
                        />
                      </div>
                      {this.state.supplierModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalSupplier()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className="mobileBorder">
                  <Col lg="12" className="modalSelectCol p-0">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Lütfen ürün seçiniz..."
                          selectValue={this.state.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) => {
                            this.handleProductSelectChange(option);
                          }}
                          options={this.state.productArr}
                          error={this.state.productError}
                        />
                      </div>
                      {this.state.productModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalProduct()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="plateNo"
                      label="Plaka No"
                      value={this.state.plateNo}
                      placeHolder="Lütfen plaka no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={(option) => {
                        this.handlePlateChange(option);
                      }}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="policyNo"
                      label="Poliçe No"
                      value={this.state.policyNo}
                      placeHolder="Lütfen poliçe no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.policyNoError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="documentNo"
                      label="Belge No"
                      value={this.state.documentNo}
                      placeHolder="Lütfen belge no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChangeDocument}
                      error={this.state.documentNoError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnDatePicker
                      name="policyDate"
                      label="Poliçe Tarihi [*]"
                      selected={
                        this.state.policyDate &&
                        this.state.policyDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.policyDate.split("/")[2] +
                                "-" +
                                this.state.policyDate.split("/")[1] +
                                "-" +
                                this.state.policyDate.split("/")[0]
                            )
                          : this.state.policyDate
                      }
                      onChange={(policyDate) =>
                        this.handlePolicyDateChange(policyDate)
                      }
                      error={this.state.policyDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6">
                    <CgnDatePicker
                      name="nextPolicyDate"
                      label="Sonraki Poliçe Tarihi [*]"
                      selected={
                        this.state.nextPolicyDate &&
                        this.state.nextPolicyDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.nextPolicyDate.split("/")[2] +
                                "-" +
                                this.state.nextPolicyDate.split("/")[1] +
                                "-" +
                                this.state.nextPolicyDate.split("/")[0]
                            )
                          : this.state.nextPolicyDate
                      }
                      onChange={(nextPolicyDate) =>
                        this.handleNextPolicyDateChange(nextPolicyDate)
                      }
                      error={this.state.nextPolicyDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}

                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalSupplier}
          toggle={this.addModalSupplier}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tedarikçi Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SupplierAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSupplier}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default PolicyAddUpdate;
