import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Label
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import moment from "moment";
import axios from "axios";
import {
  URL,
  
  UserOperationClaimControl,
  entryTypes,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import { withRouter } from "react-router-dom";
import TimePicker from "rc-time-picker";

class LocationMaterialDeliveryAddUpdate extends Component {
  state = {
    pageTitle: "Depo El Aleti İşlemi Ekle",
    pageTitleAdd: "Depo El Aleti İşlemi Ekle",
    pageTitleUpdate: "Depo El Aleti İşlemi Güncelle",
    pageAddLink: "depo-el-aleti-islemi-ekle",
    pageListLink: "depo-el-aleti-islemi-listele",
    breadcrumb: [{ text: "Depo El Aleti İşlemi", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productArr: [],
    locationProductDto: [],
    locationArr: [],
    locationGuid: "",
    code: "",
    image: "",
    name: "",
    guid: "",
    types: [],
    entryDate: "",
    entryHour: "",
    workDate: "",
    workOrderNo: "",
    workOrderGuid: "",
    customerGuid: "",
    personelGuid: "",
    productGuid: "",
    materialGuid: "",
    productOutGuid: "",
    productOutNo: null,
    productData: [],
    description: "",
    piece: null,
    workEndDate: "",
    workStartDate: "",
    pieceError: "",
    productOutError: "",
    fileName: "",
    productError: "",
    customerError: "",
    locationError: "",
    personelError: "",
    workDateError: "",
    workStartDateError: "",
    status: true,
    workInstructionDate: "",
    workInstructionCode: "",
    workInstructionGuid: "",
    isReturnHidden: true,
    isDeliveryHidden: true,
    modalButtonIdx: 0,
    isTrue: false,
    entryTypeError: "",
    entryHourError: "",
    entryType: "",
    codeError: "",
    nameError: "",
    stockError: "",
    alertErrorMessage: "",
    message: "",
    contractError: "",
    counter1: -1,
    workInstructionArr: [],
    locationDeliveryProductDto: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    categoryModalPlus: false,
    productPropertyTypeModalPlus: false,
    productModalPlus: false,
    customerModalPlus: false,
    materialModalPlus: false,
    workOrderStepModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      categoryModalPlus: await UserOperationClaimControl(1006),
      productPropertyTypeModalPlus: await UserOperationClaimControl(1033),
      productModalPlus: await UserOperationClaimControl(1029),
      customerModalPlus: await UserOperationClaimControl(1012),
      materialModalPlus: await UserOperationClaimControl(1133),
      workOrderStepModalPlus: await UserOperationClaimControl(1123),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/workInstructions/getliststatus/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.code + "/" + item.customerName + "/" + item.productName,
          };
        });
        this.setState({
          workInstructionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/materials/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}`,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          personelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "depo-el-aleti-islemi-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/locationMaterialDeliveries/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              entryType: response.data.entryType,
              customerGuid: response.data.customerGuid,
              personelGuid: response.data.personelGuid,
              locationGuid: response.data.locationGuid,
              productOutGuid: response.data.productOutGuid,
              entryDate: response.data.entryDate,
              entryHour: moment(
                response.data.entryHour,
                "DD-MM-YYYY hh:mm"
              ),
              workDate: response.data.workInstructionDate,
              workInstructionCode: response.data.workInstructionCode,
              workInstructionGuid: response.data.workInstructionGuid,
              workStartDate: response.data.workStartDate,
              workEndDate:response.data.workEndDate === "01/01/1900" ? "" : response.data.workEndDate,
              status: response.data.status,
              isLoading: false,
              isNull: false,
              productData:response.data.locationMaterialDeliveryProductDto,
              counter1: response.data.locationMaterialDeliveryProductDto.length - 1,
              description: response.data.description,
              isReturnHidden: response.data.entryType === 3 ? false : true,
              isDeliveryHidden: response.data.entryType !== 3 ? false : true
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        entryType: "",
        entryDate: "",
        description: "",
        enrtyHour: "",
        customerGuid: "",
        personelGuid: "",
        code: "",
        name: "",
        productOutGuid: "",
        productOutNo: null,
        workDate: "",
        workEndDate: "",
        workStartDate: "",
        productData: [],
        status: true,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/materials/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}`,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data
            ) {
              this.setState({
                productGuid: element.guid,
                productError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });

  };

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  addModalProductMaterial = async (idx) => {
    await this.setState({
      isOpenModalProductMaterial: !this.state.isOpenModalProductMaterial,
      modalButtonIdx: idx,
    });
  };

  addModalQualityMaterial = async (idx) => {
    await this.setState({
      isOpenModalQualityMaterial: !this.state.isOpenModalQualityMaterial,
      modalButtonIdx: idx,
    });
  };

  addModalWorkOrderStep = async (idx) => {
    await this.setState({
      isOpenModalWorkOrderStep: !this.state.isOpenModalWorkOrderStep,
      modalButtonIdx: idx,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      pieceError: "",
      codeError: "",
      alertErrorMessage: "",
    });
    if (
      (name === "piece" && value === "") ||
      (name === "piece" && value === null)
    ) {
      this.setState({
        pieceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "code" && value === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].deliveryPiece = parseFloat(e.target.value);
    productData[idx].deliveryPieceError = "";
    this.setState({
      productData: productData,
    });
  }


  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    await this.setState({
      customerGuid: "",
      personelGuid: "",
      productOutGuid: "",
      workDate: "",
      workStartDate: "",
      workEndDate: "",
      productOutNo: null,
      code: "",
      description: "",
      name: "",
      entryDate: "",
      entryHour: "",
      workInstructionCode: "",
      workInstructionDate: "",
      workInstructionGuid: "",
      productData: [],
      status: true,
      isTrue: false,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      productError: CgnMessage.textErrorMessage,
      codeError: CgnMessage.textErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      personelError: CgnMessage.textErrorMessage,
      workDateError: CgnMessage.textErrorMessage,
      workStartDateError: CgnMessage.textErrorMessage,
    });
  }

  async handleWorkInstructionSelectChange(option) {
    if (option) {
      await this.setState({
        workInstructionGuid: option.value,
        workInstructionError: "",
        alertErrorMessage: "",
        isLoading: true
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(
          URL + "/workInstructions/get/" + this.state.workInstructionGuid,
          config
        )
        .then((response) => {
          this.setState({
            customerGuid: response.data.customerGuid,
            personelGuid: response.data.personelGuid,
            productOutGuid: response.data.productOutGuid,
            workDate: response.data.workDate,
            workStartDate: response.data.workStartDate,
            workEndDate: response.data.workEndDate,
            productData: response.data.productRecipeQualityDto.concat(response.data.productRecipeProductionDto),
            workInstructionCode: response.data.code,
            isLoading: false,
            workInstructionCodeError: "",
            workDateError: "",
            customerError: "",
            personelError: "",
            workStartDateError: "",
            workEndDateError: "",
            productOutError: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        workInstructionGuid: "",
        productData: [],
        customerGuid: "",
        personelGuid: "",
        productOutGuid: "",
        workDate: "",
        workStartDate: "",
        workEndDate: "",
        workInstructionCode: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        workInstructionCodeError: CgnMessage.alertErrorMessage,
        workDateError: CgnMessage.alertErrorMessage,
        customerError: CgnMessage.alertErrorMessage,
        personelError: CgnMessage.alertErrorMessage,
        workStartDateError: CgnMessage.alertErrorMessage,
        workEndDateError: CgnMessage.alertErrorMessage,
        productOutError: CgnMessage.alertErrorMessage,
      });
    }

  }

  handleEntryDateChange(entryDate) {
    if (entryDate) {
      this.setState({
        entryDate: entryDate,
        entryDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        entryDate: "",
        entryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleTimeChange(option) {
    if (option) {
      var time = moment(option);
      await this.setState({
        entryHour: time,
        entryHourError: "",
      });
    } else {
      this.setState({
        entryHour: null,
        entryHourError: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleEntryTypeSelectChange(option) {
    if (option) {
      await this.setState({
        entryType: option.value,
        entryTypeError: "",
        alertErrorMessage: "",
        isReturnHidden: true,
        isDeliveryHidden: true
      });

      if (this.state.entryType === 1 || this.state.entryType === 2) {
        await this.setState({
          isDeliveryHidden: false,
          isReturnHidden: true
        });
      }
      if (this.state.entryType === 3) {
        await this.setState({
          isReturnHidden: false,
          isDeliveryHidden: true
        });
      }
    } else {
      await this.setState({
        entryType: "",
        entryTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isReturnHidden: true,
        isDeliveryHidden: true
      });
    }
  }

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }


  handleEntryHourChange(entryHour) {
    if (entryHour) {
      this.setState({
        entryHour: entryHour,
        entryHourError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        entryHour: "",
        entryHourError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      customerError: "",
      personelError: "",
      productOutError: "",
      workDateError: "",
      workStartDateError: "",
      codeError: "",
      productError: "",
      pieceError: "",
    });
    if (this.state.personelGuid === "") {
      this.setState({
        personelError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.entryType === "") {
      this.setState({
        entryTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.locationGuid === "") {
      this.setState({
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.productOutGuid === "") {
      this.setState({
        productOutError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.workInstructionCode === "") {
      this.setState({
        workInstructionCodeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.workInstructionGuid === "") {
      this.setState({
        workInstructionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.workDate === "") {
      this.setState({
        workDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.entryDate === "") {
      this.setState({
        entryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.entryHour === "") {
      this.setState({
        entryHourError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.workStartDate === "") {
      this.setState({
        workStartDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.productData !== null && this.state.productData.length > 0) {
      this.state.productData.forEach((element) => {
        if (element.productGuid === "") {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            productError: CgnMessage.alertErrorMessage,
          });
        }       
        if(element.deliveryPiece !== "" || element.deliveryPiece !== undefined || element.deliverPiece !== null){
          if(parseFloat(element.piece) < parseFloat(element.deliveryPiece)){
            control = false;
            element.deliveryPieceError = CgnMessage.materialErrorMessage;
          }
        }
      });
    }
    if (
      this.state.customerGuid !== "" &&
      this.state.personelGuid !== "" &&
      this.state.locationGuid !== "" &&
      this.state.workStartDate !== "" &&
      this.state.entryType !== "" &&
      control === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

     var productData = [];
     this.state.productData.forEach((element) => {
         productData.push({
           guid: element.guid,
           materialGuid: element.materialGuid,
           piece: parseFloat(element.piece),
           deliveryPiece: (element.deliveryPiece === "" || element.deliveryPiece === undefined || element.deliveryPiece === null) ? 0 : parseFloat(element.deliveryPiece),
           locationGuid: this.state.locationGuid,
         });
     });

      var myObj = {
        entryType: this.state.entryType,
        entryDate: this.state.entryDate,
        entryHour: this.state.entryHour,
        customerGuid: this.state.customerGuid,
        locationGuid: this.state.locationGuid,
        personelGuid: this.state.personelGuid,
        workInstructionGuid: this.state.workInstructionGuid,
        workInstructionCode: this.state.workInstructionCode,
        workStartDate: this.state.workStartDate,
        workDate: this.state.workDate,
        productOutGuid: this.state.productOutGuid,
        workEndDate: this.state.workEndDate,
        locationMaterialDeliveryProductDto: productData,
        status: true,
        description: this.state.description
      };
      var URLParam = "/locationMaterialDeliveries/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "depo-el-aleti-islemi-guncelle"
      ) {
        URLParam = "/locationMaterialDeliveries/update";
        myObj = {
          guid: this.state.guid,
          entryType: this.state.entryType,
          entryDate: this.state.entryDate,
          entryHour: this.state.entryHour,
          customerGuid: this.state.customerGuid,
          locationGuid: this.state.locationGuid,
          personelGuid: this.state.personelGuid,
          workInstructionGuid: this.state.workInstructionGuid,
          workInstructionCode: this.state.workInstructionCode,
          workStartDate: this.state.workStartDate,
          workDate: this.state.workDate,
          productOutGuid: this.state.productOutGuid,
          workEndDate: this.state.workEndDate,
          locationMaterialDeliveryProductDto: productData,
          status: true,
          description: this.state.description
        };
      }

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "depo-el-aleti-islemi-guncelle"
            ) {
              window.location.href = "/depo-el-aleti-islemi-listele";
            }
            if (this.state.urlSplit[1] === "depo-el-aleti-islemi-ekle") {
              window.location.reload();
            }
            var modalAddedLocationMaterialDelivery = {
              data: `${this.state.workInstructionCode}`,
              isModal: false,
            };
            this.props.history.push({ modalAddedLocationMaterialDelivery });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    var self = this.state;
    var self1 = this;
    return (
      <>
        {(this.state.urlSplit[1] === "depo-el-aleti-islemi-ekle" ||
          this.state.urlSplit[1] === "depo-el-aleti-islemi-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="entryType"
                      label="Tip [*]"
                      isMulti={false}
                      selectValue={this.state.entryType}
                      placeholder="Tip seçiniz..."
                      options={entryTypes}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleEntryTypeSelectChange(option)
                      }
                      error={this.state.entryTypeError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="locationGuid"
                      label="Depo [*]"
                      isMulti={false}
                      selectValue={this.state.locationGuid}
                      placeholder="Depo seçiniz..."
                      options={this.state.locationArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleLocationSelectChange(option)
                      }
                      error={this.state.locationError}
                    />
                  </Col>
                  </Row>
                  <Row>
                  <Col lg="6" className="pl-0" style={{display: this.state.isDeliveryHidden === true ? "none" : ""}}>
                    <CgnDatePicker
                      name="entryDate"
                      label="Teslim Tarihi [*]"
                      selected={
                        this.state.entryDate &&
                        this.state.entryDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.entryDate.split("/")[2] +
                                "-" +
                                this.state.entryDate.split("/")[1] +
                                "-" +
                                this.state.entryDate.split("/")[0]
                            )
                          : this.state.entryDate
                      }
                      onChange={(entryDate) =>
                        this.handleEntryDateChange(entryDate)
                      }
                      error={this.state.entryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6" style={{display: this.state.isDeliveryHidden === true ? "none" : ""}}>
                  <Label className="font-weight-bolder d-block">
                        Teslim Saati [*]
                      </Label>
                      <TimePicker
                        placeholder="Teslim saati giriniz"
                        showSecond={false}
                        onChange={(option) => this.handleTimeChange(option)}
                        errors={this.state.entryHourError}
                        value={this.state.entryHour}
                      />
                  </Col>
                  <Col lg="6" className="pl-0" style={{display: this.state.isReturnHidden === true ? "none" : ""}}>
                    <CgnDatePicker
                      name="entryDate"
                      label="İade Tarihi [*]"
                      selected={
                        this.state.entryDate &&
                        this.state.entryDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.entryDate.split("/")[2] +
                                "-" +
                                this.state.entryDate.split("/")[1] +
                                "-" +
                                this.state.entryDate.split("/")[0]
                            )
                          : this.state.entryDate
                      }
                      onChange={(entryDate) =>
                        this.handleEntryDateChange(entryDate)
                      }
                      error={this.state.entryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6"  style={{display: this.state.isReturnHidden === true ? "none" : ""}}>
                      <Label className="font-weight-bolder d-block">
                        İade Saati [*]
                      </Label>
                      <TimePicker
                        placeholder="İade saati giriniz"
                        showSecond={false}
                        onChange={(option) => this.handleTimeChange(option)}
                        errors={this.state.entryHourError}
                        value={this.state.entryHour}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      isMulti={false}
                      selectValue={this.state.workInstructionGuid}
                      placeholder="İş Emri seçiniz..."
                      options={this.state.workInstructionArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionSelectChange(option)
                      }
                      error={this.state.workInstructionError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="workInstructionCode"
                      label="İş Emri No [*]"
                      value={this.state.workInstructionCode}
                      placeHolder="Lütfen iş emri no girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.workInstructionCodeError}
                      readOnly={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnDatePicker
                      name="workDate"
                      label="İş Emri Tarihi [*]"
                      selected={ this.state.workDate &&
                        this.state.workDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.workDate.split("/")[2] +
                                "-" +
                                this.state.workDate.split("/")[1] +
                                "-" +
                                this.state.workDate.split("/")[0]
                            )
                          : this.state.workDate}
                      onChange={(workDate) =>
                        this.handleWorkDateChange(workDate)
                      }
                      error={this.state.workDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="modalSelectCol">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          isMulti={false}
                          selectValue={this.state.customerGuid}
                          placeholder="Müşteri seçiniz..."
                          options={this.state.customerArr}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          error={this.state.customerError}
                          disabled={true}
                        />                   
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="personelGuid"
                      label="Personel [*]"
                      isMulti={false}
                      selectValue={this.state.personelGuid}
                      placeholder="Personel seçiniz..."
                      options={this.state.personelArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePersonelSelectChange(option)
                      }
                      error={this.state.personelError}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnDatePicker
                      name="workStartDate"
                      label="Başlangıç Tarihi [*]"
                      selected={
                        this.state.workStartDate &&
                        this.state.workStartDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.workStartDate.split("/")[2] +
                                "-" +
                                this.state.workStartDate.split("/")[1] +
                                "-" +
                                this.state.workStartDate.split("/")[0]
                            )
                          : this.state.workStartDate
                      }
                      onChange={(workStartDate) =>
                        this.handleWorkStartDateChange(workStartDate)
                      }
                      error={this.state.workStartDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnDatePicker
                      name="workEndDate"
                      label="Planlı Bitiş Tarihi"
                      selected={
                        this.state.workEndDate &&
                        this.state.workEndDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.workEndDate.split("/")[2] +
                                "-" +
                                this.state.workEndDate.split("/")[1] +
                                "-" +
                                this.state.workEndDate.split("/")[0]
                            )
                          : this.state.workEndDate
                      }
                      onChange={(workEndDate) =>
                        this.handleWorkEndDateChange(workEndDate)
                      }
                      error={this.state.workEndDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      isMulti={false}
                      selectValue={this.state.productOutGuid}
                      placeholder="Satış seçiniz..."
                      options={this.state.productOutArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductOutSelectChange(option)
                      }
                      error={this.state.productOutError}
                      disabled={true}
                    />
                  </Col>
                </Row>

                {this.state.productData.map((productData, idx) => (
                  <>
                    <Row className="mobileBorder pl-0">
                      <Col lg="4" className="modalSelectCol pl-0">
                        <CgnReactSelect
                          name="materialGuid"
                          label="El Aleti [*]"
                          placeholder="Lütfen el aleti seçiniz..."
                          selectValue={productData.materialGuid}
                          isMulti={false}
                          isClearable={true}
                          options={self.productArr}
                          disabled={true}
                        />
                      </Col>
                      <Col lg="4">
                        <CgnTextbox
                          type="number"
                          name="piece"
                          label="El Aleti Adedi"
                          value={productData.piece}
                          placeHolder="Lütfen iade/teslim adedi girin..."
                          maxLength="100"
                          autoComplete="off"
                          onChange={this.handleChange}
                          error={this.state.pieceError}
                          readOnly={true}
                        />
                      </Col>
                      <Col lg="4">
                        <CgnTextbox
                          type="number"
                          name="deliveryPiece"
                          label="İade/Teslim Adedi [*]"
                          value={productData.deliveryPiece}
                          placeHolder="Lütfen iade/teslim adedi girin..."
                          maxLength="50"
                          autoComplete="off"
                          onChange={(e) => self1.handleTextBoxChange(e, idx)}
                          error={productData.deliveryPieceError}
                          step={0.1}
                        />
                      </Col>
                    </Row>
                  </>
                ))}

                <Row>
                    <Col lg="12">
                      <CgnTextbox
                        inlineStyle={textAreaStyle}
                        type="textarea"
                        name="description"
                        label="Açıklama"
                        value={this.state.description}
                        placeHolder="Lütfen açıklama girin..."
                        autoComplete="off"
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "depo-el-aleti-islemi-ekle" ||
              this.state.urlSplit[1] === "depo-el-aleti-islemi-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(LocationMaterialDeliveryAddUpdate);
