import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
class GmailApiInformationDetail extends Component {
  state = {
    pageTitle: "GMail API Bilgileri Detay",
    pageTitleDetail: "GMail API Bilgileri Detay",
    pageAddLink: "gmail-api-bilgileri-ekle",
    pageListLink: "gmail-api-bilgileri-listele",
    breadcrumb: [{ text: "GMail API Bilgileri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    membershipName: "",
    membershipEMail: "",
    clientId: "",
    clientSecret: "",
    smtpPassword: "",
    controlDateTime: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
  };
  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/gmailapiinformation/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            membershipName: response.data.membershipName,
            membershipEMail: response.data.membershipEMail,
            clientId: response.data.clientId,
            clientSecret: response.data.clientSecret,
            smtpPassword: response.data.smtpPassword,
            controlDateTime: response.data.controlDateTime,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }
  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Kullanıcı</th>
                    <td colSpan="4">
                      {this.state.membershipName}
                    </td>
                  </tr>
                  <tr>
                    <th>E-Mail</th>
                    <td colSpan="4">
                      {this.state.membershipEMail}
                    </td>
                  </tr>
                  <tr>
                    <th>Client Id</th>
                    <td colSpan="4">
                      {this.state.clientId}
                    </td>
                  </tr>
                  <tr>
                    <th>Client Secret</th>
                    <td colSpan="4">
                      {this.state.clientSecret}
                    </td>
                  </tr>
                  <tr>
                    <th>SMTP Uygulama Şifresi</th>
                    <td colSpan="4">
                      {this.state.smtpPassword}
                    </td>
                  </tr>
                  <tr>
                    <th>Son Kontrol Tarihi</th>
                    <td colSpan="4">
                      {this.state.controlDateTime}
                    </td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default GmailApiInformationDetail;
