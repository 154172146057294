import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, fotoStyle,  fotoStyle2, localFilePath } from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";

class MaterialDetail extends Component {
  state = {
    pageTitle: "El Aleti Detay",
    pageTitleDetail: "El Aleti Detay",
    pageAddLink: "malzeme-ekle",
    pageListLink: "malzeme-listele",
    breadcrumb: [{ text: "El Aleti", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    materialCategoryName: "",
    code: "",
    name: "",
    location: "",
    stock: null,
    peerProductName: "",
    materialImageDto: [],
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/materials/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            image: response.data.image,
            materialCategoryName: response.data.materialCategoryName,
            locationName: response.data.locationName,
            code: response.data.code,
            name: response.data.name,
            stock: response.data.stock,
            peerProductName: response.data.peerProductName,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            materialImageDto: response.data.materialImageDto,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath, "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  }


  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>El Aleti Adı</th>
                    <td colSpan="4">
                      {this.state.name ? this.state.name : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.image ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.image}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.image}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal>
                  <tr>
                    <th>Barkod</th>
                    <td colSpan="4">
                      {this.state.code ? this.state.code : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>El Aleti Kategori</th>
                    <td colSpan="4">
                      {this.state.materialCategoryName ? this.state.materialCategoryName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Emsal Ürün</th>
                    <td colSpan="4">
                      {this.state.peerProductName
                        ? this.state.peerProductName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">
                      {this.state.locationName === null
                        ? "-"
                        : this.state.locationName}
                    </td>
                  </tr>

                  <tr>
                    <th>Stok Durumu</th>
                    <td colSpan="4">
                      {this.state.stock === 0
                        ? "-"
                        : this.state.stock}
                    </td>
                  </tr>
                
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.materialImageDto &&
                this.state.materialImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Dosya</th>
                    </tr>
                    <Row>
                    {this.state.materialImageDto.map(function (item) {
                      return (
                        <>
                        <div className="pl-2">
                        <Button
                          onClick={() => self1.fileClick(item.image)}
                          className="modalButtonDetail"
                        >
                          <img
                            src={pdfIcon}
                            style={fotoStyle2}
                            alt={item.imageName}
                          />
                        </Button>
                        <p style={{maxWidth: "150px"}}>{item.imageName}</p>
                        </div>
                      
                  </>
                      );
                    })}
                    </Row>
                    
                  </Table>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default MaterialDetail;
