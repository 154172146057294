import React, { Component } from "react";
import { Row, Col, Card, Form,   Modal, Label,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL,  UserOperationClaimControl, emailStatus} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import CgnButton from "../toolbox/CgnButton";
import { ReactMultiEmailCustom } from 'react-multi-email-custom';
import 'react-multi-email-custom/style.css';

class DocumentRecordAddUpdate extends Component {
  state = {
    pageTitle: "Müşteriden İstenen Belgeler/ÜTS Belge Kayıt Ekle",
    pageTitleAdd: "Müşteriden İstenen Belgeler/ÜTS Belge Kayıt Ekle",
    pageTitleUpdate: "Müşteriden İstenen Belgeler/ÜTS Belge Kayıt Güncelle",
    pageAddLink: "musteri-belge-talep-ekle",
    pageListLink: "musteri-belge-talep-listele",
    breadcrumb: [{ text: "Müşteriden İstenen Belgeler/ÜTS Belge Kayıt", link: "#" }],
    isLoading: false,
    urlSplit: [],
    customerArr: [],
    offerArr: [],
    requestedDocumentsArr: [],
    requestedDocumentsOption: [],
    requestedDocumentsId: [],
    documentRecordEmailDto: [],
    status: true,
    guid: "",
    customerGuid: "",
    offerGuid: "",
    statusMailError: "",
    offerError: "",
    emails: [], 
    requestedDocuments: "",
    customerError: "",
    requestedDocumentsError: "",
    alertErrorMessage: "",
    customerModalPlus: false,
    isOpenModalCustomer: false,
    statusMail: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
    .get(URL + "/customers/getlist/" + statusType, config)
    .then((response) => {
      var myArr = response.data.map(function (item) {
        return {
          value: item.guid,
          label: item.name,
        };
      });
      this.setState({
        customerArr: myArr,
      });
    })
    .catch((error) => {
      this.setState({
        alertErrorMessage: error.response.data,
        isLoading: false,
      });
    });
    await axios
    .get(URL + "/offerNews/getlistdocumenttype", config)
    .then((response) => {
      var myArr = response.data.map(function (item) {
        return {
          value: item.guid,
          label: item.offerDate + "-" + item.customerName + "-" + item.total + " " +
         (item.currencyType === 1 ? "TL" : item.currencyType === 2 ? "$" : item.currencyType === 3 ? "€" : ""),
        };
      });
      this.setState({
        offerArr: myArr,
      });
    })
    .catch((error) => {
      this.setState({
        alertErrorMessage: error.response.data,
        isLoading: false,
      });
    });
    await axios
    .get(URL + "/documentTypes2/getlist/" + statusType, config)
    .then((response) => {
      var myArr = response.data.map(function (item) {
        return {
          value: item.guid,
          label: item.name,
        };
      });
      this.setState({
        requestedDocumentsOption: myArr,
      });
    })
    .catch((error) => {
      this.setState({
        alertErrorMessage: error.response.data,
        isLoading: false,
      });
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if(urlSplit.length === 3){
    await axios
    .get(URL + "/documentRecords/get/" + urlSplit[2], config)
    .then((response) => {
      if(response.data.requestedDocumentsId !== null){
        const requestedDocumentsArr = response.data.requestedDocumentsId.split(",");
        requestedDocumentsArr.forEach((element) => {
          var requestedDocumentsObj = {};
          requestedDocumentsObj.value = element;
          this.state.requestedDocumentsArr.push(requestedDocumentsObj);
        });
      }
    })
  }
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/documentRecords/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              customerGuid: response.data.customerGuid,
              offerGuid: response.data.offerGuid,
              requestedDocuments: response.data.requestedDocuments,
              status: response.data.status,
              isLoading: false,
              documentRecordEmailDto: response.data.documentRecordEmailDto,
              statusMail: response.data.statusMail,
            });
  
            if(response.data.documentRecordEmailDto !== null){
              response.data.documentRecordEmailDto.forEach((element) => {
                var emailObj = {};
                emailObj = element.email;
                this.state.emails.push(emailObj);
              });
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        customerGuid: "",
        requestedDocuments: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusMailSelectChange(option) {
    if (option) {
      this.setState({
        statusMail: option.value,
        statusMailError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        statusMail: "",
        statusMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleOfferSelectChange(option) {
    if (option) {
      this.setState({
        offerGuid: option.value,
        offerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerGuid: "",
        offerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      customerGuid: "",
      status: true,
      requestedDocuments: null,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      requestedDocumentsError: CgnMessage.textErrorMessage,
    });
  }

  handleRequestedDocumentSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        requestedDocumentsArr: option,
        requestedDocumentsError: "",
        alertErrorMessage: "",
      });
    } 
    else {
      this.setState({
        requestedDocumentsArr: [],
      });
    }
  }


  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      customerError: "",
      requestedDocumentsError: "",
      alertErrorMessage: "",
    });
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.offerGuid === "") {
      this.setState({
        offerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.statusMail === "" || this.state.statusMail === null) {
      this.setState({
        statusMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.requestedDocumentsArr.length === 0) {
      control = false;
      this.setState({
        requestedDocumentsError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid 
      && control === true && this.state.offerGuid && this.state.statusMail !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var requestedDocuments = [];
        this.state.requestedDocumentsArr.forEach((element) => {
        var requestedDocumentsObj = {};
        requestedDocumentsObj = element.value;
        requestedDocuments.push(requestedDocumentsObj);
      });

      var documentRecordEmail = [];
      this.state.emails.forEach((element) => {
        documentRecordEmail.push({
          email: element,
        });
      })

      var myObj = {
        customerGuid: this.state.customerGuid,
        requestedDocumentsId: requestedDocuments.toString(),
        offerGuid: this.state.offerGuid,
        status: true,
        documentRecordEmailDto: documentRecordEmail,
        statusMail: this.state.statusMail,
      };
      var URLParam = "/documentRecords/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/documentRecords/update";
        myObj = {
          guid: this.state.guid,
          customerGuid: this.state.customerGuid,
          requestedDocumentsId: requestedDocuments.toString(),
          offerGuid: this.state.offerGuid,
          status: true,
          documentRecordEmailDto: documentRecordEmail,
          statusMail: this.state.statusMail,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/musteri-belge-talep-listele";
            }
            if (this.state.urlSplit[1] === "musteri-belge-talep-ekle") {
              window.location.reload();
            }
            var modalAddedDocumentRecord = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedDocumentRecord });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "musteri-belge-talep-ekle" ||
          this.state.urlSplit[1] === "musteri-belge-talep-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="5" className="pl-0">
                  <CgnReactSelect
                          name="offerGuid"
                          label="Teklif [*]"
                          placeholder="Teklif seçiniz..."
                          selectValue={this.state.offerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleOfferSelectChange(option)
                          }
                          options={this.state.offerArr}
                          error={this.state.offerError}
                        />
                  </Col>
                  <Col lg="5">
                  <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="2">
                  <CgnReactSelect
                          name="statusMail"
                          label="E-mail Durumu [*]"
                          placeholder="E-mail durumu seçiniz..."
                          selectValue={this.state.statusMail}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleStatusMailSelectChange(option)
                          }
                          options={emailStatus}
                          error={this.state.statusMailError}
                        />
                  </Col>
                  </Row>
                  <Row>
                  <Col lg="12">
                      <CgnReactSelect
                          name="requestedDocumentsArr"
                          label="Belge [*]"
                          placeholder="Belge seçiniz..."
                          selectValue={this.state.requestedDocumentsArr}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleRequestedDocumentSelectChange(option)
                          }
                          isMulti={true}
                          options={this.state.requestedDocumentsOption}
                          error={this.state.requestedDocumentsError}
                        />
                    </Col>
                </Row>
                <Row>
                  <Col lg="12">
                  <ReactMultiEmailCustom
                //style={myStyle}
                emails={this.state.emails}
                onChange={_emails => {
                  this.setState({ emails: _emails });
                }}
                getLabel={(
                  email,
                  index,
                  removeEmail,
                ) => {
                  return (
                    <Label className="mr-2" style={{backgroundColor: "#1d4b8c", color: "#fff", fontSize: "11px", padding: "4px 5px"}} key={index}>
                      {email}
                      <span className="mt-2 ml-2 spanClose" style={{padding:"4px", fontSize:"13px"}} data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
                    </Label>
                  );
                }}
              />
                <label className="mt-2" style={{fontWeight: "bold"}}>Girilen E-mail : </label>
              {this.state.emails.join(', ') || ''}
                  </Col>
                </Row>
                         
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}

                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "musteri-belge-talep-ekle" ||
              this.state.urlSplit[1] === "musteri-belge-talep-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(DocumentRecordAddUpdate);
