import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div className="error-page">
        <h1>404</h1>
        <h3>Sayfa bulunamadı!</h3>
        <a href="/" className="btn btn-warning">Ana Sayfa</a>
      </div>
    );
  }
}

export default NotFound;
