import React from "react";
import { ButtonGroup } from "reactstrap";
import CgnButton from "./CgnButton";

const CgnButtonGroup = ({ items }) => {
  return (
    <>
      {items && items.length > 0 && (
        <ButtonGroup>
          {items.map((item, index) => (
            <CgnButton
              key={index}
              type={item.type}
              color={item.color}
              text={item.text}
              size={item.size}
              outline={item.outline}
              block={item.block}
              active={item.active}
              disabled={item.disabled}
              icon={item.icon}
              onClick={item.onClick}
            />
          ))}
        </ButtonGroup>
      )}
    </>
  );
};

export default CgnButtonGroup;
