import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Modal, ModalHeader } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  localFilePath,
  URL,
  
  machineProductUnits,
  machineProductStatuses,
  CategoryList,
  fotoStyle,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButtonLinkGroup from "../toolbox/CgnButtonLinkGroup";
import noImg from "../../images/no-img.png";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";
import CgnTextbox from "../toolbox/CgnTextbox";

class MachineProductList extends Component {
  state = {
    pageTitle: "Ürün Listele",
    pageAddLink: "urun3-ekle",
    pageUpdateLink: "urun3-guncelle",
    pageDetailLink: "urun3-detay",
    pageListLink: "urun3-listele",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    productData: [],
    name: "",
    categoryName: "",
    salePrice: "",
    guid: "",
    currencyType: 0,
    alertErrorMessage: "",
    activeTab: "1",
    categoryArr: [],
    categoryGuid: "00000000-0000-0000-0000-000000000000",
    unit: "",
    productStatus: "",
    image: "",
    name: "",
    isOpen: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var self = this;

    await CategoryList().then(function (data) {
      self.setState({
        categoryArr: data,
      });
    });

    if (localStorage.getItem("categoryGuidMachineProduct")) {
      this.setState({
        categoryGuid: localStorage.getItem("categoryGuidMachineProduct"),
      });
    }

    this.getList(null);
  }

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  // async componentDidUpdate() {
  //   let urlSplit = window.location.pathname.split("/");
  //   if (urlSplit.length === 3) {
  //     if (this.state.categoryGuid !== urlSplit[urlSplit.length - 1]) {
  //       await this.setState({
  //         isLoading: true,
  //         categoryGuid: urlSplit[urlSplit.length - 1],
  //       });
  //       this.state.categoryArr.forEach((item) => {
  //         if ("/urun3-listele/" + this.state.categoryGuid === item.to) {
  //           item.color = "primary";
  //         } else {
  //           item.color = "secondary";
  //         }
  //       });
  //       this.getList();
  //     }
  //   } else if (urlSplit.length === 2) {
  //     if (this.state.categoryGuid !== null) {
  //       await this.setState({
  //         isLoading: true,
  //         categoryGuid: null,
  //       });
  //       this.state.categoryArr.forEach((item) => {
  //         if ("/urun3-listele" === item.to) {
  //           item.color = "primary";
  //         } else {
  //           item.color = "secondary";
  //         }
  //       });
  //       this.getList();
  //     }
  //   }
  // }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    if (this.state.unit === "") {
      this.state.unit = 0;
    }
    if (this.state.productStatus === "") {
      this.state.productStatus = 0;
    }
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var statusType = 1;
    URLParam =
      "/machineProducts/getlist/" +
      statusType +
      "/" +
      this.state.categoryGuid +
      "/" +
      this.state.unit +
      "/" +
      this.state.productStatus;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          productData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/machineProducts/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  handleCopyProduct = async (guid) => {
    confirmAlert({
      title: CgnMessage.copyQuestionTitle,
      message: CgnMessage.copyQuestionMessage,
      buttons: [
        {
          label: CgnMessage.copyQuestionYes,
          onClick: () => this.copyItem(guid),
        },
        {
          label: CgnMessage.copyQuestionNo,
        },
      ],
    });
  };

  async copyItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineProducts/copy/" + guid, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleAddToCart = async (row) => {
    if (row.guid && row.piece && row.piece !== 0) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(
          URL + "/machineproducts/addtocart/" + row.guid + "/" + row.piece,
          config
        )
        .then((response) => {
          this.props.data.cartCount = this.props.data.cartCount + row.piece;
          row.piece = 1;
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  };

  async handleCategorySelectChange(option) {
    if (option) {
      await this.setState({
        categoryGuid: option.value,
      });
    } else {
      await this.setState({
        categoryGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("categoryGuidMachineProduct", this.state.categoryGuid);
  }

  async handleUnitSelectChange(option) {
    if (option) {
      await this.setState({
        unit: option.value,
      });
    } else {
      await this.setState({
        unit: 0,
      });
    }
    localStorage.setItem("unitMachineProduct", this.state.unit);
  }

  async handleProductStatusSelectChange(option) {
    if (option) {
      await this.setState({
        productStatus: option.value,
      });
    } else {
      await this.setState({
        productStatus: 0,
      });
    }
    localStorage.setItem(
      "productStatusMachineProduct",
      this.state.productStatus
    );
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineProducts/productPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleBarcodePrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineProducts/barcodenumberprint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
  }

  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "145px",
  };

  tdStyle = {
    width: "400px",
  };

  tdStyle2 = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" lg="6">
                  <CgnReactSelect
                    name="categoryGuid"
                    label="Kategori"
                    placeholder="Kategori seçiniz..."
                    selectValue={this.state.categoryGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCategorySelectChange(option)
                    }
                    options={this.state.categoryArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="unit"
                    label="Birim"
                    placeholder="Birim seçiniz..."
                    selectValue={this.state.unit}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) => this.handleUnitSelectChange(option)}
                    options={machineProductUnits}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="productStatus"
                    label="İthal/Yerli/Sarf"
                    placeholder="Durum seçiniz..."
                    selectValue={this.state.productStatus}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleProductStatusSelectChange(option)
                    }
                    options={machineProductStatuses}
                  />
                </Col>
                <Col className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>

              <CgnDatatable
                data={this.state.productData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    selector: "image",
                    sortable: false,
                    width: "80px",
                    cell: (cell) => (
                      <div>
                        {cell.image ? (
                          <Button
                            onClick={() => this.toggle(cell.image, cell.name)}
                            className="modalButtonList"
                          >
                            <img
                              alt={cell.name}
                              src={`/Images/${cell.image}`}
                              className="rdt_image rounded"
                            />
                          </Button>
                        ) : (
                          <img
                            alt={cell.name}
                            src={noImg}
                            className="rdt_image rdt_no_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Ürün Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Ürün Kodu</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.code}</td>
                              <th style={this.thStyle2}>Kategori Ad</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.categoryName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ürün Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>{row.name}</td>
                              <th style={this.thStyle2}>Yabancı Ürün Adı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.foreignName ? row.foreignName : "-"}</td>
                            </tr>

                            <tr>
                              <th style={this.thStyle}>Birim</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.unit === 1
                                  ? "Adet"
                                  : row.unit === 2
                                    ? "Metre"
                                    : row.unit === 3
                                      ? "Kilogram"
                                      : "-"}
                              </td>
                              <th style={this.thStyle2}>Durum</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.productStatus === 1
                                  ? "İthal"
                                  : row.productStatus === 2
                                    ? "Yerli"
                                    : row.productStatus === 3
                                      ? "Saf"
                                      : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th style={this.thStyle}>Alış Fiyatı</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.buyPrice > 0
                                  ? row.buyPrice.toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                  }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                      ? "$"
                                      : row.currencyType === 3
                                        ? "€"
                                        : row.currencyType === 4
                                          ? "SFr"
                                          : "-")
                                  : "-"}
                              </td>
                              <th style={this.thStyle2}>Satış Fiyatı</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.salePrice > 0
                                  ? row.salePrice.toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                  }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                      ? "$"
                                      : row.currencyType === 3
                                        ? "€"
                                        : row.currencyType === 4
                                          ? "SFr"
                                          : "-")
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Stok Durumu</th>
                              <td style={this.tdStyle}>
                                {row.stockStatus === 2
                                  ? "Stoklu Ürün (Zorunlu)"
                                  : row.stockStatus === 1
                                    ? "Stoklu Ürün"
                                    : "Stoksuz Ürün"}
                              </td>
                              <th style={this.thStyle2}>Kritik Stok</th>
                              <td>{row.criticalPieces}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td colSpan={3}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    width: "100px",
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, idx) => (
                      <CgnTextbox
                        type="number"
                        name="piece"
                        className="dataTableInput"
                        value={row.piece}
                        placeHolder="Adet"
                        maxLength="100"
                        autoComplete="off"
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        error={row.pieceError}
                      />
                    ),
                  },
                  {
                    width: "125px",
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, index) => (
                      <CgnButton
                        text={"Sepete Ekle"}
                        type="button"
                        className="w-100 ml-0"
                        color="warning"
                        onClick={() => this.handleAddToCart(row)}
                      />
                    ),
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return (
                          <CgnBadge
                            color="success"
                            className="w-100"
                            text="Aktif"
                          />
                        );
                      } else {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            text="Pasif"
                          />
                        );
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Kopyala",
                            className: "dropDownBtn",
                            onClick: () => this.handleCopyProduct(row.guid),
                          },
                          // {
                          //   text: "Sepete Ekle",
                          //   className: "dropDownBtn",
                          //   onClick: () => this.handleAddToCart(row.guid),
                          // },
                          {
                            text: "Barkod Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handleBarcodePrint(row.guid),
                          },
                          // {
                          //   text: "Ürün Yazdır",
                          //   className: "dropDownBtn",
                          //   onClick: () => this.handlePdfPrint(row.guid),
                          // },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default MachineProductList;
