import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Button } from "reactstrap";
import { URL } from "../../redux/actions/constants";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "./ContentTop";
import CgnMessage from "../toolbox/CgnMessage";
import grafik01 from "../../images/grafik-01.jpg";
import grafik02 from "../../images/grafik-02.jpg";
import grafik03 from "../../images/grafik-03.jpg";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class Home extends Component {
  state = {
    pageTitle: "Raporlar",
    breadcrumb: [{ text: "Raporlar", link: "#" }],
    isLoading: false,
    reportData: [],
    firstDate: "",
    lastDate: "",
    firstDate2: "",
    lastDate2: "",
    userGuid: "null",
    userArr: [],
    userError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: "Filtrele",
        disabled: this.isLoading,
        className: "mr-0",
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    const currentDate = new Date();
    const getMonth = currentDate.getMonth() + 1;
    const getFullYear = currentDate.getFullYear();
    var x = 0; // ay 1-2-3... diye gidiyor. 01-02-03 gitsin diye.
    var y = 0;
    var day = 0;
    if (getMonth > 9) {
      // ay 10-11-12 olduğunda sıkıntı olmasın diye.
      x = "";
      y = "";
    }
    if (
      getMonth === 1 ||
      getMonth === 3 ||
      getMonth === 5 ||
      getMonth === 7 ||
      getMonth === 8 ||
      getMonth === 10 ||
      getMonth === 12
    ) {
      day = 31;
    }
    if (getMonth === 3 || getMonth === 6 || getMonth === 9 || getMonth === 11) {
      day = 30;
    }
    if (getMonth === 2 && (getFullYear === 2016 || getFullYear === 2020 || getFullYear === 2024 || getFullYear === 2028)) {
      day = 29; //max
    }else if(getMonth === 2){
      day = 28; //max
    }
    var currentFirstDate = `${x}${getMonth}.01.${getFullYear}`;
    var currentLastDate = `${y}${getMonth}.${day}.${getFullYear}`;

    await this.setState({
      firstDate: currentFirstDate,
      lastDate: currentLastDate,
      firstDate2: currentFirstDate,
      lastDate2: currentLastDate,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/users/getlistuser", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          userArr: myArr,
        });
        this.updateData();
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
  }

  handleUserSelectChange(option) {
    if (option) {
      this.setState({
        userGuid: option.value,
        userError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        userGuid: "null",
      });
    }
  }

  async updateData() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(
        URL +
          "/reports/getlist/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate +
          "/" +
          this.state.userGuid,
        config
      )
      .then((response) => {
        this.setState({
          reportData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row className="report" id="list">
          <Col>
            <Card body>
              <Row>
                <Col lg="3">
                  <CgnDatePicker
                    name="firstDate2"
                    label="Başlangıç Tarihi [*]"
                    selected={this.state.firstDate2}
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    error={this.state.firstDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col lg="3">
                  <CgnDatePicker
                    name="lastDate2"
                    label="Bitiş Tarihi [*]"
                    selected={this.state.lastDate2}
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    error={this.state.lastDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col lg="5">
                  <CgnReactSelect
                    name="userGuid"
                    label="Kullanıcı [*]"
                    placeholder="Kullanıcı seçiniz..."
                    selectValue={this.state.userGuid}
                    isMulti={false}
                    isClearable={true}
                    disabled={this.state.isTrue}
                    onChange={(option) => this.handleUserSelectChange(option)}
                    options={this.state.userArr}
                    error={this.state.userError}
                  />
                </Col>
                <Col lg="1">
                  <Button
                    className="d-block w-100 mt-2 btnReport"
                    onClick={() => this.updateData()}
                  >
                    Listele
                  </Button>
                </Col>
              </Row>

              <Row>
                <CgnLoader visibled={this.state.isLoading} />
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
              </Row>

              {this.state.reportData !== null && (
                <>
                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          GELİR <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.incomeTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          GELİR <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.incomeTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          GELİR <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.incomeTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TAHSİLAT <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.collectionTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TAHSİLAT <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.collectionTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TAHSİLAT <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.collectionTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TAHSİLAT TAKVİMİ <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.collectionCalendarTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TAHSİLAT TAKVİMİ <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.collectionCalendarTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TAHSİLAT TAKVİMİ <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.collectionCalendarTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          SATIŞ <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.productOutTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          SATIŞ <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.productOutTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          SATIŞ <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.productOutTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          SATIŞ ÜRÜN KALEM ADEDİ
                        </h5>
                        <div className="reportTotal">
                          {this.state.reportData.productOutItemCount}
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          SATIŞ ÜRÜN ADEDİ
                        </h5>
                        <div className="reportTotal">
                          {this.state.reportData.productOutTotalPiece}
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TEKLİF <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.offerTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TEKLİF <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.offerTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TEKLİF <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.offerTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TEKLİF ÜRÜN KALEM ADEDİ
                        </h5>
                        <div className="reportTotal">
                          {this.state.reportData.offerItemCount}
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleGreen">
                          TEKLİF ÜRÜN ADEDİ
                        </h5>
                        <div className="reportTotal">
                          {this.state.reportData.offerTotalPiece}
                        </div>
                        <img src={grafik01} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          GİDER <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.costTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          GİDER <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.costTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          GİDER <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.costTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ÖDEME <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.paymentTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ÖDEME <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.paymentTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ÖDEME <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.paymentTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ÖDEME TAKVİMİ <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.paymentCalendarTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ÖDEME TAKVİMİ <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.paymentCalendarTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ÖDEME TAKVİMİ <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.paymentCalendarTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ALIŞ <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.productEntryTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ALIŞ <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.productEntryTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">
                          ALIŞ <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.productEntryTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">ALIŞ ÜRÜN KALEM ADEDİ</h5>
                        <div className="reportTotal">
                          {this.state.reportData.productEntryItemCount}
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle">ALIŞ ÜRÜN ADEDİ</h5>
                        <div className="reportTotal">
                          {this.state.reportData.productEntryTotalPiece}
                        </div>
                        <img src={grafik02} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleOrange">
                          İADE <span>(₺)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.returnTotalTL
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>₺</span>
                        </div>
                        <img src={grafik03} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleOrange">
                          İADE <span>($)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.returnTotalDolar
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>$</span>
                        </div>
                        <img src={grafik03} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleOrange">
                          İADE <span>(€)</span>
                        </h5>
                        <div className="reportTotal">
                          {parseFloat(
                            this.state.reportData.returnTotalEuro
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          })}{" "}
                          <span>€</span>
                        </div>
                        <img src={grafik03} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleOrange">
                          İADE ÜRÜN KALEM ADEDİ
                        </h5>
                        <div className="reportTotal">
                          {this.state.reportData.returnItemCount}
                        </div>
                        <img src={grafik03} alt="x" className="grafik01" />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="reportCircle reportSuccess">
                        <h5 className="reportTitle reportTitleOrange">
                          İADE ÜRÜN ADEDİ
                        </h5>
                        <div className="reportTotal">
                          {this.state.reportData.returnTotalPiece}
                        </div>
                        <img src={grafik03} alt="x" className="grafik01" />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
