import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const CgnTextbox = ({
  type,
  name,
  label,
  onChange,
  onKeyPress,
  placeHolder,
  value,
  maxLength,
  max,
  min,
  error,
  autoComplete,
  disabled,
  style,
  inlineStyle,
  readOnly,
  className,
  hidden,
  onWheel,
  step,
  rows,
  cols,
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <FormGroup className={wrapperClass} style={style} hidden={hidden}>
    <Label className="font-weight-bolder" for={name} hidden={hidden}>
        {label}
      </Label>
      <Input
        type={type}
        name={name}
        id={name}
        value={value}
        maxLength={maxLength}
        placeholder={placeHolder}
        onChange={onChange}
        //onKeyPress={onKeyPress}
        onKeyPress={type !== "textarea" ? (e) => { e.key === 'Enter' && e.preventDefault(); } : onKeyPress}
        autoComplete={autoComplete}
        disabled={disabled}
        max={max}
        min={min}
        readOnly= {readOnly}
        onWheel={(e) => e.target.blur()}
        style={inlineStyle}
        className={className}
        hidden={hidden}
        rows={rows}
        cols={cols}
        //step={step}
      />
      {error && !hidden && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
};

export default CgnTextbox;
