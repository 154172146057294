import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  RemindType,
  textAreaStyle,
  
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import EmployeeAddUpdate from "../employee/EmployeeAddUpdate";
import CgnButton from "../toolbox/CgnButton";
import CgnTextEditor from "../toolbox/CgnTextEditor";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { confirmAlert } from "react-confirm-alert";

class RemindAddUpdate extends Component {
  state = {
    pageTitle: "Hatırlatma Ekle",
    pageTitleAdd: "Hatırlatma Ekle",
    pageTitleUpdate: "Hatırlatma Güncelle",
    pageAddLink: "hatirlatma-ekle",
    pageListLink: "hatirlatma-listele",
    breadcrumb: [{ text: "Hatırlatma", link: "#" }],
    isLoading: false,
    urlSplit: [],
    remindType: "",
    descriptionData: [],
    remindDate: "",
    userGuid: null,
    title: "",
    description: "",
    descriptionHtml: "",
    status: true,
    remindTypeError: "",
    remindDateError: "",
    userError: "",
    titleError: "",
    descriptionError: "",
    guid: "",
    alertErrorMessage: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    isOpenModalEmployee: false,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/users/getlistuser", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          userArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/reminds/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              remindType: response.data.remindType,
              remindDate: response.data.remindDate,
              userGuid: response.data.userGuid,
              title: response.data.title,
              descriptionHtml: response.data.description,
              description: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(response.data.description)
                )
              ),
              status: response.data.status,
              isLoading: false,
            });
            if (
              response.data.remindType === 1 ||
              response.data.remindType === 2
            ) {
              this.setState({
                isTrue: true,
              });
            } else if (response.data.remindType === 3) {
              this.setState({
                isTrue: false,
              });
            }
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        remindType: "",
        remindDate: "",
        userGuid: null,
        title: "",
        description: "",
        descriptionHtml: "",
        status: true,
        isTrue: false,
      });
    }
    if (
      this.state.isOpenModalEmployee &&
      this.props.location.modalAddedEmployee !== undefined &&
      this.props.location.modalAddedEmployee.isModal !== undefined &&
      this.props.location.modalAddedEmployee.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/users/getlistuser", config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            userArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedEmployee.data &&
              element.name === this.props.location.modalAddedEmployee.data
            ) {
              this.setState({
                userGuid: element.guid,
                userError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedEmployee.isModal = true;
      this.setState({
        isOpenModalEmployee: false,
      });
    }
  }

  handleEditorChange = (editorState, editorStateHtml) => {
    if (editorStateHtml) {
      this.setState({
        description: editorState,
        descriptionHtml: editorStateHtml,
        descriptionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      remindTypeError: "",
      remindDateError: "",
      userError: "",
      titleError: "",
      descriptionError: "",
      alertErrorMessage: "",
    });
    if (name === "remindDate" && value === "") {
      this.setState({
        remindDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: this.include,
      });
    }
    if (name === "userGuid" && value === null) {
      this.setState({
        userError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "title" && value === "") {
      this.setState({
        titleError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "description" && value === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleRemindTypeSelectChange(option) {
    if (option) {
      this.setState({
        remindType: option.value,
        remindTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 2) {
        this.setState({
          isTrue: true,
          userGuid: null,
          userError: "",
          alertErrorMessage: "",
        });
      } else {
        this.setState({
          isTrue: false,
          userError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    } else {
      this.setState({
        remindType: "",
        userGuid: null,
        isTrue: false,
        remindTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleUserSelectChange(option) {
    if (option) {
      this.setState({
        userGuid: option.value,
        userError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        userGuid: null,
        userError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDateChange(remindDate) {
    if (remindDate) {
      this.setState({
        remindDate: remindDate,
        remindDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        remindDate: "",
        remindDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  addModalEmployee = () => {
    this.setState({
      isOpenModalEmployee: !this.state.isOpenModalEmployee,
    });
  };

  clearPage() {
    this.setState({
      remindType: "",
      remindDate: "",
      userGuid: null,
      title: "",
      description: "",
      status: true,
      isTrue: false,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      remindTypeError: CgnMessage.textErrorMessage,
      remindDateError: CgnMessage.textErrorMessage,
      userError: CgnMessage.textErrorMessage,
      titleError: CgnMessage.textErrorMessage,
      descriptionError: CgnMessage.textErrorMessage,
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      remindTypeError: "",
      remindDateError: "",
      userError: "",
      titleError: "",
      descriptionError: "",
      alertErrorMessage: "",
    });

    if (this.state.remindType === "") {
      this.setState({
        remindTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.remindDate === "") {
      this.setState({
        remindDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      (this.state.remindType === 3 || this.state.remindType === "") &&
      this.state.userGuid === null
    ) {
      this.setState({
        userError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.title === "") {
      this.setState({
        titleError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.descriptionHtml === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.remindType &&
      this.state.remindDate &&
      this.state.title &&
      this.state.descriptionHtml &&
      ((this.state.remindType === 3 && this.state.userGuid !== null) ||
        (this.state.remindType === 1 && this.state.userGuid === null) ||
        this.state.remindType === 2)
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        remindType: this.state.remindType,
        remindDate: moment(this.state.remindDate).format("DD/MM/YYYY"),
        userGuid: this.state.userGuid,
        title: this.state.title,
        description: this.state.descriptionHtml,
        status: this.state.status,
      };
      var URLParam = "/reminds/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/reminds/update";
        myObj = {
          guid: this.state.guid,
          remindType: this.state.remindType,
          remindDate:
            this.state.remindDate &&
            this.state.remindDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.remindDate.split("/")[2] +
                    "-" +
                    this.state.remindDate.split("/")[1] +
                    "-" +
                    this.state.remindDate.split("/")[0]
                )
              : moment(this.state.reminderDate).format("DD/MM/YYYY"),
          userGuid: this.state.userGuid,
          title: this.state.title,
          description: this.state.descriptionHtml,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          confirmAlert({
            title: CgnMessage.alertSuccessTitle,
            message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  if (this.state.guid) {
                    window.location.href = "/hatirlatma-listele";
                  } else {
                    window.location.reload();
                  }
                }
              },
            ],
          });
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="remindDate"
                      label="Tarih [*]"
                      selected={
                        this.state.remindDate &&
                        this.state.remindDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.remindDate.split("/")[2] +
                                "-" +
                                this.state.remindDate.split("/")[1] +
                                "-" +
                                this.state.remindDate.split("/")[0]
                            )
                          : this.state.remindDate
                      }
                      onChange={(remindDate) =>
                        this.handleDateChange(remindDate)
                      }
                      error={this.state.remindDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9"></Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="remindType"
                      label="Hatırlatma Tipi [*]"
                      placeholder="Hatırlatma tipi seçiniz..."
                      selectValue={this.state.remindType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleRemindTypeSelectChange(option)
                      }
                      options={RemindType}
                      error={this.state.remindTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="userGuid"
                          label="Kullanıcı [*]"
                          placeholder="Kullanıcı seçiniz..."
                          selectValue={this.state.userGuid}
                          isMulti={false}
                          isClearable={true}
                          disabled={this.state.isTrue}
                          onChange={(option) =>
                            this.handleUserSelectChange(option)
                          }
                          options={this.state.userArr}
                          error={this.state.userError}
                        />
                      </div>
                      <div className="p-0 flexAddButton">
                        <CgnButton
                          type="button"
                          color="secondary"
                          className="modalSelectAddButton"
                          onClick={() => this.addModalEmployee()}
                          text={"+"}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6"></Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="title"
                      label="Başlık [*]"
                      value={this.state.title}
                      placeHolder="Lütfen başlık girin..."
                      autoComplete="off"
                      //maxLength="511"
                      onChange={this.handleChange}
                      error={this.state.titleError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextEditor
                      name="description"
                      label="Açıklama [*]"
                      editorState={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      onChange={(editorState) =>
                        this.handleEditorChange(
                          editorState,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                      }
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalEmployee}
          toggle={this.addModalEmployee}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Kullanıcı Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <EmployeeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalEmployee}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default RemindAddUpdate;
