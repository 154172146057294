import React from "react";
import Loader from "react-loader-spinner";

const CgnLoader = ({ visibled }) => {
  return (
    <>
      {visibled && (
        <div className="loader">
          <Loader type="TailSpin" color="#1d4b8c" height={50} width={50} />
          <p>İşlem devam ediyor, lütfen bekleyin!</p>
        </div>
      )}
    </>
  );
};

export default CgnLoader;
