import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import ContentTop from "../root/ContentTop";

import { withRouter } from "react-router-dom";
import pdfIcon from "../../images/pdfIcon.png";

class InstructionsCSSavunma extends Component {
  state = {
    pageTitle: "Talimatlar",
    pageListLink: "talimatlar-cs-savunma",
    breadcrumb: [{ text: "CS Savunma", link: "#" }],
    isLoading: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <div className="procedure">
          <Row>
            <Col lg="2">
              <a href="/Files/t01.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.01 Lehimleme Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t02.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.02 Crimp Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t03.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.03 Crimp TOOL-AF8-AFM8</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t04.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.04 Açık Ağız Kontakların Sonlandırılması</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t06.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.06 Crimp Splice Tool Kullanma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t07.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.07 CTI6-HTN21-PZ6-PZ16-PZ50 Tool Kullanma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t08.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.08 RJ45 Kullanma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t09.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.09 S1125 Kullanımı Soket Arkası Kılıf Braşman Büzüştürme Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t10.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.10 Kablaj Dallandırılmalarında Ekran Sonlandırılması</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t11.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.11 Tinel Lock Halka Daraltma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t12.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.12 Band It Tool İle Ekran Sonlandırma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t13.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.13 Isı İle Daralan Makaron Etiket Şeffaf Büzüştürme Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t14.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.14 Backsell Torgmetre</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t15.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.15 Jam Nut Sıkma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t16.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.16 Konnektör Pin Geri Kaçma Testi Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t17.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.17 Havya Doğrulama Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t18.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.18 Crımp Aleti Doğrulama</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t19.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.19 Kontak Çekme Testi Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t20.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.20  Pota Kullanma Ve Bakım Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t21.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.21 Şerit Metre Dogrulama Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t22.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.22 Esd Kontrol Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t23.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.23 Kablo Takımları Sızdırmazlık Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t24.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.24 Raf Ömürlü Kimyasal Ürün Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t25.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.25 Malzeme Giriş Kontrol Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t26.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.26 Depolama Ve Muhafaza Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t27.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.27 Paketleme  Ve Sevk Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t28.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.28 Stok Kodu Ve Malzeme Tanımlama Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t30.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.30 Kalite Kontrol Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t31.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.31 Müşteri Ve Tedarikçi Mülkiyeti Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t32.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.32 Sayım Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t33.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.33 Tedarikçi Seçme Ve Değerlendirme Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t34.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.34 Döküman Oluşturma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t35.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.35 Yangın Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t36.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.36 COAXSTRIP5200 Uç Açma Makinası Kullanma Ve Temizlik Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t38.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.38 Solder Sleeve Daraltma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t39.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.39  Kompresör Makinesi Bakım Kullanım Ve İş Güvenliği Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t40.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.40 Kablo Uç Açma El Aleti Doğrulama Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t41.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.41 Kişisel Koruyucu Donanım Kullanımı Ve Genel Talimatlar</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t42.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.42 Elektrik İşlerinde İş Sağlığı Ve Güvenliği Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t43.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.43 Sıcaklık Nem İzleme Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t44.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.44 Ysc Kontrol Ve Bakım Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t45.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.45 Göz Solüsyonu Kullanma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t46.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.46 Yetkinlik Bazlı Performans Değerlendirme Kriterlerinin Açıklanması Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t48.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.48 Neopran Madde</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t49.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.49 Kablo Örme Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t50.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.50  S1030 Yapıştırıcı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t52.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.52 S1125 Kürleme</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t53.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.53  FS 600 H Poşet Yapıştırma</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t54.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.54 Dozlama Cihazı Kullanma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t55.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.55 Infrared Isıtıcı Kullanım Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t56.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.56 Makaron Kesme Cihazı Kullanım Ve Bakım Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t57.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.57 Easy Touch Pro Cırrıs Test Cihazı - Kullanım Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t58.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.58 Fai Doldurma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t59.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.59 Pin Geri Kaçma Testi Doğrulama Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t60.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.60 Elle Malzeme Taşıma Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t61.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.61 Ecostrip 9380 Otomatik Kesme Sıyırma Makinesi Temizlik Ve Bakım Talimatı</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/t62.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>T.62 Esd Bileziği Ve Topukluğu Testleri Kullanma Talimatı</h6>
              </a>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(InstructionsCSSavunma);
