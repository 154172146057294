import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import ContentTop from "../root/ContentTop";

import { withRouter } from "react-router-dom";
import pdfIcon from "../../images/pdfIcon.png";

class ProcedureCSSavunma extends Component {
  state = {
    pageTitle: "Prosedürler",
    pageListLink: "prosedurler-cs-savunma",
    breadcrumb: [{ text: "CS Savunma", link: "#" }],
    isLoading: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <div className="procedure">
          <Row>
            <Col lg="2">
              <a href="/Files/p01.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.01 Yönetim Gözden Geçirme Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p02.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.02 Dokümanların Kontrolü Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p03.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.03 Kayıtların Kontrolü Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p04.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.04 İnsan Kaynakları Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p05.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.05 Eğitim Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p06.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.06 Satınalma ve stok yönetimi</h6>
              </a>
            </Col>
          </Row>
          <Row>
            <Col lg="2">
              <a href="/Files/p07.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.07 Kalibrasyon ve Bakım Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p08.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.08 Satış ve Pazarlama Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p09.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.09 Uygunsuzluk ve DF Proseduru</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p10.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.10 İç Tetkik Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p11.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.11 Performans Ölçüm ve Analiz Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p12.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.12 Kaynak Yönetimi Prosedürü</h6>
              </a>
            </Col>
          </Row>
          <Row>
            <Col lg="2">
              <a href="/Files/p13.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.13 Risk Yönetim Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p14.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.14 İletişim Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p15.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.15 Sürekli İyileştirme Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p16.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.16 Değişikliklerin Planlanması Ve Kontrolü Prosedür</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p17.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.17 İzlenebilirlik Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p18.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.18 Özel Süreçler Prosedürü</h6>
              </a>
            </Col>
          </Row>
          <Row>
            <Col lg="2">
              <a href="/Files/p19.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.19 İlk Ürün Denetimi (FAİ) Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p20.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.20 Kişisel Verilerin Korunması Prosedürü</h6>
              </a>
            </Col>
            <Col lg="2">
              <a href="/Files/p21.pdf" target="_blank">
                <img src={pdfIcon} alt="PDF" className="pdfIcon" />
                <h6>P.21 Konfigürasyon Yönetimi Prosedürü</h6>
              </a>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(ProcedureCSSavunma);
