import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonLink from "../toolbox/CgnButtonLink";

class BankList extends Component {
  state = {
    pageTitle: "Banka Hareketleri",
    breadcrumb: [{ text: "Banka", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    bankMovementData: [],
    entryTotal: 0,
    outTotal: 0,
    openingBalance: "",
    guid: "",
    alertErrorMessage: "",
    detail: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "banka-detay") {
      this.setState({
        detail: true,
      });
    }
    await axios
      .get(URL + "/banks/bankmovements/" + urlSplit[2], config)
      .then((response) => {
        this.setState({
          bankMovementData: response.data,
          isLoading: false,
        });
        var entryTotal = 0;
        var outTotal = 0;
        response.data.forEach((element) => {
          entryTotal += element.type === 2 ? element.total : 0;
          outTotal += element.type === 1 ? element.total : 0;
        });
        this.setState({
          entryTotal: entryTotal,
          outTotal: outTotal,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "85px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "250px",
  };

  render() {
    const remainingTotal = this.state.entryTotal + this.state.outTotal; //  outTotal zaten (-) geliyor. (Aslında bu çıkarma işlemi)
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
          />
        )}
        <Row className="bankMovementList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.bankMovementData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Banka Hareketleri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tr>
                            <th style={this.thStyle}>Tarih</th>
                            <th className="colon">:</th>
                            <td style={this.tdStyle}>{row.date}</td>
                            <th style={this.thStyle}>Kişi/Firma</th>
                            <th className="colon">:</th>
                            <td colSpan={10}>{row.name}</td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>Giriş Tutarı</th>
                            <th className="colon">:</th>
                            <td style={this.tdStyle}>
                              {row.type === 2
                                ? `${row.total.toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                  })} TL`
                                : "-"}
                            </td>
                            <th style={this.thStyle}>Çıkış Tutarı</th>
                            <th className="colon">:</th>
                            <td colSpan={4} style={this.tdStyle2}>
                              {row.type === 1
                                ? `${row.total.toLocaleString("tr-TR", {
                                    minimumFractionDigits: 2,
                                  })} TL`
                                : "-"}
                            </td>
                          </tr>
                        </Table>
                      );
                    },
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="clearfix">
              <div className="float-right ListDownButton">
                Toplam Kalan Tutar:{" "}
                {remainingTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                TL
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Çıkış Tutarı:{" "}
                {this.state.outTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                TL
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Giriş Tutarı:{" "}
                {this.state.entryTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                TL
              </div>
            </div>
            {this.state.detail === false && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text="Geri"
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default BankList;
