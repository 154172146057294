import React, { Component } from "react";
import { Row, Col, Card,Table  } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";

class MachineList extends Component {
  state = {
    pageTitle: "Makine Listele",
    pageAddLink: "makine-ekle",
    pageUpdateLink: "makine-guncelle",
    pageDetailLink: "makine-detay",
    pageListLink: "makine-listele",
    breadcrumb: [{ text: "Makine", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    modelData: [],
    guid: "",
    canalName: "",
    brandName: "",
    modelName: "",
    machineSerialNo: "",
    customsEntryDate: "",
    refrigeratorModel: "",
    coolerSerialNo: "",
    vendonSerialNo: "",
    standModel: "",
    standDimensions: "",
    purifierBrand: "",
    alertErrorMessage: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    axios
      .get(URL + "/machines/getlist/" + statusType, config)
      .then((response) => {
        this.setState({
          modelData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/machines/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "155px",
  };

  tdStyle = {
    width: "250px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="machineList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.modelData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Makine Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Kanal</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.canalName}
                              </td>
                              <th style={this.thStyle2}>Marka</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.brandName}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Model</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.modelName}
                              </td>
                              <th style={this.thStyle2}>Makine Seri No</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.machineSerialNo}
                              </td>
                            </tr>
                            <tr>
                            <th style={this.thStyle}>Buzdolabı Model</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.refrigeratorModel ? row.refrigeratorModel : "-"}
                              </td>
                              <th style={this.thStyle2}>Soğutucu Seri No</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.coolerSerialNo ? row.coolerSerialNo : "-"}
                              </td>
                            </tr>     
                            <tr>
                            <th style={this.thStyle}>Vendon Seri No</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.vendonSerialNo ? row.vendonSerialNo : "-"}
                              </td>
                              <th style={this.thStyle2}>Stand Model</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.standModel ? row.standModel : "-"}
                              </td>
                            </tr>        
                            <tr>
                            <th style={this.thStyle}>Stand Ebatı</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.standDimensions ? row.standDimensions : "-"}
                              </td>
                              <th style={this.thStyle2}>Arıtma Cihazı Marka</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.purifierBrand ? row.purifierBrand : "-"}
                              </td>
                            </tr>     
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td colSpan={4}>{row.createdBy}</td>
                            </tr>                      
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineList;
