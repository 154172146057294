import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
} from "../../redux/actions/constants";

class ProductCountDetail extends Component {
  state = {
    pageTitle: "Sayım Detay",
    pageTitleDetail: "Sayım Detay",
    pageAddLink: "sayim-ekle",
    pageListLink: "sayim-listele",
    breadcrumb: [{ text: "Sayım", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    locationName: "",
    locationGuid: null,
    productCountProductDto: [],
    totalPiece: "",
    productName: "",
    piece: null,
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/productCount/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            productCountProductDto: response.data.productCountProductDto,
            locationName: response.data.locationName,
            locationGuid: response.data.locationGuid,          
            status: response.data.status,
            totalPiece: response.data.totalPiece,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">{this.state.locationName}</td>
                  </tr>     
                  <tr>
                    <th>Ekleyen Kullanıcı</th>
                    <td colSpan="4">{this.state.createdBy}</td>
                  </tr>
                  <tr>
                    <th>Eklenen Tarih</th>
                    <td colSpan="4">{this.state.createdAt}</td>
                  </tr>          
                </tbody>
              </Table>

              {this.state.productCountProductDto !== null &&
                this.state.productCountProductDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th>Ürün Adı</th>
                      <th className="text-center">Adet</th>
                    </tr>
                    {this.state.productCountProductDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.productName}</td>
                          <td className="text-center">{item.piece}</td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductCountDetail;
