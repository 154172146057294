import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Home from "./Home";
import CompanyInfoUpdate from "../companyInfoUpdate/CompanyInfoUpdate";
import UserInfoUpdate from "../employeeToUser/UserInfoUpdate";
import Logout from "../user/Logout";
import CompanyUserGroupAddUpdate from "../companyUserGroup/CompanyUserGroupAddUpdate";
import CompanyUserGroupDetail from "../companyUserGroup/CompanyUserGroupDetail";
import CompanyUserGroupList from "../companyUserGroup/CompanyUserGroupList";
import CalendarList from "../calendar/CalendarList";
import CalendarListUser from "../calendar/CalendarListUser";
import RemindAddUpdate from "../remind/RemindAddUpdate";
import RemindDetail from "../remind/RemindDetail";
import RemindList from "../remind/RemindList";
import EmployeeAddUpdate from "../employee/EmployeeAddUpdate";
import EmployeeDetail from "../employee/EmployeeDetail";
import EmployeeList from "../employee/EmployeeList";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";
import SupplierDetail from "../supplier/SupplierDetail";
import SupplierList from "../supplier/SupplierList";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import CustomerDetail from "../customer/CustomerDetail";
import CustomerList from "../customer/CustomerList";
import BankAddUpdate from "../bank/BankAddUpdate";
import BankDetail from "../bank/BankDetail";
import BankList from "../bank/BankList";
import BankMovementList from "../bank/BankMovementList";
import CostTypeAddUpdate from "../costType/CostTypeAddUpdate";
import CostTypeDetail from "../costType/CostTypeDetail";
import CostTypeList from "../costType/CostTypeList";
import CategoryAddUpdate from "../category/CategoryAddUpdate";
import CategoryDetail from "../category/CategoryDetail";
import CategoryList from "../category/CategoryList";
import ProductPropertyTypeDetail from "../productPropertyType/ProductPropertyTypeDetail";
import ProductPropertyTypeAddUpdate from "../productPropertyType/ProductPropertyTypeAddUpdate";
import ProductPropertyTypeList from "../productPropertyType/ProductPropertyTypeList";
import ProductAddUpdate from "../product/ProductAddUpdate";
import ProductDetail from "../product/ProductDetail";
import ProductList from "../product/ProductList";
import CriticalProductList from "../product/CriticalProductList";
import ProductEntryAddUpdate from "../productEntry/ProductEntryAddUpdate";
import ProductEntryDetail from "../productEntry/ProductEntryDetail";
import ProductEntryList from "../productEntry/ProductEntryList";
import ProductOutAddUpdate from "../productOut/ProductOutAddUpdate";
import ProductOutDetail from "../productOut/ProductOutDetail";
import ProductOutList from "../productOut/ProductOutList";
import ProductReturnAddUpdate from "../productReturn/ProductReturnAddUpdate";
import ProductReturnDetail from "../productReturn/ProductReturnDetail";
import ProductReturnList from "../productReturn/ProductReturnList";
import CostAddUpdate from "../cost/CostAddUpdate";
import CostDetail from "../cost/CostDetail";
import CostList from "../cost/CostList";
import PaymentCalendarList from "../cost/PaymentCalendarList";
import PaymentAddUpdate from "../payment/PaymentAddUpdate";
import PaymentDetail from "../payment/PaymentDetail";
import PaymentList from "../payment/PaymentList";
import IncomeAddUpdate from "../income/IncomeAddUpdate";
import IncomeDetail from "../income/IncomeDetail";
import IncomeList from "../income/IncomeList";
import CollectionCalendarList from "../income/CollectionCalendarList";
import CollectionAddUpdate from "../collection/CollectionAddUpdate";
import CollectionDetail from "../collection/CollectionDetail";
import CollectionList from "../collection/CollectionList";
import OfferCoverAddUpdate from "../offerCover/OfferCoverAddUpdate";
import OfferCoverDetail from "../offerCover/OfferCoverDetail";
import OfferCoverList from "../offerCover/OfferCoverList";
import OfferAddUpdate from "../offer/OfferAddUpdate";
import OfferDetail from "../offer/OfferDetail";
import OfferList from "../offer/OfferList";
import NoteAddUpdate from "../notes/NoteAddUpdate";
import NoteDetail from "../notes/NoteDetail";
import NoteList from "../notes/NoteList";
import FooterContent from "./FooterContent";
import CompanyAddUpdate from "../company/CompanyAddUpdate";
import CompanyList from "../company/CompanyList";
import CompanyDetail from "../company/CompanyDetail";
import ContractAddUpdate from "../contract/ContractAddUpdate";
import ContractDetail from "../contract/ContractDetail";
import ContractList from "../contract/ContractList";
import DocumentTypeAddUpdate from "../documentType/DocumentTypeAddUpdate";
import DocumentTypeDetail from "../documentType/DocumentTypeDetail";
import DocumentTypeList from "../documentType/DocumentTypeList";
import ServiceAddUpdate from "../service/ServiceAddUpdate";
import ServiceDetail from "../service/ServiceDetail";
import ServiceList from "../service/ServiceList";
import ServiceListNext from "../service/ServiceListNext";
import DocumentAddUpdate from "../document/DocumentAddUpdate";
import SaleRegionAddUpdate from "../saleRegion/SaleRegionAddUpdate";
import SaleRegionDetail from "../saleRegion/SaleRegionDetail";
import SaleRegionList from "../saleRegion/SaleRegionList";
import DocumentList from "../document/DocumentList";
import DocumentDetail from "../document/DocumentDetail";
import LocationAddUpdate from "../location/LocationAddUpdate";
import LocationDetail from "../location/LocationDetail";
import LocationList from "../location/LocationList";
import PersonAddUpdate from "../person/PersonAddUpdate";
import PersonDetail from "../person/PersonDetail";
import PersonList from "../person/PersonList";
import PersonGroupAddUpdate from "../personGroup/PersonGroupAddUpdate";
import PersonGroupDetail from "../personGroup/PersonGroupDetail";
import PersonGroupList from "../personGroup/PersonGroupList";
import OfferDefinitionAddUpdate from "../offerDefinition/OfferDefinitionAddUpdate";
import OfferDefinitionDetail from "../offerDefinition/OfferDefinitionDetail";
import OfferDefinitionList from "../offerDefinition/OfferDefinitionList";
import FaultStatusAddUpdate from "../faultStatus/FaultStatusAddUpdate";
import FaultStatusDetail from "../faultStatus/FaultStatusDetail";
import FaultStatusList from "../faultStatus/FaultStatusList";
import ServiceStatusAddUpdate from "../serviceStatus/ServiceStatusAddUpdate";
import ServiceStatusDetail from "../serviceStatus/ServiceStatusDetail";
import ServiceStatusList from "../serviceStatus/ServiceStatusList";
import SaleTypeAddUpdate from "../saleType/SaleTypeAddUpdate";
import SaleTypeDetail from "../saleType/SaleTypeDetail";
import SaleTypeList from "../saleType/SaleTypeList";
import ComplaintReasonAddUpdate from "../complaintReason/ComplaintReasonAddUpdate";
import ComplaintReasonDetail from "../complaintReason/ComplaintReasonDetail";
import ComplaintReasonList from "../complaintReason/ComplaintReasonList";
import GuarantyTypeAddUpdate from "../guarantyType/GuarantyTypeAddUpdate";
import GuarantyTypeDetail from "../guarantyType/GuarantyTypeDetail";
import GuarantyTypeList from "../guarantyType/GuarantyTypeList";
import BrandAddUpdate from "../brand/BrandAddUpdate";
import BrandDetail from "../brand/BrandDetail";
import BrandList from "../brand/BrandList";
import ProcessTypeAddUpdate from "../processType/ProcessTypeAddUpdate";
import ProcessTypeDetail from "../processType/ProcessTypeDetail";
import ProcessTypeList from "../processType/ProcessTypeList";
import ModelAddUpdate from "../model/ModelAddUpdate";
import ModelDetail from "../model/ModelDetail";
import ModelList from "../model/ModelList";
import CanalAddUpdate from "../canal/CanalAddUpdate";
import CanalDetail from "../canal/CanalDetail";
import CanalList from "../canal/CanalList";
import MachineAddUpdate from "../machine/MachineAddUpdate";
import MachineDetail from "../machine/MachineDetail";
import MachineList from "../machine/MachineList";
import MachineCustomerAddUpdate from "../machineCustomer/MachineCustomerAddUpdate";
import MachineCustomerDetail from "../machineCustomer/MachineCustomerDetail";
import MachineCustomerList from "../machineCustomer/MachineCustomerList";
import MachineCustomer2AddUpdate from "../machineCustomer2/MachineCustomer2AddUpdate";
import MachineCustomer2Detail from "../machineCustomer2/MachineCustomer2Detail";
import MachineCustomer2List from "../machineCustomer2/MachineCustomer2List";
import MachinePersonelAddUpdate from "../machinePersonel/MachinePersonelAddUpdate";
import MachinePersonelDetail from "../machinePersonel/MachinePersonelDetail";
import MachinePersonelList from "../machinePersonel/MachinePersonelList";
import AssignmentAddUpdate from "../assignment/AssignmentAddUpdate";
import AssignmentDetail from "../assignment/AssignmentDetail";
import AssignmentList from "../assignment/AssignmentList";
import ProductCountAddUpdate from "../productCount/ProductCountAddUpdate";
import ProductCountDetail from "../productCount/ProductCountDetail";
import ProductCountList from "../productCount/ProductCountList";
import ProductCountGetList from "../productCountGetList/ProductCountGetList";
import ProductCountGetDetail from "../productCountGetList/ProductCountGetDetail";
import MachineServiceAddUpdate from "../machineService/MachineServiceAddUpdate";
import MachineServiceDetail from "../machineService/MachineServiceDetail";
import MachineServiceList from "../machineService/MachineServiceList";
import ListIncomingInvoice from "../bill/ListIncomingInvoice";
import IncomingInvoiceDetail from "../bill/IncomingInvoiceDetail";
import ListOutgoingInvoice from "../bill/ListOutgoingInvoice";
import OutgoingInvoiceDetail from "../bill/OutgoingInvoiceDetail";
import AssignmentPersonelList from "../assignmentPersonel/AssignmentPersonelList";
import AssignmentPersonelDetail from "../assignmentPersonel/AssignmentPersonelDetail";
import AssignmentPersonelCompleteList from "../assignmentPersonel/AssignmentPersonelCompleteList";
import AssignmentPersonelCompleteDetail from "../assignmentPersonel/AssignmentPersonelCompleteDetail";
import AreaAddUpdate from "../area/AreaAddUpdate";
import AreaDetail from "../area/AreaDetail";
import AreaList from "../area/AreaList";
import MachineReportList from "../machineReport/MachineReportList";
import ServiceTypeAddUpdate from "../serviceType/ServiceTypeAddUpdate";
import ServiceTypeDetail from "../serviceType/ServiceTypeDetail";
import ServiceTypeList from "../serviceType/ServiceTypeList";
import OrderAddUpdate from "../order/OrderAddUpdate";
import OrderDetail from "../order/OrderDetail";
import OrderList from "../order/OrderList";
import PolicyAddUpdate from "../policy/PolicyAddUpdate";
import PolicyDetail from "../policy/PolicyDetail";
import PolicyList from "../policy/PolicyList";
import PolicyCreatedList from "../policy/PolicyCreatedList";
import PolicyListNext from "../policy/PolicyListNext";
import KingUserAddUpdate from "../kingUser/KingUserAddUpdate";
import KingUserDetail from "../kingUser/KingUserDetail";
import KingUserList from "../kingUser/KingUserList";
import DescriptionAddUpdate from "../description/DescriptionAddUpdate";
import DescriptionDetail from "../description/DescriptionDetail";
import DescriptionList from "../description/DescriptionList";
import TaskCategoryAddUpdate from "../taskCategory/TaskCategoryAddUpdate";
import TaskCategoryDetail from "../taskCategory/TaskCategoryDetail";
import TaskCategoryList from "../taskCategory/TaskCategoryList";
import TaskPriorityAddUpdate from "../taskPriority/TaskPriorityAddUpdate";
import TaskPriorityDetail from "../taskPriority/TaskPriorityDetail";
import TaskPriorityList from "../taskPriority/TaskPriorityList";
import TaskSituationAddUpdate from "../taskSituation/TaskSituationAddUpdate";
import TaskSituationDetail from "../taskSituation/TaskSituationDetail";
import TaskSituationList from "../taskSituation/TaskSituationList";
import TaskAddUpdate from "../task/TaskAddUpdate";
import TaskDetail from "../task/TaskDetail";
import TaskList from "../task/TaskList";
import TaskPersonelList from "../task/TaskPersonelList";
import TaskPersonelDetail from "../task/TaskPersonelDetail";
import HomePage from "../homePage/HomePage";
import OrderCoverAddUpdate from "../orderCover/OrderCoverAddUpdate";
import OrderCoverDetail from "../orderCover/OrderCoverDetail";
import OrderCoverList from "../orderCover/OrderCoverList";
import TypeAddUpdate from "../type/TypeAddUpdate";
import TypeDetail from "../type/TypeDetail";
import TypeList from "../type/TypeList";
import TypeTableList from "../typeTable/TypeTableList";
import PersonelCustomerAddUpdate from "../personelCustomer/PersonelCustomerAddUpdate";
import PersonelCustomerDetail from "../personelCustomer/PersonelCustomerDetail";
import PersonelCustomerList from "../personelCustomer/PersonelCustomerList";
import TypeTablePersonelList from "../typeTablePersonel/TypeTablePersonelList";
import CutAddUpdate from "../cut/CutAddUpdate";
import CutDetail from "../cut/CutDetail";
import CutList from "../cut/CutList";
import CutTypeAddUpdate from "../cutType/CutTypeAddUpdate";
import CutTypeDetail from "../cutType/CutTypeDetail";
import CutTypeList from "../cutType/CutTypeList";
import CuttingProcessAddUpdate from "../cuttingProcess/CuttingProcessAddUpdate";
import CuttingProcessDetail from "../cuttingProcess/CuttingProcessDetail";
import CuttingProcessList from "../cuttingProcess/CuttingProcessList";
import CustomerGroupAddUpdate from "../customerGroup/CustomerGroupAddUpdate";
import CustomerGroupDetail from "../customerGroup/CustomerGroupDetail";
import CustomerGroupList from "../customerGroup/CustomerGroupList";
import SupplierGroupAddUpdate from "../supplierGroup/SupplierGroupAddUpdate";
import SupplierGroupDetail from "../supplierGroup/SupplierGroupDetail";
import SupplierGroupList from "../supplierGroup/SupplierGroupList";
import CuttingProjectList from "../cuttingProcess/CuttingProjectList";
import CuttingProjectDetail from "../cuttingProcess/CuttingProjectDetail";
import LaserCuttingDetail from "../laserCutting/LaserCuttingDetail";
import CuttingReportList from "../cuttingReport/CuttingReportList";
import OfferNewAddUpdate from "../offerNew/OfferNewAddUpdate";
import OfferNewDetail from "../offerNew/OfferNewDetail";
import OfferNewList from "../offerNew/OfferNewList";
import ProductEntryNewAddUpdate from "../productEntryNew/ProductEntryNewAddUpdate";
import ProductEntryNewDetail from "../productEntryNew/ProductEntryNewDetail";
import ProductEntryNewList from "../productEntryNew/ProductEntryNewList";
import OrderNewAddUpdate from "../orderNew/OrderNewAddUpdate";
import OrderNewDetail from "../orderNew/OrderNewDetail";
import OrderNewList from "../orderNew/OrderNewList";
import ProductOutNewAddUpdate from "../productOutNew/ProductOutNewAddUpdate";
import ProductOutNewDetail from "../productOutNew/ProductOutNewDetail";
import ProductOutNewList from "../productOutNew/ProductOutNewList";
import ProductReturnNewAddUpdate from "../productReturnNew/ProductReturnNewAddUpdate";
import ProductReturnNewDetail from "../productReturnNew/ProductReturnNewDetail";
import ProductReturnNewList from "../productReturnNew/ProductReturnNewList";
import StockMovements from "../stockMovements/StockMovements";
import StockMovementsDetail from "../stockMovements/StockMovementsDetail";
import MaterialCategoryAddUpdate from "../materialCategory/MaterialCategoryAddUpdate";
import MaterialCategoryDetail from "../materialCategory/MaterialCategoryDetail";
import MaterialCategoryList from "../materialCategory/MaterialCategoryList";
import WorkOrderStepAddUpdate from "../workOrderStep/WorkOrderStepAddUpdate";
import WorkOrderStepDetail from "../workOrderStep/WorkOrderStepDetail";
import WorkOrderStepList from "../workOrderStep/WorkOrderStepList";
import ErrorCodeCategoryAddUpdate from "../errorCodeCategory/ErrorCodeCategoryAddUpdate";
import ErrorCodeCategoryDetail from "../errorCodeCategory/ErrorCodeCategoryDetail";
import ErrorCodeCategoryList from "../errorCodeCategory/ErrorCodeCategoryList";
import PhysicalControlAddUpdate from "../physicalControl/PhysicalControlAddUpdate";
import PhysicalControlDetail from "../physicalControl/PhysicalControlDetail";
import PhysicalControlList from "../physicalControl/PhysicalControlList";
import ElectricalTestAddUpdate from "../electricalTest/ElectricalTestAddUpdate";
import ElectricalTestDetail from "../electricalTest/ElectricalTestDetail";
import ElectricalTestList from "../electricalTest/ElectricalTestList";
import DeviceUsedAddUpdate from "../deviceUsed/DeviceUsedAddUpdate";
import DeviceUsedDetail from "../deviceUsed/DeviceUsedDetail";
import DeviceUsedList from "../deviceUsed/DeviceUsedList";
import ProcessDefinitionAddUpdate from "../processDefinition/ProcessDefinitionAddUpdate";
import ProcessDefinitionDetail from "../processDefinition/ProcessDefinitionDetail";
import ProcessDefinitionList from "../processDefinition/ProcessDefinitionList";
import WorkOrderStatusAddUpdate from "../workOrderStatus/WorkOrderStatusAddUpdate";
import WorkOrderStatusDetail from "../workOrderStatus/WorkOrderStatusDetail";
import WorkOrderStatusList from "../workOrderStatus/WorkOrderStatusList";
import ErrorCodeAddUpdate from "../errorCode/ErrorCodeAddUpdate";
import ErrorCodeDetail from "../errorCode/ErrorCodeDetail";
import ErrorCodeList from "../errorCode/ErrorCodeList";
import ProductRecipeAddUpdate from "../productRecipe/ProductRecipeAddUpdate";
import ProductRecipeDetail from "../productRecipe/ProductRecipeDetail";
import ProductRecipeList from "../productRecipe/ProductRecipeList";
import MaterialAddUpdate from "../material/MaterialAddUpdate";
import MaterialDetail from "../material/MaterialDetail";
import MaterialList from "../material/MaterialList";
import OfferSecondAddUpdate from "../offerSecond/OfferSecondAddUpdate";
import OfferSecondDetail from "../offerSecond/OfferSecondDetail";
import OfferSecondList from "../offerSecond/OfferSecondList";
import ProductOutSecondAddUpdate from "../productOutSecond/ProductOutSecondAddUpdate";
import ProductOutSecondDetail from "../productOutSecond/ProductOutSecondDetail";
import ProductOutSecondList from "../productOutSecond/ProductOutSecondList";
import ProductEntrySecondAddUpdate from "../productEntrySecond/ProductEntrySecondAddUpdate";
import ProductEntrySecondDetail from "../productEntrySecond/ProductEntrySecondDetail";
import ProductEntrySecondList from "../productEntrySecond/ProductEntrySecondList";
import ReturnSecondAddUpdate from "../returnSecond/ReturnSecondAddUpdate";
import ReturnSecondDetail from "../returnSecond/ReturnSecondDetail";
import ReturnSecondList from "../returnSecond/ReturnSecondList";
import WorkInstructionAddUpdate from "../workInstruction/WorkInstructionAddUpdate";
import WorkInstructionDetail from "../workInstruction/WorkInstructionDetail";
import WorkInstructionList from "../workInstruction/WorkInstructionList";
import PersonelWorkInstructionList from "../personelWorkInstruction/PersonelWorkInstructionList";
import PersonelWorkInstructionDetail from "../personelWorkInstruction/PersonelWorkInstructionDetail";
import LocationDeliveryAddUpdate from "../locationDelivery/LocationDeliveryAddUpdate";
import LocationDeliveryDetail from "../locationDelivery/LocationDeliveryDetail";
import LocationDeliveryList from "../locationDelivery/LocationDeliveryList";
import QualityPoolList from "../qualityPool/QualityPoolList";
import QualityDetail from "../qualityPool/QualityDetail";
import QualityPersonelList from "../qualityPersonel/QualityPersonelList";
import QualityHistoryDetail from "../qualityPersonel/QualityHistoryDetail";
import LocationMaterialDeliveryAddUpdate from "../locationMaterialDelivery/LocationMaterialDeliveryAddUpdate";
import LocationMaterialDeliveryDetail from "../locationMaterialDelivery/LocationMaterialDeliveryDetail";
import LocationMaterialDeliveryList from "../locationMaterialDelivery/LocationMaterialDeliveryList";
import LastControlPoolList from "../lastControlPool/LastControlPoolList";
import LastControlDetail from "../lastControlPool/LastControlDetail";
import LastControlPersonelList from "../lastControlPersonel/LastControlPersonelList";
import SuitabilityFormAddUpdate from "../suitabilityForm/SuitabilityFormAddUpdate";
import SuitabilityFormDetail from "../suitabilityForm/SuitabilityFormDetail";
import SuitabilityFormList from "../suitabilityForm/SuitabilityFormList";
import ManuelControlAddUpdate from "../manuelControl/ManuelControlAddUpdate";
import ManuelControlDetail from "../manuelControl/ManuelControlDetail";
import ManuelControlList from "../manuelControl/ManuelControlList";
import ManuelControl2AddUpdate from "../manuelControl2/ManuelControl2AddUpdate";
import ManuelControl2Detail from "../manuelControl2/ManuelControl2Detail";
import ManuelControl2List from "../manuelControl2/ManuelControl2List";
import DimensionalReportAddUpdate from "../dimensionalReport/DimensionalReportAddUpdate";
import DimensionalReportDetail from "../dimensionalReport/DimensionalReportDetail";
import DimensionalReportList from "../dimensionalReport/DimensionalReportList";
import PinPutTestAddUpdate from "../pinPutTest/PinPutTestAddUpdate";
import PinPutTestDetail from "../pinPutTest/PinPutTestDetail";
import PinPutTestList from "../pinPutTest/PinPutTestList";
import PinPullTestAddUpdate from "../pinPullTest/PinPullTestAddUpdate";
import PinPullTestDetail from "../pinPullTest/PinPullTestDetail";
import PinPullTestList from "../pinPullTest/PinPullTestList";
import DocumentType2AddUpdate from "../documentType2/DocumentType2AddUpdate";
import DocumentType2Detail from "../documentType2/DocumentType2Detail";
import DocumentType2List from "../documentType2/DocumentType2List";
import DocumentRecordAddUpdate from "../documentRecord/DocumentRecordAddUpdate";
import DocumentRecordDetail from "../documentRecord/DocumentRecordDetail";
import DocumentRecordList from "../documentRecord/DocumentRecordList";
import ManuelControlTemplateAddUpdate from "../manuelControlTemplate/ManuelControlTemplateAddUpdate";
import ManuelControlTemplateDetail from "../manuelControlTemplate/ManuelControlTemplateDetail";
import ManuelControlTemplateList from "../manuelControlTemplate/ManuelControlTemplateList";
import CustomerDocumentRecordAddUpdate from "../customerDocumentRecord/CustomerDocumentRecordAddUpdate";
import CustomerDocumentRecordDetail from "../customerDocumentRecord/CustomerDocumentRecordDetail";
import CustomerDocumentRecordList from "../customerDocumentRecord/CustomerDocumentRecordList";
import CustomerDocumentNotPaymentList from "../customerDocumentNotPayment/CustomerDocumentNotPaymentList";
import CustomerDocumentPaymentList from "../customerDocumentPayment/CustomerDocumentPaymentList";
import CustomerDocumentPaymentDetail from "../customerDocumentPayment/CustomerDocumentPaymentDetail";
import CustomerDocumentSuitableList from "../customerDocumentSuitable/CustomerDocumentSuitableList";
import CustomerDocumentSuitableDetail from "../customerDocumentSuitable/CustomerDocumentSuitableDetail";
import CustomerDocumentTranslationList from "../customerDocumentTranslation/CustomerDocumentTranslationList";
import CustomerDocumentTranslationDetail from "../customerDocumentTranslation/CustomerDocumentTranslationDetail";
import CustomerDocumentUTSSuitableList from "../customerDocumentUTSSuitable/CustomerDocumentUTSSuitableList";
import CustomerDocumentUTSSuitableDetail from "../customerDocumentUTSSuitable/CustomerDocumentUTSSuitableDetail";
import CustomerDocumentUTSList from "../customerDocumentUTS/CustomerDocumentUTSList";
import CustomerDocumentUTSDetail from "../customerDocumentUTS/CustomerDocumentUTSDetail";
import PersonelCuttingProcessList from "../personelCuttingProcess/PersonelCuttingProcessList";
import PersonelCuttingProcessDetail from "../personelCuttingProcess/PersonelCuttingProcessDetail";
import PersonelCuttingProcessAddUpdate from "../personelCuttingProcess/PersonelCuttingProcessAddUpdate";
import AccountingCuttingProcessAddUpdate from "../accountingCuttingProcess/AccountingCuttingProcessAddUpdate";
import AccountingCuttingProcessList from "../accountingCuttingProcess/AccountingCuttingProcessList";
import DocumentProductRecordAddUpdate from "../documentProductRecord/DocumentProductRecordAddUpdate";
import DocumentProductRecordDetail from "../documentProductRecord/DocumentProductRecordDetail";
import DocumentProductRecordList from "../documentProductRecord/DocumentProductRecordList";
import CuttingLogList from "../cuttingLog/CuttingLogList";
import CustomerDocumentProductRecordAddUpdate from "../customerDocumentProductRecord/CustomerDocumentProductRecordAddUpdate";
import CustomerDocumentProductRecordDetail from "../customerDocumentProductRecord/CustomerDocumentProductRecordDetail";
import CustomerDocumentProductRecordList from "../customerDocumentProductRecord/CustomerDocumentProductRecordList";
import CustomerDocumentProductNotImageList from "../customerDocumentProductNotImage/CustomerDocumentProductNotImageList";
import CustomerDocumentProductNotImageDetail from "../customerDocumentProductNotImage/CustomerDocumentProductNotImageDetail";
import CustomerDocumentProductImageList from "../customerDocumentProductImage/CustomerDocumentProductImageList";
import CustomerDocumentProductImageDetail from "../customerDocumentProductImage/CustomerDocumentProductImageDetail";
import CustomerDocumentProductStatusList from "../customerDocumentProductStatus/CustomerDocumentProductStatusList";
import CustomerDocumentProductStatusDetail from "../customerDocumentProductStatus/CustomerDocumentProductStatusDetail";
import DimensionalTemplateAddUpdate from "../dimensionalTemplate/DimensionalTemplateAddUpdate";
import DimensionalTemplateDetail from "../dimensionalTemplate/DimensionalTemplateDetail";
import DimensionalTemplateList from "../dimensionalTemplate/DimensionalTemplateList";
import CustomerTicketDocumentList from "../customerTicketDocument/CustomerTicketDocumentList";
import CustomerTicketDocumentDetail from "../customerTicketDocument/CustomerTicketDocumentDetail";
import CustomerProductTicketDocumentList from "../customerProductTicketDocument/CustomerProductTicketDocumentList";
import CustomerProductTicketDocumentDetail from "../customerProductTicketDocument/CustomerProductTicketDocumentDetail";
import CustomerDocumentBarcodeStatusList from "../customerDocumentBarcodeStatus/CustomerDocumentBarcodeStatusList";
import CustomerDocumentBarcodeStatusDetail from "../customerDocumentBarcodeStatus/CustomerDocumentBarcodeStatusDetail";
import CustomerDocumentOtherAddUpdate from "../customerDocumentOther/CustomerDocumentOtherAddUpdate";
import CustomerDocumentOtherDetail from "../customerDocumentOther/CustomerDocumentOtherDetail";
import CustomerDocumentOtherList from "../customerDocumentOther/CustomerDocumentOtherList";
import CustomerDocumentLastStatusList from "../customerDocumentLastStatus/CustomerDocumentLastStatusList";
import CustomerDocumentLastStatusDetail from "../customerDocumentLastStatus/CustomerDocumentLastStatusDetail";
import OtherTicketDocumentList from "../otherTicketDocument/OtherTicketDocumentList";
import OtherTicketDocumentDetail from "../otherTicketDocument/OtherTicketDocumentDetail";
import OfferTypeAddUpdate from "../offerType/OfferTypeAddUpdate";
import OfferTypeDetail from "../offerType/OfferTypeDetail";
import OfferTypeList from "../offerType/OfferTypeList";
import SieveProductAddUpdate from "../sieveProduct/SieveProductAddUpdate";
import SieveProductDetail from "../sieveProduct/SieveProductDetail";
import SieveProductList from "../sieveProduct/SieveProductList";
import SieveCriticalProductList from "../sieveProduct/SieveCriticalProductList";
import OfferSieveAddUpdate from "../offerSieve/OfferSieveAddUpdate";
import OfferSieveDetail from "../offerSieve/OfferSieveDetail";
import OfferSieveDetail2 from "../offerSieve/OfferSieveDetail2";
import OfferSieveList from "../offerSieve/OfferSieveList";
import OfferSieveListApproved from "../offerSieve/OfferSieveListApproved";
import OfferSieveListApproved2 from "../offerSieve/OfferSieveListApproved2";
import OfferSieveListApproved3 from "../offerSieve/OfferSieveListApproved3";
import OfferSieveListShipped from "../offerSieve/OfferSieveListShipped";
import PersonelOfferSieveList from "../personelOfferSieve/PersonelOfferSieveList";
import PersonelOfferSieveDetail from "../personelOfferSieve/PersonelOfferSieveDetail";
import PersonelOfferSieveAddUpdate from "../personelOfferSieve/PersonelOfferSieveAddUpdate";
import OfferSieveReportList from "../offerSieveReport/OfferSieveReportList";
import OfferSieveReportDetail from "../offerSieveReport/OfferSieveReportDetail";
import CastingCostAddUpdate from "../castingCost/CastingCostAddUpdate";
import CastingCostDetail from "../castingCost/CastingCostDetail";
import CastingCostList from "../castingCost/CastingCostList";
import CastingCostDetailList from "../castingCost/CastingCostDetailList";
import TpuCostAddUpdate from "../tpuCost/TpuCostAddUpdate";
import TpuCostDetail from "../tpuCost/TpuCostDetail";
import TpuCostList from "../tpuCost/TpuCostList";
import TpuCostDetailList from "../tpuCost/TpuCostDetailList";
import ShipmentAddUpdate from "../shipment/ShipmentAddUpdate";
import ShipmentDetail from "../shipment/ShipmentDetail";
import ShipmentList from "../shipment/ShipmentList";
import PaymentTypeAddUpdate from "../paymentType/PaymentTypeAddUpdate";
import PaymentTypeDetail from "../paymentType/PaymentTypeDetail";
import PaymentTypeList from "../paymentType/PaymentTypeList";
import DeliveryTimeAddUpdate from "../deliveryTime/DeliveryTimeAddUpdate";
import DeliveryTimeDetail from "../deliveryTime/DeliveryTimeDetail";
import DeliveryTimeList from "../deliveryTime/DeliveryTimeList";
import DeliveryTypeAddUpdate from "../deliveryType/DeliveryTypeAddUpdate";
import DeliveryTypeDetail from "../deliveryType/DeliveryTypeDetail";
import DeliveryTypeList from "../deliveryType/DeliveryTypeList";
import WarrantyPeriodAddUpdate from "../warrantyPeriod/WarrantyPeriodAddUpdate";
import WarrantyPeriodDetail from "../warrantyPeriod/WarrantyPeriodDetail";
import WarrantyPeriodList from "../warrantyPeriod/WarrantyPeriodList";
import MachineModelAddUpdate from "../machineModel/MachineModelAddUpdate";
import MachineModelDetail from "../machineModel/MachineModelDetail";
import MachineModelList from "../machineModel/MachineModelList";
import MachineStatusAddUpdate from "../machineStatus/MachineStatusAddUpdate";
import MachineStatusDetail from "../machineStatus/MachineStatusDetail";
import MachineStatusList from "../machineStatus/MachineStatusList";
import NewMachineAddUpdate from "../newMachine/NewMachineAddUpdate";
import NewMachineDetail from "../newMachine/NewMachineDetail";
import NewMachineList from "../newMachine/NewMachineList";
import MachineProductAddUpdate from "../machineProduct/MachineProductAddUpdate";
import MachineProductDetail from "../machineProduct/MachineProductDetail";
import MachineProductLocationDetail from "../machineProduct/MachineProductLocationDetail";
import MachineProductList from "../machineProduct/MachineProductList";
import MachineProductLocationList from "../machineProduct/MachineProductLocationList";
import MachinePieceProductList from "../machineProduct/MachinePieceProductList";
import MachinePieceProductLocationList from "../machineProduct/MachinePieceProductLocationList";
import MachineCriticalProductList from "../machineProduct/MachineCriticalProductList";
import ProductEntryMachineAddUpdate from "../productEntryMachine/ProductEntryMachineAddUpdate";
import ProductEntryMachineDetail from "../productEntryMachine/ProductEntryMachineDetail";
import ProductEntryMachineList from "../productEntryMachine/ProductEntryMachineList";
import ProductOutMachineAddUpdate from "../productOutMachine/ProductOutMachineAddUpdate";
import ProductOutMachineDetail from "../productOutMachine/ProductOutMachineDetail";
import ProductOutMachineList from "../productOutMachine/ProductOutMachineList";
import OfferMachineAddUpdate from "../offerMachine/OfferMachineAddUpdate";
import OfferMachineDetail from "../offerMachine/OfferMachineDetail";
import OfferMachineList from "../offerMachine/OfferMachineList";
import ProductCountMachineAddUpdate from "../productCountMachine/ProductCountMachineAddUpdate";
import ProductCountMachineDetail from "../productCountMachine/ProductCountMachineDetail";
import ProductCountMachineList from "../productCountMachine/ProductCountMachineList";
import ProductCountMachineGetList from "../productCountMachineGetList/ProductCountMachineGetList";
import ProductCountMachineGetDetail from "../productCountMachineGetList/ProductCountMachineGetDetail";
import TransferStockAddUpdate from "../transferStock/TransferStockAddUpdate";
import TransferStockDetail from "../transferStock/TransferStockDetail";
import TransferStockList from "../transferStock/TransferStockList";
import TransferStockMovements from "../transferStock/TransferStockMovements";
import TransferStockGetList from "../transferStockGetList/TransferStockGetList";
import TransferStockGetDetail from "../transferStockGetList/TransferStockGetDetail";
import OfferSieveProductionDetail from "../offerSieveProduction/OfferSieveProductionDetail";
import OfferSieveProductionList from "../offerSieveProduction/OfferSieveProductionList";
import OfferSieveProductEntryDetail from "../offerSieveProductEntry/OfferSieveProductEntryDetail";
import OfferSieveProductEntryList from "../offerSieveProductEntry/OfferSieveProductEntryList";
import PurchaseWorkOrdersAddUpdate from "../purchaseWorkOrders/PurchaseWorkOrdersAddUpdate";
import PurchaseWorkOrdersDetail from "../purchaseWorkOrders/PurchaseWorkOrdersDetail";
import PurchaseWorkOrdersList from "../purchaseWorkOrders/PurchaseWorkOrdersList";
import MoldingRoomDetail from "../moldingRoom/MoldingRoomDetail";
import MoldingRoomList from "../moldingRoom/MoldingRoomList";
import OfferGenerationDetail from "../offerGeneration/OfferGenerationDetail";
import OfferGenerationList from "../offerGeneration/OfferGenerationList";
import OfferGenerationDetail2 from "../offerGeneration/OfferGenerationDetail2";
import OfferGenerationList2 from "../offerGeneration/OfferGenerationList2";
import FixedCastingAddUpdate from "../fixedCasting/FixedCastingAddUpdate";
import FixedCastingDetail from "../fixedCasting/FixedCastingDetail";
import FixedCastingList from "../fixedCasting/FixedCastingList";
import FixedTpuAddUpdate from "../fixedTpu/FixedTpuAddUpdate";
import FixedTpuDetail from "../fixedTpu/FixedTpuDetail";
import FixedTpuList from "../fixedTpu/FixedTpuList";
import TypeProductionAddUpdate from "../typeProduction/TypeProductionAddUpdate";
import TypeProductionDetail from "../typeProduction/TypeProductionDetail";
import TypeProductionList from "../typeProduction/TypeProductionList";
import OfferShipmentDetail from "../offerShipment/OfferShipmentDetail";
import OfferShipmentList from "../offerShipment/OfferShipmentList";

import CustomerEducationAddUpdate from "../customerEducation/CustomerEducationAddUpdate";
import CustomerEducationDetail from "../customerEducation/CustomerEducationDetail";
import CustomerEducationList from "../customerEducation/CustomerEducationList";

import CustomerEducationYoutubeAddUpdate from "../customerEducationYoutube/CustomerEducationYoutubeAddUpdate";
import CustomerEducationYoutubeDetail from "../customerEducationYoutube/CustomerEducationYoutubeDetail";
import CustomerEducationYoutubeList from "../customerEducationYoutube/CustomerEducationYoutubeList";

import CustomerUsefulLinksAddUpdate from "../customerUsefulLinks/CustomerUsefulLinksAddUpdate";
import CustomerUsefulLinksDetail from "../customerUsefulLinks/CustomerUsefulLinksDetail";
import CustomerUsefulLinksList from "../customerUsefulLinks/CustomerUsefulLinksList";

import CustomerEducationExamAddUpdate from "../customerEducationExam/CustomerEducationExamAddUpdate";
import CustomerEducationExamDetail from "../customerEducationExam/CustomerEducationExamDetail";
import CustomerEducationExamList from "../customerEducationExam/CustomerEducationExamList";

import WorkAccidentAddUpdate from "../workAccident/WorkAccidentAddUpdate";
import WorkAccidentDetail from "../workAccident/WorkAccidentDetail";
import WorkAccidentList from "../workAccident/WorkAccidentList";

import MachineProductOrderAddUpdate from "../machineProductOrder/MachineProductOrderAddUpdate";
import MachineProductOrderDetail from "../machineProductOrder/MachineProductOrderDetail";
import MachineProductOrderList from "../machineProductOrder/MachineProductOrderList";

import UsedSparePartsAddUpdate from "../usedSpareParts/UsedSparePartsAddUpdate";
import UsedSparePartsDetail from "../usedSpareParts/UsedSparePartsDetail";
import UsedSparePartsList from "../usedSpareParts/UsedSparePartsList";
import UsedSparePartsStockMovements from "../usedSpareParts/UsedSparePartsStockMovements";

import MachineStockRemoveList from "../machineStockRemove/MachineStockRemoveList";
import MachineStockRemoveDetail from "../machineStockRemove/MachineStockRemoveDetail";

import RecyclingProductsAddUpdate from "../recyclingProducts/RecyclingProductsAddUpdate";
import RecyclingProductsDetail from "../recyclingProducts/RecyclingProductsDetail";
import RecyclingProductsList from "../recyclingProducts/RecyclingProductsList";
import RecyclingProductsLocationDetail from "../recyclingProducts/RecyclingProductsDetail";
import RecyclingProductsLocationList from "../recyclingProducts/RecyclingProductsList";

import Employee2AddUpdate from "../employee2/EmployeeAddUpdate";
import Employee2Detail from "../employee2/EmployeeDetail";
import Employee2List from "../employee2/EmployeeList";

import ProcedureCSSavunma from "../documents/ProcedureCSSavunma";
import InstructionsCSSavunma from "../documents/InstructionsCSSavunma";

import ShippingCompaniesAddUpdate from "../ShippingCompanies/ShippingCompaniesAddUpdate";
import ShippingCompaniesDetail from "../ShippingCompanies/ShippingCompaniesDetail";
import ShippingCompaniesList from "../ShippingCompanies/ShippingCompaniesList";

import ShippingTypeAddUpdate from "../ShippingType/ShippingTypeAddUpdate";
import ShippingTypeDetail from "../ShippingType/ShippingTypeDetail";
import ShippingTypeList from "../ShippingType/ShippingTypeList";

import CargoTrackingAddUpdate from "../CargoTracking/CargoTrackingAddUpdate";
import CargoTrackingDetail from "../CargoTracking/CargoTrackingDetail";
import CargoTrackingList from "../CargoTracking/CargoTrackingList";

import EMailQuestAddUpdate from "../EMailQuest/EMailQuestAddUpdate";
import EMailQuestDetail from "../EMailQuest/EMailQuestDetail";
import EMailQuestList from "../EMailQuest/EMailQuestList";
import PersonEMailQuestAddUpdate from "../EMailQuest/PersonEMailQuestAddUpdate";
import PersonEMailQuestList from "../EMailQuest/PersonEMailQuestList";
import PersonEMailQuestDetail from "../EMailQuest/PersonEMailQuestDetail";

import GmailApiInformationAddUpdate from "../GmailApiInformation/GmailApiInformationAddUpdate";
import GmailApiInformationDetail from "../GmailApiInformation/GmailApiInformationDetail";
import GmailApiInformationList from "../GmailApiInformation/GmailApiInformationList";

import GMailAPIBlockedAddUpdate from "../GMailAPIBlocked/GMailAPIBlockedAddUpdate";
import GMailAPIBlockedDetail from "../GMailAPIBlocked/GMailAPIBlockedDetail";
import GMailAPIBlockedList from "../GMailAPIBlocked/GMailAPIBlockedList";

import ReportNestleList from "../reportNestle/reportNestleList";

import BrochureCategoryAddUpdate from "../BrochureCategory/BrochureCategoryAddUpdate";
import BrochureCategoryDetail from "../BrochureCategory/BrochureCategoryDetail";
import BrochureCategoryList from "../BrochureCategory/BrochureCategoryList";

import BrochureAddUpdate from "../Brochure/BrochureAddUpdate";
import BrochureDetail from "../Brochure/BrochureDetail";
import BrochureList from "../Brochure/BrochureList";

import FilesAddUpdate from "../Files/FilesAddUpdate";
import FilesDetail from "../Files/FilesDetail";
import FilesList from "../Files/FilesList";

import DefectiveQuantityAddUpdate from "../defectiveQuantity/DefectiveQuantityAddUpdate";
import DefectiveQuantityDetail from "../defectiveQuantity/DefectiveQuantityDetail";
import DefectiveQuantityList from "../defectiveQuantity/DefectiveQuantityList";
import DefectiveQuantityAdminList from "../defectiveQuantity/DefectiveQuantityAdminList";
import DefectiveQuantityAdminDetail from "../defectiveQuantity/DefectiveQuantityAdminDetail";

import UserLoginInformationList from "../userLoginInformation/UserLoginInformationList";

import DocumentTrackingAddUpdate from "../DocumentTracking/DocumentTrackingAddUpdate";
import DocumentTrackingDetail from "../DocumentTracking/DocumentTrackingDetail";
import DocumentTrackingList from "../DocumentTracking/DocumentTrackingList";

import CustomerEducationAnnouncementAddUpdate from "../CustomerEducationAnnouncement/CustomerEducationAnnouncementAddUpdate";
import CustomerEducationAnnouncementDetail from "../CustomerEducationAnnouncement/CustomerEducationAnnouncementDetail";
import CustomerEducationAnnouncementList from "../CustomerEducationAnnouncement/CustomerEducationAnnouncementList";

import CustomerEducationAnnouncementEMailAddUpdate from "../CustomerEducationAnnouncementEMail/CustomerEducationAnnouncementEMailAddUpdate";
import CustomerEducationAnnouncementEMailDetail from "../CustomerEducationAnnouncementEMail/CustomerEducationAnnouncementEMailDetail";
import CustomerEducationAnnouncementEMailList from "../CustomerEducationAnnouncementEMail/CustomerEducationAnnouncementEMailList";

import ProductGroupAddUpdate from "../ProductGroup/ProductGroupAddUpdate";
import ProductGroupDetail from "../ProductGroup/ProductGroupDetail";
import ProductGroupList from "../ProductGroup/ProductGroupList";

import OfferSieveReport from "../offerSieve/OfferSieveReport";

class Content extends Component {
  render() {
    return (
      <>
        <div className="content">
          <Switch>
            <Route
              path="/raporlar"
              exact
              render={() => <Home items={this.props.items} />}
            />
            <Route path="/firma-ekle" component={CompanyAddUpdate} />
            <Route path="/firma-guncelle" component={CompanyAddUpdate} />
            <Route path="/firma-detay" component={CompanyDetail} />
            <Route path="/firma-listele" component={CompanyList} />
            <Route
              path="/firma-bilgilerini-guncelle"
              component={CompanyInfoUpdate}
            />
            <Route
              path="/kullanici-bilgilerini-guncelle"
              component={UserInfoUpdate}
            />
            <Route path="/guvenli-cikis" component={Logout} />
            <Route
              path="/firma-personel-grup-ekle"
              component={CompanyUserGroupAddUpdate}
            />
            <Route
              path="/firma-personel-grup-guncelle"
              component={CompanyUserGroupAddUpdate}
            />
            <Route
              path="/firma-personel-grup-detay"
              component={CompanyUserGroupDetail}
            />
            <Route
              path="/firma-personel-grup-listele"
              component={CompanyUserGroupList}
            />
            <Route path="/takvim" component={CalendarList} />
            <Route path="/takvim-kullanici" component={CalendarListUser} />
            <Route path="/hatirlatma-ekle" component={RemindAddUpdate} />
            <Route path="/hatirlatma-guncelle" component={RemindAddUpdate} />
            <Route path="/hatirlatma-detay" component={RemindDetail} />
            <Route path="/hatirlatma-listele" component={RemindList} />
            <Route path="/bolge-ekle" component={AreaAddUpdate} />
            <Route path="/bolge-guncelle" component={AreaAddUpdate} />
            <Route path="/bolge-detay" component={AreaDetail} />
            <Route path="/bolge-listele" component={AreaList} />

            <Route path="/personel-ekle" component={EmployeeAddUpdate} />
            <Route path="/personel-guncelle" component={EmployeeAddUpdate} />
            <Route path="/personel-detay" component={EmployeeDetail} />
            <Route path="/personel-listele" component={EmployeeList} />

            <Route
              path="/tedarikci-grubu-ekle"
              component={SupplierGroupAddUpdate}
            />
            <Route
              path="/tedarikci-grubu-guncelle"
              component={SupplierGroupAddUpdate}
            />
            <Route
              path="/tedarikci-grubu-detay"
              component={SupplierGroupDetail}
            />
            <Route
              path="/tedarikci-grubu-listele"
              component={SupplierGroupList}
            />
            <Route path="/tedarikci-ekle" component={SupplierAddUpdate} />
            <Route path="/tedarikci-guncelle" component={SupplierAddUpdate} />
            <Route path="/tedarikci-detay" component={SupplierDetail} />
            <Route path="/tedarikci-listele" component={SupplierList} />
            <Route
              path="/bolge2-ekle"
              component={CustomerGroupAddUpdate}
            />
            <Route
              path="/bolge2-guncelle"
              component={CustomerGroupAddUpdate}
            />
            <Route
              path="/bolge2-detay"
              component={CustomerGroupDetail}
            />
            <Route
              path="/bolge2-listele"
              component={CustomerGroupList}
            />
            <Route path="/musteri-ekle" component={CustomerAddUpdate} />
            <Route path="/musteri-guncelle" component={CustomerAddUpdate} />
            <Route path="/musteri-detay" component={CustomerDetail} />
            <Route path="/musteri-listele" component={CustomerList} />
            <Route path="/uye-ekle" component={KingUserAddUpdate} />
            <Route path="/uye-guncelle" component={KingUserAddUpdate} />
            <Route path="/uye-detay" component={KingUserDetail} />
            <Route path="/uye-listele" component={KingUserList} />
            <Route path="/banka-ekle" component={BankAddUpdate} />
            <Route path="/banka-guncelle" component={BankAddUpdate} />
            <Route path="/banka-detay" component={BankDetail} />
            <Route path="/banka-listele" component={BankList} />
            <Route path="/banka-hareketleri" component={BankMovementList} />
            <Route path="/gider-turu-ekle" component={CostTypeAddUpdate} />
            <Route path="/gider-turu-guncelle" component={CostTypeAddUpdate} />
            <Route path="/gider-turu-detay" component={CostTypeDetail} />
            <Route path="/gider-turu-listele" component={CostTypeList} />
            <Route path="/kategori-ekle" component={CategoryAddUpdate} />
            <Route path="/kategori-guncelle" component={CategoryAddUpdate} />
            <Route path="/kategori-detay" component={CategoryDetail} />
            <Route path="/kategori-listele" component={CategoryList} />

            <Route
              path="/urun-ozellikleri-tipi-ekle"
              component={ProductPropertyTypeAddUpdate}
            />
            <Route
              path="/urun-ozellikleri-tipi-guncelle"
              component={ProductPropertyTypeAddUpdate}
            />
            <Route
              path="/urun-ozellikleri-tipi-detay"
              component={ProductPropertyTypeDetail}
            />
            <Route
              path="/urun-ozellikleri-tipi-listele"
              component={ProductPropertyTypeList}
            />
            <Route path="/urun-ekle" component={ProductAddUpdate} />
            <Route path="/urun-guncelle" component={ProductAddUpdate} />
            <Route path="/urun-detay" component={ProductDetail} />
            <Route path="/urun-listele" component={ProductList} />
            <Route path="/stok-hareketleri" component={StockMovements} />
            <Route path="/urun-stok-detay" component={StockMovementsDetail} />
            <Route path="/aciklama-ekle" component={DescriptionAddUpdate} />
            <Route path="/aciklama-guncelle" component={DescriptionAddUpdate} />
            <Route path="/aciklama-detay" component={DescriptionDetail} />
            <Route path="/aciklama-listele" component={DescriptionList} />
            <Route
              path="/kritik-urun-listele"
              component={CriticalProductList}
            />
            <Route path="/alis2-ekle" component={ProductEntryNewAddUpdate} />
            <Route
              path="/alis2-guncelle"
              component={ProductEntryNewAddUpdate}
            />
            <Route path="/alis2-detay" component={ProductEntryNewDetail} />
            <Route path="/alis2-listele" component={ProductEntryNewList} />
            <Route path="/alis-ekle" component={ProductEntryAddUpdate} />
            <Route path="/alis-guncelle" component={ProductEntryAddUpdate} />
            <Route path="/alis-detay" component={ProductEntryDetail} />
            <Route path="/alis-listele" component={ProductEntryList} />
            <Route path="/satis-ekle" component={ProductOutAddUpdate} />
            <Route path="/satis-guncelle" component={ProductOutAddUpdate} />
            <Route path="/satis-detay" component={ProductOutDetail} />
            <Route path="/satis-listele" component={ProductOutList} />
            <Route path="/satis2-ekle" component={ProductOutNewAddUpdate} />
            <Route path="/satis2-guncelle" component={ProductOutNewAddUpdate} />
            <Route path="/satis2-detay" component={ProductOutNewDetail} />
            <Route path="/satis2-listele" component={ProductOutNewList} />
            <Route path="/iade2-ekle" component={ProductReturnNewAddUpdate} />
            <Route
              path="/iade2-guncelle"
              component={ProductReturnNewAddUpdate}
            />
            <Route path="/iade2-detay" component={ProductReturnNewDetail} />
            <Route path="/iade2-listele" component={ProductReturnNewList} />
            <Route path="/iade-ekle" component={ProductReturnAddUpdate} />
            <Route path="/iade-guncelle" component={ProductReturnAddUpdate} />
            <Route path="/iade-detay" component={ProductReturnDetail} />
            <Route path="/iade-listele" component={ProductReturnList} />
            <Route path="/gelir-ekle" component={IncomeAddUpdate} />
            <Route path="/gelir-guncelle" component={IncomeAddUpdate} />
            <Route path="/gelir-detay" component={IncomeDetail} />
            <Route path="/gelir-listele" component={IncomeList} />
            <Route
              path="/tahsilat-takvimi-listele"
              component={CollectionCalendarList}
            />
            <Route path="/tahsilat-ekle" component={CollectionAddUpdate} />
            <Route path="/tahsilat/guncelle" component={CollectionAddUpdate} />
            <Route path="/tahsilat-detay" component={CollectionDetail} />
            <Route path="/tahsilat-listele" component={CollectionList} />
            <Route path="/gider-ekle" component={CostAddUpdate} />
            <Route path="/gider-guncelle" component={CostAddUpdate} />
            <Route path="/gider-detay" component={CostDetail} />
            <Route path="/gider-listele" component={CostList} />
            <Route
              path="/odeme-takvimi-listele"
              component={PaymentCalendarList}
            />
            <Route path="/odeme-ekle" component={PaymentAddUpdate} />
            <Route path="/odeme/guncelle" component={PaymentAddUpdate} />
            <Route path="/odeme-detay" component={PaymentDetail} />
            <Route path="/odeme-listele" component={PaymentList} />
            <Route path="/teklif-kapagi-ekle" component={OfferCoverAddUpdate} />
            <Route
              path="/teklif-kapagi-guncelle"
              component={OfferCoverAddUpdate}
            />
            <Route path="/teklif-kapagi-detay" component={OfferCoverDetail} />
            <Route path="/teklif-kapagi-listele" component={OfferCoverList} />
            <Route path="/teklif-ekle" component={OfferAddUpdate} />
            <Route path="/teklif-guncelle" component={OfferAddUpdate} />
            <Route path="/teklif-detay" component={OfferDetail} />
            <Route path="/teklif-listele" component={OfferList} />
            <Route path="/teklif2-ekle" component={OfferNewAddUpdate} />
            <Route path="/teklif2-guncelle" component={OfferNewAddUpdate} />
            <Route path="/teklif2-detay" component={OfferNewDetail} />
            <Route path="/teklif2-listele" component={OfferNewList} />
            <Route path="/not-ekle" component={NoteAddUpdate} />
            <Route path="/not-guncelle" component={NoteAddUpdate} />
            <Route path="/not-detay" component={NoteDetail} />
            <Route path="/not-listele" component={NoteList} />
            <Route path="/sozlesme-ekle" component={ContractAddUpdate} />
            <Route path="/sozlesme-guncelle" component={ContractAddUpdate} />
            <Route path="/sozlesme-detay" component={ContractDetail} />
            <Route path="/sozlesme-listele" component={ContractList} />
            <Route path="/belge-turu-ekle" component={DocumentTypeAddUpdate} />
            <Route
              path="/belge-turu-guncelle"
              component={DocumentTypeAddUpdate}
            />
            <Route path="/belge-turu-detay" component={DocumentTypeDetail} />
            <Route path="/belge-turu-listele" component={DocumentTypeList} />
            <Route path="/belge-ekle" component={DocumentAddUpdate} />
            <Route path="/belge-guncelle" component={DocumentAddUpdate} />
            <Route path="/belge-detay" component={DocumentDetail} />
            <Route path="/belge-listele" component={DocumentList} />
            <Route path="/servis-turu-ekle" component={ServiceTypeAddUpdate} />
            <Route
              path="/servis-turu-guncelle"
              component={ServiceTypeAddUpdate}
            />
            <Route path="/servis-turu-detay" component={ServiceTypeDetail} />
            <Route path="/servis-turu-listele" component={ServiceTypeList} />
            <Route path="/servis-ekle" component={ServiceAddUpdate} />
            <Route path="/servis-guncelle" component={ServiceAddUpdate} />
            <Route path="/servis-detay" component={ServiceDetail} />
            <Route path="/servis-listele" component={ServiceList} />
            <Route
              path="/siparis-kapagi-ekle"
              component={OrderCoverAddUpdate}
            />
            <Route
              path="/siparis-kapagi-guncelle"
              component={OrderCoverAddUpdate}
            />
            <Route path="/siparis-kapagi-detay" component={OrderCoverDetail} />
            <Route path="/siparis-kapagi-listele" component={OrderCoverList} />
            <Route path="/siparis-ekle" component={OrderAddUpdate} />
            <Route path="/siparis-guncelle" component={OrderAddUpdate} />
            <Route path="/siparis-detay" component={OrderDetail} />
            <Route path="/siparis-listele" component={OrderList} />
            <Route path="/siparis2-ekle" component={OrderNewAddUpdate} />
            <Route path="/siparis2-guncelle" component={OrderNewAddUpdate} />
            <Route path="/siparis2-detay" component={OrderNewDetail} />
            <Route path="/siparis2-listele" component={OrderNewList} />
            <Route path="/police-ekle" component={PolicyAddUpdate} />
            <Route path="/police-guncelle" component={PolicyAddUpdate} />
            <Route path="/police-detay" component={PolicyDetail} />
            <Route path="/police-listele" component={PolicyList} />
            <Route
              path="/girilen-police-listele"
              component={PolicyCreatedList}
            />
            <Route path="/yaklasan-police-listele" component={PolicyListNext} />
            <Route
              path="/yaklasan-servis-listele"
              component={ServiceListNext}
            />
            <Route path="/ariza-durumu-ekle" component={FaultStatusAddUpdate} />
            <Route
              path="/ariza-durumu-guncelle"
              component={FaultStatusAddUpdate}
            />
            <Route path="/ariza-durumu-detay" component={FaultStatusDetail} />
            <Route path="/ariza-durumu-listele" component={FaultStatusList} />
            <Route
              path="/servis-durumu-ekle"
              component={ServiceStatusAddUpdate}
            />
            <Route
              path="/servis-durumu-guncelle"
              component={ServiceStatusAddUpdate}
            />
            <Route
              path="/servis-durumu-detay"
              component={ServiceStatusDetail}
            />
            <Route
              path="/servis-durumu-listele"
              component={ServiceStatusList}
            />
            <Route path="/satis-turu-ekle" component={SaleTypeAddUpdate} />
            <Route path="/satis-turu-guncelle" component={SaleTypeAddUpdate} />
            <Route path="/satis-turu-detay" component={SaleTypeDetail} />
            <Route path="/satis-turu-listele" component={SaleTypeList} />
            <Route
              path="/sikayet-sebebi-ekle"
              component={ComplaintReasonAddUpdate}
            />
            <Route
              path="/sikayet-sebebi-guncelle"
              component={ComplaintReasonAddUpdate}
            />
            <Route
              path="/sikayet-sebebi-detay"
              component={ComplaintReasonDetail}
            />
            <Route
              path="/sikayet-sebebi-listele"
              component={ComplaintReasonList}
            />
            <Route
              path="/garanti-turu-ekle"
              component={GuarantyTypeAddUpdate}
            />
            <Route
              path="/garanti-turu-guncelle"
              component={GuarantyTypeAddUpdate}
            />
            <Route path="/garanti-turu-detay" component={GuarantyTypeDetail} />
            <Route path="/garanti-turu-listele" component={GuarantyTypeList} />
            <Route path="/marka-ekle" component={BrandAddUpdate} />
            <Route path="/marka-guncelle" component={BrandAddUpdate} />
            <Route path="/marka-detay" component={BrandDetail} />
            <Route path="/marka-listele" component={BrandList} />
            <Route path="/model-ekle" component={ModelAddUpdate} />
            <Route path="/model-guncelle" component={ModelAddUpdate} />
            <Route path="/model-detay" component={ModelDetail} />
            <Route path="/model-listele" component={ModelList} />
            <Route path="/kanal-ekle" component={CanalAddUpdate} />
            <Route path="/kanal-guncelle" component={CanalAddUpdate} />
            <Route path="/kanal-detay" component={CanalDetail} />
            <Route path="/kanal-listele" component={CanalList} />
            <Route path="/islem-tipi-ekle" component={ProcessTypeAddUpdate} />
            <Route
              path="/islem-tipi-guncelle"
              component={ProcessTypeAddUpdate}
            />
            <Route path="/islem-tipi-detay" component={ProcessTypeDetail} />
            <Route path="/islem-tipi-listele" component={ProcessTypeList} />
            <Route path="/makine-ekle" component={MachineAddUpdate} />
            <Route path="/makine-guncelle" component={MachineAddUpdate} />
            <Route path="/makine-detay" component={MachineDetail} />
            <Route path="/makine-listele" component={MachineList} />
            <Route
              path="/makine-musteri-ekle"
              component={MachineCustomerAddUpdate}
            />
            <Route
              path="/makine-musteri-guncelle"
              component={MachineCustomerAddUpdate}
            />
            <Route
              path="/makine-musteri-detay"
              component={MachineCustomerDetail}
            />
            <Route
              path="/makine-musteri-listele"
              component={MachineCustomerList}
            />

            <Route
              path="/makine-musteri-2-ekle"
              component={MachineCustomer2AddUpdate}
            />
            <Route
              path="/makine-musteri-2-guncelle"
              component={MachineCustomer2AddUpdate}
            />
            <Route
              path="/makine-musteri-2-detay"
              component={MachineCustomer2Detail}
            />
            <Route
              path="/makine-musteri-2-listele"
              component={MachineCustomer2List}
            />

            <Route
              path="/makine-personel-ekle"
              component={MachinePersonelAddUpdate}
            />
            <Route
              path="/makine-personel-guncelle"
              component={MachinePersonelAddUpdate}
            />
            <Route
              path="/makine-personel-detay"
              component={MachinePersonelDetail}
            />
            <Route
              path="/makine-personel-listele"
              component={MachinePersonelList}
            />
            <Route path="/is-atama-ekle" component={AssignmentAddUpdate} />
            <Route path="/is-atama-guncelle" component={AssignmentAddUpdate} />
            <Route path="/is-atama-detay" component={AssignmentDetail} />
            <Route path="/is-atama-listele" component={AssignmentList} />
            <Route
              path="/bekleyen-is-atama-listele"
              component={AssignmentPersonelList}
            />
            <Route
              path="/bekleyen-is-atama-detay"
              component={AssignmentPersonelDetail}
            />
            <Route
              path="/tamamlanan-is-atama-listele"
              component={AssignmentPersonelCompleteList}
            />
            <Route
              path="/tamamlanan-is-atama-detay"
              component={AssignmentPersonelCompleteDetail}
            />
            <Route path="/sayim-ekle" component={ProductCountAddUpdate} />
            <Route path="/sayim-guncelle" component={ProductCountAddUpdate} />
            <Route path="/sayim-detay" component={ProductCountDetail} />
            <Route path="/sayim-listele" component={ProductCountList} />
            <Route
              path="/yetkili-sayim-listele"
              component={ProductCountGetList}
            />
            <Route
              path="/yetkili-sayim-detay"
              component={ProductCountGetDetail}
            />
            <Route
              path="/makine-servis-ekle"
              component={MachineServiceAddUpdate}
            />
            <Route
              path="/makine-servis-guncelle"
              component={MachineServiceAddUpdate}
            />
            <Route
              path="/makine-servis-detay"
              component={MachineServiceDetail}
            />
            <Route
              path="/makine-servis-listele"
              component={MachineServiceList}
            />
            <Route
              path="/gorev-kategori-ekle"
              component={TaskCategoryAddUpdate}
            />
            <Route
              path="/gorev-kategori-guncelle"
              component={TaskCategoryAddUpdate}
            />
            <Route
              path="/gorev-kategori-detay"
              component={TaskCategoryDetail}
            />
            <Route
              path="/gorev-kategori-listele"
              component={TaskCategoryList}
            />
            <Route
              path="/gorev-onceligi-ekle"
              component={TaskPriorityAddUpdate}
            />
            <Route
              path="/gorev-onceligi-guncelle"
              component={TaskPriorityAddUpdate}
            />
            <Route
              path="/gorev-onceligi-detay"
              component={TaskPriorityDetail}
            />
            <Route
              path="/gorev-onceligi-listele"
              component={TaskPriorityList}
            />
            <Route
              path="/gorev-durumu-ekle"
              component={TaskSituationAddUpdate}
            />
            <Route
              path="/gorev-durumu-guncelle"
              component={TaskSituationAddUpdate}
            />
            <Route path="/gorev-durumu-detay" component={TaskSituationDetail} />
            <Route path="/gorev-durumu-listele" component={TaskSituationList} />
            <Route path="/gorev-atama-ekle" component={TaskAddUpdate} />
            <Route path="/gorev-atama-guncelle" component={TaskAddUpdate} />
            <Route path="/gorev-atama-detay" component={TaskDetail} />
            <Route path="/gorev-atama-listele" component={TaskList} />
            <Route
              path="/personel-gorev-listele"
              component={TaskPersonelList}
            />
            <Route
              path="/personel-gorev-detay"
              component={TaskPersonelDetail}
            />
            <Route
              path="/personel-musteri-ekle"
              component={PersonelCustomerAddUpdate}
            />
            <Route
              path="/personel-musteri-guncelle"
              component={PersonelCustomerAddUpdate}
            />
            <Route
              path="/personel-musteri-detay"
              component={PersonelCustomerDetail}
            />
            <Route
              path="/personel-musteri-listele"
              component={PersonelCustomerList}
            />
            <Route path="/tip-ekle" component={TypeAddUpdate} />
            <Route path="/tip-guncelle" component={TypeAddUpdate} />
            <Route path="/tip-detay" component={TypeDetail} />
            <Route path="/tip-listele" component={TypeList} />
            <Route path="/muhasebe-takip" component={TypeTableList} />
            <Route
              path="/personel-muhasebe-takip"
              component={TypeTablePersonelList}
            />
            <Route path="/kesim-ekle" component={CutAddUpdate} />
            <Route path="/kesim-guncelle" component={CutAddUpdate} />
            <Route path="/kesim-detay" component={CutDetail} />
            <Route path="/kesim-listele" component={CutList} />
            <Route path="/kesim-cinsi-ekle" component={CutTypeAddUpdate} />
            <Route path="/kesim-cinsi-guncelle" component={CutTypeAddUpdate} />
            <Route path="/kesim-cinsi-detay" component={CutTypeDetail} />
            <Route path="/kesim-cinsi-listele" component={CutTypeList} />
            <Route path="/tl-saat-tanimla" component={LaserCuttingDetail} />
            <Route
              path="/kesim-islemi-ekle"
              component={CuttingProcessAddUpdate}
            />
            <Route
              path="/kesim-islemi-guncelle"
              component={CuttingProcessAddUpdate}
            />
            <Route
              path="/kesim-islemi-detay"
              component={CuttingProcessDetail}
            />
            <Route
              path="/kesim-islemi-listele"
              component={CuttingProcessList}
            />
            <Route path="/kesim-proje-listele" component={CuttingProjectList} />
            <Route path="/kesim-proje-detay" component={CuttingProjectDetail} />
            <Route path="/kesim-raporlari" component={CuttingReportList} />

            <Route
              path="/personel-kesim-islemi-ekle"
              component={PersonelCuttingProcessAddUpdate}
            />
            <Route
              path="/personel-kesim-islemi-guncelle"
              component={PersonelCuttingProcessAddUpdate}
            />
            <Route
              path="/personel-kesim-islemi-detay"
              component={PersonelCuttingProcessDetail}
            />
            <Route
              path="/personel-kesim-islemi-listele"
              component={PersonelCuttingProcessList}
            />

            <Route
              path="/muhasebe-kesim-islemi-guncelle"
              component={AccountingCuttingProcessAddUpdate}
            />
            <Route
              path="/muhasebe-kesim-islemi-listele"
              component={AccountingCuttingProcessList}
            />

            <Route path="/kesim-islemleri-log" component={CuttingLogList} />

            <Route
              path="/saha-satis-bolgesi-ekle"
              component={SaleRegionAddUpdate}
            />
            <Route
              path="/saha-satis-bolgesi-guncelle"
              component={SaleRegionAddUpdate}
            />
            <Route
              path="/saha-satis-bolgesi-detay"
              component={SaleRegionDetail}
            />
            <Route
              path="/saha-satis-bolgesi-listele"
              component={SaleRegionList}
            />

            <Route path="/depo-ekle" component={LocationAddUpdate} />
            <Route path="/depo-guncelle" component={LocationAddUpdate} />
            <Route path="/depo-detay" component={LocationDetail} />
            <Route path="/depo-listele" component={LocationList} />

            <Route path="/kisi-grubu-ekle" component={PersonGroupAddUpdate} />
            <Route
              path="/kisi-grubu-guncelle"
              component={PersonGroupAddUpdate}
            />
            <Route path="/kisi-grubu-detay" component={PersonGroupDetail} />
            <Route path="/kisi-grubu-listele" component={PersonGroupList} />

            <Route path="/kisi-ekle" component={PersonAddUpdate} />
            <Route path="/kisi-guncelle" component={PersonAddUpdate} />
            <Route path="/kisi-detay" component={PersonDetail} />
            <Route path="/kisi-listele" component={PersonList} />

            <Route
              path="/teklif-tanimlama-ekle"
              component={OfferDefinitionAddUpdate}
            />
            <Route
              path="/teklif-tanimlama-guncelle"
              component={OfferDefinitionAddUpdate}
            />
            <Route
              path="/teklif-tanimlama-detay"
              component={OfferDefinitionDetail}
            />
            <Route
              path="/teklif-tanimlama-listele"
              component={OfferDefinitionList}
            />

            <Route
              path="/malzeme-kategori-ekle"
              component={MaterialCategoryAddUpdate}
            />
            <Route
              path="/malzeme-kategori-guncelle"
              component={MaterialCategoryAddUpdate}
            />
            <Route
              path="/malzeme-kategori-detay"
              component={MaterialCategoryDetail}
            />
            <Route
              path="/malzeme-kategori-listele"
              component={MaterialCategoryList}
            />

            <Route
              path="/is-emri-adimi-ekle"
              component={WorkOrderStepAddUpdate}
            />
            <Route
              path="/is-emri-adimi-guncelle"
              component={WorkOrderStepAddUpdate}
            />
            <Route
              path="/is-emri-adimi-detay"
              component={WorkOrderStepDetail}
            />
            <Route
              path="/is-emri-adimi-listele"
              component={WorkOrderStepList}
            />

            <Route
              path="/hata-kodu-kategori-ekle"
              component={ErrorCodeCategoryAddUpdate}
            />
            <Route
              path="/hata-kodu-kategori-guncelle"
              component={ErrorCodeCategoryAddUpdate}
            />
            <Route
              path="/hata-kodu-kategori-detay"
              component={ErrorCodeCategoryDetail}
            />
            <Route
              path="/hata-kodu-kategori-listele"
              component={ErrorCodeCategoryList}
            />

            <Route
              path="/fiziksel-kontrol-ekle"
              component={PhysicalControlAddUpdate}
            />
            <Route
              path="/fiziksel-kontrol-guncelle"
              component={PhysicalControlAddUpdate}
            />
            <Route
              path="/fiziksel-kontrol-detay"
              component={PhysicalControlDetail}
            />
            <Route
              path="/fiziksel-kontrol-listele"
              component={PhysicalControlList}
            />

            <Route
              path="/elektrik-ve-diger-testler-ekle"
              component={ElectricalTestAddUpdate}
            />
            <Route
              path="/elektrik-ve-diger-testler-guncelle"
              component={ElectricalTestAddUpdate}
            />
            <Route
              path="/elektrik-ve-diger-testler-detay"
              component={ElectricalTestDetail}
            />
            <Route
              path="/elektrik-ve-diger-testler-listele"
              component={ElectricalTestList}
            />

            <Route
              path="/kullanilan-cihaz-ekle"
              component={DeviceUsedAddUpdate}
            />
            <Route
              path="/kullanilan-cihaz-guncelle"
              component={DeviceUsedAddUpdate}
            />
            <Route
              path="/kullanilan-cihaz-detay"
              component={DeviceUsedDetail}
            />
            <Route
              path="/kullanilan-cihaz-listele"
              component={DeviceUsedList}
            />

            <Route
              path="/uygulanan-process-tanimi-ekle"
              component={ProcessDefinitionAddUpdate}
            />
            <Route
              path="/uygulanan-process-tanimi-guncelle"
              component={ProcessDefinitionAddUpdate}
            />
            <Route
              path="/uygulanan-process-tanimi-detay"
              component={ProcessDefinitionDetail}
            />
            <Route
              path="/uygulanan-process-tanimi-listele"
              component={ProcessDefinitionList}
            />

            <Route
              path="/is-emri-durumu-ekle"
              component={WorkOrderStatusAddUpdate}
            />
            <Route
              path="/is-emri-durumu-guncelle"
              component={WorkOrderStatusAddUpdate}
            />
            <Route
              path="/is-emri-durumu-detay"
              component={WorkOrderStatusDetail}
            />
            <Route
              path="/is-emri-durumu-listele"
              component={WorkOrderStatusList}
            />

            <Route path="/hata-kodu-ekle" component={ErrorCodeAddUpdate} />
            <Route path="/hata-kodu-guncelle" component={ErrorCodeAddUpdate} />
            <Route path="/hata-kodu-detay" component={ErrorCodeDetail} />
            <Route path="/hata-kodu-listele" component={ErrorCodeList} />

            <Route
              path="/urun-recete-ekle"
              component={ProductRecipeAddUpdate}
            />
            <Route
              path="/urun-recete-guncelle"
              component={ProductRecipeAddUpdate}
            />
            <Route path="/urun-recete-detay" component={ProductRecipeDetail} />
            <Route path="/urun-recete-listele" component={ProductRecipeList} />

            <Route path="/malzeme-ekle" component={MaterialAddUpdate} />
            <Route path="/malzeme-guncelle" component={MaterialAddUpdate} />
            <Route path="/malzeme-detay" component={MaterialDetail} />
            <Route path="/malzeme-listele" component={MaterialList} />

            <Route path="/yeni-teklif-ekle" component={OfferSecondAddUpdate} />
            <Route
              path="/yeni-teklif-guncelle"
              component={OfferSecondAddUpdate}
            />
            <Route path="/yeni-teklif-detay" component={OfferSecondDetail} />
            <Route path="/yeni-teklif-listele" component={OfferSecondList} />

            <Route
              path="/yeni-satis-ekle"
              component={ProductOutSecondAddUpdate}
            />
            <Route
              path="/yeni-satis-guncelle"
              component={ProductOutSecondAddUpdate}
            />
            <Route
              path="/yeni-satis-detay"
              component={ProductOutSecondDetail}
            />
            <Route
              path="/yeni-satis-listele"
              component={ProductOutSecondList}
            />

            <Route
              path="/yeni-alis-ekle"
              component={ProductEntrySecondAddUpdate}
            />
            <Route
              path="/yeni-alis-guncelle"
              component={ProductEntrySecondAddUpdate}
            />
            <Route
              path="/yeni-alis-detay"
              component={ProductEntrySecondDetail}
            />
            <Route
              path="/yeni-alis-listele"
              component={ProductEntrySecondList}
            />

            <Route path="/yeni-iade-ekle" component={ReturnSecondAddUpdate} />
            <Route
              path="/yeni-iade-guncelle"
              component={ReturnSecondAddUpdate}
            />
            <Route path="/yeni-iade-detay" component={ReturnSecondDetail} />
            <Route path="/yeni-iade-listele" component={ReturnSecondList} />

            <Route path="/is-emri-ekle" component={WorkInstructionAddUpdate} />
            <Route
              path="/is-emri-guncelle"
              component={WorkInstructionAddUpdate}
            />
            <Route path="/is-emri-detay" component={WorkInstructionDetail} />
            <Route path="/is-emri-listele" component={WorkInstructionList} />

            <Route
              path="/personel-is-emri-listele"
              component={PersonelWorkInstructionList}
            />
            <Route
              path="/personel-is-emri-detay"
              component={PersonelWorkInstructionDetail}
            />

            <Route
              path="/depo-urun-islemi-ekle"
              component={LocationDeliveryAddUpdate}
            />
            <Route
              path="/depo-urun-islemi-guncelle"
              component={LocationDeliveryAddUpdate}
            />
            <Route
              path="/depo-urun-islemi-detay"
              component={LocationDeliveryDetail}
            />
            <Route
              path="/depo-urun-islemi-listele"
              component={LocationDeliveryList}
            />

            <Route
              path="/depo-el-aleti-islemi-ekle"
              component={LocationMaterialDeliveryAddUpdate}
            />
            <Route
              path="/depo-el-aleti-islemi-guncelle"
              component={LocationMaterialDeliveryAddUpdate}
            />
            <Route
              path="/depo-el-aleti-islemi-detay"
              component={LocationMaterialDeliveryDetail}
            />
            <Route
              path="/depo-el-aleti-islemi-listele"
              component={LocationMaterialDeliveryList}
            />

            <Route path="/kalite-havuzu" component={QualityPoolList} />
            <Route path="/kalite-detay" component={QualityDetail} />

            <Route path="/kalite-gecmisi" component={QualityHistoryDetail} />

            <Route
              path="/personel-kalite-listesi"
              component={QualityPersonelList}
            />

            <Route path="/son-kontrol-havuzu" component={LastControlPoolList} />
            <Route path="/son-kontrol-detay" component={LastControlDetail} />

            <Route
              path="/personel-son-kontrol-listesi"
              component={LastControlPersonelList}
            />

            <Route
              path="/uygunluk-formu-ekle"
              component={SuitabilityFormAddUpdate}
            />
            <Route
              path="/uygunluk-formu-guncelle"
              component={SuitabilityFormAddUpdate}
            />
            <Route
              path="/uygunluk-formu-detay"
              component={SuitabilityFormDetail}
            />
            <Route
              path="/uygunluk-formu-listele"
              component={SuitabilityFormList}
            />

            <Route
              path="/manuel-kontrol-raporu-ekle"
              component={ManuelControlAddUpdate}
            />
            <Route
              path="/manuel-kontrol-raporu-guncelle"
              component={ManuelControlAddUpdate}
            />
            <Route
              path="/manuel-kontrol-raporu-detay"
              component={ManuelControlDetail}
            />
            <Route
              path="/manuel-kontrol-raporu-listele"
              component={ManuelControlList}
            />

            <Route
              path="/manuel-kontrol-raporu2-ekle"
              component={ManuelControl2AddUpdate}
            />
            <Route
              path="/manuel-kontrol-raporu2-guncelle"
              component={ManuelControl2AddUpdate}
            />
            <Route
              path="/manuel-kontrol-raporu2-detay"
              component={ManuelControl2Detail}
            />
            <Route
              path="/manuel-kontrol-raporu2-listele"
              component={ManuelControl2List}
            />

            <Route
              path="/boyutsal-olcum-raporu-ekle"
              component={DimensionalReportAddUpdate}
            />
            <Route
              path="/boyutsal-olcum-raporu-guncelle"
              component={DimensionalReportAddUpdate}
            />
            <Route
              path="/boyutsal-olcum-raporu-detay"
              component={DimensionalReportDetail}
            />
            <Route
              path="/boyutsal-olcum-raporu-listele"
              component={DimensionalReportList}
            />

            <Route
              path="/pin-itme-testi-ekle"
              component={PinPutTestAddUpdate}
            />
            <Route
              path="/pin-itme-testi-guncelle"
              component={PinPutTestAddUpdate}
            />
            <Route path="/pin-itme-testi-detay" component={PinPutTestDetail} />
            <Route path="/pin-itme-testi-listele" component={PinPutTestList} />

            <Route
              path="/pin-cekme-testi-ekle"
              component={PinPullTestAddUpdate}
            />
            <Route
              path="/pin-cekme-testi-guncelle"
              component={PinPullTestAddUpdate}
            />
            <Route
              path="/pin-cekme-testi-detay"
              component={PinPullTestDetail}
            />
            <Route
              path="/pin-cekme-testi-listele"
              component={PinPullTestList}
            />

            <Route
              path="/belge-turu2-ekle"
              component={DocumentType2AddUpdate}
            />
            <Route
              path="/belge-turu2-guncelle"
              component={DocumentType2AddUpdate}
            />
            <Route path="/belge-turu2-detay" component={DocumentType2Detail} />
            <Route path="/belge-turu2-listele" component={DocumentType2List} />

            <Route
              path="/musteri-belge-talep-ekle"
              component={DocumentRecordAddUpdate}
            />
            <Route
              path="/musteri-belge-talep-guncelle"
              component={DocumentRecordAddUpdate}
            />
            <Route
              path="/musteri-belge-talep-detay"
              component={DocumentRecordDetail}
            />
            <Route
              path="/musteri-belge-talep-listele"
              component={DocumentRecordList}
            />

            <Route
              path="/musteri-belge-kayit-ekle"
              component={CustomerDocumentRecordAddUpdate}
            />
            <Route
              path="/musteri-belge-kayit-guncelle"
              component={CustomerDocumentRecordAddUpdate}
            />
            <Route
              path="/musteri-belge-kayit-detay"
              component={CustomerDocumentRecordDetail}
            />
            <Route
              path="/musteri-belge-kayit-listele"
              component={CustomerDocumentRecordList}
            />

            <Route
              path="/odemesi-onaylanmayan-kayitlari-listele"
              component={CustomerDocumentNotPaymentList}
            />

            <Route
              path="/teknik-okuma-bekleyen-kayitlari-listele"
              component={CustomerDocumentPaymentList}
            />
            <Route
              path="/teknik-okuma-bekleyen-kayit-detay"
              component={CustomerDocumentPaymentDetail}
            />

            <Route
              path="/ceviri-bekleyen-kayitlari-listele"
              component={CustomerDocumentSuitableList}
            />
            <Route
              path="/ceviri-bekleyen-kayit-detay"
              component={CustomerDocumentSuitableDetail}
            />

            <Route
              path="/ceviri-onayi-bekleyen-kayitlari-listele"
              component={CustomerDocumentTranslationList}
            />
            <Route
              path="/ceviri-onayi-bekleyen-kayit-detay"
              component={CustomerDocumentTranslationDetail}
            />

            <Route
              path="/uts-tarama-bekleyen-kayitlari-listele"
              component={CustomerDocumentUTSSuitableList}
            />
            <Route
              path="/uts-tarama-bekleyen-kayit-detay"
              component={CustomerDocumentUTSSuitableDetail}
            />

            <Route
              path="/uts-belgeleri-onay-bekleyen-kayitlari-listele"
              component={CustomerDocumentUTSList}
            />
            <Route
              path="/uts-belgeleri-onay-bekleyen-kayit-detay"
              component={CustomerDocumentUTSDetail}
            />

            <Route
              path="/musteri-urun-belge-talep-ekle"
              component={DocumentProductRecordAddUpdate}
            />
            <Route
              path="/musteri-urun-belge-talep-guncelle"
              component={DocumentProductRecordAddUpdate}
            />
            <Route
              path="/musteri-urun-belge-talep-detay"
              component={DocumentProductRecordDetail}
            />
            <Route
              path="/musteri-urun-belge-talep-listele"
              component={DocumentProductRecordList}
            />

            <Route
              path="/musteri-urun-belge-kayit-ekle"
              component={CustomerDocumentProductRecordAddUpdate}
            />
            <Route
              path="/musteri-urun-belge-kayit-guncelle"
              component={CustomerDocumentProductRecordAddUpdate}
            />
            <Route
              path="/musteri-urun-belge-kayit-detay"
              component={CustomerDocumentProductRecordDetail}
            />
            <Route
              path="/musteri-urun-belge-kayit-listele"
              component={CustomerDocumentProductRecordList}
            />

            <Route
              path="/musteri-urun-belge-barkod-bekleyen-detay"
              component={CustomerDocumentProductNotImageDetail}
            />
            <Route
              path="/musteri-urun-belge-barkod-bekleyen-listele"
              component={CustomerDocumentProductNotImageList}
            />

            <Route
              path="/musteri-urun-belge-barkod-eklenen-detay"
              component={CustomerDocumentProductImageDetail}
            />
            <Route
              path="/musteri-urun-belge-barkod-eklenen-listele"
              component={CustomerDocumentProductImageList}
            />

            <Route
              path="/musteri-urun-belge-barkod-tamamlanan-detay"
              component={CustomerDocumentProductStatusDetail}
            />
            <Route
              path="/musteri-urun-belge-barkod-tamamlanan-listele"
              component={CustomerDocumentProductStatusList}
            />

            <Route
              path="/musteri-ticket-belgeleri-listele"
              component={CustomerTicketDocumentList}
            />
            <Route
              path="/musteri-ticket-belgeleri-detay"
              component={CustomerTicketDocumentDetail}
            />

            <Route
              path="/musteri-urun-ticket-belgeleri-listele"
              component={CustomerProductTicketDocumentList}
            />
            <Route
              path="/musteri-urun-ticket-belgeleri-detay"
              component={CustomerProductTicketDocumentDetail}
            />

            <Route
              path="/musteri-urun-belge-barkod-durum-detay"
              component={CustomerDocumentBarcodeStatusDetail}
            />
            <Route
              path="/musteri-urun-belge-barkod-durum-listele"
              component={CustomerDocumentBarcodeStatusList}
            />

            <Route
              path="/musteri-diger-islemler-ekle"
              component={CustomerDocumentOtherAddUpdate}
            />
            <Route
              path="/musteri-diger-islemler-guncelle"
              component={CustomerDocumentOtherAddUpdate}
            />
            <Route
              path="/musteri-diger-islemler-detay"
              component={CustomerDocumentOtherDetail}
            />
            <Route
              path="/musteri-diger-islemler-listele"
              component={CustomerDocumentOtherList}
            />

            <Route
              path="/musteri-diger-islemler-onay-detay"
              component={CustomerDocumentLastStatusDetail}
            />
            <Route
              path="/musteri-diger-islemler-onay-listele"
              component={CustomerDocumentLastStatusList}
            />

            <Route
              path="/diger-islemler-ticket-belgeleri-listele"
              component={OtherTicketDocumentList}
            />
            <Route
              path="/diger-islemler-ticket-belgeleri-detay"
              component={OtherTicketDocumentDetail}
            />

            <Route
              path="/manuel-kontrol-sablon-ekle"
              component={ManuelControlTemplateAddUpdate}
            />
            <Route
              path="/manuel-kontrol-sablon-guncelle"
              component={ManuelControlTemplateAddUpdate}
            />
            <Route
              path="/manuel-kontrol-sablon-detay"
              component={ManuelControlTemplateDetail}
            />
            <Route
              path="/manuel-kontrol-sablon-listele"
              component={ManuelControlTemplateList}
            />

            <Route
              path="/boyutsal-olcum-sablon-ekle"
              component={DimensionalTemplateAddUpdate}
            />
            <Route
              path="/boyutsal-olcum-sablon-guncelle"
              component={DimensionalTemplateAddUpdate}
            />
            <Route
              path="/boyutsal-olcum-sablon-detay"
              component={DimensionalTemplateDetail}
            />
            <Route
              path="/boyutsal-olcum-sablon-listele"
              component={DimensionalTemplateList}
            />

            <Route path="/sevkiyat-ekle" component={ShipmentAddUpdate} />
            <Route path="/sevkiyat-guncelle" component={ShipmentAddUpdate} />
            <Route path="/sevkiyat-detay" component={ShipmentDetail} />
            <Route path="/sevkiyat-listele" component={ShipmentList} />

            <Route path="/teklif-turu-ekle" component={OfferTypeAddUpdate} />
            <Route
              path="/teklif-turu-guncelle"
              component={OfferTypeAddUpdate}
            />
            <Route path="/teklif-turu-detay" component={OfferTypeDetail} />
            <Route path="/teklif-turu-listele" component={OfferTypeList} />

            <Route path="/urun2-ekle" component={SieveProductAddUpdate} />
            <Route path="/urun2-guncelle" component={SieveProductAddUpdate} />
            <Route path="/urun2-detay" component={SieveProductDetail} />
            <Route path="/urun2-listele" component={SieveProductList} />
            <Route
              path="/kritik-urun2-listele"
              component={SieveCriticalProductList}
            />

            <Route
              path="/dokum-maliyet-ekle"
              component={CastingCostAddUpdate}
            />
            <Route
              path="/dokum-maliyet-guncelle"
              component={CastingCostAddUpdate}
            />
            <Route path="/dokum-maliyet-detay" component={CastingCostDetail} />
            <Route path="/dokum-maliyet-listele" component={CastingCostList} />
            <Route
              path="/tum-dokum-maliyetler"
              component={CastingCostDetailList}
            />

            <Route path="/tpu-maliyet-ekle" component={TpuCostAddUpdate} />
            <Route path="/tpu-maliyet-guncelle" component={TpuCostAddUpdate} />
            <Route path="/tpu-maliyet-detay" component={TpuCostDetail} />
            <Route path="/tpu-maliyet-listele" component={TpuCostList} />
            <Route path="/tum-tpu-maliyetler" component={TpuCostDetailList} />

            <Route
              path="/odeme-yontemi-ekle"
              component={PaymentTypeAddUpdate}
            />
            <Route
              path="/odeme-yontemi-guncelle"
              component={PaymentTypeAddUpdate}
            />
            <Route path="/odeme-yontemi-detay" component={PaymentTypeDetail} />
            <Route path="/odeme-yontemi-listele" component={PaymentTypeList} />

            <Route
              path="/teslimat-suresi-ekle"
              component={DeliveryTimeAddUpdate}
            />
            <Route
              path="/teslimat-suresi-guncelle"
              component={DeliveryTimeAddUpdate}
            />
            <Route
              path="/teslimat-suresi-detay"
              component={DeliveryTimeDetail}
            />
            <Route
              path="/teslimat-suresi-listele"
              component={DeliveryTimeList}
            />

            <Route
              path="/teslimat-yontemi-ekle"
              component={DeliveryTypeAddUpdate}
            />
            <Route
              path="/teslimat-yontemi-guncelle"
              component={DeliveryTypeAddUpdate}
            />
            <Route
              path="/teslimat-yontemi-detay"
              component={DeliveryTypeDetail}
            />
            <Route
              path="/teslimat-yontemi-listele"
              component={DeliveryTypeList}
            />

            <Route
              path="/garanti-suresi-ekle"
              component={WarrantyPeriodAddUpdate}
            />
            <Route
              path="/garanti-suresi-guncelle"
              component={WarrantyPeriodAddUpdate}
            />
            <Route
              path="/garanti-suresi-detay"
              component={WarrantyPeriodDetail}
            />
            <Route
              path="/garanti-suresi-listele"
              component={WarrantyPeriodList}
            />

            <Route path="/teklif3-ekle" component={OfferSieveAddUpdate} />
            <Route path="/teklif3-guncelle" component={OfferSieveAddUpdate} />
            <Route path="/teklif3-detay" component={OfferSieveDetail} />
            <Route path="/teklif3-onaylanan-detay" component={OfferSieveDetail} />
            <Route path="/teklif3-sevk-edilen-detay" component={OfferSieveDetail} />
            <Route path="/teklif3-listele" component={OfferSieveList} />
            <Route path="/teklif32-ekle" component={OfferSieveAddUpdate} />
            <Route path="/teklif32-guncelle" component={OfferSieveAddUpdate} />
            <Route path="/teklif32-detay" component={OfferSieveDetail} />
            <Route path="/teklif32-listele" component={OfferSieveList} />
            <Route path="/teklif33-ekle" component={OfferSieveAddUpdate} />
            <Route path="/teklif33-guncelle" component={OfferSieveAddUpdate} />
            <Route path="/teklif33-detay" component={OfferSieveDetail} />
            <Route path="/teklif33-listele" component={OfferSieveList} />
            <Route
              path="/teklif3-onaylanan-listele"
              component={OfferSieveListApproved}
            />
            <Route
              path="/teklif3-sevk-edilen-listele"
              component={OfferSieveListShipped}
            />
            <Route
              path="/uretim-takibi-listele"
              component={OfferSieveListApproved2}
            />
            <Route
              path="/uretim-takibi-listele-2"
              component={OfferSieveListApproved3}
            />
            <Route path="/uretim-takibi-detay" component={OfferSieveDetail2} />

            <Route
              path="/personel-teklif3-ekle"
              component={PersonelOfferSieveAddUpdate}
            />
            <Route
              path="/personel-teklif3-guncelle"
              component={PersonelOfferSieveAddUpdate}
            />
            <Route
              path="/personel-teklif3-detay"
              component={PersonelOfferSieveDetail}
            />
            <Route
              path="/personel-teklif3-listele"
              component={PersonelOfferSieveList}
            />

            <Route path="/teklif-raporlari" component={OfferSieveReportList} />
            <Route
              path="/teklif-rapor-detay"
              component={OfferSieveReportDetail}
            />

            <Route
              path="/makine-model-ekle"
              component={MachineModelAddUpdate}
            />
            <Route
              path="/makine-model-guncelle"
              component={MachineModelAddUpdate}
            />
            <Route path="/makine-model-detay" component={MachineModelDetail} />
            <Route path="/makine-model-listele" component={MachineModelList} />

            <Route
              path="/makine-durum-ekle"
              component={MachineStatusAddUpdate}
            />
            <Route
              path="/makine-durum-guncelle"
              component={MachineStatusAddUpdate}
            />
            <Route path="/makine-durum-detay" component={MachineStatusDetail} />
            <Route path="/makine-durum-listele" component={MachineStatusList} />

            <Route path="/yeni-makine-ekle" component={NewMachineAddUpdate} />
            <Route
              path="/yeni-makine-guncelle"
              component={NewMachineAddUpdate}
            />
            <Route path="/yeni-makine-detay" component={NewMachineDetail} />
            <Route path="/yeni-makine-listele" component={NewMachineList} />

            <Route path="/urun3-ekle" component={MachineProductAddUpdate} />
            <Route path="/urun3-guncelle" component={MachineProductAddUpdate} />
            <Route path="/urun3-detay" component={MachineProductDetail} />
            <Route
              path="/urun3-depo-detay"
              component={MachineProductLocationDetail}
            />
            <Route
              path="/urun3-listele"
              render={(data) => <MachineProductList data={this.props.data} />}
            />
            <Route
              path="/depomdaki-urunler"
              render={(data) => (
                <MachineProductLocationList data={this.props.data} />
              )}
            />
            <Route
              path="/urun3-adet-listele"
              component={MachinePieceProductList}
            />
            <Route
              path="/depomdaki-urun-adetleri"
              component={MachinePieceProductLocationList}
            />
            <Route
              path="/kritik-urun3-listele"
              render={(data) => (
                <MachineCriticalProductList data={this.props.data} />
              )}
            />

            <Route
              path="/alis4-ekle"
              component={ProductEntryMachineAddUpdate}
            />
            <Route
              path="/alis4-guncelle"
              component={ProductEntryMachineAddUpdate}
            />
            <Route path="/alis4-detay" component={ProductEntryMachineDetail} />
            <Route path="/alis4-listele" component={ProductEntryMachineList} />

            <Route path="/satis4-ekle" component={ProductOutMachineAddUpdate} />
            <Route
              path="/satis4-guncelle"
              component={ProductOutMachineAddUpdate}
            />
            <Route path="/satis4-detay" component={ProductOutMachineDetail} />
            <Route path="/satis4-listele" component={ProductOutMachineList} />

            <Route path="/teklif4-ekle" component={OfferMachineAddUpdate} />
            <Route path="/teklif4-guncelle" component={OfferMachineAddUpdate} />
            <Route path="/teklif4-detay" component={OfferMachineDetail} />
            <Route path="/teklif4-listele" component={OfferMachineList} />

            <Route
              path="/sayim2-ekle"
              component={ProductCountMachineAddUpdate}
            />
            <Route
              path="/sayim2-guncelle"
              component={ProductCountMachineAddUpdate}
            />
            <Route path="/sayim2-detay" component={ProductCountMachineDetail} />
            <Route path="/sayim2-listele" component={ProductCountMachineList} />
            <Route
              path="/yetkili-sayim2-listele"
              component={ProductCountMachineGetList}
            />
            <Route
              path="/yetkili-sayim2-detay"
              component={ProductCountMachineGetDetail}
            />

            <Route
              path="/stok-aktarim-ekle"
              component={TransferStockAddUpdate}
            />
            <Route
              path="/stok-aktarim-guncelle"
              component={TransferStockAddUpdate}
            />
            <Route path="/stok-aktarim-detay" component={TransferStockDetail} />
            <Route path="/stok-aktarim-listele" component={TransferStockList} />
            <Route
              path="/yetkili-stok-aktarim-listele"
              component={TransferStockGetList}
            />
            <Route
              path="/yetkili-stok-aktarim-detay"
              component={TransferStockGetDetail}
            />
            <Route
              path="/yetkili-stok-aktarim-stok-hareketleri"
              component={TransferStockMovements}
            />

            <Route
              path="/uretim-planlama-detay"
              component={OfferSieveProductionDetail}
            />
            <Route
              path="/uretim-planlama"
              component={OfferSieveProductionList}
            />

            <Route
              path="/satin-alma-detay"
              component={OfferSieveProductEntryDetail}
            />
            <Route path="/satin-alma" component={OfferSieveProductEntryList} />

            <Route
              path="/satin-alma-is-emri-ekle"
              component={PurchaseWorkOrdersAddUpdate}
            />
            <Route
              path="/satin-alma-is-emri-guncelle"
              component={PurchaseWorkOrdersAddUpdate}
            />
            <Route
              path="/satin-alma-is-emri-detay"
              component={PurchaseWorkOrdersDetail}
            />
            <Route
              path="/satin-alma-is-emri-listele"
              component={PurchaseWorkOrdersList}
            />
            <Route
              path="/yetkili-satin-alma-is-emri-detay"
              component={PurchaseWorkOrdersDetail}
            />
            <Route
              path="/yetkili-satin-alma-is-emri"
              component={PurchaseWorkOrdersList}
            />

            <Route path="/kaliphane-detay" component={MoldingRoomDetail} />
            <Route path="/kaliphane" component={MoldingRoomList} />

            <Route path="/uretim-detay" component={OfferGenerationDetail} />
            <Route path="/uretim" component={OfferGenerationList} />

            <Route path="/uretim-arsivi-detay" component={OfferGenerationDetail2} />
            <Route path="/uretim-arsivi" component={OfferGenerationList2} />

            <Route
              path="/uretim-sevkiyat-detay"
              component={OfferShipmentDetail}
            />
            <Route path="/sevkiyat" component={OfferShipmentList} />

            <Route
              path="/dokum-sabitleri-ekle"
              component={FixedCastingAddUpdate}
            />
            <Route
              path="/dokum-sabitleri-guncelle"
              component={FixedCastingAddUpdate}
            />
            <Route
              path="/dokum-sabitleri-detay"
              component={FixedCastingDetail}
            />
            <Route
              path="/dokum-sabitleri-listele"
              component={FixedCastingList}
            />

            <Route path="/tpu-sabitleri-ekle" component={FixedTpuAddUpdate} />
            <Route
              path="/tpu-sabitleri-guncelle"
              component={FixedTpuAddUpdate}
            />
            <Route path="/tpu-sabitleri-detay" component={FixedTpuDetail} />
            <Route path="/tpu-sabitleri-listele" component={FixedTpuList} />

            <Route
              path="/uretim-turu-ekle"
              component={TypeProductionAddUpdate}
            />
            <Route
              path="/uretim-turu-guncelle"
              component={TypeProductionAddUpdate}
            />
            <Route path="/uretim-turu-detay" component={TypeProductionDetail} />
            <Route path="/uretim-turu-listele" component={TypeProductionList} />

            <Route
              path="/gelen-fatura-listele"
              component={ListIncomingInvoice}
            />
            <Route
              path="/gelen-fatura-detay"
              component={IncomingInvoiceDetail}
            />
            <Route
              path="/giden-fatura-listele"
              component={ListOutgoingInvoice}
            />
            <Route
              path="/giden-fatura-detay"
              component={OutgoingInvoiceDetail}
            />

            <Route path="/is-raporlari" component={MachineReportList} />
            <Route path="/anasayfa" component={HomePage} />

            <Route
              path="/musteri-egitim-ekle"
              component={CustomerEducationAddUpdate}
            />
            <Route
              path="/musteri-egitim-guncelle"
              component={CustomerEducationAddUpdate}
            />
            <Route
              path="/musteri-egitim-detay"
              component={CustomerEducationDetail}
            />
            <Route
              path="/musteri-egitim-listele"
              component={CustomerEducationList}
            />

            <Route
              path="/musteri-egitim-youtube-ekle"
              component={CustomerEducationYoutubeAddUpdate}
            />
            <Route
              path="/musteri-egitim-youtube-guncelle"
              component={CustomerEducationYoutubeAddUpdate}
            />
            <Route
              path="/musteri-egitim-youtube-detay"
              component={CustomerEducationYoutubeDetail}
            />
            <Route
              path="/musteri-egitim-youtube-listele"
              component={CustomerEducationYoutubeList}
            />

            <Route
              path="/musteri-faydali-link-ekle"
              component={CustomerUsefulLinksAddUpdate}
            />
            <Route
              path="/musteri-faydali-link-guncelle"
              component={CustomerUsefulLinksAddUpdate}
            />
            <Route
              path="/musteri-faydali-link-detay"
              component={CustomerUsefulLinksDetail}
            />
            <Route
              path="/musteri-faydali-link-listele"
              component={CustomerUsefulLinksList}
            />

            <Route
              path="/musteri-egitim-sinav-ekle"
              component={CustomerEducationExamAddUpdate}
            />
            <Route
              path="/musteri-egitim-sinav-guncelle"
              component={CustomerEducationExamAddUpdate}
            />
            <Route
              path="/musteri-egitim-sinav-detay"
              component={CustomerEducationExamDetail}
            />
            <Route
              path="/musteri-egitim-sinav-listele"
              component={CustomerEducationExamList}
            />

            <Route path="/is-kazasi-ekle" component={WorkAccidentAddUpdate} />
            <Route
              path="/is-kazasi-guncelle"
              component={WorkAccidentAddUpdate}
            />
            <Route path="/is-kazasi-detay" component={WorkAccidentDetail} />
            <Route path="/is-kazasi-listele" component={WorkAccidentList} />

            <Route
              path="/makine-urun-siparis-ekle"
              render={(data) => (
                <MachineProductOrderAddUpdate data={this.props.data} />
              )}
            />
            <Route
              path="/makine-urun-siparis-guncelle"
              render={(data) => (
                <MachineProductOrderAddUpdate data={this.props.data} />
              )}
            />
            <Route
              path="/makine-urun-siparis-detay"
              component={MachineProductOrderDetail}
            />
            <Route
              path="/makine-urun-siparis-listele"
              component={MachineProductOrderList}
            />

            <Route
              path="/yetkili-makine-urun-siparis-detay"
              component={MachineProductOrderDetail}
            />
            <Route
              path="/yetkili-makine-urun-siparis-listele"
              component={MachineProductOrderList}
            />

            <Route
              path="/kullanilan-yedek-parca-ekle"
              component={UsedSparePartsAddUpdate}
            />
            <Route
              path="/kullanilan-yedek-parca-guncelle"
              component={UsedSparePartsAddUpdate}
            />
            <Route
              path="/kullanilan-yedek-parca-detay"
              component={UsedSparePartsDetail}
            />
            <Route
              path="/kullanilan-yedek-parca-listele"
              component={UsedSparePartsList}
            />

            <Route
              path="/kullanilan-yedek-parca-stok-hareketleri"
              component={UsedSparePartsStockMovements}
            />

            <Route
              path="/depolardan-cikarilan-urunler"
              component={MachineStockRemoveList}
            />
            <Route
              path="/depolardan-cikarilan-urunler-detay"
              component={MachineStockRemoveDetail}
            />

            <Route
              path="/geri-donusum-ekle"
              component={RecyclingProductsAddUpdate}
            />
            <Route
              path="/geri-donusum-guncelle"
              component={RecyclingProductsAddUpdate}
            />
            <Route
              path="/geri-donusum-detay"
              component={RecyclingProductsLocationDetail}
            />
            <Route
              path="/geri-donusum-listele"
              component={RecyclingProductsLocationList}
            />

            <Route
              path="/yetkili-geri-donusum-detay"
              component={RecyclingProductsDetail}
            />
            <Route
              path="/yetkili-geri-donusum-listele"
              component={RecyclingProductsList}
            />

            <Route path="/personel2-ekle" component={Employee2AddUpdate} />
            <Route path="/personel2-guncelle" component={Employee2AddUpdate} />
            <Route path="/personel2-detay" component={Employee2Detail} />
            <Route path="/personel2-listele" component={Employee2List} />

            <Route
              path="/prosedurler-cs-savunma"
              component={ProcedureCSSavunma}
            />
            <Route
              path="/talimatlar-cs-savunma"
              component={InstructionsCSSavunma}
            />

            <Route
              path="/kargo-firmalari-ekle"
              component={ShippingCompaniesAddUpdate}
            />
            <Route
              path="/kargo-firmalari-guncelle"
              component={ShippingCompaniesAddUpdate}
            />
            <Route
              path="/kargo-firmalari-detay"
              component={ShippingCompaniesDetail}
            />
            <Route
              path="/kargo-firmalari-listele"
              component={ShippingCompaniesList}
            />

            <Route path="/kargo-turu-ekle" component={ShippingTypeAddUpdate} />
            <Route
              path="/kargo-turu-guncelle"
              component={ShippingTypeAddUpdate}
            />
            <Route path="/kargo-turu-detay" component={ShippingTypeDetail} />
            <Route path="/kargo-turu-listele" component={ShippingTypeList} />

            <Route
              path="/kargo-takip-ekle"
              component={CargoTrackingAddUpdate}
            />
            <Route
              path="/kargo-takip-guncelle"
              component={CargoTrackingAddUpdate}
            />
            <Route path="/kargo-takip-detay" component={CargoTrackingDetail} />
            <Route path="/kargo-takip-listele" component={CargoTrackingList} />

            <Route path="/e-mail-gorev-ekle" component={EMailQuestAddUpdate} />
            <Route
              path="/e-mail-gorev-guncelle"
              component={EMailQuestAddUpdate}
            />
            <Route path="/e-mail-gorev-detay" component={EMailQuestDetail} />
            <Route path="/e-mail-gorev-listele" component={EMailQuestList} />
            <Route
              path="/personel-e-mail-gorev-ekle"
              component={PersonEMailQuestAddUpdate}
            />
            <Route
              path="/personel-e-mail-gorev-guncelle"
              component={PersonEMailQuestAddUpdate}
            />
            <Route
              path="/personel-e-mail-gorev-listele"
              component={PersonEMailQuestList}
            />
            <Route
              path="/personel-e-mail-gorev-detay"
              component={PersonEMailQuestDetail}
            />

            <Route
              path="/gmail-api-bilgileri-ekle"
              component={GmailApiInformationAddUpdate}
            />
            <Route
              path="/gmail-api-bilgileri-guncelle"
              component={GmailApiInformationAddUpdate}
            />
            <Route
              path="/gmail-api-bilgileri-detay"
              component={GmailApiInformationDetail}
            />
            <Route
              path="/gmail-api-bilgileri-listele"
              component={GmailApiInformationList}
            />

            <Route
              path="/gmail-api-engellenenler-ekle"
              component={GMailAPIBlockedAddUpdate}
            />
            <Route
              path="/gmail-api-engellenenler-guncelle"
              component={GMailAPIBlockedAddUpdate}
            />
            <Route
              path="/gmail-api-engellenenler-detay"
              component={GMailAPIBlockedDetail}
            />
            <Route
              path="/gmail-api-engellenenler-listele"
              component={GMailAPIBlockedList}
            />

            <Route path="/raporlar-nestle" component={ReportNestleList} />

            <Route
              path="/brosur-kategori-ekle"
              component={BrochureCategoryAddUpdate}
            />
            <Route
              path="/brosur-kategori-guncelle"
              component={BrochureCategoryAddUpdate}
            />
            <Route
              path="/brosur-kategori-detay"
              component={BrochureCategoryDetail}
            />
            <Route
              path="/brosur-kategori-listele"
              component={BrochureCategoryList}
            />

            <Route path="/brosur-ekle" component={BrochureAddUpdate} />
            <Route path="/brosur-guncelle" component={BrochureAddUpdate} />
            <Route path="/brosur-detay" component={BrochureDetail} />
            <Route path="/brosur-listele" component={BrochureList} />

            <Route path="/dosya-ekle" component={FilesAddUpdate} />
            <Route path="/dosya-guncelle" component={FilesAddUpdate} />
            <Route path="/dosya-detay" component={FilesDetail} />
            <Route path="/dosya-listele" component={FilesList} />

            <Route
              path="/arizali-urun-ekle"
              component={DefectiveQuantityAddUpdate}
            />
            <Route
              path="/arizali-urun-guncelle"
              component={DefectiveQuantityAddUpdate}
            />
            <Route
              path="/arizali-urun-detay"
              component={DefectiveQuantityDetail}
            />
            <Route
              path="/arizali-urun-listele"
              component={DefectiveQuantityList}
            />
            <Route
              path="/yetkili-arizali-urun-listele"
              component={DefectiveQuantityAdminList}
            />
            <Route
              path="/yetkili-arizali-urun-detay"
              component={DefectiveQuantityAdminDetail}
            />
            <Route
              path="/kullanici-giris-bilgileri-listele"
              component={UserLoginInformationList}
            />

            <Route path="/belge-takip-ekle" component={DocumentTrackingAddUpdate} />
            <Route path="/belge-takip-guncelle" component={DocumentTrackingAddUpdate} />
            <Route path="/belge-takip-detay" component={DocumentTrackingDetail} />
            <Route path="/belge-takip-listele" component={DocumentTrackingList} />

            <Route path="/musteri-egitim-duyuru-ekle" component={CustomerEducationAnnouncementAddUpdate} />
            <Route path="/musteri-egitim-duyuru-guncelle" component={CustomerEducationAnnouncementAddUpdate} />
            <Route path="/musteri-egitim-duyuru-detay" component={CustomerEducationAnnouncementDetail} />
            <Route path="/musteri-egitim-duyuru-listele" component={CustomerEducationAnnouncementList} />

            <Route path="/musteri-egitim-duyuru-e-mail-ekle" component={CustomerEducationAnnouncementEMailAddUpdate} />
            <Route path="/musteri-egitim-duyuru-e-mail-guncelle" component={CustomerEducationAnnouncementEMailAddUpdate} />
            <Route path="/musteri-egitim-duyuru-e-mail-detay" component={CustomerEducationAnnouncementEMailDetail} />
            <Route path="/musteri-egitim-duyuru-e-mail-listele" component={CustomerEducationAnnouncementEMailList} />

            <Route path="/urun-grubu-ekle" component={ProductGroupAddUpdate} />
            <Route path="/urun-grubu-guncelle" component={ProductGroupAddUpdate} />
            <Route path="/urun-grubu-detay" component={ProductGroupDetail} />
            <Route path="/urun-grubu-listele" component={ProductGroupList} />

            <Route path="/teklif32-raporlari" component={OfferSieveReport} />
            <Route path="/teklif33-raporlari" component={OfferSieveReport} />

            {localStorage.getItem("isOrderCount") === "true" ? (
              <Route exact path="/" component={ReportNestleList} />
            ) : (
              <Route exact path="/" component={CalendarListUser} />
            )}
          </Switch>
          <Row>
            <Col>
              <FooterContent />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Content;
