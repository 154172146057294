import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function groupGetListSelectReducer(state = initialState.groupGetListSelect, action) {
  switch (action.type) {
    case actionTypes.GROUP_GET_LIST_SELECT_SUCCESS:
      return action.payload;
      case actionTypes.GROUP_GET_LIST_SELECT_ERROR:
        return action.payload;
    default:
      return state;
  }
}
