import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import CgnMessage from "../toolbox/CgnMessage";


const CgnBreadcrumb = ({ items, title }) => {
  return (
    <>
      {items && items.length > 0 && (
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="/">
            {CgnMessage.programName}
          </BreadcrumbItem>
          {items.map((item, index) => (
            <BreadcrumbItem key={index} tag="a" href={item.link}>
              {item.text}
            </BreadcrumbItem>
          ))}
          <BreadcrumbItem active tag="span">
            {title}
          </BreadcrumbItem>
        </Breadcrumb>
      )}
    </>
  );
};

export default CgnBreadcrumb;
