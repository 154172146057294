import Resizer from "react-image-file-resizer";
import axios from "axios";

// WEB YAYIN
export const URL = "https://sysbackend.cogenteknoloji.com:443";

// LOCAL YAYIN
//export const URL = "http://11.11.11.68:5001";

// LİBERYUS YAYIN
//export const URL = "http://192.168.0.24:5001";

// CS SAVUNMA YAYIN
//export const URL = "http://192.168.1.7:5001";

// WEB YAYIN
export const localFilePath = "C:\\inetpub\\wwwroot\\cogenteknoloji.com\\cogenteknoloji.com_sysbackend";

// LOCAL / LİBERYUS YAYIN
//export const localFilePath = "D:\\GitHub_CogenYazilim\\Complex.Rest\\WebAPI";

// CS SAVUNMA YAYIN
//export const localFilePath = "C:\\inetpub\\wwwroot\\cogen_backend";

var UserOperationClaims = [];

export async function UserOperationClaimControl(operationClaimId) {
  // 
  var result = false;
  if (UserOperationClaims && UserOperationClaims.length === 0) {
    if (localStorage.getItem("token")) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/users/getuseroperationclaims", config)
        .then((response) => {
          response.data.forEach((element) => {
            UserOperationClaims.push(element.operationClaimId);
          });
        })
        .catch(() => {
          result = false;
        });
    }
  }
  UserOperationClaims.forEach((element) => {
    if (operationClaimId === element) {
      result = true;
    }
  });
  return result;
}

export function GetMonthFirstDate() {
  const currentDate = new Date();
  const getMonth = currentDate.getMonth() + 1;
  const getFullYear = currentDate.getFullYear();
  return `${getMonth}.1.${getFullYear}`;
}

export function GetMonthFirstDateYearElkapol() {
  const getFullYear = 2023;
  return `1.1.${getFullYear}`;
}

export function GetMonthFirstDateYear() {
  const currentDate = new Date();
  const getFullYear = currentDate.getFullYear();
  return `1.1.${getFullYear}`;
}

export function GetMonthFirstDate2Elkapol() {
  var date = new Date();
  return new Date(2023, date.getMonth(), 1);
}

export function GetMonthFirstDate2() {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function GetMonthFirstDate2Year() {
  var date = new Date();
  return new Date(date.getFullYear(), 0, 1);
}

export function GetMonthFirstDate2YearElkapol() {
  return new Date(2023, 0, 1);
}


export function GetMonthLastDate() {
  const currentDate = new Date();
  const getMonth = currentDate.getMonth() + 1;
  const getFullYear = currentDate.getFullYear();
  var y = 0;
  var day = 0;
  if (getMonth > 9) {
    y = "";
  }
  if (
    getMonth === 1 ||
    getMonth === 3 ||
    getMonth === 5 ||
    getMonth === 7 ||
    getMonth === 8 ||
    getMonth === 10 ||
    getMonth === 12
  ) {
    day = 31;
  }
  if (getMonth === 4 || getMonth === 6 || getMonth === 9 || getMonth === 11) {
    day = 30;
  }
  if (
    getMonth === 2 &&
    (getFullYear === 2016 ||
      getFullYear === 2020 ||
      getFullYear === 2024 ||
      getFullYear === 2028)
  ) {
    day = 29; //max
  } else if (getMonth === 2) {
    day = 28; //max
  }
  return `${y}${getMonth}.${day}.${getFullYear}`;
}

export function GetMonthLastDateYear() {
  const currentDate = new Date();
  const getFullYear = currentDate.getFullYear() + 1;
  return `1.1.${getFullYear}`;
}

export function GetMonthLastDate2() {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function GetMonthLastDate2Year() {
  var date = new Date();
  return new Date(date.getFullYear() + 1, 0, 1);
}

export async function CategoryList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/categories/getlistselect/", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function ProductList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/products/getlistselect/", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code} - ${item.name}`,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function UserList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/members/getmemberlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function MembershipList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/members/getmemberlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function CustomerList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/customers/getlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            customerGroupName:
              item.customerGroupName === null ? "" : item.customerGroupName,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function ProductOutList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/productOutsSecond/getlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label:
              item.productOutDate +
              " " +
              item.customerName +
              " " +
              item.total +
              " " +
              (item.currencyType === 1
                ? "TL"
                : item.currencyType === 2
                  ? "$"
                  : "€"),
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function ProductOutList2() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/productOutsSecond/getlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.productOutDate + " " + item.customerName,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function TypeTableCustomerList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var personGuid = localStorage.getItem("guid");
    axios
      .get(URL + "/customers/getlisttypetablecustomer/1/" + personGuid, config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            customerGroupName:
              item.customerGroupName === null ? "" : item.customerGroupName,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function TypeList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/types/getlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function WorkInstructionList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/workInstructions/getliststatus/1", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label:
              item.workDate + "-" + item.productName + "-" + item.customerName,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function TaskPriorityList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/taskPriorities/getlist/2", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function TaskSituationList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/taskSituation/getlist/2", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function ServiceTypeList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/serviceTypes/getlist/1", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function MachineList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/machines/getlist/2", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.machineName,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function CityListTurkiye() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/cities/getlistselectturkiye", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function SupplierList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/suppliers/getlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export async function CostTypeList() {
  // 
  return new Promise((resolve, reject) => {
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/costtypes/getlistselect", config)
      .then((response) => {
        var data = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

export function CabinTypeList(cabinTypeId) {
  var cabinTypeName = "";
  CabinType.forEach((element) => {
    if (element.value === cabinTypeId) {
      cabinTypeName = element.label;
    }
  });
  return cabinTypeName;
}

export const Area = [
  { label: "İstanbul Avrupa", value: 1 },
  { label: "İstanbul Anadolu", value: 2 },
  { label: "İç Anadolu", value: 3 },
  { label: "Karadeniz", value: 4 },
];

export const optionStatuses = [
  { label: "Aktif", value: true },
  { label: "Pasif", value: false },
];

export const machineProductOptionStatuses = [
  { label: "Bekliyor", value: 1 },
  { label: "Kargolandı", value: 2 },
  { label: "Tamamlandı", value: 3 },
  { label: "Askıda", value: 4 },
];

export const statusStatusMedikoz = [
  { label: "Bekliyor", value: 1 },
  { label: "İşlemde", value: 2 },
  { label: "Tamamlandı", value: 3 },
];

export const machineProductOptionStatuses1 = [
  { label: "Bekliyor", value: 1 },
  { label: "Kargolandı", value: 2 },
];

export const cargoCompanys = [
  { label: "Yurtiçi Kargo", value: 1 },
  { label: "MNG Kargo", value: 2 },
];

export const stockTransferProcessStatus = [
  { label: "Bekliyor", value: 1 },
  { label: "Kargolandı", value: 2 },
  { label: "Elden Teslim Edildi", value: 3 },
  { label: "Tamamlandı", value: 4 },
];

export const machineProductOptionStatuses2 = [
  { label: "Tamamlandı", value: 3 },
];

export const cargoStatuses = [
  { label: "Bekliyor", value: 1 },
  { label: "İşlemde", value: 2 },
  { label: "Tamamlandı", value: 3 },
  { label: "Teslim Edildi", value: 4 },
  { label: "İptal Edildi", value: 5 },
  { label: "İade Edildi", value: 6 },
];

export const purchaseWorkOrders = [
  { label: "Bekliyor", value: 0 },
  { label: "Devam Ediyor", value: 1 },
  { label: "Kargolandı", value: 2 },
  { label: "Tamamlandı", value: 3 },
];

export const purchaseWorkOrdersElkapol = [
  { label: "Bekliyor", value: 0 },
  { label: "Devam Ediyor", value: 1 },
  { label: "Onaylanmadı", value: 2 },
  { label: "Tamamlandı", value: 3 },
];

export const purchaseWorkOrdersEmployee = [
  { label: "Bekliyor", value: 0 },
  { label: "Tamamlandı", value: 3 },
];

export const costTypeStatuses = [
  { label: "Enj", value: 1 },
  { label: "Döküm", value: 2 },
];

export const costTypes = [
  { label: "Poliüretan Döküm", value: 1 },
  { label: "Poliüretan Enjeksiyon", value: 2 },
  { label: "Kauçuk", value: 3 },
  { label: "AL-SAT", value: 4 },
];

export const barcodeStatuses = [
  { label: "Barkodlu", value: 1 },
  { label: "Barkodsuz", value: 2 },
];

export const entryTypes = [
  { label: "Giriş", value: 1 },
  { label: "Teslim", value: 2 },
  { label: "İade", value: 3 },
];

export const resultChanges = [
  { label: "Kabul", value: 1 },
  { label: "Red", value: 2 },
];

export const viewStatuses = [
  { label: "Görünsün", value: 1 },
  { label: "Görünmesin!", value: 2 },
];

export const kitStatuses = [
  { label: "Görünsün", value: 1 },
  { label: "Görünmesin!", value: 2 },
];

export const viewExchangeRate = [
  { label: "Görünsün", value: true },
  { label: "Görünmesin!", value: false },
];

export const personWorkInstructionStatus = [
  { label: "Görünsün", value: true },
  { label: "Görünmesin!", value: false },
];

export const shipmentStatuses = [
  { label: "Hiç Sevk Edilmedi", value: 1 },
  { label: "Kısmi Sevk Edildi", value: 2 },
  { label: "Sevk Edildi", value: 3 },
  { label: "Hazır", value: 4 },
];

export const emailStatus = [
  { label: "E-mail Gönderilsin", value: 1 },
  { label: "E-mail Gönderilmesin !", value: 2 },
];

export const offerLanguages = [
  { label: "Türkçe", value: 1 },
  { label: "İngilizce", value: 2 },
  { label: "Fransızca", value: 3 },
  { label: "Almanca", value: 4 },
  { label: "Arapça", value: 5 },
];

export const languages = [
  { label: "Türkçe", value: 1 },
  { label: "İngilizce", value: 2 }
];

export const productOutStatuses = [
  { label: "Tümü", value: 1 },
  { label: "Tahsilat Kalan", value: 2 },
  { label: "Tamamlanan", value: 3 },
];

export const remindPersonStatuses = [
  { label: "Yönetici", value: 1 },
  { label: "Müşteri", value: 2 },
  { label: "Yönetici ve Müşteri", value: 3 },
];

export const remindOptionStatuses = [
  { label: "Her ayın son günü", value: 1 },
  { label: "Gün seç", value: 2 },
  { label: "Gün ve Ay seç", value: 3 },
];

export const endDateOptionStatuses = [
  { label: "Gün seç", value: 1 },
  { label: "Gün ve Ay seç", value: 2 },
];

export const iTypeStatuses = [
  { label: "Bakım", value: 1 },
  { label: "Tamir/Tadilat", value: 2 },
  { label: "Operasyon", value: 3 },
  { label: "Dış Servis", value: 4 },
];

export const documentTypes = [
  { label: "E-Mail Gönderimi", value: 1 },
  { label: "Kargo Gönderimi", value: 2 },
  { label: "E-Mail ve Kargo Gönderimi", value: 3 },
];

export const errorReasons = [
  { label: "İşçilik", value: 1 },
  { label: "Makine-Ekipman", value: 2 },
  { label: "Malzeme", value: 3 },
  { label: "Diğer", value: 4 },
];

export const detectionPhases = [
  { label: "Ara Kontrol", value: 1 },
  { label: "Son Kontrol", value: 2 },
  { label: "Müşteri İadesi", value: 3 },
];

export const decisions = [
  { label: "Olduğu gibi kullan (*)", value: 1 },
  { label: "Şartlı kabul (*)", value: 2 },
  { label: "Kabul", value: 3 },
  { label: "Uygun olmayan ürün", value: 4 },
];

export const dfDescriptions = [
  { label: "Df Açılması uygundur", value: 1 },
  { label: "Df Açılmasına gerek yoktur.", value: 2 },
];

export const manuelControlStatus = [
  { label: "Uygun", value: 1 },
  { label: "Uygun Değil", value: 2 },
];

export const shipmentStatus = [
  { label: "Sevkiyata Hazır", value: 1 },
  { label: "Hazır Değil", value: 2 },
];

export const offerStatus = [
  { label: "Satınalma", value: "1" },
  { label: "Kalıphane", value: "2" },
  { label: "Üretim", value: "3" },
];

export const utsStatus = [
  { label: "Onay", value: 4 },
  { label: "Reddedildi", value: 5 },
  { label: "Kayıtlı", value: 6 },
  { label: "Revizyon", value: 7 },
  { label: "Teslim Edildi", value: 8 },
];

export const documentStatus = [
  { label: "Uygun", value: 1 },
  { label: "Uygun Değil", value: 2 },
  { label: "Tümü", value: 3 },
];

export const machineProductUnits = [
  { label: "Adet", value: 1 },
  { label: "Metre", value: 2 },
  { label: "Kilogram", value: 3 },
  { label: "Rulo", value: 4 },
];

export const SendMachineProductUnits = (value) => {
  switch (value) {
    case 1:
      return "Adet";
    case 2:
      return "Metre";
    case 3:
      return "Kilogram";
    default:
      return "";
  }
};

export const machineProductStatuses = [
  { label: "İthal", value: 1 },
  { label: "Yerli", value: 2 },
  { label: "Sarf", value: 3 },
];

export const checkboxStatus = [
  { label: "Uygun", value: 1 },
  { label: "Uygunsuz", value: 2 },
  { label: "Atla", value: 3 },
];

export const BillTypes = [
  { label: "Satış Faturası", value: 1 },
  { label: "İade Faturası", value: 2 },
  { label: "Tevkifat", value: 3 },
  { label: "İstisna", value: 4 },
  { label: "Özel Matrah", value: 5 },
  { label: "İhraç Kayıtlı", value: 6 },
  { label: "İhracat", value: 7 },
];

export const DeliveryType = [
  { label: "CFR => Cost And Freight", value: "CFR" },
  { label: "CIF => Cost, Insurance And Freight", value: "CIF" },
  { label: "CIP => Carriage And Insurance Paid To", value: "CIP" },
  { label: "CPT => Carriage Paid To", value: "CPT" },
  { label: "DAF => Delivered At Frontier", value: "DAF" },
  { label: "DAP => Delivered At Place", value: "DAP" },
  { label: "DAT => Delivered At Terminal", value: "DAT" },
  { label: "DDP => Delivered Duty Paid", value: "DDP" },
  { label: "DDU => Delivered Duty Unpaid", value: "DDU" },
  { label: "DEQ => Delivered Ex Quay", value: "DEQ" },
  { label: "DES => Delivered Ex Ship", value: "DES" },
  { label: "EXW => Ex Works", value: "EXW" },
  { label: "FAS => Free Along Ship", value: "FAS" },
  { label: "FCA => Free Carrier", value: "FCA" },
  { label: "FOB => Free On Board", value: "FOB" },
];

export const TransportType = [
  { label: "0 => Transport mode not specified", value: 0 },
  { label: "1 => Maritime transport", value: 1 },
  { label: "2 => Rail transport", value: 2 },
  { label: "3 => Road transport", value: 3 },
  { label: "4 => Air transport", value: 4 },
  { label: "5 => Mail", value: 5 },
  { label: "6 => Multimodal transport", value: 6 },
  { label: "7 => Fixed transport installations", value: 7 },
  { label: "8 => Fixed transport installations", value: 8 },
  { label: "9 => Transport mode not applicable", value: 9 },
];

export const CabinType = [
  { value: "1A", label: "1A => Drum, steel" },
  { value: "1B", label: "1B => Drum, aluminium" },
  { value: "1D", label: "1D => Drum, plywood" },
  { value: "1F", label: "1F => Container, flexible" },
  { value: "1G", label: "1G => Drum, fibre" },
  { value: "1W", label: "1W => Drum, wooden" },
  { value: "2C", label: "2C => Barrel, wooden" },
  { value: "3A", label: "3A => Jerrican, steel" },
  { value: "3H", label: "3H => Jerrican, plastic" },
  { value: "43", label: "43 => Bag, super bulk" },
  { value: "44", label: "44 => Bag, polybag" },
  { value: "4A", label: "4A => Box, steel" },
  { value: "4B", label: "4B => Box, aluminium" },
  { value: "4C", label: "4C => Box, natural wood" },
  { value: "4D", label: "4D => Box, plywood" },
  { value: "4F", label: "4F => Box, reconstituted wood" },
  { value: "4G", label: "4G => Box, fibreboard" },
  { value: "4H", label: "4H => Box, plastic" },
  { value: "5H", label: "5H => Bag, woven plastic" },
  { value: "5L", label: "5L => Bag, textile" },
  { value: "5M", label: "5M => Bag, paper" },
  { value: "6H", label: "6H => Composite packaging, plastic receptacle" },
  { value: "6P", label: "6P => Composite packaging, glass receptacle" },
  { value: "7A", label: "7A => Case, car" },
  { value: "7B", label: "7B => Case, wooden" },
  { value: "8A", label: "8A => Pallet, wooden" },
  { value: "8B", label: "8B => Crate, wooden" },
  { value: "8C", label: "8C => Bundle, wooden" },
  { value: "AA", label: "AA => Intermediate bulk container, rigid plastic" },
  { value: "AB", label: "AB => Receptacle, fibre" },
  { value: "AC", label: "AC => Receptacle, paper" },
  { value: "AD", label: "AD => Receptacle, wooden" },
  { value: "AE", label: "AE => Aerosol" },
  { value: "AF", label: "AF => Pallet, modular, collars 80cms * 60cms" },
  { value: "AG", label: "AG => Pallet, shrinkwrapped" },
  { value: "AH", label: "AH => Pallet, 100cms * 110cms" },
  { value: "AI", label: "AI => Clamshell" },
  { value: "AJ", label: "AJ => Cone" },
  { value: "AL", label: "AL => Ball" },
  { value: "AM", label: "AM => Ampoule, non-protected" },
  { value: "AP", label: "AP => Ampoule, protected" },
  { value: "AT", label: "AT => Atomizer" },
  { value: "AV", label: "AV => Capsule" },
  { value: "B4", label: "B4 => Belt" },
  { value: "BA", label: "BA => Barrel" },
  { value: "BB", label: "BB => Bobbin" },
  { value: "BC", label: "BC => Bottlecrate / bottlerack" },
  { value: "BD", label: "BD => Board" },
  { value: "BE", label: "BE => Bundle" },
  { value: "BF", label: "BF => Balloon, non-protected" },
  { value: "BG", label: "BG => Bag" },
  { value: "BH", label: "BH => Bunch" },
  { value: "BI", label: "BI => Bin" },
  { value: "BJ", label: "BJ => Bucket" },
  { value: "BK", label: "BK => Basket" },
  { value: "BL", label: "BL => Bale, compressed" },
  { value: "BM", label: "BM => Basin" },
  { value: "BN", label: "BN => Bale, non-compressed" },
  { value: "BO", label: "BO => Bottle, non-protected, cylindrical" },
  { value: "BP", label: "BP => Balloon, protected" },
  { value: "BQ", label: "BQ => Bottle, protected cylindrical" },
  { value: "BR", label: "BR => Bar" },
  { value: "BS", label: "BS => Bottle, non-protected, bulbous" },
  { value: "BT", label: "BT => Bolt" },
  { value: "BU", label: "BU => Butt" },
  { value: "BV", label: "BV => Bottle, protected bulbous" },
  { value: "BW", label: "BW => Box, for liquids" },
  { value: "BX", label: "BX => Box" },
  { value: "BY", label: "BY => Board, in bundle/bunch/truss" },
  { value: "BZ", label: "BZ => Bars, in bundle/bunch/truss" },
  { value: "CA", label: "CA => Can, rectangular" },
  { value: "CB", label: "CB => Crate, beer" },
  { value: "CC", label: "CC => Churn" },
  { value: "CD", label: "CD => Can, with handle and spout" },
  { value: "CE", label: "CE => Creel" },
  { value: "CF", label: "CF => Coffer" },
  { value: "CG", label: "CG => Cage" },
  { value: "CH", label: "CH => Chest" },
  { value: "CI", label: "CI => Canister" },
  { value: "CJ", label: "CJ => Coffin" },
  { value: "CK", label: "CK => Cask" },
  { value: "CL", label: "CL => Coil" },
  { value: "CM", label: "CM => Card" },
  {
    value: "CN",
    label: "CN => Container, not otherwise specified as transport equipment",
  },
  { value: "CO", label: "CO => Carboy, non-protected" },
  { value: "CP", label: "CP => Carboy, protected" },
  { value: "CQ", label: "CQ => Cartridge" },
  { value: "CR", label: "CR => Crate" },
  { value: "CS", label: "CS => Case" },
  { value: "CT", label: "CT => Carton" },
  { value: "CU", label: "CU => Cup" },
  { value: "CV", label: "CV => Cover" },
  { value: "CW", label: "CW => Cage, roll" },
  { value: "CX", label: "CX => Can, cylindrical" },
  { value: "CY", label: "CY => Cylinder" },
  { value: "CZ", label: "CZ => Canvas" },
  { value: "DA", label: "DA => Crate, multiple layer, plastic" },
  { value: "DB", label: "DB => Crate, multiple layer, wooden" },
  { value: "DC", label: "DC => Crate, multiple layer, cardboard" },
  {
    value: "DG",
    label: "DG => Cage, Commonwealth Handling Equipment Pool (CHEP)",
  },
  {
    value: "DH",
    label: "DH => Box, Commonwealth Handling Equipment Pool (CHEP), Eurobox",
  },
  { value: "DI", label: "DI => Drum, iron" },
  { value: "DJ", label: "DJ => Demijohn, non-protected" },
  { value: "DK", label: "DK => Crate, bulk, cardboard" },
  { value: "DL", label: "DL => Crate, bulk, plastic" },
  { value: "DM", label: "DM => Crate, bulk, wooden" },
  { value: "DN", label: "DN => Dispenser" },
  { value: "DP", label: "DP => Demijohn, protected" },
  { value: "DR", label: "DR => Drum" },
  { value: "DS", label: "DS => Tray, one layer no cover, plastic" },
  { value: "DT", label: "DT => Tray, one layer no cover, wooden" },
  { value: "DU", label: "DU => Tray, one layer no cover, polystyrene" },
  { value: "DV", label: "DV => Tray, one layer no cover, cardboard" },
  { value: "DW", label: "DW => Tray, two layers no cover, plastic tray" },
  { value: "DX", label: "DX => Tray, two layers no cover, wooden" },
  { value: "DY", label: "DY => Tray, two layers no cover, cardboard" },
  { value: "EC", label: "EC => Bag, plastic" },
  { value: "ED", label: "ED => Case, with pallet base" },
  { value: "EE", label: "EE => Case, with pallet base, wooden" },
  { value: "EF", label: "EF => Case, with pallet base, cardboard" },
  { value: "EG", label: "EG => Case, with pallet base, plastic" },
  { value: "EH", label: "EH => Case, with pallet base, metal" },
  { value: "EI", label: "EI => Case, isothermic" },
  { value: "EN", label: "EN => Envelope" },
  { value: "FB", label: "FB => Flexibag" },
  { value: "FC", label: "FC => Crate, fruit" },
  { value: "FD", label: "FD => Crate, framed" },
  { value: "FE", label: "FE => Flexitank" },
  { value: "FI", label: "FI => Firkin" },
  { value: "FL", label: "FL => Flask" },
  { value: "FO", label: "FO => Footlocker" },
  { value: "FP", label: "FP => Filmpack" },
  { value: "FR", label: "FR => Frame" },
  { value: "FT", label: "FT => Foodtainer" },
  { value: "FW", label: "FW => Cart, flatbed" },
  { value: "FX", label: "FX => Bag, flexible container" },
  { value: "GB", label: "GB => Bottle, gas" },
  { value: "GI", label: "GI => Girder" },
  { value: "GL", label: "GL => Container, gallon" },
  { value: "GR", label: "GR => Receptacle, glass" },
  {
    value: "GU",
    label: "GU => Tray, containing horizontally stacked flat items",
  },
  { value: "GY", label: "GY => Bag, gunny" },
  { value: "GZ", label: "GZ => Girders, in bundle/bunch/truss" },
  { value: "HA", label: "HA => Basket, with handle, plastic" },
  { value: "HB", label: "HB => Basket, with handle, wooden" },
  { value: "HC", label: "HC => Basket, with handle, cardboard" },
  { value: "HG", label: "HG => Hogshead" },
  { value: "HN", label: "HN => Hanger" },
  { value: "HR", label: "HR => Hamper" },
  { value: "IA", label: "IA => Package, display, wooden" },
  { value: "IB", label: "IB => Package, display, cardboard" },
  { value: "IC", label: "IC => Package, display, plastic" },
  { value: "ID", label: "ID => Package, display, metal" },
  { value: "IE", label: "IE => Package, show" },
  { value: "IF", label: "IF => Package, flow" },
  { value: "IG", label: "IG => Package, paper wrapped" },
  { value: "IH", label: "IH => Drum, plastic" },
  { value: "IK", label: "IK => Package, cardboard, with bottle grip-holes" },
  {
    value: "IL",
    label: "IL => Tray, rigid, lidded stackable (CEN TS 14482:2002)",
  },
  { value: "IN", label: "IN => Ingot" },
  { value: "IZ", label: "IZ => Ingots, in bundle/bunch/truss" },
  { value: "JB", label: "JB => Bag, jumbo" },
  { value: "JC", label: "JC => Jerrican, rectangular" },
  { value: "JG", label: "JG => Jug" },
  { value: "JR", label: "JR => Jar" },
  { value: "JT", label: "JT => Jutebag" },
  { value: "JY", label: "JY => Jerrican, cylindrical" },
  { value: "KG", label: "KG => Keg" },
  { value: "KI", label: "KI => Kit" },
  { value: "LE", label: "LE => Luggage" },
  { value: "LG", label: "LG => Log" },
  { value: "LT", label: "LT => Lot" },
  { value: "LU", label: "LU => Lug" },
  { value: "LV", label: "LV => Liftvan" },
  { value: "LZ", label: "LZ => Logs, in bundle/bunch/truss" },
  { value: "MA", label: "MA => Crate, metal" },
  { value: "MB", label: "MB => Bag, multiply" },
  { value: "MC", label: "MC => Crate, milk" },
  { value: "ME", label: "ME => Container, metal" },
  { value: "MR", label: "MR => Receptacle, metal" },
  { value: "MS", label: "MS => Sack, multi-wall" },
  { value: "MT", label: "MT => Mat" },
  { value: "MW", label: "MW => Receptacle, plastic wrapped" },
  { value: "MX", label: "MX => Matchbox" },
  { value: "NA", label: "NA => Not available" },
  { value: "NE", label: "NE => Unpacked or unpackaged" },
  { value: "NF", label: "NF => Unpacked or unpackaged, single unit" },
  { value: "NG", label: "NG => Unpacked or unpackaged, multiple units" },
  { value: "NS", label: "NS => Nest" },
  { value: "NT", label: "NT => Net" },
  { value: "NU", label: "NU => Net, tube, plastic" },
  { value: "NV", label: "NV => Net, tube, textile" },
  { value: "OA", label: "OA => Pallet, CHEP 40 cm x 60 cm" },
  { value: "OB", label: "OB => Pallet, CHEP 80 cm x 120 cm" },
  { value: "OC", label: "OC => Pallet, CHEP 100 cm x 120 cm" },
  { value: "OD", label: "OD => Pallet, AS 4068-1993" },
  { value: "OE", label: "OE => Pallet, ISO T11" },
  { value: "OF", label: "OF => Platform, unspecified weight or dimension" },
  { value: "OK", label: "OK => Block" },
  { value: "OT", label: "OT => Octabin" },
  { value: "OU", label: "OU => Container, outer" },
  { value: "P2", label: "P2 => Pan" },
  { value: "PA", label: "PA => Packet" },
  {
    value: "PB",
    label: "PB => Pallet, box Combined open-ended box and pallet",
  },
  { value: "PC", label: "PC => Parcel" },
  { value: "PD", label: "PD => Pallet, modular, collars 80cms * 100cms" },
  { value: "PE", label: "PE => Pallet, modular, collars 80cms * 120cms" },
  { value: "PF", label: "PF => Pen" },
  { value: "PG", label: "PG => Plate" },
  { value: "PH", label: "PH => Pitcher" },
  { value: "PI", label: "PI => Pipe" },
  { value: "PJ", label: "PJ => Punnet" },
  { value: "PK", label: "PK => Package" },
  { value: "PL", label: "PL => Pail" },
  { value: "PN", label: "PN => Plank" },
  { value: "PO", label: "PO => Pouch" },
  { value: "PP", label: "PP => Piece" },
  { value: "PR", label: "PR => Receptacle, plastic" },
  { value: "PT", label: "PT => Pot" },
  { value: "PU", label: "PU => Tray" },
  { value: "PV", label: "PV => Pipes, in bundle/bunch/truss" },
  { value: "PX", label: "PX => Pallet" },
  { value: "PY", label: "PY => Plates, in bundle/bunch/truss" },
  { value: "PZ", label: "PZ => Planks, in bundle/bunch/truss" },
  { value: "QA", label: "QA => Drum, steel, non-removable head" },
  { value: "QB", label: "QB => Drum, steel, removable head" },
  { value: "QC", label: "QC => Drum, aluminium, non-removable head" },
  { value: "QD", label: "QD => Drum, aluminium, removable head" },
  { value: "QF", label: "QF => Drum, plastic, non-removable head" },
  { value: "QG", label: "QG => Drum, plastic, removable head" },
  { value: "QH", label: "QH => Barrel, wooden, bung type" },
  { value: "QJ", label: "QJ => Barrel, wooden, removable head" },
  { value: "QK", label: "QK => Jerrican, steel, non-removable head" },
  { value: "QL", label: "QL => Jerrican, steel, removable head" },
  { value: "QM", label: "QM => Jerrican, plastic, non-removable head" },
  { value: "QN", label: "QN => Jerrican, plastic, removable head" },
  { value: "QP", label: "QP => Box, wooden, natural wood, ordinary" },
  {
    value: "QQ",
    label: "QQ => Box, wooden, natural wood, with sift proof walls",
  },
  { value: "QR", label: "QR => Box, plastic, expanded" },
  { value: "QS", label: "QS => Box, plastic, solid" },
  { value: "RD", label: "RD => Rod" },
  { value: "RG", label: "RG => Ring" },
  { value: "RJ", label: "RJ => Rack, clothing hanger" },
  { value: "RK", label: "RK => Rack" },
  { value: "RL", label: "RL => Reel" },
  { value: "RO", label: "RO => Roll" },
  { value: "RT", label: "RT => Rednet" },
  { value: "RZ", label: "RZ => Rods, in bundle/bunch/truss" },
  { value: "SA", label: "SA => Sack" },
  { value: "SB", label: "SB => Slab" },
  { value: "SC", label: "SC => Crate, shallow" },
  { value: "SD", label: "SD => Spindle" },
  { value: "SE", label: "SE => Sea-chest" },
  { value: "SH", label: "SH => Sachet" },
  { value: "SI", label: "SI => Skid" },
  { value: "SK", label: "SK => Case, skeleton" },
  { value: "SL", label: "SL => Slipsheet" },
  { value: "SM", label: "SM => Sheetmetal" },
  { value: "SO", label: "SO => Spool" },
  { value: "SP", label: "SP => Sheet, plastic wrapping" },
  { value: "SS", label: "SS => Case, steel" },
  { value: "ST", label: "ST => Sheet" },
  { value: "SU", label: "SU => Suitcase" },
  { value: "SV", label: "SV => Envelope, steel" },
  { value: "SW", label: "SW => Shrinkwrapped" },
  { value: "SX", label: "SX => Set" },
  { value: "SY", label: "SY => Sleeve" },
  { value: "SZ", label: "SZ => Sheets, in bundle/bunch/truss" },
  { value: "T1", label: "T1 => Tablet" },
  { value: "TB", label: "TB => Tub" },
  { value: "TC", label: "TC => Tea-chest" },
  { value: "TD", label: "TD => Tube, collapsible" },
  { value: "TE", label: "TE => Tyre" },
  { value: "TG", label: "TG => Tank container, generic" },
  { value: "TI", label: "TI => Tierce" },
  { value: "TK", label: "TK => Tank, rectangular" },
  { value: "TL", label: "TL => Tub, with lid" },
  { value: "TN", label: "TN => Tin" },
  { value: "TO", label: "TO => Tun" },
  { value: "TR", label: "TR => Trunk" },
  { value: "TS", label: "TS => Truss" },
  { value: "TT", label: "TT => Bag, tote" },
  { value: "TU", label: "TU => Tube" },
  { value: "TV", label: "TV => Tube, with nozzle" },
  { value: "TW", label: "TW => Pallet, triwall" },
  { value: "TY", label: "TY => Tank, cylindrical" },
  { value: "TZ", label: "TZ => Tubes, in bundle/bunch/truss" },
  { value: "UC", label: "UC => Uncaged" },
  { value: "UN", label: "UN => Unit" },
  { value: "VA", label: "VA => Vat" },
  { value: "VG", label: "VG => Bulk, gas (at 1031 mbar and 15°C)" },
  { value: "VI", label: "VI => Vial" },
  { value: "VK", label: "VK => Vanpack" },
  { value: "VL", label: "VL => Bulk, liquid" },
  { value: "VO", label: "VO => Bulk, solid, large particles (“nodules”)" },
  { value: "VP", label: "VP => Vacuum-packed" },
  {
    value: "VQ",
    label: "VQ => Bulk, liquefied gas (at abnormal temperature/pressure)",
  },
  { value: "VN", label: "VN => Vehicle" },
  { value: "VR", label: "VR => Bulk, solid, granular particles (“grains”)" },
  { value: "VS", label: "VS => Bulk, scrap metal" },
  { value: "VY", label: "VY => Bulk, solid, fine particles (“powders”)" },
  { value: "WA", label: "WA => Intermediate bulk container" },
  { value: "WB", label: "WB => Wickerbottle" },
  { value: "WC", label: "WC => Intermediate bulk container, steel" },
  { value: "WD", label: "WD => Intermediate bulk container, aluminium" },
  { value: "WF", label: "WF => Intermediate bulk container, metal" },
  {
    value: "WG",
    label: "WG => Intermediate bulk container, steel, pressurised &gt; 10 kpa",
  },
  {
    value: "WH",
    label:
      "WH => Intermediate bulk container, aluminium, pressurised &gt; 10 kpa",
  },
  {
    value: "WJ",
    label: "WJ => Intermediate bulk container, metal, pressure 10 kpa",
  },
  { value: "WK", label: "WK => Intermediate bulk container, steel, liquid" },
  {
    value: "WL",
    label: "WL => Intermediate bulk container, aluminium, liquid",
  },
  { value: "WM", label: "WM => Intermediate bulk container, metal, liquid" },
  {
    value: "WN",
    label:
      "WN => Intermediate bulk container, woven plastic, without coat/liner",
  },
  {
    value: "WP",
    label: "WP => Intermediate bulk container, woven plastic, coated",
  },
  {
    value: "WQ",
    label: "WQ => Intermediate bulk container, woven plastic, with liner",
  },
  {
    value: "WR",
    label: "WR => Intermediate bulk container, woven plastic, coated and liner",
  },
  { value: "WS", label: "WS => Intermediate bulk container, plastic film" },
  {
    value: "WT",
    label: "WT => Intermediate bulk container, textile with out coat/liner",
  },
  {
    value: "WU",
    label: "WU => Intermediate bulk container, natural wood, with inner liner",
  },
  { value: "WV", label: "WV => Intermediate bulk container, textile, coated" },
  {
    value: "WW",
    label: "WW => Intermediate bulk container, textile, with liner",
  },
  {
    value: "WX",
    label: "WX => Intermediate bulk container, textile, coated and liner",
  },
  {
    value: "WY",
    label: "WY => Intermediate bulk container, plywood, with inner liner",
  },
  {
    value: "WZ",
    label:
      "WZ => Intermediate bulk container, reconstituted wood, with inner liner",
  },
  { value: "XA", label: "XA => Bag, woven plastic, without inner coat/liner" },
  { value: "XB", label: "XB => Bag, woven plastic, sift proof" },
  { value: "XC", label: "XC => Bag, woven plastic, water resistant" },
  { value: "XD", label: "XD => Bag, plastics film" },
  { value: "XF", label: "XF => Bag, textile, without inner coat/liner" },
  { value: "XG", label: "XG => Bag, textile, sift proof" },
  { value: "XH", label: "XH => Bag, textile, water resistant" },
  { value: "XJ", label: "XJ => Bag, paper, multi-wall" },
  { value: "XK", label: "XK => Bag, paper, multi-wall, water resistant" },
  {
    value: "YA",
    label: "YA => Composite packaging, plastic receptacle in steel drum",
  },
  {
    value: "YB",
    label: "YB => Composite packaging, plastic receptacle in steel crate box",
  },
  {
    value: "YC",
    label: "YC => Composite packaging, plastic receptacle in aluminium drum",
  },
  {
    value: "YD",
    label: "YD => Composite packaging, plastic receptacle in aluminium crate",
  },
  {
    value: "YF",
    label: "YF => Composite packaging, plastic receptacle in wooden box",
  },
  {
    value: "YG",
    label: "YG => Composite packaging, plastic receptacle in plywood drum",
  },
  {
    value: "YH",
    label: "YH => Composite packaging, plastic receptacle in plywood box",
  },
  {
    value: "YJ",
    label: "YJ => Composite packaging, plastic receptacle in fibre drum",
  },
  {
    value: "YK",
    label: "YK => Composite packaging, plastic receptacle in fibreboard box",
  },
  {
    value: "YL",
    label: "YL => Composite packaging, plastic receptacle in plastic drum",
  },
  {
    value: "YM",
    label: "YM => Composite packaging, plastic receptacle in solid plastic box",
  },
  {
    value: "YN",
    label: "YN => Composite packaging, glass receptacle in steel drum",
  },
  {
    value: "YP",
    label: "YP => Composite packaging, glass receptacle in steel crate box",
  },
  {
    value: "YQ",
    label: "YQ => Composite packaging, glass receptacle in aluminium drum",
  },
  {
    value: "YR",
    label: "YR => Composite packaging, glass receptacle in aluminium crate",
  },
  {
    value: "YS",
    label: "YS => Composite packaging, glass receptacle in wooden box",
  },
  {
    value: "YT",
    label: "YT => Composite packaging, glass receptacle in plywood drum",
  },
  {
    value: "YV",
    label: "YV => Composite packaging, glass receptacle in wickerwork hamper",
  },
  {
    value: "YW",
    label: "YW => Composite packaging, glass receptacle in fibre drum",
  },
  {
    value: "YX",
    label: "YX => Composite packaging, glass receptacle in fibreboard box",
  },
  {
    value: "YY",
    label:
      "YY => Composite packaging, glass receptacle in expandable plastic pack",
  },
  {
    value: "YZ",
    label: "YZ => Composite packaging, glass receptacle in solid plastic pack",
  },
  {
    value: "ZA",
    label: "ZA => Intermediate bulk container, paper, multi-wall",
  },
  { value: "ZB", label: "ZB => Bag, large" },
  {
    value: "ZC",
    label:
      "ZC => Intermediate bulk container, paper, multi-wall, water resistant",
  },
  {
    value: "ZD",
    label:
      "ZD => Intermediate bulk container, rigid plastic, with structural equipment, solids",
  },
  {
    value: "ZF",
    label:
      "ZF => Intermediate bulk container, rigid plastic, freestanding, solids",
  },
  {
    value: "ZG",
    label:
      "ZG => Intermediate bulk container, rigid plastic, with structural equipment, pressurised",
  },
  {
    value: "ZH",
    label:
      "ZH => Intermediate bulk container, rigid plastic, freestanding, pressurised",
  },
  {
    value: "ZJ",
    label:
      "ZJ => Intermediate bulk container, rigid plastic, with structural equipment, liquids",
  },
  {
    value: "ZK",
    label:
      "ZK => Intermediate bulk container, rigid plastic, freestanding, liquids",
  },
  {
    value: "ZL",
    label:
      "ZL => Intermediate bulk container, composite, rigid plastic, solids",
  },
  {
    value: "ZM",
    label:
      "ZM => Intermediate bulk container, composite, flexible plastic, solids",
  },
  {
    value: "ZN",
    label:
      "ZN => Intermediate bulk container, composite, rigid plastic, pressurised",
  },
  {
    value: "ZP",
    label:
      "ZP => Intermediate bulk container, composite, flexible plastic, pressurised",
  },
  {
    value: "ZQ",
    label:
      "ZQ => Intermediate bulk container, composite, rigid plastic, liquids",
  },
  {
    value: "ZR",
    label:
      "ZR => Intermediate bulk container, composite, flexible plastic, liquids",
  },
  { value: "ZS", label: "ZS => Intermediate bulk container, composite" },
  { value: "ZT", label: "ZT => Intermediate bulk container, fibreboard" },
  { value: "ZU", label: "ZU => Intermediate bulk container, flexible" },
  {
    value: "ZV",
    label: "ZV => Intermediate bulk container, metal, other than steel",
  },
  { value: "ZW", label: "ZW => Intermediate bulk container, natural wood" },
  { value: "ZX", label: "ZX => Intermediate bulk container, plywood" },
  {
    value: "ZY",
    label: "ZY => Intermediate bulk container, reconstituted wood",
  },
  { value: "ZZ", label: "ZZ => Mutually defined" },
];

export const BillScenario = [
  { label: "Ticari", value: 1 },
  { label: "Temel", value: 2 },
  { label: "İhracat", value: 3 },
  { label: "Kamu", value: 4 },
];

export const cutList = [
  { label: "Plazma Kesim", value: 1 },
  { label: "Lazer Kesim", value: 2 },
  { label: "Plazma Kesim İşçiliği", value: 3 },
  { label: "Lazer Kesim İşçiliği", value: 4 },
  { label: "Oksijen Kesim", value: 5 },
  { label: "Oksijen Kesim İşçiliği", value: 6 },
  { label: "Makas Kesim", value: 7 },
  { label: "Makas Kesim İşçiliği", value: 8 },
  { label: "Makas Kesim & Büküm İşçiliği", value: 9 },
  { label: "Abkant Büküm", value: 10 },
  { label: "Abkant Büküm İşçiliği", value: 11 },
  { label: "Plazma Kesim & Büküm", value: 12 },
  { label: "Plazma Kesim & Büküm İşçiliği", value: 13 },
  { label: "Makas Kesim & Büküm", value: 14 },
  { label: "Lazer Kesim & Büküm", value: 15 },
  { label: "Lazer Kesim & Büküm İşçiliği", value: 16 },
];

export const cutTypeList = [
  { label: "Baklavalı", value: 1 },
  { label: "St37", value: 2 },
  { label: "HRP", value: 3 },
  { label: "DKP", value: 4 },
  { label: "St52", value: 5 },
  { label: "Paslanmaz", value: 6 },
];

export const iTypeStatusesOptions = (value) => {
  switch (value) {
    case 1:
      return "Bakım";
    case 2:
      return "Tamir/Tadilat";
    case 3:
      return "Operasyon";
    case 4:
      return "Dış Servis";
    default:
      break;
  }
};

export const optionOfferStatuses = [
  { label: "Ön Görüşme", value: 9 },
  { label: "Fiyatlandırılacak", value: 8 },
  { label: "Haber Verilecek", value: 7 },
  { label: "Beklemede", value: 1 },
  { label: "Revize", value: 4 },
  { label: "Onaylandı", value: 2 },
  { label: "Fatura Bekleme", value: 11 },
  { label: "Fatura Kesildi", value: 5 },
  { label: "Cari Bekleme", value: 12 },
  { label: "Cari İşlendi", value: 13 },
  { label: "Arşiv", value: 6 },
  { label: "İptal Edildi", value: 3 },
  { label: "Bilgilendirme", value: 10 },
];

export const optionOfferStatusesElkapol = [
  { label: "Beklemede", value: 1 },
  { label: "Onaylandı", value: 2 },
  { label: "İptal Edildi", value: 3 },
  { label: "Revize", value: 4 },
  { label: "Sevk Edilenler", value: 6 },
  // { label: "Haber Verilecek", value: 7 },
];

export const optionWorkInstructionStatuses = [
  { label: "Beklemede", value: 1 },
  { label: "İşlemde", value: 2 },
  { label: "İptal Edildi", value: 3 },
  { label: "Revize", value: 4 },
  { label: "Tamamlandı", value: 5 },
];

export const cuttingStatuses = [
  { label: "Beklemede", value: 1 },
  { label: "Onaylandı", value: 2 },
  { label: "Sonlandırıldı", value: 3 },
  { label: "Özel Durum", value: 4 },
];

export const optionOrderStatuses = [
  { label: "Beklemede", value: 1 },
  { label: "Onaylandı", value: 2 },
  { label: "İptal Edildi", value: 3 },
  { label: "Alış", value: 4 },
];

export const optionOrderStatusesAlkom = [
  { label: "Beklemede", value: 1 },
  { label: "Teklif İstendi", value: 5 },
  { label: "Onay Aşamasında", value: 6 },
  { label: "Onaylandı", value: 2 },
  { label: "İptal Edildi", value: 3 },
  { label: "Alış", value: 4 },
  { label: "Arşiv", value: 7 },
];

export const optionServiceStatuses = [
  { label: "Beklemede", value: 1 },
  { label: "Onaylandı", value: 2 },
  { label: "Parça Bekleniyor", value: 3 },
  { label: "Haber Verilecek", value: 4 },
  { label: "Fiyat Verilecek", value: 5 },
  { label: "Atölye", value: 6 },
  { label: "Yeni Servis", value: 7 },
  { label: "Tamamlandı", value: 8 },
  { label: "Arşiv", value: 9 },
  { label: "İptal Edildi", value: 10 },
];

export const unitValuesStatuses = [
  { label: "Adet", value: "NIU" },
  { label: "KG", value: "KGM" },
  { label: "GR", value: "GRM" },
  { label: "Metre", value: "MTR" },
  { label: "Litre", value: "LTR" },
  { label: "Paket", value: "PA" },
  { label: "Kutu", value: "BX" },
  { label: "CM", value: "CMT" },
  { label: "M3", value: "MTQ" },
  { label: "M2", value: "MTK" },
  { label: "Rulo", value: "ROLL" },
  { label: "Set", value: "SET" },
  { label: "CM3", value: "CMQ" },
  { label: "Saat", value: "SAA" },
];

export const SendUnitValuesStatuses = (value) => {
  switch (value) {
    case "NIU":
      return "Adet";
    case "KGM":
      return "KG";
    case "GRM":
      return "GR";
    case "MTR":
      return "Metre";
    case "LTR":
      return "Litre";
    case "PA":
      return "Paket";
    case "BX":
      return "Kutu";
    case "CMT":
      return "CM";
    case "MTQ":
      return "M3";
    case "MTK":
      return "M2";
    case "ROLL":
      return "Rulo";
    case "SET":
      return "Set";
    case "CMQ":
      return "CM3";
    case "SAA":
      return "Saat";
    default:
      break;
  }
};

export const optionOfferStatusesOptions = (value) => {
  switch (value) {
    case 1:
      return "Beklemede";
    case 2:
      return "Onaylandı";
    case 3:
      return "İptal Edildi";
    case 4:
      return "Revize";
    case 5:
      return "Satış";
    case 6:
      return "Arşiv";
    case 7:
      return "Haber Verilecek";
    default:
      break;
  }
};

export const optionOrderStatusesOptions = (value) => {
  switch (value) {
    case 1:
      return "Beklemede";
    case 2:
      return "Onaylandı";
    case 3:
      return "İptal Edildi";
    case 4:
      return "Alış";
    default:
      break;
  }
};

export const PackageOptionsStatuses = [
  { label: "Admin", value: "1" },
  { label: "Kullanıcı Yönetimi", value: "2" },
  { label: "Depo Yönetimi", value: "3" },
  { label: "Standart", value: "4" },
  { label: "Fatura Yönetimi", value: "5" },
  { label: "Kişi Yönetimi", value: "6" },
  { label: "Gelir Gider Yönetimi", value: "7" },
  { label: "Servis Yönetimi", value: "8" },
  { label: "Teklif Yönetimi", value: "9" },
  { label: "Stok Yönetimi", value: "10" },
  { label: "Makina Servis Yönetimi", value: "11" },
  { label: "Oy Kullanma Modülü", value: "12" },
  { label: "Sigorta Yönetimi", value: "13" },
  { label: "İş Takip Yönetimi", value: "14" },
  { label: "Sipariş Yönetimi", value: "15" },
  { label: "Muhasebe Yönetimi", value: "16" },
  { label: "Metal Kesim Yönetimi", value: "17" },
  { label: "Yeni Hesaplama Yönetimi", value: "18" },
  { label: "CS Savunma Yönetimi", value: "19" },
  { label: "Yeni Gelir Gider Yönetimi", value: "20" },
  { label: "Yeni Teklif Yönetimi", value: "21" },
  { label: "Yeni Sipariş Yönetimi", value: "22" },
  { label: "Yeni Alış Satış Yönetimi", value: "23" },
  { label: "Belge Yönetimi", value: "24" },
];

export const UserCount = [
  { label: "Sınırsız Kullanıcı", value: "0" },
  { label: "100 Kullanıcı", value: "1" },
  { label: "50 Kullanıcı", value: "2" },
  { label: "25 Kullanıcı", value: "3" },
  { label: "10 Kullanıcı", value: "4" },
  { label: "5 Kullanıcı", value: "5" },
];

// export const PackageOptions = (value) => {
//   switch (value) {
//     case "1":
//       return "Teklif(5 Kullanıcılı)";
//     case "2":
//       return "Teklif(10 Kullanıcılı)";
//     case "3":
//       return "Teklif(50 Kullanıcılı)";
//     case "4":
//       return "Alış(5 Kullanıcılı)";
//     case "5":
//       return "Alış(10 Kullanıcılı)";
//     case "6":
//         return "Alış(50 Kullanıcılı)";
//     default:
//       break;
//   }
// };

export const optionServiceStatusesOptions = (value) => {
  switch (value) {
    case 1:
      return "Beklemede";
    case 2:
      return "Onaylandı";
    case 3:
      return "Parça Bekleniyor";
    case 4:
      return "Haber Verilecek";
    case 5:
      return "Fiyat Verilecek";
    case 6:
      return "Atölye";
    case 7:
      return "Yeni Servis";
    case 8:
      return "Tamamlandı";
    case 9:
      return "Arşiv";
    case 10:
      return "İptal Edildi";
    default:
      break;
  }
};

export const RemindType = [
  { label: "Genel", value: 1 },
  { label: "Sadece Ben", value: 2 },
  { label: "Kullanıcı", value: 3 },
];

export const ReplyType = [
  { label: "Kabul Et", value: 1 },
  { label: "Reddet", value: 2 },
];

export const RemindTypeOptions = (value) => {
  switch (value) {
    case 1:
      return "Genel";
    case 2:
      return "Sadece Ben";
    case 3:
      return "Kullanıcı";
    default:
      break;
  }
};

export const DeliveryMethodType = [
  { label: "Adrese Teslim", value: 2 },
  { label: "Fabrikadan Teslim", value: 3 },
  { label: "Diğer", value: 1 },
];

export const DeliveryMethodTypeOptions = (value) => {
  switch (value) {
    case 1:
      return "Diğer";
    case 2:
      return "Adrese Teslim";
    case 3:
      return "Fabrikadan Teslim";
    default:
      break;
  }
};

export const DeliveryTime = [
  { label: "Stoktan", value: 2 },
  { label: "1 Hafta", value: 3 },
  { label: "2-3 Hafta", value: 4 },
  { label: "4-5 Hafta", value: 5 },
  { label: "6-8 Hafta", value: 6 },
  { label: "8-10 Hafta", value: 7 },
  { label: "9-12 Hafta", value: 8 },
  { label: "Diğer", value: 1 },
];

export const DeliveryTimeOptions = (value) => {
  switch (value) {
    case 1:
      return "Diğer";
    case 2:
      return "Stoktan";
    case 3:
      return "1 Hafta";
    case 4:
      return "2-3 Hafta";
    case 5:
      return "4-5 Hafta";
    case 6:
      return "6-8 Hafta";
    case 7:
      return "8-10 Hafta";
    case 8:
      return "9-12 Hafta";
    default:
      break;
  }
};

export const Guaranty = [
  { label: "6 Ay", value: 2 },
  { label: "1 Yıl", value: 3 },
  { label: "2 Yıl", value: 4 },
  { label: "2+2 Yıl", value: 5 },
  { label: "2+3 Yıl", value: 6 },
  { label: "Diğer", value: 1 },
];

export const GuarantyOptions = (value) => {
  switch (value) {
    case 1:
      return "Diğer";
    case 2:
      return "6 Ay";
    case 3:
      return "1 Yıl";
    case 4:
      return "2 Yıl";
    case 5:
      return "2+2 Yıl";
    case 6:
      return "2+3 Yıl";
    default:
      break;
  }
};

export const TaxType = [
  { label: "K.D.V Dahil", value: 1 },
  //{ label: "K.D.V Dahil(Hesapla)", value: 1 },
  //{ label: "K.D.V Dahil", value: 2 },
  { label: "K.D.V Hariç", value: 3 },
];

export const TaxTypeOptions = (value) => {
  switch (value) {
    case 1:
      return "K.D.V. Dahil (Hesapla)";
    case 2:
      return "K.D.V. Dahil";
    case 3:
      return "K.D.V. Hariç";
    default:
      break;
  }
};

export const TaxRateChoices = [
  // 0'ı null olarak gördüğü için değerleri string verdim
  { label: "%0", value: "0" },
  { label: "%1", value: "1" },
  { label: "%2", value: "2" },
  { label: "%8", value: "8" },
  { label: "%10", value: "10" },
  { label: "%18", value: "18" },
  { label: "%20", value: "20" },
];

export const IsRisk = [
  { label: "Var", value: true },
  { label: "Yok", value: false },
];

export const StockStatus = [
  { label: "Stoklu Ürün", value: 1 },
  { label: "Stoksuz Ürün", value: 0 },
];

export const StockStatus2 = [
  { label: "Stoklu Ürün (Zorunlu)", value: 2 },
  { label: "Stoklu Ürün", value: 1 },
  { label: "Stoksuz Ürün", value: 0 },
];

export const Gender = [
  { label: "Erkek", value: true },
  { label: "Kadın", value: false },
];

export const Mounth = [
  { label: "Ocak", value: 1 },
  { label: "Şubat", value: 2 },
  { label: "Mart", value: 3 },
  { label: "Nisan", value: 4 },
  { label: "Mayıs", value: 5 },
  { label: "Haziran", value: 6 },
  { label: "Temmuz", value: 7 },
  { label: "Ağustos", value: 8 },
  { label: "Eylül", value: 9 },
  { label: "Ekim", value: 10 },
  { label: "Kasım", value: 11 },
  { label: "Aralık", value: 12 },
];

export const Year = [];
var i;
for (i = 2010; i < 2051; i++) {
  Year.push({
    label: i,
    value: i,
  });
}

export const Day = [];
var j;
for (j = 1; j < 31; j++) {
  Day.push({
    label: j,
    value: j,
  });
}

export const GenderOptions = (value) => {
  switch (value) {
    case false:
      return "Kadın";
    case true:
      return "Erkek";
    default:
      return "-";
  }
};

export const PaymentMethodType = [
  { label: "Çek", value: 1 },
  { label: "Havale/EFT", value: 2 },
  { label: "Kredi Kartı", value: 3 },
  { label: "Nakit", value: 4 },
  { label: "Senet", value: 5 },
];

export const PaymentMethodTypeArr = [
  { label: "Çek", value: "1" },
  { label: "Havale/EFT", value: "2" },
  { label: "Kredi Kartı", value: "3" },
  { label: "Nakit", value: "4" },
  { label: "Senet", value: "5" },
];

export const CollectionMethodType = [
  { label: "Çek", value: 1 },
  { label: "Havale/EFT", value: 2 },
  { label: "Kredi Kartı", value: 3 },
  { label: "Nakit", value: 4 },
  { label: "Senet", value: 5 },
];

export const PaymentTypeOptions = (value) => {
  switch (value) {
    case 1:
      return "Çek";
    case 2:
      return "Havale/EFT";
    case 3:
      return "Kredi Kartı";
    case 4:
      return "Nakit";
    case 5:
      return "Senet";
    default:
      break;
  }
};

export const CurrencyType = [
  { label: "Türk Lirası (₺)", value: 1 },
  { label: "Dolar ($)", value: 2 },
  { label: "Euro (€)", value: 3 },
  { label: "İsviçre Frangı (SFr)", value: 4 },
];

export const TransactionStatus = [
  { label: "Devam Ediyor", value: 1 },
  { label: "Tamamlandı", value: 2 },
];

export const CurrencyTypeOptions = (value) => {
  switch (value) {
    case 1:
      return " ₺";
    case 2:
      return " $";
    case 3:
      return " €";
    default:
      return " ";
  }
};

export const WageStatus = [
  { label: "Ücretli", value: 1 },
  { label: "Ücretsiz", value: 2 },
];

export const HazirunStatus = [
  { label: "Hazirunda", value: true },
  { label: "Hazirunda Değil", value: false },
];

export const VoteStatus = [
  { label: "Oy Kullandı", value: true },
  { label: "Oy Kullanmadı", value: false },
];

export const MachineStatus = [
  { label: "Aktif", value: 1 },
  { label: "Pasif", value: 2 },
];

export const fotoStyle = {
  width: "100px",
  height: "100px",
  padding: "2px",
  boxShadow: "0px 0px 5px #999",
};

export const fotoStyle2 = {
  width: "95px",
  height: "95px",
  padding: "2px",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 5px #999",
};

export const textAreaStyle = {
  padding: "5px 10px",
  height: "75px",
};

export function loginControl(history) {
  if (!localStorage.getItem("token") && !localStorage.getItem("expiration")) {
    if (history) {
      history.push("/");
    }
  }
}

export function resizeFile(file, max) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      max,
      max,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
}

export function resizeFiles(file, max) {
  return new Promise(() => {
    Resizer.imageFileResizer(file, max, max, "PDF", 10000, 0);
  });
}

export default function parseJwt() {
  var token = localStorage.getItem("token");
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  var myRoles = jsonPayload
    .split(":")[7]
    .replaceAll('"', "")
    .split("[")[1]
    .split("]")[0]
    .split(",");
  return myRoles;
}

export async function whatIsMyIp() {
  const response = await fetch("http://api.ipify.org/?format=json");
  const data = await response.json();
  return data.ip;
}

export const BillsType = [
  { label: "Ticari Fatura", value: "TICARIFATURA" },
  { label: "Temel Fatura", value: "TEMELFATURA" },
];

export const moldingRoomStatus = [
  { label: "Hayır", value: false },
  { label: "Evet", value: true },
];

export const yesNoStatus = [
  { label: "Hayır", value: false },
  { label: "Evet", value: true },
];

export const IsItTrue = [
  { label: "Yanlış", value: false },
  { label: "Doğru", value: true },
];

export const announcementType = [
  { label: "Genel", value: false },
  { label: "Özel", value: true },
];

export function ColorSend(idx) {
  if (idx === 0) {
    return "rgba(255, 99, 132, 0.5)";
  } else if (idx === 1) {
    return "rgba(54, 162, 235, 0.5)";
  } else if (idx === 2) {
    return "rgba(255, 206, 86, 0.5)";
  } else if (idx === 3) {
    return "rgba(75, 192, 192, 0.5)";
  } else if (idx === 4) {
    return "rgba(153, 102, 255, 0.5)";
  } else if (idx === 5) {
    return "rgba(255, 159, 64, 0.5)";
  } else if (idx === 6) {
    return "rgba(255, 99, 132, 0.5)";
  } else if (idx === 7) {
    return "rgba(54, 162, 235, 0.5)";
  } else if (idx === 8) {
    return "rgba(255, 206, 86, 0.5)";
  } else if (idx === 9) {
    return "rgba(75, 192, 192, 0.5)";
  } else if (idx === 10) {
    return "rgba(153, 102, 255, 0.5)";
  } else if (idx === 11) {
    return "rgba(255, 159, 64, 0.5)";
  } else if (idx === 12) {
    return "rgba(255, 99, 132, 0.5)";
  } else if (idx === 13) {
    return "rgba(54, 162, 235, 0.5)";
  } else if (idx === 14) {
    return "rgba(255, 206, 86, 0.5)";
  } else if (idx === 15) {
    return "rgba(75, 192, 192, 0.5)";
  } else if (idx === 16) {
    return "rgba(153, 102, 255, 0.5)";
  } else if (idx === 17) {
    return "rgba(255, 159, 64, 0.5)";
  }
}

export function ColorBorderSend(idx) {
  if (idx === 0) {
    return "rgba(255, 99, 132, 1)";
  } else if (idx === 1) {
    return "rgba(54, 162, 235, 1)";
  } else if (idx === 2) {
    return "rgba(255, 206, 86, 1)";
  } else if (idx === 3) {
    return "rgba(75, 192, 192, 1)";
  } else if (idx === 4) {
    return "rgba(153, 102, 255, 1)";
  } else if (idx === 5) {
    return "rgba(255, 159, 64, 1)";
  } else if (idx === 6) {
    return "rgba(255, 99, 132, 1)";
  } else if (idx === 7) {
    return "rgba(54, 162, 235, 1)";
  } else if (idx === 8) {
    return "rgba(255, 206, 86, 1)";
  } else if (idx === 9) {
    return "rgba(75, 192, 192, 1)";
  } else if (idx === 10) {
    return "rgba(153, 102, 255, 1)";
  } else if (idx === 11) {
    return "rgba(255, 159, 64, 1)";
  } else if (idx === 12) {
    return "rgba(255, 99, 132, 1)";
  } else if (idx === 13) {
    return "rgba(54, 162, 235, 1)";
  } else if (idx === 14) {
    return "rgba(255, 206, 86, 1)";
  } else if (idx === 15) {
    return "rgba(75, 192, 192, 1)";
  } else if (idx === 16) {
    return "rgba(153, 102, 255, 1)";
  } else if (idx === 17) {
    return "rgba(255, 159, 64, 1)";
  }
}
