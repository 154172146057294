import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";

class LaserCuttingDetail extends Component {
  state = {
    pageTitle: "TL/Saat Tanımla",
    //pageTitleAdd: "Marka Ekle",
    pageTitleUpdate: "TL/Saat Tanımla",
    //pageAddLink: "marka-ekle",
    //pageListLink: "marka-listele",
    breadcrumb: [{ text: "TL/Saat", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    guid: "",
    value: null,
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 2) {
      this.setState({
        //guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      //this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/cuttingprocess/getvariable/", config)
        .then((response) => {
          if (response.data.guid) {
            this.setState({
              guid: response.data.guid,
              value: response.data.value,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  // componentDidUpdate() {
  //   let urlSplit = window.location.pathname.split("/");
  //   if (
  //     urlSplit.length === 2 &&
  //     this.state.pageTitle !== this.state.pageTitleAdd
  //   ) {
  //     this.setState({
  //       urlSplit: urlSplit,
  //       pageTitle: this.state.pageTitleAdd,
  //       name: "",
  //       status: true,
  //     });
  //   }
  //   if (this.state.status === undefined) {
  //     this.setState({
  //       status: true,
  //     });
  //   }
  // }

  // getById(guid) {
  //   this.setState({
  //     isLoading: true,
  //     guid: guid,
  //   });
  // }

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    await this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "value" && value === "") {
      await this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };


  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
    });
    if (this.state.value === "" || this.state.value === null) {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.value !== "" || this.state.value !== null) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      if (this.state.guid) {
        var URLParam = "/cuttingprocess/updatevariable";
        var myObj = {
          guid: this.state.guid,
          value: parseInt(this.state.value),
          status: this.state.status
        };
      }

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {

              window.location.reload();
            var modalAddedLaser = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedLaser });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
          />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="number"
                      name="value"
                      label="TL/Saat [*]"
                      value={this.state.value}
                      placeHolder="Lütfen TL/Saat girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
{/* 
                  <Col lg="6">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col> */}
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
{/* 
            {(this.state.urlSplit[1] === "marka-ekle" ||
              this.state.urlSplit[1] === "marka-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )} */}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(LaserCuttingDetail);
