import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class CustomerEducationExamDetail extends Component {
  state = {
    pageTitle: "Sınav Detay",
    pageAddLink: "musteri-egitim-sinav-ekle",
    pageListLink: "musteri-egitim-sinav-listele",
    breadcrumb: [{ text: "Sınavlar", link: "#" }],
    customerEducationExamAnswersDtoList: [],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/customerEducationExamQuestions/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            question: response.data.question,
            customerEducationExamAnswersDtoList:
              response.data.customerEducationExamAnswersDtoList,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  name="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Ad</th>
                    <th className="colon">:</th>
                    <td colSpan="4">{this.state.question}</td>
                  </tr>
                  <tr>
                    <th>Durum</th>
                    <th className="colon">:</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <th className="colon">:</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <th className="colon">:</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              {this.state.customerEducationExamAnswersDtoList !== null &&
                this.state.customerEducationExamAnswersDtoList.length > 0 &&
                this.state.customerEducationExamAnswersDtoList.map(function (
                  item,
                  index
                ) {
                  return (
                    <Table className="table table-responsive table-hover table-product">
                      <tr className="">
                        <th className="text-left pl-3" style={mainRow}>
                          Cevap
                        </th>
                        <th className="text-left pl-3" style={mainRow}>
                          Doğru Mu?
                        </th>
                      </tr>
                      <tr style={subRow}>
                        <td className="text-left pl-3">{item.reply}</td>
                        <td className="text-left pl-3">
                          {item.isItTrue ? "Doğru" : "Yanlış"}
                        </td>
                      </tr>
                    </Table>
                  );
                })}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerEducationExamDetail;
