import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import {
  URL,
  
  UserOperationClaimControl,
  shipmentStatuses} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";


class ShipmentAddUpdate extends Component {
  state = {
    pageTitle: "Sevkiyat Ekle",
    pageTitleAdd: "Sevkiyat Ekle",
    pageTitleUpdate: "Sevkiyat Güncelle",
    pageAddLink: "sevkiyat-ekle",
    pageListLink: "sevkiyat-listele",
    breadcrumb: [{ text: "Sevkiyat", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productArr: [],
    productGuid: "",
    lastControlPersonelGuid: "",
    workInstructionArr: [],
    supplierGuid: "",
    detectionPersonelGuid: "",
    lastControlGuid: "",
    shipmentDate: "",
    isButtonDisable: true,
    workInstructionGuid: "",
    customerGuid: "",
    personelGuid: "",
    productOutGuid: "",
    guid: "",
    status: true,
    locationArr: [],
    locationGuid: "",
    piece: 0,
    description: "",
    shipmentStatus: "",
    waybilllNumber: "",
    isNull: false,
    modalButtonIdx: 0,
    customerArr: [],
    productOutArr: [],
    personelArr: [],
    isTrue: false,
    productError: "",
    alertErrorMessage: "",
    workInstructionError: "",
    manuelControlDateError: "",
    detectionPersonelError: "",
    message: "",
    statusChange: "",
    statusChangeError: "",
    locationError: "",
    shipmentDateError: "",
    pieceError: "",
    shipmentStatusError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    productModalPlus: false,
    materialModalPlus: false,
    supplierModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      productModalPlus: await UserOperationClaimControl(1029),
      materialModalPlus: await UserOperationClaimControl(1133),
      supplierModalPlus: await UserOperationClaimControl(1038),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/workInstructions/getlistsuitabilityform", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.customerName}`,
          };
        });
        this.setState({
          workInstructionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    if (
      urlSplit.length !== 3 &&
      urlSplit[1] !== "sevkiyat-guncelle"
    ) {   
    axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          personelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    if (
      urlSplit.length === 3 &&
      urlSplit[1] === "sevkiyat-guncelle"
    ) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/shipments/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              productGuid: response.data.productGuid,
              workInstructionGuid: response.data.workInstructionGuid,
              customerGuid: response.data.customerGuid,
              productOutGuid: response.data.productOutGuid,
              personelGuid: response.data.personelGuid,
              workInstructionCode: response.data.workInstructionCode,
              shipmentDate: response.data.shipmentDate,
              locationGuid: response.data.locationGuid,
              piece: parseFloat(response.data.piece),
              waybilllNumber: response.data.waybilllNumber,
              shipmentStatus: response.data.shipmentStatus,
              description: response.data.description,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
}



  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    this.setState({
      workInstructionGuid: "",
      shipmentDate: "",
      waybilllNumber: "",
      personelGuid: "",
      locationGuid: "",
      piece: 0,
      description: "",
      shipmentStatus: "",
    });
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleShipmentStatusChange(option) {
    if (option) {
      this.setState({
        shipmentStatus: option.value,
        shipmentStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        shipmentStatus: "",
        shipmentStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }


  async handleWorkInstructionSelectChange(option) {
    if (option) {
      this.setState({
        workInstructionGuid: option.value,
        workInstructionError: "",
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/workInstructions/get/" + option.value, config)
        .then((response) => {
          if (response.data.guid) {
            this.setState({
              productGuid: response.data.productGuid,
              productOutGuid: response.data.productOutGuid,
              customerGuid: response.data.customerGuid,
              personelGuid: response.data.personelGuid,
              workInstructionCode: response.data.code,
              lastControlPersonelGuid: response.data.lastControlPersonelGuid,
              qualityPersonelGuid: response.data.qualityPersonelGuid,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        workInstructionGuid: "",
        productGuid: "",
        customerGuid: "",
        personelGuid: "",
        productOutGuid: "",
        workInstructionCode: "",
        lastControlPersonelGuid: "",
        qualityPersonelGuid: "",
        workInstructionError: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePieceTextBoxChange(e) {
    this.setState({
      piece: parseFloat(e.target.value),
      pieceError: "",
      alertErrorMessage: "",
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleShipmentDateChange(shipmentDate) {
    if (shipmentDate) {
      this.setState({
        shipmentDate: shipmentDate,
        shipmentDateError: "",
      });
    } else {
      this.setState({
        shipmentDate: "",
        shipmentDateError: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePersonelSelectChange(option) {
    if (option) {
      this.setState({
        personelGuid: option.value,
        personelError: "",
      });
    } else {
      this.setState({
        personelGuid: "",
        personelError: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationError: CgnMessage.alertErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      workInstructionErrorError: "",
      manuelControlDateError: "",
      supplierError: "",
    });
    if (this.state.workInstructionGuid === "") {
      this.setState({
        workInstructionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.shipmentDate === "") {
      this.setState({
        shipmentDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.locationGuid === "") {
      this.setState({
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.personelGuid === "") {
      this.setState({
        personelError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.shipmentStatus === "") {
      this.setState({
        shipmentStatusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    if (
      this.state.workInstructionGuid !== "" &&
      this.state.shipmentDate !== "" &&
      this.state.locationGuid !== "" &&
      this.state.personelGuid !== "" &&
      this.state.shipmentDate !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var myObj = {
        workInstructionGuid: this.state.workInstructionGuid,
        locationGuid: this.state.locationGuid,
        personelGuid: this.state.personelGuid,
        shipmentDate: this.state.shipmentDate,
        piece:
          this.state.piece === "" || this.state.piece === null
            ? 0
            : parseFloat(this.state.piece),
        waybilllNumber: this.state.waybilllNumber,
        shipmentStatus: this.state.shipmentStatus,
        description: this.state.description,
      };
      var URLParam = "/shipments/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "sevkiyat-guncelle"
      ) {
        URLParam = "/shipments/update";
        myObj = {
          guid: this.state.guid,
          workInstructionGuid: this.state.workInstructionGuid,
          locationGuid: this.state.locationGuid,
          personelGuid: this.state.personelGuid,
          shipmentDate: this.state.shipmentDate,
          piece:
            this.state.piece === "" || this.state.piece === null
              ? 0
              : parseFloat(this.state.piece),
          waybilllNumber: this.state.waybilllNumber,
          shipmentStatus: this.state.shipmentStatus,
          description: this.state.description,
        };
      }

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.urlSplit[1] === "sevkiyat-ekle") {
              window.location.reload();
            }
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "sevkiyat-guncelle"
            ) {
              window.location.href = "/sevkiyat-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    var self = this;

    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        {(this.state.urlSplit[1] === "sevkiyat-ekle" ||
          this.state.urlSplit[1] === "sevkiyat-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                 
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="workInstructionCode"
                      label="İş Emri Numarası [*]"
                      value={this.state.workInstructionCode}
                      placeHolder="Lütfen iş emri no girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.workInstructionCodeError}
                      readOnly={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      placeholder="Lütfen iş emri seçin..."
                      selectValue={this.state.workInstructionGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionSelectChange(option)
                      }
                      options={this.state.workInstructionArr}
                      error={this.state.workInstructionError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      options={this.state.customerArr}
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      placeholder="Satış seçiniz..."
                      selectValue={this.state.productOutGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      options={this.state.productOutArr}
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="productGuid"
                      label="Ürün [*]"
                      placeholder="Ürün seçiniz..."
                      selectValue={this.state.productGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      options={this.state.productArr}
                      error={this.state.productError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="personelGuid"
                      label="Personel [*]"
                      placeholder="Personel seçiniz..."
                      selectValue={this.state.personelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePersonelSelectChange(option)
                      }
                      options={this.state.personelArr}
                      error={this.state.personelError}
                    />
                  </Col>

                </Row>
                <Row>   
                <Col lg="3">
                    <CgnReactSelect
                      name="locationGuid"
                      label="Depo [*]"
                      placeholder="Depo seçiniz..."
                      selectValue={this.state.locationGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleLocationSelectChange(option)
                      }
                      options={this.state.locationArr}
                      error={this.state.locationError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="shipmentDate"
                      label="Sevkiyat Tarihi [*]"
                      selected={
                        this.state.shipmentDate &&
                        this.state.shipmentDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.shipmentDate.split("/")[2] +
                                "-" +
                                this.state.shipmentDate.split("/")[1] +
                                "-" +
                                this.state.shipmentDate.split("/")[0]
                            )
                          : this.state.shipmentDate
                      }
                      onChange={(shipmentDate) =>
                        this.handleShipmentDateChange(shipmentDate)
                      }
                      error={this.state.shipmentDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="waybillNumber"
                      label="İrsaliye Numarası"
                      value={this.state.waybillNumber}
                      placeHolder="Lütfen irsaliye numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>      
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      step="1"
                      name="piece"
                      label="Sevkiyat Adet"
                      value={this.state.piece}
                      placeHolder="Lütfen sevkiyat adeti girin..."
                      autoComplete="off"
                      onChange={(e) => this.handlePieceTextBoxChange(e)}
                    />
                  </Col>                  
                </Row>

                <Row>
                  <Col lg="12">
                  <CgnReactSelect
                      name="shipmentStatus"
                      label="Sevk Durum [*]"
                      placeholder="Sevk durumu seçiniz..."
                      selectValue={this.state.shipmentStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleShipmentStatusChange(option)
                      }
                      options={shipmentStatuses}
                      error={this.state.shipmentStatusError}
                    />
                  </Col>
                </Row>
                <Row>
                <Col lg="12">
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "sevkiyat-ekle" ||
              this.state.urlSplit[1] === "sevkiyat-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>      
      </>
    );
  }
}

export default withRouter(ShipmentAddUpdate);
