import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  localFilePath,
  GetMonthFirstDate,
  GetMonthLastDate,
  SupplierList,
  CustomerList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class PolicyList extends Component {
  state = {
    pageTitle: "Poliçe Listele",
    pageAddLink: "police-ekle",
    pageUpdateLink: "police-guncelle",
    pagePrintLink: "police-yazdir",
    pageDetailLink: "police-detay",
    pageListLink: "police-listele",
    breadcrumb: [{ text: "Poliçe", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    policyData: [],
    productItemCount: 0,
    customerName: "",
    guid: "",
    createdBy: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    supplierGuid: "00000000-0000-0000-0000-000000000000",
    supplierArr: [],
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // var firstDate = GetMonthFirstDate();
    // var lastDate = GetMonthLastDate();

    // if (localStorage.getItem("firstDatePolicy")) {
    //   firstDate = localStorage.getItem("firstDatePolicy");
    // }
    // if (localStorage.getItem("lastDatePolicy")) {
    //   lastDate = localStorage.getItem("lastDatePolicy");
    // }

    // await this.setState({
    //   firstDate: firstDate,
    //   firstDate2: firstDate,
    //   lastDate: lastDate,
    //   lastDate2: lastDate,
    // });

    // 

    var self = this;

    await SupplierList().then(function (data) {
      self.setState({
        supplierArr: data,
      });
    });

    if (localStorage.getItem("supplierGuidPolicy")) {
      this.setState({
        supplierGuid: localStorage.getItem("supplierGuidPolicy"),
      });
    }

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidPolicy")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidPolicy"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      //var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem("firstDatePolicy", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      //var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate:  "",
        lastDate2: "",
      });
    }
    localStorage.setItem("lastDatePolicy", this.state.lastDate);
  }

  async handleSupplierSelectChange(option) {
    if (option) {
      await this.setState({
        supplierGuid: option.value,
      });
    } else {
      await this.setState({
        supplierGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("supplierGuidPolicy", this.state.supplierGuid);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidPolicy", this.state.customerGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var statusType = 1;
    URLParam =
      "/policies/getlist/" +
      statusType +
      "/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
      "/" +
      this.state.supplierGuid +
      "/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {       
          this.setState({
            policyData: response.data,
            isLoading: false,
          });              
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleServicePrint = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var statusType = 1;
    URLParam =
      "/policies/print/" +
      statusType +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.supplierGuid +
      "/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleCancel = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/policies/cancelStatus";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/policies/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "180px",
  };

  thStyle2 = {
    width: "150px",
  };

  tdStyle = {
    width: "400px",
  };

  render() {

    return (
      <>
        {this.state.detail === false && (
          <div>
           <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
            excelLink={onClick => this.handleServicePrint()}
          />   
          </div>
         
        )}
        <Row className="policyList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="3">
                  <CgnReactSelect
                    name="supplierGuid"
                    label="Tedarikçi"
                    placeholder="Tedarikçi seçiniz..."
                    selectValue={this.state.supplierGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleSupplierSelectChange(option)
                    }
                    options={this.state.supplierArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="4">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.policyData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Poliçe Bilgileri",
                    sortable: false,
                    cell: (row) => {

                      var phone = row.customerPhone,
                        maskPhone = `${phone.substring(0, 1)}  ${
                          "(" + phone.substring(1, 4) + ")"
                        } ${phone.substring(4, 7)} ${phone.substring(
                          7,
                          9
                        )} ${phone.substring(9, 11)}`;

                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                           
                            <tr>
                              <th style={this.thStyle}>Poliçe Tarihi</th>
                              <td style={this.tdStyle}>{row.policyDate}</td>
                              <th style={this.thStyle2}>
                                Sonraki Poliçe Tarihi
                              </th>
                              <td>{row.nextPolicyDate}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Poliçe No</th>
                              <td style={this.tdStyle}>{row.policyNo}</td>
                              <th style={this.thStyle2}>
                                Belge No
                              </th>
                              <td>{row.documentNo}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td style={this.tdStyle}>{row.customerName}</td>
                              <th style={this.thStyle2}>Tedarikçi</th>
                              <td>{row.supplierName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri Doğum Tarihi</th>
                              <td style={this.tdStyle}>{row.customerBirth}</td>
                              <th style={this.thStyle2}>Müşteri TC No</th>
                              <td>{row.customerTaxNo}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri Telefon</th>
                              <td style={this.tdStyle}>{maskPhone ? maskPhone : "-"}</td>
                              <th style={this.thStyle2}>Müşteri Plaka No</th>
                              <td>{row.plateNo}</td>
                            </tr>
                            <tr>
                            <th style={this.thStyle}>Ürün</th>
                              <td style={this.tdStyle}>{row.productName}</td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>{row.createdBy}</td>
                            </tr>                       
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="İptal" />;
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: "Güncelle" ,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: row.status === true ? "İptal Et" : "Aktif Et",
                            className: "dropDownBtn",
                            onClick: () => this.handleCancel(row.guid),
                          },
                          {
                            text: "Sil",
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default PolicyList;
