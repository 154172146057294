import React, { Component } from "react";
import { Row, Col, Card, Form, Button, Modal, ModalHeader } from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  machineProductOptionStatuses,
  fotoStyle,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";
import noImg from "../../images/no-img.png";
import { confirmAlert } from "react-confirm-alert";


class MachineProductOrderAddUpdate extends Component {
  state = {
    pageTitle: "Sipariş Ekle",
    pageTitleAdd: "Sipariş Ekle",
    pageTitleUpdate: "Sipariş Güncelle",
    pageAddLink: "makine-urun-siparis-ekle",
    pageListLink: "makine-urun-siparis-listele",
    breadcrumb: [{ text: "Sipariş", link: "#" }],
    isLoading: false,
    urlSplit: [],
    locationGuid: "",
    locationGuidError: "",
    locationArr: [],
    machineProductCarts: [],
    machineProductCartsError: "",
    status: 1,
    statusError: "",
    alertErrorMessage: "",
    counter: -1,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    isOpen: false,
    image: "",
    name: "",
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    if (localStorage.getItem("locationGuid")) {
      this.setState({
        locationGuid: localStorage.getItem("locationGuid"),
      });
    }

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(URL + "/locations/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/machineProducts/getlistselect/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}`,
            image: item.image,
            name: item.name,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/machineProducts/listtocart", config)
      .then((response) => {
        this.setState({
          machineProductCarts: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (
      urlSplit.length === 3 &&
      urlSplit[1] === "makine-urun-siparis-guncelle"
    ) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      axios
        .get(URL + "/machineproductorder/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            locationGuid: response.data.locationGuid,
            machineProductCarts: response.data.machineProductOrderProducts,
            status: response.data.status,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    await this.setState({
      locationGuid: "",
      locationGuidError: "",
      machineProductCartsError: "",
      status: 1,
      statusError: "",
      alertErrorMessage: "",
    });
  }

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(option) {
    if (option) {
      this.setState({
        status: option.value,
        statusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        status: null,
        statusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      locationGuidError: "",
      machineProductCartsError: "",
      statusError: "",
    });
    if (this.state.locationGuid === "") {
      this.setState({
        locationGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.status === null) {
      this.setState({
        statusError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var thereIs = true;
    if (
      !this.state.machineProductCarts ||
      this.state.machineProductCarts.length === 0
    ) {
      thereIs = false;
      this.setState({
        machineProductCartsError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.machineProductCarts ||
      this.state.machineProductCarts.length > 0
    ) {
      this.state.machineProductCarts.forEach((element) => {
        if (
          element.machineProductGuid === "" ||
          element.piece === "" ||
          element.piece === 0
        ) {
          thereIs = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
      if (thereIs === false) {
        this.setState({
          machineProductCartsError: CgnMessage.textErrorMessage,
        });
      } else {
        this.setState({
          machineProductCartsError: "",
        });
      }
    }

    if (
      this.state.locationGuid !== "" &&
      this.state.status !== null &&
      thereIs
    ) {
      

      var myObj = {
        locationGuid: this.state.locationGuid,
        machineProductCarts: this.state.machineProductCarts,
        status: this.state.status,
      };
      var URLParam = "/machineproductorder/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "makine-urun-siparis-guncelle"
      ) {
        URLParam = "/machineproductorder/update";
        myObj = {
          guid: this.state.guid,
          locationGuid: this.state.locationGuid,
          machineProductOrderProducts: this.state.machineProductCarts,
          status: this.state.status,
        };
      }
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (
                      this.state.guid &&
                      this.state.urlSplit[1] === "makine-urun-siparis-guncelle"
                    ) {
                      window.location.href = "/makine-urun-siparis-listele";
                    }
                    if (this.state.urlSplit[1] === "makine-urun-siparis-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleProductInputValueChange(option, idx) {
    let machineProductCarts = this.state.machineProductCarts;
    if (option) {
      let productArr = this.state.productArr;
      productArr.forEach((element) => {
        if (element.value === option.value) {
          if (element.image) {
            machineProductCarts[idx].machineProductName = element.name;
            machineProductCarts[idx].machineProductImage = element.image;
          } else {
            machineProductCarts[idx].machineProductName = "";
            machineProductCarts[idx].machineProductImage = "";
          }
        }
      });

      machineProductCarts[idx].machineProductGuid = option.value;
      machineProductCarts[idx].machineProductGuidError = "";
      machineProductCarts[idx].piece = 1;
      this.setState({
        machineProductCarts: machineProductCarts,
      });
    } else {
      machineProductCarts[idx].machineProductGuid = null;
      machineProductCarts[idx].machineProductName = "";
      machineProductCarts[idx].machineProductImage = "";
      machineProductCarts[idx].machineProductGuidError =
        CgnMessage.textErrorMessage;
      this.setState({
        machineProductCarts: machineProductCarts,
      });
    }
  }

  async handleProductPieceTextBoxChange(e, idx) {
    this.setState({
      isLoading: true,
    });
    let machineProductCarts = this.state.machineProductCarts;
    let piece = parseFloat(e.target.value);
    if (piece && piece !== 0) {
      this.props.data.cartCount =
        this.props.data.cartCount - machineProductCarts[idx].piece;
      this.props.data.cartCount = this.props.data.cartCount + piece;
      machineProductCarts[idx].piece = piece;
      machineProductCarts[idx].pieceError = "";
      this.setState({
        machineProductCarts: machineProductCarts,
      });
    } else {
      machineProductCarts[idx].piece = piece;
      machineProductCarts[idx].pieceError = CgnMessage.textErrorMessage;
      this.setState({
        machineProductCarts: machineProductCarts,
      });
    }
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(
        URL +
        "/machineproducts/changetocart/" +
        machineProductCarts[idx].machineProductGuid +
        "/" +
        piece,
        config
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleProductRemove(idx, guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machineproducts/removetocart/" + guid, config)
      .then((response) => {
        let machineProductCarts = this.state.machineProductCarts;

        this.props.data.cartCount =
          this.props.data.cartCount - machineProductCarts[idx].piece;

        machineProductCarts.splice(idx, 1);
        this.setState({
          machineProductCarts: machineProductCarts,
          counter: this.state.counter - 1,
        });
        if (idx === 0) {
          this.setState({
            alertErrorMessage: "",
          });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                {localStorage.getItem("locationGuid") ===
                  "00000000-0000-0000-0000-000000000000" && (
                    <>
                      <Row>
                        <Col lg="3">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            isMulti={false}
                            selectValue={this.state.locationGuid}
                            placeholder="Depo seçiniz..."
                            options={this.state.locationArr}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleLocationSelectChange(option)
                            }
                            error={this.state.locationGuidError}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                <Row>
                  <Col lg="12">
                    <h6 className="font-weight-bold mb-3">Sepet Ürünleri</h6>
                  </Col>
                </Row>
                {this.state.machineProductCarts &&
                  this.state.machineProductCarts.length === 0 && (
                    <CgnAlert
                      className="mb-3"
                      color="danger"
                      title="Uyarı"
                      text="Lütfen sepetinize ürün ekleyin."
                    />
                  )}
                {this.state.machineProductCarts.map((item, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="8" className="modalSelectCol">
                      {item.machineProductImage ? (
                        <Button
                          onClick={() =>
                            this.toggle(
                              item.machineProductImage,
                              item.machineProductName
                            )
                          }
                          className="cart_image_button"
                        >
                          <img
                            alt={item.machineProductName}
                            src={`/Images/${item.machineProductImage}`}
                            className="cart_no_image rounded"
                          />
                        </Button>
                      ) : (
                        <img
                          alt={item.machineProductName}
                          src={noImg}
                          className="cart_no_image rounded"
                        />
                      )}
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="machineProductGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={item.machineProductGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={item.machineProductGuidError}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="3">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={item.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={item.pieceError}
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() =>
                          this.handleProductRemove(idx, item.machineProductGuid)
                        }
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}
                {this.state.machineProductCartsError && (
                  <div className="invalid-feedback d-block">
                    {this.state.machineProductCartsError}
                  </div>
                )}
                {/* <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu [*]"
                      isMulti={false}
                      selectValue={this.state.status}
                      placeholder="Durum seçiniz..."
                      options={machineProductOptionStatuses}
                      isClearable={true}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                      error={this.state.statusError}
                    />
                  </Col>
                </Row> */}
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    className="mt-3"
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default withRouter(MachineProductOrderAddUpdate);
