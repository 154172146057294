import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  Button,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  UserOperationClaimControl,
  fotoStyle,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnDatatable from "../toolbox/CgnDatatable";
import noImg from "../../images/no-img.png";
import { confirmAlert } from "react-confirm-alert";

class DefectiveQuantityAddUpdate extends Component {
  state = {
    pageTitle: "Arızalı Ürün Ekle",
    pageTitleAdd: "Arızalı Ürün Ekle",
    pageTitleUpdate: "Arızalı Ürün Güncelle",
    pageAddLink: "arizali-urun-ekle",
    pageListLink: "arizali-urun-listele",
    breadcrumb: [{ text: "Arızalı Ürün", link: "#" }],
    isLoading: false,
    urlSplit: [],
    guid: "",
    productDataError: "",
    productGuid: null,
    status: true,
    alertErrorMessage: "",
    isDeleted: false,
    controlProduct: false,
    counter: -1,
    productData: [],
    productError: "",
    productCountModal: false,
    productPieceError: "",
    multipleProductArr: [],
    productArr: [],
    stockData: [],
    productArr2: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    isOpen: false,
    image: "",
    name: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    this.setState({
      isLoadingStockData: true,
    });
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/machineproducts/getlistselect/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          let machineProductShelfRow = "";
          item.machineProductShelfRowDto.forEach((item) => {
            if (machineProductShelfRow !== "") {
              machineProductShelfRow += ", ";
            }
            machineProductShelfRow += item.shelf + "-" + item.row;
          });

          return {
            value: item.guid,
            label: `${item.code}-${item.name}-${machineProductShelfRow}`,
            code: item.code,
            image: item.image,
            name: item.name,
          };
        });
        this.setState({
          productArr: myArr,
          stockData: response.data,
          isLoadingStockData: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
          isLoadingStockData: false,
        });
      });

    if (urlSplit.length === 3 && urlSplit[1] === "arizali-urun-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/defectiveQuantity/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              locationGuid: response.data.locationGuid,
              productData: response.data.defectiveQuantityProductDto,
              status: response.data.status,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        locationGuid: "",
        transferLocationGuid: "",
        productData: [],
        status: true,
        transfer: "",
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data
            ) {
              this.setState({
                locationGuid: element.guid,
                locationError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleProductAdd(item.value, item.image, item.name);
        }
      });
    });
  };

  handleProductPieceDefectiveTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].pieceDefective = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.machineProductGuid = null;
      item.pieceDefective = "";
      item.productError = CgnMessage.textErrorMessage;
      item.productPieceDurableError = CgnMessage.textErrorMessage;
      item.productPieceDefectiveError = CgnMessage.textErrorMessage;
    });
    this.setState({
      locationGuid: "",
      counter: -1,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async (value, image, name) => {
    let array = this.state.productData;
    await array.push({
      machineProductGuid: value,
      machineProductImage: image,
      machineProductName: name,
      pieceDurable: 0,
      pieceDefective: 0,
      status: true,
      productError: CgnMessage.textErrorMessage,
      productPieceDurableError: CgnMessage.textErrorMessage,
      productPieceDefectiveError: CgnMessage.textErrorMessage,
      alertErrorMessage: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].machineProductGuid) {
      array[this.state.counter].productError = "";
    }
    if (array[this.state.counter].pieceDurable) {
      return array[this.state.counter].productPieceDurableError === "";
    }
    if (array[this.state.counter].pieceDefective) {
      return array[this.state.counter].productPieceDefectiveError === "";
    }
    this.setState({ productData: array });
  };

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      let productArr = this.state.productArr;
      productArr.forEach((element) => {
        if (element.value === option.value) {
          if (element.image) {
            productData[idx].machineProductName = element.name;
            productData[idx].machineProductImage = element.image;
          } else {
            productData[idx].machineProductName = "";
            productData[idx].machineProductImage = "";
          }
        }
      });

      productData[idx].machineProductGuid = option.value;
      productData[idx].machineProductGuidError = "";
      productData[idx].pieceDurable = 0;
      productData[idx].pieceDefective = 0;

      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].machineProductGuid = null;
      productData[idx].machineProductName = "";
      productData[idx].machineProductImage = "";
      productData[idx].machineProductGuidError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
  }

  async handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        productError: "",
        total: 0,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      productDataError: "",
      alertErrorMessage: "",
      productError: "",
    });
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var rowControl = true;
    this.state.productData.forEach((element) => {
      if (!element.machineProductGuid) {
        rowControl = false;
      }
      if (!element.pieceDefective || element.pieceDefective === 0) {
        rowControl = false;
      }
    });

    if (rowControl === false) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.productData &&
      this.state.productData.length > 0 &&
      rowControl
    ) {
      this.setState({
        isLoading: true,
        isLoadingStockData: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          machineProductGuid: element.machineProductGuid,
          pieceDefective: element.pieceDefective,
          status: true,
        });
      });

      if (this.state.controlProduct === false) {
        // 
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };

        var myObj = {
          defectiveQuantityProductDto: productData,
        };

        var URLParam = "/defectiveQuantity/add";
        if (this.state.guid && this.state.urlSplit.length !== 2) {
          URLParam = "/defectiveQuantity/update";
          myObj = {
            guid: this.state.guid,
            defectiveQuantityProductDto: productData,
          };
        }
        axios
          .post(URL + URLParam, myObj, config)
          .then(() => {
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (this.state.guid) {
                      window.location.href = "/arizali-urun-listele";
                    } else {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          })
          .catch((error) => {
            confirmAlert({
              title: CgnMessage.alertErrorTitle,
              message: error.response.data,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    this.setState({
                      isLoading: false,
                      isLoadingStockData: false,
                    });
                  }
                },
              ],
            });
          });
      } else {
        this.setState({
          isLoading: false,
          isLoadingStockData: false,
          controlProduct: false,
        });
      }
    }
  };

  toggle = () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
  };

  toggleImage = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  handleAddToCart = async (row) => {
    if (row.guid && row.piece && row.piece > 0) {
      let array = this.state.productData;

      let control = false;
      array.forEach((item) => {
        if (item.machineProductGuid === row.guid) {
          item.piece = row.piece;
          control = true;
        }
      });

      if (control === false) {
        await array.push({
          machineProductGuid: row.guid,
          machineProductImage: row.image,
          machineProductName: row.name,
          pieceDefective: row.piece,
          status: true,
          productError: "",
          productPieceError: "",
          alertErrorMessage: "",
        });
        await this.setState({
          counter: this.state.counter + 1,
        });
        if (array[this.state.counter].machineProductGuid) {
          return array[this.state.counter].productError === "";
        }
        if (array[this.state.counter].pieceDefective) {
          return array[this.state.counter].pieceDefectiveError === "";
        }
        if (array[this.state.counter].machineProductGuid === "") {
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      }
      this.setState({ productData: array });
    }
  };

  handleProductPieceTextBoxChange(e, row) {
    let stockData = this.state.stockData;
    stockData.forEach((item) => {
      if (item.guid === row.guid) {
        item.piece = parseFloat(e.target.value);
      }
    });
    this.setState({
      stockData: stockData,
    });
  }

  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "145px",
  };

  tdStyle = {
    width: "400px",
  };

  tdStyle2 = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productCountAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                {this.state.productData.map((productData, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="9" className="modalSelectCol">
                      {productData.machineProductImage ? (
                        <Button
                          onClick={() =>
                            this.toggleImage(
                              productData.machineProductImage,
                              productData.machineProductName
                            )
                          }
                          className="cart_image_button"
                        >
                          <img
                            alt={productData.machineProductName}
                            src={`/Images/${productData.machineProductImage}`}
                            className="cart_no_image rounded"
                          />
                        </Button>
                      ) : (
                        <img
                          alt={productData.machineProductName}
                          src={noImg}
                          className="cart_no_image rounded"
                        />
                      )}
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="machineProductGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={productData.machineProductGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={productData.productError}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="2">
                      <CgnTextbox
                        type="number"
                        name="pieceDefective"
                        label="Arızalı Adet [*]"
                        value={productData.pieceDefective}
                        onChange={(e) =>
                          this.handleProductPieceDefectiveTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productPieceDefectiveError}
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        type="button"
                        color="danger"
                        className="dynamicRowDeleteButton"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggle}
                    text={"Çoklu Ürün Ekle"}
                  />
                </Row>
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>

        <Row className="assignmentList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoadingStockData} />
              <CgnDatatable
                data={this.state.stockData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    selector: "image",
                    sortable: false,
                    width: "80px",
                    cell: (cell) => (
                      <div>
                        {cell.image ? (
                          <Button
                            onClick={() => this.toggle(cell.image, cell.name)}
                            className="modalButtonList"
                          >
                            <img
                              alt={cell.name}
                              src={`/Images/${cell.image}`}
                              className="rdt_image rounded"
                            />
                          </Button>
                        ) : (
                          <img
                            alt={cell.name}
                            src={noImg}
                            className="rdt_image rdt_no_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Ürün Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Ürün Kodu</th>
                              <td style={this.tdStyle}>{row.code}</td>
                              <th style={this.thStyle2}>Kategori Ad</th>
                              <td>{row.categoryName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ürün Adı</th>
                              <td style={this.tdStyle}>{row.name}</td>
                              <th style={this.thStyle2}>Yabancı Ürün Adı</th>
                              <td>{row.foreignName ? row.foreignName : "-"}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    width: "125px",
                    name: "Arızalı Adet",
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, idx) => (
                      <CgnTextbox
                        type="number"
                        className="dataTableInput"
                        name="piece"
                        value={row.piece}
                        placeHolder="Adet"
                        maxLength="100"
                        autoComplete="off"
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, row)
                        }
                      />
                    ),
                  },
                  {
                    width: "125px",
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, idx) => (
                      <CgnButton
                        text={"Ekle"}
                        type="button"
                        className="w-100 ml-0"
                        color="warning"
                        onClick={() => this.handleAddToCart(row)}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.productEntryModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} className="font-weight-bold">
            Çoklu Ürün Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <Input
              type="textarea"
              className="modalTextArea"
              onChange={(e) => this.handleChangeMultipleProduct(e)}
            />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="primary"
              onClick={() => this.handleClickMultipleProduct()}
              text={CgnMessage.addButton}
              className="modalButton"
            />
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.toggle}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggleImage}
        >
          <ModalHeader toggle={this.toggleImage}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default DefectiveQuantityAddUpdate;
