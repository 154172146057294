import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL, Mounth, Year} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class TypeList extends Component {
  state = {
    pageTitle: "Tip Listele",
    pageAddLink: "tip-ekle",
    pageUpdateLink: "tip-guncelle",
    pageDetailLink: "tip-detay",
    pageListLink: "tip-listele",
    breadcrumb: [{ text: "Tip", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    typeData: [],
    name: "",
    endDay: 0,
    optionDay: 0,
    remindPerson: 0,
    mounth: 0,
    year: 0,
    guid: "",
    alertErrorMessage: "",
    selectedMonth: 0,
    selectedMonthValue: 0,
    selectedYear: 0,
    selectedYearValue: 0,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    if (this.state.selectedMonth === 0 && this.state.selectedYear === 0) {
      this.setState({
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
      });
    }

    if (localStorage.getItem("selectedMonthType")) {
      await this.setState({
        selectedMonth: parseInt(localStorage.getItem("selectedMonthType")),
      });
    }
    if (localStorage.getItem("selectedYearType")) {
      await this.setState({
        selectedYear: parseInt(localStorage.getItem("selectedYearType")),
      });
    }

    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    axios
      .get(URL + "/types/getlist/" + 
      this.state.selectedMonth +
      "/" +
      this.state.selectedYear, config)
      .then((response) => {
        this.setState({
          typeData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleMounthChange(option) {
    if (option) {
      await this.setState({
        selectedMonth: option.value,
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        selectedMonth: "",
      });
    }
    localStorage.setItem("selectedMonthCalendar", this.state.selectedMonth);
  }

  async handleYearChange(option) {
    if (option) {
      await this.setState({
        selectedYear: option.value,
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        selectedYear: "",
      });
    }
    localStorage.setItem("selectedYearCalendar", this.state.selectedYear);
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/types/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }
  thStyle = {
    width: "130px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "300px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="typeList" id="list">
        <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
             <Row className="filterRow">
                <Col className="pl-2 pr-2" md="5">
                <CgnReactSelect
                      name="selectedMounth"
                      className="selectedMounth"
                      label="Ay"
                      placeholder="Ay seçiniz..."
                      selectValue={this.state.selectedMonth}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleMounthChange(option)}
                      options={Mounth}
                      error={this.state.mounthError}
                    />
                </Col>
                <Col className="pl-2 pr-2" md="5">
                <CgnReactSelect
                      name="selectedYear"
                      className="selectedYear"
                      label="Yıl"
                      placeholder="Yıl seçiniz..."
                      selectValue={this.state.selectedYear}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleYearChange(option)}
                      options={Year}
                      error={this.state.yearError}
                    />
                </Col>                            
                <Col className="pl-2 pr-0" md="2">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.typeData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Tip Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tip</th>
                              <td colSpan={4} style={this.tdStyle}>
                                {row.name}
                              </td>                          
                            </tr>
                            <tr>                           
                              <th style={this.thStyle}>Hatırlatılacak Kişi</th>
                              <td style={this.tdStyle}>
                                {row.remindPerson !== 0 ? 
                    (row.remindPerson === 1 ? "Yönetici" 
                    : row.remindPerson === 2 ? "Müşteri" 
                    : row.remindPerson === 3 ? "Yönetici ve Müşteri" : "-") 
                    : "-"}
                              </td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>{row.createdBy}</td>
                            </tr>                                                                             
                          </tbody>
                        </Table>
                      );
                    },
                  },                
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default TypeList;
