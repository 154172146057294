import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class TpuCostDetail extends Component {
  state = {
    pageTitle: "Tpu Maliyeti Detay",
    pageTitleDetail: "Tpu Maliyeti Detay",
    pageAddLink: "tpu-maliyet-ekle",
    pageListLink: "tpu-maliyet-listele",
    breadcrumb: [{ text: "Tpu Maliyeti", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    offerSieveGuid: "",
    offerSieveName: "",
    sieveProductGuid: "",
    sieveProductArr: [],
    sieveEyeSize: "",
    sieveProductName: "",
    sieveProductError: "",
    sieveSizeArr: [],
    sieveEyeSizeArr: [],
    sieveSizeMultiplier: 0,
    sieveEyeSizeMultiplier: 0,
    sieveSize: "",
    castingCostTotal: 0,
    transport: 0,
    transportRate: 0,
    transportTotal: 0,
    lama810: 0,
    lama810Rate: 0,
    lama810Total: 0,
    lama1020: 0,
    lama1020Rate: 0,
    lama1020Total: 0,
    profitRate: 0,
    pieceTotal: 0,
    m2Total: 0,
    total: 0,
    costType: "",
    costTypeError: "",
    raw1: 0,
    raw1Rate: 0,
    raw1Total: 0,
    raw2: 0,
    raw2Rate: 0,
    raw2Total: 0,
    raw3: 0,
    raw3Rate: 0,
    raw3Total: 0,
    raw4: 0,
    raw4Rate: 0,
    raw4Total: 0,
    raw5: 0,
    raw5Rate: 0,
    raw5Total: 0,
    raw6: 0,
    raw6Rate: 0,
    raw6Total: 0,
    raw7: 0,
    raw7Rate: 0,
    raw7Total: 0,
    raw8: 0,
    raw8Rate: 0,
    raw8Total: 0,
    sundry: 0,
    sundryRate: 0,
    sundryTotal: 0,
    lamaLaser: 0,
    lamaLaserRate: 0,
    lamaLaserTotal: 0,
    smallCost: 0,
    smallCostRate: 0,
    smallCostTotal: 0,
    bigCost: 0,
    bigCostRate: 0,
    bigCostTotal: 0,
    smallProduction: 0,
    smallProductionRate: 0,
    smallProductionTotal: 0,
    bigProduction: 0,
    bigProductionRate: 0,
    bigProductionTotal: 0,
    cost: 0,
    updatedAt: "",
    updatedBy: "",
    createdAt: "",
    createdBy: "",
    description: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/tpuCosts/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            offerSieveGuid: response.data.offerSieveGuid,
            offerSieveName: response.data.offerSieveName,
            sieveProductGuid: response.data.sieveProductGuid,
            sieveSize: response.data.sieveSize,
            sieveEyeSize: response.data.sieveEyeSize,
            sieveProductName: response.data.sieveProductName,
            transport: parseFloat(response.data.transport),
            transportRate: parseFloat(response.data.transportRate),
            lama810: parseFloat(response.data.lama810),
            lama810Rate: parseFloat(response.data.lama810Rate),
            profitRate: parseFloat(response.data.profitRate),
            m2Total: parseFloat(response.data.m2Total),
            total: parseFloat(response.data.total),
            castingCostTotal: parseFloat(response.data.castingCostTotal),
            isLoading: false,
            lama810Total: parseFloat(response.data.lama810Total),
            transportTotal: parseFloat(response.data.transportTotal),
            raw1: parseFloat(response.data.raw1),
            raw1Rate: parseFloat(response.data.raw1Rate),
            raw1Total: parseFloat(response.data.raw1Total),
            raw2: parseFloat(response.data.raw2),
            raw2Rate: parseFloat(response.data.raw2Rate),
            raw2Total: parseFloat(response.data.raw2Total),
            raw3: parseFloat(response.data.raw3),
            raw3Rate: parseFloat(response.data.raw3Rate),
            raw3Total: parseFloat(response.data.raw3Total),
            raw4: parseFloat(response.data.raw4),
            raw4Rate: parseFloat(response.data.raw4Rate),
            raw4Total: parseFloat(response.data.raw4Total),
            raw5: parseFloat(response.data.raw5),
            raw5Rate: parseFloat(response.data.raw5Rate),
            raw5Total: parseFloat(response.data.raw5Total),
            raw6: parseFloat(response.data.raw6),
            raw6Rate: parseFloat(response.data.raw6Rate),
            raw6Total: parseFloat(response.data.raw6Total),
            raw7: parseFloat(response.data.raw7),
            raw7Rate: parseFloat(response.data.raw7Rate),
            raw7Total: parseFloat(response.data.raw7Total),
            raw8: parseFloat(response.data.raw8),
            raw8Rate: parseFloat(response.data.raw8Rate),
            raw8Total: parseFloat(response.data.raw8Total),
            lamaLaser: parseFloat(response.data.lamaLaser),
            lamaLaserRate: parseFloat(response.data.lamaLaserRate),
            lamaLaserTotal: parseFloat(response.data.lamaLaserTotal),
            smallProduction: parseFloat(response.data.smallProduction),
            smallProductionRate: parseFloat(response.data.smallProductionRate),
            smallProductionTotal: parseFloat(
              response.data.smallProductionTotal
            ),
            bigProduction: parseFloat(response.data.bigProduction),
            bigProductionRate: parseFloat(response.data.bigProductionRate),
            bigProductionTotal: parseFloat(response.data.bigProductionTotal),
            smallCost: parseFloat(response.data.smallCost),
            smallCostRate: parseFloat(response.data.smallCostRate),
            smallCostTotal: parseFloat(response.data.smallCostTotal),
            bigCost: parseFloat(response.data.bigCost),
            bigCostRate: parseFloat(response.data.bigCostRate),
            bigCostTotal: parseFloat(response.data.bigCostTotal),
            cost: parseFloat(response.data.cost),
            sundry: parseFloat(response.data.sundry),
            sundryRate: parseFloat(response.data.sundryRate),
            sundryTotal: parseFloat(response.data.sundryTotal),
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            createdAt: response.data.createdAt,
            createdBy: response.data.createdBy,
            description: response.data.description,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  thStyle = {
    width: "150px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "180px",
  };

  thStyle1 = {
    width: "200px",
    display: "none",
  };

  tdStyle1 = {
    width: "200px",
    display: "none",
  };

  render() {
    var self = this;
    var self1 = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Teklif</th>
                    <td colSpan="4">
                      {this.state.offerSieveName
                        ? this.state.offerSieveName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Malzeme</th>
                    <td colSpan="4">
                      {this.state.sieveProductName
                        ? this.state.sieveProductName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Elek Boyutu (mm)</th>
                    <td>{this.state.sieveSize ? this.state.sieveSize : "-"}</td>
                    <th>Elek Göz Boyutu (mm)</th>
                    <td>
                      {this.state.sieveEyeSize ? this.state.sieveEyeSize : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Hammadde-1 Birimi (€)</th>
                    <td>{this.state.raw1Rate ? this.state.raw1Rate : "-"}</td>
                    <th>Hammadde-1</th>
                    <td>{this.state.raw1 ? this.state.raw1 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Hammadde-2 Birimi (€)</th>
                    <td>{this.state.raw2Rate ? this.state.raw2Rate : "-"}</td>
                    <th>Hammadde-2</th>
                    <td>{this.state.raw2 ? this.state.raw2 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Hammadde-3 Birimi (€)</th>
                    <td>{this.state.raw3Rate ? this.state.raw3Rate : "-"}</td>
                    <th>Hammadde-3</th>
                    <td>{this.state.raw3 ? this.state.raw3 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Hammadde-4 Birimi (€)</th>
                    <td>{this.state.raw4Rate ? this.state.raw4Rate : "-"}</td>
                    <th>Hammadde-4</th>
                    <td>{this.state.raw4 ? this.state.raw4 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Hammadde-5 Birimi (€)</th>
                    <td>{this.state.raw5Rate ? this.state.raw5Rate : "-"}</td>
                    <th>Hammadde-5</th>
                    <td>{this.state.raw5 ? this.state.raw5 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Hammadde-6 Birimi (€)</th>
                    <td>{this.state.raw6Rate ? this.state.raw6Rate : "-"}</td>
                    <th>Hammadde-6</th>
                    <td>{this.state.raw6 ? this.state.raw6 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Hammadde-7 Birimi (€)</th>
                    <td>{this.state.raw7Rate ? this.state.raw7Rate : "-"}</td>
                    <th>Hammadde-7</th>
                    <td>{this.state.raw7 ? this.state.raw7 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Hammadde-8 Birimi (€)</th>
                    <td>{this.state.raw8Rate ? this.state.raw8Rate : "-"}</td>
                    <th>Hammadde-8</th>
                    <td>{this.state.raw8 ? this.state.raw8 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Nakliye Birimi (€)</th>
                    <td>
                      {this.state.transportRate
                        ? this.state.transportRate
                        : "-"}
                    </td>
                    <th>Nakliye</th>
                    <td>{this.state.transport ? this.state.transport : "-"}</td>
                  </tr>

                  <tr>
                    <th>8x16 Lama Birimi (€)</th>
                    <td>
                      {this.state.lama810Rate ? this.state.lama810Rate : "-"}
                    </td>
                    <th>8x10 Lama</th>
                    <td>{this.state.lama810 ? this.state.lama810 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Muhtelif Birimi (€)</th>
                    <td>
                      {this.state.sundryRate ? this.state.sundryRate : "-"}
                    </td>
                    <th>Muhtelif</th>
                    <td>{this.state.sundry ? this.state.sundry : "-"}</td>
                  </tr>

                  <tr>
                    <th>Lama Lazer Kesim Birimi (€)</th>
                    <td>
                      {this.state.lamaLaserRate
                        ? this.state.lamaLaserRate
                        : "-"}
                    </td>
                    <th>Lama Lazer Kesim</th>
                    <td>{this.state.lamaLaser ? this.state.lamaLaser : "-"}</td>
                  </tr>

                  <tr>
                    <th>Küçük Enj Üretim Miktarı Birimi (€)</th>
                    <td>
                      {this.state.smallProductionRate
                        ? this.state.smallProductionRate
                        : "-"}
                    </td>
                    <th>Küçük Enj Üretim Miktarı</th>
                    <td>
                      {this.state.smallProduction
                        ? this.state.smallProduction
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Büyük Enj Üretim Miktarı Birimi (€)</th>
                    <td>
                      {this.state.bigProductionRate
                        ? this.state.bigProductionRate
                        : "-"}
                    </td>
                    <th>Büyük Enj Üretim Miktarı</th>
                    <td>
                      {this.state.bigProduction
                        ? this.state.bigProduction
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Küçük Enj Maliyeti Birimi (€)</th>
                    <td>
                      {this.state.smallCostRate
                        ? this.state.smallCostRate
                        : "-"}
                    </td>
                    <th>Küçük Enj Maliyeti</th>
                    <td>{this.state.smallCost ? this.state.smallCost : "-"}</td>
                  </tr>

                  <tr>
                    <th>Büyük Enj Maliyeti Birimi (€)</th>
                    <td>
                      {this.state.bigCostRate ? this.state.bigCostRate : "-"}
                    </td>
                    <th>Büyük Enj Maliyeti</th>
                    <td>{this.state.bigCost ? this.state.bigCost : "-"}</td>
                  </tr>

                  <tr>
                    <th>Genel Gider</th>
                    <td colSpan="4">
                      {this.state.cost ? this.state.cost : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Malzeme Maliyeti (€/ad)</th>
                    <td>{this.state.total}</td>
                    <th>Toplam (€/m2) </th>
                    <td>{this.state.m2Total}</td>
                  </tr>

                  <tr>
                    <th>İşçilik Yüzdesi (%)</th>
                    <td>{this.state.profitRate}</td>
                    <th>Toplam (€/ad)</th>
                    <td>{this.state.castingCostTotal}</td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan={4}>
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default TpuCostDetail;
