import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Form,
} from "reactstrap";
import Logo from "../../images/cogen-crm-logo.png";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButton from "../toolbox/CgnButton";
import CgnLoader from "../toolbox/CgnLoader";
import packageJson from "../../../package.json";
import axios from "axios";
import { URL } from "../../redux/actions/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { CgnPasswordControl } from "../toolbox/CgnPasswordControl";

class RefreshPassword extends React.Component {
  state = {
    password: "",
    passwordError: "",
    verifyCode: "",
    verifyCodeError: "",
    isLoading: false,
    alertErrorMessage: "",
    googleRecaptcha: "",
  };

  componentDidMount() {
    if (!localStorage.getItem("email")) {
      this.props.history.push("/bilgilerimi-unuttum");
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      passwordError: "",
      verifyCodeError: "",
      alertErrorMessage: "",
    });
    if (name === "password" && (value === "" || !CgnPasswordControl.test(value))) {
      this.setState({
        passwordError: CgnMessage.newPasswordErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "verifyCode" && value === "") {
      this.setState({
        verifyCodeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  onChangeGoogleRecaptcha = (value) => {
    this.setState({
      googleRecaptcha: value,
      alertErrorMessage: "",
    });
  };

  handleSave = (event) => {
    event.preventDefault();
    this.setState({
      passwordError: "",
      verifyCodeError: "",
      alertErrorMessage: "",
    });
    if (this.state.password === "" || !CgnPasswordControl.test(this.state.password)) {
      this.setState({
        passwordError: CgnMessage.newPasswordErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.verifyCode === "") {
      this.setState({
        verifyCodeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.googleRecaptcha === ""
    ) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    
    else if(
      this.state.password !== "" &&
      this.state.verifyCode !== ""
    ){
        this.setState({
          isLoading: true,
        });
        var refreshTokenDto = {
          email: localStorage.getItem("email"),
          code: this.state.verifyCode,
          password: this.state.password,
        };
        axios
          .post(URL + "/auth/rePassword/", refreshTokenDto)
          .then((response) => {
            localStorage.clear();
            setTimeout(() => {
              this.props.history.push("/");
            }, 1500);
          })
          .catch((error) => {
              this.setState({
                alertErrorMessage: error.response.data,
                isLoading: false,
              });
          });
    }
  };

  render() {
    return (
      <div className="login-page" id="refreshPassword">
        <Card>
          <CgnLoader visibled={this.state.isLoading} />
          <CardHeader>
            <Row>
              <Col md="7">
                <h4>{CgnMessage.programName}</h4>
                <h1>Şifre Yenile</h1>
              </Col>
              <Col md="5" className="pl-0">
                <a
                  href="https://www.cogen.com.tr"
                  alt="Cogen® Yazılım | CRM"
                  target="blank"
                >
                  <img src={Logo} alt="Cogen® Yazılım | CRM" className="logo" />
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.handleSave}>
              <CgnTextbox
                type="password"
                name="password"
                label="Şifre [*]"
                value={this.state.password}
                placeHolder="Lütfen şifre girin..."
                maxLength="100"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.passwordError}
              />
              <CgnTextbox
                type="text"
                name="verifyCode"
                label="Doğrulama Kodu [*]"
                value={this.state.verifyCode}
                placeHolder="Lütfen doğrulama kodu girin..."
                maxLength="100"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.verifyCodeError}
              />
              <ReCAPTCHA
                sitekey="6LdR6QQbAAAAAKmceasCKrr-0edWssWva5K672E0"
                onChange={this.onChangeGoogleRecaptcha}
                className="recaptcha text-center form-group"
              />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnButton
                type="submit"
                color="primary"
                block={true}
                text="Şifre Değiştir"
                disabled={this.state.isLoading}
              />
            </Form>
            <a href="/" className="float-right mt-2">
              Kullanıcı Girişi Yap
            </a>
          </CardBody>
          <CardFooter>
            <p className="m-0">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://www.cogen.com.tr"
                alt="Cogen® Yazılım | CRM"
                target="blank"
              >
                Cogen® Yazılım | CRM
              </a>{" "}
              | Tüm hakları saklıdır.
              <br />
              Versiyon : {packageJson.version}
            </p>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default RefreshPassword;
