import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
class CustomerEducationAnnouncementEMailList extends Component {
    state = {
        pageTitle: "Potansiyel Müşteriler Listele",
        pageAddLink: "musteri-egitim-duyuru-e-mail-ekle",
        pageUpdateLink: "musteri-egitim-duyuru-e-mail-guncelle",
        pageDetailLink: "musteri-egitim-duyuru-e-mail-detay",
        pageListLink: "musteri-egitim-duyuru-e-mail-listele",
        breadcrumb: [{ text: "Potansiyel Müşteriler", link: "#" }],
        isLoading: false,
        isDeleted: false,
        status: false,
        data: [],
        name: "",
        guid: "",
        alertErrorMessage: "",
    };
    async componentDidMount() {
        this.setState({
            isLoading: true,
        });
        // 
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        var statusType = 1;
        axios
            .get(URL + "/customereducationannouncementemail/getlist/" + statusType, config)
            .then((response) => {
                this.setState({
                    data: response.data,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
    }
    handleDelete = (guid) => {
        confirmAlert({
            title: CgnMessage.deleteQuestionTitle,
            message: CgnMessage.deleteQuestionMessage,
            buttons: [
                {
                    label: CgnMessage.deleteQuestionYes,
                    onClick: () => this.deleteItem(guid),
                },
                {
                    label: CgnMessage.deleteQuestionNo,
                },
            ],
        });
    };
    async deleteItem(guid) {
        this.setState({
            isLoading: true,
        });
        // 
        var config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        const URLParam = "/customereducationannouncementemail/delete";
        const obj = {
            guid,
        };
        axios
            .post(URL + URLParam, obj, config)
            .then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((error) => {
                this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                });
            });
    }
    render() {
        return (
            <>
                <ContentTop
                    breadcrumb={this.state.breadcrumb}
                    title={this.state.pageTitle}
                    addLink={"/" + this.state.pageAddLink}
                    listLink={"/" + this.state.pageListLink}
                />
                <Row className="CustomerEducationAnnouncementEMailList" id="list">
                    <Col>
                        <Card body>
                            <CgnLoader visibled={this.state.isLoading} />
                            {this.state.alertErrorMessage && (
                                <CgnAlert
                                    color="danger"
                                    title={CgnMessage.alertErrorTitle}
                                    text={this.state.alertErrorMessage}
                                />
                            )}
                            <CgnDatatable
                                data={this.state.data}
                                title={this.state.pageTitle}
                                columns={[
                                    {
                                        name: "E-Mail",
                                        selector: "name",
                                        sortable: false,
                                    },
                                    {
                                        selector: "status",
                                        width: "110px",
                                        padding: "0px",
                                        sortable: false,
                                        cell: (row) => {
                                            if (row.status) {
                                                return <CgnBadge color="success" text="Aktif" />;
                                            } else {
                                                return <CgnBadge color="danger " text="Pasif" />;
                                            }
                                        },
                                    },
                                    {
                                        ignoreRowClick: true,
                                        allowOverflow: true,
                                        button: true,
                                        cell: (row, cell) => (
                                            <CgnButtonDropdown
                                                label={"Yönet"}
                                                className="mr-0"
                                                color="primary"
                                                items={[
                                                    {
                                                        text: CgnMessage.updateButton,
                                                        className: "dropDownBtn",
                                                        href: `/${this.state.pageUpdateLink}/${row.guid}`,
                                                    },
                                                    {
                                                        text: CgnMessage.detailButton,
                                                        className: "dropDownBtn",
                                                        href: `/${this.state.pageDetailLink}/${row.guid}`,
                                                    },
                                                    {
                                                        text: CgnMessage.deleteButton,
                                                        className: "dropDownBtn",
                                                        onClick: () => this.handleDelete(row.guid),
                                                    },
                                                ]}
                                            />
                                        ),
                                    },
                                ]}
                                loading={this.state.isLoading}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}
export default CustomerEducationAnnouncementEMailList;