import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  fotoStyle,
  SendMachineProductUnits,
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";
import noImg from "../../images/no-img.png";
import moment from "moment";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import NewMachineAddUpdate from "../newMachine/NewMachineAddUpdate";
import { confirmAlert } from "react-confirm-alert";
class UsedSparePartsAddUpdate extends Component {
  state = {
    pageTitle: "Kullanılan Yedek Parça Ekle",
    pageTitleAdd: "Kullanılan Yedek Parça Ekle",
    pageTitleUpdate: "Kullanılan Yedek Parça Güncelle",
    pageAddLink: "kullanilan-yedek-parca-ekle",
    pageListLink: "kullanilan-yedek-parca-listele",
    breadcrumb: [{ text: "Kullanılan Yedek Parça", link: "#" }],
    isLoading: false,
    urlSplit: [],
    locationGuid: "",
    locationGuidError: "",
    locationArr: [],
    dateTime: "",
    dateTimeError: "",
    newMachineGuid: "00000000-0000-0000-0000-000000000000",
    newMachineGuidError: "",
    newMachineArr: [],
    usedSparePartsProductDtos: [],
    usedSparePartsProductDtosError: "",
    alertErrorMessage: "",
    counter: -1,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    isOpen: false,
    image: "",
    name: "",
    productEntryModal: false,
    newMachineGuidModalPlus: false,
    isOpenModalNewMachineGuid: false,
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      newMachineGuidModalPlus: await UserOperationClaimControl(1222),
    });

    if (localStorage.getItem("locationGuid")) {
      this.setState({
        locationGuid: localStorage.getItem("locationGuid"),
      });
    }

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(URL + "/locations/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(
        URL +
        "/newmachines/getlist/2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000",
        config
      )
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label:
              item.bmb ? item.bmb : "-",
            machineGuid: item.machineGuid,
          };
        });
        this.setState({
          newMachineArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(
        URL + "/machineProducts/getlistlocation/" + this.state.locationGuid,
        config
      )
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name} (${item.piece
              } ${SendMachineProductUnits(item.unit)})`,
            image: item.image,
            name: item.name,
            code: item.code,
            piece: item.piece,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (
      urlSplit.length === 3 &&
      urlSplit[1] === "kullanilan-yedek-parca-guncelle"
    ) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      axios
        .get(URL + "/usedspareparts/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            dateTime: response.data.dateTime,
            locationGuid: response.data.locationGuid,
            newMachineGuid: response.data.newMachineGuid,
            usedSparePartsProductDtos: response.data.usedSparePartsProductDtos,
            status: response.data.status,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    if (
      this.state.isOpenModalNewMachineGuid &&
      this.props.location.modalAddedNewMachine !== undefined &&
      this.props.location.modalAddedNewMachine.isModal !== undefined &&
      this.props.location.modalAddedNewMachine.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(
          URL +
          "/newmachines/getlist/2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000",
          config
        )
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label:
                (item.serialNo ? item.serialNo + " / " : "") +
                item.bmb +
                " / " +
                item.name,
              machineGuid: item.machineGuid,
            };
          });
          this.setState({
            newMachineArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedNewMachine.data &&
              element.name === this.props.location.modalAddedNewMachine.data
            ) {
              this.setState({
                newMachineGuid: element.guid,
                newMachineNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedNewMachine.isModal = true;
      this.setState({
        isOpenModalNewMachineGuid: false,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    await this.setState({
      locationGuid: "",
      locationGuidError: "",
      dateTime: "",
      dateTimeError: "",
      newMachineGuid: "00000000-0000-0000-0000-000000000000",
      newMachineGuidError: "",
      usedSparePartsProductDtosError: "",
      alertErrorMessage: "",
    });
  }

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleNewMachineSelectChange(option) {
    if (option) {
      this.setState({
        newMachineGuid: option.value,
        newMachineGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        newMachineGuid: "00000000-0000-0000-0000-000000000000",
        newMachineGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDateTimeChange(dateTime) {
    if (dateTime) {
      this.setState({
        dateTime: dateTime,
        dateTimeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        dateTime: "",
        dateTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      locationGuidError: "",
      dateTimeError: "",
      //newMachineGuidError: "",
      usedSparePartsProductDtosError: "",
    });
    if (this.state.locationGuid === "") {
      this.setState({
        locationGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.dateTime === "") {
      this.setState({
        dateTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if (this.state.newMachineGuid === "") {
    //   this.setState({
    //     newMachineGuidError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    var thereIs = true;
    if (
      !this.state.usedSparePartsProductDtos ||
      this.state.usedSparePartsProductDtos.length === 0
    ) {
      thereIs = false;
      this.setState({
        usedSparePartsProductDtosError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    if (
      !this.state.usedSparePartsProductDtos ||
      this.state.usedSparePartsProductDtos.length > 0
    ) {
      this.state.usedSparePartsProductDtos.forEach((element) => {
        if (
          element.machineProductGuid === "" ||
          element.piece === "" ||
          element.piece === 0
        ) {
          thereIs = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
      if (thereIs === false) {
        this.setState({
          usedSparePartsProductDtosError: CgnMessage.textErrorMessage,
        });
      } else {
        this.setState({
          usedSparePartsProductDtosError: "",
        });
      }
    }

    if (
      this.state.locationGuid !== "" &&
      this.state.offerDate !== "" &&
      //this.state.newMachineGuid !== "" &&
      thereIs
    ) {
      

      var myObj = {
        locationGuid: this.state.locationGuid,
        dateTime: moment(this.state.dateTime).format("DD/MM/YYYY"),
        newMachineGuid: this.state.newMachineGuid,
        usedSparePartsProductDtos: this.state.usedSparePartsProductDtos,
        status: true,
      };
      var URLParam = "/usedspareparts/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "kullanilan-yedek-parca-guncelle"
      ) {
        URLParam = "/usedspareparts/update";
        myObj = {
          guid: this.state.guid,
          locationGuid: this.state.locationGuid,
          dateTime:
            this.state.dateTime &&
              this.state.dateTime.toString().indexOf("/") > 0
              ? new Date(
                this.state.dateTime.split("/")[2] +
                "-" +
                this.state.dateTime.split("/")[1] +
                "-" +
                this.state.dateTime.split("/")[0]
              )
              : moment(this.state.dateTime).format("DD/MM/YYYY"),
          newMachineGuid: this.state.newMachineGuid,
          usedSparePartsProductDtos: this.state.usedSparePartsProductDtos,
          status: true,
        };
      }
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          confirmAlert({
            title: CgnMessage.alertSuccessTitle,
            message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  window.location.href = "/kullanilan-yedek-parca-listele";
                }
              },
            ],
          });
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoading: false,
      });
    }
  };

  handleProductInputValueChange(option, idx) {
    let usedSparePartsProductDtos = this.state.usedSparePartsProductDtos;
    if (option) {
      let productArr = this.state.productArr;
      productArr.forEach((element) => {
        if (element.value === option.value) {
          if (element.image) {
            usedSparePartsProductDtos[idx].machineProductName = element.name;
            usedSparePartsProductDtos[idx].machineProductImage = element.image;
            usedSparePartsProductDtos[idx].maxPiece = element.piece;
            usedSparePartsProductDtos[idx].piece = 1;
          } else {
            usedSparePartsProductDtos[idx].machineProductName = "";
            usedSparePartsProductDtos[idx].machineProductImage = "";
            usedSparePartsProductDtos[idx].maxPiece = 0;
            usedSparePartsProductDtos[idx].piece = 0;
          }
        }
      });

      usedSparePartsProductDtos[idx].machineProductGuid = option.value;
      usedSparePartsProductDtos[idx].machineProductGuidError = "";
      
      this.setState({
        usedSparePartsProductDtos: usedSparePartsProductDtos,
      });
    } else {
      usedSparePartsProductDtos[idx].machineProductGuid = null;
      usedSparePartsProductDtos[idx].machineProductName = "";
      usedSparePartsProductDtos[idx].machineProductImage = "";
      usedSparePartsProductDtos[idx].machineProductGuidError =
        CgnMessage.textErrorMessage;
      this.setState({
        usedSparePartsProductDtos: usedSparePartsProductDtos,
      });
    }
  }

  handleProductPieceTextBoxChange(e, idx) {
    let usedSparePartsProductDtos = this.state.usedSparePartsProductDtos;
    if (e.target.value && parseFloat(e.target.value) !== 0) {
      if(parseFloat(e.target.value) <= usedSparePartsProductDtos[idx].maxPiece)
        usedSparePartsProductDtos[idx].piece = parseFloat(e.target.value);
      else 
        usedSparePartsProductDtos[idx].piece = parseFloat(usedSparePartsProductDtos[idx].maxPiece);
      usedSparePartsProductDtos[idx].pieceError = "";
      this.setState({
        usedSparePartsProductDtos: usedSparePartsProductDtos,
      });
    } else {
      usedSparePartsProductDtos[idx].piece = parseFloat(e.target.value);
      usedSparePartsProductDtos[idx].pieceError = CgnMessage.textErrorMessage;
      this.setState({
        usedSparePartsProductDtos: usedSparePartsProductDtos,
      });
    }
  }

  async handleProductRemove(idx, guid) {
    let usedSparePartsProductDtos = this.state.usedSparePartsProductDtos;
    usedSparePartsProductDtos.splice(idx, 1);
    this.setState({
      usedSparePartsProductDtos: usedSparePartsProductDtos,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleProductAdd = async (value, image, name) => {
    let array = this.state.usedSparePartsProductDtos;
    await array.push({
      machineProductGuid: value,
      machineProductImage: image,
      machineProductName: name,
      machineProductGuidError: CgnMessage.textErrorMessage,
      piece: 1,
      status: true,
      usedSparePartsProductDtosError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (
      array[this.state.counter].machineProductGuid === "" ||
      array[this.state.counter].piece === 0
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ usedSparePartsProductDtos: array });
  };

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleProductAdd(item.value, item.image, item.name);
        }
      });
    });
  };

  toggleMultiProduct = () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
  };

  addModalNewMachineGuid = () => {
    this.setState({
      isOpenModalNewMachineGuid: !this.state.isOpenModalNewMachineGuid,
    });
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                {localStorage.getItem("locationGuid") ===
                  "00000000-0000-0000-0000-000000000000" && (
                    <>
                      <Row>
                        <Col lg="3">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            isMulti={false}
                            selectValue={this.state.locationGuid}
                            placeholder="Depo seçiniz..."
                            options={this.state.locationArr}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleLocationSelectChange(option)
                            }
                            error={this.state.locationGuidError}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="dateTime"
                      label="Tarih [*]"
                      selected={
                        this.state.dateTime &&
                          this.state.dateTime.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.dateTime.split("/")[2] +
                            "-" +
                            this.state.dateTime.split("/")[1] +
                            "-" +
                            this.state.dateTime.split("/")[0]
                          )
                          : this.state.dateTime
                      }
                      onChange={(dateTime) =>
                        this.handleDateTimeChange(dateTime)
                      }
                      error={this.state.dateTimeError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="newMachineGuid"
                          label="C4C [*]"
                          isMulti={false}
                          selectValue={this.state.newMachineGuid}
                          placeholder="Makine seçiniz..."
                          options={this.state.newMachineArr}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleNewMachineSelectChange(option)
                          }
                          error={this.state.newMachineGuidError}
                        />
                      </div>
                      {this.state.newMachineGuidModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalNewMachineGuid()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {this.state.usedSparePartsProductDtos.map((item, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="8" className="modalSelectCol">
                      {item.machineProductImage ? (
                        <Button
                          onClick={() =>
                            this.toggle(
                              item.machineProductImage,
                              item.machineProductName
                            )
                          }
                          className="cart_image_button"
                        >
                          <img
                            alt={item.machineProductName}
                            src={`/Images/${item.machineProductImage}`}
                            className="cart_no_image rounded"
                          />
                        </Button>
                      ) : (
                        <img
                          alt={item.machineProductName}
                          src={noImg}
                          className="cart_no_image rounded"
                        />
                      )}
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="machineProductGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={item.machineProductGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={item.machineProductGuidError}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="3">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={item.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={item.pieceError}
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() =>
                          this.handleProductRemove(idx, item.machineProductGuid)
                        }
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}
                {this.state.usedSparePartsProductDtosError && (
                  <div className="invalid-feedback d-block mb-3">
                    {this.state.usedSparePartsProductDtosError}
                  </div>
                )}
                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton mb-3"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggleMultiProduct}
                    text={"Çoklu Ürün Ekle"}
                  />
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
        <Modal
          isOpen={this.state.productEntryModal}
          toggle={this.toggleMultiProduct}
        >
          <ModalHeader
            toggle={this.toggleMultiProduct}
            className="font-weight-bold"
          >
            Çoklu Ürün Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <Input
              type="textarea"
              className="modalTextArea"
              onChange={(e) => this.handleChangeMultipleProduct(e)}
            />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="primary"
              onClick={() => this.handleClickMultipleProduct()}
              text={CgnMessage.addButton}
              className="modalButton"
            />
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.toggleMultiProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalNewMachineGuid}
          toggle={this.addModalNewMachineGuid}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Makine Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <NewMachineAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalNewMachineGuid}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(UsedSparePartsAddUpdate);
