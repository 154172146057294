import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  ModalHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  TaxTypeOptions,
  optionOfferStatusesOptions,
  URL,
  fotoStyle,
  localFilePath,
  
  fotoStyle2,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import CgnButton from "../toolbox/CgnButton";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import pdfIcon from "../../images/pdfIcon.png";
import fileIcon from "../../images/file.jpg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import NoteAddUpdate from "../notes/NoteAddUpdate";

class OfferSieveDetail2 extends Component {
  state = {
    pageTitle: "Üretim Takibi Detay",
    pageTitleDetail: "Üretim Takibi Detay",
    pageListLink: "uretim-takibi-listele",
    breadcrumb: [{ text: "Üretim Takibi", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    offerNumber: "",
    offerDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    sevkAddress: "",
    generalDiscountTotal: null,
    generalTaxTotal: null,
    taxTotal: null,
    discountPercent: "",
    language: "",
    customerPhone: "",
    discountValue: "",
    discountTotal: "",
    taxType: null,
    taxRate: null,
    currencyType: 0,
    description: "",
    bankName: "",
    productDto: [],
    customerEmail: "",
    offerSieveImageDto: [],
    offerSievePictureDto: [],
    productName: "",
    piece: null,
    unitPrice: null,
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    offerCoverImage: "",
    confirmationDate: "",
    warrantyPeriodName: "",
    deliveryTimeName: "",
    deliveryTypeName: "",
    paymentTypeName: "",
    approvalDate: "",
    noteDto: [],
    isOpenModalNote: false,
    isOpenModalUpdateNote: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/offersieves/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            customerName: response.data.customerName,
            offerTypeName: response.data.offerTypeName,
            offerNumber: response.data.offerNumber,
            offerDate: response.data.offerDate,
            optionDate:
              response.data.optionDate === "01/01/1900"
                ? "-"
                : response.data.optionDate,
            reminderDate: response.data.reminderDate,
            language: response.data.language,
            productDto: response.data.offerSieveProductDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),
            currencyType: response.data.currencyType,
            customerEmail: response.data.customerEmail,
            customerPhone: response.data.customerPhone,
            paymentTypeName: response.data.paymentTypeName,
            deliveryTimeName: response.data.deliveryTimeName,
            deliveryTypeName: response.data.deliveryTypeName,
            //warrantyPeriodName: response.data.warrantyPeriodName,
            description: response.data.description,
            sevkAddress: response.data.sevkAddress,
            offerCoverImage: response.data.offerCoverImage,
            confirmationDate: response.data.confirmationDate,
            //offerCancelText: response.data.offerCancelText,
            offerSieveImageDto: response.data.offerSieveImageDto,
            offerSievePictureDto: response.data.offerSievePictureDto,
            bankName: response.data.bankName,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            approvalDate: response.data.approvalDate,
            noteDto: response.data.noteDto,
            isLoading: false,
            garantiden: response.data.garantiden,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalNote = () => {
    this.setState({
      isOpenModalNote: !this.state.isOpenModalNote,
      noteGuid: null,
    });
  };

  addModalUpdateNote = (guid) => {
    this.setState({
      isOpenModalUpdateNote: !this.state.isOpenModalUpdateNote,
      noteGuid: guid,
    });
  };

  handleNoteDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleNoteSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleNoteSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/notes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const sira = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "20px",
      textAlign: "center",
    };
    const siraTd = {
      backgroundColor: "#f00",
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    };
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const mainRowDate = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "215px",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self1 = this;
    var self = this.state;

    var phone = this.state.customerPhone,
      maskPhone = `${phone.substring(0, 1)}  ${
        "(" + phone.substring(1, 4) + ")"
      } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
        9,
        11
      )}`;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>
                <tr>
                  <th>Teklif Tarihi</th>
                  <td colSpan="4">{this.state.offerDate}</td>
                </tr>
                <tr>
                  <th>Teklif No</th>
                  <td colSpan="4">{this.state.offerNumber}</td>
                </tr>
                <tr>
                  <th>Teslimat Süresi </th>
                  <td colSpan="4">{this.state.deliveryTimeName}</td>
                </tr>
                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              {this.state.productDto !== null &&
                this.state.productDto.length > 0 &&
                this.state.productDto.map(function (item, idx) {
                  return (
                    <Table className="table table-responsive table-hover table-product">
                      <tr className="">
                        <th style={sira}>Sıra</th>
                        <th className="text-left pl-1" style={mainRow}>
                          Depo
                        </th>
                        <th className="text-left pl-1" style={mainRow}>
                          Ürün Adı
                        </th>
                        <th className="text-center" style={mainRow}>
                          Adet
                        </th>
                        <th className="text-center" style={mainRow}>
                          Birim
                        </th>
                      </tr>
                      <tr style={subRow}>
                        <td style={siraTd}>{idx + 1}</td>
                        <td className="text-left pl-1">{item.locationName}</td>
                        <td className="text-left pl-1">{item.productName}</td>
                        <td className="text-center">{item.piece}</td>
                        <td className="text-center">
                          {item.unit === "NIU"
                            ? "Adet"
                            : item.unit === "KGM"
                            ? "kg"
                            : item.unit === "GRM"
                            ? "gr"
                            : item.unit === "MTR"
                            ? "Metre"
                            : item.unit === "LTR"
                            ? "Litre"
                            : item.unit === "PA"
                            ? "Paket"
                            : item.unit === "BX"
                            ? "Kutu"
                            : item.unit === "CMT"
                            ? "cm"
                            : item.unit === "MTQ"
                            ? "m3"
                            : item.unit === "MTK"
                            ? "m2"
                            : item.unit === "ROLL"
                            ? "Rulo"
                            : item.unit === "SET"
                            ? "Set"
                            : item.unit === "CMQ"
                            ? "cm3"
                            : item.unit === "SAA" ? "Saat" : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th
                          colSpan={3}
                          style={mainRow}
                          className="text-left pl-3"
                        >
                          Makine Boyutu
                        </th>
                        <th style={mainRow} className="text-left pl-3">
                          Elek Boyutu
                        </th>
                        <th style={mainRow} className="text-left pl-3">
                          Elek Göz Boyutu
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={3} className="text-left pl-3">
                          {item.machineSize && item.machineSize !== ""
                            ? item.machineSize
                            : "-"}
                        </td>
                        <td className="text-left pl-3">
                          {item.sieveSize && item.sieveSize !== ""
                            ? item.sieveSize
                            : "-"}
                        </td>
                        <td className="text-left pl-3">
                          {item.sieveEyeSize && item.sieveEyeSize !== ""
                            ? item.sieveEyeSize
                            : "-"}
                        </td>
                      </tr>
                    </Table>
                  );
                })}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalNote}
          toggle={this.addModalNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.guid} updated={false} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalUpdateNote}
          toggle={this.addModalUpdateNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.noteGuid} updated={true} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default OfferSieveDetail2;
