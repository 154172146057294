import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import {
  URL,
  
  UserOperationClaimControl, manuelControlStatus} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import { withRouter } from "react-router-dom";
import { config } from "@fortawesome/fontawesome-svg-core";

class PinPullTestAddUpdate extends Component {
  state = {
    pageTitle: "Pin Çekme Testi Ekle",
    pageTitleAdd: "Pin Çekme Testi Ekle",
    pageTitleUpdate: "Pin Çekme Testi Güncelle",
    pageAddLink: "pin-cekme-testi-ekle",
    pageListLink: "pin-cekme-testi-listele",
    breadcrumb: [{ text: "Pin Çekme Testi", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productArr: [],
    productGuid: "",
    product2Guid: "",
    productArr2: [],
    materialArr: [],
    materialGuid: "",
    materialArr2 :[],
    material2Guid: "",
    workInstructionArr: [],
    pinPullTestProductDto: [],
    pinPullTestDeviceUsedDto: [],
    deviceUsedArr: [],
    deviceUsedGuid: "",
    lastControlPersonelGuid: "",
    detectionPersonelGuid: "",
    lastControlGuid: "",
    pinPullTestDate: "",
    itemNumber: "",
    processDefinitionGuid: "",
    serialNo: "",
    testSerialNo: "",
    workInstructionGuid: "",
    customerGuid: "",
    personelGuid: "",
    productOutGuid: "",
    guid: "",
    cocNo: "",
    status: true,
    isNull: false,
    modalButtonIdx: 0,
    customerArr: [],
    productOutArr: [],
    detectionPersonelArr: [],
    lastControlPersonelArr: [],
    qualityPersonelArr: [],
    qualityPersonelGuid: "",
    processDefinitionArr: [],
    isTrue: false,
    productError: "",
    alertErrorMessage: "",
    workInstructionError: "",
    pinPullTestDateError: "",
    detectionPersonelError: "",
    processDefinitionError: "",
    counter: -1,
    counter1: -1,
    message: "",
    statusChange: "",
    statusChangeError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    productModalPlus: false,
    materialModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      productModalPlus: await UserOperationClaimControl(1029),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/workInstructions/getlistsuitabilityform", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.customerName}`,
          };
        });
        this.setState({
          workInstructionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/materials/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          materialArr: myArr,
          materialArr2: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
      axios
      .get(URL + "/deviceUseds/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          deviceUsedArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/processDefinitions/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          processDefinitionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
          productArr2: myArr
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          detectionPersonelArr: myArr,
          qualityPersonelArr: myArr,
          lastControlPersonelArr: myArr,
          personelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (
      urlSplit.length === 3 &&
      urlSplit[1] === "pin-cekme-testi-guncelle"
    ) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/pinPullTests/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              productGuid: response.data.productGuid,
              workInstructionGuid: response.data.workInstructionGuid,
              customerGuid: response.data.customerGuid,
              productOutGuid: response.data.productOutGuid,
              personelGuid: response.data.personelGuid,
              serialNo: response.data.serialNo,
              testSerialNo: response.data.testSerialNo,
              workInstructionCode: response.data.workInstructionCode,
              detectionPersonelGuid: response.data.detectionPersonelGuid,
              pinPullTestDeviceUsedDto: response.data.pinPullTestDeviceUsedDto,
              counter: response.data.pinPullTestDeviceUsedDto.length - 1,
              pinPullTestProductDto: response.data.pinPullTestProductDto,
              counter1: response.data.pinPullTestProductDto.length - 1,
              dispatchPiece: response.data.dispatchPiece,
              pinPullTestDate: response.data.pinPullTestDate,
              lastControlPersonelGuid: response.data.lastControlPersonelGuid,
              qualityPersonelGuid: response.data.qualityPersonelGuid,
              isLoading: false
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        serialNo: "",
        workInstructionGuid: "",
        pinPullTestDate: "",
        pinPullTestDeviceUsedDto: [],
        pinPullTestProductDto: [],
        detectionPersonelGuid: "",
        dispatchPiece: "",
        testSerialNo: "",
        status: true,
        counter: -1,
        counter1: -1,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    this.setState({
      serialNo: "",
      workInstructionGuid: "",
      pinPullTestDate: "",
      pinPullTestDeviceUsedDto: [],
      pinPullTestProductDto: [],
      detectionPersonelGuid: "",
      dispatchPiece: "",
      testSerialNo: "",
      status: true,
      counter: -1,
      counter1: -1,
      isTrue: false,
    });
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleWorkInstructionSelectChange(option){
    if(option){
     this.setState({
       workInstructionGuid: option.value,
       workInstructionError: "",
       isLoading: true
     });
     // 
     var config = {
       headers: {
         Authorization: "Bearer " + localStorage.getItem("token"),
       },
     };
     axios 
     .get(URL + "/workInstructions/get/" + option.value, config)    
     .then((response) => {
       if(response.data.guid){
         this.setState({
            productGuid: response.data.productGuid,
            productOutGuid: response.data.productOutGuid,
            customerGuid: response.data.customerGuid,
            personelGuid: response.data.personelGuid,
            workInstructionCode: response.data.code,
            lastControlPersonelGuid: response.data.lastControlPersonelGuid,
            qualityPersonelGuid: response.data.qualityPersonelGuid,
            isLoading: false
         });
       }
     })
    }
    else{
     this.setState({
       workInstructionGuid: "",
       productGuid: "",
       customerGuid: "",
       personelGuid: "",
       productOutGuid: "",
       workInstructionCode: "",
       lastControlPersonelGuid: "",
       qualityPersonelGuid: "",
       workInstructionError: CgnMessage.alertErrorMessage,
     });
    }
   }

  handleDispatchPieceTextBoxChange(e) {
    this.setState({
      dispatchPiece: parseFloat(e.target.value),
      dispatchPieceError: "",
      alertErrorMessage: "",
    });
  }

  handleMeasuredValueTextBoxChange(e, idx) {
   let pinPullTestProductDto = this.state.pinPullTestProductDto;

   pinPullTestProductDto[idx].measuredValue = parseFloat(e.target.value);
   pinPullTestProductDto[idx].measuredValueError = "";

   this.setState({ pinPullTestProductDto: pinPullTestProductDto});
  }

  handleMinValueTextBoxChange(e, idx) {
    let pinPullTestProductDto = this.state.pinPullTestProductDto;
 
    pinPullTestProductDto[idx].minValue = parseFloat(e.target.value);
    pinPullTestProductDto[idx].minValueError = "";
 
    this.setState({ pinPullTestProductDto: pinPullTestProductDto});
   }

  handleSerialNoTextBoxChange(e) {
    this.setState({
      serialNo: e.target.value,
      serialNoError: "",
      alertErrorMessage: "",
    });
  }

  handleTestSerialNoTextBoxChange(e) {
    this.setState({
      testSerialNo: e.target.value,
      testSerialNoError: "",
      alertErrorMessage: "",
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handlePinPullTestDateChange(pinPullTestDate) {
    if (pinPullTestDate) {
      this.setState({
        pinPullTestDate: pinPullTestDate,
        pinPullTestDateError: "",
      });
    } else {
      this.setState({
        pinPullTestDate: "",
        pinPullTestDateError: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDetectionPersonelSelectChange(option) {
    if (option) {
      this.setState({
        detectionPersonelGuid: option.value,
        detectionPersonelError: "",
      });
    } else {
      this.setState({
        detectionPersonelGuid: "",
        detectionPersonelError: CgnMessage.alertErrorMessage,
      });
    }
  }


  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange2 = tags => (event) => {
    const { name, value } = event.target;
    let pinPullTestProductDto = this.state.pinPullTestProductDto;
    var idx = tags;

    pinPullTestProductDto[idx][name] = value;
    this.setState({
      pinPullTestProductDto: pinPullTestProductDto,
    });
  };


  handlePinProductSelectChange(option, idx) {
    let pinPullTestProductDto = this.state.pinPullTestProductDto;
    if (option) {
      pinPullTestProductDto[idx].productGuid = option.value;
      pinPullTestProductDto[idx].productError = "";
    } else {
      pinPullTestProductDto[idx].productGuid = "";
      pinPullTestProductDto[idx].productError = "";
    }

    this.setState({ pinPullTestProductDto: pinPullTestProductDto });
  }

  handlePin2ProductSelectChange(option, idx) {
    let pinPullTestProductDto = this.state.pinPullTestProductDto;
    if (option) {
      pinPullTestProductDto[idx].product2Guid = option.value;
      pinPullTestProductDto[idx].product2Error = "";
    } else {
      pinPullTestProductDto[idx].product2Guid = "";
      pinPullTestProductDto[idx].product2Error = "";
    }

    this.setState({ pinPullTestProductDto: pinPullTestProductDto });
  }

  handlePinMaterialSelectChange(option, idx) {
    let pinPullTestProductDto = this.state.pinPullTestProductDto;
    if (option) {
      pinPullTestProductDto[idx].materialGuid = option.value;
      pinPullTestProductDto[idx].materialError = "";
    } else {
      pinPullTestProductDto[idx].materialGuid = "";
      pinPullTestProductDto[idx].materialError = "";
    }

    this.setState({ pinPullTestProductDto: pinPullTestProductDto });
  }

  handlePin2MaterialSelectChange(option, idx) {
    let pinPullTestProductDto = this.state.pinPullTestProductDto;
    if (option) {
      pinPullTestProductDto[idx].material2Guid = option.value;
      pinPullTestProductDto[idx].material2Error = "";
    } else {
      pinPullTestProductDto[idx].material2Guid = "";
      pinPullTestProductDto[idx].material2Error = "";
    }

    this.setState({ pinPullTestProductDto: pinPullTestProductDto });
  }

  handleResultStatusChange(option, idx) {
    let pinPullTestProductDto = this.state.pinPullTestProductDto;
    if (option) {
      pinPullTestProductDto[idx].resultChange = option.value;
      pinPullTestProductDto[idx].resultChangeError = "";
    } else {
      pinPullTestProductDto[idx].resultChange = null;
      pinPullTestProductDto[idx].resultChangeError = "";
    }

    this.setState({ pinPullTestProductDto: pinPullTestProductDto });
  }


  handleDeviceUsedSelectChange(option, idx) {
    let pinPullTestDeviceUsedDto = this.state.pinPullTestDeviceUsedDto;
    if (option) {
      pinPullTestDeviceUsedDto[idx].deviceUsedGuid = option.value;
      pinPullTestDeviceUsedDto[idx].deviceUsedError = "";
    } else {
      pinPullTestDeviceUsedDto[idx].deviceUsedGuid = "";
      pinPullTestDeviceUsedDto[idx].deviceUsedError = "";
    }

    this.setState({ pinPullTestDeviceUsedDto: pinPullTestDeviceUsedDto });
  }

  handleProductAdd = async () => {
    let array = this.state.pinPullTestProductDto;
    await array.push({
      productGuid: "",
      product2Guid: "",
      materialGuid: "",
      material2Guid: "",
      power: "",
      description: "",
      resultChange: "",
    });
    await this.setState({
      counter1: this.state.counter1 + 1,
    });
    if (array[this.state.counter1].resultChange) {
      return array[this.state.counter1].resultChangeError === "";
    }
    if (array[this.state.counter1].resultChange === "") {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ pinPullTestProductDto: array });
  };

  handleDeviceUsedAdd = async () => {
    let array = this.state.pinPullTestDeviceUsedDto;
    await array.push({
      deviceUsedGuid: "",
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].deviceUsedGuid) {
      return array[this.state.counter].deviceUsedGuidError === "";
    }
    if (array[this.state.counter].deviceUsedGuid === "") {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ pinPullTestDeviceUsedDto: array });
  };

  async handleProductRemove(idx) {
    let someArray = this.state.pinPullTestProductDto;
    someArray.splice(idx, 1);
    await this.setState({
      pinPullTestProductDto: someArray,
      counter1: this.state.counter1 - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  async handleDeviceUsedRemove(idx) {
    let someArray = this.state.pinPullTestDeviceUsedDto;
    someArray.splice(idx, 1);
    await this.setState({
      pinPullTestDeviceUsedDto: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      workInstructionErrorError: "",
      pinPullTestDateError: "",
    });
    if (this.state.workInstructionGuid === "") {
      this.setState({
        workInstructionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.pinPullTestDate === "") {
      this.setState({
        pinPullTestDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.detectionPersonelGuid === "") {
      this.setState({
        detectionPersonelError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    
    var control = true;
    if (
      this.state.pinPullTestDeviceUsedDto !== null &&
      this.state.pinPullTestDeviceUsedDto.length > 0
    ) {
      this.state.pinPullTestDeviceUsedDto.forEach((element) => {
        if (element.deviceUsedGuid === "") {
          control = false;
        }
      });
    }

    var control2 = true;
    if(this.state.pinPullTestProductDto !== null &&
      this.state.pinPullTestProductDto.length > 0){
        this.state.pinPullTestProductDto.forEach((element) => {
          if (element.productGuid === "") {
            control2 = false;
          }
          if (element.materialGuid === "") {
            control2 = false;
          }
          if (element.product2Guid === "") {
            control2 = false;
          }
          if (element.material2Guid === "") {
            control2 = false;
          }
          if (element.resultChange === "" || element.resultChange === null) {
            control2 = false;
          }
        });
    }

    if (
      this.state.workInstructionGuid !== "" &&
      this.state.pinPullTestDate !== "" &&
      this.state.detectionPersonelGuid !== "" &&
      control === true &&
      control2 === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };    

      var pinPullTestProductDto = [];
      this.state.pinPullTestProductDto.forEach((element) => {
        pinPullTestProductDto.push({
          guid: element.guid,
          productGuid: element.productGuid,
          materialGuid: element.materialGuid,
          product2Guid: element.product2Guid,
          material2Guid: element.material2Guid,
          minValue: (element.minValue === "" || element.minValue === null) ? 0 : parseFloat(element.minValue),
          measuredValue: (element.measuredValue === "" || element.measuredValue === null) ? 0 : parseFloat(element.measuredValue),
          resultChange: element.resultChange,
          description: element.description
        });
      });

      var myObj = {
        workInstructionGuid: this.state.workInstructionGuid,
        detectionPersonelGuid: this.state.detectionPersonelGuid,
        pinPullTestDate: this.state.pinPullTestDate,
        serialNo: this.state.serialNo,
        testSerialNo: this.state.testSerialNo,
        dispatchPiece: this.state.dispatchPiece === "" ? 0 : parseFloat(this.state.dispatchPiece),
        pinPullTestProductDto: pinPullTestProductDto,
        pinPullTestDeviceUsedDto: this.state.pinPullTestDeviceUsedDto,
      };
      var URLParam = "/pinPullTests/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "pin-cekme-testi-guncelle"
      ) {
        URLParam = "/pinPullTests/update";
        myObj = {
          guid: this.state.guid,
          workInstructionGuid: this.state.workInstructionGuid,
          detectionPersonelGuid: this.state.detectionPersonelGuid,
          pinPullTestDate: this.state.pinPullTestDate,
          serialNo: this.state.serialNo,
          testSerialNo: this.state.testSerialNo,
          dispatchPiece: this.state.dispatchPiece === "" ? 0 : parseFloat(this.state.dispatchPiece),
          pinPullTestProductDto: pinPullTestProductDto,
          pinPullTestDeviceUsedDto: this.state.pinPullTestDeviceUsedDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.urlSplit[1] === "pin-cekme-testi-ekle") {
              window.location.reload();
            }
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "pin-cekme-testi-guncelle"
            ) {
              window.location.href = "/pin-cekme-testi-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "pin-cekme-testi-ekle" ||
          this.state.urlSplit[1] === "pin-cekme-testi-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="4">
                    <CgnDatePicker
                      name="pinPullTestDate"
                      label="Tarih [*]"
                      selected={
                        this.state.pinPullTestDate &&
                        this.state.pinPullTestDate
                          .toString()
                          .indexOf("/") > 0
                          ? new Date(
                              this.state.pinPullTestDate.split("/")[2] +
                                "-" +
                                this.state.pinPullTestDate.split("/")[1] +
                                "-" +
                                this.state.pinPullTestDate.split("/")[0]
                            )
                          : this.state.pinPullTestDate
                      }
                      onChange={(pinPullTestDate) =>
                        this.handlePinPullTestDateChange(
                          pinPullTestDate
                        )
                      }
                      error={this.state.pinPullTestDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="workInstructionCode"
                      label="İş Emri Numarası [*]"
                      value={this.state.workInstructionCode}
                      placeHolder="Lütfen iş emri no girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.workInstructionCodeError}
                      readOnly={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      placeholder="Lütfen iş emri girin..."
                      selectValue={this.state.workInstructionGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleWorkInstructionSelectChange(option)
                      }
                      options={this.state.workInstructionArr}
                      error={this.state.workInstructionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="personelGuid"
                      label="Personel [*]"
                      placeholder="Personel seçiniz..."
                      selectValue={this.state.personelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePersonelSelectChange(option)
                      }
                      options={this.state.personelArr}
                      error={this.state.personelError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri [*]"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      options={this.state.customerArr}
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="qualityPersonelGuid"
                      label="Kalite Personel [*]"
                      placeholder="Kalite Personeli seçiniz..."
                      selectValue={this.state.qualityPersonelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleQualityPersonelSelectChange(option)
                      }
                      options={this.state.qualityPersonelArr}
                      error={this.state.qualityPersonelError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="lastControlPersonelGuid"
                      label="Son Kontrolü Yapan Personel [*]"
                      placeholder="Son kontrolü yapan personeli seçiniz..."
                      selectValue={this.state.lastControlPersonelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleLastControlPersonelSelectChange(option)
                      }
                      options={this.state.lastControlPersonelArr}
                      error={this.state.lastControlPersonelError}
                      disabled={true}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="detectionPersonelGuid"
                      label="Tespiti Yapan Personel [*]"
                      placeholder="Tespiti yapan personeli seçiniz..."
                      selectValue={this.state.detectionPersonelGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleDetectionPersonelSelectChange(option)
                      }
                      options={this.state.detectionPersonelArr}
                      error={this.state.detectionPersonelError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      placeholder="Satış seçiniz..."
                      selectValue={this.state.productOutGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductSelectChange(option)
                      }
                      options={this.state.productOutArr}
                      error={this.state.customerError}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  
                  <Col lg="4">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Ürün seçiniz..."
                          selectValue={this.state.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productArr}
                          error={this.state.productError}
                          disabled={true}
                        />                  
                  </Col>
                  <Col lg="4">
                  <CgnTextbox
                      type="number"
                      name="dispatchPiece"
                      label="Sevk Edilecek Ürün Miktarı"
                      value={this.state.dispatchPiece}
                      placeHolder="Lütfen sevk edilecek ürün miktarı girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleDispatchPieceTextBoxChange(e)}
                      step="0.1"
                    />
                  </Col>
                  <Col lg="4">
                  <CgnTextbox
                      type="text"
                      name="serialNo"
                      label="Seri Numarası"
                      value={this.state.serialNo}
                      placeHolder="Lütfen seri numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="testSerialNo"
                      label="Test Edilen Cihazlar Seri No"
                      value={this.state.testSerialNo}
                      placeHolder="Lütfen test edilen cihazlar seri no girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                {this.state.pinPullTestDeviceUsedDto.map(
                  (pinPullTestDeviceUsedDto, idx) => (
                    <Row>
                      <Col lg="11" className="pl-0">
                      <CgnReactSelect
                          name="deviceUsedGuid"
                          label="Kullanılan Cihaz [*]"
                          placeholder="Kullanılan cihaz seçiniz..."
                          selectValue={pinPullTestDeviceUsedDto.deviceUsedGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleDeviceUsedSelectChange(option, idx)
                          }
                          options={this.state.deviceUsedArr}
                          error={pinPullTestDeviceUsedDto.deviceUsedError}
                        />       
                      </Col>                   
                      <Col lg="1">
                        <CgnButton
                          className="dynamicRowDeleteButton"
                          type="button"
                          color="danger"
                          onClick={() => this.handleDeviceUsedRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  )
                )}

                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-2 dynamicRowAddButton"
                      onClick={this.handleDeviceUsedAdd}
                      text={"Kullanılan Cihaz Ekle"}
                    />
                    {this.state.pinPullTestDeviceUsedError && (
                      <div className="invalid-feedback d-block">
                        {this.state.pinPullTestDeviceUsedError}
                      </div>
                    )}
                  </Col>
                </Row>

                {this.state.pinPullTestProductDto.map(
                  (pinPullTestProductDto, idx) => (
                    <>
                     <Row>
                      <Col className="pl-0">
                      <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Ürün seçiniz..."
                          selectValue={pinPullTestProductDto.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePinProductSelectChange(option, idx)
                          }
                          options={this.state.productArr}
                          error={pinPullTestProductDto.productError}
                        />       
                      </Col>        
                      <Col>
                      <CgnReactSelect
                          name="materialGuid"
                          label="El Aleti [*]"
                          placeholder="El Aleti seçiniz..."
                          selectValue={pinPullTestProductDto.materialGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePinMaterialSelectChange(option, idx)
                          }
                          options={this.state.materialArr}
                          error={pinPullTestProductDto.materialError}
                        />       
                      </Col>    
                      <Col>
                      <CgnReactSelect
                          name="product2Guid"
                          label="Ürün [*]"
                          placeholder="Ürün seçiniz..."
                          selectValue={pinPullTestProductDto.product2Guid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePin2ProductSelectChange(option, idx)
                          }
                          options={this.state.productArr2}
                        />       
                      </Col>        
                      <Col>
                      <CgnReactSelect
                          name="material2Guid"
                          label="El Aleti [*]"
                          placeholder="El Aleti seçiniz..."
                          selectValue={pinPullTestProductDto.material2Guid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePin2MaterialSelectChange(option, idx)
                          }
                          options={this.state.materialArr2}
                          error={pinPullTestProductDto.materialError}
                        />       
                      </Col>   
                    <Col>
                    <CgnTextbox
                      type="number"
                      name="measuredValue"
                      label="Ölçülen Değer"
                      value={pinPullTestProductDto.measuredValue}
                      placeHolder="Lütfen ölçülen değer girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleMeasuredValueTextBoxChange(e, idx)}
                      step="0.1"
                    />                            
                      </Col>
                      <Col>
                    <CgnTextbox
                      type="number"
                      name="minValue"
                      label="Olması Gerek Min. Değer"
                      value={pinPullTestProductDto.minValue}
                      placeHolder="Lütfen min değer girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleMinValueTextBoxChange(e, idx)}
                      step="0.1"
                    />                            
                      </Col>
                      <Col>
                      <CgnReactSelect
                          name="resultChange"
                          label="Sonuç [*]"
                          placeholder="Sonuç seçiniz..."
                          selectValue={pinPullTestProductDto.resultChange}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleResultStatusChange(option, idx)
                          }
                          options={manuelControlStatus}
                          error={pinPullTestProductDto.resultChangeError}
                        />       
                      </Col>   
                    </Row>
                    <Row>
                      <Col lg="11" className="pl-0">
                      <CgnTextbox
                      type="text"
                      name="description"
                      label="Açıklama"
                      value={pinPullTestProductDto.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange2(idx)}
                    />
                      </Col>
                    <Col lg="1">
                        <CgnButton
                          className="dynamicRowDeleteButton"
                          type="button"
                          color="danger"
                          onClick={() => this.handleProductRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>                               
                    </Row>
                    </>

                  )
                )}

                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-2 dynamicRowAddButton"
                      onClick={this.handleProductAdd}
                      text={"İtme Testi Ekle"}
                    />
                    {this.state.pinPullTestProductError && (
                      <div className="invalid-feedback d-block">
                        {this.state.pinPullTestProductError}
                      </div>
                    )}
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "pin-cekme-testi-ekle" ||
              this.state.urlSplit[1] === "pin-cekme-testi-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(PinPullTestAddUpdate);
