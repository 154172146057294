import React, { Component } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/tr";
import axios from "axios";
import {  URL, Mounth, Year } from "../../redux/actions/constants";
import ContentTop from "../root/ContentTop";
import CgnLoader from "../toolbox/CgnLoader";
import { Row, Col, Card, Button, Table } from "reactstrap";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnMessage from "../toolbox/CgnMessage";
import CgnBadge from "../toolbox/CgnBadge";

class CalendarListUser extends Component {
  state = {
    pageTitle: "Takvim Kullanıcı",
    pageListLink: "takvim-kullanici",
    pageCostUpdateLink: "gider-guncelle",
    pageCostDetailLink: "gider-detay",
    pageIncomeUpdateLink: "gelir-guncelle",
    pageIncomeDetailLink: "gelir-detay",
    pageCollectionUpdateLink: "tahsilat-guncelle",
    pageCollectionCalendarListLink: "tahsilat-takvimi-listele",
    pagePaymentCalendarListLink: "odeme-takvimi-listele",
    pageRemindUpdateLink: "hatirlatma-guncelle",
    pageRemindDetailLink: "hatirlatma-detay",
    pageOfferUpdateLink: "teklif-guncelle",
    pageOfferDetailLink: "teklif-detay",
    pageServiceUpdateLink: "servis-guncelle",
    pageServiceDetailLink: "servis-detay",
    pageAssignmentUpdateLink: "is-atama-guncelle",
    pageAssignmentDetailLink: "is-atama-detay",
    pageMachinesUpdateLink: "makine-servis-guncelle",
    pageMachinesDetailLink: "makine-servis-detay",
    pageAddMachinesLink: "makine-servis-ekle",
    pagePolicyUpdateLink: "police-guncelle",
    pagePolicyDetailLink: "police-detay",
    pageCuttingUpdateLink: "kesim-islemi-guncelle",
    pageCuttingDetailLink: "kesim-islemi-detay",
    breadcrumb: [{ text: "Takvim Kullanıcı", link: "#" }],
    isLoading: false,
    status: false,
    calendarData: [],
    selectedMonth: 0,
    selectedMonthValue: 0,
    selectedYear: 0,
    selectedYearValue: 0,
    backgroundColor: "",
    alertErrorMessage: "",
    dayHeaderFormat: "DD MM YYYY",
    view: {
      day: "Gün",
      week: "Hafta",
      month: "Ay",
    },
    isOpenModalBank: false,
  };

  async componentDidMount() {
    this.getList();
  }

  async getList() {
    this.setState({
      isLoading: true,
    });

    if (this.state.selectedMonth === 0 && this.state.selectedYear === 0) {
      this.setState({
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
      });
    }

    if (localStorage.getItem("selectedMonthCalendarUser")) {
      await this.setState({
        selectedMonth: parseInt(
          localStorage.getItem("selectedMonthCalendarUser")
        ),
      });
    }
    if (localStorage.getItem("selectedYearCalendarUser")) {
      await this.setState({
        selectedYear: parseInt(
          localStorage.getItem("selectedYearCalendarUser")
        ),
      });
    }

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var personGuid = localStorage.getItem("guid");
    await axios
      .get(
        URL +
          "/calendars/getlistuser/" +
          personGuid +
          "/" +
          this.state.selectedMonth +
          "/" +
          this.state.selectedYear,
        config
      )
      .then((response) => {
        this.setState({
          calendarData: response.data,
          isLoading: false,
        });

        // this.setState({
        //   selectedMonthValue: this.state.selectedMonth - 1,
        //   selectedYearValue: this.state.selectedYear,
        //   isLoading: false,
        // });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleMounthChange(option) {
    if (option) {
      await this.setState({
        selectedMonth: option.value,
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        selectedMonth: "",
      });
    }
    localStorage.setItem("selectedMonthCalendarUser", this.state.selectedMonth);
  }

  async handleYearChange(option) {
    if (option) {
      await this.setState({
        selectedYear: option.value,
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        selectedYear: "",
      });
    }
    localStorage.setItem("selectedYearCalendarUser", this.state.selectedYear);
  }

  addModalDay = () => {
    this.setState({
      isOpenModalBank: !this.state.isOpenModalBank,
    });
  };

  thStyle = {
    width: "75px",
  };

  tdStyle = {
    width: "350px",
  };

  tdStyle2 = {
    width: "100px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row>
          <Col>
            <Card body>
              <div className="monthFilter filterRow">
                <div className="row">
                  <div className="col-md-5">
                    <CgnReactSelect
                      name="selectedMounth"
                      className="selectedMounth"
                      label="Ay"
                      placeholder="Ay seçiniz..."
                      selectValue={this.state.selectedMonth}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleMounthChange(option)}
                      options={Mounth}
                      error={this.state.mounthError}
                    />
                  </div>
                  <div className="col-md-6">
                    <CgnReactSelect
                      name="selectedYear"
                      className="selectedYear"
                      label="Yıl"
                      placeholder="Yıl seçiniz..."
                      selectValue={this.state.selectedYear}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleYearChange(option)}
                      options={Year}
                      error={this.state.yearError}
                    />
                  </div>
                  <div className="col-md-1">
                    <Button
                      className="d-block mt-2 w-100 btnReport btn-primary"
                      onClick={(date) => this.getList(date)}
                    >
                      Listele
                    </Button>
                  </div>
                </div>
              </div>
              <CgnDatatable
                className="mt-3"
                data={this.state.calendarData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Tarih",
                    selector: "date",
                    sortable: false,
                    width: "120px",
                    cell: (row) => {
                      return moment(row.date).format("DD/MM/YYYY");
                    },
                  },
                  {
                    name: "Ad",
                    selector: "name",
                    sortable: false,
                  },
                  {
                    selector: "status",
                    width: "150px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.type === 1) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#196F3D" }}
                            text="Gelir"
                          />
                        );
                      } else if (row.type === 2) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#27AE60" }}
                            text="Tahsilat"
                          />
                        );
                      } else if (row.type === 3) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#7DCEA0" }}
                            text="Tahsilat Takvimi"
                          />
                        );
                      } else if (row.type === 4) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#A93226" }}
                            text="Gider"
                          />
                        );
                      } else if (row.type === 5) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#E74C3C" }}
                            text="Ödeme"
                          />
                        );
                      } else if (row.type === 6) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#F1948A" }}
                            text="Ödeme Takvimi"
                          />
                        );
                      } else if (row.type === 7) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#1D4B8C" }}
                            text="Hatırlatma"
                          />
                        );
                      } else if (row.type === 8) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#D4AC0D" }}
                            text="Teklif"
                          />
                        );
                      } else if (row.type === 9) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#D2B4DE" }}
                            text="Doğum Günü"
                          />
                        );
                      } else if (row.type === 10) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#2196f3" }}
                            text="Servis"
                          />
                        );
                      } else if (row.type === 11) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#28A745" }}
                            text="İş Atama"
                          />
                        );
                      } else if (row.type === 12) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: row.color }}
                            text="Servis Formu"
                          />
                        );
                      } else if (row.type === 13) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: row.color }}
                            text={row.policyName}
                          />
                        );
                      } else if (row.type === 14) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: row.color }}
                            text="Kesim İşlemi"
                          />
                        );
                      } else if (row.type === 15) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: row.color }}
                            text="İş Emri"
                          />
                        );
                      } else if (row.type === 16) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: row.color }}
                            text="E-Mail Görev"
                          />
                        );
                      } else if (row.type === 17) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: row.color }}
                            text="Kargo Takip"
                          />
                        );
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => {
                      if (row.type === 1) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageIncomeUpdateLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageIncomeDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 3) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageCollectionCalendarListLink}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 4) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageCostUpdateLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageCostDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 6) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pagePaymentCalendarListLink}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 7) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageRemindUpdateLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageRemindDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 8) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageOfferUpdateLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageOfferDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 10) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageServiceDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (
                        row.type === 11 &&
                        row.machineServiceGuid !==
                          "00000000-0000-0000-0000-000000000000"
                      ) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageAssignmentDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (
                        row.type === 11 &&
                        row.machineServiceGuid ===
                          "00000000-0000-0000-0000-000000000000"
                      ) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageAssignmentDetailLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.machineServiceAddButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageAddMachinesLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 12) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageMachinesUpdateLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageMachinesDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 13) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pagePolicyUpdateLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pagePolicyDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 14) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageCuttingUpdateLink}/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageCuttingDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 16) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/personel-e-mail-gorev-detay/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 17) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              {
                                text: CgnMessage.updateButton,
                                className: "dropDownBtn",
                                href: `/kargo-takip-guncelle/${row.guid}`,
                              },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/kargo-takip-detay/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      }
                    },
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CalendarListUser;
