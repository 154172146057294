import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  iTypeStatusesOptions,
  iTypeStatuses,
  URL,
  
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  ProductList,
  CityListTurkiye,
  ServiceTypeList,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class ServiceListNext extends Component {
  state = {
    pageTitle: "Yaklaşan Servis Listele",
    pageAddLink: "servis-ekle",
    pageListLink: "yaklasan-servis-listele",
    breadcrumb: [{ text: "Servis", link: "#" }],
    isLoading: false,
    isDeleted: false,
    serviceData: [],
    alertErrorMessage: "",
    discountTotal: 0,
    discountTotalDolar: 0,
    discountTotalEuro: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    productGuid: "00000000-0000-0000-0000-000000000000",
    productArr: [],
    cityGuid: "00000000-0000-0000-0000-000000000000",
    cityArr: [],
    iType: "00000000-0000-0000-0000-000000000000",
    serviceTypeArr: [],
  };

  async componentDidMount() {
    await this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateServiceNext")) {
      firstDate = localStorage.getItem("firstDateServiceNext");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateServiceNext")) {
      lastDate = localStorage.getItem("lastDateServiceNext");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    if (localStorage.getItem("iTypeServiceNext")) {
      this.setState({
        iType: parseInt(localStorage.getItem("iTypeServiceNext")),
      });
    }

    await ProductList().then(function (data) {
      self.setState({
        productArr: data,
      });
    });

    if (localStorage.getItem("productGuidServiceNext")) {
      this.setState({
        productGuid: localStorage.getItem("productGuidServiceNext"),
      });
    }

    await CityListTurkiye().then(function (data) {
      self.setState({
        cityArr: data,
      });
    });

    if (localStorage.getItem("cityGuidServiceNext")) {
      this.setState({
        cityGuid: localStorage.getItem("cityGuidServiceNext"),
      });
    }

    await ServiceTypeList().then(function (data) {
      self.setState({
        serviceTypeArr: data,
      });
    });

    if (localStorage.getItem("iTypeService")) {
      this.setState({
        iType: localStorage.getItem("iTypeService"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateServiceNext", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateServiceNext", this.state.lastDate);
  }

  async handleITypeSelectChange(option) {
    if (option) {
      await this.setState({
        iType: option.value,
      });
      localStorage.setItem("iTypeServiceNext", this.state.iType);
    } else {
      await this.setState({
        iType: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  async handleCitySelectChange(option) {
    if (option) {
      await this.setState({
        cityGuid: option.value,
      });
    } else {
      await this.setState({
        cityGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("cityGuidServiceNext", this.state.cityGuid);
  }

  async handleProductSelectChange(option) {
    if (option) {
      await this.setState({
        productGuid: option.value,
      });
    } else {
      await this.setState({
        productGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("productGuidServiceNext", this.state.productGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .get(
        URL +
          "/services/getlistnext/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate +
          "/" +
          this.state.iType +
          "/" +
          this.state.productGuid +
          "/" +
          this.state.cityGuid,
        config
      )
      .then((response) => {
        var discountTotal = 0;
        var discountTotalDolar = 0;
        var discountTotalEuro = 0;
        response.data.forEach((element) => {
          if (element.currencyType === 1) {
            discountTotal += element.discountTotal;
          } else if (element.currencyType === 2) {
            discountTotalDolar += element.discountTotal;
          } else if (element.currencyType === 3) {
            discountTotalEuro += element.discountTotal;
          }
        });
        this.setState({
          discountTotal: discountTotal,
          discountTotalDolar: discountTotalDolar,
          discountTotalEuro: discountTotalEuro,
          serviceData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "160px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "200px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="serviceList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="cityGuid"
                    label="Şehir"
                    placeholder="Şehir seçiniz..."
                    selectValue={this.state.cityGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) => this.handleCitySelectChange(option)}
                    options={this.state.cityArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="iType"
                    label="Servis Türü"
                    placeholder="Servis türü seçiniz..."
                    isMulti={false}
                    isClearable={true}
                    selectValue={this.state.iType}
                    options={this.state.serviceTypeArr}
                    onChange={(iType) => this.handleITypeSelectChange(iType)}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="3">
                  <CgnReactSelect
                    name="productGuid"
                    label="Ürün"
                    placeholder="Ürün seçiniz..."
                    selectValue={this.state.productGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleProductSelectChange(option)
                    }
                    options={this.state.productArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.serviceData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Yaklaşan Servis Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Servis Türü</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.serviceTypeName}
                              </td>
                              <th style={this.thStyle2}>Müşteri</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={4}>{row.customerName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Son Bakım Tarihi</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.maintenanceDate}</td>
                              <th style={this.thStyle2}>Kategori</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.categoryName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>
                                Bir Sonraki Bakım Tarihi
                              </th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.nextMaintenanceDate}</td>
                              <th style={this.thStyle2}>Ürün</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.productName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Personel</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.employeeName}</td>
                              <th style={this.thStyle2}>Şehir</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.cityName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Toplam Tutar</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.discountTotal.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (₺):{" "}
                {this.state.discountTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.discountTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.discountTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ServiceListNext;
