import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as companyUserGroupListAction from "../../redux/actions/companyUserGroups/companyUserGroupListAction";
import { Row, Col, Card } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";

class CompanyUserGroupList extends Component {
  state = {
    pageTitle: "Firma Personel Grup Listele",
    pageAddLink: "firma-personel-grup-ekle",
    pageUpdateLink: "firma-personel-grup-guncelle",
    pageDetailLink: "firma-personel-grup-detay",
    pageListLink: "firma-personel-grup-listele",
    breadcrumb: [{ text: "Firma Personel Grupları", link: "#" }],
    isLoading: false,
    isDeleted: false,
    alertErrorMessage: "",
    companyUserGroupList: [],
    cell: 0,
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.props.actions.companyUserGroupList();
  }

  componentDidUpdate() {
    if (
      this.props.companyUserGroupList.status &&
      this.state.companyUserGroupList !==
      this.props.companyUserGroupList.data &&
      this.props.companyUserGroupList.data &&
      this.props.companyUserGroupList.status === 200
    ) {
      this.setState({
        isLoading: false,
        companyUserGroupList: this.props.companyUserGroupList.data,
      });
    }

    if (
      this.props.companyUserGroupList.status &&
      this.props.companyUserGroupList.status !== 200 &&
      this.state.alertErrorMessage !== this.props.companyUserGroupList
    ) {
      this.setState({
        alertErrorMessage: this.props.companyUserGroupList.data,
        isLoading: false,
      });
    }
  }

  handleDelete = (row) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(row),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(row) {
    await this.setState({
      isDeleted: true,
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/groups/updategroupbygroupadmin";
    const obj = row;
    obj.isDeleted = this.state.isDeleted;
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="companyUserGroupList">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.companyUserGroupList}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Firma Personel Grubu",
                    selector: "name",
                    sortable: false,
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyUserGroupList: state.companyUserGroupListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      companyUserGroupList: bindActionCreators(
        companyUserGroupListAction.companyUserGroupList,
        dispatch
      ),
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyUserGroupList)
);
