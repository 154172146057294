import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  fotoStyle,
  fotoStyle2,
  localFilePath,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";

class QualityHistoryDetail extends Component {
  state = {
    pageTitle: "Kalite Geçmişi",
    pageTitleDetail: "Kalite Geçmişi",
    breadcrumb: [{ text: "Kalite Geçmişi", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    workDate: "",
    workStartDate: "",
    workEndDate: "",
    customerName: "",
    isImage: "",
    personelName: "",
    productOutName: "",
    productCode: "",
    productName: "",
    statusChange: "",
    piece: "",
    qualityImageDto: "",
    qualityData: [],
    errorCodeGuid: "",
    detectionPhase: "",
    errorDescription: "",
    errorReason: "",
    errorPiece: null,
    transactions: "",
    decision: "",
    dfDescription: "",
    improprietyNo: "",
    name: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    decsription: "",
    detectionPhaseError: "",
    currencyType: "",
    isOpen: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/qualities/getqualityhistory/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            qualityData: response.data,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath, "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };


  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  thStyle = {
    width: "175px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "400px",
  };

  render() {
    const mainRow = {
      backgroundColor: "#9B9796",
      color: "#fff",
    };

    var self = this.state;
    var self1 = this;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              {this.state.qualityData !== null &&
                this.state.qualityData.length > 0 && (
                  <>
                    {this.state.qualityData.map(function (item) {
                      return (
                        <>
                          <Table className="mb-3">
                            <tbody>
                              <tr>
                                <th style={self1.thStyle}>İş Emri Adımı</th>
                                <td style={self1.tdStyle}>
                                  {item.workInstructionStepName}
                                </td>
                                <th style={self1.thStyle2}>
                                  İşe Alınma Tarihi
                                </th>
                                <td>{item.employmentDate}</td>
                              </tr>
                              <tr>
                                <th style={self1.thStyle}>Tespit Aşaması</th>
                                <td style={self1.tdStyle}>
                                  {item.detectionPhase === 1
                                    ? "Ara Kontrol"
                                    : item.detectionPhase === 2
                                    ? "Son Kontrol"
                                    : item.detectionPhase === 3
                                    ? "Müşteri İadesi"
                                    : "-"}
                                </td>
                                <th style={self1.thStyle2}>Hata Kodu</th>
                                <td>
                                  {item.errorCodeName !== null
                                    ? item.errorCodeName
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={self1.thStyle}>Hata Tanımı</th>
                                <td colSpan={4}>
                                  {item.errorDescription !== null
                                    ? item.errorDescription
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="text-danger">
                                <th style={self1.thStyle}>Hata Adet</th>
                                <td colSpan={4}>
                                  {item.errorPiece !== null
                                    ? item.errorPiece
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={self1.thStyle}>Hata Nedeni</th>
                                <td colSpan={4}>
                                  {item.errorReason === 1
                                    ? "İşçilik"
                                    : item.errorReason === 2
                                    ? "Makine-Ekipman"
                                    : item.errorReason === 3
                                    ? "Malzeme"
                                    : item.errorReason === 4
                                    ? "Diğer"
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={self1.thStyle}>Yapılan İşlemler</th>
                                <td colSpan={4}>
                                  {item.transactions !== null
                                    ? item.transactions
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={self1.thStyle}>Karar</th>
                                <td style={self1.tdStyle}>
                                  {item.decision === 1
                                    ? "Olduğu Gibi Kullan"
                                    : item.decision === 2
                                    ? "Şartlı Kabul"
                                    : item.decision === 3
                                    ? "Kabul"
                                    : item.decision === 4
                                    ? "Uygun Olmayan Durum"
                                    : "-"}
                                </td>
                                <th style={self1.thStyle2}>Df Açılması</th>
                                <td>
                                  {item.dfDescription === 1
                                    ? "Df Açılması Uygundur"
                                    : item.dfDescription === 2
                                    ? "Df Açılmasına Gerek Yoktur"
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={self1.thStyle}>
                                  Uygunsuzluk Sıra No
                                </th>
                                <td colSpan={4}>{item.improprietyNo === null ? "-" : item.improprietyNo}</td>
                              </tr>
                              <tr>
                                <th>Hata Fotoğrafı</th>
                                <td colSpan="4">
                                  {item.image ? (
                                    <Button
                                    onClick={() =>
                                      self1.toggle(item.image)
                                    }
                                      className="modalButtonDetail"
                                    >
                                      <img
                                        src={item.image}
                                        style={fotoStyle}
                                        alt={item.errorCodeName}
                                      />
                                    </Button>
                                  ) : (
                                    <img
                                      src={noImg}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  )}
                                </td>
                              </tr>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </tbody>
                          </Table>
                          
                          {item.qualityImageDto &&
                                item.qualityImageDto.length > 0 && (
                                  <Table className="table table-responsive table-hover table-product2 mb-3">
                                    <tr style={mainRow}>
                                      <th>Test Cihazı Raporları</th>
                                    </tr>
                                    <Row>
                                      {item.qualityImageDto.map(function (
                                        item
                                      ) {
                                        return (
                                          <>
                                            <div className="pl-2">
                                              <Button
                                                onClick={() =>
                                                  self1.fileClick(item.image)
                                                }
                                                className="modalButtonDetail"
                                              >
                                                <img
                                                  src={pdfIcon}
                                                  style={fotoStyle2}
                                                  alt={item.imageName}
                                                />
                                              </Button>
                                              <p style={{ maxWidth: "150px" }}>
                                                {item.imageName}
                                              </p>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </Row>
                                  </Table>
                                )}
                        </>
                      );
                    })}
                  </>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default QualityHistoryDetail;
