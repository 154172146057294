import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { localFilePath, URL,  CustomerList, MembershipList, ProductList, ProductOutList2, WorkInstructionList, entryTypes} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class LocationMaterialDeliveryList extends Component {
  state = {
    pageTitle: "Depo El Aleti İşlem Listele",
    pageAddLink: "depo-el-aleti-islemi-ekle",
    pageUpdateLink: "depo-el-aleti-islemi-guncelle",
    pageDetailLink: "depo-el-aleti-islemi-detay",
    pageListLink: "depo-el-aleti-islemi-listele",
    breadcrumb: [{ text: "Depo El Aleti İşlemi", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    name: "",
    locationName: "",
    workInstructionCode: "",
    guid: "",
    entryDate: "",
    entryHour: "",
    workInstructionDate: "",
    workInstructionName: "",
    customerName: "",
    personelName: "",
    workEndDate: "",
    workStartDate: "",
    productOutName: "",
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    personelGuid: "00000000-0000-0000-0000-000000000000",
    personelArr: [],
    productOutGuid: "00000000-0000-0000-0000-000000000000",
    productOutArr: [],
    productGuid: "00000000-0000-0000-0000-000000000000",
    productArr: [],
    workInstructionGuid: "00000000-0000-0000-0000-000000000000",
    workInstructionArr: [],
    entryType: 0,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // // 

    // var firstDate = GetMonthFirstDate();
    // var lastDate = GetMonthLastDate();

    // if (localStorage.getItem("firstDateWorkInstruction")) {
    //   firstDate = localStorage.getItem("firstDateWorkInstruction");
    // }
    // if (localStorage.getItem("lastDateWorkInstruction")) {
    //   lastDate = localStorage.getItem("lastDateWorkInstruction");
    // }

    // await this.setState({
    //   firstDate: firstDate,
    //   firstDate2: firstDate,
    //   lastDate: lastDate,
    //   lastDate2: lastDate,
    // });

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidLocationMaterialDelivery")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidLocationMaterialDelivery"),
      });
    }

    await MembershipList().then(function (data) {
      self.setState({
        personelArr: data,
      });
    });

    if (localStorage.getItem("personelGuidLocationMaterialDelivery")) {
      this.setState({
        personelGuid: localStorage.getItem("personelGuidLocationMaterialDelivery"),
      });
    }

    await ProductList().then(function (data) {
      self.setState({
        productArr: data,
      });
    });

    await ProductOutList2().then(function (data) {
      self.setState({
        productOutArr: data,
      });
    });

    await WorkInstructionList().then(function (data) {
      self.setState({
        workInstructionArr: data,
      });
    });

    this.getList();
  }
  
  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      //var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem("firstDateLocationMaterialDelivery", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      //var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate:  "",
        lastDate2: "",
      });
    }
    localStorage.setItem("lastDateLocationMaterialDelivery", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidLocationMaterialDelivery", this.state.customerGuid);
  }

  async handlePersonelSelectChange(option) {
    if (option) {
      await this.setState({
        personelGuid: option.value,
      });
    } else {
      await this.setState({
        personelGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("personelGuidLocationMaterialDelivery", this.state.personelGuid);
  }

  async handleWorkInstructionSelectChange(option) {
    if (option) {
      await this.setState({
        workInstructionGuid: option.value,
      });
    } else {
      await this.setState({
        workInstructionGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("workInstructionGuidLocationMaterialDelivery", this.state.workInstructionGuid);
  }

  async handleProductOutSelectChange(option) {
    if (option) {
      await this.setState({
        productOutGuid: option.value,
      });
    } else {
      await this.setState({
        productOutGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("productOutGuidLocationMaterialDelivery", this.state.productOutGuid);
  }

  async handleEntryTypeSelectChange(option){
    if(option){
      await this.setState({
        entryType: option.value
      })
    }
    else{
      await this.setState({
        entryType: 0
      })
    }

  }

  async handleProductSelectChange(option) {
    if (option) {
      await this.setState({
        productGuid: option.value,
      });
    } else {
      await this.setState({
        productGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("productGuidLocationMaterialDelivery", this.state.productGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    URLParam =
      "/locationMaterialDeliveries/getlist/" +
      this.state.entryType + 
      "/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
      "/" +
      this.state.workInstructionGuid +
      "/" +
      this.state.customerGuid +
      "/" +
      this.state.personelGuid +
      "/" +
      this.state.productOutGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {       
          this.setState({
            workInstructionData: response.data,
            isLoading: false,
          });              
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };


  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/locationMaterialDeliveries/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleServicePrint = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var statusType = 1;
    URLParam =
      "/locationMaterialDeliveries/print/" +
      this.state.entryType + 
      "/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
      "/" +
      this.state.workInstructionGuid +
      "/" +
      this.state.customerGuid +
      "/" +
      this.state.personelGuid +
      "/" +
      this.state.productOutGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };



  thStyle = {
    width: "75px",
  };

  thStyle2 = {
    width: "75px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "250px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          excelLink={onClick => this.handleServicePrint()}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="workInstructionGuid"
                    label="İş Emri"
                    placeholder="İş Emri seçiniz..."
                    selectValue={this.state.workInstructionGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleWorkInstructionSelectChange(option)
                    }
                    options={this.state.workInstructionArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="personelGuid"
                    label="Personel"
                    placeholder="Personel seçiniz..."
                    selectValue={this.state.personelGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handlePersonelSelectChange(option)
                    }
                    options={this.state.personelArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="productOutGuid"
                    label="Satış"
                    placeholder="Satış seçiniz..."
                    selectValue={this.state.productOutGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleProductOutSelectChange(option)
                    }
                    options={this.state.productOutArr}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="entryType"
                    label="Durum"
                    placeholder="Durum seçiniz..."
                    selectValue={this.state.entryType}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleEntryTypeSelectChange(option)
                    }
                    options={entryTypes}
                  />
                </Col>
                <Col className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.workInstructionData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Depo El Aleti İşlemi Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                            <th style={this.thStyle}>Tipi</th>
                              <td colSpan={4} style={this.tdStyle}>{row.entryType === 1 ? "Giriş" :
                              row.entryType === 2 ? "Teslim" : 
                              row.entryType === 3 ? "İade" : ""}</td>
                            </tr>

                            <tr style={{display: row.entryType === 3 ? "none" : ""}}>
                            <th style={this.thStyle}>Teslim Tarihi</th>
                            <td style={this.tdStyle}>{row.entryDate}</td>
                            <th style={this.thStyle2}>Teslim Saati</th>
                            <td style={this.tdStyle2}>{row.entryHour}</td>
                            </tr>

                            <tr style={{display: row.entryType === 3 ? "" : "none"}}>
                            <th style={this.thStyle}>İade Tarihi</th>
                            <td style={this.tdStyle}>{row.entryDate}</td>
                            <th style={this.thStyle2}>İade Saati</th>
                            <td style={this.tdStyle2}>{row.entryHour}</td>
                            </tr>

                            <tr>
                            <th style={this.thStyle}>İş Emri</th>
                              <td style={this.tdStyle}>{row.productName + "-" + row.customerName}</td>
                              <th style={this.thStyle2}>Satış</th>
                              <td style={this.tdStyle2}>{row.productOutDate + "-" + row.productOutCustomer}</td>
                            </tr>
                           
                            <tr>
                              <th style={this.thStyle}>İş Emri Tarihi</th>
                              <td style={this.tdStyle}>{row.workInstructionDate}</td>
                              <th style={this.thStyle2}>
                                İş Emri Numarası
                              </th>
                              <td style={this.tdStyle2}>{row.workInstructionCode}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td style={this.tdStyle}>{row.customerName}</td>
                              <th style={this.thStyle2}>
                                Personel
                              </th>
                              <td style={this.tdStyle2}>{row.personelName}</td>
                            </tr>

                            <tr>
                              <th style={this.thStyle}>Başlangıç Tarihi</th>
                              <td style={this.tdStyle}>{row.workStartDate}</td>
                              <th style={this.thStyle2}>Planlı Bitiş Tarihi</th>
                              <td style={this.tdStyle2}>{row.workEndDate === "01/01/1900" ? "-" : row.workEndDate}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td colSpan={4} style={this.tdStyle}>{row.createdBy}</td>
                            </tr>                       
                          </tbody>
                        </Table>
                      );
                    },
                  },    
                  {
                    selector: "status",
                    width: "120px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.subtractDate !== "") {
                        return (
                          <p className="mt-2">
                          {row.subtractDate} Gecikme
                          </p>
                        );
                      }
                      }
                    },
                  {
                    selector: "status",
                    width: "120px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.statusChange === 1) {
                        return (
                          <CgnBadge
                            color="success"
                            className="w-100"
                            text={'Giriş'}
                            style={{backgroundColor: "#8206D3"}}
                          />
                        );
                      } else if (row.statusChange === 2) {
                          return <CgnBadge color="danger" className="w-100" text={'Teslim Edildi'}  style={{backgroundColor: "#F57817"}}/>;
                        }
                        else if(row.statusChange === 3){
                          return <CgnBadge color="success" className="w-100" text={'İade Edildi'} />;
                        }
                        else if(row.statusChange === 4){
                          return <CgnBadge color="danger" className="w-100" text={'İade Edilmedi'} />;
                         }
                       
                      }
                    },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: "Güncelle" ,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Sil",
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default LocationMaterialDeliveryList;
