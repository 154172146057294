import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import LocationAddUpdate from "../location/LocationAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";

class ProductCountAddUpdate extends Component {
  state = {
    pageTitle: "Sayım Ekle",
    pageTitleAdd: "Sayım Ekle",
    pageTitleUpdate: "Sayım Güncelle",
    pageAddLink: "sayim-ekle",
    pageListLink: "sayim-listele",
    breadcrumb: [{ text: "Sayım", link: "#" }],
    isLoading: false,
    urlSplit: [],
    guid: "",
    locationGuid: null,
    productDataError: "",
    productGuid: null,
    status: true,
    locationError: "",
    alertErrorMessage: "",
    transfer: "",
    isDeleted: false,
    isOpenModalProduct: false,
    modalButtonIdx: 0,
    isNull: false,
    isOpenModalLocation: false,
    controlProduct: false,
    counter: -1,
    productData: [],
    productError: "",
    productCountModal: false,
    productPieceError: "",
    multipleProductArr: [],
    locationArr: [],
    productArr: [],
    productArr2: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    locationModalPlus: false,
    productModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      locationModalPlus: await UserOperationClaimControl(1055),
      productModalPlus: await UserOperationClaimControl(1029),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            locationGuid: item.locationGuid,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    if (urlSplit.length === 3 && urlSplit[1] === "sayim-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/productCount/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              locationGuid: response.data.locationGuid,
              productData: response.data.productCountProductDto,
              transfer: response.data.transfer,
              status: response.data.status,
              isLoading: false,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        locationGuid: "",
        productData: [],
        status: true,
        transfer: "",
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data
            ) {
              this.setState({
                locationGuid: element.guid,
                locationError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
              productData[this.state.modalButtonIdx].piece = 1;
              productData[this.state.modalButtonIdx].productUnitPriceError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      locationError: "",
      alertErrorMessage: "",
    });
    if (name === "locationGuid" && value === "") {
      this.setState({
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalLocation = () => {
    this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productCountModal: !this.state.productCountModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleProductAdd(item.value);
        }
      });
    });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.piece = "";

      item.productError = CgnMessage.textErrorMessage;
      item.productPieceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      locationGuid: "",
      counter: -1,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: null,
      piece: 0,
      status: true,
      productError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      alertErrorMessage: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].productGuid === "") {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].status = true;
      this.setState({
        productData: productData,
        alertErrorMessage: "",
      });
    } else {
      productData[idx].productGuid = null;
      //productData[idx].piece = 0;
      productData[idx].productError = CgnMessage.textErrorMessage;
      //productData[idx].productPieceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
  }

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (!item.productGuid && item.piece) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (item.productGuid && !item.piece) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (item.productGuid && item.piece) {
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (!item.productGuid && !item.piece) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (!item.productGuid && item.piece) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (item.productGuid && !item.piece) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (item.productGuid && item.piece) {
        item.productError = "";
        item.productPieceError = "";
        this.setState({
          alertErrorMessage: "",
        });
      }
      if (!item.productGuid && !item.piece) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
  };

  async handleProductRemove(idx) {
    let someArray = this.state.productData;
    someArray.splice(idx, 1);
    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        productError: "",
        total: 0,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      locationError: "",
      productDataError: "",
      alertErrorMessage: "",
      productError: "",
    });
    if (this.state.locationGuid === null) {
      this.setState({
        locationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var rowControl = true;
    this.state.productData.forEach((element) => {
      if (element.productGuid === null) {
        rowControl = false;
      }
    });

    if (rowControl === false) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.locationGuid !== null &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      rowControl
    ) {
      this.setState({
        isLoading: true,
      });
      var statusType = 2;
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
     
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          productGuid: element.productGuid,
          piece: element.piece,
          status: true,
        });
      });

      if (this.state.productArr2 != null && this.state.productArr2.length > 0) {
        this.state.productArr2.forEach((element2) => {
          var lVarmi = false;
          this.state.productData.forEach((element1) => {
            if (element2.productGuid === element1.productGuid) {
              lVarmi = true;
            }
          });

          if (lVarmi === false) {
            this.state.controlProduct = true;
            productData.push({
              guid: element2.guid,
              productGuid: element2.productGuid,
              piece: 0,
              status: true,
            });
          }
        });
        this.setState({
          productData: productData,
        });
      }

      if (this.state.controlProduct === false) {
        // 
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };

        var myObj = {
          locationGuid: this.state.locationGuid,
          transfer: 0,
          status: true, //this.state.status,
          productCountProductDto: productData,
        };
        var URLParam = "/productCount/add";
        if (this.state.guid && this.state.urlSplit.length !== 2) {
          URLParam = "/productCount/update";
          myObj = {
            guid: this.state.guid,
            locationGuid: this.state.locationGuid,
            status: true, //this.state.status,
            productCountProductDto: productData,
          };
        }
        axios
          .post(URL + URLParam, myObj, config)
          .then(() => {
            setTimeout(() => {
              if (this.state.guid) {
                window.location.href = "/sayim-listele";
              } else {
                window.location.reload();
              }
            }, 1500);
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
              isLoading: false,
            });
          });
      } else {
        this.setState({
          isLoading: false,
          controlProduct: false,
        });
      }
    } 
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productCountAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="12" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="locationGuid"
                          label="Depo [*]"
                          placeholder="Depo seçiniz..."
                          selectValue={this.state.locationGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleLocationSelectChange(option)
                          }
                          options={this.state.locationArr}
                          error={this.state.locationError}
                        />
                      </div>
                      {this.state.locationModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalLocation()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {this.state.productData.map((productData, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="6" className="modalSelectCol">
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="productGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={productData.productGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={productData.productError}
                          />
                        </div>
                        {this.state.productModalPlus && (
                          <div className="p-0 flexAddButton">
                            <CgnButton
                              type="button"
                              color="secondary"
                              className="modalSelectAddButton"
                              onClick={() => this.addModalProduct(idx)}
                              text={"+"}
                            />
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col lg="5">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={productData.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productData.productPieceError}
                      />
                    </Col>
                    <Col lg="1" sm="3">
                      <CgnButton
                        type="button"
                        color="danger"
                        className="dynamicRowDeleteButton"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  {/* {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )} */}
                </Row>
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductCountAddUpdate;
