import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Modal, ModalHeader } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL,  fotoStyle } from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";

class MachineStockRemoveDetail extends Component {
  state = {
    pageTitle: "Depolardan Çıkarılan Ürünler Detay",
    pageListLink: "depolardan-cikarilan-urunler",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    guid: "",
    dateTime: "",
    locationGuid: "",
    locationName: "",
    machineProductGuid: "",
    machineProductName: "",
    piece: "",
    description: "",
    status: "",
    machineId: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    isOpen: false,
    image: "",
    name: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/machinestockremove/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            dateTime: response.data.dateTime,
            locationGuid: response.data.locationGuid,
            locationName: response.data.locationName,
            machineProductGuid: response.data.machineProductGuid,
            machineProductImage:
              response.data.machineProductImage !== ""
                ? response.data.machineProductImage
                : "",
            machineProductName: response.data.machineProductName,
            piece: response.data.piece,
            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };


  thStyle = {
    width: "auto",
  };

  thStyle2 = {
    width: "125px",
    textAlign: "center",
  };

  thStyle3 = {
    width: "125px",
  };

  tdStyle = {
    width: "auto",
    paddingLeft: "10px",
  };

  tdStyle2 = {
    width: "125px",
    textAlign: "center",
  };

  tdStyle3 = {
    width: "125px",
    paddingLeft: "10px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <td colSpan="4">
                      {this.state.dateTime ? this.state.dateTime : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">
                      {this.state.locationName ? this.state.locationName : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.machineProductImage ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.machineProductImage}
                            style={fotoStyle}
                            alt={this.state.machineProductName}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.machineProductName}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal
                    className="modal-xs"
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                  >
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.image}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal>
                  <tr>
                    <th>Ürün</th>
                    <td colSpan="4">
                      {this.state.machineProductName
                        ? this.state.machineProductName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Adet</th>
                    <td colSpan="4">
                      {this.state.piece ? this.state.piece : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Depodan Çıkarma Sebebi</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineStockRemoveDetail;
