import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  IsRisk,
  textAreaStyle,
  Gender,
  
} from "../../redux/actions/constants";
import { CgnEMailControl } from "../toolbox/CgnEMailControl";
import { CgnPhoneControl } from "../toolbox/CgnPhoneControl";
import { CgnTaxNoControl } from "../toolbox/CgnTaxNoControl";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

class SupplierAddUpdate extends Component {
  state = {
    pageTitle: "Tedarikçi Ekle",
    pageTitleAdd: "Tedarikçi Ekle",
    pageTitleUpdate: "Tedarikçi Güncelle",
    pageAddLink: "tedarikci-ekle",
    pageListLink: "tedarikci-listele",
    breadcrumb: [{ text: "Tedarikçi", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    taxNo: "",
    commercialRegistrationNumber: "",
    mersisNumber: "",
    taxAdministration: "",
    sector: "",
    reference: "",
    isRisk: false,
    address: "",
    districtGuid: "00000000-0000-0000-0000-000000000000",
    townGuid: "00000000-0000-0000-0000-000000000000",
    cityGuid: "00000000-0000-0000-0000-000000000000",
    countryGuid: "00000000-0000-0000-0000-000000000000",
    supplierGroupGuid: "00000000-0000-0000-0000-000000000000",
    zipCode: "",
    fax: "",
    phone: "",
    eMail: "",
    webSite: "",
    description: "",
    connectionName: "",
    connectionDegree: "",
    connectionDepartment: "",
    connectionPhone: "",
    connectionEMail: "",
    connectionGender: null,
    connectionDescription: "",
    countryName: "",
    districtArr: [],
    townArr: [],
    cityArr: [],
    countryArr: [],
    guid: "",
    status: true,
    isCityTrue: true,
    isDistrictTrue: true,
    isTownTrue: true,
    labelIsTurkey: false,
    nameError: "",
    taxNoError: "",
    commercialRegistrationNumberError: "",
    mersisNumberError: "",
    taxAdministrationError: "",
    addressError: "",
    districtError: "",
    townError: "",
    cityError: "",
    countryError: "",
    phoneError: "",
    eMailError: "",
    faxError: "",
    connectionPhoneError: "",
    connectionEmailError: "",
    alertErrorMessage: "",
    supplierGroupError: "",
    supplierGroupArr: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
    .get(URL + "/suppliergroups/getlist/1/", config)
    .then((response) => {
      var myArr = response.data.map(function (item) {
        return {
          value: item.guid,
          label: item.name,
        };
      });
      this.setState({
        supplierGroupArr: myArr,
      });
    })
    .catch((error) => {
      this.setState({
        alertErrorMessage: error.response.data,
        isLoading: false,
      });
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(URL + "/countries/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          countryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/suppliers/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              name: response.data.name,
              taxNo: response.data.taxNo,
              commercialRegistrationNumber: response.data.commercialRegistrationNumber,
              mersisNumber: response.data.mersisNumber,
              supplierGroupGuid: response.data.supplierGroupGuid,
              taxAdministration: response.data.taxAdministration,
              sector: response.data.sector,
              reference: response.data.reference,
              isRisk: response.data.isRisk,
              address: response.data.address,
              districtGuid: response.data.districtGuid,
              townGuid: response.data.townGuid,
              cityGuid: response.data.cityGuid,
              countryGuid: response.data.countryGuid,
              zipCode: response.data.zipCode,
              fax: response.data.fax,
              phone: response.data.phone,
              eMail: response.data.eMail,
              webSite: response.data.website,
              description: response.data.description,
              connectionName: response.data.connectionName,
              connectionDegree: response.data.connectionDegree,
              connectionDepartment: response.data.connectionDepartment,
              connectionPhone: response.data.connectionPhone,
              connectionEMail: response.data.connectionEMail,
              connectionGender: response.data.connectionGender,
              connectionDescription: response.data.connectionDescription,
              countryName: response.data.countryName,
              status: response.data.status,
              isLoading: false,
            });
            if (this.state.cityGuid) {
              axios
                .get(URL + "/cities/getlist/" + this.state.countryGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    cityArr: myArr,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.townGuid) {
              axios
                .get(URL + "/towns/getlist/" + this.state.cityGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    townArr: myArr,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.districtGuid) {
              axios
                .get(URL + "/districts/getlist/" + this.state.townGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    districtArr: myArr,
                    isLoading: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.countryName === "TÜRKİYE") {
              this.setState({
                isCityTrue: false,
                isTownTrue: false,
                isDistrictTrue: false,
                labelIsTurkey: true,
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        taxNo: "",
        commercialRegistrationNumber: "",
        mersisNumber: "",
        taxAdministration: "",
        sector: "",
        reference: "",
        isRisk: false,
        address: "",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        townGuid: "00000000-0000-0000-0000-000000000000",
        cityGuid: "00000000-0000-0000-0000-000000000000",
        supplierGroupGuid: "00000000-0000-0000-0000-000000000000",
        countryGuid: "00000000-0000-0000-0000-000000000000",
        zipCode: "",
        fax: "",
        phone: "",
        eMail: "",
        webSite: "",
        description: "",
        connectionName: "",
        connectionDegree: "",
        connectionDepartment: "",
        connectionPhone: "",
        connectionEMail: "",
        connectionGender: null,
        connectionDescription: "",
        status: true,
        isCityTrue: true,
        isTownTrue: true,
        isDistrictTrue: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      taxNoError: "",
      commercialRegistrationNumberError: "",
      mersisNumberError: "",
      taxAdministrationError: "",
      addressError: "",
      districtError: "",
      townError: "",
      cityError: "",
      countryError: "",
      phoneError: "",
      eMailError: "",
      connectionPhoneError: "",
      connectionEmailError: "",
      faxError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      taxNo: "",
      commercialRegistrationNumber: "",
      mersisNumber: "",
      taxAdministration: "",
      sector: "",
      reference: "",
      isRisk: false,
      address: "",
      districtGuid: "00000000-0000-0000-0000-000000000000",
      townGuid: "00000000-0000-0000-0000-000000000000",
      cityGuid: "00000000-0000-0000-0000-000000000000",
      supplierGroupGuid: "00000000-0000-0000-0000-000000000000",
      countryGuid: "",
      zipCode: "",
      fax: "",
      phone: "",
      eMail: "",
      webSite: "",
      description: "",
      connectionName: "",
      connectionDegree: "",
      connectionDepartment: "",
      connectionPhone: "",
      connectionEMail: "",
      connectionGender: null,
      connectionDescription: "",
      status: true,
      isCityTrue: true,
      isTownTrue: true,
      isDistrictTrue: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      taxNoError: CgnMessage.textErrorMessage,
      commercialRegistrationNumberError: CgnMessage.textErrorMessage,
      mersisNumberError: CgnMessage.textErrorMessage,
      taxAdministrationError: CgnMessage.textErrorMessage,
      addressError: CgnMessage.textErrorMessage,
      eMailError: CgnMessage.textErrorMessage,
      phoneError: CgnMessage.textErrorMessage,
      countryError: CgnMessage.textErrorMessage,
    });
  }

  async handleIsRiskSelectChange(option) {
    if (option) {
      await this.setState({
        isRisk: option.value,
      });
    } else {
      await this.setState({
        isRisk: false,
      });
    }
  }

  async handleSupplierGroupSelectChange(option) {
    if (option) {
      this.setState({
        supplierGroupGuid: option.value,
        supplierGroupError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        supplierGroupGuid: "00000000-0000-0000-0000-000000000000",
        supplierGroupError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleCountrySelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      await this.setState({
        countryGuid: option.value,
        isCityTrue: false,
        countryError: "",
        alertErrorMessage: "",
      });
      if (option.label === "TÜRKİYE") {
        this.setState({
          labelIsTurkey: true,
        });
      }
      if (option.label !== "TÜRKİYE") {
        this.setState({
          isCityTrue: true,
          isTownTrue: true,
          isDistrictTrue: true,
          labelIsTurkey: false,
          isLoading: false,
          cityGuid: "00000000-0000-0000-0000-000000000000",
          townGuid: "00000000-0000-0000-0000-000000000000",
          districtGuid: "00000000-0000-0000-0000-000000000000",
          cityError: "",
          townError: "",
          districtError: "",
        });
      }
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/cities/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            cityArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        countryGuid: "",
        isCityTrue: true,
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        cityGuid: "00000000-0000-0000-0000-000000000000",
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        cityError: "",
        townError: "",
        districtError: "",
        countryError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleCitySelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      await this.setState({
        cityGuid: option.value,
        isTownTrue: false,
        cityError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/towns/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            townArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        cityGuid: "",
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        townError: "",
        districtError: "",
        cityError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleTownSelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      this.setState({
        townGuid: option.value,
        isDistrictTrue: false,
        townError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/districts/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            districtArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        townGuid: "",
        isDistrictTrue: true,
        districtGuid: "00000000-0000-0000-0000-000000000000",
        districtError: "",
        isLoading: false,
        townError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleDistrictSelectChange(option) {
    if (option) {
      this.setState({
        districtGuid: option.value,
        districtError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        districtGuid: "00000000-0000-0000-0000-000000000000",
        districtError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleGenderSelectChange(option) {
    if (option) {
      this.setState({
        connectionGender: option.value,
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        connectionGender: "",
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
      taxNoError: "",
      commercialRegistrationNumberError: "",
      mersisNumberError: "",
      taxAdministrationError: "",
      addressError: "",
      districtError: "",
      townError: "",
      cityError: "",
      countryError: "",
      phoneError: "",
      eMailError: "",
      connectionEmailError: "",
      connectionPhoneError: "",
      faxError: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if (this.state.taxNo === "") {
    //   this.setState({
    //     taxNoError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.taxNo !== "" && !CgnTaxNoControl.test(this.state.taxNo)) {
    //   this.setState({
    //     taxNoError: CgnMessage.taxNoErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.taxAdministration === "") {
    //   this.setState({
    //     taxAdministrationError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.address === "") {
    //   this.setState({
    //     addressError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.countryGuid === "") {
    //   this.setState({
    //     countryError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (
    //   this.state.labelIsTurkey === true &&
    //   this.state.cityGuid === "00000000-0000-0000-0000-000000000000"
    // ) {
    //   this.setState({
    //     cityError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (
    //   this.state.labelIsTurkey === true &&
    //   this.state.cityGuid !== "00000000-0000-0000-0000-000000000000" &&
    //   this.state.townGuid === "00000000-0000-0000-0000-000000000000"
    // ) {
    //   this.setState({
    //     townError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (
    //   this.state.labelIsTurkey === true &&
    //   this.state.townGuid !== "00000000-0000-0000-0000-000000000000" &&
    //   this.state.districtGuid === "00000000-0000-0000-0000-000000000000"
    // ) {
    //   this.setState({
    //     districtError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.phone === "") {
    //   this.setState({
    //     phoneError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.phone !== "" && !CgnPhoneControl.test(this.state.phone)) {
    //   this.setState({
    //     phoneError: CgnMessage.phoneErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.eMail === "") {
    //   this.setState({
    //     eMailError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.eMail !== "" && !CgnEMailControl.test(this.state.eMail)) {
    //   this.setState({
    //     eMailError: CgnMessage.emailErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (
    //   this.state.connectionEMail !== "" &&
    //   !CgnEMailControl.test(this.state.connectionEMail)
    // ) {
    //   this.setState({
    //     connectionEmailError: CgnMessage.emailErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (
    //   this.state.connectionPhone !== "" &&
    //   !CgnPhoneControl.test(this.state.connectionPhone)
    // ) {
    //   this.setState({
    //     connectionPhoneError: CgnMessage.phoneErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.fax !== "" && !CgnPhoneControl.test(this.state.fax)) {
    //   this.setState({
    //     faxError: CgnMessage.phoneErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.labelIsTurkey === false && this.state.countryGuid) {
    //   this.setState({
    //     cityError: "",
    //     townError: "",
    //     districtError: "",
    //     alertErrorMessage: "",
    //   });
    // }
    if (
      this.state.name !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        name: this.state.name,
        taxNo: this.state.taxNo,
        commercialRegistrationNumber: this.state.commercialRegistrationNumber,
        mersisNumber: this.state.mersisNumber,
        taxAdministration: this.state.taxAdministration,
        sector: this.state.sector,
        reference: this.state.reference,
        isRisk: this.state.isRisk,
        address: this.state.address,
        countryGuid: this.state.countryGuid,
        cityGuid: this.state.cityGuid,
        townGuid: this.state.townGuid,
        districtGuid: this.state.districtGuid,
        supplierGroupGuid: this.state.supplierGroupGuid,
        zipCode: this.state.zipCode,
        fax: this.state.fax,
        phone: this.state.phone,
        eMail: this.state.eMail,
        website: this.state.webSite,
        description: this.state.description,
        connectionName: this.state.connectionName,
        connectionDegree: this.state.connectionDegree,
        connectionDepartment: this.state.connectionDepartment,
        connectionPhone: this.state.connectionPhone,
        connectionEMail: this.state.connectionEMail,
        connectionGender: this.state.connectionGender,
        connectionDescription: this.state.connectionDescription,
        status: this.state.status,
      };
      var URLParam = "/suppliers/add";
      if (this.state.guid && this.state.urlSplit.length !== 2  && this.state.urlSplit[1] === "tedarikci-guncelle") {
        URLParam = "/suppliers/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          taxNo: this.state.taxNo,
          commercialRegistrationNumber: this.state.commercialRegistrationNumber,
          mersisNumber: this.state.mersisNumber,
          taxAdministration: this.state.taxAdministration,
          supplierGroupGuid: this.state.supplierGroupGuid,
          sector: this.state.sector,
          reference: this.state.reference,
          isRisk: this.state.isRisk,
          address: this.state.address,
          countryGuid: this.state.countryGuid,
          cityGuid: this.state.cityGuid,
          townGuid: this.state.townGuid,
          districtGuid: this.state.districtGuid,
          zipCode: this.state.zipCode,
          fax: this.state.fax,
          phone: this.state.phone,
          eMail: this.state.eMail,
          website: this.state.webSite,
          description: this.state.description,
          connectionName: this.state.connectionName,
          connectionDegree: this.state.connectionDegree,
          connectionDepartment: this.state.connectionDepartment,
          connectionPhone: this.state.connectionPhone,
          connectionEMail: this.state.connectionEMail,
          connectionGender: this.state.connectionGender,
          connectionDescription: this.state.connectionDescription,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            var modalAddedSupplier = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedSupplier });
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (this.state.guid && this.state.urlSplit[1] === "tedarikci-guncelle") {
                      window.location.href = "/tedarikci-listele";
                    }
                    if (this.state.urlSplit[1] === "tedarikci-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "tedarikci-ekle" ||
          this.state.urlSplit[1] === "tedarikci-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <h2 className="d-block mb-3">Tedarikçi Bilgileri</h2>

                <Row>
                  <Col className="p-0">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Ad [*]"
                      value={this.state.name}
                      placeHolder="Lütfen ad girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    /> 
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="taxAdministration"
                      label="Vergi Dairesi"
                      value={this.state.taxAdministration}
                      placeHolder="Lütfen vergi dairesi girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.taxAdministrationError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="taxNo"
                      maxLength="11"
                      label="Vergi Numarası"
                      value={this.state.taxNo}
                      placeHolder="Lütfen vergi numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.taxNoError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="commercialRegistrationNumber"
                      label="Ticari Sicil Numarası"
                      value={this.state.commercialRegistrationNumber}
                      placeHolder="Lütfen ticari sicil numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.commercialRegistrationNumberError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="mersisNumber"
                      label="Mersis Numarası"
                      value={this.state.mersisNumber}
                      placeHolder="Lütfen mersis numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.mersisNumberError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="sector"
                      label="Sektör"
                      value={this.state.sector}
                      placeHolder="Lütfen sektör girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.sectorError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="reference"
                      label="Referans"
                      value={this.state.reference}
                      placeHolder="Lütfen referans girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.referenceError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="isRisk"
                      label="Risk"
                      placeholder="Lütfen risk seçiniz..."
                      selectValue={this.state.isRisk}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleIsRiskSelectChange(option)
                      }
                      options={IsRisk}
                      error={this.state.isRiskError}
                    />
                  </Col>
                  <Col lg="3">
                  <CgnReactSelect
                      name="supplierGroupGuid"
                      label="Tedarikçi Grubu"
                      placeholder="Lütfen tedairkçi grubu seçiniz..."
                      selectValue={this.state.supplierGroupGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleSupplierGroupSelectChange(option)
                      }
                      options={this.state.supplierGroupArr}
                      error={this.state.supplierGroupError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="address"
                      label="Adres"
                      value={this.state.address}
                      placeHolder="Lütfen adres girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.addressError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="countryGuid"
                      label="Ülke"
                      placeholder="Lütfen ülke seçiniz..."
                      selectValue={this.state.countryGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCountrySelectChange(option)
                      }
                      options={this.state.countryArr}
                      error={this.state.countryError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="cityGuid"
                      label="Şehir"
                      placeholder="Lütfen şehir seçiniz..."
                      selectValue={this.state.cityGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isCityTrue}
                      onChange={(option) => this.handleCitySelectChange(option)}
                      options={this.state.cityArr}
                      error={this.state.cityError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="townGuid"
                      label="İlçe"
                      placeholder="Lütfen ilçe seçiniz..."
                      selectValue={this.state.townGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTownTrue}
                      onChange={(option) => this.handleTownSelectChange(option)}
                      options={this.state.townArr}
                      error={this.state.townError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="districtGuid"
                      label="Mahalle"
                      placeholder="Lütfen mahalle seçiniz..."
                      selectValue={this.state.districtGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isDistrictTrue}
                      onChange={(option) =>
                        this.handleDistrictSelectChange(option)
                      }
                      options={this.state.districtArr}
                      error={this.state.districtError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="zipCode"
                      label="Posta Kodu"
                      value={this.state.zipCode}
                      placeHolder="Lütfen posta kodu girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.zipCodeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="phone"
                      label="Telefon"
                      value={this.state.phone}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.phoneError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="fax"
                      label="Faks"
                      value={this.state.fax}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.faxError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="eMail"
                      label="E-Mail"
                      value={this.state.eMail}
                      placeHolder="Lütfen e-mail girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.eMailError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="webSite"
                      label="Web Adresi"
                      value={this.state.webSite}
                      placeHolder="Lütfen web adresi girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.webSiteError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                <h2 className="d-block mb-3">Yetkili Bilgileri</h2>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="connectionName"
                      label="Ad Soyad"
                      value={this.state.connectionName}
                      placeHolder="Lütfen ad soyad girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.connectionNameError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="connectionDegree"
                      label="Ünvan"
                      value={this.state.connectionDegree}
                      placeHolder="Lütfen ünvan girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.connectionDegreeError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="connectionDepartment"
                      label="Departman"
                      value={this.state.connectionDepartment}
                      placeHolder="Lütfen departman girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.connectionDepartmentError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="connectionPhone"
                      label="Cep Telefonu"
                      value={this.state.connectionPhone}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.connectionPhoneError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="connectionEMail"
                      label="E-Mail"
                      value={this.state.connectionEMail}
                      placeHolder="Lütfen e-mail girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.connectionEmailError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="connectionGender"
                      label="Cinsiyet"
                      placeholder="Lütfen cinsiyet seçiniz..."
                      selectValue={this.state.connectionGender}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleGenderSelectChange(option)
                      }
                      options={Gender}
                      error={this.state.genderError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="connectionDescription"
                      label="Açıklama"
                      value={this.state.connectionDescription}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.connectionDescriptionError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "tedarikci-ekle" ||
              this.state.urlSplit[1] === "tedarikci-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(SupplierAddUpdate);
