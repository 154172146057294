import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import "moment/locale/tr";
import SaleTypeAddUpdate from "../saleType/SaleTypeAddUpdate";
import MachineAddUpdate from "../machine/MachineAddUpdate";
import CgnButton from "../toolbox/CgnButton";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import { confirmAlert } from "react-confirm-alert";

class MachineCustomer2AddUpdate extends Component {
  state = {
    pageTitle: "Makine Müşteri Ekle",
    pageTitleAdd: "Makine Müşteri Ekle",
    pageTitleUpdate: "Makine Müşteri Güncelle",
    pageAddLink: "makine-musteri-2-ekle",
    pageListLink: "makine-musteri-2-listele",
    breadcrumb: [{ text: "Makine Müşteri", link: "#" }],
    isLoading: false,
    machineArr: [],
    customerArr: [],
    saleTypeArr: [],
    urlSplit: [],
    guid: "",
    machine: "",
    customer: "",
    entryDate: null,
    endDate: null,
    saleType: "",
    status: true,
    nameError: "",
    machineGuid: null,
    customerGuid: null,
    saleTypeGuid: null,
    isNull: false,
    isOpenModalSaleType: false,
    isOpenModalMachine: false,
    isOpenModalCustomer: false,
    modalButtonIdx: 0,
    entryDateError: "",
    endDateError: "",
    machineNameError: "",
    customerNameError: "",
    saleTypeNameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    machineModalPlus: false,
    customerModalPlus: false,
    saleTypeModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      machineModalPlus: await UserOperationClaimControl(1071),
      customerModalPlus: await UserOperationClaimControl(1012),
      saleTypeModalPlus: await UserOperationClaimControl(1076),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/newmachines/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            machineGuid: item.machineGuid,
          };
        });
        this.setState({
          machineArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            customerGuid: item.customerGuid,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/saleType/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            saleTypeGuid: item.saleTypeGuid,
          };
        });
        this.setState({
          saleTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
      isLoading: true,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/machinecustomer2/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              machineName: response.data.machineName,
              machineGuid: response.data.machineGuid,
              customerGuid: response.data.customerGuid,
              saleTypeGuid: response.data.saleTypeGuid,
              entryDate: response.data.entryDate,
              endDate: response.data.endDate,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        taxNo: "",
        taxAdministration: "",
        address: "",
        eMail: "",
        phone: "",
        machineGuid: null,
        customerGuid: null,
        saleTypeGuid: null,
        endDate: null,
        entryDate: null,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalSaleType &&
      this.props.location.modalAddedSaleType !== undefined &&
      this.props.location.modalAddedSaleType.isModal !== undefined &&
      this.props.location.modalAddedSaleType.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/saleType/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              saleTypeGuid: item.saleTypeGuid,
            };
          });
          this.setState({
            saleTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSaleType.data &&
              element.name === this.props.location.modalAddedSaleType.data
            ) {
              this.setState({
                saleTypeGuid: element.guid,
                saleTypeNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSaleType.isModal = true;
      this.setState({
        isOpenModalSaleType: false,
      });
    }
    if (
      this.state.isOpenModalMachine &&
      this.props.location.modalAddedMachine !== undefined &&
      this.props.location.modalAddedMachine.isModal !== undefined &&
      this.props.location.modalAddedMachine.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/machines/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.machineName,
            };
          });
          this.setState({
            machineArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMachine.data &&
              element.machineSerialNo ===
              this.props.location.modalAddedMachine.data
            ) {
              this.setState({
                machineGuid: element.guid,
                machineNameError: "",
                alertErrorMessage: "",
              });
            }
            else {
              this.setState({
                alertErrorMessage: CgnMessage.alertErrorMessage,
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMachine.isModal = true;
      this.setState({
        isOpenModalMachine: false,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      machineGuid: null,
      customerGuid: null,
      saleTypeGuid: null,
      endDate: "",
      entryDate: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      entryDateError: CgnMessage.textErrorMessage,
      endDateError: CgnMessage.textErrorMessage,
      machineNameError: CgnMessage.textErrorMessage,
      customerNameError: CgnMessage.textErrorMessage,
      saleTypeNameError: CgnMessage.textErrorMessage,
    });
  }

  handleMachineSelectChange(option) {
    if (option) {
      this.setState({
        machineGuid: option.value,
        machineNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        machineGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        machineNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        customerNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleSaleTypeSelectChange(option) {
    if (option) {
      this.setState({
        saleTypeGuid: option.value,
        saleTypeNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        saleTypeGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        saleTypeNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  addModalSaleType = () => {
    this.setState({
      isOpenModalSaleType: !this.state.isOpenModalSaleType,
    });
  };

  addModalMachine = () => {
    this.setState({
      isOpenModalMachine: !this.state.isOpenModalMachine,
    });
  };

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  handleEntryDateChange(entryDate) {
    if (entryDate) {
      this.setState({
        entryDate: entryDate,
        entryDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        entryDate: "",
        entryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleEndDateChange(endDate) {
    if (endDate) {
      this.setState({
        endDate: endDate,
        endDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        endDate: "",
        endDateError: "",
        alertErrorMessage: "",
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      entryDateError: "",
      endDateError: "",
      machineNameError: "",
      customerNameError: "",
      saleTypeNameError: "",
    });
    if (this.state.machineGuid === null) {
      this.setState({
        machineNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === null) {
      this.setState({
        customerNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.saleTypeGuid === null) {
      this.setState({
        saleTypeNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.entryDate === null) {
      this.setState({
        entryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.machineGuid !== null &&
      this.state.customerGuid !== null &&
      this.state.saleTypeGuid !== null &&
      this.state.entryDate !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        machineGuid: this.state.machineGuid,
        customerGuid: this.state.customerGuid,
        saleTypeGuid: this.state.saleTypeGuid,
        entryDate: moment(this.state.entryDate).format("DD/MM/YYYY"),
        endDate: this.state.endDate
          ? moment(this.state.endDate).format("DD/MM/YYYY")
          : null,
        status: this.state.status,
      };
      var URLParam = "/machineCustomer2/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/machineCustomer2/update";
        myObj = {
          guid: this.state.guid,
          machineGuid: this.state.machineGuid,
          customerGuid: this.state.customerGuid,
          endDate: this.state.endDate
            ? moment(this.state.endDate).format("DD/MM/YYYY")
            : null,
          entryDate: this.state.entryDate,
          saleTypeGuid: this.state.saleTypeGuid,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then((response) => {
          confirmAlert({
            title: CgnMessage.alertSuccessTitle,
            message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  if (this.state.guid) {
                    window.location.href = "/makine-musteri-2-listele";
                  } else {
                    window.location.reload();
                  }
                }
              },
            ],
          });
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "makine-musteri-2-ekle" ||
          this.state.urlSplit[1] === "makine-musteri-2-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="6">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="machineGuid"
                          label="Makine [*]"
                          placeholder="Makine seçiniz..."
                          selectValue={this.state.machineGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleMachineSelectChange(option)
                          }
                          options={this.state.machineArr}
                          error={this.state.machineNameError}
                        />
                      </div>
                      {this.state.machineModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalMachine()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerNameError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="saleTypeGuid"
                          label="Satış Türü [*]"
                          placeholder="Satış Türü seçiniz..."
                          selectValue={this.state.saleTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSaleTypeSelectChange(option)
                          }
                          options={this.state.saleTypeArr}
                          error={this.state.saleTypeNameError}
                        />
                      </div>
                      {this.state.saleTypeModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalSaleType()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4">
                    <CgnDatePicker
                      name="entryDate"
                      label="Giriş Tarihi [*]"
                      selected={
                        this.state.entryDate &&
                          this.state.entryDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.entryDate.split("/")[2] +
                            "-" +
                            this.state.entryDate.split("/")[1] +
                            "-" +
                            this.state.entryDate.split("/")[0]
                          )
                          : this.state.entryDate
                      }
                      onChange={(entryDate) =>
                        this.handleEntryDateChange(entryDate)
                      }
                      error={this.state.entryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>

                  <Col lg="4">
                    <CgnDatePicker
                      name="endDate"
                      label="Çıkış Tarihi"
                      selected={this.state.endDate}
                      onChange={(endDate) => this.handleEndDateChange(endDate)}
                      error={this.state.endDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="4" style={{ display: "none" }}>
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "makine-musteri-ekle" ||
              this.state.urlSplit[1] === "makine-musteri-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalMachine}
          toggle={this.addModalMachine}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Makine Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MachineAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalMachine}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalSaleType}
          toggle={this.addModalSaleType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Satış Türü Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <SaleTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSaleType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(MachineCustomer2AddUpdate);
