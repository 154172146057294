import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  textAreaStyle,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnButton from "../toolbox/CgnButton";
import PersonGroupAddUpdate from "../personGroup/PersonGroupAddUpdate";

class PersonAddUpdate extends Component {
  state = {
    pageTitle: "Kişi Ekle",
    pageTitleAdd: "Kişi Ekle",
    pageTitleUpdate: "Kişi Güncelle",
    pageAddLink: "kisi-ekle",
    pageListLink: "kisi-listele",
    breadcrumb: [{ text: "Kişi", link: "#" }],
    isLoading: false,
    urlSplit: [],
    groupGuid: "00000000-0000-0000-0000-000000000000",
    firstName: "",
    lastName: "",
    job: "",
    phone1: "",
    phone2: "",
    email: "",
    address: "",
    countryGuid: "00000000-0000-0000-0000-000000000000",
    countryName: "",
    cityGuid: "00000000-0000-0000-0000-000000000000",
    townGuid: "00000000-0000-0000-0000-000000000000",
    districtGuid: "00000000-0000-0000-0000-000000000000",
    isCityTrue: true,
    isDistrictTrue: true,
    isTownTrue: true,
    labelIsTurkey: false,
    zipCode: "",
    description: "",
    isOpenModalPersonGroup: false,
    firstNameError: "",
    lastNameError: "",
    phone1Error: "",
    phone2Error: "",
    emailError: "",
    guid: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    personGroupModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      personGroupModalPlus: await UserOperationClaimControl(1056),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/countries/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          countryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/personGroups/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          groupArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/persons/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              groupGuid: response.data.groupGuid,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              job: response.data.job,
              phone1: response.data.phone1,
              phone2: response.data.phone2,
              email: response.data.email,
              address: response.data.address,
              countryGuid: response.data.countryGuid,
              countryName: response.data.countryName,
              cityGuid: response.data.cityGuid,
              districtGuid: response.data.districtGuid,
              townGuid: response.data.townGuid,
              zipCode: response.data.zipCode,
              description: response.data.description,
              status: response.data.status,
              isLoading: false,
            });
            if (this.state.cityGuid) {
              axios
                .get(URL + "/cities/getlist/" + this.state.countryGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    cityArr: myArr,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.townGuid) {
              axios
                .get(URL + "/towns/getlist/" + this.state.cityGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    townArr: myArr,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.districtGuid) {
              axios
                .get(URL + "/districts/getlist/" + this.state.townGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    districtArr: myArr,
                    isLoading: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.countryName === "TÜRKİYE") {
              this.setState({
                isCityTrue: false,
                isTownTrue: false,
                isDistrictTrue: false,
                labelIsTurkey: true,
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        groupGuid: "00000000-0000-0000-0000-000000000000",
        firstName: "",
        lastName: "",
        job: "",
        phone1: "",
        phone2: "",
        email: "",
        address: "",
        description: "",
        isCityTrue: true,
        isTownTrue: true,
        isDistrictTrue: true,
        districtGuid: "00000000-0000-0000-0000-000000000000",
        townGuid: "00000000-0000-0000-0000-000000000000",
        cityGuid: "00000000-0000-0000-0000-000000000000",
        countryGuid: "00000000-0000-0000-0000-000000000000",
        zipCode: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalPersonGroup &&
      this.props.location.modalAddedPersonGroup !== undefined &&
      this.props.location.modalAddedPersonGroup.isModal !== undefined &&
      this.props.location.modalAddedPersonGroup.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/personGroups/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            groupArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedPersonGroup.data &&
              element.name === this.props.location.modalAddedPersonGroup.data
            ) {
              this.setState({
                groupGuid: element.guid,
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedPersonGroup.isModal = true;
      this.setState({
        isOpenModalPersonGroup: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handlePersonGroupSelectChange(option) {
    if (option) {
      this.setState({
        groupGuid: option.value,
      });
    } else {
      this.setState({
        groupGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  addModalPersonGroup = async () => {
    await this.setState({
      isOpenModalPersonGroup: !this.state.isOpenModalPersonGroup,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      firstNameError: "",
      lastNameError: "",
      phone1Error: "",
      phone2Error: "",
      emailError: "",
      alertErrorMessage: "",
    });
    if (name === "firstName" && value === "") {
      this.setState({
        firstNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "lastName" && value === "") {
      this.setState({
        lastNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if (name === "phone1" && value === "") {
    //   this.setState({
    //     phone1Error: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (name === "phone1" && !CgnPhoneControl.test(value)) {
    //   this.setState({
    //     phone1Error: CgnMessage.phoneErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (name === "phone2" && value !== "" && !CgnPhoneControl.test(value)) {
    //   this.setState({
    //     phone2Error: CgnMessage.phoneErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (name === "email" && value !== "" && !CgnEMailControl.test(value)) {
    //   this.setState({
    //     emailError: CgnMessage.emailErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
  };
  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      groupGuid: "00000000-0000-0000-0000-000000000000",
      firstName: "",
      lastName: "",
      job: "",
      phone1: "",
      phone2: "",
      email: "",
      address: "",
      description: "",
      districtGuid: "00000000-0000-0000-0000-000000000000",
      townGuid: "00000000-0000-0000-0000-000000000000",
      cityGuid: "00000000-0000-0000-0000-000000000000",
      countryGuid: "00000000-0000-0000-0000-000000000000",
      zipCode: "",
      isCityTrue: true,
      isTownTrue: true,
      isDistrictTrue: true,
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      firstNameError: CgnMessage.textErrorMessage,
      lastNameError: CgnMessage.textErrorMessage,
      phone1Error: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  async handleCountrySelectChange(option) {
    this.setState({
      isLoading: true,
      isCityTrue: true,
      isTownTrue: true,
      isDistrictTrue: true,
      labelIsTurkey: false,
      countryGuid: "00000000-0000-0000-0000-000000000000",
      cityGuid: "00000000-0000-0000-0000-000000000000",
      townGuid: "00000000-0000-0000-0000-000000000000",
      districtGuid: "00000000-0000-0000-0000-000000000000",
      cityError: "",
      townError: "",
      districtError: "",
    });
    if (option) {
      await this.setState({
        countryGuid: option.value,
        isCityTrue: false,
        countryError: "",
        alertErrorMessage: "",
      });
      if (option.label === "TÜRKİYE") {
        this.setState({
          labelIsTurkey: true,
        });
      }
      if (option.label !== "TÜRKİYE") {
        this.setState({
          isCityTrue: true,
          isTownTrue: true,
          isDistrictTrue: true,
          labelIsTurkey: false,
          isLoading: false,
          cityGuid: "00000000-0000-0000-0000-000000000000",
          townGuid: "00000000-0000-0000-0000-000000000000",
          districtGuid: "00000000-0000-0000-0000-000000000000",
          cityError: "",
          townError: "",
          districtError: "",
        });
      }
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/cities/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            cityArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        countryGuid: "00000000-0000-0000-0000-000000000000",
        isCityTrue: true,
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        cityGuid: "00000000-0000-0000-0000-000000000000",
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        cityError: "",
        townError: "",
        districtError: "",
      });
    }
  }

  async handleCitySelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      await this.setState({
        cityGuid: option.value,
        isTownTrue: false,
        cityError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/towns/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            townArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        cityGuid: "00000000-0000-0000-0000-000000000000",
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        townError: "",
        districtError: "",
      });
    }
  }

  async handleTownSelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      this.setState({
        townGuid: option.value,
        isDistrictTrue: false,
        townError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/districts/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            districtArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        townGuid: "00000000-0000-0000-0000-000000000000",
        isDistrictTrue: true,
        districtGuid: "00000000-0000-0000-0000-000000000000",
        districtError: "",
        isLoading: false,
      });
    }
  }

  async handleDistrictSelectChange(option) {
    if (option) {
      this.setState({
        districtGuid: option.value,
        districtError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        districtGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
      districtError: "",
      townError: "",
      cityError: "",
      countryError: "",
    });
    if (this.state.firstName === "") {
      this.setState({
        firstNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.lastName === "") {
      this.setState({
        lastNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.labelIsTurkey === true &&
      this.state.cityGuid === "00000000-0000-0000-0000-000000000000"
    ) {
      this.setState({
        cityError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.labelIsTurkey === true &&
      this.state.cityGuid !== "00000000-0000-0000-0000-000000000000" &&
      this.state.townGuid === "00000000-0000-0000-0000-000000000000"
    ) {
      this.setState({
        townError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.labelIsTurkey === true &&
      this.state.townGuid !== "00000000-0000-0000-0000-000000000000" &&
      this.state.districtGuid === "00000000-0000-0000-0000-000000000000"
    ) {
      this.setState({
        districtError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if (this.state.phone1 === "") {
    //   this.setState({
    //     phone1Error: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.phone1 !== "" && !CgnPhoneControl.test(this.state.phone1)) {
    //   this.setState({
    //     phone1Error: CgnMessage.phoneErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.phone2 !== "" && !CgnPhoneControl.test(this.state.phone2)) {
    //   this.setState({
    //     phone2Error: CgnMessage.phoneErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    // if (this.state.email !== "" && !CgnEMailControl.test(this.state.email)) {
    //   this.setState({
    //     emailError: CgnMessage.emailErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    else if (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      ((this.state.labelIsTurkey === true &&
        this.state.countryGuid &&
        this.state.cityGuid &&
        this.state.cityGuid !== "00000000-0000-0000-0000-000000000000" &&
        this.state.townGuid &&
        this.state.townGuid !== "00000000-0000-0000-0000-000000000000" &&
        this.state.districtGuid &&
        this.state.districtGuid !== "00000000-0000-0000-0000-000000000000") ||
        (this.state.labelIsTurkey === false && this.state.countryGuid)) // &&
      // this.state.phone1 !== "" &&
      // CgnPhoneControl.test(this.state.phone1) &&
      // (this.state.phone2 === "" || (this.state.phone2 !== "" && CgnPhoneControl.test(this.state.phone2))) &&
      // (this.state.email === "" || (this.state.email !== "" && CgnEMailControl.test(this.state.email)))
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        groupGuid: this.state.groupGuid,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        job: this.state.job,
        phone1: this.state.phone1,
        phone2: this.state.phone2,
        email: this.state.email,
        address: this.state.address,
        countryGuid: this.state.countryGuid,
        cityGuid: this.state.cityGuid,
        districtGuid: this.state.districtGuid,
        townGuid: this.state.townGuid,
        zipCode: this.state.zipCode,
        description: this.state.description,
        status: this.state.status,
      };
      var URLParam = "/persons/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/persons/update";
        myObj = {
          guid: this.state.guid,
          groupGuid: this.state.groupGuid,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          job: this.state.job,
          phone1: this.state.phone1,
          phone2: this.state.phone2,
          email: this.state.email,
          address: this.state.address,
          countryGuid: this.state.countryGuid,
          cityGuid: this.state.cityGuid,
          districtGuid: this.state.districtGuid,
          townGuid: this.state.townGuid,
          zipCode: this.state.zipCode,
          description: this.state.description,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/kisi-listele";
            }
            if (this.state.urlSplit[1] === "kisi-ekle") {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "kisi-ekle" ||
          this.state.urlSplit[1] === "kisi-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="groupGuid"
                          label="Grup"
                          placeholder="Grup seçiniz..."
                          selectValue={this.state.groupGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePersonGroupSelectChange(option)
                          }
                          options={this.state.groupArr}
                        />
                      </div>
                      {this.state.personGroupModalPlus && (
                        <div className="p-0 flexMaxAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalPersonGroup()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="firstName"
                      label="Ad [*]"
                      value={this.state.firstName}
                      placeHolder="Lütfen ad girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.firstNameError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="lastName"
                      label="Soyad [*]"
                      value={this.state.lastName}
                      placeHolder="Lütfen soyad girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.lastNameError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="job"
                      label="Meslek"
                      value={this.state.job}
                      placeHolder="Lütfen meslek girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="address"
                      label="Adres"
                      value={this.state.address}
                      placeHolder="Lütfen adres girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="zipCode"
                      label="Posta Kodu"
                      value={this.state.zipCode}
                      placeHolder="Lütfen posta kodu girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.zipCodeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="phone1"
                      label="Telefon"
                      value={this.state.phone1}
                      placeHolder="Lütfen telefon girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.phone1Error}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="phone2"
                      label="GSM"
                      value={this.state.phone2}
                      placeHolder="Lütfen telefon girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.phone2Error}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="email"
                      label="E-Mail"
                      value={this.state.email}
                      placeHolder="Lütfen email girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.emailError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="countryGuid"
                      label="Ülke"
                      placeholder="Lütfen ülke seçiniz..."
                      selectValue={this.state.countryGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCountrySelectChange(option)
                      }
                      options={this.state.countryArr}
                      error={this.state.countryError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="cityGuid"
                      label="Şehir"
                      placeholder="Lütfen şehir seçiniz..."
                      selectValue={this.state.cityGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isCityTrue}
                      onChange={(option) => this.handleCitySelectChange(option)}
                      options={this.state.cityArr}
                      error={this.state.cityError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="townGuid"
                      label="İlçe"
                      placeholder="Lütfen ilçe seçiniz..."
                      selectValue={this.state.townGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTownTrue}
                      onChange={(option) => this.handleTownSelectChange(option)}
                      options={this.state.townArr}
                      error={this.state.townError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="districtGuid"
                      label="Mahalle"
                      placeholder="Lütfen mahalle seçiniz..."
                      selectValue={this.state.districtGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isDistrictTrue}
                      onChange={(option) =>
                        this.handleDistrictSelectChange(option)
                      }
                      options={this.state.districtArr}
                      error={this.state.districtError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "kisi-ekle" ||
              this.state.urlSplit[1] === "kisi-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalPersonGroup}
          toggle={this.addModalPersonGroup}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Kişi Grubu Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <PersonGroupAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalPersonGroup}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(PersonAddUpdate);
