import React from "react";
import { Button, FormGroup } from "reactstrap";
import FileInput from "./CgnFileInput";

const ImageUploadMulti = ({
  images,
  noFoto,
  style,
  pdfIcon,
  excelIcon,
  docIcon,
  rarIcon,
  zipIcon,
  onChange,
  onDelete,
  error,
  disabled,
  label,
  name,
  hidden,
  accept,
}) => {
  let re = /(?:\.([^.]+))?$/;
  let excelRegex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
  let docxRegex = /^([a-zA-Z0-9\s_\\.\-:])+(.doc|.docx)$/;
  let rarRegex = /^([a-zA-Z0-9\s_\\.\-:])+(.rar)$/;
  let zipRegex = /^([a-zA-Z0-9\s_\\.\-:])+(.zip)$/;
  let wrapperClass = "form-group image-upload";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  let urlWebSite = "http://sys.cogenteknoloji.com";
  let urlSplit = window.location.hostname;
  if (urlSplit === "crm.cevirikalitesi.com") {
    urlWebSite = "https://www.cevirikalitesi.com";
  } else  if (urlSplit === "localhost") {
    urlWebSite = "http://localhost:3000";
  } else  if (urlSplit === "192.168.1.7") {
    urlWebSite = "http://192.168.1.7:3000";
  }


  return (
    <FormGroup className={wrapperClass} hidden={hidden}>
      <div className="image-list">
        {images.map(function (item) {
          return (
            item &&
            item.image &&
            item.imageName && (
              <div className="image-list-item mb-3">
                {item.image &&
                  re.exec(item.image)[1].toLowerCase() === "pdf" && (
                    <a
                      href={urlWebSite + "/files/" + item.image}
                      target="_blank"
                      title={item.imageName}
                    >
                      <img
                        src={pdfIcon}
                        alt={item.imageName}
                        title={item.imageName}
                        className="image mb-2 rounded"
                        style={style}
                      />
                    </a>
                  )}
                {item.image &&
                  excelRegex.exec(item.image) != null &&
                  (excelRegex.exec(item.image)[2].toLowerCase() === ".xls" ||
                    excelRegex.exec(item.image)[2].toLowerCase() ===
                      ".xlsx") && (
                    <a
                      href={urlWebSite + "/files/" + item.image}
                      target="_blank"
                      title={item.imageName}
                    >
                      <img
                        src={excelIcon}
                        alt={item.imageName}
                        title={item.imageName}
                        className="image mb-2 rounded"
                        style={style}
                      />
                    </a>
                  )}
                {item.image &&
                  docxRegex.exec(item.image) != null &&
                  (docxRegex.exec(item.image)[2].toLowerCase() === ".docx" ||
                    docxRegex.exec(item.image)[2].toLowerCase() === ".doc") && (
                    <a
                      href={urlWebSite + "/files/" + item.image}
                      target="_blank"
                      title={item.imageName}
                    >
                      <img
                        src={docIcon}
                        alt={item.imageName}
                        title={item.imageName}
                        className="image mb-2 rounded"
                        style={style}
                      />
                    </a>
                  )}
                {item.image &&
                  rarRegex.exec(item.image) != null &&
                  rarRegex.exec(item.image)[2].toLowerCase() === ".rar" && (
                    <a
                      href={urlWebSite + "/files/" + item.image}
                      target="_blank"
                      title={item.imageName}
                    >
                      <img
                        src={rarIcon}
                        alt={item.imageName}
                        title={item.imageName}
                        className="image mb-2 rounded"
                        style={style}
                      />
                    </a>
                  )}
                {item.image &&
                  zipRegex.exec(item.image) != null &&
                  zipRegex.exec(item.image)[2].toLowerCase() === ".zip" && (
                    <a
                      href={urlWebSite + "/files/" + item.image}
                      target="_blank"
                      title={item.imageName}
                    >
                      <img
                        src={zipIcon}
                        alt={item.imageName}
                        title={item.imageName}
                        className="image mb-2 rounded"
                        style={style}
                      />
                    </a>
                  )}
                {item.image &&
                  (re.exec(item.image)[1].toLowerCase() === "jpeg" ||
                    re.exec(item.image)[1].toLowerCase() === "jpg" ||
                    re.exec(item.image)[1].toLowerCase() === "png") && (
                    <a
                      href={urlWebSite + "/files/" + item.image}
                      target="_blank"
                      title={item.imageName}
                    >
                      <img
                        src={"/files/" + item.image}
                        alt={item.imageName}
                        title={item.imageName}
                        className="image mb-2 rounded"
                        style={style}
                      />
                    </a>
                  )}
                {!item.image && noFoto && (
                  <img
                    src={noFoto}
                    alt={item.imageName}
                    title={item.imageName}
                    className="image mb-2 rounded"
                    style={style}
                  />
                )}
                {!disabled && (
                  <Button color="danger" onClick={() => onDelete(item.image)}>
                    Sil
                  </Button>
                )}
              </div>
            )
          );
        })}
      </div>
      {!disabled && (
        <FileInput
          accept={accept}
          name={name}
          label={label}
          placeHolder="Lütfen fotoğraf/dosya seçin..."
          autoComplete="off"
          multiple={true}
          onChange={onChange}
          hidden={hidden}
        />
      )}
      {error && !hidden && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </FormGroup>
  );
};

export default ImageUploadMulti;
