import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import CgnButton from "../toolbox/CgnButton";
import CanalAddUpdate from "../canal/CanalAddUpdate";
import BrandAddUpdate from "../brand/BrandAddUpdate";
import ModelAddUpdate from "../model/ModelAddUpdate";

class MachineAddUpdate extends Component {
  state = {
    pageTitle: "Makine Ekle",
    pageTitleAdd: "Makine Ekle",
    pageTitleUpdate: "Makine Güncelle",
    pageAddLink: "makine-ekle",
    pageListLink: "makine-listele",
    breadcrumb: [{ text: "Makine", link: "#" }],
    isLoading: false,
    canalArr: [],
    brandArr: [],
    modelArr: [],
    urlSplit: [],
    name: "",
    guid: "",
    customsEntryDate: "",
    machineSerialNo: "",
    refrigeratorModel: "",
    coolerSerialNo: "",
    vendonSerialNo: "",
    standModel: "",
    standDimensions: "",
    purifierBrand: "",
    status: true,
    nameError: "",
    canalGuid: null,
    brandGuid: null,
    modelGuid: null,
    isNull: false,
    isOpenModalCanal: false,
    isOpenModalBrand: false,
    isOpenModalModel: false,
    modalButtonIdx: 0,
    modelNameError: "",
    brandNameError: "",
    canalNameError: "",
    customsEntryDateError: "",
    machineSerialNoError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    canalModalPlus: false,
    brandModalPlus: false,
    modelModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      canalModalPlus: await UserOperationClaimControl(1062),
      brandModalPlus: await UserOperationClaimControl(1061),
      modelModalPlus: await UserOperationClaimControl(1072),
    });
    var statusType = 2; // sadece aktif olanları göstermesi için
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/canal/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            canalGuid: item.canalGuid,
          };
        });
        this.setState({
          canalArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/brand/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            brandGuid: item.brandGuid,
          };
        });
        this.setState({
          brandArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/machines/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              brandGuid: response.data.brandGuid,
              canalGuid: response.data.canalGuid,
              modelGuid: response.data.modelGuid,
              machineSerialNo: response.data.machineSerialNo,
              customsEntryDate: response.data.customsEntryDate,
              refrigeratorModel: response.data.refrigeratorModel,
              coolerSerialNo: response.data.coolerSerialNo,
              vendonSerialNo: response.data.vendonSerialNo,
              standModel: response.data.standModel,
              standDimensions: response.data.standDimensions,
              purifierBrand: response.data.purifierBrand,
              status: response.data.status,
              isLoading: false,
            });
            if (this.state.modelGuid) {
              axios
                .get(
                  URL +
                    "/model/getlistmodels/" +
                    this.state.brandGuid +
                    "/" +
                    statusType,
                  config
                )
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    modelArr: myArr,
                    isLoading: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        taxNo: "",
        taxAdministration: "",
        address: "",
        eMail: "",
        phone: "",
        brandGuid: null,
        canalGuid: null,
        modelGuid: null,
        machineSerialNo: "",
        customsEntryDate: "",
        refrigeratorModel: "",
        coolerSerialNo: "",
        vendonSerialNo: "",
        standModel: "",
        standDimensions: "",
        purifierBrand: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalCanal &&
      this.props.location.modalAddedCanal !== undefined &&
      this.props.location.modalAddedCanal.isModal !== undefined &&
      this.props.location.modalAddedCanal.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/canal/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              canalGuid: item.canalGuid,
            };
          });
          this.setState({
            canalArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCanal.data &&
              element.name === this.props.location.modalAddedCanal.data
            ) {
              this.setState({
                canalGuid: element.guid,
                canalNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCanal.isModal = true;
      this.setState({
        isOpenModalCanal: false,
      });
    }
    if (
      this.state.isOpenModalBrand &&
      this.props.location.modalAddedBrand !== undefined &&
      this.props.location.modalAddedBrand.isModal !== undefined &&
      this.props.location.modalAddedBrand.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/brand/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              brandGuid: item.brandGuid,
            };
          });
          this.setState({
            brandArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBrand.data &&
              element.name === this.props.location.modalAddedBrand.data
            ) {
              this.setState({
                brandGuid: element.guid,
                brandNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBrand.isModal = true;
      this.setState({
        isOpenModalBrand: false,
      });
    }
    if (
      this.state.isOpenModalModel &&
      this.props.location.modalAddedModel !== undefined &&
      this.props.location.modalAddedModel.isModal !== undefined &&
      this.props.location.modalAddedModel.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/model/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              modelGuid: item.modelGuid,
            };
          });
          this.setState({
            modelArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedModel.data &&
              element.name === this.props.location.modalAddedModel.data
            ) {
              this.setState({
                modelGuid: element.guid,
                modelNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedModel.isModal = true;
      this.setState({
        isOpenModalModel: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  addModalCanal = () => {
    this.setState({
      isOpenModalCanal: !this.state.isOpenModalCanal,
    });
  };

  addModalBrand = () => {
    this.setState({
      isOpenModalBrand: !this.state.isOpenModalBrand,
    });
  };

  addModalModel = () => {
    this.setState({
      isOpenModalModel: !this.state.isOpenModalModel,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      machineSerialNoError: "",
      alertErrorMessage: "",
    });
    if (name === "machineSerialNo" && value === "") {
      this.setState({
        machineSerialNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "customsEntryDate" && value === "") {
      this.setState({
        customsEntryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: this.include,
      });
    }
  };

  handleNotChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      machineSerialNo: "",
      refrigeratorModel: "",
      coolerSerialNo: "",
      vendonSerialNo: "",
      standModel: "",
      standDimensions: "",
      purifierBrand: "",
      brandGuid: null,
      modelGuid: null,
      canalGuid: null,
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      brandNameError: CgnMessage.textErrorMessage,
      modelNameError: CgnMessage.textErrorMessage,
      canalNameError: CgnMessage.textErrorMessage,
      machineSerialNoError: CgnMessage.textErrorMessage,
    });
  }

  handleCanalSelectChange(option) {
    if (option) {
      this.setState({
        canalGuid: option.value,
        canalNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        canalGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        canalNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleBrandSelectChange(option) {
    if (option) {
      this.setState({
        brandGuid: option.value,
        brandNameError: "",
        alertErrorMessage: "",
        isLoading: true,
      });
      
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2;
      axios
        .get(
          URL + "/model/getlistmodels/" + option.value + "/" + statusType,
          config
        )
        .then((response) => {
          var myArr1 = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            modelArr: myArr1,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            brandNameError: CgnMessage.textErrorMessage,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        brandGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        brandNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleModelSelectChange(option) {
    if (option) {
      this.setState({
        modelGuid: option.value,
        modelNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        modelGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        modelNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleDateChange(customsEntryDate) {
    if (customsEntryDate) {
      this.setState({
        customsEntryDate: customsEntryDate,
        customsEntryDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customsEntryDate: "",
        customsEntryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      modelNameError: "",
      brandNameError: "",
      canalNameError: "",
      customsEntryDateError: "",
      machineSerialNoError: "",
    });
    if (this.state.machineSerialNo === "" || this.state.machineSerialNo === undefined) {
      this.setState({
        machineSerialNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.canalGuid === null) {
      this.setState({
        canalNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.brandGuid === null) {
      this.setState({
        brandNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.modelGuid === null) {
      this.setState({
        modelNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customsEntryDate === "" || this.state.customsEntryDate === undefined) {
      this.setState({
        customsEntryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.customsEntryDate === "" &&
      this.state.modelGuid === null &&
      this.state.brandGuid === null &&
      this.state.canalGuid === null &&
      this.state.machineSerialNo === ""
    ) {
      this.setState({
        canalNameError: CgnMessage.textErrorMessage,
        modelNameError: CgnMessage.textErrorMessage,
        brandNameError: CgnMessage.textErrorMessage,
        customsEntryDateError: CgnMessage.textErrorMessage,
        machineSerialNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.machineSerialNo !== "" &&
      this.state.canalGuid !== null &&
      this.state.brandGuid !== null &&
      this.state.modelGuid !== null &&
      this.state.customsEntryDate !== ""
    ) {
      this.setState({
        modelNameError: "",
        brandNameError: "",
        canalNameError: "",
        customsEntryDateError: "",
        machineSerialNoError: "",
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        status: this.state.status,
        brandGuid: this.state.brandGuid,
        canalGuid: this.state.canalGuid,
        modelGuid: this.state.modelGuid,
        machineSerialNo: this.state.machineSerialNo,
        customsEntryDate: moment(this.state.customsEntryDate).format(
          "DD/MM/YYYY"
        ),
        refrigeratorModel: this.state.refrigeratorModel,
        coolerSerialNo: this.state.coolerSerialNo,
        vendonSerialNo: this.state.vendonSerialNo,
        standModel: this.state.standModel,
        standDimensions: this.state.standDimensions,
        purifierBrand: this.state.purifierBrand,
      };
      var URLParam = "/machines/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "makine-guncelle"
      ) {
        URLParam = "/machines/update";
        myObj = {
          guid: this.state.guid,
          customsEntryDate:
            this.state.customsEntryDate &&
            this.state.customsEntryDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.customsEntryDate.split("/")[2] +
                    "-" +
                    this.state.customsEntryDate.split("/")[1] +
                    "-" +
                    this.state.customsEntryDate.split("/")[0]
                )
              : moment(this.state.customsEntryDate).format("DD/MM/YYYY"),
          status: this.state.status,
          brandGuid: this.state.brandGuid,
          canalGuid: this.state.canalGuid,
          modelGuid: this.state.modelGuid,
          machineSerialNo: this.state.machineSerialNo,
          refrigeratorModel: this.state.refrigeratorModel,
          coolerSerialNo: this.state.coolerSerialNo,
          vendonSerialNo: this.state.vendonSerialNo,
          standModel: this.state.standModel,
          standDimensions: this.state.standDimensions,
          purifierBrand: this.state.purifierBrand,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "makine-guncelle"
            ) {
              window.location.href = "/makine-listele";
            }
            if (this.state.urlSplit[1] === "makine-ekle") {
              window.location.reload();
            }
            var modalAddedMachine = {
              data: this.state.machineSerialNo,
              isModal: false,
            };
            this.props.history.push({ modalAddedMachine });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "makine-ekle" ||
          this.state.urlSplit[1] === "makine-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="canalGuid"
                          label="Kanal [*]"
                          placeholder="Kanal seçiniz..."
                          selectValue={this.state.canalGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCanalSelectChange(option)
                          }
                          options={this.state.canalArr}
                          error={this.state.canalNameError}
                        />
                      </div>
                      {this.state.canalModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCanal()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="brandGuid"
                          label="Marka [*]"
                          placeholder="Marka seçiniz..."
                          selectValue={this.state.brandGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleBrandSelectChange(option)
                          }
                          options={this.state.brandArr}
                          error={this.state.brandNameError}
                        />
                      </div>
                      {this.state.brandModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalBrand()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="modelGuid"
                          label="Model [*]"
                          placeholder="Model seçiniz..."
                          selectValue={this.state.modelGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleModelSelectChange(option)
                          }
                          options={this.state.modelArr}
                          error={this.state.modelNameError}
                        />
                      </div>
                      {this.state.modelModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalModel()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="customsEntryDate"
                      label="Gümrük Giriş Tarihi [*]"
                      selected={
                        this.state.customsEntryDate &&
                        this.state.customsEntryDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.customsEntryDate.split("/")[2] +
                                "-" +
                                this.state.customsEntryDate.split("/")[1] +
                                "-" +
                                this.state.customsEntryDate.split("/")[0]
                            )
                          : this.state.customsEntryDate
                      }
                      onChange={(customsEntryDate) =>
                        this.handleDateChange(customsEntryDate)
                      }
                      error={this.state.customsEntryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="machineSerialNo"
                      label="Makine Seri No [*]"
                      value={this.state.machineSerialNo}
                      placeHolder="Lütfen makine seri no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.machineSerialNoError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="refrigeratorModel"
                      label="Buzdolabı Model"
                      value={this.state.refrigeratorModel}
                      placeHolder="Lütfen buzdolabı model girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNotChange}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="coolerSerialNo"
                      label="Soğutucu Seri No"
                      value={this.state.coolerSerialNo}
                      placeHolder="Lütfen soğutucu seri no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNotChange}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="vendonSerialNo"
                      label="Vendon Seri No"
                      value={this.state.vendonSerialNo}
                      placeHolder="Lütfen vendon seri no girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNotChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="standModel"
                      label="Stand Model"
                      value={this.state.standModel}
                      placeHolder="Lütfen stand model girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNotChange}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="standDimensions"
                      label="Stand Ebatı"
                      value={this.state.standDimensions}
                      placeHolder="Lütfen stand ebatı girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNotChange}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="purifierBrand"
                      label="Arıtma Cihazı Marka"
                      value={this.state.purifierBrand}
                      placeHolder="Lütfen arıtma cihazı marka girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNotChange}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "makine-ekle" ||
              this.state.urlSplit[1] === "makine-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCanal}
          toggle={this.addModalCanal}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Kanal Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CanalAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCanal}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalBrand}
          toggle={this.addModalBrand}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Marka Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <BrandAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBrand}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalModel}
          toggle={this.addModalModel}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Model Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ModelAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalModel}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(MachineAddUpdate);
