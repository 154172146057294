import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class UserLoginInformationList extends Component {
  state = {
    pageTitle: "Kullanıcı Giriş Bilgileri Listele",
    breadcrumb: [{ text: "Kullanıcı", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    userLoginInformationDtos: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateMachineService")) {
      firstDate = localStorage.getItem("firstDateMachineService");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateMachineService")) {
      lastDate = localStorage.getItem("lastDateMachineService");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateMachineService", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateMachineService", this.state.lastDate);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    URLParam =
      "/userlogininformation/getlist/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate;
    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          userLoginInformationDtos: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row className="UserLoginInformationList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="5">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.firstDate2.split("/")[2] +
                          "-" +
                          this.state.firstDate2.split("/")[1] +
                          "-" +
                          this.state.firstDate2.split("/")[0]
                        )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="5">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.lastDate2.split("/")[2] +
                          "-" +
                          this.state.lastDate2.split("/")[1] +
                          "-" +
                          this.state.lastDate2.split("/")[0]
                        )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-0" md="2">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.userLoginInformationDtos}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Tarih Saat",
                    selector: "dateTime",
                    width: "225px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => { return <span>{row.dateTime}</span>; }
                  },
                  {
                    name: "Kullanıcı",
                    selector: "memberName",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => { return <span>{row.memberName}</span>; }
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default UserLoginInformationList;
