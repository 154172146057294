import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { GetMonthLastDateYear, URL, GetMonthFirstDateYearElkapol, GetMonthFirstDate, GetMonthLastDate, GetMonthFirstDate2, GetMonthLastDate2 } from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import { Pie } from "react-chartjs-2";

class OfferSieveReport extends Component {

  emptyChart = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      }
    ],
  };

  state = {
    pageTitle: "Yurt Dışı Teklif Raporları",
    pageTitleDetail: "Yurt Dışı Teklif Raporları",
    breadcrumb: [{ text: "Teklif", link: "#" }],
    isLoading: false,
    dataGuid: "0955275F-E9C7-408D-BB40-4EC9088995D2",
    data: null,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    urunGruplariChartDataTL: this.emptyChart,
    urunGruplariChartDataDolar: this.emptyChart,
    urunGruplariChartDataEuro: this.emptyChart,
    urunGruplariChartDataIsvicreFrangi: this.emptyChart,
    karOraniChartDataTL: this.emptyChart,
    karOraniChartDataDolar: this.emptyChart,
    karOraniChartDataEuro: this.emptyChart,
    karOraniChartDataIsvicreFrangi: this.emptyChart,
    teklifChartData: this.emptyChart,
    ulkeToplamChartData: this.emptyChart,
    ulkeToplamOnaylananChartData: this.emptyChart,
    ulkeToplamBekleyenChartData: this.emptyChart,
  };

  async componentDidMount() {
    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateOfferSieve")) {
      firstDate = localStorage.getItem("firstDateOfferSieve");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateOfferSieve")) {
      lastDate = localStorage.getItem("lastDateOfferSieve");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    let urlSplit = window.location.pathname.split("/");
    if(urlSplit.length === 2) {
      if(urlSplit[1] === "teklif32-raporlari") {
        await this.setState({
          pageTitle: "Yurt Dışı Teklif Raporları",
          pageTitleDetail: "Yurt Dışı Teklif Raporları",
          dataGuid: "0955275F-E9C7-408D-BB40-4EC9088995D2"
        })
        this.getList();
      } else if(urlSplit[1] === "teklif33-raporlari") {
        await this.setState({
          pageTitle: "Yurt İçi Teklif Raporları",
          pageTitleDetail: "Yurt İçi Teklif Raporları",
          dataGuid: "335D0B2A-9BDB-4E64-9373-5FB5D5B8A4CB"
        })
        this.getList();
      }
    }
  }

  getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  UrunGrubuGrafikOlustur(response) {
    const totalAmounts = response.data.urunGruplari.reduce((totals, group) => {
      totals.tl += group.tutar.tl;
      totals.dolar += group.tutar.dolar;
      totals.euro += group.tutar.euro;
      totals.isvicre_Frangi += group.tutar.isvicre_Frangi;
      return totals;
    }, { tl: 0, dolar: 0, euro: 0, isvicre_Frangi: 0 });

    const urunGruplariWithPercentage = response.data.urunGruplari.map(group => ({
      ...group,
      percentages: {
        tl: (group.tutar.tl / totalAmounts.tl) * 100 || 0,
        dolar: (group.tutar.dolar / totalAmounts.dolar) * 100 || 0,
        euro: (group.tutar.euro / totalAmounts.euro) * 100 || 0,
        isvicre_Frangi: (group.tutar.isvicre_Frangi / totalAmounts.isvicre_Frangi) * 100 || 0,
      }
    }));

    this.setState({
      urunGruplariChartDataTL: {
        labels: urunGruplariWithPercentage.map(group => `${group.urunGrubuAdi} (${group.percentages.tl.toFixed(2)}%)`),
        datasets: [
          {
            label: "",
            data: urunGruplariWithPercentage.map(group => group.percentages.tl),
            backgroundColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderWidth: 1,
          }
        ],
      },
      urunGruplariChartDataDolar: {
        labels: urunGruplariWithPercentage.map(group => `${group.urunGrubuAdi} (${group.percentages.dolar.toFixed(2)}%)`),
        datasets: [
          {
            label: "",
            data: urunGruplariWithPercentage.map(group => group.percentages.dolar),
            backgroundColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderWidth: 1,
          }
        ],
      },
      urunGruplariChartDataEuro: {
        labels: urunGruplariWithPercentage.map(group => `${group.urunGrubuAdi} (${group.percentages.euro.toFixed(2)}%)`),
        datasets: [
          {
            label: "",
            data: urunGruplariWithPercentage.map(group => group.percentages.euro),
            backgroundColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderWidth: 1,
          }
        ],
      },
      urunGruplariChartDataIsvicreFrangi: {
        labels: urunGruplariWithPercentage.map(group => `${group.urunGrubuAdi} (${group.percentages.isvicre_Frangi.toFixed(2)}%)`),
        datasets: [
          {
            label: "",
            data: urunGruplariWithPercentage.map(group => group.percentages.isvicre_Frangi),
            backgroundColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderColor: urunGruplariWithPercentage.map(() => this.getRandomColor()),
            borderWidth: 1,
          }
        ],
      }
    });
  }


  KarOraniGrafikOlustur(response) {
    const toplamTL = response.data.alisTutari.tl + response.data.satisTutari.tl;
    const toplamDolar = response.data.alisTutari.dolar + response.data.satisTutari.dolar;
    const toplamEuro = response.data.alisTutari.euro + response.data.satisTutari.euro;
    const toplamIsvicreFrangi = response.data.alisTutari.isvicre_Frangi + response.data.satisTutari.isvicre_Frangi;

    const alisYuzdeTL = toplamTL === 0 ? 0 : (response.data.alisTutari.tl / toplamTL) * 100;
    const alisYuzdeDolar = toplamDolar === 0 ? 0 : (response.data.alisTutari.dolar / toplamDolar) * 100;
    const alisYuzdeEuro = toplamEuro === 0 ? 0 : (response.data.alisTutari.euro / toplamEuro) * 100;
    const alisYuzdeIsvicreFrangi = toplamIsvicreFrangi === 0 ? 0 : (response.data.alisTutari.isvicre_Frangi / toplamIsvicreFrangi) * 100;

    const satisYuzdeTL = toplamTL === 0 ? 0 : (response.data.satisTutari.tl / toplamTL) * 100;
    const satisYuzdeDolar = toplamDolar === 0 ? 0 : (response.data.satisTutari.dolar / toplamDolar) * 100;
    const satisYuzdeEuro = toplamEuro === 0 ? 0 : (response.data.satisTutari.euro / toplamEuro) * 100;
    const satisYuzdeIsvicreFrangi = toplamIsvicreFrangi === 0 ? 0 : (response.data.satisTutari.isvicre_Frangi / toplamIsvicreFrangi) * 100;

    this.setState({
      karOraniChartDataTL: {
        labels: [`Alış Tutarı (${alisYuzdeTL.toFixed(2)}%)`, `Satış Tutarı (${satisYuzdeTL.toFixed(2)}%)`],
        datasets: [
          {
            label: "",
            data: [alisYuzdeTL, satisYuzdeTL],
            backgroundColor: [this.getRandomColor(), this.getRandomColor()],
            borderColor: [this.getRandomColor(), this.getRandomColor()],
            borderWidth: 1,
          }
        ],
      },
      karOraniChartDataDolar: {
        labels: [`Alış Tutarı (${alisYuzdeDolar.toFixed(2)}%)`, `Satış Tutarı (${satisYuzdeDolar.toFixed(2)}%)`],
        datasets: [
          {
            label: "",
            data: [alisYuzdeDolar, satisYuzdeDolar],
            backgroundColor: [this.getRandomColor(), this.getRandomColor()],
            borderColor: [this.getRandomColor(), this.getRandomColor()],
            borderWidth: 1,
          }
        ],
      },
      karOraniChartDataEuro: {
        labels: [`Alış Tutarı (${alisYuzdeEuro.toFixed(2)}%)`, `Satış Tutarı (${satisYuzdeEuro.toFixed(2)}%)`],
        datasets: [
          {
            label: "",
            data: [alisYuzdeEuro, satisYuzdeEuro],
            backgroundColor: [this.getRandomColor(), this.getRandomColor()],
            borderColor: [this.getRandomColor(), this.getRandomColor()],
            borderWidth: 1,
          }
        ],
      },
      karOraniChartDataIsvicreFrangi: {
        labels: [`Alış Tutarı (${alisYuzdeIsvicreFrangi.toFixed(2)}%)`, `Satış Tutarı (${satisYuzdeIsvicreFrangi.toFixed(2)}%)`],
        datasets: [
          {
            label: "",
            data: [alisYuzdeIsvicreFrangi, satisYuzdeIsvicreFrangi],
            backgroundColor: [this.getRandomColor(), this.getRandomColor()],
            borderColor: [this.getRandomColor(), this.getRandomColor()],
            borderWidth: 1,
          }
        ],
      }
    });
  }

  TeklifGrafikOlustur(response) {
    const beyleyenTeklifYuzde = response.data.toplam.teklifSayisi === 0 ? 0 : (response.data.toplam_Bekleyen.teklifSayisi / response.data.toplam.teklifSayisi) * 100;
    const onaylananTeklifYuzde = response.data.toplam.teklifSayisi === 0 ? 0 : (response.data.toplam_Onaylanan.teklifSayisi / response.data.toplam.teklifSayisi) * 100;

    this.setState({
      teklifChartData: {
        labels: [`Bekleyen Teklif Yüzdesi (${beyleyenTeklifYuzde.toFixed(2)}% - ${response.data.toplam_Bekleyen.teklifSayisi} Adet)`, `Onaylanan Teklif Yüzdesi (${onaylananTeklifYuzde.toFixed(2)}% - ${response.data.toplam_Onaylanan.teklifSayisi} Adet)`],
        datasets: [
          {
            label: "",
            data: [beyleyenTeklifYuzde, onaylananTeklifYuzde],
            backgroundColor: [this.getRandomColor(), this.getRandomColor()],
            borderColor: [this.getRandomColor(), this.getRandomColor()],
            borderWidth: 1,
          }
        ],
      }
    });
  }

  UlkeToplamGrafikOlustur(response) {
    const totalProposals = response.data.ulkeToplam.reduce((total, country) => total + country.toplam.teklifSayisi, 0);
    const ulkeToplamWithPercentage = response.data.ulkeToplam.map(country => ({
      ...country,
      percentage: (country.toplam.teklifSayisi / totalProposals) * 100
    }));

    this.setState({
      ulkeToplamChartData: {
        labels: ulkeToplamWithPercentage.map(country => `${country.ulkeAdi} (${country.percentage.toFixed(2)}% - ${country.toplam.teklifSayisi} Adet)`),
        datasets: [
          {
            label: "",
            data: ulkeToplamWithPercentage.map(country => country.percentage),
            backgroundColor: ulkeToplamWithPercentage.map(() => this.getRandomColor()),
            borderColor: ulkeToplamWithPercentage.map(() => this.getRandomColor()),
            borderWidth: 1,
          }
        ],
      }
    });
  }

  UlkeToplamOnaylananGrafikOlustur(response) {
    const totalProposals = response.data.ulkeToplam.reduce((total, country) => total + country.toplam_Onaylanan.teklifSayisi, 0);
    const ulkeToplamWithPercentage = response.data.ulkeToplam.map(country => ({
      ...country,
      percentage: (country.toplam_Onaylanan.teklifSayisi / totalProposals) * 100
    }));

    this.setState({
      ulkeToplamOnaylananChartData: {
        labels: ulkeToplamWithPercentage.map(country => `${country.ulkeAdi} (${country.percentage.toFixed(2)}% - ${country.toplam_Onaylanan.teklifSayisi} Adet)`),
        datasets: [
          {
            label: "",
            data: ulkeToplamWithPercentage.map(country => country.percentage),
            backgroundColor: ulkeToplamWithPercentage.map(() => this.getRandomColor()),
            borderColor: ulkeToplamWithPercentage.map(() => this.getRandomColor()),
            borderWidth: 1,
          }
        ],
      }
    });
  }

  UlkeToplamBekleyenGrafikOlustur(response) {
    const totalProposals = response.data.ulkeToplam.reduce((total, country) => total + country.toplam_Bekleyen.teklifSayisi, 0);
    const ulkeToplamWithPercentage = response.data.ulkeToplam.map(country => ({
      ...country,
      percentage: (country.toplam_Bekleyen.teklifSayisi / totalProposals) * 100
    }));

    this.setState({
      ulkeToplamBekleyenChartData: {
        labels: ulkeToplamWithPercentage.map(country => `${country.ulkeAdi} (${country.percentage.toFixed(2)}% - ${country.toplam_Bekleyen.teklifSayisi} Adet)`),
        datasets: [
          {
            label: "",
            data: ulkeToplamWithPercentage.map(country => country.percentage),
            backgroundColor: ulkeToplamWithPercentage.map(() => this.getRandomColor()),
            borderColor: ulkeToplamWithPercentage.map(() => this.getRandomColor()),
            borderWidth: 1,
          }
        ],
      }
    });
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/report/" + this.state.dataGuid + "/" +
        (this.state.firstDate2.getFullYear() + "-" + (this.state.firstDate2.getMonth() + 1) + "-" + this.state.firstDate2.getDate()) + "/" +
        (this.state.lastDate2.getFullYear() + "-" + (this.state.lastDate2.getMonth() + 1) + "-" + this.state.lastDate2.getDate()), config)
      .then((response) => {
        this.UrunGrubuGrafikOlustur(response);
        this.KarOraniGrafikOlustur(response);
        this.TeklifGrafikOlustur(response);
        this.UlkeToplamGrafikOlustur(response);
        this.UlkeToplamOnaylananGrafikOlustur(response);
        this.UlkeToplamBekleyenGrafikOlustur(response);

        this.setState({
          data: response.data,
          isLoading: false
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDateLocal = GetMonthFirstDateYearElkapol();
      await this.setState({
        firstDate: firstDateLocal,
        firstDate2: firstDateLocal,
      });
    }
    localStorage.setItem("firstDateOfferSieveReport", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDateLocal = GetMonthLastDateYear();
      await this.setState({
        lastDate: lastDateLocal,
        lastDate2: lastDateLocal,
      });
    }
    localStorage.setItem("lastDateOfferSieveReport", this.state.lastDate);
  }

  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  handleClick = () => {
    if (this.divRef.current) {
      window.print();
    }
  };

  render() {
    const tableBosluk = {
      marginBottom: "25px",
      border: "1px solid #ddd"
    }
    const h2First = {
      marginBottom: "10px"
    }
    const h2Second = {
      marginTop: "15px",
      marginBottom: "10px"
    }
    const h2 = {
      marginTop: "15px",
      marginBottom: "10px"
    }
    const h2Center = {
      marginTop: "15px",
      marginBottom: "10px",
      textAlign: "center"
    }
    const table = {
      border: "1px solid #ddd"
    }
    const tableTh = {
      borderRight: "1px solid #ddd",
      paddingLeft: "10px",
      backgroundColor: "#f7f7f7"
    };
    const tableTh2 = {
      borderRight: "1px solid #ddd",
      paddingLeft: "10px",
      backgroundColor: "#f7f7f7",
      textAlign: "center"
    };
    const tableThIndex = {
      borderRight: "1px solid #ddd",
      paddingLeft: "10px",
      backgroundColor: "#f7f7f7",
      maxWidth: "50px",
      textAlign: "center",
    };
    const tableThTutar = {
      borderRight: "1px solid #ddd",
      paddingLeft: "10px",
      backgroundColor: "#f7f7f7",
      maxWidth: "100px",
      textAlign: "center",
    };
    const tableTd = {
      borderRight: "1px solid #ddd",
      textAlign: "center",
      minWidth: "100px",
    };
    const tableTdIndex = {
      borderRight: "1px solid #ddd",
      textAlign: "center",
      maxWidth: "50px"
    };
    const tableTdTutar = {
      borderRight: "1px solid #ddd",
      textAlign: "center",
      maxWidth: "100px"
    };
    const chartRow = {
      border: "1px solid #ddd",
      marginTop: "15px",
      marginBottom: "15px",
      paddingBottom: "15px"
    }
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          printLink={this.handleClick}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" xs="5">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.firstDate2.split("/")[2] +
                          "-" +
                          this.state.firstDate2.split("/")[1] +
                          "-" +
                          this.state.firstDate2.split("/")[0]
                        )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" xs="5">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.lastDate2.split("/")[2] +
                          "-" +
                          this.state.lastDate2.split("/")[1] +
                          "-" +
                          this.state.lastDate2.split("/")[0]
                        )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-0" xs="2">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <div ref={this.divRef} className="print">
                <h1 style={h2}>TEKLİF SAYISI VE SATIŞ TUTARI</h1>
                <h2 style={h2}>Toplam</h2>
                <Table style={table}>
                  <tr>
                    <th style={tableTh}>Teklif Sayısı</th>
                    <td style={tableTd}>{this.state.data?.toplam?.teklifSayisi ?? "0"}</td>
                    <th style={tableTh}>Türk Lirası</th>
                    <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.data?.toplam?.tutar?.tl ?? 0)}</td>
                    <th style={tableTh}>Dolar</th>
                    <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.data?.toplam?.tutar?.dolar ?? 0)}</td>
                    <th style={tableTh}>Euro</th>
                    <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.data?.toplam?.tutar?.euro ?? 0)}</td>
                    <th style={tableTh}>İşviçre Frangı</th>
                    <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(this.state.data?.toplam?.tutar?.isvicre_Frangi ?? 0)}</td>
                  </tr>
                </Table>
                <h2 style={h2}>Toplam Bekleyen</h2>
                <Table style={table}>
                  <tr>
                    <th style={tableTh}>Teklif Sayısı</th>
                    <td style={tableTd}>{this.state.data?.toplam_Bekleyen?.teklifSayisi ?? "0"}</td>
                    <th style={tableTh}>Türk Lirası</th>
                    <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.data?.toplam_Bekleyen?.tutar?.tl ?? 0)}</td>
                    <th style={tableTh}>Dolar</th>
                    <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.data?.toplam_Bekleyen?.tutar?.dolar ?? 0)}</td>
                    <th style={tableTh}>Euro</th>
                    <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.data?.toplam_Bekleyen?.tutar?.euro ?? 0)}</td>
                    <th style={tableTh}>İşviçre Frangı</th>
                    <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(this.state.data?.toplam_Bekleyen?.tutar?.isvicre_Frangi ?? 0)}</td>
                  </tr>
                </Table>
                <h2 style={h2}>Toplam Onaylanan</h2>
                <Table style={tableBosluk}>
                  <tr>
                    <th style={tableTh}>Teklif Sayısı</th>
                    <td style={tableTd}>{this.state.data?.toplam_Onaylanan?.teklifSayisi ?? "0"}</td>
                    <th style={tableTh}>Türk Lirası</th>
                    <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.data?.toplam_Onaylanan?.tutar?.tl ?? 0)}</td>
                    <th style={tableTh}>Dolar</th>
                    <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.data?.toplam_Onaylanan?.tutar?.dolar ?? 0)}</td>
                    <th style={tableTh}>Euro</th>
                    <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.data?.toplam_Onaylanan?.tutar?.euro ?? 0)}</td>
                    <th style={tableTh}>İşviçre Frangı</th>
                    <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(this.state.data?.toplam_Onaylanan?.tutar?.isvicre_Frangi ?? 0)}</td>
                  </tr>
                </Table>
                <Row style={chartRow}>
                  <Col lg={3}>
                    <h2 style={h2Center}>TEKLİF YÜZDESİ</h2>
                    <Pie data={this.state.teklifChartData} />
                  </Col>
                  <Col lg={3}>
                    <h2 style={h2Center}>ÜLKE TEKLİF YÜZDESİ</h2>
                    <Pie data={this.state.ulkeToplamChartData} />
                  </Col>
                  <Col lg={3}>
                    <h2 style={h2Center}>ÜLKE ONAYLANAN TEKLİF YÜZDESİ</h2>
                    <Pie data={this.state.ulkeToplamOnaylananChartData} />
                  </Col>
                  <Col lg={3}>
                    <h2 style={h2Center}>ÜLKE BEKLEYEN TEKLİF YÜZDESİ</h2>
                    <Pie data={this.state.ulkeToplamBekleyenChartData} />
                  </Col>
                </Row>
                <h1 style={h2}>ÜLKENİN ŞEHİRLERİNE GÖRE TEKLİF SAYISI VE SATIŞ TUTARI</h1>
                {this.state.data?.ulkeSehirToplam?.map(ulkeSehir => (
                  <>
                    <h1 style={h2Second}>{ulkeSehir?.ulkeAdi + (ulkeSehir?.sehirToplam?.sehirAdi ? "-" + ulkeSehir?.sehirToplam?.sehirAdi : "")}</h1>
                    <h2 style={h2First}>Toplam</h2>
                    <Table style={table}>
                      <tr>
                        <th style={tableTh}>Teklif Sayısı</th>
                        <td style={tableTd}>{ulkeSehir?.sehirToplam?.toplam?.teklifSayisi ?? "0"}</td>
                        <th style={tableTh}>Türk Lirası</th>
                        <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(ulkeSehir?.sehirToplam?.toplam?.tutar?.tl ?? 0)}</td>
                        <th style={tableTh}>Dolar</th>
                        <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ulkeSehir?.sehirToplam?.toplam?.tutar?.dolar ?? 0)}</td>
                        <th style={tableTh}>Euro</th>
                        <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ulkeSehir?.sehirToplam?.toplam?.tutar?.euro ?? 0)}</td>
                        <th style={tableTh}>İşviçre Frangı</th>
                        <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(ulkeSehir?.sehirToplam?.toplam?.tutar?.isvicre_Frangi ?? 0)}</td>
                      </tr>
                    </Table>
                    <h2 style={h2}>Toplam Bekleyen</h2>
                    <Table style={table}>
                      <tr>
                        <th style={tableTh}>Teklif Sayısı</th>
                        <td style={tableTd}>{ulkeSehir?.sehirToplam?.toplam_Bekleyen?.teklifSayisi ?? "0"}</td>
                        <th style={tableTh}>Türk Lirası</th>
                        <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(ulkeSehir?.sehirToplam?.toplam_Bekleyen?.tutar?.tl ?? 0)}</td>
                        <th style={tableTh}>Dolar</th>
                        <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ulkeSehir?.sehirToplam?.toplam_Bekleyen?.tutar?.dolar ?? 0)}</td>
                        <th style={tableTh}>Euro</th>
                        <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ulkeSehir?.sehirToplam?.toplam_Bekleyen?.tutar?.euro ?? 0)}</td>
                        <th style={tableTh}>İşviçre Frangı</th>
                        <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(ulkeSehir?.sehirToplam?.toplam_Bekleyen?.tutar?.isvicre_Frangi ?? 0)}</td>
                      </tr>
                    </Table>
                    <h2 style={h2}>Toplam Onaylanan</h2>
                    <Table style={tableBosluk}>
                      <tr>
                        <th style={tableTh}>Teklif Sayısı</th>
                        <td style={tableTd}>{ulkeSehir?.sehirToplam?.toplam_Onaylanan?.teklifSayisi ?? "0"}</td>
                        <th style={tableTh}>Türk Lirası</th>
                        <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(ulkeSehir?.sehirToplam?.toplam_Onaylanan?.tutar?.tl ?? 0)}</td>
                        <th style={tableTh}>Dolar</th>
                        <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ulkeSehir?.sehirToplam?.toplam_Onaylanan?.tutar?.dolar ?? 0)}</td>
                        <th style={tableTh}>Euro</th>
                        <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ulkeSehir?.sehirToplam?.toplam_Onaylanan?.tutar?.euro ?? 0)}</td>
                        <th style={tableTh}>İşviçre Frangı</th>
                        <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(ulkeSehir?.sehirToplam?.toplam_Onaylanan?.tutar?.isvicre_Frangi ?? 0)}</td>
                      </tr>
                    </Table>
                  </>
                ))}
                <h1 style={h2}>ÜLKELERE GÖRE TEKLİF SAYISI VE SATIŞ TUTARI</h1>
                {this.state.data?.ulkeToplam?.map(ulke => (
                  <>
                    <h1 style={h2Second}>{ulke?.ulkeAdi}</h1>
                    <h2 style={h2First}>Toplam</h2>
                    <Table style={table}>
                      <tr>
                        <th style={tableTh}>Teklif Sayısı</th>
                        <td style={tableTd}>{ulke?.toplam?.teklifSayisi ?? "0"}</td>
                        <th style={tableTh}>Türk Lirası</th>
                        <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(ulke?.toplam?.tutar?.tl ?? 0)}</td>
                        <th style={tableTh}>Dolar</th>
                        <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ulke?.toplam?.tutar?.dolar ?? 0)}</td>
                        <th style={tableTh}>Euro</th>
                        <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ulke?.toplam?.tutar?.euro ?? 0)}</td>
                        <th style={tableTh}>İşviçre Frangı</th>
                        <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(ulke?.toplam?.tutar?.isvicre_Frangi ?? 0)}</td>
                      </tr>
                    </Table>
                    <h2 style={h2}>Toplam Bekleyen</h2>
                    <Table style={table}>
                      <tr>
                        <th style={tableTh}>Teklif Sayısı</th>
                        <td style={tableTd}>{ulke?.toplam_Bekleyen?.teklifSayisi ?? "0"}</td>
                        <th style={tableTh}>Türk Lirası</th>
                        <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(ulke?.toplam_Bekleyen?.tutar?.tl ?? 0)}</td>
                        <th style={tableTh}>Dolar</th>
                        <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ulke?.toplam_Bekleyen?.tutar?.dolar ?? 0)}</td>
                        <th style={tableTh}>Euro</th>
                        <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ulke?.toplam_Bekleyen?.tutar?.euro ?? 0)}</td>
                        <th style={tableTh}>İşviçre Frangı</th>
                        <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(ulke?.toplam_Bekleyen?.tutar?.isvicre_Frangi ?? 0)}</td>
                      </tr>
                    </Table>
                    <h2 style={h2}>Toplam Onaylanan</h2>
                    <Table style={tableBosluk}>
                      <tr>
                        <th style={tableTh}>Teklif Sayısı</th>
                        <td style={tableTd}>{ulke?.toplam_Onaylanan?.teklifSayisi ?? "0"}</td>
                        <th style={tableTh}>Türk Lirası</th>
                        <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(ulke?.toplam_Onaylanan?.tutar?.tl ?? 0)}</td>
                        <th style={tableTh}>Dolar</th>
                        <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ulke?.toplam_Onaylanan?.tutar?.dolar ?? 0)}</td>
                        <th style={tableTh}>Euro</th>
                        <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ulke?.toplam_Onaylanan?.tutar?.euro ?? 0)}</td>
                        <th style={tableTh}>İşviçre Frangı</th>
                        <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(ulke?.toplam_Onaylanan?.tutar?.isvicre_Frangi ?? 0)}</td>
                      </tr>
                    </Table>
                  </>
                ))}
                <h1 style={h2}>ALIŞ SATIŞ TUTARI</h1>
                <h2 style={h2First}>Alış Tutarı</h2>
                <Table style={table}>
                  <tr>
                    <th style={tableTh}>Türk Lirası</th>
                    <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.data?.alisTutari?.tl ?? 0)}</td>
                    <th style={tableTh}>Dolar</th>
                    <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.data?.alisTutari?.dolar ?? 0)}</td>
                    <th style={tableTh}>Euro</th>
                    <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.data?.alisTutari?.euro ?? 0)}</td>
                    <th style={tableTh}>İşviçre Frangı</th>
                    <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(this.state.data?.alisTutari?.isvicre_Frangi ?? 0)}</td>
                  </tr>
                </Table>
                <h2 style={h2}>Satış Tutarı</h2>
                <Table style={table}>
                  <tr>
                    <th style={tableTh}>Türk Lirası</th>
                    <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.data?.satisTutari?.tl ?? 0)}</td>
                    <th style={tableTh}>Dolar</th>
                    <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.data?.satisTutari?.dolar ?? 0)}</td>
                    <th style={tableTh}>Euro</th>
                    <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.data?.satisTutari?.euro ?? 0)}</td>
                    <th style={tableTh}>İşviçre Frangı</th>
                    <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(this.state.data?.satisTutari?.isvicre_Frangi ?? 0)}</td>
                  </tr>
                </Table>
                <h1 style={h2}>KAR</h1>
                <h2 style={h2First}>Kar Tutarı</h2>
                <Table style={table}>
                  <tr>
                    <th style={tableTh}>Türk Lirası</th>
                    <td style={tableTd}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.data?.karTutari?.tl ?? 0)}</td>
                    <th style={tableTh}>Dolar</th>
                    <td style={tableTd}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.data?.karTutari?.dolar ?? 0)}</td>
                    <th style={tableTh}>Euro</th>
                    <td style={tableTd}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.data?.karTutari?.euro ?? 0)}</td>
                    <th style={tableTh}>İşviçre Frangı</th>
                    <td style={tableTd}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(this.state.data?.karTutari?.isvicre_Frangi ?? 0)}</td>
                  </tr>
                </Table>
                <Row style={chartRow}>
                  <Col md={3}>
                    <h2 style={h2Center}>TRY KAR YÜZDESİ</h2>
                    <Pie data={this.state.karOraniChartDataTL} />
                  </Col>
                  <Col md={3}>
                    <h2 style={h2Center}>USD KAR YÜZDESİ</h2>
                    <Pie data={this.state.karOraniChartDataDolar} />
                  </Col>
                  <Col md={3}>
                    <h2 style={h2Center}>EUR KAR YÜZDESİ</h2>
                    <Pie data={this.state.karOraniChartDataEuro} />
                  </Col>
                  <Col md={3}>
                    <h2 style={h2Center}>CHF KAR YÜZDESİ</h2>
                    <Pie data={this.state.karOraniChartDataIsvicreFrangi} />
                  </Col>
                </Row>
                {this.state.data?.urunGruplari?.length > 0 &&
                  <>
                    <h1 style={h2}>ÜRÜN GRUPLARI SATIŞ TUTARI</h1>
                    <Table style={table}>
                      <tr>
                        <th style={tableTh2}>Ürün Grubu</th>
                        <th style={tableThTutar}>₺</th>
                        <th style={tableThTutar}>$</th>
                        <th style={tableThTutar}>€</th>
                        <th style={tableThTutar}>CHF</th>
                      </tr>
                      {this.state.data?.urunGruplari?.map((urunGrubu, index) => (
                        <tr>
                          <td style={tableTd}>{urunGrubu.urunGrubuAdi}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(urunGrubu.tutar?.tl ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(urunGrubu.tutar?.dolar ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(urunGrubu.tutar?.euro ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(urunGrubu.tutar?.isvicre_Frangi ?? 0)}</td>
                        </tr>
                      ))}
                    </Table>
                    <Row style={chartRow}>
                      <Col lg={3}>
                        <h2 style={h2Center}>TRY SATIŞ YÜZDESİ</h2>
                        <Pie data={this.state.urunGruplariChartDataTL} />
                      </Col>
                      <Col lg={3}>
                        <h2 style={h2Center}>USD SATIŞ YÜZDESİ</h2>
                        <Pie data={this.state.urunGruplariChartDataDolar} />
                      </Col>
                      <Col lg={3}>
                        <h2 style={h2Center}>EUR SATIŞ YÜZDESİ</h2>
                        <Pie data={this.state.urunGruplariChartDataEuro} />
                      </Col>
                      <Col lg={3}>
                        <h2 style={h2Center}>CHF SATIŞ YÜZDESİ</h2>
                        <Pie data={this.state.urunGruplariChartDataIsvicreFrangi} />
                      </Col>
                    </Row>
                  </>
                }
                {this.state.data?.oncekiOnaylananTeklifler?.length > 0 &&
                  <>
                    <h1 style={h2}>GEÇMİŞ AYLARDAN KALAN ONAYLANAN TEKLİFLER</h1>
                    <Table style={tableBosluk}>
                      <tr>
                        <th style={tableThIndex}>#</th>
                        <th style={tableThTutar}>Sipariş Numarası</th>
                        <th style={tableThTutar}>Ülke</th>
                        <th style={tableTh2}>Müşteri</th>
                        <th style={tableTh2}>Ürün</th>
                        <th style={tableThTutar}>₺</th>
                        <th style={tableThTutar}>$</th>
                        <th style={tableThTutar}>€</th>
                        <th style={tableThTutar}>CHF</th>
                      </tr>
                      {this.state.data?.oncekiOnaylananTeklifler?.map((teklif, index) => (
                        <tr>
                          <td style={tableTdIndex}>{index + 1}</td>
                          <td style={tableTdTutar}>{teklif.siparisNumarasi}</td>
                          <td style={tableTdTutar}>{teklif.ulke}</td>
                          <td style={tableTd}>{teklif.musteri}</td>
                          <td style={tableTd}>{teklif.urun}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(teklif.tutar.tl ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(teklif.tutar.dolar ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(teklif.tutar.euro ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(teklif.tutar.isvicre_Frangi ?? 0)}</td>
                        </tr>
                      ))}
                    </Table>
                  </>
                }
                {this.state.data?.bekleyenTeklifler?.length > 0 &&
                  <>
                    <h1 style={h2}>BEKLEYEN TEKLİFLER</h1>
                    <Table style={tableBosluk}>
                      <tr>
                        <th style={tableThIndex}>#</th>
                        <th style={tableThTutar}>Sipariş Numarası</th>
                        <th style={tableThTutar}>Ülke</th>
                        <th style={tableTh2}>Müşteri</th>
                        <th style={tableTh2}>Ürün</th>
                        <th style={tableThTutar}>₺</th>
                        <th style={tableThTutar}>$</th>
                        <th style={tableThTutar}>€</th>
                        <th style={tableThTutar}>CHF</th>
                      </tr>
                      {this.state.data?.bekleyenTeklifler?.map((teklif, index) => (
                        <tr>
                          <td style={tableTdIndex}>{index + 1}</td>
                          <td style={tableTdTutar}>{teklif.siparisNumarasi}</td>
                          <td style={tableTdTutar}>{teklif.ulke}</td>
                          <td style={tableTd}>{teklif.musteri}</td>
                          <td style={tableTd}>{teklif.urun}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(teklif.tutar.tl ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(teklif.tutar.dolar ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(teklif.tutar.euro ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(teklif.tutar.isvicre_Frangi ?? 0)}</td>
                        </tr>
                      ))}
                    </Table>
                  </>
                }
                {this.state.data?.offerSieves_Garantiden_Giden?.length > 0 &&
                  <>
                    <h1 style={h2}>GARANTİDEN GİDEN SİPARİŞLER</h1>
                    <Table style={table}>
                      <tr>
                        <th style={tableThIndex}>#</th>
                        <th style={tableThTutar}>Sipariş Numarası</th>
                        <th style={tableThTutar}>Ülke</th>
                        <th style={tableTh2}>Müşteri</th>
                        <th style={tableTh2}>Ürün</th>
                        <th style={tableThTutar}>₺</th>
                        <th style={tableThTutar}>$</th>
                        <th style={tableThTutar}>€</th>
                        <th style={tableThTutar}>CHF</th>
                      </tr>
                      {this.state.data?.offerSieves_Garantiden_Giden?.map((teklif, index) => (
                        <tr>
                          <td style={tableTdIndex}>{index + 1}</td>
                          <td style={tableTdTutar}>{teklif.siparisNumarasi}</td>
                          <td style={tableTdTutar}>{teklif.ulke}</td>
                          <td style={tableTd}>{teklif.musteri}</td>
                          <td style={tableTd}>{teklif.urun}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(teklif.tutar.tl ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(teklif.tutar.dolar ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(teklif.tutar.euro ?? 0)}</td>
                          <td style={tableTdTutar}>{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(teklif.tutar.isvicre_Frangi ?? 0)}</td>
                        </tr>
                      ))}
                    </Table>
                  </>
                }
              </div>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default OfferSieveReport;
