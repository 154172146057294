import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import CgnButton from "../toolbox/CgnButton";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  PaymentTypeOptions,
  TaxTypeOptions,
  URL,
  
  localFilePath
} from "../../redux/actions/constants";
import moment from "moment";

class ProductOutDetail extends Component {
  state = {
    pageTitle: "Satış Detay",
    pageTitleDetail: "Satış Detay",
    pageAddLink: "satis-ekle",
    pageListLink: "satis-listele",
    breadcrumb: [{ text: "Satış", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    productOutDate: "",
    incomeDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    taxType: null,
    taxRate: null,
    taxtTotal: null,
    description: "",
    productDto: [],
    collectionCalendarDto: [],
    productName: "",
    currencyType: 0,
    piece: null,
    unitPrice: null,
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    billsProductOutGuid: "00000000-0000-0000-0000-000000000000",
    billsGuid: "00000000-0000-0000-0000-000000000000",
    pdfFileName: "",
    billsType: "",
    billsBillType: "",
    billsCancel: 0,
    billsCancelCogen: 0,
    bills: false,
    guid: "00000000-0000-0000-0000-000000000000"
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/productOuts/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            productOutDate: response.data.productOutDate,
            incomeDate: moment(response.data.incomeDate).format("DD/MM/YYYY"),
            productDto: response.data.productOutProductDto,
            collectionCalendarDto: response.data.collectionCalendarDto,
            customerName: response.data.customerName,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            currencyType: response.data.currencyType,
            discountPercent: response.data.discountPercent,
            discountValue: response.data.discountValue.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            discountTotal: response.data.discountTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            taxTotal: (response.data.generalTotal * (response.data.taxRate / 100)).toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            taxType: response.data.taxType,
            taxRate: response.data.taxRate,
            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            billsProductOutGuid: response.data.billsProductOutGuid,
            billsGuid: response.data.billsGuid,
            pdfFileName: response.data.pdfFileName,
            billsType: response.data.billsType,
            billsBillType: response.data.billsBillType,
            billsCancel: response.data.billsCancel,
            billsCancelCogen: response.data.billsCancelCogen,
            bills: response.data.bills,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
    .get(URL + "/productouts/print/" + guid, config)
    .then((response) => {
      var myData = response.data;
      let replaceLink = myData.replace(localFilePath, "");
      if (replaceLink) {
        window.open(replaceLink, "_blank");
        this.setState({
          isLoading: false,
        });
      }
    })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tr>
                  <th>Satış Tarihi</th>
                  <td colSpan="4">{this.state.productOutDate}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>

                <tr>
                  <th>Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>İskonto(Yüzde)</th>
                  <td colSpan="4">
                    {this.state.discountPercent
                      ? "% " + this.state.discountPercent
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>İskonto(Net Tutar)</th>
                  <td colSpan="4">
                    {this.state.discountValue
                      ? this.state.discountValue +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Genel Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>K.D.V. Türü</th>
                  <td colSpan="4">{TaxTypeOptions(this.state.taxType)}</td>
                </tr>

                <tr>
                  <th>K.D.V. Oranı</th>
                  <td>
                    {this.state.taxRate ? "% " + this.state.taxRate : "-"}
                  </td>

                  <th>K.D.V. Tutarı</th>
                  <td>
                  {this.state.taxTotal
                      ? this.state.taxTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.discountTotal
                      ? this.state.discountTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "TL"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Açıklama</th>
                  <td colSpan="4">
                    {this.state.description ? this.state.description : "-"}
                  </td>
                </tr>

                {/* <tr>
                  <th>Durum</th>
                  <th className="colon">:</th>
                  <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                </tr> */}

                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              {this.state.productDto !== null &&
                this.state.productDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th>Depo</th>
                      <th style={{textAlign: "left", color: "white"}}>Ürün Adı</th>
                      <th className="text-center">Adet</th>
                      <th>Birim Fiyatı</th>
                      <th>Fiyat</th>
                    </tr>
                    {this.state.productDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.locationName}</td>
                          <td style={{textAlign: "left"}}>{item.productName}</td>
                          <td className="text-center">{item.piece}</td>
                          <td>
                            {item.unitPrice.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.currencyType === 1
                                ? "TL"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : "-")}
                          </td>
                          <td>
                            {item.total.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.currencyType === 1
                                ? "TL"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : "-")}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
              {this.state.collectionCalendarDto !== null &&
                this.state.collectionCalendarDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th>Tahsilat Takvimi Tarihi</th>
                      <th>Tahsilat Tutarı</th>
                      <th className="text-center">Tahsilat Yöntemi</th>
                    </tr>
                    {this.state.collectionCalendarDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.targetCollectionDate}</td>
                          <td>
                            {item.targetTotal.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              (item.targetCurrencyType === 1
                                ? "TL"
                                : item.targetCurrencyType === 2
                                ? "$"
                                : item.targetCurrencyType === 3
                                ? "€"
                                : "-")}
                          </td>
                          <td className="text-center">
                            {PaymentTypeOptions(
                              item.targetCollectionMethodType
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
            </Card>
            <CgnButton
              type="button"
              color="success"
              className="printButton mr-3"
              onClick={() => this.handlePdfPrint(this.state.guid)}
              text={"Yazdır"}
            />
            {this.state.bills &&
              this.state.billsGuid !== null &&
              this.state.billsGuid !==
                "00000000-0000-0000-0000-000000000000" && (
                <CgnButtonLink
                  color="primary"
                  to={"/giden-fatura-detay/" + this.state.billsGuid}
                  text="Fatura Detay"
                />
              )}
            {this.state.bills &&
              this.state.pdfFileName !== null &&
              this.state.pdfFileName !== "" && (
                <CgnButtonLink
                  color="primary"
                  to={"/EFatura/" + this.state.pdfFileName}
                  target={"_blank"}
                  text="Faturayı Görüntüle"
                  className="ml-2"
                />
              )}
            {this.state.bills &&
              this.state.billsGuid !== null &&
              this.state.billsGuid !== "00000000-0000-0000-0000-000000000000" &&
              this.state.billsType !== null &&
              this.state.billsType === 3 &&
              this.state.billsBillType !== null &&
              this.state.billsBillType === "TICARIFATURA" &&
              this.state.billsCancel !== null &&
              this.state.billsCancel === 0 &&
              this.state.billsCancelCogen !== null &&
              this.state.billsCancelCogen === 0 && (
                <CgnButton
                  type="button"
                  color="secondary"
                  onClick={() => this.cancel(this.state.billsGuid)}
                  text="Faturayı İptal Et"
                  className="ml-2"
                />
              )}
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductOutDetail;
