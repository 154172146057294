import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class TypeDetail extends Component {
  state = {
    pageTitle: "Tip Detay",
    pageTitleDetail: "Tip Detay",
    pageAddLink: "tip-ekle",
    pageListLink: "tip-listele",
    breadcrumb: [{ text: "Tip", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    name: "",
    endDay: 0,
    adminDay: 0,
    adminMounth: 0,
    customerDay: 0,
    customerMounth: 0, 
    optionDayAdmin: 0,
    optionDayCustomer: 0,
    remindPerson: 0,
    mounth: 0,
    typeDateDtosAdmin: [],
    typeDateDtosCustomer: [],
    typeEndDateDtos: [],
    day: 0,
    mount: 0,
    year: 0,
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/types/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            name: response.data.name,
              endDay: response.data.endDay,
              customerOptionDay: response.data.customerOptionDay,
              adminOptionDay: response.data.adminOptionDay,
              remindPerson: response.data.remindPerson,
              year: response.data.year,
              mounth: response.data.mounth,
              status: response.data.status,
              isLoading: false,
              typeDateDtosAdmin: response.data.typeDateDtosAdmin,
              typeDateDtosCustomer: response.data.typeDateDtosCustomer,
              typeEndDateDtos: response.data.typeEndDateDtos,
              optionTypeAdmin: response.data.optionTypeAdmin,
              optionTypeCustomer: response.data.optionTypeCustomer,
              createdBy: response.data.createdBy,
              createdAt: response.data.createdAt,
              updatedBy: response.data.updatedBy,
              updatedAt: response.data.updatedAt,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {

    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    const textRight = {
      textAlign: 'right',
    };

    var self = this.state;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tip</th>
                    {/* <th className="colon">:</th> */}
                    <td colSpan="4">
                      {this.state.name ? this.state.name : "-"}
                    </td>
                  </tr>

                  <tr style={{display: this.state.endDay === 0 ? "none" : ""}}>
                    <th>Son Günü</th>
                    {/* <th className="colon">:</th> */}
                    <td colSpan="4">{this.state.endDay}</td>
                  </tr>

                  <tr>
                    <th>Hatırlatılacak Kişi</th>
                    {/* <th className="colon">:</th> */}
                    <td colSpan="4">{this.state.remindPerson !== 0 ? 
                    (this.state.remindPerson === 1 ? "Yönetici" 
                    : this.state.remindPerson === 2 ? "Müşteri" 
                    : this.state.remindPerson === 3 ? "Yönetici ve Müşteri" : "-") 
                    : "-"}</td>
                  </tr>

                  <tr style={{display : this.state.remindPerson === 2 && this.state.customerOptionDay !== 0 && this.state.optionTypeCustomer !== 1 ? "" : "none"}}>
                  <th>Müşteri Hatırlatma Günü</th>
                  <td colSpan="4">
                   {this.state.customerOptionDay}
                  </td>
                  </tr>

                  <tr style={{display : this.state.remindPerson === 2 && this.state.customerOptionDay !== 0 && this.state.optionTypeCustomer === 1 ? "" : "none"}}>
                  <th>Müşteri Hatırlatma Günü</th>
                  <td colSpan="4">
                   {"Her ayın son günü"}
                  </td>
                  </tr>

                  <tr style={{display : this.state.remindPerson === 1 && this.state.adminOptionDay !== 0 && this.state.optionTypeAdmin !== 1 ? "" : "none"}}>
                  <th>Yönetici Hatırlatma Günü</th>
                  <td colSpan="4">
                   {this.state.adminOptionDay}
                  </td>
                  </tr>

                  <tr style={{display : this.state.remindPerson === 1 && this.state.adminOptionDay !== 0 && this.state.optionTypeAdmin === 1 ? "" : "none"}}>
                  <th>Yönetici Hatırlatma Günü</th>
                  <td colSpan="4">
                   {"Her ayın son günü"}
                  </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    {/* <th className="colon">:</th> */}
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    {/* <th className="colon">:</th> */}
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    {/* <th className="colon">:</th> */}
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    {/* <th className="colon">:</th> */}
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.typeEndDateDtos !== null && this.state.typeEndDateDtos.length > 0 && (           
                <Table className="table table-responsive table-hover table-spare">
                  <tr style={mainRow}>
                  <th className="text-left pl-3">Son Gün</th>
                  </tr>
                  {this.state.typeEndDateDtos.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td className="text-left pl-3">{item.day + " " + (item.mount === 1 ? "Ocak" :
                          item.mount === 2 ? "Şubat" :
                          item.mount === 3 ? "Mart" :
                          item.mount === 4 ? "Nisan" : 
                          item.mount === 5 ? "Mayıs" : 
                          item.mount === 6 ? "Haziran" : 
                          item.mount === 7 ? "Temmuz" : 
                          item.mount === 8 ? "Ağustos" :
                          item.mount === 9 ? "Eylül" : 
                          item.mount === 10 ? "Ekim" : 
                          item.mount === 11 ? "Kasım" : 
                          item.mount === 12 ? "Aralık" : "-") + " " + self.year}</td>                                    
                        </tr>
                      );
                    })}
                </Table>
              )}

              {this.state.typeDateDtosAdmin !== null && this.state.typeDateDtosAdmin.length > 0 && (           
                <Table className="table table-responsive table-hover table-spare">
                  <tr style={mainRow}>
                  <th className="text-left pl-3">Yönetici Hatırlatma Günü</th>
                  <th className="text-left pl-3">Yönetici Hatırlatma Ayı</th>
                  </tr>
                  {this.state.typeDateDtosAdmin.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td className="text-left pl-3">{item.adminDay}</td>
                          <td className="text-left pl-3">{item.adminMounth === 1 ? "Ocak" : 
                          item.adminMounth === 2 ? "Şubat" : 
                          item.adminMounth === 3 ? "Mart" :
                          item.adminMounth === 4 ? "Nisan" : 
                          item.adminMounth === 5 ? "Mayıs" :
                          item.adminMounth === 6 ? "Haziran" :
                          item.adminMounth === 7 ? "Temmuz" : 
                          item.adminMounth === 8 ? "Ağustos" : 
                          item.adminMounth === 9 ? "Eylül" : 
                          item.adminMounth === 10 ? "Ekim" : 
                          item.adminMounth === 11 ? "Kasım" :
                          item.adminMounth === 12 ? "Aralık" : "-"}</td>                  
                        </tr>
                      );
                    })}
                </Table>
              )}


            {this.state.typeDateDtosCustomer !== null && this.state.typeDateDtosCustomer.length > 0 && (           
                <Table className="table table-responsive table-hover table-spare">
                  <tr style={mainRow}>
                  <th className="text-left pl-3"> Müşteri Hatırlatma Günü</th>
                  <th className="text-left pl-3">Müşteri Hatırlatma Ayı</th>
                  </tr>
                  {this.state.typeDateDtosCustomer.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td className="text-left pl-3">{item.customerDay}</td>
                          <td className="text-left pl-3">{item.customerMounth === 1 ? "Ocak" : 
                          item.customerMounth === 2 ? "Şubat" : 
                          item.customerMounth === 3 ? "Mart" :
                          item.customerMounth === 4 ? "Nisan" : 
                          item.customerMounth === 5 ? "Mayıs" :
                          item.customerMounth === 6 ? "Haziran" :
                          item.customerMounth === 7 ? "Temmuz" : 
                          item.customerMounth === 8 ? "Ağustos" : 
                          item.customerMounth === 9 ? "Eylül" : 
                          item.customerMounth === 10 ? "Ekim" : 
                          item.customerMounth === 11 ? "Kasım" :
                          item.customerMounth === 12 ? "Aralık" : "-"}</td>                  
                        </tr>
                      );
                    })}
                </Table>
              )}

            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default TypeDetail;
