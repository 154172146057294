import React, { Component } from "react";
import { Row, Col, Card, Table, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL, VoteStatus } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";


class KingUserList extends Component {
  state = {
    pageTitle: "Üye Listele",
    pageAddLink: "uye-ekle",
    pageUpdateLink: "uye-guncelle",
    pageDetailLink: "uye-detay",
    pageListLink: "uye-listele",
    padeVoteUpdateLink: "oy-guncelle",
    breadcrumb: [{ text: "Üye", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    kingUserData: [],
    name: "",
    roomNo: 0,
    registrationNo: 0,
    vote: null,
    voteModal: null,
    hazirunStatus: null,
    guid: "",
    alertErrorMessage: "",
    isOpenModalVote: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    
    this.getList();
  }

  async handleVoteSelectChange(option) {
    if (option) {
      await this.setState({
        vote: option.value,
      });
    } else {
      await this.setState({
        vote: null,
      });
    }
    localStorage.setItem("voteKingUser", this.state.vote);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var voteStatus;
    if(this.state.vote === null){
      voteStatus = 3;
    }
    else if (this.state.vote === true){
      voteStatus = 1;
    }
    else{
      voteStatus = 2;
    }
    var statusType = 1; // 1 aktif ve pasiflerin ikisinide getirmesi için
    var URLParam = "/kingUsers/getlist/" + statusType + "/" + voteStatus;
    axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          kingUserData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleVoteStatusChange(option) {
    if (option) {
      this.setState({
        voteModal: option.value,
        voteError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        voteModal: false,
        voteError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      voteError: "",
    });
    if (this.state.voteModal === 0) {
      this.setState({
        voteError: CgnMessage.textErrorMessage,
      });
    } else {
      this.setState({
        voteError: "",
        isLoading: true,
        isLoadingModal: true,
      });
      var myObj = {
        vote: this.state.voteModal,
        guid: this.state.guid,
      };
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/kingUsers/statusvote/";
      axios.post(URL + URLParam, myObj, config).then(() => {
        setTimeout(() => {
          window.location.href = "/uye-listele";
        }).catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isOpenModalReply: !this.state.isOpenModalReply,
            isLoading: false,
            isLoadingModal: false,
          });
        });
      });
    }
  };

  addModalVote = (guid, vote) => {
    this.setState({
      isOpenModalVote: !this.state.isOpenModalVote,
      guid: guid,
      voteModal: vote
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/kingUsers/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "100px",
  };

  thStyle2 = {
    width: "130px",
  };

  tdStyle = {
    width: "250px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="kingUserList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
                <Row className="filterRow">
                <Col className="pl-2 pr-2" md="11">
                  <CgnReactSelect
                    name="vote"
                    label="Oy Durumu"
                    placeholder="Oy Durumu seçiniz..."
                    selectValue={this.state.vote}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleVoteSelectChange(option)
                    }
                    options={VoteStatus}
                    />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.kingUserData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Üye Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Ad</th>
                              <td style={this.tdStyle}>
                                {row.name}
                              </td> 
                              <th style={this.thStyle2}>Hazirun Durumu</th>
                              <td>
                                {row.hazirunStatus === true ? "Hazirunda" : "Hazirunda Değil"}
                              </td>                          
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Esnaf Sicil No</th>
                              <td style={this.tdStyle}>
                                {row.registrationNo}
                              </td>
                              <th style={this.thStyle2}>Oda Sicil No</th>
                              <td>
                                {row.roomNo}
                              </td>                            
                            </tr>                                                   
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "150px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.vote === true) {
                        return <CgnBadge color="success" className="w-100" text="Oy Kullandı" />;
                      } else {
                        return <CgnBadge color="danger" className="w-100" text="Oy Kullanmadı" />;
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.voteButton,
                            className: "dropDownBtn",
                            onClick: () => this.addModalVote(row.guid, row.vote),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalVote}
          toggle={this.addModalVote}
          className="modal-xs content"
        >
          <ModalHeader className="font-weight-bold">Oy Durumu Güncelle</ModalHeader>
          <ModalBody className="modalBody">
            <CgnLoader visibled={this.state.isLoadingModal} />
            <Row>
              <Col>
              <CgnReactSelect
                      name="vote"
                      label="Oy Durumu [*]"
                      placeholder="Oy Durumu seçiniz..."
                      selectValue={this.state.voteModal}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleVoteStatusChange(option)}
                      options={VoteStatus}
                      error={this.state.voteError}
                    />
                 </Col>
            </Row>
            <Row>
              <Col>
                {this.state.alertModalErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertModalErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="primary"
              onClick={this.handleSave}
              text="Kaydet"
              className="modalButton"
            />
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalVote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default KingUserList;
