import React from "react";
import { Button, FormGroup } from "reactstrap";
import FileInput from "./CgnFileInput";

const PictureUploadMulti = ({
  image,
  noFoto,
  style,
  onChange,
  onDelete,
  error,
  disabled,
  label,
  name,
  hidden,
  accept,
}) => {
  let re = /(?:\.([^.]+))?$/;
  let wrapperClass = "form-group image-upload";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <FormGroup className={wrapperClass} hidden={hidden}>
      <div className="image-list mb-3">
        {image.map(function (item) {
          return (
            item &&
            item.image &&
            item.imageName && (
              <div className="image-list-item">
                {item.image && (
                  <img
                    src={item.image}
                    alt={item.imageName}
                    title={item.imageName}
                    className="image mb-2 rounded"
                    style={style}
                  />
                )}            
                {!item.image && noFoto && (
                  <img
                    src={noFoto}
                    alt={item.imageName}
                    title={item.imageName}
                    className="image mb-2 rounded"
                    style={style}
                  />
                )}
                {!disabled && (
                  <Button color="danger" onClick={() => onDelete(item.image)}>
                    Sil
                  </Button>
                )}
              </div>
            )
          );
        })}
      </div>
      {!disabled && (
        <FileInput
          accept={accept}
          name={name}
          label={label}
          placeHolder="Lütfen fotoğraf seçin..."
          autoComplete="off"
          multiple={true}
          onChange={onChange}
          hidden={hidden}
        />
      )}
      {error && !hidden && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </FormGroup>
  );
};

export default PictureUploadMulti;
