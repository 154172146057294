import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  
  URL,
  localFilePath,
  utsStatus,
  fotoStyle2
} from "../../redux/actions/constants";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import pdfIcon from "../../images/pdfIcon.png";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";

class CustomerDocumentUTSDetail extends Component {
  state = {
    pageTitle: "ÜTS'ye Yüklenen Belgeler Detay (ÜTS Belge Kayıt)",
    pageTitleDetail: "ÜTS'ye Yüklenen Belgeler Detay (ÜTS Belge Kayıt)",
    pageAddLink: "musteri-belge-kayit-ekle",
    pageListLink: "uts-belgeleri-onay-bekleyen-kayitlari-listele",
    breadcrumb: [{ text: "ÜTS'ye Yüklenen Belgeler", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    requestedDocumentsName: "",
    customerDocumentImageDto: [],
    customerDocumentSuitableImageDto: [],
    customerDocumentSuitableImageDtoError: "",
    technicalReadingDocumentDto: [],
    utsDocumentDto: [],
    description: "",
    status: "",
    resultChange: "",
    resultStatusChange: "",
    customerGuid: "",
    statusChange: "",
    customerMail: "",
    guid: "",
    createdBy: "",
    remainingAmount: "",
    utsDescription: "",
    offerTotal: "",
    currencyType: "",
    total: "",
    addTotal: "",
    customerGroupId: 0,
    createdAt: "",
    isLoadingModal: false,
    updatedAt: "",
    updatedBy: "",
    paymentValue: "",
    paymentValueEFT: "",
    isHiddenSuitable: true,
    isHiddenUnSuitable: true, 
    isOpenModalPayment: false,
    isOpenModalPaymentEFT: false,
    isDescriptionHidden: true,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ]
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/customerDocumentRecords/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            customerName: response.data.customerName,
            customerMail: response.data.customerMail,
            requestedDocumentsName: response.data.requestedDocumentsName,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            customerDocumentImageDto: response.data.customerDocumentImageDto,
            resultChange: response.data.resultChange,
            isLoading: false,
            customerDocumentSuitableImageDto: response.data.customerDocumentSuitableImageDto,
            technicalReadingDocumentDto: response.data.technicalReadingDocumentDto,
            statusChange: response.data.statusChange,
            utsDocumentDto: response.data.utsDocumentDto,
            offerTotal: response.data.offerTotal,
            currencyType: response.data.currencyType,
            remainingAmount: response.data.remainingAmount,
            total: response.data.total,
            customerGroupId: response.data.customerGroupId,
            utsDescription: response.data.utsDescription,
            isDescriptionHidden: response.data.resultChange === 5 ? false : true,
            customerGuid: response.data.customerGuid
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }


  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  ticketClick = () => {
    window.open("/musteri-bilgilendirme-sistemi/" + this.state.customerGuid + "/" + this.state.guid + "/1", "_blank");
    this.setState({
      isLoading: false,
    });
 };

 enTicketClick = () => {
  window.open("/customer-information-system/" + this.state.customerGuid + "/" + this.state.guid + "/1", "_blank");
  this.setState({
    isLoading: false,
  });
};

  handleSendPayment = async () => {
    this.setState({
      isOpenModalPayment: !this.state.isOpenModalPayment,
    });
  };

  handleSendPaymentEFT = async () => {
    this.setState({
      isOpenModalPaymentEFT: !this.state.isOpenModalPaymentEFT,
    });
  };

  handleSendMailPayment = async () => {
    this.setState({
      isLoadingModal: true,
    });
    if(this.state.paymentValue === "" || this.state.paymentValue === null){
      this.setState({
        paymentValueError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoadingModal: false,
      });
    }
    else if(this.state.paymentValue !== "" || this.state.paymentValue !== null){
      this.setState({
        isLoadingModal: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/customerDocumentRecords/paymentsendmail";
      var myObj = {
        guid: this.state.guid,
        customerName: this.state.customerName,
        customerMail: this.state.customerMail,
        total: parseFloat(this.state.paymentValue),
      };
       axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
              window.location.reload();
            this.setState({
              isLoadingModal: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoadingModal: false,
          });
        });
    }
    else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSendAddPaymentEFT = async () => {
    this.setState({
      isLoadingModal: true,
    });
    if(this.state.paymentValueEFT === "" || this.state.paymentValueEFT === null){
      this.setState({
        paymentValueEFTError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoadingModal: false,
      });
    }
    else if(this.state.paymentValueEFT !== "" || this.state.paymentValueEFT !== null){
      this.setState({
        isLoadingModal: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/customerDocumentRecords/addpayment";
      var myObj = {
        guid: this.state.guid,
        customerName: this.state.customerName,
        customerMail: this.state.customerMail,
        addTotal: parseFloat(this.state.paymentValueEFT),
      };
       axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
              window.location.reload();
            this.setState({
              isLoadingModal: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoadingModal: false,
          });
        });
    }
    else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    if (status) {
      this.setState({
        resultStatusChange: status.value,
        resultChangeError: "",
        alertErrorMessage: "",
      });
      if(status.value === 5){
        this.setState({isDescriptionHidden: false})
      }
      else{
        this.setState({isDescriptionHidden: true, utsDescription: ""})
      }
    } else {
      this.setState({
        resultStatusChange: "",
        utsDescription: "",
        resultChangeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isDescriptionHidden: true
      });
    }
  }


  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    if(this.state.resultStatusChange === null || this.state.resultStatusChange === ""){
      this.setState({
        resultChangeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoading: false,
      });
    }
    else if(this.state.resultStatusChange !== null || this.state.resultStatusChange !== ""){
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var URLParam = "/customerDocumentRecords/updateuts";
      var myObj = {
        guid: this.state.guid,
        resultChange: this.state.resultStatusChange,
        utsDescription: this.state.utsDescription,
        customerName: this.state.customerName,
        customerMail: this.state.customerMail,
      };
       await axios
        .post(URL + URLParam, myObj, config)
        .then((response) => {
          setTimeout(() => {
            window.location.href = "/uts-belgeleri-onay-bekleyen-kayitlari-listele";
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
    else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }


  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">
                      {this.state.customerName ? this.state.customerName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>İstenen Belgeler</th>
                    <td colSpan="4">
                      {this.state.requestedDocumentsName
                        ? this.state.requestedDocumentsName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">
                      {this.state.resultChange === 4 ? "Onaylandı" : this.state.resultChange === 5 ? "Reddedildi" : this.state.resultChange === 6 ? "Kayıtlı" : this.state.resultChange === 7 ? "Revizyon" : this.state.resultChange === 8 ? "Teslim Edildi" : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {this.state.utsDocumentDto &&
                this.state.utsDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>UTS Belgeleri</th>
                    </tr>
                    <Row>
                      {this.state.utsDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
               <Row className="mt-3" style={{display: this.state.total !== 0 ? "" : "none", color:"red"}}>
                   <div>
                    <p style={{fontWeight: "bold"}}>Gönderilen Ödeme Tutarı : {this.state.total + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                 <p style={{fontWeight: "bold"}}>Kalan Ödeme Tutarı : {(parseFloat(this.state.offerTotal) - parseFloat(this.state.total)).toFixed(2) + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                   </div>
                </Row>
            </Card>
            <Card body className="table-responsive">   
            <CgnLoader visibled={this.state.isLoading} />          
            <Form onSubmit={this.handleSave}>              
            <Col lg="12" className="pl-0">
                    <CgnReactSelect
                      name="resultStatusChange"
                      placeholder="Durum seçiniz..."
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.resultStatusChange}
                      options={utsStatus}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                      error={this.state.resultChangeError}
                    />
                  </Col>     
                  <Col lg="12" className="pl-0" style={{display: this.state.isDescriptionHidden === true ? "none" : ""}}>
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="utsDescription"
                      label="Reddedilme Sebebi"
                      value={this.state.utsDescription}
                      placeHolder="Lütfen reddedilme sebebi girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.utsDescriptionError}
                    />
                  </Col>      
                    <CgnButtonGroup items={this.state.buttons} />
                 </Form>
                {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />     
            
            <CgnButton
              style={{float:"right"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.ticketClick()}
              text={"Ticket Sistemi"}
              target="_blank"
            />

            <CgnButton
              style={{float:"right"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.enTicketClick()}
              text={"EN Ticket Sistemi"}
              target="_blank"
            />

             <CgnButton
              style={{display: (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)) > 0 && (this.state.customerGroupId === 7) ? "" : "none", float:"right"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.handleSendPayment()}
              text={"Ödeme Ekle"}
            />

             <CgnButton
              style={{display: (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)) > 0 ? "" : "none", float:"right"}}
              type="button"
              color="primary"
              className="sendEmailButton mr-3"
              onClick={() => this.handleSendPaymentEFT()}
              text={"Gelen Ödeme"}
            />

            <Modal
              isOpen={this.state.isOpenModalPayment}
              toggle={this.handleSendPayment}
              className="modal-xl content"
            >
              <ModalHeader className="font-weight-bold">
                Ödeme Gönder
              </ModalHeader>
              <ModalBody className="modalBody">
              <CgnLoader visibled={this.state.isLoadingModal} />
                <Row>
                  <Col>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Teklif Tutarı : {this.state.offerTotal + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Ödenen Tutar : {this.state.remainingAmount + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                    <CgnTextbox
                      type="number"
                      name="paymentValue"
                      label="Ödenecek Tutar"
                      value={this.state.paymentValue}
                      placeHolder="Lütfen ödenecek tutarı girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleChange(e)}
                      step="0.1"
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
              <CgnButton
                  type="button"
                  color="primary"
                  onClick={this.handleSendMailPayment}
                  text="Gönder"
                  className="modalButton"
                />
                <CgnButton
                  type="button"
                  color="secondary"
                  onClick={this.handleSendPayment}
                  text="Kapat"
                  className="modalButton"
                />
              </ModalFooter>
            </Modal>      

            <Modal
              isOpen={this.state.isOpenModalPaymentEFT}
              toggle={this.handleSendPaymentEFT}
              className="modal-xl content"
            >
              <ModalHeader className="font-weight-bold">
                Gelen Ödeme Ekle
              </ModalHeader>
              <ModalBody className="modalBody">
              <CgnLoader visibled={this.state.isLoadingModal} />
                <Row>
                  <Col>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Teklif Tutarı : {this.state.offerTotal + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                  <p style={{color: "red", fontWeight: "bold"}}>Toplam Ödenen Tutar : {this.state.remainingAmount + " " + (this.state.currencyType === 1 ? "TL" :
                  this.state.currencyType === 2 ? "$" : this.state.currencyType === 3 ? "€" : "")}</p>
                    <CgnTextbox
                      type="number"
                      name="paymentValueEFT"
                      label="Gelen Ödeme Tutarı"
                      value={this.state.paymentValueEFT}
                      placeHolder="Lütfen gelen ödeme tutarı girin..."
                      autoComplete="off"
                      onChange={(e) => this.handleChange(e)}
                      step="0.1"
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
              <CgnButton
                  type="button"
                  color="primary"
                  onClick={this.handleSendAddPaymentEFT}
                  text="Ekle"
                  className="modalButton"
                />
                <CgnButton
                  type="button"
                  color="secondary"
                  onClick={this.handleSendPaymentEFT}
                  text="Kapat"
                  className="modalButton"
                />
              </ModalFooter>
            </Modal> 
          </Col>
        </Row>
      </>
    );
  }
}

export default CustomerDocumentUTSDetail;
