import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  
  localFilePath,
  machineProductOptionStatuses,
  GetMonthFirstDateYear,
  GetMonthLastDateYear,
  GetMonthFirstDate2Year,
  GetMonthLastDate2Year,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class MachineProductOrderList extends Component {
  state = {
    pageTitle: "Sipariş Listele",
    pageAddLink: "makine-urun-siparis-ekle",
    pageUpdateLink: "makine-urun-siparis-guncelle",
    pageDetailLink: "makine-urun-siparis-detay",
    pageListLink: "makine-urun-siparis-listele",
    breadcrumb: [{ text: "Sipariş", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    pageType: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "yetkili-makine-urun-siparis-listele") {
      this.setState({
        pageTitle: "Yetkili Sipariş Listele",
        pageAddLink: "",
        pageUpdateLink: "",
        pageDetailLink: "yetkili-makine-urun-siparis-detay",
        pageListLink: "yetkili-makine-urun-siparis-listele",
        pageType: "yetkili-makine-urun-siparis-listele",
      });
    }


    this.setState({
      isLoading: true,
    });

    

    var firstDate = GetMonthFirstDateYear();
    var lastDate = GetMonthLastDateYear();
    var firstDate2 = GetMonthFirstDate2Year();
    var lastDate2 = GetMonthLastDate2Year();

    if (localStorage.getItem("firstDateMachineProductOrderList")) {
      firstDate = localStorage.getItem("firstDateMachineProductOrderList");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateMachineProductOrderList")) {
      lastDate = localStorage.getItem("lastDateMachineProductOrderList");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    if (localStorage.getItem("statusMachineProductOrderList")) {
      this.setState({
        status: localStorage.getItem("statusMachineProductOrderList"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem(
      "firstDateMachineProductOrderList",
      this.state.firstDate
    );
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      await this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
    localStorage.setItem(
      "lastDateMachineProductOrderList",
      this.state.lastDate
    );
  }

  async handleStatusSelectChange(option) {
    if (option) {
      await this.setState({
        status: option.value,
      });
      localStorage.setItem(
        "statusMachineProductOrderList",
        this.state.status
      );
    } else {
      await this.setState({
        status: 0,
      });
      localStorage.setItem(
        "statusMachineProductOrderList",
        0
      );
    }
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var URLParam = "";
    URLParam =
      "/machineproductorder/getlistuser/" +
      this.state.status +
      "/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos");

    if (this.state.pageType === "yetkili-makine-urun-siparis-listele") {
      URLParam =
        "/machineproductorder/getlist/" +
        this.state.status +
        "/" +
        (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
        "/" +
        (this.state.lastDate !== "" ? this.state.lastDate : "bos");
    }

    // 
    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          data: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    const URLParam = "/machineproductorder/delete";
    const obj = {
      guid,
    };
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  thStyle = {
    width: "50px",
  };

  thStyle2 = {
    width: "50px",
  };

  tdStyle = {
    width: "100px",
  };

  handlePrint = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam =
      "/machineproductorder/getlistexcel/" +
      this.state.status +
      "/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos");

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={this.state.pageAddLink !== "" && "/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          excelLink={onClick => this.handlePrint()}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.firstDate2.split("/")[2] +
                          "-" +
                          this.state.firstDate2.split("/")[1] +
                          "-" +
                          this.state.firstDate2.split("/")[0]
                        )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.lastDate2.split("/")[2] +
                          "-" +
                          this.state.lastDate2.split("/")[1] +
                          "-" +
                          this.state.lastDate2.split("/")[0]
                        )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="status"
                    label="Durum"
                    placeholder="Durum seçiniz..."
                    selectValue={this.state.status}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) => this.handleStatusSelectChange(option)}
                    options={machineProductOptionStatuses}
                  />
                </Col>
                <Col className="pl-2 pr-0" lg="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.data}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Sipariş Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.date}</td>
                              <th style={this.thStyle2}>Depo</th>
                              <td>{row.locationName}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "120px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status === 1) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            text={"Bekliyor"}
                          />
                        );
                      } else if (row.status === 2) {
                        return (
                          <CgnBadge
                            color="warning"
                            className="w-100"
                            text={"Kargolandı"}
                          />
                        );
                      } else if (row.status === 3) {
                        return (
                          <CgnBadge
                            color="success"
                            className="w-100"
                            text={"Tamamlandı"}
                          />
                        );
                      } else if (row.status === 4) {
                        return (
                          <CgnBadge
                            color="info"
                            className="w-100"
                            text={"Askıda"}
                          />
                        );
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: this.state.pageUpdateLink !== "" && row.status === 1 ? "Güncelle" : "",
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: row.status === 1 || row.status === 4 ? "Sil" : "",
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineProductOrderList;
