import React, { Component } from "react";
import { Row, Col, Card, Table, Button } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL, CustomerList, GetMonthFirstDate,
  GetMonthLastDate, cuttingStatuses } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnDatatable2 from "../toolbox/CgnDatatable2";

class CuttingProcessList extends Component {
  state = {
    pageTitle: "Kesim İşlemleri Listele",
    pageAddLink: "kesim-islemi-ekle",
    pageUpdateLink: "kesim-islemi-guncelle",
    pageDetailLink: "kesim-islemi-detay",
    pageListLink: "kesim-islemi-listele",
    pageProjectLink: "kesim-proje-listele",
    breadcrumb: [{ text: "Kesim İşlemi", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    cutData: [],
    name: "",
    guid: "",
    cutId: 0,
    processStatus1: 0,
    cutTypeId: 0,
    totalPrice: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    cuttingProcessData: [],
    alertErrorMessage: "",
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
    statusAll: 0 
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

      // var firstDate = GetMonthFirstDate();
      // var lastDate = GetMonthLastDate();
  
      // if (localStorage.getItem("firstDateCuttingProcess")) {
      //   firstDate = localStorage.getItem("firstDateCuttingProcess");
      // }
      // if (localStorage.getItem("lastDateCuttingProcess")) {
      //   lastDate = localStorage.getItem("lastDateCuttingProcess");
      // }
  
      // await this.setState({
      //   firstDate: firstDate,
      //   firstDate2: firstDate,
      //   lastDate: lastDate,
      //   lastDate2: lastDate,
      // });
  
      // 
  
      var self = this;
  
      await CustomerList().then(function (data) {
        self.setState({
          customerArr: data,
        });
      });
  
      if (localStorage.getItem("customerGuidCuttingProcess")) {
        this.setState({
          customerGuid: localStorage.getItem("customerGuidCuttingProcess"),
        });
      }

      this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      //var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem("firstDateCuttingProcess", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      //var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
    localStorage.setItem("lastDateCuttingProcess", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidCuttingProcess", this.state.customerGuid);
  }

  handleProcessStatusSelectChange(option) {
    if (option) {
      this.setState({
        processStatus1: option.value,
        processStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        processStatus1: 0,
      });
    }
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: ""
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    let urlSplit = window.location.pathname.split("/");
    var urlSplitX = urlSplit.length > 2 ? urlSplit[2] : 0;
    this.setState({
      urlSplitX: urlSplitX,
    });

    URLParam =
        "/cuttingprocess/getlist/" +
        urlSplitX + "/" 
        +
        (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
        "/" +
        (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
        "/" +
        this.state.customerGuid +
        "/" +
        this.state.processStatus1;

    var statusType = 2;
    axios
      .get(URL + URLParam , config)
      .then((response) => {
        this.setState({
          cuttingProcessData: response.data.cuttingProcessDtos, 
          status1: response.data.status1,
          status2: response.data.status2,
          status3: response.data.status3,
          status4: response.data.status4,
          statusAll: response.data.statusAll,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/cuttingprocess/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }


  async handleProject(fileName) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/cuttingprocess/getlistprojects";
    const obj = {
      fileName,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "110px",
  };

  thStyle2 = {
    width: "130px",
  };

  tdStyle = {
    width: "350px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="cutList" id="list">
          <Col>
          <div className="d-inline-block mt-2 w-100">
                <a
                  href={`/${this.state.pageListLink}/${1}`}
                  className={
                    this.state.urlSplitX === "1"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                  style={{backgroundColor: "#F77211"}}
                >{`Beklemede: ${this.state.status1}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${2}`}
                  className={
                    this.state.urlSplitX === "2"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                  style={{backgroundColor: "#6440F5"}}
                >{`Onaylandı: ${this.state.status2}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${3}`}
                  className={
                    this.state.urlSplitX === "3"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2 bg-danger"
                  }
                >{`Sonlandırıldı: ${this.state.status3}`}</a>
                                <a
                  href={`/${this.state.pageListLink}/${4}`}
                  className={
                    this.state.urlSplitX === "4"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2 bg-info"
                  }
                >{`Özel Durum: ${this.state.status4}`}</a>
                <a
                  href={`/${this.state.pageListLink}`}
                  className={
                    this.state.urlSplitX === 0
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2 bg-info"
                  }
                >{`Tümü: ${this.state.statusAll}`}</a>
              </div>
          <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
                <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2  &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="5">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                <CgnReactSelect
                      name="processStatus1"
                      label="Durumu"
                      placeholder="Lütfen durum seçin.."
                      selectValue={this.state.processStatus1}
                      isMulti={false}
                      options={cuttingStatuses}
                      onChange={(status) =>
                        this.handleProcessStatusSelectChange(status)
                      }
                      isClearable={true}
                    />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable2
                data={this.state.cuttingProcessData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Kesim İşlemi Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td style={this.tdStyle}>
                                {row.customerName}
                              </td>
                              <th style={this.thStyle2}>Dosya Adı</th>
                              <td>
                                {row.fileName !== "" ? row.fileName : "-"}
                              </td>                          
                            </tr>
                            <tr>
                            <th style={this.thStyle}>Tarih</th>
                              <td>
                              {row.createdAt}
                              </td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>
                              {row.createdBy}
                              </td>
                            </tr>
                            <tr style={{display: row.specialTL === 0 ? "" : "none"}}>
                            <th style={this.thStyle}>Genel Toplam</th>
                              <td colSpan={4} style={this.tdStyle}>
                              {row.totalPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " ₺"}
                              </td>      
                            </tr>
                            <tr style={{display: row.specialTL !== 0 ? "" : "none"}}>
                            <th style={this.thStyle}>Özel TL</th>
                              <td colSpan={4} style={this.tdStyle}>
                              {row.specialTL.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " ₺"}
                              </td>      
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },     
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (
                        row.processStatus === 1
                      ) {
                        return (
                          <CgnBadge
                            color="success"
                            className="w-100"
                            text={'Bekliyor'}
                            style={{backgroundColor: "#F77211"}}
                          />
                        );
                      } else if(row.processStatus === 2){
                          return <CgnBadge color="danger" style={{backgroundColor: "#6440F5"}} className="w-100" text={'Onaylandı'} />;
                        }
                        else if(row.processStatus === 3){
                          return <CgnBadge color="danger" className="w-100" text={'Sonlandırıldı'} />;
                        }
                        else {
                          return <CgnBadge color="info" className="w-100" text={'Özel Durum'} />;
                        }
                       
                      }
                  },    
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <Button 
                      type="button"
                      className=""
                      style={{width: "120px"}}
                      href={"/kesim-islemi-detay/" + (row.guid)}
                      color="primary">Detay</Button>                     
                    ),
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <Button 
                      type="button"
                      className=""
                      style={{width: "200px", backgroundColor: "green", padding: "6px"}}
                      href={"/kesim-islemi-guncelle/" + (row.guid)}
                      >Güncelle</Button>                     
                    ),
                  },    
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <Button 
                      type="button"
                      className=""
                      style={{width: "200px", backgroundColor: "red", padding: "6px"}}
                      onClick={() => this.handleDelete(row.guid)}
                      >Sil</Button>                     
                    ),
                  },     
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default CuttingProcessList;
