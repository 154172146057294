import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  fotoStyle,
  CurrencyTypeOptions,
  
} from "../../redux/actions/constants";
import moment from "moment";
import noFotoMember from "../../images/no-foto-user.jpg";

class EmployeeDetail extends Component {
  state = {
    pageTitle: "Personel Detay",
    pageTitleDetail: "Personel Detay",
    pageAddLink: "personel-ekle",
    pageListLink: "personel-listele",
    breadcrumb: [{ text: "Personel", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    groupId: null,
    getListGroupId: null,
    getListGroupName: "",
    firstName: "",
    lastName: "",
    userName: "",
    eMail: "",
    phone: "",
    salary: null,
    startDate: "",
    endDate: "",
    status: "",
    image: "",
    title: "",
    salaryVestingDate: "",
    birthDate: "",
    groupName: "",
    currencyType: "",
    createdByFullName: "",
    categoryArr: [],
    productArr: [],
    saleRegionArr: [],
    discountAmount: 0,
    createdAt: "",
    updatedAt: "",
    updatedByFullName: "",
    isOpen: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/members/getmember/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            groupName: response.data.groupName,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            userName: response.data.userName,
            eMail: response.data.eMail,
            phone: response.data.phone,
            areaName: response.data.areaName,
            locationName: response.data.locationName,
            currencyType: response.data.currencyType,
            salary: response.data.salary
              ? response.data.salary.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })
              : "-",
            startDate: response.data.startDate
              ? moment(response.data.startDate).format("DD/MM/YYYY")
              : null,
            endDate: response.data.endDate
              ? moment(response.data.endDate).format("DD/MM/YYYY")
              : null,
            salaryVestingDate: response.data.salaryVestingDate
              ? moment(response.data.salaryVestingDate).format("DD/MM/YYYY")
              : null,
            birthDate: response.data.birthDate
              ? moment(response.data.birthDate).format("DD/MM/YYYY")
              : null,
            status: response.data.status,
            image: response.data.image,
            title: response.data.title,
            categoryArr: response.data.category,
            productArr: response.data.product,
            saleRegionArr: response.data.salesRegion,
            createdByFullName: response.data.createdByFullName,
            createdAt: response.data.createdAt ? response.data.createdAt : null,
            updatedByFullName: response.data.updatedByFullName,
            updatedAt: response.data.updatedAt ? response.data.updatedAt : null,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const name = this.state.firstName + " " + this.state.lastName;
    var phone = this.state.phone,
      maskPhone = `${phone.substring(0, 1)}  ${
        "(" + phone.substring(1, 4) + ")"
      } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
        9,
        11
      )}`;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="employeeDetail">
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  
                  <tr>
                    <th>Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.image ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.image}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noFotoMember}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      )}
                    </td>
                  </tr>

                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.image}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal>

                  <tr>
                    <th>Grup Adı</th>
                    <td colSpan="4">{this.state.groupName}</td>
                  </tr>

                  <tr>
                    <th>Ünvan</th>
                    <td colSpan="4">
                      {this.state.title ? this.state.title : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ad Soyad</th>
                    <td colSpan="4">{name ? name : "-"}</td>
                  </tr>

                  <tr>
                    <th>Kullanıcı Adı</th>
                    <td colSpan="4">
                      {this.state.userName ? this.state.userName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>E-Mail</th>
                    <td colSpan="4">
                      {this.state.eMail ? this.state.eMail : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Telefon</th>
                    <td colSpan="4">{maskPhone ? maskPhone : "-"}</td>
                  </tr>

                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">
                    {this.state.locationName === null ? "-" : this.state.locationName}
                    </td>
                  </tr>

                  <tr>
                    <th>Bölge</th>
                    <td colSpan="4">
                    {this.state.areaName === null ? "-" : this.state.areaName}
                    </td>
                  </tr>

                  <tr>
                    <th>Maaş</th>
                    <td colSpan="4">
                      {`${
                        this.state.salary ? this.state.salary : "-"
                      } ${CurrencyTypeOptions(this.state.currencyType)}`}
                    </td>
                  </tr>

                  <tr>
                    <th>Giriş Tarihi</th>
                    <td colSpan="4">
                      {this.state.startDate ? this.state.startDate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Çıkış Tarihi</th>
                    <td colSpan="4">
                      {this.state.endDate ? this.state.endDate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Maaş Hak Ediş Tarihi</th>
                    <td colSpan="4">
                      {this.state.salaryVestingDate
                        ? this.state.salaryVestingDate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Doğum Tarihi</th>
                    <td colSpan="4">
                      {this.state.birthDate ? this.state.birthDate : "-"}
                    </td>
                  </tr>

                  {/* <tr>
                    <th>Kategoriler</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.categoryArr ? this.state.categoryArr : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ürünler</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.productArr ? this.state.productArr : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Saha Satış Bölgeleri</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.saleRegionArr
                        ? this.state.saleRegionArr
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>İndirim Tutarı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.discountAmount
                        ? this.state.discountAmount
                        : "-"}
                    </td>
                  </tr> */}

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>
                      {this.state.createdByFullName
                        ? this.state.createdByFullName
                        : "-"}
                    </td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>
                      {this.state.updatedByFullName
                        ? this.state.updatedByFullName
                        : "-"}
                    </td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default EmployeeDetail;
