import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionOfferStatusesElkapol,
  TaxRateChoices,
  unitValuesStatuses,
  CurrencyType,
  UserOperationClaimControl,
  offerLanguages,
  fotoStyle,
  resizeFile,
  yesNoStatus,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import SieveProductAddUpdate from "../sieveProduct/SieveProductAddUpdate";
import OfferCoverAddUpdate from "../offerCover/OfferCoverAddUpdate";
import OfferDefinitionAddUpdate from "../offerDefinition/OfferDefinitionAddUpdate";
import LocationAddUpdate from "../location/LocationAddUpdate";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";
import PaymentTypeAddUpdate from "../paymentType/PaymentTypeAddUpdate";
import DeliveryTypeAddUpdate from "../deliveryType/DeliveryTypeAddUpdate";
import DeliveryTimeAddUpdate from "../deliveryTime/DeliveryTimeAddUpdate";
import WarrantyPeriodAddUpdate from "../warrantyPeriod/WarrantyPeriodAddUpdate";

class OfferSieveAddUpdate extends Component {
  state = {
    pageTitle: "Teklif Ekle",
    pageTitleAdd: "Teklif Ekle",
    pageTitleUpdate: "Teklif Güncelle",
    pageAddLink: "teklif3-ekle",
    pageListLink: "teklif3-listele",
    pageTitleCostLink: "tum-dokum-maliyetler",
    pageTitleTpuCostLink: "tum-tpu-maliyetler",
    breadcrumb: [{ text: "Teklif", link: "#" }],
    isLoading: false,
    urlSplit: [],
    offerSieveImageDto: [],
    offerSievePictureDto: [],
    bankArr: [],
    bankOption: [],
    bankId: [],
    customerGuid: "",
    connectionName: "",
    connectionNameError: "",
    offerDate: "",
    optionDate: "",
    offerNumber: "",
    reminderDate: "",
    guid: "",
    total: 0,
    sevkAddress: "",
    generalTotal: 0, //ara toplam diyelim
    generalDiscountTotal: 0, //toplam indirim tutarları
    grossTotal: 0, //brüt toplam (ara toplam - toplam indirim)
    generalTaxTotal: 0, //genel kdv tutatları toplamı
    taxRateValue: "",
    taxTypeValue: null,
    paymentMethodType: "",
    deliveryMethodType: "",
    deliveryTime: "",
    guaranty: "",
    title: "",
    description: "",
    descriptionHtml: "",
    // discountPercent: "",
    // discountValue: "",
    // discountTotal: "",
    currencyType: "",
    currencyTypeError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    productDiscountPercent: "",
    generalTotal1: 0,
    generalTaxTotal1: 0,
    generalDiscountTotal1: 0,
    discountTotal1: 0,
    productDiscountValue: "",
    status: 1,
    customerError: "",
    offerTypeGuidError: "",
    languageError: "",
    offerDateError: "",
    optionDateError: "",
    reminderDateError: "",
    totalError: "",
    taxRateError: "",
    taxTypeError: "",
    paymentMethodTypeError: "",
    deliveryMethodTypeError: "",
    deliveryTimeError: "",
    guarantyError: "",
    productDataError: "",
    offerCoverError: "",
    productGuid: "",
    coverGuid: "00000000-0000-0000-0000-000000000000",
    offerCoverButtonStyle: "",
    alertErrorMessage: "",
    unitError: "",
    productTaxRate: null,
    productDiscountPercentError: "",
    productDiscountValueError: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    isOpenModalCustomer: false,
    isOpenModalProduct: false,
    isOpenModalOfferCover: false,
    isOpenModalPaymentType: false,
    isOpenModalDeliveryType: false,
    isOpenModalDeliveryTime: false,
    isOpenModalWarrantyPeriod: false,
    isOpenModalLocation: false,
    modalButtonIdx: 0,
    counter: -1,
    idx: 0,
    productData: [],
    offerCoverData: [],
    descriptionData: [],
    offerMultipleModal: false,
    multipleProductArr: [],
    paymentTypeGuid: "00000000-0000-0000-0000-000000000000",
    paymentTypeArr: [],
    deliveryTimeGuid: "00000000-0000-0000-0000-000000000000",
    deliveryTimeArr: [],
    deliveryTypeGuid: "00000000-0000-0000-0000-000000000000",
    deliveryTypeArr: [],
    warrantyPeriodGuid: "00000000-0000-0000-0000-000000000000",
    warrantyPeriodArr: [],
    language: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    customerModalPlus: false,
    productModalPlus: false,
    definitionModalPlus: false,
    locationModalPlus: false,
    offerTypeGuid: "00000000-0000-0000-0000-000000000000",
    offerTypeArr: [],
    contents: "",
    garantiden: false,
    confirmationDate: "",
    confirmationDateError: "",
    reasonForCancellation: "",
    reasonForCancellationError: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if ((urlSplit[1] === "teklif3-ekle" || urlSplit[1] === "teklif3-guncelle") && await UserOperationClaimControl(1209)) {
      this.setState({
        pageTitle: "Teklif Ekle",
        pageTitleAdd: "Teklif Ekle",
        pageTitleUpdate: "Teklif Güncelle",
        pageAddLink: "teklif3-ekle",
        pageListLink: "teklif3-listele",
        offerTypeGuid: "00000000-0000-0000-0000-000000000000",
        pageType: "teklif3",
      });
    } else if ((urlSplit[1] === "teklif32-ekle" || urlSplit[1] === "teklif32-guncelle") && await UserOperationClaimControl(1252)) {
      this.setState({
        pageTitle: "Yurt Dışı Teklif Ekle",
        pageTitleAdd: "Yurt Dışı Teklif Ekle",
        pageTitleUpdate: "Yurt Dışı Teklif Güncelle",
        pageAddLink: "teklif32-ekle",
        pageListLink: "teklif32-listele",
        offerTypeGuid: "0955275F-E9C7-408D-BB40-4EC9088995D2",
        pageType: "teklif32",
      });
    } else if ((urlSplit[1] === "teklif33-ekle" || urlSplit[1] === "teklif33-guncelle") && await UserOperationClaimControl(1281)) {
      this.setState({
        pageTitle: "Yurt İçi Teklif Ekle",
        pageTitleAdd: "Yurt İçi Teklif Ekle",
        pageTitleUpdate: "Yurt İçi Teklif Güncelle",
        pageAddLink: "teklif33-ekle",
        pageListLink: "teklif33-listele",
        offerTypeGuid: "335D0B2A-9BDB-4E64-9373-5FB5D5B8A4CB",
        pageType: "teklif33",
      });
    } else {
      window.location.href = "/";
    }


    this.setState({
      isLoading: true,
      customerModalPlus: await UserOperationClaimControl(1012),
      productModalPlus: await UserOperationClaimControl(1208),
      definitionModalPlus: await UserOperationClaimControl(1058),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label:
              item.connectionName !== ""
                ? item.name + " | " + item.connectionName
                : item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/paymentTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          paymentTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/deliveryTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          deliveryTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/deliveryTimes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          deliveryTimeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/warrantyPeriods/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          warrantyPeriodArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/sieveProducts/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return (
            item.categoryName !== "SARF MALZEME" && {
              value: item.guid,
              label: `${item.code}-${item.name}-${item.sieveSize}-${item.sieveEyeSize}`,
              salePrice: item.salePrice,
              code: item.code,
              machineSize: item.machineSize,
              sieveEyeSize: item.sieveEyeSize,
              sieveSize: item.sieveSize,
              costType: item.costType,
            }
          );
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          bankOption: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/offerCovers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            src: item.image,
          };
        });
        this.setState({
          offerCoverData: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/descriptions/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.title,
          };
        });
        this.setState({
          descriptionData: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/offerTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          offerTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      await axios
        .get(URL + "/offersieves/get/" + urlSplit[2], config)
        .then((response) => {
          if (response.data.bankId !== null) {
            const bankArr = response.data.bankId.split(",");
            bankArr.forEach((element) => {
              var bankObj = {};
              bankObj.value = element;
              this.state.bankArr.push(bankObj);
            });
          }
        });
    }
    if (
      urlSplit.length === 3 &&
      (urlSplit[1] === "teklif3-guncelle" ||
        urlSplit[1] === "teklif32-guncelle" ||
        urlSplit[1] === "teklif33-guncelle")
    ) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/offersieves/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              customerGuid: response.data.customerGuid,
              connectionName: response.data.connectionName,
              offerDate: response.data.offerDate,
              optionDate:
                response.data.optionDate === "01/01/1900"
                  ? ""
                  : response.data.optionDate,
              reminderDate: response.data.reminderDate,
              productData: response.data.offerSieveProductDto,
              discountTotal: response.data.total,
              generalTotal: response.data.generalTotal,
              //warrantyPeriodGuid: response.data.warrantyPeriodGuid,
              sevkAddress: response.data.sevkAddress,
              paymentTypeGuid: response.data.paymentTypeGuid,
              deliveryTypeGuid: response.data.deliveryTypeGuid,
              deliveryTimeGuid: response.data.deliveryTimeGuid,
              generalDiscountTotal: response.data.generalDiscountTotal,
              generalTaxTotal: response.data.generalTaxTotal,
              language: response.data.language,
              offerSieveImageDto: response.data.offerSieveImageDto,
              offerSievePictureDto: response.data.offerSievePictureDto,
              descriptionHtml: response.data.description,
              // description: EditorState.createWithContent(
              //   ContentState.createFromBlockArray(
              //     htmlToDraft(response.data.description)
              //   )
              // ),
              description: response.data.description,
              coverGuid: response.data.coverGuid,
              currencyType: response.data.currencyType,
              status: response.data.status,
              isLoading: false,
              offerNumber: response.data.offerNumber,
              counter: response.data.offerSieveProductDto.length - 1,
              offerTypeGuid: response.data.offerTypeGuid,
              contents: response.data.contents,
              garantiden: response.data.garantiden,
              reasonForCancellation: response.data.reasonForCancellation,
            });
            let productData = this.state.productData;
            productData.forEach((item, idx) => {
              if (item.productDiscountPercent === 0) {
                item.productDiscountPercent = "";
              }
              if (item.productDiscountValue === 0) {
                item.productDiscountValue = "";
              }
              this.ProductPriceCalculate(idx);
            });
            this.setState({
              productData: productData,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        customerGuid: "",
        offerDate: "",
        optionDate: "",
        reminderDate: "",
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        paymentMethodType: null,
        deliveryMethodType: null,
        deliveryTime: null,
        guaranty: null,
        productData: [],
        descriptionData: [],
        description: "",
        descriptionHtml: "",
        coverGuid: "",
        currencyType: "",
        isDiscountPercent: false,
        isDiscountValue: false,
        status: 1,
        counter: -1,
        offerTypeGuid: "00000000-0000-0000-0000-000000000000",
      });

      if (this.state.pageType === "teklif32") {
        this.setState({
          offerTypeGuid: "0955275F-E9C7-408D-BB40-4EC9088995D2",
        });
      } else if (this.state.pageType === "teklif33") {
        this.setState({
          offerTypeGuid: "335D0B2A-9BDB-4E64-9373-5FB5D5B8A4CB",
        });
      }
    }

    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
    if (
      this.state.isOpenModalOfferCover &&
      this.props.location.modalAddedOfferCover !== undefined &&
      this.props.location.modalAddedOfferCover.isModal !== undefined &&
      this.props.location.modalAddedOfferCover.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/offerCovers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              src: item.image,
            };
          });
          this.setState({
            offerCoverData: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedOfferCover.name &&
              element.name === this.props.location.modalAddedOfferCover.name
            ) {
              this.setState({
                coverGuid: element.guid,
                offerCoverError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedOfferCover.isModal = true;
      this.setState({
        isOpenModalOfferCover: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedSieveProduct !== undefined &&
      this.props.location.modalAddedSieveProduct.isModal !== undefined &&
      this.props.location.modalAddedSieveProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/sieveproducts/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}-${item.sieveSize}-${item.sieveEyeSize}`,
              salePrice: item.salePrice,
              code: item.code,
              machineSize: item.machineSize,
              sieveSize: item.sieveSize,
              sieveEyeSize: item.sieveEyeSize,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSieveProduct.data &&
              `${element.code}-${element.name}` ===
              this.props.location.modalAddedSieveProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
            }
          });
          if (this.props.location.modalAddedSieveProduct.salePrice) {
            productData[this.state.modalButtonIdx].unitPrice = parseFloat(
              this.props.location.modalAddedSieveProduct.salePrice
            );
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].unit = "";
            productData[this.state.modalButtonIdx].productTaxRate = null;
            productData[this.state.modalButtonIdx].productDiscountPercent = "";
            productData[this.state.modalButtonIdx].productDiscountValue = "";
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
            productData[this.state.modalButtonIdx].averageCost = "";
            productData[this.state.modalButtonIdx].listTotal = "";
            productData[this.state.modalButtonIdx].profitRate = "";
          }
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSieveProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].locationGuid =
                element.guid;
              productData[this.state.modalButtonIdx].locationError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
    if (
      this.state.isOpenModalPaymentType &&
      this.props.location.modalAddedPaymentType !== undefined &&
      this.props.location.modalAddedPaymentType.isModal !== undefined &&
      this.props.location.modalAddedPaymentType.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/paymentTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            paymentTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedPaymentType.data &&
              element.name === this.props.location.modalAddedPaymentType.data
            ) {
              this.setState({
                paymentTypeGuid: element.guid,
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedPaymentType.isModal = true;
      this.setState({
        isOpenModalPaymentType: false,
      });
    }
    if (
      this.state.isOpenModalDeliveryType &&
      this.props.location.modalAddedDeliveryType !== undefined &&
      this.props.location.modalAddedDeliveryType.isModal !== undefined &&
      this.props.location.modalAddedDeliveryType.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/deliveryTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            deliveryTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedDeliveryType.data &&
              element.name === this.props.location.modalAddedDeliveryType.data
            ) {
              this.setState({
                deliveryTypeGuid: element.guid,
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedDeliveryType.isModal = true;
      this.setState({
        isOpenModalDeliveryType: false,
      });
    }
    if (
      this.state.isOpenModalDeliveryTime &&
      this.props.location.modalAddedDeliveryTime !== undefined &&
      this.props.location.modalAddedDeliveryTime.isModal !== undefined &&
      this.props.location.modalAddedDeliveryTime.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/deliveryTimes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            deliveryTimeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedDeliveryTime.data &&
              element.name === this.props.location.modalAddedDeliveryTime.data
            ) {
              this.setState({
                deliveryTimeGuid: element.guid,
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedDeliveryTime.isModal = true;
      this.setState({
        isOpenModalDeliveryTime: false,
      });
    }
    if (
      this.state.isOpenModalWarrantyPeriod &&
      this.props.location.modalAddedWarrantyPeriod !== undefined &&
      this.props.location.modalAddedWarrantyPeriod.isModal !== undefined &&
      this.props.location.modalAddedWarrantyPeriod.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/warrantyPeriods/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            warrantyPeriodArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedWarrantyPeriod.data &&
              element.name === this.props.location.modalAddedWarrantyPeriod.data
            ) {
              this.setState({
                warrantyPeriodGuid: element.guid,
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedWarrantyPeriod.isModal = true;
      this.setState({
        isOpenModalWarrantyPeriod: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleEditorChange = (editorState, editorStateHtml) => {
    if (editorStateHtml) {
      this.setState({
        description: editorState,
        descriptionHtml: editorStateHtml,
        descriptionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleBankSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        bankArr: option,
        bankError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        bankArr: [],
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      customerError: "",
      offerTypeGuidError: "",
      languageError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "customerGuid" && value === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      name === "offerTypeGuid" &&
      value === "00000000-0000-0000-0000-000000000000"
    ) {
      this.setState({
        offerTypeGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "language" && value === "") {
      this.setState({
        languageError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "reasonForCancellation" && value === "") {
      this.setState({
        reasonForCancellationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleOfferTypeSelectChange(option) {
    if (option) {
      this.setState({
        offerTypeGuid: option.value,
        offerTypeGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerTypeGuid: "00000000-0000-0000-0000-000000000000",
        offerTypeGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  uploadedImage3 = async (event) => {
    let array = this.state.offerSieveImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    }
    await this.setState({ offerSieveImageDto: array });
  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.offerSieveImageDto;
    this.state.offerSieveImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ offerSieveImageDto: array });
        }
      }
    });
  };

  uploadedImage = async (event) => {
    let array = this.state.offerSievePictureDto;
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        });
      }
    }
    await this.setState({ offerSievePictureDto: array });
  };

  deleteImage = (event) => {
    var index = -1;
    let array = this.state.offerSievePictureDto;
    this.state.offerSievePictureDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ offerSievePictureDto: array });
        }
      }
    });
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleProductTaxRateSelectChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productTaxRate = option.value;
      productData[idx].productTaxRateError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productTaxRate = null;
      productData[idx].productTaxRateError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleOfferDateChange(offerDate) {
    if (offerDate) {
      this.setState({
        offerDate: offerDate,
        offerDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerDate: "",
        offerDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleConfirmationDateChange(confirmationDate) {
    if (confirmationDate) {
      this.setState({
        confirmationDate: confirmationDate,
        confirmationDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        confirmationDate: "",
        confirmationDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      offerMultipleModal: !this.state.offerMultipleModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleMultipleProductAdd(item.value);
        }
      });
    });
  };

  handleMultipleProductAdd = async (value) => {
    let productData = this.state.productData;
    await productData.push({
      productGuid: value,
      locationGuid: "",
      piece: 1,
      unitPrice: 1,
      total: 1,
      discountTotal: 1,
      unit: "NIU",
      taxTotal: 0,
      productDiscountTotal: 0,
      productDiscountPercent: 0,
      productDiscountValue: 0,
      averageCost: 0,
      listTotal: 0,
      profitRate: 0,
      productTaxRate: null,
      locationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });

    this.setState({ productData: productData });
  };

  handleOptionDateChange(optionDate) {
    if (optionDate) {
      this.setState({
        optionDate: optionDate,
        optionDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        optionDate: "",
        optionDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleReminderDateChange(reminderDate) {
    if (reminderDate) {
      this.setState({
        reminderDate: reminderDate,
        reminderDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        reminderDate: "",
        reminderDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCustomerSelectChange(option) {
    if (option) {
      var splitCustomer = option.label.split("|");
      if (splitCustomer.length === 2) {
        this.setState({
          connectionName: splitCustomer[1],
        });
      } else {
        this.setState({
          connectionName: "",
        });
      }
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        connectionName: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePaymentTypeSelectChange(option) {
    if (option) {
      this.setState({
        paymentTypeGuid: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        paymentTypeGuid: "",
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDeliveryTypeSelectChange(option) {
    if (option) {
      this.setState({
        deliveryTypeGuid: option.value,
        deliveryMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        deliveryTypeGuid: "",
        deliveryMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDeliveryTimeSelectChange(option) {
    if (option) {
      this.setState({
        deliveryTimeGuid: option.value,
        deliveryTimeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        deliveryTimeGuid: "",
        deliveryTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleWarrantyPeriodSelectChange(option) {
    if (option) {
      this.setState({
        warrantyPeriodGuid: option.value,
        guarantyError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        warrantyPeriodGuid: "",
        guarantyError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange2 = (tags) => (event) => {
    const { name, value } = event.target;
    let productData = this.state.productData;
    var idx = tags;

    productData[idx][name] = value;
    this.setState({
      productData: productData,
    });
  };

  handleLanguageSelectChange(option) {
    if (option) {
      this.setState({
        language: option.value,
        languageError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        language: "",
        languageError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePaymentMethodSelectChange(option) {
    if (option) {
      this.setState({
        paymentMethodType: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        paymentMethodType: null,
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDeliveryMethodSelectChange(option) {
    if (option) {
      this.setState({
        deliveryMethodType: option.value,
        deliveryMethodTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        deliveryMethodType: null,
        deliveryMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleOfferCoverSelectChange(option) {
    if (option) {
      this.setState({
        coverGuid: option,
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleGarantidenSelectChange(garantiden) {
    if (garantiden) {
      this.setState({
        garantiden: garantiden.value,
      });
    } else {
      this.setState({
        garantiden: false,
      });
    }
  }

  handleStatusSelectChange(status) {
    if (status) {
      this.setState({
        status: status.value,
      });
    }
  }

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  addModalOfferCover = async () => {
    await this.setState({
      isOpenModalOfferCover: !this.state.isOpenModalOfferCover,
    });
  };

  addModalDefinitionWarrantyPeriod = () => {
    this.setState({
      isOpenModalWarrantyPeriod: !this.state.isOpenModalWarrantyPeriod,
    });
  };

  addModalDefinitionPaymentType = () => {
    this.setState({
      isOpenModalPaymentType: !this.state.isOpenModalPaymentType,
    });
  };

  addModalDefinitionDeliveryType = () => {
    this.setState({
      isOpenModalDeliveryType: !this.state.isOpenModalDeliveryType,
    });
  };

  addModalDefinitionDeliveryTime = () => {
    this.setState({
      isOpenModalDeliveryTime: !this.state.isOpenModalDeliveryTime,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;
      item.averageCost = "";
      item.listTotal = "";
      item.profitRate = "";

      item.productError = CgnMessage.textErrorMessage;
      item.productPieceError = CgnMessage.textErrorMessage;
      item.productUnitPriceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      customerGuid: "",
      total: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      offerDate: "",
      optionDate: "",
      reminderDate: "",
      paymentMethodType: null,
      deliveryMethodType: null,
      deliveryTime: null,
      guaranty: null,
      description: "",
      descriptionHtml: "",
      coverGuid: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      status: 1,
      counter: -1,
      garantiden: false,

      offerTypeGuid: "00000000-0000-0000-0000-000000000000",
      alertErrorMessage: CgnMessage.alertErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      offerTypeGuidError: CgnMessage.textErrorMessage,
      languageError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      offerDateError: CgnMessage.textErrorMessage,
      //optionDateError: CgnMessage.textErrorMessage,
      reminderDateError: CgnMessage.textErrorMessage,
      paymentMethodTypeError: CgnMessage.textErrorMessage,
      deliveryMethodTypeError: CgnMessage.textErrorMessage,
      deliveryTimeError: CgnMessage.textErrorMessage,
      guarantyError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
      //offerCoverError: CgnMessage.textErrorMessage,
    });

    if (this.state.pageType === "teklif32") {
      this.setState({
        offerTypeGuid: "0955275F-E9C7-408D-BB40-4EC9088995D2",
      });
    } else if (this.state.pageType === "teklif33") {
      this.setState({
        offerTypeGuid: "335D0B2A-9BDB-4E64-9373-5FB5D5B8A4CB",
      });
    }
  }

  toggle = () => {
    this.setState({
      offerMultipleModal: !this.state.offerMultipleModal,
    });
  };

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: "",
      locationGuid: "",
      piece: 1,
      unitPrice: 0,
      taxTotal: 0,
      productDiscountTotal: 0,
      unit: "",
      productDiscountPercent: "",
      productDiscountValue: "",
      total: 0,
      discountTotal: 0,
      averageCost: "",
      listTotal: "",
      costType: "",
      profitRate: "",
      productTaxRate: null,
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
      productTaxRateError: CgnMessage.textErrorMessage,
      purchasePrice: 0,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (array[this.state.counter].unit) {
      return array[this.state.counter].unitError === "";
    }
    if (array[this.state.counter].productTaxRate) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountPercent) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountValue) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (
      array[this.state.counter].productGuid === "" ||
      array[this.state.counter].locationGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].unit === "" ||
      array[this.state.counter].productTaxRate === null
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleOfferNumberTextBoxChange(e) {
    if (e.target.value !== "") {
      this.setState({
        offerNumber: e.target.value,
        offerNumberError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerNumber: "",
        offerNumberError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleConnectionNameChange(e) {
    if (e.target.value !== "") {
      this.setState({
        connectionName: e.target.value,
        connectionNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        connectionName: "",
        connectionNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductDiscountPercentChange(e, idx) {
    let productData = this.state.productData;
    if (e.target.value !== "") {
      productData[idx].productDiscountPercent = parseFloat(e.target.value);
    } else {
      productData[idx].productDiscountPercent = "";
    }
    this.setState({
      productData: productData,
    });

    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductDiscountValueChange(e, idx) {
    let productData = this.state.productData;
    if (e.target.value !== "") {
      productData[idx].productDiscountValue = parseFloat(e.target.value);
    } else {
      productData[idx].productDiscountValue = "";
    }
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleUnitChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].unit = option.value;
      productData[idx].unitError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].unit = "";
      productData[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handlePurchasePriceChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].purchasePrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
  }

  async handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].unitPrice = "0";
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].productUnitPriceError = "";
      productData[idx].machineSize = option.machineSize;
      productData[idx].sieveSize = option.sieveSize;
      productData[idx].sieveEyeSize = option.sieveEyeSize;
      productData[idx].costType = option.costType;

      if (option.costType === 1) {
        this.setState({
          isLoading: true,
        });
        // 
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };

        this.setState({
          productData: productData,
          isLoading: false,
        });
      }
      if (option.costType === 2) {
        this.setState({
          isLoading: true,
        });
        // 
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        // await axios
        //   .get(URL + "/tpuCosts/getcastingcost/" + option.value, config)
        //   .then((response) => {
        //     productData[idx].unitPrice = response.data;
        //   });

        this.setState({
          productData: productData,
          isLoading: false,
        });
      }
    } else {
      productData[idx].productGuid = null;
      productData[idx].piece = 0;
      productData[idx].unitPrice = 0;
      productData[idx].machineSize = "";
      productData[idx].costType = "";
      productData[idx].sieveSize = "";
      productData[idx].sieveEyeSize = "";
      productData[idx].productError = CgnMessage.textErrorMessage;
      productData[idx].productPieceError = CgnMessage.textErrorMessage;
      productData[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = "";
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  async ProductPriceCalculate(idx) {
    var totalProductPrice = 0.0;
    var totalProductTotal = 0.0;
    var itemTotal = 0.0;
    var percent = 0.0;
    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;
    var taxTotal = 0;

    let productData = this.state.productData;

    var discountPercent = parseFloat(productData[idx].productDiscountPercent);

    var discountValue = parseFloat(productData[idx].productDiscountValue);

    itemTotal = (
      parseFloat(productData[idx].piece) *
      parseFloat(productData[idx].unitPrice)
    ).toFixed(2);

    totalProductPrice += parseFloat(itemTotal);

    if (discountPercent > 0) {
      percent = parseFloat(totalProductPrice) * (discountPercent / 100);
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - parseFloat(percent);

      productData[idx].total2 = parseFloat(totalProductTotal).toFixed(2);

      var newProductTaxRate = parseInt(productData[idx].productTaxRate);
      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );
      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total = parseFloat(
        parseFloat(totalProductTotal) + parseFloat(taxTotal)
      ).toFixed(2);
    } else if (discountValue > 0) {
      percent = discountValue * parseFloat(productData[idx].piece);
      productData[idx].productDiscountTotal = percent;
      totalProductTotal =
        parseFloat(totalProductPrice) -
        discountValue * parseFloat(productData[idx].piece);

      productData[idx].total2 = parseFloat(totalProductTotal).toFixed(2);

      var newProductTaxRate = parseInt(productData[idx].productTaxRate);

      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total = parseFloat(
        parseFloat(totalProductTotal) + parseFloat(taxTotal)
      ).toFixed(2);
    } else {
      productData[idx].productDiscountTotal = 0;

      productData[idx].total2 = parseFloat(totalProductPrice).toFixed(2);

      var newProductTaxRate = parseInt(productData[idx].productTaxRate);
      taxTotal = parseFloat(
        totalProductPrice * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);

      totalProductTotal = parseFloat(taxTotal) + parseFloat(totalProductPrice);
      productData[idx].total = parseFloat(totalProductTotal).toFixed(2);
    }

    this.setState({
      productData: productData,
    });
    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      discountTotal: parseFloat(discountTotal1).toFixed(2),
    });
  }

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductRemove(idx) {
    let someArray = await this.state.productData;

    await someArray.splice(idx, 1);

    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });

    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;

    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);


    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      discountTotal: parseFloat(discountTotal1).toFixed(2),
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      customerError: "",
      offerTypeGuidError: "",
      languageError: "",
      totalError: "",
      taxRateError: "",
      taxTypeError: "",
      offerDateError: "",
      optionDateError: "",
      reminderDateError: "",
      paymentMethodTypeError: "",
      deliveryMethodTypeError: "",
      deliveryTimeError: "",
      guarantyError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
      productDataError: "",
      offerCoverError: "",
      offerNumberError: "",
      confirmationDateError: "",
      reasonForCancellationError: "",
    });
    if (this.state.offerNumber === "") {
      this.setState({
        offerNumberError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.connectionName === "") {
      this.setState({
        connectionNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.language === "") {
      await this.setState({
        languageError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.offerTypeGuid === "00000000-0000-0000-0000-000000000000") {
      await this.setState({
        offerTypeGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.offerDate === "") {
      this.setState({
        offerDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.reminderDate === "") {
      this.setState({
        reminderDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.productData !== null || this.state.productData.length > 0) {
      this.state.productData.forEach((element) => {
        if (element.unit === "" || element.productTaxRate === null) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (this.state.status === 2 && this.state.confirmationDate === "") {
      this.setState({
        confirmationDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.status === 3 && this.state.reasonForCancellation === "") {
      this.setState({
        confirmationDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.discountTotal === "") {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.offerNumber !== "" &&
      this.state.language !== "" &&
      this.state.offerTypeGuid !== "00000000-0000-0000-0000-000000000000" &&
      this.state.customerGuid !== "" &&
      this.state.connectionName !== "" &&
      //this.state.total &&
      this.state.offerDate !== "" &&
      this.state.reminderDate !== "" &&
      this.state.currencyType &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      this.state.isNull === false &&
      this.state.discountTotal !== "" &&
      (this.state.status !== 2 || (this.state.status === 2 && this.state.confirmationDate !== "")) &&
      (this.state.status !== 3 || (this.state.status === 3 && this.state.reasonForCancellation !== "")) &&
      control === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          productGuid: element.productGuid,
          locationGuid: element.locationGuid,
          piece: element.piece,
          unitPrice: element.unitPrice,
          unit: element.unit,
          productDiscountPercent:
            element.productDiscountPercent !== ""
              ? parseFloat(element.productDiscountPercent)
              : 0,
          productDiscountValue:
            element.productDiscountValue !== ""
              ? parseFloat(element.productDiscountValue)
              : 0,
          productTaxRate: element.productTaxRate,
          total: parseFloat(element.total),
          averageCost:
            element.averageCost !== "" ? parseFloat(element.averageCost) : 0,
          listTotal:
            element.listTotal !== "" ? parseFloat(element.listTotal) : 0,
          profitRate:
            element.profitRate !== "" ? parseFloat(element.profitRate) : 0,
          machineSize: element.machineSize,
          sieveEyeSize: element.sieveEyeSize,
          sieveSize: element.sieveSize,
          purchasePrice: parseFloat(element.purchasePrice),
        });
      });

      var banks = [];
      this.state.bankArr.forEach((element) => {
        var bankObj = {};
        bankObj = element.value;
        banks.push(bankObj);
      });

      var myObj = {
        offerNumber: this.state.offerNumber,
        customerGuid: this.state.customerGuid,
        connectionName: this.state.connectionName,
        offerDate: moment(this.state.offerDate).format("DD/MM/YYYY"),
        optionDate:
          this.state.optionDate !== ""
            ? moment(this.state.optionDate).format("DD/MM/YYYY")
            : null,
        reminderDate: moment(this.state.reminderDate).format("DD/MM/YYYY"),
        generalTotal: parseFloat(this.state.generalTotal),
        //warrantyPeriodGuid: this.state.warrantyPeriodGuid,
        sevkAddress: this.state.sevkAddress,
        paymentTypeGuid: this.state.paymentTypeGuid,
        deliveryTypeGuid: this.state.deliveryTypeGuid,
        deliveryTimeGuid: this.state.deliveryTimeGuid,
        description: this.state.description,
        coverGuid: this.state.coverGuid,
        currencyType: this.state.currencyType,
        total: parseFloat(this.state.discountTotal),
        generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
        generalTaxTotal: parseFloat(this.state.generalTaxTotal),
        status: this.state.status,
        offerSieveProductDto: productData,
        language:
          this.state.language === "" || this.state.language === null
            ? null
            : this.state.language,
        offerSieveImageDto: this.state.offerSieveImageDto,
        offerSievePictureDto: this.state.offerSievePictureDto,
        bankId: banks.toString(),
        offerTypeGuid: this.state.offerTypeGuid,
        contents: this.state.contents,
        garantiden: this.state.garantiden,
        confirmationDate:
          this.state.confirmationDate !== ""
            ? moment(this.state.confirmationDate).format("DD/MM/YYYY")
            : null,
        reasonForCancellation: this.state.reasonForCancellation,
      };
      var URLParam = "/offersieves/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/offersieves/update";
        myObj = {
          guid: this.state.guid,
          offerNumber: this.state.offerNumber,
          customerGuid: this.state.customerGuid,
          connectionName: this.state.connectionName,
          offerDate:
            this.state.offerDate &&
              this.state.offerDate.toString().indexOf("/") > 0
              ? new Date(
                this.state.offerDate.split("/")[2] +
                "-" +
                this.state.offerDate.split("/")[1] +
                "-" +
                this.state.offerDate.split("/")[0]
              )
              : moment(this.state.offerDate).format("DD/MM/YYYY"),
          optionDate:
            this.state.optionDate &&
              this.state.optionDate.toString().indexOf("/") > 0
              ? new Date(
                this.state.optionDate.split("/")[2] +
                "-" +
                this.state.optionDate.split("/")[1] +
                "-" +
                this.state.optionDate.split("/")[0]
              )
              : null,
          reminderDate:
            this.state.reminderDate &&
              this.state.reminderDate.toString().indexOf("/") > 0
              ? new Date(
                this.state.reminderDate.split("/")[2] +
                "-" +
                this.state.reminderDate.split("/")[1] +
                "-" +
                this.state.reminderDate.split("/")[0]
              )
              : moment(this.state.reminderDate).format("DD/MM/YYYY"),
          generalTotal: parseFloat(this.state.generalTotal),
          //warrantyPeriodGuid: this.state.warrantyPeriodGuid,
          sevkAddress: this.state.sevkAddress,
          paymentTypeGuid: this.state.paymentTypeGuid,
          deliveryTypeGuid: this.state.deliveryTypeGuid,
          deliveryTimeGuid: this.state.deliveryTimeGuid,
          description: this.state.description,
          coverGuid: this.state.coverGuid,
          currencyType: this.state.currencyType,
          total: parseFloat(this.state.discountTotal),
          generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
          generalTaxTotal: parseFloat(this.state.generalTaxTotal),
          status: this.state.status,
          offerSieveProductDto: productData,
          language:
            this.state.language === "" || this.state.language === null
              ? null
              : this.state.language,
          offerSieveImageDto: this.state.offerSieveImageDto,
          offerSievePictureDto: this.state.offerSievePictureDto,
          bankId: banks.toString(),
          offerTypeGuid: this.state.offerTypeGuid,
          contents: this.state.contents,
          garantiden: this.state.garantiden,
          confirmationDate:
            this.state.confirmationDate !== ""
              ? moment(this.state.confirmationDate).format("DD/MM/YYYY")
              : null,
          reasonForCancellation: this.state.reasonForCancellation,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              if (this.state.pageType === "teklif32") {
                window.location.href = "/teklif32-listele";
              } else if (this.state.pageType === "teklif33") {
                window.location.href = "/teklif33-listele";
              } else {
                window.location.href = "/teklif3-listele";
              }
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    var self = this.state;
    const textAreaStyle = {
      padding: "5px 10px",
      whiteSpace: "pre-line",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="piece"
                      label="Teklif Numarası [*]"
                      value={this.state.offerNumber}
                      onChange={(e) => this.handleOfferNumberTextBoxChange(e)}
                      placeHolder="Lütfen teklif numarası girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.offerNumberError}
                      step="0.1"
                      min={0}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="language"
                      label="Teklif Dili [*]"
                      placeholder="Lütfen teklif dili seçiniz..."
                      selectValue={this.state.language}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleLanguageSelectChange(option)
                      }
                      options={offerLanguages}
                      error={this.state.languageError}
                    />
                  </Col>
                  <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    {this.state.pageType !== "teklif32" && this.state.pageType !== "teklif33" && (
                      <CgnReactSelect
                        name="offerTypeGuid"
                        label="Teklif Türü [*]"
                        placeholder="Lütfen teklif türü seçiniz..."
                        selectValue={this.state.offerTypeGuid}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) =>
                          this.handleOfferTypeSelectChange(option)
                        }
                        options={this.state.offerTypeArr}
                        error={this.state.offerTypeGuidError}
                      />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="offerDate"
                      label="Teklif Tarihi [*]"
                      selected={
                        this.state.offerDate &&
                          this.state.offerDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.offerDate.split("/")[2] +
                            "-" +
                            this.state.offerDate.split("/")[1] +
                            "-" +
                            this.state.offerDate.split("/")[0]
                          )
                          : this.state.offerDate
                      }
                      onChange={(offerDate) =>
                        this.handleOfferDateChange(offerDate)
                      }
                      error={this.state.offerDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="connectionName"
                      label="Yetkili Ad Soyad [*]"
                      value={this.state.connectionName}
                      placeHolder="Lütfen yetkili ad soyad girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={(e) => this.handleConnectionNameChange(e)}
                      error={this.state.connectionNameError}
                    />
                    {/* <CgnDatePicker
                      name="optionDate"
                      label="Opsiyon Tarihi"
                      selected={
                        this.state.optionDate &&
                        this.state.optionDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.optionDate.split("/")[2] +
                                "-" +
                                this.state.optionDate.split("/")[1] +
                                "-" +
                                this.state.optionDate.split("/")[0]
                            )
                          : this.state.optionDate
                      }
                      onChange={(optionDate) =>
                        this.handleOptionDateChange(optionDate)
                      }
                      error={this.state.optionDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    /> */}
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="reminderDate"
                      label="Hatırlatma Tarihi [*]"
                      selected={
                        this.state.reminderDate &&
                          this.state.reminderDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.reminderDate.split("/")[2] +
                            "-" +
                            this.state.reminderDate.split("/")[1] +
                            "-" +
                            this.state.reminderDate.split("/")[0]
                          )
                          : this.state.reminderDate
                      }
                      onChange={(reminderDate) =>
                        this.handleReminderDateChange(reminderDate)
                      }
                      error={this.state.reminderDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                </Row>

                {this.state.productData.map((productData, idx) => (
                  <>
                    <Row className="mobileBorder">
                      <Col lg="2" className="modalSelectCol">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="locationGuid"
                              label="Depo [*]"
                              placeholder="Lütfen depo seçiniz..."
                              selectValue={productData.locationGuid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleLocationInputValueChange(
                                  option,
                                  idx
                                );
                              }}
                              options={this.state.locationArr}
                              error={productData.locationError}
                            />
                          </div>
                          {this.state.locationModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() => this.addModalLocation(idx)}
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg="7" className="modalSelectCol">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="productGuid"
                              label="Ürün [*]"
                              placeholder="Lütfen ürün seçiniz..."
                              selectValue={productData.productGuid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleProductInputValueChange(option, idx);
                              }}
                              options={this.state.productArr}
                              error={productData.productError}
                            />
                          </div>
                          {this.state.productModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() => this.addModalProduct(idx)}
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg="2">
                        <CgnTextbox
                          type="text"
                          name="machineSize"
                          label="Makine Boyutu"
                          value={productData.machineSize}
                          onChange={this.handleChange2(idx)}
                          placeHolder="Lütfen makine boyutu girin..."
                          maxLength="50"
                          autoComplete="off"
                          error={productData.machineSizeError}
                        />
                      </Col>
                      <Col
                        lg="1"
                      >
                        <div style={{
                          display:
                            productData.productGuid !== "" &&
                              productData.costType === 1
                              ? ""
                              : "none",
                        }}>
                          <Button
                            type="button"
                            color="primary"
                            style={{ width: "100%", marginTop: "30px", height: "36px", paddingTop: "8px" }}
                            text="Tüm Maliyet"
                            href={`/${self.pageTitleCostLink}/${productData.productGuid}`}
                            target="_blank"
                          >
                            {" "}
                            Tüm Maliyet
                          </Button>
                        </div>
                        <div style={{
                          display:
                            productData.productGuid !== "" &&
                              productData.costType === 2
                              ? ""
                              : "none",
                        }}>
                          <Button
                            type="button"
                            color="primary"
                            style={{ width: "100%", marginTop: "30px", height: "36px", paddingTop: "8px" }}
                            text="Tüm Maliyet"
                            href={`/${self.pageTitleTpuCostLink}/${productData.productGuid}`}
                            target="_blank"
                          >
                            {" "}
                            Tüm Maliyet
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pl-0">
                      <Col className="pl-0">
                        <CgnTextbox
                          type="number"
                          name="piece"
                          label="Miktar [*]"
                          value={productData.piece}
                          onChange={(e) =>
                            this.handleProductPieceTextBoxChange(e, idx)
                          }
                          placeHolder="Lütfen miktar girin..."
                          maxLength="50"
                          autoComplete="off"
                          error={productData.productPieceError}
                          step="0.1"
                          min={0}
                        />
                      </Col>

                      <Col>
                        <CgnReactSelect
                          name="unit"
                          label="Birim [*]"
                          placeholder="Birim"
                          selectValue={productData.unit}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleUnitChange(option, idx)
                          }
                          options={unitValuesStatuses}
                          error={productData.unitError}
                        />
                      </Col>

                      <Col>
                        <CgnTextbox
                          type="number"
                          name="unitPrice"
                          label="Birim Fiyat [*]"
                          value={productData.unitPrice}
                          onChange={(e) =>
                            this.handleProductUnitPriceTextBoxChange(e, idx)
                          }
                          placeHolder="Lütfen birim fiyat girin..."
                          maxLength="50"
                          autoComplete="off"
                          error={productData.productUnitPriceError}
                        />
                      </Col>
                      <Col>
                        <CgnTextbox
                          type="number"
                          name="productDiscountPercent"
                          label="İskonto(Yüzde)"
                          value={productData.productDiscountPercent}
                          placeHolder="İskonto"
                          maxLength="50"
                          autoComplete="off"
                          readOnly={this.state.isProductDiscountPercent}
                          onChange={(e) =>
                            this.handleProductDiscountPercentChange(e, idx)
                          }
                          error={productData.productDiscountPercentError}
                        />
                      </Col>
                      <Col>
                        <CgnTextbox
                          type="number"
                          name="productDiscountValue"
                          label="İskonto(Net Tutar)"
                          value={productData.productDiscountValue}
                          placeHolder="İskonto net tutar"
                          maxLength="50"
                          autoComplete="off"
                          readOnly={this.state.isProductDiscountValue}
                          onChange={(e) =>
                            this.handleProductDiscountValueChange(e, idx)
                          }
                          error={productData.productDiscountValueError}
                        />
                      </Col>

                      <Col>
                        <CgnTextbox
                          type="number"
                          name="total2"
                          label="Fiyat [*]"
                          value={productData.total2}
                          readOnly={true}
                          maxLength="50"
                          autoComplete="off"
                        />
                      </Col>

                      <Col>
                        <CgnReactSelect
                          name="productTaxRate"
                          label="K.D.V Oranı [*]"
                          placeholder="K.D.V oranı"
                          selectValue={productData.productTaxRate}
                          isMulti={false}
                          isClearable={true}
                          disabled={this.state.isTrue}
                          onChange={(option) =>
                            this.handleProductTaxRateSelectChange(option, idx)
                          }
                          options={TaxRateChoices}
                          error={productData.productTaxRateError}
                        />
                      </Col>

                      <Col>
                        <CgnTextbox
                          type="number"
                          name="total"
                          label="Fiyat [*]"
                          value={productData.total}
                          readOnly={true}
                          //onChange={this.ProductPriceCalculate()}
                          maxLength="50"
                          autoComplete="off"
                        />
                      </Col>

                      <Col>
                        <CgnTextbox
                          type="number"
                          name="purchasePrice"
                          label="Alış Fiyat"
                          value={productData.purchasePrice}
                          onChange={(e) =>
                            this.handlePurchasePriceChange(e, idx)
                          }
                          maxLength="50"
                          autoComplete="off"
                        />
                      </Col>

                      <Col>
                        <CgnButton
                          className="dynamicRowDeleteButton"
                          type="button"
                          color="danger"
                          onClick={() => this.handleProductRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                    <div
                      className="cizgi"
                      style={{
                        width: "100%",
                        border: "1px solid red",
                        marginBottom: "5px",
                      }}
                    ></div>
                  </>
                ))}

                <p className="text-danger"><strong>Önemli Not : </strong> Teklife ürün eklerken <strong>AYNI ÜRÜNÜ</strong> alt alta birden çok defa seçmeyin, bunun yerine ürünün adedini artırın! Aksi taktirde ürün kopyalama işlemi sağlıklı çalışmayacaktır!</p>

                <Row>
                  <Col lg="2">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton"
                      onClick={this.handleProductAdd}
                      text={"Ürün Ekle"}
                    />
                    <CgnButton
                      type="button"
                      color="primary"
                      className="ml-2 dynamicRowAddButton"
                      onClick={this.toggle}
                      text={"Çoklu Ürün Ekle"}
                    />
                  </Col>
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalDiscountTotal"
                      label="İndirim Toplamı"
                      value={this.state.generalDiscountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTaxTotal"
                      label="KDV Toplamı"
                      value={this.state.generalTaxTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="paymentMethodType"
                      label="Ödeme Yöntemi"
                      placeholder="Lütfen ödeme yöntemi girin..."
                      value={this.state.paymentMethodType}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="paymentTypeGuid"
                          label="Ödeme Yöntemi"
                          placeholder="Ödeme yöntemi seçiniz..."
                          selectValue={this.state.paymentTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePaymentTypeSelectChange(option)
                          }
                          options={this.state.paymentTypeArr}
                          error={this.state.paymentMethodTypeError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalDefinitionPaymentType()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="deliveryMethodType"
                      label="Teslimat Yöntemi"
                      placeholder="Lütfen teslimat yöntemi girin..."
                      value={this.state.deliveryMethodType}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="deliveryTypeGuid"
                          label="Teslimat Yöntemi"
                          placeholder="Teslimat yöntemi seçiniz..."
                          selectValue={this.state.deliveryTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleDeliveryTypeSelectChange(option)
                          }
                          options={this.state.deliveryTypeArr}
                          error={this.state.deliveryMethodTypeError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() =>
                              this.addModalDefinitionDeliveryType()
                            }
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    {/* <CgnTextbox
                      type="text"
                      name="deliveryTime"
                      label="Teslimat Süresi"
                      placeholder="Lütfen teslimat süresi girin..."
                      value={this.state.deliveryTime}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    /> */}
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="deliveryTimeGuid"
                          label="Teslimat Süresi"
                          placeholder="Teslimat süresi seçiniz..."
                          selectValue={this.state.deliveryTimeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleDeliveryTimeSelectChange(option)
                          }
                          options={this.state.deliveryTimeArr}
                          error={this.state.deliveryTimeError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() =>
                              this.addModalDefinitionDeliveryTime()
                            }
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="sevkAddress"
                      label="Sevk Adresi"
                      placeholder="Lütfen sevk adresi girin..."
                      value={this.state.sevkAddress}
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    {/* <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="warrantyPeriodGuid"
                          label="Sevk Adresi"
                          placeholder="Sevk Adresi seçiniz..."
                          selectValue={this.state.warrantyPeriodGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleWarrantyPeriodSelectChange(option)
                          }
                          options={this.state.warrantyPeriodArr}
                          error={this.state.guarantyError}
                        />
                      </div>
                      {this.state.definitionModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalDefinitionWarrantyPeriod()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      rows={5}
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    {/* <CgnTextEditor
                      name="description"
                      label="Teklif Notu"
                      editorState={this.state.description}
                      placeHolder="Lütfen teklif notu girin..."
                      onChange={(editorState) =>
                        this.handleEditorChange(
                          editorState,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                      }
                      error={this.state.descriptionError}
                    /> */}
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="contents"
                      label="İçerik"
                      placeholder="Lütfen içerik girin..."
                      value={this.state.contents}
                      maxLength="500"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="bankArr"
                      label="Bankalar"
                      placeholder="Banka seçiniz..."
                      selectValue={this.state.bankArr}
                      isClearable={true}
                      onChange={(option) => this.handleBankSelectChange(option)}
                      isMulti={true}
                      options={this.state.bankOption}
                      error={this.state.bankError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Label className="font-weight-bolder">Teklif Kapağı</Label>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={12}>
                    {this.state.offerCoverData.map((item) => (
                      <Button
                        type="button"
                        className="offerCoverButton"
                        onClick={() =>
                          this.handleOfferCoverSelectChange(item.value)
                        }
                      >
                        {this.state.coverGuid === item.value ? (
                          <img
                            src={item.src}
                            alt={item.label}
                            className="offerCoverImage offerCoverImageSelected"
                          />
                        ) : (
                          <img
                            src={item.src}
                            alt={item.label}
                            className="offerCoverImage"
                          />
                        )}
                      </Button>
                    ))}
                  </Col>
                  {this.state.offerCoverError && (
                    <div className="invalid-feedback d-block">
                      {this.state.offerCoverError}
                    </div>
                  )}
                </Row>

                <Row>
                  <Col lg="2" className="mb-2">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton"
                      onClick={this.addModalOfferCover}
                      text={"Teklif Kapağı Ekle"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" className="pl-0">
                    <ImageUploadMulti
                      images={this.state.offerSieveImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx"
                      className="text-center text-danger"
                      label={"Belgeler"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      error={this.state.offerSieveImageDtoError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <PictureUploadMulti
                      image={this.state.offerSievePictureDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Teklif Fotoğrafları"}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      alt={this.state.name}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="garantiden"
                      label="Garantiden"
                      selectValue={this.state.garantiden}
                      isMulti={false}
                      isClearable={true}
                      placeholder="Garantiden seçiniz..."
                      options={yesNoStatus}
                      onChange={(garantiden) =>
                        this.handleGarantidenSelectChange(garantiden)
                      }
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      selectValue={this.state.status}
                      isMulti={false}
                      options={optionOfferStatusesElkapol}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                  {this.state.status === 2 &&
                    <Col lg="4">
                      <CgnDatePicker
                        name="confirmationDate"
                        label="Onaylannma Tarihi [*]"
                        selected={
                          this.state.confirmationDate &&
                            this.state.confirmationDate.toString().indexOf("/") > 0
                            ? new Date(
                              this.state.confirmationDate.split("/")[2] +
                              "-" +
                              this.state.confirmationDate.split("/")[1] +
                              "-" +
                              this.state.confirmationDate.split("/")[0]
                            )
                            : this.state.confirmationDate
                        }
                        onChange={(confirmationDate) =>
                          this.handleConfirmationDateChange(confirmationDate)
                        }
                        error={this.state.confirmationDateError}
                        maxDate={new Date(2100, 1, 1)}
                        minDate={new Date(1900, 1, 1)}
                        placeholder="GG/AA/YYYY"
                        autoComplete="off"
                      />
                    </Col>
                  }
                </Row>
                {this.state.status === 3 &&
                  <Row>
                    <Col lg="12">
                      <CgnTextbox
                        type="text"
                        name="reasonForCancellation"
                        label="İptal Sebebi [*]"
                        placeholder="Lütfen iptal sebebi girin..."
                        value={this.state.reasonForCancellation}
                        maxLength="500"
                        autoComplete="off"
                        onChange={this.handleChange}
                        error={this.state.reasonForCancellationError}
                      />
                    </Col>
                  </Row>
                }
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />

                <Row>
                  <Modal
                    isOpen={this.state.offerMultipleModal}
                    toggle={this.toggle}
                  >
                    <ModalHeader
                      toggle={this.toggle}
                      className="font-weight-bold"
                    >
                      Çoklu Ürün Ekle
                    </ModalHeader>
                    <ModalBody className="modalBody">
                      <Input
                        type="textarea"
                        className="modalTextArea"
                        onChange={(e) => this.handleChangeMultipleProduct(e)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <CgnButton
                        type="button"
                        color="primary"
                        onClick={() => this.handleClickMultipleProduct()}
                        text={CgnMessage.addButton}
                        className="modalButton"
                      />
                      <CgnButton
                        type="button"
                        color="secondary"
                        onClick={this.toggle}
                        text="Kapat"
                        className="modalButton"
                      />
                    </ModalFooter>
                  </Modal>
                </Row>
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SieveProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalOfferCover}
          toggle={this.addModalOfferCover}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Teklif Kapağı Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <OfferCoverAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalOfferCover}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalDefinition}
          toggle={this.addModalDefinition}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tanımlama Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <OfferDefinitionAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalDefinition}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalPaymentType}
          toggle={this.addModalDefinitionPaymentType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Ödeme Yöntemi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <PaymentTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalDefinitionPaymentType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalDeliveryType}
          toggle={this.addModalDefinitionDeliveryType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Teslimat Yöntemi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <DeliveryTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalDefinitionDeliveryType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalDeliveryTime}
          toggle={this.addModalDefinitionDeliveryTime}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Teslimat Süresi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <DeliveryTimeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalDefinitionDeliveryTime}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalWarrantyPeriod}
          toggle={this.addModalDefinitionWarrantyPeriod}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Garanti Süresi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <WarrantyPeriodAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalDefinitionWarrantyPeriod}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default OfferSieveAddUpdate;
