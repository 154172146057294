const obj = {
  
  success: {},
  error: {},
  login:{},
  companyUserGroup: {},
  employee:{},
  groupGetListSelect:{},

  };
  export default obj;
  