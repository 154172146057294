import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  SupplierList,
  CustomerList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class ReturnSecondList extends Component {
  state = {
    pageTitle: "İade Listele",
    pageAddLink: "yeni-iade-ekle",
    pageUpdateLink: "yeni-iade-guncelle",
    pageDetailLink: "yeni-iade-detay",
    pageListLink: "yeni-iade-listele",
    breadcrumb: [{ text: "İade", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    productReturnData: [],
    discountTotal: 0,
    totalPiece: 0,
    productItemCount: 0,
    discountTotalDolar: 0,
    totalPieceDolar: 0,
    productItemCountDolar: 0,
    discountTotalEuro: 0,
    totalPieceEuro: 0,
    productItemCountEuro: 0,
    guid: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    supplierGuid: "00000000-0000-0000-0000-000000000000",
    supplierArr: [],
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateProductReturnSecond")) {
      firstDate = localStorage.getItem("firstDateProductReturnSecond");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateProductReturnSecond")) {
      lastDate = localStorage.getItem("lastDateProductReturnSecond");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    var self = this;

    await SupplierList().then(function (data) {
      self.setState({
        supplierArr: data,
      });
    });

    if (localStorage.getItem("supplierGuidProductReturnSecond")) {
      this.setState({
        supplierGuid: localStorage.getItem("supplierGuidProductReturnSecond"),
      });
    }

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidProductReturnSecond")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidProductReturnSecond"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateProductReturnSecond", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateProductReturnSecond", this.state.lastDate);
  }

  async handleSupplierSelectChange(option) {
    if (option) {
      await this.setState({
        supplierGuid: option.value,
      });
    } else {
      await this.setState({
        supplierGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem(
      "supplierGuidProductReturnSecond",
      this.state.supplierGuid
    );
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem(
      "customerGuidProductReturnSecond",
      this.state.customerGuid
    );
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let urlSplit = window.location.pathname.split("/");
    var URLParam = "";
    if (urlSplit[1] === "musteri-detay") {
      URLParam = "/returnsSecond/getlistforcustomer/" + urlSplit[2];
      this.setState({
        detail: true,
      });
    } else if (urlSplit[1] === "tedarikci-detay") {
      URLParam = "/returnsSecond/getlistforsupplier/" + urlSplit[2];
      this.setState({
        detail: true,
      });
    } else {
      URLParam =
        "/returnsSecond/getlist" +
        "/" +
        this.state.firstDate +
        "/" +
        this.state.lastDate +
        "/" +
        this.state.supplierGuid +
        "/" +
        this.state.customerGuid;
    }
    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          productReturnData: response.data,
          isLoading: false,
        });
        var discountTotal = 0;
        var totalPiece = 0;
        var productItemCount = 0;
        var discountTotalDolar = 0;
        var totalPieceDolar = 0;
        var productItemCountDolar = 0;
        var discountTotalEuro = 0;
        var totalPieceEuro = 0;
        var productItemCountEuro = 0;
        response.data.forEach((element) => {
          if (element.currencyType === 1) {
            discountTotal += element.total;
            totalPiece += element.totalPiece;
            productItemCount += element.productItemCount;
          } else if (element.currencyType === 2) {
            discountTotalDolar += element.total;
            totalPieceDolar += element.totalPiece;
            productItemCountDolar += element.productItemCount;
          } else if (element.currencyType === 3) {
            discountTotalEuro += element.total;
            totalPieceEuro += element.totalPiece;
            productItemCountEuro += element.productItemCount;
          }
        });
        this.setState({
          discountTotal: discountTotal,
          totalPiece: totalPiece,
          productItemCount: productItemCount,
          discountTotalDolar: discountTotalDolar,
          totalPieceDolar: totalPieceDolar,
          productItemCountDolar: productItemCountDolar,
          discountTotalEuro: discountTotalEuro,
          totalPieceEuro: totalPieceEuro,
          productItemCountEuro: productItemCountEuro,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/returnsSecond/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "150px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row className="productReturnList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              {this.state.detail === false && (
                <Row className="filterRow">
                  <Col className="pl-2 pr-2" md="1">
                    <CgnDatePicker
                      name="firstDate"
                      label="Baş. Tarihi"
                      selected={
                        this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.firstDate2.split("/")[2] +
                                "-" +
                                this.state.firstDate2.split("/")[1] +
                                "-" +
                                this.state.firstDate2.split("/")[0]
                            )
                          : this.state.firstDate2
                      }
                      onChange={(firstDate2) =>
                        this.handleFirstDateChange(firstDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="1">
                    <CgnDatePicker
                      name="lastDate"
                      label="Bit. Tarihi"
                      selected={
                        this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.lastDate2.split("/")[2] +
                                "-" +
                                this.state.lastDate2.split("/")[1] +
                                "-" +
                                this.state.lastDate2.split("/")[0]
                            )
                          : this.state.lastDate2
                      }
                      onChange={(lastDate2) =>
                        this.handleLastDateChange(lastDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="4">
                    <CgnReactSelect
                      name="supplierGuid"
                      label="Tedarikçi"
                      placeholder="Tedarikçi seçiniz..."
                      selectValue={this.state.supplierGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleSupplierSelectChange(option)
                      }
                      options={this.state.supplierArr}
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="5">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      options={this.state.customerArr}
                    />
                  </Col>
                  <Col className="pl-2 pr-0" md="1">
                    <CgnButton
                      type="button"
                      color="primary"
                      onClick={this.getList}
                      text="Listele"
                      className="listButton"
                    />
                  </Col>
                </Row>
              )}
              <CgnDatatable
                data={this.state.productReturnData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "İade Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.returnDate}</td>
                              <th style={this.thStyle2}>
                                {row.supplierName ? "Tedarikçi" : "Müşteri"}
                              </th>
                              <td>
                                {row.supplierName
                                  ? row.supplierName
                                  : row.customerName}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle2}>Toplam Tutar</th>
                              <td>
                                {row.total.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (₺):{" "}
                {this.state.discountTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>

              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.discountTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>

              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.discountTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ReturnSecondList;
