import React from "react";
import { Alert } from "reactstrap";

const CgnAlert = ({ color, title, text, className }) => {
  return (
    <>
      {color && title && text && (
        <Alert className={className} color={color}>
          <strong>{title + " : "}</strong>
          {text}
        </Alert>
      )}
    </>
  );
};

export default CgnAlert;
