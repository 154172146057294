import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  localFilePath,
  URL,
  
  GetMonthFirstDateYearElkapol,
  GetMonthLastDateYear,
  GetMonthFirstDate2YearElkapol,
  GetMonthLastDate2Year,
  CustomerList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class OfferSieveListApproved extends Component {
  state = {
    pageTitle: "Teklif Sevk Edilen Listele",
    // pageAddLink: "teklif3-ekle",
    // pageUpdateLink: "teklif3-guncelle",
    pageDetailLink: "teklif3-sevk-edilen-detay",
    pageListLink: "teklif3-sevk-edilen-listele",
    breadcrumb: [{ text: "Teklif", link: "#" }],
    isLoading: false,
    isDeleted: false,
    offerData: [],
    urlSplit: [],
    discountTotal: 0,
    totalPiece: 0,
    productItemCount: 0,
    discountTotalDolar: 0,
    totalPieceDolar: 0,
    productItemCountDolar: 0,
    discountTotalEuro: 0,
    totalPieceEuro: 0,
    productItemCountEuro: 0,
    statusAll: 0,
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
    status5: 0,
    status6: 0,
    guid: "",
    replaceLink: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });

    var firstDate = GetMonthFirstDateYearElkapol();
    var lastDate = GetMonthLastDateYear();
    var firstDate2 = GetMonthFirstDate2YearElkapol();
    var lastDate2 = GetMonthLastDate2Year();

    if (localStorage.getItem("firstDateOfferSieve")) {
      firstDate = localStorage.getItem("firstDateOfferSieve");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateOfferSieve")) {
      lastDate = localStorage.getItem("lastDateOfferSieve");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    // 

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidOfferSieve")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidOfferSieve"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDateYearElkapol();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateOfferSieve", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDateYear();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateOfferSieve", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidOfferSieve", this.state.customerGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    URLParam =
      "/offersieves/getlisttostatus/6" +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        if (this.state.detail === false) {
          this.setState({
            offerData: response.data.offerSieveDto,
            status1: response.data.status1,
            status2: response.data.status2,
            status3: response.data.status3,
            status4: response.data.status4,
            status5: response.data.status5,
            status6: response.data.status6,
            statusAll: response.data.statusAll,
          });
        } else {
          this.setState({
            offerData: response.data,
          });
        }
        var discountTotal = 0;
        var totalPiece = 0;
        var productItemCount = 0;
        var discountTotalDolar = 0;
        var totalPieceDolar = 0;
        var productItemCountDolar = 0;
        var discountTotalEuro = 0;
        var totalPieceEuro = 0;
        var productItemCountEuro = 0;
        this.state.offerData.forEach((element) => {
          if (element.currencyType === 1) {
            discountTotal += element.total;
            totalPiece += element.totalPiece;
            productItemCount += element.productItemCount;
          } else if (element.currencyType === 2) {
            discountTotalDolar += element.total;
            totalPieceDolar += element.totalPiece;
            productItemCountDolar += element.productItemCount;
          } else if (element.currencyType === 3) {
            discountTotalEuro += element.total;
            totalPieceEuro += element.totalPiece;
            productItemCountEuro += element.productItemCount;
          }
        });
        this.setState({
          discountTotal: discountTotal,
          totalPiece: totalPiece,
          productItemCount: productItemCount,
          discountTotalDolar: discountTotalDolar,
          totalPieceDolar: totalPieceDolar,
          productItemCountDolar: productItemCountDolar,
          discountTotalEuro: discountTotalEuro,
          totalPieceEuro: totalPieceEuro,
          productItemCountEuro: productItemCountEuro,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "100px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row className="offerList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              {this.state.detail === false && (
                <Row className="filterRow">
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="firstDate"
                      label="Baş. Tarihi"
                      selected={
                        this.state.firstDate2 &&
                          this.state.firstDate2.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.firstDate2.split("/")[2] +
                            "-" +
                            this.state.firstDate2.split("/")[1] +
                            "-" +
                            this.state.firstDate2.split("/")[0]
                          )
                          : this.state.firstDate2
                      }
                      onChange={(firstDate2) =>
                        this.handleFirstDateChange(firstDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="lastDate"
                      label="Bit. Tarihi"
                      selected={
                        this.state.lastDate2 &&
                          this.state.lastDate2.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.lastDate2.split("/")[2] +
                            "-" +
                            this.state.lastDate2.split("/")[1] +
                            "-" +
                            this.state.lastDate2.split("/")[0]
                          )
                          : this.state.lastDate2
                      }
                      onChange={(lastDate2) =>
                        this.handleLastDateChange(lastDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="7">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      options={this.state.customerArr}
                    />
                  </Col>
                  <Col className="pl-2 pr-0" md="1">
                    <CgnButton
                      type="button"
                      color="primary"
                      onClick={this.getList}
                      text="Listele"
                      className="listButton"
                    />
                  </Col>
                </Row>
              )}
              <CgnDatatable
                data={this.state.offerData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Teklif Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      if (row.customerPhone !== null) {
                        var phone = row.customerPhone,
                          maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
                            } ${phone.substring(4, 7)} ${phone.substring(
                              7,
                              9
                            )} ${phone.substring(9, 11)}`;
                      }
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.offerDate}</td>
                              <th style={this.thStyle2}>Teklif Numarası</th>
                              <td>{row.offerNumber}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td colSpan={4}>{row.customerName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri Telefon</th>
                              <td>
                                {row.customerPhone === "" ? "-" : maskPhone}
                              </td>
                              <th style={this.thStyle2}>Müşteri E-mail</th>
                              <td>
                                {row.customerEmail === ""
                                  ? "-"
                                  : row.customerEmail}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Toplam Tutar</th>
                              <td>
                                {row.total.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                      ? "$"
                                      : row.currencyType === 3
                                        ? "€"
                                        : "-")}
                              </td>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "garantiden",
                    width: "110px",
                    padding: "0px",
                    center: true,
                    sortable: false,
                    cell: (row) => {
                      if (row.garantiden) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Garantiden"
                            style={{ backgroundColor: "#29B237" }}
                          />
                        );
                      }
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    center: true,
                    sortable: false,
                    cell: (row) => {
                      if (row.status === 1) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Beklemede"
                            style={{ backgroundColor: "red" }}
                          />
                        );
                      } else if (row.status === 2) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Onaylandı"
                            style={{ backgroundColor: "#29B237" }}
                          />
                        );
                      } else if (row.status === 3) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="İptal"
                            style={{ backgroundColor: "#FFC433" }}
                          />
                        );
                      } else if (row.status === 4) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Revize"
                            style={{ backgroundColor: "#FF6E33" }}
                          />
                        );
                      } else if (row.status === 5) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Satış"
                            style={{ backgroundColor: "#0679AF" }}
                          />
                        );
                      } else if (row.status === 6) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Arşiv"
                            style={{ backgroundColor: "#7C4811" }}
                          />
                        );
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Teklif Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfPrint(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (₺):{" "}
                {this.state.discountTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.discountTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.discountTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default OfferSieveListApproved;
