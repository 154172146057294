import React, { Component } from "react";
import ContentTop from "../root/ContentTop";
import { URL } from "../../redux/actions/constants";
import axios from "axios";
import CgnAlert from "../toolbox/CgnAlert";
import { Row, Col, Card, Table } from "reactstrap";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import CgnLoader from "../toolbox/CgnLoader";
import CgnBadge from "../toolbox/CgnBadge";

class IncomingInvoiceDetail extends Component {
  state = {
    breadcrumb: [{ text: "Fatura", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    data: {},
    dataBillsProductsDto: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/bills/get/" + urlSplit[2], config)
      .then((response) => {
        this.setState({
          data: response.data,
          dataBillsProductsDto: response.data.billsProductsDto,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          data: {},
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title="Gelen Fatura Detay"
          listLink="/gelen-fatura-listele"
        />
        {this.state.alertErrorMessage && (
          <CgnAlert
            color="danger"
            title="Uyarı"
            text={this.state.alertErrorMessage}
          />
        )}
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.dateTime
                        ? this.state.data.dateTime
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fatura Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.billNumber
                        ? this.state.data.billNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>UUID</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.uuid ? this.state.data.uuid : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fatura Türü</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.billType ? (
                        this.state.data.billType === "TEMELFATURA" ? (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="primary "
                            text="Temel Fatura"
                          />
                        ) : this.state.data.billType === "TICARIFATURA" ? (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="info"
                            text="Ticari Fatura"
                          />
                        ) : (
                          this.state.data.billType
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Firma Adı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyName
                        ? this.state.data.companyName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Adres</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyAddress
                        ? this.state.data.companyAddress
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>İlçe</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyDistrict
                        ? this.state.data.companyDistrict
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Şehir</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyCity
                        ? this.state.data.companyCity
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Ülke</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyCountry
                        ? this.state.data.companyCountry
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Posta Kodu</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyPostCode
                        ? this.state.data.companyPostCode
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Vergi Dairesi</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyTaxAdministration
                        ? this.state.data.companyTaxAdministration
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Vergi Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyTaxNumber
                        ? this.state.data.companyTaxNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Ticari Sicil Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyCommercialRegistrationNumber
                        ? this.state.data.companyCommercialRegistrationNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Mersis Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyMersisNumber
                        ? this.state.data.companyMersisNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Tutar</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.price
                        ? this.state.data.price.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>İskonto Tutarı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.discountPrice
                        ? this.state.data.discountPrice.toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                            }
                          ) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Toplam Tutar</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.totalPrice
                        ? this.state.data.totalPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>K.D.V. Oranı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.taxRate
                        ? "%" + this.state.data.taxRate
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>K.D.V. Tutarı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.taxPrice
                        ? this.state.data.taxPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Genel Tutar</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.generalPrice
                        ? this.state.data.generalPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.billType === "TICARIFATURA" &&
                        this.state.data.replyCogen === 0 && (
                          <CgnBadge color="info" text="Cevap Bekleniyor" />
                        )}
                      {this.state.data.billType === "TICARIFATURA" &&
                        this.state.data.replyCogen === 1 &&
                        this.state.data.reply === 0 && (
                          <CgnBadge color="primary" text="Kabul Ediliyor" />
                        )}
                      {this.state.data.billType === "TICARIFATURA" &&
                        this.state.data.replyCogen === 1 &&
                        this.state.data.reply === 1 && (
                          <CgnBadge color="success" text="Kabul Edildi" />
                        )}
                      {this.state.data.billType === "TICARIFATURA" &&
                        this.state.data.replyCogen === 2 &&
                        this.state.data.reply === 0 && (
                          <CgnBadge color="warning" text="Reddediliyor" />
                        )}
                      {this.state.data.billType === "TICARIFATURA" &&
                        this.state.data.replyCogen === 2 &&
                        this.state.data.reply === 2 && (
                          <CgnBadge color="danger" text="Reddedildi" />
                        )}
                      {this.state.data.billType === "TEMELFATURA" && (
                        <CgnBadge color="success " text="Kabul Edildi" />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.createdBy
                        ? this.state.data.createdBy
                        : "-"}
                    </td>
                    <th>Kaydedilme Tarihi</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.createdAt
                        ? this.state.data.createdAt
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.updatedBy
                        ? this.state.data.updatedBy
                        : "-"}
                    </td>
                    <th>Son Güncellenme Tarihi</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.updatedAt
                        ? this.state.data.updatedAt
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {this.state.dataBillsProductsDto !== null &&
                this.state.dataBillsProductsDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th className="w-auto">Ürün Adı</th>
                      <th className="w-125">Adet</th>
                      <th className="w-125">Birim Fiyatı</th>
                      <th className="w-200">İskonto / Aktarım Oranı</th>
                      <th className="w-200">İskonto / Aktarım Fiyatı</th>
                      <th className="w-125">K.D.V. Oranı</th>
                      <th className="w-125">K.D.V. Fiyatı</th>
                      {/* <th className="w-100">Diğer Vergiler</th> */}
                      <th className="w-125">Fiyat</th>
                    </tr>
                    {this.state.dataBillsProductsDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.productName}</td>
                          <td>{item.piece + " " + item.unit}</td>
                          <td>
                            {item.unitPrice !== 0
                              ? item.unitPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : "-"}
                          </td>
                          <td>
                            {item.discountPercent !== 0
                              ? "%" + item.discountPercent
                              : "-"}
                          </td>
                          <td>
                            {item.discountPrice !== 0
                              ? item.discountPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : "-"}
                          </td>
                          <td>
                            {item.taxRate !== 0 ? "%" + item.taxRate : "-"}
                          </td>
                          <td>
                            {item.taxPrice !== 0
                              ? item.taxPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : ""}
                          </td>
                          {/* <td>
                            {item.otherPrice !== 0
                              ? item.otherPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : "-"}
                          </td> */}
                          <td>
                            {item.price.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              item.currency}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
            </Card>
            {this.state.data.pdfFileName !== null &&
              this.state.pdfFileName !== "" && (
                <CgnButtonLink
                  color="primary"
                  to={"/EFatura/" + this.state.data.pdfFileName}
                  target={"_blank"}
                  text="Görüntüle"
                />
              )}
            <CgnButtonLink
              color="secondary"
              to={"/gelen-fatura-listele"}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default IncomingInvoiceDetail;
