import React, { Component } from "react";
import { Row, Col, Card, Table, Button } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  iTypeStatusesOptions,
  localFilePath,
  URL,
  
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  iTypeStatuses,
  ProductList,
  UserList,
  CustomerList,
  ServiceTypeList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class ServiceList extends Component {
  state = {
    pageTitle: "Servis Listele",
    pageAddLink: "servis-ekle",
    pageUpdateLink: "servis-guncelle",
    pageDetailLink: "servis-detay",
    pageListLink: "servis-listele",
    breadcrumb: [{ text: "Servis", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    serviceData: [],
    guid: "",
    serialNo: "",
    discountTotal: 0,
    discountTotalDolar: 0,
    discountTotalEuro: 0,
    statusAll: 0,
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
    status5: 0,
    status6: 0,
    status7: 0,
    status8: 0,
    status9: 0,
    status10: 0,
    alertErrorMessage: "",
    detail: false,
    urlSplitX: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    userGuid: "00000000-0000-0000-0000-000000000000",
    userArr: [],
    productGuid: "00000000-0000-0000-0000-000000000000",
    productArr: [],
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    iType: "00000000-0000-0000-0000-000000000000",
    serviceTypeArr: [],
  };

  async componentDidMount() {
    await this.setState({
      isLoading: true,
    });

    // 

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    var urlSplitX = urlSplit.length > 2 ? urlSplit[2] : 0;
    this.setState({
      urlSplitX: urlSplitX,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateService")) {
      firstDate = localStorage.getItem("firstDateService");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateService")) {
      lastDate = localStorage.getItem("lastDateService");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    var self = this;

    if (localStorage.getItem("iTypeService")) {
      this.setState({
        iType: parseInt(localStorage.getItem("iTypeService")),
      });
    }

    if (localStorage.getItem("categoryGuidService")) {
      this.setState({
        categoryGuid: localStorage.getItem("categoryGuidService"),
      });
    }

    await ProductList().then(function (data) {
      self.setState({
        productArr: data,
      });
    });

    if (localStorage.getItem("productGuidService")) {
      this.setState({
        productGuid: localStorage.getItem("productGuidService"),
      });
    }

    await UserList().then(function (data) {
      self.setState({
        userArr: data,
      });
    });

    if (localStorage.getItem("userGuidService")) {
      this.setState({
        userGuid: localStorage.getItem("userGuidService"),
      });
    }

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidService")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidService"),
      });
    }

    await ServiceTypeList().then(function (data) {
      self.setState({
        serviceTypeArr: data,
      });
    });

    if (localStorage.getItem("iTypeService")) {
      this.setState({
        iType: localStorage.getItem("iTypeService"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateService", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateService", this.state.lastDate);
  }

  async handleITypeSelectChange(option) {
    if (option) {
      await this.setState({
        iType: option.value,
      });
      localStorage.setItem("iTypeService", this.state.iType);
    } else {
      await this.setState({
        iType: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  async handleUserSelectChange(option) {
    if (option) {
      await this.setState({
        userGuid: option.value,
      });
    } else {
      await this.setState({
        userGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("userGuidService", this.state.userGuid);
  }

  async handleProductSelectChange(option) {
    if (option) {
      await this.setState({
        productGuid: option.value,
      });
    } else {
      await this.setState({
        productGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("productGuidService", this.state.productGuid);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidService", this.state.customerGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .get(
        URL +
          "/services/getlisttostatus/" +
          this.state.urlSplitX +
          "/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate +
          "/" +
          this.state.iType +
          "/" +
          this.state.productGuid +
          "/" +
          this.state.userGuid +
          "/" +
          this.state.customerGuid,
        config
      )
      .then((response) => {
        var discountTotal = 0;
        var discountTotalDolar = 0;
        var discountTotalEuro = 0;
        response.data.serviceDto.forEach((element) => {
          if (element.currencyType === 1) {
            discountTotal += element.discountTotal;
          } else if (element.currencyType === 2) {
            discountTotalDolar += element.discountTotal;
          } else if (element.currencyType === 3) {
            discountTotalEuro += element.discountTotal;
          }
        });
        this.setState({
          discountTotal: discountTotal,
          discountTotalDolar: discountTotalDolar,
          discountTotalEuro: discountTotalEuro,
          serviceData: response.data.serviceDto,
          status1: response.data.status1,
          status2: response.data.status2,
          status3: response.data.status3,
          status4: response.data.status4,
          status5: response.data.status5,
          status6: response.data.status6,
          status7: response.data.status7,
          status8: response.data.status8,
          status9: response.data.status9,
          status10: response.data.status10,
          statusAll: response.data.statusAll,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleServicePrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/services/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/services/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "160px",
  };

  thStyle2 = {
    width: "95px",
  };

  tdStyle = {
    width: "250px",
  };
  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row className="serviceList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <div className="d-inline-block mb-3">
                <a
                  href={`/${this.state.pageListLink}/${1}`}
                  style={{ backgroundColor: "red" }}
                  className={
                    this.state.urlSplitX === "1"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Beklemede: ${this.state.status1}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${2}`}
                  style={{ backgroundColor: "#29B237" }}
                  className={
                    this.state.urlSplitX === "2"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Onaylandı: ${this.state.status2}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${3}`}
                  style={{ backgroundColor: "#FF6E33" }}
                  className={
                    this.state.urlSplitX === "3"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Parça Bekleniyor: ${this.state.status3}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${4}`}
                  style={{ backgroundColor: "#FFC433" }}
                  className={
                    this.state.urlSplitX === "4"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Haber Verilecek: ${this.state.status4}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${5}`}
                  style={{ backgroundColor: "green" }}
                  className={
                    this.state.urlSplitX === "5"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Fiyat Verilecek: ${this.state.status5}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${6}`}
                  style={{ backgroundColor: "#0679AF" }}
                  className={
                    this.state.urlSplitX === "6"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Atölye: ${this.state.status6}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${7}`}
                  style={{ backgroundColor: "#5AD500" }}
                  className={
                    this.state.urlSplitX === "7"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Yeni Servis: ${this.state.status7}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${8}`}
                  style={{ backgroundColor: "#00B5D5" }}
                  className={
                    this.state.urlSplitX === "8"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Tamamlandı: ${this.state.status8}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${9}`}
                  style={{ backgroundColor: "#7C4811" }}
                  className={
                    this.state.urlSplitX === "9"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`Arşiv: ${this.state.status9}`}</a>
                <a
                  href={`/${this.state.pageListLink}/${10}`}
                  style={{ backgroundColor: "#D12B0E" }}
                  className={
                    this.state.urlSplitX === "10"
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2"
                  }
                >{`İptal Edildi: ${this.state.status10}`}</a>
                <a
                  href={`/${this.state.pageListLink}`}
                  className={
                    this.state.urlSplitX === 0
                      ? "float-left statusTopButton mr-2 btn btn-primary"
                      : "float-left statusTopButton mr-2 bg-secondary"
                  }
                >{`Tümü: ${this.state.statusAll}`}</a>
              </div>
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="1">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="1">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="iType"
                    label="Servis Türü"
                    placeholder="Servis türü seçiniz..."
                    isMulti={false}
                    isClearable={true}
                    selectValue={this.state.iType}
                    options={this.state.serviceTypeArr}
                    onChange={(iType) => this.handleITypeSelectChange(iType)}
                  />
                </Col>
                <Col className="pl-2 pr-2">
                  <CgnReactSelect
                    name="productGuid"
                    label="Ürün"
                    placeholder="Ürün seçiniz..."
                    selectValue={this.state.productGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleProductSelectChange(option)
                    }
                    options={this.state.productArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="userGuid"
                    label="Personel"
                    placeholder="Personel seçiniz..."
                    selectValue={this.state.userGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) => this.handleUserSelectChange(option)}
                    options={this.state.userArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>

              <CgnDatatable
                data={this.state.serviceData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Servis Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Servis Türü</th>
                              {/* <th className="colon">:</th> */}
                              <td style={this.tdStyle}>
                                {row.serviceTypeName}
                              </td>
                              <th style={this.thStyle2}>Müşteri</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={4}>{row.customerName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Son Bakım Tarihi</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.maintenanceDate === "01/01/1900"
                                  ? "-"
                                  : row.maintenanceDate}
                              </td>
                              <th style={this.thStyle2}>Kategori</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.categoryName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>
                                Bir Sonraki Bakım Tarihi
                              </th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.nextMaintenanceDate === "01/01/1900"
                                  ? "-"
                                  : row.nextMaintenanceDate}
                              </td>
                              <th style={this.thStyle2}>Ürün</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.productName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Personel</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.employeeName}</td>
                              <th style={this.thStyle2}>Seri No</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.serialNo !== "" ? row.serialNo : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td>{row.createdBy}</td>
                              <th style={this.thStyle2}>Toplam Tutar</th>
                              {/* <th className="colon">:</th> */}
                              <td>
                                {row.discountTotal.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Firma Açıklama</th>
                              <td colSpan={3}>{row.notDescription ? row.notDescription : "-"}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    selector: "status",
                    width: "175px",
                    padding: "0px",
                    center: true,
                    sortable: false,
                    cell: (row) => {
                      if (row.status === 1) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Beklemede"
                            style={{ backgroundColor: "red" }}
                          />
                        );
                      } else if (row.status === 2) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Onaylandı"
                            style={{ backgroundColor: "#29B237" }}
                          />
                        );
                      } else if (row.status === 3) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Parça Bekleniyor"
                            style={{ backgroundColor: "#FF6E33" }}
                          />
                        );
                      } else if (row.status === 4) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Haber Verilecek"
                            style={{ backgroundColor: "#FFC433" }}
                          />
                        );
                      } else if (row.status === 5) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Fiyat Verilecek"
                            style={{ backgroundColor: "green" }}
                          />
                        );
                      } else if (row.status === 6) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Atölye"
                            style={{ backgroundColor: "#0679AF" }}
                          />
                        );
                      } else if (row.status === 7) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Yeni Servis"
                            style={{ backgroundColor: "#5AD500" }}
                          />
                        );
                      } else if (row.status === 8) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Tamamlandı"
                            style={{ backgroundColor: "#00B5D5" }}
                          />
                        );
                      } else if (row.status === 9) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="Arşiv"
                            style={{ backgroundColor: "#7C4811" }}
                          />
                        );
                      } else if (row.status === 10) {
                        return (
                          <CgnBadge
                            color="secondary"
                            text="İptal Edildi"
                            style={{ backgroundColor: "#D12B0E" }}
                          />
                        );
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Servis Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handleServicePrint(row.guid),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (₺):{" "}
                {this.state.discountTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.discountTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.discountTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ServiceList;
