import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnDatatable2 from "../toolbox/CgnDatatable2";

class TpuCostList extends Component {
  state = {
    pageTitle: "TPU Maliyetleri Listele",
    pageAddLink: "tpu-maliyet-ekle",
    pageUpdateLink: "tpu-maliyet-guncelle",
    pageDetailLink: "tpu-maliyet-detay",
    pageListLink: "tpu-maliyet-listele",
    breadcrumb: [{ text: "TPU Maliyetleri", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    cutData: [],
    name: "",
    guid: "",
    totalPrice: 0,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    sieveProductGuid: "00000000-0000-0000-0000-000000000000",
    sieveProductArr: [],
    castingCostData: [],
    alertErrorMessage: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2;
    await axios
      .get(URL + "/sieveProducts/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          sieveProductArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      //var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem("firstDateTpuCost", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      //var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
    localStorage.setItem("lastDateTpuCost", this.state.lastDate);
  }

  async handleSieveProductSelectChange(option) {
    if (option) {
      await this.setState({
        sieveProductGuid: option.value,
      });
    } else {
      await this.setState({
        sieveProductGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem(
      "sieveProductGuidTpuCost",
      this.state.sieveProductGuid
    );
  }

  handleProcessStatusSelectChange(option) {
    if (option) {
      this.setState({
        processStatus1: option.value,
        processStatusError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        processStatus1: 0,
      });
    }
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    let urlSplit = window.location.pathname.split("/");
    var urlSplitX = urlSplit.length > 2 ? urlSplit[2] : 0;
    this.setState({
      urlSplitX: urlSplitX,
    });

    URLParam =
      "/tpuCosts/getliststatus/" +
      (this.state.firstDate !== "" ? this.state.firstDate : "bos") +
      "/" +
      (this.state.lastDate !== "" ? this.state.lastDate : "bos") +
      "/" +
      this.state.sieveProductGuid;

    axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          castingCostData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/tpuCosts/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "175px",
  };

  thStyle2 = {
    width: "130px",
  };

  tdStyle = {
    width: "350px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="cutList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="7">
                  <CgnReactSelect
                    name="sieveProductGuid"
                    label="Malzeme"
                    placeholder="Malzeme seçiniz..."
                    selectValue={this.state.sieveProductGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleSieveProductSelectChange(option)
                    }
                    options={this.state.sieveProductArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable2
                data={this.state.castingCostData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "TPU Maliyet Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Teklif</th>
                              <td colSpan={4}>{row.offerSieveName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Malzeme</th>
                              <td style={this.tdStyle}>
                                {row.sieveProductName}
                              </td>
                              <th style={this.thStyle2}>Elek Boyutu</th>
                              <td>
                                {row.sieveSize !== "" ? row.sieveSize : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>
                                Malzeme Maliyeti (€/ad)
                              </th>
                              <td>{row.castingCostTotal}</td>
                              <th style={this.thStyle2}>Toplam (€/m2)</th>
                              <td>{row.m2Total}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle2}>Ekleyen Kullanıcı</th>
                              <td colSpan={4}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default TpuCostList;
