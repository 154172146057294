import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Modal, ModalHeader } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { URL, CategoryList, fotoStyle } from "../../redux/actions/constants";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import noImg from "../../images/no-img.png";

class MachineStockRemove extends Component {
  state = {
    pageTitle: "Depolardan Çıkarılan Ürünler",
    pageDetailLink: "depolardan-cikarilan-urunler-detay",
    pageListLink: "depolardan-cikarilan-urunler",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    productData: [],
    alertErrorMessage: "",
    image: "",
    name: "",
    isOpen: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var self = this;

    await CategoryList().then(function (data) {
      self.setState({
        categoryArr: data,
      });
    });

    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/machinestockremove/getlist/", config)
      .then((response) => {
        this.setState({
          productData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  thStyle = {
    width: "80px",
  };

  thStyle2 = {
    width: "60px",
  };

  tdStyle = {
    width: "400px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.productData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    selector: "image",
                    sortable: false,
                    width: "80px",
                    cell: (cell) => (
                      <div>
                        {cell.machineProductImage ? (
                          <Button
                            onClick={() =>
                              this.toggle(
                                cell.machineProductImage,
                                cell.machineProductName
                              )
                            }
                            className="modalButtonList"
                          >
                            <img
                              alt={cell.machineProductName}
                              src={`/Images/${cell.machineProductImage}`}
                              className="rdt_image rounded"
                            />
                          </Button>
                        ) : (
                          <img
                            alt={cell.name}
                            src={noImg}
                            className="rdt_image rdt_no_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Ürün Adet Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.dateTime}</td>
                              <th style={this.thStyle2}>Depo</th>
                              <td>{row.locationName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ürün Adı</th>
                              <td style={this.tdStyle}>
                                {row.machineProductName}
                              </td>
                              <th style={this.thStyle2}>Adet</th>
                              <td>{row.piece}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    width: "125px",
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonLink
                        text={"Detay"}
                        type="button"
                        className="w-100 ml-0"
                        color="primary"
                        to={"/" + this.state.pageDetailLink + "/" + row.guid}
                      />
                    ),
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default MachineStockRemove;
