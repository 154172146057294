import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxType,
  TaxRateChoices,
  PaymentMethodType,
  CurrencyType,
  
  UserOperationClaimControl,
  resizeFile,
  fotoStyle,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import moment from "moment";
import CostTypeAddUpdate from "../costType/CostTypeAddUpdate";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";
import EmployeeAddUpdate from "../employee/EmployeeAddUpdate";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";

class CostAddUpdate extends Component {
  state = {
    pageTitle: "Gider Ekle",
    pageTitleAdd: "Gider Ekle",
    pageTitleUpdate: "Gider Güncelle",
    pageAddLink: "gider-ekle",
    pageListLink: "gider-listele",
    breadcrumb: [{ text: "Gider", link: "#" }],
    isLoading: false,
    urlSplit: [],
    costDate: "",
    costTypeGuid: "",
    supplierGuid: null,
    employeeGuid: null,
    total: null,
    generalTotal: parseFloat(0).toFixed(2),
    taxRateValue: "",
    taxTypeValue: null,
    description: "",
    discountPercent: "",
    discountValue: "",
    discountTotal: parseFloat(0).toFixed(2),
    currencyType: "",
    currencyTypeError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    status: true,
    costDateError: "",
    costTypeError: "",
    supplierNameError: "",
    employeeNameError: "",
    totalError: "",
    descriptionError: "",
    taxRateError: "",
    taxTypeError: "",
    targetPaymentDateError: "",
    targetTotalError: "",
    targetPaymentMethodTypeError: "",
    guid: "",
    alertErrorMessage: "",
    receiptNo: "",
    expiry: null,
    paymentMethodValue: null,
    bankGuid: null,
    bankArr: [],
    chequesBondNo: "",
    image: "",
    imageName: "",
    isOpenModalBank: false,
    isBankTrue: false,
    isChequesBondNoTrue: false,
    isHidden: true,
    isDeleted: false,
    isTrue: false,
    isNull: false,
    receiptNoError: "",
    expiryError: "",
    paymentMethodTypeError: "",
    bankError: "",
    chequesBondNoError: "",
    isOpenModalCostType: false,
    isOpenModalSupplier: false,
    isOpenModalEmployee: false,
    targetPaymentDate: "",
    targetTotal: null,
    targetPaymentMethodType: null,
    paymentCalendarData: [],
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    costTypeModalPlus: false,
    supplierModalPlus: false,
    membershipModalPlus: false,
    paymentCalendarModalPlus: false,
    paymentModalPlus: false
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      costTypeModalPlus: await UserOperationClaimControl(1010),
      supplierModalPlus: await UserOperationClaimControl(1038),
      membershipModalPlus: await UserOperationClaimControl(1020),
      paymentCalendarModalPlus: await UserOperationClaimControl(1025),
      paymentModalPlus: await UserOperationClaimControl(1026),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          employeeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/costTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          costTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/costs/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              costDate: response.data.costDate,
              costTypeGuid: response.data.costTypeGuid,
              taxRateValue: response.data.taxRate.toString(),
              taxTypeValue: response.data.taxType,
              supplierGuid: response.data.supplierGuid,
              employeeGuid: response.data.employeeGuid,
              description: response.data.description,
              total: response.data.total,
              generalTotal: response.data.generalTotal,
              paymentCalendarData: response.data.paymentCalendarDto,
              currencyType: response.data.currencyType,
              discountPercent: response.data.discountPercent,
              discountTotal: response.data.discountTotal,
              discountValue: response.data.discountValue,
              receiptNo: response.data.receiptNo,
              expiry: response.data.expiry,
              paymentMethodValue: response.data.paymentMethodType,
              bankGuid: response.data.bankGuid,
              chequesBondNo: response.data.chequesBondNo,
              image: response.data.image,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        costDate: "",
        costTypeGuid: "",
        supplierGuid: null,
        employeeGuid: null,
        total: "",
        generalTotal: "",
        paymentCalendarData: [],
        description: "",
        taxRateValue: "",
        taxTypeValue: null,
        discountPercent: "",
        discountValue: "",
        discountTotal: "",
        currencyType: "",
        isDiscountPercent: false,
        isDiscountValue: false,
        status: true,
        isHidden: true,
      });
    }
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    if (
      this.state.isOpenModalCostType &&
      this.props.location.modalAddedCostType !== undefined &&
      this.props.location.modalAddedCostType.isModal !== undefined &&
      this.props.location.modalAddedCostType.isModal === false
    ) {
      axios
        .get(URL + "/costTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            costTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCostType.data &&
              element.name === this.props.location.modalAddedCostType.data
            ) {
              this.setState({
                costTypeGuid: element.guid,
                costTypeError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCostType.isModal = true;
      this.setState({
        isOpenModalCostType: false,
      });
    }

    if (
      this.state.isOpenModalSupplier &&
      this.props.location.modalAddedSupplier !== undefined &&
      this.props.location.modalAddedSupplier.isModal !== undefined &&
      this.props.location.modalAddedSupplier.isModal === false
    ) {
      axios
        .get(URL + "/suppliers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            supplierArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSupplier.data &&
              element.name === this.props.location.modalAddedSupplier.data
            ) {
              this.setState({
                supplierGuid: element.guid,
                employeeGuid: null,
                supplierError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSupplier.isModal = true;
      this.setState({
        isOpenModalSupplier: false,
      });
    }

    if (
      this.state.isOpenModalEmployee &&
      this.props.location.modalAddedEmployee !== undefined &&
      this.props.location.modalAddedEmployee.isModal !== undefined &&
      this.props.location.modalAddedEmployee.isModal === false
    ) {
      axios
        .get(URL + "/members/getmembers/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.firstName} ${item.lastName}`,
            };
          });
          this.setState({
            employeeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedEmployee.data &&
              `${element.firstName} ${element.lastName}` ===
                this.props.location.modalAddedEmployee.data
            ) {
              this.setState({
                employeeGuid: element.guid,
                supplierGuid: null,
                employeeNameError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedEmployee.isModal = true;
      this.setState({
        isOpenModalEmployee: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handlePaymentMethodSelectChange(option) {
    if (option) {
      this.setState({
        paymentMethodValue: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else if (!this.state.isHidden) {
      this.setState({
        paymentMethodValue: null,
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else if (this.state.isHidden) {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      costDateError: "",
      costTypeError: "",
      supplierNameError: "",
      employeeNameError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "costDate" && value === "") {
      this.setState({
        costDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: this.include,
      });
    }
    if (name === "costTypeGuid" && value === "") {
      this.setState({
        costTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "total" && (value === null || value === "")) {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "total" && value && value < 0) {
      this.setState({
        totalError: CgnMessage.totalErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if(name === "discountPercent" || name === "discountValue"){
    //   await this.DiscountCalculate();
    // }
    // if(this.state.discountPercent){
    //  await this.setState({
    //     discountValue : "",
    //     isDiscountValue: true,
    //   });
    // }
    //   if(this.state.discountValue ){
    //   await  this.setState({
    //       discountPercent : "",
    //       isDiscountPercent: true,
    //     });
    // }
    // if(this.state.discountPercent ===""){
    //   await  this.setState({
    //     isDiscountValue: false,
    //   });
    // }
    // if(this.state.discountValue ===""){
    //   await  this.setState({
    //     isDiscountPercent: false,
    //   });
    // }
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
          discountTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
          discountTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
          discountTotal: this.state.total,
        });
      }
    }
  };

  DiscountCalculate = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    if (this.state.discountPercent !== null && this.state.discountPercent > 0) {
      await this.setState({
        discountTotal:
          this.state.generalTotal * (1 - this.state.discountPercent / 100),
      });
    } else if (
      this.state.discountValue !== null &&
      this.state.discountValue > 0
    ) {
      await this.setState({
        discountTotal: this.state.generalTotal - this.state.discountValue,
      });
    } else {
      await this.setState({
        discountTotal: this.state.generalTotal,
      });
    }

    // var totalAmount =0;
    // if (this.state.generalTotal && this.state.discountPercent) {
    //      totalAmount = this.state.generalTotal * (1 - this.state.discountPercent / 100);
    //   if (this.state.discountTotal !== totalAmount) {
    //           this.state.discountTotal= totalAmount;
    //     }
    //   }
    //   if(this.state.generalTotal && this.state.discountPercent === ""){
    //       this.state.discountTotal= "";
    //   }
    //   if (this.state.generalTotal && this.state.discountValue) {
    //     totalAmount = this.state.generalTotal - this.state.discountValue;
    //   if (this.state.discountTotal !== totalAmount) {
    //       this.state.discountTotal= totalAmount;
    //   }
    // }
  };

  async handleTaxTypeSelectChange(option) {
    if (option) {
      await this.setState({
        taxTypeValue: option.value,
        taxTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 3) {
        this.setState({
          taxRateValue: "0",
          isTrue: true,
        });
      } else {
        this.setState({
          taxRateValue: "",
          isTrue: false,
        });
      }
    } else {
      await this.setState({
        taxTypeValue: null,
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  async handleTaxRateSelectChange(option) {
    if (option) {
      await this.setState({
        taxRateValue: option.value,
        taxRateError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        taxRateValue: "",
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleCostTypeSelectChange(option) {
    if (option) {
      this.setState({
        costTypeGuid: option.value,
        costTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        costTypeGuid: "",
        costTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSupplierSelectChange(option) {
    if (option) {
      this.setState({
        supplierGuid: option.value,
        supplierNameError: "",
        employeeNameError: "",
        employeeGuid: null,
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        supplierGuid: null,
      });
    }
  }

  handleEmployeeSelectChange(option) {
    if (option) {
      this.setState({
        employeeGuid: option.value,
        employeeNameError: "",
        supplierNameError: "",
        supplierGuid: null,
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        employeeGuid: null,
      });
    }
  }

  handleDateChange(costDate) {
    if (costDate) {
      this.setState({
        costDate: costDate,
        costDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        costDate: "",
        costDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.PriceCalculate(null);
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalCostType = () => {
    this.setState({
      isOpenModalCostType: !this.state.isOpenModalCostType,
    });
  };

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  addModalEmployee = () => {
    this.setState({
      isOpenModalEmployee: !this.state.isOpenModalEmployee,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var array = this.state.paymentCalendarData;
    array.forEach((item) => {
      item.targetPaymentDate = "";
      item.targetTotal = "";
      item.targetPaymentMethodType = null;
      item.targetPaymentDateError = CgnMessage.textErrorMessage;
      item.targetTotalError = CgnMessage.textErrorMessage;
      item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
      item.targetCurrencyType = CgnMessage.textErrorMessage;
      item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
    });
    this.setState({
      costDate: "",
      costTypeGuid: "",
      supplierGuid: null,
      employeeGuid: null,
      total: "",
      description: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      status: true,
      isHidden: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      costDateError: CgnMessage.textErrorMessage,
      costTypeError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
    });
  }

  handlePaymentCalendarAdd = async () => {
    let array = this.state.paymentCalendarData;
    await array.push({
      targetPaymentDate: "",
      targetTotal: null,
      targetPaymentMethodType: null,
      targetCurrencyType: null,
      targetPaymentDateError: "",
      targetTotalError: "",
      targetPaymentMethodTypeError: "",
      targetCurrencyTypeError: "",
    });
    array.forEach((item) => {
      if (
        array.length > 0 &&
        (!item.targetPaymentDate ||
          !item.targetTotal ||
          !item.targetPaymentMethodType ||
          !item.targetCurrencyType)
      ) {
        item.targetPaymentDateError = CgnMessage.textErrorMessage;
        item.targetTotalError = CgnMessage.textErrorMessage;
        item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
        item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        this.setState({
          isNull: true,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
    this.setState({ paymentCalendarData: array });
  };

  handleTextBoxChange(e, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    paymentCalendarData[idx].targetTotal = parseFloat(e.target.value);
    this.setState({
      paymentCalendarData: paymentCalendarData,
    });
    this.paymentCalendarRequiredField();
  }

  handleInputValueChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetPaymentMethodType = option.value;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetPaymentMethodType = null;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  handleTargetPaymentDateChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetPaymentDate = option;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetPaymentDate = "";
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  handleTargetCurrencyTypeChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetCurrencyType = option.value;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetCurrencyType = null;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  paymentCalendarRequiredField = () => {
    var isAlert = false;
    var arr = this.state.paymentCalendarData;
    arr.forEach((item) => {
      item.targetPaymentDateError = "";
      item.targetTotalError = "";
      item.targetPaymentMethodTypeError = "";
      item.targetCurrencyTypeError = "";

      if (!item.targetPaymentDate) {
        item.targetPaymentDateError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetTotal) {
        item.targetTotalError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetPaymentMethodType) {
        item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCurrencyType) {
        item.targetCurrencyType = this.state.currencyType;
        // item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        // isAlert= true;
        // this.setState({
        //   isNull: true
        // })
      }
    });
    if (isAlert) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else {
      this.setState({
        isNull: false,
      });
    }
  };

  handlePaymentCalendarRemove(idx) {
    let someArray = this.state.paymentCalendarData;
    someArray.splice(idx, 1);
    this.setState({ paymentCalendarData: someArray,
      isNull: false });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        isNull: false,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      costDateError: "",
      costTypeError: "",
      supplierNameError: "",
      employeeNameError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (this.state.costDate === "") {
      this.setState({
        costDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.costTypeGuid === "") {
      this.setState({
        costTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.total === null || this.state.total === "") {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      (this.state.total !== null || this.state.total === "") &&
      this.state.total < 0
    ) {
      this.setState({
        totalError: CgnMessage.totalErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxTypeValue === null) {
      this.setState({
        taxTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxRateValue === "") {
      this.setState({
        taxRateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } 
    if (!this.state.isHidden && this.state.paymentMethodValue === null) {
      this.setState({
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.bankGuid === null &&
      (this.state.paymentMethodValue === 2 ||
        this.state.paymentMethodValue === 3)
    ) {
      this.setState({
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.chequesBondNo === "" &&
      (this.state.paymentMethodValue === 1 ||
        this.state.paymentMethodValue === 5)
    ) {
      this.setState({
        chequesBondNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.costDate !== "" &&
      this.state.costTypeGuid !== "" &&
      this.state.total &&
      this.state.taxTypeValue &&
      this.state.taxRateValue &&
      this.state.currencyType &&
      this.state.isNull === false &&
      (!this.state.isHidden
        ? this.state.paymentMethodValue !== "" &&
          ((this.state.bankGuid !== null &&
            (this.state.paymentMethodValue === 2 ||
              this.state.paymentMethodValue === 3)) ||
            (this.state.chequesBondNo !== "" &&
              (this.state.paymentMethodValue === 1 ||
                this.state.paymentMethodValue === 5)) ||
            (this.state.chequesBondNo === "" &&
              this.state.bankGuid === null &&
              this.state.paymentMethodValue === 4))
        : true)
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        costDate: moment(this.state.costDate).format("DD/MM/YYYY"),
        costTypeGuid: this.state.costTypeGuid,
        supplierGuid: this.state.supplierGuid,
        employeeGuid: this.state.employeeGuid,
        taxType: this.state.taxTypeValue,
        taxRate: parseInt(this.state.taxRateValue),
        total: parseFloat(this.state.total),
        generalTotal: parseFloat(this.state.generalTotal),
        description: this.state.description,
        currencyType: this.state.currencyType,
        discountPercent:
          this.state.discountPercent !== ""
            ? parseInt(this.state.discountPercent)
            : 0,
        discountValue:
          this.state.discountValue !== ""
            ? parseFloat(this.state.discountValue)
            : 0,
        discountTotal: parseFloat(this.state.discountTotal),
        status: true, //this.state.status,
        paymentCalendarDto: this.state.paymentCalendarData,
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        paymentMethodType: this.state.paymentMethodValue
          ? parseInt(this.state.paymentMethodValue)
          : 0,
        bankGuid: this.state.bankGuid,
        chequesBondNo: this.state.chequesBondNo,
        image: this.state.image,
        isHidden: this.state.isHidden,
      };
      var URLParam = "/costs/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/costs/update";
        myObj = {
          guid: this.state.guid,
          costDate:
            this.state.costDate &&
            this.state.costDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.costDate.split("/")[2] +
                    "-" +
                    this.state.costDate.split("/")[1] +
                    "-" +
                    this.state.costDate.split("/")[0]
                )
              : moment(this.state.costDate).format("DD/MM/YYYY"),
          costTypeGuid: this.state.costTypeGuid,
          supplierGuid: this.state.supplierGuid,
          employeeGuid: this.state.employeeGuid,
          taxType: this.state.taxTypeValue,
          taxRate: parseInt(this.state.taxRateValue),
          total: parseFloat(this.state.total),
          generalTotal: parseFloat(this.state.generalTotal),
          description: this.state.description,
          currencyType: this.state.currencyType,
          discountPercent:
            this.state.discountPercent !== ""
              ? parseInt(this.state.discountPercent)
              : 0,
          discountValue:
            this.state.discountValue !== ""
              ? parseFloat(this.state.discountValue)
              : 0,
          discountTotal: parseFloat(this.state.discountTotal),
          status: true, //this.state.status,
          paymentCalendarDto: this.state.paymentCalendarData,
          isHidden: this.state.isHidden,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/gider-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  PriceCalculate = async (event) => {
    if (event) {
      const { name, value } = event.target;
      await this.setState({
        [name]: value,
        totalError: ""
      });
      if (name === "total" && value === "") {
        this.setState({
          totalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "generalTotal" && value === "") {
        this.setState({
          generalTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountTotal" && value === "") {
        this.setState({
          discountTotalError: CgnMessage.textErrorMessage,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
      if (name === "discountPercent") {
        await this.setState({
          discountValue: "",
        });
      }
      if (name === "discountValue") {
        await this.setState({
          discountPercent: "",
        });
      }
    }
    var total = parseFloat(this.state.total);
    var discountPercent = parseFloat(this.state.discountPercent);
    var discountValue = parseFloat(this.state.discountValue);
    var generalTotal = 0;
    var discountTotal = 0;
    if (discountPercent > 0) {
      generalTotal = total - total * (discountPercent / 100);
    } else if (discountValue > 0) {
      generalTotal = total - discountValue;
    } else {
      generalTotal = total;
    }
    if (this.state.taxTypeValue && this.state.taxRateValue) {
      if (this.state.taxTypeValue === 1) {
        discountTotal = generalTotal * (this.state.taxRateValue / 100 + 1);
      } else if (this.state.taxTypeValue === 2) {
        discountTotal = generalTotal;
      } else if (this.state.taxTypeValue === 3) {
        discountTotal = generalTotal;
      }
    }

    await this.setState({
      total: total,
    });
    await this.setState({
      generalTotal: parseFloat(generalTotal).toFixed(2),
    });
    await this.setState({
      discountTotal: parseFloat(discountTotal).toFixed(2),
    });
  };

  render() {
    const noMultipleChoiceStyle = {
      marginTop: "-14px",
      marginBottom: "5px",
    };
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  
                  <Col lg="3">
                    <CgnDatePicker
                      name="costDate"
                      label="Tarih [*]"
                      selected={
                        this.state.costDate &&
                        this.state.costDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.costDate.split("/")[2] +
                                "-" +
                                this.state.costDate.split("/")[1] +
                                "-" +
                                this.state.costDate.split("/")[0]
                            )
                          : this.state.costDate
                      }
                      onChange={(costDate) => this.handleDateChange(costDate)}
                      error={this.state.costDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="costTypeGuid"
                          label="Gider Türü"
                          placeholder="Gider türü seçiniz..."
                          selectValue={this.state.costTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCostTypeSelectChange(option)
                          }
                          options={this.state.costTypeArr}
                          error={this.state.costTypeError}
                        />
                      </div>
                      {this.state.costTypeModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCostType()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="supplierGuid"
                          label="Tedarikçi"
                          placeholder="Tedarikçi seçiniz..."
                          selectValue={this.state.supplierGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSupplierSelectChange(option)
                          }
                          options={this.state.supplierArr}
                          error={this.state.supplierError}
                        />
                      </div>
                      {this.state.supplierModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalSupplier()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="employeeGuid"
                          label="Personel"
                          placeholder="Personel seçiniz..."
                          selectValue={this.state.employeeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleEmployeeSelectChange(option)
                          }
                          options={this.state.employeeArr}
                          error={this.state.employeeNameError}
                        />
                      </div>
                      {this.state.membershipModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalEmployee()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="text-danger" style={noMultipleChoiceStyle}>
                      Not: Lütfen sadece tedarikçi veya personel seçimlerinden
                      birini yapınız!
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="total"
                      label="Tutar [*]"
                      value={this.state.total}
                      placeHolder="Lütfen tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.PriceCalculate}
                      error={this.state.totalError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountPercent"
                      label="İskonto(Yüzde)"
                      value={this.state.discountPercent}
                      placeHolder="Lütfen iskonto girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountPercent}
                      onChange={this.PriceCalculate}
                      error={this.state.discountPercentError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountValue"
                      label="İskonto(Net Tutar)"
                      value={this.state.discountValue}
                      placeHolder="Lütfen iskonto net tutar girin..."
                      maxLength="50"
                      autoComplete="off"
                      readOnly={this.state.isDiscountValue}
                      onChange={this.PriceCalculate}
                      error={this.state.discountValueError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxTypeValue"
                      label="K.D.V Türü [*]"
                      placeholder="Lütfen K.D.V türü seçiniz..."
                      selectValue={this.state.taxTypeValue}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTaxTypeSelectChange(option)
                      }
                      options={TaxType}
                      error={this.state.taxTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="taxRateValue"
                      label="K.D.V Oranı [*]"
                      placeholder="Lütfen K.D.V oranı seçiniz..."
                      selectValue={this.state.taxRateValue}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTrue}
                      onChange={(option) =>
                        this.handleTaxRateSelectChange(option)
                      }
                      options={TaxRateChoices}
                      error={this.state.taxRateError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                {this.state.paymentCalendarModalPlus &&
                  this.state.paymentCalendarData.map(
                    (paymentCalendarData, idx) => (
                      <Row className="mobileBorder">
                        <Col lg="3">
                          <CgnDatePicker
                            name="targetPaymentDate"
                            label="Ödeme Tarihi [*]"
                            selected={
                              paymentCalendarData.targetPaymentDate &&
                              paymentCalendarData.targetPaymentDate
                                .toString()
                                .indexOf("/") > 0
                                ? new Date(
                                    paymentCalendarData.targetPaymentDate.split(
                                      "/"
                                    )[2] +
                                      "-" +
                                      paymentCalendarData.targetPaymentDate.split(
                                        "/"
                                      )[1] +
                                      "-" +
                                      paymentCalendarData.targetPaymentDate.split(
                                        "/"
                                      )[0]
                                  )
                                : paymentCalendarData.targetPaymentDate
                            }
                            onChange={(option) => {
                              this.handleTargetPaymentDateChange(option, idx);
                            }}
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                            error={paymentCalendarData.targetPaymentDateError}
                          />
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="number"
                            name="targetTotal"
                            label="Ödeme Tutarı [*]"
                            value={paymentCalendarData.targetTotal}
                            onChange={(e) => this.handleTextBoxChange(e, idx)}
                            placeHolder="Lütfen tutar girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={paymentCalendarData.targetTotalError}
                          />
                        </Col>

                        {/* <Col lg="3">
                    <CgnReactSelect
                      name="targetCurrencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={
                        paymentCalendarData.targetCurrencyType
                      }
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTargetCurrencyTypeChange(option, idx)
                      }
                      options={CurrencyType}
                      error={paymentCalendarData.targetCurrencyTypeError}
                    />
                  </Col> */}

                        <Col lg="4">
                          <CgnReactSelect
                            name="targetPaymentMethodType"
                            label="Ödeme Yöntemi [*]"
                            placeholder="Lütfen ödeme yöntemi seçiniz..."
                            selectValue={
                              paymentCalendarData.targetPaymentMethodType
                            }
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleInputValueChange(option, idx);
                            }}
                            options={PaymentMethodType}
                            error={
                              paymentCalendarData.targetPaymentMethodTypeError
                            }
                          />
                        </Col>

                        <Col lg="1" sm="3">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton"
                            onClick={() =>
                              this.handlePaymentCalendarRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    )
                  )}

                {this.state.paymentCalendarModalPlus && (
                  <Row>
                    <Col lg="4">
                      <CgnButton
                        type="button"
                        color="primary"
                        className="mb-3 dynamicRowAddButton"
                        onClick={this.handlePaymentCalendarAdd}
                        text={"Ödeme Takvimi Ekle"}
                      />
                    </Col>
                  </Row>
                )}

                {this.state.paymentModalPlus && (
                    <>
                      <Row>
                        <Col
                          lg="12"
                          className="font-weight-bold"
                          style={{ padding: "0 15px 10px 15px" }}
                        >
                          <Label
                            check
                            className="labelCheck"
                            style={{ fontSize: "13px" }}
                          >
                            <Input
                              type="checkbox"
                              className="mt-1"
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              checked={!this.state.isHidden}
                            />{" "}
                            Ödeme Yapıldı mı ?
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <CgnTextbox
                            type="text"
                            name="receiptNo"
                            label="Makbuz No"
                            value={this.state.receiptNo}
                            placeHolder="Lütfen makbuz no girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.receiptNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                        <Col lg="6">
                          <CgnTextbox
                            type="number"
                            name="expiry"
                            label="Vade"
                            value={this.state.expiry}
                            placeHolder="Lütfen vade sayısı girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.expiryError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <CgnReactSelect
                            name="paymentMethodValue"
                            label="Ödeme Yöntemi [*]"
                            placeholder="Lütfen ödeme yöntemi seçiniz..."
                            selectValue={this.state.paymentMethodValue}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handlePaymentMethodSelectChange(option)
                            }
                            options={PaymentMethodType}
                            error={this.state.paymentMethodTypeError}
                            hidden={this.state.isHidden}
                          />
                        </Col>

                        <Col
                          lg="4"
                          className="modalSelectCol"
                          style={
                            this.state.isHidden
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <div className="flex-container">
                            <div className="p-0 flexMaxInput">
                              <CgnReactSelect
                                name="bankGuid"
                                label="Banka"
                                placeholder="Banka seçiniz..."
                                selectValue={this.state.bankGuid}
                                isMulti={false}
                                isClearable={true}
                                disabled={this.state.isBankTrue}
                                onChange={(option) =>
                                  this.handleBankSelectChange(option)
                                }
                                options={this.state.bankArr}
                                error={this.state.bankError}
                                hidden={this.state.isHidden}
                              />
                            </div>
                            {this.state.bankModalPlus && (
                              <div className="p-0 flexAddMinButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalBank()}
                                  text={"+"}
                                  visibled={this.state.isHidden}
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="text"
                            name="chequesBondNo"
                            label="Çek/Senet No"
                            value={this.state.chequesBondNo}
                            placeHolder="Lütfen çek/senet no girin..."
                            autoComplete="off"
                            readOnly={this.state.isChequesBondNoTrue}
                            onChange={this.handleChange}
                            error={this.state.chequesBondNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <ImageUpload
                            accept="image/png, image/jpeg"
                            className="text-center"
                            style={fotoStyle}
                            label="Makbuz Fotoğrafı"
                            image={this.state.image}
                            noFoto={noImage}
                            alt={this.state.name}
                            onChange={this.uploadedImage}
                            onDelete={this.deleteImage}
                            error={this.state.imageError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12" >
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalCostType}
          toggle={this.addModalCostType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Gider Türü Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <CostTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCostType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalSupplier}
          toggle={this.addModalSupplier}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tedarikçi Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SupplierAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSupplier}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalEmployee}
          toggle={this.addModalEmployee}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Personel Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <EmployeeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalEmployee}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default CostAddUpdate;
