import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  PaymentTypeOptions,
  URL,
  
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  CustomerList,
  localFilePath,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class CollectionCalendarList extends Component {
  state = {
    pageTitle: "Tahsilat Takvimi Listele",
    pageCollectionAddLink: "tahsilat-ekle",
    pageCollectionListLink: "tahsilat-listele",
    breadcrumb: [{ text: "Tahsilat Takvimi", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    collectionCalendarData: [],
    targetTotal: 0,
    targetTotalDolar: 0,
    targetTotalEuro: 0,
    guid: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateCollectionCalendar")) {
      firstDate = localStorage.getItem("firstDateCollectionCalendar");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateCollectionCalendar")) {
      lastDate = localStorage.getItem("lastDateCollectionCalendar");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateCollectionCalendar", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateCollectionCalendar", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem(
      "customerGuidCollectionCalendar",
      this.state.customerGuid
    );
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var apiURL =
      "/collectioncalendars/getlist" +
      "/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.customerGuid;

    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "gelir-detay") {
      apiURL = "/collectioncalendars/getlistbyguid/" + urlSplit[2];
      this.setState({
        detail: true,
      });
    }

    await axios
      .get(URL + apiURL, config)
      .then((response) => {
        this.setState({
          collectionCalendarData: response.data,
          isLoading: false,
        });
        var targetTotal = 0;
        var targetTotalDolar = 0;
        var targetTotalEuro = 0;
        response.data.forEach((element) => {
          if (element.targetCurrencyType === 1) {
            targetTotal += element.targetTotal;
          } else if (element.targetCurrencyType === 2) {
            targetTotalDolar += element.targetTotal;
          } else if (element.targetCurrencyType === 3) {
            targetTotalEuro += element.targetTotal;
          }
        });
        this.setState({
          targetTotal: targetTotal,
          targetTotalDolar: targetTotalDolar,
          targetTotalEuro: targetTotalEuro,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleExcel = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    var URLParam = "";
    URLParam =
      "/collectionCalendars/print/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate +
      "/" +
      this.state.customerGuid;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/collectionCalendars/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "120px",
  };

  thStyle2 = {
    width: "155px",
  };

  tdStyle = {
    width: "150px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            excelLink={(onClick) => this.handleExcel()}
            //addLink={"/" + this.state.pageAddLink}
            //listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row className="collectionCalendarList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              {this.state.detail === false && (
                <Row className="filterRow">
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="firstDate"
                      label="Baş. Tarihi"
                      selected={
                        this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.firstDate2.split("/")[2] +
                                "-" +
                                this.state.firstDate2.split("/")[1] +
                                "-" +
                                this.state.firstDate2.split("/")[0]
                            )
                          : this.state.firstDate2
                      }
                      onChange={(firstDate2) =>
                        this.handleFirstDateChange(firstDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="lastDate"
                      label="Bit. Tarihi"
                      selected={
                        this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.lastDate2.split("/")[2] +
                                "-" +
                                this.state.lastDate2.split("/")[1] +
                                "-" +
                                this.state.lastDate2.split("/")[0]
                            )
                          : this.state.lastDate2
                      }
                      onChange={(lastDate2) =>
                        this.handleLastDateChange(lastDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="7">
                    <CgnReactSelect
                      name="customerGuid"
                      label="Müşteri"
                      placeholder="Müşteri seçiniz..."
                      selectValue={this.state.customerGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCustomerSelectChange(option)
                      }
                      options={this.state.customerArr}
                    />
                  </Col>
                  <Col className="pl-2 pr-0" md="1">
                    <CgnButton
                      type="button"
                      color="primary"
                      onClick={this.getList}
                      text="Listele"
                      className="listButton"
                    />
                  </Col>
                </Row>
              )}
              <CgnDatatable
                data={this.state.collectionCalendarData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Tahsilat Takvimi Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td colSpan={4}>{row.targetCollectionDate}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td colSpan={4}>{row.customerName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Tahsilat Tutarı</th>
                              <td style={this.tdStyle}>
                                {row.targetTotal.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.targetCurrencyType === 1
                                    ? "₺"
                                    : row.targetCurrencyType === 2
                                    ? "$"
                                    : row.targetCurrencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                              <th style={this.thStyle2}>
                                Tahsilat Yöntemi Türü
                              </th>
                              <td>
                                {PaymentTypeOptions(
                                  row.targetCollectionMethodType
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td colSpan={4}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  // {
                  //   ignoreRowClick: true,
                  //   allowOverflow: true,
                  //   button: true,
                  //   cell: (row, cell) => (
                  //     <CgnButtonDropdown
                  //       label={"Yönet"}
                  //       className="mr-0"
                  //       color="primary"
                  //       items={[
                  //         {
                  //           text: "Tahsilat Ekle",
                  //           className: "dropDownBtn",
                  //           href: `/${this.state.pageCollectionAddLink}/${row.incomeGuid}`,
                  //         },
                  //         {
                  //           text: "Tahsilat Listele",
                  //           className: "dropDownBtn",
                  //           href: `/${this.state.pageCollectionListLink}/${row.incomeGuid}`,
                  //         },
                  //       ]}
                  //     />
                  //   ),
                  // },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton ml-2">
                Toplam Tahsilat Tutarı (₺):{" "}
                {this.state.targetTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton ml-2">
                Toplam Tahsilat Tutarı ($):{" "}
                {this.state.targetTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton ml-2">
                Toplam Tahsilat Tutarı (€):{" "}
                {this.state.targetTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default CollectionCalendarList;
