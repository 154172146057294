import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import CgnButton from "../toolbox/CgnButton";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import ShippingTypeAddUpdate from "../ShippingType/ShippingTypeAddUpdate";
import ShippingCompaniesAddUpdate from "../ShippingCompanies/ShippingCompaniesAddUpdate";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import moment from "moment";
import axios from "axios";
import {
  URL,
  cargoStatuses,
  moldingRoomStatus,
  
  UserOperationClaimControl,
  fotoStyle,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";


class CargoTrackingAddUpdate extends Component {
  state = {
    pageTitle: "Kargo Takip Ekle",
    pageTitleAdd: "Kargo Takip Ekle",
    pageTitleUpdate: "Kargo Takip Güncelle",
    pageAddLink: "kargo-takip-ekle",
    pageListLink: "kargo-takip-listele",
    breadcrumb: [{ text: "Kargo Takip", link: "#" }],
    isLoading: false,
    urlSplit: [],
    guid: "",
    arrivedDate: "",
    arrivedDateError: "",
    shippingTypeGuid: "",
    shippingTypeGuidError: "",
    shippingCompaniesGuid: "",
    shippingCompaniesGuidError: "",
    customerGuid: "",
    customerGuidError: "",
    description: "",
    isCustomerInfo: false,
    isScanner: false,
    isWhatsapp: false,
    status: 1,
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    shippingTypeArr: [],
    shippingCompaniesArr: [],
    customerArr: [],
    shippingTypeModalPlus: false,
    isOpenModalShippingType: false,
    shippingCompaniesModalPlus: false,
    isOpenModalShippingCompanies: false,
    customerModalPlus: false,
    isOpenModalCustomer: false,
    statusDateTime: "",
    statusDateTimeError: "",
    cargoTrackingDocumentDto: [],
    cargoTrackingDocumentDtoError: "",
  };
  async componentDidMount() {
    this.setState({
      isLoading: true,
      shippingTypeModalPlus: await UserOperationClaimControl(1267),
      shippingCompaniesModalPlus: await UserOperationClaimControl(1266),
      customerModalPlus: await UserOperationClaimControl(1012),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/shippingtype/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          shippingTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    await axios
      .get(URL + "/shippingcompanies/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          shippingCompaniesArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    await axios
      .get(URL + "/customers/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });

    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/cargotracking/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              arrivedDate: response.data.arrivedDate,
              shippingTypeGuid: response.data.shippingTypeGuid,
              shippingCompaniesGuid: response.data.shippingCompaniesGuid,
              customerGuid: response.data.customerGuid,
              description: response.data.description,
              isCustomerInfo: response.data.isCustomerInfo,
              isScanner: response.data.isScanner,
              isWhatsapp: response.data.isWhatsapp,
              status: response.data.status,
              statusDateTime: response.data.statusDateTime,
              cargoTrackingDocumentDto: response.data.cargoTrackingDocumentDto,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        arrivedDate: "",
        shippingTypeGuid: "",
        shippingCompaniesGuid: "",
        customerGuid: "",
        description: "",
        isCustomerInfo: false,
        isScanner: false,
        isWhatsapp: false,
        status: 1,
        cargoTrackingDocumentDto: [],
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: 1,
      });
    }
  }
  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }
  handleClear = (event) => {
    this.clearPage();
  };
  clearPage() {
    this.setState({
      arrivedDate: "",
      arrivedDateError: "",
      shippingTypeGuid: "",
      shippingTypeGuidError: CgnMessage.textErrorMessage,
      shippingCompaniesGuid: "",
      shippingCompaniesGuidError: CgnMessage.textErrorMessage,
      customerGuid: "",
      customerGuidError: CgnMessage.textErrorMessage,
      description: "",
      isCustomerInfo: false,
      isScanner: false,
      isWhatsapp: false,
      status: 1,
      cargoTrackingDocumentDto: [],
      cargoTrackingDocumentDtoError: "",
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  }
  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }
  handleCustomerInfoSelectChange(customerInfo) {
    this.setState({ isCustomerInfo: customerInfo.value });
  }
  handleScannerInfoSelectChange(scanner) {
    this.setState({ isScanner: scanner.value });
  }
  handleWhatsappInfoSelectChange(whatsapp) {
    this.setState({ isWhatsapp: whatsapp.value });
  }
  handleShippingTypeGuidChange(option) {
    if (option) {
      this.setState({
        shippingTypeGuid: option.value,
        shippingTypeGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        shippingTypeGuid: "",
        shippingTypeGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  handleShippingCompaniesGuidChange(option) {
    if (option) {
      this.setState({
        shippingCompaniesGuid: option.value,
        shippingCompaniesGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        shippingCompaniesGuid: "",
        shippingCompaniesGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  handleCustomerGuidChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
  };
  handleArrivedDateChange(arrivedDate) {
    if (arrivedDate) {
      this.setState({
        arrivedDate: arrivedDate,
        arrivedDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        arrivedDate: "",
        arrivedDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  handleStatusDateTimeChange(statusDateTime) {
    if (statusDateTime) {
      this.setState({
        statusDateTime: statusDateTime,
        statusDateTimeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        statusDateTime: "",
        statusDateTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  addModalShippingType = () => {
    this.setState({
      isOpenModalShippingType: !this.state.isOpenModalShippingType,
    });
  };
  addModalShippingCompanies = () => {
    this.setState({
      isOpenModalShippingCompanies: !this.state.isOpenModalShippingCompanies,
    });
  };
  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };
  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      arrivedDateError: "",
      shippingTypeGuidError: "",
      shippingCompaniesGuidError: "",
      customerGuidError: "",
      cargoTrackingDocumentDtoError: "",
    });
    if (this.state.arrivedDate === "") {
      this.setState({
        arrivedDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.shippingTypeGuid === "") {
      this.setState({
        shippingTypeGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.shippingCompaniesGuid === "") {
      this.setState({
        shippingCompaniesGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.customerGuid === "") {
      this.setState({
        customerGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      (this.state.status === 4 ||
        this.state.status === 5 ||
        this.state.status === 6) &&
      this.state.statusDateTime === ""
    ) {
      this.setState({
        statusDateTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.arrivedDate !== "" &&
      this.state.shippingTypeGuid !== "" &&
      this.state.shippingCompaniesGuid !== "" &&
      this.state.customerGuid !== "" &&
      (this.state.status < 4 ||
        (this.state.status >= 4 && this.state.statusDateTime !== ""))
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        arrivedDate: moment(this.state.arrivedDate).format("DD/MM/YYYY"),
        shippingTypeGuid: this.state.shippingTypeGuid,
        shippingCompaniesGuid: this.state.shippingCompaniesGuid,
        customerGuid: this.state.customerGuid,
        description: this.state.description,
        isCustomerInfo: this.state.isCustomerInfo,
        isScanner: this.state.isScanner,
        isWhatsapp: this.state.isWhatsapp,
        status: this.state.status,
        statusDateTime:
          this.state.statusDateTime !== ""
            ? moment(this.state.statusDateTime).format("DD/MM/YYYY")
            : null,
        cargoTrackingDocumentDto: this.state.cargoTrackingDocumentDto,
      };
      var URLParam = "/cargotracking/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "kargo-takip-guncelle"
      ) {
        URLParam = "/cargotracking/update";
        myObj = {
          guid: this.state.guid,
          arrivedDate:
            this.state.arrivedDate &&
            this.state.arrivedDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.arrivedDate.split("/")[2] +
                    "-" +
                    this.state.arrivedDate.split("/")[1] +
                    "-" +
                    this.state.arrivedDate.split("/")[0]
                )
              : moment(this.state.arrivedDate).format("DD/MM/YYYY"),
          shippingTypeGuid: this.state.shippingTypeGuid,
          shippingCompaniesGuid: this.state.shippingCompaniesGuid,
          customerGuid: this.state.customerGuid,
          description: this.state.description,
          isCustomerInfo: this.state.isCustomerInfo,
          isScanner: this.state.isScanner,
          isWhatsapp: this.state.isWhatsapp,
          status: this.state.status,
          statusDateTime:
            this.state.statusDateTime !== ""
              ? this.state.statusDateTime &&
                this.state.statusDateTime.toString().indexOf("/") > 0
                ? new Date(
                    this.state.statusDateTime.split("/")[2] +
                      "-" +
                      this.state.statusDateTime.split("/")[1] +
                      "-" +
                      this.state.statusDateTime.split("/")[0]
                  )
                : moment(this.state.statusDateTime).format("DD/MM/YYYY")
              : null,
          cargoTrackingDocumentDto: this.state.cargoTrackingDocumentDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "kargo-takip-guncelle"
            ) {
              window.location.href = "/kargo-takip-listele";
            }
            if (this.state.urlSplit[1] === "kargo-takip-ekle") {
              window.location.reload();
            }
            var modalAddedCargoTracking = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedCargoTracking });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  uploadedDocument = async (event) => {
    let array = this.state.cargoTrackingDocumentDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.setState({
        isLoading: true,
      });
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    }
    await this.setState({ cargoTrackingDocumentDto: array });
  };
  deleteDocument = (event) => {
    var index = -1;
    let array = this.state.cargoTrackingDocumentDto;
    this.state.cargoTrackingDocumentDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ cargoTrackingDocumentDto: array });
        }
      }
    });
  };
  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "kargo-takip-ekle" ||
          this.state.urlSplit[1] === "kargo-takip-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="costDate"
                      label="Geldiği Tarih [*]"
                      selected={
                        this.state.arrivedDate &&
                        this.state.arrivedDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.arrivedDate.split("/")[2] +
                                "-" +
                                this.state.arrivedDate.split("/")[1] +
                                "-" +
                                this.state.arrivedDate.split("/")[0]
                            )
                          : this.state.arrivedDate
                      }
                      onChange={(arrivedDate) =>
                        this.handleArrivedDateChange(arrivedDate)
                      }
                      error={this.state.arrivedDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9"></Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="shippingTypeGuid"
                          label="Kargo Türü [*]"
                          placeholder="Kargo Türü seçiniz..."
                          selectValue={this.state.shippingTypeGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleShippingTypeGuidChange(option)
                          }
                          options={this.state.shippingTypeArr}
                          error={this.state.shippingTypeGuidError}
                        />
                      </div>
                      {this.state.shippingTypeModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalShippingType()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="shippingCompaniesGuid"
                          label="Kargo Firması [*]"
                          placeholder="Kargo Firması seçiniz..."
                          selectValue={this.state.shippingCompaniesGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleShippingCompaniesGuidChange(option)
                          }
                          options={this.state.shippingCompaniesArr}
                          error={this.state.shippingCompaniesGuidError}
                        />
                      </div>
                      {this.state.shippingCompaniesModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalShippingCompanies()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerGuidChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerGuidError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="İçinde Ne Var? (Kaç Adet Dosya vb.)"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnReactSelect
                      name="isCustomerInfo"
                      label="Müşteri Bilgilendirildi Mi?"
                      isMulti={false}
                      selectValue={this.state.isCustomerInfo}
                      options={moldingRoomStatus}
                      onChange={(isCustomerInfo) =>
                        this.handleCustomerInfoSelectChange(isCustomerInfo)
                      }
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="isScanner"
                      label="Tarama Yapıldı Mı?"
                      isMulti={false}
                      selectValue={this.state.isScanner}
                      options={moldingRoomStatus}
                      onChange={(isScanner) =>
                        this.handleScannerInfoSelectChange(isScanner)
                      }
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="isWhatsapp"
                      label="Whatsapp Gönderildi Mi?"
                      isMulti={false}
                      selectValue={this.state.isWhatsapp}
                      options={moldingRoomStatus}
                      onChange={(isWhatsapp) =>
                        this.handleWhatsappInfoSelectChange(isWhatsapp)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pl-0">
                    <CgnImageUploadMulti
                      images={this.state.cargoTrackingDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png"
                      className="text-center text-danger"
                      label={"Belgeler"}
                      onChange={this.uploadedDocument}
                      onDelete={this.deleteDocument}
                      error={this.state.cargoTrackingDocumentDtoError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={cargoStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="statusDateTime"
                      label="Tarih"
                      selected={
                        this.state.statusDateTime &&
                        this.state.statusDateTime.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.statusDateTime.split("/")[2] +
                                "-" +
                                this.state.statusDateTime.split("/")[1] +
                                "-" +
                                this.state.statusDateTime.split("/")[0]
                            )
                          : this.state.statusDateTime
                      }
                      onChange={(statusDateTime) =>
                        this.handleStatusDateTimeChange(statusDateTime)
                      }
                      error={this.state.statusDateTimeError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6"></Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "kargo-takip-ekle" ||
              this.state.urlSplit[1] === "kargo-takip-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalShippingType}
          toggle={this.addModalShippingType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Kargo Türü Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ShippingTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalShippingType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalShippingCompanies}
          toggle={this.addModalShippingCompanies}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Kargo Firması Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ShippingCompaniesAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalShippingCompanies}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default withRouter(CargoTrackingAddUpdate);
