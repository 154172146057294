import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class FixedCastingDetail extends Component {
  state = {
    pageTitle: "Döküm Sabitleri Detay",
    pageTitleDetail: "Döküm Sabitleri Detay",
    pageAddLink: "dokum-sabitleri-ekle",
    pageListLink: "dokum-sabitleri-listele",
    breadcrumb: [{ text: "Döküm Sabitleri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    name: "",
    bDORate: 0,
    dSMRate: 0,
    lama1020Rate: 0,
    lama810Rate: 0,
    mocaPercent: 0,
    pimRate: 0,
    ropeRate: 0,
    sandblastingRate: 0,
    sidePlateRate: 0,
    profitRate: 0,
    ringRate: 0,
    transportTotal: 0,
    dsm: 0,
    rope: 0,
    ring: 0,
    pim: 0,
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    tdiEsterRate: 0,
    mdiEsterRate: 0,
    tdiEtherRate: 0,
    mdiEtherRate: 0,
    bdoPercent: 0,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/fixedCastings/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            bDORate: parseFloat(response.data.bdoRate),
            dSMRate: parseFloat(response.data.dsmRate),
            lama1020Rate: parseFloat(response.data.lama1020Rate),
            lama810Rate: parseFloat(response.data.lama810Rate),
            mocaPercent: parseFloat(response.data.mocaPercent),
            pimRate: parseFloat(response.data.pimRate),
            profitRate: parseFloat(response.data.profitRate),
            ringRate: parseFloat(response.data.ringRate),
            ropeRate: parseFloat(response.data.ropeRate),
            sandblastingRate: parseFloat(response.data.sandblastingRate),
            sidePlateRate: parseFloat(response.data.sidePlateRate),
            transportTotal: parseFloat(response.data.transportTotal),
            mocaRate: parseFloat(response.data.mocaRate),
            dsm: parseFloat(response.data.dsm),
            rope: parseFloat(response.data.rope),
            ring: parseFloat(response.data.ring),
            pim: parseFloat(response.data.pim),
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            tdiEsterRate: parseFloat(response.data.tdiEsterRate),
            mdiEsterRate: parseFloat(response.data.mdiEsterRate),
            tdiEtherRate: parseFloat(response.data.tdiEtherRate),
            mdiEtherRate: parseFloat(response.data.mdiEtherRate),
            bdoPercent: parseFloat(response.data.bdoPercent),
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>TDI Ester (Euro)</th>
                    <td colSpan="4">
                      {this.state.tdiEsterRate !== 0
                        ? this.state.tdiEsterRate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>MDI Ester (Euro)</th>
                    <td colSpan="4">
                      {this.state.mdiEsterRate !== 0
                        ? this.state.mdiEsterRate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>TDI Ether (Euro)</th>
                    <td colSpan="4">
                      {this.state.tdiEtherRate !== 0
                        ? this.state.tdiEtherRate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>MDI Ether (Euro)</th>
                    <td colSpan="4">
                      {this.state.mdiEtherRate !== 0
                        ? this.state.mdiEtherRate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>BDO Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.bDORate !== 0 ? this.state.bDORate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>BDO Yüzdesi</th>
                    <td colSpan="4">
                      {this.state.bdoPercent !== 0
                        ? this.state.bdoPercent
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Moca Yüzdesi</th>
                    <td colSpan="4">
                      {this.state.mocaPercent !== 0
                        ? this.state.mocaPercent
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>DSM Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.dSMRate !== 0 ? this.state.dSMRate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kumlama Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.sandblastingRate !== 0
                        ? this.state.sandblastingRate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Yan Gergi Sacı Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.sidePlateRate !== 0
                        ? this.state.sidePlateRate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>8x16 Lama Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.lama810Rate !== 0
                        ? this.state.lama810Rate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>10x20 Lama Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.lama1020Rate !== 0
                        ? this.state.lama1020Rate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Halat Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.ropeRate !== 0 ? this.state.ropeRate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kurşun Yüzük Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.ringRate !== 0 ? this.state.ringRate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Pim Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.pimRate !== 0 ? this.state.pimRate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Nakliye Birimi (Euro)</th>
                    <td colSpan="4">
                      {this.state.transportTotal !== 0
                        ? this.state.transportTotal
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Moca Değeri (Euro)</th>
                    <td colSpan="4">
                      {this.state.mocaRate !== 0 ? this.state.mocaRate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>İşçilik Yüzdesi</th>
                    <td colSpan="4">
                      {this.state.profitRate !== 0
                        ? this.state.profitRate
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>DSM</th>
                    <td colSpan="4">
                      {this.state.dsm !== 0 ? this.state.dsm : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Rope</th>
                    <td colSpan="4">
                      {this.state.rope !== 0 ? this.state.rope : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ring</th>
                    <td colSpan="4">
                      {this.state.ring !== 0 ? this.state.ring : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Pim</th>
                    <td colSpan="4">
                      {this.state.pim !== 0 ? this.state.pim : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default FixedCastingDetail;
