import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Modal, Table, ModalHeader, Button,  ModalBody, ModalFooter
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import CgnButton from "../toolbox/CgnButton";
import axios from "axios";
import {
  PaymentTypeOptions,
  URL,
  
  localFilePath,
  fotoStyle,
} from "../../redux/actions/constants";
import noImage from "../../images/no-img.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PaymentAddUpdate from "../payment/PaymentAddUpdate";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";

class CostDetail extends Component {
  state = {
    pageTitle: "Gider Detay",
    pageTitleDetail: "Gider Detay",
    pageAddLink: "gider-ekle",
    pageListLink: "gider-listele",
    breadcrumb: [{ text: "Gider", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    costDate: "",
    costTypeName: "",
    supplierName: "",
    employeeName: "",
    customerName: "",
    total: null,
    generalTotal: null,
    discountPercent: "",
    discountValue: "",
    discountTotal: "",
    currencyType: 0,
    taxType: null,
    taxRate: null,
    description: "",
    paymentCalendarDto: [],
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    activeTab: "1",
    costDetail: "1",
    paymentList: "2",
    paymentCalendarList: "3",
    paymentDto: [],
    isModalPayment :false,
    isModalUpdatePayment: false,
    paymentGuid: ""
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/costs/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            costDate: response.data.costDate,
            paymentCalendarDto: response.data.paymentCalendarDto,
            paymentDto: response.data.paymentDto,
            costTypeName: response.data.costTypeName,
            supplierName: response.data.supplierName,
            employeeName: response.data.employeeName,
            customerName: response.data.customerName,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            discountPercent: response.data.discountPercent,
            discountValue: parseFloat(response.data.discountValue).toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            discountTotal: parseFloat(response.data.discountTotal),
            currencyType: response.data.currencyType,
            taxType: response.data.taxType,
            groupName: response.data.groupName,
            taxRate: response.data.taxRate,
            description: response.data.description,
            paymentTotal: response.data.paymentTotal,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handlePaymentDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handlePaymentSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handlePaymentSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/payments/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalPayment = () => {
    this.setState({
      isOpenModalPayment: !this.state.isOpenModalPayment,
    });
  };

  addModalUpdatePayment = (guid) => {
    this.setState({
      isModalUpdatePayment: !this.state.isModalUpdatePayment,
      paymentGuid: guid
    });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  thStyle = {
    width: "175px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "200px",
  };


  tdStyle3 = {
    width: "90px",
  };

  tdStyle2 = {
    width: "600px",
  };

  thStyle3 = {
    width:"150px",
  };


  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self = this.state;
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          paymentLink={onClick => this.addModalPayment()}
        />
        <Row className="customerDetail">
          <Col>
            <CgnLoader visibled={this.state.isLoading} />
            {this.state.alertErrorMessage && (
              <CgnAlert
                color="danger"
                title="Uyarı"
                text={this.state.alertErrorMessage}
              />
            )}
            <Card body className="table-responsive">
                  <Table className="table table-responsive table-hover">
                    <tbody>
                      <tr>
                        <th>Gider</th>
                        <td colSpan="4">{this.state.costDate}</td>
                      </tr>

                      <tr>
                        <th>Gider Türü</th>
                        <td colSpan="4">
                          {this.state.costTypeName
                            ? this.state.costTypeName
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Tedarikçi</th>
                        <td colSpan="4">
                          {this.state.supplierName
                            ? this.state.supplierName
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Personel</th>
                        <td colSpan="4">
                          {this.state.employeeName !== " "
                            ? this.state.employeeName
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Müşteri</th>
                        <td colSpan="4">
                          {this.state.customerName
                            ? this.state.customerName
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Ara Tutar</th>
                        <td colSpan="4">
                          {this.state.generalTotal
                            ? this.state.generalTotal +
                              " " +
                              (this.state.currencyType === 1
                                ? "₺"
                                : this.state.currencyType === 2
                                ? "$"
                                : this.state.currencyType === 3
                                ? "€"
                                : "-")
                            : "-"}
                        </td>
                      </tr>

                      {/* <tr>
                        <th>İskonto(Yüzde)</th>
                        <td colSpan="4">
                          {this.state.discountPercent
                            ? "% " + this.state.discountPercent
                            : "-"}
                        </td>
                      </tr> */}

                      <tr>
                        <th>İskonto(Net Tutar)</th>
                        <td colSpan="4">
                        {this.state.discountValue.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>

                      {/* <tr>
                        <th>Genel Tutar</th>
                        <td colSpan="4">
                          {this.state.generalTotal
                            ? this.state.generalTotal +
                              " " +
                              (this.state.currencyType === 1
                                ? "₺"
                                : this.state.currencyType === 2
                                ? "$"
                                : this.state.currencyType === 3
                                ? "€"
                                : "-")
                            : "-"}
                        </td>
                      </tr> */}

                      {/* <tr>
                        <th>K.D.V. Türü</th>
                        <td colSpan="4">
                          {TaxTypeOptions(this.state.taxType)}
                        </td>
                      </tr>

                      <tr>
                        <th>K.D.V. Oranı</th>
                        <td colSpan="4">
                          {this.state.taxRate ? "% " + this.state.taxRate : "-"}
                        </td>
                      </tr> */}

                      <tr>
                        <th>Toplam Tutar</th>
                        <td colSpan="4">
                          {this.state.discountTotal.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>

                      <tr>
                        <th>Ödenen Tutar</th>
                        <td colSpan="4">
                          {parseFloat(this.state.paymentTotal).toLocaleString(
                            "tr-TR",
                            { minimumFractionDigits: 2 }
                          ) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>

                      <tr>
                        <th>Kalan Tutar</th>
                        <td colSpan="4">
                          {(
                            this.state.discountTotal - this.state.paymentTotal
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>
                      </tr>

                      <tr>
                        <th>Açıklama</th>
                        <td colSpan="4">
                          {this.state.description
                            ? this.state.description
                            : "-"}
                        </td>
                      </tr>

                      {/* <tr>
                    <th>Durum</th>
                    <th className="colon">:</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr> */}

                      <tr>
                        <th>Kaydeden Kullanıcı</th>
                        <td>
                          {this.state.createdBy ? this.state.createdBy : "-"}
                        </td>
                        <th>Kaydedilme Tarihi</th>
                        <td>
                          {this.state.createdAt ? this.state.createdAt : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Son Güncelleyen Kullanıcı</th>
                        <td>
                          {this.state.updatedBy ? this.state.updatedBy : "-"}
                        </td>
                        <th>Son Güncellenme Tarihi</th>
                        <td>
                          {this.state.updatedAt ? this.state.updatedAt : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {this.state.paymentCalendarDto !== null &&
                    this.state.paymentCalendarDto.length > 0 && (
                      <>
                <div
                  className="row"
                  style={{
                    display:
                      self.paymentCalendarDto !== null && self.paymentCalendarDto.length > 0
                        ? ""
                        : "none",
                  }}
                >
                  <h2 className="mt-2">Ödeme Takvimleri</h2>
                </div>
                       <Table className="table table-responsive table-hover table-product2">
                        <tr style={mainRow}>
                          <th>Ödeme Takvimi Tarihi</th>
                          <th className="text-center">Ödeme Tutarı</th>
                          <th>Ödeme Yöntemi</th>
                        </tr>
                        {this.state.paymentCalendarDto.map(function (item) {
                          return (
                            <tr style={subRow}>
                              <td>{item.targetPaymentDate}</td>
                              <td className="text-center">
                                {PaymentTypeOptions(
                                  item.targetPaymentMethodType
                                )}
                              </td>
                              <td>
                                {item.targetTotal.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (item.targetCurrencyType === 1
                                    ? "₺"
                                    : item.targetCurrencyType === 2
                                    ? "$"
                                    : item.targetCurrencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                          );
                        })}
                      </Table>
                      </>                  
                    )}

               <div
                className="row"
                style={{
                  display:
                    self.paymentDto !== null && self.paymentDto.length > 0
                      ? ""
                      : "none",
                }}
              >
                <h2 className="mb-2 mt-2">Ödemeler</h2>
              </div>
              {this.state.paymentDto.map(function (item) {
                return (
                  <>
                    <Table
                      className="table table-responsive table-hover mb-2"
                      style={{
                        display:
                          self.paymentDto !== null &&
                          self.paymentDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <tbody>
                        <>
                          <Accordion allowZeroExpanded>
                            <AccordionItem>
                              <AccordionItemHeading
                                style={{ backgroundColor: "#9B9796" }}
                              >
                                <AccordionItemButton>
                                  <tr className="cuttingTr4 w-100">
                                    <th className="pl-3" style={self1.thStyle3}>Ödeme Tarihi</th>
                                    <td style={self1.tdStyle2}>{item.paymentDate}</td>
                                    <th style={self1.thStyle}>Ödeme Yöntemi Türü</th>
                                  <td style={self1.tdStyle2}>
                                    {item.paymentMethodType === 1
                                      ? "Çek"
                                      : item.paymentMethodType === 2
                                      ? "Havale/EFT"
                                      : item.paymentMethodType === 3
                                      ? "Kredi Kartı"
                                      : item.paymentMethodType === 4
                                      ? "Nakit"
                                      : item.paymentMethodType === 5
                                      ? "Senet"
                                      : "-"}
                                  </td>
                                    <th className="text-right" style={self1.thStyle3}>Tutar</th>
                                    <td className="text-right pr-3" style={self1.tdStyle3}>
                                    {item.total.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                                        (self.currencyType === 1
                                          ? "₺"
                                          : self.currencyType === 2
                                          ? "$"
                                          : self.currencyType === 3
                                          ? "€"
                                          : "-")}
                                    </td>
                                  </tr>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <tr>
                                  <th className="pl-3" style={self1.thStyle2}>Banka Adı</th>
                                  <td style={self1.tdStyle}>{item.bankName ? item.bankName : "-"}</td>

                                  <th style={self1.thStyle2}>Iban</th>
                                  <td style={self1.tdStyle}>{item.iban ? item.iban : "-"}</td>
      
                                <th style={self1.thStyle2}>Vade</th>
                                  <td style={self1.tdStyle}>{item.expiry > 0 ? item.expiry : "-"}</td>                                
                                  <th style={self1.thStyle}>Çek/Senet Numarası</th>
                                  <td style={self1.tdStyle}>
                                    {item.chequesBondNo
                                      ? item.chequesBondNo
                                      : "-"}
                                  </td>
                                </tr>

                                <tr>
                                  <th className="pl-3">Açıklama</th>
                                  <td colSpan={8}>
                                    {item.description ? item.description : "-"}
                                  </td>
                                </tr>

                                <tr>
                                  <th className="pl-3" style={self1.thStyle}>Makbuz Fotoğraf</th>
                                  <td style={{width: "90%"}} colSpan={8}>
                                    {item.image ? (
                                      <Button
                                        onClick={self1.toggle}
                                        className="modalButtonDetail"
                                      >
                                        <img
                                          src={item.image}
                                          style={fotoStyle}
                                          alt={item.name}
                                        />
                                      </Button>
                                    ) : (
                                      <img
                                        src={noImage}
                                        style={fotoStyle}
                                        alt={item.receiptNo}
                                      />
                                    )}
                                  </td>
                                </tr>

                                <Modal
                                  isOpen={self.isOpen}
                                  toggle={self1.toggle}
                                >
                                  <ModalHeader
                                    toggle={self1.toggle}
                                  ></ModalHeader>
                                  <img
                                    className="modalImage"
                                    src={item.image}
                                    style={fotoStyle}
                                    alt={item.receiptNo}
                                  />
                                </Modal>

                                <tr>
                                  <th className="pl-3" style={self1.thStyle2}>Kaydeden Kullanıcı</th>
                                  <td colSpan={3} style={self1.tdStyle}>
                                    {item.createdBy ? item.createdBy : "-"}
                                  </td>
                                  <th style={self1.thStyle2} className="pl-0">Kaydedilme Tarihi</th>
                                  <td colSpan={4} style={self1.tdStyle}>
                                    {item.createdAt ? item.createdAt : "-"}
                                  </td>
                                </tr>
                                <CgnButton
                                type="button"
                                color="success"
                                className="printButton ml-3 mt-2"
                                onClick={() => self1.addModalUpdatePayment(item.guid)}
                                text="Güncelle"
                               />
                               <CgnButton
                                type="button"
                                color="danger"
                                style={{width:"90px"}}
                                className="ml-3 mt-2"
                                onClick={() => self1.handlePaymentDelete(item.guid)}
                                text="Sil"
                               />
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion>
                        </>
                      </tbody>
                    </Table>
                  </>
                );
              })}

             <div className="row mt-2">
                <div className="col-lg-12">
                <h6 className="p-1 mt-2 pl-2 collectionH2" style={{border: "2px solid #1d4b8c"}}>Kalan Tutar : {(
                            this.state.discountTotal - this.state.paymentTotal
                          ).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}</h6>

                          
                <h6 className="p-1 mt-2 pl-2 collectionH2" style={{border: "2px solid #1d4b8c"}}>Toplam Ödeme : {parseFloat(this.state.paymentTotal).toLocaleString(
                            "tr-TR",
                            { minimumFractionDigits: 2 }
                          ) +
                            " " +
                            (this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : "-")}</h6>

                <h6 className="p-1 mt-2 pl-2 collectionH2" style={{border: "2px solid #1d4b8c"}}>Genel Tutar : {parseFloat(this.state.discountTotal).toLocaleString(
                            "tr-TR",
                            { minimumFractionDigits: 2 }
                          ) +
                            " " +
                        (this.state.currencyType === 1
                          ? " ₺"
                          : this.state.currencyType === 2
                          ? " $"
                          : this.state.currencyType === 3
                          ? " €"
                          : "-")}</h6>
                </div>
              </div>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalPayment}
          toggle={this.addModalPayment}
          className="modal-xl content"
        >
         <ModalBody className="modalBody">
            <PaymentAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalPayment}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isModalUpdatePayment}
          toggle={this.addModalUpdatePayment}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <PaymentAddUpdate guid={this.state.paymentGuid}/>
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdatePayment}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default CostDetail;
