import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  fotoStyle,
  
  localFilePath,
  fotoStyle2,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";


class MachineProductLocationDetail extends Component {
  state = {
    pageTitle: "Ürün Detay",
    pageTitleDetail: "Ürün Detay",
    pageListLink: "depomdaki-urunler",
    breadcrumb: [{ text: "Depom", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    categoryName: "",
    contractName: "",
    code: "",
    name: "",
    description: null,
    criticalPieces: null,
    currencyType: "",
    salePrice: "",
    buyPrice: "",
    machineName: "",
    peerProductName: "",
    supplierProductCode: "",
    status: "",
    machineId: "",
    foreignName: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    machineProductPropertyDto: [],
    machineProductShelfRowDto: [],
    productImageDto: [],
    stockStatus: false,
    productStock: 0,
    locationProductStock: [],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/machineProducts/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            image: response.data.image !== "" ? "/Images/" + response.data.image: "",
            categoryName: response.data.categoryName,
            code: response.data.code,
            name: response.data.name,
            criticalPieces: response.data.criticalPieces,
            currencyType: response.data.currencyType,
            machineProductPropertyDto: response.data.machineProductPropertyDto,
            machineProductShelfRowDto: response.data.machineProductShelfRowDto,
            salePrice: response.data.salePrice.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            buyPrice: response.data.buyPrice.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            stockStatus: response.data.stockStatus,
            productStock: response.data.productStock,
            locationProductStock: response.data.locationProductStock,
            //productImageDto: response.data.productImageDto,
            description: response.data.description,
            machineId: response.data.machineId,
            foreignName: response.data.foreignName,
            machineName: response.data.machineName,
            unit: response.data.unit,
            productStatus: response.data.productStatus,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  thStyle = {
    width: "auto",
  };

  thStyle2 = {
    width: "125px",
    textAlign: "center",
  };

  thStyle3 = {
    width: "125px",
  };

  tdStyle = {
    width: "auto",
    paddingLeft: "10px",
  };

  tdStyle2 = {
    width: "125px",
    textAlign: "center",
  };

  tdStyle3 = {
    width: "125px",
    paddingLeft: "10px",
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Kategori Adı</th>
                    <td colSpan="4">
                      {this.state.categoryName ? this.state.categoryName : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.image ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.image}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal className="modal-xs" isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.image}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal>
                  <tr>
                    <th>Barkod</th>
                    <td colSpan="4">
                      {this.state.code ? this.state.code : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ürün Adı</th>
                    <td colSpan="4">
                      {this.state.name ? this.state.name : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Yabancı Ürün Adı</th>
                    <td colSpan="4">
                      {this.state.foreignName ? this.state.foreignName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Makine</th>
                    <td colSpan="4">
                      {this.state.machineName ? this.state.machineName : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Birim</th>
                    <td colSpan="4">
                      {this.state.unit === 1
                        ? "Adet"
                        : this.state.unit === 2
                        ? "Metre"
                        : this.state.unit === 3
                        ? "Kilogram"
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Durum</th>
                    <td colSpan="4">
                      {this.state.productStatus === 1
                        ? "İthal"
                        : this.state.productStatus === 2
                        ? "Yerli"
                        : this.state.productStatus === 3
                        ? "Saf"
                        : "-"}
                    </td>
                  </tr>

                  {/* <tr>
                    <th>Alış Fiyatı</th>
                    <td colSpan={4}>
                      {this.state.buyPrice
                        ? this.state.buyPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.currencyType === 1
                            ? "₺"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : this.state.currencyType === 4
                            ? "SFr"
                            : "-")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Satış Fiyatı</th>
                    <td colSpan="4">
                      {this.state.salePrice
                        ? `${this.state.salePrice} ${
                            this.state.currencyType === 1
                              ? "₺"
                              : this.state.currencyType === 2
                              ? "$"
                              : this.state.currencyType === 3
                              ? "€"
                              : this.state.currencyType === 4
                              ? "SFr"
                              : "-"
                          }`
                        : "-"}
                    </td>
                  </tr> */}

                  <tr>
                    <th>Stok Durumu</th>
                    <td colSpan="4">
                      {this.state.stockStatus === 2 ? "Stoklu Ürün (Zorunlu)" : this.state.stockStatus === 1 ? "Stoklu Ürün" : "Stoksuz Ürün"}
                    </td>
                  </tr>

                  {/* {this.state.stockStatus ? (
                    <>
                      <tr>
                        <th>Stok</th>
                        <td colSpan="4">{this.state.productStock + " Adet"}</td>
                      </tr>
                      <tr>
                        <th>Kritik Adet</th>
                        <td colSpan="4">
                          {this.state.criticalPieces + " Adet"}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )} */}

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description === null
                        ? "-"
                        : this.state.description}
                    </td>
                  </tr>

                  {/* <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr> */}

                  {/* <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr> */}
                </tbody>
              </Table>

              {/* {this.state.productImageDto &&
                this.state.productImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Dosya</th>
                    </tr>
                    <Row>
                      {this.state.productImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )} */}

              {/* {this.state.locationProductStock !== null &&
                this.state.locationProductStock.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th>Depo</th>
                      <th>Stok</th>
                    </tr>
                    {this.state.locationProductStock.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.locationName}</td>
                          <td>{item.piece + " Adet"}</td>
                        </tr>
                      );
                    })}
                  </Table>
                )}

              {this.state.machineProductPropertyDto !== null &&
                this.state.machineProductPropertyDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product table-product-2">
                    <tr style={mainRow}>
                      <th>Ürün Özellikleri Tipi</th>
                      <th>Ürün Özellikleri</th>
                    </tr>
                    {this.state.machineProductPropertyDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.propertyTypeName}</td>
                          <td>{item.value}</td>
                        </tr>
                      );
                    })}
                  </Table>
                )} */}

              {/* {this.state.machineProductShelfRowDto !== null &&
                this.state.machineProductShelfRowDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product table-product-2">
                    <tr style={mainRow}>
                      <th style={this.thStyle3}>Depo</th>
                      <th style={this.thStyle2}>Raf</th>
                      <th style={this.thStyle}>Sıra</th>
                    </tr>
                    {this.state.machineProductShelfRowDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td style={self1.tdStyle3}>{item.locationName}</td>
                          <td style={self1.tdStyle2}>{item.shelf}</td>
                          <td style={self1.tdStyle}>{item.row}</td>
                        </tr>
                      );
                    })}
                  </Table>
                )} */}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineProductLocationDetail;
