import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, optionStatuses } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnButton from "../toolbox/CgnButton";
import { confirmAlert } from "react-confirm-alert";

class LocationAddUpdate extends Component {
  state = {
    pageTitle: "Depo Ekle",
    pageTitleAdd: "Depo Ekle",
    pageTitleUpdate: "Depo Güncelle",
    pageAddLink: "depo-ekle",
    pageListLink: "depo-listele",
    breadcrumb: [{ text: "Depo", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    description: "",
    guid: "",
    status: true,
    nameError: "",
    descriptionError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    locationFullnessDtos: [],
    counter: -1,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/locations/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              name: response.data.name,
              description: response.data.description,
              status: response.data.status,
              locationFullnessDtos: response.data.locationFullnessDtos,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        name: "",
        description: "",
        status: true,
        locationFullnessDtos: [],
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      description: "",
      status: true,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handlePercentageTextBoxChange(e, idx) {
    let locationFullnessDtos = this.state.locationFullnessDtos;
    locationFullnessDtos[idx].percentage = parseFloat(e.target.value);
    if (locationFullnessDtos[idx].percentage && locationFullnessDtos[idx].percentage !== 0) {
      locationFullnessDtos[idx].percentageError = "";
    } else {
      locationFullnessDtos[idx].percentageError = CgnMessage.textErrorMessage;
    }
    this.setState({
      locationFullnessDtos: locationFullnessDtos,
    });
  }

  handleExplanationTextBoxChange(e, idx) {
    let locationFullnessDtos = this.state.locationFullnessDtos;
    locationFullnessDtos[idx].explanation = e.target.value;
    if (locationFullnessDtos[idx].explanation !== "") {
      locationFullnessDtos[idx].explanationError = "";
    } else {
      locationFullnessDtos[idx].explanationError = CgnMessage.textErrorMessage;
    }
    this.setState({
      locationFullnessDtos: locationFullnessDtos,
    });
  }

  async handleLocationFullnessRemove(idx) {
    let someArray = this.state.locationFullnessDtos;
    someArray.splice(idx, 1);
    await this.setState({
      locationFullnessDtos: someArray,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
        total: 0,
      });
    }
  }

  handleLocationFullnessAdd = async () => {
    let array = this.state.locationFullnessDtos;
    await array.push({
      percentage: 0,
      explanation: "",
      percentageError: CgnMessage.textErrorMessage,
      explanationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].percentage === 0) {
      return array[this.state.counter].percentageError === "";
    }
    if (array[this.state.counter].explanation === "") {
      return array[this.state.counter].explanationError === "";
    }
    if (
      array[this.state.counter].percentage === 0 ||
      array[this.state.counter].explanation === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ locationFullnessDtos: array });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
    });

    var control = true;
    if (this.state.locationFullnessDtos !== null || this.state.locationFullnessDtos.length > 0) {
      this.state.locationFullnessDtos.forEach((element) => {
        if (element.percentage === "" && element.percentage === 0) {
          control = false;
          this.setState({
            percentageError: CgnMessage.textErrorMessage,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
        if (element.explanation === "") {
          control = false;
          this.setState({
            explanationError: CgnMessage.textErrorMessage,
            alertErrorMessage: CgnMessage.alertErrorMessage,
          })
        }
      });
    }

    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.name !== "" && control !== false) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        name: this.state.name,
        description: this.state.description,
        status: this.state.status,
        locationFullnessDtos: this.state.locationFullnessDtos,
      };
      var URLParam = "/locations/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "depo-guncelle"
      ) {
        URLParam = "/locations/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          description: this.state.description,
          status: this.state.status,
          locationFullnessDtos: this.state.locationFullnessDtos,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            var modalAddedLocation = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedLocation });
            confirmAlert({
              title: CgnMessage.alertSuccessTitle,
              message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
              buttons: [
                {
                  label: CgnMessage.closeButton,
                  onClick: () => {
                    if (this.state.guid && this.state.urlSplit[1] === "depo-guncelle") {
                      window.location.href = "/depo-listele";
                    }
                    if (this.state.urlSplit[1] === "depo-ekle") {
                      window.location.reload();
                    }
                  }
                },
              ],
            });
          }, 1500);
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "150px",
    };

    return (
      <>
        {(this.state.urlSplit[1] === "depo-ekle" ||
          this.state.urlSplit[1] === "depo-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Depo [*]"
                      value={this.state.name}
                      placeHolder="Lütfen depo girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                {this.state.locationFullnessDtos.map((item, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="3" className="pl-0">
                      <CgnTextbox
                        type="number"
                        name="percentage"
                        label="Yüzde [*]"
                        value={item.percentage}
                        onChange={(e) =>
                          this.handlePercentageTextBoxChange(e, idx)
                        }
                        maxLength="100"
                        autoComplete="off"
                        error={item.percentageError}
                      />
                    </Col>
                    <Col lg="8" className="modalSelectCol">
                      <CgnTextbox
                        type="text"
                        name="explanation"
                        label="Açıklama [*]"
                        value={item.explanation}
                        onChange={(e) =>
                          this.handleExplanationTextBoxChange(e, idx)
                        }
                        maxLength="100"
                        autoComplete="off"
                        error={item.explanationError}
                      />
                    </Col>
                    <Col lg="1">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() => this.handleLocationFullnessRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col lg="12">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton mb-3"
                      onClick={this.handleLocationFullnessAdd}
                      text={"Doluluk Oranı Ekle"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "depo-ekle" ||
              this.state.urlSplit[1] === "depo-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(LocationAddUpdate);
