import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class MachineDetail extends Component {
  state = {
    pageTitle: "Makine Detay",
    pageTitleDetail: "Makine Detay",
    pageAddLink: "makine-ekle",
    pageListLink: "makine-listele",
    breadcrumb: [{ text: "Makine", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    canalName: "",
    brandName: "",
    modelName: "",
    machineSerialNo: "",
    customsEntryDate: "",
    refrigeratorModel: "",
    coolerSerialNo: "",
    vendonSerialNo: "",
    standModel: "",
    standDimensions: "",
    purifierBrand: "",
    name: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/machines/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            brandName: response.data.brandName,
            canalName: response.data.canalName,
            modelName: response.data.modelName,
            machineSerialNo: response.data.machineSerialNo,
            customsEntryDate: response.data.customsEntryDate,
            refrigeratorModel: response.data.refrigeratorModel,
            coolerSerialNo: response.data.coolerSerialNo,
            vendonSerialNo: response.data.vendonSerialNo,
            standModel: response.data.standModel,
            standDimensions: response.data.standDimensions,
            purifierBrand: response.data.purifierBrand,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Kanal</th>
                    <td colSpan="4">
                      {this.state.canalName
                        ? this.state.canalName
                        : "Kanal"}
                    </td>
                  </tr>

                  <tr>
                    <th>Marka</th>
                    <td colSpan="4">
                      {this.state.brandName
                        ? this.state.brandName
                        : "Marka"}
                    </td>
                  </tr>

                  <tr>
                    <th>Model</th>
                    <td colSpan="4">
                      {this.state.modelName ? this.state.modelName : "Model"}
                    </td>
                  </tr>

                  <tr>
                    <th>Gümrük Giriş Tarihi</th>
                    <td colSpan="4">
                      {this.state.customsEntryDate}
                    </td>
                  </tr>

                  <tr>
                    <th>Makine Seri No</th>
                    <td colSpan="4">
                      {this.state.machineSerialNo
                        ? this.state.machineSerialNo
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Buzdolabı Model</th>
                    <td colSpan="4">
                      {this.state.refrigeratorModel
                        ? this.state.refrigeratorModel
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Soğutucu Seri No</th>
                    <td colSpan="4">
                      {this.state.coolerSerialNo ? this.state.coolerSerialNo : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Vendon Seri No</th>
                    <td colSpan="4"> 
                    {this.state.vendonSerialNo ? this.state.vendonSerialNo : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Stand Model</th>
                    <td colSpan="4">
                      {this.state.standModel
                        ? this.state.standModel
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Stand Ebatı</th>
                    <td colSpan="4">
                      {this.state.standDimensions
                        ? this.state.standDimensions
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Artıma Cihazı Marka</th>
                    <td colSpan="4">
                      {this.state.purifierBrand ? this.state.purifierBrand : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>


                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineDetail;
