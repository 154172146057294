import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  TaxRateChoices,
  PaymentMethodType,
  CurrencyType,
  resizeFile,
  fotoStyle,
  
  UserOperationClaimControl,
  unitValuesStatuses,
  BillScenario,
  BillTypes,
  DeliveryType,
  TransportType,
  CabinType,
} from "../../redux/actions/constants";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImage from "../../images/no-img.png";
import BankAddUpdate from "../bank/BankAddUpdate";
import LocationAddUpdate from "../location/LocationAddUpdate";

class ProductEntrySecondAddUpdate extends Component {
  state = {
    pageTitle: "Alış Ekle",
    pageTitleAdd: "Alış Ekle",
    pageTitleUpdate: "Alış Güncelle",
    pageAddLink: "yeni-alis-ekle",
    pageListLink: "yeni-alis-listele",
    breadcrumb: [{ text: "Alış", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productEntryDate: "",
    supplierGuid: "",
    guid: "",
    costGuid: "",
    total: 0,
    generalTotal: null,
    // taxRateValue: "",
    // taxTypeValue: null,
    // discountPercent: "",
    // discountValue: "",
    discountTotal: "",
    currencyType: "",
    description: "",
    currencyTypeError: "",
    productDataError: "",
    isDiscountPercent: false,
    isDiscountValue: false,
    productGuid: "",
    locationGuid: "",
    status: true,
    receiptNo: "",
    expiry: null,
    paymentMethodValue: null,
    bankGuid: null,
    chequesBondNo: "",
    image: "",
    imageName: "",
    isOpenModalBank: false,
    isBankTrue: false,
    isChequesBondNoTrue: false,
    isHidden: true,
    productEntryDateError: "",
    supplierError: "",
    totalError: "",
    unitError: "",
    productTaxRate: "",
    productDiscountPercentError: "",
    productDiscountValueError: "",
    productDiscountPercent: "",
    generalTotal1: 0,
    generalTaxTotal1: 0,
    generalDiscountTotal1: 0,
    productDiscountValue: "",
    taxRateError: "",
    taxTypeError: "",
    alertErrorMessage: "",
    isDeleted: false,
    isTrue: false,
    isNull: false,
    receiptNoError: "",
    expiryError: "",
    paymentMethodTypeError: "",
    bankError: "",
    chequesBondNoError: "",
    isOpenModalSupplier: false,
    isOpenModalLocation: false,
    isOpenModalProduct: false,
    isOpenModalBrand: false,
    modalButtonIdx: 0,
    counter: -1,
    paymentCalendarData: [],
    productData: [],
    productEntryModal: false,
    multipleProductArr: [],
    istisnaError: "",
    istisnaArr: [],
    countryArr: [],
    isHiddenIstisna: true,
    isHiddenIhracat: true,
    cabinAddress: null,
    cabinCity: null,
    cabinDistrict: null,
    cabinCountry: null,
    cabinAddressError: "",
    cabinCityError: "",
    cabinDistrictError: "",
    cabinNoError: "",
    tevkifatArr: [],
    deliveryTypeError: "",
    cabinPiece: 1,
    cabinNo: null,
    deliveryType: null,
    transportTypeId: null,
    transportTypeError: "",
    cabinType: null,
    cabinTypeError: "",
    billTypeId: 0,
    billScenarioId: 0,
    billScenarioError: "",
    billTypeError: "",
    tevkifatRate: 0,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    supplierModalPlus: false,
    productModalPlus: false,
    paymentCalendarModalPlus: false,
    paymentModalPlus: false,
    bankModalPlus: false,
    locationModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      supplierModalPlus: await UserOperationClaimControl(1038),
      productModalPlus: await UserOperationClaimControl(1029),
      paymentCalendarModalPlus: await UserOperationClaimControl(1025),
      paymentModalPlus: await UserOperationClaimControl(1026),
      bankModalPlus: await UserOperationClaimControl(1003),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
            buyPrice: item.buyPrice,
            code: item.code,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/banks/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: [`${item.name} / ${item.iban}`],
          };
        });
        this.setState({
          bankArr: myArr,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/tevkifats/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.tevkifatId,
            label: item.tevkifatNo + " (" + item.tevkifatRate + ")",
            tevkifatRate: item.tevkifatRate,
          };
        });
        this.setState({
          tevkifatArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/istisnas/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.id,
            label:
              item.istisnaCode +
              " " +
              item.istisnaKDVK +
              " " +
              item.istisnaName,
          };
        });
        this.setState({
          istisnaArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/countries/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          countryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    if (urlSplit.length === 3 && urlSplit[1] === "yeni-alis-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/productEntriesSecond/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              productEntryDate: response.data.productEntryDate,
              billTypeId: response.data.billTypeId,
              billScenarioId: response.data.billScenarioId,
              costGuid: response.data.costGuid,
              supplierGuid: response.data.supplierGuid,
              productData: response.data.productEntryProductSecondDto,
              discountTotal: response.data.total,
              generalTotal: response.data.generalTotal,
              paymentCalendarData: response.data.paymentCalendarDto,
              currencyType: response.data.currencyType,
              description: response.data.description,
              receiptNo: response.data.receiptNo,
              expiry: response.data.expiry,
              paymentMethodValue: response.data.paymentMethodType,
              bankGuid: response.data.bankGuid,
              chequesBondNo: response.data.chequesBondNo,
              image: response.data.image,
              status: response.data.status,
              isLoading: false,
              generalDiscountTotal: response.data.generalDiscountTotal,
              generalTaxTotal: response.data.generalTaxTotal,
              isHiddenIstisna: response.data.billTypeId === 4 ? false : true,
              cabinAddress: response.data.cabinAddress,
              cabinCity: response.data.cabinCity,
              cabinDistrict: response.data.cabinDistrict,
              cabinPiece: response.data.cabinPiece,
              cabinNo: response.data.cabinNo,
              deliveryType: response.data.deliveryType,
              transportTypeId: response.data.transportType,
              cabinCountry: response.data.cabinCountryGuid,
              isHiddenIhracat:
                response.data.billScenarioId === 3 ? false : true,
              cabinType: response.data.cabinType,
              counter: response.data.productEntryProductSecondDto.length - 1,
            });

            let productData = this.state.productData;
            productData.forEach((item) => {
              if (item.deliveryDate === "01/01/1900") {
                item.deliveryDate = null;
              }
            });
            this.setState({
              productData: productData,
            });
          }
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        productEntryDate: "",
        supplierGuid: "",
        total: 0,
        taxTypeValue: null,
        taxRateValue: "",
        discountPercent: "",
        discountValue: "",
        discountTotal: "",
        currencyType: "",
        paymentCalendarData: [],
        isDiscountPercent: false,
        isDiscountValue: false,
        description: "",
        receiptNo: "",
        expiry: "",
        paymentMethodValue: null,
        bankGuid: null,
        chequesBondNo: "",
        image: "",
        imageName: "",
        status: true,
        isHidden: true,
        counter: -1,
      });
    }
    if (
      this.state.isOpenModalSupplier &&
      this.props.location.modalAddedSupplier !== undefined &&
      this.props.location.modalAddedSupplier.isModal !== undefined &&
      this.props.location.modalAddedSupplier.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/suppliers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            supplierArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSupplier.data &&
              element.name === this.props.location.modalAddedSupplier.data
            ) {
              this.setState({
                supplierGuid: element.guid,
                supplierError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSupplier.isModal = true;
      this.setState({
        isOpenModalSupplier: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
              buyPrice: item.buyPrice,
              code: item.code,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].productGuid = element.guid;
              productData[this.state.modalButtonIdx].productError = "";
            }
          });
          if (this.props.location.modalAddedProduct.buyPrice) {
            productData[this.state.modalButtonIdx].unitPrice = parseFloat(
              this.props.location.modalAddedProduct.buyPrice
            );
            productData[this.state.modalButtonIdx].piece = 1;
            productData[this.state.modalButtonIdx].unit = "";
            productData[this.state.modalButtonIdx].productTaxRate = "";
            productData[this.state.modalButtonIdx].productDiscountPercent = "";
            productData[this.state.modalButtonIdx].productDiscountValue = "";
            productData[this.state.modalButtonIdx].productUnitPriceError = "";
            productData[this.state.modalButtonIdx].productPieceError = "";
            //productData[this.state.modalButtonIdx].unitError = "";
          }
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }

    if (
      this.state.isOpenModalBank &&
      this.props.location.modalAddedBank !== undefined &&
      this.props.location.modalAddedBank.isModal !== undefined &&
      this.props.location.modalAddedBank.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/banks/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            bankArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBank.data &&
              element.name === this.props.location.modalAddedBank.data
            ) {
              this.setState({
                bankGuid: element.guid,
                bankError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBank.isModal = true;
      this.setState({
        isOpenModalBank: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].locationGuid =
                element.guid;
              productData[this.state.modalButtonIdx].locationError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleBillTypeSelectChange(option) {
    if (option) {
      this.setState({
        billTypeId: option.value,
        billTypeError: "",
      });

      if (option.value === 4) {
        this.setState({
          isHiddenIstisna: false,
        });
      } else {
        this.setState({
          isHiddenIstisna: true,
        });
      }
    } else {
      this.setState({
        billTypeId: 0,
        isHiddenIstisna: true,
        billTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleBillScenarioSelectChange(option) {
    if (option) {
      this.setState({
        billScenarioId: option.value,
        billScenarioError: "",
      });

      if (option.value === 3) {
        this.setState({
          isHiddenIhracat: false,
        });
      } else {
        this.setState({
          isHiddenIhracat: true,
          cabinAddress: null,
          cabinNo: null,
          cabinPiece: 1,
          deliveryType: null,
          transportType: null,
          cabinType: null,
          cabinDistrict: null,
          cabinCity: null,
          cabinCountry: null,
        });
      }
    } else {
      this.setState({
        billScenarioId: 0,
        cabinAddress: null,
        cabinNo: null,
        cabinPiece: 1,
        deliveryType: null,
        transportType: null,
        cabinType: null,
        cabinDistrict: null,
        cabinCity: null,
        cabinCountry: null,
        billScenarioError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleDeliveryTypeSelectChange(option) {
    if (option) {
      this.setState({
        deliveryType: option.value,
        deliveryTypeError: "",
      });
    } else {
      this.setState({
        deliveryType: null,
        deliveryTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleCabinTypeSelectChange(option) {
    if (option) {
      this.setState({
        cabinType: option.value,
        cabinTypeError: "",
      });
    } else {
      this.setState({
        cabinType: null,
        cabinTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleTransportTypeSelectChange(option) {
    if (option) {
      this.setState({
        transportTypeId: option.value,
        transportTypeError: "",
      });
    } else {
      this.setState({
        transportTypeId: null,
        transportTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  async handleCabinCountrySelectChange(option) {
    if (option) {
      await this.setState({
        cabinCountry: option.value,
        cabinCountryError: "",
      });
    } else {
      await this.setState({
        cabinCountry: null,
        cabinCountryError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleDateChange(productEntryDate) {
    if (productEntryDate) {
      this.setState({
        productEntryDate: productEntryDate,
        productEntryDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productEntryDate: "",
        productEntryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePaymentMethodSelectChange(option) {
    if (option) {
      this.setState({
        paymentMethodValue: option.value,
        paymentMethodTypeError: "",
        alertErrorMessage: "",
      });
      if (option.value === 1 || option.value === 5) {
        this.setState({
          bankGuid: null,
          bankError: "",
          isBankTrue: true,
          isChequesBondNoTrue: false,
        });
      }
      if (option.value === 2 || option.value === 3) {
        this.setState({
          chequesBondNo: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: false,
        });
      }
      if (option.value === 4) {
        this.setState({
          bankGuid: null,
          chequesBondNo: "",
          bankError: "",
          chequesBondNoError: "",
          isChequesBondNoTrue: true,
          isBankTrue: true,
        });
      }
    } else if (!this.state.isHidden) {
      this.setState({
        paymentMethodValue: null,
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        bankError: "",
        chequesBondNoError: "",
        bankGuid: null,
        chequesBondNo: "",
        isBankTrue: false,
        isChequesBondNoTrue: false,
      });
    }
  }

  handleBankSelectChange(option) {
    if (option) {
      this.setState({
        bankGuid: option.value,
        bankError: "",
        alertErrorMessage: "",
      });
    } else if (this.state.isHidden) {
      this.setState({
        bankGuid: null,
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  addModalBank = () => {
    this.setState({
      isOpenModalBank: !this.state.isOpenModalBank,
    });
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHidden: false,
      });
    } else {
      this.setState({
        isHidden: true,
      });
    }
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      supplierError: "",
      totalError: "",
      descriptionError: "",
      taxRateError: "",
      taxTypeError: "",
      paymentMethodTypeError: "",
      bankError: "",
      chequesBondNoError: "",
      currencyTypeError: "",
      alertErrorMessage: "",
    });
    if (name === "supplierGuid" && value === "") {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  TaxCalculate = async () => {
    if (
      this.state.taxTypeValue === 1 &&
      this.state.total &&
      this.state.total !== 0 &&
      this.state.taxRateValue
    ) {
      var generalTotal = (
        this.state.total *
        (1 + parseInt(this.state.taxRateValue) / 100)
      ).toFixed(2);
      if (this.state.generalTotal !== generalTotal) {
        await this.setState({
          generalTotal: generalTotal,
        });
      }
    }
    if (
      this.state.taxTypeValue === 2 &&
      this.state.total &&
      this.state.taxRateValue
    ) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total && this.state.taxTypeValue === 3) {
      if (this.state.generalTotal !== this.state.total) {
        await this.setState({
          generalTotal: this.state.total,
        });
      }
    }
    if (this.state.total === 0) {
      if (
        this.state.generalTotal &&
        this.state.generalTotal !== this.state.total
      ) {
        await this.setState({
          generalTotal: 0,
        });
      }
    }
  };

  async handleProductTaxRateSelectChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productTaxRate = option.value;
      productData[idx].productTaxRateError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productTaxRate = "";
      productData[idx].productTaxRateError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleSupplierSelectChange(option) {
    if (option) {
      this.setState({
        supplierGuid: option.value,
        supplierError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        supplierGuid: "",
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleMultipleProductAdd(item.value);
        }
      });
    });
  };

  handleMultipleProductAdd = async (value) => {
    let productData = this.state.productData;
    await productData.push({
      productGuid: value,
      locationGuid: "",
      piece: 1,
      unitPrice: 1,
      total: 1,
      tevkifatRate: 0,
      tevkifatTotal: 0,
      unit: "NIU",
      productDiscountPercent: 0,
      productDiscountValue: 0,
      productDiscountTotal: 0,
      productTaxRate: null,
      deliveryDate: null,
      requestItemNo: "",
      requestNo: "",
      workOrderNo: "",
      notes: "",
      locationError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (
      productData[this.state.counter].productGuid === "" ||
      productData[this.state.counter].piece === 0 ||
      productData[this.state.counter].unitPrice === 0 ||
      productData[this.state.counter].locationGuid === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: productData });
  };

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  addModalProduct = async (idx) => {
    await this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
      modalButtonIdx: idx,
    });
  };

  addModalLocation = async (idx) => {
    await this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
      modalButtonIdx: idx,
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    var arrayPaymentCalendar = this.state.paymentCalendarData;
    arrayPaymentCalendar.forEach((item) => {
      item.targetPaymentDate = "";
      item.targetTotal = "";
      item.targetPaymentMethodType = null;
      item.targetPaymentDateError = CgnMessage.textErrorMessage;
      item.targetTotalError = CgnMessage.textErrorMessage;
      item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
      item.targetCurrencyType = CgnMessage.textErrorMessage;
      item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
    });
    var productData = this.state.productData;
    productData.forEach((item) => {
      item.productGuid = null;
      item.locationGuid = null;
      item.piece = "";
      item.unitPrice = "";
      item.total = 0;

      item.productError = CgnMessage.textErrorMessage;
      item.locationError = CgnMessage.textErrorMessage;
      item.productPieceError = CgnMessage.textErrorMessage;
      item.productUnitPriceError = CgnMessage.textErrorMessage;
    });
    this.setState({
      productEntryDate: "",
      supplierGuid: "",
      total: "",
      taxRateValue: "",
      taxTypeValue: null,
      generalTotal: "",
      discountPercent: "",
      discountValue: "",
      discountTotal: "",
      currencyType: "",
      isDiscountPercent: false,
      isDiscountValue: false,
      description: "",
      status: true,
      receiptNo: "",
      expiry: "",
      paymentMethodValue: null,
      bankGuid: null,
      chequesBondNo: "",
      image: "",
      imageName: "",
      isBankTrue: false,
      isChequesBondNoTrue: false,
      isHidden: true,
      counter: -1,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      productEntryDateError: CgnMessage.textErrorMessage,
      supplierError: CgnMessage.textErrorMessage,
      totalError: CgnMessage.textErrorMessage,
      taxRateError: CgnMessage.textErrorMessage,
      taxTypeError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      productDataError: CgnMessage.textErrorMessage,
    });
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      productGuid: "",
      locationGuid: "",
      piece: 1,
      unitPrice: 0,
      taxTotal: 0,
      productDiscountTotal: 0,
      unit: "",
      productDiscountPercent: "",
      productDiscountValue: "",
      total: 0,
      discountTotal: 0,
      deliveryDate: null,
      notes: "",
      requestNo: "",
      requestItemNo: "",
      workOrderNo: "",
      tevkifatRate: 0,
      tevkifatTotal: 0,
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
      productTaxRateError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].productGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (array[this.state.counter].unit) {
      return array[this.state.counter].unitError === "";
    }
    if (array[this.state.counter].productTaxRate) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountPercent) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (array[this.state.counter].productDiscountValue) {
      return array[this.state.counter].productTaxRateError === "";
    }
    if (
      array[this.state.counter].productGuid === "" ||
      array[this.state.counter].locationGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].unit === "" ||
      array[this.state.counter].productTaxRate === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };

  handleProductPieceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].piece = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleCabinPieceTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinPiece: e.target.value,
        cabinPieceError: "",
      });
    } else {
      this.setState({
        cabinPiece: 1,
        cabinPieceError: "",
      });
    }
  }

  handleCabinNoTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinNo: e.target.value,
        cabinNoError: "",
      });
    } else {
      this.setState({
        cabinPiece: null,
        cabinNoError: "",
      });
    }
  }

  handleDeliveryDateChange(deliveryDate, idx) {
    let productData = this.state.productData;
    productData[idx].deliveryDate = deliveryDate;
    this.setState({
      productData: productData,
    });
  }

  handleRequestNoChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].requestNo = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleRequestItemNoChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].requestItemNo = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleWorkOrderNoChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].workOrderNo = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleNotesChange(event, idx) {
    let productData = this.state.productData;
    productData[idx].notes = event.target.value;
    this.setState({
      productData: productData,
    });
  }

  handleDeliveryAddressTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinAddress: e.target.value,
        cabinAddressError: "",
      });
    } else {
      this.setState({
        cabinAddress: null,
        cabinAddressError: "",
      });
    }
  }

  handleDeliveryCityTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinCity: e.target.value,
        cabinCityError: "",
      });
    } else {
      this.setState({
        cabinCity: null,
        cabinCityError: "",
      });
    }
  }

  handleDeliveryDistrictTextBoxChange(e) {
    if (e) {
      this.setState({
        cabinDistrict: e.target.value,
        cabinDistrictError: "",
      });
    } else {
      this.setState({
        cabinDistrict: null,
        cabinDistrictError: "",
      });
    }
  }

  handleProductDiscountPercentChange(e, idx) {
    let productData = this.state.productData;
    if (e.target.value !== "") {
      productData[idx].productDiscountPercent = parseFloat(e.target.value);
    } else {
      productData[idx].productDiscountPercent = "";
    }
    this.setState({
      productData: productData,
    });

    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductDiscountValueChange(e, idx) {
    let productData = this.state.productData;
    if (e.target.value !== "") {
      productData[idx].productDiscountValue = parseFloat(e.target.value);
    } else {
      productData[idx].productDiscountValue = "";
    }
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleTevkifatChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].tevkifatId = option.value;
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].tevkifatId = 0;
      productData[idx].tevkifatTotal = 0;
      this.setState({
        productData: productData,
      });
    }
    this.ProductPriceCalculate(idx);
  }

  handleIstisnaChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].istisnaId = option.value;
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].istisnaId = 0;
      this.setState({
        productData: productData,
      });
    }
  }

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productData = this.state.productData;
    productData[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productData: productData,
    });
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleUnitChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].unit = option.value;
      productData[idx].unitError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].unit = "";
      productData[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleProductInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].productGuid = option.value;
      productData[idx].piece = 1;
      productData[idx].unitPrice = option.buyPrice;
      productData[idx].productError = "";
      productData[idx].productPieceError = "";
      productData[idx].productUnitPriceError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].productGuid = null;
      productData[idx].piece = 0;
      productData[idx].unitPrice = 0;
      productData[idx].productError = CgnMessage.textErrorMessage;
      productData[idx].productPieceError = CgnMessage.textErrorMessage;
      productData[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  handleLocationInputValueChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].locationGuid = option.value;
      productData[idx].locationError = "";
      this.setState({
        productData: productData,
      });
    } else {
      productData[idx].locationGuid = "";
      productData[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productData: productData,
      });
    }
    this.productRequiredField();
    this.ProductPriceCalculate(idx);
  }

  async ProductPriceCalculate(idx) {
    var totalProductPrice = 0.0;
    var totalProductTotal = 0.0;
    var itemTotal = 0.0;
    var percent = 0.0;
    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;
    var taxTotal = 0;
    var tevkifatTotal = 0;
    var tevkifatRate = 0;

    let productData = this.state.productData;

    var discountPercent = parseFloat(productData[idx].productDiscountPercent);

    var discountValue = parseFloat(productData[idx].productDiscountValue);

    itemTotal = (
      parseFloat(productData[idx].piece) *
      parseFloat(productData[idx].unitPrice)
    ).toFixed(2);

    totalProductPrice += parseFloat(itemTotal);

    if (productData[idx].tevkifatId !== undefined) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      this.setState({
        isLoading: true,
      });
      await axios
        .get(URL + "/tevkifats/get/" + productData[idx].tevkifatId, config)
        .then((response) => {
          tevkifatRate = response.data.tevkifatRate;
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }

    if (discountPercent > 0) {
      percent = parseFloat(totalProductPrice) * (discountPercent / 100);
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - parseFloat(percent);
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);
      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );
      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(taxTotal);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        productData[idx].total =
          parseFloat(totalProductTotal) + parseFloat(productData[idx].taxTotal);
      }
    } else if (discountValue > 0) {
      percent = discountValue;
      productData[idx].productDiscountTotal = percent;
      totalProductTotal = parseFloat(totalProductPrice) - discountValue;
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);

      taxTotal = parseFloat(
        totalProductTotal * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);
      productData[idx].total =
        parseFloat(totalProductTotal) + parseFloat(taxTotal);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        productData[idx].total =
          parseFloat(totalProductTotal) + parseFloat(productData[idx].taxTotal);
      }
    } else {
      productData[idx].productDiscountTotal = 0;
      var newProductTaxRate = parseInt(productData[idx].productTaxRate);

      taxTotal = parseFloat(
        totalProductPrice * parseFloat(newProductTaxRate / 100)
      );

      productData[idx].taxTotal = parseFloat(taxTotal);

      totalProductTotal = parseFloat(taxTotal) + parseFloat(totalProductPrice);
      productData[idx].total = parseFloat(totalProductTotal).toFixed(2);

      if (tevkifatRate !== undefined) {
        tevkifatTotal =
          parseFloat(productData[idx].taxTotal) * parseFloat(tevkifatRate);
        productData[idx].taxTotal =
          parseFloat(productData[idx].taxTotal) - parseFloat(tevkifatTotal);
        productData[idx].tevkifatTotal = parseFloat(tevkifatTotal).toFixed(2);

        totalProductTotal =
          parseFloat(productData[idx].taxTotal) + parseFloat(totalProductPrice);
        productData[idx].total = parseFloat(totalProductTotal).toFixed(2);
      }
    }

    this.setState({
      productData: productData,
    });
    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      discountTotal: parseFloat(discountTotal1).toFixed(2),
    });
  }

  productRequiredField = () => {
    var arr = this.state.productData;
    arr.forEach((item) => {
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productError = "";
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        !item.productGuid &&
        item.piece &&
        !item.unitPrice &&
        item.locationGuid
      ) {
        item.productError = CgnMessage.textErrorMessage;
        item.ProductUnitPriceError = CgnMessage.textErrorMessage;
        item.productPieceError = "";
        item.locationError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (
        item.productGuid &&
        item.piece &&
        item.unitPrice &&
        item.locationGuid
      ) {
        item.locationError = "";
        item.productError = "";
        item.productPieceError = "";
        item.productUnitPriceError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
      if (
        !item.productGuid &&
        !item.piece &&
        !item.unitPrice &&
        !item.locationGuid
      ) {
        item.locationError = CgnMessage.textErrorMessage;
        item.productError = CgnMessage.textErrorMessage;
        item.productPieceError = CgnMessage.textErrorMessage;
        item.productUnitPriceError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
    });
  };

  async handleProductRemove(idx) {
    let someArray = await this.state.productData;
    await someArray.splice(idx, 1);

    await this.setState({
      productData: someArray,
      counter: this.state.counter - 1,
    });
    var generalTotal1 = 0;
    var generalTaxTotal1 = 0;
    var generalDiscountTotal1 = 0;
    var discountTotal1 = 0;

    await this.state.productData.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice * parseFloat(item.piece));
      generalTaxTotal1 += parseFloat(item.taxTotal);
      generalDiscountTotal1 += parseFloat(item.productDiscountTotal);
      discountTotal1 += parseFloat(item.total);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
      generalTaxTotal: parseFloat(generalTaxTotal1).toFixed(2),
      generalDiscountTotal: parseFloat(generalDiscountTotal1).toFixed(2),
      discountTotal: parseFloat(discountTotal1).toFixed(2),
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handlePaymentCalendarAdd = async () => {
    let array = this.state.paymentCalendarData;
    await array.push({
      targetPaymentDate: "",
      targetTotal: null,
      targetPaymentMethodType: null,
      targetCurrencyType: null,
      targetPaymentDateError: "",
      targetTotalError: "",
      targetPaymentMethodTypeError: "",
      targetCurrencyTypeError: "",
    });
    array.forEach((item) => {
      if (
        array.length > 0 &&
        (!item.targetPaymentDate ||
          !item.targetTotal ||
          !item.targetPaymentMethodType ||
          !item.targetCurrencyType)
      ) {
        item.targetPaymentDateError = CgnMessage.textErrorMessage;
        item.targetTotalError = CgnMessage.textErrorMessage;
        item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
        item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        this.setState({
          isNull: true,
          alertErrorMessage: CgnMessage.alertErrorMessage,
        });
      }
    });
    this.setState({ paymentCalendarData: array });
  };

  handleTextBoxChange(e, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    paymentCalendarData[idx].targetTotal = parseFloat(e.target.value);
    this.setState({
      paymentCalendarData: paymentCalendarData,
    });
    this.paymentCalendarRequiredField();
  }

  handleInputValueChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetPaymentMethodType = option.value;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetPaymentMethodType = null;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  handleTargetPaymentDateChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetPaymentDate = option;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetPaymentDate = "";
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  handleTargetCurrencyTypeChange(option, idx) {
    let paymentCalendarData = this.state.paymentCalendarData;
    if (option) {
      paymentCalendarData[idx].targetCurrencyType = option.value;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    } else {
      paymentCalendarData[idx].targetCurrencyType = null;
      this.setState({
        paymentCalendarData: paymentCalendarData,
      });
    }
    this.paymentCalendarRequiredField();
  }

  paymentCalendarRequiredField = () => {
    var isAlert = false;
    var arr = this.state.paymentCalendarData;
    arr.forEach((item) => {
      item.targetPaymentDateError = "";
      item.targetTotalError = "";
      item.targetPaymentMethodTypeError = "";
      item.targetCurrencyTypeError = "";

      if (!item.targetPaymentDate) {
        item.targetPaymentDateError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetTotal) {
        item.targetTotalError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetPaymentMethodType) {
        item.targetPaymentMethodTypeError = CgnMessage.textErrorMessage;
        isAlert = true;
        this.setState({
          isNull: true,
        });
      }

      if (!item.targetCurrencyType) {
        item.targetCurrencyType = this.state.currencyType;
        // item.targetCurrencyTypeError = CgnMessage.textErrorMessage;
        // isAlert = true;
        // this.setState({
        //   isNull: true,
        // });
      }
    });
    if (isAlert) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else {
      this.setState({
        isNull: false,
      });
    }
  };

  handlePaymentCalendarRemove(idx) {
    let someArray = this.state.paymentCalendarData;
    someArray.splice(idx, 1);
    this.setState({ paymentCalendarData: someArray });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      productEntryDateError: "",
      supplierError: "",
      totalError: "",
      descriptionError: "",
      currencyTypeError: "",
      productDataError: "",
      alertErrorMessage: "",
    });
    if (this.state.productEntryDate === "") {
      this.setState({
        productEntryDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.supplierGuid === "") {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.productData || this.state.productData.length === 0) {
      this.setState({
        productDataError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (!this.state.isHidden && this.state.paymentMethodValue === null) {
      this.setState({
        paymentMethodTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    var control1 = true;
    if (this.state.productData !== null || this.state.productData.length > 0) {
      this.state.productData.forEach((element) => {
        if (element.unit === "" || element.productTaxRate === null) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
          if (element.unit === "") {
            this.setState({
              unitError: CgnMessage.textErrorMessage,
            });
          }
          if (element.productTaxRate === null) {
            this.setState({
              productTaxRateError: CgnMessage.textErrorMessage,
            });
          }
        }
        if (this.state.isHiddenIstisna === false) {
          if (element.istisnaId === undefined || element.istisnaId === 0) {
            control = false;
            this.setState({
              istisnaError: CgnMessage.alertErrorMessage,
            });
          }
        }
      });
    }
    if (this.state.billScenarioId === 3) {
      if (this.state.deliveryType === null) {
        control1 = false;
        this.setState({
          deliveryTypeError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.transportTypeId === null) {
        control1 = false;
        this.setState({
          transportTypeError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinType === null) {
        control1 = false;
        this.setState({
          cabinTypeError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinPiece === 0 || this.state.cabinPiece === null) {
        control1 = false;
        this.setState({
          cabinPieceError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinNo === null) {
        control1 = false;
        this.setState({
          cabinNoError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinAddress === null) {
        control1 = false;
        this.setState({
          cabinAddressError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinDistrict === null) {
        control1 = false;
        this.setState({
          cabinDistrictError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinCity === null) {
        control1 = false;
        this.setState({
          cabinCityError: CgnMessage.alertErrorMessage,
        });
      }
      if (this.state.cabinCountry === null) {
        control1 = false;
        this.setState({
          cabinCountryError: CgnMessage.alertErrorMessage,
        });
      }
    }
    if (this.state.discountTotal === "" || this.state.discountTotal === 0) {
      this.setState({
        totalError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.bankGuid === null &&
      (this.state.paymentMethodValue === 2 ||
        this.state.paymentMethodValue === 3)
    ) {
      this.setState({
        bankError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      !this.state.isHidden &&
      this.state.chequesBondNo === "" &&
      (this.state.paymentMethodValue === 1 ||
        this.state.paymentMethodValue === 5)
    ) {
      this.setState({
        chequesBondNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.productEntryDate !== "" &&
      this.state.supplierGuid !== "" &&
      this.state.discountTotal &&
      this.state.currencyType &&
      this.state.productData &&
      this.state.productData.length > 0 &&
      this.state.isNull === false &&
      (!this.state.isHidden
        ? this.state.paymentMethodValue !== "" &&
          ((this.state.bankGuid !== null &&
            (this.state.paymentMethodValue === 2 ||
              this.state.paymentMethodValue === 3)) ||
            (this.state.chequesBondNo !== "" &&
              (this.state.paymentMethodValue === 1 ||
                this.state.paymentMethodValue === 5)) ||
            (this.state.chequesBondNo === "" &&
              this.state.bankGuid === null &&
              this.state.paymentMethodValue === 4))
        : true) &&
      this.state.discountTotal > 0 &&
      control === true &&
      control1 === true
    ) {
      this.setState({
        isLoading: true,
      });
      var productData = [];
      this.state.productData.forEach((element) => {
        productData.push({
          guid: element.guid,
          locationGuid: element.locationGuid,
          productGuid: element.productGuid,
          piece: element.piece,
          unitPrice: element.unitPrice,
          unit: element.unit,
          productDiscountPercent:
            element.productDiscountPercent !== ""
              ? parseFloat(element.productDiscountPercent)
              : 0,
          productDiscountValue:
            element.productDiscountValue !== ""
              ? parseFloat(element.productDiscountValue)
              : 0,
          productTaxRate: element.productTaxRate,
          total: parseFloat(element.total),
          tevkifatId: element.tevkifatId !== undefined ? element.tevkifatId : 0,
          istisnaId: element.istisnaId !== undefined ? element.istisnaId : 0,
          tevkifatTotal: parseFloat(element.tevkifatTotal),
          deliveryDate:
            element.deliveryDate !== null ? element.deliveryDate : null,
          requestNo: element.requestNo,
          requestItemNo: element.requestItemNo,
          workOrderNo: element.workOrderNo,
          notes: element.notes,
        });
      });

      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        productEntryDate: this.state.productEntryDate,
        supplierGuid: this.state.supplierGuid,
        total: parseFloat(this.state.discountTotal),
        generalTotal: parseFloat(this.state.generalTotal),
        currencyType: this.state.currencyType,
        description: this.state.description,
        status: true, //this.state.status,
        paymentCalendarDto: this.state.paymentCalendarData,
        billTypeId: this.state.billTypeId,
        billScenarioId: this.state.billScenarioId,
        receiptNo: this.state.receiptNo,
        expiry: this.state.expiry ? parseInt(this.state.expiry) : 0,
        generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
        generalTaxTotal: parseFloat(this.state.generalTaxTotal),
        paymentMethodType: this.state.paymentMethodValue
          ? parseInt(this.state.paymentMethodValue)
          : 0,
        bankGuid: this.state.bankGuid,
        chequesBondNo: this.state.chequesBondNo,
        image: this.state.image,
        productEntryProductSecondDto: productData,
        isHidden: this.state.isHidden,
        deliveryType: this.state.deliveryType,
        transportType:
          this.state.transportTypeId === null ? 0 : this.state.transportTypeId,
        cabinType: this.state.cabinType,
        cabinPiece: this.state.cabinPiece,
        cabinNo: this.state.cabinNo,
        cabinAddress: this.state.cabinAddress,
        cabinDistrict: this.state.cabinDistrict,
        cabinCity: this.state.cabinCity,
        cabinCountryGuid:
          this.state.cabinCountry === null
            ? "00000000-0000-0000-0000-000000000000"
            : this.state.cabinCountry,
      };
      var URLParam = "/productentriesSecond/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/productentriesSecond/update";
        myObj = {
          guid: this.state.guid,
          productEntryDate: this.state.productEntryDate,
          costGuid: this.state.costGuid,
          supplierGuid: this.state.supplierGuid,
          billTypeId: this.state.billTypeId,
          billScenarioId: this.state.billScenarioId,
          total: parseFloat(this.state.discountTotal),
          generalTotal: parseFloat(this.state.generalTotal),
          currencyType: this.state.currencyType,
          description: this.state.description,
          status: true, //this.state.status,
          paymentCalendarDto: this.state.paymentCalendarData,
          productEntryProductSecondDto: productData,
          generalDiscountTotal: parseFloat(this.state.generalDiscountTotal),
          generalTaxTotal: parseFloat(this.state.generalTaxTotal),
          isHidden: this.state.isHidden,
          deliveryType: this.state.deliveryType,
          transportType:
            this.state.transportTypeId === null
              ? 0
              : this.state.transportTypeId,
          cabinType: this.state.cabinType,
          cabinPiece: parseInt(this.state.cabinPiece),
          cabinNo: this.state.cabinNo,
          cabinAddress: this.state.cabinAddress,
          cabinDistrict: this.state.cabinDistrict,
          cabinCity: this.state.cabinCity,
          cabinCountryGuid:
            this.state.cabinCountry === null
              ? "00000000-0000-0000-0000-000000000000"
              : this.state.cabinCountry,
        };
      }

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/yeni-alis-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  toggle = () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="productEntryAddUpdate">
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="productEntryDate"
                      label="Tarih [*]"
                      selected={
                        this.state.productEntryDate &&
                        this.state.productEntryDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.productEntryDate.split("/")[2] +
                                "-" +
                                this.state.productEntryDate.split("/")[1] +
                                "-" +
                                this.state.productEntryDate.split("/")[0]
                            )
                          : this.state.productEntryDate
                      }
                      onChange={(productEntryDate) =>
                        this.handleDateChange(productEntryDate)
                      }
                      error={this.state.productEntryDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="supplierGuid"
                          label="Tedarikçi [*]"
                          placeholder="Tedarikçi seçiniz..."
                          selectValue={this.state.supplierGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSupplierSelectChange(option)
                          }
                          options={this.state.supplierArr}
                          error={this.state.supplierError}
                        />
                      </div>
                      {this.state.supplierModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalSupplier()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="billTypeId"
                      label="Fatura Tipi"
                      placeholder="Fatura Tipi seçiniz..."
                      selectValue={this.state.billTypeId}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleBillTypeSelectChange(option)
                      }
                      options={BillTypes}
                      error={this.state.billTypeError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="billScenarioId"
                      label="Fatura Senaryo"
                      placeholder="Fatura Senaryo seçiniz..."
                      selectValue={this.state.billScenarioId}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleBillScenarioSelectChange(option)
                      }
                      options={BillScenario}
                      error={this.state.billScenarioError}
                    />
                  </Col>
                </Row>

                {this.state.productData &&
                  this.state.productData.length > 0 &&
                  this.state.productData.map((productData, idx) => (
                    <>
                      <Row className="mobileBorder">
                        <Col className="modalSelectCol pl-0">
                          <div className="flex-container">
                            <div className="p-0 flexInput">
                              <CgnReactSelect
                                name="locationGuid"
                                label="Depo [*]"
                                placeholder="Lütfen depo seçiniz..."
                                selectValue={productData.locationGuid}
                                isMulti={false}
                                isClearable={true}
                                onChange={(option) => {
                                  this.handleLocationInputValueChange(
                                    option,
                                    idx
                                  );
                                }}
                                options={this.state.locationArr}
                                error={productData.locationError}
                              />
                            </div>
                            {this.state.locationModalPlus && (
                              <div className="p-0 flexAddButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalLocation(idx)}
                                  text={"+"}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col className="modalSelectCol">
                          <div className="flex-container">
                            <div className="p-0 flexInput">
                              <CgnReactSelect
                                name="productGuid"
                                label="Ürün [*]"
                                placeholder="Lütfen ürün seçiniz..."
                                selectValue={productData.productGuid}
                                isMulti={false}
                                isClearable={true}
                                onChange={(option) => {
                                  this.handleProductInputValueChange(
                                    option,
                                    idx
                                  );
                                }}
                                options={this.state.productArr}
                                error={productData.productError}
                              />
                            </div>
                            {this.state.productModalPlus && (
                              <div className="p-0 flexAddButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalProduct(idx)}
                                  text={"+"}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="piece"
                            label="Miktar [*]"
                            value={productData.piece}
                            onChange={(e) =>
                              this.handleProductPieceTextBoxChange(e, idx)
                            }
                            placeHolder="Lütfen miktar girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.productPieceError}
                            step="0.1"
                            min={0}
                          />
                        </Col>

                        <Col>
                          <CgnReactSelect
                            name="unit"
                            label="Birim [*]"
                            placeholder="Birim"
                            selectValue={productData.unit}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleUnitChange(option, idx)
                            }
                            options={unitValuesStatuses}
                            error={productData.unitError}
                          />
                        </Col>

                        <Col>
                          <CgnTextbox
                            type="number"
                            name="unitPrice"
                            label="Birim Fiyat [*]"
                            value={productData.unitPrice}
                            onChange={(e) =>
                              this.handleProductUnitPriceTextBoxChange(e, idx)
                            }
                            placeHolder="Lütfen birim fiyat girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={productData.productUnitPriceError}
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="productDiscountPercent"
                            label="İskonto(Yüzde)"
                            value={productData.productDiscountPercent}
                            placeHolder="İskonto"
                            maxLength="50"
                            autoComplete="off"
                            readOnly={this.state.isProductDiscountPercent}
                            onChange={(e) =>
                              this.handleProductDiscountPercentChange(e, idx)
                            }
                            error={productData.productDiscountPercentError}
                          />
                        </Col>
                        <Col lg="12 pl-0">
                          <CgnReactSelect
                            name="istisnaId"
                            label="İstisna [*]"
                            placeholder="İstisna seçiniz"
                            selectValue={productData.istisnaId}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleIstisnaChange(option, idx)
                            }
                            options={this.state.istisnaArr}
                            hidden={this.state.isHiddenIstisna}
                            error={productData.istisnaError}
                          />
                        </Col>
                      </Row>
                      <Row className="pl-0">
                        <Col className="pl-0">
                          <CgnTextbox
                            type="number"
                            name="productDiscountValue"
                            label="İskonto(Net Tutar)"
                            value={productData.productDiscountValue}
                            placeHolder="İskonto net tutar"
                            maxLength="50"
                            autoComplete="off"
                            readOnly={this.state.isProductDiscountValue}
                            onChange={(e) =>
                              this.handleProductDiscountValueChange(e, idx)
                            }
                            error={productData.productDiscountValueError}
                          />
                        </Col>
                        <Col>
                          <CgnReactSelect
                            name="productTaxRate"
                            label="K.D.V Oranı [*]"
                            placeholder="K.D.V oranı"
                            selectValue={productData.productTaxRate}
                            isMulti={false}
                            isClearable={true}
                            disabled={this.state.isTrue}
                            onChange={(option) =>
                              this.handleProductTaxRateSelectChange(option, idx)
                            }
                            options={TaxRateChoices}
                            error={productData.productTaxRateError}
                          />
                        </Col>
                        <Col>
                          <CgnReactSelect
                            name="tevkifatId"
                            label="Tevkifat"
                            placeholder="Tevkifat"
                            selectValue={productData.tevkifatId}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleTevkifatChange(option, idx)
                            }
                            options={this.state.tevkifatArr}
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="tevkifatTotal"
                            label="Tevkifat Fiyat"
                            value={productData.tevkifatTotal}
                            readOnly={true}
                            //onChange={this.ProductPriceCalculate()}
                            maxLength="50"
                            autoComplete="off"
                          />
                        </Col>
                        <Col>
                          <CgnTextbox
                            type="number"
                            name="total"
                            label="Fiyat [*]"
                            value={productData.total}
                            readOnly={true}
                            //onChange={this.ProductPriceCalculate()}
                            maxLength="50"
                            autoComplete="off"
                          />
                        </Col>

                        <Col>
                          <CgnButton
                            className="dynamicRowDeleteButton"
                            type="button"
                            color="danger"
                            onClick={() => this.handleProductRemove(idx)}
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <CgnDatePicker
                            name="deliveryDate"
                            label="Teslimat Tarihi"
                            selected={
                              productData.deliveryDate &&
                              productData.deliveryDate.toString().indexOf("/") >
                                0
                                ? new Date(
                                    productData.deliveryDate.split("/")[2] +
                                      "-" +
                                      productData.deliveryDate.split("/")[1] +
                                      "-" +
                                      productData.deliveryDate.split("/")[0]
                                  )
                                : productData.deliveryDate
                            }
                            onChange={(deliveryDate) =>
                              this.handleDeliveryDateChange(deliveryDate, idx)
                            }
                            error={productData.deliveryDateError}
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                          />
                        </Col>
                        <Col lg="3">
                          <CgnTextbox
                            type="text"
                            name="requestNo"
                            label="Talep Numarası"
                            value={productData.requestNo}
                            placeHolder="Talep numarası"
                            autoComplete="off"
                            onChange={(event) =>
                              this.handleRequestNoChange(event, idx)
                            }
                            error={productData.requestItemNoError}
                          />
                        </Col>
                        <Col lg="3">
                          <CgnTextbox
                            type="text"
                            name="requestItemNo"
                            label="Talep Kalem Numarası"
                            value={productData.requestItemNo}
                            placeHolder="Talep kalem numarası"
                            autoComplete="off"
                            onChange={(event) =>
                              this.handleRequestItemNoChange(event, idx)
                            }
                            error={productData.requestItemNoError}
                          />
                        </Col>
                        <Col lg="3">
                          <CgnTextbox
                            type="text"
                            name="workOrderNo"
                            label="İş Emri Numarası"
                            value={productData.workOrderNo}
                            placeHolder="İş emri numarası"
                            maxLength="50"
                            autoComplete="off"
                            onChange={(event) =>
                              this.handleWorkOrderNoChange(event, idx)
                            }
                            error={productData.requestItemNoError}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <CgnTextbox
                            type="text"
                            name="notes"
                            label="Notlar ve Ekler"
                            value={productData.notes}
                            placeHolder="Notlar ve ekler"
                            autoComplete="off"
                            onChange={(event) =>
                              this.handleNotesChange(event, idx)
                            }
                            error={productData.requestItemNoError}
                          />
                        </Col>
                      </Row>
                    </>
                  ))}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggle}
                    text={"Çoklu Ürün Ekle"}
                  />
                  {this.state.productDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.productDataError}
                    </div>
                  )}
                </Row>

                <Row className="mt-3">
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTotal"
                      label="Genel Tutar"
                      value={this.state.generalTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalDiscountTotal"
                      label="İndirim Toplamı"
                      value={this.state.generalDiscountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="generalTaxTotal"
                      label="KDV Toplamı"
                      value={this.state.generalTaxTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="number"
                      name="discountTotal"
                      label="Toplam Tutar"
                      value={this.state.discountTotal}
                      maxLength="50"
                      autoComplete="off"
                      readOnly={true}
                      onChange={this.PriceCalculate}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <h2 className="mb-2">İhracat Ayarları</h2>
                </Row>
                <Row
                  className="pl-0"
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <Col lg="3" className="pl-0">
                    <CgnReactSelect
                      name="deliveryType"
                      label="Teslim Şekli [*]"
                      placeholder="Teslim şekli seçiniz..."
                      selectValue={this.state.deliveryType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleDeliveryTypeSelectChange(option)
                      }
                      options={DeliveryType}
                      error={this.state.deliveryTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="transportTypeId"
                      label="Taşıma Şekli [*]"
                      placeholder="Taşıma şekli seçiniz..."
                      selectValue={this.state.transportTypeId}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleTransportTypeSelectChange(option)
                      }
                      options={TransportType}
                      error={this.state.transportTypeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnReactSelect
                      name="cabinType"
                      label="Kabin Cinsi [*]"
                      placeholder="Kabin cinsi seçiniz..."
                      selectValue={this.state.cabinType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCabinTypeSelectChange(option)
                      }
                      options={CabinType}
                      error={this.state.cabinTypeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="cabinPiece"
                      label="Kabin Adet [*]"
                      value={this.state.cabinPiece}
                      onChange={(e) => this.handleCabinPieceTextBoxChange(e)}
                      placeHolder="Lütfen kabin adet girin..."
                      maxLength="50"
                      autoComplete="off"
                      step="0.1"
                      min={0}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="cabinNo"
                      label="Kabin No [*]"
                      value={this.state.cabinNo}
                      onChange={(e) => this.handleCabinNoTextBoxChange(e)}
                      placeHolder="Lütfen kabin no girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinNoError}
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: this.state.isHiddenIhracat === false ? "" : "none",
                  }}
                >
                  <Col lg="4" className="pl-0">
                    <CgnTextbox
                      type="text"
                      name="cabinAddress"
                      label="Teslimat Adres [*]"
                      value={this.state.cabinAddress}
                      onChange={(e) =>
                        this.handleDeliveryAddressTextBoxChange(e)
                      }
                      placeHolder="Lütfen teslimat adres girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinAddressError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="cabinDistrict"
                      label="Teslimat İlçe [*]"
                      value={this.state.cabinDistrict}
                      onChange={(e) =>
                        this.handleDeliveryDistrictTextBoxChange(e)
                      }
                      placeHolder="Lütfen teslimat ilçe girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinDistrictError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="cabinCity"
                      label="Teslimat Şehir [*]"
                      value={this.state.cabinCity}
                      onChange={(e) => this.handleDeliveryCityTextBoxChange(e)}
                      placeHolder="Lütfen teslimat şehir girin..."
                      maxLength="50"
                      autoComplete="off"
                      error={this.state.cabinCityError}
                    />
                  </Col>

                  <Col lg="2">
                    <CgnReactSelect
                      name="cabinCountry"
                      label="Teslimat Ülke [*]"
                      placeholder="Teslimat ülke seçiniz..."
                      selectValue={this.state.cabinCountry}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCabinCountrySelectChange(option)
                      }
                      options={this.state.countryArr}
                      error={this.state.cabinCountryError}
                    />
                  </Col>
                </Row>

                {this.state.paymentCalendarModalPlus &&
                  this.state.paymentCalendarData.length > 0 &&
                  this.state.paymentCalendarData.map(
                    (paymentCalendarData, idx) => (
                      <Row className="mobileBorder">
                        <Col lg="3">
                          <CgnDatePicker
                            name="targetPaymentDate"
                            label="Ödeme Tarihi [*]"
                            selected={
                              paymentCalendarData.targetPaymentDate &&
                              paymentCalendarData.targetPaymentDate
                                .toString()
                                .indexOf("/") > 0
                                ? new Date(
                                    paymentCalendarData.targetPaymentDate.split(
                                      "/"
                                    )[2] +
                                      "-" +
                                      paymentCalendarData.targetPaymentDate.split(
                                        "/"
                                      )[1] +
                                      "-" +
                                      paymentCalendarData.targetPaymentDate.split(
                                        "/"
                                      )[0]
                                  )
                                : paymentCalendarData.targetPaymentDate
                            }
                            onChange={(option) => {
                              this.handleTargetPaymentDateChange(option, idx);
                            }}
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                            error={paymentCalendarData.targetPaymentDateError}
                          />
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="number"
                            name="targetTotal"
                            label="Ödeme Tutarı [*]"
                            value={paymentCalendarData.targetTotal}
                            onChange={(e) => this.handleTextBoxChange(e, idx)}
                            placeHolder="Lütfen tutar girin..."
                            maxLength="50"
                            autoComplete="off"
                            error={paymentCalendarData.targetTotalError}
                          />
                        </Col>

                        {/* <Col lg="3">
                        <CgnReactSelect
                          name="targetCurrencyType"
                          label="Para Birimi [*]"
                          placeholder="Lütfen para birimi seçiniz..."
                          selectValue={paymentCalendarData.targetCurrencyType}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTargetCurrencyTypeChange(option, idx)
                          }
                          options={CurrencyType}
                          error={paymentCalendarData.targetCurrencyTypeError}
                        />
                      </Col> */}

                        <Col lg="4">
                          <CgnReactSelect
                            name="targetPaymentMethodType"
                            label="Ödeme Yöntemi [*]"
                            placeholder="Lütfen ödeme yöntemi seçiniz..."
                            selectValue={
                              paymentCalendarData.targetPaymentMethodType
                            }
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleInputValueChange(option, idx);
                            }}
                            options={PaymentMethodType}
                            error={
                              paymentCalendarData.targetPaymentMethodTypeError
                            }
                          />
                        </Col>

                        <Col lg="1" sm="3">
                          <CgnButton
                            type="button"
                            color="danger"
                            className="dynamicRowDeleteButton"
                            onClick={() =>
                              this.handlePaymentCalendarRemove(idx)
                            }
                            text={"Sil"}
                          />
                        </Col>
                      </Row>
                    )
                  )}

                {this.state.paymentCalendarModalPlus && (
                  <Row>
                    <Col lg="4">
                      <CgnButton
                        type="button"
                        color="primary"
                        className="mb-3 dynamicRowAddButton"
                        onClick={this.handlePaymentCalendarAdd}
                        text={"Ödeme Takvimi Ekle"}
                      />
                    </Col>
                  </Row>
                )}

                {this.state.paymentModalPlus &&
                  this.state.urlSplit.length === 2 && (
                    <>
                      <Row>
                        <Col
                          lg="12"
                          className="font-weight-bold"
                          style={{ padding: "0 15px 10px 15px" }}
                        >
                          <Label
                            check
                            className="labelCheck"
                            style={{ fontSize: "13px" }}
                          >
                            <Input
                              type="checkbox"
                              className="mt-1"
                              onChange={(e) => this.handleCheckBoxChange(e)}
                              checked={!this.state.isHidden}
                            />{" "}
                            Ödeme Yapıldı mı ?
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <CgnTextbox
                            type="text"
                            name="receiptNo"
                            label="Makbuz No"
                            value={this.state.receiptNo}
                            placeHolder="Lütfen makbuz no girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.receiptNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                        <Col lg="6">
                          <CgnTextbox
                            type="number"
                            name="expiry"
                            label="Vade"
                            value={this.state.expiry}
                            placeHolder="Lütfen vade sayısı girin..."
                            autoComplete="off"
                            onChange={this.handleChange}
                            error={this.state.expiryError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <CgnReactSelect
                            name="paymentMethodValue"
                            label="Ödeme Yöntemi [*]"
                            placeholder="Lütfen ödeme yöntemi seçiniz..."
                            selectValue={this.state.paymentMethodValue}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) =>
                              this.handlePaymentMethodSelectChange(option)
                            }
                            options={PaymentMethodType}
                            error={this.state.paymentMethodTypeError}
                            hidden={this.state.isHidden}
                          />
                        </Col>

                        <Col
                          lg="4"
                          className="modalSelectCol"
                          style={
                            this.state.isHidden
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          <div className="flex-container">
                            <div className="p-0 flexMaxInput">
                              <CgnReactSelect
                                name="bankGuid"
                                label="Banka"
                                placeholder="Banka seçiniz..."
                                selectValue={this.state.bankGuid}
                                isMulti={false}
                                isClearable={true}
                                disabled={this.state.isBankTrue}
                                onChange={(option) =>
                                  this.handleBankSelectChange(option)
                                }
                                options={this.state.bankArr}
                                error={this.state.bankError}
                                hidden={this.state.isHidden}
                              />
                            </div>
                            {this.state.bankModalPlus && (
                              <div className="p-0 flexAddMinButton">
                                <CgnButton
                                  type="button"
                                  color="secondary"
                                  className="modalSelectAddButton"
                                  onClick={() => this.addModalBank()}
                                  text={"+"}
                                  visibled={this.state.isHidden}
                                />
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col lg="4">
                          <CgnTextbox
                            type="text"
                            name="chequesBondNo"
                            label="Çek/Senet No"
                            value={this.state.chequesBondNo}
                            placeHolder="Lütfen çek/senet no girin..."
                            autoComplete="off"
                            readOnly={this.state.isChequesBondNoTrue}
                            onChange={this.handleChange}
                            error={this.state.chequesBondNoError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <ImageUpload
                            accept="image/png, image/jpeg"
                            className="text-center"
                            style={fotoStyle}
                            label="Makbuz Fotoğrafı"
                            image={this.state.image}
                            noFoto={noImage}
                            alt={this.state.name}
                            onChange={this.uploadedImage}
                            onDelete={this.deleteImage}
                            error={this.state.imageError}
                            hidden={this.state.isHidden}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row> */}

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />

                <Row>
                  <Modal
                    isOpen={this.state.productEntryModal}
                    toggle={this.toggle}
                  >
                    <ModalHeader
                      toggle={this.toggle}
                      className="font-weight-bold"
                    >
                      Çoklu Ürün Ekle
                    </ModalHeader>
                    <ModalBody className="modalBody">
                      <Input
                        type="textarea"
                        className="modalTextArea"
                        onChange={(e) => this.handleChangeMultipleProduct(e)}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <CgnButton
                        type="button"
                        color="primary"
                        onClick={() => this.handleClickMultipleProduct()}
                        text={CgnMessage.addButton}
                        className="modalButton"
                      />
                      <CgnButton
                        type="button"
                        color="secondary"
                        onClick={this.toggle}
                        text="Kapat"
                        className="modalButton"
                      />
                    </ModalFooter>
                  </Modal>
                </Row>
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalSupplier}
          toggle={this.addModalSupplier}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tedarikçi Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SupplierAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSupplier}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalBank}
          toggle={this.addModalBank}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Banka Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <BankAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBank}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductEntrySecondAddUpdate;
