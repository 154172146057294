import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  fotoStyle,
  
  localFilePath,
  fotoStyle2,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import pdfIcon from "../../images/pdfIcon.png";

class ProductDetail extends Component {
  state = {
    pageTitle: "Ürün Detay",
    pageTitleDetail: "Ürün Detay",
    pageAddLink: "urun-ekle",
    pageListLink: "urun-listele",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    categoryName: "",
    contractName: "",
    code: "",
    name: "",
    description: null,
    criticalPieces: null,
    currencyType: "",
    salePrice: "",
    buyPrice: "",
    peerProductName: "",
    supplierProductCode: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    viewStatus: null,
    kitStatus: null,
    productPropertyDto: [],
    maintenanceKitDto: [],
    productImageDto: [],
    stockStatus: false,
    productStock: 0,
    locationProductStock: [],
    productPictureDto: [],
    productPicture2Dto: [],
    shelfInformation: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/products/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            image: response.data.image,
            categoryName: response.data.categoryName,
            contractName: response.data.contractName,
            code: response.data.code,
            supplierProductCode: response.data.supplierProductCode,
            name: response.data.name,
            criticalPieces: response.data.criticalPieces,
            currencyType: response.data.currencyType,
            productPropertyDto: response.data.productPropertyDto,
            peerProductName: response.data.peerProductName,
            maintenanceKitDto: response.data.maintenanceKitDto,
            salePrice: response.data.salePrice.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            buyPrice: response.data.buyPrice.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            status: response.data.status,
            viewStatus: response.data.viewStatus,
            kitStatus: response.data.kitStatus,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            stockStatus: response.data.stockStatus,
            productStock: response.data.productStock,
            locationProductStock: response.data.locationProductStock,
            productImageDto: response.data.productImageDto,
            productPictureDto: response.data.productPictureDto,
            productPicture2Dto: response.data.productPicture2Dto,
            description: response.data.description,
            isLoading: false,
            shelfInformation: response.data.shelfInformation,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;
    var self1 = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Kategori Adı</th>
                    <td colSpan="4">
                      {this.state.categoryName ? this.state.categoryName : "-"}
                    </td>
                  </tr>
                  {/* <tr>
                    <th>Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.image ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.image}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.image}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal> */}
                  <tr>
                    <th>Barkod</th>
                    <td colSpan="4">
                      {this.state.code ? this.state.code : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Tedarikçi Ürün Kodu</th>
                    <td colSpan="4">
                      {this.state.supplierProductCode
                        ? this.state.supplierProductCode
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ürün Adı</th>
                    <td colSpan="4">
                      {this.state.name ? this.state.name : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Emsal Ürün</th>
                    <td colSpan="4">
                      {this.state.peerProductName
                        ? this.state.peerProductName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ürün Özellik Durum</th>
                    <td colSpan="4">
                      {this.state.viewStatus === 2
                        ? "Görünmesin !"
                        : "Görünsün"}
                    </td>
                  </tr>
                  <tr>
                    <th>Bakım Kiti Durum</th>
                    <td colSpan="4">
                      {this.state.kitStatus === 2 ? "Görünmesin !" : "Görünsün"}
                    </td>
                  </tr>
                  <tr>
                    <th>Alış Fiyatı</th>
                    <td colSpan={4}>
                      {this.state.buyPrice
                        ? this.state.buyPrice.toLocaleString("tr-TR", {
                          minimumFractionDigits: 2,
                        }) +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                              ? "€"
                              : "-")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Satış Fiyatı</th>
                    <td colSpan="4">
                      {this.state.salePrice
                        ? `${this.state.salePrice} ${this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                              ? "€"
                              : "-"
                        }`
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Stok Durumu</th>
                    <td colSpan="4">
                      {this.state.stockStatus ? "Stoklu Ürün" : "Stoksuz Ürün"}
                    </td>
                  </tr>

                  <tr>
                    <th>Raf Bilgisi</th>
                    <td colSpan="4">
                      {this.state.shelfInformation
                        ? this.state.shelfInformation
                        : "-"}
                    </td>
                  </tr>

                  {this.state.stockStatus ? (
                    <>
                      <tr>
                        <th>Stok</th>
                        <td colSpan="4">{this.state.productStock + " Adet"}</td>
                      </tr>
                      <tr>
                        <th>Kritik Adet</th>
                        <td colSpan="4">
                          {this.state.criticalPieces + " Adet"}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description === null
                        ? "-"
                        : this.state.description}
                    </td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.productPicture2Dto !== null &&
                this.state.productPicture2Dto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Ürün Fotoğrafları (Müşteri)</th>
                      </tr>
                      <Row>
                        {this.state.productPicture2Dto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}

              {this.state.productPictureDto !== null &&
                this.state.productPictureDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Ürün Fotoğrafları</th>
                      </tr>
                      <Row>
                        {this.state.productPictureDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}

              {this.state.productImageDto &&
                this.state.productImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Dosya</th>
                    </tr>
                    <Row>
                      {this.state.productImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.locationProductStock !== null &&
                this.state.locationProductStock.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th>Depo</th>
                      <th>Stok</th>
                    </tr>
                    {this.state.locationProductStock.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.locationName}</td>
                          <td>{item.piece + " Adet"}</td>
                        </tr>
                      );
                    })}
                  </Table>
                )}

              {this.state.productPropertyDto !== null &&
                this.state.productPropertyDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product table-product-2">
                    <tr style={mainRow}>
                      <th>Ürün Özellikleri Tipi</th>
                      <th>Ürün Özellikleri</th>
                    </tr>
                    {this.state.productPropertyDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.propertyTypeName}</td>
                          <td>{item.value}</td>
                        </tr>
                      );
                    })}
                  </Table>
                )}

              {this.state.maintenanceKitDto !== null &&
                this.state.maintenanceKitDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product table-product-2">
                    <tr style={mainRow}>
                      <th>Bakım Kiti</th>
                      <th>Adet</th>
                      <th>Alış Fiyatı</th>
                      <th>Satış Fiyatı</th>
                    </tr>
                    {this.state.maintenanceKitDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.maintenanceKitName}</td>
                          <td>{item.maintenanceKitPiece}</td>
                          <td>
                            {" "}
                            {item.maintenanceKitBuyPrice
                              ? item.maintenanceKitBuyPrice.toLocaleString(
                                "tr-TR",
                                { minimumFractionDigits: 2 }
                              ) +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                  ? "$"
                                  : item.currencyType === 3
                                    ? "€"
                                    : "-")
                              : "-"}
                          </td>
                          <td>
                            {" "}
                            {item.maintenanceKitSalePrice
                              ? item.maintenanceKitSalePrice.toLocaleString(
                                "tr-TR",
                                { minimumFractionDigits: 2 }
                              ) +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                  ? "$"
                                  : item.currencyType === 3
                                    ? "€"
                                    : "-")
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductDetail;
