import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  resizeFile,
  fotoStyle,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import ImageUpload from "../toolbox/CgnImageUpload";
import noImg from "../../images/no-img.png";
import MaterialCategoryAddUpdate from "../materialCategory/MaterialCategoryAddUpdate";
import ProductAddUpdate from "../product/ProductAddUpdate";
import { withRouter } from "react-router-dom";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import LocationAddUpdate from "../location/LocationAddUpdate";

class MaterialAddUpdate extends Component {
  state = {
    pageTitle: "El Aleti Ekle",
    pageTitleAdd: "El Aleti Ekle",
    pageTitleUpdate: "El Aleti Güncelle",
    pageAddLink: "malzeme-ekle",
    pageListLink: "malzeme-listele",
    breadcrumb: [{ text: "El Aleti", link: "#" }],
    isLoading: false,
    urlSplit: [],
    materialCategoryArr: [],
    materialCategoryGuid: "",
    materialCategoryName: "",
    peerProductArr: [],
    peerProductGuid: "00000000-0000-0000-0000-000000000000",
    locationGuid: "",
    locationError: "",
    code: "",
    image: "",
    name: "",
    stock: "",
    firstValues: [],
    locationArr: [],
    materialImageDto: [],
    guid: "",
    status: true,
    isNull: false,
    isOpenModalMaterialCategory: false,
    isOpenModalProduct: false,
    isOpenModalLocation: false,
    modalButtonIdx: 0,
    isTrue: false,
    materialCategoryNameError: "",
    codeError: "",
    nameError: "",
    stockError: "",
    alertErrorMessage: "",
    message: "",
    contractError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    categoryModalPlus: false,
    productPropertyTypeModalPlus: false,
    productModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      categoryModalPlus: await UserOperationClaimControl(1006),
      productPropertyTypeModalPlus: await UserOperationClaimControl(1033),
      productModalPlus: await UserOperationClaimControl(1029),
      locationModalPlus: await UserOperationClaimControl(1055),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/materialCategories/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          materialCategoryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/locations/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          peerProductArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "malzeme-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/materials/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              materialCategoryGuid: response.data.materialCategoryGuid,
              materialCategoryName: response.data.materialCategoryName,
              peerProductGuid: response.data.peerProductGuid,
              locationGuid:
                response.data.locationGuid ===
                "00000000-0000-0000-0000-000000000000"
                  ? ""
                  : response.data.locationGuid,
              image: response.data.image,
              code: response.data.code,
              name: response.data.name,
              stock: response.data.stock === 0 ? "" : response.data.stock,
              status: response.data.status,
              isLoading: false,
              isNull: false,
              materialImageDto: response.data.materialImageDto,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        image: "",
        materialCategoryGuid: "",
        materialCategoryName: "",
        peerProductGuid: "00000000-0000-0000-0000-000000000000",
        locationGuid: "",
        code: "",
        name: "",
        stock: "",
        materialImageDto: [],
        status: true,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalMaterialCategory &&
      this.props.location.modalAddedMaterialCategory !== undefined &&
      this.props.location.modalAddedMaterialCategory.isModal !== undefined &&
      this.props.location.modalAddedMaterialCategory.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/materialcategories/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            materialCategoryArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMaterialCategory.data &&
              element.name ===
                this.props.location.modalAddedMaterialCategory.data
            ) {
              this.setState({
                materialCategoryGuid: element.guid,
                materialCategoryNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMaterialCategory.isModal = true;
      this.setState({
        isOpenModalMaterialCategory: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProduct !== undefined &&
      this.props.location.modalAddedProduct.isModal !== undefined &&
      this.props.location.modalAddedProduct.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/products/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            var label = `${item.code}-${item.name}`;
            if (item.supplierProductCode && item.supplierProductCode !== "") {
              label = `${item.code}-${item.supplierProductCode}-${item.name}`;
            }
            return {
              value: item.guid,
              label: label,
            };
          });
          this.setState({
            peerProductArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProduct.data &&
              `${element.code}-${element.name}` ===
                this.props.location.modalAddedProduct.data
            ) {
              this.setState({
                peerProductGuid: element.guid,
                peerProductError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProduct.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalLocation &&
      this.props.location.modalAddedLocation !== undefined &&
      this.props.location.modalAddedLocation.isModal !== undefined &&
      this.props.location.modalAddedLocation.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedLocation.data &&
              element.name === this.props.location.modalAddedLocation.data
            ) {
              this.setState({
                locationGuid: element.guid,
                locationError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedLocation.isModal = true;
      this.setState({
        isOpenModalLocation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  barcodeGenerator = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/materials/barcodenumbergenerate", config)
      .then((response) => {
        this.setState({
          code: response.data,
          codeError: "",
          alertErrorMessage: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalMaterialCategory = () => {
    this.setState({
      isOpenModalMaterialCategory: !this.state.isOpenModalMaterialCategory,
    });
  };

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  addModalLocation = () => {
    this.setState({
      isOpenModalLocation: !this.state.isOpenModalLocation,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      materiaCategoryNameError: "",
      codeError: "",
      nameError: "",
      stockError: "",
      alertErrorMessage: "",
    });
    if (name === "materialCategoryGuid" && value === "") {
      this.setState({
        materialCategoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "code" && value === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    await this.setState({
      materialCategoryGuid: "",
      materialCategoryName: "",
      peerProductGuid: "00000000-0000-0000-0000-000000000000",
      image: "",
      code: "",
      name: "",
      stock: "",
      status: true,
      isTrue: false,
      materialImageDto: [],
      alertErrorMessage: CgnMessage.alertErrorMessage,
      materialCategoryNameError: CgnMessage.textErrorMessage,
      codeError: CgnMessage.textErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      stockError: CgnMessage.textErrorMessage,
    });
  }

  handleMaterialCategorySelectChange(option) {
    if (option) {
      this.setState({
        materialCategoryGuid: option.value,
        materialCategoryNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        materialCategoryGuid: "",
        materialCategoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationError: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationError: CgnMessage.textErrorMessage,
      });
    }
  }

  handlePeerProductSelectChange(option) {
    if (option) {
      this.setState({
        peerProductGuid: option.value,
      });
    } else {
      this.setState({
        peerProductGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      materialCategoryNameError: "",
      codeError: "",
      nameError: "",
      stockError: "",
      locationError: "",
    });
    if (this.state.materialCategoryGuid === "") {
      this.setState({
        materialCategoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.code === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.locationGuid !== "") {
      if (this.state.stock === "") {
        control = false;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          stockError: CgnMessage.textErrorMessage,
        });
      }
    }
    if (this.state.stock !== "") {
      if (this.state.locationGuid === "") {
        control = false;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          locationError: CgnMessage.textErrorMessage,
        });
      }
    }
    if (
      this.state.materialCategoryGuid !== "" &&
      this.state.code !== "" &&
      this.state.name !== "" &&
      this.state.isNull === false &&
      control === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        materialCategoryGuid: this.state.materialCategoryGuid,
        image: this.state.image,
        code: this.state.code,
        name: this.state.name,
        peerProductGuid: this.state.peerProductGuid,
        locationGuid: this.state.locationGuid,
        stock: this.state.stock !== "" ? parseInt(this.state.stock) : 0,
        status: this.state.status,
        materialImageDto: this.state.materialImageDto,
      };
      var URLParam = "/materials/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "malzeme-guncelle"
      ) {
        URLParam = "/materials/update";
        myObj = {
          guid: this.state.guid,
          materialCategoryGuid: this.state.materialCategoryGuid,
          image: this.state.image,
          code: this.state.code,
          name: this.state.name,
          peerProductGuid: this.state.peerProductGuid,
          locationGuid:
            this.state.locationGuid === ""
              ? "00000000-0000-0000-0000-000000000000"
              : this.state.locationGuid,
          stock: this.state.stock !== "" ? parseInt(this.state.stock) : 0,
          status: this.state.status,
          materialImageDto: this.state.materialImageDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "malzeme-guncelle"
            ) {
              window.location.href = "/malzeme-listele";
            }
            if (this.state.urlSplit[1] === "malzeme-ekle") {
              window.location.reload();
            }
            var modalAddedMaterial = {
              data: `${this.state.code}-${this.state.name}`,
              isModal: false,
            };
            this.props.history.push({ modalAddedMaterial });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  uploadedImage2 = async (event) => {
    let array = this.state.materialImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "application/pdf") {
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
              image: response.data,
              imageName: file.name,
            });
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    await this.setState({ materialImageDto: array });
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.materialImageDto;
    this.state.materialImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ materialImageDto: array });
        }
      }
    });
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "malzeme-ekle" ||
          this.state.urlSplit[1] === "malzeme-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Fotoğraf"
                      image={this.state.image}
                      noFoto={noImg}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      error={this.state.imageError}
                    />
                  </Col>
                  <Col lg="3">
                    <ImageUploadMulti
                      images={this.state.materialImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Dosya"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="code"
                      label="El Aleti Kodu [*]"
                      value={this.state.code}
                      placeHolder="Lütfen el aleti kodu girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.codeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnButton
                      className="barcodeButton"
                      type="button"
                      color="secondary"
                      block
                      onClick={() => this.barcodeGenerator()}
                      text={"El Aleti Kodu Üret"}
                    />
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="materialCategoryGuid"
                          label="El Aleti Kategori [*]"
                          placeholder="El Aleti kategori seçiniz..."
                          selectValue={this.state.materialCategoryGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleMaterialCategorySelectChange(option)
                          }
                          options={this.state.materialCategoryArr}
                          error={this.state.materialCategoryNameError}
                        />
                      </div>
                      {this.state.categoryModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalMaterialCategory()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="El Aleti Adı [*]"
                      value={this.state.name}
                      placeHolder="Lütfen el aleti girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>

                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="peerProductGuid"
                          label="Emsal Ürün"
                          placeholder="Emsal ürün seçiniz..."
                          selectValue={this.state.peerProductGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePeerProductSelectChange(option)
                          }
                          options={this.state.peerProductArr}
                          error={this.state.peerProductError}
                        />
                      </div>
                      {this.state.productModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalProduct()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="4" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="locationGuid"
                          label="Depo"
                          isMulti={false}
                          selectValue={this.state.locationGuid}
                          placeholder="Depo seçiniz..."
                          options={this.state.locationArr}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleLocationSelectChange(option)
                          }
                          error={this.state.locationError}
                        />
                      </div>
                      {this.state.locationModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalLocation()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="stock"
                      label="Stok Durumu"
                      value={this.state.stock}
                      placeHolder="Lütfen stok girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.stockError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "malzeme-ekle" ||
              this.state.urlSplit[1] === "malzeme-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalMaterialCategory}
          toggle={this.addModalMaterialCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            El Aleti Kategori Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <MaterialCategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalMaterialCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Emsal Ürün Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ProductAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalLocation}
          toggle={this.addModalLocation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Depo Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <LocationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalLocation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(MaterialAddUpdate);
