import React, { Component } from "react";
import axios from "axios";
import { URL} from "../../redux/actions/constants";
import CgnLoader from "../toolbox/CgnLoader";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnMessage from "../toolbox/CgnMessage";
import { Row, Col, Card, Form } from "reactstrap";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";

class OfferNewDetail extends Component {
  state = {
    isLoading: false,
    alertErrorMessage: "",
    cancelText: "",
    cancelTextError: "",
    offerGuid: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        isLoading: true,
      });
      await axios
        .get(URL + "/offerstatus2/statuschange/" + urlSplit[2] + "/3")
        .then((response) => {
          this.setState({
            alertErrorMessage: "",
            isLoading: false,
            offerGuid: urlSplit[2]
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      cancelTextError: "",
      alertErrorMessage: "",
    });
    if (name === "cancelText" && value === "") {
      this.setState({
        cancelTextError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };


  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      cancelTextError: "",
    });
    if (this.state.cancelText === "") {
      this.setState({
        cancelTextError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.cancelText !== "") {
      this.setState({
        isLoading: true,
      });
      var myObj = {
        cancelText: this.state.cancelText,
        offerGuid: this.state.offerGuid
      };
      var URLParam = "/offercanceltexts/add";  
      axios
        .post(URL + URLParam, myObj)
        .then(() => {
          setTimeout(() => {
              window.location.reload();
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };


  render() {
    return (
      <div className="teklif-box">
        <CgnLoader visibled={this.state.isLoading} />
        {this.state.alertErrorMessage && (
          <div className="teklif teklif-iptal">
            <h3>Uyarı!</h3>
            <p>{this.state.alertErrorMessage}</p>
          </div>
        )}
        {!this.state.alertErrorMessage && (
          <div className="teklif teklif-iptal">
            <h3>Teklif İptal Edildi!</h3>
            <p>
              Yetkili personelimiz teklif ile alakalı sizinle en kısa sürede
              iletişime geçecektir.
            </p>
            <p>Teşekkürler, iyi çalışmalar dileriz.</p>
            {/* <Form onSubmit={this.handleSave}>
          <Card body>
           <Row>
             <Col lg="12">
             <CgnTextbox
                type="textarea"
                name="cancelText"
                label="Teklif İptal Sebebi"
                value={this.state.cancelText}
                placeHolder="Lütfen teklif iptal sebebi girin..."
                maxLength="500"
                autoComplete="off"
                onChange={this.handleChange}
                error={this.state.cancelTextError}
                    />
             </Col>
           </Row>

          </Card>
          <CgnButtonGroup className="mt-1" items={this.state.buttons} />
        </Form> */}
          </div>        
        )}
      </div>
    );
  }
}

export default OfferNewDetail;
