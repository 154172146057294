import React, { Component } from "react";
import ContentTop from "../root/ContentTop";
import { URL } from "../../redux/actions/constants";
import axios from "axios";
import CgnAlert from "../toolbox/CgnAlert";
import { Row, Col, Card, Table } from "reactstrap";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import CgnButton from "../toolbox/CgnButton";
import CgnLoader from "../toolbox/CgnLoader";
import CgnBadge from "../toolbox/CgnBadge";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";

class OutgoingInvoiceDetail extends Component {
  state = {
    breadcrumb: [{ text: "Fatura", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    data: {},
    dataBillsProductsDto: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/bills/get/" + urlSplit[2], config)
      .then((response) => {
        this.setState({
          data: response.data,
          dataBillsProductsDto: response.data.billsProductsDto,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          data: {},
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  cancel = (uuid) => {
    confirmAlert({
      title: CgnMessage.cancelQuestionTitle,
      message: CgnMessage.cancelQuestionMessage,
      buttons: [
        {
          label: CgnMessage.cancelQuestionYes,
          onClick: () => this.cancelItem(uuid),
        },
        {
          label: CgnMessage.cancelQuestionNo,
        },
      ],
    });
  };

  cancelItem = async (uuid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var myObj = {
      uuid: uuid,
      cancelCogen: 1,
    };
    axios
      .post(URL + "/bills/cancel", myObj, config)
      .then(() => {
        this.setState({
          isLoading: false,
          alertErrorMessage: "",
        });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          alertErrorMessage: error.response.data,
        });
      });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title="Giden Fatura Detay"
          listLink="/giden-fatura-listele"
        />
        {this.state.alertErrorMessage && (
          <CgnAlert
            color="danger"
            title="Uyarı"
            text={this.state.alertErrorMessage}
          />
        )}
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.dateTime
                        ? this.state.data.dateTime
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fatura Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.billNumber
                        ? this.state.data.billNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>UUID</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.uuid ? this.state.data.uuid : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fatura Türü</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.billType ? (
                        this.state.data.billType === "TEMELFATURA" ? (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="primary "
                            text="Temel Fatura"
                          />
                        ) : this.state.data.billType === "TICARIFATURA" ? (
                          <CgnBadge
                            style={this.badgeStyle}
                            color="info"
                            text="Ticari Fatura"
                          />
                        ) : (
                          this.state.data.billType
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Firma Adı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyName
                        ? this.state.data.companyName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Adres</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyAddress
                        ? this.state.data.companyAddress
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>İlçe</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyDistrict
                        ? this.state.data.companyDistrict
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Şehir</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyCity
                        ? this.state.data.companyCity
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Ülke</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyCountry
                        ? this.state.data.companyCountry
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Posta Kodu</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyPostCode
                        ? this.state.data.companyPostCode
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Vergi Dairesi</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyTaxAdministration
                        ? this.state.data.companyTaxAdministration
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Vergi Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyTaxNumber
                        ? this.state.data.companyTaxNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Ticari Sicil Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyCommercialRegistrationNumber
                        ? this.state.data.companyCommercialRegistrationNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Mersis Numarası</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.companyMersisNumber
                        ? this.state.data.companyMersisNumber
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Tutar</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.price
                        ? this.state.data.price.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>İskonto Tutarı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.discountPrice
                        ? this.state.data.discountPrice.toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                            }
                          ) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Toplam Tutar</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.totalPrice
                        ? this.state.data.totalPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>K.D.V. Oranı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.taxRate
                        ? "%" + this.state.data.taxRate
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>K.D.V. Tutarı</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.taxPrice
                        ? this.state.data.taxPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Genel Tutar</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.generalPrice
                        ? this.state.data.generalPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                          " " +
                          (this.state.data.currencyType === 1
                            ? "TL"
                            : this.state.data.currencyType === 2
                            ? "$"
                            : this.state.data.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 1 && (
                          <CgnBadge color="secondary" text="Bilinmiyor (1)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 2 && (
                          <CgnBadge
                            color="secondary"
                            text="İşlenmeyi Bekliyor (1)"
                          />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 3 && (
                          <CgnBadge color="secondary" text="İşleniyor (1)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 4 && (
                          <CgnBadge color="danger" text="Hatalı (1)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 0 && (
                          <CgnBadge color="success" text="Başarılı (1)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 1 && (
                          <CgnBadge color="secondary " text="Taslak Veri (2)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 2 && (
                          <CgnBadge color="secondary " text="Bekliyor (2)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 3 && (
                          <CgnBadge
                            color="secondary "
                            text="İşlem Yapılıyor (2)"
                          />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 4 && (
                          <CgnBadge color="danger " text="Hatalı (2)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 0 && (
                          <CgnBadge color="success " text="Başarılı (2)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 1 && (
                          <CgnBadge color="secondary " text="Taslak Veri (3)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 2 && (
                          <CgnBadge color="secondary " text="Bekliyor (3)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 3 && (
                          <CgnBadge
                            color="secondary "
                            text="İşlem Yapılıyor (3)"
                          />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 4 && (
                          <CgnBadge color="danger " text="Hatalı (3)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 5 &&
                        this.state.data.answerTypeCode === 0 && (
                          <CgnBadge color="success " text="Başarılı (3)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 5 &&
                        this.state.data.answerTypeCode === 1 && (
                          <CgnBadge color="secondary " text="Bilinmiyor (4)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 5 &&
                        this.state.data.answerTypeCode === 2 && (
                          <CgnBadge color="warning " text="İade Edildi (4)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 5 &&
                        this.state.data.answerTypeCode === 3 && (
                          <CgnBadge color="danger " text="Reddedildi (4)" />
                        )}
                      {this.state.data.transferStatus === 1 &&
                        this.state.data.transferStateCode === 5 &&
                        this.state.data.invoiceStateCode === 5 &&
                        this.state.data.answerStateCode === 5 &&
                        this.state.data.answerTypeCode === 4 && (
                          <CgnBadge color="success " text="Kabul Edildi (4)" />
                        )}
                    </td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.createdBy
                        ? this.state.data.createdBy
                        : "-"}
                    </td>
                    <th>Kaydedilme Tarihi</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.createdAt
                        ? this.state.data.createdAt
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.updatedBy
                        ? this.state.data.updatedBy
                        : "-"}
                    </td>
                    <th>Son Güncellenme Tarihi</th>
                    <th className="colon">:</th>
                    <td>
                      {this.state.data.updatedAt
                        ? this.state.data.updatedAt
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {this.state.dataBillsProductsDto !== null &&
                this.state.dataBillsProductsDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product">
                    <tr style={mainRow}>
                      <th className="w-auto">Ürün Adı</th>
                      <th className="w-125">Adet</th>
                      <th className="w-125">Birim Fiyatı</th>
                      <th className="w-200">İskonto / Aktarım Oranı</th>
                      <th className="w-200">İskonto / Aktarım Fiyatı</th>
                      <th className="w-125">K.D.V. Oranı</th>
                      <th className="w-125">K.D.V. Fiyatı</th>
                      {/* <th className="w-100">Diğer Vergiler</th> */}
                      <th className="w-125">Fiyat</th>
                    </tr>
                    {this.state.dataBillsProductsDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.productName}</td>
                          <td>{item.piece + " " + item.unit}</td>
                          <td>
                            {item.unitPrice !== 0
                              ? item.unitPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : "-"}
                          </td>
                          <td>
                            {item.discountPercent !== 0
                              ? "%" + item.discountPercent
                              : "-"}
                          </td>
                          <td>
                            {item.discountPrice !== 0
                              ? item.discountPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : "-"}
                          </td>
                          <td>
                            {item.taxRate !== 0 ? "%" + item.taxRate : "-"}
                          </td>
                          <td>
                            {item.taxPrice !== 0
                              ? item.taxPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : ""}
                          </td>
                          {/* <td>
                            {item.otherPrice !== 0
                              ? item.otherPrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                " " +
                                item.currency
                              : "-"}
                          </td> */}
                          <td>
                            {item.price.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) +
                              " " +
                              item.currency}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}
            </Card>
            {this.state.data.pdfFileName !== null &&
              this.state.data.pdfFileName !== "" && (
                <CgnButtonLink
                  color="primary"
                  to={"/EFatura/" + this.state.data.pdfFileName}
                  target={"_blank"}
                  text="Görüntüle"
                />
              )}
            {this.state.data.uuid !== null &&
              this.state.data.uuid !== "00000000-0000-0000-0000-000000000000" &&
              this.state.data.type === 3 &&
              this.state.data.billType === "TICARIFATURA" &&
              this.state.data.cancel === 0 &&
              this.state.data.cancelCogen === 0 && (
                <CgnButton
                  type="button"
                  color="secondary"
                  onClick={() => this.cancel(this.state.data.uuid)}
                  text="İptal Et"
                  className="ml-2"
                />
              )}
            <CgnButtonLink
              color="secondary"
              to={"/giden-fatura-listele"}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default OutgoingInvoiceDetail;
