import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  GenderOptions,
  URL,

  fotoStyle,
  localFilePath,
  fotoStyle2,
} from "../../redux/actions/constants";
import classnames from "classnames";
import NoteList from "../notes/NoteList";
import NoteAddUpdate from "../notes/NoteAddUpdate";
import moment from "moment";
import CgnButton from "../toolbox/CgnButton";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import pdfIcon from "../../images/pdfIcon.png";
import noImg from "../../images/no-img.png";

class CustomerDetail extends Component {
  state = {
    pageTitle: "Müşteri Detay",
    pageTitleDetail: "Müşteri Detay",
    pageAddLink: "musteri-ekle",
    pageListLink: "musteri-listele",
    breadcrumb: [{ text: "Müşteri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    name: "",
    taxNo: "",
    commercialRegistrationNumber: "",
    mersisNumber: "",
    taxAdministration: "",
    sector: "",
    reference: "",
    isRisk: false,
    address: "",
    districtGuid: "",
    townGuid: "",
    cityGuid: "",
    countryGuid: "",
    districtName: "",
    townName: "",
    cityName: "",
    city: "",
    countryName: "",
    zipCode: "",
    fax: "",
    phone: "",
    eMail: "",
    webSite: "",
    description: "",
    connectionName: "",
    connectionDegree: "",
    connectionDepartment: "",
    totalOfferCount: 0,
    totalProductOutCount: 0,
    totalProductOutCollectionCount: 0,
    totalIncomeCount: 0,
    totalIncomeCollectionCount: 0,
    totalCustomerCollectionTotal: 0,
    connectionPhone: "",
    connectionEMail: "",
    connectionGender: "",
    connectionDescription: "",
    birthDate: "",
    documentNo: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    customerGroupName: "",
    updatedBy: "",
    activeTab: "1",
    customerDetail: "1",
    incomeList: "2",
    costList: "3",
    productOutList: "4",
    returnList: "5",
    offerList: "7",
    notes: "6",
    offerNewDto: [],
    productOutNewDto: [],
    collectionDto: [],
    incomeDto: [],
    noteDto: [],
    isOpenModalNote: false,
    isOpenModalUpdateNote: false,
    customerFileDto: [],
    customerImageDto: [],
    noteGuid: "",
    offerCurrencyType: 1,
    incomeCurrencyType: 1,
    productOutCurrencyType: 1,
    collectionCurrencyType: 1,
    isOpen: false,
    notificationEMail: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/customers/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            name: response.data.name,
            taxNo: response.data.taxNo,
            commercialRegistrationNumber:
              response.data.commercialRegistrationNumber,
            mersisNumber: response.data.mersisNumber,
            taxAdministration: response.data.taxAdministration,
            sector: response.data.sector,
            reference: response.data.reference,
            birthDate: response.data.birthDate
              ? moment(response.data.birthDate).format("DD/MM/YYYY")
              : null,
            customerGroupName: response.data.customerGroupName,
            isRisk: response.data.isRisk,
            address: response.data.address,
            countryGuid: response.data.countryGuid,
            cityGuid: response.data.cityGuid,
            townGuid: response.data.townGuid,
            districtGuid: response.data.districtGuid,
            countryName: response.data.countryName,
            cityName: response.data.cityName,
            city: response.data.city,
            townName: response.data.townName,
            districtName: response.data.districtName,
            zipCode: response.data.zipCode,
            fax: response.data.fax,
            phone: response.data.phone,
            eMail: response.data.eMail,
            webSite: response.data.website,
            description: response.data.description,
            connectionName: response.data.connectionName,
            connectionDegree: response.data.connectionDegree,
            connectionDepartment: response.data.connectionDepartment,
            connectionPhone: response.data.connectionPhone,
            connectionEMail: response.data.connectionEMail,
            connectionGender: response.data.connectionGender,
            connectionDescription: response.data.connectionDescription,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            offerNewDto: response.data.offerNewDto,
            productOutNewDto: response.data.productOutNewDto,
            collectionDto: response.data.collectionDto,
            customerFileDto: response.data.customerFileDto,
            customerImageDto: response.data.customerImageDto,
            noteDto: response.data.noteDto,
            incomeDto: response.data.incomeDto,
            notificationEMail: response.data.notificationEMail,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });

      var totalOfferCount = 0;
      var totalProductOutCount = 0;
      var totalProductOutCollectionCount = 0;
      var totalIncomeCount = 0;
      var totalIncomeCollectionCount = 0;
      var totalCustomerCollectionTotal = 0;
      var offerCurrencyType = 1;
      var productOutCurrencyType = 1;
      var incomeCurrencyType = 1;
      var collectionCurrencyType = 1;

      this.state.offerNewDto.forEach((element) => {
        totalOfferCount += element.total;
        offerCurrencyType = element.currencyType;
      });

      this.state.productOutNewDto.forEach((element) => {
        totalProductOutCount += element.total;
        totalProductOutCollectionCount += element.generalTotalCollection;
        productOutCurrencyType = element.currencyType;
      });

      this.state.incomeDto.forEach((element) => {
        totalIncomeCount += element.discountTotal;
        totalIncomeCollectionCount += element.collectionTotal;
        incomeCurrencyType = element.currencyType;
      });

      this.state.collectionDto.forEach((element) => {
        totalCustomerCollectionTotal += element.total;
        collectionCurrencyType = element.currencyType;
      });

      await this.setState({
        totalOfferCount: totalOfferCount,
        totalProductOutCount: totalProductOutCount,
        totalProductOutCollectionCount: totalProductOutCollectionCount,
        totalIncomeCount: totalIncomeCount,
        totalIncomeCollectionCount: totalIncomeCollectionCount,
        totalCustomerCollectionTotal: totalCustomerCollectionTotal,
        offerCurrencyType: offerCurrencyType,
        productOutCurrencyType: productOutCurrencyType,
        incomeCurrencyType: incomeCurrencyType,
        collectionCurrencyType: collectionCurrencyType,
      });
    }
  }

  addModalNote = () => {
    this.setState({
      isOpenModalNote: !this.state.isOpenModalNote,
    });
  };

  addModalUpdateNote = (guid) => {
    this.setState({
      isOpenModalUpdateNote: !this.state.isOpenModalUpdateNote,
      noteGuid: guid,
    });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  handleNoteDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleNoteSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleNoteSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/notes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  // toggle = (tab) => {
  //   if (this.state.activeTab !== tab) {
  //     this.setState({
  //       activeTab: tab,
  //     });
  //   }
  // };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  // toggle = () => {
  //   this.setState({
  //     isOpen: !this.state.isOpen,
  //   });
  // };

  render() {
    var self = this.state;
    var self1 = this;

    const mainRow = {
      backgroundColor: "#9B9796",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    const mainRow2 = {
      backgroundColor: "#9B9796",
      color: "#fff",
      width: "300px",
    };

    var phone = this.state.phone,
      maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
        } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
          9,
          11
        )}`;

    if (this.state.connectionPhone) {
      var connectionPhone = this.state.connectionPhone,
        maskconnectionPhone = `${connectionPhone.substring(0, 1)}  ${"(" + connectionPhone.substring(1, 4) + ")"
          } ${connectionPhone.substring(4, 7)} ${connectionPhone.substring(
            7,
            9
          )} ${connectionPhone.substring(9, 11)}`;
    }

    if (this.state.fax) {
      var fax = this.state.fax,
        maskFax = `${fax.substring(0, 1)}  ${"(" + fax.substring(1, 4) + ")"
          } ${fax.substring(4, 7)} ${fax.substring(7, 9)} ${fax.substring(
            9,
            11
          )}`;
    }

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          noteLink={(onClick) => this.addModalNote()}
        />
        <Row className="customerDetail">
          <Col>
            <CgnLoader visibled={this.state.isLoading} />
            {this.state.alertErrorMessage && (
              <CgnAlert
                color="danger"
                title="Uyarı"
                text={this.state.alertErrorMessage}
              />
            )}

            <Card body className="table-responsive">
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">
                      {this.state.name ? this.state.name : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Vergi Dairesi</th>
                    <td colSpan="4">
                      {this.state.taxAdministration
                        ? this.state.taxAdministration
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Vergi Numarası</th>
                    <td colSpan="4">
                      {this.state.taxNo ? this.state.taxNo : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ticari Sicil Numarası</th>
                    <td colSpan="4">
                      {this.state.commercialRegistrationNumber
                        ? this.state.commercialRegistrationNumber
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Mersis Numarası</th>
                    <td colSpan="4">
                      {this.state.mersisNumber ? this.state.mersisNumber : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Sektör</th>
                    <td colSpan="4">
                      {this.state.sector ? this.state.sector : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Referans</th>
                    <td colSpan="4">
                      {this.state.reference ? this.state.reference : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Doğum Tarihi</th>
                    <td colSpan="4">
                      {this.state.birthDate ? this.state.birthDate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Bölge</th>
                    <td colSpan="4">
                      {this.state.customerGroupName
                        ? this.state.customerGroupName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Risk</th>
                    <td colSpan="4">{this.state.isRisk ? "Var" : "Yok"}</td>
                  </tr>

                  <tr>
                    <th>Adres</th>
                    <td colSpan="4">
                      {this.state.address ? this.state.address : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Mahalle/İlçe/Şehir/Ülke</th>
                    <td colSpan="4">
                      {`${this.state.districtName
                          ? this.state.districtName + "/"
                          : ""
                        }${this.state.townName ? this.state.townName + "/" : ""}${this.state.cityName ? this.state.cityName + "/" : ""
                        }${this.state.countryName ? this.state.countryName : "-"
                        }`}
                    </td>
                  </tr>

                  {this.state.city &&
                    <tr>
                      <th>Şehir (Diğer)</th>
                      <td colSpan="4">
                        {this.state.city}
                      </td>
                    </tr>
                  }
                  <tr>
                    <th>Posta Kodu</th>
                    <td colSpan="4">
                      {this.state.zipCode ? this.state.zipCode : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Telefon</th>
                    <td colSpan="4">{maskPhone ? maskPhone : "-"}</td>
                  </tr>

                  <tr>
                    <th>Faks</th>
                    <td colSpan="4">{maskFax ? maskFax : "-"}</td>
                  </tr>

                  <tr>
                    <th>E-Mail</th>
                    <td colSpan="4">
                      {this.state.eMail ? this.state.eMail : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Web Site</th>
                    <td colSpan="4">
                      {this.state.webSite ? this.state.webSite : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Bilgilendirme E-Maili Gönderilsin mi?</th>
                    <td colSpan="4">{this.state.notificationEMail ? "Evet" : "Hayır"}</td>
                  </tr>

                  <tr>
                    <th>Ad Soyad</th>
                    <td colSpan="4">
                      {this.state.connectionName
                        ? this.state.connectionName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Ünvan</th>
                    <td colSpan="4">
                      {this.state.connectionDegree
                        ? this.state.connectionDegree
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Departman</th>
                    <td colSpan="4">
                      {this.state.connectionDepartment
                        ? this.state.connectionDepartment
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Cep Telefonu</th>
                    <td colSpan="4">
                      {maskconnectionPhone ? maskconnectionPhone : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>E-Mail</th>
                    <td colSpan="4">
                      {this.state.connectionEMail
                        ? this.state.connectionEMail
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Cinsiyet</th>
                    <td colSpan="4">
                      {GenderOptions(this.state.connectionGender)}
                    </td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.connectionDescription
                        ? this.state.connectionDescription
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.customerFileDto &&
                this.state.customerFileDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.customerFileDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.customerImageDto !== null &&
                this.state.customerImageDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Fotoğraf</th>
                      </tr>
                      <Row>
                        {this.state.customerImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}

              {this.state.offerNewDto !== null &&
                this.state.offerNewDto.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.offerNewDto !== null &&
                            self.offerNewDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Teklif</h2>
                    </div>
                    <Table className="table table-responsive table-product2">
                      <tr className="">
                        <th style={mainRow}>Teklif No</th>
                        <th style={mainRow2} className="text-center">
                          Tarih
                        </th>
                        <th className="text-center" style={mainRow2}>
                          Teklif Tutarı
                        </th>
                        <th style={mainRow} className="text-right"></th>
                      </tr>
                      {this.state.offerNewDto.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.offerNumber}</td>
                            <td className="text-center">{item.offerDate}</td>
                            <td className="text-center">
                              {item.total.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                    ? "$"
                                    : item.currencyType === 3
                                      ? "€"
                                      : "-")}
                            </td>
                            <td>
                              <Button
                                type="button"
                                color="primary"
                                style={{ width: "90px" }}
                                className="ml-3 mt-1"
                                href={"/teklif2-detay/" + item.guid}
                                text="Detay"
                                target="_blank"
                              >
                                Detay
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </Table>

                    <div className="row mt-2">
                      <div className="col-lg-12">
                        <h6
                          className="p-1 mt-2 pl-2 collectionH2"
                          style={{ border: "2px solid #1d4b8c" }}
                        >
                          Toplam Teklif Tutarı :{" "}
                          {this.state.totalOfferCount.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.offerCurrencyType === 1
                              ? "₺"
                              : this.state.offerCurrencyType === 2
                                ? "$"
                                : this.state.offerCurrencyType === 3
                                  ? "€"
                                  : "-")}
                        </h6>
                      </div>
                    </div>
                  </>
                )}

              {this.state.productOutNewDto !== null &&
                this.state.productOutNewDto.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.productOutNewDto !== null &&
                            self.productOutNewDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Satış</h2>
                    </div>
                    <Table className="table table-responsive table-product2">
                      <tr className="">
                        <th style={mainRow}>Tarih</th>
                        <th className="text-center" style={mainRow2}>
                          Satış Tutarı
                        </th>
                        <th className="text-center" style={mainRow2}>
                          Tahsil Edilen Tutar
                        </th>
                        <th style={mainRow} className="text-right"></th>
                      </tr>
                      {this.state.productOutNewDto.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.productOutDate}</td>
                            <td className="text-center">
                              {item.total.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                    ? "$"
                                    : item.currencyType === 3
                                      ? "€"
                                      : "-")}
                            </td>
                            <td className="text-center">
                              {item.generalTotalCollection.toLocaleString(
                                "tr-TR",
                                {
                                  minimumFractionDigits: 2,
                                }
                              ) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                    ? "$"
                                    : item.currencyType === 3
                                      ? "€"
                                      : "-")}
                            </td>
                            <td>
                              <Button
                                type="button"
                                color="primary"
                                style={{ width: "90px" }}
                                className="ml-3 mt-1"
                                href={"/satis2-detay/" + item.guid}
                                text="Detay"
                                target="_blank"
                              >
                                Detay
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </Table>

                    <div className="row mt-2">
                      <div className="col-lg-12">
                        <h6
                          className="p-1 mt-2 pl-2 collectionH2"
                          style={{ border: "2px solid #1d4b8c" }}
                        >
                          Toplam Tahsilat Tutarı :{" "}
                          {this.state.totalProductOutCollectionCount.toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                            }
                          ) +
                            " " +
                            (this.state.productOutCurrencyType === 1
                              ? "₺"
                              : this.state.productOutCurrencyType === 2
                                ? "$"
                                : this.state.productOutCurrencyType === 3
                                  ? "€"
                                  : "-")}
                        </h6>
                        <h6
                          className="p-1 mt-2 pl-2 collectionH2"
                          style={{ border: "2px solid #1d4b8c" }}
                        >
                          Toplam Satış Tutarı :{" "}
                          {this.state.totalProductOutCount.toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                            }
                          ) +
                            " " +
                            (this.state.productOutCurrencyType === 1
                              ? "₺"
                              : this.state.productOutCurrencyType === 2
                                ? "$"
                                : this.state.productOutCurrencyType === 3
                                  ? "€"
                                  : "-")}
                        </h6>
                      </div>
                    </div>
                  </>
                )}

              {this.state.incomeDto !== null &&
                this.state.incomeDto.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.incomeDto !== null && self.incomeDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Gelir</h2>
                    </div>
                    <Table className="table table-responsive table-product2">
                      <tr className="">
                        <th style={mainRow}>Tarih</th>
                        <th className="text-center" style={mainRow2}>
                          Tutar
                        </th>
                        <th className="text-center" style={mainRow2}>
                          Tahsil Edilen Tutar
                        </th>
                        <th style={mainRow} className="text-right"></th>
                      </tr>
                      {this.state.incomeDto.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.incomeDate}</td>
                            <td className="text-center">
                              {item.discountTotal.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                    ? "$"
                                    : item.currencyType === 3
                                      ? "€"
                                      : "-")}
                            </td>
                            <td className="text-center">
                              {item.collectionTotal.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                    ? "$"
                                    : item.currencyType === 3
                                      ? "€"
                                      : "-")}
                            </td>
                            <td>
                              <Button
                                type="button"
                                color="primary"
                                style={{ width: "90px" }}
                                className="ml-3 mt-1"
                                href={"/gelir-detay/" + item.guid}
                                text="Detay"
                                target="_blank"
                              >
                                Detay
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </Table>

                    <div className="row mt-2">
                      <div className="col-lg-12">
                        <h6
                          className="p-1 mt-2 pl-2 collectionH2"
                          style={{ border: "2px solid #1d4b8c" }}
                        >
                          Toplam Tahsilat Tutarı :{" "}
                          {this.state.totalIncomeCollectionCount.toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                            }
                          ) +
                            " " +
                            (this.state.incomeCurrencyType === 1
                              ? "₺"
                              : this.state.incomeCurrencyType === 2
                                ? "$"
                                : this.state.incomeCurrencyType === 3
                                  ? "€"
                                  : "-")}
                        </h6>
                        <h6
                          className="p-1 mt-2 pl-2 collectionH2"
                          style={{ border: "2px solid #1d4b8c" }}
                        >
                          Toplam Gelir Tutarı :{" "}
                          {this.state.totalIncomeCount.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (this.state.incomeCurrencyType === 1
                              ? "₺"
                              : this.state.incomeCurrencyType === 2
                                ? "$"
                                : this.state.incomeCurrencyType === 3
                                  ? "€"
                                  : "-")}
                        </h6>
                      </div>
                    </div>
                  </>
                )}

              {this.state.collectionDto !== null &&
                this.state.collectionDto.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.collectionDto !== null &&
                            self.collectionDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Tahsilat</h2>
                    </div>
                    <Table className="table table-responsive table-product2">
                      <tr className="">
                        <th style={mainRow}>Tarih</th>
                        <th className="text-right" style={mainRow2}>
                          Tahsilat Tutarı
                        </th>
                      </tr>
                      {this.state.collectionDto.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.collectionDate}</td>
                            <td className="text-right">
                              {item.total.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                    ? "$"
                                    : item.currencyType === 3
                                      ? "€"
                                      : "-")}
                            </td>
                          </tr>
                        );
                      })}
                    </Table>

                    <div className="row mt-2">
                      <div className="col-lg-12">
                        <h6
                          className="p-1 mt-2 pl-2 collectionH2"
                          style={{ border: "2px solid #1d4b8c" }}
                        >
                          Toplam Tahsilat Tutarı :{" "}
                          {this.state.totalCustomerCollectionTotal.toLocaleString(
                            "tr-TR",
                            {
                              minimumFractionDigits: 2,
                            }
                          ) +
                            " " +
                            (this.state.collectionCurrencyType === 1
                              ? "₺"
                              : this.state.collectionCurrencyType === 2
                                ? "$"
                                : this.state.collectionCurrencyType === 3
                                  ? "€"
                                  : "-")}
                        </h6>
                      </div>
                    </div>
                  </>
                )}

              {this.state.noteDto !== null && this.state.noteDto.length > 0 && (
                <>
                  <div
                    className="row"
                    style={{
                      display:
                        self.noteDto !== null && self.noteDto.length > 0
                          ? ""
                          : "none",
                    }}
                  >
                    <h2 className="mt-2">Not</h2>
                  </div>
                  <Table className="table table-responsive table-product2">
                    <tr className=""></tr>
                    {this.state.noteDto.map(function (item) {
                      return (
                        <tbody>
                          <>
                            <Accordion allowZeroExpanded>
                              <AccordionItem>
                                <AccordionItemHeading
                                  style={{ backgroundColor: "#9B9796" }}
                                >
                                  <AccordionItemButton>
                                    <tr className="cuttingTr4 w-100">
                                      <th
                                        className="pl-3"
                                        style={self1.thStyle3}
                                      >
                                        Not Başlığı
                                      </th>
                                      <td style={self1.tdStyle2}>
                                        {item.title}
                                      </td>
                                    </tr>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <tr></tr>
                                  <tr>
                                    <th className="pl-3">Not</th>
                                    <td>{item.description}</td>
                                  </tr>
                                  <CgnButton
                                    type="button"
                                    color="success"
                                    className="printButton ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.addModalUpdateNote(item.guid)
                                    }
                                    text="Güncelle"
                                  />
                                  <CgnButton
                                    type="button"
                                    color="danger"
                                    style={{ width: "90px" }}
                                    className="ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.handleNoteDelete(item.guid)
                                    }
                                    text="Sil"
                                  />
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </>
                        </tbody>
                      );
                    })}
                  </Table>
                </>
              )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalNote}
          toggle={this.addModalNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.guid} updated={false} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalUpdateNote}
          toggle={this.addModalUpdateNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.noteGuid} updated={true} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default CustomerDetail;
