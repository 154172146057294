import React from "react";
import Sidebar from "react-sidebar";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "../../images/cogen-crm-logo.png";
import Logo1 from "../../images/medikozlogo.png";
import Logo3 from "../../images/alkomlogo.png";
import NoUser from "../../images/no-foto-user.jpg";
import CgnListGroup from "../toolbox/CgnListGroup";
import { Button, Col, Row, Modal, ModalHeader } from "reactstrap";
import Content from "../root/Content";
import Footer from "../root/Footer";
import { fotoStyle } from "../../redux/actions/constants";
import dugumGunu from "../../images/dugumGunu.png";

class CgnLeftSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      companyId: 0,
      isOpen: false,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  async componentDidMount() {
    let urlSplit1 = window.location.href.split("//");
    let urlSplit2 = urlSplit1[1].split("/");
    if (
      urlSplit2[0] === "crm.medikozceviri.com" ||
      urlSplit2[0] === "crm.cevirikalitesi.com"
    ) {
      this.setState({
        companyId: 1,
      });
    }
    if (
      urlSplit2[0] === "crm.alkom.com.tr"
    ) {
      this.setState({
        companyId: 3,
      });
    }
    if (
      parseInt(localStorage.getItem("birthday")) === 0 &&
      parseInt(localStorage.getItem("birthdayMonth")) ===
      new Date().getMonth() + 1 &&
      parseInt(localStorage.getItem("birthdayDay")) === new Date().getDate()
    ) {
      localStorage.setItem("birthday", 1);
      this.setState({
        isOpen: true,
      });
    }
  }

  render() {
    return (
      <>
        <Sidebar
          sidebar={
            <div className="sidebar">
              <a
                href="https://www.cogen.com.tr"
                alt="Cogen® Yazılım | CRM"
                target="blank"
                style={{ display: this.state.companyId === 0 ? "" : "none" }}
              >
                <img src={Logo} alt="Cogen® Yazılım | CRM" className="logo" />
              </a>
              <a
                href="https://www.medikozceviri.com/"
                alt="Medikoz Tercüme | CRM"
                target="blank"
                style={{ display: this.state.companyId === 1 ? "" : "none" }}
              >
                <img src={Logo1} alt="Medikoz Tercüme | CRM" className="logo" />
              </a>
              <a
                href="https://www.alkom.com.tr/"
                alt="Alkom | CRM"
                target="blank"
                style={{ display: this.state.companyId === 3 ? "" : "none" }}
              >
                <img src={Logo3} alt="Alkom | CRM" className="logo" />
              </a>
              <div className="program-name">
                <h3>{this.props.data.programName}</h3>
              </div>
              <div className="user clearfix">
                <Row>
                  <Col xs="3">
                    <img
                      src={
                        this.props.data.userPhoto
                          ? this.props.data.userPhoto
                          : NoUser
                      }
                      alt={
                        this.props.data.userName +
                        " / " +
                        this.props.data.userTitle
                      }
                      className="photo"
                    />
                  </Col>
                  <Col xs="9" className="px-0">
                    <Col xs="12">
                      <p className="font-weight-bold">
                        {this.props.data.userName
                          ? this.props.data.userName
                          : "-"}
                      </p>
                    </Col>
                    <Col xs="12">
                      <p className="m-0">
                        {this.props.data.userTitle
                          ? this.props.data.userTitle
                          : "-"}
                      </p>
                    </Col>
                  </Col>
                </Row>
              </div>
              {this.props.data.workAccidentDay !== -1 && (
                <div className="work-accident">
                  <p>
                    {this.props.data.workAccidentDay} gündür iş kazası
                    gerçekleşmedi!
                  </p>
                </div>
              )}
              {this.props.data.cartCount > 0 && (
                <div className="cart-count">
                  <a href="/makine-urun-siparis-ekle">
                    {this.props.data.cartCount} adet sepette ürün var!
                  </a>
                </div>
              )}
              {this.props.data.remindCount > 0 && (
                <div className="remind-count">
                  <a href="/hatirlatma-listele">
                    Okunmamış {this.props.data.remindCount} hatırlatma var!
                  </a>
                </div>
              )}
              {this.props.data.orderCount > 0 &&
                localStorage.getItem("isOrderCount") === "true" && (
                  <div className="order-count">
                    <a href="/yetkili-makine-urun-siparis-listele">
                      Bekleyen {this.props.data.orderCount} adet sipariş var!
                    </a>
                  </div>
                )}
              {this.props.data.orderLocationCount > 0 && (
                <div className="order-location-count">
                  <a href="/makine-urun-siparis-listele">
                    Kargolanan {this.props.data.orderLocationCount} adet sipariş
                    var!
                  </a>
                </div>
              )}
              <div className="menu">
                <CgnListGroup items={this.props.data.items} />
              </div>
              <Footer />
            </div>
          }
          open={this.state.sidebarOpen}
          docked={this.props.data.docked}
          pullRight={this.props.data.pullRight}
          shadow={this.props.data.shadow}
          transitions={this.props.data.transitions}
          onSetOpen={this.onSetSidebarOpen}
          styles={{ sidebar: { background: "#f5f5f5" } }}
        >
          <Button
            color="primary"
            className="sidebar-open-button"
            onClick={() => this.onSetSidebarOpen(true)}
          >
            <AiOutlineMenu />
          </Button>
          <Content data={this.props.data} items={this.props.data.items} />
          <Modal
            className="modal-xs"
            isOpen={this.state.isOpen}
            toggle={this.toggle}
          >
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <img
              className="modalImage"
              src={dugumGunu}
              style={fotoStyle}
              alt="Doğum Günü"
            />
            <p className="dogum-gunu-text">
              Ekibimizin bir parçası olduğunuz için teşekkür ederiz.
              <br />
              Doğum gününüz kutlu olsun; sağlıklı, mutlu ve başarılarla dolu bir
              yıl sizin olsun.
            </p>
          </Modal>
        </Sidebar>
      </>
    );
  }
}

export default CgnLeftSidebar;
