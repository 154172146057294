import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Button } from "reactstrap";
import {
  URL,
  
  GetMonthFirstDateYear,
  GetMonthLastDateYear,
  GetMonthFirstDate2Year,
  GetMonthLastDate2Year,
  localFilePath,
} from "../../redux/actions/constants";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnMessage from "../toolbox/CgnMessage";
import grafik01 from "../../images/grafik-01.jpg";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import ReactHtmlParser from "react-html-parser";

class reportNestleList extends Component {
  state = {
    pageTitle: "Raporlar",
    breadcrumb: [{ text: "Raporlar", link: "#" }],
    isLoading: false,
    reportNestleDto: [],
    firstDate: "",
    lastDate: "",
    firstDate2: "",
    lastDate2: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: "Filtrele",
        disabled: this.isLoading,
        className: "mr-0",
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDateYear();
    var lastDate = GetMonthLastDateYear();
    var firstDate2 = GetMonthFirstDate2Year();
    var lastDate2 = GetMonthLastDate2Year();

    if (localStorage.getItem("firstDateReportNestleList")) {
      firstDate = localStorage.getItem("firstDateReportNestleList");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateReportNestleList")) {
      lastDate = localStorage.getItem("lastDateReportNestleList");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    this.updateData();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
  }

  async updateData() {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(
        URL +
          "/reportnestle/getlist/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate,
        config
      )
      .then((response) => {
        this.setState({
          reportNestleDto: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handlePrint = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(
        URL +
          "/reportnestle/getlistexcel/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate,
        config
      )
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          excelLink={(onClick) => this.handlePrint()}
        />
        <Row className="report" id="list">
          <Col>
            <Card body>
              <Row>
                <Col lg="5" style={{ paddingLeft: "0px" }}>
                  <CgnDatePicker
                    name="firstDate2"
                    label="Başlangıç Tarihi [*]"
                    selected={this.state.firstDate2}
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    error={this.state.firstDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col lg="5">
                  <CgnDatePicker
                    name="lastDate2"
                    label="Bitiş Tarihi [*]"
                    selected={this.state.lastDate2}
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    error={this.state.lastDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col lg="2">
                  <Button
                    className="d-block w-100 mt-2 btnReport"
                    onClick={() => this.updateData()}
                  >
                    Listele
                  </Button>
                </Col>
              </Row>

              <CgnLoader visibled={this.state.isLoading} />

              <Row>
                <Col lg="12">
                  {this.state.alertErrorMessage && (
                    <CgnAlert
                      color="danger"
                      title={CgnMessage.alertErrorTitle}
                      text={this.state.alertErrorMessage}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                {this.state.reportNestleDto.machineProductOrderWaitingCount !==
                  null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        BEKLEYEN SİPARİŞ
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto
                          .machineProductOrderWaitingCount + " Adet"}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.machineProductOrderShippedCount !==
                  null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        KARGOLANDI SİPARİŞ
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto
                          .machineProductOrderShippedCount + " Adet"}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.machineProductOrderCount !==
                  null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        TAMAMLANAN SİPARİŞ
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto.machineProductOrderCount +
                          " Adet"}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.machineProductOrderHangingCount !==
                  null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        ASKIDA SİPARİŞ
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto.machineProductOrderHangingCount +
                          " Adet"}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.machineProductOrderMemberName !==
                  null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        EN ÇOK SİPARİŞ VEREN KULLANICI
                      </h5>
                      <div className="reportTotal">
                        {
                          this.state.reportNestleDto
                            .machineProductOrderMemberName
                        }
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.usedSparePartsCount !== null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        KULLANILAN YEDEK PARÇA
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto.usedSparePartsCount +
                          " Adet"}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.usedSparePartsName3 !== null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        EN ÇOK KULLANILAN YEDEK PARÇA
                      </h5>
                      <div className="reportTotal">
                        {ReactHtmlParser(
                          this.state.reportNestleDto.usedSparePartsName3
                        )}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.usedSparePartsName !== null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        EN ÇOK KULLANILAN YEDEK PARÇA KULLANICI
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto.usedSparePartsName}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.usedSparePartsName2 !== null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        EN AZ KULLANILAN YEDEK PARÇA KULLANICI
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto.usedSparePartsName2}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.transferStocksWaitingCount !==
                  null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        BEKLEYEN STOK AKTARIM
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto.transferStocksWaitingCount +
                          " Adet"}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}

                {this.state.reportNestleDto.transferStocksCount !== null && (
                  <Col lg="4" style={{ padding: "0px", paddingRight: "15px" }}>
                    <div
                      className="reportCircle reportSuccess"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5 className="reportTitle reportTitleGreen">
                        TAMAMLANAN STOK AKTARIM
                      </h5>
                      <div className="reportTotal">
                        {this.state.reportNestleDto.transferStocksCount +
                          " Adet"}
                      </div>
                      <img src={grafik01} alt="x" className="grafik01" />
                    </div>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {},
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(reportNestleList)
);
