import { Component } from "react";


 class Logout extends Component {

  componentDidMount() {
    localStorage.clear();
    this.props.history.push("/");
  }

  render() {
    return (
      <>
      </>
    )
  }
 }

export default (Logout);

