import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  GetMonthFirstDate,
  GetMonthLastDate,
  SupplierList,
  CustomerList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class PolicyListNext extends Component {
  state = {
    pageTitle: "Yaklaşan Poliçe Listele",
    pageAddLink: "police-ekle",
    pageDetailLink: "police-detay",
    pageUpdateLink: "police-guncelle",
    pageListLink: "yaklasan-police-listele",
    breadcrumb: [{ text: "Yaklaşan Poliçe", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    policyData: [],
    productItemCount: 0,
    customerName: "",
    guid: "",
    createdBy: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    supplierGuid: "00000000-0000-0000-0000-000000000000",
    supplierArr: [],
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    var statusType = 1;
    URLParam = "/policies/getlistnext/" + statusType;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {       
          this.setState({
            policyData: response.data,
            isLoading: false,
          });              
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/policies/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  thStyle = {
    width: "180px",
  };

  thStyle2 = {
    width: "150px",
  };

  tdStyle = {
    width: "400px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row className="policyList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.policyData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Yaklaşan Poliçe Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                           
                            <tr>
                              <th style={this.thStyle}>Poliçe Tarihi</th>
                              <td style={this.tdStyle}>{row.policyDate}</td>
                              <th style={this.thStyle2}>
                                Sonraki Poliçe Tarihi
                              </th>
                              <td>{row.nextPolicyDate}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri</th>
                              <td style={this.tdStyle}>{row.customerName}</td>
                              <th style={this.thStyle2}>Tedarikçi</th>
                              <td>{row.supplierName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Müşteri Doğum Tarihi</th>
                              <td style={this.tdStyle}>{row.customerBirth}</td>
                              <th style={this.thStyle2}>Müşteri TC No</th>
                              <td>{row.customerTaxNo}</td>
                            </tr>
                            <tr>
                            <th style={this.thStyle}>Ürün</th>
                              <td style={this.tdStyle}>{row.productName}</td>
                              <th style={this.thStyle2}>Belge No</th>
                              <td>{row.policyNo}</td>
                            </tr>
                            <tr>
                            <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              <td colSpan={4} style={this.tdStyle}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: "Güncelle" ,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Sil",
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default PolicyListNext;
