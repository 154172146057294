import React from "react";
import { FormGroup, Label } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({
  name,
  label,
  editorState,
  onChange,
  placeHolder,
  error,
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  const config = {
    image: { uploadEnabled: true, uploadCallback: uploadCallback },
  };

  function uploadCallback(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();
      reader.onload = function (e) {
        img.src = this.result;
        resolve({
          data: {
            link: img.src,
          },
        });
      };
    });
  }

  return (
    <FormGroup className={wrapperClass}>
      <Label className="font-weight-bolder" for={name}>
        {label}
      </Label>
      <Editor
        localization={{
          locale: "tr",
        }}
        toolbar={config}
        name={name}
        id={name}
        placeholder={placeHolder}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="text-editor"
        editorClassName="editorClassName"
        onEditorStateChange={onChange}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
};

export default TextEditor;
