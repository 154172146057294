import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
} from "../../redux/actions/constants";

class PolicyDetail extends Component {
  state = {
    pageTitle: "Poliçe Detay",
    pageTitleDetail: "Poliçe Detay",
    pageAddLink: "police-ekle",
    pageListLink: "police-listele",
    breadcrumb: [{ text: "Poliçe", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    supplierName: "",
    totalPiece: "",
    plateNo: "",
    customerBirth: "",
    customerTaxNo: "",
    productName: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    policyNo: "",
    documentNo: "",
    policyDate: "",
    nextPolicyDate: "",
    customerPhone: ""
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/policies/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            customerName: response.data.customerName,
            supplierName: response.data.supplierName,
            productName: response.data.productName,
            plateNo: response.data.plateNo !== "" ? response.data.plateNo : "-",
            customerBirth: response.data.customerBirth,
            customerTaxNo: response.data.customerTaxNo,
            customerPhone: response.data.customerPhone,
            policyNo: response.data.policyNo !== "" ? response.data.policyNo : "-",
            documentNo: response.data.documentNo !== "" ? response.data.documentNo : "-",
            policyDate: response.data.policyDate,
            nextPolicyDate: response.data.nextPolicyDate,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {

    var phone = this.state.customerPhone,
    maskPhone = `${phone.substring(0, 1)}  ${
      "(" + phone.substring(1, 4) + ")"
    } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
      9,
      11
    )}`;

    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Belge No</th>
                    <td colSpan="4">{this.state.documentNo}</td>
                  </tr>     
                  <tr>
                    <th>Poliçe No</th>
                    <td colSpan="4">{this.state.policyNo}</td>
                  </tr> 
                  <tr>
                    <th>Poliçe Tarihi</th>
                    <td colSpan="4">{this.state.policyDate}</td>
                  </tr> 
                  <tr>
                    <th>Sonraki Poliçe Tarihi</th>
                    <td colSpan="4">{this.state.nextPolicyDate}</td>
                  </tr>     
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">{this.state.customerName}</td>
                  </tr>  
                  <tr>
                    <th>Müşteri Doğum Tarihi</th>
                    <td colSpan="4">{this.state.customerBirth}</td>
                  </tr>   
                  <tr>
                    <th>Müşteri TC No</th>
                    <td colSpan="4">{this.state.customerTaxNo}</td>
                  </tr> 
                  <tr>
                    <th>Müşteri Telefon</th>
                    <td colSpan="4">{maskPhone ? maskPhone : "-"}</td>
                  </tr> 
                  <tr>
                    <th>Tedarikçi</th>
                    <td colSpan="4">{this.state.supplierName}</td>
                  </tr>   
                  <tr>
                    <th>Ürün</th>
                    <td colSpan="4">{this.state.productName}</td>
                  </tr>     
                  <tr>
                    <th>Plaka No</th>
                    <td colSpan="4">{this.state.plateNo}</td>
                  </tr>     
                  <tr>
                    <th>Ekleyen Kullanıcı</th>
                    <td colSpan="4">{this.state.createdBy}</td>
                  </tr>    
                  <tr>
                    <th>Eklenen Tarih</th>
                    <td colSpan="4">{this.state.createdAt}</td>
                  </tr>      
                </tbody>
              </Table>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PolicyDetail;
