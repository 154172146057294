import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  resizeFiles,
  
  UserOperationClaimControl,
  fotoStyle,
  optionWorkInstructionStatuses,
  personWorkInstructionStatus,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CustomerAddUpdate from "../customer/CustomerAddUpdate";
import ProductRecipeAddUpdate from "../productRecipe/ProductRecipeAddUpdate";
import MaterialAddUpdate from "../material/MaterialAddUpdate";
import WorkOrderStepAddUpdate from "../workOrderStep/WorkOrderStepAddUpdate";
import { withRouter } from "react-router-dom";
import pdfIcon from "../../images/pdfIcon.png";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import EmployeeAddUpdate from "../employee/EmployeeAddUpdate";

class WorkInstructionAddUpdate extends Component {
  state = {
    pageTitle: "İş Emri Ekle",
    pageTitleAdd: "İş Emri Ekle",
    pageTitleUpdate: "İş Emri Güncelle",
    pageAddLink: "is-emri-ekle",
    pageListLink: "is-emri-listele",
    breadcrumb: [{ text: "İş Emri", link: "#" }],
    isLoading: false,
    urlSplit: [],
    workQualityArr: [],
    workProductArr: [],
    stepArr: [],
    customerArr: [],
    personelArr: [],
    productOutArr: [],
    isOpenModalEmployee: false,
    workOrderStepImageDto: [],
    code: "",
    customerCode: "",
    image: "",
    name: "",
    guid: "",
    customerGuid: "",
    personelGuid: "",
    productGuid: "",
    selectedFile: null,
    productOutGuid: "",
    productOutNo: null,
    productData: [],
    qualityData: [],
    stepData: [],
    stepImageDto: [],
    useProductData: [],
    description: "",
    useProductError: "",
    unit: "",
    piece: null,
    usePiece: null,
    workDate: "",
    workEndDate: "",
    workStartDate: "",
    pieceError: "",
    statusChange: 1,
    productOutError: "",
    fileName: "",
    productError: "",
    customerError: "",
    personelError: "",
    workDateError: "",
    workStartDateError: "",
    status: true,
    isNull1: false,
    isNull2: false,
    isNull3: false,
    isOpenModalCustomer: false,
    isOpenModalProduct: false,
    isOpenModalProductMaterial: false,
    isOpenModalQualityMaterial: false,
    isOpenModalWorkOrderStep: false,
    modalButtonIdx: 0,
    isTrue: false,
    materialCategoryNameError: "",
    codeError: "",
    customerCodeError: "",
    nameError: "",
    stockError: "",
    alertErrorMessage: "",
    message: "",
    contractError: "",
    counter1: -1,
    counter2: -1,
    counter3: -1,
    workOrderStepGuid: "",
    usePieceError: "",
    unitError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    categoryModalPlus: false,
    productPropertyTypeModalPlus: false,
    productModalPlus: false,
    customerModalPlus: false,
    materialModalPlus: false,
    workOrderStepModalPlus: false,
    membershipModalPlus: false,
    serialNumber: "",
    serialNumberError: "",
    productRevisionNo: "",
    productRevisionNoError: "",
    drawinRevisionNo: "",
    drawinRevisionNoError: "",
    bomRevisionNo: "",
    bomRevisionNoError: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      categoryModalPlus: await UserOperationClaimControl(1006),
      productPropertyTypeModalPlus: await UserOperationClaimControl(1033),
      productModalPlus: await UserOperationClaimControl(1029),
      customerModalPlus: await UserOperationClaimControl(1012),
      materialModalPlus: await UserOperationClaimControl(1133),
      workOrderStepModalPlus: await UserOperationClaimControl(1123),
      membershipModalPlus: await UserOperationClaimControl(1020),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/materials/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}`,
          };
        });
        this.setState({
          workQualityArr: myArr,
          workProductArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/workOrderSteps/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
            workOrderStepImageDto: item.workOrderStepImageDto
          };
        });
        this.setState({
          stepArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if (item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
          useProductArr: myArr
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          personelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "is-emri-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/workInstructions/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              customerGuid: response.data.customerGuid,
              personelGuid: response.data.personelGuid,
              productGuid: response.data.productGuid,
              productOutGuid: response.data.productOutGuid,
              code: response.data.code,
              customerCode: response.data.customerCode,
              description: response.data.description,
              piece: response.data.piece,
              workDate: response.data.workDate,
              workEndDate: response.data.workEndDate === "01/01/1900" ? "" : response.data.workEndDate,
              workStartDate: response.data.workStartDate,
              status: response.data.status,
              isLoading: false,
              isNull: false,
              counter3: response.data.workInstructionStepDto.length - 1,
              stepData: response.data.workInstructionStepDto,
              statusChange: response.data.statusChange,
              serialNumber: response.data.serialNumber,
              productRevisionNo: response.data.productRevisionNo,
              drawinRevisionNo: response.data.drawinRevisionNo,
              bomRevisionNo: response.data.bomRevisionNo,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        productGuid: "",
        customerGuid: "",
        personelGuid: "",
        code: "",
        customerCode: "",
        name: "",
        productOutGuid: "",
        productOutNo: null,
        description: "",
        piece: null,
        workDate: "",
        workEndDate: "",
        workStartDate: "",
        status: true,
        isTrue: false,
        serialNumber: "",
        productRevisionNo: "",
        drawinRevisionNo: "",
        bomRevisionNo: "",
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalCustomer &&
      this.props.location.modalAddedCustomer !== undefined &&
      this.props.location.modalAddedCustomer.isModal !== undefined &&
      this.props.location.modalAddedCustomer.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/customers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            customerArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCustomer.data &&
              element.name === this.props.location.modalAddedCustomer.data
            ) {
              this.setState({
                customerGuid: element.guid,
                customerError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCustomer.isModal = true;
      this.setState({
        isOpenModalCustomer: false,
      });
    }
    if (
      this.state.isOpenModalProduct &&
      this.props.location.modalAddedProductRecipe !== undefined &&
      this.props.location.modalAddedProductRecipe.isModal !== undefined &&
      this.props.location.modalAddedProductRecipe.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/productRecipes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.productGuid,
              label: `${item.productCode}-${item.productName}`,
            };
          });
          this.setState({
            productArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProductRecipe.data &&
              `${element.productCode}-${element.productName}` ===
              this.props.location.modalAddedProductRecipe.data
            ) {
              this.setState({
                productGuid: element.productGuid,
                productError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProductRecipe.isModal = true;
      this.setState({
        isOpenModalProduct: false,
      });
    }
    if (
      this.state.isOpenModalProductMaterial &&
      this.props.location.modalAddedMaterial !== undefined &&
      this.props.location.modalAddedMaterial.isModal !== undefined &&
      this.props.location.modalAddedMaterial.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/materials/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}`,
            };
          });
          this.setState({
            workProductArr: myArr,
            isLoading: false,
          });
          let productData = this.state.productData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMaterial.data &&
              `${element.code}-${element.name}` ===
              this.props.location.modalAddedMaterial.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              productData[this.state.modalButtonIdx].materialGuid = element.guid;
              productData[this.state.modalButtonIdx].materialProductError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
            isNull1: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMaterial.isModal = true;
      this.setState({
        isOpenModalProductMaterial: false,
      });
    }
    if (
      this.state.isOpenModalQualityMaterial &&
      this.props.location.modalAddedMaterial !== undefined &&
      this.props.location.modalAddedMaterial.isModal !== undefined &&
      this.props.location.modalAddedMaterial.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/materials/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}`,
            };
          });
          this.setState({
            workQualityArr: myArr,
            isLoading: false,
          });
          let qualityData = this.state.qualityData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedMaterial.data &&
              `${element.code}-${element.name}` ===
              this.props.location.modalAddedMaterial.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              qualityData[this.state.modalButtonIdx].materialGuid = element.guid;
              qualityData[this.state.modalButtonIdx].materialProductError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
            isNull2: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedMaterial.isModal = true;
      this.setState({
        isOpenModalQualityMaterial: false,
      });
    }
    if (
      this.state.isOpenModalWorkOrderStep &&
      this.props.location.modalAddedWorkOrderStep !== undefined &&
      this.props.location.modalAddedWorkOrderStep.isModal !== undefined &&
      this.props.location.modalAddedWorkOrderStep.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/workOrderSteps/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            stepArr: myArr,
            isLoading: false,
          });
          let stepData = this.state.stepData;
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedWorkOrderStep.data &&
              element.name ===
              this.props.location.modalAddedWorkOrderStep.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              stepData[this.state.modalButtonIdx].workOrderStepGuid = element.guid;
              stepData[this.state.modalButtonIdx].workOrderStepError = "";
            }
          });
          this.setState({
            alertErrorMessage: "",
            isNull3: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedWorkOrderStep.isModal = true;
      this.setState({
        isOpenModalWorkOrderStep: false,
      });
    }
    if (
      this.state.isOpenModalEmployee &&
      this.props.location.modalAddedEmployee !== undefined &&
      this.props.location.modalAddedEmployee.isModal !== undefined &&
      this.props.location.modalAddedEmployee.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/members/getmembers/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.firstName} ${item.lastName}`,
            };
          });
          this.setState({
            personelArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedEmployee.data &&
              `${element.firstName} ${element.lastName}` ===
              this.props.location.modalAddedEmployee.data
            ) {
              this.setState({
                personelGuid: element.guid,
                personelError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedEmployee.isModal = true;
      this.setState({
        isOpenModalEmployee: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  barcodeGenerator = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/workInstructions/barcodenumbergenerate", config)
      .then((response) => {
        this.setState({
          code: response.data,
          codeError: "",
          alertErrorMessage: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalCustomer = () => {
    this.setState({
      isOpenModalCustomer: !this.state.isOpenModalCustomer,
    });
  };


  addModalEmployee = () => {
    this.setState({
      isOpenModalEmployee: !this.state.isOpenModalEmployee,
    });
  };

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };

  addModalProductMaterial = async (idx) => {
    await this.setState({
      isOpenModalProductMaterial: !this.state.isOpenModalProductMaterial,
      modalButtonIdx: idx,
    });
  };

  addModalQualityMaterial = async (idx) => {
    await this.setState({
      isOpenModalQualityMaterial: !this.state.isOpenModalQualityMaterial,
      modalButtonIdx: idx,
    });
  };

  addModalWorkOrderStep = async (idx) => {
    await this.setState({
      isOpenModalWorkOrderStep: !this.state.isOpenModalWorkOrderStep,
      modalButtonIdx: idx,
    });
  };

  handleStatusChangeSelectChange(status) {
    if (status) {
      this.setState({
        statusChange: status.value,
      });
    }
  }

  handleProductAdd = async () => {
    let array = this.state.productData;
    await array.push({
      materialGuid: "",
      materialProductError: "",
    });
    await this.setState({
      counter1: this.state.counter1 + 1,
    });
    if (array[this.state.counter1].materialGuid) {
      return array[this.state.counter1].materialProductError === "";
    }
    if (array[this.state.counter1].materialGuid === "") {
      this.setState({
        isNull1: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productData: array });
  };


  handleQualityAdd = async () => {
    let array = this.state.qualityData;
    await array.push({
      materialGuid: "",
      materialQualityError: "",
    });
    await this.setState({
      counter2: this.state.counter2 + 1,
    });
    if (array[this.state.counter2].materialGuid) {
      return array[this.state.counter2].materialQualityError === "";
    }
    if (array[this.state.counter2].materialGuid === "") {
      this.setState({
        isNull2: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ qualityData: array });
  };

  handleStepAdd = async () => {
    let array = this.state.stepData;
    await array.push({
      workOrderStepGuid: "",
      workOrderStepError: "",
      stepImageDto: [],
    });
    await this.setState({
      counter3: this.state.counter3 + 1,
    });
    if (array[this.state.counter3].workOrderStepGuid) {
      return array[this.state.counter3].workOrderStepError === "";
    }
    if (array[this.state.counter3].workOrderStepGuid === "") {
      this.setState({
        isNull3: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ stepData: array });
  };

  async handleProductMaterialSelectChange(option, idx) {
    let productData = this.state.productData;
    if (option) {
      productData[idx].materialGuid = option.value;
      productData[idx].materialProductError = "";
    } else {
      productData[idx].materialGuid = "";
      productData[idx].materialProductError = CgnMessage.textErrorMessage;
    }

    this.setState({ productData: productData });
  }

  async handleQualityMaterialSelectChange(option, idx) {
    let qualityData = this.state.qualityData;
    if (option) {
      qualityData[idx].materialGuid = option.value;
      qualityData[idx].materialQualityError = "";
    } else {
      qualityData[idx].materialGuid = "";
      qualityData[idx].materialQualityError = CgnMessage.textErrorMessage;
    }

    this.setState({ qualityData: qualityData });
  }

  async handleWorkOrderStepSelectChange(option, idx) {
    let stepData = this.state.stepData;
    if (option) {
      stepData[idx].workOrderStepGuid = option.value;
      stepData[idx].workOrderStepError = "";
      stepData[idx].stepImageDto = option.workOrderStepImageDto;

    } else {
      stepData[idx].workOrderStepGuid = "";
      stepData[idx].workOrderStepError = CgnMessage.textErrorMessage;
      stepData[idx].stepImageDto = [];
    }

    await this.setState({ stepData: stepData });
  }

  async handlePerson2SelectChange(option, idx) {
    let stepData = this.state.stepData;
    if (option) {
      stepData[idx].personGuid = option.value;
      stepData[idx].personGuidError = "";
    } else {
      stepData[idx].personGuid = "";
      stepData[idx].personGuidError = CgnMessage.textErrorMessage;
    }
    await this.setState({ stepData: stepData });
  }

  async handlePersonWorkInstructionStatusSelectChange(option, idx) {
    let stepData = this.state.stepData;
    if (option) {
      stepData[idx].personWorkInstructionStatus = option.value;
      stepData[idx].personWorkInstructionStatusError = "";
    } else {
      stepData[idx].personWorkInstructionStatus = "";
      stepData[idx].personWorkInstructionStatusError = CgnMessage.textErrorMessage;
    }
    await this.setState({ stepData: stepData });
  }



  async handleProductRemove(idx) {
    let someArray = await this.state.productData;
    await someArray.splice(idx, 1);

    await this.setState({
      productData: someArray,
      counter1: this.state.counter1 - 1,
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  async handleQualityRemove(idx) {
    let someArray = await this.state.qualityData;
    await someArray.splice(idx, 1);

    await this.setState({
      qualityData: someArray,
      counter2: this.state.counter2 - 1,
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  async handleStepRemove(idx) {
    let someArray = await this.state.stepData;
    await someArray.splice(idx, 1);

    await this.setState({
      stepData: someArray,
      counter3: this.state.counter3 - 1,
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      pieceError: "",
      codeError: "",
      alertErrorMessage: "",
    });
    if (
      (name === "piece" && value === "") ||
      (name === "piece" && value === null)
    ) {
      this.setState({
        pieceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "code" && value === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    await this.setState({
      productGuid: "",
      customerGuid: "",
      personelGuid: "",
      productOutGuid: "",
      workDate: "",
      workStartDate: "",
      workEndDate: "",
      productOutNo: null,
      code: "",
      customerCode: "",
      name: "",
      piece: null,
      status: true,
      isTrue: false,
      serialNumber: "",
      productRevisionNo: "",
      drawinRevisionNo: "",
      bomRevisionNo: "",
      alertErrorMessage: CgnMessage.alertErrorMessage,
      productError: CgnMessage.textErrorMessage,
      pieceError: CgnMessage.textErrorMessage,
      codeError: CgnMessage.textErrorMessage,
      customerError: CgnMessage.textErrorMessage,
      personelError: CgnMessage.textErrorMessage,
      workDateError: CgnMessage.textErrorMessage,
      workStartDateError: CgnMessage.textErrorMessage,
      serialNumberError: CgnMessage.textErrorMessage,
      productRevisionNoError: CgnMessage.textErrorMessage,
      drawinRevisionNoError: CgnMessage.textErrorMessage,
      bomRevisionNoError: CgnMessage.textErrorMessage,
    });
  }

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductOutSelectChange(option) {
    if (option) {
      this.setState({
        productOutGuid: option.value,
        productOutError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productOutGuid: "",
        productOutError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePersonelSelectChange(option) {
    if (option) {
      this.setState({
        personelGuid: option.value,
        personelError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        personelGuid: "",
        personelError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePieceTextBoxChange(e) {
    this.setState({
      piece: e.target.value,
      pieceError: "",
      alertErrorMessage: "",
    });
  }

  handleWorkDateChange(workDate) {
    if (workDate) {
      this.setState({
        workDate: workDate,
        workDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        workDate: "",
        workDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleWorkStartDateChange(workStartDate) {
    if (workStartDate) {
      this.setState({
        workStartDate: workStartDate,
        workStartDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        workStartDate: "",
        workStartDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleWorkEndDateChange(workEndDate) {
    if (workEndDate) {
      this.setState({
        workEndDate: workEndDate,
        workEndDateError: "",
      });
    } else {
      this.setState({
        workEndDate: "",
      });
    }
  }

  uploadedImage2 = async (event, idx) => {
    let array1 = this.state.stepData;
    let array = this.state.stepData[idx].stepImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "application/pdf") {
        const formData = new FormData();
        formData.append("files", file);
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        await axios
          .post(URL + "/uploads/add", formData, config)
          .then((response) => {
            array.push({
              image: response.data,
              imageName: file.name
            })
            this.state.name2 += file.name + " ";
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
    array1[idx].stepImageDto = array;
    this.setState({ stepData: array1 });
  };

  deleteImage2 = (event, idx) => {
    var index = -1;
    let array1 = this.state.stepData;
    let array = this.state.stepData[idx].stepImageDto;
    array.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          array1[idx].stepImageDto = array;
          this.setState({ stepData: array1 });
        }
      }
    });
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      customerError: "",
      personelError: "",
      productOutError: "",
      workDateError: "",
      workStartDateError: "",
      codeError: "",
      productError: "",
      pieceError: "",
    });
    if (this.state.personelGuid === "") {
      this.setState({
        personelError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === "") {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.productGuid === "") {
      this.setState({
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.productOutGuid === "") {
      this.setState({
        productOutError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.code === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerCode === "") {
      this.setState({
        customerCodeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.piece === "" || this.state.piece === null) {
      this.setState({
        pieceError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.workDate === "") {
      this.setState({
        workDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.workStartDate === "") {
      this.setState({
        workStartDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.productData !== null && this.state.productData.length > 0) {
      this.state.productData.forEach((element) => {
        if (element.materialGuid === "") {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            materialProductError: CgnMessage.alertErrorMessage,
          });
        }
      })
    }
    var control2 = true;
    if (this.state.qualityData !== null && this.state.qualityData.length > 0) {
      this.state.qualityData.forEach((element) => {
        if (element.materialGuid === "") {
          control2 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            materialQualityError: CgnMessage.alertErrorMessage,
          });
        }
      })
    }
    var control3 = true;
    if (this.state.stepData !== null && this.state.stepData.length > 0) {
      this.state.stepData.forEach((element) => {
        if (element.workOrderStepGuid === "" ||
          element.personGuid === "" ||
          element.personWorkInstructionStatus === "") {
          control3 = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
            workOrderStepError: CgnMessage.alertErrorMessage,
          });
        }
      })
    }

    if (
      this.state.customerGuid !== "" &&
      this.state.code !== "" &&
      this.state.customerCode !== "" &&
      this.state.personelGuid !== "" &&
      this.state.productGuid !== "" &&
      this.state.piece !== null &&
      control === true &&
      control2 === true &&
      control3 === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        customerGuid: this.state.customerGuid,
        personelGuid: this.state.personelGuid,
        productGuid: this.state.productGuid,
        code: this.state.code,
        customerCode: this.state.customerCode,
        workStartDate: this.state.workStartDate,
        workDate: this.state.workDate,
        productOutGuid: this.state.productOutGuid,
        piece: parseInt(this.state.piece),
        description: this.state.description,
        workEndDate: this.state.workEndDate,
        productOutNo: 1,
        workInstructionProductDto: this.state.productData,
        workInstructionQualityDto: this.state.qualityData,
        workInstructionStepDto: this.state.stepData,
        status: true,
        statusChange: 1,
        serialNumber: this.state.serialNumber,
        productRevisionNo: this.state.productRevisionNo,
        drawinRevisionNo: this.state.drawinRevisionNo,
        bomRevisionNo: this.state.bomRevisionNo,
      };
      var URLParam = "/workInstructions/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "is-emri-guncelle"
      ) {
        URLParam = "/workInstructions/update";
        myObj = {
          guid: this.state.guid,
          customerGuid: this.state.customerGuid,
          personelGuid: this.state.personelGuid,
          productGuid: this.state.productGuid,
          code: this.state.code,
          customerCode: this.state.customerCode,
          workStartDate: this.state.workStartDate,
          workDate: this.state.workDate,
          productOutGuid: this.state.productOutGuid,
          piece: parseInt(this.state.piece),
          description: this.state.description,
          workEndDate: this.state.workEndDate,
          productOutNo: 1,
          workInstructionProductDto: this.state.productData,
          workInstructionQualityDto: this.state.qualityData,
          workInstructionStepDto: this.state.stepData,
          status: true,
          statusChange: this.state.statusChange,
          serialNumber: this.state.serialNumber,
          productRevisionNo: this.state.productRevisionNo,
          drawinRevisionNo: this.state.drawinRevisionNo,
          bomRevisionNo: this.state.bomRevisionNo,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "is-emri-guncelle"
            ) {
              window.location.href = "/is-emri-listele";
            }
            if (this.state.urlSplit[1] === "is-emri-ekle") {
              window.location.reload();
            }
            var modalAddedWorkInstruction = {
              data: `${this.state.code}`,
              isModal: false,
            };
            this.props.history.push({ modalAddedWorkInstruction });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFiles(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    var self = this.state;
    var self1 = this;
    return (
      <>
        {(this.state.urlSplit[1] === "is-emri-ekle" ||
          this.state.urlSplit[1] === "is-emri-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="customerCode"
                      label="Müşteri İş Emri Numarası [*]"
                      value={this.state.customerCode}
                      placeHolder="Lütfen müşteri iş emri numarası girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.customerCodeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="code"
                      label="İş Emri Numarası [*]"
                      value={this.state.code}
                      placeHolder="Lütfen iş emri numarası girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.codeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnButton
                      className="barcodeButton"
                      type="button"
                      color="secondary"
                      block
                      onClick={() => this.barcodeGenerator()}
                      text={"İş Emri Numarası Üret"}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnDatePicker
                      name="workDate"
                      label="İş Emri Tarihi [*]"
                      selected={
                        this.state.workDate &&
                          this.state.workDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.workDate.split("/")[2] +
                            "-" +
                            this.state.workDate.split("/")[1] +
                            "-" +
                            this.state.workDate.split("/")[0]
                          )
                          : this.state.workDate
                      }
                      onChange={(workDate) =>
                        this.handleWorkDateChange(workDate)
                      }
                      error={this.state.workDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="serialNumber"
                      label="Seri Numarası"
                      value={this.state.serialNumber}
                      placeHolder="Lütfen seri numarası girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.serialNumberError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="productRevisionNo"
                      label="Ürün Revizyon Numarası"
                      value={this.state.productRevisionNo}
                      placeHolder="Lütfen ürün revizyon numarası girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.productRevisionNoError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="drawinRevisionNo"
                      label="Çizim Revizyon Numarası"
                      value={this.state.drawinRevisionNo}
                      placeHolder="Lütfen çizim revizyon numarası girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.drawinRevisionNoError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="bomRevisionNo"
                      label="Bom Revizyon Numarası"
                      value={this.state.bomRevisionNo}
                      placeHolder="Lütfen bom revizyon numarası girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.bomRevisionNoError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          isMulti={false}
                          selectValue={this.state.customerGuid}
                          placeholder="Müşteri seçiniz..."
                          options={this.state.customerArr}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          error={this.state.customerError}
                        />
                      </div>
                      {this.state.customerModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCustomer()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="personelGuid"
                          label="Personel [*]"
                          isMulti={false}
                          selectValue={this.state.personelGuid}
                          placeholder="Personel seçiniz..."
                          options={this.state.personelArr}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePersonelSelectChange(option)
                          }
                          error={this.state.personelError}
                        />
                      </div>
                      {this.state.membershipModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalEmployee()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="workStartDate"
                      label="Başlangıç Tarihi [*]"
                      selected={
                        this.state.workStartDate &&
                          this.state.workStartDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.workStartDate.split("/")[2] +
                            "-" +
                            this.state.workStartDate.split("/")[1] +
                            "-" +
                            this.state.workStartDate.split("/")[0]
                          )
                          : this.state.workStartDate
                      }
                      onChange={(workStartDate) =>
                        this.handleWorkStartDateChange(workStartDate)
                      }
                      error={this.state.workStartDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="workEndDate"
                      label="Planlı Bitiş Tarihi"
                      selected={
                        this.state.workEndDate &&
                          this.state.workEndDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.workEndDate.split("/")[2] +
                            "-" +
                            this.state.workEndDate.split("/")[1] +
                            "-" +
                            this.state.workEndDate.split("/")[0]
                          )
                          : this.state.workEndDate
                      }
                      onChange={(workEndDate) =>
                        this.handleWorkEndDateChange(workEndDate)
                      }
                      error={this.state.workEndDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="productOutGuid"
                      label="Satış [*]"
                      isMulti={false}
                      selectValue={this.state.productOutGuid}
                      placeholder="Satış seçiniz..."
                      options={this.state.productOutArr}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleProductOutSelectChange(option)
                      }
                      error={this.state.productOutError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="8" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          isMulti={false}
                          selectValue={this.state.productGuid}
                          placeholder="Ürün seçiniz..."
                          options={this.state.productArr}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          error={this.state.productError}
                        />
                      </div>
                      {/* {this.state.productModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalProduct()}
                            text={"+"}
                          />
                        </div>
                      )} */}
                    </div>
                  </Col>
                  <Col lg="4">
                    <CgnTextbox
                      type="number"
                      name="piece"
                      label="Ürün Adedi [*]"
                      value={this.state.piece}
                      placeHolder="Lütfen ürün adedi girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.pieceError}
                    />
                  </Col>
                </Row>

                {this.state.stepData.map((stepData, idx) => (
                  <>
                    <Row className="mobileBorder pl-0">
                      <Col lg="3" className="modalSelectCol pl-0">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="workOrderStepGuid"
                              label="İş Emri Adımı [*]"
                              placeholder="Lütfen iş emri adımı seçiniz..."
                              selectValue={stepData.workOrderStepGuid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleWorkOrderStepSelectChange(
                                  option,
                                  idx
                                );
                              }}
                              options={this.state.stepArr}
                              error={stepData.workOrderStepError}
                            />
                          </div>
                          {this.state.workOrderStepModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() => this.addModalWorkOrderStep(idx)}
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg="3" className="modalSelectCol">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="personelGuid"
                              label="Personel [*]"
                              isMulti={false}
                              selectValue={stepData.personGuid}
                              placeholder="Personel seçiniz..."
                              options={this.state.personelArr}
                              isClearable={true}
                              onChange={(option) => {
                                this.handlePerson2SelectChange(
                                  option,
                                  idx
                                );
                              }}
                              error={stepData.personGuidError}
                            />
                          </div>
                          {this.state.membershipModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() => this.addModalEmployee()}
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg="2">
                        <CgnReactSelect
                          name="personWorkInstructionStatus"
                          label="Durumu [*]"
                          isMulti={false}
                          selectValue={stepData.personWorkInstructionStatus}
                          placeholder="Durumu seçiniz..."
                          options={personWorkInstructionStatus}
                          isClearable={true}
                          onChange={(option) => {
                            this.handlePersonWorkInstructionStatusSelectChange(
                              option,
                              idx
                            );
                          }}
                          error={stepData.personWorkInstructionStatusError}
                        />
                      </Col>
                      <Col lg="2">
                        <ImageUploadMulti
                          images={stepData.stepImageDto}
                          noFoto={noImg}
                          style={fotoStyle}
                          pdfIcon={pdfIcon}
                          accept="application/pdf"
                          className="text-center text-danger"
                          label={"Dosya"}
                          onChange={(e) => this.uploadedImage2(e, idx)}
                          onDelete={(e) => this.deleteImage2(e, idx)}
                          error={stepData.imageError2}
                        />
                      </Col>
                      <Col lg="1">
                        <CgnButton
                          className="dynamicRowDeleteButton"
                          type="button"
                          color="danger"
                          onClick={() => self1.handleStepRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  </>
                ))}

                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton productAdd mb-2"
                    onClick={this.handleStepAdd}
                    text={"İş Emri Adımı Ekle"}
                  />
                  {this.state.stepDataError && (
                    <div className="invalid-feedback d-block">
                      {this.state.stepDataError}
                    </div>
                  )}
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {(this.state.urlSplit[1] === "is-emri-guncelle") &&
                  <Row>
                    <Col lg="12">
                      <CgnReactSelect
                        name="statusChange"
                        label="Durumu"
                        placeholder="Lütfen durum seçiniz..."
                        selectValue={this.state.statusChange}
                        isMulti={false}
                        options={optionWorkInstructionStatuses}
                        onChange={(status) =>
                          this.handleStatusChangeSelectChange(status)
                        }
                      />
                    </Col>
                  </Row>
                }

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "is-emri-ekle" ||
              this.state.urlSplit[1] === "is-emri-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCustomer}
          toggle={this.addModalCustomer}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Müşteri Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CustomerAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCustomer}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProduct}
          toggle={this.addModalProduct}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Reçete Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductRecipeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProductMaterial}
          toggle={this.addModalProductMaterial}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">El Aleti Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MaterialAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProductMaterial}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalQualityMaterial}
          toggle={this.addModalQualityMaterial}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">El Aleti Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <MaterialAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalQualityMaterial}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalWorkOrderStep}
          toggle={this.addModalWorkOrderStep}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">İş Adımı Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <WorkOrderStepAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalWorkOrderStep}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalEmployee}
          toggle={this.addModalEmployee}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Personel Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <EmployeeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalEmployee}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(WorkInstructionAddUpdate);
