import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  localFilePath
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class RemindList extends Component {
  state = {
    pageTitle: "Hatırlatma Listele",
    pageAddLink: "hatirlatma-ekle",
    pageUpdateLink: "hatirlatma-guncelle",
    pageDetailLink: "hatirlatma-detay",
    pageListLink: "hatirlatma-listele",
    breadcrumb: [{ text: "Hatırlatma", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    remindData: [],
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateRemind")) {
      firstDate = localStorage.getItem("firstDateRemind");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateRemind")) {
      lastDate = localStorage.getItem("lastDateRemind");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    // 

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(
        URL +
        "/reminds/getlist" +
        "/" +
        this.state.firstDate +
        "/" +
        this.state.lastDate,
        config
      )
      .then((response) => {
        this.setState({
          remindData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateRemind", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateRemind", this.state.lastDate);
  }

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/reminds/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/reminds/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        confirmAlert({
          title: CgnMessage.alertSuccessTitle,
          message: CgnMessage.deleteSuccessMessage,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                window.location.reload();
              }
            },
          ],
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.alertErrorTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.closeButton,
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              }
            },
          ],
        });
      });
  }

  thStyle = {
    width: "120px",
  };

  thStyle2 = {
    width: "100px",
  };

  tdStyle = {
    width: "150px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="remindList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-0 pr-2" lg="5">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.firstDate2.split("/")[2] +
                          "-" +
                          this.state.firstDate2.split("/")[1] +
                          "-" +
                          this.state.firstDate2.split("/")[0]
                        )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" lg="6">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                          this.state.lastDate2.split("/")[2] +
                          "-" +
                          this.state.lastDate2.split("/")[1] +
                          "-" +
                          this.state.lastDate2.split("/")[0]
                        )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-0 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.remindData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Hatırlatma Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tr>
                            <th style={this.thStyle}>Tarih</th>
                            <td style={this.tdStyle}>{row.remindDate}</td>
                            <th style={this.thStyle2}>Ad Soyad</th>
                            <td>{row.name === " " ? "-" : row.name}</td>
                          </tr>
                          <tr>
                            <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                            <td>{row.createdBy}</td>
                            <th style={this.thStyle2}>Başlık</th>
                            <td>{row.title}</td>
                          </tr>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "readStatus",
                    width: "110px",
                    sortable: false,
                    cell: (row) => {
                      if (row.readStatus > 0) {
                        return <CgnBadge color="success" text="Okundu" style={{ width: "100%" }} />;
                      } else {
                        return <CgnBadge color="danger " text="Okunmadı" style={{ width: "100%" }} />;
                      }
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" style={{ width: "96%" }} />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" style={{ width: "96%" }} />;
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: "Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfPrint(row.guid),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default RemindList;
