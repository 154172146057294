import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { GenderOptions, URL } from "../../redux/actions/constants";
import classnames from "classnames";
import IncomeList from "../income/IncomeList";
import CostList from "../cost/CostList";
import ProductEntryList from "../productEntry/ProductEntryList";
import ProductReturnList from "../productReturn/ProductReturnList";
import NoteList from "../notes/NoteList";
import NoteAddUpdate from "../notes/NoteAddUpdate";
import OrderList from "../order/OrderList";


class SupplierDetail extends Component {
  state = {
    pageTitle: "Tedarikçi Detay",
    pageTitleDetail: "Tedarikçi Detay",
    pageAddLink: "tedarikci-ekle",
    pageListLink: "tedarikci-listele",
    breadcrumb: [{ text: "Tedarikçi", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    name: "",
    taxNo: "",
    commercialRegistrationNumber: "",
    mersisNumber: "",
    taxAdministration: "",
    sector: "",
    reference: "",
    isRisk: false,
    address: "",
    districtGuid: "",
    townGuid: "",
    cityGuid: "",
    countryGuid: "",
    districtName: "",
    townName: "",
    cityName: "",
    countryName: "",
    zipCode: "",
    fax: "",
    phone: "",
    eMail: "",
    webSite: "",
    description: "",
    connectionName: "",
    connectionDegree: "",
    connectionDepartment: "",
    connectionPhone: "",
    connectionEMail: "",
    connectionGender: "",
    connectionDescription: "",
    supplierGroupName: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    activeTab: "1",
    supplierDetail: "1",
    incomeList: "2",
    costList: "3",
    productEntryList: "4",
    returnList: "5",
    notes: "6",
    orders: "7",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/suppliers/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            name: response.data.name,
            taxNo: response.data.taxNo,
            commercialRegistrationNumber: response.data.commercialRegistrationNumber,
            mersisNumber: response.data.mersisNumber,
            taxAdministration: response.data.taxAdministration,
            sector: response.data.sector,
            reference: response.data.reference,
            isRisk: response.data.isRisk,
            address: response.data.address,
            countryGuid: response.data.countryGuid,
            cityGuid: response.data.cityGuid,
            townGuid: response.data.townGuid,
            districtGuid: response.data.districtGuid,
            supplierGroupName: response.data.supplierGroupName,
            countryName: response.data.countryName,
            cityName: response.data.cityName,
            townName: response.data.townName,
            districtName: response.data.districtName,
            zipCode: response.data.zipCode,
            fax: response.data.fax,
            phone: response.data.phone,
            eMail: response.data.eMail,
            webSite: response.data.website,
            description: response.data.description,
            connectionName: response.data.connectionName,
            connectionDegree: response.data.connectionDegree,
            connectionDepartment: response.data.connectionDepartment,
            connectionPhone: response.data.connectionPhone,
            connectionEMail: response.data.connectionEMail,
            connectionGender: response.data.connectionGender,
            connectionDescription: response.data.connectionDescription,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  render() {
    var phone = this.state.phone,
      maskPhone = `${phone.substring(0, 1)}  ${
        "(" + phone.substring(1, 4) + ")"
      } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
        9,
        11
      )}`;

    if (this.state.connectionPhone) {
      var connectionPhone = this.state.connectionPhone,
        maskconnectionPhone = `${connectionPhone.substring(0, 1)}  ${
          "(" + connectionPhone.substring(1, 4) + ")"
        } ${connectionPhone.substring(4, 7)} ${connectionPhone.substring(
          7,
          9
        )} ${connectionPhone.substring(9, 11)}`;
    }

    if (this.state.fax) {
      var fax = this.state.fax,
        maskFax = `${fax.substring(0, 1)}  ${
          "(" + fax.substring(1, 4) + ")"
        } ${fax.substring(4, 7)} ${fax.substring(7, 9)} ${fax.substring(
          9,
          11
        )}`;
    }

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="customerDetail">
          <Col>
            <CgnLoader visibled={this.state.isLoading} />
            {this.state.alertErrorMessage && (
              <CgnAlert
                color="danger"
                title="Uyarı"
                text={this.state.alertErrorMessage}
              />
            )}

            <Nav tabs className="mt-3">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === this.state.supplierDetail,
                  })}
                  onClick={() => {
                    this.toggle(this.state.supplierDetail);
                  }}
                >
                  TEDARİKÇİ DETAY
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === this.state.incomeList,
                  })}
                  onClick={() => {
                    this.toggle(this.state.incomeList);
                  }}
                >
                  GELİR
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === this.state.costList,
                  })}
                  onClick={() => {
                    this.toggle(this.state.costList);
                  }}
                >
                  GİDER
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      this.state.activeTab === this.state.productEntryList,
                  })}
                  onClick={() => {
                    this.toggle(this.state.productEntryList);
                  }}
                >
                  ALIŞ
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === this.state.returnList,
                  })}
                  onClick={() => {
                    this.toggle(this.state.returnList);
                  }}
                >
                  İADE
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === this.state.notes,
                  })}
                  onClick={() => {
                    this.toggle(this.state.notes);
                  }}
                >
                  NOTLAR
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === this.state.orders,
                  })}
                  onClick={() => {
                    this.toggle(this.state.orders);
                  }}
                >
                  SİPARİŞLER
                </NavLink>
              </NavItem>
            </Nav>

            <Card body className="customerDetailCard table-responsive">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={this.state.supplierDetail}>
                  <Table className="table table-responsive table-hover">
                    <tbody>
                      <tr>
                        <th>Tedarikçi</th>
                        <td colSpan="4">
                          {this.state.name ? this.state.name : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Vergi Dairesi</th>
                        <td colSpan="4">
                          {this.state.taxAdministration
                            ? this.state.taxAdministration
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Vergi Numarası</th>
                        <td colSpan="4">
                          {this.state.taxNo ? this.state.taxNo : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Ticari Sicil Numarası</th>
                        <td colSpan="4">
                          {this.state.commercialRegistrationNumber ? this.state.commercialRegistrationNumber : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Mersis Numarası</th>
                        <td colSpan="4">
                          {this.state.mersisNumber ? this.state.mersisNumber : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Sektör</th>
                        <td colSpan="4">
                          {this.state.sector ? this.state.sector : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Referans</th>
                        <td colSpan="4">
                          {this.state.reference ? this.state.reference : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Risk</th>
                        <td colSpan="4">{this.state.isRisk ? "Var" : "Yok"}</td>
                      </tr>

                      <tr>
                        <th>Tedairkçi Grubu</th>
                        <td colSpan="4">
                          {this.state.supplierGroupName ? this.state.supplierGroupName : "-"}
                        </td>
                      </tr>


                      <tr>
                        <th>Adres</th>
                        <td colSpan="4">
                          {this.state.address ? this.state.address : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Mahalle/İlçe/Şehir/Ülke</th>
                        <td colSpan="4">
                          {`${
                            this.state.districtName
                              ? this.state.districtName
                              : "-"
                          } / ${
                            this.state.townName ? this.state.townName : "-"
                          } / ${
                            this.state.cityName ? this.state.cityName : "-"
                          } / ${
                            this.state.countryName
                              ? this.state.countryName
                              : "-"
                          }`}
                        </td>
                      </tr>

                      <tr>
                        <th>Posta Kodu</th>
                        <td colSpan="4">
                          {this.state.zipCode ? this.state.zipCode : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Telefon</th>
                        <td colSpan="4">{maskPhone ? maskPhone : "-"}</td>
                      </tr>

                      <tr>
                        <th>Faks</th>
                        <td colSpan="4">{maskFax ? maskFax : "-"}</td>
                      </tr>

                      <tr>
                        <th>E-Mail</th>
                        <td colSpan="4">
                          {this.state.eMail ? this.state.eMail : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Web Site</th>
                        <td colSpan="4">
                          {this.state.webSite ? this.state.webSite : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Açıklama</th>
                        <td colSpan="4">
                          {this.state.description
                            ? this.state.description
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Ad Soyad</th>
                        <td colSpan="4">
                          {this.state.connectionName
                            ? this.state.connectionName
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Ünvan</th>
                        <td colSpan="4">
                          {this.state.connectionDegree
                            ? this.state.connectionDegree
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Departman</th>
                        <td colSpan="4">
                          {this.state.connectionDepartment
                            ? this.state.connectionDepartment
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Cep Telefonu</th>
                        <td colSpan="4">
                          {maskconnectionPhone ? maskconnectionPhone : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>E-Mail</th>
                        <td colSpan="4">
                          {this.state.connectionEMail
                            ? this.state.connectionEMail
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Cinsiyet</th>
                        <td colSpan="4">
                          {GenderOptions(this.state.connectionGender)}
                        </td>
                      </tr>

                      <tr>
                        <th>Açıklama</th>
                        <td colSpan="4">
                          {this.state.connectionDescription
                            ? this.state.connectionDescription
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Durumu</th>
                        <td colSpan="4">
                          {this.state.status ? "Aktif" : "Pasif"}
                        </td>
                      </tr>

                      <tr>
                        <th>Kaydeden Kullanıcı</th>
                        <td>
                          {this.state.createdBy ? this.state.createdBy : "-"}
                        </td>
                        <th>Kaydedilme Tarihi</th>
                        <td>
                          {this.state.createdAt ? this.state.createdAt : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Son Güncelleyen Kullanıcı</th>
                        <td>
                          {this.state.updatedBy ? this.state.updatedBy : "-"}
                        </td>
                        <th>Son Güncellenme Tarihi</th>
                        <td>
                          {this.state.updatedAt ? this.state.updatedAt : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId={this.state.incomeList}>
                  <IncomeList />
                </TabPane>
                <TabPane tabId={this.state.costList}>
                  <CostList />
                </TabPane>
                <TabPane tabId={this.state.productEntryList}>
                  <ProductEntryList />
                </TabPane>
                <TabPane tabId={this.state.returnList}>
                  <ProductReturnList />
                </TabPane>
                <TabPane tabId={this.state.notes}>
                  <NoteAddUpdate />
                  <NoteList />
                </TabPane>
                <TabPane tabId={this.state.orders}>
                  <OrderList />
                </TabPane>
              </TabContent>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default SupplierDetail;
