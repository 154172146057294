import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  resizeFile,
  fotoStyle,
  
} from "../../redux/actions/constants";
import ImageUpload from "../toolbox/CgnImageUpload";
import noFotoMember from "../../images/no-foto-user.jpg";
import { CgnEMailControl } from "../toolbox/CgnEMailControl";
import { CgnPhoneControl } from "../toolbox/CgnPhoneControl";
import { CgnPasswordControl } from "../toolbox/CgnPasswordControl";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

class UserInfoUpdate extends Component {
  state = {
    pageTitle: "Kullanıcı Bilgilerini Güncelle",
    pageTitleUpdate: "Kullanıcı Bilgilerini Güncelle",
    pageAddLink: "kullanıcı-bilgilerini-guncelle",
    pageListLink: "kullanıcı-listele",
    breadcrumb: [{ text: "Kullanıcı", link: "#" }],
    isLoading: false,
    image: "",
    title: "",
    firstName: "",
    lastName: "",
    eMail: "",
    phone: "",
    userName: "",
    password: "",
    newPassword: "",
    guid: "",
    birthDate: "",
    imageError: "",
    firstNameError: "",
    lastNameError: "",
    eMailError: "",
    phoneError: "",
    userNameError: "",
    passwordError: "",
    newPasswordError: "",
    alertErrorMessage: "",
    alertPasswordErrorMessage: "",
    birthDateError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(URL + "/users/getprofile", config)
      .then((response) => {
        this.setState({
          image: response.data.memberDto.image,
          title: response.data.memberDto.title,
          firstName: response.data.memberDto.firstName,
          lastName: response.data.memberDto.lastName,
          eMail: response.data.memberDto.eMail,
          phone: response.data.memberDto.phone,
          guid: response.data.memberDto.guid,
          birthDate: response.data.memberDto.birthDate,
          userName: response.data.userName,
          password: response.data.password,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    await this.setState({
      [name]: value,
    });
    this.setState({
      imageError: "",
      firstNameError: "",
      lastNameError: "",
      userNameError: "",
      eMailError: "",
      phoneError: "",
      passwordError: "",
      newPasswordError: "",
      alertPasswordErrorMessage: "",
      alertErrorMessage: "",
    });
    if (name === "firstName" && value === "") {
      this.setState({
        firstNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "lastName" && value === "") {
      this.setState({
        lastNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        alertPasswordErrorMessage: "",
      });
    }
    if (name === "eMail" && value === "") {
      this.setState({
        eMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "eMail" && value !== "" && !CgnEMailControl.test(value)) {
      this.setState({
        eMailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "phone" && value === "") {
      this.setState({
        phoneError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "phone" && !CgnPhoneControl.test(value)) {
      this.setState({
        phoneError: CgnMessage.phoneErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "userName" && value === "") {
      this.setState({
        userNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      (await (this.state.password !== "" || this.state.password !== null)) &&
      !CgnPasswordControl.test(this.state.newPassword)
    ) {
      this.setState({
        newPasswordError: CgnMessage.newPasswordErrorMessage,
        alertPasswordErrorMessage: CgnMessage.savePasswordControlError,
      });
    }
    if (
      (await (this.state.password === "" || this.state.password === null)) &&
      CgnPasswordControl.test(this.state.newPassword)
    ) {
      this.setState({
        passwordError: CgnMessage.passwordErrorMessage,
        alertPasswordErrorMessage: CgnMessage.savePasswordControlError,
      });
    }
    if (
      (await (this.state.password === "" || this.state.password === null)) &&
      (this.state.newPassword === "" || this.state.newPassword === null)
    ) {
      this.setState({
        newPasswordError: "",
        passwordError: "",
        alertPasswordErrorMessage: "",
      });
    }
  };

  handleDateChange(startDate) {
    this.setState({ startDate: startDate });
  }

  handleDateChange2(endDate) {
    this.setState({ endDate: endDate });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      image: "",
      title: "",
      firstName: "",
      lastName: "",
      eMail: "",
      phone: "",
      userName: "",
      password: "",
      newPassword: "",

      firstNameError: CgnMessage.textErrorMessage,
      lastNameError: CgnMessage.textErrorMessage,
      eMailError: CgnMessage.textErrorMessage,
      phoneError: CgnMessage.textErrorMessage,
      userNameError: CgnMessage.textErrorMessage,
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      imageError: "",
      firstNameError: "",
      lastNameError: "",
      userNameError: "",
      eMailError: "",
      phoneError: "",
      passwordError: "",
      newPasswordError: "",
      alertPasswordErrorMessage: "",
      alertErrorMessage: "",
    });
    if (this.state.firstName === "") {
      this.setState({
        firstNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.lastName === "") {
      this.setState({
        lastNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.phone === "") {
      this.setState({
        phoneError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.phone !== "" && !CgnPhoneControl.test(this.state.phone)) {
      this.setState({
        phoneError: CgnMessage.phoneErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.eMail === "") {
      this.setState({
        eMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.eMail !== "" && !CgnEMailControl.test(this.state.eMail)) {
      this.setState({
        eMailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.userName === "") {
      this.setState({
        userNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      (await (this.state.password !== "" || this.state.password !== null)) &&
      !CgnPasswordControl.test(this.state.newPassword)
    ) {
      this.setState({
        newPasswordError: CgnMessage.newPasswordErrorMessage,
        alertPasswordErrorMessage: CgnMessage.savePasswordControlError,
      });
    }

    if (
      (await (this.state.password === "" || this.state.password === null)) &&
      CgnPasswordControl.test(this.state.newPassword)
    ) {
      this.setState({
        passwordError: CgnMessage.passwordErrorMessage,
        alertPasswordErrorMessage: CgnMessage.savePasswordControlError,
      });
    }

    if (
      (await (this.state.password === "" || this.state.password === null)) &&
      (this.state.newPassword === "" || this.state.newPassword === null)
    ) {
      this.setState({
        newPasswordError: "",
        passwordError: "",
        alertPasswordErrorMessage: "",
      });
    }
    if (
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.eMail !== "" &&
      CgnEMailControl.test(this.state.eMail) &&
      this.state.phone !== "" &&
      CgnPhoneControl.test(this.state.phone) &&
      this.state.userName !== "" &&
      (((this.state.password === "" || this.state.password === null) &&
        (this.state.newPassword === "" || this.state.newPassword === null)) ||
        ((this.state.password !== "" || this.state.password !== null) &&
          CgnPasswordControl.test(this.state.newPassword)))
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        memberDto: {
          image: this.state.image,
          title: this.state.title,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          eMail: this.state.eMail,
          phone: this.state.phone,
          guid: this.state.guid,
          birthDate: this.state.birthDate
            ? moment(this.state.birthDate).format("YYYY-MM-DD")
            : null,
          status: true,
        },
        userName: this.state.userName,
        password: this.state.password,
        newPassword: this.state.newPassword,
      };
      var URLParam = "/users/profileupdate";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          confirmAlert({
            title: CgnMessage.alertSuccessTitle,
            message: CgnMessage.updateSuccessMessage,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  window.location.reload();
                }
              },
            ],
          });
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    }
  };

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      image: image,
      imageName: event.target.files[0].name,
      imageError: "",
      alertErrorMessage: "",
    });
  };

  deleteImage = (event) => {
    this.setState({
      image: "",
      imageName: "",
      imageError: CgnMessage.textErrorMessage,
    });
  };

  handleBirthDateChange(birthDate) {
    this.setState({ birthDate: birthDate });
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Fotoğraf"
                      image={this.state.image}
                      noFoto={noFotoMember}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      error={this.state.imageError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="title"
                      label="Ünvan"
                      value={this.state.title}
                      placeHolder="Lütfen ünvan girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.titleError}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="firstName"
                      label="Ad [*]"
                      value={this.state.firstName}
                      placeHolder="Lütfen ad girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.firstNameError}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="lastName"
                      label="Soyad [*]"
                      value={this.state.lastName}
                      placeHolder="Lütfen soyad girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.lastNameError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="eMail"
                      label="E-Mail [*]"
                      value={this.state.eMail}
                      placeHolder="Lütfen e-mail girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.eMailError}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="phone"
                      label="Telefon [*]"
                      value={this.state.phone}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.phoneError}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnDatePicker
                      name="birthDate"
                      label="Doğum Tarihi"
                      selected={this.state.birthDate}
                      onChange={(birthDate) =>
                        this.handleBirthDateChange(birthDate)
                      }
                      error={this.state.birthDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="4">
                    <CgnTextbox
                      type="text"
                      name="userName"
                      label="Kullanıcı Adı [*]"
                      value={this.state.userName}
                      placeHolder="Lütfen kullanıcı adı girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.userNameError}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="password"
                      name="password"
                      label="Eski Şifre"
                      value={this.state.password}
                      placeHolder="Lütfen eski şifrenizi girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.passwordError}
                    />
                  </Col>

                  <Col lg="4">
                    <CgnTextbox
                      type="password"
                      name="newPassword"
                      label="Yeni Şifre"
                      value={this.state.newPassword}
                      placeHolder="Lütfen yeni şifrenizi girin..."
                      maxLength="50"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.newPasswordError}
                    />
                  </Col>
                </Row>

                {(this.state.alertErrorMessage ||
                  this.state.alertPasswordErrorMessage) && (
                    <CgnAlert
                      color="danger"
                      title={CgnMessage.alertErrorTitle}
                      text={
                        this.state.alertErrorMessage ||
                        this.state.alertPasswordErrorMessage
                      }
                    />
                  )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default UserInfoUpdate;
