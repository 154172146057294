import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
import CgnButton from "../toolbox/CgnButton";
import EmployeeAddUpdate from "../employee/EmployeeAddUpdate";
import CgnTextbox from "../toolbox/CgnTextbox";
import TaskCategoryAddUpdate from "../taskCategory/TaskCategoryAddUpdate";
import TaskPriorityAddUpdate from "../taskPriority/TaskPriorityAddUpdate";
import TaskSituationAddUpdate from "../taskSituation/TaskSituationAddUpdate";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import CgnTextEditor from "../toolbox/CgnTextEditor";

class TaskAddUpdate extends Component {
  state = {
    pageTitle: "Görev Atama Ekle",
    pageTitleAdd: "Görev Atama Ekle",
    pageTitleUpdate: "Görev Atama Güncelle",
    pageAddLink: "gorev-atama-ekle",
    pageListLink: "gorev-atama-listele",
    breadcrumb: [{ text: "Görev Atama", link: "#" }],
    isLoading: false,
    taskPriorityArr: [],
    taskSituationArr: [],
    taskCategoryArr: [],
    personelArr: [],
    urlSplit: [],
    guid: "",
    personName: "",
    taskName: "",
    startDate: null,
    endDate: null,
    status: true,
    nameError: "",
    taskCategoryGuid: null,
    taskPriorityGuid: null,
    taskSituationGuid: null,
    personGuid: null,
    isNull: false,
    isOpenModalTaskCategory: false,
    isOpenModalTaskPrioirity: false,
    isOpenModalTaskSituation: false,
    isOpenModalEmployee: false,
    modalButtonIdx: 0,
    taskDescription: "",
    descriptionHtml: "",
    startDateError: "",
    endDateError: "",
    taskCategoryNameError: "",
    taskPriorityNameError: "",
    taskSituationNameError: "",
    personelNameError: "",
    taskNameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    taskCategoryModalPlus: false,
    taskPriorityModalPlus: false,
    taskSituationModalPlus: false,
    personelModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      taskCategoryModalPlus: await UserOperationClaimControl(1091),
      taskPriorityModalPlus: await UserOperationClaimControl(1092),
      taskSituationModalPlus: await UserOperationClaimControl(1093),
      personelModalPlus: await UserOperationClaimControl(1020),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için

    // 
    config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
            personGuid: item.personGuid,
          };
        });
        this.setState({
          personelArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/taskPriorities/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          taskPriorityArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/taskSituation/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          taskSituationArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    await axios
      .get(URL + "/taskCategories/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          taskCategoryArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/tasks/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              personName: response.data.personName,
              personGuid: response.data.personGuid,
              taskCategoryGuid: response.data.taskCategoryGuid,
              taskPriorityGuid: response.data.taskPriorityGuid,
              taskSituationGuid: response.data.taskSituationGuid,
              startDate: response.data.startDate,
              endDate: response.data.endDate,
              note: response.data.note === null ? "-" : response.data.note,
              taskCategoryName: response.data.taskCategoryName,
              taskPriorityName: response.data.taskPriorityName,
              taskSituationName: response.data.taskSituationName,
              taskName: response.data.taskName,
              descriptionHtml: response.data.taskDescription,
              taskDescription: response.data.taskDescription !== null ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(response.data.taskDescription)
                )
              ) : "",
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        taskCategoryGuid: null,
        taskPriorityGuid: null,
        taskSituationGuid: null,
        personGuid: null,
        endDate: "",
        startDate: "",
        taskName: "",
        taskDescription: "",
        descriptionHtml: "",
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalEmployee &&
      this.props.location.modalAddedEmployee !== undefined &&
      this.props.location.modalAddedEmployee.isModal !== undefined &&
      this.props.location.modalAddedEmployee.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/members/getmembers/" + 2, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.firstName} ${item.lastName}`,
            };
          });
          this.setState({
            personelArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedEmployee.data &&
              `${element.firstName} ${element.lastName}` ===
                this.props.location.modalAddedEmployee.data
            ) {
              this.setState({
                personGuid: element.guid,
                personelNameError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedEmployee.isModal = true;
      this.setState({
        isOpenModalEmployee: false,
      });
    }
    if (
      this.state.isOpenModalTaskCategory &&
      this.props.location.modalAddedTaskCategory !== undefined &&
      this.props.location.modalAddedTaskCategory.isModal !== undefined &&
      this.props.location.modalAddedTaskCategory.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/taskCategories/getlist/" + 2, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            taskCategoryArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedTaskCategory.data &&
              element.name === this.props.location.modalAddedTaskCategory.data
            ) {
              this.setState({
                taskCategoryGuid: element.guid,
                taskCategoryNameError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedTaskCategory.isModal = true;
      this.setState({
        isOpenModalTaskCategory: false,
      });
    }
    if (
      this.state.isOpenModalTaskPriority &&
      this.props.location.modalAddedTaskPriority !== undefined &&
      this.props.location.modalAddedTaskPriority.isModal !== undefined &&
      this.props.location.modalAddedTaskPriority.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/taskPriorities/getlist/" + 2, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            taskPriorityArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedTaskPriority.data &&
              element.name === this.props.location.modalAddedTaskPriority.data
            ) {
              this.setState({
                taskPriorityGuid: element.guid,
                taskPriorityNameError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedTaskPriority.isModal = true;
      this.setState({
        isOpenModalTaskPriority: false,
      });
    }
    if (
      this.state.isOpenModalTaskSituation &&
      this.props.location.modalAddedTaskSituation !== undefined &&
      this.props.location.modalAddedTaskSituation.isModal !== undefined &&
      this.props.location.modalAddedTaskSituation.isModal === false
    ) {
      // 
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/taskSituation/getlist/" + 2, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            taskSituationArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedTaskSituation.data &&
              element.name === this.props.location.modalAddedTaskSituation.data
            ) {
              this.setState({
                taskSituationGuid: element.guid,
                taskSituationNameError: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedTaskSituation.isModal = true;
      this.setState({
        isOpenModalTaskSituation: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "taskDescription" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
      });
    }
  };

  handleNameChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      taskNameError: "",
      alertErrorMessage: "",
    });
    if (name === "taskName" && value === "") {
      this.setState({
        taskNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.textErrorMessage,
      });
    }
  };

  handleEditorChange = (editorState, editorStateHtml) => {
    if (editorStateHtml) {
      this.setState({
        taskDescription: editorState,
        descriptionHtml: editorStateHtml,
        taskDescriptionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        taskDescriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      taskCategoryGuid: null,
      taskPriorityGuid: null,
      taskSituationGuid: null,
      personGuid: null,
      endDate: "",
      startDate: "",
      taskName: "",
      taskDescription: "",
      descriptionHtml: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      taskNameError: CgnMessage.textErrorMessage,
      startDateError: CgnMessage.textErrorMessage,
      endDateError: CgnMessage.textErrorMessage,
      taskCategoryNameError: CgnMessage.textErrorMessage,
      taskPriorityNameError: CgnMessage.textErrorMessage,
      taskSituationNameError: CgnMessage.textErrorMessage,
      personelNameError: CgnMessage.textErrorMessage,
    });
  }

  handleTaskCategorySelectChange(option) {
    if (option) {
      this.setState({
        taskCategoryGuid: option.value,
        taskCategoryNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        taskCategoryGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        taskCategoryNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  addModalTaskCategory = () => {
    this.setState({
      isOpenModalTaskCategory: !this.state.isOpenModalTaskCategory,
    });
  };

  handleTaskPrioritySelectChange(option) {
    if (option) {
      this.setState({
        taskPriorityGuid: option.value,
        taskPriorityNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        taskPriorityGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        taskPriorityNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  addModalTaskPriority = () => {
    this.setState({
      isOpenModalTaskPriority: !this.state.isOpenModalTaskPriority,
    });
  };

  handleTaskSituationSelectChange(option) {
    if (option) {
      this.setState({
        taskSituationGuid: option.value,
        taskSituationNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        taskSituationGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        taskSituationNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  addModalTaskSituation = () => {
    this.setState({
      isOpenModalTaskSituation: !this.state.isOpenModalTaskSituation,
    });
  };

  addModalEmployee = () => {
    this.setState({
      isOpenModalEmployee: !this.state.isOpenModalEmployee,
    });
  };

  handlePersonelSelectChange(option) {
    if (option) {
      this.setState({
        personGuid: option.value,
        personelNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        personGuid: null,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        personelNameError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleStartDateChange(startDate) {
    if (startDate) {
      this.setState({
        startDate: startDate,
        startDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        startDate: "",
        startDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleEndDateChange(endDate) {
    if (endDate) {
      this.setState({
        endDate: endDate,
        endDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        endDate: "",
        endDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      taskNameError: "",
      startDateError: "",
      endDateError: "",
      taskCategoryNameError: "",
      taskPriorityNameError: "",
      taskSituationNameError: "",
      personelNameError: "",
    });
    if (this.state.personGuid === null) {
      this.setState({
        personelNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taskName === "") {
      this.setState({
        taskNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taskCategoryGuid === null) {
      this.setState({
        taskCategoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taskPriorityGuid === null) {
      this.setState({
        taskPriorityNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taskSituationGuid === null) {
      this.setState({
        taskSituationNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.startDate === null) {
      this.setState({
        startDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.endDate === null) {
      this.setState({
        endDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.taskCategoryGuid !== null &&
      this.state.taskPriorityGuid !== null &&
      this.state.taskSituationGuid !== null &&
      this.state.personGuid !== null &&
      this.state.startDate !== "" &&
      this.state.endDate !== "" &&
      this.state.taskName !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        personGuid: this.state.personGuid,
        taskCategoryGuid: this.state.taskCategoryGuid,
        taskPriorityGuid: this.state.taskPriorityGuid,
        taskSituationGuid: this.state.taskSituationGuid,
        taskName: this.state.taskName,
        taskDescription: this.state.descriptionHtml,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        status: this.state.status,
      };
      var URLParam = "/tasks/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/tasks/update";
        myObj = {
          guid: this.state.guid,
          personGuid: this.state.personGuid,
          taskCategoryGuid: this.state.taskCategoryGuid,
          taskPriorityGuid: this.state.taskPriorityGuid,
          taskSituationGuid: this.state.taskSituationGuid,
          taskName: this.state.taskName,
          taskDescription: this.state.descriptionHtml,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/gorev-atama-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };

    return (
      <>
        {(this.state.urlSplit[1] === "gorev-atama-ekle" ||
          this.state.urlSplit[1] === "gorev-atama-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  <Col lg="4" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="personGuid"
                          label="Personel [*]"
                          placeholder="Personel seçiniz..."
                          selectValue={this.state.personGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePersonelSelectChange(option)
                          }
                          options={this.state.personelArr}
                          error={this.state.personelNameError}
                        />
                      </div>
                      {this.state.personelModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalEmployee()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4">
                    <CgnDatePicker
                      name="startDate"
                      label="Başlangıç Tarihi [*]"
                      selected={
                        this.state.startDate &&
                        this.state.startDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.startDate.split("/")[2] +
                                "-" +
                                this.state.startDate.split("/")[1] +
                                "-" +
                                this.state.startDate.split("/")[0]
                            )
                          : this.state.startDate
                      }
                      onChange={(startDate) =>
                        this.handleStartDateChange(startDate)
                      }
                      error={this.state.startDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>

                  <Col lg="4">
                    <CgnDatePicker
                      name="endDate"
                      label="Bitiş Tarihi [*]"
                      selected={
                        this.state.endDate &&
                        this.state.endDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.endDate.split("/")[2] +
                                "-" +
                                this.state.endDate.split("/")[1] +
                                "-" +
                                this.state.endDate.split("/")[0]
                            )
                          : this.state.endDate
                      }
                      onChange={(endDate) => this.handleEndDateChange(endDate)}
                      error={this.state.endDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="taskPriorityGuid"
                          label="Öncelik [*]"
                          placeholder="Öncelik seçiniz..."
                          selectValue={this.state.taskPriorityGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTaskPrioritySelectChange(option)
                          }
                          options={this.state.taskPriorityArr}
                          error={this.state.taskPriorityNameError}
                        />
                      </div>
                      {this.state.taskPriorityModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalTaskPriority()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="taskCategoryGuid"
                          label="Kategori [*]"
                          placeholder="Kategori seçiniz..."
                          selectValue={this.state.taskCategoryGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTaskCategorySelectChange(option)
                          }
                          options={this.state.taskCategoryArr}
                          error={this.state.taskCategoryNameError}
                        />
                      </div>
                      {this.state.taskCategoryModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalTaskCategory()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="4" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="taskSituationGuid"
                          label="Durumu [*]"
                          placeholder="Durum seçiniz..."
                          selectValue={this.state.taskSituationGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleTaskSituationSelectChange(option)
                          }
                          options={this.state.taskSituationArr}
                          error={this.state.taskSituationNameError}
                        />
                      </div>
                      {this.state.taskSituationModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalTaskSituation()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="taskName"
                      label="Görev Adı [*]"
                      value={this.state.taskName}
                      placeHolder="Lütfen görev adı girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleNameChange}
                      error={this.state.taskNameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextEditor
                      name="taskDescription"
                      label="Açıklama"
                      editorState={this.state.taskDescription}
                      placeHolder="Lütfen açıklama girin..."
                      onChange={(editorState) =>
                        this.handleEditorChange(
                          editorState,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                      }
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" style={{ display: "none" }}>
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "gorev-atama-ekle" ||
              this.state.urlSplit[1] === "gorev-atama-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalEmployee}
          toggle={this.addModalEmployee}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Personel Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <EmployeeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalEmployee}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalTaskCategory}
          toggle={this.addModalTaskCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Kategori Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <TaskCategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalTaskCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalTaskPriority}
          toggle={this.addModalTaskPriority}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Öncelik Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <TaskPriorityAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalTaskPriority}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalTaskSituation}
          toggle={this.addModalTaskSituation}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Durum Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <TaskSituationAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalTaskSituation}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(TaskAddUpdate);
