import React, { Component } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/tr";
import axios from "axios";
import {
  
  URL,
  Mounth,
  Year,
  MachineList,
  MembershipList,
  CustomerList,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
} from "../../redux/actions/constants";
import ContentTop from "../root/ContentTop";
import CgnLoader from "../toolbox/CgnLoader";
import { Row, Col, Card, Button, Table } from "reactstrap";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnMessage from "../toolbox/CgnMessage";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class MachineReportList extends Component {
  state = {
    pageTitle: "İş Raporları",
    pageListLink: "is-raporiari",
    pageAssignmentUpdateLink: "is-atama-guncelle",
    pageAssignmentDetailLink: "is-atama-detay",
    pageMachinesUpdateLink: "makine-servis-guncelle",
    pageMachinesDetailLink: "makine-servis-detay",
    breadcrumb: [{ text: "İş Raporları", link: "#" }],
    isLoading: false,
    status: false,
    machineReportData: [],
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
    membershipGuid: "00000000-0000-0000-0000-000000000000",
    membershipArr: [],
    machineGuid: "00000000-0000-0000-0000-000000000000",
    machineArr: [],
    selectedMonth: 0,
    selectedMonthValue: 0,
    selectedYear: 0,
    selectedYearValue: 0,
    backgroundColor: "",
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateMachineReport")) {
      firstDate = localStorage.getItem("firstDateMachineReport");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateMachineReport")) {
      lastDate = localStorage.getItem("lastDateMachineReport");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    var self = this;

    await CustomerList().then(function (data) {
      self.setState({
        customerArr: data,
      });
    });

    if (localStorage.getItem("customerGuidMachineReport")) {
      this.setState({
        customerGuid: localStorage.getItem("customerGuidMachineReport"),
      });
    }

    await MembershipList().then(function (data) {
      self.setState({
        membershipArr: data,
      });
    });

    if (localStorage.getItem("membershipGuidMachineReport")) {
      this.setState({
        membershipGuid: localStorage.getItem("membershipGuidMachineReport"),
      });
    }

    await MachineList().then(function (data) {
      self.setState({
        machineArr: data,
      });
    });

    if (localStorage.getItem("machineGuidMachineReport")) {
      this.setState({
        machineGuid: localStorage.getItem("machineGuidMachineReport"),
      });
    }

    this.getList();
  }

  getList = async () => {
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .get(
        URL +
          "/machineReports/getlist/" +
          this.state.firstDate +
          "/" +
          this.state.lastDate +
          "/" +
          this.state.membershipGuid +
          "/" +
          this.state.customerGuid +
          "/" +
          this.state.machineGuid,
        config
      )
      .then((response) => {
        this.setState({
          machineReportData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateMachineReport", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateMachineReport", this.state.lastDate);
  }

  async handleCustomerSelectChange(option) {
    if (option) {
      await this.setState({
        customerGuid: option.value,
      });
    } else {
      await this.setState({
        customerGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("customerGuidMachineReport", this.state.customerGuid);
  }

  async handleMembershipSelectChange(option) {
    if (option) {
      await this.setState({
        membershipGuid: option.value,
      });
    } else {
      await this.setState({
        membershipGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem(
      "membershipGuidMachineReport",
      this.state.membershipGuid
    );
  }

  async handleMachineSelectChange(option) {
    if (option) {
      await this.setState({
        machineGuid: option.value,
      });
    } else {
      await this.setState({
        machineGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("machineGuidMachineReport", this.state.machineGuid);
  }

  thStyle = {
    width: "75px",
  };

  tdStyle = {
    width: "350px",
  };

  tdStyle2 = {
    width: "100px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        />
        <Row>
          <Col>
            <Card body>
              <Row className="filterRow">
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="membershipGuid"
                    label="Personel"
                    placeholder="Personel seçiniz..."
                    selectValue={this.state.membershipGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleMembershipSelectChange(option)
                    }
                    options={this.state.membershipArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="3">
                  <CgnReactSelect
                    name="customerGuid"
                    label="Müşteri"
                    placeholder="Müşteri seçiniz..."
                    selectValue={this.state.customerGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCustomerSelectChange(option)
                    }
                    options={this.state.customerArr}
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnReactSelect
                    name="machineGuid"
                    label="Makine"
                    placeholder="Makine seçiniz..."
                    selectValue={this.state.machineGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleMachineSelectChange(option)
                    }
                    options={this.state.machineArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.machineReportData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Tarih",
                    selector: "date",
                    sortable: false,
                    width: "90px",
                    cell: (row) => {
                      return moment(row.date).format("DD/MM/YYYY");
                    },
                  },
                  {
                    name: "Ad",
                    selector: "name",
                    sortable: false,
                  },
                  {
                    selector: "status",
                    width: "150px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.type === 11) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: "#28A745" }}
                            text="İş Atama"
                          />
                        );
                      } else if (row.type === 12) {
                        return (
                          <CgnBadge
                            color="danger"
                            className="w-100"
                            style={{ backgroundColor: row.color }}
                            text="Servis Formu"
                          />
                        );
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => {
                      if (row.type === 11) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              // {
                              //   text: CgnMessage.updateButton,
                              //   className: "dropDownBtn",
                              //   href: `/${this.state.pageAssignmentUpdateLink}/${row.guid}`,
                              // },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageAssignmentDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      } else if (row.type === 12) {
                        return (
                          <CgnButtonDropdown
                            label={"Yönet"}
                            className="mr-0"
                            color="primary"
                            items={[
                              // {
                              //   text: CgnMessage.updateButton,
                              //   className: "dropDownBtn",
                              //   href: `/${this.state.pageMachinesUpdateLink}/${row.guid}`,
                              // },
                              {
                                text: CgnMessage.detailButton,
                                className: "dropDownBtn",
                                href: `/${this.state.pageMachinesDetailLink}/${row.guid}`,
                              },
                            ]}
                          />
                        );
                      }
                    },
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default MachineReportList;
