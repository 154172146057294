import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  
  fotoStyle,
  UserOperationClaimControl,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";
import CgnButton from "../toolbox/CgnButton";
import BrochureCategoryAddUpdate from "../BrochureCategory/BrochureCategoryAddUpdate";
class BrochureAddUpdate extends Component {
  state = {
    pageTitle: "Broşür Ekle",
    pageTitleAdd: "Broşür Ekle",
    pageTitleUpdate: "Broşür Güncelle",
    pageAddLink: "brosur-ekle",
    pageListLink: "brosur-listele",
    breadcrumb: [{ text: "Broşür", link: "#" }],
    isLoading: false,
    urlSplit: [],
    Guid: "",
    brochureCategoryGuidError: "",
    name: "",
    guid: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    brochureDocumentDto: [],
    brochureDocumentDtoError: "",
  };
  async componentDidMount() {
    this.setState({
      isLoading: true,
      brochureCategoryModalPlus: await UserOperationClaimControl(1275)
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
    await axios
      .get(URL + "/brochurecategory/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          brochureCategoryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/brochure/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              brochureCategoryGuid: response.data.brochureCategoryGuid,
              name: response.data.name,
              brochureDocumentDto: response.data.brochureDocumentDto,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        guid: "",
        brochureCategoryGuid: "",
        name: "",
        brochureDocumentDto: [],
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalBrochureCategory &&
      this.props.location.modalAddedBrochureCategory !== undefined &&
      this.props.location.modalAddedBrochureCategory.isModal !== undefined &&
      this.props.location.modalAddedBrochureCategory.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/brochurecategory/getlist/2", config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            brochureCategoryArr: myArr,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedBrochureCategory.data &&
              element.name === this.props.location.modalAddedBrochureCategory.data
            ) {
              this.setState({
                brochureCategoryGuid: element.guid,
                brochureCategoryGuidError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedBrochureCategory.isModal = true;
      this.setState({
        isOpenModalBrochureCategory: false,
      });
    }
  }
  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };
  clearPage() {
    this.setState({
      brochureCategoryGuid: "",
      brochureCategoryGuidError: CgnMessage.textErrorMessage,
      name: "",
      brochureDocumentDto: [],
      brochureDocumentDtoError: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      nameError: CgnMessage.textErrorMessage,
    });
  }
  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }
  handleBrochureCategoryGuidChange(option) {
    if (option) {
      this.setState({
        brochureCategoryGuid: option.value,
        brochureCategoryGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        brochureCategoryGuid: "",
        brochureCategoryGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  addModalBrochureCategory = () => {
    this.setState({
      isOpenModalBrochureCategory: !this.state.isOpenModalBrochureCategory,
    });
  };
  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
      brochureCategoryGuidError: "",
      brochureDocumentDtoError: "",
    });
    if (this.state.brochureCategoryGuid === "") {
      this.setState({
        brochureCategoryGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.brochureCategoryGuid !== "" && this.state.name !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        brochureCategoryGuid: this.state.brochureCategoryGuid,
        name: this.state.name,
        brochureDocumentDto: this.state.brochureDocumentDto,
        status: this.state.status,
      };
      var URLParam = "/brochure/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "brosur-guncelle"
      ) {
        URLParam = "/brochure/update";
        myObj = {
          guid: this.state.guid,
          brochureCategoryGuid: this.state.brochureCategoryGuid,
          name: this.state.name,
          brochureDocumentDto: this.state.brochureDocumentDto,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "brosur-guncelle"
            ) {
              window.location.href = "/brosur-listele";
            }
            if (this.state.urlSplit[1] === "brosur-ekle") {
              window.location.reload();
            }
            var modalAddedBrochure = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedBrochure });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  uploadedDocument = async (event) => {
    let array = this.state.brochureDocumentDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.setState({
        isLoading: true,
      });
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    }
    await this.setState({ brochureDocumentDto: array });
  };
  deleteDocument = (event) => {
    var index = -1;
    let array = this.state.brochureDocumentDto;
    this.state.brochureDocumentDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ brochureDocumentDto: array });
        }
      }
    });
  };
  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "brosur-ekle" ||
          this.state.urlSplit[1] === "brosur-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="brochureCategoryGuid"
                          label="Broşür Kategori [*]"
                          placeholder="Broşür Kategori seçiniz..."
                          selectValue={this.state.brochureCategoryGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleBrochureCategoryGuidChange(option)
                          }
                          options={this.state.brochureCategoryArr}
                          error={this.state.brochureCategoryGuidError}
                        />
                      </div>
                      {this.state.brochureCategoryModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalBrochureCategory()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Adı [*]"
                      value={this.state.name}
                      placeHolder="Lütfen adı girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pl-0">
                    <CgnImageUploadMulti
                      images={this.state.brochureDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/,.jpg, .jpeg, .png"
                      className="text-center text-danger"
                      label={"Belgeler"}
                      onChange={this.uploadedDocument}
                      onDelete={this.deleteDocument}
                      error={this.state.brochureDocumentDtoError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                  <Col lg="9"></Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "brosur-ekle" ||
              this.state.urlSplit[1] === "brosur-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalBrochureCategory}
          toggle={this.addModalBrochureCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Broşür Kategori Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <BrochureCategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalBrochureCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default withRouter(BrochureAddUpdate);
