import React, { Component } from "react";
import { Row, Col, Card, Table, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL, fotoStyle } from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";

class ProductRecipeDetail extends Component {
  state = {
    pageTitle: "Ürün Reçete Detay",
    pageTitleDetail: "Ürün Reçete Detay",
    pageAddLink: "urun-recete-ekle",
    pageListLink: "urun-recete-listele",
    breadcrumb: [{ text: "Ürün Reçete", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    image: "",
    productName: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    productRecipeProductDto: [],
    productRecipeProductionDto: [],
    productRecipeQualityDto: []
   };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/productRecipes/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            image: response.data.image,
            productName: response.data.productName,
            productRecipeProductDto: response.data.productRecipeProductDto,    
            productRecipeProductionDto: response.data.productRecipeProductionDto,
            productRecipeQualityDto: response.data.productRecipeQualityDto,      
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self = this.state;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Ürün Adı</th>
                    <td colSpan="4">
                      {this.state.productName ? this.state.productName : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Fotoğraf</th>
                    <td colSpan="4">
                      {this.state.image ? (
                        <Button
                          onClick={this.toggle}
                          className="modalButtonDetail"
                        >
                          <img
                            src={this.state.image}
                            style={fotoStyle}
                            alt={this.state.name}
                          />
                        </Button>
                      ) : (
                        <img
                          src={noImg}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      )}
                    </td>
                  </tr>
                  <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <img
                      className="modalImage"
                      src={this.state.image}
                      style={fotoStyle}
                      alt={this.state.name}
                    />
                  </Modal>
                  
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.productRecipeProductDto !== null &&
                this.state.productRecipeProductDto.length > 0 && (
                  <>
                  <div
                  className="row"
                  style={{
                    display:
                      self.productRecipeProductDto !== null && self.productRecipeProductDto.length > 0
                        ? ""
                        : "none",
                  }}
                >
                  <h2 className="mt-2">Ürünler</h2>
                </div>
                <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Ürün</th>
                      <th className="text-center">Adet</th>
                      <th>Birim</th>
                    </tr>
                    {this.state.productRecipeProductDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.productName}</td>
                          <td className="text-center">{item.piece}</td>
                          <td> {item.unit === "NIU"
                              ? "Adet"
                              : item.unit === "KGM"
                              ? "kg"
                              : item.unit === "GRM"
                              ? "gr"
                              : item.unit === "MTR"
                              ? "Metre"
                              : item.unit === "LTR"
                              ? "Litre"
                              : item.unit === "PA"
                              ? "Paket"
                              : item.unit === "BX"
                              ? "Kutu"
                              : item.unit === "CMT"
                              ? "cm"
                              : item.unit === "MTQ"
                              ? "m3"
                              : item.unit === "MTK"
                              ? "m2"
                              : item.unit === "ROLL"
                              ? "Rulo"
                              : item.unit === "SET"
                              ? "Set"
                              : item.unit === "CMQ"
                              ? "cm3"
                              : item.unit === "SAA" ? "Saat" : "-"}</td>
                        </tr>
                      );
                    })}
                  </Table>
                  </>
                )}

           {this.state.productRecipeProductionDto !== null &&
                this.state.productRecipeProductionDto.length > 0 && (
                  <>
                  <div
                  className="row"
                  style={{
                    display:
                      self.productRecipeProductionDto !== null && self.productRecipeProductionDto.length > 0
                        ? ""
                        : "none",
                  }}
                >
                  <h2 className="mt-2">Üretim Alet ve Cihazları</h2>
                </div>
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Üretim Alet veya Cihaz</th>
                      <th>Adet</th>
                    </tr>
                    {this.state.productRecipeProductionDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.materialName}</td>
                          <td>{item.piece}</td>
                        </tr>
                      );
                    })}
                  </Table>
                  </>          
                )}

            {this.state.productRecipeQualityDto !== null &&
                this.state.productRecipeQualityDto.length > 0 && (
                  <>
                 <div
                  className="row"
                  style={{
                    display:
                      self.productRecipeQualityDto !== null && self.productRecipeQualityDto.length > 0
                        ? ""
                        : "none",
                  }}
                >
                  <h2 className="mt-2">Kalite Alet ve Cihazları</h2>
                </div>
                <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Kalite Alet veya Cihaz</th>
                      <th>Adet</th>
                    </tr>
                    {this.state.productRecipeQualityDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.materialName}</td>
                          <td>{item.piece}</td>
                        </tr>
                      );
                    })}
                  </Table>
                  </>        
                )}

           </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProductRecipeDetail;
