import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  optionOfferStatusesOptions,
  URL,
  fotoStyle,
  localFilePath,
  
  fotoStyle2,
  offerStatus,
  moldingRoomStatus,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import fileIcon from "../../images/file.jpg";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import excelIcon from "../../images/xlsIcon.png";
import { isThisQuarter } from "date-fns";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import CgnTextbox from "../toolbox/CgnTextbox";
import NoteAddUpdate from "../notes/NoteAddUpdate";

class OfferSieveProductionDetail extends Component {
  state = {
    pageTitle: "Üretim Planlama Detay",
    pageTitleDetail: "Üretim Planlama Detay",
    //pageAddLink: "teklif3-ekle",
    pageListLink: "uretim-planlama",
    breadcrumb: [{ text: "Üretim Planlama", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    offerNumber: "",
    offerDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    offerStatusArr: [],
    generalDiscountTotal: null,
    generalTaxTotal: null,
    taxTotal: null,
    discountPercent: "",
    language: "",
    customerPhone: "",
    typeProductionGuid: null,
    discountValue: "",
    discountTotal: "",
    taxType: null,
    taxRate: null,
    currencyType: 0,
    description: "",
    bankName: "",
    productDto: [],
    isHiddenSatinAlma: true,
    customerEmail: "",
    offerSieveImageDto: [],
    offerSievePictureDto: [],
    productArr: [],
    productEntryDto: [],
    typeProductionArr: [],
    productName: "",
    piece: null,
    isHiddenKaliphane: true,
    unitPrice: null,
    moldDesign: "",
    orderMaterial: "",
    vertical: "",
    wire: "",
    render: "",
    foreignAffair: "",
    deliveryTime: "",
    status: "",
    createdBy: "",
    createdAt: "",
    shipmentDate: "",
    updatedAt: "",
    updatedByF: "",
    offerCoverImage: "",
    confirmationDate: "",
    warrantyPeriodName: "",
    deliveryTimeName: "",
    deliveryTypeName: "",
    paymentTypeName: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    moldingRoomImageDto: [],
    moldingRoomImageError: "",
    approvalDate: "",
    noteDto: [],
    isOpenModalNote: false,
    isOpenModalUpdateNote: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 1;
      await axios
        .get(URL + "/sieveProducts/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: `${item.code}-${item.name}`,
              buyPrice: item.buyPrice,
              code: item.code,
            };
          });
          this.setState({
            productArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/typeProductions/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            typeProductionArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/offersieves/get/" + urlSplit[2], config)
        .then((response) => {
          if (response.data.offerStatus && response.data.offerStatus !== "") {
            const offerStatus = response.data.offerStatus.split(",");
            offerStatus.forEach((element) => {
              var offerStatusObj = {};
              offerStatusObj.value = element;
              this.state.offerStatusArr.push(offerStatusObj);
            });
          } else {
            this.setState({
              offerStatusArr: [],
            });
          }
          this.setState({
            guid: response.data.guid,
            customerName: response.data.customerName,
            offerNumber: response.data.offerNumber,
            offerDate: response.data.offerDate,
            optionDate:
              response.data.optionDate === "01/01/1900"
                ? "-"
                : response.data.optionDate,
            reminderDate: response.data.reminderDate,
            language: response.data.language,
            productDto: response.data.offerSieveProductDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),
            currencyType: response.data.currencyType,
            customerEmail: response.data.customerEmail,
            customerPhone: response.data.customerPhone,
            paymentTypeName: response.data.paymentTypeName,
            deliveryTimeName: response.data.deliveryTimeName,
            deliveryTypeName: response.data.deliveryTypeName,
            warrantyPeriodName: response.data.warrantyPeriodName,
            description: response.data.description,
            offerCoverImage: response.data.offerCoverImage,
            confirmationDate: response.data.confirmationDate,
            //offerCancelText: response.data.offerCancelText,
            offerSieveImageDto: response.data.offerSieveImageDto,
            offerSievePictureDto: response.data.offerSievePictureDto,
            bankName: response.data.bankName,
            //offerStatus: offerStatus.toString(),
            typeProductionGuid: response.data.typeProductionGuid,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            moldingRoomImageDto: response.data.moldingRoomImageDto,
            approvalDate: response.data.approvalDate,
            noteDto: response.data.noteDto,
            customerAddress: response.data.customerAddress,
            customerZipCode: response.data.customerZipCode,
            customerCountryName: response.data.customerCountryName,
            customerCityName: response.data.customerCityName,
            customerDistrictName: response.data.customerDistrictName,
            customerTownName: response.data.customerTownName,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  uploadedImage3 = async (event) => {
    let array = this.state.moldingRoomImageDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    }
    await this.setState({ moldingRoomImageDto: array });
  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.moldingRoomImageDto;
    this.state.moldingRoomImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ moldingRoomImageDto: array });
        }
      }
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenSatinAlma: false,
      });
    } else {
      this.setState({
        isHiddenSatinAlma: true,
      });
    }
  };

  handleKaliphaneCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenKaliphane: false,
        isHiddenSatinAlma: true,
        productEntryDto: [],
      });
    } else {
      this.setState({
        isHiddenKaliphane: true,
      });
    }
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  // handleOfferStatusChange(option) {
  //   if (option) {
  //     this.setState({
  //       offerStatus: option.value,
  //     });
  //   } else {
  //     this.setState({
  //       offerStatus: "",
  //     });
  //   }
  // }

  handleTypeProductionChange(option) {
    if (option) {
      this.setState({
        typeProductionGuid: option.value,
      });
    } else {
      this.setState({
        typeProductionGuid: null,
      });
    }
  }

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleOfferStatusSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        offerStatusArr: option,
      });
    } else {
      this.setState({
        offerStatusArr: [],
      });
    }
  }

  handleCustomerEstimatedDeliveryDateChange2(
    customerEstimatedDeliveryDate,
    idx
  ) {
    let productDto = this.state.productDto;
    if (customerEstimatedDeliveryDate) {
      productDto[idx].customerEstimatedDeliveryDate =
        customerEstimatedDeliveryDate;
      this.setState({
        productDto: productDto,
      });
    } else {
      productDto[idx].customerEstimatedDeliveryDate = "";
      this.setState({
        productDto: productDto,
      });
    }
  }

  handleCustomerEstimatedDeliveryDatePurchasingStatusChange2(
    customerEstimatedDeliveryDatePurchasingStatus,
    idx
  ) {
    let productDto = this.state.productDto;
    if (customerEstimatedDeliveryDatePurchasingStatus) {
      productDto[idx].customerEstimatedDeliveryDatePurchasingStatus =
        customerEstimatedDeliveryDatePurchasingStatus;
      this.setState({
        productDto: productDto,
      });
    } else {
      productDto[idx].customerEstimatedDeliveryDatePurchasingStatus = "";
      this.setState({
        productDto: productDto,
      });
    }
  }

  handleIsMoldingRoomChange(option, idx) {
    let productDto = this.state.productDto;
    if (option) {
      productDto[idx].isMoldingRoom = option.value;
      this.setState({
        productDto: productDto,
      });
    } else {
      productDto[idx].isMoldingRoom = "";
      this.setState({
        productEntryDto: productDto,
      });
    }
  }

  handleIsPurchasingStatusChange(option, idx) {
    let productDto = this.state.productDto;
    if (option) {
      productDto[idx].isPurchasingStatus = option.value;
      this.setState({
        productDto: productDto,
      });
    } else {
      productDto[idx].isPurchasingStatus = "";
      this.setState({
        productEntryDto: productDto,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });

    this.setState({
      isLoading: true,
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var offerStatus = [];
    this.state.offerStatusArr.forEach((element) => {
      var offerStatusObj = {};
      offerStatusObj = element.value;
      offerStatus.push(offerStatusObj);
    });
    var myObj = {
      offerStatus: offerStatus.toString(),
      typeProductionGuid: this.state.typeProductionGuid,
      moldingRoomImageDto: this.state.moldingRoomImageDto,
      guid: this.state.guid,
      offerSieveProductDto: this.state.productDto,
    };
    var URLParam = "/offerSieves/addstatus";

    axios
      .post(URL + URLParam, myObj, config)
      .then(() => {
        setTimeout(() => {
          if (this.state.guid) {
            window.location.href = "/uretim-planlama";
          } else {
            window.location.reload();
          }
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleMoldingRoomNoteChange(e, idx) {
    let productDto = this.state.productDto;
    if (e.target.value !== "") {
      productDto[idx].moldingRoomNote = e.target.value;
    } else {
      productDto[idx].moldingRoomNote = "";
    }
    this.setState({
      productDto: productDto,
    });
  }

  addModalNote = () => {
    this.setState({
      isOpenModalNote: !this.state.isOpenModalNote,
      noteGuid: null,
    });
  };

  addModalUpdateNote = (guid) => {
    this.setState({
      isOpenModalUpdateNote: !this.state.isOpenModalUpdateNote,
      noteGuid: guid,
    });
  };

  handleNoteDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleNoteSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleNoteSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/notes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };


  render() {
    const sira = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "20px",
      textAlign: "center",
    };
    const siraTd = {
      backgroundColor: "#f00",
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    };
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const mainRowDate = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "215px",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self1 = this;
    var self = this.state;

    var phone = this.state.customerPhone,
      maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
        } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
          9,
          11
        )}`;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
          noteLink={(onClick) => this.addModalNote()}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                {/* <tr>
                  <th>Teklif Dili</th>
                  <td colSpan="4">
                    {this.state.language === 1
                      ? "Türkçe"
                      : this.state.language === 2
                      ? "İngilizce"
                      : this.state.language === 3
                      ? "Fransızca"
                      : this.state.language === 4
                      ? "Almanca"
                      : this.state.language === 5
                      ? "Arapça"
                      : "-"}
                  </td>
                </tr> */}
                <tr>
                  <th>Tarih</th>
                  <td colSpan="4">{this.state.approvalDate}</td>
                </tr>
                <tr>
                  <th>Sipariş No</th>
                  <td colSpan="4">{this.state.offerNumber}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>

                <tr>
                  <th>Müşteri Adres</th>
                  <td colSpan="4">
                    {this.state.customerAddress
                      ? this.state.customerAddress
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Müşteri Mahalle/İlçe/Şehir/Ülke</th>
                  <td colSpan="4">
                    {`${this.state.customerDistrictName
                      ? this.state.customerDistrictName + "/"
                      : ""
                      }${this.state.customerTownName
                        ? this.state.customerTownName + "/"
                        : ""
                      }${this.state.customerCityName
                        ? this.state.customerCityName + "/"
                        : ""
                      }${this.state.customerCountryName
                        ? this.state.customerCountryName
                        : "-"
                      }`}
                  </td>
                </tr>

                <tr>
                  <th>Müşteri Posta Kodu</th>
                  <td colSpan="4">
                    {this.state.customerZipCode
                      ? this.state.customerZipCode
                      : "-"}
                  </td>
                </tr>
                {/* <tr>
                  <th>Müşteri E-mail</th>
                  <td colSpan="4">
                    {this.state.customerEmail === ""
                      ? "-"
                      : this.state.customerEmail}
                  </td>
                </tr>
                <tr>
                  <th>Müşteri Telefon</th>
                  <td colSpan="4">
                    {this.state.customerPhone === "" ? "-" : maskPhone}
                  </td>
                </tr> */}
                {/* <tr>
                  <th>Teklif Tarihi</th>
                  <td colSpan="4">{this.state.offerDate}</td>
                </tr>
                <tr>
                  <th>Opsiyon Tarihi</th>
                  <td colSpan="4">{this.state.optionDate}</td>
                </tr>

                <tr>
                  <th>Hatırlatma Tarihi</th>
                  <td colSpan="4">{this.state.reminderDate}</td>
                </tr>

                <tr>
                  <th>Ara Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam İndirim</th>
                  <td colSpan="4">
                    {this.state.generalDiscountTotal
                      ? this.state.generalDiscountTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam KDV Tutarı</th>
                  <td colSpan="4">
                    {this.state.generalTaxTotal
                      ? this.state.generalTaxTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Ödeme Yöntemi </th>
                  <td colSpan="4">{this.state.paymentTypeName}</td>
                </tr> */}

                <tr>
                  <th>Teslimat Yöntemi </th>
                  <td colSpan="4">{this.state.deliveryTypeName}</td>
                </tr>

                <tr>
                  <th>Teslimat Süresi </th>
                  <td colSpan="4">{this.state.deliveryTimeName}</td>
                </tr>

                {/* <tr>
                  <th>Garanti Süresi </th>
                  <td colSpan="4">{this.state.warrantyPeriodName}</td>
                </tr> */}

                <tr>
                  <th>Teklif Notu</th>
                  <td
                    className="text-danger font-weight-bold"
                    colSpan="4"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description
                        ? this.state.description
                        : "-",
                    }}
                  ></td>
                </tr>

                {/* <tr>
                  <th>Teklif Kapağı</th>
                  <td colSpan="4">
                    {this.state.offerCoverImage ? (
                      <Button
                        onClick={this.toggle}
                        className="modalButtonDetail"
                      >
                        <img
                          src={this.state.offerCoverImage}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      </Button>
                    ) : (
                      <img
                        src={noImg}
                        style={fotoStyle}
                        alt={this.state.customerName}
                      />
                    )}
                  </td>
                </tr>

                <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}></ModalHeader>
                  <img
                    className="modalofferCoverImage"
                    src={this.state.offerCoverImage}
                    style={fotoStyle}
                    alt={this.state.customerName}
                  />
                </Modal>

                <tr>
                  <th>Teklif Onay/İptal Tarihi Saati</th>
                  <td colSpan="4">{this.state.confirmationDate}</td>
                </tr>

                <tr>
                  <th>Banka</th>
                  <td colSpan="4">{this.state.bankName}</td>
                </tr>
                <tr>

                  <th>Durum</th>
                  <td colSpan="4">
                    {optionOfferStatusesOptions(this.state.status)}
                  </td>
                </tr>*/}
                <tr>
                  <th>Onaylanma Tarihi</th>
                  <td colSpan="4">{this.state.confirmationDate}</td>
                </tr>
                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>
              <Form onSubmit={this.handleSave} className="mt-3">
                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="offerStatusArr"
                      label="Durum"
                      placeholder="Durum seçiniz..."
                      isMulti={true}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleOfferStatusSelectChange(option)
                      }
                      options={offerStatus}
                      selectValue={this.state.offerStatusArr}
                      error={this.state.paymentMethodTypeError}
                    />
                  </Col>
                  <Col lg="6">
                    <CgnReactSelect
                      name="typeProductionGuid"
                      label="Üretim Türü"
                      placeholder="Lütfen üretim türü seçiniz..."
                      selectValue={this.state.typeProductionGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => {
                        this.handleTypeProductionChange(option);
                      }}
                      options={this.state.typeProductionArr}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pl-0">
                    <ImageUploadMulti
                      images={this.state.moldingRoomImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx"
                      className="text-center text-danger"
                      label={"Kalıphane Belgeler"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      error={this.state.moldingRoomImageError}
                    />
                  </Col>
                </Row>

                {this.state.productDto !== null &&
                  this.state.productDto.length > 0 &&
                  this.state.productDto.map((productDto, idx) => (
                    <>
                      <Table className="table table-responsive table-hover table-product mt-0">
                        <tr className="">
                          <th style={sira}>Sıra</th>
                          <th className="text-left pl-3" style={mainRow}>
                            Depo
                          </th>
                          <th
                            colSpan={3}
                            className="text-left pl-3"
                            style={mainRow}
                          >
                            Ürün Adı
                          </th>
                          <th className="text-left pl-3" style={mainRow}>
                            Adet
                          </th>
                          <th className="text-left pl-3" style={mainRow}>
                            Birim
                          </th>
                          {/* <th className="text-center" style={mainRow}>Birim Fiyatı </th>
                        <th className="text-center" style={mainRow}>İndirim(Yüzde) </th>
                        <th className="text-center" style={mainRow}>İndirim(Net Tutar) </th>
                        <th className="text-center" style={mainRow}>KDV Oranı </th>
                        <th style={mainRow}>Fiyat</th> */}
                        </tr>
                        <tr style={subRow}>
                          <td style={siraTd}>{idx + 1}</td>
                          <td className="text-left pl-3">
                            {productDto.locationName}
                          </td>
                          <td colSpan={3} className="text-left pl-3">
                            {productDto.productName}
                          </td>
                          <td className="text-left pl-3">{productDto.piece}</td>
                          <td className="text-left pl-3">
                            {productDto.unit === "NIU"
                              ? "Adet"
                              : productDto.unit === "KGM"
                                ? "kg"
                                : productDto.unit === "GRM"
                                  ? "gr"
                                  : productDto.unit === "MTR"
                                    ? "Metre"
                                    : productDto.unit === "LTR"
                                      ? "Litre"
                                      : productDto.unit === "PA"
                                        ? "Paket"
                                        : productDto.unit === "BX"
                                          ? "Kutu"
                                          : productDto.unit === "CMT"
                                            ? "cm"
                                            : productDto.unit === "MTQ"
                                              ? "m3"
                                              : productDto.unit === "MTK"
                                                ? "m2"
                                                : productDto.unit === "ROLL"
                                                  ? "Rulo"
                                                  : productDto.unit === "SET"
                                                    ? "Set"
                                                    : productDto.unit === "CMQ"
                                                      ? "cm3"
                                                      : productDto.unit === "SAA" ? "Saat" : "-"}
                          </td>
                          {/* <td className="text-center">
                          {item.unitPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>

                        <td className="text-center">
                          {item.productDiscountPercent
                            ? "% " + item.productDiscountPercent
                            : "-"}
                        </td>
                        <td className="text-center">
                        {item.productDiscountValue && item.productDiscountValue > 0
                      ? item.productDiscountValue +
                        " " +
                        (item.currencyType === 1
                          ? "₺"
                          : item.currencyType === 2
                          ? "$"
                          : item.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                        </td>
                        <td className="text-center">
                          {"% " + item.productTaxRate}
                        </td>
                        <td>
                          {item.total.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td> */}
                        </tr>
                        <tr>
                          <th
                            colSpan={2}
                            style={mainRow}
                            className="text-left pl-3"
                          >
                            Makine Boyutu
                          </th>
                          <th style={mainRow} className="text-left pl-3">
                            Elek Boyutu
                          </th>
                          <th
                            colSpan={2}
                            style={mainRow}
                            className="text-left pl-3"
                          >
                            Elek Göz Boyutu
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Güncel Teslimat Tarihi
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Tahmini Teslimat Tarihi
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={2} className="text-left pl-3">
                            {productDto.machineSize !== ""
                              ? productDto.machineSize
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.sieveSize !== ""
                              ? productDto.sieveSize
                              : "-"}
                          </td>
                          <td colSpan={2} className="text-left pl-3">
                            {productDto.sieveEyeSize !== ""
                              ? productDto.sieveEyeSize
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.deliveryDateDetail !== ""
                              ? productDto.deliveryDateDetail
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.estimatedDeliveryDateDetail !== ""
                              ? productDto.estimatedDeliveryDateDetail
                              : "-"}
                          </td>
                        </tr>

                        {productDto.sieveProductPropertyDto != null &&
                          productDto.sieveProductPropertyDto.length > 0 && (
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                colSpan="7"
                                className="text-left pl-3"
                              >
                                Ürün Özellikleri
                              </th>
                            </tr>
                          )}
                        {productDto.sieveProductPropertyDto.map(function (
                          item2
                        ) {
                          return (
                            <tr style={subRow}>
                              <td colSpan={4} className="text-left pl-3">
                                {item2.propertyTypeName}
                              </td>
                              <td colSpan={3} className="text-left pl-3">
                                {" "}
                                {item2.value}
                              </td>
                            </tr>
                          );
                        })}
                        {productDto.offerSievesProductsShippingDto != null &&
                          productDto.offerSievesProductsShippingDto.length >
                          0 && (
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                colSpan="4"
                              ></th>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                className="text-left pl-3"
                              >
                                Adet
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                className="text-left pl-3"
                              >
                                Hazır Tarihi
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                className="text-left pl-3"
                              >
                                Kalem Sevkiyat
                              </th>
                            </tr>
                          )}
                        {productDto.offerSievesProductsShippingDto.map(
                          function (item2) {
                            return (
                              <tr style={subRow}>
                                <td colSpan={4} className="text-left pl-3"></td>
                                <td className="text-left pl-3">
                                  {item2.piece}
                                </td>
                                <td className="text-left pl-3">
                                  {item2.readyDateDetail}
                                </td>
                                <td className="text-left pl-3">
                                  {item2.shippingDateDetail !== "01 Ocak 1900"
                                    ? item2.shippingDateDetail
                                    : "-"}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </Table>
                      <Row>
                        <Col lg="3" className="pt-3">
                          <CgnReactSelect
                            name="isMoldingRoom"
                            label="Kalıphane Durumu"
                            placeholder="Lütfen kalıphane durumu seçiniz..."
                            selectValue={productDto.isMoldingRoom}
                            isMulti={false}
                            isClearable={true}
                            options={moldingRoomStatus}
                            onChange={(option) => {
                              this.handleIsMoldingRoomChange(option, idx);
                            }}
                          />
                        </Col>
                        <Col lg="3" className="pt-3">
                          <CgnDatePicker
                            name="customerEstimatedDeliveryDate"
                            label=" Müşteriye Tahmini Teslimat Tarihi"
                            selected={
                              productDto.customerEstimatedDeliveryDate &&
                                productDto.customerEstimatedDeliveryDate
                                  .toString()
                                  .indexOf("/") > 0
                                ? new Date(
                                  productDto.customerEstimatedDeliveryDate.split(
                                    "/"
                                  )[2] +
                                  "-" +
                                  productDto.customerEstimatedDeliveryDate.split(
                                    "/"
                                  )[1] +
                                  "-" +
                                  productDto.customerEstimatedDeliveryDate.split(
                                    "/"
                                  )[0]
                                )
                                : productDto.customerEstimatedDeliveryDate
                            }
                            onChange={(customerEstimatedDeliveryDate) =>
                              this.handleCustomerEstimatedDeliveryDateChange2(
                                customerEstimatedDeliveryDate,
                                idx
                              )
                            }
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                          />
                        </Col>
                        <Col lg="6" className="pt-3">
                          <CgnTextbox
                            type="text"
                            name="moldingRoomNote"
                            label="Kalıphane Notu"
                            value={productDto.moldingRoomNote}
                            placeHolder="Lütfen kalıphane notu girin..."
                            maxLength="500"
                            autoComplete="off"
                            onChange={(e) => this.handleMoldingRoomNoteChange(e, idx)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6"></Col>
                        <Col lg="3" className="pt-3">
                          <CgnReactSelect
                            name="isPurchasingStatus"
                            label="Satınalma Durumu"
                            placeholder="Lütfen satınalma durumu seçiniz..."
                            selectValue={productDto.isPurchasingStatus}
                            isMulti={false}
                            isClearable={true}
                            options={moldingRoomStatus}
                            onChange={(option) => {
                              this.handleIsPurchasingStatusChange(option, idx);
                            }}
                          />
                        </Col>
                        <Col lg="3" className="pt-3">
                          <CgnDatePicker
                            name="customerEstimatedDeliveryDatePurchasingStatus"
                            label=" Müşteriye Tahmini Teslimat Tarihi"
                            selected={
                              productDto.customerEstimatedDeliveryDatePurchasingStatus &&
                                productDto.customerEstimatedDeliveryDatePurchasingStatus
                                  .toString()
                                  .indexOf("/") > 0
                                ? new Date(
                                  productDto.customerEstimatedDeliveryDatePurchasingStatus.split(
                                    "/"
                                  )[2] +
                                  "-" +
                                  productDto.customerEstimatedDeliveryDatePurchasingStatus.split(
                                    "/"
                                  )[1] +
                                  "-" +
                                  productDto.customerEstimatedDeliveryDatePurchasingStatus.split(
                                    "/"
                                  )[0]
                                )
                                : productDto.customerEstimatedDeliveryDatePurchasingStatus
                            }
                            onChange={(
                              customerEstimatedDeliveryDatePurchasingStatus
                            ) =>
                              this.handleCustomerEstimatedDeliveryDatePurchasingStatusChange2(
                                customerEstimatedDeliveryDatePurchasingStatus,
                                idx
                              )
                            }
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                    </>
                  ))}

                {this.state.offerSieveImageDto &&
                  this.state.offerSieveImageDto.length > 0 && (
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Belgeler</th>
                      </tr>
                      <Row>
                        {this.state.offerSieveImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                <Button
                                  onClick={() => self1.fileClick(item.image)}
                                  className="modalButtonDetail"
                                >
                                  <img
                                    src={fileIcon}
                                    style={fotoStyle2}
                                    alt={item.imageName}
                                  />
                                </Button>
                                <p style={{ maxWidth: "150px" }}>
                                  {item.imageName}
                                </p>
                              </div>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  )}

                {this.state.offerSievePictureDto !== null &&
                  this.state.offerSievePictureDto.length > 0 && (
                    <>
                      <Table className="table table-responsive table-hover table-product2">
                        <tr style={mainRow}>
                          <th>Teklif Fotoğraf</th>
                        </tr>
                        <Row>
                          {this.state.offerSievePictureDto.map(function (item) {
                            return (
                              <>
                                <div className="pl-4">
                                  {item.image ? (
                                    <Button
                                      onClick={() => self1.toggle(item.image)}
                                      className="modalButtonDetail"
                                    >
                                      <img
                                        src={item.image}
                                        style={fotoStyle}
                                        alt={item.errorCodeName}
                                      />
                                    </Button>
                                  ) : (
                                    <img
                                      src={noImg}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  )}
                                </div>
                                <Modal
                                  isOpen={self.isOpen}
                                  toggle={self1.toggle}
                                >
                                  <ModalHeader
                                    toggle={self1.toggle}
                                  ></ModalHeader>
                                  <img
                                    className="modalImage"
                                    src={self.isImage}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                </Modal>
                              </>
                            );
                          })}
                        </Row>
                      </Table>
                    </>
                  )}
                <CgnButtonGroup className="pl-0" items={this.state.buttons} />
              </Form>

              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}

              {this.state.noteDto !== null && this.state.noteDto.length > 0 && (
                <>
                  <div
                    className="row"
                    style={{
                      display:
                        self.noteDto !== null && self.noteDto.length > 0
                          ? ""
                          : "none",
                    }}
                  >
                    <h2 className="mt-2">Not</h2>
                  </div>
                  <Table className="table table-responsive table-product2">
                    <tr className=""></tr>
                    {this.state.noteDto.map(function (item) {
                      return (
                        <tbody>
                          <>
                            <Accordion allowZeroExpanded>
                              <AccordionItem>
                                <AccordionItemHeading
                                  style={{ backgroundColor: "#9B9796" }}
                                >
                                  <AccordionItemButton>
                                    <tr className="cuttingTr4 w-100">
                                      <th
                                        className="pl-3"
                                        style={self1.thStyle3}
                                      >
                                        Not Başlığı
                                      </th>
                                      <td style={self1.tdStyle2}>
                                        {item.title}
                                      </td>
                                    </tr>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <tr></tr>
                                  <tr>
                                    <th className="pl-3">Not</th>
                                    <td>{item.description}</td>
                                  </tr>
                                  <CgnButton
                                    type="button"
                                    color="success"
                                    className="printButton ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.addModalUpdateNote(item.guid)
                                    }
                                    text="Güncelle"
                                  />
                                  <CgnButton
                                    type="button"
                                    color="danger"
                                    style={{ width: "90px" }}
                                    className="ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.handleNoteDelete(item.guid)
                                    }
                                    text="Sil"
                                  />
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </>
                        </tbody>
                      );
                    })}
                  </Table>
                </>
              )}
            </Card>

            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalNote}
          toggle={this.addModalNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.guid} updated={false} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalUpdateNote}
          toggle={this.addModalUpdateNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.noteGuid} updated={true} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default OfferSieveProductionDetail;
