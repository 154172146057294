import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  CategoryList,
} from "../../redux/actions/constants";
import CgnBadge from "../toolbox/CgnBadge";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class StockMovements extends Component {
  state = {
    pageTitle: "Stok Hareketleri",
    pageAddLink: "stok-hareketleri",
    //pageUpdateLink: "urun-guncelle",
    pageDetailLink: "urun-stok-detay",
    pageListLink: "stok-hareketleri",
    breadcrumb: [{ text: "Stok Hareketleri", link: "#" }],
    isLoading: false,
    isDeleted: false,
    status: false,
    productData: [],
    alertErrorMessage: "",
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    productGuid: "00000000-0000-0000-0000-000000000000",
    productArr: [],
    buyPriceTotal: 0,
    buyPriceTotalDolar: 0,
    buyPriceTotalEuro: 0,
    buyPriceTotalSFr: 0,
    salePriceTotal: 0,
    salePriceTotalDolar: 0,
    salePriceTotalEuro: 0,
    salePriceTotalSFr: 0,
    categoryArr: [],
    categoryGuid: "00000000-0000-0000-0000-000000000000",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateStockMovements")) {
      firstDate = localStorage.getItem("firstDateStockMovements");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateStockMovements")) {
      lastDate = localStorage.getItem("lastDateStockMovements");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    var self = this;

    await CategoryList().then(function (data) {
      self.setState({
        categoryArr: data,
      });
    });

    if (localStorage.getItem("categoryGuidStockMovements")) {
      this.setState({
        categoryGuid: localStorage.getItem("categoryGuidStockMovements"),
      });
    }

    this.getList();
  }


  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateStockMovements", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateStockMovements", this.state.lastDate);
  }

  async handleCategorySelectChange(option) {
    if (option) {
      await this.setState({
        categoryGuid: option.value,
      });
    } else {
      await this.setState({
        categoryGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("categoryGuidMachineProduct", this.state.categoryGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .get(URL + "/products/getliststock/" + this.state.categoryGuid, config)
      .then((response) => {
        this.setState({
          productData: response.data,
        });
        var buyPriceTotalLocal = 0;
        var buyPriceTotalLocalDolar = 0;
        var buyPriceTotalLocalEuro = 0;
        var buyPriceTotalLocalSFr = 0;
        var salePriceTotalLocal = 0;
        var salePriceTotalLocalDolar = 0;
        var salePriceTotalLocalEuro = 0;
        var salePriceTotalLocalSFr = 0;
        this.state.productData.forEach((element) => {
          if (element.currencyType === 1) {
            buyPriceTotalLocal += element.productStock * element.buyPrice;
            salePriceTotalLocal += element.productStock * element.salePrice;
          } else if (element.currencyType === 2) {
            buyPriceTotalLocalDolar += element.productStock * element.buyPrice;
            salePriceTotalLocalDolar += element.productStock * element.salePrice;
          } else if (element.currencyType === 3) {
            buyPriceTotalLocalEuro += element.productStock * element.buyPrice;
            salePriceTotalLocalEuro += element.productStock * element.salePrice;
          } else if (element.currencyType === 3) {
            buyPriceTotalLocalSFr += element.productStock * element.buyPrice;
            salePriceTotalLocalSFr += element.productStock * element.salePrice;
          }
        });
        this.setState({
          buyPriceTotal: buyPriceTotalLocal,
          buyPriceTotalDolar: buyPriceTotalLocalDolar,
          buyPriceTotalEuro: buyPriceTotalLocalEuro,
          buyPriceTotalSFr: buyPriceTotalLocalSFr,
          salePriceTotal: salePriceTotalLocal,
          salePriceTotalDolar: salePriceTotalLocalDolar,
          salePriceTotalEuro: salePriceTotalLocalEuro,
          salePriceTotalSFr: salePriceTotalLocalSFr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "100px",
  };

  thStyle2 = {
    width: "90px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "300px",
  };

  tdStyle3 = {
    width: "300px",
    color: "red",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          // addLink={"/" + this.state.pageAddLink}
          // listLink={"/" + this.state.pageListLink}
        />
        <Row className="criticalProductList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-0 pr-2" md="2">
                  <CgnDatePicker
                    name="firstDate"
                    label="Baş. Tarihi"
                    selected={
                      this.state.firstDate2 &&
                      this.state.firstDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.firstDate2.split("/")[2] +
                              "-" +
                              this.state.firstDate2.split("/")[1] +
                              "-" +
                              this.state.firstDate2.split("/")[0]
                          )
                        : this.state.firstDate2
                    }
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" md="2">
                  <CgnDatePicker
                    name="lastDate"
                    label="Bit. Tarihi"
                    selected={
                      this.state.lastDate2 &&
                      this.state.lastDate2.toString().indexOf("/") > 0
                        ? new Date(
                            this.state.lastDate2.split("/")[2] +
                              "-" +
                              this.state.lastDate2.split("/")[1] +
                              "-" +
                              this.state.lastDate2.split("/")[0]
                          )
                        : this.state.lastDate2
                    }
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                  />
                </Col>
                <Col className="pl-2 pr-2" lg="7">
                  <CgnReactSelect
                    name="categoryGuid"
                    label="Kategori"
                    placeholder="Kategori seçiniz..."
                    selectValue={this.state.categoryGuid}
                    isMulti={false}
                    isClearable={true}
                    onChange={(option) =>
                      this.handleCategorySelectChange(option)
                    }
                    options={this.state.categoryArr}
                  />
                </Col>
                <Col className="pl-2 pr-0" md="1">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.productData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Ürünler",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Kategori Adı</th>
                              <td style={this.tdStyle}>{row.categoryName}</td>
                              <th style={this.thStyle}>Ürün Adı</th>
                              <td style={this.tdStyle2}>{row.name}</td>
                            </tr>

                            <tr>
                              <th style={this.thStyle}>Alış Fiyatı</th>
                              <td style={this.tdStyle}>
                                {row.buyPrice > 0
                                  ? row.buyPrice.toLocaleString("tr-TR", {
                                      minimumFractionDigits: 2,
                                    }) +
                                    " " +
                                    (row.currencyType === 1
                                      ? "₺"
                                      : row.currencyType === 2
                                      ? "$"
                                      : row.currencyType === 3
                                      ? "€"
                                      : "-")
                                  : "-"}
                              </td>

                              <th style={this.thStyle}>Satış Fiyatı</th>
                              <td>
                                {row.salePrice.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Stok</th>
                              <td style={this.tdStyle}>{row.productStock}</td>

                              {row.criticalPieces ? (
                                <>
                                  <th style={this.thStyle}>Kritik Stok</th>
                                  <td style={{ color: "red" }}>
                                    {row.criticalPieces}
                                  </td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>

                            <tr>
                              <th style={this.thStyle}>Tedarikçi Stok Kodu</th>
                              <td colSpan={4} style={this.tdStyle}>
                                {row.supplierProductCode}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                  {
                    selector: "status",
                    width: "110px",
                    padding: "0px",
                    sortable: false,
                    cell: (row) => {
                      if (row.status) {
                        return <CgnBadge color="success" text="Aktif" />;
                      } else {
                        return <CgnBadge color="danger " text="Pasif" />;
                      }
                    },
                  },
                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${this.state.firstDate}/${this.state.lastDate}/${row.guid}`,
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar (SFr):{" "}
                {this.state.buyPriceTotalSFr.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                SFr
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar (€):{" "}
                {this.state.buyPriceTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar ($):{" "}
                {this.state.buyPriceTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Alış Tutar (₺):{" "}
                {this.state.buyPriceTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
            </div>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar (SFr):{" "}
                {this.state.salePriceTotalSFr.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                SFr
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar (€):{" "}
                {this.state.salePriceTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar ($):{" "}
                {this.state.salePriceTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Satış Tutar (₺):{" "}
                {this.state.salePriceTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default StockMovements;
