import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import { URL,  fotoStyle } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";
import noImg from "../../images/no-img.png";
import moment from "moment";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import { confirmAlert } from "react-confirm-alert";

class RecyclingProductsAddUpdate extends Component {
  state = {
    pageTitle: "Geri Dönüşüm Ekle",
    pageTitleAdd: "Geri Dönüşüm Ekle",
    pageTitleUpdate: "Geri Dönüşüm Güncelle",
    pageAddLink: "geri-donusum-ekle",
    pageListLink: "geri-donusum-listele",
    breadcrumb: [{ text: "Geri Dönüşüm", link: "#" }],
    isLoading: false,
    urlSplit: [],
    locationGuid: "",
    locationGuidError: "",
    locationArr: [],
    dateTime: "",
    dateTimeError: "",
    recyclingProductsProductDtos: [],
    recyclingProductsProductDtosError: "",
    alertErrorMessage: "",
    counter: -1,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    isOpen: false,
    image: "",
    name: "",
    productEntryModal: false,
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    if (localStorage.getItem("locationGuid")) {
      this.setState({
        locationGuid: localStorage.getItem("locationGuid"),
      });
    }

    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(URL + "/locations/getlist/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          locationArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    await axios
      .get(URL + "/machineProducts/getlistselect/2", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}`,
            image: item.image,
            name: item.name,
            code: item.code,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "geri-donusum-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      axios
        .get(URL + "/recyclingproducts/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            dateTime: response.data.dateTime,
            locationGuid: response.data.locationGuid,
            recyclingProductsProductDtos:
              response.data.recyclingProductsProductDtos,
            status: response.data.status,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    await this.setState({
      locationGuid: "",
      locationGuidError: "",
      dateTime: "",
      dateTimeError: "",
      recyclingProductsProductDtosError: "",
      alertErrorMessage: "",
    });
  }

  handleLocationSelectChange(option) {
    if (option) {
      this.setState({
        locationGuid: option.value,
        locationGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        locationGuid: "",
        locationGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDateTimeChange(dateTime) {
    if (dateTime) {
      this.setState({
        dateTime: dateTime,
        dateTimeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        dateTime: "",
        dateTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      locationGuidError: "",
      dateTimeError: "",
      recyclingProductsProductDtosError: "",
    });
    if (this.state.locationGuid === "") {
      this.setState({
        locationGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.dateTime === "") {
      this.setState({
        dateTimeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var thereIs = true;
    if (
      !this.state.recyclingProductsProductDtos ||
      this.state.recyclingProductsProductDtos.length === 0
    ) {
      thereIs = false;
      this.setState({
        recyclingProductsProductDtosError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }

    if (
      !this.state.recyclingProductsProductDtos ||
      this.state.recyclingProductsProductDtos.length > 0
    ) {
      this.state.recyclingProductsProductDtos.forEach((element) => {
        if (
          element.machineProductGuid === "" ||
          element.piece === "" ||
          element.piece === 0
        ) {
          thereIs = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
      if (thereIs === false) {
        this.setState({
          recyclingProductsProductDtosError: CgnMessage.textErrorMessage,
        });
      } else {
        this.setState({
          recyclingProductsProductDtosError: "",
        });
      }
    }

    if (
      this.state.locationGuid !== "" &&
      this.state.offerDate !== "" &&
      thereIs
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var myObj = {
        locationGuid: this.state.locationGuid,
        dateTime: moment(this.state.dateTime).format("DD/MM/YYYY"),
        recyclingProductsProductDtos: this.state.recyclingProductsProductDtos,
        status: true,
      };
      var URLParam = "/recyclingproducts/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "geri-donusum-guncelle"
      ) {
        URLParam = "/recyclingproducts/update";
        myObj = {
          guid: this.state.guid,
          locationGuid: this.state.locationGuid,
          dateTime:
            this.state.dateTime &&
              this.state.dateTime.toString().indexOf("/") > 0
              ? new Date(
                this.state.dateTime.split("/")[2] +
                "-" +
                this.state.dateTime.split("/")[1] +
                "-" +
                this.state.dateTime.split("/")[0]
              )
              : moment(this.state.dateTime).format("DD/MM/YYYY"),
          recyclingProductsProductDtos: this.state.recyclingProductsProductDtos,
          status: true,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          confirmAlert({
            title: CgnMessage.alertSuccessTitle,
            message: this.state.guid ? CgnMessage.updateSuccessMessage : CgnMessage.addSuccessMessage,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  if (
                    this.state.guid &&
                    this.state.urlSplit[1] === "geri-donusum-guncelle"
                  ) {
                    window.location.href = "/geri-donusum-listele";
                  }
                  if (this.state.urlSplit[1] === "geri-donusum-ekle") {
                    window.location.reload();
                  }
                }
              },
            ],
          });
        })
        .catch((error) => {
          confirmAlert({
            title: CgnMessage.alertErrorTitle,
            message: error.response.data,
            buttons: [
              {
                label: CgnMessage.closeButton,
                onClick: () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              },
            ],
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleProductInputValueChange(option, idx) {
    let recyclingProductsProductDtos = this.state.recyclingProductsProductDtos;
    if (option) {
      let productArr = this.state.productArr;
      productArr.forEach((element) => {
        if (element.value === option.value) {
          if (element.image) {
            recyclingProductsProductDtos[idx].machineProductName = element.name;
            recyclingProductsProductDtos[idx].machineProductImage =
              element.image;
          } else {
            recyclingProductsProductDtos[idx].machineProductName = "";
            recyclingProductsProductDtos[idx].machineProductImage = "";
          }
        }
      });

      recyclingProductsProductDtos[idx].machineProductGuid = option.value;
      recyclingProductsProductDtos[idx].machineProductGuidError = "";
      recyclingProductsProductDtos[idx].piece = 1;
      this.setState({
        recyclingProductsProductDtos: recyclingProductsProductDtos,
      });
    } else {
      recyclingProductsProductDtos[idx].machineProductGuid = null;
      recyclingProductsProductDtos[idx].machineProductName = "";
      recyclingProductsProductDtos[idx].machineProductImage = "";
      recyclingProductsProductDtos[idx].machineProductGuidError =
        CgnMessage.textErrorMessage;
      this.setState({
        recyclingProductsProductDtos: recyclingProductsProductDtos,
      });
    }
  }

  handleProductPieceTextBoxChange(e, idx) {
    let recyclingProductsProductDtos = this.state.recyclingProductsProductDtos;
    if (e.target.value && parseFloat(e.target.value) !== 0) {
      recyclingProductsProductDtos[idx].piece = parseFloat(e.target.value);
      recyclingProductsProductDtos[idx].pieceError = "";
      this.setState({
        recyclingProductsProductDtos: recyclingProductsProductDtos,
      });
    } else {
      recyclingProductsProductDtos[idx].piece = parseFloat(e.target.value);
      recyclingProductsProductDtos[idx].pieceError =
        CgnMessage.textErrorMessage;
      this.setState({
        recyclingProductsProductDtos: recyclingProductsProductDtos,
      });
    }
  }

  async handleProductRemove(idx, guid) {
    let recyclingProductsProductDtos = this.state.recyclingProductsProductDtos;
    recyclingProductsProductDtos.splice(idx, 1);
    this.setState({
      recyclingProductsProductDtos: recyclingProductsProductDtos,
      counter: this.state.counter - 1,
    });
    if (idx === 0) {
      this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleProductAdd = async (value, image, name) => {
    let array = this.state.recyclingProductsProductDtos;
    await array.push({
      machineProductGuid: value,
      machineProductImage: image,
      machineProductName: name,
      machineProductGuidError: CgnMessage.textErrorMessage,
      piece: 1,
      status: true,
      recyclingProductsProductDtosError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (
      array[this.state.counter].machineProductGuid === "" ||
      array[this.state.counter].piece === 0
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ recyclingProductsProductDtos: array });
  };

  handleChangeMultipleProduct(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      multipleProductArr: value,
    });
  }

  handleClickMultipleProduct = async () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
    var x = this.state.multipleProductArr.split("\n");
    await this.state.productArr.forEach((item) => {
      x.forEach((item2) => {
        if (item.code === item2) {
          this.handleProductAdd(item.value, item.image, item.name);
        }
      });
    });
  };

  toggleMultiProduct = () => {
    this.setState({
      productEntryModal: !this.state.productEntryModal,
    });
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                {localStorage.getItem("locationGuid") ===
                  "00000000-0000-0000-0000-000000000000" && (
                    <>
                      <Row>
                        <Col lg="3">
                          <CgnReactSelect
                            name="locationGuid"
                            label="Depo [*]"
                            isMulti={false}
                            selectValue={this.state.locationGuid}
                            placeholder="Depo seçiniz..."
                            options={this.state.locationArr}
                            isClearable={true}
                            onChange={(option) =>
                              this.handleLocationSelectChange(option)
                            }
                            error={this.state.locationGuidError}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="dateTime"
                      label="Tarih [*]"
                      selected={
                        this.state.dateTime &&
                          this.state.dateTime.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.dateTime.split("/")[2] +
                            "-" +
                            this.state.dateTime.split("/")[1] +
                            "-" +
                            this.state.dateTime.split("/")[0]
                          )
                          : this.state.dateTime
                      }
                      onChange={(dateTime) =>
                        this.handleDateTimeChange(dateTime)
                      }
                      error={this.state.dateTimeError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                {this.state.recyclingProductsProductDtos.map((item, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="8" className="modalSelectCol">
                      {item.machineProductImage ? (
                        <Button
                          onClick={() =>
                            this.toggle(
                              item.machineProductImage,
                              item.machineProductName
                            )
                          }
                          className="cart_image_button"
                        >
                          <img
                            alt={item.machineProductName}
                            src={`/Images/${item.machineProductImage}`}
                            className="cart_no_image rounded"
                          />
                        </Button>
                      ) : (
                        <img
                          alt={item.machineProductName}
                          src={noImg}
                          className="cart_no_image rounded"
                        />
                      )}
                      <div className="flex-container">
                        <div className="p-0 flexInput">
                          <CgnReactSelect
                            name="machineProductGuid"
                            label="Ürün [*]"
                            placeholder="Lütfen ürün seçiniz..."
                            selectValue={item.machineProductGuid}
                            isMulti={false}
                            isClearable={true}
                            onChange={(option) => {
                              this.handleProductInputValueChange(option, idx);
                            }}
                            options={this.state.productArr}
                            error={item.machineProductGuidError}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="3">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={item.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={item.pieceError}
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        className="dynamicRowDeleteButton"
                        type="button"
                        color="danger"
                        onClick={() =>
                          this.handleProductRemove(idx, item.machineProductGuid)
                        }
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}
                {this.state.recyclingProductsProductDtosError && (
                  <div className="invalid-feedback d-block mb-3">
                    {this.state.recyclingProductsProductDtosError}
                  </div>
                )}
                <Row>
                  <CgnButton
                    type="button"
                    color="primary"
                    className="dynamicRowAddButton mb-3"
                    onClick={this.handleProductAdd}
                    text={"Ürün Ekle"}
                  />
                  <CgnButton
                    type="button"
                    color="primary"
                    className="ml-2 dynamicRowAddButton"
                    onClick={this.toggleMultiProduct}
                    text={"Çoklu Ürün Ekle"}
                  />
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
        <Modal
          isOpen={this.state.productEntryModal}
          toggle={this.toggleMultiProduct}
        >
          <ModalHeader
            toggle={this.toggleMultiProduct}
            className="font-weight-bold"
          >
            Çoklu Ürün Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <Input
              type="textarea"
              className="modalTextArea"
              onChange={(e) => this.handleChangeMultipleProduct(e)}
            />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="primary"
              onClick={() => this.handleClickMultipleProduct()}
              text={CgnMessage.addButton}
              className="modalButton"
            />
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.toggleMultiProduct}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(RecyclingProductsAddUpdate);
