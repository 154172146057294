import React, { Component } from "react";
import { Row, Col, Card, Table, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL, fotoStyle2, localFilePath } from "../../redux/actions/constants";
import fileIcon from "../../images/file.jpg";
class CustomerEducationAnnouncementDetail extends Component {
    state = {
        pageTitle: "Duyuru Detay",
        pageTitleDetail: "Duyuru Detay",
        pageAddLink: "musteri-egitim-duyuru-ekle",
        pageListLink: "musteri-egitim-duyuru-listele",
        breadcrumb: [{ text: "Duyurular", link: "#" }],
        isLoading: false,
        alertErrorMessage: "",
        name: "",
        briefDescription: "",
        description: "",
        isInformationalEMail: "",
        status: "",
        createdBy: "",
        createdAt: "",
        updatedAt: "",
        updatedBy: "",
        customerEducationAnnouncementFilesDto: [],
    };
    async componentDidMount() {
        let urlSplit = window.location.pathname.split("/");
        if (urlSplit.length === 3) {
            this.setState({
                pageTitle: this.state.pageTitleDetail,
                isLoading: true,
            });
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .get(URL + "/customereducationannouncement/get/" + urlSplit[2], config)
                .then((response) => {
                    this.setState({
                        name: response.data.name,
                        briefDescription: response.data.briefDescription,
                        description: response.data.description,
                        isInformationalEMail: response.data.isInformationalEMail,
                        status: response.data.status,
                        createdBy: response.data.createdBy,
                        createdAt: response.data.createdAt,
                        updatedBy: response.data.updatedBy,
                        updatedAt: response.data.updatedAt,
                        isLoading: false,
                        customerEducationAnnouncementFilesDto: response.data.customerEducationAnnouncementFilesDto,
                    });
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        }
    }
    fileClick = (image) => {
        let urlWebSite = "http://sys.cogenteknoloji.com";
        let urlSplit = window.location.hostname;
        if (urlSplit === "crm.cevirikalitesi.com") {
          urlWebSite = "https://www.cevirikalitesi.com";
        }
    
        var myData = image;
        let replaceLink = myData.replace(localFilePath + "/Files/", "");
        if (replaceLink) {
          window.open(urlWebSite + "/Files/" + myData, "_blank");
          this.setState({
            isLoadingParent: false,
          });
        }
      };
    render() {
        var self1 = this;
        const mainRow = {
            backgroundColor: "#1d4b8c",
            color: "#fff",
        };
        return (
            <>
                <ContentTop
                    breadcrumb={this.state.breadcrumb}
                    title={this.state.pageTitle}
                    addLink={"/" + this.state.pageAddLink}
                    listLink={"/" + this.state.pageListLink}
                />
                <Row>
                    <Col>
                        <Card body className="table-responsive">
                            <CgnLoader visibled={this.state.isLoading} />
                            {this.state.alertErrorMessage && (
                                <CgnAlert
                                    color="danger"
                                    title="Uyarı"
                                    text={this.state.alertErrorMessage}
                                />
                            )}
                            <Table className="table table-responsive table-hover">
                                <tbody>
                                    <tr>
                                        <th>Başlık</th>
                                        <td colSpan="4">
                                            {this.state.name ? this.state.name : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Kısa Açıklama</th>
                                        <td colSpan="4">
                                            {this.state.briefDescription ? this.state.briefDescription : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Açıklama</th>
                                        <td colSpan="4"
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.description
                                                    ? this.state.description
                                                    : "-",
                                            }}>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Bilgilendirme E-Maili Gönderildi Mi?</th>
                                        <td colSpan="4">
                                            {this.state.isInformationalEMail ? "Evet" : "Hayır"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Durumu</th>
                                        <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                                    </tr>
                                    <tr>
                                        <th>Kaydeden Kullanıcı</th>
                                        <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                                        <th>Kaydedilme Tarihi</th>
                                        <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                                    </tr>
                                    <tr>
                                        <th>Son Güncelleyen Kullanıcı</th>
                                        <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                                        <th>Son Güncellenme Tarihi</th>
                                        <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            {this.state.customerEducationAnnouncementFilesDto &&
                                this.state.customerEducationAnnouncementFilesDto.length > 0 && (
                                    <Table className="table table-responsive table-hover table-product2">
                                        <tr style={mainRow}>
                                            <th>Belgeler</th>
                                        </tr>
                                        <Row>
                                            {this.state.customerEducationAnnouncementFilesDto.map(function (item) {
                                                return (
                                                    <>
                                                        <div className="pl-4">
                                                            <Button
                                                                onClick={() => self1.fileClick(item.image)}
                                                                className="modalButtonDetail"
                                                            >
                                                                <img
                                                                    src={fileIcon}
                                                                    style={fotoStyle2}
                                                                    alt={item.imageName}
                                                                />
                                                            </Button>
                                                            <p style={{ maxWidth: "150px" }}>
                                                                {item.imageName}
                                                            </p>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Table>
                                )}
                        </Card>
                        <CgnButtonLink
                            color="secondary"
                            to={"/" + this.state.pageListLink}
                            text="Geri"
                            className="btn-back"
                        />
                    </Col>
                </Row>
            </>
        );
    }
}
export default CustomerEducationAnnouncementDetail;