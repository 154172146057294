import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
import moment from "moment";

class NoteDetail extends Component {
  state = {
    pageTitle: "Not Detay",
    pageTitleDetail: "Not Detay",
    pageAddLink: "not-ekle",
    pageListLink: "not-listele",
    breadcrumb: [{ text: "Not", link: "#" }],
    isLoading: false,
    customerGuid: null,
    supplierGuid: null,
    alertErrorMessage: "",
    noteDate: "",
    title: "",
    description: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/notes/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            noteDate: moment(response.data.noteDate).format("DD/MM/YYYY"),
            customerGuid: response.data.customerGuid,
            supplierGuid: response.data.supplierGuid,
            title: response.data.title,
            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.noteDate ? this.state.noteDate : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Başlık</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.title ? this.state.title : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <th className="colon">:</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <th className="colon">:</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <th className="colon">:</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <th className="colon">:</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>

            {/* <CgnButtonLink
              color="secondary"
              to={{ pathname:("/" + (this.state.customerGuid? `musteri-detay/${this.state.customerGuid}` : `tedarikci-detay/${this.state.supplierGuid}`)), deneme:"1" }}
              text="Geri"
              className="btn-back"
            /> */}
          </Col>
        </Row>
      </>
    );
  }
}

export default NoteDetail;
