import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Modal,
  Table,
  ModalHeader,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  PaymentTypeOptions,
  TaxTypeOptions,
  URL,
  
  localFilePath,
  fotoStyle,
} from "../../redux/actions/constants";
// import CollectionCalendarAddUpdate from "./CollectionCalendarAddUpdate";
import CollectionAddUpdate from "../collection/CollectionAddUpdate";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButton from "../toolbox/CgnButton";
import noImage from "../../images/no-img.png";

class IncomeDetail extends Component {
  state = {
    pageTitle: "Gelir Detay",
    pageTitleDetail: "Gelir Detay",
    pageAddLink: "gelir-ekle",
    pageListLink: "gelir-listele",
    breadcrumb: [{ text: "Gelir", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    incomeDate: "",
    customerName: "",
    supplierName: "",
    total: null,
    generalTotal: null,
    taxType: null,
    taxRate: null,
    currencyType: 0,
    description: "",
    status: "",
    collectionCalendarDto: [],
    collectionDto: [],
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    activeTab: "1",
    incomeDetail: "1",
    collectionList: "2",
    collectionCalendarList: "3",
    isModalUpdateCollection: false,
    isOpenModalCollection: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/incomes/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            incomeDate: response.data.incomeDate,
            customerName: response.data.customerName,
            collectionCalendarDto: response.data.collectionCalendarDto,
            collectionDto: response.data.collectionDto,
            supplierName: response.data.supplierName,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            discountPercent: response.data.discountPercent,
            discountValue: response.data.discountValue.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            discountTotal: response.data.discountTotal,
            currencyType: response.data.currencyType,
            taxType: response.data.taxType,
            taxRate: response.data.taxRate,
            description: response.data.description,
            collectionTotal: response.data.collectionTotal,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handleCollectionDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleCollectionSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleCollectionSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/collections/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalCollection = () => {
    this.setState({
      isOpenModalCollection: !this.state.isOpenModalCollection,
    });
  };

  addModalUpdateCollection = (guid) => {
    this.setState({
      isModalUpdateCollection: !this.state.isModalUpdateCollection,
      collectionGuid: guid,
    });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  thStyle = {
    width: "175px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "200px",
  };

  tdStyle3 = {
    width: "90px",
  };

  tdStyle2 = {
    width: "600px",
  };

  thStyle3 = {
    width: "150px",
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self = this.state;
    var self1 = this;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          collectionLink={(onClick) => this.addModalCollection()}
        />
        <Row className="customerDetail">
          <Col>
            <CgnLoader visibled={this.state.isLoading} />
            {this.state.alertErrorMessage && (
              <CgnAlert
                color="danger"
                title="Uyarı"
                text={this.state.alertErrorMessage}
              />
            )}
            <Card body className="table-responsive">
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <td colSpan="4">{this.state.incomeDate}</td>
                  </tr>

                  <tr>
                    <th>{this.state.customerName ? "Müşteri" : "Tedarikçi"}</th>
                    <td colSpan="4">
                      {this.state.customerName
                        ? this.state.customerName
                        : this.state.supplierName}
                    </td>
                  </tr>

                  <tr>
                    <th>Ara Tutar</th>
                    <td colSpan="4">
                      {this.state.generalTotal
                        ? this.state.generalTotal +
                          " " +
                          (this.state.currencyType === 1
                            ? "₺"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>İskonto(Net Tutar)</th>
                    <td colSpan="4">
                      {this.state.discountValue && this.state.discountValue > 0
                        ? this.state.discountValue +
                          " " +
                          (this.state.currencyType === 1
                            ? "₺"
                            : this.state.currencyType === 2
                            ? "$"
                            : this.state.currencyType === 3
                            ? "€"
                            : "-")
                        : "-"}
                    </td>
                  </tr>

                  {/* <tr>
                    <th>K.D.V. Türü</th>
                    <td colSpan="4">{TaxTypeOptions(this.state.taxType)}</td>
                  </tr>

                  <tr>
                    <th>K.D.V. Oranı</th>
                    <td colSpan="4">
                      {this.state.taxRate ? "% " + this.state.taxRate : "-"}
                    </td>
                  </tr> */}

                  <tr>
                    <th>Toplam Tutar</th>
                    <td colSpan="4">
                      {parseFloat(this.state.discountTotal).toLocaleString(
                        "tr-TR",
                        { minimumFractionDigits: 2 }
                      ) +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")}
                    </td>
                  </tr>

                  <tr>
                    <th>Tahsil Edilen Tutar</th>
                    <td colSpan="4">
                      {parseFloat(this.state.collectionTotal).toLocaleString(
                        "tr-TR",
                        {
                          minimumFractionDigits: 2,
                        }
                      ) +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")}
                    </td>
                  </tr>

                  <tr>
                    <th>Kalan Tutar</th>
                    <td colSpan="4">
                      {(
                        this.state.discountTotal - this.state.collectionTotal
                      ).toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                      }) +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")}
                    </td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>

                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.collectionCalendarDto !== null &&
                this.state.collectionCalendarDto.length > 0 && (
                  <>
                    <div
                      className="row"
                      style={{
                        display:
                          self.collectionCalendarDto !== null &&
                          self.collectionCalendarDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <h2 className="mt-2">Tahsilat Takvimleri</h2>
                    </div>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Tahsilat Takvimi Tarihi</th>
                        <th className="text-center">Tahsilat Tutarı</th>
                        <th>Tahsilat Yöntemi</th>
                      </tr>
                      {this.state.collectionCalendarDto.map(function (item) {
                        return (
                          <tr style={subRow}>
                            <td>{item.targetCollectionDate}</td>
                            <td className="text-center">
                              {PaymentTypeOptions(
                                item.targetCollectionMethodType
                              )}
                            </td>
                            <td>
                              {item.targetTotal.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                                " " +
                                (item.targetCurrencyType === 1
                                  ? "₺"
                                  : item.targetCurrencyType === 2
                                  ? "$"
                                  : item.targetCurrencyType === 3
                                  ? "€"
                                  : "-")}
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  </>
                )}

              <div
                className="row"
                style={{
                  display:
                    self.collectionDto !== null && self.collectionDto.length > 0
                      ? ""
                      : "none",
                }}
              >
                <h2 className="mb-2 mt-2">Tahsilatlar</h2>
              </div>
              {this.state.collectionDto.map(function (item) {
                return (
                  <>
                    <Table
                      className="table table-responsive table-hover mb-2"
                      style={{
                        display:
                          self.collectionDto !== null &&
                          self.collectionDto.length > 0
                            ? ""
                            : "none",
                      }}
                    >
                      <tbody>
                        <>
                          <Accordion allowZeroExpanded>
                            <AccordionItem>
                              <AccordionItemHeading
                                style={{ backgroundColor: "#9B9796" }}
                              >
                                <AccordionItemButton>
                                  <tr className="cuttingTr4 w-100">
                                    <th className="pl-3" style={self1.thStyle3}>
                                      Tahsilat Tarihi
                                    </th>
                                    <td style={self1.tdStyle2}>
                                      {item.collectionDate}
                                    </td>
                                    <th style={self1.thStyle}>
                                      Tahsilat Yöntemi Türü
                                    </th>
                                    <td style={self1.tdStyle2}>
                                      {item.collectionMethodType === 1
                                        ? "Çek"
                                        : item.collectionMethodType === 2
                                        ? "Havale/EFT"
                                        : item.collectionMethodType === 3
                                        ? "Kredi Kartı"
                                        : item.collectionMethodType === 4
                                        ? "Nakit"
                                        : item.collectionMethodType === 5
                                        ? "Senet"
                                        : "-"}
                                    </td>
                                    <th
                                      className="text-right"
                                      style={self1.thStyle3}
                                    >
                                      Tutar
                                    </th>
                                    <td
                                      className="text-right pr-3"
                                      style={self1.tdStyle3}
                                    >
                                      {item.total.toLocaleString("tr-TR", {
                                        minimumFractionDigits: 2,
                                      }) +
                                        " " +
                                        (self.currencyType === 1
                                          ? "₺"
                                          : self.currencyType === 2
                                          ? "$"
                                          : self.currencyType === 3
                                          ? "€"
                                          : "-")}
                                    </td>
                                  </tr>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <tr>
                                  <th className="pl-3" style={self1.thStyle2}>
                                    Banka Adı
                                  </th>
                                  <td style={self1.tdStyle}>
                                    {item.bankName ? item.bankName : "-"}
                                  </td>

                                  <th style={self1.thStyle2}>Iban</th>
                                  <td style={self1.tdStyle}>
                                    {item.iban ? item.iban : "-"}
                                  </td>

                                  <th style={self1.thStyle2}>Vade</th>
                                  <td style={self1.tdStyle}>
                                    {item.expiry > 0 ? item.expiry : "-"}
                                  </td>
                                  <th style={self1.thStyle}>
                                    Çek/Senet Numarası
                                  </th>
                                  <td style={self1.tdStyle}>
                                    {item.chequesBondNo
                                      ? item.chequesBondNo
                                      : "-"}
                                  </td>
                                </tr>

                                <tr>
                                  <th className="pl-3">Açıklama</th>
                                  <td colSpan={8}>
                                    {item.description ? item.description : "-"}
                                  </td>
                                </tr>

                                <tr>
                                  <th className="pl-3" style={self1.thStyle}>
                                    Makbuz Fotoğraf
                                  </th>
                                  <td style={{ width: "90%" }} colSpan={8}>
                                    {item.image ? (
                                      <Button
                                        onClick={self1.toggle}
                                        className="modalButtonDetail"
                                      >
                                        <img
                                          src={item.image}
                                          style={fotoStyle}
                                          alt={item.name}
                                        />
                                      </Button>
                                    ) : (
                                      <img
                                        src={noImage}
                                        style={fotoStyle}
                                        alt={item.receiptNo}
                                      />
                                    )}
                                  </td>
                                </tr>

                                <Modal
                                  isOpen={self.isOpen}
                                  toggle={self1.toggle}
                                >
                                  <ModalHeader
                                    toggle={self1.toggle}
                                  ></ModalHeader>
                                  <img
                                    className="modalImage"
                                    src={item.image}
                                    style={fotoStyle}
                                    alt={item.receiptNo}
                                  />
                                </Modal>

                                <tr>
                                  <th className="pl-3" style={self1.thStyle2}>
                                    Kaydeden Kullanıcı
                                  </th>
                                  <td colSpan={3} style={self1.tdStyle}>
                                    {item.createdBy ? item.createdBy : "-"}
                                  </td>
                                  <th style={self1.thStyle2} className="pl-0">
                                    Kaydedilme Tarihi
                                  </th>
                                  <td colSpan={4} style={self1.tdStyle}>
                                    {item.createdAt ? item.createdAt : "-"}
                                  </td>
                                </tr>
                                <CgnButton
                                  type="button"
                                  color="success"
                                  className="printButton ml-3 mt-2"
                                  onClick={() =>
                                    self1.addModalUpdateCollection(item.guid)
                                  }
                                  text="Güncelle"
                                />
                                <CgnButton
                                  type="button"
                                  color="danger"
                                  style={{ width: "90px" }}
                                  className="ml-3 mt-2"
                                  onClick={() =>
                                    self1.handleCollectionDelete(item.guid)
                                  }
                                  text="Sil"
                                />
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion>
                        </>
                      </tbody>
                    </Table>
                  </>
                );
              })}

              <div className="row mt-2">
                <div className="col-lg-12">
                  <h6
                    className="p-1 mt-2 pl-2 collectionH2"
                    style={{ border: "2px solid #1d4b8c" }}
                  >
                    Kalan Tutar :{" "}
                    {(
                      this.state.discountTotal - this.state.collectionTotal
                    ).toLocaleString("tr-TR", {
                      minimumFractionDigits: 2,
                    }) +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                        ? "$"
                        : this.state.currencyType === 3
                        ? "€"
                        : "-")}
                  </h6>

                  <h6
                    className="p-1 mt-2 pl-2 collectionH2"
                    style={{ border: "2px solid #1d4b8c" }}
                  >
                    Toplam Tahsilat :{" "}
                    {parseFloat(this.state.collectionTotal).toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                      }) +
                      (this.state.currencyType === 1
                        ? " ₺"
                        : this.state.currencyType === 2
                        ? " $"
                        : this.state.currencyType === 3
                        ? " €"
                        : "-")}
                  </h6>

                  <h6
                    className="p-1 mt-2 pl-2 collectionH2"
                    style={{ border: "2px solid #1d4b8c" }}
                  >
                    Genel Tutar :{" "}
                    {parseFloat(this.state.discountTotal).toLocaleString(
                      "tr-TR",
                      {
                        minimumFractionDigits: 2,
                      }
                    ) +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                        ? "$"
                        : this.state.currencyType === 3
                        ? "€"
                        : "-")}
                  </h6>
                </div>
              </div>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCollection}
          toggle={this.addModalCollection}
          className="modal-xl content"
        >
          {/* <ModalHeader className="font-weight-bold">Tahsilat Ekle</ModalHeader> */}
          <ModalBody className="modalBody">
            <CollectionAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCollection}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isModalUpdateCollection}
          toggle={this.addModalUpdateCollection}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <CollectionAddUpdate guid={this.state.collectionGuid} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateCollection}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default IncomeDetail;
