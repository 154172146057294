import * as actionTypes from "../actionTypes";
import { URL } from "../constants";
import { handleError, handleResponse } from "../handles";
import axios from "axios";

export function groupGetListSelectSuccess(success) {
  return { type: actionTypes.GROUP_GET_LIST_SELECT_SUCCESS, payload: success };
}

export function groupGetListSelectError(error) {
  return { type: actionTypes.GROUP_GET_LIST_SELECT_ERROR, payload: error };
}

export function groupGetListSelect() {
  return async function (dispatch) {
    var config = {
      method: "GET",
      url: URL + "/groups/getlist",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          handleResponse(groupGetListSelectSuccess, dispatch, response);
          handleError(groupGetListSelectError, dispatch, {});
        } else {
          handleResponse(groupGetListSelectSuccess, dispatch, response);
          handleError(groupGetListSelectError, dispatch, {});
        }
      })
      .catch((error) => {
        handleResponse(groupGetListSelectSuccess, dispatch, {});
        handleError(groupGetListSelectError, dispatch, error.response);
      });
  };
}
