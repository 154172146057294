import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import CgnTextEditor from "../toolbox/CgnTextEditor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import CgnImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";
import axios from "axios";
import {
  URL,
  machineProductOptionStatuses,
  
  fotoStyle,
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
class PersonEMailQuestAddUpdate extends Component {
  state = {
    pageTitle: "Personel E-Mail Görev Ekle",
    pageTitleAdd: "Personel E-Mail Görev Ekle",
    pageTitleUpdate: "Personel E-Mail Görev Güncelle",
    pageAddLink: "personel-e-mail-gorev-ekle",
    pageListLink: "personel-e-mail-gorev-listele",
    breadcrumb: [{ text: "Personel E-Mail Görev", link: "#" }],
    isLoading: false,
    urlSplit: [],
    guid: "",
    parentGuid: "00000000-0000-0000-0000-000000000000",
    senderEMail: "",
    membershipGuid: "",
    membershipGuidError: "",
    name: "",
    nameError: "",
    description: "",
    descriptionHtml: "",
    descriptionError: "",
    eMailQuestDocumentDto: [],
    eMailQuestDocumentDtoError: "",
    sendEMail: false,
    status: 1,
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    userArr: [],
    plannedEndDate: "",
    plannedEndDateError: "",
  };
  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    if (localStorage.getItem("guid")) {
      this.setState({
        membershipGuid: localStorage.getItem("guid"),
      });
    }
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/users/getlistuser", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          userArr: myArr,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/emailquest/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              parentGuid: response.data.parentGuid,
              senderEMail: response.data.senderEMail,
              membershipGuid: response.data.membershipGuid,
              plannedEndDate: response.data.plannedEndDate,
              name: response.data.name,
              description: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(response.data.description)
                )
              ),
              descriptionHtml: response.data.description,
              eMailQuestDocumentDto: response.data.eMailQuestDocumentDto,
              sendEMail: response.data.sendEMail,
              status: response.data.status,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        guid: "",
        parentGuid: "00000000-0000-0000-0000-000000000000",
        membershipGuid: "",
        plannedEndDate: "",
        name: "",
        description: "",
        descriptionHtml: "",
        eMailQuestDocumentDto: [],
        sendEMail: false,
        status: 1,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: 1,
      });
    }
  }
  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      descriptionError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "description" && value === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleClear = (event) => {
    this.clearPage();
  };
  clearPage() {
    this.setState({
      parentGuid: "",
      membershipGuid: "",
      membershipGuidError: CgnMessage.textErrorMessage,
      plannedEndDate: "",
      plannedEndDateError: CgnMessage.textErrorMessage,
      name: "",
      nameError: CgnMessage.textErrorMessage,
      description: "",
      descriptionHtml: "",
      descriptionError: CgnMessage.textErrorMessage,
      eMailQuestDocumentDto: [],
      eMailQuestDocumentDtoError: "",
      sendEMail: false,
      status: 1,
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  }
  handleEditorChange = (editorState, editorStateHtml) => {
    if (editorStateHtml) {
      this.setState({
        description: editorState,
        descriptionHtml: editorStateHtml,
        descriptionError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }
  handleUserSelectChange(option) {
    if (option) {
      this.setState({
        membershipGuid: option.value,
        membershipGuidError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        membershipGuid: null,
        membershipGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  uploadedDocument = async (event) => {
    let array = this.state.eMailQuestDocumentDto;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.setState({
        isLoading: true,
      });
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    }
    await this.setState({ eMailQuestDocumentDto: array });
  };
  deleteDocument = (event) => {
    var index = -1;
    let array = this.state.eMailQuestDocumentDto;
    this.state.eMailQuestDocumentDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ eMailQuestDocumentDto: array });
        }
      }
    });
  };
  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      membershipGuidError: "",
      nameError: "",
      descriptionError: "",
      eMailQuestDocumentDtoError: [],
    });
    if (this.state.membershipGuid === "") {
      this.setState({
        membershipGuidError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.plannedEndDate === "") {
      this.setState({
        plannedEndDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.description === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.membershipGuid !== "" &&
      this.state.plannedEndDate !== "" &&
      this.state.name !== "" &&
      this.state.description !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        parentGuid: this.state.parentGuid,
        senderEMail: this.state.senderEMail,
        membershipGuid: this.state.membershipGuid,
        plannedEndDate: moment(this.state.plannedEndDate).format("DD/MM/YYYY"),
        name: this.state.name,
        description: this.state.descriptionHtml,
        eMailQuestDocumentDto: this.state.eMailQuestDocumentDto,
        sendEMail: this.state.sendEMail,
        status: this.state.status,
      };
      var URLParam = "/emailquest/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "personel-e-mail-gorev-guncelle"
      ) {
        URLParam = "/emailquest/update";
        myObj = {
          parentGuid: this.state.parentGuid,
          senderEMail: this.state.senderEMail,
          membershipGuid: this.state.membershipGuid,
          plannedEndDate:
            this.state.plannedEndDate &&
            this.state.plannedEndDate.toString().indexOf("/") > 0
              ? new Date(
                  this.state.plannedEndDate.split("/")[2] +
                    "-" +
                    this.state.plannedEndDate.split("/")[1] +
                    "-" +
                    this.state.plannedEndDate.split("/")[0]
                )
              : moment(this.state.plannedEndDate).format("DD/MM/YYYY"),
          guid: this.state.guid,
          name: this.state.name,
          description: this.state.descriptionHtml,
          eMailQuestDocumentDto: this.state.eMailQuestDocumentDto,
          sendEMail: this.state.sendEMail,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.urlSplit[1] === "personel-e-mail-gorev-guncelle") {
              window.location.href = "/personel-e-mail-gorev-listele";
            } else if (
              this.state.urlSplit[1] === "personel-e-mail-gorev-ekle"
            ) {
              window.location.reload();
            }
            var modalAddedEMailQuest = {
              data: this.state.name,
              isModal: false,
            };
            this.props.history.push({ modalAddedEMailQuest });
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleDateChange(plannedEndDate) {
    if (plannedEndDate) {
      this.setState({
        plannedEndDate: plannedEndDate,
        plannedEndDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        plannedEndDate: "",
        plannedEndDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }
  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "personel-e-mail-gorev-ekle" ||
          this.state.urlSplit[1] === "personel-e-mail-gorev-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="3">
                    <CgnDatePicker
                      name="plannedEndDate"
                      label="Planlı Bitiş Tarihi [*]"
                      selected={
                        this.state.plannedEndDate &&
                        this.state.plannedEndDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.plannedEndDate.split("/")[2] +
                                "-" +
                                this.state.plannedEndDate.split("/")[1] +
                                "-" +
                                this.state.plannedEndDate.split("/")[0]
                            )
                          : this.state.plannedEndDate
                      }
                      onChange={(plannedEndDate) =>
                        this.handleDateChange(plannedEndDate)
                      }
                      error={this.state.plannedEndDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="9"></Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Konu [*]"
                      value={this.state.name}
                      placeHolder="Lütfen konu girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextEditor
                      name="description"
                      label="Açıklama [*]"
                      editorState={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      onChange={(editorState) =>
                        this.handleEditorChange(
                          editorState,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                      }
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pl-0">
                    <CgnImageUploadMulti
                      images={this.state.eMailQuestDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png"
                      className="text-center text-danger"
                      label={"Belgeler"}
                      onChange={this.uploadedDocument}
                      onDelete={this.deleteDocument}
                      error={this.state.eMailQuestDocumentDtoError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={machineProductOptionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                  <Col lg="9"></Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "personel-e-mail-gorev-ekle" ||
              this.state.urlSplit[1] === "personel-e-mail-gorev-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(PersonEMailQuestAddUpdate);
