import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import axios from "axios";
import {
  URL,
  localFilePath,
  fotoStyle,
  fotoStyle2
} from "../../redux/actions/constants";
import pdfIcon from "../../images/pdfIcon.png";
import CgnMessage from "../toolbox/CgnMessage";
import ImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import noImg from "../../images/no-img.png";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";
import medikozLogo from "../../images/medikozlogo.png";

class EnOtherTicketDetail extends Component {
  state = {
    pageTitle: "Other Processes Information System",
    pageTitleDetail: "Other Processes Information System",
    isLoading: false,
    alertErrorMessage: "",
    customerName: "",
    enOtherTicketImageDto: [],
    customerDocumentOtherGuid: "",
    currencyType: "",
    total: 0,
    description: "",
    descriptionError: "",
    descriptionHtml: "",
    otherTicketData: [],
    otherTicket2Dto: [],
    customerDocumentOtherImageDto: [],
    otherTranslationDocumentDto: [],
    status: "",
    customerMail: "",
    offerTotal: 0,
    customerGuid: "",
    personelName: "",
    guid: "",
    createdAt: "",
    isLoadingModal: false,
    isOpenModalPayment: false,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ]
  };



  
  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
      this.setState({
        isLoading: true,
      });
      this.state.customerGuid = urlSplit[2];
      this.state.customerDocumentOtherGuid = urlSplit[3];
      this.state.personelName = urlSplit[4];
      axios
        .get(URL + "/enOtherTickets/getlist/" +  this.state.customerGuid + "/" +  this.state.customerDocumentOtherGuid)
        .then((response) => {
          this.setState({
            customerGuid: response.data.customerGuid,
            customerName: response.data.customerName,
            description: this.state.description,
            createdAt: response.data.createdAt,
            otherTicket2Dto: response.data.otherTicket2Dto,
            customerDocumentOtherGuid: response.data.customerDocumentOtherGuid,
            isLoading: false,
            offerTotal: response.data.offerTotal,
            currencyType: response.data.currencyType,
            total: response.data.total,
            customerDocumentOtherImageDto: response.data.customerDocumentOtherImageDto,
            otherTranslationDocumentDto: response.data.otherTranslationDocumentDto,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
  }


  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  async handleSendPayment(offerTotal) {
    var kalanTutar = (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)).toFixed(2);
    window.location.href = "https://www.medikozceviri.com/Odeme/Odeme?total=" + kalanTutar + "&customerGuid=" + this.state.customerGuid + "&guid=" + this.state.customerDocumentOtherGuid;
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    if(this.state.description === ""){
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoading: false
      });
    }
    else if(this.state.description !== ""){
      var URLParam = "/enOtherTickets/add";     
      var myObj = {
        customerGuid: this.state.customerGuid,
        enOtherTicketImageDto: this.state.enOtherTicketImageDto,
        customerDocumentOtherGuid: this.state.customerDocumentOtherGuid,
        description: this.state.description,
        customerDocumentOtherImageDto: this.state.customerDocumentOtherImageDto,
      };
       await axios
        .post(URL + URLParam, myObj)
        .then((response) => {
          setTimeout(() => {
             window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
    else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
        isLoading: false,
      });
    }
  }



  uploadedImage2 = async (event) => {
    let array = this.state.enOtherTicketImageDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
      if(file.type === "application/pdf"){
        const formData = new FormData();
        formData.append("files", file);
        await axios
          .post(URL + "/uploads2/add", formData)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
   await this.setState({enOtherTicketImageDto: array});

  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.enOtherTicketImageDto;
     this.state.enOtherTicketImageDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({enOtherTicketImageDto: array});
          }
        }
     });
  };

  uploadedImage3 = async (event) => {
    let array = this.state.customerDocumentOtherImageDto;
    const files = event.target.files;
    for(var i = 0; i < files.length; i++){
      const file = event.target.files[i];
      if(file.type === "application/pdf"){
        const formData = new FormData();
        formData.append("files", file);
        await axios
          .post(URL + "/uploads2/add", formData)
          .then((response) => {
            array.push({
                image: response.data,
                imageName: file.name
            })
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
    }
   await this.setState({customerDocumentOtherImageDto: array});

  };

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.customerDocumentOtherImageDto;
     this.state.customerDocumentOtherImageDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({customerDocumentOtherImageDto: array});
          }
        }
     });
  };
 
  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };

    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    var self1 = this;
    var self = this.state;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <p style={{fontSize: "30px", color:"#cd0b0b", paddingLeft:"20px", marginTop:"25px", fontWeight:"bold"}}>Other Transactions Information System</p>
          </div>
        </div>
        <Row>
          <Col lg="12">
          <img
            src={medikozLogo}
            style={{width: "200px",
            height: "80px",
            padding: "2px",
            boxShadow: "0px 0px 5px #999",
            marginLeft: "20px"}}
            alt="medikozceviri"
           />
          </Col>
        </Row>
        <Row className="p-3">
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              
              <div className="row">
                <p style={{color: "red", paddingLeft:"20px"}}>Total Amount Due : {(parseFloat(this.state.offerTotal) - parseFloat(this.state.total)).toFixed(2) + " " + (this.state.currencyType === 1 ? "TL" : this.state.currencyType === 2 ? "$" : 
                this.state.currencyType === 3 ? "€" : "")}</p>
              </div>
              <div className="row">
              <CgnButton
                  type="button"
                  color="primary"
                  style={{display: (parseFloat(this.state.offerTotal) - parseFloat(this.state.total)) > 0  ? "" : "none", float:"right"}}
                  className="float-rigth ml-3"
                  text="Pay"
                  onClick={() => self1.handleSendPayment(this.state.offerTotal)}
                />
              </div>
              {this.state.otherTicket2Dto !== null &&
                this.state.otherTicket2Dto.length > 0 &&
                this.state.otherTicket2Dto.map(function (item) {
                  return (
                    <>
                <Table className="table table-responsive table-hover table-product2 mb-3">
                      <tr className="">
                        <th style={{borderTop: "1px solid #ddd", color:"#fff"}}>Customer</th>
                        <td style={{borderTop: "1px solid #ddd", textAlign:"left", color:"#fff"}}>{self.customerName}</td>                       
                      </tr>    
                      <tr className="">
                        <th>Added Date</th>
                        <td style={{textAlign:"left"}}>{item.createdAt}</td>                       
                      </tr>       
                      <tr className="">
                        <th>Description</th>
                        <td style={{textAlign:"left"}}>{item.description}</td>                       
                      </tr>            
                    </Table>
                    <Table>
              {item.enOtherTicketImageDto &&
                 item.enOtherTicketImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Ticket Documents</th>
                    </tr>
                    <Row>
                      {item.enOtherTicketImageDto.map(function (item1) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item1.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item1.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item1.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
               </Table>
               </>
                 );
              })}

              {this.state.customerDocumentOtherImageDto &&
                this.state.customerDocumentOtherImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Attached Documents</th>
                    </tr>
                    <Row>
                      {this.state.customerDocumentOtherImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.otherTranslationDocumentDto &&
                this.state.otherTranslationDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2 mb-3">
                    <tr style={mainRow}>
                      <th>Translation Documents</th>
                    </tr>
                    <Row>
                      {this.state.otherTranslationDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
            </Card>
            <Card body className="table-responsive mt-3">   
            <CgnLoader visibled={this.state.isLoading} />          
            <Form onSubmit={this.handleSave}>   
                <Col lg="12" className="pl-0">
                <CgnTextbox
                        inlineStyle={textAreaStyle}
                        type="textarea"
                        name="description"
                        label="Description"
                        value={this.state.description}
                        placeHolder="Please enter description..."
                        autoComplete="off"
                        onChange={this.handleChange}
                        error={this.state.descriptionError}
                      />
                </Col>           
                  <Col lg="12" className="pl-0">
                  <ImageUploadMulti
                      images={this.state.enOtherTicketImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Additional Documents (PDF)"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      error={this.state.enOtherTicketImageDtoError}
                    />
                    </Col>       
                    <Col lg="12" className="pl-0">
                  <ImageUploadMulti
                      images={this.state.customerDocumentOtherImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      accept="application/pdf"
                      className="text-center text-danger"
                      label={"Documents Required for Registration (PDF)"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      error={this.state.customerDocumentOtherImageDtoError}
                      disabled={(this.state.customerDocumentOtherImageDto.length > 0) ? true : false}
                    />
                    </Col>              
                    <CgnButtonGroup items={this.state.buttons} />
                 </Form>
                {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Alert"
                  text={this.state.alertErrorMessage}
                />
              )}
            </Card>          
          </Col>
        </Row>
      </>
    );
  }
}

export default EnOtherTicketDetail;