import React from "react";
import packageJson from "../../../package.json";

class Footer extends React.Component {
  state = {
    companyId: 0,
  };

  async componentDidMount() {
    let urlSplit1 = window.location.href.split("//");
    let urlSplit2 = urlSplit1[1].split("/");
    if (
      urlSplit2[0] === "crm.medikozceviri.com" ||
      urlSplit2[0] === "crm.cevirikalitesi.com"
    ) {
      await this.setState({
        companyId: 1,
      });
    }
    if (
      urlSplit2[0] === "crm.alkom.com.tr"
    ) {
      await this.setState({
        companyId: 3,
      });
    }
  }

  render() {
    return (
      <>
        <div
          className="footer"
          style={{ display: this.state.companyId === 0 ? "" : "none" }}
        >
          <p className="m-0">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://www.cogen.com.tr"
              alt="Cogen® Yazılım | CRM"
              target="blank"
            >
              Cogen® Yazılım | CRM
            </a>{" "}
            | Tüm hakları saklıdır.
          </p>
        </div>
        <div
          className="footer"
          style={{ display: this.state.companyId === 0 ? "" : "none" }}
        >
          <p>
            <strong>Adres :</strong>{" "}
            <a
              href="https://www.google.com/maps?q=39.91381934392228,32.80556486691228"
              alt="Cogen® Yazılım | CRM Yol Tarifi"
              target="blank"
            >
              Beştepe Mahallesi Nergiz Sokak Via Flat No: 7/2 İç Kapı No: 87 Yenimahalle / Ankara / Türkiye
            </a>
          </p>
          <p>
            <strong>GSM :</strong>{" "}
            <a href="tel:+905455012734" alt="Cogen® Yazılım | CRM GSM">
              +90 [545] 501 27 34
            </a>
          </p>
          <p>
            <strong>E-Mail :</strong>{" "}
            <a
              href="mailto:destek@cogen.com.tr"
              alt="Cogen® Yazılım | CRM E-Mail"
            >
              destek@cogen.com.tr
            </a>
          </p>
          <p>
            <strong>Web :</strong>{" "}
            <a
              href="https://www.cogen.com.tr"
              alt="Cogen® Yazılım | CRM"
              target="blank"
            >
              https://www.cogen.com.tr
            </a>
          </p>
          <p className="m-0">
            <strong>Versiyon :</strong> {packageJson.version}
          </p>
          <p className="note">Teknik destek talepleriniz için lütfen yukarıdaki iletişim adreslerinden bizimle iletişime geçiniz.</p>
        </div>

        <div
          className="footer"
          style={{ display: this.state.companyId === 1 ? "" : "none" }}
        >
          <p className="m-0">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://www.medikozceviri.com/"
              alt="Medikoz Tercüme | CRM"
              target="blank"
            >
              Medikoz Tercüme | CRM
            </a>{" "}
            | Tüm hakları saklıdır.
          </p>
        </div>
        <div
          className="footer"
          style={{ display: this.state.companyId === 1 ? "" : "none" }}
        >
          <p>
            <strong>Adres :</strong>{" "}
            <a href="/" alt="Medikoz Tercüme" target="blank">
              Ziya Gökalp Caddesi 24/35 Kat:3 Çankaya/ANKARA
            </a>
          </p>
          <p>
            <strong>Telefon :</strong>{" "}
            <a href="tel:+903123781718" alt="Medikoz Tercüme | CRM GSM">
              +90 [312] 911 13 63
            </a>
          </p>
          <p>
            <strong>GSM :</strong>{" "}
            <a href="tel:+905455012734" alt="Medikoz Tercüme | CRM GSM">
              +90 [533] 749 71 55
            </a>
          </p>
          <p>
            <strong>E-Mail :</strong>{" "}
            <a
              href="mailto:info@medikozceviri.com"
              alt="Medikoz Tercüme | CRM E-Mail"
            >
              info@medikozceviri.com
            </a>
          </p>
          <p>
            <strong>Web :</strong>{" "}
            <a
              href="https://www.medikozceviri.com/"
              alt="Medikoz Tercüme | CRM"
              target="blank"
            >
              https://www.medikozceviri.com/
            </a>
          </p>
          <p className="m-0">
            <strong>Versiyon :</strong> {packageJson.version}
          </p>
        </div>

        <div
          className="footer"
          style={{ display: this.state.companyId === 3 ? "" : "none" }}
        >
          <p className="m-0">
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://www.alkom.com.tr/"
              alt="Alkom | CRM"
              target="blank"
            >
              Alkom | CRM
            </a>{" "}
            | Tüm hakları saklıdır.
          </p>
        </div>
        <div
          className="footer"
          style={{ display: this.state.companyId === 3 ? "" : "none" }}
        >
          <p>
            <strong>Adres :</strong>{" "}
            <a href="/" alt="Alkom | CRM Adres" target="blank">
              İvedik Osb Mahallesi 1354.Caddesi 1374.Sokak No:4 Yenimahalle / Ankara / Türkiye
            </a>
          </p>
          <p>
            <strong>Telefon :</strong>{" "}
            <a href="tel:+903123323457" alt="Alkom | CRM Telefon">
              +90 [312] 332 34 57
            </a>
          </p>
          <p>
            <strong>GSM :</strong>{" "}
            <a href="tel:+905073323457" alt="Alkom | CRM GSM">
              +90 [507] 332 34 57
            </a>
          </p>
          <p>
            <strong>E-Mail :</strong>{" "}
            <a
              href="mailto:info@alkom.com.tr"
              alt="Alkom | CRM E-Mail"
            >
              info@alkom.com.tr
            </a>
          </p>
          <p>
            <strong>Web :</strong>{" "}
            <a
              href="https://www.alkom.com.tr/"
              alt="Alkom | CRM Web Adresi"
              target="blank"
            >
              https://www.alkom.com.tr/
            </a>
          </p>
          <p className="m-0">
            <strong>Versiyon :</strong> {packageJson.version}
          </p>
        </div>
      </>
    );
  }
}

export default Footer;
