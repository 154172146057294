import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  resizeFile,
  fotoStyle,
  textAreaStyle,
  optionStatuses,
  PaymentMethodTypeArr,
  PackageOptionsStatuses,
  
  Day
} from "../../redux/actions/constants";
import ImageUpload from "../toolbox/CgnImageUpload";
import noFotoMember from "../../images/no-img.png";
import { CgnEMailControl } from "../toolbox/CgnEMailControl";
import { CgnPhoneControl } from "../toolbox/CgnPhoneControl";
import { CgnTaxNoControl } from "../toolbox/CgnTaxNoControl";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnDatePicker from "../toolbox/CgnDatePicker";

class CompanyAddUpdate extends Component {
  state = {
    pageTitle: "Firma Ekle",
    pageTitleAdd: "Firma Ekle",
    pageTitleUpdate: "Firma Güncelle",
    pageAddLink: "firma-ekle",
    pageListLink: "firma-listele",
    breadcrumb: [{ text: "Firma", link: "#" }],
    isLoading: false,
    guid: "",
    name: "",
    taxNo: "",
    commercialRegistrationNumber: "",
    mersisNumber: "",
    taxAdministration: "",
    veribanUsername: "",
    veribanPassword: "",
    address: "",
    phone: "",
    fax: "",
    eMail: "",
    website: "",
    logo: "",
    logoName: "",
    icon: "",
    iconName: "",
    districtGuid: "00000000-0000-0000-0000-000000000000",
    townGuid: "00000000-0000-0000-0000-000000000000",
    cityGuid: "00000000-0000-0000-0000-000000000000",
    countryGuid: "",
    countryName: "",
    userCount: "",
    locationCount: "",
    isCityTrue: true,
    isDistrictTrue: true,
    isTownTrue: true,
    labelIsTurkey: false,
    zipCode: "",
    paymentMethodTypeArr: [],
    messagePort: 0,
    messageHost: "",
    messageEMail: "",
    messagePassword: "",
    startDate: "",
    endDate: "",
    isDeleted: false,
    status: true,
    gsm: "",
    whatsapp: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    remindDay: null,
    createdBy: 0,
    createdAt: "",
    updatedBy: 0,
    packageArr: [],
    updatedAt: "",
    startDateError: "",
    endDateError: "",
    nameError: "",
    taxNoError: "",
    commercialRegistrationNumberError: "",
    mersisNumberError: "",
    veribanUsernameError: "",
    veribanPasswordError: "",
    taxAdministrationError: "",
    packageError: "",
    addressError: "",
    phoneError: "",
    eMailError: "",
    websiteError: "",
    messagePortError: "",
    messageHostError: "",
    messageEMailError: "",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios
      .get(URL + "/countries/getlist", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          countryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      await axios
        .get(URL + "/companies/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            if (response.data.packageArr !== "") {
              const packageArr = response.data.packageArr.split(",");
              packageArr.forEach((element) => {
                var packageArrObj = {};
                packageArrObj.value = element;
                this.state.packageArr.push(packageArrObj);
              });
            } else {
              this.setState({
                packageArr: [],
              });
            }
            if (response.data.paymentMethod !== "") {
              const paymentMethod = response.data.paymentMethod.split(",");
              paymentMethod.forEach((element) => {
                var paymentMethodObj = {};
                paymentMethodObj.value = element;
                this.state.paymentMethodTypeArr.push(paymentMethodObj);
              });
            } else {
              this.setState({
                paymentMethodTypeArr: [],
              });
            }
            this.setState({
              name: response.data.name,
              taxNo: response.data.taxNo,
              commercialRegistrationNumber:
                response.data.commercialRegistrationNumber,
              mersisNumber: response.data.mersisNumber,
              veribanUsername: response.data.veribanUsername,
              veribanPassword: response.data.veribanPassword,
              taxAdministration: response.data.taxAdministration,
              address: response.data.address,
              phone: response.data.phone,
              fax: response.data.fax,
              eMail: response.data.eMail,
              website: response.data.website,
              logo: response.data.logo,
              icon: response.data.icon,
              districtGuid: response.data.districtGuid
                ? response.data.districtGuid
                : "00000000-0000-0000-0000-000000000000",
              townGuid: response.data.townGuid
                ? response.data.townGuid
                : "00000000-0000-0000-0000-000000000000",
              cityGuid: response.data.cityGuid
                ? response.data.cityGuid
                : "00000000-0000-0000-0000-000000000000",
              countryGuid: response.data.countryGuid,
              countryName: response.data.countryName,
              userCount: response.data.userCount.toString(),
              locationCount: response.data.locationCount.toString(),
              zipCode: response.data.zipCode,
              startDate: response.data.startDate,
              endDate: response.data.endDate,
              status: response.data.status,
              messagePort: response.data.messagePort,
              messageHost: response.data.messageHost,
              messageEMail: response.data.messageEMail,
              guid: response.data.guid,
              gsm: response.data.gsm,
              whatsapp: response.data.whatsapp,
              facebook: response.data.facebook,
              twitter: response.data.twitter,
              instagram: response.data.instagram,
              youtube: response.data.youtube,
              remindDay: response.data.remindDay,
              isLoading: false,
            });
            if (this.state.cityGuid) {
              axios
                .get(URL + "/cities/getlist/" + this.state.countryGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    cityArr: myArr,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.townGuid) {
              axios
                .get(URL + "/towns/getlist/" + this.state.cityGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    townArr: myArr,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.districtGuid) {
              axios
                .get(URL + "/districts/getlist/" + this.state.townGuid, config)
                .then((response) => {
                  var myArr = response.data.map(function (item) {
                    return {
                      value: item.guid,
                      label: item.name,
                    };
                  });
                  this.setState({
                    districtArr: myArr,
                    isLoading: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    alertErrorMessage: error.response.data,
                    isLoading: false,
                  });
                });
            }
            if (this.state.countryName === "TÜRKİYE") {
              this.setState({
                isCityTrue: false,
                isTownTrue: false,
                isDistrictTrue: false,
                labelIsTurkey: true,
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleDateChange(startDate) {
    if (startDate) {
      this.setState({
        startDate: startDate,
        startDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        startDate: "",
        startDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDayChange(option) {
    if (option) {
      this.setState({
        remindDay: option.value,
      });
    } else {
      this.setState({
        remindDay: null,
      });
    }
  }

  handleDateChange2(endDate) {
    if (endDate) {
      this.setState({
        endDate: endDate,
        endDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        endDate: "",
        endDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handlePaymentMethodSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        paymentMethodTypeArr: option,
      });
    } else {
      this.setState({
        paymentMethodTypeArr: [],
      });
    }
  }

  handlePackageSelectChange(option) {
    if (option && option.length > 0) {
      this.setState({
        packageArr: option,
        packageError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        packageArr: [],
        packageError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      taxNoError: "",
      commercialRegistrationNumberError: "",
      mersisNumberError: "",
      veribanUsernameError: "",
      veribanPasswordError: "",
      taxAdministrationError: "",
      addressError: "",
      phoneError: "",
      eMailError: "",
      websiteError: "",
      messagePortError: "",
      messageHostError: "",
      messageEMailError: "",
      userCountError: "",
      locationCountError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "taxNo" && value === "") {
      this.setState({
        taxNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "taxNo" && value !== "" && !CgnTaxNoControl.test(value)) {
      this.setState({
        taxNoError: CgnMessage.taxNoErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "taxAdministration" && value === "") {
      this.setState({
        taxAdministrationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "address" && value === "") {
      this.setState({
        addressError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "eMail" && value === "") {
      this.setState({
        eMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "phone" && value === "") {
      this.setState({
        phoneError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "phone" && value !== "" && !CgnPhoneControl.test(value)) {
      this.setState({
        phoneError: CgnMessage.phoneErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "eMail" && value !== "" && !CgnEMailControl.test(value)) {
      this.setState({
        eMailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "messagePort" && value === "") {
      this.setState({
        messagePortError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "messageHost" && value === "") {
      this.setState({
        messageHostError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "messageEMail" && value === "") {
      this.setState({
        messageEMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === " userCount" && value === "") {
      this.setState({
        messageEMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === " locationCount" && value === "") {
      this.setState({
        messageEMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      nameError: "",
      taxNoError: "",
      commercialRegistrationNumberError: "",
      mersisNumberError: "",
      veribanUsernameError: "",
      veribanPasswordError: "",
      taxAdministrationError: "",
      addressError: "",
      phoneError: "",
      eMailError: "",
      websiteError: "",
      districtError: "",
      townError: "",
      cityError: "",
      countryError: "",
      messagePortError: "",
      messageHostError: "",
      messageEMailError: "",
      userCountError: "",
      locationCountError: "",
      alertErrorMessage: "",
    });
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxNo === "") {
      this.setState({
        taxNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxNo !== "" && !CgnTaxNoControl.test(this.state.taxNo)) {
      this.setState({
        taxNoError: CgnMessage.taxNoErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.taxAdministration === "") {
      this.setState({
        taxAdministrationError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.address === "") {
      this.setState({
        addressError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.countryGuid === "") {
      this.setState({
        countryError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.labelIsTurkey === true &&
      this.state.cityGuid === "00000000-0000-0000-0000-000000000000"
    ) {
      this.setState({
        cityError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.labelIsTurkey === true &&
      this.state.cityGuid !== "00000000-0000-0000-0000-000000000000" &&
      this.state.townGuid === "00000000-0000-0000-0000-000000000000"
    ) {
      this.setState({
        townError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.labelIsTurkey === true &&
      this.state.townGuid !== "00000000-0000-0000-0000-000000000000" &&
      this.state.districtGuid === "00000000-0000-0000-0000-000000000000"
    ) {
      this.setState({
        districtError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.phone === "") {
      this.setState({
        phoneError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.phone !== "" && !CgnPhoneControl.test(this.state.phone)) {
      this.setState({
        phoneError: CgnMessage.phoneErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.eMail === "") {
      this.setState({
        eMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.eMail !== "" && !CgnEMailControl.test(this.state.eMail)) {
      this.setState({
        eMailError: CgnMessage.emailErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.messagePort === 0) {
      this.setState({
        messagePortError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.messageHost === "") {
      this.setState({
        messageHostError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.messageEMail === "") {
      this.setState({
        messageEMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.packageArr.length === 0) {
      this.setState({
        packageError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.userCount === "") {
      this.setState({
        userCountError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.locationCount === "") {
      this.setState({
        locationCountError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      this.state.name !== "" &&
      this.state.address !== "" &&
      ((this.state.labelIsTurkey === true &&
        this.state.countryGuid &&
        this.state.cityGuid &&
        this.state.cityGuid !== "00000000-0000-0000-0000-000000000000" &&
        this.state.townGuid &&
        this.state.townGuid !== "00000000-0000-0000-0000-000000000000" &&
        this.state.districtGuid &&
        this.state.districtGuid !== "00000000-0000-0000-0000-000000000000") ||
        (this.state.labelIsTurkey === false && this.state.countryGuid)) &&
      this.state.taxNo !== "" &&
      CgnTaxNoControl.test(this.state.taxNo) &&
      this.state.phone !== "" &&
      CgnPhoneControl.test(this.state.phone) &&
      this.state.taxAdministration !== "" &&
      this.state.phone !== "" &&
      this.state.eMail !== "" &&
      this.state.messagePort !== "" &&
      this.state.messageHost !== "" &&
      this.state.messageEMail !== "" &&
      this.state.userCount !== "" &&
      this.state.locationCount !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var paymentMethodType = [];
      this.state.paymentMethodTypeArr.forEach((element) => {
        var packageyObj = {};
        packageyObj = element.value;
        paymentMethodType.push(packageyObj);
      });
      var packageArr = [];
      this.state.packageArr.forEach((element) => {
        var packageyObj = {};
        packageyObj = element.value;
        packageArr.push(packageyObj);
      });
      var myObj = {
        name: this.state.name,
        taxNo: this.state.taxNo,
        commercialRegistrationNumber: this.state.commercialRegistrationNumber,
        mersisNumber: this.state.mersisNumber,
        veribanUsername: this.state.veribanUsername,
        veribanPassword: this.state.veribanPassword,
        taxAdministration: this.state.taxAdministration,
        address: this.state.address,
        phone: this.state.phone,
        eMail: this.state.eMail,
        website: this.state.website,
        fax: this.state.fax,
        logo: this.state.logo,
        icon: this.state.icon,
        status: this.state.status,
        countryGuid: this.state.countryGuid,
        cityGuid: this.state.cityGuid,
        districtGuid: this.state.districtGuid,
        townGuid: this.state.townGuid,
        zipCode: this.state.zipCode,
        userCount: parseInt(this.state.userCount),
        locationCount: parseInt(this.state.locationCount),
        paymentMethod: paymentMethodType.toString(),
        messagePort: parseInt(this.state.messagePort),
        messageHost: this.state.messageHost,
        messageEMail: this.state.messageEMail,
        messagePassword: this.state.messagePassword,
        packageArr: packageArr.toString(),
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        gsm: this.state.gsm,
        whatsapp: this.state.whatsapp,
        facebook: this.state.facebook,
        twitter: this.state.twitter,
        instagram: this.state.instagram,
        youtube: this.state.youtube,
        remindDay: this.state.remindDay,
      };
      var URLParam = "/companies/add";
      if (this.state.guid && this.state.urlSplit.length !== 2) {
        URLParam = "/companies/update";
        myObj = {
          guid: this.state.guid,
          name: this.state.name,
          taxNo: this.state.taxNo,
          commercialRegistrationNumber: this.state.commercialRegistrationNumber,
          mersisNumber: this.state.mersisNumber,
          veribanUsername: this.state.veribanUsername,
          veribanPassword: this.state.veribanPassword,
          taxAdministration: this.state.taxAdministration,
          address: this.state.address,
          phone: this.state.phone,
          eMail: this.state.eMail,
          website: this.state.website,
          fax: this.state.fax,
          logo: this.state.logo,
          icon: this.state.icon,
          status: this.state.status,
          countryGuid: this.state.countryGuid,
          cityGuid: this.state.cityGuid,
          districtGuid: this.state.districtGuid,
          townGuid: this.state.townGuid,
          zipCode: this.state.zipCode,
          paymentMethod: paymentMethodType.toString(),
          messagePort: parseInt(this.state.messagePort),
          messageHost: this.state.messageHost,
          messageEMail: this.state.messageEMail,
          messagePassword: this.state.messagePassword,
          userCount: parseInt(this.state.userCount),
          locationCount: parseInt(this.state.locationCount),
          packageArr: packageArr.toString(),
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          gsm: this.state.gsm,
          whatsapp: this.state.whatsapp,
          facebook: this.state.facebook,
          twitter: this.state.twitter,
          instagram: this.state.instagram,
          youtube: this.state.youtube,
          remindDay: this.state.remindDay,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/firma-listele";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  };

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        name: "",
        taxNo: "",
        commercialRegistrationNumber: "",
        mersisNumber: "",
        veribanUsername: "",
        veribanPassword: "",
        taxAdministration: "",
        address: "",
        fax: "",
        phone: "",
        eMail: "",
        website: "",
        status: true,
        isCityTrue: true,
        isTownTrue: true,
        isDistrictTrue: true,
        districtGuid: "00000000-0000-0000-0000-000000000000",
        townGuid: "00000000-0000-0000-0000-000000000000",
        cityGuid: "00000000-0000-0000-0000-000000000000",
        countryGuid: "",
        zipCode: "",
        paymentMethodTypeArr: [],
        userCount: "",
        locationCount: "",
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const logo = await resizeFile(file, 1000);
    await this.setState({
      logo: logo,
      logoName: event.target.files[0].name,
      alertErrorMessage: "",
    });
  };

  deleteImage = async (e) => {
    await this.setState({
      logo: "",
      logoName: "",
    });
  };

  uploadedIcon = async (event) => {
    const file = event.target.files[0];
    const icon = await resizeFile(file, 1000);
    this.setState({
      icon: icon,
      iconName: event.target.files[0].name,
      alertErrorMessage: "",
    });
  };

  deleteIcon = async (event) => {
    this.setState({
      icon: "",
      iconName: "",
    });
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      name: "",
      taxNo: "",
      commercialRegistrationNumber: "",
      mersisNumber: "",
      veribanUsername: "",
      veribanPassword: "",
      taxAdministration: "",
      address: "",
      phone: "",
      eMail: "",
      website: "",
      logo: "",
      logoName: "",
      icon: "",
      iconName: "",
      countryGuid: "",
      paymentMethodTypeArr: [],
      packageArr: [],
      userCount: "",
      locationCount: "",
      status: true,
      gsm: "",
      whatsapp: "",
      facebook: "",
      twitter: "",
      instagram: "",
      youtube: "",
      nameError: CgnMessage.textErrorMessage,
      taxNoError: CgnMessage.textErrorMessage,
      commercialRegistrationNumberError: CgnMessage.textErrorMessage,
      mersisNumberError: CgnMessage.textErrorMessage,
      veribanUsernameError: CgnMessage.textErrorMessage,
      veribanPasswordError: CgnMessage.textErrorMessage,
      taxAdministrationError: CgnMessage.textErrorMessage,
      addressError: CgnMessage.textErrorMessage,
      phoneError: CgnMessage.textErrorMessage,
      eMailError: CgnMessage.textErrorMessage,
      websiteError: CgnMessage.textErrorMessage,
      packageArrError: CgnMessage.textErrorMessage,
      userCountError: CgnMessage.textErrorMessage,
      locationCountError: CgnMessage.textErrorMessage,
      countryError: CgnMessage.alertErrorMessage,
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  }

  async handleCountrySelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      await this.setState({
        countryGuid: option.value,
        isCityTrue: false,
        countryError: "",
        alertErrorMessage: "",
      });
      if (option.label === "TÜRKİYE") {
        this.setState({
          labelIsTurkey: true,
        });
      }
      if (option.label !== "TÜRKİYE") {
        this.setState({
          isCityTrue: true,
          isTownTrue: true,
          isDistrictTrue: true,
          labelIsTurkey: false,
          isLoading: false,
          cityGuid: "00000000-0000-0000-0000-000000000000",
          townGuid: "00000000-0000-0000-0000-000000000000",
          districtGuid: "00000000-0000-0000-0000-000000000000",
          cityError: "",
          townError: "",
          districtError: "",
        });
      }
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/cities/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            cityArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        countryGuid: "",
        isCityTrue: true,
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        cityGuid: "00000000-0000-0000-0000-000000000000",
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        cityError: "",
        townError: "",
        districtError: "",
        countryError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleCitySelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      await this.setState({
        cityGuid: option.value,
        isTownTrue: false,
        cityError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/towns/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            townArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        cityGuid: "",
        isTownTrue: true,
        isDistrictTrue: true,
        isLoading: false,
        townGuid: "00000000-0000-0000-0000-000000000000",
        districtGuid: "00000000-0000-0000-0000-000000000000",
        townError: "",
        districtError: "",
        cityError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleTownSelectChange(option) {
    this.setState({
      isLoading: true,
    });
    if (option) {
      this.setState({
        townGuid: option.value,
        isDistrictTrue: false,
        townError: "",
        alertErrorMessage: "",
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/districts/getlist/" + option.value, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            districtArr: myArr,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        townGuid: "",
        isDistrictTrue: true,
        districtGuid: "00000000-0000-0000-0000-000000000000",
        districtError: "",
        isLoading: false,
        townError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  async handleDistrictSelectChange(option) {
    if (option) {
      this.setState({
        districtGuid: option.value,
        districtError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        districtGuid: "00000000-0000-0000-0000-000000000000",
        districtError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <h2 className="d-block mb-3">Firma Bilgileri</h2>

                <Row>
                  <Col lg="2">
                    <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Logo"
                      name="logo"
                      image={this.state.logo}
                      noFoto={noFotoMember}
                      alt={this.state.name}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                    />
                  </Col>

                  <Col lg="2">
                    <ImageUpload
                      className="text-center"
                      style={fotoStyle}
                      label="İkon"
                      name="icon"
                      image={this.state.icon}
                      noFoto={noFotoMember}
                      alt={this.state.name}
                      onChange={this.uploadedIcon}
                      onDelete={this.deleteIcon}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Ad [*]"
                      value={this.state.name}
                      placeHolder="Lütfen firma adı girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="taxAdministration"
                      label="Vergi Dairesi [*]"
                      value={this.state.taxAdministration}
                      placeHolder="Lütfen vergi dairesi girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.taxAdministrationError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="taxNo"
                      maxLength="11"
                      label="Vergi Numarası [*]"
                      value={this.state.taxNo}
                      placeHolder="Lütfen vergi numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.taxNoError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="commercialRegistrationNumber"
                      label="Ticari Sicil Numarası"
                      value={this.state.commercialRegistrationNumber}
                      placeHolder="Lütfen ticari sicil numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.commercialRegistrationNumberError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="mersisNumber"
                      label="Mersis Numarası"
                      value={this.state.mersisNumber}
                      placeHolder="Lütfen mersis numarası girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.mersisNumberError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="veribanUsername"
                      label="Veriban Kullanıcı Adı"
                      value={this.state.veribanUsername}
                      placeHolder="Lütfen veriban kullanıcı adı girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.veribanUsernameError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="veribanPassword"
                      label="Veriban Şifre"
                      value={this.state.veribanPassword}
                      placeHolder="Lütfen veriban şifre girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.veribanPasswordError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="address"
                      label="Adres [*]"
                      value={this.state.address}
                      placeHolder="Lütfen adres girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.addressError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="countryGuid"
                      label="Ülke [*]"
                      placeholder="Lütfen ülke seçiniz..."
                      selectValue={this.state.countryGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCountrySelectChange(option)
                      }
                      options={this.state.countryArr}
                      error={this.state.countryError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="cityGuid"
                      label="Şehir [*]"
                      placeholder="Lütfen şehir seçiniz..."
                      selectValue={this.state.cityGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isCityTrue}
                      onChange={(option) => this.handleCitySelectChange(option)}
                      options={this.state.cityArr}
                      error={this.state.cityError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="townGuid"
                      label="İlçe [*]"
                      placeholder="Lütfen ilçe seçiniz..."
                      selectValue={this.state.townGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isTownTrue}
                      onChange={(option) => this.handleTownSelectChange(option)}
                      options={this.state.townArr}
                      error={this.state.townError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnReactSelect
                      name="districtGuid"
                      label="Mahalle [*]"
                      placeholder="Lütfen mahalle seçiniz..."
                      selectValue={this.state.districtGuid}
                      isMulti={false}
                      isClearable={true}
                      disabled={this.state.isDistrictTrue}
                      onChange={(option) =>
                        this.handleDistrictSelectChange(option)
                      }
                      options={this.state.districtArr}
                      error={this.state.districtError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="zipCode"
                      label="Posta Kodu"
                      value={this.state.zipCode}
                      placeHolder="Lütfen posta kodu girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.zipCodeError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="eMail"
                      label="E-Mail [*]"
                      value={this.state.eMail}
                      placeHolder="Lütfen e-mail girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.eMailError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="website"
                      label="Web Adresi"
                      value={this.state.website}
                      placeHolder="Lütfen web adresi girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.websiteError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="phone"
                      label="Telefon [*]"
                      value={this.state.phone}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.phoneError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="fax"
                      label="Faks"
                      value={this.state.fax}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.faxError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="gsm"
                      label="GSM"
                      value={this.state.gsm}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.gsmError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="whatsapp"
                      label="Whatsapp"
                      value={this.state.whatsapp}
                      placeHolder="0xxxxxxxxxx"
                      maxLength="11"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.whatsappError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="facebook"
                      label="Facebook"
                      value={this.state.facebook}
                      placeHolder="Lütfen facebook adresi girin..."
                      maxLength="500"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.facebookError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="twitter"
                      label="Twitter"
                      value={this.state.twitter}
                      placeHolder="Lütfen twitter adresi girin..."
                      maxLength="500"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.twitterError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="instagram"
                      label="Instagram"
                      value={this.state.instagram}
                      placeHolder="Lütfen instagram adresi girin..."
                      maxLength="500"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.instagramError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="youtube"
                      label="Youtube"
                      value={this.state.youtube}
                      placeHolder="Lütfen youtube adresi girin..."
                      maxLength="500"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.youtubeError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnReactSelect
                      name="packageArr"
                      label="Paket [*]"
                      placeholder="Lütfen paket seçiniz..."
                      isMulti={true}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePackageSelectChange(option)
                      }
                      options={PackageOptionsStatuses}
                      selectValue={this.state.packageArr}
                      error={this.state.packageError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="userCount"
                      label="Kullanıcı Sayısı [*]"
                      value={this.state.userCount}
                      placeHolder="Lütfen kullanıcı sayısı girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.userCountError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="locationCount"
                      label="Depo Sayısı [*]"
                      value={this.state.locationCount}
                      placeHolder="Lütfen depo sayısı girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.locationCountError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnReactSelect
                      name="remindDay"
                      label="Hatırlatma Günü"
                      placeholder="Lütfen gün seçiniz..."
                      selectValue={this.state.remindDay}
                      isMulti={false}
                      isClearable={true}
                      //disabled={this.state.isTownTrue}
                      onChange={(option) => this.handleDayChange(option)}
                      options={Day}
                      //error={this.state.townError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="paymentMethodTypeArr"
                      label="Ödeme Yöntemi"
                      placeholder="Ödeme yöntemi seçiniz..."
                      isMulti={true}
                      isClearable={true}
                      onChange={(option) =>
                        this.handlePaymentMethodSelectChange(option)
                      }
                      options={PaymentMethodTypeArr}
                      selectValue={this.state.paymentMethodTypeArr}
                      error={this.state.paymentMethodTypeError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnDatePicker
                      name="startDate"
                      label="Başlangıç Tarihi [*]"
                      selected={this.state.startDate}
                      onChange={(startDate) => this.handleDateChange(startDate)}
                      error={this.state.startDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="6">
                    <CgnDatePicker
                      name="endDate"
                      label="Bitiş Tarihi"
                      selected={this.state.endDate}
                      onChange={(endDate) => this.handleDateChange2(endDate)}
                      error={this.state.endDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>

                <h2 className="d-block mb-3">E-Mail Ayarları</h2>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="messageHost"
                      label="Giden Posta Sunucusu Adresi [*]"
                      value={this.state.messageHost}
                      placeHolder="Lütfen giden posta sunucusu adresi girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.messageHostError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="messagePort"
                      maxLength="10"
                      label="Giden Posta Sunucu Portu [*]"
                      value={this.state.messagePort}
                      placeHolder="Lütfen giden sunucu portu girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.messagePortError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="messageEMail"
                      label="Gönderen E-Mail [*]"
                      value={this.state.messageEMail}
                      placeHolder="Lütfen gönderen e-mail girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.messageEMailError}
                    />
                  </Col>

                  <Col lg="3">
                    <CgnTextbox
                      type="password"
                      name="messagePassword"
                      maxLength="50"
                      label="Gönderen E-Mail Şifresi"
                      value={this.state.messagePassword}
                      placeHolder="Lütfen gönderen e-mail şifresi girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.messagePasswordError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/"}
              text={CgnMessage.backButton}
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CompanyAddUpdate;
