import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";
class ProductGroupDetail extends Component {
    state = {
        pageTitle: "Ürün Grubu Detay",
        pageTitleDetail: "Ürün Grubu Detay",
        pageAddLink: "urun-grubu-ekle",
        pageListLink: "urun-grubu-listele",
        breadcrumb: [{ text: "Ürün Grubu", link: "#" }],
        isLoading: false,
        alertErrorMessage: "",
        name: "",
        status: "",
        createdBy: "",
        createdAt: "",
        updatedAt: "",
        updatedBy: "",
    };
    async componentDidMount() {
        let urlSplit = window.location.pathname.split("/");
        if (urlSplit.length === 3) {
            this.setState({
                pageTitle: this.state.pageTitleDetail,
                isLoading: true,
            });
            // 
            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            };
            axios
                .get(URL + "/productgroup/get/" + urlSplit[2], config)
                .then((response) => {
                    this.setState({
                        name: response.data.name,
                        status: response.data.status,
                        createdBy: response.data.createdBy,
                        createdAt: response.data.createdAt,
                        updatedBy: response.data.updatedBy,
                        updatedAt: response.data.updatedAt,
                        isLoading: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        alertErrorMessage: error.response.data,
                        isLoading: false,
                    });
                });
        }
    }
    render() {
        return (
            <>
                <ContentTop
                    breadcrumb={this.state.breadcrumb}
                    title={this.state.pageTitle}
                    addLink={"/" + this.state.pageAddLink}
                    listLink={"/" + this.state.pageListLink}
                />
                <Row>
                    <Col>
                        <Card body className="table-responsive">
                            <CgnLoader visibled={this.state.isLoading} />
                            {this.state.alertErrorMessage && (
                                <CgnAlert
                                    color="danger"
                                    title="Uyarı"
                                    text={this.state.alertErrorMessage}
                                />
                            )}
                            <Table className="table table-responsive table-hover">
                                <tbody>
                                    <tr>
                                        <th>Ürün Grubu</th>
                                        <td colSpan="4">
                                            {this.state.name ? this.state.name : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Durumu</th>
                                        <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                                    </tr>
                                    <tr>
                                        <th>Kaydeden Kullanıcı</th>
                                        <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                                        <th>Kaydedilme Tarihi</th>
                                        <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                                    </tr>
                                    <tr>
                                        <th>Son Güncelleyen Kullanıcı</th>
                                        <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                                        <th>Son Güncellenme Tarihi</th>
                                        <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                        <CgnButtonLink
                            color="secondary"
                            to={"/" + this.state.pageListLink}
                            text="Geri"
                            className="btn-back"
                        />
                    </Col>
                </Row>
            </>
        );
    }
}
export default ProductGroupDetail;