import React, { Component } from "react";
import { Row, Col, Card, Table, Form, Modal, ModalHeader, Button } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  
  URL,
  purchaseWorkOrdersElkapol,
  fotoStyle,
} from "../../redux/actions/constants";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import noImg from "../../images/no-img.png";
import CgnTextbox from "../toolbox/CgnTextbox";

class PurchaseWorkOrdersDetail extends Component {
  state = {
    pageTitle: "Satınalma İş Emri Detay",
    pageTitleDetail: "Satınalma İş Emri Detay",
    pageAddLink: "satin-alma-is-emri-ekle",
    pageListLink: "satin-alma-is-emri-listele",
    breadcrumb: [{ text: "Satınalma İş Emri", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    name: "",
    description: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    pageType: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    purchaseWorkOrderImageDto: [],
    isOpen: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit[1] === "yetkili-satin-alma-is-emri-detay") {
      await this.setState({
        pageType: "yetkili-satin-alma-is-emri",
        pageTitle: "Yetkili Satınalma İş Emri Detay",
        pageTitleDetail: "Yetkili Satınalma İş Emri Detay",
        pageAddLink: "",
        pageListLink: "yetkili-satin-alma-is-emri",
        breadcrumb: [{ text: "Yetkili Satınalma İş Emri", link: "#" }],
      });
    }

    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/purchaseworkorders/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            purchaseWorkOrderImageDto: response.data.purchaseWorkOrderImageDto,
            name: response.data.name,
            description: response.data.description,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = async () => {
    await this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggle = async (image) => {
    await this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      nameError: "",
      alertErrorMessage: "",
    });
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      nameError: "",
    });
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "/purchaseworkorders/update";
    var myObj = {
      guid: this.state.guid,
      purchaseWorkOrderImageDto: this.state.purchaseWorkOrderImageDto,
      name: this.state.name,
      description: this.state.description,
      status: this.state.status,
    };
    axios
      .post(URL + URLParam, myObj, config)
      .then(() => {
        setTimeout(() => {
          window.location.href = "/yetkili-satin-alma-is-emri";
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    var self = this;
    var self1 = this;
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={
            this.state.pageAddLink !== "" && "/" + this.state.pageAddLink
          }
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Satınalma İş Emri</th>
                    <td colSpan="4">
                      {this.state.name ? this.state.name : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              {this.state.purchaseWorkOrderImageDto !== null &&
                this.state.purchaseWorkOrderImageDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2 mb-3">
                      <tr style={mainRow}>
                        <th>Ürün Fotoğrafı</th>
                      </tr>
                      <Row>
                        {this.state.purchaseWorkOrderImageDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}
              {this.state.pageType === "yetkili-satin-alma-is-emri" && (
                <Form onSubmit={this.handleSave} style={{ height: "250px" }}>
                  <CgnLoader visibled={this.state.isLoading} />
                  <Row>
                    <Col lg="12">
                      <CgnTextbox
                        inlineStyle={textAreaStyle}
                        type="textarea"
                        name="description"
                        label="Açıklama"
                        value={this.state.description}
                        placeHolder="Lütfen açıklama girin..."
                        autoComplete="off"
                        onChange={this.handleChange}
                        error={this.state.descriptionError}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <CgnReactSelect
                        name="status"
                        label="Durumu"
                        isMulti={false}
                        selectValue={this.state.status}
                        options={purchaseWorkOrdersElkapol}
                        onChange={(status) =>
                          this.handleStatusSelectChange(status)
                        }
                      />
                    </Col>
                  </Row>
                  <CgnButtonGroup items={this.state.buttons} />
                </Form>
              )}
            </Card>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
              <ModalHeader
                toggle={this.toggle}
              ></ModalHeader>
              <img
                className="modalImage"
                src={this.state.isImage}
                style={fotoStyle}
              />
            </Modal>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PurchaseWorkOrdersDetail;
