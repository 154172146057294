import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Form } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import CgnTextEditor from "../toolbox/CgnTextEditor";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import CgnImageUploadMulti from "../toolbox/CgnImageUploadMulti";
import pdfIcon from "../../images/pdfIcon.png";
import docIcon from "../../images/docIcon.png";
import noImg from "../../images/no-img.png";
import excelIcon from "../../images/xlsIcon.png";
import CgnMessage from "../toolbox/CgnMessage";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnButton from "../toolbox/CgnButton";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  
  URL,
  fotoStyle,
  fotoStyle2,
  localFilePath,
  moldingRoomStatus,
  statusStatusMedikoz,
  MembershipList,
} from "../../redux/actions/constants";
import fileIcon from "../../images/file.jpg";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import moment from "moment";
class EMailQuestDetail extends Component {
  state = {
    pageTitle: "E-Mail Görev Detay",
    pageTitleDetail: "E-Mail Görev Detay",
    pageAddLink: "e-mail-gorev-ekle",
    pageListLink: "e-mail-gorev-listele",
    breadcrumb: [{ text: "E-Mail Görev", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    creationDate: "",
    guid: "",
    senderEMail: "",
    membershipGuid: "",
    plannedEndDate: "",
    plannedEndDateNew: "",
    plannedEndDateNewError: "",
    name: "",
    description: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    eMailQuestDocumentDto: [],
    eMailQuestDtoParent: [],
    descriptionParent: "",
    descriptionParentHtml: "",
    descriptionParentError: "",
    eMailQuestDocumentDtoParent: [],
    eMailQuestDocumentDtoParentError: "",
    senderEMailParent: false,
    alertErrorMessageParent: "",
    isLoadingParent: false,
    eMailQuestArr: [],
    alertErrorMessageCombine: "",
    isLoadingCombine: false,
    eMailQuestGuidCombine: "",
    eMailQuestGuidCombineError: "",
    isLoadingHandOver: false,
    membershipGuidHandOver: "",
    membershipGuidHandOverError: "",
    alertErrorMessageStatus: "",
    isLoadingStatus: false,
    statusStatus: "",
    statusStatusError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoadingParent,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoadingParent,
      },
    ],
    buttons2: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoadingParent,
      },
    ],
    alertErrorMessagePlannedEndDateNew: "",
    isLoadingPlannedEndDateNew: false,
  };
  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
        isLoadingCombine: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/emailquest/getlistselect", config)
        .then((response) => {
          var myArr = response.data
            .filter((item) => item.guid !== urlSplit[2])
            .map(function (item) {
              return {
                value: item.guid,
                label:
                  item.creationDate +
                  (item.senderEMail !== "-" ? " - " + item.senderEMail : "") +
                  " - " +
                  item.membershipName +
                  " - " +
                  item.name,
              };
            });
          this.setState({
            eMailQuestArr: myArr,
            isLoadingCombine: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoadingCombine: false,
          });
        });
      var self = this;
      await MembershipList().then(function (data) {
        self.setState({
          membershipArr: data,
        });
      });
      axios
        .get(URL + "/emailquest/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            senderEMail: response.data.senderEMail,
            membershipGuid: response.data.membershipGuid,
            plannedEndDate: response.data.plannedEndDate,
            plannedEndDateNew: response.data.plannedEndDate,
            creationDate: response.data.creationDate,
            membershipName: response.data.membershipName,
            name: response.data.name,
            description: response.data.description,
            eMailQuestDocumentDto: response.data.eMailQuestDocumentDto,
            eMailQuestDtoParent: response.data.eMailQuestDtoParent,
            status: response.data.status,
            statusStatus: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  fileClick = (image) => {
    let urlWebSite = "http://sys.cogenteknoloji.com";
    let urlSplit = window.location.hostname;
    if (urlSplit === "crm.cevirikalitesi.com") {
      urlWebSite = "https://www.cevirikalitesi.com";
    }

    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open(urlWebSite + "/Files/" + myData, "_blank");
      this.setState({
        isLoadingParent: false,
      });
    }
  };
  handleEditorChange = (editorState, editorStateHtml) => {
    if (editorStateHtml) {
      this.setState({
        descriptionParent: editorState,
        descriptionParentHtml: editorStateHtml,
        descriptionParentError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        descriptionParentError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };
  uploadedDocument = async (event) => {
    let array = this.state.eMailQuestDocumentDtoParent;
    const files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.setState({
        isLoadingParent: true,
      });
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      await axios
        .post(URL + "/uploads2/add", formData)
        .then((response) => {
          array.push({
            image: response.data,
            imageName: file.name,
          });
          this.setState({
            isLoadingParent: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessageParent: error.response.data,
          });
        });
    }
    await this.setState({ eMailQuestDocumentDtoParent: array });
  };
  deleteDocument = (event) => {
    var index = -1;
    let array = this.state.eMailQuestDocumentDtoParent;
    this.state.eMailQuestDocumentDtoParent.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ eMailQuestDocumentDtoParent: array });
        }
      }
    });
  };
  handleSenderEMailSelectChange(senderEMailParent) {
    this.setState({ senderEMailParent: senderEMailParent.value });
  }
  handleClear = (event) => {
    this.clearPage();
  };
  clearPage() {
    this.setState({
      descriptionParent: "",
      descriptionParentHtml: "",
      descriptionParentError: "",
      eMailQuestDocumentDtoParent: [],
      eMailQuestDocumentDtoParentError: "",
      senderEMailParent: false,
      alertErrorMessageParent: "",
    });
  }
  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessageParent: "",
      descriptionParentError: "",
    });
    if (this.state.descriptionParent === "") {
      this.setState({
        descriptionParentError: CgnMessage.textErrorMessage,
        alertErrorMessageParent: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.descriptionParent !== "") {
      this.setState({
        isLoadingParent: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        parentGuid: this.state.guid,
        senderEMail: this.state.senderEMail,
        membershipGuid: this.state.membershipGuid,
        name: this.state.name,
        description: this.state.descriptionParentHtml,
        eMailQuestDocumentDto: this.state.eMailQuestDocumentDtoParent,
        sendEMail: this.state.senderEMailParent,
        status: this.state.status,
      };
      var URLParam = "/emailquest/add";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessageParent: error.response.data,
            isLoadingParent: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessageParent: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };
  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/emailquest/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }
  handleEMailQuestGuidCombineSelectChange(option) {
    if (option) {
      this.setState({
        eMailQuestGuidCombine: option.value,
        eMailQuestGuidCombineError: "",
        alertErrorMessageCombine: "",
      });
    } else {
      this.setState({
        eMailQuestGuidCombine: null,
        eMailQuestGuidCombineError: CgnMessage.textErrorMessage,
        alertErrorMessageCombine: CgnMessage.alertErrorMessage,
      });
    }
  }
  handleSave2 = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessageCombine: "",
      eMailQuestGuidCombineError: "",
    });
    if (this.state.eMailQuestGuidCombine === "") {
      this.setState({
        eMailQuestGuidCombineError: CgnMessage.textErrorMessage,
        alertErrorMessageCombine: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.eMailQuestGuidCombine !== "") {
      this.setState({
        isLoadingCombine: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        guid: this.state.eMailQuestGuidCombine,
        parentGuid: this.state.guid,
      };
      var URLParam = "/emailquest/combine/";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.href =
              "/e-mail-gorev-detay/" + this.state.eMailQuestGuidCombine;
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessageCombine: error.response.data,
            isLoadingCombine: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessageCombine: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleStatusSelectChange(statusStatus) {
    this.setState({ statusStatus: statusStatus.value });
  }
  handleSave3 = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessageStatus: "",
      eMailQuestGuidStatusError: "",
    });
    if (this.state.statusStatus === "") {
      this.setState({
        statusStatusError: CgnMessage.textErrorMessage,
        alertErrorMessageStatus: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.statusStatus !== "") {
      this.setState({
        isLoadingStatus: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        guid: this.state.guid,
        status: this.state.statusStatus,
      };
      var URLParam = "/emailquest/status/";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.href = "/e-mail-gorev-detay/" + this.state.guid;
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessageStatus: error.response.data,
            isLoadingStatus: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessageStatus: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleMembershipGuidHandOverSelectChange(option) {
    if (option) {
      this.setState({
        membershipGuidHandOver: option.value,
        membershipGuidHandOverError: "",
        alertErrorMessageHandOver: "",
      });
    } else {
      this.setState({
        membershipGuidHandOver: null,
        membershipGuidHandOverError: CgnMessage.textErrorMessage,
        alertErrorMessageHandOver: CgnMessage.alertErrorMessage,
      });
    }
  }
  handleSave4 = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessageHandOver: "",
      membershipGuidHandOverError: "",
    });
    if (this.state.membershipGuidHandOver === "") {
      this.setState({
        membershipGuidHandOverError: CgnMessage.textErrorMessage,
        alertErrorMessageHandOver: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.membershipGuidHandOver !== "") {
      this.setState({
        isLoadingHandOver: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        guid: this.state.membershipGuidHandOver,
        parentGuid: this.state.guid,
      };
      var URLParam = "/emailquest/handover/";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.href = "/e-mail-gorev-listele";
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessageHandOver: error.response.data,
            isLoadingHandOver: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessageHandOver: CgnMessage.alertErrorMessage,
      });
    }
  };
  handleDateChange(plannedEndDateNew) {
    if (plannedEndDateNew) {
      this.setState({
        plannedEndDateNew: plannedEndDateNew,
        plannedEndDateNewError: "",
        alertErrorMessagePlannedEndDateNew: "",
      });
    } else {
      this.setState({
        plannedEndDateNew: "",
        plannedEndDateNewError: CgnMessage.textErrorMessage,
        alertErrorMessagePlannedEndDateNew: CgnMessage.alertErrorMessage,
      });
    }
  }
  handleSave5 = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessagePlannedEndDateNew: "",
      plannedEndDateNewError: "",
    });
    if (this.state.plannedEndDateNew === "") {
      this.setState({
        plannedEndDateNewError: CgnMessage.textErrorMessage,
        alertErrorMessagePlannedEndDateNew: CgnMessage.alertErrorMessage,
      });
    } else if (this.state.plannedEndDateNew !== "") {
      this.setState({
        isLoadingPlannedEndDateNew: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        guid: this.state.guid,
        plannedEndDate:
          this.state.plannedEndDateNew &&
          this.state.plannedEndDateNew.toString().indexOf("/") > 0
            ? new Date(
                this.state.plannedEndDateNew.split("/")[2] +
                  "-" +
                  this.state.plannedEndDateNew.split("/")[1] +
                  "-" +
                  this.state.plannedEndDateNew.split("/")[0]
              )
            : moment(this.state.plannedEndDateNew).format("DD/MM/YYYY"),
      };
      var URLParam = "/emailquest/plannedenddate/";
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            window.location.href = "/e-mail-gorev-detay/" + this.state.guid;
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessagePlannedEndDateNew: error.response.data,
            isLoadingPlannedEndDateNew: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessagePlannedEndDateNew: CgnMessage.alertErrorMessage,
      });
    }
  };
  render() {
    var self1 = this;
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Tarih</th>
                    <td colSpan="4">{this.state.creationDate}</td>
                  </tr>
                  <tr>
                    <th>Planlı Bitiş Tarihi</th>
                    <td colSpan="4">{this.state.plannedEndDate ? this.state.plannedEndDate : "-"}</td>
                  </tr>
                  <tr>
                    <th>Gönderen</th>
                    <td colSpan="4">
                      {this.state.senderEMail ? this.state.senderEMail : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Kullanıcı</th>
                    <td colSpan="4">{this.state.membershipName}</td>
                  </tr>
                  <tr>
                    <th>Konu</th>
                    <td colSpan="4">{this.state.name}</td>
                  </tr>
                  <tr>
                    <th>Açıklama</th>
                    <td
                      colSpan="4"
                      dangerouslySetInnerHTML={{
                        __html: this.state.description
                          ? this.state.description
                          : "-",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">
                      {this.state.status === 1
                        ? "Bekliyor"
                        : this.state.status === 2
                        ? "İşlemde"
                        : "Tamamlandı"}
                    </td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              {this.state.eMailQuestDocumentDto &&
                this.state.eMailQuestDocumentDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.eMailQuestDocumentDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={fileIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}
            </Card>
            {this.state.eMailQuestDtoParent.map(function (item) {
              return (
                <>
                  <Card body className="table-responsive">
                    <CgnLoader visibled={self1.isLoading} />
                    <Table className="table table-responsive table-hover">
                      <tbody>
                        <tr>
                          <th>Tarih</th>
                          <td colSpan="4">{item.creationDate}</td>
                        </tr>
                        <tr>
                          <th>Gönderen</th>
                          <td colSpan="4">
                            {item.senderEMail
                              ? item.senderEMail
                              : item.createdBy}
                          </td>
                        </tr>
                        <tr>
                          <th>Açıklama</th>
                          <td
                            colSpan="4"
                            dangerouslySetInnerHTML={{
                              __html: item.description ? item.description : "-",
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <th>E-Mail Gönderildi Mi?</th>
                          <td colSpan="4">
                            {item.sendEMail ? "Evet" : "Hayır"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    {item.eMailQuestDocumentDto &&
                      item.eMailQuestDocumentDto.length > 0 && (
                        <Table className="table table-responsive table-hover table-product2">
                          <tr style={mainRow}>
                            <th>Belgeler</th>
                          </tr>
                          <Row>
                            {item.eMailQuestDocumentDto.map(function (item2) {
                              return (
                                <>
                                  <div className="pl-4">
                                    <Button
                                      onClick={() =>
                                        self1.fileClick(item2.image)
                                      }
                                      className="modalButtonDetail"
                                    >
                                      <img
                                        src={fileIcon}
                                        style={fotoStyle2}
                                        alt={item2.imageName}
                                      />
                                    </Button>
                                    <p style={{ maxWidth: "150px" }}>
                                      {item2.imageName}
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                          </Row>
                        </Table>
                      )}
                    <div className="w-100">
                      <CgnButton
                        className="dynamicRowDeleteButton2"
                        type="button"
                        color="danger"
                        onClick={() => self1.handleDelete(item.guid)}
                        text={"Sil"}
                      />
                    </div>
                  </Card>
                </>
              );
            })}

            <Form onSubmit={this.handleSave5}>
              <Card body>
                <CgnLoader visibled={this.state.isLoadingPlannedEndDateNew} />
                <Row>
                  <Col lg="12">
                    <h2 className="mb-3">Planlı Bitiş Tarihi Değiştir</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnDatePicker
                      name="plannedEndDateNew"
                      label="Planlı Bitiş Tarihi [*]"
                      selected={
                        this.state.plannedEndDateNew &&
                        this.state.plannedEndDateNew.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.plannedEndDateNew.split("/")[2] +
                                "-" +
                                this.state.plannedEndDateNew.split("/")[1] +
                                "-" +
                                this.state.plannedEndDateNew.split("/")[0]
                            )
                          : this.state.plannedEndDateNew
                      }
                      onChange={(plannedEndDateNew) =>
                        this.handleDateChange(plannedEndDateNew)
                      }
                      error={this.state.plannedEndDateNewError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessagePlannedEndDateNew && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessagePlannedEndDateNew}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons2} />
              </Card>
            </Form>

            <Form onSubmit={this.handleSave3}>
              <Card body>
                <CgnLoader visibled={this.state.isLoadingStatus} />
                <Row>
                  <Col lg="12">
                    <h2 className="mb-3">Durum Değiştir</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="statusStatus"
                      label="Durumu"
                      placeholder="E-Mail Görev durumu seçiniz..."
                      selectValue={this.state.statusStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(statusStatus) =>
                        this.handleStatusSelectChange(statusStatus)
                      }
                      options={statusStatusMedikoz}
                      error={this.state.statusStatusError}
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessageStatus && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessageStatus}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons2} />
              </Card>
            </Form>

            <Form onSubmit={this.handleSave2}>
              <Card body>
                <CgnLoader visibled={this.state.isLoadingCombine} />
                <Row>
                  <Col lg="12">
                    <h2 className="mb-3">Birleştir</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="eMailQuestGuidCombine"
                      label="E-Mail Görev [*]"
                      placeholder="E-Mail Görev seçiniz..."
                      selectValue={this.state.eMailQuestGuidCombine}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleEMailQuestGuidCombineSelectChange(option)
                      }
                      options={this.state.eMailQuestArr}
                      error={this.state.eMailQuestGuidCombineError}
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessageCombine && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessageCombine}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons2} />
              </Card>
            </Form>

            <Form onSubmit={this.handleSave4}>
              <Card body>
                <CgnLoader visibled={this.state.isLoadingHandOver} />
                <Row>
                  <Col lg="12">
                    <h2 className="mb-3">Devret</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="membershipGuid"
                      label="Kullanıcı [*]"
                      placeholder="Kullanıcı seçiniz..."
                      selectValue={this.state.membershipGuidHandOver}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleMembershipGuidHandOverSelectChange(option)
                      }
                      options={this.state.membershipArr}
                      error={this.state.membershipGuidHandOverError}
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessageHandOver && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessageHandOver}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons2} />
              </Card>
            </Form>

            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoadingParent} />
                <Row>
                  <Col lg="12">
                    <h2 className="mb-3">Cevap Yaz</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnTextEditor
                      name="descriptionParent"
                      label="Açıklama [*]"
                      editorState={this.state.descriptionParent}
                      placeHolder="Lütfen açıklama girin..."
                      onChange={(editorState) =>
                        this.handleEditorChange(
                          editorState,
                          draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                        )
                      }
                      error={this.state.descriptionParentError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="pl-0">
                    <CgnImageUploadMulti
                      images={this.state.eMailQuestDocumentDtoParent}
                      noFoto={noImg}
                      style={fotoStyle}
                      pdfIcon={pdfIcon}
                      docIcon={docIcon}
                      excelIcon={excelIcon}
                      accept="application/pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png"
                      className="text-center text-danger"
                      label={"Belgeler"}
                      onChange={this.uploadedDocument}
                      onDelete={this.deleteDocument}
                      error={this.state.eMailQuestDocumentDtoParentError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="status"
                      label="E-Mail Gönderilsin Mi?"
                      isMulti={false}
                      selectValue={this.state.senderEMailParent}
                      options={moldingRoomStatus}
                      onChange={(senderEMailParent) =>
                        this.handleSenderEMailSelectChange(senderEMailParent)
                      }
                      disabled={this.state.senderEMail ? false : true}
                    />
                  </Col>
                  <Col lg="9"></Col>
                </Row>
                {this.state.alertErrorMessageParent && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessageParent}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default EMailQuestDetail;
