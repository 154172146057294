import React, { Component } from "react";
import { Row, Col, Card, Form, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  fotoStyle,
  resizeFile
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import noImg from "../../images/no-img.png";
import ImageUpload from "../toolbox/CgnImageUpload";
import CgnTextbox from "../toolbox/CgnTextbox";
import ExcelUploadMulti from "../toolbox/CgnExcelUploadMulti";
import excelIcon from "../../images/xlsIcon.png";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";

class CustomerDocumentProductRecordAddUpdate extends Component {
  state = {
    pageTitle: "Müşteri Tarafından Eklenen/Eklenecek Belgeler Ekle (ÜTS Ürün Kaydı)",
    pageTitleAdd: "Müşteri Tarafından Eklenen/Eklenecek Belgeler Ekle (ÜTS Ürün Kaydı)",
    pageTitleUpdate: "Müşteri Tarafından Eklenen/Eklenecek Belgeler Güncelle",
    pageAddLink: "musteri-urun-belge-kayit-ekle",
    pageListLink: "musteri-urun-belge-kayit-listele",
    breadcrumb: [{ text: "Müşteri Tarafından Eklenen/Eklenecek Belgeler", link: "#" }],
    isLoading: false,
    urlSplit: [],
    barcodeDocumentDto: [],
    customerDocumentImageProductDto: [],
    barcodeProductImageDto: [],
    gtipDocumentDto: [],
    name: "",
    customerName: "",
    requestedDocumentsName: "",
    guid: "",
    productName: "",
    barcode: "",
    productImage: "",
    tag: "",
    productNameError: "",
    productImageError: "",
    tagError: "",
    description: "",
    gtipNoError: "",
    gtipNo: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    customerDocumentImageDtoProductError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/customerDocumentProductRecords/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              customerName: response.data.customerName,
              documentRecordGuid: response.data.documentRecordGuid,
              productImage: response.data.productImage,
              productName: response.data.productName,
              barcode: response.data.barcode,
              tag: response.data.tag,
              gtipNo: response.data.gtipNo,
              status: response.data.status,
              description: response.data.description,
              barcodeDocumentDto: response.data.barcodeDocumentDto,
              barcodeProductImageDto: response.data.barcodeProductImageDto,
              gtipDocumentDto: response.data.gtipDocumentDto,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  uploadedImage1 = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      tag: image,
      //imageName: event.target.files[0].name,
    });
  };

  deleteImage1 = (event) => {
    this.setState({
      tag: "",
    });
  };

  uploadedImage2 = async (event) => {
    let array = this.state.barcodeDocumentDto;
    for(var i = 0; i < event.target.files.length; i++){
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .post(URL + "/uploads/add", formData, config)
        .then((response) => {
          array.push({
              image: response.data,
              imageName: file.name
          })
          this.state.name2 += file.name + " ";
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    } 
    await this.setState({barcodeDocumentDto: array});
  };

  deleteImage2 = (event) => {
    var index = -1;
    let array = this.state.barcodeDocumentDto;
     this.state.barcodeDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({barcodeDocumentDto: array});
          }
        }
     });
  };

  uploadedImage4 = async (event) => {
    let array = this.state.gtipDocumentDto;
    for(var i = 0; i < event.target.files.length; i++){
      const file = event.target.files[i];
      const formData = new FormData();
      formData.append("files", file);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .post(URL + "/uploads/add", formData, config)
        .then((response) => {
          array.push({
              image: response.data,
              imageName: file.name
          })
          this.state.name2 += file.name + " ";
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
          });
        });
    } 
    await this.setState({gtipDocumentDto: array});
  };

  deleteImage4 = (event) => {
    var index = -1;
    let array = this.state.gtipDocumentDto;
     this.state.gtipDocumentDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({gtipDocumentDto: array});
          }
        }
     });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      productNameError: "",
      tagError: "",
      gtipNoError: "",
      alertErrorMessage: "",
    });
    if (name === "productName" && value === "") {
      this.setState({
        productNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "tag" && value === "") {
      this.setState({
        tagError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "gtipNo" && value === "") {
      this.setState({
        gtipNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      status: true,
      productImage: "",
      productName: "",
      barcode: "",
      tag: "",
      gtipNo: "",
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  uploadedImage = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file, 1000);
    this.setState({
      productImage: image,
      imageName: event.target.files[0].name,
    });
  };

  deleteImage = (event) => {
    this.setState({
      productImage: "",
    });
  };

  uploadedImage3 = async (event) => {
    let array = this.state.barcodeProductImageDto;
    for(var i = 0; i < event.target.files.length; i++){
      const file = event.target.files[i];
      if(file.type === "image/png" || file.type === "image/jpeg"){
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        })
      }
    }
    await this.setState({barcodeProductImageDto: array});
  }

  deleteImage3 = (event) => {
    var index = -1;
    let array = this.state.barcodeProductImageDto;
     this.state.barcodeProductImageDto.forEach((element) => {
      index++;
        if(element.image === event){         
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({barcodeProductImageDto: array});
          }
        }
     });
  };
 

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      productNameError: "",
      productImageError: "",
      tagError: "",
      gtipNoError: "",
    });
    if (this.state.productName === "" || this.state.productName === null) {
      this.setState({
        productNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }  
    if (this.state.tag === "" || this.state.tag === null) {
      this.setState({
        tagError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } 
    if (this.state.gtipNo === "" || this.state.gtipNo === null) {
      this.setState({
        gtipNoError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } 
    if(this.state.barcodeProductImageDto.length === 0){
      this.setState({
        productImageError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    else if (this.state.barcodeProductImageDto.length > 0 &&
            this.state.productName !== "" &&
            this.state.tag !== "" &&
            this.state.gtipNo !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        productImage: this.state.productImage,
        productName: this.state.productName,
        barcode: this.state.barcode,
        tag: this.state.tag,
        gtipNo: this.state.gtipNo,
        status: this.state.status,
        barcodeDocumentDto: this.state.barcodeDocumentDto,
        barcodeProductImageDto: this.state.barcodeProductImageDto,
        gtipDocumentDto: this.state.gtipDocumentDto,
      };
      var URLParam = "/customerDocumentProductRecords/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "musteri-urun-belge-kayit-guncelle"
      ) {
        URLParam = "/customerDocumentProductRecords/update";
        myObj = {
          guid: this.state.guid,
          productImage: this.state.productImage,
          productName: this.state.productName,
          barcode: this.state.barcode,
          tag: this.state.tag,
          gtipNo: this.state.gtipNo,
          status: this.state.status,
          barcodeDocumentDto: this.state.barcodeDocumentDto,
          barcodeProductImageDto: this.state.barcodeProductImageDto,
          gtipDocumentDto: this.state.gtipDocumentDto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "musteri-urun-belge-kayit-guncelle"
            ) {
              window.location.href = "/musteri-urun-belge-kayit-listele";
            }
            if (this.state.urlSplit[1] === "musteri-urun-belge-kayit-ekle") {
              window.location.reload();
            }
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
        {(this.state.urlSplit[1] === "musteri-urun-belge-kayit-ekle" ||
          this.state.urlSplit[1] === "musteri-urun-belge-kayit-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            listLink={"/" + this.state.pageListLink}
          />
        )}       
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Müşteri</th>
                    <td colSpan="4">
                      {this.state.customerName ? this.state.customerName : "-"}
                    </td>
                  </tr>             
                 <tr>
                  <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description}
                    </td>
                  </tr>

                </tbody>
              </Table>
                <Row>
                  <Col lg="6">
                  <PictureUploadMulti
                      image={this.state.barcodeProductImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Ürün Fotoğrafları [*]"}
                      onChange={this.uploadedImage3}
                      onDelete={this.deleteImage3}
                      alt={this.state.name}
                      error={this.state.productImageError}
                    />
                  </Col>
                  <Col lg="6">
                  <ImageUpload
                      accept="image/png, image/jpeg"
                      className="text-center"
                      style={fotoStyle}
                      label="Etiket [*]"
                      image={this.state.tag}
                      noFoto={noImg}
                      alt={this.state.name}
                      onChange={this.uploadedImage1}
                      onDelete={this.deleteImage1}
                      error={this.state.tagError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                  <CgnTextbox
                      type="text"
                      name="productName"
                      label="Ürün Adı [*]"
                      value={this.state.productName}
                      placeHolder="Lütfen ürün adı girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.productNameError}
                    />
                  </Col>
                  <Col lg="6">
                  <CgnTextbox
                      type="text"
                      name="barcode"
                      label="Tekli Barkod"
                      value={this.state.barcode}
                      placeHolder="Lütfen tekli barkod girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col lg="6" className="pl-0">
                  <ExcelUploadMulti
                      images={this.state.barcodeDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      excelIcon={excelIcon}
                      accept=".xlsx, .xls, .csv"
                      className="text-center text-danger"
                      label={"Excel Çoklu Barkod Dosyaları"}
                      onChange={this.uploadedImage2}
                      onDelete={this.deleteImage2}
                      //error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                <Row>           
                <Col lg="6" className="pl-0">
                  <ExcelUploadMulti
                      images={this.state.gtipDocumentDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      excelIcon={excelIcon}
                      accept=".xlsx, .xls, .csv"
                      className="text-center text-danger"
                      label={"Excel Çoklu Gtip Numaraları"}
                      onChange={this.uploadedImage4}
                      onDelete={this.deleteImage4}
                      //error={this.state.imageError2}
                    />
                  </Col>    
                  <Col lg="6">
                  <CgnTextbox
                      type="text"
                      name="gtipNo"
                      label="GTİP Numarası [*]"
                      value={this.state.gtipNo}
                      placeHolder="Lütfen GTİP Numarası girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.gtipNoError}
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "musteri-urun-belge-kayit-ekle" ||
              this.state.urlSplit[1] === "musteri-urun-belge-kayit-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(CustomerDocumentProductRecordAddUpdate);
