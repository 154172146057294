import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import axios from "axios";
import {  URL } from "../../redux/actions/constants";

class NoteAddUpdate extends Component {
  state = {
    pageTitle: "Not Ekle",
    pageTitleAdd: "Not Ekle",
    pageTitleUpdate: "Not Güncelle",
    pageAddLink: "not-ekle",
    pageListLink: "not-listele",
    breadcrumb: [{ text: "Not", link: "#" }],
    isLoading: false,
    urlSplit: [],
    customerGuid: null,
    supplierGuid: null,
    moldingRoomGuid: null,
    offerGuid: null,
    noteType: null,
    noteDate: "",
    title: "",
    description: "",
    guid: "",
    noteDateError: "",
    titleError: "",
    descriptionError: "",
    status: true,
    activeTab: "6",
    alertErrorMessage: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    updated: false,
    parentGuid: null,
    url: "",
  };

  async componentDidMount() {
    if (this.props.guid !== undefined) {
      this.state.guid = this.props.guid;
    }
    if (this.props.updated !== undefined) {
      this.state.updated = this.props.updated;
    }
    this.setState({
      isLoading: true,
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      url: urlSplit[1],
    });
    if (
      (urlSplit.length === 3 && urlSplit[1] === "not-guncelle") ||
      urlSplit[1] === "musteri-detay" ||
      urlSplit[1] === "kaliphane-detay" ||
      urlSplit[1] === "teklif3-detay" ||
      urlSplit[1] === "teklif32-detay" ||
      urlSplit[1] === "teklif33-detay" ||
      urlSplit[1] === "uretim-detay" ||
      urlSplit[1] === "uretim-planlama-detay" ||
      urlSplit[1] === "teklif3-sevk-edilen-detay"
    ) {
      if (
        urlSplit[1] === "musteri-detay" ||
        urlSplit[1] === "kaliphane-detay" ||
        urlSplit[1] === "teklif3-detay" ||
        urlSplit[1] === "teklif32-detay" ||
        urlSplit[1] === "teklif33-detay" ||
        urlSplit[1] === "uretim-detay" ||
        urlSplit[1] === "uretim-planlama-detay" ||
        urlSplit[1] === "teklif3-sevk-edilen-detay"
      ) {
        this.setState({
          guid: this.state.guid,
          pageTitle: this.state.pageTitleUpdate,
          noteType: null,
          customerGuid: null,
          supplierGuid: null,
          moldingRoomGuid: null,
          offerGuid: null,
          noteDate: "",
          title: "",
          description: "",
          isLoading: true,
        });
      } else {
        this.setState({
          guid: urlSplit[urlSplit.length - 1],
          pageTitle: this.state.pageTitleUpdate,
          noteType: null,
          customerGuid: null,
          supplierGuid: null,
          moldingRoomGuid: null,
          offerGuid: null,
          noteDate: "",
          title: "",
          description: "",
          isLoading: true,
        });
      }

      //this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/notes/get/" + this.state.guid, config)
        .then((response) => {
          if (this.state.guid) {
            var noteType = 1;
            if (urlSplit[1] === "musteri-detay") {
              noteType = 1;
            } else if (urlSplit[1] === "tedarikci-detay") {
              noteType = 2;
            } else if (urlSplit[1] === "kaliphane-detay") {
              noteType = 3;
            } else if (urlSplit[1] === "teklif3-detay" || urlSplit[1] === "teklif32-detay" || urlSplit[1] === "teklif33-detay") {
              noteType = 4;
            } else if (urlSplit[1] === "uretim-detay") {
              noteType = 5;
            } else if (urlSplit[1] === "uretim-planlama-detay") {
              noteType = 6;
            }

            this.setState({
              noteType: noteType,
              supplierGuid: response.data.supplierGuid,
              customerGuid: response.data.customerGuid,
              moldingRoomGuid: response.data.moldingRoomGuid,
              offerGuid: response.data.offerGuid,
              noteDate: response.data.noteDate,
              title: response.data.title,
              description: response.data.description,
              status: response.data.status,
              parentGuid: response.data.parentGuid,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else if (
      urlSplit.length === 2 ||
      urlSplit[1] === "musteri-detay" ||
      urlSplit[1] === "tedarikci-detay" ||
      urlSplit[1] === "kaliphane-detay" ||
      urlSplit[1] === "teklif3-detay" ||
      urlSplit[1] === "teklif32-detay" ||
      urlSplit[1] === "teklif33-detay" ||
      urlSplit[1] === "uretim-detay" ||
      urlSplit[1] === "uretim-planlama-detay"
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        noteType: null,
        customerGuid: null,
        supplierGuid: null,
        moldingRoomGuid: null,
        offerGuid: null,
        noteDate: "",
        title: "",
        description: "",
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      titleError: "",
      descriptionError: "",
      alertErrorMessage: "",
    });
    if (name === "title" && value === "") {
      this.setState({
        titleError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "description" && value === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      noteDate: "",
      title: "",
      description: "",
      status: true,
      alertErrorMessage: CgnMessage.alertErrorMessage,
      noteDateError: CgnMessage.textErrorMessage,
      titleError: CgnMessage.textErrorMessage,
      descriptionError: CgnMessage.textErrorMessage,
    });
  }

  handleDateChange(noteDate) {
    if (noteDate) {
      this.setState({
        noteDate: noteDate,
        noteDateError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        noteDate: "",
        noteDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      noteDateError: "",
      titleError: "",
      descriptionError: "",
    });
    // if (this.state.noteDate === "") {
    //   this.setState({
    //     noteDateError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    if (this.state.title === "") {
      this.setState({
        titleError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.description === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } else if (
      // this.state.noteDate !== "" &&
      this.state.title !== "" &&
      this.state.description !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      let urlSplit = window.location.pathname.split("/");
      if (urlSplit[1] === "musteri-detay") {
        var myObj = {
          noteType: 1, // müşteriyse 1
          customerGuid: urlSplit[2],
          noteDate: new Date(),
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
        };
      } else if (urlSplit[1] === "tedarikci-detay") {
        myObj = {
          noteType: 2, // tedarikçiyse 2
          supplierGuid: urlSplit[2],
          noteDate: new Date(),
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
        };
      } else if (urlSplit[1] === "kaliphane-detay") {
        myObj = {
          noteType: 3, // kalıphane 3
          moldingRoomGuid: urlSplit[2],
          noteDate: new Date(),
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
        };
      } else if (urlSplit[1] === "teklif3-detay" || urlSplit[1] === "teklif32-detay" || urlSplit[1] === "teklif33-detay") {
        myObj = {
          noteType: 4, // teklif 4
          offerGuid: urlSplit[2],
          noteDate: new Date(),
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
        };
      } else if (urlSplit[1] === "uretim-detay") {
        myObj = {
          noteType: 5, // üretim 5
          offerGuid: urlSplit[2],
          noteDate: new Date(),
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
        };
      } else if (urlSplit[1] === "uretim-planlama-detay") {
        myObj = {
          noteType: 6, // Üretim Planlama 6
          offerGuid: urlSplit[2],
          noteDate: new Date(),
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
        };
      }
      var URLParam = "/notes/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.updated
      ) {
        URLParam = "/notes/update";
        myObj = {
          guid: this.state.guid,
          noteType: this.state.noteType,
          customerGuid: this.state.customerGuid,
          supplierGuid: this.state.supplierGuid,
          moldingRoomGuid:
            this.state.noteType === 3 ? this.state.parentGuid : null,
          offerGuid:
            this.state.noteType === 4 || this.state.noteType === 5 || this.state.noteType === 6
              ? this.state.parentGuid
              : null,
          noteDate: new Date(),
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.customerGuid &&
              this.state.noteType === 1
            ) {
              window.location.href = `/musteri-detay/${this.state.customerGuid}`;
            } else if (
              this.state.guid &&
              this.state.supplierGuid &&
              this.state.noteType === 2
            ) {
              window.location.href = `/tedarikci-detay/${this.state.supplierGuid}`;
            } else if (
              this.state.guid &&
              this.state.moldingRoomGuid &&
              this.state.noteType === 3
            ) {
              window.location.href = `/kaliphane-detay/${this.state.moldingRoomGuid}`;
            } else if (
              this.state.guid &&
              this.state.offerGuid &&
              this.state.noteType === 4
            ) {
              window.location.href = `/${this.state.url}/${this.state.offerGuid}`;
            } else if (
              this.state.guid &&
              this.state.offerGuid &&
              this.state.noteType === 5
            ) {
              window.location.href = `/uretim-detay/${this.state.offerGuid}`;
            } else if (
              this.state.guid &&
              this.state.offerGuid &&
              this.state.noteType === 6
            ) {
              window.location.href = `/uretim-planlama-detay/${this.state.offerGuid}`;
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {/* <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
        /> */}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />

                <Row>
                  {/* <Col lg="4">
                    <CgnDatePicker
                      name="noteDate"
                      label="Not Tarihi [*]"
                      selected={this.state.noteDate}
                      onChange={(noteDate) => this.handleDateChange(noteDate)}
                      error={this.state.noteDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col> */}
                  <Col lg="12">
                    <CgnTextbox
                      type="text"
                      name="title"
                      label="Başlık"
                      value={this.state.title}
                      placeHolder="Lütfen başlık girin..."
                      autoComplete="off"
                      maxLength="511"
                      onChange={this.handleChange}
                      error={this.state.titleError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {}
            {/* <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text={CgnMessage.backButton}
              className="btn-back"
            /> */}
          </Col>
        </Row>
      </>
    );
  }
}

export default NoteAddUpdate;
