import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  ModalHeader,
  Button,
  Form,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  optionOfferStatusesOptions,
  URL,
  fotoStyle,
  localFilePath,
  
  fotoStyle2,
  unitValuesStatuses,
  TransactionStatus,
  CurrencyType,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import fileIcon from "../../images/file.jpg";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";

class OfferSieveProductEntryDetail extends Component {
  state = {
    pageTitle: "Satınalma Detay",
    pageTitleDetail: "Satınalma Detay",
    //pageAddLink: "teklif3-ekle",
    pageListLink: "satin-alma",
    breadcrumb: [{ text: "Satınalma", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    offerNumber: "",
    offerDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    generalDiscountTotal: null,
    generalTaxTotal: null,
    taxTotal: null,
    discountPercent: "",
    workCode2: "",
    language: "",
    customerPhone: "",
    offerStatus: "",
    discountValue: "",
    discountTotal: "",
    taxType: null,
    taxRate: null,
    currencyTypeError: "",
    description: "",
    bankName: "",
    productDto: [],
    isHiddenSatinAlma: true,
    customerEmail: "",
    productPieceError: "",
    productUnitPriceError: "",
    productError: "",
    locationError: "",
    unitError: "",
    offerSieveImageDto: [],
    offerSievePictureDto: [],
    productArr: [],
    productEntryDto: [],
    locationArr: [],
    productName: "",
    piece: null,
    isHiddenKaliphane: true,
    unitPrice: null,
    moldDesign: "",
    orderMaterial: "",
    vertical: "",
    wire: "",
    render: "",
    foreignAffair: "",
    deliveryTime: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedByF: "",
    offerCoverImage: "",
    confirmationDate: "",
    counter: -1,
    warrantyPeriodName: "",
    deliveryTimeName: "",
    deliveryTypeName: "",
    paymentTypeName: "",
    transactionStatus: 1,
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    approvalDate: "",
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 1;
      await axios
        .get(URL + "/sieveProducts/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return (
              item.categoryName === "SARF MALZEME" && {
                value: item.guid,
                label: `${item.code}-${item.name}`,
                buyPrice: item.buyPrice,
                code: item.code,
              }
            );
          });
          this.setState({
            productArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/locations/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            locationArr: myArr,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      await axios
        .get(URL + "/offersieves/getbuy/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            customerName: response.data.customerName,
            offerNumber: response.data.offerNumber,
            offerDate: response.data.offerDate,
            optionDate:
              response.data.optionDate === "01/01/1900"
                ? "-"
                : response.data.optionDate,
            reminderDate: response.data.reminderDate,
            language: response.data.language,
            productDto: response.data.offerSieveProductDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),
            //currencyType: response.data.currencyType,
            customerEmail: response.data.customerEmail,
            workCode2: response.data.workCode2,
            customerPhone: response.data.customerPhone,
            paymentTypeName: response.data.paymentTypeName,
            deliveryTimeName: response.data.deliveryTimeName,
            deliveryTypeName: response.data.deliveryTypeName,
            warrantyPeriodName: response.data.warrantyPeriodName,
            description: response.data.description,
            offerCoverImage: response.data.offerCoverImage,
            confirmationDate: response.data.confirmationDate,
            //offerCancelText: response.data.offerCancelText,
            offerSieveImageDto: response.data.offerSieveImageDto,
            offerSievePictureDto: response.data.offerSievePictureDto,
            bankName: response.data.bankName,
            offerStatus: response.data.offerStatus,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            productEntryDto: response.data.productEntryDto,
            counter: response.data.productEntryDto.length - 1,
            transactionStatus: response.data.transactionStatus,
            approvalDate: response.data.approvalDate,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenSatinAlma: false,
      });
    } else {
      this.setState({
        isHiddenSatinAlma: true,
      });
    }
  };

  handleKaliphaneCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenKaliphane: false,
        isHiddenSatinAlma: true,
        productEntryDto: [],
      });
    } else {
      this.setState({
        isHiddenKaliphane: true,
      });
    }
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handleOfferStatusChange(option) {
    if (option) {
      this.setState({
        offerStatus: option.value,
      });
    } else {
      this.setState({
        offerStatus: "",
      });
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      alertErrorMessage: "",
    });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productEntryDto = this.state.productEntryDto;
    productEntryDto[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productEntryDto: productEntryDto,
    });
  }

  handleProductPieceTextBoxChange(e, idx) {
    let productEntryDto = this.state.productEntryDto;
    productEntryDto[idx].piece = parseFloat(e.target.value);
    this.setState({
      productEntryDto: productEntryDto,
    });
  }

  handleCurrencyTypeSelectChange(option, idx) {
    let productEntryDto = this.state.productEntryDto;
    if (option) {
      productEntryDto[idx].currencyType = option.value;
      productEntryDto[idx].currencyTypeError = "";
      this.setState({
        productEntryDto: productEntryDto,
      });
    } else {
      productEntryDto[idx].currencyType = "";
      productEntryDto[idx].currencyTypeError = CgnMessage.textErrorMessage;
      this.setState({
        productEntryDto: productEntryDto,
      });
    }
  }

  handleUnitChange(option, idx) {
    let productEntryDto = this.state.productEntryDto;
    if (option) {
      productEntryDto[idx].unit = option.value;
      productEntryDto[idx].unitError = "";
      this.setState({
        productEntryDto: productEntryDto,
      });
    } else {
      productEntryDto[idx].unit = "";
      productEntryDto[idx].unitError = CgnMessage.textErrorMessage;
      this.setState({
        productEntryDto: productEntryDto,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });
    if (this.state.productEntryDto) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.transactionStatus === "" &&
      this.state.transactionStatus === null
    ) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (
      this.state.productEntryDto !== null ||
      this.state.productEntryDto.length > 0
    ) {
      this.state.productEntryDto.forEach((element) => {
        if (
          element.unit === "" ||
          element.sieveProductGuid === "" ||
          element.locationGuid === "" ||
          element.piece === "" ||
          element.unitPrice === "" ||
          element.unitPrice === 0 ||
          element.currencyType === "" ||
          element.currencyType === 0
        ) {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      });
    }
    if (
      this.state.productEntryDto &&
      control === true &&
      this.state.transactionStatus !== "" &&
      this.state.transactionStatus !== null
    ) {
      this.setState({
        isLoading: true,
      });

      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var myObj = {
        productEntryDto: this.state.productEntryDto,
        workCode2: this.state.workCode2,
        offerGuid: this.state.guid,
        transactionStatus: this.state.transactionStatus,
      };
      var URLParam = "/offerSieveProductEntries/update";

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/satin-alma";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleLocationInputValueChange(option, idx) {
    let productEntryDto = this.state.productEntryDto;
    if (option) {
      productEntryDto[idx].locationGuid = option.value;
      productEntryDto[idx].locationError = "";
      this.setState({
        productEntryDto: productEntryDto,
      });
    } else {
      productEntryDto[idx].locationGuid = "";
      productEntryDto[idx].locationError = CgnMessage.textErrorMessage;
      this.setState({
        productEntryDto: productEntryDto,
      });
    }
  }

  handleProductInputValueChange(option, idx) {
    let productEntryDto = this.state.productEntryDto;
    if (option) {
      productEntryDto[idx].sieveProductGuid = option.value;
      productEntryDto[idx].piece = 1;
      productEntryDto[idx].unitPrice = option.buyPrice;
      productEntryDto[idx].productError = "";
      productEntryDto[idx].productPieceError = "";
      productEntryDto[idx].productUnitPriceError = "";
      this.setState({
        productEntryDto: productEntryDto,
      });
    } else {
      productEntryDto[idx].sieveProductGuid = null;
      productEntryDto[idx].piece = 0;
      productEntryDto[idx].unitPrice = 0;
      productEntryDto[idx].productError = CgnMessage.textErrorMessage;
      productEntryDto[idx].productPieceError = CgnMessage.textErrorMessage;
      productEntryDto[idx].productUnitPriceError = CgnMessage.textErrorMessage;
      this.setState({
        productEntryDto: productEntryDto,
      });
    }
    this.ProductPriceCalculate();
  }

  ProductPriceCalculate = () => {
    var totalProductPrice = 0;
    this.state.productEntryDto.forEach((item) => {
      item.total = (
        parseFloat(item.piece) * parseFloat(item.unitPrice)
      ).toFixed(2);
      totalProductPrice += parseFloat(item.total);
    });
    this.state.total = parseFloat(totalProductPrice).toFixed(2); // loop girmemesi için setState kullanmadım.
  };

  async handleProductRemove(idx) {
    let someArray = await this.state.productEntryDto;

    await someArray.splice(idx, 1);

    await this.setState({
      productEntryDto: someArray,
      counter: this.state.counter - 1,
    });

    var generalTotal1 = 0;
    await this.state.productEntryDto.forEach((item) => {
      generalTotal1 += parseFloat(item.unitPrice) * parseFloat(item.piece);
    });

    await this.setState({
      generalTotal: parseFloat(generalTotal1).toFixed(2),
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleProductAdd = async () => {
    let array = this.state.productEntryDto;
    await array.push({
      sieveProductGuid: "",
      locationGuid: "",
      piece: 1,
      unitPrice: 0,
      unit: "",
      currencyType: "",
      productError: CgnMessage.textErrorMessage,
      locationError: CgnMessage.textErrorMessage,
      productPieceError: CgnMessage.textErrorMessage,
      productUnitPriceError: CgnMessage.textErrorMessage,
      unitError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].sieveProductGuid) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].locationGuid) {
      return array[this.state.counter].locationError === "";
    }
    if (array[this.state.counter].piece) {
      return array[this.state.counter].productPieceError === "";
    }
    if (array[this.state.counter].unitPrice) {
      return array[this.state.counter].productUnitPriceError === "";
    }
    if (array[this.state.counter].unit) {
      return array[this.state.counter].unitError === "";
    }
    if (array[this.state.counter].currencyType) {
      return array[this.state.counter].currencyTypeError === "";
    }

    if (
      array[this.state.counter].sieveProductGuid === "" ||
      array[this.state.counter].locationGuid === "" ||
      array[this.state.counter].piece === 0 ||
      array[this.state.counter].unitPrice === 0 ||
      array[this.state.counter].unit === "" ||
      array[this.state.counter].currencyType === ""
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ productEntryDto: array });
  };

  handleTransactionStatusSelectChange(transactionStatus) {
    if (transactionStatus) {
      this.setState({
        transactionStatus: transactionStatus.value,
      });
    }
  }

  render() {
    const sira = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "20px",
      textAlign: "center",
    };
    const siraTd = {
      backgroundColor: "#f00",
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    };
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const mainRowDate = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "215px",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self1 = this;
    var self = this.state;

    var phone = this.state.customerPhone,
      maskPhone = `${phone.substring(0, 1)}  ${
        "(" + phone.substring(1, 4) + ")"
      } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
        9,
        11
      )}`;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          //addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
          //emailLink={onClick => this.handlePdfSendMail(this.state.urlSplit)}
          //printLink={onClick => this.handlePdfPrint(this.state.urlSplit)}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                {/* <tr>
                  <th>Teklif Dili</th>
                  <td colSpan="4">
                    {this.state.language === 1
                      ? "Türkçe"
                      : this.state.language === 2
                      ? "İngilizce"
                      : this.state.language === 3
                      ? "Fransızca"
                      : this.state.language === 4
                      ? "Almanca"
                      : this.state.language === 5
                      ? "Arapça"
                      : "-"}
                  </td>
                </tr> */}
                <tr>
                  <th>Tarih</th>
                  <td colSpan="4">{this.state.approvalDate}</td>
                </tr>
                <tr>
                  <th>Sipariş No</th>
                  <td colSpan="4">{this.state.offerNumber}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>
                {/* <tr>
                  <th>Müşteri E-mail</th>
                  <td colSpan="4">
                    {this.state.customerEmail === ""
                      ? "-"
                      : this.state.customerEmail}
                  </td>
                </tr>
                <tr>
                  <th>Müşteri Telefon</th>
                  <td colSpan="4">
                    {this.state.customerPhone === "" ? "-" : maskPhone}
                  </td>
                </tr>
                <tr>
                  <th>Teklif Tarihi</th>
                  <td colSpan="4">{this.state.offerDate}</td>
                </tr>
                <tr>
                  <th>Opsiyon Tarihi</th>
                  <td colSpan="4">{this.state.optionDate}</td>
                </tr>

                <tr>
                  <th>Hatırlatma Tarihi</th>
                  <td colSpan="4">{this.state.reminderDate}</td>
                </tr>

                <tr>
                  <th>Ara Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam İndirim</th>
                  <td colSpan="4">
                    {this.state.generalDiscountTotal
                      ? this.state.generalDiscountTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam KDV Tutarı</th>
                  <td colSpan="4">
                    {this.state.generalTaxTotal
                      ? this.state.generalTaxTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Ödeme Yöntemi </th>
                  <td colSpan="4">{this.state.paymentTypeName}</td>
                </tr> */}

                <tr>
                  <th>Teslimat Yöntemi </th>
                  <td colSpan="4">{this.state.deliveryTypeName}</td>
                </tr>

                <tr>
                  <th>Teslimat Süresi </th>
                  <td colSpan="4">{this.state.deliveryTimeName}</td>
                </tr>

                {/* <tr>
                  <th>Garanti Süresi </th>
                  <td colSpan="4">{this.state.warrantyPeriodName}</td>
                </tr>

                <tr>
                  <th>Teklif Notu</th>
                  <td
                    colSpan="4"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description
                        ? this.state.description
                        : "-",
                    }}
                  ></td>
                </tr>

                <tr>
                  <th>Teklif Kapağı</th>
                  <td colSpan="4">
                    {this.state.offerCoverImage ? (
                      <Button
                        onClick={this.toggle}
                        className="modalButtonDetail"
                      >
                        <img
                          src={this.state.offerCoverImage}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      </Button>
                    ) : (
                      <img
                        src={noImg}
                        style={fotoStyle}
                        alt={this.state.customerName}
                      />
                    )}
                  </td>
                </tr>

                <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}></ModalHeader>
                  <img
                    className="modalofferCoverImage"
                    src={this.state.offerCoverImage}
                    style={fotoStyle}
                    alt={this.state.customerName}
                  />
                </Modal>

                <tr>
                  <th>Teklif Onay/İptal Tarihi Saati</th>
                  <td colSpan="4">{this.state.confirmationDate}</td>
                </tr>

                <tr>
                  <th>Banka</th>
                  <td colSpan="4">{this.state.bankName}</td>
                </tr>
                <tr>
                  <th>Durum</th>
                  <td colSpan="4">
                    {optionOfferStatusesOptions(this.state.status)}
                  </td>
                </tr>*/}
                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              {this.state.productDto !== null &&
                this.state.productDto.length > 0 &&
                this.state.productDto.map((productDto, idx) =>
                  productDto.isPurchasingStatus ? (
                    <>
                      <Table className="table table-responsive table-hover table-product">
                        <tr className="">
                          <th style={sira}>Sıra</th>
                          <th className="text-left pl-3" style={mainRow}>
                            Depo
                          </th>
                          <th
                            colSpan={4}
                            className="text-left pl-3"
                            style={mainRow}
                          >
                            Ürün Adı
                          </th>
                          <th className="text-left pl-3" style={mainRow}>
                            Adet
                          </th>
                          <th className="text-left pl-3" style={mainRow}>
                            Birim
                          </th>
                          {/* <th className="text-center" style={mainRow}>
                          Birim Fiyatı{" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Yüzde){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Net Tutar){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          KDV Oranı{" "}
                        </th>
                        <th style={mainRow}>Fiyat</th> */}
                        </tr>
                        <tr style={subRow}>
                          <td style={siraTd}>{idx + 1}</td>
                          <td className="text-left pl-3">
                            {productDto.locationName}
                          </td>
                          <td colSpan={4} className="text-left pl-3">
                            {productDto.productName}
                          </td>
                          <td className="text-left pl-3">{productDto.piece}</td>
                          <td className="text-left pl-3">
                            {productDto.unit === "NIU"
                              ? "Adet"
                              : productDto.unit === "KGM"
                              ? "kg"
                              : productDto.unit === "GRM"
                              ? "gr"
                              : productDto.unit === "MTR"
                              ? "Metre"
                              : productDto.unit === "LTR"
                              ? "Litre"
                              : productDto.unit === "PA"
                              ? "Paket"
                              : productDto.unit === "BX"
                              ? "Kutu"
                              : productDto.unit === "CMT"
                              ? "cm"
                              : productDto.unit === "MTQ"
                              ? "m3"
                              : productDto.unit === "MTK"
                              ? "m2"
                              : productDto.unit === "ROLL"
                              ? "Rulo"
                              : productDto.unit === "SET"
                              ? "Set"
                              : productDto.unit === "CMQ"
                              ? "cm3"
                              : productDto.unit === "SAA" ? "Saat" : "-"}
                          </td>
                          {/* <td className="text-center">
                          {item.unitPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>

                        <td className="text-center">
                          {item.productDiscountPercent
                            ? "% " + item.productDiscountPercent
                            : "-"}
                        </td>
                        <td className="text-center">
                          {item.productDiscountValue &&
                          item.productDiscountValue > 0
                            ? item.productDiscountValue +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : "-")
                            : "-"}
                        </td>
                        <td className="text-center">
                          {"% " + item.productTaxRate}
                        </td>
                        <td>
                          {item.total.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td> */}
                        </tr>
                        <tr>
                          <th
                            colSpan={2}
                            className="text-left pl-3"
                            style={mainRow}
                          >
                            Makine Boyutu
                          </th>
                          <th style={mainRow} className="text-left pl-3">
                            Elek Boyutu
                          </th>
                          <th
                            colSpan={2}
                            style={mainRow}
                            className="text-left pl-3"
                          >
                            Elek Göz Boyutu
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Müşteri Tahmini Teslimat
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Güncel Teslimat
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Tahmini Teslimat
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={2} className="text-left pl-3">
                            {productDto.machineSize !== ""
                              ? productDto.machineSize
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.sieveSize !== ""
                              ? productDto.sieveSize
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.sieveEyeSize !== ""
                              ? productDto.sieveEyeSize
                              : "-"}
                          </td>
                          <td colSpan={2} className="text-left pl-3">
                            {productDto.customerEstimatedDeliveryDatePurchasingStatus !==
                            ""
                              ? productDto.customerEstimatedDeliveryDatePurchasingStatus
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.deliveryDateDetail !== ""
                              ? productDto.deliveryDateDetail
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.estimatedDeliveryDateDetail !== ""
                              ? productDto.estimatedDeliveryDateDetail
                              : "-"}
                          </td>
                        </tr>

                        {productDto.sieveProductPropertyDto != null &&
                          productDto.sieveProductPropertyDto.length > 0 && (
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                colSpan="7"
                                className="text-left pl-3"
                              >
                                Ürün Özellikleri
                              </th>
                            </tr>
                          )}
                        {productDto.sieveProductPropertyDto.map(function (
                          item2
                        ) {
                          return (
                            <tr style={subRow}>
                              <td colSpan={3} className="text-left pl-3">
                                {item2.propertyTypeName}
                              </td>
                              <td colSpan={4} className="text-left pl-3">
                                {" "}
                                {item2.value}
                              </td>
                            </tr>
                          );
                        })}
                        {productDto.offerSievesProductsShippingDto != null &&
                          productDto.offerSievesProductsShippingDto.length >
                            0 && (
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                colSpan="5"
                              ></th>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                className="text-left pl-3"
                              >
                                Adet
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                className="text-left pl-3"
                              >
                                Hazır Tarihi
                              </th>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                className="text-left pl-3"
                              >
                                Kalem Sevkiyat
                              </th>
                            </tr>
                          )}
                        {productDto.offerSievesProductsShippingDto.map(
                          function (item2) {
                            return (
                              <tr style={subRow}>
                                <td colSpan={5} className="text-left pl-3"></td>
                                <td className="text-left pl-3">
                                  {item2.piece}
                                </td>
                                <td className="text-left pl-3">
                                  {item2.readyDateDetail}
                                </td>
                                <td className="text-left pl-3">
                                  {item2.shippingDateDetail !== "01 Ocak 1900"
                                    ? item2.shippingDateDetail
                                    : "-"}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </Table>
                    </>
                  ) : (
                    <></>
                  )
                )}
              {/* {this.state.offerSieveImageDto &&
                this.state.offerSieveImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.offerSieveImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={fileIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.offerSievePictureDto !== null &&
                this.state.offerSievePictureDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Teklif Fotoğraf</th>
                      </tr>
                      <Row>
                        {this.state.offerSievePictureDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )} */}
              <Form onSubmit={this.handleSave} className="mt-3">
                <Row>
                  <Col lg="8">
                    <CgnTextbox
                      type="text"
                      name="workCode2"
                      label="İş Notu"
                      value={this.state.workCode2}
                      placeHolder="Lütfen iş kodu girin..."
                      //maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      //error={this.state.descriptionError}
                    />
                  </Col>
                  <Col lg="4">
                    <CgnReactSelect
                      name="transactionStatus"
                      label="İşlem Durumu"
                      placeholder="Lütfen işlem durumu seçiniz..."
                      selectValue={this.state.transactionStatus}
                      isMulti={false}
                      options={TransactionStatus}
                      onChange={(transactionStatus) =>
                        this.handleTransactionStatusSelectChange(
                          transactionStatus
                        )
                      }
                    />
                  </Col>
                </Row>
                {this.state.productEntryDto.map((productEntryDto, idx) => (
                  <Row className="mobileBorder">
                    <Col lg="2" className="modalSelectCol">
                      <CgnReactSelect
                        name="locationGuid"
                        label="Depo [*]"
                        placeholder="Lütfen depo seçiniz..."
                        selectValue={productEntryDto.locationGuid}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleLocationInputValueChange(option, idx);
                        }}
                        options={this.state.locationArr}
                        error={productEntryDto.locationError}
                      />
                    </Col>
                    <Col lg="3" className="modalSelectCol">
                      <CgnReactSelect
                        name="sieveProductGuid"
                        label="Ürün [*]"
                        placeholder="Lütfen ürün seçiniz..."
                        selectValue={productEntryDto.sieveProductGuid}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) => {
                          this.handleProductInputValueChange(option, idx);
                        }}
                        options={this.state.productArr}
                        error={productEntryDto.productError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnReactSelect
                        name="unit"
                        label="Birim [*]"
                        placeholder="Birim"
                        selectValue={productEntryDto.unit}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) =>
                          this.handleUnitChange(option, idx)
                        }
                        options={unitValuesStatuses}
                        error={productEntryDto.unitError}
                      />
                    </Col>

                    <Col lg="1">
                      <CgnTextbox
                        type="number"
                        name="piece"
                        label="Adet [*]"
                        value={productEntryDto.piece}
                        onChange={(e) =>
                          this.handleProductPieceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen adet girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productEntryDto.productPieceError}
                      />
                    </Col>

                    <Col lg="1">
                      <CgnTextbox
                        type="number"
                        name="unitPrice"
                        label="Birim Fiyat [*]"
                        value={productEntryDto.unitPrice}
                        onChange={(e) =>
                          this.handleProductUnitPriceTextBoxChange(e, idx)
                        }
                        placeHolder="Lütfen birim fiyat girin..."
                        maxLength="50"
                        autoComplete="off"
                        error={productEntryDto.productUnitPriceError}
                      />
                    </Col>

                    <Col lg="2">
                      <CgnReactSelect
                        name="currencyType"
                        label="Para Birimi [*]"
                        placeholder="Lütfen para birimi seçiniz..."
                        selectValue={productEntryDto.currencyType}
                        isMulti={false}
                        isClearable={true}
                        onChange={(option) =>
                          this.handleCurrencyTypeSelectChange(option, idx)
                        }
                        options={CurrencyType}
                        error={productEntryDto.currencyTypeError}
                      />
                    </Col>

                    <Col lg="1" sm="3">
                      <CgnButton
                        type="button"
                        color="danger"
                        className="dynamicRowDeleteButton"
                        onClick={() => this.handleProductRemove(idx)}
                        text={"Sil"}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col className="pl-0">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="dynamicRowAddButton"
                      onClick={this.handleProductAdd}
                      text={"Ürün Ekle"}
                    />
                  </Col>
                </Row>

                <CgnButtonGroup items={this.state.buttons} />
              </Form>

              {this.state.alertErrorMessage && (
                <CgnAlert
                  className="mt-3"
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
            </Card>

            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default OfferSieveProductEntryDetail;
