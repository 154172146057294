import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  ModalHeader,
  Button,
} from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  optionOfferStatusesOptions,
  URL,
  fotoStyle,
  localFilePath,
  
  shipmentStatus,
  fotoStyle2,
  shipmentStatuses,
  TransactionStatus,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import { confirmAlert } from "react-confirm-alert";
import CgnMessage from "../toolbox/CgnMessage";
import fileIcon from "../../images/file.jpg";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import NoteAddUpdate from "../notes/NoteAddUpdate";

class OfferGenerationDetail extends Component {
  state = {
    pageTitle: "Üretim Arşivi Detay",
    pageTitleDetail: "Üretim Arşivi Detay",
    //pageAddLink: "teklif3-ekle",
    pageListLink: "uretim-arsivi",
    breadcrumb: [{ text: "Üretim Arşivi", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    offerNumber: "",
    offerDate: "",
    customerName: "",
    total: null,
    generalTotal: null,
    generalDiscountTotal: null,
    generalTaxTotal: null,
    taxTotal: null,
    discountPercent: "",
    language: "",
    customerPhone: "",
    offerStatus: "",
    discountValue: "",
    discountTotal: "",
    taxType: null,
    taxRate: null,
    shipmentStatus: null,
    currencyType: 0,
    description: "",
    offerMoldingRoomDto: [],
    bankName: "",
    productDto: [],
    isHiddenSatinAlma: true,
    customerEmail: "",
    productPieceError: "",
    productUnitPriceError: "",
    productError: "",
    locationError: "",
    unitError: "",
    offerSieveImageDto: [],
    offerSievePictureDto: [],
    productArr: [],
    productEntryDto: [],
    locationArr: [],
    productName: "",
    piece: null,
    isHiddenKaliphane: true,
    unitPrice: null,
    moldDesign: "",
    materialOrder: "",
    vertical: "",
    wire: "",
    render: "",
    foreignAffairs: "",
    deliveryTime: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    wireErosion: "",
    statusText: "",
    statusChange: "",
    processing: "",
    offerCoverImage: "",
    confirmationDate: "",
    counter: -1,
    shipmentDate: "",
    warrantyPeriodName: "",
    deliveryTimeName: "",
    deliveryTypeName: "",
    guid: "",
    paymentTypeName: "",
    buttons: [
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    approvalDate: "",
    transactionStatus3: 1,
    noteDto: [],
    isOpenModalNote: false,
    isOpenModalUpdateNote: false,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .get(URL + "/offersieves/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            customerName: response.data.customerName,
            offerNumber: response.data.offerNumber,
            offerDate: response.data.offerDate,
            optionDate:
              response.data.optionDate === "01/01/1900"
                ? "-"
                : response.data.optionDate,
            reminderDate: response.data.reminderDate,
            language: response.data.language,
            productDto: response.data.offerSieveProductDto,
            total: response.data.total.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalTotal: response.data.generalTotal.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            generalDiscountTotal:
              response.data.generalDiscountTotal.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
              }),
            generalTaxTotal: response.data.generalTaxTotal.toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
              }
            ),
            currencyType: response.data.currencyType,
            customerEmail: response.data.customerEmail,
            shipmentDate:
              response.data.shipmentDate === "01/01/0001" ||
                response.data.shipmentDate === "01/01/1900"
                ? null
                : response.data.shipmentDate,
            customerPhone: response.data.customerPhone,
            paymentTypeName: response.data.paymentTypeName,
            deliveryTimeName: response.data.deliveryTimeName,
            deliveryTypeName: response.data.deliveryTypeName,
            warrantyPeriodName: response.data.warrantyPeriodName,
            description: response.data.description,
            offerCoverImage: response.data.offerCoverImage,
            confirmationDate: response.data.confirmationDate,
            //offerCancelText: response.data.offerCancelText,
            offerSieveImageDto: response.data.offerSieveImageDto,
            offerSievePictureDto: response.data.offerSievePictureDto,
            bankName: response.data.bankName,
            offerStatus: response.data.offerStatus,
            shipmentStatus: response.data.shipmentStatus,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            approvalDate: response.data.approvalDate,
            transactionStatus3: response.data.transactionStatus3,
            noteDto: response.data.noteDto,
            customerAddress: response.data.customerAddress,
            customerZipCode: response.data.customerZipCode,
            customerCountryName: response.data.customerCountryName,
            customerCityName: response.data.customerCityName,
            customerDistrictName: response.data.customerDistrictName,
            customerTownName: response.data.customerTownName,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenSatinAlma: false,
      });
    } else {
      this.setState({
        isHiddenSatinAlma: true,
      });
    }
  };

  handleKaliphaneCheckBoxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        isHiddenKaliphane: false,
        isHiddenSatinAlma: true,
        productEntryDto: [],
      });
    } else {
      this.setState({
        isHiddenKaliphane: true,
      });
    }
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  handleOfferStatusChange(option) {
    if (option) {
      this.setState({
        offerStatus: option.value,
      });
    } else {
      this.setState({
        offerStatus: "",
      });
    }
  }

  handleShipmentStatusChange(option) {
    if (option) {
      this.setState({
        shipmentStatus: option.value,
      });
    } else {
      this.setState({
        shipmentStatus: null,
      });
    }
  }

  handleShipmentDateChange(shipmentDate) {
    if (shipmentDate) {
      this.setState({
        shipmentDate: shipmentDate,
      });
    } else {
      this.setState({
        shipmentDate: "",
      });
    }
  }

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/offerSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "offerListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleProductUnitPriceTextBoxChange(e, idx) {
    let productEntryDto = this.state.productEntryDto;
    productEntryDto[idx].unitPrice = parseFloat(e.target.value);
    this.setState({
      productEntryDto: productEntryDto,
    });
  }

  handleShipmentDateChange2(shipmentDate, idx) {
    let productDto = this.state.productDto;
    if (shipmentDate) {
      productDto[idx].shipmentDate = shipmentDate;
      this.setState({
        productDto: productDto,
      });
    } else {
      productDto[idx].shipmentDate = null;
      this.setState({
        productDto: productDto,
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
    });
    if (this.state.shipmentStatus === null) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (
      this.state.transactionStatus3 === "" &&
      this.state.transactionStatus3 === null
    ) {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.productDto !== null || this.state.productDto.length > 0) {
      this.state.productDto.forEach((element) => {
        element.offerSievesProductsShippingDto.forEach((element2) => {
          if (
            element2.piece === "" ||
            element2.piece === 0 ||
            element2.readyDate === "" ||
            element2.readyDate === null ||
            element2.readyDate === undefined
          ) {
            control = false;
            this.setState({
              alertErrorMessage: CgnMessage.alertErrorMessage,
            });
          }
        });
      });
    }
    if (
      this.state.shipmentStatus !== null &&
      this.state.transactionStatus3 !== null &&
      control === true
    ) {
      this.setState({
        isLoading: true,
      });

      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      var myObj = {
        shipmentStatus: this.state.shipmentStatus,
        shipmentDate:
          this.state.shipmentDate === ""
            ? "01/01/1900"
            : this.state.shipmentDate,
        guid: this.state.guid,
        offerSieveProductDto: this.state.productDto,
        transactionStatus3: this.state.transactionStatus3,
      };
      var URLParam = "/offerSieves/addshipment";

      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid) {
              window.location.href = "/uretim";
            } else {
              window.location.reload();
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleChange2 = (tags) => (event) => {
    const { name, value } = event.target;
    let offerMoldingRoomDto = this.state.offerMoldingRoomDto;
    var idx = tags;

    offerMoldingRoomDto[idx][name] = value;
    this.setState({
      offerMoldingRoomDto: offerMoldingRoomDto,
    });
  };

  async handleProductRemove(idx) {
    let someArray = await this.state.offerMoldingRoomDto;

    await someArray.splice(idx, 1);

    await this.setState({
      offerMoldingRoomDto: someArray,
      counter: this.state.counter - 1,
    });

    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
      });
    }
  }

  handleProductAdd = async () => {
    let array = this.state.offerMoldingRoomDto;
    await array.push({
      moldDesign: "",
      processing: "",
      render: "",
      wireErosion: "",
      statusText: "",
      materialOrder: "",
      foreignAffairs: "",
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].moldDesign) {
      return array[this.state.counter].productError === "";
    }
    if (array[this.state.counter].moldDesign === "") {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ offerMoldingRoomDto: array });
  };

  handleTransactionStatusSelectChange(transactionStatus3) {
    if (transactionStatus3) {
      this.setState({
        transactionStatus3: transactionStatus3.value,
      });
    }
  }

  async handleOfferSievesProductsShippingRemove(idx, index2) {
    let array = await this.state.productDto;
    await array[idx].offerSievesProductsShippingDto.splice(index2, 1);
    await this.setState({
      productDto: array,
    });
  }

  async handleOfferSievesProductsShippingAdd(idx) {
    let array = this.state.productDto;
    await array[idx].offerSievesProductsShippingDto.push({
      piece: 1,
      shippingDate: "",
      readyDate: "",
      pieceError: "",
      shippingDateError: CgnMessage.textErrorMessage,
      readyDateError: CgnMessage.textErrorMessage,
    });
    this.setState({ productDto: array });
  }

  handleOfferSievesProductsShippingPieceTextBoxChange(e, idx, index2) {
    let array = this.state.productDto;
    if (e.target.value !== "") {
      array[idx].offerSievesProductsShippingDto[index2].piece = parseFloat(
        e.target.value
      );
      array[idx].offerSievesProductsShippingDto[index2].pieceError = "";
    } else {
      array[idx].offerSievesProductsShippingDto[index2].piece = parseFloat(
        e.target.value
      );
      array[idx].offerSievesProductsShippingDto[index2].pieceError =
        CgnMessage.textErrorMessage;
    }
    this.setState({
      productDto: array,
    });
  }

  handleOfferSievesProductsShippingDateChange(shippingDate, idx, index2) {
    let array = this.state.productDto;
    if (shippingDate) {
      array[idx].offerSievesProductsShippingDto[index2].shippingDate =
        shippingDate;
      array[idx].offerSievesProductsShippingDto[index2].shippingDateError = "";
      this.setState({
        productDto: array,
      });
    } else {
      array[idx].offerSievesProductsShippingDto[index2].shippingDate = null;
      this.setState({
        productDto: array,
      });
    }
  }

  handleOfferSievesProductsReadyDateChange(readyDate, idx, index2) {
    let array = this.state.productDto;
    if (readyDate) {
      array[idx].offerSievesProductsShippingDto[index2].readyDate = readyDate;
      array[idx].offerSievesProductsShippingDto[index2].readyDateError = "";
      this.setState({
        productDto: array,
      });
    } else {
      array[idx].offerSievesProductsShippingDto[index2].readyDate = null;
      this.setState({
        productDto: array,
      });
    }
  }

  addModalNote = () => {
    this.setState({
      isOpenModalNote: !this.state.isOpenModalNote,
      noteGuid: null,
    });
  };

  addModalUpdateNote = (guid) => {
    this.setState({
      isOpenModalUpdateNote: !this.state.isOpenModalUpdateNote,
      noteGuid: guid,
    });
  };

  handleNoteDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleNoteSendDelete(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleNoteSendDelete = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/notes/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const sira = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "20px",
      textAlign: "center",
    };
    const siraTd = {
      backgroundColor: "#f00",
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    };
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const mainRowDate = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
      width: "215px",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };

    var self1 = this;
    var self = this.state;

    var phone = this.state.customerPhone,
      maskPhone = `${phone.substring(0, 1)}  ${"(" + phone.substring(1, 4) + ")"
        } ${phone.substring(4, 7)} ${phone.substring(7, 9)} ${phone.substring(
          9,
          11
        )}`;

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
          noteLink={(onClick) => this.addModalNote()}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                {/* <tr>
                  <th>Teklif Dili</th>
                  <td colSpan="4">
                    {this.state.language === 1
                      ? "Türkçe"
                      : this.state.language === 2
                      ? "İngilizce"
                      : this.state.language === 3
                      ? "Fransızca"
                      : this.state.language === 4
                      ? "Almanca"
                      : this.state.language === 5
                      ? "Arapça"
                      : "-"}
                  </td>
                </tr> */}
                <tr>
                  <th>Tarih</th>
                  <td colSpan="4">{this.state.approvalDate}</td>
                </tr>
                <tr>
                  <th>Sipariş No</th>
                  <td colSpan="4">{this.state.offerNumber}</td>
                </tr>
                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>
                <tr>
                  <th>Müşteri Adres</th>
                  <td colSpan="4">
                    {this.state.customerAddress
                      ? this.state.customerAddress
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Müşteri Mahalle/İlçe/Şehir/Ülke</th>
                  <td colSpan="4">
                    {`${this.state.customerDistrictName
                        ? this.state.customerDistrictName + "/"
                        : ""
                      }${this.state.customerTownName
                        ? this.state.customerTownName + "/"
                        : ""
                      }${this.state.customerCityName
                        ? this.state.customerCityName + "/"
                        : ""
                      }${this.state.customerCountryName
                        ? this.state.customerCountryName
                        : "-"
                      }`}
                  </td>
                </tr>

                <tr>
                  <th>Müşteri Posta Kodu</th>
                  <td colSpan="4">
                    {this.state.customerZipCode
                      ? this.state.customerZipCode
                      : "-"}
                  </td>
                </tr>
                {/* <tr>
                  <th>Müşteri E-mail</th>
                  <td colSpan="4">
                    {this.state.customerEmail === ""
                      ? "-"
                      : this.state.customerEmail}
                  </td>
                </tr>
                <tr>
                  <th>Müşteri Telefon</th>
                  <td colSpan="4">
                    {this.state.customerPhone === "" ? "-" : maskPhone}
                  </td>
                </tr>
                <tr>
                  <th>Teklif Tarihi</th>
                  <td colSpan="4">{this.state.offerDate}</td>
                </tr>

                <tr>
                  <th>Opsiyon Tarihi</th>
                  <td colSpan="4">{this.state.optionDate}</td>
                </tr>

                <tr>
                  <th>Hatırlatma Tarihi</th>
                  <td colSpan="4">{this.state.reminderDate}</td>
                </tr>

                <tr>
                  <th>Ara Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam İndirim</th>
                  <td colSpan="4">
                    {this.state.generalDiscountTotal
                      ? this.state.generalDiscountTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam KDV Tutarı</th>
                  <td colSpan="4">
                    {this.state.generalTaxTotal
                      ? this.state.generalTaxTotal +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.total
                      ? this.state.total +
                        " " +
                        (this.state.currencyType === 1
                          ? "₺"
                          : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                          ? "€"
                          : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Ödeme Yöntemi </th>
                  <td colSpan="4">{this.state.paymentTypeName}</td>
                </tr> */}

                <tr>
                  <th>Teslimat Yöntemi </th>
                  <td colSpan="4">{this.state.deliveryTypeName}</td>
                </tr>

                <tr>
                  <th>Teslimat Süresi </th>
                  <td colSpan="4">{this.state.deliveryTimeName}</td>
                </tr>
                {/* 
                <tr>
                  <th>Garanti Süresi </th>
                  <td colSpan="4">{this.state.warrantyPeriodName}</td>
                </tr> */}

                <tr>
                  <th>Teklif Notu</th>
                  <td
                    colSpan="4"
                    className="text-danger font-weight-bold"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description
                        ? this.state.description
                        : "-",
                    }}
                  ></td>
                </tr>

                {/* <tr>
                  <th>Teklif Kapağı</th>
                  <td colSpan="4">
                    {this.state.offerCoverImage ? (
                      <Button
                        onClick={this.toggle}
                        className="modalButtonDetail"
                      >
                        <img
                          src={this.state.offerCoverImage}
                          style={fotoStyle}
                          alt={this.state.name}
                        />
                      </Button>
                    ) : (
                      <img
                        src={noImg}
                        style={fotoStyle}
                        alt={this.state.customerName}
                      />
                    )}
                  </td>
                </tr>

                <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                  <ModalHeader toggle={this.toggle}></ModalHeader>
                  <img
                    className="modalofferCoverImage"
                    src={this.state.offerCoverImage}
                    style={fotoStyle}
                    alt={this.state.customerName}
                  />
                </Modal>

                <tr>
                  <th>Teklif Onay/İptal Tarihi Saati</th>
                  <td colSpan="4">{this.state.confirmationDate}</td>
                </tr>

                <tr>
                  <th>Banka</th>
                  <td colSpan="4">{this.state.bankName}</td>
                </tr>
                <tr>
                  <th>Durum</th>
                  <td colSpan="4">
                    {optionOfferStatusesOptions(this.state.status)}
                  </td>
                </tr> */}
                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              <Form onSubmit={this.handleSave} className="mt-3">
                <Row>
                  <Col lg="3">
                    <CgnReactSelect
                      name="transactionStatus3"
                      label="İşlem Durumu [*]"
                      placeholder="Lütfen işlem durumu seçiniz..."
                      selectValue={this.state.transactionStatus3}
                      isMulti={false}
                      options={TransactionStatus}
                      onChange={(transactionStatus3) =>
                        this.handleTransactionStatusSelectChange(
                          transactionStatus3
                        )
                      }
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="shipmentStatus"
                      label="Sevkiyat Durumu [*]"
                      placeholder="Lütfen durum seçiniz..."
                      selectValue={this.state.shipmentStatus}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => {
                        this.handleShipmentStatusChange(option);
                      }}
                      options={shipmentStatuses}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnDatePicker
                      name="shipmentDate"
                      label="Sevkiyat Tarihi"
                      selected={
                        this.state.shipmentDate &&
                          this.state.shipmentDate.toString().indexOf("/") > 0
                          ? new Date(
                            this.state.shipmentDate.split("/")[2] +
                            "-" +
                            this.state.shipmentDate.split("/")[1] +
                            "-" +
                            this.state.shipmentDate.split("/")[0]
                          )
                          : this.state.shipmentDate
                      }
                      onChange={(shipmentDate) =>
                        this.handleShipmentDateChange(shipmentDate)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col lg="3"></Col>
                </Row>

                {this.state.productDto !== null &&
                  this.state.productDto.length > 0 &&
                  this.state.productDto.map((productDto, idx) => (
                    <>
                      <Table className="table table-responsive table-hover table-product mt-1">
                        <tr className="">
                          <th style={sira}>Sıra</th>
                          <th className="text-left pl-3" style={mainRow}>
                            Depo
                          </th>
                          <th
                            colSpan={4}
                            className="text-left pl-3"
                            style={mainRow}
                          >
                            Ürün Adı
                          </th>
                          <th className="text-left pl-3" style={mainRow}>
                            Adet
                          </th>
                          <th className="text-left pl-3" style={mainRow}>
                            Birim
                          </th>
                          {/* <th className="text-center" style={mainRow}>
                          Birim Fiyatı{" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Yüzde){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          İndirim(Net Tutar){" "}
                        </th>
                        <th className="text-center" style={mainRow}>
                          KDV Oranı{" "}
                        </th>
                        <th style={mainRow}>Fiyat</th> */}
                        </tr>
                        <tr style={subRow}>
                          <td style={siraTd}>{idx + 1}</td>
                          <td className="text-left pl-3">
                            {productDto.locationName}
                          </td>
                          <td colSpan={4} className="text-left pl-3">
                            {productDto.productName}
                          </td>
                          <td className="text-left pl-3">{productDto.piece}</td>
                          <td className="text-left pl-3">
                            {productDto.unit === "NIU"
                              ? "Adet"
                              : productDto.unit === "KGM"
                                ? "kg"
                                : productDto.unit === "GRM"
                                  ? "gr"
                                  : productDto.unit === "MTR"
                                    ? "Metre"
                                    : productDto.unit === "LTR"
                                      ? "Litre"
                                      : productDto.unit === "PA"
                                        ? "Paket"
                                        : productDto.unit === "BX"
                                          ? "Kutu"
                                          : productDto.unit === "CMT"
                                            ? "cm"
                                            : productDto.unit === "MTQ"
                                              ? "m3"
                                              : productDto.unit === "MTK"
                                                ? "m2"
                                                : productDto.unit === "ROLL"
                                                  ? "Rulo"
                                                  : productDto.unit === "SET"
                                                    ? "Set"
                                                    : productDto.unit === "CMQ"
                                                      ? "cm3"
                                                      : productDto.unit === "SAA"
                                                        ? "Saat"
                                                        : "-"}
                          </td>
                          {/* <td className="text-center">
                          {item.unitPrice.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td>

                        <td className="text-center">
                          {item.productDiscountPercent
                            ? "% " + item.productDiscountPercent
                            : "-"}
                        </td>
                        <td className="text-center">
                          {item.productDiscountValue &&
                          item.productDiscountValue > 0
                            ? item.productDiscountValue +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                ? "$"
                                : item.currencyType === 3
                                ? "€"
                                : "-")
                            : "-"}
                        </td>
                        <td className="text-center">
                          {"% " + item.productTaxRate}
                        </td>
                        <td>
                          {item.total.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                          }) +
                            " " +
                            (item.currencyType === 1
                              ? "₺"
                              : item.currencyType === 2
                              ? "$"
                              : item.currencyType === 3
                              ? "€"
                              : "-")}
                        </td> */}
                        </tr>
                        <tr>
                          <th
                            colSpan={2}
                            style={mainRow}
                            className="text-left pl-3"
                          >
                            Makine Boyutu
                          </th>
                          <th style={mainRow} className="text-left pl-3">
                            Elek Boyutu
                          </th>
                          <th
                            colSpan={2}
                            style={mainRow}
                            className="text-left pl-3"
                          >
                            Elek Göz Boyutu
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Müşteri Tahmini Teslimat
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Güncel Teslimat
                          </th>
                          <th style={mainRowDate} className="text-left pl-3">
                            Tahmini Teslimat
                          </th>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            {productDto.machineSize !== ""
                              ? productDto.machineSize
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.sieveSize !== ""
                              ? productDto.sieveSize
                              : "-"}
                          </td>
                          <td colSpan={2} className="text-left pl-3">
                            {productDto.sieveEyeSize !== ""
                              ? productDto.sieveEyeSize
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.customerEstimatedDeliveryDateDetail !==
                              ""
                              ? productDto.customerEstimatedDeliveryDateDetail
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.deliveryDateDetail !== ""
                              ? productDto.deliveryDateDetail
                              : "-"}
                          </td>
                          <td className="text-left pl-3">
                            {productDto.estimatedDeliveryDateDetail !== ""
                              ? productDto.estimatedDeliveryDateDetail
                              : "-"}
                          </td>
                        </tr>

                        {productDto.sieveProductPropertyDto != null &&
                          productDto.sieveProductPropertyDto.length > 0 && (
                            <tr>
                              <th
                                style={{
                                  backgroundColor: "#1D4B8C",
                                  color: "#fff",
                                }}
                                colSpan="9"
                                className="text-left"
                              >
                                Ürün Özellikleri
                              </th>
                            </tr>
                          )}
                        {productDto.sieveProductPropertyDto.map(function (
                          item2
                        ) {
                          return (
                            <tr style={subRow}>
                              <td colSpan={3} className="text-left">
                                {item2.propertyTypeName}
                              </td>
                              <td colSpan={6} className="text-left">
                                {" "}
                                {item2.value}
                              </td>
                            </tr>
                          );
                        })}
                      </Table>

                      <Row>
                        <Col className="pl-0">
                          <CgnButton
                            type="button"
                            color="primary"
                            className="dynamicRowAddButton px-3"
                            onClick={() =>
                              this.handleOfferSievesProductsShippingAdd(idx)
                            }
                            text={"Hazır Ekle"}
                          />
                        </Col>
                      </Row>

                      {productDto.offerSievesProductsShippingDto.map(
                        (item, index2) => (
                          <Row>
                            <Col lg="5"></Col>
                            <Col lg="2">
                              <CgnTextbox
                                type="number"
                                name="piece"
                                label="Adet [*]"
                                value={item.piece}
                                onChange={(e) =>
                                  this.handleOfferSievesProductsShippingPieceTextBoxChange(
                                    e,
                                    idx,
                                    index2
                                  )
                                }
                                placeHolder="Lütfen adet girin..."
                                maxLength="50"
                                autoComplete="off"
                                error={item.pieceError}
                              />
                            </Col>

                            <Col lg="2">
                              <CgnDatePicker
                                name="readyDate"
                                label="Hazır Tarihi [*]"
                                selected={
                                  item.readyDate &&
                                    item.readyDate.toString().indexOf("/") > 0
                                    ? new Date(
                                      item.readyDate.split("/")[2] +
                                      "-" +
                                      item.readyDate.split("/")[1] +
                                      "-" +
                                      item.readyDate.split("/")[0]
                                    )
                                    : item.readyDate
                                }
                                onChange={(readyDate) =>
                                  this.handleOfferSievesProductsReadyDateChange(
                                    readyDate,
                                    idx,
                                    index2
                                  )
                                }
                                maxDate={new Date(2100, 1, 1)}
                                minDate={new Date(1900, 1, 1)}
                                placeholder="GG/AA/YYYY"
                                autoComplete="off"
                                error={item.readyDateError}
                              />
                            </Col>

                            <Col lg="2">
                              <CgnDatePicker
                                name="shippingDate"
                                label="Kalem Sevkiyat Tarihi"
                                selected={
                                  item.shippingDate &&
                                  item.shippingDate !== "01/01/1900" &&
                                  (item.shippingDate.toString().indexOf("/") > 0
                                    ? new Date(
                                      item.shippingDate.split("/")[2] +
                                      "-" +
                                      item.shippingDate.split("/")[1] +
                                      "-" +
                                      item.shippingDate.split("/")[0]
                                    )
                                    : item.shippingDate)
                                }
                                onChange={(shippingDate) =>
                                  this.handleOfferSievesProductsShippingDateChange(
                                    shippingDate,
                                    idx,
                                    index2
                                  )
                                }
                                maxDate={new Date(2100, 1, 1)}
                                minDate={new Date(1900, 1, 1)}
                                placeholder="GG/AA/YYYY"
                                autoComplete="off"
                              />
                            </Col>

                            <Col lg="1" sm="3">
                              <CgnButton
                                type="button"
                                color="danger"
                                className="dynamicRowDeleteButton"
                                onClick={() =>
                                  this.handleOfferSievesProductsShippingRemove(
                                    idx,
                                    index2
                                  )
                                }
                                text={"Sil"}
                              />
                            </Col>
                          </Row>
                        )
                      )}

                      {/* <Row>
                        <Col lg="10"></Col>
                        <Col lg="2" className="pt-3">
                          <CgnDatePicker
                            name="offerDate"
                            label="Kalem Sevkiyat Tarihi"
                            selected={
                              productDto.shipmentDate &&
                              productDto.shipmentDate.toString().indexOf("/") >
                                0
                                ? new Date(
                                    productDto.shipmentDate.split("/")[2] +
                                      "-" +
                                      productDto.shipmentDate.split("/")[1] +
                                      "-" +
                                      productDto.shipmentDate.split("/")[0]
                                  )
                                : productDto.shipmentDate
                            }
                            onChange={(shipmentDate) =>
                              this.handleShipmentDateChange2(shipmentDate, idx)
                            }
                            maxDate={new Date(2100, 1, 1)}
                            minDate={new Date(1900, 1, 1)}
                            placeholder="GG/AA/YYYY"
                            autoComplete="off"
                          />
                        </Col>
                      </Row> */}
                    </>
                  ))}

                {/* {this.state.offerSieveImageDto &&
                this.state.offerSieveImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.offerSieveImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={fileIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.offerSievePictureDto !== null &&
                this.state.offerSievePictureDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Teklif Fotoğraf</th>
                      </tr>
                      <Row>
                        {this.state.offerSievePictureDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )} */}
                <CgnButtonGroup items={this.state.buttons} />
              </Form>

              {this.state.alertErrorMessage && (
                <CgnAlert
                  className="mt-3"
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}

              {this.state.offerSieveImageDto &&
                this.state.offerSieveImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Belgeler</th>
                    </tr>
                    <Row>
                      {this.state.offerSieveImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-4">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={fileIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.offerSievePictureDto !== null &&
                this.state.offerSievePictureDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Teklif Fotoğraf</th>
                      </tr>
                      <Row>
                        {this.state.offerSievePictureDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}

              {this.state.noteDto !== null && this.state.noteDto.length > 0 && (
                <>
                  <div
                    className="row"
                    style={{
                      display:
                        self.noteDto !== null && self.noteDto.length > 0
                          ? ""
                          : "none",
                    }}
                  >
                    <h2 className="mt-2">Not</h2>
                  </div>
                  <Table className="table table-responsive table-product2">
                    <tr className=""></tr>
                    {this.state.noteDto.map(function (item) {
                      return (
                        <tbody>
                          <>
                            <Accordion allowZeroExpanded>
                              <AccordionItem>
                                <AccordionItemHeading
                                  style={{ backgroundColor: "#9B9796" }}
                                >
                                  <AccordionItemButton>
                                    <tr className="cuttingTr4 w-100">
                                      <th
                                        className="pl-3"
                                        style={self1.thStyle3}
                                      >
                                        Not Başlığı
                                      </th>
                                      <td style={self1.tdStyle2}>
                                        {item.title}
                                      </td>
                                    </tr>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <tr></tr>
                                  <tr>
                                    <th className="pl-3">Not</th>
                                    <td>{item.description}</td>
                                  </tr>
                                  <CgnButton
                                    type="button"
                                    color="success"
                                    className="printButton ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.addModalUpdateNote(item.guid)
                                    }
                                    text="Güncelle"
                                  />
                                  <CgnButton
                                    type="button"
                                    color="danger"
                                    style={{ width: "90px" }}
                                    className="ml-3 mt-2 mb-2"
                                    onClick={() =>
                                      self1.handleNoteDelete(item.guid)
                                    }
                                    text="Sil"
                                  />
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </>
                        </tbody>
                      );
                    })}
                  </Table>
                </>
              )}
            </Card>

            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
        <Modal
          isOpen={this.state.isOpenModalNote}
          toggle={this.addModalNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.guid} updated={false} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.isOpenModalUpdateNote}
          toggle={this.addModalUpdateNote}
          className="modal-xl content"
        >
          <ModalBody className="modalBody">
            <NoteAddUpdate guid={this.state.noteGuid} updated={true} />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalUpdateNote}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default OfferGenerationDetail;
