import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import moment from "moment-timezone";
registerLocale("tr", tr);

const CgnDatePicker = ({
  name,
  label,
  error,
  selected,
  autoComplete,
  onChange,
  disabled,
  placeholder,
  maxDate,
  minDate,
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <FormGroup className={wrapperClass}>
      <Label className="font-weight-bolder d-block" for={name}>
        {label}
      </Label>
      {!disabled && (
        <DatePicker
          id={name}
          name={name}
          className="datepicker"
          locale="tr"
          dateFormat="dd/MM/yyyy"
          selected={selected ? new Date(Date.parse(selected)) : null}
          onChange={onChange}
          autoComplete={autoComplete}
          placeholderText={placeholder}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          showYearDropdown
        />
      )}
      {disabled && (
        <Input
          type="text"
          name={name}
          id={name}
          value={moment(selected).locale("tr").format("DD/MM/yyyy")}
          disabled="disabled"
        />
      )}
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
};

export default CgnDatePicker;
