import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  
  URL,
  ColorSend,
  ColorBorderSend,
} from "../../redux/actions/constants";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

class LocationDetail extends Component {
  data = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };

  state = {
    pageTitle: "Depo Detay",
    pageTitleDetail: "Depo Detay",
    pageAddLink: "depo-ekle",
    pageListLink: "depo-listele",
    breadcrumb: [{ text: "Depo", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    name: "",
    description: "",
    locationFullnessDtos: [],
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    chartData: this.data,
    total: 0,
  };

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/locations/get/" + urlSplit[2], config)
        .then((response) => {
          var total = 0;
          var arrayLabel = [];
          var arrayNumber = [];
          var arrayBackgroundColor = [];
          var arrayBorderColor = [];
          {
            response.data.locationFullnessDtos.map((item, idx) => {
              total += parseFloat(item.percentage.toFixed(2));
              arrayLabel.push(item.explanation);
              arrayNumber.push(item.percentage);
              arrayBackgroundColor.push(ColorSend(idx));
              arrayBorderColor.push(ColorBorderSend(idx));
            });
          }
          arrayLabel.push("Boş");
          arrayNumber.push(100 - total);
          arrayBackgroundColor.push("rgba(29, 75, 140, 0.5");
          arrayBorderColor.push("rgba(29, 75, 140, 1)");
          var arrayChartData = {
            labels: arrayLabel,
            datasets: [
              {
                label: "%",
                data: arrayNumber,
                backgroundColor: arrayBackgroundColor,
                borderColor: arrayBorderColor,
                borderWidth: 1,
              },
            ],
          };
          this.setState({
            name: response.data.name,
            description: response.data.description,
            locationFullnessDtos: response.data.locationFullnessDtos,
            status: response.data.status,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            total: total,
            empty: parseFloat(100 - total),
            chartData: arrayChartData,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  thStyle = {
    width: "175px",
    textAlign: "center",
  };

  tdStyle = {
    width: "175px",
    textAlign: "center",
  };

  thStyle2 = {
    width: "auto",
  };

  tdStyle2 = {
    width: "auto",
    paddingLeft: "10px",
  };

  mainRow = {
    backgroundColor: "#1d4b8c",
    color: "#fff",
  };

  render() {
    var self = this;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}
              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Depo</th>
                    <td colSpan="4">
                      {this.state.name ? this.state.name : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Açıklama</th>
                    <td colSpan="4">
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Durumu</th>
                    <td colSpan="4">{this.state.status ? "Aktif" : "Pasif"}</td>
                  </tr>
                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              {/* {this.state.locationFullnessDtos !== null &&
                this.state.locationFullnessDtos.length > 0 && (
                  <>
                    <h2 className="mt-1">Doluluk Oranları</h2>
                    <Table className="table table-responsive table-hover table-product table-product-2 mt-2">
                      <tr style={this.mainRow}>
                        <th style={this.thStyle}>Yüzde</th>
                        <th style={this.thStyle2}>Açıklama</th>
                      </tr>
                      {this.state.locationFullnessDtos.map(function (item) {
                        return (
                          <tr style={self.subRow}>
                            <td style={self.tdStyle}>
                              {"%" + item.percentage}
                            </td>
                            <td style={self.tdStyle2}>{item.explanation}</td>
                          </tr>
                        );
                      })}
                      <tr className="bottomRow">
                        <th style={this.thStyle}>
                          {"Dolu : %" + this.state.total.toFixed(2)}
                        </th>
                        <th style={this.thStyle2}>
                          {"Boş : %" + this.state.empty.toFixed(2)}
                        </th>
                      </tr>
                    </Table>
                  </>
                )} */}
              <Row className="chartRow">
                <Col lg={3}>
                  <Pie data={this.state.chartData} />
                </Col>
              </Row>
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default LocationDetail;
