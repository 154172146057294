import React, { Component } from "react";
import { Row, Col, Card, Form } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  
  emailStatus
} from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class CustomerDocumentOtherAddUpdate extends Component {
  state = {
    pageTitle: "Diğer Çeviri İşlemleri Ekle (ÜTS Dışı)",
    pageTitleAdd: "Diğer Çeviri İşlemleri Ekle (ÜTS Dışı)",
    pageTitleUpdate: "Diğer Çeviri İşlemleri Güncelle",
    pageAddLink: "musteri-diger-islemler-ekle",
    pageListLink: "musteri-diger-islemler-listele",
    breadcrumb: [{ text: "Diğer Çeviri İşlemleri", link: "#" }],
    isLoading: false,
    urlSplit: [],
    name: "",
    offerArr: [],
    offerGuid: "",
    customerGuid: "",
    customerName: "",
    customerArr: [],
    guid: "",
    statusMail: "",
    statusMailError: "",
    description: "",
    descriptionError: "",
    status: true,
    nameError: "",
    alertErrorMessage: "",
    customerError: "",
    offerError: "",
    customerDocumentImageDtoProductError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    await axios
    .get(URL + "/customers/getlist/" + statusType, config)
    .then((response) => {
      var myArr = response.data.map(function (item) {
        return {
          value: item.guid,
          label: item.name,
        };
      });
      this.setState({
        customerArr: myArr,
      });
    })
    .catch((error) => {
      this.setState({
        alertErrorMessage: error.response.data,
        isLoading: false,
      });
    });
    await axios
    .get(URL + "/offerNews/getlistdocumenttype", config)
    .then((response) => {
      var myArr = response.data.map(function (item) {
        return {
          value: item.guid,
          label: item.offerDate + "-" + item.customerName + "-" + item.total + " " +
         (item.currencyType === 1 ? "TL" : item.currencyType === 2 ? "$" : item.currencyType === 3 ? "€" : ""),
        };
      });
      this.setState({
        offerArr: myArr,
      });
    })
    .catch((error) => {
      this.setState({
        alertErrorMessage: error.response.data,
        isLoading: false,
      });
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3) {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
        isLoading: true,
      });
      this.getById(urlSplit[2]);
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/customerDocumentOthers/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              customerName: response.data.customerName,
              customerGuid: response.data.customerGuid,
              offerGuid: response.data.offerGuid,
              status: response.data.status,
              description: response.data.description,
              statusMail: response.data.statusMail, 
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        urlSplit: urlSplit,
        pageTitle: this.state.pageTitleAdd,
        status: true,
      });
    }
    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      descriptionError: "",
      alertErrorMessage: "",
    });
    if (name === "description" && value === "") {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleOfferSelectChange(option) {
    if (option) {
      this.setState({
        offerGuid: option.value,
        offerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        offerGuid: "",
        offerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }


  handleStatusMailSelectChange(option) {
    if (option) {
      this.setState({
        statusMail: option.value,
        statusMailError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        statusMail: "",
        statusMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleCustomerSelectChange(option) {
    if (option) {
      this.setState({
        customerGuid: option.value,
        customerError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        customerGuid: "",
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleClear = (event) => {
    this.clearPage();
  };

  clearPage() {
    this.setState({
      status: true,
      description: "",
      offerGuid: "",
      customerGuid: "",
      alertErrorMessage: CgnMessage.alertErrorMessage,
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      customerError: "",
      offerError: "",
      descriptionError: "",
    });
    if (this.state.offerGuid === "" || this.state.offerGuid === null) {
      this.setState({
        offerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.customerGuid === "" || this.state.customerGuid === null) {
      this.setState({
        customerError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }  
    if (this.state.statusMail === "" || this.state.statusMail === null) {
      this.setState({
        statusMailError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.description === "" || this.state.description === null) {
      this.setState({
        descriptionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    } 
    else if (this.state.customerGuid !== null &&
            this.state.offerGuid !== "" &&
            this.state.description !== "") {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        customerGuid: this.state.customerGuid,
        offerGuid: this.state.offerGuid,
        description: this.state.description,
        statusMail: this.state.statusMail,
        status: this.state.status,
      };
      var URLParam = "/customerDocumentOthers/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "musteri-diger-islemler-guncelle"
      ) {
        URLParam = "/customerDocumentOthers/update";
        myObj = {
          guid: this.state.guid,
          customerGuid: this.state.customerGuid,
          offerGuid: this.state.offerGuid,
          description: this.state.description,
          statusMail: this.state.statusMail,
          status: this.state.status,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (
              this.state.guid &&
              this.state.urlSplit[1] === "musteri-diger-islemler-guncelle"
            ) {
              window.location.href = "/musteri-diger-islemler-listele";
            }
            if (this.state.urlSplit[1] === "musteri-diger-islemler-ekle") {
              window.location.reload();
            }
            this.setState({
              isLoading: false,
            });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "musteri-diger-islemler-ekle" ||
          this.state.urlSplit[1] === "musteri-diger-islemler-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            listLink={"/" + this.state.pageListLink}
          />
        )}       
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="5" className="pl-0">
                  <CgnReactSelect
                          name="offerGuid"
                          label="Teklif [*]"
                          placeholder="Teklif seçiniz..."
                          selectValue={this.state.offerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleOfferSelectChange(option)
                          }
                          options={this.state.offerArr}
                          error={this.state.offerError}
                        />
                  </Col>
                  <Col lg="5">
                  <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCustomerSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                        />
                  </Col>
                  <Col lg="2">
                  <CgnReactSelect
                          name="statusMail"
                          label="E-mail Durumu [*]"
                          placeholder="E-mail durumu seçiniz..."
                          selectValue={this.state.statusMail}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleStatusMailSelectChange(option)
                          }
                          options={emailStatus}
                          error={this.state.statusMailError}
                        />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                  <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama [*]"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>

            {(this.state.urlSplit[1] === "musteri-diger-islemler-ekle" ||
              this.state.urlSplit[1] === "musteri-diger-islemler-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(CustomerDocumentOtherAddUpdate);
