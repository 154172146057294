import React, { Component } from "react";
import { Row, Col, Card, Table,   ModalHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter, } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {  URL, fotoStyle } from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";

class CastingCostDetail extends Component {
  state = {
    pageTitle: "Döküm Maliyeti Detay",
    pageTitleDetail: "Döküm Maliyeti Detay",
    pageAddLink: "dokum-maliyet-ekle",
    pageListLink: "dokum-maliyet-listele",
    breadcrumb: [{ text: "Döküm Maliyeti", link: "#" }],
    isLoading: false,
    alertErrorMessage: "",
    sieveProductGuid: "",
    offerSieveGuid: "",
    offerSieveName: "",
    sieveProductArr: [],
    sieveEyeSize: "",
    sieveProductName: "",
    sieveProductError: "",
    sieveSizeArr: [],
    sieveEyeSizeArr: [],
    sieveSizeMultiplier: 0,
    sieveEyeSizeMultiplier: 0,
    sieveSize: "",
    tDIEster: 0,
    castingCostTotal: 0,
    tDIEsterRate: 0,
    tDIEsterTotal: 0,
    moca: 0,
    mocaPercent: 0,
    mocaRate: 0,
    mocaTotal: 0,
    transport: 0,
    transportRate: 0,
    transportTotal: 0,
    dSM: 0,
    dSMRate: 0,
    dSMTotal: 0,
    sandblasting: 0,
    sandblastingRate: 0,
    sandblastingTotal: 0,
    sidePlate: 0,
    sidePlateRate: 0,
    sidePlateTotal: 0,
    lama810: 0,
    lama810Rate: 0,
    lama810Total: 0,
    lama1020: 0,
    lama1020Rate: 0,
    lama1020Total: 0,
    rope: 0,
    ropeRate: 0,
    ropeTotal: 0,
    ring: 0,
    ringRate: 0,
    ringTotal: 0,
    pim: 0,
    pimRate: 0,
    pimTotal: 0,
    profitRate: 0,
    pieceTotal: 0,
    m2Total: 0,
    total: 0,
    tDIEther: 0,
    tDIEtherRate: 0,
    tDIEtherTotal: 0,
    mDIEster: 0,
    mDIEsterRate: 0,
    mDIEsterTotal: 0,
    mDIEther: 0,
    mDIEtherRate: 0,
    mDIEtherTotal: 0,
    bDO: 0,
    bDORate: 0,
    bDOTotal: 0,
    sundry: 0,
    sundryRate: 0,
    sundryTotal: 0,
    updatedAt: "",
    updatedBy: "",
    createdAt: "",
    createdBy: "",
    descripiton: "",
    sundryKg: 0,
    productWeightKg: 0,
    shoreA: "",
    bDOPercent: "",
    castingCostPictureDto: [],
  };

  

  async componentDidMount() {
    let urlSplit = window.location.pathname.split("/");
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(URL + "/castingCosts/get/" + urlSplit[2], config)
        .then((response) => {
          this.setState({
            guid: response.data.guid,
            offerSieveGuid: response.data.offerSieveGuid,
            offerSieveName: response.data.offerSieveName,
            sieveProductGuid: response.data.sieveProductGuid,
            sieveProductName: response.data.sieveProductName,
            sieveSize: response.data.sieveSize,
            sieveEyeSize: response.data.sieveEyeSize,
            tDIEster: parseFloat(response.data.tdiEster),
            tDIEsterRate: parseFloat(response.data.tdiEsterRate),
            moca: parseFloat(response.data.moca),
            mocaRate: parseFloat(response.data.mocaRate),
            mocaPercent: parseFloat(response.data.mocaPercent),
            transport: parseFloat(response.data.transport),
            transportRate: parseFloat(response.data.transportRate),
            dSM: parseFloat(response.data.dsm),
            dSMRate: parseFloat(response.data.dsmRate),
            sandblasting: parseFloat(response.data.sandblasting),
            sandblastingRate: parseFloat(response.data.sandblastingRate),
            sidePlate: parseFloat(response.data.sidePlate),
            sidePlateRate: parseFloat(response.data.sidePlateRate),
            lama810: parseFloat(response.data.lama810),
            lama810Rate: parseFloat(response.data.lama810Rate),
            lama1020: parseFloat(response.data.lama1020),
            lama1020Rate: parseFloat(response.data.lama1020Rate),
            rope: parseFloat(response.data.rope),
            ropeRate: parseFloat(response.data.ropeRate),
            ring: parseFloat(response.data.ring),
            ringRate: parseFloat(response.data.ringRate),
            pim: parseFloat(response.data.pim),
            pimRate: parseFloat(response.data.pimRate),
            profitRate: parseFloat(response.data.profitRate),
            pieceTotal: parseFloat(response.data.pieceTotal),
            m2Total: parseFloat(response.data.m2Total),
            total: parseFloat(response.data.total),
            tDIEther: parseFloat(response.data.tdiEther),
            tDIEtherRate: parseFloat(response.data.tdiEtherRate),
            mDIEster: parseFloat(response.data.mdiEster),
            mDIEsterRate: parseFloat(response.data.mdiEsterRate),
            mDIEther: parseFloat(response.data.mdiEther),
            mDIEtherRate: parseFloat(response.data.mdiEtherRate),
            bDO: parseFloat(response.data.bdo),
            bDORate: parseFloat(response.data.bdoRate),
            castingCostTotal: parseFloat(response.data.castingCostTotal),
            isLoading: false,
            tDIEsterTotal: parseFloat(response.data.tdiEsterTotal),
            tDIEtherTotal: parseFloat(response.data.tdiEtherTotal),
            mDIEsterTotal: parseFloat(response.data.mdiEsterTotal),
            mDIEtherTotal: parseFloat(response.data.mdiEtherTotal),
            bDOTotal: parseFloat(response.data.bdoTotal),
            mocaTotal: parseFloat(response.data.mocaTotal),
            dSMTotal: parseFloat(response.data.dsmTotal),
            sandblastingTotal: parseFloat(response.data.sandblastingTotal),
            sidePlateTotal: parseFloat(response.data.sidePlateTotal),
            lama810Total: parseFloat(response.data.lama810Total),
            lama1020Total: parseFloat(response.data.lama1020Total),
            ropeTotal: parseFloat(response.data.ropeTotal),
            ringTotal: parseFloat(response.data.ringTotal),
            pimTotal: parseFloat(response.data.pimTotal),
            transportTotal: parseFloat(response.data.transportTotal),
            updatedAt: response.data.updatedAt,
            updatedBy: response.data.updatedBy,
            createdAt: response.data.createdAt,
            createdBy: response.data.createdBy,
            description: response.data.description,
            sundry: parseFloat(response.data.sundry),
            sundryRate: parseFloat(response.data.sundryRate),
            sundryTotal: parseFloat(response.data.sundryTotal),
            sundryKg: parseFloat(response.data.sundryKg),
            productWeightKg: parseFloat(response.data.productWeightKg),
            bDOPercent: parseFloat(response.data.bdoPercent),
            shoreA: response.data.shoreA,
            castingCostPictureDto: response.data.castingCostPictureDto,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  
  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "125px",
  };

  tdStyle = {
    width: "180px",
  };

  thStyle1 = {
    width: "200px",
    display: "none",
  };

  tdStyle1 = {
    width: "200px",
    display: "none",
  };

  render() {
    var self1 = this;
    var self = this.state;

    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };

    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body className="table-responsive">
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tbody>
                  <tr>
                    <th>Teklif</th>
                    <td colSpan="4">
                      {this.state.offerSieveName
                        ? this.state.offerSieveName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Malzeme</th>
                    <td colSpan="4">
                      {this.state.sieveProductName
                        ? this.state.sieveProductName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th>Elek Boyutu (mm)</th>
                    <td>{this.state.sieveSize ? this.state.sieveSize : "-"}</td>
                    <th>Elek Göz Boyutu (mm)</th>
                    <td>
                      {this.state.sieveEyeSize ? this.state.sieveEyeSize : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>TDI Ester Birimi (€)</th>
                    <td>
                      {this.state.tDIEsterRate ? this.state.tDIEsterRate : "-"}
                    </td>
                    <th>TDI Ester</th>
                    <td>{this.state.tDIEster ? this.state.tDIEster : "-"}</td>
                  </tr>

                  <tr>
                    <th>MDI Ester Birimi (€)</th>
                    <td>
                      {this.state.mDIEsterRate ? this.state.mDIEsterRate : "-"}
                    </td>
                    <th>MDI Ester</th>
                    <td>{this.state.mDIEster ? this.state.mDIEster : "-"}</td>
                  </tr>

                  <tr>
                    <th>MDI Ether Birimi (€)</th>
                    <td>
                      {this.state.mDIEtherRate ? this.state.mDIEtherRate : "-"}
                    </td>
                    <th>MDI Ether</th>
                    <td>{this.state.mDIEther ? this.state.mDIEther : "-"}</td>
                  </tr>

                  <tr>
                    <th>Moca Birimi (€)</th>
                    <td>{this.state.mocaRate ? this.state.mocaRate : "-"}</td>
                    <th>Moca</th>
                    <td>{this.state.moca ? this.state.moca : "-"}</td>
                  </tr>

                  <tr>
                    <th>BDO Birimi (€)</th>
                    <td>{this.state.bDORate ? this.state.bDORate : "-"}</td>
                    <th>BDO</th>
                    <td>{this.state.bDO ? this.state.bDO : "-"}</td>
                  </tr>

                  <tr>
                    <th>Nakliye Birimi (€)</th>
                    <td>
                      {this.state.transportRate
                        ? this.state.transportRate
                        : "-"}
                    </td>
                    <th>Nakliye</th>
                    <td>{this.state.transport ? this.state.transport : "-"}</td>
                  </tr>

                  <tr>
                    <th>DSM Birimi (€)</th>
                    <td>{this.state.dSMRate ? this.state.dSMRate : "-"}</td>
                    <th>DSM</th>
                    <td>{this.state.dSM ? this.state.dSM : "-"}</td>
                  </tr>

                  <tr>
                    <th>Kumlama Birimi (€)</th>
                    <td>
                      {this.state.sandblastingRate
                        ? this.state.sandblastingRate
                        : "-"}
                    </td>
                    <th>Kumlama</th>
                    <td>
                      {this.state.sandblasting ? this.state.sandblasting : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>Yan Gergi Sacı Birimi (€)</th>
                    <td>
                      {this.state.sidePlateRate
                        ? this.state.sidePlateRate
                        : "-"}
                    </td>
                    <th>Yan Gergi Sacı</th>
                    <td>{this.state.sidePlate ? this.state.sidePlate : "-"}</td>
                  </tr>

                  <tr>
                    <th>8x16 Lama Birimi (€)</th>
                    <td>
                      {this.state.lama810Rate ? this.state.lama810Rate : "-"}
                    </td>
                    <th>8x10 Lama</th>
                    <td>{this.state.lama810 ? this.state.lama810 : "-"}</td>
                  </tr>

                  <tr>
                    <th>10x20 Lama Birimi (€)</th>
                    <td>
                      {this.state.lama1020Rate ? this.state.lama1020Rate : "-"}
                    </td>
                    <th>10x20 Lama</th>
                    <td>{this.state.lama1020 ? this.state.lama1020 : "-"}</td>
                  </tr>

                  <tr>
                    <th>Halat Birimi (€)</th>
                    <td>{this.state.ropeRate ? this.state.ropeRate : "-"}</td>
                    <th>Halat</th>
                    <td>{this.state.rope ? this.state.rope : "-"}</td>
                  </tr>

                  <tr>
                    <th>Kurşun Yüzük Birimi (€)</th>
                    <td>{this.state.ringRate ? this.state.ringRate : "-"}</td>
                    <th>Kurşun Yüzük </th>
                    <td>{this.state.ring ? this.state.ring : "-"}</td>
                  </tr>

                  <tr>
                    <th>Pim Birimi (€)</th>
                    <td>{this.state.pimRate ? this.state.pimRate : "-"}</td>
                    <th>Pim </th>
                    <td>{this.state.pim ? this.state.pim : "-"}</td>
                  </tr>

                  <tr>
                    <th>Muhtelif Birimi (€)</th>
                    <td>
                      {this.state.sundryRate ? this.state.sundryRate : "-"}
                    </td>
                    <th>Muhtelif</th>
                    <td>{this.state.sundry ? this.state.sundry : "-"}</td>
                  </tr>

                  <tr>
                    <th>Muhtelif (Kg)</th>
                    <td>{this.state.sundryKg ? this.state.sundryKg : "-"}</td>
                    <th>Malzeme Maliyeti (€/ad)</th>
                    <td>{this.state.total}</td>
                  </tr>

                  <tr>
                    <th>Toplam (€/m2) </th>
                    <td>{this.state.m2Total}</td>
                    <th>İşçilik Yüzdesi (%)</th>
                    <td>{this.state.profitRate}</td>
                  </tr>

                  <tr>
                    <th>Ürün Ağırlığı (Kg)</th>
                    <td>
                      {this.state.productWeightKg
                        ? this.state.productWeightKg
                        : "-"}
                    </td>
                    <th>Toplam (€/ad)</th>
                    <td>{this.state.castingCostTotal}</td>
                  </tr>

                  <tr>
                    <th>Açıklama</th>
                    <td colSpan={4}>
                      {this.state.description ? this.state.description : "-"}
                    </td>
                  </tr>


                  <tr>
                    <th>BDO Yüzdesi</th>
                    <td>{this.state.bDOPercent ? this.state.bDOPercent : "-"}</td>
                    <th>Shore A</th>
                    <td>{this.state.shoreA ? this.state.shoreA : "-"}</td>
                  </tr>

                  <tr>
                    <th>Kaydeden Kullanıcı</th>
                    <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                    <th>Kaydedilme Tarihi</th>
                    <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                  </tr>
                  <tr>
                    <th>Son Güncelleyen Kullanıcı</th>
                    <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                    <th>Son Güncellenme Tarihi</th>
                    <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                  </tr>
                </tbody>
              </Table>

              {this.state.castingCostPictureDto !== null &&
                this.state.castingCostPictureDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Fotoğraflar</th>
                      </tr>
                      <Row>
                        {this.state.castingCostPictureDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}
            </Card>
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default CastingCostDetail;
