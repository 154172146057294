import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  URL,
  optionStatuses,
  CurrencyType,
  resizeFile,
  fotoStyle,
  StockStatus,
  
  UserOperationClaimControl,
  costTypes
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import noImg from "../../images/no-img.png";
import CategoryAddUpdate from "../category/CategoryAddUpdate";
import ProductPropertyTypeAddUpdate from "../productPropertyType/ProductPropertyTypeAddUpdate";
import ContractAddUpdate from "../contract/ContractAddUpdate";
import { withRouter } from "react-router-dom";
import PictureUploadMulti from "../toolbox/CgnPictureUploadMulti";
import ProductGroupAddUpdate from "../ProductGroup/ProductGroupAddUpdate";

class SieveProductAddUpdate extends Component {
  state = {
    pageTitle: "Ürün Ekle",
    pageTitleAdd: "Ürün Ekle",
    pageTitleUpdate: "Ürün Güncelle",
    pageAddLink: "urun2-ekle",
    pageListLink: "urun2-listele",
    breadcrumb: [{ text: "Ürün", link: "#" }],
    isLoading: false,
    urlSplit: [],
    categoryArr: [],
    categoryGuid: "",
    categoryName: "",
    productGroupArr: [],
    productGroupGuid: "00000000-0000-0000-0000-000000000000",
    productGroupName: "",
    contractGuid: "00000000-0000-0000-0000-000000000000",
    peerProductArr: [],
    peerProductGuid: "00000000-0000-0000-0000-000000000000",
    code: "",
    supplierProductCode: "",
    image: "",
    name: "",
    firstValues: [],
    costType: "",
    criticalPieces: "",
    currencyType: "",
    salePrice: "",
    buyPrice: "",
    productPropertyTypeArr: [],
    sieveProductPropertyDto: [],
    propertyTypeGuid: "",
    sieveProductImageDto: [],
    guid: "",
    name2: "",
    costTypeError: "",
    pieceIdx: 0,
    status: true,
    isNull: false,
    isOpenModalCategory: false,
    isOpenModalProductGroup: false,
    isOpenModalProductPropertyType: false,
    isOpenModalContract: false,
    modalButtonIdx: 0,
    counter: -1,
    counter2: -1,
    stockStatus: 1, // 1se stoklu, 0sa stoksuz
    isTrue: false,
    isTrueBuyPrices: false,
    isTrueSalePrices: false,
    categoryNameError: "",
    productGroupNameError: "",
    codeError: "",
    nameError: "",
    sieveSize: "",
    machineSize: "",
    sieveEyeSize: "",
    criticalPiecesError: "",
    currencyTypeError: "",
    salePriceError: "",
    alertErrorMessage: "",
    isOpenModalProduct: false,
    isOpenModalProduct2: false,
    message: "",
    contractError: "",
    description: null,
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    categoryModalPlus: false,
    productGroupModalPlus: false,
    productPropertyTypeModalPlus: false,
    productModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      categoryModalPlus: await UserOperationClaimControl(1006),
      productPropertyTypeModalPlus: await UserOperationClaimControl(1033),
      sieveProductModalPlus: await UserOperationClaimControl(1029),
      productGroupModalPlus: await UserOperationClaimControl(1288),
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/categories/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          categoryArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/productgroup/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          productGroupArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/contracts/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          contractArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/sieveproducts/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.name}-${item.sieveSize}-${item.sieveEyeSize}`,
            unitBuyPrice: item.buyPrice,
            unitSalePrice: item.salePrice
          };
        });
        this.setState({
          peerProductArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    axios
      .get(URL + "/ProductPropertyTypes/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          productPropertyTypeArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    if (urlSplit.length === 3 && urlSplit[1] === "urun2-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/sieveproducts/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              categoryGuid: response.data.categoryGuid,
              categoryName: response.data.categoryName,
              productGroupGuid: response.data.productGroupGuid,
              productGroupName: response.data.productGroupName,
              contractGuid: response.data.contractGuid,
              peerProductGuid: response.data.peerProductGuid,
              sieveProductPropertyDto: response.data.sieveProductPropertyDto,
              code: response.data.code,
              name: response.data.name,
              criticalPieces: response.data.criticalPieces,
              currencyType: response.data.currencyType,
              salePrice: response.data.salePrice,
              buyPrice: response.data.buyPrice,
              stockStatus: response.data.stockStatus,
              supplierProductCode: response.data.supplierProductCode,
              status: response.data.status,
              description: response.data.description,
              machineSize: response.data.machineSize,
              sieveEyeSize: response.data.sieveEyeSize,
              sieveSize: response.data.sieveSize,
              sieveProductImageDto: response.data.sieveProductImageDto,
              costType: response.data.costType,
              isLoading: false,
              isNull: false,
            });
            if (response.data.stockStatus === 0) {
              this.setState({
                isTrue: true,
                criticalPieces: "",
                criticalPiecesError: "",
                alertErrorMessage: "",
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        image: "",
        categoryGuid: "",
        categoryName: "",
        productGroupGuid: "00000000-0000-0000-0000-000000000000",
        productGroupName: "",
        contractGuid: "00000000-0000-0000-0000-000000000000",
        peerProductGuid: "00000000-0000-0000-0000-000000000000",
        code: "",
        name: "",
        supplierProductCode: "",
        criticalPieces: "",
        currencyType: "",
        salePrice: "",
        buyPrice: "",
        description: null,
        sieveProductPropertyDto: [],
        sieveProductImageDto: [],
        stockStatus: 1,
        machineSize: "",
        sieveEyeSize: "",
        sieveSize: "",
        status: true,
        counter: -1,
        counter2: -1,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }
    if (
      this.state.isOpenModalCategory &&
      this.props.location.modalAddedCategory !== undefined &&
      this.props.location.modalAddedCategory.isModal !== undefined &&
      this.props.location.modalAddedCategory.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/categories/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              categoryGuid: item.categoryGuid,
            };
          });
          this.setState({
            categoryArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedCategory.data &&
              element.name === this.props.location.modalAddedCategory.data
            ) {
              this.setState({
                categoryGuid: element.guid,
                categoryNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedCategory.isModal = true;
      this.setState({
        isOpenModalCategory: false,
      });
    }
    if (
      this.state.isOpenModalProductGroup &&
      this.props.location.modalAddedProductGroup !== undefined &&
      this.props.location.modalAddedProductGroup.isModal !== undefined &&
      this.props.location.modalAddedProductGroup.isModal === false
    ) {
      axios
        .get(URL + "/productgroup/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
              productGroupGuid: item.productGroupGuid,
            };
          });
          this.setState({
            productGroupArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProductGroup.data &&
              element.name === this.props.location.modalAddedProductGroup.data
            ) {
              this.setState({
                productGroupGuid: element.guid,
                productGroupNameError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProductGroup.isModal = true;
      this.setState({
        isOpenModalProductGroup: false,
      });
    }
    if (
      this.state.isOpenModalProductPropertyType &&
      this.props.location.modalAddedProductPropertyType !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal !== undefined &&
      this.props.location.modalAddedProductPropertyType.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      await axios
        .get(URL + "/productPropertyTypes/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            productPropertyTypeArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedProductPropertyType.data &&
              element.name ===
              this.props.location.modalAddedProductPropertyType.data &&
              this.state.modalButtonIdx !== previousState
            ) {
              let sieveProductPropertyDto = this.state.sieveProductPropertyDto;
              sieveProductPropertyDto[this.state.modalButtonIdx].propertyTypeGuid =
                element.guid;
              sieveProductPropertyDto[this.state.modalButtonIdx].propertyTypeError =
                "";
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedProductPropertyType.isModal = true;
      this.setState({
        isOpenModalProductPropertyType: false,
      });
    }
    if (
      this.state.isOpenModalContract &&
      this.props.location.modalAddedContract !== undefined &&
      this.props.location.modalAddedContract.isModal !== undefined &&
      this.props.location.modalAddedContract.isModal === false
    ) {
      config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/contracts/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            contractArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedContract.data &&
              element.name === this.props.location.modalAddedContract.data
            ) {
              this.setState({
                contractGuid: element.guid,
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedContract.isModal = true;
      this.setState({
        isOpenModalContract: false,
      });
    }
    // if (
    //   this.state.isOpenModalProduct &&
    //   this.props.location.modalAddedProduct !== undefined &&
    //   this.props.location.modalAddedProduct.isModal !== undefined &&
    //   this.props.location.modalAddedProduct.isModal === false
    // ) {
    //   // 
    //   config = {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   };
    //   statusType = 2; // sadece aktif olanları göstermesi için
    //   axios
    //     .get(URL + "/products/getlist/" + statusType, config)
    //     .then((response) => {
    //       var myArr = response.data.map(function (item) {
    //         return {
    //           value: item.guid,
    //           label: `${item.code}-${item.name}`,
    //         };
    //       });
    //       this.setState({
    //         peerProductArr: myArr,
    //         isLoading: false,
    //       });
    //       response.data.forEach((element) => {
    //         if (
    //           this.props.location.modalAddedProduct.data &&
    //           element.name === this.props.location.modalAddedProduct.data
    //         ) {
    //           this.setState({
    //             peerProductGuid: element.guid,
    //             peerProductError: "",
    //             alertErrorMessage: "",
    //           });
    //         }
    //       });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         alertErrorMessage: error.response.data,
    //         isLoading: false,
    //       });
    //     });
    //   this.props.location.modalAddedProduct.isModal = true;
    //   this.setState({
    //     isOpenModalProduct: false,
    //   });
    // }  if (
    //   this.state.isOpenModalProduct2 &&
    //   this.props.location.modalAddedProduct2 !== undefined &&
    //   this.props.location.modalAddedProduct2.isModal !== undefined &&
    //   this.props.location.modalAddedProduct2.isModal === false
    // ) {
    //   config = {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   };
    //   statusType = 2; // sadece aktif olanları göstermesi için
    //   await axios
    //     .get(URL + "/products/getlist/" + statusType, config)
    //     .then((response) => {
    //       var myArr = response.data.map(function (item) {
    //         return {
    //           value: item.guid,
    //           label: `${item.code}-${item.name}`,
    //         };
    //       });
    //       this.setState({
    //         maintenanceKitArr: myArr,
    //         isLoading: false,
    //       });
    //       let productData = this.state.maintenanceKitDto;
    //       response.data.forEach((element) => {
    //         if (
    //           this.props.location.modalAddedProduct2.data &&
    //           `${element.code}-${element.name}` ===
    //             this.props.location.modalAddedProduct2.data &&
    //           this.state.modalButtonIdx !== previousState
    //         ) {
    //           productData[this.state.modalButtonIdx].maintenanceKitGuid = element.guid;
    //           productData[this.state.modalButtonIdx].maintenanceKitError = "";
    //         }
    //       });
    //       if (this.props.location.modalAddedProduct.unitBuyPrice) {
    //         productData[this.state.modalButtonIdx].unitBuyPrice =
    //           this.props.location.modalAddedProduct.unitBuyPrice;
    //         productData[this.state.modalButtonIdx].piece = 1;
    //         productData[this.state.modalButtonIdx].productUnitPriceError = "";
    //         productData[this.state.modalButtonIdx].productPieceError = "";
    //       }
    //       this.setState({
    //         alertErrorMessage: "",
    //       });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         alertErrorMessage: error.response.data,
    //         isLoading: false,
    //       });
    //     });
    //   // this.props.location.modalAddedProduct2.isModal = true;
    //   // this.setState({
    //   //   isOpenModalProduct2: false,
    //   // });
    // }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  barcodeGenerator = async () => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/sieveproducts/barcodenumbergenerate", config)
      .then((response) => {
        this.setState({
          code: response.data.toString(),
          codeError: "",
          alertErrorMessage: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  addModalCategory = () => {
    this.setState({
      isOpenModalCategory: !this.state.isOpenModalCategory,
    });
  };

  addModalProductGroup = () => {
    this.setState({
      isOpenModalProductGroup: !this.state.isOpenModalProductGroup,
    });
  };

  addModalContract = () => {
    this.setState({
      isOpenModalContract: !this.state.isOpenModalContract,
    });
  };

  addModalProductPropertyType = async (idx) => {
    await this.setState({
      isOpenModalProductPropertyType:
        !this.state.isOpenModalProductPropertyType,
      modalButtonIdx: idx,
    });
  };

  addModalProduct = () => {
    this.setState({
      isOpenModalProduct: !this.state.isOpenModalProduct,
    });
  };


  addModalProduct2 = async (idx) => {
    await this.setState({
      isOpenModalProduct2: !this.state.isOpenModalProduct2,
      modalButtonIdx: idx,
    });
  };

  handleCostTypeSelectChange(option) {
    if (option) {
      this.setState({
        costType: option.value,
        costTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        costType: "",
        alertErrorMessage: CgnMessage.alertErrorMessage,
        costTypeError: CgnMessage.textErrorMessage,
      });
    }
  }

  handleStockStatus = async (e) => {
    if (e.value === 0) {
      await this.setState({
        stockStatus: 0,
        isTrue: true,
        criticalPieces: "",
        criticalPiecesError: "",
        alertErrorMessage: "",
      });
    } else {
      await this.setState({
        stockStatus: 1,
        isTrue: false,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      categoryNameError: "",
      productGroupNameError: "",
      codeError: "",
      nameError: "",
      criticalPiecesError: "",
      currencyTypeError: "",
      salePriceError: "",
      alertErrorMessage: "",
    });
    if (name === "categoryGuid" && value === "") {
      this.setState({
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "productGroupGuid" && value === "") {
      this.setState({
        productGroupNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "code" && value === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "name" && value === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "criticalPieces" && value === "") {
      this.setState({
        criticalPiecesError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (name === "currencyType" && value === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    var array = this.state.sieveProductPropertyDto;
    array.forEach((item) => {
      item.propertyTypeGuid = "";
      item.value = "";
      item.propertyTypeError = CgnMessage.textErrorMessage;
      item.valueError = CgnMessage.textErrorMessage;
    });
    await this.setState({
      categoryGuid: "",
      categoryName: "",
      productGroupGuid: "00000000-0000-0000-0000-000000000000",
      productGroupName: "",
      contractGuid: "00000000-0000-0000-0000-000000000000",
      peerProductGuid: "00000000-0000-0000-0000-000000000000",
      image: "",
      code: "",
      description: null,
      name: "",
      criticalPieces: "",
      currencyType: "",
      salePrice: "",
      buyPrice: "",
      supplierProductCode: "",
      machineSize: "",
      sieveEyeSize: "",
      sieveSize: "",
      status: true,
      counter: -1,
      counter2: -1,
      stockStatus: 1,
      isTrue: false,
      isTrueBuyPrices: false,
      isTrueSalePrices: false,

      alertErrorMessage: CgnMessage.alertErrorMessage,
      categoryNameError: CgnMessage.textErrorMessage,
      productGroupNameError: CgnMessage.textErrorMessage,
      codeError: CgnMessage.textErrorMessage,
      nameError: CgnMessage.textErrorMessage,
      currencyTypeError: CgnMessage.textErrorMessage,
      criticalPiecesError: CgnMessage.textErrorMessage,
    });
  }

  handleCategorySelectChange(option) {
    if (option) {
      this.setState({
        categoryGuid: option.value,
        categoryNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        categoryGuid: "",
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleProductGroupSelectChange(option) {
    if (option) {
      this.setState({
        productGroupGuid: option.value,
        productGroupNameError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGroupGuid: "00000000-0000-0000-0000-000000000000",
        // productGroupNameError: CgnMessage.textErrorMessage,
        // alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleContractSelectChange(option) {
    if (option) {
      this.setState({
        contractGuid: option.value,
      });
    } else {
      this.setState({
        contractGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handlePeerProductSelectChange(option) {
    if (option) {
      this.setState({
        peerProductGuid: option.value,
      });
    } else {
      this.setState({
        peerProductGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
  }

  handleCurrencyTypeSelectChange(option) {
    if (option) {
      this.setState({
        currencyType: option.value,
        currencyTypeError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        currencyType: "",
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleProductPropertyAdd = async () => {
    var array = this.state.sieveProductPropertyDto;
    await array.push({
      guid: "00000000-0000-0000-0000-000000000000",
      sieveProductGuid: "00000000-0000-0000-0000-000000000000",
      propertyTypeGuid: "",
      value: "",
      propertyTypeError: CgnMessage.textErrorMessage,
      valueError: CgnMessage.textErrorMessage,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    if (array[this.state.counter].propertyTypeGuid) {
      return array[this.state.counter].propertyTypeError === "";
    }
    if (array[this.state.counter].value) {
      return array[this.state.counter].valueError === "";
    }
    if (
      (array[this.state.counter].propertyTypeGuid === "" ||
        array[this.state.counter].value === "") &&
      array.length > 0
    ) {
      this.setState({
        isNull: true,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    this.setState({ sieveProductPropertyDto: array });
  };

  handleTextBoxChange(e, idx) {
    let sieveProductPropertyDto = this.state.sieveProductPropertyDto;
    sieveProductPropertyDto[idx].value = e.target.value;
    this.setState({
      sieveProductPropertyDto: sieveProductPropertyDto,
    });
    this.productPropertyRequiredField();
  }

  handleInputValueChange(option, idx) {
    let sieveProductPropertyDto = this.state.sieveProductPropertyDto;
    if (option) {
      sieveProductPropertyDto[idx].propertyTypeGuid = option.value;
      sieveProductPropertyDto[idx].propertyTypeError = "";
      this.setState({
        sieveProductPropertyDto: sieveProductPropertyDto,
      });
    } else {
      sieveProductPropertyDto[idx].propertyTypeGuid = null;
      sieveProductPropertyDto[idx].propertyTypeError = CgnMessage.textErrorMessage;
      this.setState({
        sieveProductPropertyDto: sieveProductPropertyDto,
      });
    }
    this.productPropertyRequiredField();
  }

  productPropertyRequiredField = () => {
    var arr = this.state.sieveProductPropertyDto;
    arr.forEach((item) => {
      if (!item.propertyTypeGuid && item.value) {
        item.propertyTypeError = CgnMessage.textErrorMessage;
        item.valueError = "";
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (item.propertyTypeGuid && !item.value) {
        item.propertyTypeError = "";
        item.valueError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (!item.propertyTypeGuid && !item.value) {
        item.propertyTypeError = CgnMessage.textErrorMessage;
        item.valueError = CgnMessage.textErrorMessage;
        this.setState({
          alertErrorMessage: CgnMessage.alertErrorMessage,
          isNull: true,
        });
      }
      if (item.propertyTypeGuid && item.value) {
        item.propertyTypeError = "";
        item.valueError = "";
        this.setState({
          alertErrorMessage: "",
          isNull: false,
        });
      }
    });
  };

  async handleProductPropertyRemove(idx) {
    let someArray = await this.state.sieveProductPropertyDto;
    someArray.splice(idx, 1);
    this.setState({
      sieveProductPropertyDto: someArray,
      counter: this.state.counter - 1,
      isNull: false
    });
    if (idx === 0) {
      await this.setState({
        alertErrorMessage: "",
        isNull: false,
        sieveProductGuid: "00000000-0000-0000-0000-000000000000",
        propertyTypeGuid: "",
        value: "",
      });
    }
  }

  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      categoryNameError: "",
      productGroupNameError: "",
      codeError: "",
      nameError: "",
      criticalPiecesError: "",
      currencyTypeError: "",
      salePriceError: "",
      contractError: "",
    });
    if (this.state.categoryGuid === "") {
      this.setState({
        categoryNameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    // if (this.state.productGroupGuid === "") {
    //   this.setState({
    //     productGroupNameError: CgnMessage.textErrorMessage,
    //     alertErrorMessage: CgnMessage.alertErrorMessage,
    //   });
    // }
    if (this.state.code === "") {
      this.setState({
        codeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.costType === "") {
      this.setState({
        costTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.name === "") {
      this.setState({
        nameError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.stockStatus === 1 && this.state.criticalPieces === "") {
      this.setState({
        criticalPiecesError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.currencyType === "") {
      this.setState({
        currencyTypeError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if (this.state.sieveProductPropertyDto.length > 0) {
      this.state.sieveProductPropertyDto.forEach((element) => {
        if (element.productPropertyTypeGuid === "" || element.value === "") {
          control = false;
          this.setState({
            alertErrorMessage: CgnMessage.alertErrorMessage,
          });
        }
      })
    }
    if (
      this.state.categoryGuid !== "" &&
      // this.state.productGroupGuid !== "" &&
      this.state.code !== "" &&
      this.state.name !== "" &&
      ((this.state.stockStatus === 0 && this.state.criticalPieces === "") ||
        (this.state.stockStatus === 1 && this.state.criticalPieces !== "")) &&
      this.state.currencyType !== "" &&
      this.state.isNull === false &&
      control === true &&
      this.state.costType !== ""
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var myObj = {
        categoryGuid: this.state.categoryGuid,
        productGroupGuid: this.state.productGroupGuid,
        contractGuid: this.state.contractGuid,
        code: this.state.code,
        supplierProductCode: this.state.supplierProductCode,
        name: this.state.name,
        peerProductGuid: this.state.peerProductGuid,
        sieveProductPropertyDto: this.state.sieveProductPropertyDto,
        criticalPieces: parseInt(this.state.criticalPieces),
        currencyType: this.state.currencyType,
        salePrice: this.state.salePrice
          ? parseFloat(this.state.salePrice)
          : parseFloat(0),
        buyPrice: this.state.buyPrice
          ? parseFloat(this.state.buyPrice)
          : parseFloat(0),
        stockStatus: this.state.stockStatus,
        status: this.state.status,
        description: this.state.description,
        machineSize: this.state.machineSize,
        sieveEyeSize: this.state.sieveEyeSize,
        sieveSize: this.state.sieveSize,
        sieveProductImageDto: this.state.sieveProductImageDto,
        costType: this.state.costType,
      };
      var URLParam = "/sieveproducts/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "urun2-guncelle"
      ) {
        URLParam = "/sieveproducts/update";
        myObj = {
          guid: this.state.guid,
          image: this.state.image,
          categoryGuid: this.state.categoryGuid,
          productGroupGuid: this.state.productGroupGuid,
          contractGuid: this.state.contractGuid,
          code: this.state.code,
          supplierProductCode: this.state.supplierProductCode,
          name: this.state.name,
          peerProductGuid: this.state.peerProductGuid,
          sieveProductPropertyDto: this.state.sieveProductPropertyDto,
          criticalPieces: parseInt(this.state.criticalPieces),
          currencyType: this.state.currencyType,
          buyPrice: this.state.buyPrice
            ? parseFloat(this.state.buyPrice)
            : parseFloat(0.0),
          salePrice: this.state.salePrice
            ? parseFloat(this.state.salePrice)
            : parseFloat(0),
          stockStatus: this.state.stockStatus,
          status: this.state.status,
          description: this.state.description,
          machineSize: this.state.machineSize,
          sieveEyeSize: this.state.sieveEyeSize,
          sieveSize: this.state.sieveSize,
          sieveProductImageDto: this.state.sieveProductImageDto,
          costType: this.state.costType,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.guid && this.state.urlSplit[1] === "urun2-guncelle") {
              window.location.href = "/urun2-listele/" + this.state.categoryGuid;
            }
            if (this.state.urlSplit[1] === "urun2-ekle") {
              window.location.reload();
            }
            var modalAddedSieveProduct = {
              data: `${this.state.code}-${this.state.name}-${this.state.sieveSize}-${this.state.sieveEyeSize}`,
              salePrice: this.state.salePrice,
              buyPrice: this.state.buyPrice,
              isModal: false,
            };
            this.props.history.push({ modalAddedSieveProduct });
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  uploadedImage = async (event) => {
    let array = this.state.sieveProductImageDto;
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const image = await resizeFile(file, 1000);
        array.push({
          image: image,
          imageName: file.name,
        })
      }
    }
    await this.setState({ sieveProductImageDto: array });
  }

  deleteImage = (event) => {
    var index = -1;
    let array = this.state.sieveProductImageDto;
    this.state.sieveProductImageDto.forEach((element) => {
      index++;
      if (element.image === event) {
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ sieveProductImageDto: array });
        }
      }
    });
  };

  render() {
    const textAreaStyle = {
      padding: "5px 10px",
      height: "100px",
    };
    return (
      <>
        {(this.state.urlSplit[1] === "urun2-ekle" ||
          this.state.urlSplit[1] === "urun2-guncelle") && (
            <ContentTop
              breadcrumb={this.state.breadcrumb}
              title={this.state.pageTitle}
              addLink={"/" + this.state.pageAddLink}
              listLink={"/" + this.state.pageListLink}
            />
          )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                  <Col lg="12">
                    <PictureUploadMulti
                      image={this.state.sieveProductImageDto}
                      noFoto={noImg}
                      style={fotoStyle}
                      accept="image/png, image/jpeg"
                      className="text-center text-danger"
                      label={"Ürün Fotoğrafları"}
                      onChange={this.uploadedImage}
                      onDelete={this.deleteImage}
                      alt={this.state.name}
                    //error={this.state.imageError2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="code"
                      label="Ürün Kodu [*]"
                      value={this.state.code}
                      placeHolder="Lütfen ürün kodu girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.codeError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnButton
                      className="barcodeButton"
                      type="button"
                      color="secondary"
                      block
                      onClick={() => this.barcodeGenerator()}
                      text={"Ürün Kodu Üret"}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="text"
                      name="supplierProductCode"
                      label="Tedarikçi Ürün Kodu"
                      value={this.state.supplierProductCode}
                      placeHolder="Lütfen tedarikçi ürün kodu girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.supplierProductCodeError}
                    />
                  </Col>
                  <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="categoryGuid"
                          label="Kategori [*]"
                          placeholder="Kategori seçiniz..."
                          selectValue={this.state.categoryGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleCategorySelectChange(option)
                          }
                          options={this.state.categoryArr}
                          error={this.state.categoryNameError}
                        />
                      </div>
                      {this.state.categoryModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalCategory()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="3" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="productGroupGuid"
                          label="Ürün Grubu"
                          placeholder="Ürün grubu seçiniz..."
                          selectValue={this.state.productGroupGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductGroupSelectChange(option)
                          }
                          options={this.state.productGroupArr}
                          error={this.state.productGroupNameError}
                        />
                      </div>
                      {this.state.productGroupModalPlus && (
                        <div className="p-0 flexAddButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalProductGroup()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <CgnTextbox
                      type="text"
                      name="name"
                      label="Ürün Adı [*]"
                      value={this.state.name}
                      placeHolder="Lütfen ürün girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.nameError}
                    />
                  </Col>

                  <Col lg="6" className="modalSelectCol">
                    <div className="flex-container">
                      <div className="p-0 flexInput">
                        <CgnReactSelect
                          name="peerProductGuid"
                          label="Emsal Ürün"
                          placeholder="Emsal ürün seçiniz..."
                          selectValue={this.state.peerProductGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handlePeerProductSelectChange(option)
                          }
                          options={this.state.peerProductArr}
                          error={this.state.peerProductError}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="machineSize"
                      label="Makine Boyutu"
                      value={this.state.machineSize}
                      onChange={this.handleChange}
                      placeHolder="Lütfen makine boyutu girin..."
                      autoComplete="off"
                      error={this.state.machineSizeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="sieveSize"
                      label="Elek Boyutu"
                      value={this.state.sieveSize}
                      onChange={this.handleChange}
                      placeHolder="Lütfen elek boyutu girin..."
                      autoComplete="off"
                      error={this.state.sieveSizeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnTextbox
                      type="text"
                      name="sieveEyeSize"
                      label="Elek Göz Boyutu"
                      value={this.state.sieveEyeSize}
                      onChange={this.handleChange}
                      placeHolder="Lütfen elek göz boyutu girin..."
                      autoComplete="off"
                      error={this.state.sieveEyeSizeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="costType"
                      label="Tür [*]"
                      placeholder="Tür seçiniz..."
                      selectValue={this.state.costType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCostTypeSelectChange(option)
                      }
                      options={costTypes}
                      error={this.state.costTypeError}
                    />
                  </Col>
                </Row>

                {this.state.sieveProductPropertyDto.map(
                  (sieveProductPropertyDto, idx) => (
                    <Row className="mobileBorder">
                      <Col lg="6" className="modalSelectCol">
                        <div className="flex-container">
                          <div className="p-0 flexInput">
                            <CgnReactSelect
                              name="propertyTypeGuid"
                              label="Ürün Özellikleri Tipi [*]"
                              placeholder="Lütfen ürün özellikleri tipi seçiniz..."
                              selectValue={sieveProductPropertyDto.propertyTypeGuid}
                              isMulti={false}
                              isClearable={true}
                              onChange={(option) => {
                                this.handleInputValueChange(option, idx);
                              }}
                              options={this.state.productPropertyTypeArr}
                              error={sieveProductPropertyDto.propertyTypeError}
                            />
                          </div>
                          {this.state.productPropertyTypeModalPlus && (
                            <div className="p-0 flexAddButton">
                              <CgnButton
                                type="button"
                                color="secondary"
                                className="modalSelectAddButton"
                                onClick={() =>
                                  this.addModalProductPropertyType(idx)
                                }
                                text={"+"}
                              />
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col lg="5">
                        <CgnTextbox
                          type="text"
                          name="value"
                          label="Ürün Özellikleri [*]"
                          value={sieveProductPropertyDto.value}
                          onChange={(e) => this.handleTextBoxChange(e, idx)}
                          placeHolder="Lütfen ürün özellikleri girin..."
                          //maxLength="50"
                          autoComplete="off"
                          error={sieveProductPropertyDto.valueError}
                        />
                      </Col>

                      <Col lg="1" sm="3">
                        <CgnButton
                          type="button"
                          color="danger"
                          className="dynamicRowDeleteButton"
                          onClick={() => this.handleProductPropertyRemove(idx)}
                          text={"Sil"}
                        />
                      </Col>
                    </Row>
                  )
                )}

                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-3 dynamicRowAddButton"
                      onClick={this.handleProductPropertyAdd}
                      text={"Ürün Özellikleri Ekle"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="pl-0" lg="2">
                    <CgnTextbox
                      type="number"
                      name="buyPrice"
                      label="Alış Fiyatı"
                      value={this.state.buyPrice}
                      placeHolder="Lütfen alış fiyatı girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      readOnly={this.state.isTrueBuyPrices}
                      error={this.state.buyPriceError}
                    />
                  </Col>
                  <Col lg="2">
                    <CgnTextbox
                      type="number"
                      name="salePrice"
                      label="Satış Fiyatı"
                      value={this.state.salePrice}
                      placeHolder="Lütfen satış fiyatı girin..."
                      maxLength="100"
                      autoComplete="off"
                      onChange={this.handleChange}
                      error={this.state.salePriceError}
                      readOnly={this.state.isTrueSalePrices}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="currencyType"
                      label="Para Birimi [*]"
                      placeholder="Lütfen para birimi seçiniz..."
                      selectValue={this.state.currencyType}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleCurrencyTypeSelectChange(option)
                      }
                      options={CurrencyType}
                      error={this.state.currencyTypeError}
                    />
                  </Col>
                  <Col lg="3">
                    <CgnReactSelect
                      name="stockStatus"
                      label="Stok Durumu"
                      placeholder="Lütfen stok durumu seçiniz..."
                      selectValue={this.state.stockStatus}
                      isMulti={false}
                      isClearable={false}
                      onChange={(e) => this.handleStockStatus(e)}
                      options={StockStatus}
                      error={this.state.stockStatusError}
                    />
                  </Col>
                  <Col className="pr-0" lg="2">
                    <CgnTextbox
                      type="number"
                      name="criticalPieces"
                      label="Kritik Adet [*]"
                      value={this.state.criticalPieces}
                      placeHolder="Lütfen kritik adet girin..."
                      maxLength="100"
                      autoComplete="off"
                      disabled={this.state.isTrue}
                      onChange={this.handleChange}
                      error={this.state.criticalPiecesError}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <CgnTextbox
                      inlineStyle={textAreaStyle}
                      type="textarea"
                      name="description"
                      label="Açıklama"
                      value={this.state.description}
                      placeHolder="Lütfen açıklama girin..."
                      maxLength="255"
                      autoComplete="off"
                      onChange={this.handleChange}
                    //error={this.state.descriptionError}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <CgnReactSelect
                      name="status"
                      label="Durumu"
                      isMulti={false}
                      selectValue={this.state.status}
                      options={optionStatuses}
                      onChange={(status) =>
                        this.handleStatusSelectChange(status)
                      }
                    />
                  </Col>
                </Row>
                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "urun2-ekle" ||
              this.state.urlSplit[1] === "urun2-guncelle") && (
                <CgnButtonLink
                  color="secondary"
                  to={"/" + this.state.pageListLink}
                  text={CgnMessage.backButton}
                  className="btn-back"
                />
              )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isOpenModalCategory}
          toggle={this.addModalCategory}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Kategori Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <CategoryAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalCategory}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProductGroup}
          toggle={this.addModalProductGroup}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Ürün Grubu Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ProductGroupAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProductGroup}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalProductPropertyType}
          toggle={this.addModalProductPropertyType}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">
            Ürün Özellikleri Tipi Ekle
          </ModalHeader>
          <ModalBody className="modalBody">
            <ProductPropertyTypeAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalProductPropertyType}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isOpenModalContract}
          toggle={this.addModalContract}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Sözleşme Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <ContractAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalContract}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withRouter(SieveProductAddUpdate);
