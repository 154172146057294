import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Modal, ModalHeader } from "reactstrap";
import CgnAlert from "../toolbox/CgnAlert";
import CgnLoader from "../toolbox/CgnLoader";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import {
  iTypeStatusesOptions,
  localFilePath,
  optionServiceStatusesOptions,
  TaxTypeOptions,
  URL,
  
  fotoStyle2,
  fotoStyle,
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import pdfIcon from "../../images/pdfIcon.png";
import noImg from "../../images/no-img.png";

class ServiceDetail extends Component {
  state = {
    pageTitle: "Servis Detay",
    pageTitleDetail: "Servis Detay",
    pageAddLink: "servis-ekle",
    pageListLink: "servis-listele",
    breadcrumb: [{ text: "Servis", link: "#" }],
    isLoading: false,
    urlSplit: [],
    alertErrorMessage: "",
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    customerConnectionName: "",
    customerConnectionPhone: "",
    customerRisk: "",
    totalSalePrice: "",
    discountPercent: "",
    discountValue: "",
    generalTotal: "",
    currencyType: "",
    taxType: "",
    taxRate: "",
    taxtTotal: null,
    serviceTypeName: "",
    discountTotal: "",
    employeeName: "",
    productCode: "",
    productName: "",
    productionDate: "",
    maintenanceDate: "",
    nextMaintenanceDate: "",
    description: "",
    serviceMaintenanceKitDto: [],
    serviceImageDto: [],
    loadWorkHour: 0,
    totalWorkHour: 0,
    workPressure: 0,
    guarantyArr: [],
    serviceOrderNo: "",
    serialNo: "",
    guarantyName: "",
    notDescription: "",
    status: "",
    createdBy: "",
    createdAt: "",
    updatedAt: "",
    updatedBy: "",
    technicianName: "",
    assistantName: "",
    serviceTime: "",
    customer: "",
    servicePictureDto: [],
    nameSurname: "",
    phone: "",
    address: "",
    workingHours: 0,
    dailyWorkingHours: 0,
  };

  async componentDidMount() {
    var urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit[2],
    });
    if (urlSplit.length === 3) {
      this.setState({
        pageTitle: this.state.pageTitleDetail,
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      await axios
        .get(URL + "/services/get/" + urlSplit[2], config)
        .then((response) => {
          if (response.data.guaranty) {
            const guaranty = response.data.guaranty.split(",");
            var guarantyValues = "";
            guaranty.forEach((element) => {
              if (guarantyValues !== "") {
                guarantyValues += ", ";
              }
              if (element === "1") {
                guarantyValues += "İç";
              }
              if (element === "2") {
                guarantyValues += "Dış";
              }
            });
            this.setState({ guarantyName: guarantyValues });
          }
          this.setState({
            iType: response.data.iType,
            serviceTypeName: response.data.serviceTypeName,
            customerName: response.data.customerName,
            customerPhone: response.data.customerPhone,
            customerAddress: response.data.customerAddress,
            customerConnectionName: response.data.customerConnectionName,
            customerConnectionPhone: response.data.customerConnectionPhone,
            totalSalePrice: response.data.total,
            discountPercent: response.data.discountPercent,
            discountValue: response.data.discountValue,
            generalTotal: response.data.generalTotal,
            currencyType: response.data.currencyType,
            taxType: response.data.taxType,
            taxRate: response.data.taxRate,
            taxTotal: (
              response.data.generalTotal *
              (response.data.taxRate / 100)
            ).toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
            }),
            discountTotal: response.data.discountTotal,
            productCode: response.data.productCode,
            productName: response.data.productName,
            employeeName: response.data.employeeName,
            productionDate: response.data.productionDate,
            maintenanceDate:
              response.data.maintenanceDate === "01/01/1900"
                ? "-"
                : response.data.maintenanceDate,
            nextMaintenanceDate:
              response.data.nextMaintenanceDate === "01/01/1900"
                ? "-"
                : response.data.nextMaintenanceDate,
            description: response.data.description,
            serviceMaintenanceKitDto: response.data.serviceMaintenanceKitDto,
            status: response.data.status,
            loadWorkHour: response.data.loadWorkHour,
            totalWorkHour: response.data.totalWorkHour,
            serviceOrderNo: response.data.serviceOrderNo,
            guarantyArr: response.data.guaranty,
            serialNo: response.data.serialNo,
            workPressure: response.data.workPressure,
            createdBy: response.data.createdBy,
            createdAt: response.data.createdAt,
            updatedBy: response.data.updatedBy,
            updatedAt: response.data.updatedAt,
            isLoading: false,
            technicianName: response.data.technicianName,
            assistantName: response.data.assistantName,
            serviceTime:
              response.data.serviceTime === "01/01/1900 00:00"
                ? "-"
                : response.data.serviceTime,
            customer: response.data.customer,
            serviceImageDto: response.data.serviceImageDto,
            notDescription: response.data.notDescription,
            servicePictureDto: response.data.servicePictureDto,
            exchangeRate: response.data.exchangeRate,
            isExchangeRate: response.data.isExchangeRate,
            discountTotalExchangeRate: response.data.discountTotalExchangeRate,
            nameSurname: response.data.nameSurname,
            phone: response.data.phone,
            address: response.data.address,
            workingHours: response.data.workingHours,
            dailyWorkingHours: response.data.dailyWorkingHours,
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    }
  }

  handleServicePrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/services/print/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  fileClick = (image) => {
    var myData = image;
    let replaceLink = myData.replace(localFilePath + "/Files/", "");
    if (replaceLink) {
      window.open("/Files/" + myData, "_blank");
      this.setState({
        isLoading: false,
      });
    }
  };

  toggle = (image) => {
    this.setState({
      isOpen: !this.state.isOpen,
      isImage: image,
    });
  };

  render() {
    const mainRow = {
      backgroundColor: "#1d4b8c",
      color: "#fff",
    };
    const subRow = {
      backgroundColor: "#f2f2f2",
    };
    var self1 = this;
    var self = this.state;
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          addLink={"/" + this.state.pageAddLink}
          listLink={"/" + this.state.pageListLink}
        />
        <Row>
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title="Uyarı"
                  text={this.state.alertErrorMessage}
                />
              )}

              <Table className="table table-responsive table-hover">
                <tr>
                  <th>Servis Türü</th>
                  <td colSpan="4">{this.state.serviceTypeName}</td>
                </tr>

                <tr>
                  <th>Servis Saati</th>
                  <td colSpan="4">{this.state.serviceTime}</td>
                </tr>

                <tr>
                  <th>Son Bakım Tarihi</th>
                  <td colSpan="4">{this.state.maintenanceDate}</td>
                </tr>

                <tr>
                  <th>Çalışma Saati</th>
                  <td colSpan="4">{this.state.workingHours && this.state.workingHours !== 0 ? this.state.workingHours : "-"}</td>
                </tr>

                <tr>
                  <th>Günlük Çalışma Saati</th>
                  <td colSpan="4">{this.state.dailyWorkingHours && this.state.dailyWorkingHours !== 0 ? this.state.dailyWorkingHours : "-"}</td>
                </tr>

                <tr>
                  <th>Bir Sonraki Bakım Tarihi</th>
                  <td colSpan="4">{this.state.nextMaintenanceDate}</td>
                </tr>

                <tr>
                  <th>Müşteri</th>
                  <td colSpan="4">{this.state.customerName}</td>
                </tr>

                <tr>
                  <th>Müşteri Telefon</th>
                  <td colSpan="4">{this.state.customerPhone}</td>
                </tr>

                <tr>
                  <th>Müşteri Yetkili Ad</th>
                  <td colSpan="4">
                    {this.state.customerConnectionName
                      ? this.state.customerConnectionName
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Müşteri Yetkili Telefon</th>
                  <td colSpan="4">
                    {this.state.customerConnectionPhone
                      ? this.state.customerConnectionPhone
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Servis Ad Soyad</th>
                  <td colSpan="4">
                    {this.state.nameSurname
                      ? this.state.nameSurname
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Servis Telefon</th>
                  <td colSpan="4">
                    {this.state.phone
                      ? this.state.phone
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Servis Adresi</th>
                  <td colSpan="4">
                    {this.state.address
                      ? this.state.address
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Müşteri Risk</th>
                  <td colSpan="4">{this.state.customerRisk ? "Var" : "Yok"}</td>
                </tr>

                <tr>
                  <th>Müşteri Adres</th>
                  <td colSpan="4">{this.state.customerAddress}</td>
                </tr>

                <tr>
                  <th>Servis Hazırlayan Personel</th>
                  <td colSpan="4">{this.state.employeeName}</td>
                </tr>

                <tr>
                  <th>Üretim Tarihi</th>
                  <td colSpan="4">
                    {this.state.productionDate === "01/01/1900"
                      ? "-"
                      : this.state.productionDate}
                  </td>
                </tr>

                <tr>
                  <th>Ürün</th>
                  <td colSpan="4">{this.state.productCode + " / " + this.state.productName}</td>
                </tr>

                <tr>
                  <th>Çalışma Basıncı</th>
                  <td colSpan="4">
                    {this.state.workPressure ? this.state.workPressure : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Yükte Çalışma Saati</th>
                  <td colSpan="4">
                    {this.state.loadWorkHour ? this.state.loadWorkHour : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Çalışma Saati</th>
                  <td colSpan="4">
                    {this.state.totalWorkHour ? this.state.totalWorkHour : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Servis Sıra No</th>
                  <td colSpan="4">
                    {this.state.serviceOrderNo
                      ? this.state.serviceOrderNo
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Seri No</th>
                  <td colSpan="4">
                    {this.state.serialNo ? this.state.serialNo : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Garanti Türü</th>
                  <td colSpan="4">{this.state.guarantyName}</td>
                </tr>

                <tr>
                  <th>Tutar</th>
                  <td colSpan="4">
                    {this.state.totalSalePrice
                      ? this.state.totalSalePrice +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>İskonto(Yüzde)</th>
                  <td colSpan="4">
                    {this.state.discountPercent
                      ? "% " + this.state.discountPercent
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>İskonto(Net Tutar)</th>
                  <td colSpan="4">
                    {this.state.discountValue && this.state.discountValue > 0
                      ? this.state.discountValue +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Genel Tutar</th>
                  <td colSpan="4">
                    {this.state.generalTotal
                      ? this.state.generalTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>K.D.V. Türü</th>
                  <td colSpan="4">{TaxTypeOptions(this.state.taxType)}</td>
                </tr>

                <tr>
                  <th>K.D.V. Oranı</th>
                  <td>
                    {this.state.taxRate ? "% " + this.state.taxRate : "-"}
                  </td>

                  <th>K.D.V. Tutarı</th>
                  <td>
                    {this.state.taxTotal
                      ? this.state.taxTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Toplam Tutar</th>
                  <td colSpan="4">
                    {this.state.discountTotal
                      ? this.state.discountTotal +
                      " " +
                      (this.state.currencyType === 1
                        ? "₺"
                        : this.state.currencyType === 2
                          ? "$"
                          : this.state.currencyType === 3
                            ? "€"
                            : "-")
                      : "-"}
                  </td>
                </tr>

                {(this.state.currencyType === 2 ||
                  this.state.currencyType === 3) && (
                    <>
                      <tr>
                        <th>Döviz Kuru</th>
                        <td colSpan="4">
                          {this.state.exchangeRate
                            ? this.state.exchangeRate.toLocaleString("tr-TR", {
                              minimumFractionDigits: 2,
                            }) + " ₺"
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Toplam Tutar (₺)</th>
                        <td colSpan="4">
                          {this.state.discountTotalExchangeRate
                            ? this.state.discountTotalExchangeRate.toLocaleString(
                              "tr-TR",
                              {
                                minimumFractionDigits: 2,
                              }
                            ) + " ₺"
                            : "-"}
                        </td>
                      </tr>
                    </>
                  )}

                <tr>
                  <th>Açıklama</th>
                  <td
                    colSpan="4"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description
                        ? this.state.description
                        : "-",
                    }}
                  >
                    {/* {this.state.description ? this.state.description : "-"} */}
                  </td>
                </tr>
                <tr>
                  <th>Firma Açıklama</th>
                  <td colSpan="4">
                    {this.state.notDescription
                      ? this.state.notDescription
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th>Teknisyen</th>
                  <td>
                    {this.state.technicianName !== ""
                      ? this.state.technicianName
                      : "-"}
                  </td>
                  <th>Servis Müşteri</th>
                  <td>
                    {this.state.customer !== null ? this.state.customer : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Yardımcı Personel</th>
                  <td colSpan={4}>
                    {this.state.assistantName !== ""
                      ? this.state.assistantName
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>Durumu</th>
                  <td colSpan="4">
                    {optionServiceStatusesOptions(this.state.status)}
                  </td>
                </tr>

                <tr>
                  <th>Kaydeden Kullanıcı</th>
                  <td>{this.state.createdBy ? this.state.createdBy : "-"}</td>
                  <th>Kaydedilme Tarihi</th>
                  <td>{this.state.createdAt ? this.state.createdAt : "-"}</td>
                </tr>

                <tr>
                  <th>Son Güncelleyen Kullanıcı</th>
                  <td>{this.state.updatedBy ? this.state.updatedBy : "-"}</td>
                  <th>Son Güncellenme Tarihi</th>
                  <td>{this.state.updatedAt ? this.state.updatedAt : "-"}</td>
                </tr>
              </Table>

              {this.state.serviceMaintenanceKitDto !== null &&
                this.state.serviceMaintenanceKitDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product mt-3">
                    <tr style={mainRow}>
                      <th>Bakım Kiti Adı</th>
                      <th className="text-center">Adet</th>
                      <th>Satış Fiyatı</th>
                    </tr>
                    {this.state.serviceMaintenanceKitDto.map(function (item) {
                      return (
                        <tr style={subRow}>
                          <td>{item.maintenanceKitName}</td>
                          <td className="text-center">
                            {item.maintenanceKitPiece}
                          </td>
                          <td>
                            {" "}
                            {item.Price
                              ? item.Price.toLocaleString("tr-TR", {
                                minimumFractionDigits: 2,
                              }) +
                              " " +
                              (item.currencyType === 1
                                ? "₺"
                                : item.currencyType === 2
                                  ? "$"
                                  : item.currencyType === 3
                                    ? "€"
                                    : "-")
                              : item.maintenanceKitSalePrice
                                ? item.maintenanceKitSalePrice.toLocaleString(
                                  "tr-TR",
                                  { minimumFractionDigits: 2 }
                                ) +
                                " " +
                                (item.currencyType === 1
                                  ? "₺"
                                  : item.currencyType === 2
                                    ? "$"
                                    : item.currencyType === 3
                                      ? "€"
                                      : "-")
                                : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </Table>
                )}

              {this.state.serviceImageDto &&
                this.state.serviceImageDto.length > 0 && (
                  <Table className="table table-responsive table-hover table-product2">
                    <tr style={mainRow}>
                      <th>Dosya</th>
                    </tr>
                    <Row>
                      {this.state.serviceImageDto.map(function (item) {
                        return (
                          <>
                            <div className="pl-2">
                              <Button
                                onClick={() => self1.fileClick(item.image)}
                                className="modalButtonDetail"
                              >
                                <img
                                  src={pdfIcon}
                                  style={fotoStyle2}
                                  alt={item.imageName}
                                />
                              </Button>
                              <p style={{ maxWidth: "150px" }}>
                                {item.imageName}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </Table>
                )}

              {this.state.servicePictureDto !== null &&
                this.state.servicePictureDto.length > 0 && (
                  <>
                    <Table className="table table-responsive table-hover table-product2">
                      <tr style={mainRow}>
                        <th>Servis Fotoğrafı</th>
                      </tr>
                      <Row>
                        {this.state.servicePictureDto.map(function (item) {
                          return (
                            <>
                              <div className="pl-4">
                                {item.image ? (
                                  <Button
                                    onClick={() => self1.toggle(item.image)}
                                    className="modalButtonDetail"
                                  >
                                    <img
                                      src={item.image}
                                      style={fotoStyle}
                                      alt={item.errorCodeName}
                                    />
                                  </Button>
                                ) : (
                                  <img
                                    src={noImg}
                                    style={fotoStyle}
                                    alt={item.errorCodeName}
                                  />
                                )}
                              </div>
                              <Modal isOpen={self.isOpen} toggle={self1.toggle}>
                                <ModalHeader
                                  toggle={self1.toggle}
                                ></ModalHeader>
                                <img
                                  className="modalImage"
                                  src={self.isImage}
                                  style={fotoStyle}
                                  alt={item.errorCodeName}
                                />
                              </Modal>
                            </>
                          );
                        })}
                      </Row>
                    </Table>
                  </>
                )}
            </Card>
            <CgnButton
              type="button"
              color="success"
              className="printButton btn-success"
              onClick={() => this.handleServicePrint(this.state.urlSplit)}
              text={"Yazdır"}
            />
            <CgnButtonLink
              color="secondary"
              to={"/" + this.state.pageListLink}
              text="Geri"
              className="btn-back"
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ServiceDetail;
