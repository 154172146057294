import React from "react";
import { ButtonGroup } from "reactstrap";
import CgnButtonLink from "./CgnButtonLink";

const CgnButtonLinkGroup = ({ items }) => {
  return (
    <>
      {items && items.length > 0 && (
        <ButtonGroup>
          {items.map((item, index) => {
            return (item.onClick && item.onClick !== "") ||
              (item.to && item.to !== "") ? (
              <CgnButtonLink
                key={index}
                to={item.to}
                color={item.color}
                text={item.text}
                size={item.size}
                outline={item.outline}
                block={item.block}
                active={item.active}
                disabled={item.disabled}
                onClick={item.onClick}
              />
            ) : null;
          })}
        </ButtonGroup>
      )}
    </>
  );
};

export default CgnButtonLinkGroup;
