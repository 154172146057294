import React, { Component } from "react";
import { Row, Col, Card, Table, Button, Modal, ModalHeader } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  URL,
  
  fotoStyle,
  localFilePath,
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
} from "../../redux/actions/constants";
import noImg from "../../images/no-img.png";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnButton from "../toolbox/CgnButton";

class TransferStockMovements extends Component {
  state = {
    pageTitle: "Stok Hareketleri",
    breadcrumb: [{ text: "Yetkili Stok Aktarım", link: "#" }],
    isLoading: false,
    productData: [],
    alertErrorMessage: "",
    image: "",
    name: "",
    isOpen: false,
    firstDate: "",
    lastDate: "",
    firstDate2: "",
    lastDate2: "",
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateTransferStockMovements")) {
      firstDate = localStorage.getItem("firstDateAssignment");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateTransferStockMovements")) {
      lastDate = localStorage.getItem("lastDateTransferStockMovements");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });

    this.getList(null);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam =
      "/transferstocks/stockmovements/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        this.setState({
          productData: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      //var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: "",
        firstDate2: "",
      });
    }
    localStorage.setItem(
      "firstDateCuttingProcessAccounting",
      this.state.firstDate
    );
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      //var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: "",
        lastDate2: "",
      });
    }
    localStorage.setItem(
      "lastDateCuttingProcessAccounting",
      this.state.lastDate
    );
  }

  handleExcel = async () => {
    this.setState({
      isLoading: true,
    });
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam =
      "/transferstocks/stockmovementsexcel/" +
      this.state.firstDate +
      "/" +
      this.state.lastDate;

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  toggle = (image, name) => {
    this.setState({
      isOpen: !this.state.isOpen,
      image: image,
      name: name,
    });
  };

  thStyle = {
    width: "110px",
  };

  thStyle2 = {
    width: "90px",
  };

  tdStyle = {
    width: "250px",
  };

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          excelLink={(onClick) => this.handleExcel()}
        />
        <Row className="productList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <Row className="filterRow">
                <Col className="pl-0 pr-2" lg="5">
                  <CgnDatePicker
                    name="firstDate2"
                    label="Başlangıç Tarihi [*]"
                    selected={this.state.firstDate2}
                    onChange={(firstDate2) =>
                      this.handleFirstDateChange(firstDate2)
                    }
                    error={this.state.firstDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col className="pl-2 pr-2" lg="5">
                  <CgnDatePicker
                    name="lastDate2"
                    label="Bitiş Tarihi [*]"
                    selected={this.state.lastDate2}
                    onChange={(lastDate2) =>
                      this.handleLastDateChange(lastDate2)
                    }
                    error={this.state.lastDateError}
                    maxDate={new Date(2100, 1, 1)}
                    minDate={new Date(1900, 1, 1)}
                    placeholder="GG/AA/YYYY"
                    autoComplete="off"
                    dateFormat="DD.MM.YYYY"
                  />
                </Col>
                <Col className="pl-2 pr-0">
                  <CgnButton
                    type="button"
                    color="primary"
                    onClick={this.getList}
                    text="Listele"
                    className="listButton"
                  />
                </Col>
              </Row>
              <CgnDatatable
                data={this.state.productData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Fotoğraf",
                    selector: "image",
                    sortable: false,
                    width: "80px",
                    cell: (cell) => (
                      <div>
                        {cell.machineProductImage ? (
                          <Button
                            onClick={() =>
                              this.toggle(
                                cell.machineProductImage,
                                cell.machineProductName
                              )
                            }
                            className="modalButtonList"
                          >
                            <img
                              alt={cell.machineProductName}
                              src={`/Images/${cell.machineProductImage}`}
                              className="rdt_image rounded"
                            />
                          </Button>
                        ) : (
                          <img
                            alt={cell.machineProductName}
                            src={noImg}
                            className="rdt_image rdt_no_image rounded"
                          />
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Ürün Adet Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Depo</th>
                              <td colSpan={3}>{row.locationName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle2}>Aktarılan Depo</th>
                              <td colSpan={3}>{row.transferLocationName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ürün Kodu</th>
                              <td style={this.tdStyle}>
                                {row.machineProductCode}
                              </td>
                              <th style={this.thStyle2}>Ürün Adı</th>
                              <td>{row.machineProductName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td style={this.tdStyle}>{row.createdAt}</td>
                              <th style={this.thStyle2}>Adet</th>
                              <td>{row.piece}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Sağlam Adet</th>
                              <td style={this.tdStyle}>{row.pieceDurable}</td>
                              <th style={this.thStyle2}>Arızalı Adet</th>
                              <td>{row.pieceDefective}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Birim Fiyat</th>
                              <td style={this.tdStyle}>
                                {row.buyPrice}{" "}
                                {row.currencyType === 1
                                  ? "₺"
                                  : row.currencyType === 2
                                  ? "$"
                                  : row.currencyType === 3
                                  ? "€"
                                  : row.currencyType === 4
                                  ? "SFr"
                                  : ""}
                              </td>
                              <th style={this.thStyle2}>Fiyat</th>
                              <td>
                                {row.buyPrice * row.piece}{" "}
                                {row.currencyType === 1
                                  ? "₺"
                                  : row.currencyType === 2
                                  ? "$"
                                  : row.currencyType === 3
                                  ? "€"
                                  : row.currencyType === 4
                                  ? "SFr"
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Raf</th>
                              <td colSpan={3}>
                                {row.machineProductShelfRowDto !== null &&
                                  row.machineProductShelfRowDto.length > 0 &&
                                  row.machineProductShelfRowDto.map(function (
                                    item
                                  ) {
                                    return item.shelf ? item.locationName + " " + item.shelf + "-" + item.row + " / " : "-";
                                  })}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          className="modal-xs"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <img
            className="modalImage"
            src={`/Images/${this.state.image}`}
            style={fotoStyle}
            alt={this.state.name}
          />
        </Modal>
      </>
    );
  }
}

export default TransferStockMovements;
