import * as actionTypes from "../actionTypes";
import { URL } from "../constants";
import { handleError, handleResponse } from "../handles";
import axios from "axios";

export function companyUserGroupListSuccess(success) {
  return {
    type: actionTypes.COMPANY_USER_GROUP_GET_LIST_SUCCESS,
    payload: success,
  };
}

export function companyUserGroupListError(error) {
  return {
    type: actionTypes.COMPANY_USER_GROUP_GET_LIST_ERROR,
    payload: error,
  };
}

export function companyUserGroupList() {
  return async function (dispatch) {
    var config = {
      method: "GET",
      url: URL + "/groups/getlist",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          handleResponse(companyUserGroupListSuccess, dispatch, response);
          handleError(companyUserGroupListError, dispatch, {});
        } else {
          handleResponse(companyUserGroupListSuccess, dispatch, response);
          handleError(companyUserGroupListError, dispatch, {});
        }
      })
      .catch((error) => {
        handleResponse(companyUserGroupListSuccess, dispatch, {});
        handleError(companyUserGroupListError, dispatch, error.response);
      });
  };
}
