import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter 
} from "reactstrap";
import CgnTextbox from "../toolbox/CgnTextbox";
import CgnReactSelect from "../toolbox/CgnReactSelect";
import CgnAlert from "../toolbox/CgnAlert";
import CgnButtonGroup from "../toolbox/CgnButtonGroup";
import CgnLoader from "../toolbox/CgnLoader";
import CgnMessage from "../toolbox/CgnMessage";
import CgnButtonLink from "../toolbox/CgnButtonLink";
import ContentTop from "../root/ContentTop";
import axios from "axios";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import {
  URL,
  
  UserOperationClaimControl,
  checkboxStatus
} from "../../redux/actions/constants";
import CgnButton from "../toolbox/CgnButton";
import { withRouter } from "react-router-dom";
import SupplierAddUpdate from "../supplier/SupplierAddUpdate";

class ManuelControl2AddUpdate extends Component {
  state = {
    pageTitle: "Manuel Kontrol Raporu 2 Ekle",
    pageTitleAdd: "Manuel Kontrol Raporu 2 Ekle",
    pageTitleUpdate: "Manuel Kontrol Raporu 2 Güncelle",
    pageAddLink: "manuel-kontrol-raporu2-ekle",
    pageListLink: "manuel-kontrol-raporu2-listele",
    breadcrumb: [{ text: "Manuel Kontrol Raporu 2", link: "#" }],
    isLoading: false,
    urlSplit: [],
    productArr: [],
    productGuid: "",
    workInstructionArr: [],
    manuelControlPin2Dto: [],
    supplierGuid: "",
    detectionPersonelGuid: "",
    lastControlPersonelGuid: "",
    lastControlGuid: "",
    manuelControlDate: "",
    lastControlPersonelArr: [],
    itemNumber: "",
    nmsNo: "",
    serialNo: "",
    cocNo: "",
    workInstructionGuid: "",
    isOpenModalSupplier: false,
    customerGuid: "",
    personelGuid: "",
    productOutGuid: "",
    guid: "",
    status: true,
    isNull: false,
    modalButtonIdx: 0,
    isButtonDisable: true,
    customerArr: [],
    productOutArr: [],
    supplierArr: [],
    detectionPersonelArr: [],
    lastPersonelArr: [],
    qualityPersonelArr: [],
    qualityPersonelGuid: "",
    isTrue: false,   
    productError: "",
    alertErrorMessage: "",
    workInstructionError: "",
    manuelControlDateError: "",
    detectionPersonelError: "",
    counter: -1,
    message: "",
    statusChange: "",
    statusChangeError: "",
    buttons: [
      {
        type: "button",
        color: "secondary",
        text: CgnMessage.clearButton,
        onClick: (event) => this.handleClear(event),
        disabled: this.isLoading,
      },
      {
        type: "submit",
        color: "primary",
        text: CgnMessage.saveButton,
        disabled: this.isLoading,
      },
    ],
    productModalPlus: false,
    materialModalPlus: false,
    supplierModalPlus: false,
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      productModalPlus: await UserOperationClaimControl(1029),
      materialModalPlus: await UserOperationClaimControl(1133),
      supplierModalPlus: await UserOperationClaimControl(1038),
    });
    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var statusType = 2; // sadece aktif olanları göstermesi için
    axios
      .get(URL + "/workInstructions/getlistsuitabilityform", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.code}-${item.customerName}`,
          };
        });
        this.setState({
          workInstructionArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
      axios
      .get(URL + "/customers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          customerArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
      if (
        urlSplit.length !== 3 &&
        urlSplit[1] !== "manuel-kontrol-raporu2-guncelle"
      ) {
        axios
          .get(URL + "/manuelControlTemplates/getlist/" + statusType, config)
          .then((response) => {
            this.setState({
              manuelControlPin2Dto: response.data,
            });
            let manuelControlPin2Dto = this.state.manuelControlPin2Dto;
            var manuelControlLenght = manuelControlPin2Dto.length;
            for (var i = 0; i < manuelControlLenght; i++) {
              if (i === 0) {
                manuelControlPin2Dto[i].isDisabled = true;
              } else {
                manuelControlPin2Dto[i].isDisabled = false;
                this.state.isButtonDisable = true;
              }
            }
  
            this.setState({ manuelControlPin2Dto: manuelControlPin2Dto });
          })
          .catch((error) => {
            this.setState({
              alertErrorMessage: error.response.data,
            });
          });
      }
      axios
      .get(URL + "/suppliers/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        this.setState({
          supplierArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
        });
      });
      axios
      .get(URL + "/productOutsSecond/getlistforworkorder", config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.productOutDate}-${item.customerName}`,
          };
        });
        this.setState({
          productOutArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      axios
      .get(URL + "/products/getlist/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          var label = `${item.code}-${item.name}`;
          if(item.supplierProductCode && item.supplierProductCode !== "") {
            label = `${item.code}-${item.supplierProductCode}-${item.name}`;
          }
          return {
            value: item.guid,
            label: label,
          };
        });
        this.setState({
          productArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
      await axios
      .get(URL + "/members/getmembers/" + statusType, config)
      .then((response) => {
        var myArr = response.data.map(function (item) {
          return {
            value: item.guid,
            label: `${item.firstName} ${item.lastName}`,
          };
        });
        this.setState({
          detectionPersonelArr: myArr,
          qualityPersonelArr: myArr,
          lastControlPersonelArr: myArr,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
     
    if (urlSplit.length === 3 && urlSplit[1] === "manuel-kontrol-raporu2-guncelle") {
      this.setState({
        guid: urlSplit[urlSplit.length - 1],
        pageTitle: this.state.pageTitleUpdate,
      });
      this.getById(urlSplit[2]);
      axios
        .get(URL + "/manuelControls2/get/" + urlSplit[2], config)
        .then((response) => {
          if (this.state.guid) {
            this.setState({
              guid: response.data.guid,
              productGuid: response.data.productGuid,
              workInstructionGuid: response.data.workInstructionGuid,
              customerGuid: response.data.customerGuid,
              productOutGuid: response.data.productOutGuid,
              personelGuid: response.data.personelGuid,
              serialNo: response.data.serialNo,
              workInstructionCode: response.data.workInstructionCode,
              supplierGuid: response.data.supplierGuid,
              detectionPersonelGuid: response.data.detectionPersonelGuid,
              manuelControlPin2Dto: response.data.manuelControlPin2Dto,
              counter: response.data.manuelControlPin2Dto.length - 1,
              manuelControlDate: response.data.manuelControlDate,
              lastControlPersonelGuid: response.data.lastControlPersonelGuid,
              qualityPersonelGuid: response.data.qualityPersonelGuid,
              isLoading: false
            });
            var arrLength = response.data.manuelControlPin2Dto.length - 1;
            let manuelControlPin2Dto = response.data.manuelControlPin2Dto;
            for (var i = 0; i < manuelControlPin2Dto.length; i++) {
              if (i === arrLength) {
                if(manuelControlPin2Dto[i].statusChange === 2){
                   manuelControlPin2Dto[i].isDisabled = false;
                   this.state.isButtonDisable = true;
                }
                else{
                  manuelControlPin2Dto[i].isDisabled = true;
                  this.state.isButtonDisable = false;
               }
              } else {
                   manuelControlPin2Dto[i].isDisabled = true;
                   this.state.isButtonDisable = false;
              }
            }   
            this.setState({manuelControlPin2Dto: manuelControlPin2Dto});
          }
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }
  async componentDidUpdate(previousState) {
    let urlSplit = window.location.pathname.split("/");
    if (
      urlSplit.length === 2 &&
      this.state.pageTitle !== this.state.pageTitleAdd
    ) {
      this.setState({
        pageTitle: this.state.pageTitleAdd,
        urlSplit: urlSplit,
        serialNo: "",
        workInstructionGuid: "",
        manuelControlDate: "",
        manuelControlPin2Dto: [],
        itemNumber: "",
        detectionPersonelGuid: "",
        nmsNo: "",
        cocNo: "",
        status: true,
        counter: -1,
        isTrue: false,
      });
    }

    if (this.state.status === undefined) {
      this.setState({
        status: true,
      });
    }

    if (
      this.state.isOpenModalSupplier &&
      this.props.location.modalAddedSupplier !== undefined &&
      this.props.location.modalAddedSupplier.isModal !== undefined &&
      this.props.location.modalAddedSupplier.isModal === false
    ) {
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      var statusType = 2; // sadece aktif olanları göstermesi için
      axios
        .get(URL + "/suppliers/getlist/" + statusType, config)
        .then((response) => {
          var myArr = response.data.map(function (item) {
            return {
              value: item.guid,
              label: item.name,
            };
          });
          this.setState({
            supplierArr: myArr,
            isLoading: false,
          });
          response.data.forEach((element) => {
            if (
              this.props.location.modalAddedSupplier.data &&
              element.name === this.props.location.modalAddedSupplier.data
            ) {
              this.setState({
                supplierGuid: element.guid,
                supplierError: "",
                alertErrorMessage: "",
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
      this.props.location.modalAddedSupplier.isModal = true;
      this.setState({
        isOpenModalSupplier: false,
      });
    }
  }

  getById(guid) {
    this.setState({
      isLoading: true,
      guid: guid,
    });
  }

  handleClear = (event) => {
    this.clearPage();
  };

  async clearPage() {
    this.setState({
      serialNo: "",
      workInstructionGuid: "",
      manuelControlDate: "",
      manuelControlPin2Dto: [],
      detectionPersonelGuid: "",
      status: true,
      counter: -1,
      isTrue: false,
    });
  }

  addModalSupplier = () => {
    this.setState({
      isOpenModalSupplier: !this.state.isOpenModalSupplier,
    });
  };

  handleProductSelectChange(option) {
    if (option) {
      this.setState({
        productGuid: option.value,
        productError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        productGuid: "",
        productError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleSupplierSelectChange(option) {
    if (option) {
      this.setState({
        supplierGuid: option.value,
        supplierError: "",
        alertErrorMessage: "",
      });
    } else {
      this.setState({
        supplierGuid: "",
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  }

  handleDetectionPersonelSelectChange(option){
    if(option){
      this.setState({
        detectionPersonelGuid: option.value,
        detectionPersonelError: ""
      });
    }
    else{
      this.setState({
        detectionPersonelGuid: "",
        detectionPersonelError: CgnMessage.alertErrorMessage,
      })
    }
  }

  async handleWorkInstructionSelectChange(option){
   if(option){
    this.setState({
      workInstructionGuid: option.value,
      workInstructionError: "",
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    axios 
    .get(URL + "/workInstructions/get/" + option.value, config)    
    .then((response) => {
      if(response.data.guid){
        this.setState({
           productGuid: response.data.productGuid,
           productOutGuid: response.data.productOutGuid,
           customerGuid: response.data.customerGuid,
           personelGuid: response.data.personelGuid,
           workInstructionCode: response.data.code,
           lastControlPersonelGuid: response.data.lastControlPersonelGuid,
           isLoading: false,
           qualityPersonelGuid: response.data.qualityPersonelGuid
        });
      }
    })
   }
   else{
    this.setState({
      workInstructionGuid: "",
      productGuid: "",
      customerGuid: "",
      personelGuid: "",
      productOutGuid: "",
      workInstructionCode: "",
      lastControlPersonelGuid: "",
      qualityPersonelGuid: "",
      workInstructionError: CgnMessage.alertErrorMessage,
    });
   }
  }


  handleSerialNoTextBoxChange(e) {
    this.setState({
      serialNo: e.target.value,
      serialNoError: "",
      alertErrorMessage: "",
    });
  }

  handleStatusSelectChange(status) {
    this.setState({ status: status.value });
  }

  handleManuelControlDateChange(manuelControlDate){
    if(manuelControlDate){
      this.setState({
        manuelControlDate: manuelControlDate,
        manuelControlDateError: ""
      });
    }
    else{
      this.setState({
        manuelControlDate: "",
        manuelControlDateError: CgnMessage.alertErrorMessage,
      })
    }
  }

  handleDetectionPersonelChange(option){
    if(option){
      this.setState({
        detectionPersonelGuid: option.value,
        detectionPersonelError: ""
      });
    }
    else{
      this.setState({
        detectionPersonelGuid: "",
        detectionPersonelError: CgnMessage.alertErrorMessage,
      })
    }
  }

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange2 = tags => (event) => {
    const { name, value } = event.target;
    let manuelControlPin2Dto = this.state.manuelControlPin2Dto;
    var idx = tags;

    manuelControlPin2Dto[idx][name] = value;
    this.setState({
      manuelControlPin2Dto: manuelControlPin2Dto,
    });
  };

  handleStatusChange(option, idx) {
    let manuelControlPin2Dto = this.state.manuelControlPin2Dto;
    if (option) {
      manuelControlPin2Dto[idx].statusChange = option.value;
      manuelControlPin2Dto[idx].statusChangeError = "";

      if(option.value === 1){
        this.state.isButtonDisable = false;
        if(idx + 1 < manuelControlPin2Dto.length){
          manuelControlPin2Dto[idx + 1].isDisabled = true;
        }
      }
      if(option.value === 2){
        this.state.isButtonDisable = true;
        if(idx + 1 < manuelControlPin2Dto.length){
          manuelControlPin2Dto[idx + 1].isDisabled = false;
          manuelControlPin2Dto[idx + 1].statusChange = 0;
        }
      }
      if(option.value === 3){
        this.state.isButtonDisable = false;
        if(idx + 1 < manuelControlPin2Dto.length){
          manuelControlPin2Dto[idx + 1].isDisabled = true;
        }
      }

    } else {
      manuelControlPin2Dto[idx].statusChange = 0;
      manuelControlPin2Dto[idx].statusChangeError = CgnMessage.alertErrorMessage;

      if (idx + 1 < manuelControlPin2Dto.length) {
        manuelControlPin2Dto[idx + 1].isDisabled = false;
        this.state.isButtonDisable = true;
      }
    }

    this.setState({ manuelControlPin2Dto: manuelControlPin2Dto });
  }

  handlePinAdd = async () => {
    let array = this.state.manuelControlPin2Dto;
    await array.push({
      outConnector: "",
      pinNo: "",
      outConnector2: "",
      pinNo2: "",
      arrivalConnector: "",
      pinNo3: "",
      arrivalConnector2: "",
      pinNo4: "",
      statusChange: "",
      statusChangeError: "",
      isDisabled: true,
    });
    await this.setState({
      counter: this.state.counter + 1,
    });
    this.setState({ manuelControlPin2Dto: array });
  };

  async handlePinRemove(idx) {
    let someArray = this.state.manuelControlPin2Dto;
    someArray.splice(idx, 1);
    await this.setState({
     manuelControlPin2Dto: someArray,
     counter: this.state.counter - 1,
   });
   if (idx === 0) {
    if(someArray.length >= 1){
      if(someArray[someArray.length - 1].statusChange === 2){
        await this.setState({
          isButtonDisable: true,
        })
      }
    }
      await this.setState({
        isButtonDisable: false,
      })
  }
  else{
    if(someArray[someArray.length - 1].statusChange !== null || someArray[someArray.length - 1].statusChange !== undefined){
      if(someArray[someArray.length - 1].statusChange === 2){
        this.setState({
          isButtonDisable: true,
        })
      }
      else{
        this.setState({
          isButtonDisable: false,
        })
      }
    }
    
  }
}


  handleSave = async (event) => {
    event.preventDefault();
    this.setState({
      alertErrorMessage: "",
      workInstructionErrorError: "",
      manuelControlDateError: "",
      supplierError: "",
    });
    if (this.state.workInstructionGuid === "") {
      this.setState({
        workInstructionError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.manuelControlDate === "") {
      this.setState({
        manuelControlDateError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    if (this.state.supplierGuid === "") {
      this.setState({
        supplierError: CgnMessage.textErrorMessage,
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
    var control = true;
    if(this.state.manuelControlPin2Dto !== null && this.state.manuelControlPin2Dto.length > 0){
      this.state.manuelControlPin2Dto.forEach((element) => {
        if(element.statusChange === "" || element.statusChange === null){
          control = false;
        }
      })
    }
  
    if (this.state.workInstructionGuid !== "" &&
        this.state.manuelControlDate !== "" &&
        this.state.supplierGuid !== "" &&
        control === true
    ) {
      this.setState({
        isLoading: true,
      });
      // 
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
  
      var myObj = {
        workInstructionGuid: this.state.workInstructionGuid,
        supplierGuid: this.state.supplierGuid,
        detectionPersonelGuid: this.state.detectionPersonelGuid,
        manuelControlDate: this.state.manuelControlDate,
        manuelControlPin2Dto: this.state.manuelControlPin2Dto,
      };
      var URLParam = "/manuelControls2/add";
      if (
        this.state.guid &&
        this.state.urlSplit.length !== 2 &&
        this.state.urlSplit[1] === "manuel-kontrol-raporu2-guncelle"
      ) {
        URLParam = "/manuelControls2/update";
        myObj = {
          guid: this.state.guid,
          workInstructionGuid: this.state.workInstructionGuid,
          supplierGuid: this.state.supplierGuid,
          detectionPersonelGuid: this.state.detectionPersonelGuid,
          manuelControlDate: this.state.manuelControlDate,
          manuelControlPin2Dto: this.state.manuelControlPin2Dto,
        };
      }
      axios
        .post(URL + URLParam, myObj, config)
        .then(() => {
          setTimeout(() => {
            if (this.state.urlSplit[1] === "manuel-kontrol-raporu2-ekle") {
              window.location.reload();
            }
            if (this.state.guid && this.state.urlSplit[1] === "manuel-kontrol-raporu2-guncelle") {
              window.location.href = "/manuel-kontrol-raporu2-listele";
            }
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            alertErrorMessage: error.response.data,
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alertErrorMessage: CgnMessage.alertErrorMessage,
      });
    }
  };

  render() {
    return (
      <>
       {(this.state.urlSplit[1] === "manuel-kontrol-raporu2-ekle" ||
          this.state.urlSplit[1] === "manuel-kontrol-raporu2-guncelle") && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={this.state.pageTitle}
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card body>
                <CgnLoader visibled={this.state.isLoading} />
                <Row>
                 <Col lg="4">
                 <CgnDatePicker
                      name="manuelControlDate"
                      label="Tarih [*]"
                      selected={
                        this.state.manuelControlDate &&
                        this.state.manuelControlDate.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.manuelControlDate.split("/")[2] +
                                "-" +
                                this.state.manuelControlDate.split("/")[1] +
                                "-" +
                                this.state.manuelControlDate.split("/")[0]
                            )
                          : this.state.manuelControlDate
                      }
                      onChange={(manuelControlDate) =>
                        this.handleManuelControlDateChange(manuelControlDate)
                      }
                      error={this.state.manuelControlDateError}
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                 </Col>
                 <Col lg="4">
                   <CgnTextbox
                   type="text"
                   name="workInstructionCode"
                   label="İş Emri Numarası [*]"
                   value={this.state.workInstructionCode}
                   placeHolder="Lütfen iş emri no girin..."
                   maxLength="100"
                   autoComplete="off"
                   onChange={this.handleChange}
                   error={this.state.workInstructionCodeError}
                   readOnly={true}
                 />
                 </Col>
                 <Col lg="4">
                 <CgnReactSelect
                      name="workInstructionGuid"
                      label="İş Emri [*]"
                      placeholder="Lütfen iş emri girin..."
                      selectValue={this.state.workInstructionGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) => this.handleWorkInstructionSelectChange(option)}
                      options={this.state.workInstructionArr}
                      error={this.state.workInstructionError}
                       />
                 </Col>
                </Row>
                <Row>
                <Col lg="4">
                <div className="flex-container">
                      <div className="p-0 flexMaxInput">
                        <CgnReactSelect
                          name="supplierGuid"
                          label="Tedarikçi [*]"
                          placeholder="Tedarikçi seçiniz..."
                          selectValue={this.state.supplierGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleSupplierSelectChange(option)
                          }
                          options={this.state.supplierArr}
                          error={this.state.supplierError}
                        />
                      </div>
                      {this.state.supplierModalPlus && (
                        <div className="p-0 flexAddMinButton">
                          <CgnButton
                            type="button"
                            color="secondary"
                            className="modalSelectAddButton"
                            onClick={() => this.addModalSupplier()}
                            text={"+"}
                          />
                        </div>
                      )}
                    </div>       
                  </Col>
                  <Col lg="4">
                  <CgnReactSelect
                          name="customerGuid"
                          label="Müşteri [*]"
                          placeholder="Müşteri seçiniz..."
                          selectValue={this.state.customerGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.customerArr}
                          error={this.state.customerError}
                          disabled={true}
                        />        
                  </Col>
                  <Col lg="4">
                  <CgnReactSelect
                          name="qualityPersonelGuid"
                          label="Kalite Personel [*]"
                          placeholder="Kalite Personeli seçiniz..."
                          selectValue={this.state.qualityPersonelGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleQualityPersonelSelectChange(option)
                          }
                          options={this.state.qualityPersonelArr}
                          error={this.state.qualityPersonelError}
                          disabled={true}
                        />        
                  </Col>
                </Row>
                <Row>
                <Col lg="6">
                  <CgnReactSelect
                          name="lastControlPersonelGuid"
                          label="Son Kontrolü Yapan Personel [*]"
                          placeholder="Son kontrolü yapan personeli seçiniz..."
                          selectValue={this.state.lastControlPersonelGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleLastControlPersonelSelectChange(option)
                          }
                          options={this.state.lastControlPersonelArr}
                          error={this.state.lastControlPersonelError}
                          disabled={true}
                        />        
                  </Col>
                  <Col lg="6">
                  <CgnReactSelect
                          name="detectionPersonelGuid"
                          label="Tespiti Yapan Personel [*]"
                          placeholder="Tespiti yapan personeli seçiniz..."
                          selectValue={this.state.detectionPersonelGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleDetectionPersonelSelectChange(option)
                          }
                          options={this.state.detectionPersonelArr}
                          error={this.state.detectionPersonelError}
                        />        
                  </Col>
                 
                </Row>
                <Row>
                <Col lg="6">
                  <CgnReactSelect
                          name="productOutGuid"
                          label="Satış [*]"
                          placeholder="Satış seçiniz..."
                          selectValue={this.state.productOutGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productOutArr}
                          error={this.state.customerError}
                          disabled={true}
                        />        
                  </Col>
                <Col lg="6">
                        <CgnReactSelect
                          name="productGuid"
                          label="Ürün [*]"
                          placeholder="Ürün seçiniz..."
                          selectValue={this.state.productGuid}
                          isMulti={false}
                          isClearable={true}
                          onChange={(option) =>
                            this.handleProductSelectChange(option)
                          }
                          options={this.state.productArr}
                          error={this.state.productError}
                          disabled={true}
                        />                  
                  </Col>
                </Row>


                {this.state.manuelControlPin2Dto.map((manuelControlPin2Dto, idx) => (
                  <>                 
                 <Row className="mobileBorder">
                 <Col lg="2">
                 <CgnTextbox
                   type="text"
                   name="outConnector"
                   label="Çıkış Konnektörü"
                   value={manuelControlPin2Dto.outConnector}
                   placeHolder="Lütfen çıkış konnektörü girin..."
                   autoComplete="off"
                   onChange={this.handleChange2(idx)}
                   disabled={
                    manuelControlPin2Dto.isDisabled === true
                      ? false
                      : true
                  }
                 />                
                 </Col>
                 <Col lg="2">
                 <CgnTextbox
                   type="text"
                   name="pinNo"
                   label="Pin No"
                   value={manuelControlPin2Dto.pinNo}
                   placeHolder="Lütfen pin no girin..."
                   autoComplete="off"
                   onChange={this.handleChange2(idx)}
                   disabled={
                    manuelControlPin2Dto.isDisabled === true
                      ? false
                      : true
                  }
                 />       
                 </Col>

                 <Col lg="2">
                 <CgnTextbox
                   type="text"
                   name="arrivalConnector"
                   label="Varış Konnektörü"
                   value={manuelControlPin2Dto.arrivalConnector}
                   placeHolder="Lütfen varış konnektörü girin..."
                   autoComplete="off"
                   onChange={this.handleChange2(idx)}
                   disabled={
                    manuelControlPin2Dto.isDisabled === true
                      ? false
                      : true
                  }
                 />          
                 </Col>

                 <Col lg="2">
                 <CgnTextbox
                   type="text"
                   name="pinNo2"
                   label="Pin No"
                   value={manuelControlPin2Dto.pinNo2}
                   placeHolder="Lütfen pin no girin..."
                   autoComplete="off"
                   onChange={this.handleChange2(idx)}
                   disabled={
                    manuelControlPin2Dto.isDisabled === true
                      ? false
                      : true
                  }
                 />      
                 </Col>
                 <Col lg="3">
                 <CgnReactSelect
                      name="statusChange"
                      label="Uygunluk Durumu [*]"
                      placeholder="Uygunluk durumu seçiniz..."
                      selectValue={manuelControlPin2Dto.statusChange}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleStatusChange(option, idx)
                      }
                      options={checkboxStatus}
                      error={manuelControlPin2Dto.statusChangeError}
                      disabled={
                        manuelControlPin2Dto.isDisabled === true
                          ? false
                          : true
                      }
                       />
                 </Col>
                 <Col lg="1">
                 <CgnButton
                     className="dynamicRowDeleteButton"
                     type="button"
                     color="danger"
                     onClick={() => this.handlePinRemove(idx)}
                     text={"Sil"}
                     disabled={
                      manuelControlPin2Dto.isDisabled === true
                        ? false
                        : true
                    }
                   />
                 </Col>
                </Row>
               </>
                ))}

                <Row>
                  <Col lg="4">
                    <CgnButton
                      type="button"
                      color="primary"
                      className="mb-2 dynamicRowAddButton"
                      onClick={this.handlePinAdd}
                      text={"Satır Ekle"}
                      disabled={this.state.isButtonDisable === true ? true : false}
                        />
                      {this.state.manuelControlPinError && (
                        <div className="invalid-feedback d-block">
                          {this.state.manuelControlPinError}
                        </div>
                      )}
                  </Col>
                  </Row>

                {this.state.alertErrorMessage && (
                  <CgnAlert
                    color="danger"
                    title={CgnMessage.alertErrorTitle}
                    text={this.state.alertErrorMessage}
                  />
                )}
                <CgnButtonGroup items={this.state.buttons} />
              </Card>
            </Form>
            {(this.state.urlSplit[1] === "manuel-kontrol-raporu2-ekle" ||
              this.state.urlSplit[1] === "manuel-kontrol-raporu2-guncelle") && (
              <CgnButtonLink
                color="secondary"
                to={"/" + this.state.pageListLink}
                text={CgnMessage.backButton}
                className="btn-back"
              />
            )}
          </Col>
        </Row>

        
        <Modal
          isOpen={this.state.isOpenModalSupplier}
          toggle={this.addModalSupplier}
          className="modal-xl content"
        >
          <ModalHeader className="font-weight-bold">Tedarikçi Ekle</ModalHeader>
          <ModalBody className="modalBody">
            <SupplierAddUpdate />
          </ModalBody>
          <ModalFooter>
            <CgnButton
              type="button"
              color="secondary"
              onClick={this.addModalSupplier}
              text="Kapat"
              className="modalButton"
            />
          </ModalFooter>
        </Modal>

      </>
    );
  }
}

export default withRouter(ManuelControl2AddUpdate);
