import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import { URL } from "../../redux/actions/constants";

class OfferSieveListApproved2 extends Component {
  state = {
    pageTitle: "Üretim Takibi (Sevk Edilenler)",
    // pageAddLink: "teklif3-ekle",
    // pageUpdateLink: "teklif3-guncelle",
    pageDetailLink: "uretim-takibi-detay",
    pageListLink: "uretim-takibi-listele",
    breadcrumb: [{ text: "Üretim Takibi", link: "#" }],
    isLoading: false,
    isDeleted: false,
    offerData: [],
    urlSplit: [],
    discountTotal: 0,
    totalPiece: 0,
    productItemCount: 0,
    discountTotalDolar: 0,
    totalPieceDolar: 0,
    productItemCountDolar: 0,
    discountTotalEuro: 0,
    totalPieceEuro: 0,
    productItemCountEuro: 0,
    statusAll: 0,
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
    status5: 0,
    status6: 0,
    guid: "",
    replaceLink: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    customerGuid: "00000000-0000-0000-0000-000000000000",
    customerArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // 

    this.getList();
  }

  getList = async () => {
    this.setState({
      isLoading: true,
      alertErrorMessage: "",
    });


    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/offersieves/listproductiontracking2", config)
      .then((response) => {
        this.setState({
          offerData: response.data.offerSieveDto,
        });

        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "125px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "125px",
  };

  tdStyle2 = {
    width: "100px",
  };

  customerGuidLast = "";

  render() {
    return (
      <>
        <ContentTop
          breadcrumb={this.state.breadcrumb}
          title={this.state.pageTitle}
          listLink={"/" + this.state.pageListLink}
        />
        <Row className="offerList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              <CgnDatatable
                data={this.state.offerData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Teklif Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      return (
                        <>
                          <Table className="table table-responsive ">
                            <tbody>
                              <tr>
                                <th style={this.thStyle}>Tarih</th>
                                <td style={this.tdStyle}>{row.offerDate}</td>
                                <th style={this.thStyle}>Teklif Numarası</th>
                                <td style={this.tdStyle}>{row.offerNumber}</td>
                                <th style={this.thStyle2}>Teslim Süresi</th>
                                <td>{row.deliveryTimeName}</td>
                              </tr>
                              <tr>
                                <th style={this.thStyle}>Müşteri</th>
                                <td colSpan={6}>{row.customerName}</td>
                              </tr>
                              <tr>
                                <th style={this.thStyle}>Ürün Adı</th>
                                <td colSpan={6}>{row.sieveProductName}</td>
                              </tr>
                              <tr>
                                <th style={this.thStyle}>Elek Boyutu</th>
                                <td style={this.tdStyle}>
                                  {row.sieveProductSieveSize !== "" ? row.sieveProductSieveSize : "-"}
                                </td>
                                <th style={this.thStyle}>Elek Göz Boyutu</th>
                                <td colSpan={4}>
                                  {row.sieveProductSieveEyeSize !== "" ? row.sieveProductSieveEyeSize : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={this.thStyle}>Adet</th>
                                <td style={this.tdStyle}>
                                  {row.totalPiece}
                                </td>
                                <th style={this.thStyle2}>Sevk Tarihi</th>
                                <td style={this.tdStyle}>{row.shipmentDate && row.shipmentDate !== "01/01/1900" ? row.shipmentDate : "-"}</td>
                                <th style={this.thStyle}>Teslimat Tarihi</th>
                                <td>
                                  {row.customerEstimatedDeliveryDate ? row.customerEstimatedDeliveryDate : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      );

                    },
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default OfferSieveListApproved2;
