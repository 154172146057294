/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button } from "reactstrap";

const CgnButtonLink = ({
  to,
  color,
  text,
  size,
  outline,
  block,
  active,
  disabled,
  className,
  onClick
}) => {
  return (
    <>
      {to && color && text && (
        <Button
          type="button"
          color={color}
          size={size}
          outline={outline}
          block={block}
          active={active}
          disabled={disabled}
          className={className ? "p-0 " + className : "p-0"}
        >
          <a href={to}>{text}</a>
        </Button>
      )}

       {onClick && color && text &&  (
        <Button
          type="button"
          color={color}
          size={size}
          outline={outline}
          block={block}
          active={active}
          disabled={disabled}
          className={className ? "p-0 " + className : "p-0"}
          onClick={onClick}
        >
          <a href="#">{text}</a>
        </Button>
      )}
    </>
  );
};

export default CgnButtonLink;
