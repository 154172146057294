import React, { Component } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import CgnLoader from "../toolbox/CgnLoader";
import ContentTop from "../root/ContentTop";
import CgnBadge from "../toolbox/CgnBadge";
import CgnAlert from "../toolbox/CgnAlert";
import CgnDatatable from "../toolbox/CgnDatatable";
import CgnMessage from "../toolbox/CgnMessage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import {
  localFilePath,
  URL,
  
  GetMonthFirstDate,
  GetMonthLastDate,
  GetMonthFirstDate2,
  GetMonthLastDate2,
  SupplierList,
} from "../../redux/actions/constants";
import CgnButtonDropdown from "../toolbox/CgnButtonDropdown";
import CgnButton from "../toolbox/CgnButton";
import CgnDatePicker from "../toolbox/CgnDatePicker";
import CgnReactSelect from "../toolbox/CgnReactSelect";

class OrderList extends Component {
  state = {
    pageTitle: "Sipariş Listele",
    pageAddLink: "siparis-ekle",
    pageUpdateLink: "siparis-guncelle",
    pageDetailLink: "siparis-detay",
    pageListLink: "siparis-listele",
    breadcrumb: [{ text: "Sipariş", link: "#" }],
    isLoading: false,
    isDeleted: false,
    orderData: [],
    urlSplit: [],
    discountTotal: 0,
    totalPiece: 0,
    productItemCount: 0,
    discountTotalDolar: 0,
    totalPieceDolar: 0,
    productItemCountDolar: 0,
    discountTotalEuro: 0,
    totalPieceEuro: 0,
    productItemCountEuro: 0,
    statusAll: 0,
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
    status5: 0,
    status6: 0,
    status7: 0,
    guid: "",
    replaceLink: "",
    alertErrorMessage: "",
    detail: false,
    firstDate: "",
    firstDate2: "",
    lastDate: "",
    lastDate2: "",
    supplierGuid: "00000000-0000-0000-0000-000000000000",
    supplierArr: [],
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    let urlSplit = window.location.pathname.split("/");
    this.setState({
      urlSplit: urlSplit,
    });

    var firstDate = GetMonthFirstDate();
    var lastDate = GetMonthLastDate();
    var firstDate2 = GetMonthFirstDate2();
    var lastDate2 = GetMonthLastDate2();

    if (localStorage.getItem("firstDateOrder")) {
      firstDate = localStorage.getItem("firstDateOrder");
      firstDate2 = new Date(firstDate);
    }
    if (localStorage.getItem("lastDateOrder")) {
      lastDate = localStorage.getItem("lastDateOrder");
      lastDate2 = new Date(lastDate);
    }

    await this.setState({
      firstDate: firstDate,
      lastDate: lastDate,
      firstDate2: firstDate2,
      lastDate2: lastDate2,
    });


    // 

    var self = this;

    await SupplierList().then(function (data) {
      self.setState({
        supplierArr: data,
      });
    });

    if (localStorage.getItem("supplierGuidOrder")) {
      this.setState({
        supplierGuid: localStorage.getItem("supplierGuidOrder"),
      });
    }

    this.getList();
  }

  async handleFirstDateChange(firstDate) {
    if (firstDate) {
      await this.setState({
        firstDate:
          firstDate.getMonth() +
          1 +
          "." +
          firstDate.getDate() +
          "." +
          firstDate.getFullYear(),
        firstDate2: firstDate,
      });
    } else {
      var firstDate = GetMonthFirstDate();
      await this.setState({
        firstDate: firstDate,
        firstDate2: firstDate,
      });
    }
    localStorage.setItem("firstDateOrder", this.state.firstDate);
  }

  async handleLastDateChange(lastDate) {
    if (lastDate) {
      await this.setState({
        lastDate:
          lastDate.getMonth() +
          1 +
          "." +
          lastDate.getDate() +
          "." +
          lastDate.getFullYear(),
        lastDate2: lastDate,
      });
    } else {
      var lastDate = GetMonthLastDate();
      await this.setState({
        lastDate: lastDate,
        lastDate2: lastDate,
      });
    }
    localStorage.setItem("lastDateOrder", this.state.lastDate);
  }

  async handleSupplierSelectChange(option) {
    if (option) {
      await this.setState({
        supplierGuid: option.value,
      });
    } else {
      await this.setState({
        supplierGuid: "00000000-0000-0000-0000-000000000000",
      });
    }
    localStorage.setItem("supplierGuidOrder", this.state.supplierGuid);
  }

  getList = async () => {
    this.setState({
      isLoading: true,
    });

    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    var URLParam = "";
    if (this.state.urlSplit[1] === "tedarikci-detay") {
      URLParam = "/orders/getlistforsupplier/" + this.state.urlSplit[2];
      this.setState({
        detail: true,
      });
    } else {
      var urlSplitX =
        this.state.urlSplit.length > 2 ? this.state.urlSplit[2] : 0;
      this.setState({
        urlSplitX: urlSplitX,
      });
      URLParam =
        "/orders/getlisttostatus/" +
        urlSplitX +
        "/" +
        this.state.firstDate +
        "/" +
        this.state.lastDate +
        "/" +
        this.state.supplierGuid;
    }

    await axios
      .get(URL + URLParam, config)
      .then((response) => {
        if (this.state.detail === false) {
          this.setState({
            orderData: response.data.orderDto,
            status1: response.data.status1,
            status2: response.data.status2,
            status3: response.data.status3,
            status4: response.data.status4,
            status5: response.data.status5,
            status6: response.data.status6,
            status7: response.data.status7,
            statusAll: response.data.statusAll,
          });
        } else {
          this.setState({
            orderData: response.data,
          });
        }
        var discountTotal = 0;
        var totalPiece = 0;
        var productItemCount = 0;
        var discountTotalDolar = 0;
        var totalPieceDolar = 0;
        var productItemCountDolar = 0;
        var discountTotalEuro = 0;
        var totalPieceEuro = 0;
        var productItemCountEuro = 0;
        this.state.orderData.forEach((element) => {
          if (element.currencyType === 1) {
            discountTotal += element.discountTotal;
            totalPiece += element.totalPiece;
            productItemCount += element.productItemCount;
          } else if (element.currencyType === 2) {
            discountTotalDolar += element.discountTotal;
            totalPieceDolar += element.totalPiece;
            productItemCountDolar += element.productItemCount;
          } else if (element.currencyType === 3) {
            discountTotalEuro += element.discountTotal;
            totalPieceEuro += element.totalPiece;
            productItemCountEuro += element.productItemCount;
          }
        });
        this.setState({
          discountTotal: discountTotal,
          totalPiece: totalPiece,
          productItemCount: productItemCount,
          discountTotalDolar: discountTotalDolar,
          totalPieceDolar: totalPieceDolar,
          productItemCountDolar: productItemCountDolar,
          discountTotalEuro: discountTotalEuro,
          totalPieceEuro: totalPieceEuro,
          productItemCountEuro: productItemCountEuro,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleDelete = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.deleteQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.deleteItem(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  handleProductEntryAlert = (guid) => {
    confirmAlert({
      title: CgnMessage.deleteQuestionTitle,
      message: CgnMessage.orderQuestionMessage,
      buttons: [
        {
          label: CgnMessage.deleteQuestionYes,
          onClick: () => this.handleOrderToProductEntry(guid),
        },
        {
          label: CgnMessage.deleteQuestionNo,
        },
      ],
    });
  };

  async deleteItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const URLParam = "/orders/delete";
    const obj = {
      guid,
    };
    axios
      .post(URL + URLParam, obj, config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handleOrderToProductEntry = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .post(URL + "/orders/ordertoproductentry/" + guid, "", config)
      .then((response) => {
        if (response.data) {
          window.open("/alis-guncelle/" + response.data, "_self");
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handleCopyOrder = async (guid) => {
    confirmAlert({
      title: CgnMessage.copyQuestionTitle,
      message: CgnMessage.copyQuestionMessage,
      buttons: [
        {
          label: CgnMessage.copyQuestionYes,
          onClick: () => this.copyItem(guid),
        },
        {
          label: CgnMessage.copyQuestionNo,
        },
      ],
    });
  };

  async copyItem(guid) {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .post(URL + "/orders/neworderafterrevision/" + guid, "", config)
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  }

  handlePdfPrint = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/orders/orderPrint/" + guid, config)
      .then((response) => {
        var myData = response.data;
        let replaceLink = myData.replace(localFilePath, "");
        if (replaceLink) {
          window.open(replaceLink, "_blank");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  handlePdfSendMail = async (guid) => {
    this.setState({
      isLoading: true,
    });
    // 
    var config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await axios
      .get(URL + "/orders/orderSendMail/" + guid, config)
      .then((response) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "orderListSendMailAlert",
            },
          ],
        });
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        confirmAlert({
          title: CgnMessage.sendMailTitle,
          message: error.response.data,
          buttons: [
            {
              label: CgnMessage.sendMailConfirm,
              className: "orderListSendMailAlert",
            },
          ],
        });
        this.setState({
          alertErrorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  thStyle = {
    width: "120px",
  };

  thStyle2 = {
    width: "120px",
  };

  tdStyle = {
    width: "150px",
  };

  tdStyle2 = {
    width: "100px",
  };

  render() {
    return (
      <>
        {this.state.detail === false && (
          <ContentTop
            breadcrumb={this.state.breadcrumb}
            title={
              this.state.urlSplit[1] !== "tedarikci-detay"
                ? this.state.pageTitle
                : ""
            }
            addLink={"/" + this.state.pageAddLink}
            listLink={"/" + this.state.pageListLink}
          />
        )}
        <Row className="orderList" id="list">
          <Col>
            <Card body>
              <CgnLoader visibled={this.state.isLoading} />
              {this.state.alertErrorMessage && (
                <CgnAlert
                  color="danger"
                  title={CgnMessage.alertErrorTitle}
                  text={this.state.alertErrorMessage}
                />
              )}
              {this.state.detail === false && (
                <div className="d-inline-block mb-3">
                  <a
                    href={`/${this.state.pageListLink}/${1}`}
                    style={{ backgroundColor: "red" }}
                    className={
                      this.state.urlSplitX === "1"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Beklemede: ${this.state.status1}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${5}`}
                    style={{ backgroundColor: "#ff6e33" }}
                    className={
                      this.state.urlSplitX === "5"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Teklif İstendi: ${this.state.status5}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${6}`}
                    style={{ backgroundColor: "#6434cd" }}
                    className={
                      this.state.urlSplitX === "6"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Onay Aşamasında: ${this.state.status6}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${2}`}
                    style={{ backgroundColor: "#29B237" }}
                    className={
                      this.state.urlSplitX === "2"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Onaylandı: ${this.state.status2}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${3}`}
                    style={{ backgroundColor: "#FFC433", color: "#333" }}
                    className={
                      this.state.urlSplitX === "3"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`İptal Edildi: ${this.state.status3}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${4}`}
                    style={{ backgroundColor: "#0679AF" }}
                    className={
                      this.state.urlSplitX === "4"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Alış: ${this.state.status4}`}</a>
                  <a
                    href={`/${this.state.pageListLink}/${7}`}
                    style={{ backgroundColor: "#7C4811" }}
                    className={
                      this.state.urlSplitX === "7"
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2"
                    }
                  >{`Arşiv: ${this.state.status7}`}</a>
                  <a
                    href={`/${this.state.pageListLink}`}
                    className={
                      this.state.urlSplitX === 0
                        ? "float-left statusTopButton mr-2 btn btn-primary"
                        : "float-left statusTopButton mr-2 bg-secondary"
                    }
                  >{`Tümü: ${this.state.statusAll}`}</a>
                </div>
              )}
              {this.state.detail === false && (
                <Row className="filterRow">
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="firstDate"
                      label="Baş. Tarihi"
                      selected={
                        this.state.firstDate2 &&
                        this.state.firstDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.firstDate2.split("/")[2] +
                                "-" +
                                this.state.firstDate2.split("/")[1] +
                                "-" +
                                this.state.firstDate2.split("/")[0]
                            )
                          : this.state.firstDate2
                      }
                      onChange={(firstDate2) =>
                        this.handleFirstDateChange(firstDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="2">
                    <CgnDatePicker
                      name="lastDate"
                      label="Bit. Tarihi"
                      selected={
                        this.state.lastDate2 &&
                        this.state.lastDate2.toString().indexOf("/") > 0
                          ? new Date(
                              this.state.lastDate2.split("/")[2] +
                                "-" +
                                this.state.lastDate2.split("/")[1] +
                                "-" +
                                this.state.lastDate2.split("/")[0]
                            )
                          : this.state.lastDate2
                      }
                      onChange={(lastDate2) =>
                        this.handleLastDateChange(lastDate2)
                      }
                      maxDate={new Date(2100, 1, 1)}
                      minDate={new Date(1900, 1, 1)}
                      placeholder="GG/AA/YYYY"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pl-2 pr-2" md="7">
                    <CgnReactSelect
                      name="supplierGuid"
                      label="Tedarikçi"
                      placeholder="Tedarikçi seçiniz..."
                      selectValue={this.state.supplierGuid}
                      isMulti={false}
                      isClearable={true}
                      onChange={(option) =>
                        this.handleSupplierSelectChange(option)
                      }
                      options={this.state.supplierArr}
                    />
                  </Col>
                  <Col className="pl-2 pr-0" md="1">
                    <CgnButton
                      type="button"
                      color="primary"
                      onClick={this.getList}
                      text="Listele"
                      className="listButton"
                    />
                  </Col>
                </Row>
              )}
              <CgnDatatable
                data={this.state.orderData}
                title={this.state.pageTitle}
                columns={[
                  {
                    name: "Sipariş Bilgileri",
                    sortable: false,
                    cell: (row) => {
                      var phone = row.supplierPhone,
                        maskPhone = phone ? `${phone.substring(0, 1)}  ${
                          "(" + phone.substring(1, 4) + ")"
                        } ${phone.substring(4, 7)} ${phone.substring(
                          7,
                          9
                        )} ${phone.substring(9, 11)}` : "";
                      return (
                        <Table className="table table-responsive ">
                          <tbody>
                            <tr>
                              <th style={this.thStyle}>Tarih</th>
                              <td colSpan={4}>{row.orderDate}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Tedarikçi</th>
                              <td colSpan={4}>{row.supplierName}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Tedarikçi Telefon</th>
                              <td style={this.tdStyle}>
                                {row.supplierPhone === "" ? "-" : maskPhone}
                              </td>
                              <th style={this.thStyle2}>Tedarikçi Mail</th>
                              <td>{row.supplierMail}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Sipariş No</th>
                              <td style={this.tdStyle}>{row.orderNumber}</td>
                              <th style={this.thStyle2}>Toplam Tutar</th>
                              <td>
                                {row.discountTotal.toLocaleString("tr-TR", {
                                  minimumFractionDigits: 2,
                                }) +
                                  " " +
                                  (row.currencyType === 1
                                    ? "₺"
                                    : row.currencyType === 2
                                    ? "$"
                                    : row.currencyType === 3
                                    ? "€"
                                    : "-")}
                              </td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Firma Açıklama</th>
                              <td colSpan={3}>{row.companyDescription ? row.companyDescription : "-"}</td>
                            </tr>
                            <tr>
                              <th style={this.thStyle}>Ekleyen Kullanıcı</th>
                              {/* <th className="colon">:</th> */}
                              <td colSpan={4}>{row.createdBy}</td>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    },
                  },

                  {
                    selector: "status",
                    width: "150px",
                    padding: "0px",
                    center: true,
                    sortable: false,
                    cell: (row) => {
                      if (row.status === 1) {
                        return (
                          <CgnBadge
                            className="w-100"
                            color="secondary"
                            text="Beklemede"
                            style={{ backgroundColor: "red" }}
                          />
                        );
                      } else if (row.status === 5) {
                        return (
                          <CgnBadge
                            className="w-100"
                            color="secondary"
                            text="Teklif İstendi"
                            style={{ backgroundColor: "#ff6e33" }}
                          />
                        );
                      } else if (row.status === 6) {
                        return (
                          <CgnBadge
                            className="w-100"
                            color="secondary"
                            text="Onay Aşamasında"
                            style={{ backgroundColor: "#6434cd" }}
                          />
                        );
                      } else if (row.status === 2) {
                        return (
                          <CgnBadge
                            className="w-100"
                            color="secondary"
                            text="Onaylandı"
                            style={{ backgroundColor: "#29B237" }}
                          />
                        );
                      } else if (row.status === 3) {
                        return (
                          <CgnBadge
                            className="w-100"
                            color="secondary"
                            text="İptal Edildi"
                            style={{ backgroundColor: "#FFC433", color: "#333" }}
                          />
                        );
                      } else if (row.status === 4) {
                        return (
                          <CgnBadge
                            className="w-100"
                            color="secondary"
                            text="Alış"
                            style={{ backgroundColor: "#0679AF" }}
                          />
                        );
                      } else if (row.status === 7) {
                        return (
                          <CgnBadge
                            className="w-100"
                            color="secondary"
                            text="Arşiv"
                            style={{ backgroundColor: "#7C4811" }}
                          />
                        );
                      }
                    },
                  },

                  {
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    cell: (row, cell) => (
                      <CgnButtonDropdown
                        label={"Yönet"}
                        className="mr-0"
                        color="primary"
                        items={[
                          {
                            text: CgnMessage.updateButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageUpdateLink}/${row.guid}`,
                          },
                          {
                            text: CgnMessage.detailButton,
                            className: "dropDownBtn",
                            href: `/${this.state.pageDetailLink}/${row.guid}`,
                          },
                          {
                            text: row.status === 2 ? "Alışa Dönüştür" : "",
                            className: "dropDownBtn",
                            onClick: () =>
                              this.handleProductEntryAlert(row.guid),
                          },
                          // {
                          //   text: row.status === 1 ? "Kopyala" : "",
                          //   className: "dropDownBtn",
                          //   onClick: () => this.handleCopyOffer(row.guid),
                          // },
                          {
                            text: "Sipariş Yazdır",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfPrint(row.guid),
                          },
                          {
                            text:
                              row.status === 1 && row.supplierMail !== ""
                                ? "Sipariş E-Mail Gönder"
                                : "",
                            className: "dropDownBtn",
                            onClick: () => this.handlePdfSendMail(row.guid),
                          },
                          {
                            text: CgnMessage.deleteButton,
                            className: "dropDownBtn",
                            onClick: () => this.handleDelete(row.guid),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                loading={this.state.isLoading}
              />
            </Card>
            <div className="d-block clearfix">
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (₺):{" "}
                {this.state.discountTotal.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                ₺
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar ($):{" "}
                {this.state.discountTotalDolar.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                $
              </div>
              <div className="float-right ListDownButton mr-2">
                Toplam Tutar (€):{" "}
                {this.state.discountTotalEuro.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                })}{" "}
                €
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default OrderList;
