import React from "react";
import { Button, FormGroup } from "reactstrap";
import FileInput from "./CgnFileInput";

const ImageUpload = ({
  image,
  noFoto,
  alt,
  onChange,
  onDelete,
  error,
  disabled,
  label,
  name,
  style,
  hidden,
  accept,
}) => {
  let wrapperClass = "form-group image-upload";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <FormGroup className={wrapperClass}  hidden={hidden}>
      <img src={image ? image : noFoto} alt={alt} className="image mb-2 rounded" style={style} />
      {image && !disabled && (
        <Button color="danger" onClick={onDelete}>
           Sil
        </Button>
      )}
      {!disabled && (
        <FileInput
          accept={accept}
          name={name}
          label={label}
          placeHolder="Lütfen fotoğraf/dosya seçin..."
          autoComplete="off"
          multiple={false}
          onChange={onChange}
          hidden={hidden}
        />
      )}
      {error && !hidden && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
};

export default ImageUpload;
